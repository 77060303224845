import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import * as Sentry from "@sentry/react";
import { SignIn } from "aws-amplify-react";
import { Card, CardBody } from "reactstrap";

import rivialLogo from "../../../assets/img/brand/rivialLogo.png";
import { ErrorLogger } from "../../EventLogger";

import FirstSignIn from "./FirstSignIn";
import { handleSignInError } from "./functions/handleSignInError";
import Login from "./Login";
import { isMFAChallenge } from "./MFA/functions/isMFAChallenge";
import { isOrgRequiresMFA } from "./MFA/functions/isOrgRequiresMFA";
import MFAScreen from "./MFA/MFAScreen";

/**
 * @description This component extends Amplify SignIn page with a custom layout
 * @param {object} props - The props passed to the component
 * @returns {JSX.Element}
 */
export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      user: null,
      isMFA: false,
      isNewPassword: false,
      qrCode: "",
      isLoading: false,
    };

    this.handleFederatedSignInFailure = this.handleFederatedSignInFailure.bind(this);
    this.signIn = this.signIn.bind(this);

    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn_failure") {
        this.handleFederatedSignInFailure(data?.message);
      }
    });
  }

  handleFederatedSignInFailure(message) {
    const isInvalidRequest = message === "invalid_request";
    const isInvalidFunctionOutput = typeof message === "string" && message.includes("Invalid+lambda+function+output");

    if (isInvalidRequest || isInvalidFunctionOutput) {
      const errorMessage =
        "Failed to sign in with provider. This provider might not be configured yet for your organization, please contact an administrator.";
      //NOTE: attempted to call on mount but UI alert does not appear, therefore using a timeout
      setTimeout(() => {
        this.error(new Error(errorMessage));
      }, 2000);
    }
  }

  async signIn(username, password) {
    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(username, password);
      this.setState({ user });

      switch (user.challengeName) {
        case "NEW_PASSWORD_REQUIRED":
          this.setState({ isNewPassword: true });
          break;
        case "MFA_SETUP": {
          const code = await Auth.setupTOTP(user);
          const authCode = `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=AWSCognito`;
          this.setState({ qrCode: authCode });
          break;
        }
        default: {
          if (
            isMFAChallenge(user?.challengeName) ||
            (isOrgRequiresMFA(user?.attributes) && user?.preferredMFA === "NOMFA")
          ) {
            this.setState({ isMFA: true });
          } else {
            this.changeState("signedIn", user);
          }
        }
      }
    } catch (err) {
      handleSignInError(err, {
        error: this.error,
        EventLogger: ErrorLogger,
      });

      Sentry.withScope((scope) => {
        scope.setTag("username", username);
        Sentry.captureMessage(err.message);
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  renderContent() {
    const { isNewPassword, isMFA, user, isLoading } = this.state;

    if (isNewPassword) {
      return <FirstSignIn user={user} changeState={this.changeState} />;
    }

    if (isMFA) {
      return <MFAScreen user={user} changeState={this.changeState} />;
    }

    return <Login onSignIn={this.signIn} onError={this.error} isLoading={isLoading} />;
  }

  showComponent(_theme) {
    return (
      <div style={{ display: "flex", justifyContent: "center", paddingTop: "8em" }}>
        <Card style={{ maxWidth: "min-content" }}>
          <img
            src={rivialLogo}
            alt="Rivial Logo"
            style={{ width: "27rem" }}
            className="shadow appearance-none leading-tight"
          />
          <CardBody>{this.renderContent()}</CardBody>
        </Card>
      </div>
    );
  }
}
