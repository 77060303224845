import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

//REFERENCE: Adapted from - https://material-ui.com/components/lists/

/**
 * Component showing a list of selectable strings with checkboxes to the left
 * @param {string[]} availableField - list of all fields that can be selected
 * @param {function} onChangeCallback - function to call when the items that are checked change
 */
const ShownFieldsSelector = ({ onChangeCallback, auditID, isTemplate = false, ...props }) => {
  const [availableFields, setAvailableFields] = useState([]);

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChangeCallback && onChangeCallback(newChecked);
  };

  //Gets the list of available fields for the control details table
  const getAvailableFields = async () => {
    const newAvailableFields = [];

    // don't do this query for Automation Templates
    if (!isTemplate) {
      const { getQuery } = generateGraphql("Audit", ["controlSet"], {
        controlSet: "{customFields {name}}",
      });
      const auditData = await GetQuery({
        query: getQuery,
        variables: { id: auditID || props?.input?.auditID },
      });

      if (auditData?.controlSet?.customFields && Array.isArray(auditData.controlSet.customFields)) {
        for (const customField of auditData.controlSet.customFields) {
          if (customField?.name) {
            newAvailableFields.push(customField.name);
          }
        }
      }
    }

    newAvailableFields.push("auditor");
    newAvailableFields.push("interviewee");
    newAvailableFields.push("evidenceLinks");
    newAvailableFields.push("notes");
    setAvailableFields(newAvailableFields);
  };
  useEffect(() => {
    getAvailableFields();
  }, [auditID, props?.input?.auditID]);

  const getFriendlyName = (value) => {
    switch (value) {
      case "evidenceLinks":
        return "Evidence Review";
      case "notes":
        return "Notes";
      case "auditor":
        return "Auditor";
      case "interviewee":
        return "Interviewee";
      default:
        return value;
    }
  };

  return (
    <List>
      {availableFields.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <>
            {availableFields && Array.isArray(availableFields) && (
              <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={getFriendlyName(value)} />
              </ListItem>
            )}
          </>
        );
      })}
    </List>
  );
};

export default ShownFieldsSelector;
