export const listPointOfContacts_pointOfContactList =
  /* GraphQL */
  `
    query ContactsByOwnerGroup(
      $ownerGroup: String
      $sortDirection: ModelSortDirection
      $filter: ModelPointOfContactFilterInput
      $limit: Int
      $nextToken: String
    ) {
      contactsByOwnerGroup(
        ownerGroup: $ownerGroup
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          firstName
          lastName
          ownerGroup
          title
          email
          phone
          meetings {
            items {
              id
            }
          }
          user {
            id
            username
            email
            name
            roleLinks {
              items {
                id
              }
            }
          }
        }
        nextToken
      }
    }
  `;

export const getPointOfContact_minimal = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    firstName
    lastName
    title
    email
    phone
    ownerGroup
  }
}
`;

export const getPointOfContact_evidenceList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    evidences(limit: 200) {
      items {
        id
        evidence {
          id
          name
          status
          frequency
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        }
      }
    }
  }
}
`;

export const getPointOfContact_systemList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    systems(limit: 500) {
      items {
        id
        name
        description
        hosting
        riskRating
        residualRisk
        inherentRisk
        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
      }
    }
    systemsAdmin(limit: 500) {
      items {
        id
        name
        description
        hosting
        riskRating
        residualRisk
        inherentRisk
        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
      }
    }
  }
}
`;

export const getPointOfContact_meetingList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    meetings(limit: 500) {
      items {
        meeting {
          id
          name
          description
          meetingDate
          meetingTime
          duration
          organizer
          location
          status
          isAllDay
          startTime
          endTime
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        module
        }
      }
    }
  }
}
`;
