import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

/**
 * Compact button used in the grid for performing crud operations on grid views
 * @param {JSX.Element|string} children - the button content (usually just some text)
 * @param {function} onClick - callback function to be called when the button is clicked
 * @param {string} color - mui color setting for the button (`neutral` + standard colors available)
 * @param {object} style - css styles to apply to the mui button
 * @return {JSX.Element}
 */
const ViewControlButton = ({ children, onClick, color = "neutral", style = {}, title }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        title={title}
        variant={"text"}
        color={color}
        size={"small"}
        onClick={onClick}
        style={{
          textTransform: "none", //prevent text from being capitalized
          padding: 0,
          ...style,
        }}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default ViewControlButton;
