"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultHistoryChartConfig = void 0;
const contextType_1 = require("../contextType");
/**
 * Preset settings to use when rendering or displaying a history widget
 * in different contexts (when there is no other config provided)
 */
exports.defaultHistoryChartConfig = {
    [contextType_1.CONTEXT_TYPE.REPORT]: {
        layout: {
            width: "800px",
            height: "600px",
            margin: {
                top: 50,
                right: 60,
                bottom: 100,
                left: 60,
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.WEB]: {
        layout: {
            width: "100%",
            height: "100%",
            margin: {
                top: 50,
                right: 60,
                bottom: 100,
                left: 60,
            },
        },
    },
};
