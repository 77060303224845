export const rolesByOwnerGroupDataGrid = /* GraphQL */ `
  query RolesByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rolesByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        precedence
        roleConfig
        resources
        ownerGroup
      }
      nextToken
    }
  }
`;

export const listRoles_roleList = /* GraphQL */ `
  query ListRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        roleConfig
        userLinks(limit: 500) {
          items {
            id
            userID
            roleID
            user {
              id
              name
              username
              email
              phoneNumber
              ownerGroup
              config
              accountType
              roleLinks {
                items {
                  id
                  userID
                  roleID
                  ownerGroup
                }
                nextToken
              }
            }
            role {
              id
              name
              userLinks {
                items {
                  id
                  userID
                  roleID
                  ownerGroup
                }
                nextToken
              }
              roleConfig
              precedence
              ownerGroup
            }
            ownerGroup
          }
          nextToken
        }
        ownerGroup
      }
      nextToken
    }
  }
`;

export const getRoleDetails = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      userLinks {
        items {
          id
          userID
          roleID
          user {
            id
            name
            username
            email
            phoneNumber
            ownerGroup
            config
            accountType
            preferences
            createdAt
            updatedAt
          }
          role {
            id
            name
            ownerGroup
          }
          ownerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      roleConfig
      precedence
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
