import React from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useSetAsyncData } from "../../../../../hooks/functional/useSetAsyncData";
import SelectNewItemsDataGrid from "../../../../../utils/Connections/components/SelectNewItemsDataGrid";
import { performToastOperation } from "../../../../../utils/Toasts/functions/toastOperation";
import PointOfContactDataGrid from "../../../../OrganizationManager/PointOfContacts/components/PointOfContactDataGrid";
import PointOfContactDetails from "../../../../OrganizationManager/PointOfContacts/components/PointOfContactDetails";
import { deleteMeetingParticipant } from "../../../../OrganizationManager/PointOfContacts/functions/deleteMeetingParticipant";
import { usePointOfContactDataGrid } from "../../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";

import { addPointsOfContactToMeeting } from "./functions/addPointsOfContactToMeeting";
import { getMeetingParticipatingPointsOfContact } from "./functions/getMeetingParticipatingPointsOfContact";

/**
 * Grid for displaying and manage a single questionnaires assignees
 * @param {string} organizationID - the organization that the questionnaire is a part of
 * @param {string} meetingID - the meeting for which to display participants
 * @param {function} resetFunctionExternal - function to refresh any parent component data on change of assignees
 * @return {{selectedData: [], gridDisplay: *, cardDisplay: *, data: (Object[]|Object), setData: (function(*): void), setSelectedData: (function(((function([]): [])|[])): void), display: *, dashboardDisplay: *, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: (function(*): void), modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: (function(): void)}, setIsLoading: (function(((function(*): *)|*)): void), resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (function(((function([]): [])|[])): void), setCheckboxSelection: (function(((function(boolean): boolean)|boolean)): void)}}
 */
export const useMeetingParticipants = ({ organizationID, meetingID, resetFunctionExternal }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;
  const roleConfig = {
    module,
    resource,
  };

  const { addToast, updateToast } = useUIContext();

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      return await getMeetingParticipatingPointsOfContact({ meetingID });
    },
    setData: (newParticipants) => {
      grid?.setData(newParticipants);
    },
    dependencies: [meetingID],
  });

  const gridConfig = {
    options: ["details", "delete"],
    typename: resources.POINT_OF_CONTACT,
    createResourceComponent: (
      <SelectNewItemsDataGrid
        GridComponent={PointOfContactDataGrid}
        submitButtonProps={{
          title: "Add points of contacts to Meeting",
          text: "Add",
          dataTestId: "add-points-of-contact-to-meeting-submit-button",
        }}
        getFilteredItems={() =>
          getMeetingParticipatingPointsOfContact({
            meetingID,
          })
        }
        filterItems={({ filteredItems, allItems }) => {
          const newItems = [];
          if (!isNonEmptyArray(allItems)) {
            return [];
          }

          if (!isNonEmptyArray(filteredItems)) {
            return allItems;
          }

          for (const item of allItems) {
            const isFound = filteredItems.find((filteredItem) => {
              return filteredItem?.id === item?.id;
            });

            if (isFound) {
              continue;
            }

            newItems.push(item);
          }

          return newItems;
        }}
        submitFunction={async ({ selectedItems }) => {
          await performToastOperation({
            addToast,
            updateToast,
            operation: async () => {
              await addPointsOfContactToMeeting({
                organizationID,
                meetingID,
                pointsOfContact: selectedItems,
              });
            },
            inProgressText: "Adding Points of Contacts to Meeting",
            failedText: "Failed to add Points of Contacts to Meeting",
            successText: "Successfully added Points of Contacts to Meeting",
            iconColor: "success",
          });
        }}
      />
    ),
    detailsComponent: <PointOfContactDetails tableDisplay={true} />,
    route: "#/organization_manager/contacts/",
    deleteFunction: async (itemToDelete) => {
      const linkIDToDelete = itemToDelete?.linkID;
      if (linkIDToDelete) {
        await deleteMeetingParticipant({
          id: linkIDToDelete,
        });
      }
    },
    persistenceUUID: "edaf1226-2826-4aea-a78a-00941d316072",
    createResourceButtonText: "Add",
    createResourceButtonTitle: "Add points of contacts that need to participate in this Meeting",
    createItemModalHeader: "Add Points of Contacts to Meeting",
    createResourceComponentWidth: "80vw",
    deleteButtonText: "Remove",
    deleteButtonTitle: "Remove points of contacts from participating in this Meeting",
    resetFunction: () => {
      resetFunction?.();
      resetFunctionExternal?.();
    },
  };

  const cardConfig = {
    title: "Participants",
    icon: "icon-people",
  };

  const grid = usePointOfContactDataGrid({
    query: null,
    isLoading,

    ...gridConfig,
    cardConfig,
    roleConfig,
  });

  return grid;
};
