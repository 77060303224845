import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

import { gridGetViewMenuItems } from "./gridGetViewMenuItems";

/**
 * @description Create Menu items for the grid
 * @param {boolean} enablePrint - enable print functionality for the grid
 * @param {object} checkPermissionsHook - checks permissions for the grid component
 * @param {boolean} enablePersistence - enable persistence functionality for the grid
 * @param {object} checkPermissionsHelpCenter - checks permissions for the help center
 * @param {string} persistenceUUID - unique grid identifier in UUID format
 * @param {object[]} propsMenuItems - array of object for the menu of the grid
 * @return {object} {[]}
 */
export const gridMenuItems = ({
  enablePrint,
  checkPermissionsHook,
  checkPermissionsHelpCenter,
  enablePersistence,
  persistenceUUID,
  propsMenuItems = [],
  deleteButtonText,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = { checkPermissionsHook, checkPermissionsHelpCenter };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  const menuItemsArray = [];

  /**
   * Add File tab if at least one item in the items array
   */
  const file = {
    id: "menu-item-file",
    text: "File",
    items: [{ id: "refresh_data", text: "Refresh" }, ...(enablePrint ? [{ id: "print", text: "Print" }] : [])],
  };
  file?.items && Array.isArray(file.items) && file?.items.length > 0 && menuItemsArray.push(file);

  /**
   * Add Edit tab if at least one item in the items array
   */
  const edit = {
    id: "menu-item-edit",
    text: "Edit",
    items: (function () {
      const array = [];
      checkPermissionsHook.resource.delete && array.push({ id: "delete", text: deleteButtonText || "Delete" });
      return array;
    })(),
  };
  edit?.items && Array.isArray(edit.items) && edit?.items.length > 0 && menuItemsArray.push(edit);

  /**
   * Add View tab if at least one item in the items array
   */
  const view = {
    id: "menu-item-view",
    text: "View",
    items: gridGetViewMenuItems({ enablePersistence, persistenceUUID }),
  };
  view?.items && Array.isArray(view.items) && view?.items.length > 0 && menuItemsArray.push(view);

  /**
   * Add Export tab if at least one item in the items array
   */
  const exports = {
    id: "menu-item-export",
    text: "Export",
    items: [
      { id: "csv_export", text: "CSV Export" },
      { id: "pdf_export", text: "PDF Export" },
    ],
  };

  menuItemsArray.push(exports);

  /**
   * Add props tabs
   */
  menuItemsArray.push(...propsMenuItems);

  /**
   * Add Help tab if a user has permissions
   */
  checkPermissionsHelpCenter?.module?.isEnabled &&
    menuItemsArray.push({
      id: "menu-item-help",
      text: "Help",
      items: [{ id: "help_center", text: "View Help Center" }],
    });

  return menuItemsArray;
};
