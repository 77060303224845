import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { Toast } from "@application/Toasts/types/LayoutToastsHook";

import { ErrorLogger } from "../../EventLogger";

export interface ToastType {
  id?: string;
  header: string;
  icon: string;
  color?: string;
  timeoutDuration?: number;
}

export interface ToastOperationType {
  addToast: (toast: ToastType | Toast) => string | undefined;
  updateToast: (toast: ToastType | Toast) => void;
  typename: string;
  operation: () => Promise<unknown>;
}

/**
 * Called to show progress toasts when editing an item
 */
export const editToastOperation = async ({ addToast, updateToast, typename, operation }: ToastOperationType) => {
  typename = convertCamelCaseToSentence(typename);

  const inProgressText = `Editing a(n) ${typename}...`;
  const failedText = `Failed to edit a(n) ${typename}`;
  const successText = `Successfully edited a(n) ${typename}`;
  const iconColor = `warning`;

  await performToastOperation({
    addToast,
    updateToast,
    operation,
    inProgressText,
    failedText,
    successText,
    iconColor,
  });
};

/**
 * @description Called to show progress toasts when deleting an item
 */
export const deleteToastOperation = async ({ addToast, updateToast, typename, operation }: ToastOperationType) => {
  typename = convertCamelCaseToSentence(typename);

  const inProgressText = `Deleting a(n) ${typename}...`;
  const failedText = `Failed to delete a(n) ${typename}`;
  const successText = `Successfully deleted a(n) ${typename}`;
  const iconColor = `danger`;

  await performToastOperation({
    addToast,
    updateToast,
    operation,
    inProgressText,
    failedText,
    successText,
    iconColor,
  });
};

/**
 * Called to show progress toasts when creating an item
 */
export const createToastOperation = async ({ addToast, updateToast, typename, operation }: ToastOperationType) => {
  typename = convertCamelCaseToSentence(typename);

  const inProgressText = `Creating a(n) ${typename}...`;
  const failedText = `Failed to create a(n) ${typename}`;
  const successText = `Successfully created a(n) ${typename}`;
  const iconColor = `success`;

  return await performToastOperation({
    addToast,
    updateToast,
    operation,
    inProgressText,
    failedText,
    successText,
    iconColor,
  });
};

export interface PerformToastOperationType<T = unknown> {
  addToast: (toast: ToastType) => string | undefined;
  updateToast: (toast: ToastType) => void;
  operation: () => Promise<T>;
  inProgressText: string;
  failedText: string;
  successText: string;
  iconColor?: string;
  /** a callback which is passed the result of the operation when successful */
  onSuccess?: (operationResult: T) => void;
  /** a callback which is passed the error from the operation when in failure */
  onError?: (error: unknown) => void;
  timeoutDuration?: number;
}

/**
 * Called to show progress toasts when executing an action
 */
export const performToastOperation = async <T = unknown>({
  addToast,
  updateToast,
  operation,
  inProgressText,
  failedText,
  successText,
  iconColor,
  timeoutDuration,
  onSuccess,
  onError,
}: PerformToastOperationType<T>) => {
  const toastId = addToast({
    header: inProgressText,
    icon: "spinner",
    color: iconColor,
    timeoutDuration,
  });

  try {
    // No null check because will want to show failed toast if no action function is passed in
    const result = await operation();

    updateToast({
      id: toastId,
      header: successText,
      icon: iconColor ?? "success",
    });
    onSuccess?.(result);
    return result;
  } catch (e) {
    ErrorLogger("Unable to update toast.", e);
    updateToast({
      id: toastId,
      header: failedText,
      icon: "danger",
    });
    onError?.(e);
  }

  return undefined;
};
