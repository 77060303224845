"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWidget = void 0;
const react_1 = require("react");
const contextType_1 = require("../constants/contextType");
const widgetType_1 = require("../constants/widgetType");
const getWebWidget_1 = require("../functions/getWidget/getWebWidget");
const getWidgetConfig_1 = require("../functions/utils/getWidgetConfig/getWidgetConfig");
const getWidgetData_1 = require("../functions/utils/getWidgetData/getWidgetData");
const getWidgetType_1 = require("../functions/utils/getWidgetType");
/**
 * Hook allowing to efficiently retrieve the widget component and widget data
 * @param {object} widget - the widget object from the database
 * @param {function} runCustomQuery - function to run custom queries given a customQueryId
 * @return {{isLoading: boolean, widget: JSX.Element | null}}
 */
const useWidget = ({ widget, runCustomQuery }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [contextType, setContextType] = (0, react_1.useState)(contextType_1.CONTEXT_TYPE.WEB);
    const [widgetType, setWidgetType] = (0, react_1.useState)(widgetType_1.WIDGET_TYPE.UNSUPPORTED);
    const [data, setData] = (0, react_1.useState)({
        metricTypes: {},
        customQueries: {},
    });
    const [config, setConfig] = (0, react_1.useState)(null);
    const [result, setResult] = (0, react_1.useState)(null);
    //Update the widgets state
    const updateWidget = async () => {
        setIsLoading(true);
        try {
            // Get new widget TYPE
            const newWidgetType = (0, getWidgetType_1.getWidgetType)({ widget });
            setWidgetType(newWidgetType);
            // Get any new DATA
            const newData = await (0, getWidgetData_1.getWidgetData)({ widget, existingData: data, runCustomQuery });
            // - check if any new metric data has been retrieved (new keys)
            const originalMetricKeyAmount = Object.keys(data?.metricTypes || {}).length;
            const newMetricKeyAmount = Object.keys(newData?.metricTypes || {}).length;
            // - check if any new custom query data has been retrieved (new keys)
            const originalCustomQueryKeyAmount = Object.keys(data?.customQueries || {}).length;
            const newCustomQueryKeyAmount = Object.keys(newData?.customQueries || {}).length;
            // - check if new widget data has been retrieved (new top level keys)
            const originalAllKeysAmount = Object.keys(data || {}).length;
            const newAllKeysAmount = Object.keys(newData || {}).length;
            if (originalMetricKeyAmount !== newMetricKeyAmount ||
                originalCustomQueryKeyAmount !== newCustomQueryKeyAmount ||
                originalAllKeysAmount !== newAllKeysAmount) {
                setData(newData);
            }
            const newConfig = await (0, getWidgetConfig_1.getWidgetConfig)({ widget, data: newData, contextType });
            setConfig(newConfig);
            // Get new widget CONTEXT TYPE
            setContextType(contextType ?? contextType_1.CONTEXT_TYPE.WEB);
        }
        catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };
    (0, react_1.useEffect)(() => {
        updateWidget();
    }, [widget]);
    // Retrieves the widget results
    const getWidget = async () => {
        const newWidget = (0, getWebWidget_1.getWebWidget)({ type: widgetType, config });
        setResult(newWidget);
    };
    (0, react_1.useEffect)(() => {
        getWidget();
    }, [widgetType, contextType, data, config]);
    return {
        widget: result,
        isLoading,
    };
};
exports.useWidget = useWidget;
