import React from "react";

/**
 * Context Provider used to pass smart value data to child components
 *
 * @type {React.Context<{smartValues: string[], setSmartValues: function}>}
 */
const SmartValueContext = React.createContext({
  smartValues: [],
  setSmartValues: () => {},
});

export default SmartValueContext;
