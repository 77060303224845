export const listVendorSolutionsByOwnerGroup = /* GraphQL */ `
  query VendorSolutionsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorSolutionsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        description
        contractDetails
        contractStatus
        contractExpirationDate
        contractExpiringSoonOffset
        vendor {
          id
          name
        }
        vendorID
      }
      nextToken
    }
  }
`;
