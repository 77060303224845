import { API, graphqlOperation } from "@aws-amplify/api";

import { ErrorLogger, InfoLogger } from "../../EventLogger";

/**
 * @deprecated - Please Use ListQuery (src/utils/Functions/ListQuery.js) to utilize our NPM package
 * @param organizationID
 * @param query
 * @param otherFilters
 * @param limit
 * @returns {Promise<Array>}
 * @constructor
 */
export const QueryWithNextToken = async ({ organizationID, query, otherFilters, limit }) => {
  InfoLogger("QueryWithNextToken Function is running...");

  let nextToken = "";
  let allData = [];
  const getAllData = async () => {
    const data = await API.graphql(
      graphqlOperation(query, {
        filter: {
          ownerGroup: {
            eq: organizationID,
          },
          ...otherFilters,
        },
        limit: limit ? limit : 250,
        nextToken: nextToken ? nextToken : undefined,
      }),
    );
    const typeName = Object.keys(data.data)[0];
    const listArray = data && data.data && data.data[typeName] && data.data[typeName].items;
    allData = allData.concat(listArray);
    if (data && data.data && data.data[typeName] && data.data[typeName].nextToken) {
      nextToken = data && data.data && data.data[typeName] && data.data[typeName].nextToken;
      return await getAllData();
    } else {
      nextToken = "";
      return allData;
    }
  };
  try {
    await getAllData();
    return allData;
  } catch (error) {
    ErrorLogger(error);
  }
  InfoLogger("QueryWithNextToken Function was finished...");
};
