"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateObjectInArray = void 0;
const findObjectInArray_1 = require("./findObjectInArray");
/**
 * Updates a single object in an array of objects. Returns the updated array.
 * @param {object[]} array - the array of objects to update
 * @param {object} object - the object to update
 * @param {string} field - the field name to find the object by
 */
const updateObjectInArray = (array, object, field = "id") => {
    /**
     * Validate input
     */
    if (Array.isArray(array) && object && object[field]) {
        /**
         * Find the object in the array to update
         */
        const foundObject = (0, findObjectInArray_1.findObjectInArray)(array, { [field]: object[field] }, field);
        /**
         * Update the object in the array
         */
        if (foundObject) {
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    foundObject[key] = object[key];
                }
            }
        }
    }
    return [...array];
};
exports.updateObjectInArray = updateObjectInArray;
