"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateRiskMitigated = void 0;
/**
 * Calculate the risk mitigated value by subtracting the residual risk from the inherent risk
 */
const calculateRiskMitigated = ({ inherentRisk, residualRisk }) => {
    if (isNaN(inherentRisk) || isNaN(residualRisk)) {
        return 0;
    }
    // for whatever reason this happens, we don't want to return a negative number
    if (residualRisk > inherentRisk) {
        return 0;
    }
    return inherentRisk - residualRisk;
};
exports.calculateRiskMitigated = calculateRiskMitigated;
