import { ReactElement } from "react";
import { Button } from "reactstrap";

/**
 * The close button for the Help Center
 */
const CloseButton = ({ handleCloseClick }: { handleCloseClick: VoidFunction }): ReactElement => {
  return (
    <Button
      size="sm"
      style={{ margin: "0.4em" }}
      color={"ghost-danger"}
      className={"float-right"}
      onClick={handleCloseClick}
    >
      <i className={"icon-close"} />
    </Button>
  );
};

export default CloseButton;
