import React, { useEffect, useState } from "react";

import { useModal } from "../../../../hooks/views/useModal";
import EditButton from "../../buttons/EditButton";
import CreateOrSelect from "../../CreateOrSelect";

/**
 * Preconfigured to use the CreateOrSelect component with the GenericEditField custom input
 *
 * @example
 <GenericEditFieldV3
 customInputComponent={
          <CreateOrSelectInput
            typename={"Source"}
            form={<CreateSource/>}
            grid={<SourceDataGrid/>}
          />
      }
 />
 * @param {function} setValue - passed from GenericEditFieldContext
 * @param {string} typename - used for display purposes
 * @param {string} [displayField = "name"] - the field on the selected object to use to display
 * @param {function} displayFieldFunction - a function that takes the selected object and returns a string to display
 * @param {JSX.Element} form - component instance of the useForm hook
 * @param {JSX.Element} grid - component instance of the useGrid hook
 * @param {object} displayComponent - component to use to display the selected object
 * @param {object} [prepareSelectionForm] - a form to display before a new item can created or selected
 * @returns {JSX.Element}
 * @constructor
 */
const CreateOrSelectInput = ({
  setValue,
  typename,
  displayField = "name",
  displayFieldFunction,
  form,
  grid,
  displayComponent,
  prepareSelectionForm,
}) => {
  const [displayValue, setDisplayValue] = useState(null);

  const callback = (item) => {
    if (typeof setDisplayValue === "function") {
      setDisplayValue(item?.[displayField]);
    }

    if (typeof displayFieldFunction === "function") {
      setDisplayValue(displayFieldFunction(item));
    }

    setValue(item?.id);
    modal.setModalIsOpen(false);
  };

  const modal = useModal(
    `Create or Select ${typename}`,
    <CreateOrSelect
      form={form}
      grid={grid}
      prepareSelectionForm={prepareSelectionForm}
      callback={callback}
      typename={typename}
    />,
    <EditButton stopPropagation={true} />,
    {
      width: "80vw",
      onClosed: () => {
        //Reset the inputs into the selection preparation form if it exists on exit from create or select modal
        prepareSelectionForm?.getInitialState?.();
      },
    },
  );

  useEffect(() => {
    modal.setModalIsOpen(true);
    return () => modal.setModalIsOpen(false);
  }, []);

  return (
    <span>
      {displayValue ? <span>Selected: {displayValue}</span> : displayComponent}
      {modal.modal}
    </span>
  );
};

export default CreateOrSelectInput;
