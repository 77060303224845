import { gql } from "@graphql/types";

export const USER_CREATABLE_FRAMEWORK_TEMPLATES = gql(`
  query userCreateableFrameworkTemplates {
    userCreateableFrameworkTemplates {
      id
      name
    }
  }
`);
