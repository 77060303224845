import React, { useState } from "react";
import { Collapse, ListGroupItem, ListGroupItemText, Table } from "reactstrap";

import { formattedDollar } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import AccordionIcon from "../../../../../../../utils/GenericComponents/AccordionIcon";

import NumberOfRecords from "./NumberOfRecords";

/**
 * A single Information Asset item for a System
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InformationAssetItem = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const { item } = props;

  const [isOpen, setIsOpen] = useState(!!props.isOpen);

  return (
    <ListGroupItem>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
        }}
      >
        <h8>{item.name}</h8>
        <AccordionIcon isOpen={isOpen} />
      </div>
      <Collapse isOpen={isOpen}>
        <ListGroupItemText style={{ marginTop: "1em" }}>
          <Table>
            <tr>
              <th scope="row">Confidentiality</th>
              <th scope="row">Integrity</th>
              <th scope="row">Number of Records</th>
            </tr>
            <tr>
              <td>{formattedDollar(item.confidentialityPerRecord || 0)} per record</td>
              <td>{formattedDollar(item.integrityPerRecord || 0)} per record</td>
              <td>
                <NumberOfRecords
                  module={module}
                  resource={resource}
                  informationAssetLink={item.informationAssetLink}
                  resetFunction={props.resetFunction}
                  disableRoleChecking={disableRoleChecking}
                />
              </td>
            </tr>
          </Table>
        </ListGroupItemText>
      </Collapse>
    </ListGroupItem>
  );
};

InformationAssetItem.defaultProps = {
  item: {},
};

export default InformationAssetItem;
