import Button from "@mui/material/Button";
import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import DocumentEditorModalButton from "../../DocumentEditor/components/DocumentEditorModalButton";

/**
 * Displays a DocumentEditorModal button to generate a findings report
 * @param {string} [sourceID] - the source id for which to create an associated finding report
 * @param {string} [auditID] - the audit id for which to create an associated finding report
 * @param {string} [complianceControlID] - the complianceControl id for which to create an associated finding report
 * @param {string} [riskControlID] - the riskControl id for which to create an associated finding report
 * @param {string} [exerciseID] - the exercise id for which to create an associated finding report
 * @param {string} [evidenceID] - the evidence id for which to create an associated finding report
 * @param {string} [incidentID] - the incident id for which to create an associated finding report
 * @param {string} [vulnerabilityID] - the vulnerability id for which to create an associated finding report
 * @param {boolean} isCompact - if true, only the icon will be displayed
 * @param {string} departmentID - the department id for which to create an associated finding report
 * @param {string} assessmentID - the assessment id for which to create an associated finding report
 * @returns {JSX.Element}
 */
const FindingsReportButton = ({
  sourceID,
  auditID,
  complianceControlID,
  riskControlID,
  exerciseID,
  evidenceID,
  incidentID,
  vulnerabilityID,
  isCompact = false,
  departmentID,
  assessmentID,
}) => {
  return (
    <DocumentEditorModalButton
      button={
        <Button size={"small"}>
          <span
            style={{
              fontSize: isCompact ? "1.5em" : "1.3em",
              marginRight: isCompact ? "0em" : ".5em",
              padding: 0,
            }}
          >
            {getIcon("vscode-icons:file-type-word2")}
          </span>
          {isCompact ? "" : "Findings Report"}
        </Button>
      }
      auditID={auditID}
      sourceID={sourceID}
      complianceControlID={complianceControlID}
      riskControlID={riskControlID}
      exerciseID={exerciseID}
      evidenceID={evidenceID}
      incidentID={incidentID}
      vulnerabilityID={vulnerabilityID}
      departmentID={departmentID}
      assessmentID={assessmentID}
      defaultTemplate={"FINDINGS_TEMPLATE"}
      type={"Findings Report"}
      module={"governance"}
    />
  );
};

export default withOrganizationCheck(FindingsReportButton);
