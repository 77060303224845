"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateLists = void 0;
const generateLists = () => {
    return [
        {
            abstractListId: 0,
            levelOverrides: [],
            listId: 0,
        },
        {
            abstractListId: 1,
            levelOverrides: [],
            listId: 1,
        },
        {
            abstractListId: 2,
            levelOverrides: [],
            listId: 2,
        },
        {
            abstractListId: 3,
            levelOverrides: [],
            listId: 3,
        },
    ];
};
exports.generateLists = generateLists;
