import { isEqual } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { tryParse } from "@rivial-security/func-utils";

import { useSetAsyncData } from "../../../../../../../hooks/functional/useSetAsyncData";
import { useForm } from "../../../../../../../hooks/views/useForm";
import WidgetPreview from "../../../../../WidgetRenderer/components/ChartWidgetPreview";
import { createWidgetActions } from "../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../useCreateWidget";

import { getInitialSettingsFormInput } from "./functions/getInitialSettingsFormInput";

/**
 * The step in which user is allowed to change how a bar chart widget looks,
 * but not its underlying data. State is passed up through reducer functions.
 * @returns {JSX.Element}
 */
const CustomizeBarChartWidgetVisuals = () => {
  const { widget, dispatch } = useContext(CreateWidgetContext);
  const [axes, setAxes] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  //Initial parsing of the config and setting up of variables
  useSetAsyncData({
    getData: async () => {
      const currentConfig = tryParse(widget?.config);
      if (currentConfig?.axes) {
        return currentConfig?.axes;
      } else {
        return {
          x: {
            label: "Category",
          },
          y: {
            label: "Totals",
          },
        };
      }
    },
    setData: (newAxes) => {
      setAxes(newAxes);
    },
    dependencies: [widget?.config],
  });

  const axesForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fields: ["xAxisName", "yAxisName"],

    //FIELDS
    fieldConfig: {
      //General
      xAxisName: {
        inputType: "text",
        label: "X Axis Name",
      },

      yAxisName: {
        inputType: "text",
        label: "Y Axis Name",
      },
    },
  });

  //Update the widget axes when form changes
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    dispatch({
      type: createWidgetActions.MERGE_WIDGET_CONFIG_INPUT,
      mergeWidgetConfigInput: {
        axes: {
          x: {
            label: axesForm?.input?.xAxisName,
          },
          y: {
            label: axesForm?.input?.yAxisName,
          },
        },
      },
    });
  }, [axesForm?.input]);

  useEffect(() => {
    if (!isInitialized && axes) {
      const axesFormInput = getInitialSettingsFormInput({ axes });

      axesForm.setInput({
        ...axesFormInput,
      });
    }
  }, [isInitialized, axes]);

  //Check if ui state has been initialized
  useEffect(() => {
    //Check if axes were retrieved yet
    if (!axes) {
      return;
    }

    //Axes form not yet set to the currently edited value
    if (!isEqual(getInitialSettingsFormInput({ axes }), axesForm?.input)) {
      return;
    }

    //Dispatches table settings in case the defaults override empty settings
    setIsInitialized(true);
  }, [axes, axesForm?.input]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
      <div>
        <p style={{ fontWeight: "bold" }}>Axes Settings:</p>
        {axesForm.display}
      </div>
      <div
        style={{
          flex: 1,
          height: "70vh",
        }}
      >
        <WidgetPreview widget={widget} />
      </div>
    </div>
  );
};

export default CustomizeBarChartWidgetVisuals;
