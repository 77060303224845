import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemQuery } from "../../../../../../../utils/Functions/Graphql/ItemQuery";

/**
 * Generates a mapping of Controls to their mapped Controls, using Evidence as the intermediary.
 * De-dupess the results, so that each Control is only listed once, and removes self-mappings.
 *@param {object} param - The function parameters
 * @param {string[]} param.selectedIDs - IDs of selected Controls to generate a mapping for
 * @returns {Promise<object[]>}
 */
export const controlControlMapping = async ({ selectedIDs = [] }) => {
  const { getQuery } = generateGraphql("Control", ["id", "statementNumber", "name", "evidences"], {
    evidences: `(limit: 500)
        {
          items {
            evidence {
              id
              name
              itemNumber
              controls (limit: 500) {
                items {
                  control {
                    id
                    statementNumber
                    name
                    controlSet {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
  });

  const data = [];

  const promises = [];

  for (const controlID of selectedIDs) {
    const fullControlPromise = ItemQuery(getQuery, controlID);

    promises.push(fullControlPromise);
  }

  const promiseResults = await Promise.allSettled(promises);

  for (const result of promiseResults) {
    if (result.status === "fulfilled") {
      const control = result.value;
      const evidences = control?.evidences?.items;
      if (!isNonEmptyArray(evidences)) {
        continue;
      }
      for (const evidenceLink of evidences) {
        for (const controlLink of evidenceLink.evidence.controls.items) {
          if (!controlLink?.control) {
            continue;
          }
          const {
            control: {
              id: mappedControlID,
              statementNumber: mappedControlStatementNumber,
              name: mappedControlName,
              controlSet: mappedControlSet,
            },
          } = controlLink;
          data.push({
            controlID: control.id,
            controlName: control.name,
            controlStatementNumber: control.statementNumber,
            mappedControlID,
            mappedControlName,
            mappedControlStatementNumber,
            mappedControlFrameworkName: mappedControlSet?.name ?? "",
          });
        }
      }
    }
  }

  // de-duplicate entries and also remove self-mappings

  const dedupedData = [];
  const dedupeMap = {};
  for (const entry of data) {
    const { controlID, mappedControlID } = entry;
    if (controlID === mappedControlID) {
      continue;
    }
    const key = `${controlID}-${mappedControlID}`;
    if (!dedupeMap[key]) {
      dedupeMap[key] = true;
      dedupedData.push(entry);
    }
  }

  return dedupedData;
};
