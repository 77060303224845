import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useControlGrid } from "../hooks/useControlGrid/useControlGrid";

/**
 * A component instance of the useControlGrid.js hook
 * @param props
 */

const ControlGrid = (props) => {
  return useControlGrid(props).display;
};

export default withOrganizationCheck(ControlGrid);
