import { Stack } from "@mui/material";
import React from "react";

import InterfaceAutoHideEditButtons from "../InterfaceOptions/components/InterfaceAutoHideEditButtons";
import InterfaceGridPersistence from "../InterfaceOptions/components/InterfaceGridPersistence";
import InterfaceHighlightedRows from "../InterfaceOptions/components/InterfaceHighlightedRows";
import InterfaceInfoVulnerabilities from "../InterfaceOptions/components/InterfaceInfoVulnerabilities";
import InterfaceLoaderStyle from "../InterfaceOptions/components/InterfaceLoaderStyle";
import InterfaceSafeDelete from "../InterfaceOptions/components/InterfaceSafeDelete";

const DataTableOptions = (props) => {
  return useStack({
    items: [
      <InterfaceAutoHideEditButtons />,
      <InterfaceGridPersistence />,
      <InterfaceHighlightedRows />,
      <InterfaceInfoVulnerabilities />,
      <InterfaceSafeDelete />,
      <InterfaceLoaderStyle />,
    ],
  });
};

export const useStack = ({ items = [] }) => {
  return (
    <Stack spacing={2} className="animated fadeIn">
      {Array.isArray(items) && items.map((item, index) => item)}
    </Stack>
  );
};

export default DataTableOptions;
