import React from "react";

import { resources } from "@rivial-security/role-utils";

import { useNestedDataCard } from "../../../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import CreateTarget from "../../../../../../Testing/Targets/components/CreateTarget";
import TargetsGrid from "../../../../../../Testing/Targets/components/TargetsGrid";
import { useTargetsGrid } from "../../../../../../Testing/Targets/hooks/useTargetsGrid";
import { createSystemTargetLink } from "../function/createSystemTargetLink";
import { deleteSystemTargetLink } from "../function/deleteSystemTargetLink";

/**
 * Displays a list of security testing targets assigned to the system and allows user to attach more
 * @param {object} itemRef - the system details data item reference
 * @param {string} organizationID - the organization ID
 */
const SystemTargets = ({ itemRef, organizationID }) => {
  const item = itemRef?.current;
  const fields = ["targets"];
  const nestedFields = {
    targets: `(limit: 500) {
      items {
        id
        target {
          id
          hostName
          ip
          macAddress
          lastTestedDate
        }
      }
    }`,
  };

  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "targets",
    gridProps: {
      createResourceButtonText: "Link",
    },
    connectionField: "target",
    typename: resources.TARGET,
    fields,
    nestedFields,
    parentTypename: resources.INFORMATION_SYSTEM,
    grid: <TargetsGrid />,
    gridHook: useTargetsGrid,
    gridHookGridConfig: { enableColumnChooser: false, enableMenu: false },
    form: <CreateTarget />,
    createFunction: createSystemTargetLink,
    deleteFunction: deleteSystemTargetLink,
    flipCreateParams: true, //since create function parameters are (system, target)
  });

  return <div style={{ height: "30em" }}>{nestedDataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(SystemTargets);
