"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToKebabCase = void 0;
/**
 * Converts string or numeric input to kebab case
 * @param {string|number} text - the text to convert
 * @return {string} - the converted text
 */
const convertToKebabCase = ({ text }) => {
    if (text === null || text === undefined || text === "") {
        return null;
    }
    if (typeof text !== "string" && typeof text !== "number") {
        return null;
    }
    const firstPass = String(text)
        .replace(/([A-Z])([A-Z])/g, "$1-$2") // capital letters followed by capital letters
        .replace(/([a-z])([A-Z])/g, "$1-$2") // camelCase to kebab-case
        .replace(/\s+/g, "-") // empty space
        .replace(/([a-zA-Z])([0-9])/g, "$1-$2") //separate numbers from letters
        .replace(/([0-9])([a-zA-Z])/g, "$1-$2"); //separate numbers from letters
    return String(firstPass)
        .replace(/([A-Z])([A-Z])/g, "$1-$2") // capital letters followed by capital letters
        .toLowerCase();
};
exports.convertToKebabCase = convertToKebabCase;
