/**
 * Enum for different risk module change operations
 * NOTE: the values here have to mirror the ones from createRiskChangeLambda
 */
export const RISK_CHANGE_TYPE = {
  ENTERPRISE_CONTROL: "enterpriseControl",
  NON_STANDARD_CONTROL: "nonStandardControl",
  KEY_RISK_INDICATOR: "keyRiskIndicator",
  MANUAL_ENTRY: "manualEntry",
};
