import { useCustomColor } from "../hooks/useCustomColor";

/**
 * A component instance of the useCustomColor.js hook
 * @param {string} field - the name of the field being displayed (should be present as a property in item)
 * @param {string} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed
 * @param {object[]} inputConfig - holds settings for the color component
 */
const CustomColor = ({ field, value, setValue, inputConfig }) => {
  return useCustomColor({ field, value, setValue, inputConfig }).display;
};

export default CustomColor;
