export const getCustomToolNavigationBarName = (toolName?: string): string => {
  if (!toolName) {
    return "Unknown Integration";
  }

  if (toolName.length > 15) {
    return `${toolName.substring(0, 15).trim()}...`;
  }
  return toolName;
};
