import React, { useEffect, useState } from "react";

import { useTable } from "../../../../hooks/views/useTable";

import OverallAROChange from "./OverallAROChange";

const KeyRiskIndicatorsAffectedList = ({ item }) => {
  const [keyRiskIndicators, setKeyRiskIndicators] = useState([]);

  useEffect(() => {
    const temp = [];

    const change = item && JSON.parse(item.change);

    if (change && change.keyRiskIndicator) {
      temp.push({
        id: change.keyRiskIndicator.id,
        name: change.keyRiskIndicator.name,
        annualRateOfOccurrenceChange: change.annualRateOfOccurrenceChange || 0,
      });
    }
    setKeyRiskIndicators([...temp]);
  }, [item]);

  const customFields = [
    {
      field: "annualRateOfOccurrenceChange",
      component: <OverallAROChange />,
    },
  ];

  const dataCardHook = useTable({
    data: keyRiskIndicators,
    fields: ["name", "annualRateOfOccurrenceChange"],
    customFields,
    fieldNameDictionary: {
      annualRateOfOccurrenceChange: "Change in Annual Rate of Occurrence",
    },
    disableRoleChecking: true,
    config: {
      borderless: true,
    },
  });

  useEffect(() => {
    dataCardHook.setData([...keyRiskIndicators]);
  }, [keyRiskIndicators]);

  return (
    <td colSpan={2}>
      <h5>Key Risk Indicators affected by Change</h5>
      {dataCardHook.display}
    </td>
  );
};

export default KeyRiskIndicatorsAffectedList;
