import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Popover, PopoverBody, PopoverHeader } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useGUID } from "../../../../hooks/functional/useGUID";
import { useRefState } from "../../../../hooks/functional/useRefState";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { PriorityBadge } from "../../PriorityActions/CustomFields/PriorityBadge/PriorityBadge";

/**
 * Displays the current priority value for a resource with priority field.
 * Allows the user to adjust this value if edits are enabled (currently edits only work for action items)
 * @param {object} item - the resource with a priority field defined as a 0 - 600 value
 * @param {function} updateItemById - callback to pass back data when it has been changed
 * @param {boolean} disableEdits - if TRUE will not allow to edit the priority
 * @returns {JSX.Element}
 */
const Priority = ({ item, updateItemById, disableEdits }) => {
  const [tooltipIsOpen, setTooltipIsOpen, tooltipIsOpenRef] = useRefState(false);

  const [initialValue, setInitialValue] = useState(item.priority);
  const [currentValue, setCurrentValue] = useState(item.priority);

  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;
  const field = "priority";

  const checkPermissionsHook = useCheckPermissions({ module, resource, field });

  const mutationHook = useMutation({
    mutation: generateGraphql("GenericActionItem", ["priority"]).updateMutation,
    disableRoleChecking: true,
    typename: "Action Item",
  });

  const setPriority = (priority) => {
    if (priority !== item.priority) {
      mutationHook
        .editItem({
          id: item.id,
          priority,
        })
        .then((item) => {
          updateItemById && updateItemById(item);
        });
    }
  };

  //On scroll wheel toggle the popover to close
  useEffect(() => {
    const handleScroll = () => {
      if (tooltipIsOpenRef?.current) {
        setTooltipIsOpen(false);
      }
    };
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, []);

  const cancel = () => {
    setCurrentValue(initialValue);
    setTooltipIsOpen(false);
  };

  const submit = () => {
    setPriority(currentValue);
    setInitialValue(currentValue);
    setTooltipIsOpen(false);
  };

  const [componentId] = useGUID();
  const linkRef = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, [linkRef.current]);

  const canUpdate = checkPermissionsHook.resource.update && !disableEdits;
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        id={`tooltip-priority-${item.id}`}
        style={{
          cursor: canUpdate ? "pointer" : undefined,
        }}
        onClick={() => canUpdate && setTooltipIsOpen((tooltipIsOpen) => !tooltipIsOpen)}
        ref={linkRef}
      >
        <PriorityBadge priority={item.priority} />
      </div>
      {item?.id && ready && (
        <Popover
          trigger="legacy"
          placement="left"
          isOpen={tooltipIsOpen}
          toggle={() => canUpdate && tooltipIsOpen && setTooltipIsOpen((popoverIsOpen) => !popoverIsOpen)}
          // target={"tooltip-priority-" + item.id}
          target={linkRef.current}
        >
          <PopoverHeader>
            Adjust Action Item Priority
            <Button close onClick={() => setTooltipIsOpen(false)} />
          </PopoverHeader>
          <PopoverBody>
            <div className="e-card" style={{ padding: "0.5em" }}>
              <PriorityBadge priority={currentValue} showValue={true} />
              <span style={{ textAlign: "center" }}>({currentValue})</span>
              <SliderComponent
                id={`priority-slider${item.id}${componentId}`}
                min={0}
                max={600}
                value={item.priority}
                changed={(e) => setCurrentValue(e.value)}
                readonly={!canUpdate}
              />
              {initialValue !== currentValue && (
                <ButtonGroup size="sm">
                  <Button color="success" onClick={submit}>
                    <i className="icon-check" />
                  </Button>
                  <Button color="danger" onClick={cancel}>
                    <i className="icon-close" />
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default Priority;
