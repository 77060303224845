"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_TYPE = void 0;
/**
 * All widget types that can be displayed in the UI or rendered into a report
 */
exports.WIDGET_TYPE = {
    STATIC_TEXT: "staticText",
    DYNAMIC_TEXT: "dynamicText",
    TABLE: "table",
    IMAGE: "image",
    HISTORY_CHART: "history",
    PIE_CHART: "pie",
    BAR_CHART: "bar",
    LINE_CHART: "line",
    RADAR_CHART: "radar",
    GAUGE: "gauge",
    PROGRESS_BAR: "progressBar",
    UNSUPPORTED: "unsupported",
    TAG: "widgetTag",
    //Non-Configurable Widgets
    RISK_TOLERANCE_CHART: "riskTolerance",
};
