import { Button } from "@mui/material";
import React, { useState } from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { withOrganizationCheck } from "../../Context/withOrganizationCheck";
import { performToastOperation } from "../../Toasts/functions/toastOperation";
import { requestSinglePermission } from "../functions/requestPermissions";

/**
 * Button that allows a User to Request Permissions for a module/resource/function
 * @param {string} loggedInUserId - the ID of the currently logged in user object
 * @param {string} module - the module that is being permission requested
 * @param {string} resource - the resource that is being permission requested
 * @param {string} operation - the resource operation name that is being permission requested
 * @param {string} func - the function name that is being permission requested
 * @param {object} style - optional style overrides
 * @constructor
 */
const RequestPermissionButton = ({ loggedInUserId, module, resource, operation, func, style }) => {
  const { addToast, updateToast } = useUIContext();

  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () =>
        await requestSinglePermission({
          userID: loggedInUserId,
          module,
          resource,
          operation,
          func,
        }),
      inProgressText: "Requesting new permissions...",
      failedText: "Failed to request new permissions! Please, contact your manager directly.",
      successText: "Successfully requested new permissions from your manager.",
      iconColor: "success",
    });
    setRequestSent(true);
    setLoading(false);
  };

  const getTitle = (module, resource) => {
    if (module && !resource) {
      return `Send a Notification to the Managers asking for permission to access the '${convertCamelCaseToSentence(
        module,
      )}' module.`;
    } else if (module && resource) {
      return `Send a Notification to the Managers asking for permission to access the '${convertCamelCaseToSentence(
        resource,
      )}' resource in the '${convertCamelCaseToSentence(module)}' module.`;
    } else return "Request Permissions";
  };

  const requestMessage = requestSent ? "Permission Request Sent" : "Request Permission";

  return (
    <Button
      style={style}
      size="sm"
      disabled={requestSent || loading}
      onClick={onClick}
      title={getTitle(module, resource)}
    >
      {loading ? "Sending Request..." : requestMessage}
    </Button>
  );
};

export default withOrganizationCheck(RequestPermissionButton);
