/**
 * Function that merges field overrides with the default field settings, ads any non-existing fields as new items
 * @param {object[]} originalFields - the original fields for a grid
 * @param {object[]} fieldOverrides - the new settings to use for each field or just additional fields
 * @return {object[]} - the new fields with overrides applied
 */
export const processFieldOverrides = ({ originalFields, fieldOverrides = [] } = {}) => {
  //Check values to be both truthy and arrays
  if (!originalFields || !fieldOverrides) {
    return originalFields;
  }

  //Check if fieldOverrides is an array of objects
  if (!Array.isArray(fieldOverrides) || !fieldOverrides.every((item) => typeof item === "object")) {
    return originalFields;
  }

  //Check if fieldOverrides is an array of objects
  if (!Array.isArray(fieldOverrides) || !fieldOverrides.every((fieldOverride) => typeof fieldOverride === "object")) {
    return originalFields;
  }

  //Perform the overrides
  // - check if any existing fields need updating
  fieldOverrides.forEach((overrideField) => {
    const foundIndex = originalFields.findIndex((field) => field?.name === overrideField?.name);
    if (foundIndex !== -1) {
      originalFields[foundIndex] = {
        ...originalFields[foundIndex],
        ...overrideField,
      };
    }
  });

  // check if any fields need to be added
  fieldOverrides.forEach((overrideField) => {
    const foundIndex = originalFields.findIndex((field) => field?.name === overrideField?.name);
    if (foundIndex === -1) {
      originalFields.push(overrideField);
    }
  });

  //Return the result
  return originalFields;
};
