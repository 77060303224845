"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPieChartWidgetData = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const seriesType_1 = require("../../constants/seriesType");
const getNestedSeriesItems_1 = require("./getNestedSeriesItems");
/**
 * Formats the data source for the pie chart widget from custom queries defined in the config
 * @param {object} config - the parsed widget config object from Custom Widget
 * @param {object[]} customQueries - converts gathered raw data into the data source used by the pie widget
 * @returns {*[]|null} - null if data is not available, otherwise the data source for the pie widget
 */
const formatPieChartWidgetData = ({ config, customQueries }) => {
    //Try to get an object type for config
    const parsedConfig = (0, func_utils_1.tryParse)(config);
    if (!(0, func_utils_1.isObject)(parsedConfig)) {
        console.log("[formatPieChartWidgetData] Failed to parse widget config", JSON.stringify({ parsedConfig }));
        return null;
    }
    //Check if series data is available
    const series = parsedConfig?.series;
    if (!(0, func_utils_1.isNonEmptyArray)(series)) {
        console.log("[formatPieChartWidgetData] No series data available", JSON.stringify({ parsedConfig }));
        return null;
    }
    //Parse the data based on series type and available queries
    const dataSource = [];
    for (const seriesItem of series) {
        const type = seriesItem?.type;
        if (type === seriesType_1.SERIES_TYPE.AUTO) {
            const { customQueryId, categoriesField, valuesField } = seriesItem || {};
            //Check if custom query data exists
            const categories = (0, getNestedSeriesItems_1.getNestedSeriesItems)({ customQueries, customQueryId, stringPath: categoriesField });
            const values = (0, getNestedSeriesItems_1.getNestedSeriesItems)({ customQueries, customQueryId, stringPath: valuesField });
            if (!Array.isArray(categories) || !Array.isArray(values) || categories.length !== values.length) {
                continue;
            }
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                const value = values[i];
                if (!(0, func_utils_1.isNullOrUndefined)(category) && !(0, func_utils_1.isNullOrUndefined)(value)) {
                    const foundCategory = dataSource.find((item) => item?.id === category);
                    if (foundCategory) {
                        foundCategory.value += value;
                    }
                    else {
                        dataSource.push({
                            id: category,
                            label: (0, func_utils_1.convertCamelCaseToSentence)(category),
                            value: Math.round(value),
                        });
                    }
                }
            }
        }
        else if (type === seriesType_1.SERIES_TYPE.MANUAL) {
            const { categories } = seriesItem || {};
            if (!Array.isArray(categories)) {
                continue;
            }
            for (const category of categories) {
                const { customQueryId, name, valueField, color } = category || {};
                const values = (0, getNestedSeriesItems_1.getNestedSeriesItems)({ customQueries, customQueryId, stringPath: valueField });
                if (!Array.isArray(values) || !name) {
                    continue;
                }
                const foundCategory = dataSource.find((item) => item?.id === name);
                const total = values.reduce((a, b) => a + b, 0);
                if (foundCategory) {
                    foundCategory.value += total;
                }
                else {
                    dataSource.push({
                        id: name,
                        label: (0, func_utils_1.convertCamelCaseToSentence)(name),
                        value: Math.round(total),
                        color,
                    });
                }
            }
        }
    }
    //Provide default colors for categories without a color
    const rivialColors = (0, func_utils_1.getRivialColors)();
    if ((0, func_utils_1.isNonEmptyArray)(rivialColors)) {
        const totalColors = rivialColors.length;
        let usedColorsCounter = 0;
        for (const category of dataSource) {
            if (!category?.color) {
                category.color = rivialColors[usedColorsCounter % totalColors];
                usedColorsCounter++;
            }
        }
    }
    return dataSource;
};
exports.formatPieChartWidgetData = formatPieChartWidgetData;
