"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWidgetTag = void 0;
/**
 * Constructs a widget tag text that can be converted to a data-populated widget
 * @param widget
 * @returns {string}
 */
const createWidgetTag = (widget) => {
    return `<<${widget.name}::${widget.id}>>`;
};
exports.createWidgetTag = createWidgetTag;
