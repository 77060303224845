import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useSelectTemplates } from "../hooks/useSelectTemplates";

/**
 * A component instance of the useSelectTemplates.js hook
 * @param props
 */
const SelectTemplates = (props) => {
  return useSelectTemplates(props).display;
};

export default withOrganizationCheck(SelectTemplates);
