import { checkArguments } from "@rivial-security/func-utils";

/**
 * Handles the checkbox toggle for either side of the transfer list
 * @param {object} item - the item that is being selected
 * @param {object[]} checkedItems - currently selected items
 * @param {function} setCheckedItems - function handler for selecting items
 * @returns {function(): void}
 */
export const handleToggle = (item, checkedItems = [], setCheckedItems) => {
  checkArguments({
    item,
    checkedItems,
    setCheckedItems,
  });

  const currentIndex = checkedItems.findIndex((a) => a.id === item.id);

  const newChecked = [...checkedItems];

  if (currentIndex === -1) {
    newChecked.push(item);
  } else {
    newChecked.splice(currentIndex, 1);
  }

  setCheckedItems(newChecked);
};
