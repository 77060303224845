import { ReactElement } from "react";

import { resources } from "@rivial-security/role-utils";
import { PointOfContact, System } from "@rivial-security/schema-types";

import PointOfContactField, {
  PointOfContactFieldProps,
} from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import {
  handleSystemContactAssignment,
  SystemContactRole,
} from "@views/OrganizationManager/Questionnaires/functions/handleSystemContactAssignment";

/**
 * Custom field component for the System.pointOfContact field
 */
const SystemOwner = ({
  item,
  field,
  resetFunction,
  ...props
}: Omit<PointOfContactFieldProps<System>, "typename" | "idField">): ReactElement => {
  const handleAfterSubmit = async ({ pointOfContact }: { pointOfContact: PointOfContact }): Promise<void> => {
    if (!item) return;
    await handleSystemContactAssignment({
      system: item,
      role: SystemContactRole.Owner,
      pointOfContact,
    }).then(() => resetFunction?.());
  };
  const handleUnassign = async ({ pointOfContact }: { pointOfContact: PointOfContact }): Promise<void> => {
    if (!item) return;
    await handleSystemContactAssignment({
      system: item,
      role: SystemContactRole.Owner,
      pointOfContact,
      isUnassign: true,
    });
  };

  return (
    <PointOfContactField
      typename={resources.INFORMATION_SYSTEM}
      field={field}
      item={item}
      resetFunction={resetFunction}
      idField={"systemPointOfContactId"}
      afterSubmit={handleAfterSubmit}
      handleUnassign={handleUnassign}
      {...props}
    />
  );
};

export default SystemOwner;
