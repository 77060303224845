import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import CreateExercise from "../components/CreateExercise";
import ExerciseDetails from "../components/ExerciseDetails";
import { deleteExercise } from "../functions/deleteExercise";

/**
 * Exercise Grid hook
 * @param {string} organizationID - selected organization
 * @param {object} cardConfig - card config
 * @param {object} queryConfig - query config
 * @param {object} gridConfig - grid config object
 * @param {object} props - additional props passed to useDataGrid hook
 * @returns {object} {{gridDisplay: JSX.Element, data: *, lastSelectedItem: string, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean, ref: string, setSelectedItems: (value: (((prevState: *[]) => *[]) | *[])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: getData, fields: Object[], selectedItems: *[]}}
 */
export const useExerciseDataGrid = ({
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  additionalFields = [],
  ...props
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.EXERCISE;
  const route = "#/response/exercises/";

  cardConfig = {
    title: "Exercises",
    headerIcon: "icon-note",
    enableSticky: true,
    ...cardConfig,
  };

  const { listQuery, updateMutation } = generateGraphql(
    "Exercise",
    ["name", "description", "scenario", "accessControl"],
    {
      accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
    },
  );

  queryConfig = {
    organizationID,
    query: listQuery,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
      width: 200,
      minWidth: 150,
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      width: 500,
      bulkEdit: true,
    },
    {
      name: "scenario",
      width: 500,
      bulkEdit: true,
    },
  ];

  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    fields,
    route,
    detailsComponent: <ExerciseDetails />,
    detailsType: DETAILS_TYPES.PANEL,
    detailsTitle: "Exercise Details",
    createResourceComponent: <CreateExercise />,
    createItemModalHeader: "Create Exercise",
    persistenceUUID: "527fc22a-4f9b-4a8b-9fa6-ea2ac4deaa9b",
    enablePersistence: true,
    typename: "Exercise",
    enableMenu: true,
    enableContextMenu: true,
    enableSearch: true,
    enableFiltering: true,
    deleteFunction: deleteExercise,
    options: ["details", "delete", "edit", "duplicate"],
    updateMutation,
    duplicationSettings: {
      description: "Duplicates an Exercise. Preserves Name, description, and scenario",
      enabled: true,
      fields: ["name", "description", "scenario"],
    },

    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return { ...grid };
};
