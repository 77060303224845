import cx from "classnames";
import { ComponentPropsWithoutRef, ReactElement } from "react";

import { getIcon, IconName } from "@components/Ions/icons/components/Icons";

import StylelessButton from "../StylelessButton/StylelessButton";

import styles from "./IconButton.module.scss";

export enum IconButtonVariant {
  LIGHT = "light",
  DARK = "dark",
}

export interface IconButtonProps extends ComponentPropsWithoutRef<"button"> {
  /** The variant for the button depending on the background it's placed on (`light` or `dark`). */
  variant?: IconButtonVariant;
  /** Which icon should be displayed in the button. */
  iconName: IconName;
}

/**
 * A button that only has an icon.  This differs from a normal `Button` component since it's not used as a call to action.
 */
const IconButton = ({
  variant = IconButtonVariant.LIGHT,
  iconName,
  className,
  ...props
}: IconButtonProps): ReactElement => {
  const classNames = cx(styles["icon-button"], styles[`icon-button--${variant}`], className);

  return (
    <StylelessButton className={classNames} {...props}>
      {getIcon(iconName)}
    </StylelessButton>
  );
};

export default IconButton;
