"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recursivelyApplyQueryFunctions = void 0;
const getCustomQueryFunction_1 = require("./getCustomQueryFunction");
const getFunctionFieldName_1 = require("./getFunctionFieldName");
/**
 * Recursively applies all the user configured functions stored in the query
 * object to the data retrieved from the database, also takes care of non-root filters.
 * @param {object} data - data retrieved from the database based on the result fields configuration
 * @param {object} query - the main config object for the custom query  (the one used to retrieve the data)
 * @return {Promise<void>} - nothing returned, data object itself is changed
 */
const recursivelyApplyQueryFunctions = async ({ data, query }) => {
    const { functions, fields } = query || {};
    //Recursively apply functions to the nested field
    const recursiveApply = async ({ fieldName, field, data }) => {
        let fieldData;
        if (field?.hasMany) {
            fieldData = data?.[fieldName]?.items;
        }
        else {
            fieldData = data?.[fieldName];
        }
        if (fieldData) {
            await (0, exports.recursivelyApplyQueryFunctions)({
                data: fieldData,
                query: field,
            });
        }
    };
    //Depending on the nested field type apply the functions to the whole field or individual items
    if (typeof fields === "object") {
        for (const fieldName in fields) {
            //Find input information
            const field = fields[fieldName];
            if (Array.isArray(data)) {
                for (const item of data) {
                    await recursiveApply({
                        fieldName,
                        field,
                        data: item,
                    });
                }
            }
            else {
                await recursiveApply({
                    fieldName,
                    field,
                    data,
                });
            }
        }
    }
    //Apply any functions defined in the query to the data
    if (typeof functions === "object") {
        for (const functionName in functions) {
            const functionData = functions[functionName];
            const functionFieldName = (0, getFunctionFieldName_1.getFunctionFieldName)({
                pathElement: functionData,
            });
            const customFunction = (0, getCustomQueryFunction_1.getCustomQueryFunction)({ functionData });
            if (typeof customFunction !== "function")
                continue;
            if (Array.isArray(data)) {
                for (const element of data) {
                    element[functionFieldName] = await customFunction({
                        element,
                        params: functionData?.config,
                    });
                }
            }
            else {
                data[functionFieldName] = await customFunction({
                    element: data,
                    params: functionData?.config,
                });
            }
        }
    }
};
exports.recursivelyApplyQueryFunctions = recursivelyApplyQueryFunctions;
