"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultPieChartConfig = void 0;
const contextType_1 = require("../contextType");
/**
 * Preset settings to use when rendering or displaying a pie widget
 * in different contexts (when there is no other config provided)
 */
exports.defaultPieChartConfig = {
    [contextType_1.CONTEXT_TYPE.REPORT]: {
        layout: {
            width: "500px",
            height: "600px",
            margin: {
                top: -40,
                right: -40,
                bottom: -80,
                left: -40,
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.WEB]: {
        layout: {
            width: "100%",
            height: "100%",
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
};
