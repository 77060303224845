"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeObjectFromArray = void 0;
/**
 * Removes a single object form an array of objects. Returns the new array after removal.
 * @param array
 * @param object
 * @param field
 */
const removeObjectFromArray = (array, object, field = "id") => {
    const indexOfObject = array.findIndex((item) => item[field] === object[field]);
    if (indexOfObject !== -1) {
        array.splice(indexOfObject, 1);
    }
    return [...array];
};
exports.removeObjectFromArray = removeObjectFromArray;
