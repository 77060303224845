"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertMuiRuleToFilters = void 0;
/**
 * Convert Mui QueryBuilder rule groups to an AppSync filter
 * REFERENCE: https://docs.aws.amazon.com/appsync/latest/devguide/extensions.html
 * @param {object} logicBuilderFilter - the rule group created with mui logic builder, this is saved to query config directly
 * @returns {object}
 */
const convertMuiRuleToFilters = ({ logicBuilderFilter }) => {
    //Check arguments
    const { rules, combinator } = logicBuilderFilter || {};
    // - check rules to exist
    if (!Array.isArray(rules) || rules.length === 0) {
        return null;
    }
    // - check combinator to be "and"/"or"
    if (combinator !== "and" && combinator !== "or") {
        return null;
    }
    // Map of Mui QueryBuilder Operators to AppSync operators
    const operatorsMap = {
        startswith: "beginsWith",
        contains: "contains",
        notcontains: "notContains",
        equal: "eq",
        notequal: "ne",
        lessthan: "lt",
        lessthanorequal: "le",
        greaterthan: "gt",
        greaterthanorequal: "ge",
    };
    // Create the result filters object
    const filters = { [combinator]: [] };
    // Loop through the rules
    for (const rule of rules) {
        const { operator, field, value } = rule || {};
        //- skip unsupported operators
        const appsyncOperator = operatorsMap[operator];
        if (!appsyncOperator) {
            continue;
        }
        //- skip rules with no field
        if (!field) {
            continue;
        }
        filters[combinator].push({
            [field]: {
                [appsyncOperator]: value,
            },
        });
    }
    return filters;
};
exports.convertMuiRuleToFilters = convertMuiRuleToFilters;
