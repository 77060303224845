import * as en from "./en";
import * as es from "./es";

const resources = {
  en,
  es,
} as const;

/**
 * Note: these values should point to a key in the resources object
 */
export enum Languages {
  EN = "en",
  ES = "es",
}

export default resources;
