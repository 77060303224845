import { Icon } from "@iconify/react";

import TabCard from "../../../../../utils/GenericComponents/TabCard";

import KeyRiskIndicatorRiskChanges from "./components/KeyRiskIndicatorRiskChanges/KeyRiskIndicatorRiskChanges";
import SystemRiskChanges from "./components/SystemRiskChanges/SystemRiskChanges";

/**
 * A tab reverse compatible tab component for showing risk changes per system
 * both at a kri level and system level (when needed data is available)
 * @param {object} systemChanges - the system change part of monte carlo calculation results
 * @return {JSX.Element}
 */
const RiskChangeTable = ({ item, systemChanges: initSystemChanges }) => {
  const systemChanges = Array.isArray(initSystemChanges) ? initSystemChanges : item?.systemChanges;

  //Only show tab view in case the modified risk change data is available for at least one of the systems
  const modifiedKeyRiskIndicatorDataAvailable =
    Array.isArray(systemChanges) &&
    systemChanges.some((systemChange) => systemChange?.systemRiskValues?.modifiedKeyRiskIndicatorData);

  if (modifiedKeyRiskIndicatorDataAvailable) {
    return (
      <TabCard
        tabDisplay={true}
        items={[
          {
            icon: <Icon icon={"simple-line-icons:screen-desktop"} />,
            title: "System Changes",
            component: <SystemRiskChanges systemChanges={systemChanges} />,
          },
          {
            icon: <Icon icon={"simple-line-icons:energy"} />,
            title: "Key Risk Indicator Changes",
            component: <KeyRiskIndicatorRiskChanges systemChanges={systemChanges} />,
          },
        ]}
        sx={{
          container: {
            flex: 1,
            maxHeight: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
          tabContainer: {},
          tabContentContainer: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          },
          tabPanels: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      />
    );
  } else {
    return <SystemRiskChanges systemChanges={systemChanges} />;
  }
};

export default RiskChangeTable;
