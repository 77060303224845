"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLossCurve = void 0;
// TODO: Increase efficiency of calculating each y value from O(n) to O(logn) using the following steps:
// -Sort the simulatedLosses array at the start of the function.
// -Each use of "simulatedLosses.filter(loss => loss > amount).length" determines the number of losses that exceed the current 'amount' value by filtering through the entire array of losses.
//  Instead of filtering through the entire array every time, use a binary-search style operation on the sorted array to determine the index of the lowest-value loss that exceeds the current
//  'amount' value. Then take that index and subtract it from the length of the simulatedLosses array. The result will be number of losses which exceed the current 'amount' value.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createLossCurve = (simulatedLosses, lossCurveXPoints = []) => {
    const graphPoints = [];
    for (const lossAmount of lossCurveXPoints) {
        graphPoints.push({
            x: lossAmount,
            // @ts-expect-error Fix me, needs better typing
            y: simulatedLosses.filter((loss) => loss > lossAmount).length / simulatedLosses.length, // Loss probability.
        });
    }
    return graphPoints;
};
exports.createLossCurve = createLossCurve;
