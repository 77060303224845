import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Edit an assessment date
 * @param {object} item - assessment
 * @param {string} module - module name
 * @param {string} resource - resource name
 * @param {string} field - field name
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentDate = ({
  item,
  module = modules.VULNERABILITIES,
  resource = resources.ASSESSMENT,
  field = "assessmentDate",
}) => {
  const { updateMutation } = generateGraphql("Assessment", ["assessmentDate"]);

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field={field}
      inputType={GENERIC_FIELD_TYPES.DATE}
      mutation={updateMutation}
      inputConfig={{
        maxDate: new Date(),
      }}
      disableEdits={true}
    />
  );
};

export default AssessmentDate;
