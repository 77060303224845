/**
 * Determines if the custom field will be show
 * @param {object} field - single custom field object from a CustomResourceType
 * @return {boolean} - TRUE if field is valid, FALSE if not
 */
export const isValidField = (field) => {
  if (!field?.name || !field?.type) {
    return false;
  }
  return true;
};
