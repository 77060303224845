import Grid from "@mui/material/Grid";
import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "@hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "@hooks/views/useDetailsCardV2";
import Dashboard from "@utils/GenericComponents/Dashboard";
import DashboardCard from "@utils/GenericComponents/DashboardCard/components/DashboardCard";
import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import StyledWrapper from "@utils/GenericComponents/StyledWrapper";
import TagsField from "@utils/Tags/customFields/TagsField";
import { createQuestionnaireTagLink } from "@utils/Tags/functions/createTagLinks/createQuestionnaireTagLink";
import QuestionnaireStatus from "@views/OrganizationManager/Questionnaires/components/CustomFields/QuestionnaireStatus/QuestionnaireStatus";

import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import CustomResourceEntryDataGrid from "../../../CustomResources/CustomResourceEntries/components/CustomResourceEntryDataGrid";
import QuestionnaireCustomResourceType from "../components/CustomFields/QuestionnaireCustomResourceType";
import QuestionnaireAssignees from "../components/QuestionnaireAssignees";

/**
 * The details view for a single questionnaire
 * @param {string} itemId - the id of the questionnaire to view
 * @param {string} organizationID - the organization id of the questionnaire to view
 * @param {string} moduleInit - the platform module to use for role checking
 * @param {string} resourceInit - the platform resource to use for role checking
 * @param {object} props - any additional props to pass down to the useDetailsCardV2 hook
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: *, setIsLoading: (function(((function(boolean): boolean)|boolean)): void), tableDisplay: (React.DetailedReactHTMLElement<{item: *, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetFunction: ((function(): void)|*), display: JSX.Element, resetIndex: number, reset: (function(): void), setItem: (function(*): void)}}
 */
export const useQuestionnaireDetails = ({
  itemId,
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  ...props
}) => {
  const module = moduleInit || modules.ORGANIZATION_MANAGER;
  const resource = resourceInit || resources.QUESTIONNAIRE;
  const typename = resources.QUESTIONNAIRE;

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });

  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const customFields = [
    {
      field: "status",
      component: <QuestionnaireStatus moduleInit={module} resourceInit={resource} />,
    },
    {
      field: "startDate",
      inputType: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
    },
    {
      field: "endDate",
      inputType: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
    },
    {
      field: "allowUnauthenticatedSubmissions",
      inputType: GENERIC_FIELD_TYPES.SWITCH,
    },
    {
      field: "customResourceType",
      component: <QuestionnaireCustomResourceType />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createQuestionnaireTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];

  const details = useDetailsCard({
    displayFields,
    nestedFields,
    customFields,
    fieldNameDictionary: {
      allowUnauthenticatedSubmissions: "Guest Submissions",
      customResourceType: "Field Configuration",
    },
    updateMutation,
    queryConfig,
    module,
    resource,
    typename,
    ...props,
  });

  const accordion = useAccordion({
    details,
    items: [
      {
        id: "questionnaire-assignees",
        title: "Assignees",
        icon: "icon-people",
        subTitle: "Points of Contact that should respond to this Questionnaire",
        defaultExpanded: true,
        component: (
          <StyledWrapper wrapperStyle={{ height: "30em" }}>
            <QuestionnaireAssignees
              questionnaireID={itemId}
              organizationID={organizationID}
              resetFunctionExternal={details?.resetFunction}
            />
          </StyledWrapper>
        ),
      },
      {
        id: "questionnaire-responses",
        title: "Responses",
        icon: "icon-speech",
        subTitle: "Complete responses submitted for this Questionnaire",
        component: (
          <StyledWrapper wrapperStyle={{ height: "30em" }}>
            <CustomResourceEntryDataGrid
              customResourceTypeID={details?.item?.customResourceType?.id}
              module={module}
              resource={resource}
            />
          </StyledWrapper>
        ),
      },
    ],
  });

  const style = { height: "100%" };
  const display = (
    <Dashboard resetFunction={details?.reset}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard style={style} title={"General Information"} icon={"icon-list"}>
              {details.tableDisplay}
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} md={12} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return { ...details, display };
};
