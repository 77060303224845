import { useVendorSolutionDataGrid } from "../../../../views/Vendor/VendorSolutions/hooks/useVendorSolutionDataGrid";

export const vendorSolutionDefinition = {
  routes: {
    grid: "vendors/solutions",
    details: "vendors/solutions",
    helpCenter: "vendor-solutions",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:basket-loaded",

  //[COMPONENTS]
  gridHook: useVendorSolutionDataGrid,

  fields: {
    name: {},
    description: {},
    vendorSolutionVendorId: {},
    contractStatus: {},
    contractExpirationDate: {},
  },
};
