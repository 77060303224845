import { ReactElement } from "react";

import { enumToDropdownData, isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "@utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { questionnaireStatus } from "@views/OrganizationManager/Questionnaires/constants/questionnaireStatus";

const QuestionnaireStatus = ({ item, moduleInit, resourceInit, ...props }): ReactElement => {
  const { updateMutation } = generateGraphql(resources.QUESTIONNAIRE, ["status"]);
  const module = moduleInit || modules.ORGANIZATION_MANAGER;
  const resource = resourceInit || resources.QUESTIONNAIRE;
  const assignees = item?.assignees?.items;
  const hasAssignees = isNonEmptyArray(assignees);
  const showStatusChangeConfirmation = hasAssignees && (item?.startDate > new Date().toISOString() || !item?.startDate);

  return (
    <GenericEditFieldV3
      {...props}
      item={item}
      module={module}
      resource={resource}
      inputType={GENERIC_FIELD_TYPES.SINGLE_SELECT}
      field={"status"}
      confirmFunction={(value: keyof typeof questionnaireStatus): boolean => {
        if (value === questionnaireStatus.IN_PROGRESS && showStatusChangeConfirmation) {
          return window.confirm(
            "Are you sure you want to change the status of this questionnaire to In Progress? This will notify the assignees right away.",
          );
        }
        return true;
      }}
      inputConfig={{ data: enumToDropdownData({ ENUM: questionnaireStatus }) }}
      mutation={updateMutation}
    />
  );
};

export default QuestionnaireStatus;
