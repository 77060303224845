import { useEffect, useState } from "react";

/**
 * A hook allowing to remove jitter from a changing value by waiting for a delay before updating the state with
 * the new value. If the value changes before the delay, the new value is ignored and the delay timer is reset.
 * @param {*} value - the value to watch and debounce
 * @param {number} delay - amount of milliseconds to wait before updating the state (if value not in delay map this is used)
 * @param {object} delayMap - if the delay needs to be different for different values can provide specific delay amounts for each value (takes priority)
 * @return {*} - the debounced value
 */
export const useDebounceState = ({ value, delay, delayMap = {} }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(
      () => setDebouncedValue(value),
      Number.isFinite(delayMap[value]) ? delayMap[value] : delay || 500,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
