"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertQueryIntoGraphql = void 0;
const definition_utils_1 = require("@rivial-security/definition-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
const deRootifyQueryObject_1 = require("./deRootifyQueryObject");
const generateNestedFieldsGraphql_1 = require("./generateNestedFieldsGraphql");
/**
 * Converts a query object built with QueryBuilder into a GraphQL query string
 * @param {object} query - the query object to convert, with the root element included
 * @param {function} log - the log function to use
 * @return {{listQuery: string, listByOwnerGroupQuery: string}}
 */
const convertQueryIntoGraphql = ({ query, log }) => {
    let listQuery;
    let listByOwnerGroupQuery;
    try {
        const cleanedQuery = (0, deRootifyQueryObject_1.deRootifyQueryObject)({ query });
        const typename = cleanedQuery?.typename;
        if (!typename) {
            return null;
        }
        //Creates root and nested fields to pass down to the graphql wrapper functions
        let nestedFields = "";
        if (typeof cleanedQuery === "object") {
            nestedFields = (0, generateNestedFieldsGraphql_1.generateNestedFieldsGraphql)({
                fieldData: { ...cleanedQuery, hasMany: true, isRoot: true },
            });
        }
        else {
            throw new Error("Could not find root fields in query object");
        }
        listQuery = (0, generategraphql_1.generateListQueryGraphql)({
            typename,
            nestedFields,
        });
        const ownerGroupIndex = (0, definition_utils_1.getResourceIndexByField)({
            resource: typename,
            partitionKey: "ownerGroup",
        });
        if (ownerGroupIndex) {
            listByOwnerGroupQuery = (0, generategraphql_1.generateListByQueryGraphql)({
                typename,
                nestedFields,
                index: ownerGroupIndex,
            });
        }
    }
    catch (e) {
        if (typeof log === "function") {
            log("Error cannot covert query into graphql", e);
        }
    }
    return { listQuery, listByOwnerGroupQuery };
};
exports.convertQueryIntoGraphql = convertQueryIntoGraphql;
