import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Returns a formatted grid header text based on the field object passed into the grid
 * @param {object} field - a grid field entry that's passed into the grid at the start
 */
export const getGridHeaderText = (field) => {
  if (field?.friendlyName) {
    return field?.friendlyName;
  } else if (field?.name) {
    return convertCamelCaseToSentence(field.name);
  } else {
    return "Field";
  }
};
