import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { getRememberedDeviceStatus } from "./getRememberedDeviceStatus";

/**
 * @description onSuccess function, called when user.setDeviceStatusRemembered() call succeeds
 * @param {object} user - Cognito user object
 * @param {object} result - results from the user.setDeviceStatusRemembered() call
 * @param {function} callback - Callback function for getting device value
 */
export const onSuccess = (user, result, callback) => {
  if ((isNullOrUndefined(user) && isNullOrUndefined(result)) || isNullOrUndefined(callback)) {
    throw new Error("[setDeviceStatusRemembered.js -> onSuccess] Invalid params");
  }

  InfoLogger(`setDeviceStatusRemembered: ${result}`);

  try {
    getRememberedDeviceStatus({ user, callback });
  } catch (e) {
    ErrorLogger("Error! Can not get RememberedDeviceStatus", e);
  }
};

/**
 * @description Function for setting remembered device to "Remembered" for a Cognito user
 * @param {object} user - Cognito user object
 * @param {function} callback - Callback function for getting device value
 * @param {function} addToast
 */
export const setDeviceStatusRemembered = ({ user, callback, addToast }) => {
  if (isNullOrUndefined(user) || isNullOrUndefined(callback)) {
    throw new Error("[setDeviceStatusRemembered.js] Invalid params");
  }

  user.setDeviceStatusRemembered({
    onSuccess: (result) => {
      onSuccess(user, result, callback);
      if (addToast) {
        addToast({
          header: `Device Status was changed to Remembered`,
          icon: "success",
        });
      }
    },
    onFailure: (err) => {
      ErrorLogger("Error! setDeviceStatusRemembered", err);
      if (addToast) {
        addToast({
          header: `Error! Device Status was NOT changed to Remembered`,
          icon: "danger",
        });
      }
    },
  });
};
