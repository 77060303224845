import { enumToDropdownData } from "@rivial-security/func-utils";

import { getPriorityScaleSegment } from "../../../../../Program/PriorityActions/CustomFields/PriorityBadge/getPriorityScaleSegment";
import {
  PriorityLevel,
  PriorityScaleSegment,
} from "../../../../../Program/PriorityActions/CustomFields/PriorityBadge/types";

export const getVulnerabilityPriorityDropdownData = ({
  systemPriority,
}: {
  systemPriority?: number | null;
}): { value: string; text: string }[] => {
  const systemPrioritySegment: PriorityScaleSegment = getPriorityScaleSegment({ priority: systemPriority });
  return enumToDropdownData({
    ENUM: PriorityLevel,
    overrides: { [PriorityLevel.NONE]: `System Priority (${systemPrioritySegment.text})` },
    doConvertCamelCaseToSentence: false,
  }) as { value: string; text: string }[];
};
