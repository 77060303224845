import React, { useEffect, useState } from "react";
import { CustomInput } from "reactstrap";

import { ErrorLogger } from "@utils/EventLogger";

import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";

const getOrganizationConfig = async ({ organizationID }) => {
  const getOrganization = /* GraphQL */ `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        config
      }
    }
  `;

  if (organizationID) {
    try {
      const organization = await QueryGetItem({
        query: getOrganization,
        itemId: organizationID,
      });

      organization["config"] = JSON.parse(organization?.config);

      return organization?.config;
    } catch (e) {
      ErrorLogger("Error! Can not parse organization config object", e);
    }
  }
};

export const getOrganizationConfigValue = async ({ organizationID, key, keys, withConfig = false }) => {
  const getValue = async () => {
    if (!organizationID) {
      return null;
    }

    const organizationConfig = await getOrganizationConfig({ organizationID });
    if (!organizationConfig) {
      return null;
    }

    if (key && organizationConfig.hasOwnProperty(key)) {
      return {
        config: { ...organizationConfig },
        value: organizationConfig[key],
      };
    }

    if (keys) {
      const values = {};
      keys.forEach((key) => {
        values[key] = organizationConfig[key];
      });

      return {
        config: { ...organizationConfig },
        value: values,
      };
    }
  };

  const { value, config } = (await getValue()) ?? { config: {} };
  if (withConfig) {
    return { value, config };
  } else {
    return value;
  }
};

/**
 * @typedef {object} UseOrganizationConfigParams
 * @param {string} organizationID - organization ID
 * @param {string} [key] - key to get value
 * @param {string[]} [keys] - keys to get values
 */

/**
 * @description Get organization config value
 * @param {UseOrganizationConfigParams} params
 * @returns {object}
 */
export const useOrganizationConfig = ({ organizationID, key, keys }) => {
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handler = async () => {
      setIsLoading(true);

      const { value } = await getOrganizationConfigValue({
        organizationID,
        key,
        keys,
        withConfig: true,
      });

      setValue(value);
      setIsLoading(false);
    };
    if (organizationID) {
      handler();
    }
  }, [organizationID]);

  const updateOrganization = /* GraphQL */ `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
      updateOrganization(input: $input) {
        id
        config
      }
    }
  `;

  const setNewValue = async (val) => {
    const configLocal = (await getOrganizationConfig({ organizationID })) || {};

    configLocal[key] = val;
    setValue(val);
    return await updateOrganizationConfigHook.editItem({
      id: organizationID,
      config: JSON.stringify({ ...configLocal }),
    });
  };

  const setNewValues = async (values) => {
    const configLocal = (await getOrganizationConfig({ organizationID })) || {};
    Object.keys(values).forEach((key) => {
      configLocal[key] = values[key];
    });
    setValue(values);
    return await updateOrganizationConfigHook.editItem({
      id: organizationID,
      config: JSON.stringify({ ...configLocal }),
    });
  };

  const updateOrganizationConfigHook = useMutation({
    mutation: updateOrganization,
    disableRoleChecking: true,
  });

  const displayEnableAutoReminders = (
    <CustomInput
      id={"enableAutoReminders"}
      type="switch"
      name="enabledStatistics"
      label="Enable Auto Reminders"
      checked={value}
      onChange={() => setValue(!value)}
      title="Turn On/Off Auto Reminders"
    />
  );

  return {
    value,
    setValue: (val) => setNewValue(val),
    setValues: (values) => setNewValues(values),
    displayEnableAutoReminders,
    isLoading,
  };
};
