import React from "react";

const Settings = React.lazy(() => import("@views/Settings/Settings"));

// Settings Routes
export const settingsRoutes = [
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
];
