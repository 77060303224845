import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { ListQuery } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import { useSetAsyncData } from "../../../../../../../../hooks/functional/useSetAsyncData";
import { useForm } from "../../../../../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../../../../../utils/Context/withOrganizationCheck";
import FeatureAlert from "../../../../../../../../utils/GenericComponents/FeatureAlert";
import WidgetPreview from "../../../../../../WidgetRenderer/components/ChartWidgetPreview";
import { createWidgetActions } from "../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../useCreateWidget";

/**
 * Component for selecting a metric type to populate a history chart widget
 * @param {string} organizationID - currently selected organization ID
 * @returns {JSX.Element}
 */
const HistoryChartDataSelection = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);
  const [editedSeriesItem, setEditedSeriesItem] = useState(null);
  const [metricTypes, setMetricTypes] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Find or create the new series item(s)
  useEffect(() => {
    let currentSeriesItem = null;
    const series = widget?.config?.series;
    if (isNonEmptyArray(series)) {
      currentSeriesItem = series[0];
    }

    if (!currentSeriesItem) {
      currentSeriesItem = {
        id: uuid(),
      };
    }

    setEditedSeriesItem(currentSeriesItem);
  }, []);

  // Retrieve selectable metric types for the widget
  useSetAsyncData({
    getData: async () => {
      const listMetricsQuery = generateGraphql("MetricType", ["format", "name", "automation"], {
        automation: `{name}`,
      }).listQuery;

      return await ListQuery({
        query: listMetricsQuery,
        organizationID,
      });
    },
    setData: (newMetricTypes) => {
      setMetricTypes(newMetricTypes);
    },
    dependencies: [organizationID],
  });

  const metricTypeSelectionForm = useForm({
    fieldConfig: {
      metricTypeId: {
        label: "Choose a Metric Type",
        tooltip: "Select a metric type to populate the widget with time series data.",
        inputType: "dropdown",
        dropdownConfig: {
          data: (metricTypes || []).map((metricType) => {
            return {
              value: metricType.id,
              text: metricType.name,
            };
          }),
        },
      },
    },
    disableSubmitButton: true,
    disableResetButton: true,
  });

  //Change value of widget series when form changes after initialization
  useEffect(() => {
    const newInput = metricTypeSelectionForm.input;

    if (!isInitialized) {
      return;
    }

    const newSeriesItem = {
      ...editedSeriesItem,
      metricTypeId: metricTypeSelectionForm?.input?.metricTypeId,
    };

    dispatch({
      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
      seriesInput: newSeriesItem,
    });
  }, [metricTypeSelectionForm.input]);

  //Wait for form to be initialized before allowing it to dispatch changes
  useEffect(() => {
    //The edited series item has not been loaded yet
    if (!editedSeriesItem) {
      return;
    }

    //Check if there is anything to initialize
    if (!editedSeriesItem?.metricTypeId) {
      setIsInitialized(true);
      return;
    }

    //Form not yet set to the currently edited value
    if (metricTypeSelectionForm?.input?.metricTypeId !== editedSeriesItem?.metricTypeId) {
      return;
    }

    setIsInitialized(true);
  }, [editedSeriesItem, metricTypeSelectionForm?.input?.metricTypeId]);

  //Attempt to sync the for to the edited series item
  useEffect(() => {
    if (!isInitialized && editedSeriesItem && metricTypes) {
      metricTypeSelectionForm.setInput({
        metricTypeId: editedSeriesItem?.metricTypeId,
      });
    }
  }, [metricTypes, editedSeriesItem]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
        <div style={{ maxWidth: "20em" }}>
          <FeatureAlert
            header={"Tip!"}
            text={
              "All history chart widgets are populated with time series data from Metric Types. A preview of the data will be shown upon selecting a valid option."
            }
          />
        </div>
        {metricTypeSelectionForm.display}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "70vh",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: ".5em",
        }}
      >
        <WidgetPreview
          widget={{
            type: WIDGET_TYPE.HISTORY_CHART,
            ...widget,
          }}
        />
      </div>
    </div>
  );
};
export default withOrganizationCheck(HistoryChartDataSelection);
