import React, { useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { InfoLogger } from "@utils/EventLogger";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import SelectPointOfContacts from "../components/SelectPointOfContacts";
import { createPointOfContactResponseTeamLink } from "../functions/createPointOfContactResponseTeamLink";

export const useCreateResponseTeam = ({ organizationID, getNewItem, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_TEAM;

  const { createMutation } = generateGraphql("ResponseTeam", ["name", "description", "pointOfContactLinks"], {
    pointOfContactLinks: `(limit: 1000) { items { id } }`,
  });

  const [contacts, setContacts] = useState([]);

  /**
   * After Response Team gets created, attach points of contact
   * @param responseTeam
   */
  const callback = (responseTeam) => {
    if (!isNullOrUndefined(contacts) && Array.isArray(contacts)) {
      for (const contact of contacts) {
        createPointOfContactResponseTeamLink(responseTeam, contact, organizationID).then(() => {
          InfoLogger("Successfully Created PointOfContactResponseTeamLink");
        });
      }
    }
  };

  const form = useForm({
    mutation: createMutation,
    module,
    resource,
    callback,
    organizationID,
    getNewItem,
    fieldConfig: {
      name: {
        label: "Response Team Name",
        required: true,
      },
      description: {
        label: "Team Description",
      },
      pointOfContacts: {
        removeFromInput: true,
        inputType: "custom",
        customConfig: {
          component: (
            <SelectPointOfContacts contacts={contacts} setContacts={setContacts} organizationID={organizationID} />
          ),
        },
      },
    },
    ...props,
  });

  return {
    ...form,
  };
};
