import React from "react";

/**
 * @description Context for the QueryBuilder component
 */
export default React.createContext({
  dispatch: null,
  filter: [],
  filtersByValue: {},
  flattenedFilters: [],
  maxLevel: null,
  operators: [],
  operatorsByType: {},
  operatorsByValue: {},
});
