import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

export const taskDefinition = {
  routes: {
    grid: "governance/tasks",
    details: "governance/tasks",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:check",
  fields: {
    name: {
      default: {
        description: "The primary text of this Task, short explanation of what needs to be done",
      },
    },
    description: {
      default: {
        description: "Any additional details regarding this Task",
      },
    },
    status: {
      default: {
        description: "The current completion state of this Task",
      },
    },
    type: {
      default: {
        description: "The current completion state of this Task",
      },
    },
    module: {
      default: {
        description: "The module of the Rivial Software Platform that this Task realates to",
      },
      form: {
        description: "What module of the Rivial Software Platform should this be categorized into?",
      },
    },
    dueDate: {
      default: {
        description: "The date after which to mark this task as past due",
      },
    },
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },

    checklist: {
      default: {
        nestedFields: `{
          name
          description
          groups {
            id
            name
            description
            items {
              id
              content
              completed
              notes ${generatedNestedFieldsForDefinition({
                definition: noteDefinition,
              })}
            }
          }
        }`,
      },
    },
    custom: {
      default: {
        nestedFields: `{ content }`,
      },
    },

    pointOfContact: {
      default: {
        description: "The contact responsible for this task's completion",
        nestedFields: `{ id firstName lastName email department {name id} }`,
      },
    },
    pointOfContactID: {
      default: {
        description: "The contact responsible for this task's completion",
      },
    },
    department: {
      default: {
        description: "The department responsible for this task's completion",
        nestedFields: `{ id name }`,
      },
    },
    departmentID: {
      default: {
        description: "The department responsible for this task's completion",
      },
    },

    questionnaireAssignee: {
      default: {
        description: "Information on the assignee of this questionnaire task",
        nestedFields: `{
          id
          questionnaire {
            id
            name
            customResourceType {
              id
              name
            }
          }
          pointOfContact {
            id
          }
          submissions (limit: 100)  {
            items {
              id
              createdAt
            }
          }
        }`,
      },
    },
    questionnaireAssigneeID: {
      default: {
        description: "Information on the assignee of this questionnaire task",
      },
    },
  },

  views: {
    grid: {
      queryFields: [
        "name",
        "description",
        "module",
        "status",
        "type",
        "dueDate",
        "pointOfContact",
        "department",
        "tags",
      ],
    },
    details: {
      displayFields: [
        "name",
        "description",
        "module",
        "status",
        "type",
        "pointOfContact",
        "department",
        "dueDate",
        "tags",
      ],
      queryFields: [
        "name",
        "description",
        "module",
        "status",
        "type",
        "dueDate",
        "notes",
        "checklist",
        "custom",
        "pointOfContact",
        "department",
        "questionnaireAssignee",
        "tags",
      ],
    },
  },
};
