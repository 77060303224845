import React from "react";
import { Button } from "reactstrap";

/**
 * Vendor sub control inPlace and audited badge display components
 * @param {boolean} value - the true/false value of the badge
 * @param {string} trueColor - the reactstrap color to display oin the badge text when the value is true
 * @param {string} trueText - text to display when the value is true
 * @param {string} falseText - text to display when the value is false
 * @return {JSX.Element}
 */
const VendorControlStatusBadge = ({ value, trueColor, trueText, falseText }) => {
  return (
    <Button color={value ? trueColor : "ghost-danger"} size="sm" onClick={null} style={{ pointerEvents: "none" }}>
      <i className={value ? "icon-check" : "icon-close"} /> {value ? trueText : falseText}
    </Button>
  );
};
export default VendorControlStatusBadge;
