import { cloneDeep } from "lodash";
import React, { useState } from "react";

import { tryParse } from "@rivial-security/func-utils";
import { generateReportSection } from "@rivial-security/report-utils";
import { CONTEXT_TYPE, getEmailWidget, getReportWidget } from "@rivial-security/widget-utils";

import { useSetAsyncData } from "../../../../../../../../hooks/functional/useSetAsyncData";
import { defaultEmailContentWidth } from "../../../../../../../OrganizationManager/EmailTemplates/constants/defaultEmailContentWidth";
import DocumentEditor from "../../../../../../../Reports/DocumentEditor/components/DocumentEditor";
import { DOCUMENT_EDITOR_MODE } from "../../../../../../../Reports/DocumentEditor/hooks/useDocumentEditor";

import EmailWidgetPreview from "./EmailWidgetPreview";
import TableWidgetViewSwitchTitle from "./TableWidgetViewSwitchTitle";

/**
 * Web preview of a table widget
 * @param {string} organizationID - the currently selected organization
 * @param {string} title - title for the table widget
 * @param {object} widget - the widget data
 * @param {object} runner - query runner responsible for getting the data for the preview
 * @param {string} [singleViewType] optionally sets the one and only view that the preview allows to display
 * @returns {JSX.Element}
 */
const TableWidgetPreview = ({ organizationID, title, widget, runner, singleViewType }) => {
  const [previewType, setPreviewType] = useState(singleViewType || CONTEXT_TYPE.WEB);
  const [widgetPreviewSFDT, setWidgetPreviewSFDT] = useState(null);
  const [widgetPreviewHTMLString, setWidgetPreviewHTMLString] = useState(null);

  //When the preview type changes load in widget data
  useSetAsyncData({
    getData: async () => {
      const parsedWidget = cloneDeep(widget);
      if (typeof parsedWidget?.config === "string") {
        parsedWidget.config = tryParse(parsedWidget?.config);
      }

      let newWidgetSFDT = null;
      let newWidgetPreviewSFDT = null;
      let newWidgetPreviewHTMLString = null;
      let runQuery = false;

      if (previewType === CONTEXT_TYPE.REPORT) {
        newWidgetSFDT = await getReportWidget({ widget: parsedWidget });
        if (newWidgetSFDT) {
          newWidgetPreviewSFDT = await generateReportSection([newWidgetSFDT]);
        }
      } else if (previewType === CONTEXT_TYPE.WEB) {
        runQuery = true;
      } else if (previewType === CONTEXT_TYPE.EMAIL) {
        newWidgetPreviewHTMLString = await getEmailWidget({
          context: { contentWidth: defaultEmailContentWidth },
          widget: parsedWidget,
        });
      }
      return { newWidgetPreviewHTMLString, newWidgetPreviewSFDT, runQuery };
    },
    setData: ({ newWidgetPreviewHTMLString, newWidgetPreviewSFDT, runQuery }) => {
      setWidgetPreviewSFDT(newWidgetPreviewSFDT);
      setWidgetPreviewHTMLString(newWidgetPreviewHTMLString);
      if (runQuery) {
        runner?.workflow?.runQuery();
      }
    },
    dependencies: [previewType, widget?.id],
  });

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <TableWidgetViewSwitchTitle
        title={title}
        defaultView={previewType}
        singleViewType={singleViewType}
        onSelectView={(view) => {
          setPreviewType(view);
        }}
      />
      <div style={{ flex: 1, marginTop: ".5em", minHeight: 0 }}>
        {previewType === CONTEXT_TYPE.WEB && runner.gridDisplay}
        {previewType === CONTEXT_TYPE.REPORT && (
          <DocumentEditor
            mode={DOCUMENT_EDITOR_MODE.VIEW}
            height={"60vh"}
            inModal={false}
            sfdtToLoad={widgetPreviewSFDT}
          />
        )}
        {previewType === CONTEXT_TYPE.EMAIL && (
          <EmailWidgetPreview organizationID={organizationID} htmlString={widgetPreviewHTMLString} />
        )}
      </div>
    </div>
  );
};

export default TableWidgetPreview;
