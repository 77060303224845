import { PolicyVersion } from "@rivial-security/schema-types";

import { PolicyVersionStatus as PolicyVersionStatusEnum } from "../../../constants/PolicyVersionStatus";

/**
 * Selects the policy version to be shown by default to a user
 * @param policyVersions - all versions that are accessible to the user
 */
export const selectShownPolicyVersion = (policyVersions: PolicyVersion[]): PolicyVersion | undefined => {
  let shownPolicyVersion: PolicyVersion | undefined;

  for (const policyVersion of policyVersions) {
    const shownVersionNumber = shownPolicyVersion?.version ?? -1;
    const currentVersionNumber = policyVersion?.version ?? -1;
    const currentVersionNumberHigher = currentVersionNumber > shownVersionNumber;
    const noShownPolicyVersion = !shownPolicyVersion;

    const shownPolicyApproved = shownPolicyVersion?.status === PolicyVersionStatusEnum.APPROVED;
    const currentPolicyApproved = policyVersion?.status === PolicyVersionStatusEnum.APPROVED;
    const shownAndCurrentPolicyStatusSame = shownPolicyApproved === currentPolicyApproved;

    if (!shownPolicyApproved && currentPolicyApproved) {
      shownPolicyVersion = policyVersion;
    } else if (shownAndCurrentPolicyStatusSame) {
      if (currentVersionNumberHigher || noShownPolicyVersion) {
        shownPolicyVersion = policyVersion;
      }
    }
  }

  return shownPolicyVersion;
};
