import AWS from "aws-sdk";

import { ErrorLogger } from "../../EventLogger";
import { ItemQuery } from "../Graphql/ItemQuery";

import { GetOrgIamCredentials } from "./GetOrgIamCredentials";

const getOrganization_s3BucketName = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      s3BucketName
    }
  }
`;

/**
 * @function
 * @description Get a signed url from S3 bucket
 * @param bucketName {string}
 * @param objectKey {string}
 * @param callbackFunction {function}
 * @param organizationID {string}
 * @returns {Promise<any>}
 * @constructor
 */

export const GetSignedObjectUrl = async ({ bucketName, objectKey, callbackFunction, organizationID }) => {
  let s3BucketName = null;

  if (organizationID) {
    const org = await ItemQuery(getOrganization_s3BucketName, organizationID);
    s3BucketName = org?.s3BucketName;
  }

  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: "us-west-2",
  });

  const params = {
    Bucket: s3BucketName || bucketName,
    Key: objectKey,
    Expires: 300,
  };

  return s3.getSignedUrl(
    "getObject",
    params,
    callbackFunction
      ? (err, data) => {
          if (err) {
            ErrorLogger("Error! ", err);
            callbackFunction?.(null);
            return null;
          } else {
            callbackFunction?.(data);
            return data;
          }
        }
      : null,
  );
};
