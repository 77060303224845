import CloudDone from "@mui/icons-material/CloudDone";
import Save from "@mui/icons-material/Save";
import Sync from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

import { useDebounce } from "./useDebounce";
/**
 * An automatic save hook that wll run the update function on the state after a delay of inactivity
 * Provides a way to view status and to allow user to force save with a mui button.
 * @param {*} stateInit - the initial state of the value to save
 * @param {number} delay - the delay in milliseconds to wait before saving on state change
 * @param {function} saveFunction - the custom function to use to save the state
 * @returns {{setState: (value: unknown) => void, state: unknown, saveButton: JSX.Element}}
 */
export const useDebounceSaveState = ({ state: stateInit, delay, saveFunction }) => {
  const [state, setState] = useState(stateInit);
  const [needsSave, setNeedsSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const runSaveFunction = async (value) => {
    setIsSaving(true);

    if (typeof saveFunction === "function") {
      await saveFunction(value);
    }
    setNeedsSave(false);
    setIsSaving(false);
  };

  const { timerRef } = useDebounce({
    value: state,
    func: runSaveFunction,
    startTimerFunc: () => {
      setNeedsSave(true);
    },
    delay: delay || 5000,
  });

  const getSaveButtonText = () => {
    if (isSaving) {
      return "Saving...";
    }
    if (needsSave) {
      return "Save";
    }
    return "Saved";
  };

  const getSaveButtonIcon = () => {
    if (isSaving) {
      return <Sync />;
    }
    if (needsSave) {
      return <Save />;
    }
    return <CloudDone />;
  };

  const saveButton = (
    <LoadingButton
      loading={isSaving}
      loadingPosition={"start"}
      startIcon={getSaveButtonIcon()}
      color={"success"}
      variant={"text"}
      size={"small"}
      disabled={needsSave === false}
      onClick={async () => {
        clearTimeout(timerRef?.current);
        await runSaveFunction(state);
      }}
    >
      {getSaveButtonText()}
    </LoadingButton>
  );

  return { state, setState, saveButton };
};
