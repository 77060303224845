import ResourcePathFormField from "../../components/generic/resourcePath/ResourcePathFormField";
import { schemaEntities } from "../schemaEntities";

export const resourcePathDefinition = {
  //[TYPE]
  isPrimitive: true,
  underlyingType: "Array",
  schemaEntity: schemaEntities.GENERIC,

  //[APPEARANCE]
  icon: "simple-line-icons:layers",
  InputComponent: ResourcePathFormField,
};
