/**
 * Restructures the data structure from query to be in riskControlID: vendorControlData format
 * @param {object} vendorReview - the vendor review data with associated vendor controls, templates and finally risk controls
 * @return {object} - the restructured object with riskControlID (keys) to vendorControlData (values) format
 */
export const extractVendorReviewRiskControls = ({ vendorReview } = {}) => {
  const riskControls = {};
  const controlCategories = vendorReview?.controlCategories?.items || [];
  if (Array.isArray(controlCategories)) {
    for (const controlCategory of controlCategories) {
      const subControls = controlCategory?.subControls?.items || [];
      if (Array.isArray(subControls)) {
        for (const subControl of subControls) {
          const vendorControlTemplate = subControl?.vendorControlTemplate || {};
          const riskControl = vendorControlTemplate?.riskControl || {};
          const riskControlID = riskControl?.id;
          if (riskControlID) {
            riskControls[riskControlID] = subControl;
          }
        }
      }
    }
  }
  return riskControls;
};
