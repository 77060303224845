import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKnowBe4TrainingEnrollmentsDataGrid } from "../hooks/useKnowBe4TrainingEnrollmentsDataGrid";

/**
 * @description Display list of KnowBe4 Training Enrollments
 * @param {object} props - The props passed to the component
 */
const KnowBe4TrainingEnrollmentsDataGrid = (props) => useKnowBe4TrainingEnrollmentsDataGrid(props).display;

export default withOrganizationCheck(KnowBe4TrainingEnrollmentsDataGrid);
