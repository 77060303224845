import { getConditionBuilderResources } from "./getConditionBuilderResources";

/**
 * Gets a list of smart values from the current automation configuration
 *
 * @param automationStep - the current automation
 * @param automationStep.trigger - the trigger configuration
 * @param automationStep.trigger.type - the type of the trigger
 * @param automationStep.condition - the condition configuration
 * @param automationStep.condition.typename - the typename for the condition
 * @returns {string[]} - a list of smart values
 */
export const getAutomationTriggerSmartValues = ({ automationStep }) => {
  const values = [];

  // if webhook trigger, set webhookData smart value(s)
  if (automationStep?.trigger?.type === "webhook") {
    values.push("webhookData.field1");
  }

  // if resource update / create trigger, set smart values based on selected type
  if (automationStep?.trigger?.type === "resourceUpdated" || automationStep?.trigger?.type === "resourceCreated") {
    // now have to check for the typenames of the conditions to figure out which fields to suggest
    if (automationStep?.condition) {
      const typename = automationStep?.condition?.typename;

      /**
       * @typedef ParsedType
       * @property {string} typename
       * @property {object} fields
       */
      const types = getConditionBuilderResources({
        manyToMany: false,
        oneToMany: false,
      });

      const parsedType = types.find((type) => type.typename === typename);

      if (parsedType) {
        Object.keys(parsedType.fields).forEach((field) => {
          values.push(`item.${field}`);
        });
      }
    }
  }

  return values;
};
