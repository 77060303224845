import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { listEvidenceActivitiesByOwnerGroupForArtifact } from "../constants/listEvidenceActivitiesByOwnerGroupForArtifact";
import { noItemsRootNode } from "../constants/noItemsRootNode";

import { combineChildQueryData } from "./combineChildQueryData";
import { getComplianceActivityData } from "./getComplianceActivityData";

/**
 * Gets data for the Compliance Activity Download by Evidence
 * @param {string} organizationID - organization ID
 * @param {string} rootName - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 * @returns {Promise<{children: *, name: string, id: string, type: string}>}
 */
export const documentsByEvidence = async ({ organizationID, rootName, dateRange }) => {
  const evidenceListPromise = ListQueryBy({
    query: listEvidenceByOwnerGroupForArtifact,
    variables: {
      ownerGroup: organizationID,
    },
  });
  const evidenceActivityListPromise = ListQueryBy({
    query: listEvidenceActivitiesByOwnerGroupForArtifact,
    variables: {
      ownerGroup: organizationID,
    },
  });
  await Promise.allSettled([evidenceListPromise, evidenceActivityListPromise]);

  const evidenceListOnly = await evidenceListPromise;
  const evidenceActivityListOnly = await evidenceActivityListPromise;
  const evidenceList = combineChildQueryData({
    parents: evidenceListOnly,
    children: evidenceActivityListOnly,
    childFieldInParent: "activity",
    parentIDFieldInChild: "evidenceID",
  });

  if (!Array.isArray(evidenceList) || evidenceList.length === 0) {
    ErrorLogger("[documentsByEvidence.js] - evidences is not an array");
    return noItemsRootNode;
  }

  return getComplianceActivityData({
    items: evidenceList,
    rootName,
    dateRange,
  });
};

const listEvidenceByOwnerGroupForArtifact = generateGraphql(
  "Evidence",
  ["name"],
  {},
  {
    indexName: "evidencesByOwnerGroup",
    partitionKey: "ownerGroup",
  },
).listByQuery;
