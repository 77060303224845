"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCostOfControl = void 0;
const checkSystemForRiskControlOverride_1 = require("./checkSystemForRiskControlOverride");
const findIndexOfRiskControlOverride_1 = require("./findIndexOfRiskControlOverride");
const isRiskControlOverrideFieldEnabled_1 = require("./isRiskControlOverrideFieldEnabled");
/**
 * Gets the cost of a riskControl associated with a system.
 * If a riskControl is overridden ("non-standard") for a particular system, return the override value.
 * If a riskControl is not overridden ("standard"), return the riskControl value
 * @param riskControl
 * @param system
 */
const getCostOfControl = (riskControl, system) => {
    if (system === null || system === undefined) {
        throw Error("Could not get cost of control. Invalid system input");
    }
    const riskControlOverrides = system.riskControlOverrides;
    const indexOfRiskControlOverride = (0, findIndexOfRiskControlOverride_1.findIndexOfRiskControlOverride)(riskControlOverrides, riskControl);
    const doesCostOfControlOverrideExist = (0, checkSystemForRiskControlOverride_1.checkSystemForRiskControlOverride)(system.riskControlOverrides, riskControl) !== false;
    const isCostOfControlOverrideEnabled = indexOfRiskControlOverride !== -1 &&
        (0, isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled)(system.riskControlOverrides?.[indexOfRiskControlOverride], "costOfControl");
    const overrideValue = doesCostOfControlOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        system.riskControlOverrides[indexOfRiskControlOverride]?.costOfControl;
    const getCostOfControlValue = () => {
        if (isCostOfControlOverrideEnabled) {
            if (overrideValue && !isNaN(overrideValue)) {
                return overrideValue;
            }
            else {
                return 0.0;
            }
        }
        else {
            return riskControl?.costOfControl ?? 0;
        }
    };
    const costOfControl = getCostOfControlValue();
    return costOfControl;
};
exports.getCostOfControl = getCostOfControl;
