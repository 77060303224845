import React, { useEffect, useState } from "react";
import { FormFeedback, Input } from "reactstrap";

/**
 * Allows the user to input a URL
 * @param {function} onChange - set the value of the input
 * @param {string} value - the value of the input
 * @param {string} fieldName - the name of the input
 * @param {boolean} required - whether the input is required
 * @param {function} onInputIsValidChange - called when the field changes status from valid to invalid or vice versa
 * @returns {JSX.Element}
 */
const CustomURLInput = ({ onChange, value = "", fieldName, required, onInputIsValidChange }) => {
  const [validURL, setValidURL] = useState(true);

  useEffect(() => {
    // Regex Reference: https://stackoverflow.com/a/25596644/7393636
    const urlRegex = new RegExp(
      "^(http[s]?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
    );
    const results = urlRegex.test(value);

    if (results !== validURL && value !== "") {
      onInputIsValidChange({ isValid: results });
      setValidURL(results);
    }
  }, [value]);

  return (
    <div data-testid={`custom-url-input-${fieldName}`}>
      <Input
        type="url"
        id={`url-input-${fieldName}`}
        value={value}
        valid={validURL === true}
        invalid={validURL === false}
        onChange={(data) => onChange(data && data.target && data.target.value)}
        required={required}
      />
      <FormFeedback>
        Looks like there is an issue with your URL. Please input a common URl format. Example:
        https://www.rivialsecurity.com/api
      </FormFeedback>
    </div>
  );
};

export default CustomURLInput;
