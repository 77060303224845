import { useObservationDataGrid } from "../../../../views/Program/Observations/hooks/useObservationDataGrid";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

export const observationDefinition = {
  routes: {
    grid: "governance/observations",
    details: "governance/observations",
    helpCenter: "observations",
  },

  displayName: "Observation",
  icon: "simple-line-icons:eye",
  gridHook: useObservationDataGrid,

  schema: {
    indexNames: {
      byOwnerGroup: "observationsByOwnerGroup",
    },
  },

  // - field definitions
  fields: {
    name: {},
    description: {},
    module: {},
    source: {},
    isFinding: {},
    recommendations: {
      default: {
        nestedFields: `(limit: 100) {
          items {
            id
            recommendation {
              id
              name
              status
              rating
              actionItems (limit: 100) {
                items {
                  id
                  action {
                    id
                    name
                  }
                }
              }
            }
          }
        }`,
      },
    },
    formalResponse: {},
    createdAt: {},

    complianceControlID: {},
    complianceControl: {
      default: {
        nestedFields: `{
          id
          name
          statementNumber
        }`,
      },
    },

    riskControlID: {},
    riskControl: {
      default: {
        nestedFields: `{
          id
          name
          statementNumber
        }`,
      },
    },

    externalSourceID: {},
    externalSource: {
      default: {
        nestedFields: `{
          id
          name
          description
          date
          ownerGroup
        }`,
      },
    },

    evidenceID: {},
    evidence: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    vulnerabilityItems: {
      default: {
        nestedFields: `(limit: 1000){
          items {
            id
            name
          }
        }`,
      },
    },

    incidentID: {},
    incident: {
      default: {
        nestedFields: `{
          id
          status
          what
          when
          who
          where
          why
        }`,
      },
    },

    exerciseID: {},
    exercise: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    artifactID: {},
    artifact: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    auditID: {},
    audit: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    departmentID: {},
    department: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    assessmentID: {},
    assessment: {
      default: {
        nestedFields: `{ id name status scanner penTester type }`,
      },
    },

    meetingID: {},
    meeting: {
      default: {
        nestedFields: `{ id name }`,
      },
    },

    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    risk: {},
    needsDecision: {},
    decision: {},
    estimatedCompletionDate: {},
    status: {},
    insurancePolicyValidated: {},
  },
  views: {
    grid: {
      queryFields: [
        "name",
        "description",
        "module",
        "isFinding",
        "source",
        "externalSource",
        "formalResponse",
        "risk",
        "department",
        "decision",
        "needsDecision",
        "status",
        "insurancePolicyValidated",
        "estimatedCompletionDate",
        "tags",
      ],
    },
    details: {
      displayFields: [
        "name",
        "description",
        "status",
        "module",
        "externalSource",
        "isFinding",
        "createdAt",
        "formalResponse",
        "risk",
        "needsDecision",
        "decision",
        "estimatedCompletionDate",
        "tags",
      ],
      queryFields: [
        "name",
        "description",
        "module",
        "source",
        "isFinding",
        "recommendations",
        "createdAt",
        "complianceControlID",
        "riskControlID",
        "riskControl",
        "complianceControl",
        "externalSource",
        "externalSourceID",
        "formalResponse",
        "evidence",
        "evidenceID",
        "vulnerabilityItems",
        "assessment",
        "assessmentID",
        "incident",
        "incidentID",
        "exercise",
        "exerciseID",
        "artifact",
        "artifactID",
        "audit",
        "auditID",
        "meeting",
        "meetingID",
        "department",
        "departmentID",
        "notes",
        "risk",
        "needsDecision",
        "decision",
        "estimatedCompletionDate",
        "status",
        "insurancePolicyValidated",
        "tags",
      ],
    },
  },
};
