import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { usePageList } from "../../../../hooks/views/usePageList";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import Loader from "../../../../utils/LoadingComponents/Loader";
import CustomResourceEntryDataGrid from "../../../CustomResources/CustomResourceEntries/components/CustomResourceEntryDataGrid";
import { useToolCustomResourceTypes } from "../hooks/useToolCustomResourceTypes";

/**
 * Displays a PageList of CustomResourceTypes with CustomResourceEntry grids for a Tool
 * @param {object[]} pages - pagelist configuration. Performed outside of this component for better re-rendering
 * @param {string} organizationID - the organization ID
 * @param {boolean} isTemplate - whether the tool is a template
 * @returns {JSX.Element}
 * @constructor
 */
const ToolCustomResourcePageList = ({ organizationID, isTemplate, item }) => {
  const [pages, setPages] = useState([]);

  const toolCustomResourceTypes = useToolCustomResourceTypes({
    item,
    organizationID,
  });

  // generate pagelist config dynamically for CustomResourceTypes related to this tool
  useEffect(() => {
    const customResourceTypes = toolCustomResourceTypes?.data;

    if (Array.isArray(customResourceTypes)) {
      const pagelistPages = [];

      for (const customResourceType of customResourceTypes) {
        if (customResourceType?.name) {
          pagelistPages.push({
            title: customResourceType?.name,
            icon: "simple-line-icons:list",
            component: (
              <Box style={{ height: "70vh" }}>
                <CustomResourceEntryDataGrid
                  customResourceTypeID={customResourceType?.id}
                  organizationID={organizationID}
                  isTemplate={isTemplate}
                />
              </Box>
            ),
          });
        }
      }

      setPages(pagelistPages);
    }
  }, [JSON.stringify(toolCustomResourceTypes?.data)]);

  const pageList = usePageList({
    pages: pages,
  });

  // Set the active page to the first page if there are pages
  useEffect(() => {
    if (Array.isArray(pages) && pages.length > 0) {
      pageList.setActivePage(pages[0]);
    }
  }, [pages?.length]);

  if (toolCustomResourceTypes?.isLoading) {
    return <Loader />;
  } else if (Array.isArray(pages) && pages.length > 0) {
    return pageList.display;
  } else {
    return (
      <h6>
        There are no Custom Resources configured for this Integration. Go to the <strong>Configuration</strong> page and
        add Custom Resource Types to get started.
      </h6>
    );
  }
};

export default withOrganizationCheck(ToolCustomResourcePageList);
