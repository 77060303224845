import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useControlCategoryDetails } from "../hooks/useControlCategoryDetails";

/**
 * Details component for Risk Control Categories in RiskConfig. Includes routing
 * aka. RiskControlCategoryDetails
 * @param props
 * @returns {*}
 * @constructor
 */
const ControlCategoryDetails = (props) => useControlCategoryDetails(props).display;

export default withDetailsPage(ControlCategoryDetails, {
  title: "Risk Control Category Details",
});
