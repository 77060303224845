import { isArrayEqual } from "../../../utils/Functions/Array/isArrayEqual";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { updateMutation } from "../graphql/customDashboardGraphql";

/**
 * Saves a dashboard layout to the database.
 *
 * @param {string} layoutID - the ID of the layout to save
 * @param {object[]} elements - the elements to save to the layout
 * @param {string} dashboardID - the ID of the dashboard to save the layout to
 * @param {function} setItemQuery - the setItem function from the useQueryGetItem hook
 * @param {object[]} currentLayouts - the current layouts array from the dashboard
 * @returns {Promise<void>}
 */
export const saveDashboardLayout = async ({ layoutID, elements, dashboardID, setItemQuery, currentLayouts }) => {
  let updatedLayouts = [...currentLayouts];

  // update the selected layout with the new elements, then save the updated layouts array to the dashboard object
  updatedLayouts = updatedLayouts.map((l) => (l.id !== layoutID ? l : { ...l, elements: elements }));

  if (isArrayEqual(updatedLayouts, currentLayouts)) {
    // no changes, don't save
  } else {
    // use ItemMutation to update the CustomDashboard with the new layout
    const updatedItem = await ItemMutation(updateMutation, {
      id: dashboardID,
      layouts: [...updatedLayouts],
    });

    setItemQuery(updatedItem);
  }
};
