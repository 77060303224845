import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Display Vulnerability severity level component
 * @param {object} item - vulnerability
 * @param {object} props - all other props passed to generic edit field
 * @returns {JSX.Element}
 * @constructor
 */
export const VulnerabilitySeverityLevel = ({ item, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.VULNERABILITY;

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field="severityLevel"
      mutation={generateGraphql("Vulnerability").updateMutation}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: enumToDropdownData({ ENUM: VULNERABILITY_SEVERITY_LEVEL }),
      }}
      {...props}
    />
  );
};
