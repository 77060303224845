import PropTypes from "prop-types";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, precedenceTypes, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import OrganizationCheck from "../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";

/**
 *
 * Author: Anatoli Railean
 * Created At: 02/04/20
 *
 * Description: A Card Component.
 *              A form for adding a new Role.
 *
 * Edits:
 * 6/22/20 CR: Removed or commented out unused imports
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const AddSingleRoleBody = (props) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.ROLE;

  const context = useContext(OrganizationContext);

  const form = useForm({
    typename: "Role",
    organizationID: props.isAdminRole ? "Admin" : props.organizationID,
    module,
    resource,
    mutation: generateGraphql("Role", ["name", "precedence"]).createMutation,
    fieldConfig: {
      name: {
        label: "Role Name",
        tooltip: "Enter a name",
        required: true,
      },
      precedence: {
        inputType: "dropdown",
        label: "Role Type",
        tooltip: `Please select a role type`,
        required: true,
        dropdownConfig: {
          data: Object.entries(precedenceTypes)
            .filter(([type, value]) => value > context?.role?.precedence)
            .map(([type, value]) => {
              return {
                value: value,
                text: type,
              };
            }),
        },
      },
    },
    callback: (item) => props.getNewItem(item),
  });

  return <>{form.display}</>;
};

AddSingleRoleBody.propTypes = {
  organizationID: PropTypes.string,
};

const AddSingleRole = (props) => {
  return (
    <OrganizationCheck {...props}>
      <AddSingleRoleBody />
    </OrganizationCheck>
  );
};

export default withRouter(AddSingleRole);
