/**
 * Query for retrieving all organization's risk control overrides
 */
export const listSystemOverridesByOwnerGroup = /* GraphQL */ `
  query SystemsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        description
        riskControlOverrides {
          enabledFields
          riskControlId
          costOfControl
          strengthRating
          implementationRating
          implementationDetails
        }
      }
      nextToken
    }
  }
`;
