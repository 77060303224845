import { Alert } from "@mui/material";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./viewPDF.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * @description Displays a PDF document
 * @param {string} url - The URL of the PDF document
 * @returns {{numPages: unknown, setError: (value: (((prevState: boolean) => boolean) | boolean)) => void, display: JSX.Element, error: boolean}}
 */
export const useViewPDF = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const display = (
    <div className="container-document">
      {error ? (
        <Alert severity={"error"}>Failed to load license agreement, please contact support</Alert>
      ) : (
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => {
            setError(true);
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page scale={2} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
    </div>
  );

  return { display, numPages, error, setError };
};
