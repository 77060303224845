"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryParse = void 0;
/**
 * Small helper function that tries to JSON.parse a string and returns the original string if it fails.
 * @param {string|object|undefined} json - json to parse
 * @returns {string|object|undefined} - the parsed json or the original string if an error
 */
const tryParse = (json) => {
    try {
        if (typeof json === "object") {
            return json;
        }
        return JSON.parse(json);
    }
    catch (e) {
        return json;
    }
};
exports.tryParse = tryParse;
