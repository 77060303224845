import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import CreateRiskChangeLandingPage from "../components/CreateRiskChangeLandingPage";
import RiskChangeDetails from "../components/RiskChangeDetails";
import ChangeType from "../customFields/ChangeType";
import OverallEffectOfChange from "../customFields/OverallEffectOfChange";
import { deleteRiskChange } from "../functions/deleteRiskChange";

import { riskChangesByOwnerGroupForGrids } from "./useGroupedRiskChangeDataGrid/useGroupedRiskChangeDataGrid";

/**
 * Displays a grid of individual risk changes
 * @param {string} organizationID - the organization id for which to display the risk changes
 * @param {object} queryConfig - the query to use to get the risk changes
 * @param {object} gridConfig - any data grid props
 * @param {object[]} additionalFields - fields to merge with the default fields by name
 * @param {boolean} isLoading - if true show the loading bar in grid header
 * @param {function} resetFunction -
 * @param {string} height - css height constrain for the grid
 * @param {object} props - any additional props passed down to the data grid
 * @return {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: *, setData: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element, dashboardDisplay: JSX.Element, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: function(): void, selectedIDs: [], setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useRiskChangeDataGrid = ({
  organizationID,
  queryConfig = {},
  gridConfig = {},
  additionalFields = [],
  isLoading = false,
  resetFunction,
  height = "85vh",
  ...props
}) => {
  // [PERMISSION CONFIG]
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;
  const roleConfig = {
    module,
    resource,
    disableRoleChecking: false,
  };

  // [QUERY CONFIG]
  queryConfig = {
    query: riskChangesByOwnerGroupForGrids,
    variables: {
      ownerGroup: organizationID,
    },
    ...queryConfig,
  };

  // [CARD CONFIG
  const cardConfig = {
    title: "Real-Time Risk Changes",
    icon: "icon-graph",
    dashboardDisplay: true,
  };

  // [GRID CONFIG]
  const fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 2,
      },
    },
    {
      name: "reason",
      flex: 1,
      minWidth: 200,
    },
    {
      name: "date",
      inputType: GENERIC_FIELD_TYPES.DATE,
      width: 200,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "changeOwner",
      visible: false,
      width: 150,
    },
    {
      name: "type",
      component: <ChangeType />,
      visible: false,
      width: 100,
    },
    {
      name: "change",
      component: <OverallEffectOfChange />,
      width: 300,
    },
  ];
  mergeAdditionalFields({ additionalFields, fields });

  gridConfig = {
    typename: "Risk Change",
    fields,
    organizationID,
    isLoading,
    createResourceComponent: <CreateRiskChangeLandingPage organizationID={organizationID} disableTitle={true} />,
    updateMutation: updateRiskChange,
    deleteFunction: deleteRiskChange,
    options: ["details", "delete"],
    detailsComponent: <RiskChangeDetails tableDisplay={true} />,
    route: "#/risk/changes/",
    createResourceComponentWidth: "80vw",

    persistenceUUID: "c66875db-13af-4d48-9f93-83aba3de81f7",
    ...gridConfig,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...roleConfig,
    ...cardConfig,
    ...gridConfig,
  });

  return { ...grid };
};

const { listQuery: listRiskChanges, updateMutation: updateRiskChange } = generateGraphql("RiskChange", [
  "name",
  "date",
  "reason",
  "changeOwner",
  "userEmail",
  "type",
  "change",
]);
