import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateSource } from "../hooks/useCreateSource";

/**
 * Component instance of the useCreateSource hook
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateSource = (props) => {
  return useCreateSource(props).display;
};

export default withOrganizationCheck(CreateSource);
