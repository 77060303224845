import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { enumSortComparator } from "../../../../utils/Functions/enumSortComparator";
import CreateRecommendation from "../components/CreateRecommendation";
import RecommendationDetails from "../components/RecommendationDetails";
import Difficulty from "../customFields/Difficulty";
import Module from "../customFields/Module";
import Rating from "../customFields/Rating";
import Resolved from "../customFields/Resolved";
import { RecommendationStatus } from "../enums/RecommendationStatus";
import { createRecommendationTagLink } from "../functions/createRecommendationsTagLink";
import { deleteRecommendation } from "../functions/deleteRecommendation";

/**
 * @description A list of RecommendationItems
 * @param {string} organizationID - The ID of the organization to get the RecommendationItems for.
 * @param {object} queryConfig - overrides for grid query settings
 * @param {object} gridConfig - overrides for grid settings
 * @param {object} hiddenFields - columns to hide by default
 * @param {string} module - the module to use for the grid
 */
export const useRecommendationsDataGrid = ({
  organizationID,
  queryConfig: initQueryConfig = {},
  gridConfig = {},
  hiddenFields = [],
  module = modules.GOVERNANCE,
  ...props
}) => {
  const typename = "RecommendationItem";
  const resource = resources.RECOMMENDATION;

  initQueryConfig = {
    indexName: "recommendationsByOwnerGroup",
    variables: { ownerGroup: organizationID },
    limit: 1000,
    ...initQueryConfig,
  };

  const { updateMutation, queryConfig } = getResourceQueryData({
    fieldContext: fieldContexts.GRID,
    overrideQueryConfig: initQueryConfig,
    typename,
  });

  const fields = [
    {
      name: "name",
      width: 500,
      minWidth: 125,
    },
    {
      name: "status",
      component: <Resolved />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      type: "singleSelect",
      valueOptions: enumToDropdownData({
        ENUM: RecommendationStatus,
        textFieldName: "label",
      }),
      sortComparator: enumSortComparator(["Unresolved", "Wont Resolve", "Resolved"]),
      width: 200,
      minWidth: 125,
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "rating",
      component: <Rating />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      type: "singleSelect",
      valueOptions: [
        {
          value: "info",
          label: "Info",
        },
        {
          value: "low",
          label: "Low",
        },
        {
          value: "lowMedium",
          label: "Low-Medium",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "mediumHigh",
          label: "Medium-High",
        },
        {
          value: "high",
          label: "High",
        },
      ],
      width: 200,
      bulkEdit: true,
    },
    {
      name: "difficulty",
      component: <Difficulty />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      type: "singleSelect",
      valueOptions: [
        {
          value: "info",
          label: "Info",
        },
        {
          value: "low",
          label: "Low",
        },
        {
          value: "lowMedium",
          label: "Low-Medium",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "mediumHigh",
          label: "Medium-High",
        },
        {
          value: "high",
          label: "High",
        },
      ],
      width: 150,
      visible: false,
      bulkEdit: true,
    },
    {
      name: "module",
      component: <Module />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 150,
      visible: !hiddenFields?.includes("module"),
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createRecommendationTagLink,
      width: 200,
      bulkEdit: true,
    },
  ];

  const roleConfig = {
    module,
    resource,
  };

  const cardConfig = {
    title: "Recommendations",
    headerIcon: "icon-check",
    headerButtons: [],
  };

  gridConfig = {
    fields,
    typename,
    createResourceComponent: <CreateRecommendation module={module} />,
    options: ["details", "delete", "edit"],
    deleteFunction: deleteRecommendation,
    deleteOptions: {
      deleteActionItems: {
        label: "Delete Action Items",
        tooltip:
          "This will delete all associated Action Items along with this Recommendation. Action Items will not be deleted if they are associated with other Recommendations. ",
        inputType: "switch",
        defaultValue: true,
      },
      deleteObservations: {
        label: "Delete Observations",
        tooltip:
          "This will delete all associated Observations along with this Recommendation. Observations will not be deleted if they are associated with other Recommendations. ",
        inputType: "switch",
        defaultValue: false,
      },
    },
    detailsComponent: <RecommendationDetails tableDisplay={true} />,
    updateMutation,
    persistenceUUID: "2e319121-35e9-43a0-aaf5-81e85aa035d4",
    enablePersistence: true,
    organizationID,
    ...gridConfig,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
  });

  return {
    ...gridCard,
  };
};
