import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useSystemTemplateDetails } from "../hooks/useSystemTemplateDetails";

/**
 * ADMIN ONLY
 * Details page for a System Template item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateDetails = (props) => useSystemTemplateDetails(props).display;

export default withDetailsPage(SystemTemplateDetails, {
  title: "System Template Details",
});
