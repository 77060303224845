export const getRiskRecommendation_recommendationDetails = /* GraphQL */ `
  query GetRiskRecommendation($id: ID!) {
    getRiskRecommendation(id: $id) {
      id
      ownerGroup
      name
      resolved
      costChange
      roiChange
      implementationChange
      riskControlID
      residualChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        implementationRating
      }
    }
  }
`;

export const updateRiskRecommendation_recommendationList = /* GraphQL */ `
  mutation UpdateRiskRecommendation($input: UpdateRiskRecommendationInput!) {
    updateRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
      }
      system {
        id
        name
      }
    }
  }
`;
