import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Determines if a schema typename is valid to use with the Query Builder
 * @param {string} typename
 * @returns {boolean}
 */
export const isValidTypename = (typename) => {
  return typeof typename === "string" && !isNullOrUndefined(typename) && typename !== "";
};
