import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useEvidenceDetailsAccordion } from "../hooks/useEvidenceDetailsAccordion";

/**
 * Evidence Details component. Used for details page and grid details
 * @param props
 * @returns {*}
 * @constructor
 */
const EvidenceDetails = (props) => {
  // Handles the Welcome Tour for Evidence Details
  // useEvidenceDetailsTour();
  return useEvidenceDetailsAccordion(props).display;
};

export default withDetailsPage(EvidenceDetails);
