"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLossCurveValue = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Gets a ratingScale percentage value from the lossToleranceCurve.
 *
 * Uses the max value of the loss tolerance curve, multiplied by the rating percentage
 *
 * @param {object} ratingScale - organization ratingScale config object
 * @param {number} ratingScale.high
 * @param {number} ratingScale.mediumHigh
 * @param {number} ratingScale.medium
 * @param {number} ratingScale.lowMedium
 * @param {number} ratingScale.low
 * @param {object[]} lossToleranceCurve - organization loss tolerance curve, converted into x,y points
 * @param {number} lossToleranceCurve.x
 * @param {number} lossToleranceCurve.y
 * @param {"high" | "mediumHigh" | "medium" | "lowMedium" | "low"} rating - high-low camelCase rating to match against the ratingScale
 * @returns {number}
 */
const getLossCurveValue = ({ ratingScale, lossToleranceCurve, rating = "high" }) => {
    if (!(0, func_utils_1.isNullOrUndefined)(lossToleranceCurve) && Array.isArray(lossToleranceCurve) && !(0, func_utils_1.isNullOrUndefined)(ratingScale)) {
        const lossCurveValues = lossToleranceCurve.map((item) => item.x);
        const max = Math.max(...lossCurveValues);
        return max * ratingScale[rating];
    }
    else {
        (0, func_utils_1.eventLogger)("Could not calculate loss curve value, invalid lossToleranceCurve or ratingScale");
        return 0;
    }
};
exports.getLossCurveValue = getLossCurveValue;
