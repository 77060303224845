import { generateGraphql } from "@rivial-security/generategraphql";

import NotesV2 from "../../../../../utils/Notes/components/NotesV2";

/**
 * Displays all notes for a single artifact (labeled as "Comments" in the UI)
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifact - all available artifact information, including its notes
 * @returns {JSX.Element}
 */
const ArtifactComments = ({ organizationID, artifact }) => {
  const { updateMutation } = generateGraphql("Artifact", ["notes"], {
    notes: "{ id type ownerGroup author timeStamp content tag observationID}",
  });

  return (
    <NotesV2
      item={artifact}
      label={"Comments"}
      notes={artifact?.notes || []}
      tooltip="Space to add comments about the artifact, both by the submitter and the validator"
      organizationID={organizationID}
      mutation={updateMutation}
      disableRoleChecking={true}
      artifactID={artifact?.id}
      observationConnectionField={"artifactID"}
    />
  );
};
export default ArtifactComments;
