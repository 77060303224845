"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addMinutesToDateTime = void 0;
/**
 * @description Adds minutes to a date
 */
const addMinutesToDateTime = (date, minutes) => {
    if (!date || (date && isNaN(date.getTime())) || !minutes) {
        return null;
    }
    return new Date(date.getTime() + minutes * 60000);
};
exports.addMinutesToDateTime = addMinutesToDateTime;
