"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLowerLossAmount = void 0;
const getAvailability_1 = require("./getAvailability");
const getCValue_1 = require("./getCValue");
const getIValue_1 = require("./getIValue");
/**
 * Determines the Lower Loss Amount to feed into Monte Carlo calcs
 * @param system
 * @param systemRiskLink
 */
const getLowerLossAmount = (system, systemRiskLink) => {
    if (system) {
        const cia = systemRiskLink?.cia ? systemRiskLink.cia : {};
        const confidentialityIsEnabled = cia.confidentiality || false;
        const integrityIsEnabled = cia.integrity || false;
        const availabilityIsEnabled = cia.availability || false;
        const values = [];
        if (confidentialityIsEnabled) {
            const cValue = (0, getCValue_1.getCValue)(system);
            values.push(cValue);
        }
        if (integrityIsEnabled) {
            const iValue = (0, getIValue_1.getIValue)(system);
            values.push(iValue);
        }
        if (availabilityIsEnabled) {
            const aValue = (0, getAvailability_1.getAvailability)(system);
            values.push(aValue);
        }
        const max = Math.min(...values);
        return max;
    }
    throw new Error("No system given to calc getLowerLossAmount");
};
exports.getLowerLossAmount = getLowerLossAmount;
