import React from "react";

/**
 * Horizontal line separator for the login screen buttons
 * @return {JSX.Element}
 */
const LoginButtonSeparator = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          backgroundColor: "lightgray",
          height: "1px",
          flex: 1,
        }}
      />
      <p
        style={{
          margin: 0,
          paddingLeft: ".5em",
          paddingRight: ".5em",
          color: "#646872",
          fontWeight: 500,
          verticalAlign: "center",
        }}
      >
        Or
      </p>
      <div
        style={{
          backgroundColor: "lightgray",
          height: "1px",
          flex: 1,
        }}
      />
    </div>
  );
};

export default LoginButtonSeparator;
