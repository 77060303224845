import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "../../../../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import AutomationsButton from "../../../../../../OrganizationManager/Automations/components/AutomationsButton";
import FindingsReportButton from "../../../../../../Reports/FindingsReport/components/FindingsReportButton";
import SubControlNotes from "../../../../../Systems/components/SystemAccordion/ControlCategories/components/Controls/components/SubControlNotes";
import RiskControlChanges from "../components/RiskControlChanges";
import RiskControlEvidence from "../components/RiskControlEvidence";
import RiskControlObservations from "../components/RiskControlObservations";
import RiskControlSystems from "../components/RiskControlSystems";
import ControlEffectiveness from "../customFields/ControlEffectiveness";
import CostOfControl from "../customFields/CostOfControl";
import ImplementationRating from "../customFields/ImplementationRating";
import KeyControl from "../customFields/KeyControl";
import KeyPerformanceIndicators from "../customFields/KeyPerformanceIndicators";
import RiskControlVendorSubControl from "../customFields/RiskControlVendorSubControl";
import StrengthRating from "../customFields/StrengthRating";

/**
 * Displays details page for a single Risk Control
 * @param {string} itemId - the database id of the risk control to display
 * @param {string} organizationID - the currently selected organization
 * @param {object} queryConfig - the query settings for the details component
 * @param {object} config - remaining props passed down to the useDetailsCard hook
 */
export const useRiskControlDetails = ({ itemId, organizationID, queryConfig = {}, ...config }) => {
  // handles scenario where the itemId is passed in as a string with the system id separated by a pipe
  if (itemId.includes("|")) {
    itemId = itemId.split("|")[0];
  }
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const typename = "RiskControl";

  const fields = [
    "statementNumber",
    "name",
    "costOfControl",
    "strengthRating",
    "implementationRating",
    "controlEffectiveness",
    "outsourced",
    "keyControl",
    "vendorSubControl",
  ];

  const queryFields = [
    "statementNumber",
    "name",
    "costOfControl",
    "strengthRating",
    "implementationRating",
    "outsourced",
    "keyControl",
    "notes",
    "vendorSubControl",
  ];

  const fieldNameDictionary = {
    outsourced: "Control Effectiveness",
  };

  const nestedFields = {
    notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
    vendorSubControl: `{ id name statementNumber inPlace audited}`,
  };

  const customFields = [
    {
      field: "implementationRating",
      component: <ImplementationRating />,
    },
    {
      field: "strengthRating",
      component: <StrengthRating />,
    },
    {
      field: "costOfControl",
      component: <CostOfControl />,
    },
    {
      field: "outsourced", // stealing this unused field for now
      component: <ControlEffectiveness />,
    },
    {
      field: "keyControl",
      component: <KeyControl />,
    },
    {
      field: "vendorSubControl",
      component: <RiskControlVendorSubControl />,
    },
  ];

  const { getQuery, updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  queryConfig = {
    query: getQuery,
    itemId,
    ...queryConfig,
  };

  const detailsConfig = {
    fields,
    fieldNameDictionary,
    nestedFields,
    customFields,
    updateMutation,
    typename,
    tableDisplay: config?.tableDisplay,
  };

  const cardConfig = {
    header: "Risk Control Details",
  };

  const detailsCard = useDetailsCard({
    typename,
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: cardConfig,
    organizationID,
    ...config,
  });

  const style = { height: "100%" };

  const accordion = useAccordion({
    items: [
      {
        title: "Evidence",
        subTitle: "Evidence items that are used to determine control Implementation Rating",
        icon: "icon-badge",
        component: <RiskControlEvidence item={detailsCard?.item} />,
        defaultExpanded: true,
      },
      {
        title: "Observations",
        subTitle: "View important findings and observations",
        icon: "icon-eye",
        component: <RiskControlObservations item={detailsCard?.item} resetFunction={detailsCard?.resetFunction} />,
        defaultExpanded: true,
      },
      {
        title: "Systems",
        subTitle: "Non-standard control settings per system",
        icon: "icon-screen-desktop",
        component: <RiskControlSystems riskControl={detailsCard?.item} />,
      },
      {
        title: "Key Performance Indicators",
        subTitle: "Performance thresholds rolled up from Associated Evidence",
        icon: "icon-chart",
        component: <KeyPerformanceIndicators riskControl={detailsCard?.item} />,
      },
      {
        title: "Grouped Changes",
        subTitle: "View the overall effect of changes to this Risk Control",
        icon: "icon-graph",
        component: <RiskControlChanges item={detailsCard?.item} />,
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={detailsCard?.reset}
      headerButtons={[
        <FindingsReportButton riskControlID={itemId} />,
        <AutomationsButton itemId={itemId} typename={"RiskControl"} />,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <DashboardCard title={"General Information"} style={style} icon={"icon-list"}>
                {detailsCard.tableDisplay}
              </DashboardCard>
            </Grid>
            <Grid item lg={12} xs={12}>
              <DashboardCard title={"Notes"} style={style} icon={"icon-note"}>
                <SubControlNotes item={detailsCard?.item} disableTitle={true} />
              </DashboardCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsCard,
    display: config?.tableDisplay ? detailsCard.tableDisplay : display,
  };
};
