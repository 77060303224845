"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.not = void 0;
/**
 * Filters the first array of values to remove any instances that exist in the second array.
 *
 * @param a
 * @param b
 * @returns {*}
 */
const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
};
exports.not = not;
