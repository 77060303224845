"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTable = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 *
 * @param fields
 * @param rows
 * @param customFields
 * @param fieldNameDictionary
 * @param items
 * @param headerConfig
 * @param rowConfig
 * @param columnConfig
 * @param tableConfig
 * @param stylingConfig contains styles as 2D array of objects that match the position of the
 * cell in the table. headerStyles is a 1D array of objects.
 * You can pass in fontStyle and/or fontColor, as well as tell the styles to repeat if it goes through them all.
 * @param isPortrait
 * @return {object} {{tableFormat: {shading: {backgroundColor: string, foregroundColor: string, textureStyle: string}, preferredWidth: number, leftMargin: number, bidi: boolean, borders: {diagonalDown: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, horizontal: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, top: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, left: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, bottom: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, vertical: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, right: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, diagonalUp: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}}, leftIndent: number, allowAutoFit: boolean, preferredWidthType: string, cellSpacing: number, topMargin: number, rightMargin: number, bottomMargin: number, tableAlignment: string}, grid: number[], description: null, rows: [{cells: [], rowFormat: {allowBreakAcrossPages: boolean, gridBeforeWidthType: string, gridAfter: number, gridAfterWidth: number, heightType: string, borders: {diagonalDown: {}, horizontal: {}, top: {}, left: {}, bottom: {}, vertical: {}, right: {}, diagonalUp: {}}, isHeader: boolean, leftIndent: number, gridAfterWidthType: string, gridBefore: number, gridBeforeWidth: number, height: number}}], title: null}}
 */
const createTable = ({ fields = [], rows, customFields = [], fieldNameDictionary = {}, items = [], headerConfig = {
    textAlignment: "Center",
    styleName: "Normal",
    bold: false,
    horizontalBorders: true,
    noHeader: false,
}, rowConfig = {
    textAlignment: "Left",
    styleName: "Normal",
    bold: false,
    horizontalBorders: true,
    spanRowsWithTheSameContent: false,
}, columnConfig: initColumnConfig = {
    columnWidths: -1, //Defines custom column widths (-1 when need to use defaults)
}, tableConfig = {
    lineWidth: 0.5,
    cellWidth: 160,
}, stylingConfig = { headerStyles: [], styles: [], repeat: false }, isPortrait = true, width, }) => {
    let tableConfigs = {
        lineWidth: 0.5,
        cellWidth: 160,
        ...tableConfig,
    };
    const columnConfig = parseInitColumnConfig({ initColumnConfig, fields, isPortrait });
    const rowWithHeaders = [];
    if (!headerConfig?.noHeader) {
        rowWithHeaders.push(createHeaderRow(fields, headerConfig, columnConfig, tableConfigs, fieldNameDictionary, stylingConfig?.headerStyles ? stylingConfig.headerStyles : []));
    }
    const res = {
        rows: rowWithHeaders,
        grid: [65, 293, 118],
        tableFormat: {
            borders: {
                top: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
                left: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
                right: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
                bottom: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
                diagonalDown: {
                    color: "#000000",
                    hasNoneStyle: false,
                    lineStyle: "None",
                    lineWidth: 0,
                    shadow: false,
                    space: 0,
                },
                diagonalUp: {
                    color: "#000000",
                    hasNoneStyle: false,
                    lineStyle: "None",
                    lineWidth: 0,
                    shadow: false,
                    space: 0,
                },
                horizontal: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
                vertical: {
                    color: "#8EAADBFF",
                    hasNoneStyle: false,
                    lineStyle: "Single",
                    lineWidth: 0.5,
                    shadow: false,
                    space: 0,
                },
            },
            shading: {
                backgroundColor: "#FFFFFFFF",
                foregroundColor: "empty",
                textureStyle: "TextureNone",
            },
            cellSpacing: 0,
            leftIndent: 0,
            tableAlignment: "Left",
            topMargin: 2.5,
            rightMargin: 5,
            leftMargin: 5,
            bottomMargin: 2.5,
            preferredWidth: width || (isPortrait ? 475.5 : 660),
            preferredWidthType: "Point",
            bidi: false,
            allowAutoFit: false, //if false the table widths look the same in document editor as they will in word
        },
        description: null,
        title: null,
    };
    /**
     * Form cell span data based on configuration options
     */
    const spanInfo = [];
    let columnCount = 0;
    //Add a span setting for every cell by iterating column data
    for (const field of fields) {
        spanInfo.push([]);
        let rowCount = 0;
        let spanRemaining = 0; // tracks how much of next cells in column to not show
        for (const item of items) {
            if (rowConfig?.spanRowsWithTheSameContent) {
                if (spanRemaining !== 0) {
                    //span is zero for cells that need not be shown
                    spanInfo[columnCount].push(0);
                    spanRemaining--;
                }
                else {
                    //span is >=1 for normal cells with spanning enabled
                    let spanLength = 0;
                    const currentContent = item.hasOwnProperty(field) && item[field];
                    let offset = rowCount;
                    while (items.length > offset &&
                        items[offset].hasOwnProperty(field) &&
                        currentContent === items[offset][field]) {
                        spanLength++;
                        offset++;
                    }
                    spanInfo[columnCount].push(spanLength);
                    spanRemaining = spanLength - 1; // -1 needed to account for the current row
                }
                rowCount++;
            }
            else {
                //span is always 1 (normal) when spanRowsWithTheSameContent is false
                spanInfo[columnCount].push(1);
            }
        }
        columnCount++;
    }
    tableConfigs = { ...tableConfigs, spanInfo };
    /**
     * if rows passed then you need to create rows using createTableRow function
     */
    if (rows && Array.isArray(rows)) {
        res.rows = [...rows];
    }
    else {
        let stylingIndex = 0;
        let rowIndex = 0;
        for (const item of items) {
            if (stylingConfig &&
                stylingConfig.styles &&
                stylingConfig.styles.length === stylingIndex &&
                stylingConfig.repeat === true) {
                stylingIndex = 0;
            }
            res.rows.push(createRow(rowIndex, item, fields, customFields, rowConfig, columnConfig, tableConfigs, stylingConfig && stylingConfig.styles && stylingConfig.styles[stylingIndex]
                ? stylingConfig.styles[stylingIndex]
                : []));
            stylingIndex++;
            rowIndex++;
        }
    }
    return res;
};
exports.createTable = createTable;
/**
 * Creates sfdt text for a single table row
 * @param {number} rowIndex - the count (starting at 0) from the top down of this row in the full table
 * @param {object} data
 * @param {string[]} fields
 * @param {object} customFields
 * @param {object} rowConfig
 * @param {object} columnConfig
 * @param {object} tableConfig
 * @param {object} stylingConfig
 */
const createRow = (rowIndex, data, fields, customFields, rowConfig = {}, columnConfig = {}, tableConfig = {}, stylingConfig = []) => {
    const { textAlignment, styleName, bold, horizontalBorders } = rowConfig;
    const { columnWidths } = columnConfig;
    const { cellWidth, lineWidth } = tableConfig;
    const row = {
        cells: [],
        rowFormat: {
            height: 0,
            allowBreakAcrossPages: true,
            heightType: "Auto",
            isHeader: false,
            borders: {
                top: {},
                left: {},
                right: {},
                bottom: {},
                diagonalDown: {},
                diagonalUp: {},
                horizontal: {},
                vertical: {},
            },
            gridBefore: 0,
            gridBeforeWidth: 0,
            gridBeforeWidthType: "Point",
            gridAfter: 0,
            gridAfterWidth: 0,
            gridAfterWidthType: "Point",
            leftIndent: 0,
        },
    };
    const getCustomFieldFormat = (field, item) => {
        if (customFields) {
            const found = customFields.find((item) => item.field === field);
            if (found) {
                const config = found.config || {};
                switch (found.format) {
                    case "LocaleDate":
                        return new Date(item[field]).toLocaleString();
                    case "localeDateNoTime":
                        return new Date(item[field]).toLocaleDateString();
                    case "ConvertCamelCaseToSentence":
                        return (0, func_utils_1.convertCamelCaseToSentence)(!(0, func_utils_1.isNullOrUndefined)(item[field]) ? item[field] : "");
                    case "dollar":
                        return (0, func_utils_1.formattedDollar)(item[field]);
                    case "percent":
                        return (0, func_utils_1.formattedPercent)(item[field], config.toFixed);
                    case "function":
                        return found.function && found.function(item, item[field]);
                    case "sfdt":
                        return found.function && found.function(item, item[field]);
                    default:
                        return !(0, func_utils_1.isNullOrUndefined)(item[field]) ? item[field] : "";
                }
            }
        }
        return !(0, func_utils_1.isNullOrUndefined)(item[field]) ? item[field] : "";
    };
    const createCell = (field, data, currentCellStyling, currentCellWidth, span) => {
        const blocks = [];
        /**
         * Handle Custom Fields
         */
        const customFieldFound = customFields && customFields.find((item) => item.field === field);
        if (customFieldFound) {
            let formattedData = getCustomFieldFormat(field, data);
            if (formattedData && typeof formattedData === "string") {
                formattedData = [formattedData];
            }
            /**
             * Check if field is a pure SFDT string
             */
            if (customFieldFound?.format === "sfdt" && formattedData && typeof formattedData === "object") {
                blocks.push(formattedData);
            }
            else {
                try {
                    (0, func_utils_1.checkArguments)({ formattedData }, {
                        formattedData: { type: "string", isArray: true, deepTypeCheck: true },
                    });
                }
                catch (e) {
                    (0, func_utils_1.eventLogger)("Failed to get formatted text for a table cell.");
                    formattedData = [""];
                }
                for (const item of formattedData) {
                    blocks.push({
                        paragraphFormat: {
                            textAlignment: currentCellStyling.textAlignment || textAlignment,
                            styleName: styleName,
                            listFormat: {},
                        },
                        characterFormat: {
                            bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                        },
                        inlines: [
                            {
                                characterFormat: {
                                    bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                                    bidi: false,
                                    fontColor: currentCellStyling.fontColor,
                                    fontSize: currentCellStyling.fontSize,
                                    fontSizeBidi: currentCellStyling.fontSize,
                                },
                                text: `${item}`,
                            },
                        ],
                    });
                }
            }
        }
        else if (data[field] && Array.isArray(data[field]) && data[field][0] && typeof data[field][0] === "string") {
            /**
             * Handle Array field
             */
            /**
             * Handle array of strings
             */
            for (const lineData of data[field]) {
                blocks.push({
                    paragraphFormat: {
                        textAlignment: currentCellStyling.textAlignment || textAlignment,
                        styleName: styleName,
                        listFormat: {},
                    },
                    characterFormat: {
                        bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                    },
                    inlines: [
                        {
                            characterFormat: {
                                bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                                bidi: false,
                                fontColor: currentCellStyling.fontColor,
                                fontSize: currentCellStyling.fontSize,
                                fontSizeBidi: currentCellStyling.fontSize,
                            },
                            text: lineData,
                        },
                    ],
                });
            }
        }
        else if (data[field] && Array.isArray(data[field]) && data[field].length > 0) {
            /**
             * Handle Array field
             */
            const table = (0, exports.createTable)({
                fields: (0, func_utils_1.getPropertiesFromObject)(data[field][0]),
                items: data[field],
            });
            blocks.push(table);
        }
        else if (data[field] && data[field].items && Array.isArray(data[field].items)) {
            /**
             * Handle 'items' field array
             */
            if (data[field].items.length > 0) {
                const table = (0, exports.createTable)({
                    fields: (0, func_utils_1.getPropertiesFromObject)(data[field].items[0]),
                    items: data[field].items,
                    width: 150,
                });
                blocks.push(table);
            }
        }
        else if (data[field] && typeof data[field] === "object") {
            /**
             * Handle nested Object field
             */
            if (!(0, func_utils_1.isNullOrUndefined)(data[field])) {
                const table = (0, exports.createTable)({
                    fields: (0, func_utils_1.getPropertiesFromObject)(data[field]),
                    items: [data[field]],
                });
                blocks.push(table);
            }
        }
        else {
            /**
             * Handle scalar field
             */
            blocks.push({
                paragraphFormat: {
                    textAlignment: currentCellStyling.textAlignment || textAlignment,
                    styleName: styleName,
                    listFormat: {},
                },
                characterFormat: {
                    bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                },
                inlines: [
                    {
                        characterFormat: {
                            bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                            bidi: false,
                            fontColor: currentCellStyling.fontColor,
                            fontSize: currentCellStyling.fontSize,
                            fontSizeBidi: currentCellStyling.fontSize,
                        },
                        text: `${getCustomFieldFormat(field, data)}`,
                    },
                ],
            });
        }
        return {
            blocks: [...blocks],
            cellFormat: {
                borders: {
                    top: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: lineWidth,
                    },
                    left: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: typeof horizontalBorders === "undefined" || horizontalBorders === true ? lineWidth : 0,
                    },
                    right: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: typeof horizontalBorders === "undefined" || horizontalBorders === true ? lineWidth : 0,
                    },
                    bottom: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: lineWidth,
                    },
                    diagonalDown: {},
                    diagonalUp: {},
                    horizontal: {},
                    vertical: {},
                },
                shading: {},
                preferredWidth: currentCellWidth || 156,
                preferredWidthType: "Point",
                cellWidth: currentCellWidth || 156,
                columnSpan: 1,
                rowSpan: span !== null && span !== undefined ? span : 1,
                verticalAlignment: currentCellStyling.verticalAlignment || "Top",
            },
            columnIndex: columnIndex,
        };
    };
    let columnIndex = 0;
    for (const field of fields) {
        const currentCellWidth = getCurrentCellWidth(cellWidth, columnWidths, columnIndex);
        const currentCellStyling = getCurrentCellStyling({ cellStyles: stylingConfig, columnConfig, columnIndex });
        const span = tableConfig?.spanInfo &&
            Array.isArray(tableConfig.spanInfo) &&
            tableConfig.spanInfo.length > columnIndex &&
            Array.isArray(tableConfig.spanInfo[columnIndex]) &&
            tableConfig.spanInfo[columnIndex].length > rowIndex &&
            tableConfig.spanInfo[columnIndex][rowIndex] !== null &&
            tableConfig.spanInfo[columnIndex][rowIndex] !== undefined
            ? tableConfig.spanInfo[columnIndex][rowIndex]
            : 1;
        if (span !== 0) {
            row.cells.push(createCell(field, data, currentCellStyling, currentCellWidth, span));
        }
        columnIndex++;
    }
    return row;
};
const createHeaderRow = (fields, headerConfig = {}, columnConfig = {}, tableConfig = {}, fieldNameDictionary = {}, stylingConfig = []) => {
    const { textAlignment, styleName, bold, horizontalBorders } = headerConfig;
    const { columnWidths } = columnConfig;
    const { cellWidth, lineWidth } = tableConfig;
    const headerRow = {
        cells: [],
        rowFormat: {
            height: 0,
            allowBreakAcrossPages: true,
            heightType: "Auto",
            isHeader: true,
            borders: {
                top: {},
                left: {},
                right: {},
                bottom: {},
                diagonalDown: {},
                diagonalUp: {},
                horizontal: {},
                vertical: {},
            },
            gridBefore: 0,
            gridBeforeWidth: 0,
            gridBeforeWidthType: "Point",
            gridAfter: 0,
            gridAfterWidth: 0,
            gridAfterWidthType: "Point",
            leftIndent: 0,
        },
    };
    let columnIndex = 0;
    for (const field of fields) {
        const currentCellWidth = getCurrentCellWidth(cellWidth, columnWidths, columnIndex);
        const currentCellStyling = getCurrentCellStyling({ cellStyles: stylingConfig, columnConfig, columnIndex });
        headerRow.cells.push({
            blocks: [
                {
                    paragraphFormat: {
                        textAlignment: currentCellStyling.textAlignment || textAlignment,
                        styleName: styleName,
                        listFormat: {},
                    },
                    characterFormat: {
                        bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                    },
                    inlines: [
                        {
                            characterFormat: {
                                bold: !(0, func_utils_1.isNullOrUndefined)(currentCellStyling?.bold) ? currentCellStyling?.bold : bold,
                                bidi: false,
                                fontColor: currentCellStyling.fontColor,
                                fontSize: currentCellStyling.fontSize,
                            },
                            text: `${fieldNameDictionary && fieldNameDictionary[field]
                                ? fieldNameDictionary[field]
                                : (0, func_utils_1.convertCamelCaseToSentence)(field)}`,
                        },
                    ],
                },
            ],
            cellFormat: {
                borders: {
                    top: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: typeof horizontalBorders === "undefined" || horizontalBorders === true ? lineWidth : 0,
                    },
                    left: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: typeof horizontalBorders === "undefined" || horizontalBorders === true ? lineWidth : 0,
                    },
                    right: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: typeof horizontalBorders === "undefined" || horizontalBorders === true ? lineWidth : 0,
                    },
                    bottom: {
                        color: "#000000",
                        lineStyle: "Single",
                        lineWidth: lineWidth,
                    },
                    diagonalDown: {},
                    diagonalUp: {},
                    horizontal: {},
                    vertical: {},
                },
                shading: {},
                preferredWidth: currentCellWidth || 156,
                preferredWidthType: "Point",
                cellWidth: currentCellWidth || 156,
                columnSpan: 1,
                rowSpan: 1,
                verticalAlignment: "Top",
            },
            columnIndex: columnIndex,
        });
        columnIndex++;
    }
    return headerRow;
};
/**
 * Calculates the cell width for a particular cell in the tabl
 * @param tableCellWidth - if provided will be used as the default cell width
 * @param columnCellWidth - if provided will overide the default cell width
 * @param columnIndex - the current table column index
 */
const getCurrentCellWidth = (tableCellWidth, columnCellWidth, columnIndex) => {
    let cellWidth = tableCellWidth || 156;
    if (columnCellWidth &&
        columnCellWidth !== -1 &&
        columnCellWidth.length &&
        columnIndex < columnCellWidth.length &&
        columnCellWidth[columnIndex] !== -1) {
        cellWidth = columnCellWidth[columnIndex];
    }
    return cellWidth;
};
const getCurrentCellStyling = ({ cellStyles, columnConfig, columnIndex } = {}) => {
    const stylingObject = { fontColor: "#000000", fontSize: "11" };
    if (cellStyles && cellStyles[columnIndex] && cellStyles[columnIndex].fontColor) {
        stylingObject.fontColor = cellStyles[columnIndex].fontColor;
    }
    else if (Array.isArray(columnConfig?.fontColor) &&
        columnConfig?.fontColor.length > columnIndex &&
        !(0, func_utils_1.isNullOrUndefined)(columnConfig?.fontColor[columnIndex])) {
        stylingObject.fontColor = columnConfig?.fontColor[columnIndex];
    }
    if (cellStyles && cellStyles[columnIndex] && !(0, func_utils_1.isNullOrUndefined)(cellStyles[columnIndex].fontSize)) {
        stylingObject.fontSize = cellStyles[columnIndex].fontSize;
    }
    if (cellStyles && cellStyles[columnIndex] && !(0, func_utils_1.isNullOrUndefined)(cellStyles[columnIndex].bold)) {
        stylingObject.bold = cellStyles[columnIndex].bold;
    }
    else if (Array.isArray(columnConfig?.bold) &&
        columnConfig?.bold.length > columnIndex &&
        !(0, func_utils_1.isNullOrUndefined)(columnConfig?.bold[columnIndex])) {
        stylingObject.bold = columnConfig?.bold[columnIndex];
    }
    if (cellStyles && cellStyles[columnIndex] && !(0, func_utils_1.isNullOrUndefined)(cellStyles[columnIndex].textAlignment)) {
        stylingObject.textAlignment = cellStyles[columnIndex].textAlignment;
    }
    if (cellStyles && cellStyles[columnIndex] && !(0, func_utils_1.isNullOrUndefined)(cellStyles[columnIndex].verticalAlignment)) {
        stylingObject.verticalAlignment = cellStyles[columnIndex].verticalAlignment;
    }
    return stylingObject;
};
const parseInitColumnConfig = ({ initColumnConfig, fields, isPortrait } = {}) => {
    if (!initColumnConfig || !Array.isArray(fields)) {
        return {};
    }
    //Width Calculations
    let totalWidthAvailable = 476;
    if (!isPortrait) {
        totalWidthAvailable = 641;
    }
    // - find the amount of table width remaining after defined sizes are subtracted
    let totalAutoSizeFieldsAmount = fields.length;
    let totalDefinedSizeWidth = 0;
    const finalColumnWidths = [];
    const columnWidths = initColumnConfig?.columnWidths;
    if (Array.isArray(columnWidths)) {
        for (const width of columnWidths) {
            if (width !== -1) {
                totalAutoSizeFieldsAmount--;
                totalDefinedSizeWidth += width;
            }
            finalColumnWidths.push(width);
        }
    }
    // - fill any remaining spots with -1
    for (let i = finalColumnWidths.length - 1; i < totalAutoSizeFieldsAmount; i++) {
        finalColumnWidths.push(-1);
    }
    // - assign the automatic size to table column
    const autoSizeColumnWidth = (totalWidthAvailable - totalDefinedSizeWidth) / totalAutoSizeFieldsAmount;
    for (let i = 0; i < finalColumnWidths.length; i++) {
        if (finalColumnWidths[i] === -1) {
            finalColumnWidths[i] = autoSizeColumnWidth;
        }
    }
    const finalColumnConfig = {
        ...initColumnConfig,
        columnWidths: finalColumnWidths,
    };
    return finalColumnConfig;
};
