"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTableCellLine = void 0;
/**
 * @description Create a new line for a table cell
 * @param {string} textAlignment
 * @param {string} styleName
 * @param {boolean} bold
 * @param {string} fontColor
 * @param {number} fontSize
 * @param {string} text
 * @param {string} singleStrike - pass "SingleStrike" for strikethrough effect
 * @param {object[]} inlines - pass an array of objects for inline text
 * @return {object}
 */
const createTableCellLine = ({ textAlignment = "Left", styleName = "Normal", bold = false, fontColor = "#000000", fontSize = 11, text = "", singleStrike = "None", inlines, }) => {
    return {
        paragraphFormat: {
            textAlignment,
            styleName,
            listFormat: {},
        },
        characterFormat: {
            bold,
        },
        inlines: inlines || [
            {
                characterFormat: {
                    bold,
                    bidi: false,
                    strikethrough: singleStrike,
                    fontColor,
                    fontSize,
                    fontSizeBidi: fontSize,
                },
                text,
            },
        ],
    };
};
exports.createTableCellLine = createTableCellLine;
