import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import MitreAttackDataSourceDetails from "../components/MitreAttackDataSourceDetails";

/**
 * @description Display list of Mitre Attack Data Sources hook
 * @param {string} organizationID - selected organization ID
 * @param {object[]} fields - fields to display
 * @param {object} props - other props for the hook
 */
export const useMitreAttackDataSourcesDataGrid = ({ organizationID, fields = [], ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.MITRE_ATTACK_DATA_SOURCE;

  return useDataGrid({
    module,
    resource,
    detailsTitle: "Data Source Details",
    detailsComponent: <MitreAttackDataSourceDetails />,
    title: "Mitre Att&ck Data Sources",
    headerIcon: "icon-magnifier",
    persistenceUUID: "c560e37c-358e-4441-98f4-3205443d5af9",
    fields,
    organizationID,
    ...props,
  });
};
