import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVulnerabilityDataGrid } from "../hooks/useVulnerabilityDataGrid";

/**
 * Component instance of the useVulnerabilityDataGrid hook
 * @param {object} props - props passed to the hook
 * @returns {JSX.Element}
 */
const VulnerabilityDataGrid = (props) => useVulnerabilityDataGrid(props).display;

export default withOrganizationCheck(VulnerabilityDataGrid);
