"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeObjectKey = void 0;
/**
 * Remove all occurrences of a key from an object
 * @param {object} obj - the object to remove the key from
 * @param {string} keyToRemove - the key name to remove
 */
const removeObjectKey = ({ obj, keyToRemove }) => {
    if (typeof obj !== "object" || obj === null) {
        // If the current value is not an object or is null, return it as is
        return obj;
    }
    if (Array.isArray(obj)) {
        // If the current value is an array, iterate over its elements
        return obj.map((item) => (0, exports.removeObjectKey)({ obj: item, keyToRemove }));
    }
    // If the current value is an object, create a new object to store non-removed properties
    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (key === keyToRemove) {
                // Skip the key to remove
                continue;
            }
            // Recursively call deepRemove on nested properties
            newObj[key] = (0, exports.removeObjectKey)({ obj: obj[key], keyToRemove });
        }
    }
    return newObj;
};
exports.removeObjectKey = removeObjectKey;
