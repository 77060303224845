import React from "react";

import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import TabCard from "../../../../utils/GenericComponents/TabCard";

import CustomWidgetSelectionTiles from "./CustomWidgetSelectionTiles";

/**
 * Displays a Tabbed Card to select a custom Bar Chart Widget
 * @param {string} organizationID - The organization ID
 * @param {function} onSelect - The function to call when a widget is selected
 * @param {boolean} isTemplate - Whether or not this is a template
 * @returns {JSX.Element}
 * @constructor
 */
const BarChartWidgetTabCard = ({ organizationID, onSelect, isTemplate }) => {
  return (
    <div>
      <TabCard
        items={[
          {
            icon: "icon-wrench",
            title: "Custom",
            component: isTemplate ? (
              <h6>Dashboard Templates currently cannot contain Custom Widgets</h6>
            ) : (
              <CustomWidgetSelectionTiles
                organizationID={organizationID}
                onSelect={onSelect}
                filteredWidgetType={WIDGET_TYPE.BAR_CHART}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default withOrganizationCheck(BarChartWidgetTabCard);
