import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes an Agenda Item from database
 * @param {object} agendaItem - the agenda item to delete, needs id field
 * @returns {Promise<void>}
 */

export const deleteAgendaItem = async (agendaItem) => {
  const deleteAgendaItem = /* GraphQL */ `
    mutation DeleteAgendaItem($input: DeleteAgendaItemInput!) {
      deleteAgendaItem(input: $input) {
        id
      }
    }
  `;

  await ItemMutation(deleteAgendaItem, {
    id: agendaItem.id,
  });
};
