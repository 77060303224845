import React, { useEffect, useState } from "react";
import { Button, Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";

/**
 * A collapsible menu container hidden within a settings icon button
 * @param {JSX.Element} buttons - the settings menu UI
 * @param {string} title - settings menu header text when opened
 * @param {object} [style] - the UI container style (position is absolute when this is null)
 * @param {object} closedStyle -  the UI container style when the menu is closed
 * @returns {{display: JSX.Element, openSettings: *}}
 */
export const useSettings = (buttons, title, style = {}, closedStyle) => {
  const [openSettings, setOpenSettings] = useState(false);

  const [currentStyle, setCurrentStyle] = useState(closedStyle || style);
  useEffect(() => {
    if (!openSettings && closedStyle) {
      setCurrentStyle(closedStyle);
    } else {
      setCurrentStyle(style);
    }
  }, [openSettings]);

  const display = (
    <Navbar
      style={{
        position: currentStyle.position || "absolute",
        top: currentStyle.top || -3,
        right: currentStyle.right || -15,
        zIndex: 100,
        marginBottom: currentStyle.marginBottom || undefined,
      }}
      color="faded"
      light
      className={currentStyle.className || undefined}
    >
      <NavbarToggler
        style={{ width: currentStyle.width || "200%", background: "white" }}
        onClick={() => setOpenSettings(!openSettings)}
        className="mr-2"
      >
        {!openSettings ? (
          currentStyle.settingsButton ? (
            React.cloneElement(currentStyle.settingsButton)
          ) : (
            <i className="icon-settings" />
          )
        ) : (
          <div title="Click to close settings">
            {title || "Settings"}
            <Button className="float-right" size="sm" close />
          </div>
        )}
      </NavbarToggler>
      <Collapse isOpen={openSettings} navbar>
        <Nav navbar>{buttons}</Nav>
      </Collapse>
    </Navbar>
  );

  return {
    display,
    openSettings,
  };
};
