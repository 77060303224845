"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskCurveUpperBound = void 0;
const getStandardLossAmountWithOverride_1 = require("./getStandardLossAmountWithOverride");
/**
 * Finds the system's highest risk upper risk bound value most simulations (90%+) will fall below this value for the system
 * @param {object} system - the system for which o get the upper bound, must have risks attached
 * @param {boolean} [applyPadding = false] - if TRUE will expand the upper bound by 1.2 times
 */
const getRiskCurveUpperBound = ({ system, applyPadding = false }) => {
    if (!system?.risks?.items) {
        console.log("Invalid system input into getRiskCurveUpperBound - ", JSON.stringify(system));
        return 0;
    }
    else {
        const riskLinks = system?.risks?.items;
        let largestMaximum = -1;
        if (Array.isArray(riskLinks)) {
            for (const riskLink of riskLinks) {
                if (riskLink) {
                    const currentUpperInterval = (0, getStandardLossAmountWithOverride_1.getStandardLossAmountWithOverride)(system, riskLink);
                    if (largestMaximum < currentUpperInterval) {
                        largestMaximum = currentUpperInterval;
                    }
                }
            }
        }
        // If found a value apply padding and return (floored to thousandth place)
        const maxUpdated = largestMaximum !== -1;
        if (maxUpdated) {
            if (applyPadding) {
                largestMaximum = largestMaximum * 1.2;
            }
            const xMax = Math.floor(largestMaximum / 1000) * 1000;
            return xMax;
        }
        else {
            return 0;
        }
    }
};
exports.getRiskCurveUpperBound = getRiskCurveUpperBound;
