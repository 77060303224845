import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useMeetingDetails } from "../hooks/useMeetingDetails";

/**
 * Author: Jacob Blazina
 * Created At:  11/7/19
 *
 * Description: Displays meeting details.
 *              This component automatically triggers the "Meeting Mode", which brings up a Meeting Overlay
 *              that allows the User to keep navigating around the app in the context of this meeting.
 */
const MeetingDetails = (props) => useMeetingDetails(props).display;

export default withDetailsPage(MeetingDetails);
