import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import DocumentDataGrid from "../../../../hooks/views/useDocumentGrid/components/DocumentDataGrid";
import UploadDocument from "../../../../hooks/views/useDocumentGrid/components/UploadDocument";
import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Documents for a VendorSolution
 * @param {object} item - the vendor solution to show documents for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const VendorSolutionDocuments = ({ item, organizationID }) => {
  const dataGrid = useNestedDataCard({
    item,
    typename: resources.DOCUMENT,
    parentTypename: resources.VENDOR_SOLUTION,
    field: "documents",
    connectionIDField: "vendorSolutionID",
    grid: <DocumentDataGrid module={modules.VENDORS} resource={resources.VENDOR_SOLUTION} />,
    form: <UploadDocument connectionInput={{ vendorSolutionID: item?.id }} />,
    gridHook: useDocumentDataGrid,
    organizationID,
  });

  return <div style={{ height: "30em" }}>{dataGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(VendorSolutionDocuments);
