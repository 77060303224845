/**
 * Gets a dashboard layout from a dashboard based on the layout index
 * @param {object} currentDashboard - The dashboard to get the layout from
 * @param {object[]} currentDashboard.layouts - The dashboard to get the layout from
 * @param {number} layoutIndex - The index of the layout to get
 * @returns {*}
 */
export const getCurrentDashboardLayout = ({ currentDashboard, layoutIndex = 0 }) => {
  if (!currentDashboard?.layouts?.[layoutIndex]) {
    return null;
  } else {
    return currentDashboard.layouts[layoutIndex];
  }
};
