import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { DETAILS_TYPES } from "../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { useModal } from "../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { ItemQuery } from "../../../../../utils/Functions/Graphql/ItemQuery";
import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import CreateObservation from "../../../../Program/Observations/components/CreateObservation";
import Recommendations from "../../../../Program/Observations/customFields/Recommendations";
import { useObservationDataGrid } from "../../../../Program/Observations/hooks/useObservationDataGrid";

/**
 * Displays Observations for a Control
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ControlObservations = (props) => {
  const module = modules.COMPLIANCE;
  const resource = resources.CONTROL;
  const field = "recommendations";

  const item = props.item ? props.item : props.control;

  const [observations, setObservations] = useState([]);

  useEffect(() => {
    if (item) {
      const { getQuery } = generateGraphql("Control", ["observations"], {
        observations: `(limit: 100) { items { id name recommendations (limit: 100) { items { id recommendation { id name status rating actionItems (limit: 100) { items { id action { id name status priority } } } } } } } }`,
      });

      ItemQuery(getQuery, item.id).then((control) => {
        setObservations(control?.observations?.items || []);
      });
    }
  }, [item]);

  const addObservationModal = useModal(
    "Add an Observation",
    <CreateObservation
      organizationID={props.organizationID}
      complianceControl={item}
      module={module}
      callback={props.resetFunction}
    />,
    <Button id="button-create-pointOfContact" size="sm" color="ghost-success" className="btn-pill float-right">
      <i className="icon-plus" /> Add an Observation
    </Button>,
  );

  const checkRolePermissions = useCheckPermissions({ module, resource, field });

  const observationGrid = useObservationDataGrid({
    organizationID: props.organizationID,
    queryFields: ["name", "recommendations"],
    queryConfig: {
      query: null,
    },
    gridConfig: {
      gridHeight: null,
      enableQuickDetails: true,
      detailsType: DETAILS_TYPES.NONE,
      enableMenu: false,
      persistenceUUID: "a718cff1-c10c-48f3-964d-76e96b89670b",
      enablePersistence: false,
      createResourceComponent: checkRolePermissions.field.update ? (
        <CreateObservation
          organizationID={props.organizationID}
          complianceControl={item}
          module={modules.COMPLIANCE}
          callback={props.resetFunction}
        />
      ) : undefined,
      fields: [
        {
          name: "name",
          friendlyName: "Observation",
          width: 100,
        },
        {
          name: "recommendations",
          component: <Recommendations module={module} />,
          disablePropagation: true,
        },
      ],
    },
    cardConfig: {
      isSticky: false,
      title: "Observations and Recommendations",
      headerButtons: checkRolePermissions.field.update && [addObservationModal.modalButton],
    },
  });

  useEffect(() => {
    if (!isNullOrUndefined(observations)) {
      observationGrid.setData(observations);
    }
  }, [observations]);

  return (
    <>
      <PermissionsOverlay module={module} resource={resource} field={field}>
        {props.gridDisplay ? observationGrid.gridDisplay : observationGrid.display}
      </PermissionsOverlay>
    </>
  );
};

ControlObservations.propTypes = {
  control: PropTypes.object, //deprecated. use `props.item` instead
  item: PropTypes.object.isRequired,
};

export default withOrganizationCheck(ControlObservations);
