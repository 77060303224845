import { Alert } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";

import { useDebounceSaveState } from "@hooks/functional/useDebounceSaveState";

import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import { taskType } from "../../constants/taskType";

import ChecklistTaskWork from "./ChecklistTaskWork";
import { taskWorkSubTitleType } from "./constants/taskWorkSubTitleType";
import { taskWorkTitleType } from "./constants/taskWorkTitleType";
import CustomTaskWork from "./CustomTaskWork";
import QuestionnaireTaskWork from "./QuestionnaireTaskWork";

/**
 * The part of task that changes with type, the main area users will work in
 * @param {object} taskInit - the initial state of the task
 * @param {function} updateTask - function to update task on the backend
 * @param {function} resetFunction - function to refresh data of 'taskInit' from backend
 * @param {boolean} enableSubmissions - if TRUE will allow user to submit submittable tasks (such as questionnaires)
 * @param {boolean} enableEditsSwitch - if TRUE will allow user to modify the data that is displayed in task work for task types that can be modified
 * @param {boolean} enableLandingPageLink - if TRUE will show a link to a landing page version of the task if the work needs to be completed there
 * @param {string} titleType - the data to use when creating the dashboard title for this task work
 * @param {string} subTitleType - the data to use when creating the dashboard subtitle for this task work
 * @param {function} [toggleModal] - function to show/hide containing modal (if any)
 * @returns {JSX.Element}
 */
export const TaskWork = ({
  task: taskInit,
  updateTask,
  resetFunction,
  enableSubmissions,
  enableEditsSwitch = true,
  enableLandingPageLink,
  titleType = taskWorkTitleType.TASK_TYPE,
  subTitleType = taskWorkSubTitleType.NONE,
  toggleModal,
}) => {
  const {
    state: task,
    setState: setTask,
    saveButton,
  } = useDebounceSaveState({
    state: taskInit,
    saveFunction: updateTask,
  });

  const [enableEdits, setEnableEdits] = useState(false);

  //Based on the type of task show the correct UI
  const type = task?.type;
  let displayComponent;
  switch (type) {
    case taskType.CUSTOM:
      displayComponent = <CustomTaskWork task={task} setTask={setTask} enableEdits={enableEdits} />;
      break;
    case taskType.CHECKLIST:
      displayComponent = <ChecklistTaskWork task={task} setTask={setTask} enableEdits={enableEdits} />;
      break;
    case taskType.QUESTIONNAIRE:
      displayComponent = (
        <QuestionnaireTaskWork
          task={task}
          resetFunction={resetFunction}
          enableSubmissions={enableSubmissions}
          enableLandingPageLink={enableLandingPageLink}
          toggleModal={toggleModal}
        />
      );
      break;
    default:
      displayComponent = <Alert severity="warning">This task type is not yet supported!</Alert>;
      break;
  }

  const getDashboardTitle = () => {
    if (titleType === taskWorkTitleType.NONE) {
      return null;
    }

    let taskTypeTitle;
    switch (type) {
      case taskType.CHECKLIST:
        taskTypeTitle = "Task Checklist";
        break;
      case taskType.QUESTIONNAIRE:
        taskTypeTitle = "Questionnaire Response";
        break;
      default:
        taskTypeTitle = "Task Work";
        break;
    }

    if (titleType === taskWorkTitleType.TASK_TYPE) {
      return taskTypeTitle;
    } else if (titleType === taskWorkTitleType.NESTED_NAME) {
      if (type === taskType.QUESTIONNAIRE) {
        return task?.questionnaireAssignee?.questionnaire?.name || taskTypeTitle;
      } else {
        return taskTypeTitle;
      }
    }
  };

  const getDashboardSubTitle = () => {
    if (subTitleType === taskWorkSubTitleType.NONE) {
      return null;
    }

    if (subTitleType === taskWorkSubTitleType.NESTED_DESCRIPTION) {
      if (task?.type === taskType.QUESTIONNAIRE) {
        return task?.questionnaireAssignee?.questionnaire?.description;
      }
    }
  };

  const editsSwitchShown = () => {
    if (type === taskType.QUESTIONNAIRE) {
      return false;
    }

    return enableEditsSwitch;
  };

  const saveButtonShown = () => {
    return type !== taskType.QUESTIONNAIRE;
  };

  return (
    <Dashboard
      title={getDashboardTitle()}
      subTitle={getDashboardSubTitle()}
      headerButtons={[
        ...(editsSwitchShown()
          ? [
              <FormControlLabel
                key="edits-switch"
                style={{ margin: 0 }}
                control={
                  <Switch
                    size="small"
                    onClick={() => setEnableEdits((enableEdits) => !enableEdits)}
                    checked={enableEdits}
                  />
                }
                label="Edits"
              />,
            ]
          : []),
        ...(saveButtonShown() ? [saveButton] : []),
      ]}
      sx={{
        headerButtonsContainer: {
          margin: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
      }}
    >
      {displayComponent}
    </Dashboard>
  );
};
