import { ReactElement } from "react";

import { useFileViewer } from "./useFileViewer";

export interface FileViewerProps {
  url: string;
  viewerHeight?: string;
}
/**
 * @description displaying file from url
 * @param input
 * @param input.url - the URL of the file to view
 * @param [input.viewerHeight] - the css height to use for the document viewer
 */
const FileViewer = ({ url, viewerHeight }: FileViewerProps): ReactElement => {
  const fileViewer = useFileViewer({ url, viewerHeight });

  return <>{fileViewer.display}</>;
};

export default FileViewer;
