import { modules, resources } from "@rivial-security/role-utils";

import { useCreateControlSet } from "../../Compliance/Controls/ControlSets/hooks/useCreateControlSet";

/**
 * Form for creating a new Control Template
 * @param {string} organizationID - the organization/operation in which to create the control framework template
 * @param {string} module=modules.ADMINISTRATOR  - platform module for role checking
 * @param {string} resource=resources.CONTROL_FRAMEWORK_TEMPLATE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {function} resetFunction - resets the parent grid
 * @param {function} getNewItem - callback for adding the item to the parent grid
 * @return {JSX.Element}
 */
const ControlFrameworkTemplateForm = ({
  organizationID,
  module = modules.ADMINISTRATOR,
  resource = resources.CONTROL_FRAMEWORK_TEMPLATE,
  disableRoleChecking = false,
  resetFunction,
  getNewItem,
}) => {
  const form = useCreateControlSet({
    organizationID,
    module,
    resource,
    disableRoleChecking,
    isTemplate: true,
    resetFunction,
    getNewItem,
  });

  return form.display;
};

export default ControlFrameworkTemplateForm;
