import React, { useContext, useState } from "react";
import { Button, Input, UncontrolledTooltip } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { onChangeAutoDismissDuration } from "../functions/onChangeAutoDismissDuration";

/**
 * @description Interface Notifications Component
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceNotifications = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const [autoDismissDuration, setAutoDismissDuration] = useState(
    preferences?.getPreference("interfaceOptions", "autoDismissDuration") || 15,
  );

  const card = useCard({
    id: "notifications",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Notifications</h4>{" "}
        <i id="notifications-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="notifications-icon-question">
          Specify time for notifications to be displayed
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <h6>Minimum On-Screen Duration (seconds)</h6>
        <Input type="number" value={autoDismissDuration} onChange={(e) => setAutoDismissDuration(e.target.value)} />
        <br />
        <Button
          color="ghost-success"
          className="btn-pill"
          size="sm"
          onClick={() =>
            onChangeAutoDismissDuration({
              preferences,
              autoDismissDuration,
              setAutoDismissDuration,
              addToast,
            })
          }
        >
          Save
        </Button>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceNotifications;
