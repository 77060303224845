import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Updates the Mutation input object before it's passed to the DB operation
 *
 * @param {object} mutationInput - the original mutation input object
 * @param {function} [updateMutationFunction] - a function that will update the mutation input object
 * @returns {object}
 */
export const handleUpdateMutationFunction = ({ mutationInput, updateMutationFunction }) => {
  let input = { ...mutationInput };

  if (!isNullOrUndefined(updateMutationFunction) && typeof updateMutationFunction === "function") {
    input = updateMutationFunction(mutationInput);
  }

  return input;
};
