import ArrowBack from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import React from "react";

import { ImporterStep } from "../hooks/useCsvImporter";

const BackButton = ({ workflow }) => {
  const isBackButtonEnabled = () => {
    const currentStep = workflow?.progressTracker?.currentStep?.id;
    const firstStep = workflow?.progressTracker?.steps?.[0]?.id;

    //Do not allow to go back from the results step
    if (currentStep === ImporterStep.FINISH_IMPORT) {
      return false;
    }

    return currentStep !== firstStep;
  };

  const onClickBack = () => {
    workflow.progressTracker.setCurrentStep(workflow.progressTracker.steps[0]);
  };

  return (
    <Button
      style={{ display: !isBackButtonEnabled() ? "none" : undefined }}
      variant="contained"
      startIcon={<ArrowBack />}
      onClick={onClickBack}
    >
      Back
    </Button>
  );
};

export default BackButton;
