import NoteAdd from "@mui/icons-material/NoteAdd";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Grid from "@mui/material/Grid";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { TEMPLATE } from "../../../../enums/TEMPLATE";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import GroupedRiskChangeDataGrid from "../../../Risk/RiskChanges/components/GroupedRiskChangeDataGrid";
import KeyPerformanceIndicators from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";
import { useArtifactsPendingValidation } from "../../Artifact/General/hooks/useArtifactsPendingValidation";
import EvidenceActivityCard from "../components/EvidenceActivityCard";
import EvidenceArtifacts from "../components/EvidenceArtifacts";
import EvidenceFindingsCard from "../components/EvidenceFindingsCard";
import EvidencePointOfContacts from "../components/EvidencePointOfContacts/EvidencePointOfContacts";
import TabbedControlsCard from "../components/TabbedControlsCard";
import { checkKPIEvidence } from "../functions/checkKPIEvidence";

import { useEvidenceGeneralDetails } from "./useEvidenceGeneralDetails";
import { useEvidenceLandingPageRoute } from "./useEvidenceLandingPageRoute";

/**
 * Displays information about a specific Evidence item
 * @param {Evidence} item - the evidence data from the database to display
 * @param {string} organizationID - the organization that is currently selected
 * @param {string} getUpdatedItem - updates the item in the grid by id
 * @param {function} updateItemById - updates the item in the grid by id
 * @param {object} props - additional props passed through into the useDetailsHook
 * @returns {{display: JSX.Element}}
 */
export const useEvidenceDetailsAccordion = ({ itemId, organizationID, getUpdatedItem, updateItemById, ...props }) => {
  const context = useContext(OrganizationContext);
  const [activityResetIndex, setActivityResetIndex] = useState(0);

  const originOrganizationID = context?.role?.ownerGroup;
  const isTemplate = organizationID === TEMPLATE || props?.location?.pathname.includes("admin_panel");
  const module = isTemplate ? modules.ADMINISTRATOR : modules.COMPLIANCE;
  const resource = isTemplate ? resources.EVIDENCE_TEMPLATE : resources.EVIDENCE;

  const togglePointOfContactModal = {
    toggle: () => {},
  };

  const details = useEvidenceGeneralDetails({
    itemId,
    setActivityResetIndex,
    module,
    resource,
    organizationID: isTemplate ? originOrganizationID : organizationID,
    getUpdatedItem,
    ...props,
  });

  const history = useHistory();
  const { route } = useEvidenceLandingPageRoute({
    evidence: details?.item,
  });

  const dashboardActions = [
    {
      name: "Assign Contact",
      icon: <PersonAdd />,
      onClick: () => togglePointOfContactModal.toggle(),
    },
    ...(route
      ? [
          {
            name: "Submit Evidence Artifact",
            icon: <NoteAdd />,
            onClick: () => history.push(route.replace(/#/g, "")),
          },
        ]
      : []),
  ];

  /**
   * Triggers a fake KPI change to recalculate if evidence is expired.
   * @param {object} kpi - the kpi object that has been added or removed to the evidence
   */
  const onKPIChangeCallback = async ({ kpi }) => {
    try {
      if (!kpi?.id) {
        throw Error("NO KPI id to trigger evidence check!");
      }
      await checkKPIEvidence({ triggerKPI: kpi, evidence: { id: itemId } });
    } catch (e) {
      ErrorLogger(e);
      ErrorLogger(`Unable to trigger a KPI change upon attaching it to an evidence. ${JSON.stringify(kpi)}`);
    }
  };

  const {
    artifactsPendingValidation,
    resetFunction: artifactResetFunction,
    isLoading: artifactIsLoading,
  } = useArtifactsPendingValidation({
    queryFunction: async () => {
      const { getQuery } = generateGraphql("Evidence", ["artifacts"], {
        artifacts: `(limit: 100) {
          items {
            __typename
            id
            name
            status
            createdAt
            evidenceActivity {
              type
            }
          }
        }`,
      });
      const evidence = await QueryGetItem({
        query: getQuery,
        itemId,
      });

      return evidence?.artifacts?.items;
    },
  });

  // Calls all individual information retrievals from in the details page
  const resetAll = () => {
    details?.reset();
    artifactResetFunction?.();
  };

  const checkArtifactPermissions = useCheckPermissions({
    module: modules.COMPLIANCE,
    resource: resources.ARTIFACT,
  });

  const accordion = useAccordion({
    details,
    items: [
      {
        id: "evidence-details-general-information",
        title: "General Information",
        component: details.display,
        icon: "icon-list",
        subTitle: "Name, Status, Frequency, Type, and Automations",
        defaultExpanded: true,
      },
      {
        id: "evidence-details-point-of-contacts",
        title: "Points of Contact",
        component: (
          <EvidencePointOfContacts
            toggleModalRef={togglePointOfContactModal}
            resetFunction={details?.resetFunction}
            setActivityResetIndex={setActivityResetIndex}
            updateItemById={updateItemById}
          />
        ),
        icon: "icon-user",
        subTitle: "Manage the people assigned to upload documentation",
      },
      {
        id: "evidence-details-controls",
        title: "Controls",
        component: <TabbedControlsCard item={details.item} resetFunction={details.reset} />,
        icon: "icon-shield",
        subTitle: "Adjust related Risk and Compliance Controls",
      },
      {
        title: "Artifacts",
        id: "evidence-details-uploads",
        component: <EvidenceArtifacts artifacts={artifactsPendingValidation} resetFunction={resetAll} />,
        forcedComponentProps: {
          resetFunction: resetAll,
          isLoading: details?.isLoading || artifactIsLoading,
        },
        ...(checkArtifactPermissions.resource.read && {
          badgeContent: artifactsPendingValidation?.length,
        }),
        icon: "icon-docs",
        subTitle: "Documents, Policies, Reports, Meetings, and Trainings",
      },
      {
        id: "evidence-details-findings",
        title: "Findings",
        component: <EvidenceFindingsCard module={module} />,
        icon: "icon-eye",
        subTitle: "Observations, Recommendations, and Action Items",
      },
      {
        id: "evidence-details-kpis",
        title: "Key Performance Indicators",
        component: (
          <KeyPerformanceIndicators
            organizationID={organizationID}
            module={module}
            resource={resource}
            evidence={details?.item}
            resetFunction={() => details?.resetFunction?.()}
            onLinkCallback={(kpi) => {
              onKPIChangeCallback({ kpi });
            }}
            onUnlinkCallback={(kpi) => {
              onKPIChangeCallback({ kpi });
            }}
          />
        ),
        icon: "icon-speedometer",
        subTitle: "Automate updates based on performance thresholds",
      },
      {
        title: "Risk Changes",
        subTitle: "Risk Changes caused by this Evidence",
        icon: "icon-graph",
        component: (
          <GroupedRiskChangeDataGrid
            tableDisplay={true}
            height={"50vh"}
            queryConfig={{
              normalizeData: (data) => data.filter((item) => item?.source?.evidenceID === itemId),
            }}
          />
        ),
      },
    ],
  });

  const display = (
    <Dashboard
      id="evidence-details-dashboard"
      title={!props.tableDisplay && "Evidence Details"}
      subTitle={details?.item?.name}
      resetFunction={resetAll}
      actions={dashboardActions}
      headerButtons={[<AutomationsButton itemId={itemId} typename={"Evidence"} />]}
    >
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {accordion.display}
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          {!isTemplate && (
            <EvidenceActivityCard
              item={details?.item}
              setItem={details?.setItem}
              resetIndex={details?.resetIndex + activityResetIndex}
              resetFunction={details?.reset}
              module={module}
              resource={resource}
            />
          )}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    display,
  };
};
