"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFunctionFieldName = void 0;
/**
 * A custom query function can have either a custom name or a default one in
 * the final query results. The default one is defined in the resource definition files
 * but a custom one takes precedence.
 * @param {object} pathElement - the part of the query from query builder that defines the function of which to get the name
 * @return {null|string} - the name of the function field in the custom query results
 */
const getFunctionFieldName = ({ pathElement }) => {
    if (typeof pathElement !== "object") {
        return null;
    }
    return pathElement?.config?.name || pathElement?.name;
};
exports.getFunctionFieldName = getFunctionFieldName;
