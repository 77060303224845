import { useTags } from "../hooks/useTags";

/**
 * @description Tags component
 * @param {object} props - props
 * @returns {*} - Tags component
 */
const Tags = (props) => {
  return useTags(props).display;
};

export default Tags;
