import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";

const alertTheme = createTheme({
  palette: {
    feature: {
      main: "#924edb",
      light: "#924edb",
      contrastText: "#fff",
    },
  },
});

const FeatureAlert = ({ header = "New Feature!", text, onClick, sx } = {}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ThemeProvider theme={alertTheme}>
      <Collapse in={isOpen} style={{ ...(sx?.collapse ?? {}) }}>
        <Alert
          style={{
            ...(typeof onClick === "function" && { cursor: "pointer" }),
          }}
          color={"feature"}
          icon={<Icon icon={"line-md:sunny-filled-loop"} width="22" height="22" />}
          onClick={onClick}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{header}</AlertTitle>
          {text}
        </Alert>
      </Collapse>
    </ThemeProvider>
  );
};

export default FeatureAlert;
