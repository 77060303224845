"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHtmlTableHeader = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Creates the html table header for an html table
 * @param {string[]} fields - the fields to show and in what order
 * @param {object} fieldConfig - field options
 * @param {object} fieldNameDictionary - dictionary of header names to use instead of the field name itself
 * @returns {string}
 */
const createHtmlTableHeader = ({ fields = [], fieldConfig = {}, fieldNameDictionary = {} }) => {
    let res = "";
    for (const field of fields) {
        let style = "border: 1px solid black;";
        const fieldWidth = fieldConfig?.[field]?.width;
        if (fieldWidth) {
            style += `width: ${fieldWidth};`;
        }
        const fieldName = fieldNameDictionary[field] || field;
        res = res.concat(`
        <th
          style="${style}"
          scope="col"
        >
          ${fieldName ? (0, func_utils_1.convertCamelCaseToSentence)(fieldName) : ""}
        </th>
      `);
    }
    return `
    <thead>
      <tr align="left">
        ${res}
      </tr>
    </thead>
  `;
};
exports.createHtmlTableHeader = createHtmlTableHeader;
