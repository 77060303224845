import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useAuditControls } from "../hooks/useAuditControlsV2";

/**
 * Displays Controls for an Audit
 * @param {object} item - the Audit item
 * @param {function} resetFunction - re-fetch parent data from the database
 * @param {string} organizationID - selected organization id
 * @param {function} optimisticUpdate - function used to update the top-level state of the audit optimistically
 * @param {object} item.controls - the Audit item 'controls' field
 * @param {object[]} item.controls.items - the AuditControlLinks
 * @param {object[]} item.controls.items[].control - the associated Control for an AuditControlLink
 * @param {object[]} item.controls.items[].isCompliant - isCompliant field for an AuditControlLink
 * @param {object[]} item.controls.items[].notes - notes field for an AuditControlLink
 * @constructor
 */
const AuditControls = (props) => {
  return useAuditControls(props).display;
};

export default withOrganizationCheck(AuditControls);
