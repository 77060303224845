/**
 * Handles the database operation for this update
 * @param {function} editItem - function that handles the database operation
 * @param {object} mutationInput - the mutation input for the database operation
 * @returns {Promise<*>}
 */
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const handleUpdate = async ({ editItem, mutationInput }) => {
  if (isNullOrUndefined(editItem)) {
    throw Error("editItem param cannot be null or undefined");
  }

  if (typeof editItem !== "function") {
    throw Error("editItem function param must be a function");
  }

  if (isNullOrUndefined(mutationInput)) {
    throw Error("mutationInput param cannot be null or undefined");
  }

  if (Array.isArray(mutationInput) || typeof mutationInput !== "object") {
    throw Error("mutationInput must be an object");
  }

  /**
   * Perform the database operation
   */
  return await editItem(mutationInput);
};
