"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLossToleranceHeightAtPoint = void 0;
/**
 * Calculates the value of the loss tolerance curve at the passed in x and y of the risk point.
 *
 * @param {object[]} lossToleranceCurve - the loss tolerance points (objects with x, y values) defined by the % of chance
 * an organization are willing to accept of a monetary loss of x or more
 * NOTE: the x values are expected to be sorted from lowest to highest and y values from highest to lowest
 * @param {object} riskPoint - a single curve point (object with x, y value)
 * @returns {object} - the loss tolerance curve value at the passed in x and y of the risk point
 */
const findLossToleranceHeightAtPoint = ({ lossToleranceCurve, riskPoint, }) => {
    const response = {
        lossToleranceProbabilityAtRiskValue: 0,
        lossToleranceRiskAtProbabilityValue: 0,
    };
    // Filter out any loss tolerance points that don't have an x and y value
    const lossTolerancePoints = lossToleranceCurve.filter(({ x, y }) => x !== null && x !== undefined && y !== undefined);
    // Check arguments
    if (!Array.isArray(lossTolerancePoints) ||
        lossTolerancePoints.length <= 1 ||
        riskPoint?.x === null ||
        riskPoint?.x === undefined ||
        riskPoint?.y === undefined) {
        return response;
    }
    // [Probability Calculation]
    // Find the loss tolerance points that encapsulate the risk point on the x-axis
    let lossToleranceLeftPoint;
    let lossToleranceRightPoint;
    for (let i = 0; i < lossTolerancePoints.length - 1; i++) {
        if (lossTolerancePoints[i].x <= riskPoint.x && lossTolerancePoints[i + 1].x >= riskPoint.x) {
            lossToleranceLeftPoint = lossTolerancePoints[i];
            lossToleranceRightPoint = lossTolerancePoints[i + 1];
        }
    }
    // Find the equation for the line between the two points if the loss tolerance points were identified
    if (lossToleranceLeftPoint && lossToleranceRightPoint) {
        let slope = 0;
        if (lossToleranceRightPoint.x - lossToleranceLeftPoint.x !== 0) {
            slope =
                (lossToleranceRightPoint.y - lossToleranceLeftPoint.y) / (lossToleranceRightPoint.x - lossToleranceLeftPoint.x);
        }
        const intercept = lossToleranceLeftPoint;
        // Find the loss tolerance value at the risk point
        response.lossToleranceProbabilityAtRiskValue = intercept.y + slope * (riskPoint.x - intercept.x);
    }
    // [Risk Calculation]
    // Find the loss tolerance points that encapsulate the risk point on the y-axis
    let lossToleranceTopPoint;
    let lossToleranceBottomPoint;
    for (let i = 0; i < lossTolerancePoints.length - 1; i++) {
        if (lossTolerancePoints[i].y >= riskPoint.y && lossTolerancePoints[i + 1].y <= riskPoint.y) {
            lossToleranceTopPoint = lossTolerancePoints[i];
            lossToleranceBottomPoint = lossTolerancePoints[i + 1];
        }
    }
    // Find the equation for the line between the two points if the loss tolerance points were identified
    if (lossToleranceTopPoint && lossToleranceBottomPoint) {
        let slope = 0;
        if (lossToleranceBottomPoint.y - lossToleranceTopPoint.y !== 0) {
            slope =
                (lossToleranceBottomPoint.x - lossToleranceTopPoint.x) / (lossToleranceBottomPoint.y - lossToleranceTopPoint.y);
        }
        const intercept = lossToleranceTopPoint;
        // Find the loss tolerance value at the risk point
        response.lossToleranceRiskAtProbabilityValue = intercept.x + slope * (riskPoint.y - intercept.y);
    }
    return response;
};
exports.findLossToleranceHeightAtPoint = findLossToleranceHeightAtPoint;
