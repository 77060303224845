"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateAbstractLists = void 0;
const listTypeLevels_1 = require("../constants/listTypeLevels");
const generateAbstractLists = (listTypes = ["bullet", "bullet", "bullet", "bullet"]) => {
    const abstractList = [];
    let count = 0;
    for (const listType of listTypes) {
        if (listType === "numeric") {
            abstractList.push({
                abstractListId: count,
                ...listTypeLevels_1.numericListTypeLevels,
            });
        }
        else {
            abstractList.push({
                abstractListId: count,
                ...listTypeLevels_1.bulletListTypeLevels,
            });
        }
        count++;
    }
    return abstractList;
};
exports.generateAbstractLists = generateAbstractLists;
