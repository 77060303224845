import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useVendorReview } from "../hooks/useVendorReview";

/**
 * Component instance of the useVendorReview hook
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VendorSolutionReview = (props) => {
  return useVendorReview(props).display;
};

export default withDetailsPage(VendorSolutionReview);
