import { useEffect, useState } from "react";

import ResetButton from "../../utils/GenericComponents/buttons/ResetButton";

/**
 * @description Custom hook around useEffect. Attaches a resetButton and resetFunction to easily re-run an effect on command
 * @param {function} effect - The effect to run
 * @param {function} func - The function to run when the reset button is clicked
 * @param {*[]} dep - The dependencies to run the effect on
 * @returns {[resetFunction, resetButton, setResetIndex, resetIndex,]} - The reset function and the reset button
 * @example const { resetFunction } = useResetEffect(handleData, [vulnerability]);
 */
export const useResetEffect = (func = () => {}, dep = []) => {
  const [resetIndex, setResetIndex] = useState(0);

  useEffect(func, [...dep, resetIndex]);

  const resetFunction = () => {
    setResetIndex((i) => i + 1);
  };

  const resetButton = <ResetButton resetFunction={() => resetFunction()} />;

  return {
    resetIndex,
    setResetIndex,
    resetButton,
    resetFunction,
  };
};
