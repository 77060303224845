import { useContext } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import isRestricted from "../../../../utils/AccessControl/functions/isRestricted";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { normalizeCustomFieldData } from "../functions/normalizeCustomFieldData";

/**
 * Handles custom field configurations for the data grid.
 * A custom field configuration is saved in some sort of parent object that defines field names and types.
 * Each resource of this type should then have a 'customFieldData' JSON field that holds corresponding data
 *
 * type CustomField {
 *   name: String
 *   type: CustomFieldType
 * }
 *
 * enum CustomFieldType {
 *   boolean
 *   number
 *   string
 *   object
 * }
 * @param {object[]} customFields - configuration object for custom fields
 * @param {string} customFields[].name - name of the custom field
 * @param {string} customFields[].type - type of the custom field
 */
export const useDataGridCustomFields = ({ customFields }) => {
  const { isAdmin, loggedInPointOfContactId, role } = useContext(OrganizationContext);

  /**
   * @description Check if the user has view access to an item
   * @param {object} item - database object with an accessControl field
   * @return {boolean}
   */
  const hasViewAccess = (item) => {
    const { accessControl } = item;

    // Admins always have access.
    if (isAdmin) {
      return true;
    }

    // If there's no accessControl on the item, it's accessible by default.
    if (!isRestricted(item)) {
      return true;
    }

    const { pointOfContacts, roles, password } = accessControl;
    const isPointOfContact = pointOfContacts?.includes(loggedInPointOfContactId);
    const hasRoleAccess = roles?.includes(role?.id);

    // If a password is set (and no roles or points of contacts), it's accessible.
    if (password && !isNonEmptyArray(roles) && !isNonEmptyArray(pointOfContacts)) {
      return true;
    }

    // If there are role or point of contact restrictions, check if the user matches one of them.
    if (hasRoleAccess || isPointOfContact) {
      return true;
    }

    // In all other cases, it's not accessible.
    return false;
  };

  const formatCustomFields = ({ data, queryCustomFieldData }) => {
    if (!isNonEmptyArray(customFields) || !isNonEmptyArray(data)) {
      return data;
    }

    const formattedData = [];
    for (const item of data) {
      if (!hasViewAccess(item)) {
        continue;
      }

      formattedData.push(
        normalizeCustomFieldData({
          item,
          customFields,
          queryCustomFieldData,
        }),
      );
    }

    return formattedData;
  };

  return {
    formatCustomFields,
  };
};
