import { ReactElement } from "react";

import Tooltip, { TooltipProps } from "@components/Atoms/Tooltip/Tooltip";
import { getIcon, IconName } from "@components/Ions/icons/components/Icons";

import styles from "./TooltipIcon.module.scss";

export type TooltipIconProps = TooltipProps;

/** Displays an icon with a tooltip when it is hovered over */
export const TooltipIcon = ({ children = getIcon(IconName.INFO), ...rest }: TooltipIconProps): ReactElement => {
  return (
    <Tooltip {...rest}>
      <button className={styles["tooltip-icon"]}>{children}</button>
    </Tooltip>
  );
};

export default TooltipIcon;
