export const listSystemTargetLinksBySystemID_vulnerabilities = /* GraphQL */ `
  query ListSystemTargetLinksBySystemId(
    $systemID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSystemTargetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemTargetLinksBySystemID(
      systemID: $systemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        target {
          id
          vulnerabilities {
            items {
              id
              vulnerability {
                id
                createdAt
                name
                type
                severityLevel
                ports
                protocols
                cves
                cvss
                pluginID
                impact
                solution
                solutionType
                summary
                exploitAvailable
                pluginID
                manualPriority
                targets(limit: 1000) {
                  items {
                    id
                    createdAt
                    status
                    priority
                    firstSeen
                    lastSeen
                    targetID
                    vulnerabilityID
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listSystemTargetLinksBySystemID_assessments = /* GraphQL */ `
  query ListSystemTargetLinksBySystemId(
    $systemID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSystemTargetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemTargetLinksBySystemID(
      systemID: $systemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        target {
          id
          assessments {
            items {
              id
              assessment {
                id
                name
                assessmentDate
                type
                pointOfContact {
                  id
                  firstName
                  lastName
                }
                status
                ownerGroup
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
