import { mitreAttackApiUrl } from "../../../../env-config";

/**
 * @description Get list of all Mitre Attack relationships for a source
 * @param {string} sourceRef - source reference id
 * @param {string} targetRef - target reference id
 * @param {string} relationshipType - the type of relationship to get
 * @param {boolean} includePagination - include pagination
 * @param {number} limit - query limit
 */
export const listMitreAttackRelationships = async ({
  sourceRef,
  targetRef,
  relationshipType,
  includePagination,
  limit,
}) => {
  if (!relationshipType) {
    throw new Error("relationshipType is required");
  }

  let params = "";

  if (sourceRef) params += `sourceRef=${sourceRef}`;
  if (targetRef) params += `targetRef=${targetRef}`;
  if (relationshipType) params += `&relationshipType=${relationshipType}`;
  if (includePagination) params += `&includePagination=${includePagination}`;
  if (limit) params += `&limit=${limit}`;

  return await fetch(`${mitreAttackApiUrl}/api/relationships?${params}`).then(async (response) => {
    return await response.json().then((data) => {
      return (
        Array.isArray(data) &&
        data.map((item) => {
          item["id"] = item?.source_object?.stix?.id;
          return item;
        })
      );
    });
  });
};
