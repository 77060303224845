import React from "react";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useDetails } from "../../../../../../../hooks/views/useDetails/hooks/useDetails";
import { GENERIC_FIELD_TYPES } from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import SystemAdmin from "../../../../customFields/SystemAdmin";
import SystemOwner from "../../../../customFields/SystemOwner";
import SystemNotes from "../components/SystemNotes";
import SystemHosting from "../customFields/SystemHosting";

/**
 * Displays general information for a System including name, description, hosting, and assigned points of contact
 */
export const useSystemOverview = ({
  updateItemById,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  fields = ["name", "description", "pointOfContact", "adminPointOfContact", "hosting"],
  disableRoleChecking = false,
  ...props
}) => {
  const typename = resources.INFORMATION_SYSTEM;

  const nestedFields = {
    pointOfContact: `{ id firstName lastName title }`,
    adminPointOfContact: `{ id firstName lastName title }`,
  };

  const fieldNameDictionary = {
    adminPointOfContact: "System Admin",
    pointOfContact: "System Owner",
  };

  const customFields = [
    {
      field: "hosting",
      component: <SystemHosting disableRoleChecking={disableRoleChecking} />,
    },
    {
      field: "pointOfContact",
      component: <SystemOwner />,
    },
    {
      field: "adminPointOfContact",
      component: <SystemAdmin />,
    },
    {
      field: "description",
      inputType: GENERIC_FIELD_TYPES.TEXT_AREA,
    },
  ];

  const table = useDetails({
    item: props?.system,
    typename,
    fields,
    nestedFields,
    fieldNameDictionary,
    customFields,
    module,
    resource,
    updateItemById,
    mutation: realTimeRiskGQL.updateSystem_minimal,
    disableRoleChecking,
  });

  const display = (
    <div>
      {table.display}
      <hr />
      <SystemNotes item={props.system} disableRoleChecking={disableRoleChecking} />
    </div>
  );

  return {
    ...table,
    display,
  };
};
