import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { GENERIC_FIELD_INPUTS } from "../constants/GENERIC_FIELD_TYPES";

/**
 * Determines whether to display the Input component or the Display component
 * @param context
 * @returns {JSX.Element}
 */
export const handleUI = (context) => {
  if (isNullOrUndefined(context)) {
    throw Error("function must be passed the GenericEditFieldContext as a parameter");
  }

  if (context?.toggleEdit === true) {
    return renderInputComponent(context);
  } else {
    return renderDisplayComponent(context);
  }
};

/**
 * Renders the Display component for the Generic Edit Field.
 * The displayed component is determined by the `inputType` field.
 * @param {string} inputType - one of the input types from GENERIC_FIELD_TYPES
 * @param {*} value - current value of the field
 * @param {JSX.Element} [customDisplayComponent] - if passed in, renders this as the display instead
 * @param {function} customFormat - if passed in, formats the value before displaying
 * @param {string} field - the name of the field
 * @param {object} item - the parent item itself
 * @param {string} typename - the database type of the parent item
 * @param {object} displayConfig - configurations to pass as props to the display component (e.g. 'forceSentenceCase: true')
 * @returns {JSX.Element}
 */
export const renderDisplayComponent = ({
  inputType,
  value,
  customDisplayComponent,
  customFormat,
  field,
  item,
  typename,
  displayConfig = {},
}) => {
  const displayComponent = !isNullOrUndefined(customDisplayComponent)
    ? React.cloneElement(customDisplayComponent, {
        item,
        [field]: value,
        field,
      })
    : GENERIC_FIELD_INPUTS[inputType]?.displayComponent;

  if (isNullOrUndefined(displayComponent)) {
    throw Error(`inputType: ${inputType} is not supported.`);
  }

  return React.cloneElement(displayComponent, {
    item,
    inputType,
    value,
    customFormat,
    typename,
    ...displayConfig,
  });
};

/**
 * Renders the Input component for the Generic Edit Field.
 * The displayed component is determined by the `inputType` field.
 * @param {string} inputType - one of the input types from GENERIC_FIELD_TYPES
 * @param {function} toggleEdit - toggles the edit state
 * @param {JSX.Element} [customInputComponent] - if passed in, renders this as the input instead
 * @returns {JSX.Element}
 */
export const renderInputComponent = ({
  inputType,
  value,
  setValue,
  customInputComponent,
  field,
  inputConfig,
  item,
  toggle,
}) => {
  const inputComponent = !isNullOrUndefined(customInputComponent)
    ? customInputComponent
    : GENERIC_FIELD_INPUTS[inputType]?.inputComponent;

  if (isNullOrUndefined(inputComponent)) {
    throw Error(`inputType: ${inputType} is not supported.`);
  }

  return React.cloneElement(inputComponent, {
    inputType,
    inputConfig,
    value,
    setValue,
    field,
    item,
    toggle,
  });
};
