import { removeDuplicateObjects } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { getMany } from "../../../../../../../utils/Functions/getMany";
import { ItemQuery } from "../../../../../../../utils/Functions/Graphql/ItemQuery";

/**
 * Fetches evidence that is related to a System by rolling them up from nested Risk Controls
 * @param props
 */
export const rollupEvidence = async ({ system }) => {
  const currentEvidence = [];

  if (system) {
    const { getQuery } = generateGraphql("System", ["controlCategories"], {
      controlCategories: `
        (limit: 200) {
          items {
            id
            ownerGroup
            riskControlCategory {
              id
              ownerGroup
              subControls (limit: 200) {
                items {
                  id
                  ownerGroup
                  evidences (limit: 500) {
                    items {
                      id
                      evidenceID
                      evidence {
                        id
                        ownerGroup
                        name
                        status
                        type
                        pointOfContacts (limit: 100) { items { id pointOfContact { id firstName lastName email title user { id } actions(limit: 100) { items { id name status createdAt data } } } } }
                        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
    });

    const systemItem = await ItemQuery(getQuery, system.id);

    const controlCategories =
      systemItem.controlCategories && systemItem.controlCategories.items ? systemItem.controlCategories.items : [];

    for (const controlCategoryLink of controlCategories) {
      const controlCategoryItem = controlCategoryLink.riskControlCategory;
      const riskControls =
        controlCategoryItem && controlCategoryItem.subControls && controlCategoryItem.subControls.items
          ? controlCategoryItem.subControls.items
          : [];
      for (const riskControl of riskControls) {
        // let evidenceLinks = riskControl.evidences && riskControl.evidences.items ? riskControl.evidences.items : [];
        // currentEvidence.push(...rollup(evidenceLinks));

        const evidences = getMany(riskControl, "evidences", "evidence");
        currentEvidence.push(...evidences);
      }
    }
  }

  return removeDuplicateObjects(currentEvidence);
};

const rollup = (keyPerformanceIndicatorLinks) => {
  const res = [];
  if (keyPerformanceIndicatorLinks) {
    for (const keyPerformanceIndicatorLink of keyPerformanceIndicatorLinks) {
      res.push(keyPerformanceIndicatorLink.keyPerformanceIndicator);
    }
  }
  return res;
};
