"use strict";
/**
 * @function
 * @description Round a number
 * @param value
 * @param precision
 * @returns
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundNumber = void 0;
const roundNumber = (value, precision = 0) => {
    if (value === null || isNaN(value) || isNaN(precision)) {
        return null;
    }
    const multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
};
exports.roundNumber = roundNumber;
