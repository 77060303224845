"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomQueryFunction = void 0;
const customQueryFunctions_1 = require("./customQueryFunctions/constants/customQueryFunctions");
/**
 * Finds the custom query function based on the function name, utilizes the definition property func
 * if it there is one defined for easier testing and debugging of the functions.
 * @param {*} functionData - the data by which to locate the function.
 * @returns {*|null}
 */
const getCustomQueryFunction = ({ functionData }) => {
    if (typeof functionData.func === "function")
        return functionData.func;
    //Original function name is used to locate the logic for the function
    const functionName = functionData?.name;
    if (!functionName) {
        return null;
    }
    return customQueryFunctions_1.customQueryFunctions?.[functionName];
};
exports.getCustomQueryFunction = getCustomQueryFunction;
