import Tooltip from "@mui/material/Tooltip";
import React, { useContext } from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { EVIDENCE_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { onEvidenceTypeChange } from "../functions/onEvidenceTypeChange";
import { updateEvidence } from "../graphql/updateEvidence";

/**
 * Custom field component for Evidence.type field
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} item - the evidence item
 * @param {function} updateItemById - function to update item in the grid
 * @param {function} resetFunction - function to re-fetch data from database
 * @param {function} setActivityResetIndex - triggers a re-fetch of the evidence activities
 * @param {boolean} disableEdits - if TRUE will not allow to change the value of this field
 * @param {object} props - other props to pass to the GenericEditFieldV3 component
 * @returns {JSX.Element}
 */
const EvidenceType = ({
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  item,
  updateItemById,
  resetFunction,
  setActivityResetIndex,
  disableEdits,
  ...props
}) => {
  const field = "type";
  const { loggedInPointOfContactId, loggedInUserId } = useContext(OrganizationContext);

  const callback = async (updatedItem) => {
    /**
     * If the type is changed create an evidence activity
     */
    await onEvidenceTypeChange({
      evidenceID: item?.id,
      oldType: item?.type,
      newType: updatedItem?.type,
      organizationID: item?.ownerGroup,
      pointOfContactID: loggedInPointOfContactId,
      userID: loggedInUserId,
    }).then(
      () =>
        typeof setActivityResetIndex === "function" &&
        setActivityResetIndex((activityResetIndex) => activityResetIndex + 1),
    );

    if (typeof updateItemById === "function") {
      updateItemById(updatedItem);
    } else if (typeof resetFunction === "function") {
      resetFunction();
    }
  };

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      field={field}
      inputType={"dropdown"}
      inputConfig={{
        data: enumToDropdownData({
          ENUM: EVIDENCE_TYPES,
          overrides: {
            [EVIDENCE_TYPES.EXTERNAL_URL]: "External URL",
          },
        }),
      }}
      mutation={updateEvidence}
      customDisplayComponent={item?.[field] && getEvidenceTypeBadge(item[field])}
      disableEdits={disableEdits}
      updateItemById={callback}
      {...props}
    />
  );
};

export const getEvidenceTypeBadge = (type) => {
  switch (type) {
    case EVIDENCE_TYPES.DOCUMENT:
      return <DocumentUploadTypeBadge />;
    case EVIDENCE_TYPES.REPORT:
      return <ReportUploadTypeBadge />;
    case EVIDENCE_TYPES.ATTESTATION:
      return <AttestationTypeBadge />;
    case EVIDENCE_TYPES.MEETING:
      return <MeetingTypeBadge />;
    case EVIDENCE_TYPES.SCREENSHOT:
      return <ScreenshotTypeBadge />;
    case EVIDENCE_TYPES.POLICY:
      return <PolicyTypeBadge />;
    case EVIDENCE_TYPES.KPI:
      return <KeyPerformanceIndicatorBadge />;
    case EVIDENCE_TYPES.TRAINING:
      return <TrainingTypeBadge />;
    case EVIDENCE_TYPES.PHISHING:
      return <PhishingTypeBadge />;
    case EVIDENCE_TYPES.EXTERNAL_URL:
      return <ExternalUrlTypeBadge />;
    default:
      return type;
  }
};

export const DocumentUploadTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Document Upload"}
      tooltip={"This Evidence can be satisfied by uploading one or multiple files"}
    />
  );
};

export const ReportUploadTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Report Upload"}
      tooltip={"This Evidence can be satisfied by linking to a Report that is generated using the Rivial Platform"}
    />
  );
};

export const MeetingTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Meeting Minutes"}
      tooltip={"This Evidence can be satisfied by linking to a Meeting that has been tracked using the Rivial Platform"}
    />
  );
};

export const ScreenshotTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Screenshot Upload"}
      tooltip={"This Evidence can be satisfied by uploading a Screenshot image"}
    />
  );
};

export const AttestationTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Attestation"}
      tooltip={"This Evidence can be satisfied by checking a box to attest that the criteria is being met or not"}
    />
  );
};

export const PolicyTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Policy Upload"}
      tooltip={"This Evidence can be satisfied by linking to a Security Policy being stored in the Rivial Platform"}
    />
  );
};

export const KeyPerformanceIndicatorBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Key Performance Indicator"}
      tooltip={"This Evidence can be satisfied by making sure all attached key performance indicators are passing"}
    />
  );
};

export const TrainingTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Training"}
      tooltip={"This Evidence can be satisfied by making sure all users were trained"}
    />
  );
};

export const PhishingTypeBadge = () => {
  return (
    <EvidenceTypeBadge
      text={"Phishing"}
      tooltip={"This Evidence can be satisfied by making sure all users were tested by a phishing test"}
    />
  );
};

export const ExternalUrlTypeBadge = () => {
  return (
    <EvidenceTypeBadge text={"External URL"} tooltip={"This Evidence can be satisfied by providing an external link"} />
  );
};

export const EvidenceTypeBadge = ({ tooltip, text }) => {
  return (
    <Tooltip arrow title={tooltip}>
      <span>{text}</span>
    </Tooltip>
  );
};

export default EvidenceType;
