import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";

import { routeType } from "../../../../definitions/constants/routeTypes";
import { getResourceRoute } from "../../../../definitions/functions/getResourceRoute";
import { openInNewTab } from "../../../../utils/Functions/Views/openInNewTab";
import { useModal } from "../../useModal";
import { DataGridDetailsModalHeader } from "../components/DataGridDetailsModalHeader";
import { DataGridModalDetailsComponent } from "../components/DataGridModalDetailsComponent";

/**
 * Hook housing the details UI of the data grid, for now only a modal view is supported
 * @param {object} gridContext - main grid settings created in the parent hook (useDataGrid)
 * @param {function} [getRouteDisabled] - determines if a route nav link should be disabled, returns a string to display a tooltip explanation and null if route is enabled
 * @param {function} updateItemById - callback function to update grid items when they are edited in the details UI
 * @return {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, openItemDetails: openItemDetails, modal: *, toggleModal: function(): void}}
 */
export const useDataGridDetails = ({ gridContext, getRouteDisabled, updateItemById }) => {
  const {
    typename,
    route,
    config,
    detailsTitle,
    detailsComponent,
    detailsLink,
    detailsHook,
    hasNext,
    hasPrevious,
    adjustSelectedItemIndex,
    getVisibleRows,
    data,
    apiRef,
    module,
    resource,
    disableRoleChecking,
  } = gridContext;

  const [detailsItem, setDetailsItem] = useState(null);

  const getRoute = ({ doAppendID = true, item }) => {
    const suffix = item?.id;
    const routeParam = detailsLink || route;

    return getResourceRoute({
      item: item || detailsItem,
      override: routeParam,
      routeType: routeType.DETAILS,
      typename,
      prefix: "#/",
      suffix,
      applyPrefixToOverride: false,
      applySuffixToOverride: doAppendID,
    });
  };

  const detailsModal = useModal(
    <DataGridDetailsModalHeader
      typename={typename}
      adjustSelectedItemIndex={adjustSelectedItemIndex}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      route={getRoute({ item: detailsItem })}
      getRouteDisabled={getRouteDisabled}
      detailsItem={detailsItem}
      setDetailsItem={setDetailsItem}
      config={config}
      detailsTitle={detailsTitle}
      data={data}
      getVisibleRows={getVisibleRows}
      item={detailsItem}
      apiRef={apiRef}
    />,
    <DataGridModalDetailsComponent
      item={detailsItem}
      detailsItem={detailsItem}
      config={config}
      detailsComponent={detailsComponent}
      detailsHook={detailsHook}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      updateItemById={updateItemById}
    />,
    <span
      title={`Click to view ${typename}` || "item" + " details"}
      id={`details_modalwrapper_${detailsItem?.id}`}
      data-testid={"details_modal_button_wrapper"}
    >
      <InfoOutlinedIcon style={{ color: "#25556a", cursor: "pointer" }} id={`details_modal_${detailsItem?.id}`} />
    </span>,
    {
      width: config?.width || "90vw",
    },
  );

  const openItemDetails = (item) => {
    //Check if details button should actually open a link (no component or hook but link is defined)
    const currentRoute = getRoute({ doAppendID: true, item });
    if (!detailsComponent && !detailsHook) {
      if (currentRoute) {
        openInNewTab({ url: currentRoute });
      } else {
        //do nothing, no details view is available
      }
    } else {
      setDetailsItem(item);
      detailsModal.setModalIsOpen(true);
    }
  };

  return {
    ...detailsModal,
    openItemDetails,
  };
};
