import React from "react";

import PercentageChange from "../../../../utils/GenericComponents/Numeric/PercentageChange";

/**
 * Displays the current, new and the % change in implementation rating
 * @param {object} item - the risk change item
 * @param {number} newPercent - the CHANGE in implementation rating
 * @param {number} oldPercent - the current implementation rating
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeInImplementation = ({ item, newPercent, oldPercent }) => {
  const newValue = item?.implementationChange || newPercent;

  const riskControl = item?.riskControl;

  const riskControlImplementation = riskControl && riskControl.implementationRating;

  const newImplementationRating = riskControlImplementation + newValue;
  const newImplementationPercent =
    newImplementationRating !== null && !isNaN(newImplementationRating) ? newImplementationRating : newPercent;

  const oldImplementationPercent =
    riskControlImplementation !== null && !isNaN(riskControlImplementation) ? riskControlImplementation : oldPercent;

  return <PercentageChange oldValue={oldImplementationPercent} newValue={newImplementationPercent} />;
};

export default ChangeInImplementation;
