"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aggregationFunctions = void 0;
const combineAsBulletPoints_1 = require("./aggregationFunctions/combineAsBulletPoints");
const combineAsText_1 = require("./aggregationFunctions/combineAsText");
const combineNotes_1 = require("./aggregationFunctions/combineNotes");
const getAverage_1 = require("./aggregationFunctions/getAverage");
const getCount_1 = require("./aggregationFunctions/getCount");
const getFirst_1 = require("./aggregationFunctions/getFirst");
const getLast_1 = require("./aggregationFunctions/getLast");
const getMax_1 = require("./aggregationFunctions/getMax");
const getMedian_1 = require("./aggregationFunctions/getMedian");
const getMin_1 = require("./aggregationFunctions/getMin");
const getMode_1 = require("./aggregationFunctions/getMode");
const getRange_1 = require("./aggregationFunctions/getRange");
const getSum_1 = require("./aggregationFunctions/getSum");
/**
 * Functions allowing to go from an array of item to a single item from that array. All options in the getNestedField custom query function.
 * @type {{combineAsText: ((function({items: Object}): (string|null))|*), lastValue: (function({items: *}): *|number|null), firstValue: (function({items: *}): number|null), combineAsBulletPoints: ((function({items: Object}): (string|null))|*), firstNumericValue: (function({items: *}): number|null), numericRange: ((function({items: *[]}): (number|null))|*), countOfEntries: ((function({items: *[]}): (*|null))|*), numericMode: ((function({items: *[]}): (number|null))|*), numericAverage: ((function({items: *[]}): (number|null))|*), numericMedian: ((function({items: *[]}): (number|null))|*), numericSum: ((function({items: *[]}): (number|null))|*), combineNotes: ((function({items: [][]}): (string|null))|*), highestNumericValue: ((function({items: *[]}): (number|null))|*), lastNumericValue: (function({items: *}): *|number|null), lowestNumericValue: ((function({items: Object}): (number|null))|*)}}
 */
exports.aggregationFunctions = {
    firstNumericValue: ({ items }) => (0, getFirst_1.getFirst)({ items, isNumeric: true }),
    firstValue: ({ items }) => (0, getFirst_1.getFirst)({ items, isNumeric: false }),
    lastNumericValue: ({ items }) => (0, getLast_1.getLast)({ items, isNumeric: true }),
    lastValue: ({ items }) => (0, getLast_1.getLast)({ items, isNumeric: false }),
    lowestNumericValue: getMin_1.getMin,
    highestNumericValue: getMax_1.getMax,
    numericRange: getRange_1.getRange,
    numericSum: getSum_1.getSum,
    numericAverage: getAverage_1.getAverage,
    numericMedian: getMedian_1.getMedian,
    numericMode: getMode_1.getMode,
    countOfEntries: getCount_1.getCount,
    combineAsText: combineAsText_1.combineAsText,
    combineAsBulletPoints: combineAsBulletPoints_1.combineAsBulletPoints,
    combineNotes: combineNotes_1.combineNotes,
};
