"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isObject = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Checks if items is a true object that is not null or undefined
 * but also not other primitive types or an array
 * @param {*} item - the item to check
 * @returns {boolean} - TRUE if item is a true object
 */
const isObject = (item) => {
    if ((0, isNullOrUndefined_1.isNullOrUndefined)(item))
        return false;
    if (typeof item !== "object")
        return false;
    return !Array.isArray(item);
};
exports.isObject = isObject;
