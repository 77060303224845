import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useMitreAttackSubTechniqueDetails } from "../hooks/useMitreAttackSubTechniqueDetails";

/**
 * @description Display Mitre Attack Sub Technique details component
 * @param {object} props - React props for the component
 * @returns {JSX.Element}
 * @constructor
 */
const MitreAttackSubTechniqueDetails = (props) => useMitreAttackSubTechniqueDetails(props).display;

export default withDetailsPage(MitreAttackSubTechniqueDetails, {
  title: "Sub Technique Details",
});
