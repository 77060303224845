import { useContext, useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useBarChart } from "../../../../hooks/charts/useBarChart";
import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Display bar chart of Vulnerabilities by the severity level
 * @param {object} item - assessment
 * @returns {JSX.Element}
 */
const TargetVulnerabilitiesBySeverityLevel = ({ item }) => {
  const context = useContext(OrganizationContext);

  const [data, setData] = useState([]);

  const chart = useBarChart(data, ["count"], "severityLevel", {
    isCustomColors: true,
  });

  useEffect(() => {
    ListQueryBy({
      query: listTargetFindingLinksByTarget,
      limit: 1000,
      variables: {
        targetID: item?.id,
      },
      normalizeData: (data) => {
        const normalizedData = data?.map((x) => x?.vulnerability) ?? [];

        if (!Array.isArray(normalizedData)) {
          throw Error("Can not normalize vulnerabilities for report. Vulnerabilities is not an array");
        }

        return normalizedData;
      },
    }).then((items) => {
      const vulnerabilities = [];

      for (const vulnerability of items) {
        const found = vulnerabilities.find((x) => x.id === vulnerability?.id);

        if (!found) {
          if (context?.preferences?.testing?.showInfoVulnerabilities) {
            vulnerabilities.push(vulnerability);
          } else {
            if (vulnerability?.severityLevel !== VULNERABILITY_SEVERITY_LEVEL.INFO) {
              vulnerabilities.push(vulnerability);
            }
          }
        }
      }

      if (!isNullOrUndefined(vulnerabilities)) {
        let high = 0,
          medium = 0,
          low = 0,
          info = 0;

        Array.isArray(items) &&
          vulnerabilities.map((vulnerability) => {
            const severityLevel = vulnerability?.severityLevel;

            if (severityLevel === VULNERABILITY_SEVERITY_LEVEL.HIGH) {
              high++;
            } else if (severityLevel === VULNERABILITY_SEVERITY_LEVEL.MEDIUM) {
              medium++;
            } else if (severityLevel === VULNERABILITY_SEVERITY_LEVEL.LOW) {
              low++;
            } else if (severityLevel === VULNERABILITY_SEVERITY_LEVEL.INFO) {
              info++;
            }
          });

        const tempChartData = [];

        tempChartData.push({
          severityLevel: "High",
          count: high,
          color: "hsl(0,100%,60%)",
        });

        tempChartData.push({
          severityLevel: "Medium",
          count: medium,
          color: "hsl(24,97%,57%)",
        });

        tempChartData.push({
          severityLevel: "Low",
          count: low,
          color: "hsl(139,100%,36%)",
        });

        context?.preferences?.testing?.showInfoVulnerabilities &&
          tempChartData.push({
            severityLevel: "Info",
            count: info,
            color: "hsl(217,100%,61%)",
          });

        setData(tempChartData);
      }
    });
  }, []);

  return chart.display;
};

const { listByQuery: listTargetFindingLinksByTarget } = generateGraphql(
  "TargetFindingLink",
  ["targetID", "vulnerability"],
  {
    vulnerability: `{ id name severityLevel }`,
  },
  {
    indexName: "listTargetFindingLinksByTargetID",
    partitionKey: "targetID",
    partitionKeyType: "ID",
  },
);

export default TargetVulnerabilitiesBySeverityLevel;
