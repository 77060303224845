import { createBlankLine, createText } from "@rivial-security/report-utils";

export const generateRiskAssessmentDetails = (vendorReview) => {
  const vendorName =
    (vendorReview &&
      vendorReview.vendorSolution &&
      vendorReview.vendorSolution.vendor &&
      vendorReview.vendorSolution.vendor.name) ||
    "";

  const vendorSolutionName = (vendorReview && vendorReview.vendorSolution && vendorReview.vendorSolution.name) || "";

  let ratingText = "[LEVEL]";
  if (vendorReview && vendorReview.rating) {
    switch (vendorReview.rating) {
      case "high":
        ratingText = "High";
        break;
      case "mediumHigh":
        ratingText = "Medium - High";
        break;
      case "medium":
        ratingText = "Medium";
        break;
      case "lowMedium":
        ratingText = "Low - Medium";
        break;
      case "low":
        ratingText = "Low";
        break;
      default:
      //pass
    }
  }

  return [
    createText(
      `${vendorName} provides the software product ${vendorSolutionName}. This includes certain types of information…`,
    ),
    createBlankLine(),
    createText("Impact Ratings", "Normal", { underline: true }),
    createText("  - Confidentiality - [LEVEL] ..."),
    createText("  - Integrity - [LEVEL] ..."),
    createText("  - Availability - [LEVEL] ..."),
    createBlankLine(),
    createText(
      `Taking into consideration the [LEVEL] cyber security risk and the [LEVEL] impact ratings above, ` +
        `Rivial's risk rating of this vendor solution is ${
          ratingText || "[LEVEL]"
        }. This rating assumes all financial institution ` +
        `user entity controls (below) are in place and operating effectively.`,
    ),
  ];
};
