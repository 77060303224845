import { useChecklist } from "./useChecklistV2";

/**
 * A component instance of the useChecklistV2.js hook
 * @param props
 */

const Checklist = (props) => {
  return useChecklist(props).display;
};
export default Checklist;
