import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useChecklist } from "../../../../../hooks/views/useChecklist/useChecklistV2";

/**
 * Meeting agenda component for
 * @param {string} organizationID - currently selected organization id
 * @param {object} meeting - data about the meeting from the database
 * @param {function} onChangeCallback - function to call when the meeting agenda changes
 * @param {boolean} disableEdits - TRUE if need to disable edits to the meeting agenda
 * @param {boolean} isMobile - override to mobile view for the meeting agenda checklist
 * @returns {JSX.Element}
 */
const MeetingAgendaV2 = ({ organizationID, meeting, onChangeCallback, disableEdits, isMobile = false }) => {
  const checklist = useChecklist({
    organizationID,
    item: meeting,
    field: "agenda",
    updateMutation: generateGraphql(resources.MEETING).updateMutation,
    enableCheckbox: true,
    disableEdits,
    isMobile,
    checkboxFieldName: "completed",
    textFieldName: "content",
    enableNotes: true,
    stepButtonName: "Agenda Item",
    onChangeCallback,
    notesProps: {
      observationConnectionField: "meetingID",
      meetingID: meeting?.id,
      module: modules.GOVERNANCE,
    },
    module: modules.GOVERNANCE,
    resource: resources.MEETING,
  });

  return checklist.display;
};

export default MeetingAgendaV2;
