import React from "react";

import SelectionTiles from "../../../../utils/GenericComponents/SelectionTiles";
import { useBuiltInWidgets } from "../hooks/useBuiltInWidgets";

/**
 * Displays selection tiles for a list of built in widgets.
 *
 * @param {function} onSelect - The function to call when a widget is selected
 * @param {object} widgets - The object containing the set of built in widgets to display. Ref BUILT_IN_WIDGETS.js configuration file.
 * @returns {Element}
 * @constructor
 */
const BuiltInWidgetSelectionTiles = ({ onSelect, widgets }) => {
  const builtInWidgets = useBuiltInWidgets(widgets, onSelect);
  return <SelectionTiles items={builtInWidgets} onSelect={onSelect} />;
};

export default BuiltInWidgetSelectionTiles;
