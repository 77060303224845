import Grid from "@mui/material/Grid";
import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import CustomDateField from "../../Email/customFields/CustomDateField";
import ObservationActionItems from "../components/ObservationActionItems";
import ObservationAssociatedResources from "../components/ObservationAssociatedResources";
import ObservationDocuments from "../components/ObservationDocuments";
import ObservationRecommendations from "../components/ObservationRecommendations";
import Decision from "../customFields/Decision";
import EstimatedCompletionDate from "../customFields/EstimatedCompletionDate";
import IsFinding from "../customFields/IsFinding";
import Module from "../customFields/Module";
import NeedsDecision from "../customFields/NeedsDecision";
import ObservationNotes from "../customFields/ObservationNotes";
import Recommendations from "../customFields/Recommendations";
import Risk from "../customFields/Risk";
import Source from "../customFields/Source";
import Status from "../customFields/Status";
import { createObservationTagLink } from "../functions/createObservationTagLink";

/**
 * Displays information about a Specific Observation
 * @param {string} itemId - the observation to show data for
 * @param {string} organizationID - the organization identifier of the observation in the database
 * @param {object} props - additional props to pass down to useDetailsCard
 */
export const useObservationDetails = ({ itemId, organizationID, ...props }) => {
  const typename = "Observation";
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;
  const route = "#/governance/observations/";

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });
  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const fieldNameDictionary = {
    isFinding: "Finding/Observation",
    externalSource: "Source",
    formalResponse: "Management Response",
  };

  const customFields = [
    {
      field: "isFinding",
      component: <IsFinding />,
    },
    {
      field: "module",
      component: <Module />,
    },
    {
      field: "recommendations",
      component: <Recommendations />,
    },
    {
      field: "createdAt",
      component: <CustomDateField />,
    },
    {
      field: "externalSource",
      component: <Source />,
    },
    {
      field: "formalResponse",
      inputType: "textarea",
    },
    {
      field: "name",
      inputType: "textarea",
    },
    {
      field: "description",
      inputType: "textarea",
    },
    {
      field: "risk",
      component: <Risk />,
    },
    {
      field: "needsDecision",
      component: <NeedsDecision />,
    },
    {
      field: "decision",
      component: <Decision />,
    },
    {
      field: "estimatedCompletionDate",
      component: <EstimatedCompletionDate />,
    },
    {
      field: "status",
      component: <Status />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createObservationTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];

  const detailsConfig = {
    fields: displayFields,
    fieldNameDictionary,
    nestedFields,
    customFields,
    updateMutation,
  };

  const cardConfig = {
    route,
    header: "Observation Details",
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: cardConfig,
    ...props,
  });

  const style = { height: "100%" };

  const accordion = useAccordion({
    details: detailsCard,
    items: [
      {
        title: "Recommendations",
        icon: "icon-check",
        component: (
          <div style={{ height: "30em" }}>
            <ObservationRecommendations item={detailsCard?.item} />
          </div>
        ),
        defaultExpanded: true,
      },
      {
        title: "Action Items",
        icon: "icon-rocket",
        component: (
          <div style={{ height: "30em" }}>
            <ObservationActionItems item={detailsCard?.item} />
          </div>
        ),
        defaultExpanded: true,
      },
      {
        title: "Documents",
        subTitle: "Documents associated with this Observation",
        icon: "icon-docs",
        defaultExpanded: true,
        component: (
          <div style={{ height: "30em" }}>
            <ObservationDocuments item={detailsCard?.item} disableTitle={true} resetFunction={detailsCard?.reset} />
          </div>
        ),
      },
      {
        title: "Associated Resources",
        icon: "icon-link",
        component: <ObservationAssociatedResources resetFunction={detailsCard?.reset} />,
        defaultExpanded: true,
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={detailsCard?.reset}
      headerButtons={[<AutomationsButton key={0} itemId={itemId} typename={"Observation"} />]}
    >
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"General Information"} style={style} icon={"icon-list"}>
              {detailsCard.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Notes"} style={style} icon={"icon-note"}>
              <ObservationNotes item={detailsCard?.item} disableTitle={true} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} md={12} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsCard,
    display,
  };
};
