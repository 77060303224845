import React, { useEffect, useState } from "react";

import { formattedDollar } from "@rivial-security/func-utils";
import { getStandardLossAmount } from "@rivial-security/risk-calc-utils";

import CustomTooltip from "../../../../../../../utils/GenericComponents/CustomTooltip";

/**
 * Retrieves the Upper Confidence Interval number for Monte Carlo
 * @param system
 * @param organizationID
 * @param systemRiskLink
 * @returns {{system: *, display: JSX.Element, standardLossAmount: number}}
 */
export const useStandardLossAmount = (system, organizationID, systemRiskLink) => {
  const [standardLossAmount, setStandardLossAmount] = useState(0);

  useEffect(() => {
    const value = getStandardLossAmount(system, systemRiskLink);

    setStandardLossAmount(value);
  }, [system, systemRiskLink]);

  const display = (
    <>
      {formattedDollar(standardLossAmount)}
      <CustomTooltip
        tooltip={`Upper Risk Amount is automatically calculated from the highest Impact rating based on Information Assets`}
      />
    </>
  );

  return {
    display,
    standardLossAmount,
    system,
  };
};
