import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import ToolAutomations from "../components/ToolAutomations";
import ToolCustomDashboards from "../components/ToolCustomDashboards";
import ToolCustomResourceTypes from "../components/ToolCustomResourceTypes";
import ToolSecret from "../components/ToolSecret";

/**
 * @description Display Tool details hook
 * @param {object} item - tool
 * @param {object} props
 * @returns {object} {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useToolDetails = ({ itemId, organizationID, isTemplate, ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;
  const typename = resources.TOOL;
  const route = "#/integrations/configuration/";

  const nestedFields = {
    secret: `{ secretId name description }`,
  };

  const { getQuery, updateMutation } = generateGraphql(typename, ["name", "description", "secret"], nestedFields);

  const fields = ["name", "description"];

  const customFields = [
    {
      field: "secret",
      component: <ToolSecret />,
      isDynamicCustomField: true,
      tooltip:
        "A Secret can be securely stored to be used by this Integration. Once a Secret is set, it cannot be displayed and can only be updated to a new Secret. Secrets can be used to store API keys, passwords, and other sensitive information to be used as part of an Automation",
    },
  ];

  const detailsConfig = {
    title: "Integration Details",
    fields,
    customFields,
    updateMutation,
  };

  const configs = {
    route,
  };

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: configs,
    tableDisplay: true,
    title: "Integration Details",
    ...props,
  });

  const dashboardItems = [
    {
      title: "Automations",
      subTitle: "Automations and routines configured for this integration",
      component: (
        <StyledWrapper
          wraperStyle={{
            height: "50vh",
          }}
        >
          <ToolAutomations organizationID={organizationID} isTemplate={props.isTemplate} />
        </StyledWrapper>
      ),
      icon: "icon-energy",
    },
    {
      title: "Resource Types",
      subTitle: "Custom data tables configured for this integration",
      component: <ToolCustomResourceTypes organizationID={organizationID} isTemplate={props.isTemplate} />,
      icon: "icon-list",
    },
    // {
    //   title: "Widgets",
    //   subTitle: "Tables, Charts, and other content to be displayed in a Dashboard, Report, or Email",
    //   component: <ToolCustomWidgets organizationID={organizationID} isTemplate={props.isTemplate} />,
    //   icon: "icon-list",
    // },
    // {
    //   title: "Report Templates",
    //   subTitle: "Dynamic reports that can be generated on demand",
    //   component: <ToolCustomWidgets organizationID={organizationID} isTemplate={props.isTemplate} />,
    //   icon: "icon-list",
    // },
  ];

  if (isTemplate) {
    dashboardItems.push({
      title: "Dashboards",
      subTitle: "Custom dashboards and landing pages related to this integration",
      component: <ToolCustomDashboards organizationID={organizationID} toolID={itemId} isTemplate={props.isTemplate} />,
      icon: "icon-list",
    });
  }

  const accordion = useAccordion({
    details,
    items: [...dashboardItems],
  });

  const display = (
    <Dashboard resetFunction={details.reset} subTitle={details?.item?.name}>
      <div>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <DashboardCard title="General Information" icon="icon-list">
              {details.display}
            </DashboardCard>
          </Grid>
          <Grid item lg={8} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </div>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
