import { useEffect, useState } from "react";

import { objectToString } from "../../../../../../utils/Functions/Object/objectToString";
import { runMonteCarlo } from "../functions/runMonteCarlo";

/**
 * Handles the Monte Carlo Calculation fetching and puts it in local state.
 * Implements an 'isLoading' and 'isOutdated' state system
 * @param {object} item - the system item to calculate for
 * @param {string} organizationID - the selected organization ID
 * @param {function} setRiskScore - function to set the state of the risk score
 * @param {boolean} isLoading - external isLoading state
 * @param {function} updateItemById - function to call to update the underlying utilities upon finish of a calculation
 * @returns {{isLoading: boolean, isOutdated: boolean, monteCarloResults: null, monteCarloInput: null}}
 */
export const useMonteCarloEffect = ({
  item,
  organizationID,
  setRiskScore,
  updateItemById,
  isLoading: isLoadingParam,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOutdated, setIsOutdated] = useState(false);

  const [monteCarloInput, setMonteCarloInput] = useState(null);
  const [monteCarloResults, setMonteCarloResults] = useState(null);

  useEffect(() => {
    if (item) {
      setIsLoading(true);
      runMonteCarlo(
        item,
        organizationID,
        {
          setMonteCarloInput,
          setMonteCarloResults,
          setRiskScore,
        },
        updateItemById,
      ).then(() => {
        setIsOutdated(false);
        setIsLoading(false);
      });
    }
  }, [
    objectToString({
      item,
      keepFields: [
        "availability",
        "confidentiality",
        "integrity",
        "controlCategories",
        "informationAssets",
        "riskControlOverrides",
        "riskOverrides",
        "risks",
      ],
    }),
  ]);

  useEffect(() => {
    setIsLoading && setIsLoading(isLoadingParam);
  }, [isLoadingParam]);

  return {
    isLoading,
    isOutdated,
    setIsOutdated,
    setIsLoading,
    monteCarloInput,
    monteCarloResults,
  };
};
