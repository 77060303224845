export const customWidgetsByOwnerGroup = /* GraphQL */ `
  query CustomWidgetsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomWidgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customWidgetsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        description
        type
        customQuery {
          id
          name
          description
        }
      }
      nextToken
    }
  }
`;
