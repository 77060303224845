import { ErrorLogger, InfoLogger } from "../EventLogger";

import { GetQuery } from "./Graphql/GetQuery";

/**
 * @description Encrypt and decrypt data
 * @param {string} operation - "encrypt" or "decrypt"
 * @param {string} dataToDecrypt
 * @param {string} dataToEncrypt
 * @return {Promise<null|*>}
 * @constructor
 */
const NodejsCipher = async ({ operation, dataToDecrypt, dataToEncrypt }) => {
  if (!operation) {
    ErrorLogger("Error! Missing operation in the NodejsCipher function!");
    return null;
  }

  const nodejsCipherLambdaGraphql = `
    query nodejsCipher($input: AWSJSON) {
      nodejsCipher(input: $input)
    }
  `;

  InfoLogger("Sending request to nodejsCipher lambda");

  const response = await GetQuery({
    query: nodejsCipherLambdaGraphql,
    variables: {
      input: JSON.stringify({
        operation,
        dataToEncrypt,
        dataToDecrypt,
      }),
    },
  });

  if (response) {
    const responseJSON = JSON.parse(response);
    if (responseJSON.statusCode && responseJSON.statusCode === 200) {
      const responseBody = JSON.parse(responseJSON.body);

      if (responseBody && responseBody.encryptedData) {
        return responseBody.encryptedData;
      } else if (responseBody && responseBody.decryptedData) {
        return responseBody.decryptedData;
      } else {
        return null;
      }
    } else {
      ErrorLogger(`Error! nodejsCipher lambda: ${JSON.stringify(responseJSON)}`);
      return null;
    }
  }
};

export default NodejsCipher;
