import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useControlDetails } from "../../../../views/Compliance/Controls/Controls/hooks/useControlDetails/useControlDetails";
import { useControlDataGrid } from "../../../../views/Compliance/Controls/Controls/hooks/useControlGrid/useControlDataGrid";
import { useCreateControl } from "../../../../views/Compliance/Controls/Controls/hooks/useCreateControl/useCreateControl";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

const CreateControl = React.lazy(
  () => import("../../../../views/Compliance/Controls/Controls/components/CreateControl"),
);

const ControlDataGrid = React.lazy(
  () => import("../../../../views/Compliance/Controls/Controls/components/ControlDataGrid"),
);

const ControlDetails = React.lazy(
  () => import("../../../../views/Compliance/Controls/Controls/components/ControlDetails"),
);

export const controlDefinition = {
  typename: "Control",
  module: modules.COMPLIANCE,
  resource: resources.CONTROL,
  routes: {
    grid: "continuous_compliance/controls",
    details: "continuous_compliance/controls",
    helpCenter: "controls",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:list",
  gridHook: useControlDataGrid,
  gridComponent: <ControlDataGrid />,
  formHook: useCreateControl,
  formComponent: <CreateControl />,
  detailsHook: useControlDetails,
  detailsComponent: <ControlDetails />,

  fields: {
    statementNumber: {},
    name: {},
    inPlace: {},
    isDisabled: {},
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
    controlControlSetId: {},
    controlSet: {
      default: {
        nestedFields: `{id name}`,
      },
    },
    customFieldData: {},
  },

  views: {
    grid: {
      queryFields: [
        "statementNumber",
        "name",
        "inPlace",
        "isDisabled",
        "tags",
        "notes",
        "controlControlSetId",
        "controlSet",
        "customFieldData",
      ],
    },
  },
};
