"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAverage = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Calculates the numeric average of all items in the array.
 * @param {*[]} items - array of items that can be converted to numeric values or are already numbers
 * @returns {number|null} - the average of all items or null if no valid items were passed
 */
const getAverage = ({ items }) => {
    const numericItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    if (!Array.isArray(numericItems) || numericItems.length === 0) {
        return null;
    }
    else {
        const sum = numericItems.reduce((a, b) => a + b, 0);
        return sum / numericItems.length;
    }
};
exports.getAverage = getAverage;
