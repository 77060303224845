import CustomFieldEditor from "../../../../views/Compliance/Controls/Controls/customFields/CustomFieldEditor/CustomFieldEditor";

/**
 * @typedef {Object} CreateCustomFieldColumnsParams
 * @param {object[]} customFields - the customFields configuration object
 * @param {boolean} customFieldsReadOnly - forces fields to be read only
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 */

/**
 * @typedef {Object} CustomFieldColumn
 * @property {string} name - the name of the custom field
 * @property {boolean} hide - whether to hide the column
 * @property {number} minWidth - the minimum width of the column
 * @property {number} flex - the flex value of the column
 * @property {ReactElement} component - the component to render in the column
 */

/**
 * Creates the custom columns corresponding to custom fields
 * @param {CreateCustomFieldColumnsParams} input
 * @returns {CustomFieldColumn[]} - the custom field columns
 */
export const createCustomFieldColumns = ({ customFields, customFieldsReadOnly, module, resource }) => {
  if (customFields && Array.isArray(customFields)) {
    const newFields = [];
    for (const customField of customFields) {
      if (customField?.type && customField?.name) {
        let currentField = {
          name: customField.name,
          hide: true,
          minWidth: 100,
          flex: 1, // divides all custom fields between a single flex: 1 unit
          component: (
            <CustomFieldEditor
              config={customField}
              inGrid={true}
              module={module}
              resource={resource}
              readOnly={customFieldsReadOnly}
            />
          ),
        };

        if (customField.type === "string" || customField.type === "query" || customField.type === "multipleSelect") {
          currentField = {
            ...currentField,
            type: "string",
          };
        } else if (customField.type === "boolean") {
          currentField = {
            ...currentField,
            type: "boolean",
          };
        } else if (customField.type === "number") {
          currentField = {
            ...currentField,
            type: "number",
          };
        } else if (customField.type === "enum") {
          currentField = {
            ...currentField,
            type: "enum",
          };
        } else {
          continue;
        }

        newFields.push(currentField);
      }
    }

    return newFields;
  } else {
    return [];
  }
};
