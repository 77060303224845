/**
 * @description Function for building fullName for evidence activity.
 * @param {PointOfContact} pointOfContact - point of contact object
 * @returns {*}
 */
export const getFullName = ({ pointOfContact }) => {
  let fullName = pointOfContact?.firstName;
  if (!fullName) {
    fullName = "Someone";
  } else if (pointOfContact?.lastName) {
    fullName += ` ${pointOfContact?.lastName}`;
  }
  return fullName;
};
