import { useContext } from "react";

import { TEMPLATE } from "@enums/TEMPLATE";
import { OrganizationContext } from "@utils/Context/OrganizationContext";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { useTags } from "@utils/Tags/hooks/useTags";

const DataGridTagFilterMenu = ({ item, applyValue, focusElementRef, organizationID }) => {
  //Use the origin organization for template pages
  const context = useContext(OrganizationContext);
  if (organizationID === TEMPLATE) {
    organizationID = context?.role?.ownerGroup;
  }

  const tags = useTags({
    organizationID,
    initSelectedTags: item?.tags,
    showTagsInitially: true,
    showHideTagsOption: false,
    showCreateTagsOption: false,
    onSelectedTagCallback: (selectedTags) => {
      applyValue({
        ...item,
        value: selectedTags,
        tags: selectedTags,
      });
    },
  });

  return tags.display;
};

export default withOrganizationCheck(DataGridTagFilterMenu);
