"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runCustomQueryWithAppSync = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const convertMuiRuleToFilters_1 = require("./convertMuiRuleToFilters");
const convertQueryIntoGraphql_1 = require("./convertQueryIntoGraphql");
const deRootifyQueryObject_1 = require("./deRootifyQueryObject");
const recursivelyApplyQueryFunctions_1 = require("./recursivelyApplyQueryFunctions");
//NOTE: on the server make sure organizationID does not come from the user
const runCustomQueryWithAppSync = async ({ query, organizationID, log, }) => {
    if (!organizationID) {
        log?.("[runCustomQueryWithAppSync] Organization id not provided");
        return {};
    }
    const cleanedQuery = (0, deRootifyQueryObject_1.deRootifyQueryObject)({ query });
    const filters = (0, convertMuiRuleToFilters_1.convertMuiRuleToFilters)({
        logicBuilderFilter: cleanedQuery?.filters,
    });
    const rootTypename = cleanedQuery?.typename;
    log?.("[runCustomQueryWithAppSync] Starting query with parameters - ");
    log?.(JSON.stringify({ cleanedQuery, filters, rootTypename }));
    if (!rootTypename) {
        log?.("[runCustomQueryWithAppSync] typename was not found");
        return {};
    }
    const { listQuery, listByOwnerGroupQuery } = (0, convertQueryIntoGraphql_1.convertQueryIntoGraphql)({ query, log });
    log?.("[runCustomQueryWithAppSync] graphql generated - ");
    log?.(JSON.stringify({ listQuery, listByOwnerGroupQuery }));
    let listData;
    if (listByOwnerGroupQuery) {
        listData = await (0, appsync_utils_1.ListQueryBy)({
            query: listByOwnerGroupQuery,
            variables: {
                ownerGroup: organizationID,
            },
            filter: filters,
        });
    }
    if (!listData && listQuery) {
        listData = await (0, appsync_utils_1.ListQuery)({
            query: listQuery,
            organizationID,
            otherFilters: filters,
        });
    }
    const rootData = {
        [rootTypename]: {
            items: listData,
        },
    };
    try {
        await (0, recursivelyApplyQueryFunctions_1.recursivelyApplyQueryFunctions)({ query, data: rootData });
    }
    catch (e) {
        log?.(`Error running custom query post processing functions `, e);
    }
    return rootData;
};
exports.runCustomQueryWithAppSync = runCustomQueryWithAppSync;
