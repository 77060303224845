import { Alert, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { GetQuery } from "@rivial-security/appsync-utils";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { resources } from "@rivial-security/role-utils";

import { WarningLogger } from "@utils/EventLogger";

import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import TemplateTypeField from "../../../../utils/CustomFields/TemplateTypeField";
import TemplateTypeFilterMenu from "../../../../utils/FilterMenus/TemplateTypeFilterMenu";
import { hideFilterMenuUI } from "../../../../utils/Functions/Views/grid/hideFilterMenuUI";
import Loader from "../../../../utils/LoadingComponents/Loader";
import ControlGrid from "../../../../views/Compliance/Controls/Controls/components/ControlGrid";
import EvidenceDataGrid from "../../../../views/Compliance/Evidence/components/EvidenceDataGrid";
import MeetingsDataGrid from "../../../../views/Program/Meetings/components/MeetingsDataGrid";
import ExerciseGrid from "../../../../views/Response/Exercises/components/ExerciseGrid";
import ThreatGrid from "../../../../views/Response/Threats/components/ThreatGrid";
import InformationAssetGrid from "../../../../views/Risk/RiskConfig/Assets/components/InformationAssetGrid";
import ControlCategoriesGrid from "../../../../views/Risk/RiskConfig/ControlCategories/components/ControlCategoriesGrid";
import RiskControlGrid from "../../../../views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlGrid";
import KeyRiskIndicatorsGrid from "../../../../views/Risk/RiskConfig/KeyRiskIndicators/components/KeyRiskIndicatorsGrid";
import AutomationTemplateGrid from "../../../../views/Templates/AutomationTemplates/AutomationTemplateGrid";
import CustomQueryTemplateGrid from "../../../../views/Templates/CustomQueryTemplates/CustomQueryTemplateGrid";
import DepartmentTemplateGrid from "../../../../views/Templates/DepartmentTemplates/DepartmentTemplateGrid";
import EmailTemplateGrid from "../../../../views/Templates/EmailTemplates/EmailTemplateGrid";
import { applyTemplates } from "../../../../views/Templates/functions/applyTemplates";
import { queryTemplates } from "../../../../views/Templates/functions/queryTemplates";
import RoleTemplateGrid from "../../../../views/Templates/RoleTemplates/components/RoleTemplateGrid";
import SystemTemplateDataGrid from "../../../../views/Templates/SystemTemplates/components/SystemTemplateDataGrid";
import ToolTemplateGrid from "../../../../views/Templates/ToolTemplates/ToolTemplateGrid";
import { getItemForGridQuery } from "../function/getItemForGridQuery";

/**
 * Shows a grid of selectable templates that the user can duplicate into their organization
 * @param {string} organizationID - the organization into which to duplicate the templates
 * @param {string} resource - the resource that is being modified
 * @param {object[]} [parents] - the parent resources (provides additional information on what to link the template(s) to)
 * @param {function} onSubmitCallback - function to call when a user submits templates to copy
 * @param {function} getNewItem - function to call when an item was added (used by grids to populate new items)
 */
export const useSelectTemplates = ({ organizationID, resource, parents, onSubmitCallback, getNewItem }) => {
  /// [CONTEXT]
  const { loggedInUserId } = useContext(OrganizationContext);
  const { addToast, updateToast } = useContext(UIContext);

  /// [STATE]
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  /// [QUERY TEMPLATES]
  const getData = async () => {
    setIsLoading(true);

    const templates = await queryTemplates({
      resource,
      userID: loggedInUserId,
    });
    if (Array.isArray(templates)) {
      setTemplates(templates);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  /// [APPLY TEMPLATES]
  // Makes the request to duplicate a certain template to applyTemplates lambda
  const duplicateTemplates = async ({ items }) => {
    //Filter out invalid items
    if (!Array.isArray(items)) {
      WarningLogger("Invalid passed in items when duplicating templates!");
    }
    items = items.filter((item) => !isNullOrUndefined(item?.id));

    if (items.length === 0) {
      WarningLogger("No valid templates items passed in when duplicating templates!");
      return;
    }

    onSubmitCallback?.();

    const createdItems = await applyTemplates({
      input: {
        templatesToApply: items,
        resource,
        reportProgress: true,
        userID: loggedInUserId,
        organizationID,
        rootParents: parents,
      },
      resourceName: resource ? convertCamelCaseToSentence(resource) : "Resource",
      addToast,
      updateToast,
    });

    if (typeof getNewItem === "function" && Array.isArray(createdItems)) {
      for (const createdItem of createdItems) {
        if (createdItem?.id) {
          const fullItem = await GetQuery({
            query: getItemForGridQuery({ resource }),
            variables: { id: createdItem?.id },
          });
          if (fullItem) {
            // fixes an issue where the templates lambda sends back a response object instead of the item
            if (fullItem?.statusCode) {
              return;
            }

            getNewItem?.(fullItem);
          }
        }
      }
    }

    // Resets the selected templates state
    setSelectedTemplates([]);
  };

  /// [GRID]
  const generalConfig = {
    grid: {
      data: templates,
      disableRoleChecking: true,
      enableSearch: true,
      createResourceComponent: null,
      detailsComponent: null,
      updateMutation: null,

      //NOTE: many custom field components depend on this id to stay the same to conditionally allow/disallow edits
      persistenceUUID: `select-${resource || "resource"}-templates-grid`,

      // syncfusion grid
      enableSelectButtons: true,
      selectButtonCallback: async (item) => {
        await duplicateTemplates({ items: [item] });
      },

      // mui grid (multi-selection)
      enableSelection: true,
      onSelectedDataChange: (data) => {
        setSelectedTemplates(data);
      },
      options: [],

      //Hide default tags column filter menu elements
      actionComplete: (args) => hideFilterMenuUI({ args, hideAllFields: ["ownerGroup"] }),
    },
    card: {
      title: `${resource ? convertCamelCaseToSentence(resource) : "Resource"} Templates`,
    },
    query: {
      query: null,
    },
    additionalFields: [
      {
        name: "ownerGroup",
        friendlyName: "Template Type",
        component: <TemplateTypeField />,
        filterTemplate: (props) => <TemplateTypeFilterMenu props={props} />,
        flex: 1,
        minWidth: 100,
      },
    ],
  };

  const getGrid = () => {
    switch (resource) {
      case resources.RISK_CONTROL:
        return (
          <RiskControlGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.THREAT:
        return (
          <ThreatGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.KEY_RISK_INDICATOR:
        return (
          <KeyRiskIndicatorsGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.INFORMATION_ASSET:
        return (
          <InformationAssetGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.RISK_CONTROL_CATEGORY:
        return (
          <ControlCategoriesGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.CONTROL:
        return (
          <ControlGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            otherFields={[
              ...(generalConfig?.additionalFields ?? {}),
              {
                name: "tags",
                visible: false,
                showInColumnChooser: false,
              },
            ]}
          />
        );
      case resources.EVIDENCE:
        return (
          <EvidenceDataGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
            visibleFields={["itemNumber", "name", "frequency", "ownerGroup"]}
          />
        );
      case resources.EXERCISE:
        return (
          <div>
            <ExerciseGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.AUTOMATION:
        return (
          <div>
            <AutomationTemplateGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.TOOL:
        return (
          <ToolTemplateGrid
            gridConfig={generalConfig?.grid}
            queryConfig={generalConfig?.query}
            cardConfig={generalConfig?.card}
            additionalFields={generalConfig?.additionalFields}
          />
        );
      case resources.EMAIL_TEMPLATE:
        return (
          <div>
            <EmailTemplateGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.MEETING:
        return (
          <div>
            <MeetingsDataGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.INFORMATION_SYSTEM:
        return (
          <div>
            <SystemTemplateDataGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.DEPARTMENT:
        return (
          <div>
            <DepartmentTemplateGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.CUSTOM_QUERY:
        return (
          <div>
            <CustomQueryTemplateGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      case resources.ROLE:
        return (
          <div>
            <RoleTemplateGrid
              gridConfig={generalConfig?.grid}
              queryConfig={generalConfig?.query}
              cardConfig={generalConfig?.card}
              additionalFields={generalConfig?.additionalFields}
            />
          </div>
        );
      default:
        return (
          <Alert severity={resource ? "warning" : "error"}>
            {resource
              ? `${convertCamelCaseToSentence(resource)} is an unsupported template resource type!`
              : `Invalid template resource provided!`}
          </Alert>
        );
    }
  };

  let display;

  const displayWithButton = (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {getGrid()}
      {selectedTemplates.length > 0 && (
        <Button
          onClick={() => duplicateTemplates({ items: selectedTemplates })}
          color="success"
          variant="contained"
          style={{ marginTop: "1em" }}
        >
          Create {selectedTemplates.length} {resource}
          {selectedTemplates.length > 1 ? "s" : ""}
        </Button>
      )}
    </div>
  );

  if (isLoading) {
    display = (
      <div>
        <div style={{ marginTop: "1em" }}>
          <Loader text={"Loading Templates.  Please Wait..."} />
        </div>
      </div>
    );
  } else {
    display = displayWithButton;
  }

  return {
    display,
    templates,
    selectedTemplates,
  };
};
