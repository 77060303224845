import Grid from "@mui/material/Grid";
import { Spinner } from "reactstrap";

import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import DateField from "../../../../../utils/CustomFields/DateField";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import DocumentEditorModalButton from "../../../../Reports/DocumentEditor/components/DocumentEditorModalButton";
import KnowBe4ModuleDataGrid from "../components/KnowBe4ModuleDataGrid";
import KnowBe4TrainingEnrollmentsDataGrid from "../components/KnowBe4TrainingEnrollmentsDataGrid";
import KnowBe4TrainingPieChart from "../components/KnowBe4TrainingPieChart";
import { getKnowBe4Training } from "../graphql/getKnowBe4Training";

/**
 * @description Display KnowBe4 Training Campaign details hook
 * @param {object} item - Training Campaign
 * @param {object} props
 * @returns {object} {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useKnowBe4TrainingDetails = ({ item, ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.KNOW_BE_4_TRAINING;
  const toolType = TOOL_TYPES.KNOW_BE_4;
  const route = "#/integrations/know_be_4/training/";

  const detailsConfig = {
    fields: ["name", "status", "start_date", "end_date", "completion_percentage"],
  };

  const configs = {
    header: "KnowBe4 Training Campaign Details",
    route,
  };

  const queryConfig = {
    queryExternalApi: {
      toolType,
      input: {
        query: getKnowBe4Training,
        queryInput: {
          id: item?.campaign_id || item?.id || props?.itemId,
        },
      },
    },
  };

  const customFields = [
    {
      field: "start_date",
      component: <DateField fieldName={"start_date"} />,
    },
    {
      field: "end_date",
      component: <DateField fieldName={"end_date"} />,
    },
    {
      field: "completion_date",
      component: <DateField fieldName={"completion_date"} />,
    },
  ];

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    customFields,
    config: configs,
    module,
    resource,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "KnowBe4 Training Details",
        component: details.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Training Content",
        subTitle: "The KnowBe4 Modules used in this Training",
        component: (
          <div style={{ height: "30em" }}>
            <KnowBe4ModuleDataGrid item={details.item} gridDisplay={true} gridConfig={{ gridHeight: "100%" }} />
          </div>
        ),
        icon: "icon-screen-desktop",
      },
      {
        title: "User Enrollments",
        subTitle: "KnowBe4 Users Enrolled in Training",
        component: (
          <div style={{ height: "30em" }}>
            <KnowBe4TrainingEnrollmentsDataGrid
              itemId={item?.id || props.itemId}
              gridDisplay={true}
              gridConfig={{ gridHeight: "100%" }}
            />
          </div>
        ),
        icon: "icon-people",
      },
    ],
    unmountOnExit: false,
  });

  const display = details?.item ? (
    <DataLoader isLoading={details?.isLoading} isEnoughData={details?.item}>
      <Dashboard
        resetFunction={details.reset}
        subTitle={details?.item?.name}
        headerButtons={[
          <DocumentEditorModalButton
            itemId={item?.campaign_id || details?.item?.id}
            defaultTemplate={"TRAINING_TEMPLATE"}
            type={"Training Report"}
            module={"Tools"}
          />,
        ]}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              {accordion.display}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <KnowBe4TrainingPieChart item={details.item} />
            </Grid>
          </Grid>
        </div>
      </Dashboard>
    </DataLoader>
  ) : (
    <>
      <Spinner style={{ marginRight: "0.5rem" }} color={"primary"} size="sm" />
      Loading Training Details...
    </>
  );

  return {
    display,
  };
};
