import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateEvidence } from "../hooks/useCreateEvidence";

/**
 * A component instance of the useCreateEvidence.js hook
 * @param props
 */
const CreateEvidence = (props) => {
  return useCreateEvidence(props).display;
};

export default withOrganizationCheck(CreateEvidence);
