import cx from "classnames";
import { ComponentPropsWithoutRef, PropsWithChildren, ReactElement } from "react";

import styles from "./StylelessButton.module.scss";

export interface StylelessButtonProps extends PropsWithChildren, ComponentPropsWithoutRef<"button"> {}

/**
 * A button component is an interactive UI element used to trigger actions or navigate within an application,
 * providing a clear and intuitive way for users to interact with the system.
 **/
const StylelessButton = ({ children, className, ...props }: StylelessButtonProps): ReactElement => {
  const classNames = cx(styles["styleless-button"], className);

  return (
    <button className={classNames} aria-disabled={props.disabled} {...props}>
      {children}
    </button>
  );
};

export default StylelessButton;
