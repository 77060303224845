"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deRootifyQueryObject = void 0;
const lodash_1 = require("lodash");
/**
 * The query builder appends a root node at the beginning of the tree representing
 * the beginning of the query, however thi node info is unneeded when not displaying
 * information in the UI therefore it is removed here. This function is safe to use on
 * already de-rootified query objects.
 * @param {object} queryInit - the query object from query builder to de rootify
 * @return {{functions: *, fields: *, typename: *}|*}
 */
const deRootifyQueryObject = ({ query: queryInit }) => {
    const query = (0, lodash_1.cloneDeep)(queryInit);
    // if the query fields is not an object, return original object
    const topLevelFields = query?.fields;
    if (typeof topLevelFields !== "object") {
        return query;
    }
    // if there are no fields or more than one field, then we can't de-rootify
    const topLevelFieldNames = Object.keys(topLevelFields);
    if (!Array.isArray(topLevelFieldNames) || topLevelFieldNames.length === 0 || topLevelFieldNames.length > 1) {
        return query;
    }
    // if the top level field is not marked as a root, then we can't de-rootify
    const rootFieldName = topLevelFieldNames[0];
    const rootField = topLevelFields[rootFieldName];
    if (rootField?.isRoot !== true) {
        return query;
    }
    // de-rootify
    return {
        typename: query?.typename,
        fields: rootField?.fields,
        functions: rootField?.functions,
        filters: rootField?.filters,
    };
};
exports.deRootifyQueryObject = deRootifyQueryObject;
