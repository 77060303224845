"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatLossToleranceCurve = void 0;
/**
 * Converts the raw tolerance curve data from the Org query to a properly formatted array of objects
 *
 * @param {object[]} rawLossToleranceCurve
 * @param {number} rawLossToleranceCurve[].probability
 * @param {number} rawLossToleranceCurve[].amount
 * @returns {object[]}
 */
const formatLossToleranceCurve = (rawLossToleranceCurve) => {
    return rawLossToleranceCurve
        .sort((a, b) => b.probability - a.probability)
        .map((row) => ({ x: row.amount, y: row.probability }));
};
exports.formatLossToleranceCurve = formatLossToleranceCurve;
