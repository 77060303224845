"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SERIES_TYPE = void 0;
/**
 * The type series, controls how data is fetched from a custom query
 * @type {{AUTO: string, MANUAL: string}}
 */
exports.SERIES_TYPE = {
    AUTO: "auto",
    MANUAL: "manual",
};
