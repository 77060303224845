import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Updates the toast to tell the user that a required field cannot be empty.
 * Does nothing if toasts are disabled
 *
 * @param {boolean} disableToast - if true, doesn't update the toast
 * @param {function} updateToast - updates the toast
 * @param {string} field - the field being changed
 * @param {string} friendlyName - an optionally field name override for display purposes
 * @param {string} [message] - what to say to the user for the fail, use token {field} to put in field name into the message
 * @param {string} [toastId] - toastId may be null or undefined if toasts are disabled
 */
export const handleFailFieldToast = ({
  disableToast,
  updateToast,
  field,
  friendlyName,
  message = "Failed to update the '{field}' field",
  toastId,
}) => {
  if (isNullOrUndefined(updateToast)) {
    throw Error("updateToast function parameter is required");
  }

  if (isNullOrUndefined(field)) {
    throw Error("field parameter is required");
  }

  const finalMessage = message.replace("{field}", convertCamelCaseToSentence(friendlyName || field));

  !disableToast &&
    !isNullOrUndefined(toastId) &&
    updateToast({
      id: toastId,
      icon: "danger",
      header: finalMessage,
    });
};
