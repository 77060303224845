import React, { useEffect, useState } from "react";
import { FormFeedback, Input } from "reactstrap";

import { CheckPhoneNumberRegex } from "../../../../utils/Regex/Regex";

/**
 * @description Allow the user to input a phone number
 * @param {function} onChange - set the value of the input
 * @param {string} value - the value of the input
 * @param {string} fieldName - the name of the input
 * @param {boolean} required - whether the input is required
 * @returns {JSX.Element}
 * @constructor
 */
const CustomPhoneNumberInput = ({ onChange, value = "", fieldName, required }) => {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  useEffect(() => {
    setValidPhoneNumber(CheckPhoneNumberRegex(value));
  }, [value]);

  const nonEmptyInput = value && value.length > 0;
  const invalidInput = validPhoneNumber === false && (required || value);

  return (
    <div>
      <Input
        type="text"
        id="phone_number"
        data-testid={`custom-phone-input-${fieldName}`}
        valid={nonEmptyInput && validPhoneNumber}
        invalid={invalidInput}
        value={value}
        onChange={(data) => onChange(data && data.target && data.target.value)}
        required={required}
      />
      {invalidInput && (
        <FormFeedback>
          Looks like there is an issue with your phone number. Please input a correct phone number. Example:
          +15095555555
        </FormFeedback>
      )}
    </div>
  );
};

export default CustomPhoneNumberInput;
