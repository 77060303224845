import { STEP_STATUS, usePleaseWaitModal } from "../../../../../../hooks/views/usePleaseWaitModal";

export const evidenceResponseSubmissionSteps = {
  PROCESSING_RESPONSE: "processingResponse",
  UPLOADING_FILES: "uploadingFiles",
  LOGGING_EVIDENCE_ACTIVITY: "loggingEvidenceActivity",
  UPDATING_ACTION_ITEM: "updatingActionItem",
  UPDATING_EVIDENCE_STATUS: "updatingEvidenceStatus",
  RECORDING_ARTIFACT_INFORMATION: "recordingArtifactInformation",
  PROCESSING_ARTIFACT: "processingArtifact",
};

/**
 * The please wait modal used for the Evidence Landing Page
 * @param {boolean} isGuest=false - if TRUE then this evidence upload is from a guest user
 * @param {boolean} aiOptIn=false - if TRUE then the organization has opted in to AI
 * @returns {{setSubTitle: *, finishWithError: function({errorMessage?: string}=): void, setStepStatus: function(*, *): void, setProgress: *, setStepsStatusById: function({ids: *, status: *, startNextStep?: boolean}): void, finished: *, setAutoCloseInterval: *, steps: *, setTotalProgress: *, setModalIsOpen: *, modalButton: JSX.Element, setSteps: *, modalIsOpen: *, autoCloseInterval: *, progress: *, totalProgress: *, incrementProgress: function(): void, autoClose: *, modal: JSX.Element, setFinished: *, toggleModal: function(): void}}
 */
export const useLandingPagePleaseWaitModal = ({ isGuest = false, aiOptIn = false }) => {
  const steps = [];
  steps.push({
    id: evidenceResponseSubmissionSteps.PROCESSING_RESPONSE,
    text: "Processing Response",
    status: STEP_STATUS.IN_PROGRESS,
  });

  if (isGuest) {
    steps.push({
      id: evidenceResponseSubmissionSteps.UPLOADING_FILES,
      text: "Uploading Selected Files",
      status: STEP_STATUS.WAITING,
    });
  }

  steps.push({
    id: evidenceResponseSubmissionSteps.LOGGING_EVIDENCE_ACTIVITY,
    text: "Logging Evidence Activity",
    status: STEP_STATUS.WAITING,
  });

  steps.push({
    id: evidenceResponseSubmissionSteps.UPDATING_ACTION_ITEM,
    text: "Updating Action Item",
    status: STEP_STATUS.WAITING,
  });

  steps.push({
    id: evidenceResponseSubmissionSteps.UPDATING_EVIDENCE_STATUS,
    text: "Updating Evidence Status",
    status: STEP_STATUS.WAITING,
  });

  steps.push({
    id: evidenceResponseSubmissionSteps.RECORDING_ARTIFACT_INFORMATION,
    text: "Recording Artifact Information",
    status: STEP_STATUS.WAITING,
  });

  // Only show this step if the organization has opted in to AI
  if (aiOptIn && !isGuest) {
    steps.push({
      id: evidenceResponseSubmissionSteps.PROCESSING_ARTIFACT,
      text: "Processing Artifact",
      status: STEP_STATUS.WAITING,
    });
  }

  return usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 2,
    confirmationText: "Thank you for submitting this Evidence Artifact!",
    steps,
  });
};
