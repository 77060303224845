"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTableWidget = void 0;
const createTable_1 = require("../createTable");
/**
 * Handles SFDT generation based on widget settings, preformatting of data handled by widget utils
 * @param {object} tableSettings - general table configurations that have not been applied yet to the rest of the data and fields
 * @param {boolean} tableSettings.showLinkToResource - if TRUE will show a 'view" link to the data
 * @param {string} tableSettings.detailsRoutePrefix - the route fo the item within each row (doesn't include the id)
 * @param {string[]} fields - array of fields to show and in what order
 * @param {object} fieldNameDictionary - the name to give to each field
 * @param {object[]} data - the pre-formatted (sorted, filtered, transformed) data to display in the table
 * @returns {{tableFormat: {shading: {backgroundColor: string, foregroundColor: string, textureStyle: string}, preferredWidth, leftMargin: number, bidi: boolean, borders: {diagonalDown: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, horizontal: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, top: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, left: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, bottom: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, vertical: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, right: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, diagonalUp: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}}, leftIndent: number, allowAutoFit: boolean, preferredWidthType: string, cellSpacing: number, topMargin: number, rightMargin: number, bottomMargin: number, tableAlignment: string}, grid: number[], description: null, rows: [], title: null}}
 */
const handleTableWidget = ({ tableSettings, fields, fieldNameDictionary, data }) => {
    console.log("[handleTableWidget] - ", { tableSettings, fields, fieldNameDictionary });
    if (!Array.isArray(data)) {
        data = [];
    }
    const showLinkToResource = tableSettings?.showLinkToResource;
    const detailsRoutePrefix = tableSettings?.detailsRoutePrefix;
    const customFields = [];
    let columnConfig = {};
    if (showLinkToResource === true && detailsRoutePrefix) {
        fields = ["details", ...fields];
        columnConfig = {
            columnWidths: [50],
        };
        customFields.push({
            field: "details",
            format: "sfdt",
            function: (item, link) => {
                return {
                    paragraphFormat: {
                        borders: {
                            top: {},
                            left: {},
                            right: {},
                            bottom: {},
                            horizontal: {},
                            vertical: {},
                        },
                        styleName: "Normal",
                        listFormat: {},
                    },
                    characterFormat: {},
                    inlines: [
                        {
                            characterFormat: {},
                            fieldType: 0,
                            hasFieldEnd: true,
                        },
                        {
                            characterFormat: {},
                            text: `HYPERLINK "${link}"`,
                        },
                        {
                            characterFormat: {},
                            fieldType: 2,
                        },
                        {
                            characterFormat: {
                                underline: "Single",
                                fontColor: "#0563C1FF",
                            },
                            text: "View",
                        },
                        {
                            characterFormat: {},
                            fieldType: 1,
                        },
                    ],
                };
            },
        });
        for (const item of data) {
            if (item?.id) {
                item["details"] = `${detailsRoutePrefix}/${item.id}`;
            }
            else {
                item["details"] = ``;
            }
        }
    }
    return (0, createTable_1.createTable)({
        fields,
        items: data,
        fieldNameDictionary,
        customFields,
        columnConfig,
    });
};
exports.handleTableWidget = handleTableWidget;
