"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLossToleranceExceedance = void 0;
const findLossToleranceHeightAtPoint_1 = require("./findLossToleranceHeightAtPoint");
/**
 * Finds loss tolerance exceedance data based opn the residual risk and loss tolerance curves.
 *
 * @function
 * @param {object[]} lossToleranceCurve - the loss tolerance points (objects with x, y values) defined by the % of chance
 * an organization are willing to accept of a monetary loss of x or more
 * @param {object[]} residualLossCurve - the residual loss points (objects with x, y values) defined % of chance a
 * simulation resulted in a monetary loss of x or more
 * @returns {object} - the annual loss curves for both the loss tolerance and residual loss curves and their averages
 */
const findLossToleranceExceedance = ({ lossToleranceCurve, residualLossCurve, }) => {
    // Check arguments
    if (!lossToleranceCurve || !residualLossCurve) {
        return null;
    }
    // Find annual losses for the residual loss curve
    const residualLossAnnualLossCurve = residualLossCurve.map((riskPoint) => {
        return { y: riskPoint.y * riskPoint.x, x: riskPoint.x };
    });
    // For Every point in the residual loss curve find the corresponding point in the loss tolerance curve and find annual loss
    const lossToleranceAnnualLossCurve = residualLossCurve.map((riskPoint) => {
        const result = (0, findLossToleranceHeightAtPoint_1.findLossToleranceHeightAtPoint)({ lossToleranceCurve, riskPoint });
        if (result?.lossToleranceProbabilityAtRiskValue !== null &&
            result?.lossToleranceProbabilityAtRiskValue !== undefined) {
            return { y: result.lossToleranceProbabilityAtRiskValue * riskPoint.x, x: riskPoint.x };
        }
        else {
            return null;
        }
    });
    // Filter out any null values for loss tolerance annual losses
    for (let i = lossToleranceAnnualLossCurve.length - 1; i >= 0; i--) {
        if (lossToleranceAnnualLossCurve[i] === null) {
            lossToleranceAnnualLossCurve.splice(i, 1);
            residualLossAnnualLossCurve.splice(i, 1);
        }
    }
    // Find the average of the y value of each curve
    const lossToleranceAnnualLossCurveAverage = lossToleranceAnnualLossCurve.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.y;
    }, 0) / lossToleranceAnnualLossCurve.length;
    const residualLossAnnualLossCurveAverage = residualLossAnnualLossCurve.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.y;
    }, 0) / residualLossAnnualLossCurve.length;
    // Return all the results
    return {
        lossToleranceAnnualLossCurve,
        residualLossAnnualLossCurve,
        lossToleranceAnnualLossCurveAverage,
        residualLossAnnualLossCurveAverage,
    };
};
exports.findLossToleranceExceedance = findLossToleranceExceedance;
