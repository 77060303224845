//IMPORTANT: also used by getMetricsForMetricType

export const listMetricsByMetricType = /* GraphQL */ `
  query MetricsByMetricType(
    $metricTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metricsByMetricType(
      metricTypeID: $metricTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        data {
          value
        }
      }
    }
  }
`;
