"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultProgressBarConfig = void 0;
const contextType_1 = require("../contextType");
/**
 * Preset settings to use when rendering or displaying a progressBar widget
 * in different contexts (when there is no other config provided)
 */
exports.defaultProgressBarConfig = {
    [contextType_1.CONTEXT_TYPE.REPORT]: {
        layout: {
            width: "900px",
            height: "40px",
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.WEB]: {
        layout: {
            width: "100%",
            height: "100%",
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
};
