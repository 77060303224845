import { Chip } from "@mui/material";
import { useRef } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useElementSize } from "../../../../../../../../hooks/functional/useElementSize";

/**
 * A custom field displaying the change in the greatest KRI between current and simulated (recommended) state
 * @param {object} systemRiskValues - results from getSystemKeyRiskIndicatorStats
 * @return {JSX.Element}
 */
const GreatestRiskKRIChange = ({ systemRiskValues }) => {
  const { greatestKeyRiskIndicator, greatestRecommendationKeyRiskIndicator, residualRisk, recommendationResidualRisk } =
    systemRiskValues;

  //Determine the badge properties
  const kriChanged =
    greatestRecommendationKeyRiskIndicator && greatestKeyRiskIndicator !== greatestRecommendationKeyRiskIndicator;

  const residualRiskChanged =
    !isNullOrUndefined(recommendationResidualRisk) && residualRisk !== recommendationResidualRisk;

  let chip = { label: "Unchanged", color: "info" };
  if (kriChanged) {
    chip = { label: "New", color: "success" };
  } else if (residualRiskChanged) {
    chip = { label: "Updated", color: "warning" };
  }

  //Determine badge content
  const keyRiskIndicator = greatestRecommendationKeyRiskIndicator || greatestKeyRiskIndicator;

  const ref = useRef();
  const { width } = useElementSize({ ref });
  const formatAsColumn = width < 300;

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: formatAsColumn ? "column" : "row",
        justifyContent: "center",
        gap: "0.5em",
      }}
    >
      <div
        style={{
          minWidth: "6em",
          ...(formatAsColumn && { alignSelf: "center" }),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Chip color={chip.color} label={chip.label} size={"small"} />
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: formatAsColumn ? "center" : "start",
          textAlign: formatAsColumn ? "center" : "start",
        }}
      >
        <p>{keyRiskIndicator}</p>
      </div>
      {kriChanged && greatestKeyRiskIndicator && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: formatAsColumn ? "center" : "start",
            textAlign: formatAsColumn ? "center" : "start",
            textDecoration: "line-through",
            color: "lightgrey",
          }}
        >
          <p>{greatestKeyRiskIndicator}</p>
        </div>
      )}
    </div>
  );
};

export default GreatestRiskKRIChange;
