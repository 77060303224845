import { useContext } from "react";

import { getCurrentDate } from "@rivial-security/func-utils";

import { createRiskChange } from "../../../../../../graphql/mutations";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { useSystemList } from "../../../../Systems/hooks/useSystemList";
import { RISK_CHANGE_TYPE } from "../../../constants/riskChangeType";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";

export const useCreateManualChange = ({ organizationID, resetFunction, toggleModal, getNewItem }) => {
  const context = useContext(OrganizationContext);

  // const [selectedSystems, setSelectedSystems] = useState([]);

  // useEffect(() => {
  //   if (selectedSystems && selectedSystems[0]) {
  //     setSelectedSystems(ItemQuery(getSystem_systemDetails, selectedSystems[0].id));
  //   }
  // }, [selectedSystems]);

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
  });

  const callback = async (riskChange) => {
    if (riskChange) {
      for (const selectedSystem of systemsList.selectedItems) {
        createSystemChangeLink.createItem({
          ownerGroup: organizationID,
          riskChangeID: riskChange.id,
          systemID: selectedSystem.id,
        });
      }
    }
  };

  const dataCardConfig = {
    fields: ["name"],
    options: null,
    config: {
      showSelectBoxes: true,
    },
  };

  const systemsList = useSystemList({ organizationID, dataCardConfig });

  const formHook = useForm({
    typename: "Risk Change",
    // header: "Add a Risk Change",
    organizationID,
    getNewItem,
    mutation: createRiskChange,
    disableRoleChecking: true,
    callback,
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name of Change",
        required: true,
        defaultValue: "",
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) {
            return false;
          }
          if (input) {
            return input.length > 5;
          } else {
            return false;
          }
        },
        validationText: "Must be more than 5 characters",
      },
      description: {
        inputType: "textarea",
        label: "Description of Change",
        required: true,
      },
      date: {
        inputType: "date",
        label: "Date of Change",
        defaultValue: getCurrentDate(),
        tooltip: "When did this Change happen?",
      },
      userEmail: {
        inputType: "text",
        label: "Person Entering Change",
        defaultValue: context.userEmail,
        disabled: true,
      },
      changeOwner: {
        inputType: "text",
        label: "Change Owner",
        tooltip: "Who in the Organization is responsible for this Change?",
        required: true,
      },
      reason: {
        inputType: "textarea",
        label: "Reason for Change",
        tooltip: "Why did this Change occur?",
        required: true,
      },
      // systemLinks: {
      //   inputType: "custom",
      //   customConfig: {
      //     component: systemsList.display
      //     // component: <AddSystemsAffected selectedSystems={selectedSystems} setSelectedSystems={setSelectedSystems}/>
      //   },
      //   removeFromInput: true
      // },
      type: {
        inputType: "text",
        label: "Change Type",
        defaultValue: RISK_CHANGE_TYPE.MANUAL_ENTRY,
        disabled: true,
      },
    },
    resetFunction,
    toggleModal,
  });

  return {
    ...formHook,
    selectedSystems: systemsList.selectedItems,
    setSelectedSystems: systemsList.setSelectedItems,
  };
};
/*
  id: ID!
  ownerGroup: String
  name: String
  type: String            # placeholder for now
  description: String     # overall affect
  date: String            # date of change
  userEmail: String       # person entering change
  changeOwner: String     # change owner
  reason: String          # reason for change
  change: AWSJSON         # JSON string with more info about change
  riskControlLinks: [RiskControlChangeLink] @connection(keyName: "byRiskChange", fields: ["id"])    # changes to a particular Risk Control
 */
