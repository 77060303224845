import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useImportActions } from "../hooks/useImportActions";

/**
 * Imports Action Items
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ImportActions = (props) => {
  return useImportActions(props).display;
};

export default withOrganizationCheck(ImportActions);
