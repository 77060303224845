import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useExerciseDetails } from "../hooks/useExerciseDetails";

/**
 * @description Wrapper component for useExerciseDetails hook
 * @param {object} props
 * @returns {*}
 * @constructor
 */
const ExerciseDetails = (props) => {
  return useExerciseDetails(props).display;
};

export default withDetailsPage(ExerciseDetails, { title: "Exercise Details" });
