import { Drawer, ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import React, { useEffect, useState } from "react";

import { usePleaseWaitModal } from "../../../hooks/views/usePleaseWaitModal";
import { adaptSFDT } from "../DocumentEditor/functions/validateSFDT/adaptSFDT";
import { getSortedByVersionReportDocuments } from "../functions/getSortedByVersionReportDocuments";
import { openDocumentVersion } from "../functions/openDocumentVersion";

/**
 * @description Report Document Drawer hook for selecting an version
 * @param {object} item - report
 * @param {object} documentEditor - document editor hook
 * @param {string} organizationID - selected organization
 * @param {function} setSelectedDocumentVersion - setter function for selected report document
 * @param {object} selectedDocumentVersion - selected report document
 * @returns {object} {{isOpen: boolean, setIsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void, display: JSX.Element}}
 */
export const useReportDocumentDrawer = ({
  item,
  documentEditor,
  organizationID,
  setSelectedDocumentVersion,
  selectedDocumentVersion,
}) => {
  const pleaseWaitModal = usePleaseWaitModal({});

  const [isOpen, setIsOpen] = useState(false);
  const [sortedDocuments, setSortedDocuments] = useState([]);

  useEffect(() => {
    if (item?.documents?.items) {
      const documentsSortedByVersion = getSortedByVersionReportDocuments({
        report: item,
      });
      setSortedDocuments(documentsSortedByVersion);
    }
  }, [item?.documents?.items]);

  const onItemClick = async ({ item }) => {
    pleaseWaitModal?.setModalIsOpen(true);

    const sfdt = await openDocumentVersion({
      id: item?.id,
      pleaseWaitModal,
      reportDocuments: sortedDocuments,
      organizationID,
    });

    adaptSFDT({ sfdt }); // updates old sfdt to the latest version
    documentEditor?.ref?.documentEditor?.open(sfdt);
    setIsOpen(false);
    setSelectedDocumentVersion && setSelectedDocumentVersion({ ...item });
    pleaseWaitModal?.setModalIsOpen(false);
  };

  /**
   * Select report document version
   */
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 100 }}
      role="presentation"
      onClick={() => setIsOpen(true)}
      onKeyDown={() => setIsOpen(false)}
    >
      <h5 style={{ textAlign: "center", marginTop: "1em" }}>Versions</h5>
      <List>
        {Array.isArray(sortedDocuments) &&
          sortedDocuments?.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onItemClick({ item })}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: item?.version === selectedDocumentVersion?.version && "#D3D3D3",
              }}
              title={`Created: ${new Date(item?.createdAt).toLocaleString()}`}
            >
              <strong>v{item?.version}</strong>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  const display = (
    <Drawer anchor={"right"} open={isOpen} onClose={() => setIsOpen(false)}>
      {list("right")}
    </Drawer>
  );

  return {
    display,
    isOpen,
    setIsOpen,
  };
};
