import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, CustomInput, ListGroupItem, ListGroupItemText } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { usePreferences } from "../../../../../../hooks/views/usePreferences/usePreferences";
import { useSettings } from "../../../../../../hooks/views/useSettings";
import { tryFunction } from "../../../../../../utils/Functions/tryFunction";
import CustomTooltip from "../../../../../../utils/GenericComponents/CustomTooltip";
import AutomationsButton from "../../../../../OrganizationManager/Automations/components/AutomationsButton";
import { clearCachedCalculation } from "../../../functions/clearCachedCalculation";

/**
 * Settings functionality for Systems
 * @param isOutdated
 * @param isLoading
 * @param resetFunction
 * @param item
 * @returns {{display: *, viewType: unknown, autoCalc: unknown}}
 */
export const useSystemSettings = ({ isOutdated, isLoading, resetFunction, item }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const checkPermissions = useCheckPermissions({ module, resource });

  const { addToast, updateToast } = useUIContext();

  const { setPreference, getPreference } = usePreferences();

  const [viewType, setViewType] = useState(getPreference("systemDetails", "viewType") || "analysis");
  const [autoCalc, setAutoCalc] = useState(getPreference("systemDetails", "autoCalc") || false);

  useEffect(() => {
    if (viewType !== getPreference("systemDetails", "viewType")) {
      setPreference("systemDetails", { viewType });
    }
  }, [viewType]);

  useEffect(() => {
    if (autoCalc !== getPreference("systemDetails", "autoCalc")) {
      setPreference("systemDetails", { autoCalc });
    }
  }, [autoCalc]);

  const settings = useSettings(
    [
      <ListGroupItem
        action
        tag="button"
        style={{
          border: !isLoading && isOutdated ? "2.5px solid gold" : undefined,
        }}
        onClick={() => resetFunction(true)}
      >
        <ListGroupItemText>
          <h5>
            Refresh System Details <i className="icon-refresh" />
          </h5>
        </ListGroupItemText>
      </ListGroupItem>,
      <ListGroupItem>
        <>
          <h5>
            View Type
            <CustomTooltip
              targetId="view-type_systemdetails"
              tooltip="Data Gathering view is slimmed down to only show necessary inputs and notes. Analysis View displays all of the information."
            />
          </h5>
          <ButtonGroup size="sm">
            <Button
              onClick={() => setViewType("dataGathering")}
              color={viewType === "dataGathering" ? "primary" : "ghost-secondary"}
              active={viewType === "dataGathering"}
              title={"Simplifies the view and hides sections that aren't relevant for gathering Risk Assessment Data"}
            >
              Data Gathering
            </Button>
            <Button
              onClick={() => setViewType("analysis")}
              color={viewType === "analysis" ? "primary" : "ghost-secondary"}
              active={viewType === "analysis"}
              title={
                "Advanced View for showing detailed Monte Carlo Analysis, Risk Scorecard, Recommendations, Risk Changes, Vendor Reviews, and Security Testing"
              }
            >
              Analysis
            </Button>
          </ButtonGroup>
        </>
      </ListGroupItem>,
      <ListGroupItem>
        <>
          <h5>
            Auto-Calculate
            <CustomTooltip
              targetId="auto-calc_systemdetails"
              tooltip="Turning Auto-Calculate on will re-run Monte-Carlo analysis on every input change."
            />
          </h5>
          <CustomInput
            id="system_details_autocalc_switch"
            type="switch"
            checked={autoCalc}
            onChange={() => setAutoCalc(!autoCalc)}
          />
        </>
      </ListGroupItem>,
      <ListGroupItem>
        <>
          <h5>
            Clear Saved Calculation
            <CustomTooltip
              targetId="clear_cached_calc"
              tooltip="Clears the saved calculation, allowing for a fresh set of simulations. "
            />
          </h5>
          <Button
            disabled={!checkPermissions.resource.update}
            onClick={async () => {
              const toastId = `clear_cache_toast${Math.random()}`;
              addToast({
                id: toastId,
                icon: "spinner",
                header: `Clearing Cached Calculation..`,
              });
              clearCachedCalculation(item).then(() => {
                updateToast({
                  id: toastId,
                  icon: "success",
                  header: `Successfully Cleared Cached Calculation!`,
                });
                tryFunction(resetFunction);
              });
            }}
          >
            Clear
          </Button>
        </>
      </ListGroupItem>,
      <ListGroupItem>
        <AutomationsButton style={{ marginRight: "2em" }} typename={"System"} itemId={item?.id} />
      </ListGroupItem>,
    ],
    "System Details Settings",
    {
      backgroundColor: "white",
    },
    {
      backgroundColor: "white",
      position: "static",
      margin: 0,
      padding: 0,
    },
  );

  return {
    ...settings,
    viewType,
    autoCalc,
  };
};
