import { Auth } from "@aws-amplify/auth";
import QRCode from "qrcode.react";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { updateUserPreferredMFA } from "../../../../../utils/Functions/MFA/updateUserPreferredMFA";
import { setPreferredMFA } from "../functions/setPreferredMFA";
import { setupMFA } from "../functions/setupMFA";

const PREFERRED_MFA = {
  SMS_MFA: "SMS",
  SOFTWARE_TOKEN_MFA: "Authenticator",
  NOMFA: "No MFA",
};

/**
 * @description Login Setup Mfa Component
 * @return {JSX.Element}
 * @constructor
 */
const LoginSetupMfa = () => {
  const context = useContext(OrganizationContext);
  const { addToast } = useContext(UIContext);

  const [user, setUser] = useState(null);
  const [qrCode, setQRCode] = useState("");
  const [qrCodePure, setQrCodePure] = useState("");
  const [challengeAnswer, setChallengeAnswer] = useState("");
  const [currentMFA, setCurrentMFA] = useState("");

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      // for getting User Device Key
      user?.getCachedDeviceKeyAndPassword();
      setCurrentMFA(PREFERRED_MFA[user?.preferredMFA]);
      setUser(user);
    });
  };

  const card = useCard({
    id: "setup-mfa",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Setup MFA</h4> <i id="setup-mfa-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="setup-mfa-icon-question">
          Multi-factor authentication is an electronic authentication method in which a device user is granted access to
          a website or application only after successfully presenting two or more pieces of evidence.
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <div style={{ marginBottom: "1em" }}>
          <h6 style={{ display: "inline" }}>Current value: {currentMFA ? currentMFA : "Not Setup"}</h6>
        </div>
        <Button
          color="ghost-success"
          className="btn-pill"
          style={{ marginRight: "1em", display: "inline" }}
          size="sm"
          onClick={() =>
            setupMFA({
              user,
              setQRCode,
              setQrCodePure,
              context,
            })
          }
          title="This action will display you a QR code with instructions"
        >
          Setup TOTP
        </Button>
        <Button
          color="ghost-primary"
          className="btn-pill"
          style={{ marginRight: "1em", display: "inline" }}
          size="sm"
          onClick={async () => {
            setPreferredMFA({
              authType: "SMS",
              user,
              challengeAnswer,
              addToast,
            });
            await updateUserPreferredMFA({ preferredMFA: "SMS", user });
            setCurrentMFA(PREFERRED_MFA["SMS_MFA"]);
          }}
          title="This action will setup your preferred MFA to SMS"
        >
          Setup SMS
        </Button>
        {user?.attributes?.["custom:requireMFA"] !== "true" &&
          user?.attributes?.["custom:accountType"] !== "operationTeamMember" && (
            <Button
              color="ghost-secondary"
              className="btn-pill"
              size="sm"
              onClick={async () => {
                setPreferredMFA({
                  authType: "NOMFA",
                  user,
                  challengeAnswer,
                  addToast,
                });
                await updateUserPreferredMFA({ preferredMFA: "NOMFA", user });
                setCurrentMFA(PREFERRED_MFA["NOMFA"]);
              }}
              title="This action will disable your MFA"
            >
              Disable MFA
            </Button>
          )}
        {qrCode !== "" && (
          <div>
            <br />
            <Toast>
              <ToastHeader>Setup TOTP</ToastHeader>
              <ToastBody>
                <p>Instructions how to setup Time-based One-time Password (TOTP)</p>
                <p>1. On your mobile device, open the Google Authenticator app.</p>
                <p>2. Use your device’s camera to scan the QR code that appears on the page.</p>
              </ToastBody>
            </Toast>
            <br />
            <QRCode value={qrCode} />
            <p>{qrCodePure}</p>
            <Input placeholder="TOTP Code" onChange={(e) => setChallengeAnswer(e.target.value)} />
            <br />
            <Button
              color="success"
              className="btn-pill"
              outline
              size="sm"
              disabled={!challengeAnswer}
              onClick={async () => {
                setPreferredMFA({
                  authType: "TOTP",
                  user,
                  challengeAnswer,
                  addToast,
                });
                await updateUserPreferredMFA({
                  preferredMFA: "TOTP",
                  user,
                });
                setCurrentMFA(PREFERRED_MFA["SOFTWARE_TOKEN_MFA"]);
                setQRCode("");
              }}
            >
              Submit Code
            </Button>
          </div>
        )}
      </>
    ),
  });

  return card?.display;
};

export default LoginSetupMfa;
