import { generateGraphql } from "@rivial-security/generategraphql";

export const listEvidenceActivitiesByOwnerGroupForArtifact: string = generateGraphql(
  "EvidenceActivity",
  ["evidenceID", "createdAt", "type", "pointOfContact", "data", "artifact", "documents"],
  {
    pointOfContact: `{
      id
      firstName
      lastName
    }`,
    artifact: `{
      id
      name
      file {
        bucket
        key
        region
      }
      ownerGroup
    }`,
    documents: `(limit: 950) {
      items {
        id
        name
        ownerGroup
        visibility
        avStatus
        lastAVCheck
        createdAt
        file {
          bucket
          region
          key
        }
        accessControl {
          password
          passwordOwnerEmail
          pointOfContacts
          roles
        }
      }
    }`,
  },
  {
    indexName: "evidenceActivitiesByOwnerGroup",
    partitionKey: "ownerGroup",
  },
).listByQuery;
