import { useCallback, useContext } from "react";

import { UIContext } from "@utils/Context/UIContext";

import { helpCenterUrl } from "../../../env-config";

/**
 * Opens the Help Center to a specific article based on the route or overrideUrl provided when called
 * @param {string} helpCenterRoute - the route of the article to open
 * @param {string} overrideUrl - overrides the base help center url to open a specific article
 * @return {(function(string=): void)|*}
 * example usage:
 * overriding the base url:
 * const openArticle = useOpenArticle({
 *  helpCenterRoute: "",
 *  overrideUrl: "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-UpdateResourceAutomation"
 *  });
 *
 *  passing in a route:
 *  const openArticle = useOpenArticle({
 *  helpCenterRoute: "automation-steps", //this will open the base url (https://rivial-helpcenter.scrollhelp.site/hcenter/) + the route passed in (automation-steps)
 *  });
 */
export function useOpenArticle({ helpCenterRoute, overrideUrl }) {
  const { helpCenter } = useContext(UIContext);

  return useCallback(() => {
    const newLink = overrideUrl || helpCenterUrl + helpCenterRoute;
    helpCenter?.setHelpCenterLink(newLink);
    helpCenter?.setIsOpen(true);
  }, [helpCenter, overrideUrl, helpCenterRoute]);
}
