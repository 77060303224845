import { NavigationBarModule, NavigationBarPage } from "@components/Molecules/NavigationBar/types";

export interface AppendCustomToolLinksParams {
  toolsNavigationBarModule?: NavigationBarModule;
  customToolLinks: NavigationBarPage[];
}

export const appendCustomToolLinks = ({
  toolsNavigationBarModule,
  customToolLinks,
}: AppendCustomToolLinksParams): void => {
  if (!toolsNavigationBarModule) {
    return;
  }

  let configurationPageIndex = toolsNavigationBarModule.pages?.findIndex((page) =>
    page.link.includes("/configuration"),
  );
  if (configurationPageIndex !== -1) {
    const pagesAmount = toolsNavigationBarModule.pages?.length;
    if (pagesAmount && pagesAmount > 1) {
      configurationPageIndex = pagesAmount - 1;
    } else {
      configurationPageIndex = 0;
    }
  }
  for (const customToolLink of customToolLinks) {
    if (!toolsNavigationBarModule.pages) {
      toolsNavigationBarModule.pages = [];
    }

    toolsNavigationBarModule.pages?.splice(configurationPageIndex, 0, customToolLink);
  }
};
