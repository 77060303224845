import { ErrorLogger } from "@utils/EventLogger";

import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * Removes a single questionnaire assignee and their tasks
 * @param {object} questionnaireAssignee - the questionnaire assignee to remove from any associated questionnaire (must have id)
 * @param {object} config - configuration object
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<void>}
 */
export const deleteQuestionnaireAssignee = async (questionnaireAssignee, config = {}, onJobStarted) => {
  if (!questionnaireAssignee?.id) {
    ErrorLogger(
      "[deleteQuestionnaireAssignee] Invalid input cannot delete questionnaire assignee!",
      JSON.stringify({ questionnaireAssignee }),
    );
    return null;
  }

  /**
   * Delete QuestionnaireAssignee in Fargate
   */
  return await deepDelete({
    typename: "QuestionnaireAssignee",
    item: questionnaireAssignee,
    config,
    onJobStarted,
  });
};
