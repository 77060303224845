import React, { ReactElement, useCallback, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import ModalWrapper from "./useModal/components/ModalWrapper";

export interface UseModalReturnType {
  modal: ReactElement;
  modalButton: ReactElement;
  modalIsOpen: boolean;
  toggleModal: () => void;
  setModalIsOpen: (isOpen: boolean) => void;
}

/**
 * Description: A Custom Hook.
 *
 * Creates a Generic modal. The modal can be used with/without a button.
 * Header is optional based on title
 *
 * config: {
 *   width,             - how wide the modal is
 *   cornerModal,       - TRUE if modal should appear in the top right corner
 *   clearBackdrop,     - TRUE if no black background is not needed when modal is opened
 *                        this allows to still dismiss the modal on outside click
 *   onClosed           - function to execute when the modal is closed
 *   onModalButtonClick - function to call when the modal button is clicked
 *   dataTestId         - data test id for the modal
 *   disableCloseModal  - if TRUE user cannot close the modal
 * }
 */
export const useModal = (
  header: string | ReactElement,
  body: ReactElement, // the component to render between header and footer
  button?: ReactElement, // button to open the modal
  config?: {
    width?: string;
    cornerModal?: boolean;
    clearBackdrop?: boolean;
    onClosed?: () => void;
    onModalButtonClick?: () => void;
    dataTestId?: string;
    disableCloseModal?: boolean;
    disableClickToggle?: boolean;
    isMobile?: boolean;
    isDisabled?: boolean;
  },
  footer?: ReactElement,
): UseModalReturnType => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const disableClickToggle = config?.disableClickToggle;
  const disableCloseModal = config?.disableCloseModal;

  const toggleModal = (): void => {
    setModalIsOpen((bool) => !bool);
  };

  const toggle = (): void => {
    if (disableClickToggle || disableCloseModal) {
      return;
    }
    setModalIsOpen(!modalIsOpen);
  };

  const getHeader = (): string | ReactElement => {
    if (!header) {
      return "";
    }

    if (typeof header === "string") {
      return header;
    }

    return React.cloneElement(header, {
      toggleModal: () => setModalIsOpen(false),
    });
  };

  const modal = (
    <ModalWrapper
      isOpen={modalIsOpen}
      dataTestId={config?.dataTestId ?? "modal"}
      onClosed={config?.onClosed}
      toggle={() => toggle()}
      clearBackdrop={config?.clearBackdrop}
      cornerModal={config?.cornerModal}
      isMobileConfig={config?.isMobile}
      width={config?.width}
      header={
        <ModalHeader
          toggle={!disableCloseModal ? (): void => setModalIsOpen(!modalIsOpen) : undefined}
          data-testid={"modal-header"}
          className={"dashboard-title"}
        >
          {getHeader()}{" "}
        </ModalHeader>
      }
      body={
        <ModalBody>
          {
            // force the modal body to unmount when the modal closes
            modalIsOpen &&
              React.cloneElement(body, {
                toggleModal: () => setModalIsOpen(false),
              })
          }
        </ModalBody>
      }
      footer={footer ? <ModalFooter>{footer}</ModalFooter> : undefined}
    />
  );

  const modalButton = (
    <>
      {button ? (
        React.cloneElement(button, {
          onClick: (e: Event) => {
            e?.stopPropagation();
            !config?.isDisabled && setModalIsOpen(!modalIsOpen);
            config?.onModalButtonClick?.();
          },
        })
      ) : (
        <Button onClick={() => !config?.isDisabled && setModalIsOpen(!modalIsOpen)}>Open</Button>
      )}
      {modal}
    </>
  );

  return {
    modal,
    modalButton,
    modalIsOpen,
    toggleModal,
    setModalIsOpen: useCallback((isOpen) => setModalIsOpen(isOpen), []),
  };
};
