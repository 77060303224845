"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkSystemForRiskOverride = void 0;
const checkSystemForRiskOverride = (overridesArray, risk) => {
    if (overridesArray && overridesArray.length > 0) {
        // If a Specific Risk is included in the system.risks array, find it
        return overridesArray.find((item) => item?.riskId === risk?.id);
    }
    return false;
};
exports.checkSystemForRiskOverride = checkSystemForRiskOverride;
