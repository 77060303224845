import { GetQuery } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * Retrieve up-to-date label list from the database
 * @param artifactID - the artifact for which to retrieve label links
 * @returns {Promise<*|null>}
 */
export const retrieveUpToDateLabelLinks = async ({ artifactID }) => {
  try {
    if (!artifactID) {
      throw Error("Artifact ID is required!");
    }

    const newArtifact = await GetQuery({
      query: getArtifactLabelsQuery,
      variables: { id: artifactID },
    });
    return newArtifact?.labels?.items;
  } catch (e) {
    ErrorLogger("Unable to retrieve up-to-date label links!", e);
    return null;
  }
};

const { getQuery: getArtifactLabelsQuery } = generateGraphql("Artifact", ["labels"], {
  labels:
    "(limit: 500) { items { id bookmarks { id createdByAI owner text data } label { id name description backgroundColor fontColor } } }",
});
