"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDuplicateObjects = void 0;
/**
 * @function
 * @description Keeps the first instance of an object but removes all subsequent instances
 * @param arr {Array}
 */
const removeDuplicateObjects = (arr) => {
    if (!arr) {
        return null;
    }
    return arr.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
};
exports.removeDuplicateObjects = removeDuplicateObjects;
