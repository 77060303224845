"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scannerFields = void 0;
const Scanner_1 = require("../../constants/Scanner");
const nessusScannerFields_1 = require("./nessusScannerFields");
const openvasScannerFields_1 = require("./openvasScannerFields");
const qualusScannerFields_1 = require("./qualusScannerFields");
const rapid7ScannerFields_1 = require("./rapid7ScannerFields");
exports.scannerFields = {
    [Scanner_1.Scanner.RAPID7]: rapid7ScannerFields_1.rapid7ScannerFields,
    [Scanner_1.Scanner.NESSUS]: nessusScannerFields_1.nessusScannerFields,
    [Scanner_1.Scanner.QUALYS]: qualusScannerFields_1.qualysScannerFields,
    [Scanner_1.Scanner.OPENVAS]: openvasScannerFields_1.openvasScannerFields,
};
