import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useEvidenceDataGrid } from "../hooks/useEvidenceGrid/useEvidenceDataGrid";

/**
 * Component instance of the useEvidenceDataGrid hook
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceDataGrid = (props) => useEvidenceDataGrid(props).display;

export default withOrganizationCheck(EvidenceDataGrid);
