import { useDashboardCard } from "../hooks/useDashboardCard";

/**
 * Component instance of the useDashboardCard hook
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardCard = (props) => {
  return useDashboardCard(props).display;
};

export default DashboardCard;
