import { format } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { listUserEvents } from "../../../../application/Toasts/functions/listUserEvents";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { eventType } from "../../../Notifications/enums/eventType";

import UserActivityList from "./UserActivityList";

/**
 * Displays User Activity in an Accordion grouped and sorted by Day
 *
 * @param {object} item - the point of contact item to query Activity for
 * @param {object[]} [activityParam] - optionally override the query with external state
 * @returns {JSX.Element}
 * @constructor
 */
const UserActivityAccordion = ({ item, activity: activityParam }) => {
  const [activityItems, setActivityItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState(activityParam || []);

  //Get activity data on first loading the component
  const retrieveActivityData = async () => {
    if (!item) {
      setActivity([]);
      return;
    }

    setIsLoading(true);

    const { getQuery } = generateGraphql("PointOfContact", ["user"], {
      user: `{ id }`,
    });

    const pointOfContact = await ItemQuery(getQuery, item.id);

    let activityResult = [];
    if (pointOfContact?.user?.id) {
      activityResult = await listUserEvents({
        userID: pointOfContact.user.id,
        eventType: eventType.ACTIVITY,
        fields: ["id", "updatedAt", "header", "data"],
      });
    }

    setActivity(activityResult);
    setIsLoading(false);
  };

  /**
   * Sets the activity state. Queries Activity for a Point of Contact or uses the external override param
   */
  useEffect(() => {
    if (!isNullOrUndefined(activityParam)) {
      setActivity(activityParam);
    } else {
      if (!isNullOrUndefined(item)) {
        setIsLoading(true);
        retrieveActivityData()
          .catch((e) => ErrorLogger("Error: could not retrieve activity data", e))
          .finally(() => setIsLoading(false));
      }
    }
  }, [item]);

  const accordion = useAccordion({
    items: activityItems,
  });

  /**
   * Converts query data for the accordion format
   */
  useEffect(() => {
    if (!isNullOrUndefined(activity)) {
      try {
        const res = [];

        const result = _.groupBy(activity, (activityItem) => {
          if (!isNullOrUndefined(activityItem?.updatedAt)) {
            return format(new Date(activityItem.updatedAt), "PPPP"); // ex. Friday, April 29th, 2021
          }
        });

        for (const [key, value] of Object.entries(result)) {
          res.push({
            title: key,
            activity: value,
            component: <DayComponent />,
          });
        }

        setActivityItems(res);
      } catch (e) {
        ErrorLogger("Error: could not set User Activity Accordion Items", e);
      }
    }
  }, [activity]);

  return (
    <DataLoader isEnoughData={true} isLoading={isLoading}>
      {accordion.display}
    </DataLoader>
  );
};

/**
 * Displays the User Activity List for a specific Day Accordion
 * @param activity
 * @returns {JSX.Element}
 * @constructor
 */
const DayComponent = ({ activity }) => {
  return (
    <span>
      <UserActivityList activity={activity} />
    </span>
  );
};

export default UserActivityAccordion;
