/**
 * Operators allowed for when free entry for the field input is allowed
 */
export const freeSoloOperators = [
  {
    label: "equal to",
    value: "equals",
  },
  {
    label: "not equal to",
    value: "notEquals",
  },
  {
    label: "contains",
    value: "contains",
  },
  {
    label: "does not contain",
    value: "notContains",
  },
  {
    label: "starts with",
    value: "startsWith",
  },
  {
    label: "ends with",
    value: "endsWith",
  },
  {
    label: "is empty",
    value: "isEmpty",
  },
  {
    label: "is not Empty",
    value: "isNotEmpty",
  },
  {
    label: "is any of",
    value: "isAnyOf",
  },
  {
    label: "numeric equals to",
    value: "=",
  },
  {
    label: "numeric not equal to",
    value: "!=",
  },
  {
    label: "greater than",
    value: ">",
  },
  {
    label: "greater or equal to",
    value: ">=",
  },
  {
    label: "less than",
    value: "<",
  },
  {
    label: "less than or equal to",
    value: "<=",
  },
];
