import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useMitreAttackMitigationDetails } from "../hooks/useMitreAttackMitigationDetails";

/**
 * @description Display Mitre Attack Mitigation details component
 * @param {object} props - React props for the component
 * @returns {JSX.Element}
 * @constructor
 */
const MitreAttackMitigationDetails = (props) => useMitreAttackMitigationDetails(props).display;

export default withDetailsPage(MitreAttackMitigationDetails, {
  title: "Mitigation Details",
});
