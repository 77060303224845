import { GetQuery } from "@rivial-security/appsync-utils";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { Playbook, ResponseSystemLink, ResponseTeam } from "@rivial-security/schema-types";

type RecordWithID = Record<string, unknown> & { id: string };

export type GetItemRequests = Promise<
  Partial<ResponseTeam> | Partial<Playbook> | Partial<ResponseSystemLink> | undefined
>[];

export interface GetItemRequestsParams {
  grid: {
    selectedData: RecordWithID[];
  };
  getQuery: string;
  existing: Record<string, boolean>;
  added: Record<string, boolean>;
  getSelectedItems?: (items: RecordWithID[]) => { id: string }[];
  getAddedID?: (item: RecordWithID) => string;
}

/**
 * Collects request to the backend only if the items are not already in the existing item set
 * @param grid - grid hook reference
 * @param getQuery - the get query that is needed to query the grids individual items
 * @param existing - items already added to the playbook
 * @param added - items that will be added to the playbook or are already added
 * @param [getSelectedItems] - custom function for getting actual parent resource items hosting the 'procedures' -> [ProcedureGroup] field
 * NOTE: this one is only currently used for systems as the grid actually shows systems not SystemResponse links
 * @param [getAddedID] - custom function for finding the unique id of the procedure group parent resource
 */
export const getItemRequests = ({
  grid,
  getQuery,
  existing,
  added,
  getSelectedItems,
  getAddedID = (item) => {
    return item?.id;
  },
}: GetItemRequestsParams): GetItemRequests => {
  let items = grid?.selectedData;
  if (getSelectedItems) {
    items = getSelectedItems(items);
  }

  const requests: GetItemRequests = [];
  if (!isNonEmptyArray(items)) {
    return requests;
  }

  for (const item of items) {
    const addedItemID = !isNullOrUndefined(getAddedID) ? getAddedID(item) : item?.id;
    const itemID = item?.id;

    const itemIsNotAdded = !added?.hasOwnProperty(addedItemID);
    const itemIsNotExisting = !existing?.hasOwnProperty(addedItemID);

    if (addedItemID && itemIsNotAdded) {
      added[addedItemID] = true;

      if (itemIsNotExisting) {
        requests.push(
          GetQuery({
            query: getQuery,
            variables: {
              id: itemID,
            },
          }),
        );
      }
    }
  }
  return requests;
};
