/**
 * Resources that do not represent a type in the schema but still have
 * associated functions, icons and so on
 */
export const genericResources = {
  RESOURCE_PATH: "ResourcePath",
  RESOURCE: "Resource",
  OBJECT: "Object",
  STRING: "String",
  ID: "ID",
  INT: "Int",
  FLOAT: "Float",
  BOOLEAN: "Boolean",
  AWS_DATE_TIME: "AWSDateTime",
  DYNAMIC: "Dynamic",
  ENUM: "Enum",
};
