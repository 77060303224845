import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";

/**
 * Notes for an AuditControlLink
 * @param {object} item - the audit control link object created in AuditControls.js
 * @param {string} organizationID - the selected organization id
 * @param {function} optimisticUpdate - optimistic update function that will update other components in Audit details
 * @param {string} auditID - the ID of the audit, used to attach Observations to the Audit when making through a Note
 * @param {function} onCreateObservation - callback function after observation is created from a NOte
 * @param {number} maxInitialNotes - maximum number of notes to show before hiding the rest behind a "Show More" button, -1 for no limit
 * @returns {JSX.Element}
 */
const AuditControlNotes = ({
  item,
  organizationID,
  optimisticUpdate,
  auditID,
  onCreateObservation,
  maxInitialNotes,
}) => {
  //Notes queries and mutations
  const { updateMutation } = generateGraphql("AuditControlLink", ["notes"], {
    notes: "{ id type ownerGroup author timeStamp content tag observationID }",
  });

  //Role permissions setup
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;

  const handleNote = (item) => {
    optimisticUpdate?.({
      mutation: false,
      id: item?.id,
      typename: "AuditControlLink",
      value: item?.notes,
      field: "notes",
    });
  };

  //The notes UI
  return (
    <NotesV2
      item={item?.auditControlLink}
      module={module}
      resource={resource}
      maxInitialNotes={maxInitialNotes || 1}
      tooltip="Notes for this Control in context of this Audit"
      organizationID={organizationID}
      mutation={updateMutation}
      disableTitle={true}
      updateItemById={handleNote}
      auditID={auditID || item?.auditControlLink?.auditID}
      complianceControlID={item?.id}
      onCreateObservation={onCreateObservation}
      observationConnectionField={"auditControlLinkID"}
    />
  );
};

export default withOrganizationCheck(AuditControlNotes);
