import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Check function params for null or undefined
 * @param {object} params - object with params to check
 * @param {string} fileName - file name without extension
 * @param {string} errorMessage - custom error message
 */
export const checkParams = ({ params = {}, errorMessage, fileName = "" }) => {
  for (const property in params) {
    if (isNullOrUndefined(params[property])) {
      throw new Error(errorMessage ? errorMessage : `Param: "${property}" is null or undefined [${fileName}]`);
    }
  }
};
