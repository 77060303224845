import { makeStyles } from "@mui/styles";

/**
 * Single source of truth for all Automation builder styles
 * @type {(props?: any) => ClassNameMap<"button"|"icon"|"label">}
 */
export const useCreateAutomationStyles = makeStyles((theme) => ({
  button: {
    height: "10em",
    width: "10em",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
  },
}));
