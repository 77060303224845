import DescriptionIcon from "@mui/icons-material/Description";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactElement, useState } from "react";

import { useAntivirusGate } from "../../../hooks/functional/useAntivirusGate";
import { DocumentPreview } from "../../../hooks/views/useDocumentGrid/customFields/DocumentPreview";
import { useModal } from "../../../hooks/views/useModal";
import { S3FileType } from "../../Functions/S3Storage/types/S3FileType";
import { DisabledWrapper } from "../DisabledWrapper";

export interface MuiPreviewButtonType {
  id?: string;
  downloadFunction?: ({ bucket, key }: { bucket?: string; key?: string }) => Promise<string>;
  s3File?: S3FileType;
  avStatus: string;
  itemDisplayName?: string;
  showDisplayNameInButtonText?: boolean;
  size?: "small" | "medium" | "large";
}

const MuiPreviewButton = ({
  id = "preview-button",
  downloadFunction,
  s3File,
  avStatus,
  itemDisplayName = "Document",
  showDisplayNameInButtonText = true,
  size = "small",
}: MuiPreviewButtonType): ReactElement => {
  const [url, setUrl] = useState<string>("");

  const onUrlReceived = ({ url: newUrl }: { url: string }): void => {
    if (!newUrl) {
      return;
    }

    setUrl(newUrl);
    previewHook.setModalIsOpen(true);
  };

  const { isGettingDownloadLink, isDownloadable, cannotDownloadReason, beginDownload, confirmUnsafeDownload } =
    useAntivirusGate({
      downloadFunction,
      s3File,
      avStatus,
      itemDisplayName,
      onUrlReceived,
      operationName: "preview",
    });

  const previewHook = useModal(
    `Preview ${itemDisplayName}`,
    <DocumentPreview url={url} organizationID={s3File?.organizationID ?? ""} />,
    undefined,
    { width: "90vw" },
  );

  const getButtonText = (): string => {
    const itemSuffix = showDisplayNameInButtonText ? ` ${itemDisplayName}` : "";
    if (isGettingDownloadLink) {
      return `Loading${itemSuffix}...`;
    } else {
      return `Preview${itemSuffix}`;
    }
  };

  return (
    <>
      {confirmUnsafeDownload.modal}
      {previewHook.modal}
      <DisabledWrapper greyscalePercentage={0} isDisabled={!isDownloadable} message={cannotDownloadReason()}>
        <LoadingButton
          id={id}
          data-testid={id}
          loading={isGettingDownloadLink}
          loadingPosition={"start"}
          color={"success"}
          size={size}
          variant={"text"}
          title={`Open this ${itemDisplayName} inside the platform`}
          startIcon={<DescriptionIcon />}
          disabled={!isDownloadable}
          onClick={() =>
            beginDownload({
              userWantsToDownloadUnsafeFile: false,
            })
          }
        >
          {getButtonText()}
        </LoadingButton>
      </DisabledWrapper>
    </>
  );
};

export default MuiPreviewButton;
