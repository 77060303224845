import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useMeetingParticipants } from "../hooks/useMeetingParticipants/useMeetingParticipants";

/**
 * @description Display meeting participants
 * @param {object} item - meeting object to display with d property
 * @param {function} resetFunction - reset state function to update parent component state
 * @param {string} organizationID - the database identifier of the currently selected organization
 * @returns {JSX.Element}
 */
const MeetingParticipants = ({ item, resetFunction, organizationID }) => {
  const meetingParticipants = useMeetingParticipants({
    meetingID: item?.id,
    organizationID,
    resetFunctionExternal: resetFunction,
  });

  return meetingParticipants.display;
};

export default withOrganizationCheck(MeetingParticipants);
