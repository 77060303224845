import { QueryGetItem } from "../../../hooks/graphql/useQueryGetItem";

import { GenericDeleteGQL } from "./GenericDeleteGQL";

/**
 * Uses the genericDelete lambda to delete an item and any nested items using graphql
 * @param graphql
 * @param item
 */
export const deleteItem = async (graphql, item) => {
  if (!item || !item.id) {
    return null;
  }

  return await QueryGetItem({ query: graphql, itemId: item.id }).then(async (objectToDelete) => {
    await GenericDeleteGQL({ objectToDelete });
  });
};
