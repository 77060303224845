/**
 * Preset configuration option for decimals input and validation from 0.0 to 1.0, to be used with useForm.js hook
 * NOTE: this approach allows reusing existing form input types while also simplifying code in various create forms
 */
export const decimalFormFieldConfig = {
  inputType: "number",
  defaultValue: 0.0,
  numberConfig: {
    min: 0.0,
    max: 1.0,
    step: 0.1,
  },
  validationFunction: (value) => {
    try {
      const numValue = Number(value);
      if (numValue < 0.0 || numValue > 1.0) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  },
  validationText: "Please enter a number between 0.0 and 1.0",
};
