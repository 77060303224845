import React, { useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";

const updateActionItemNotes = /* GraphQL */ `
  mutation UpdateGenericActionItem($input: UpdateGenericActionItemInput!) {
    updateGenericActionItem(input: $input) {
      id
      ownerGroup
      notes {
        id
        observationID
      }
    }
  }
`;

const getActionItemNotes = /* GraphQL */ `
  query GetGenericActionItem($id: ID!) {
    getGenericActionItem(id: $id) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

/**
 * Displays Notes for an Action Item
 * @param item
 * @param organizationID
 * @param disableTitle
 * @returns {JSX.Element}
 * @constructor
 */
const ActionItemNotes = ({ item, organizationID, disableTitle, disableRoleChecking }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;

  const query = useQueryGetItem({
    itemId: item.id,
    query: getActionItemNotes,
    module,
    resource,
    disableRoleChecking,
  });

  /**
   * Reset the query to handle subscriptions
   */
  useEffect(() => {
    if (!query.isLoading) {
      query.reset();
    }
  }, [item]);

  return (
    <NotesV2
      disableTitle={disableTitle}
      item={query.item}
      module={module}
      resource={resource}
      tooltip="General Notes for this Action Item"
      organizationID={organizationID}
      mutation={updateActionItemNotes}
      resetFunction={query.reset}
      disableRoleChecking={disableRoleChecking}
    />
  );
};

export default withOrganizationCheck(ActionItemNotes);
