export const moduleTypes = [
  "governance",
  "risk",
  "compliance",
  "vulnerabilities",
  "incidentResponse",
  "training",
  "vendors",
  "tools",
];

export const MODULE_TYPES = {
  COMPLIANCE: "compliance",
  RISK: "risk",
  GOVERNANCE: "governance",
  VULNERABILITIES: "vulnerabilities",
  INCIDENT_RESPONSE: "incidentResponse",
  TRAINING: "training",
  VENDOR: "vendors",
  TOOLS: "tools",
  REPORT: "report",
  UNKNOWN: "unknown",
};
