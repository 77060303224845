import Tooltip from "@mui/material/Tooltip";
import React from "react";

/**
 * Displays a Tooltip where text is underlined
 * @param {React.ReactElement} children - The content of the Tooltip
 * @param {string} tooltip - The tooltip text
 * @param {function} onClick - The function to call when the tooltip is clicked
 * @param {object} [style] - The style of the Tooltip
 */
const UnderlinedTooltip = ({ children, tooltip, style = {} }) => {
  return (
    <Tooltip
      interactive
      title={tooltip}
      style={{
        borderBottom: "2px dashed",
        textDecoration: "none",
        cursor: "pointer",
        ...style,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default UnderlinedTooltip;
