import { useContext, useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";

import { OrganizationContext } from "../../../utils/Context/OrganizationContext";
import { ItemQuery } from "../../../utils/Functions/Graphql/ItemQuery";
import { getPointOfContact_minimal } from "../../../views/OrganizationManager/PointOfContacts/graphql/__pointOfContactGQL";

/** @returns {import ('./types/useUserInfo').UserUserInfoReturn} */
export const useUserInfo = ({ routes }) => {
  const context = useContext(OrganizationContext);

  const [pointOfContactObject, setPointOfContactObject] = useState(null);
  const [loggedInPointOfContactId, setLoggedInPointOfContactId] = useState("");

  const getPointOfContactObject = () => {
    if (!context?.loggedInPointOfContactId) {
      ErrorLogger(
        "Error from Layout.js: Could not get Point of Contact Object. No logged in point of contact id found in context.",
      );
      return;
    }

    ItemQuery(getPointOfContact_minimal, context?.loggedInPointOfContactId).then((pointOfContact) => {
      if (pointOfContact) {
        setPointOfContactObject(pointOfContact);
      }
    });
  };

  //Sets logged in user after routes have been updated (redirects to dashboard for not found urls)
  useEffect(() => {
    if (routes && Array.isArray(routes) && routes.length > 0) {
      setLoggedInPointOfContactId(context?.loggedInPointOfContactId);
    }
  }, [routes, context?.loggedInPointOfContactId]);

  // Performs the initial fetch of the point of contact object
  useEffect(() => {
    if (loggedInPointOfContactId) {
      getPointOfContactObject();
    }
  }, [loggedInPointOfContactId]);

  return {
    pointOfContactObject,
    loggedInPointOfContactId,
  };
};
