import React from "react";

import { LoadingSpinner } from "../../../../../../utils/LoadingComponents/LoadingSpinner";

const MonteCarloStatusIndicator = ({ isLoading, isOutdated, messageOverride }) => {
  return (
    <div style={{ display: "inline-block" }}>
      {isLoading ? <LoadingSpinner loadingMessage="Running Real-Time Risk Simulations.. " /> : null}
      {!isLoading && isOutdated && !messageOverride ? "Input has changed, please re-run simulations" : undefined}
      {messageOverride}
    </div>
  );
};

export default MonteCarloStatusIndicator;
