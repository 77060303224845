/**
 * This enum defines the context where an event occurred in the codebase
 */
export const locationType = {
  GENERIC: "G", // used if no other location is provided
  RENDER: "R", // happened in the render function of a component, used by error boundary
  FUNCTION: "F", // any normal standalone function located in the front-end code

  //These more specific locations take precedence over the above ones
  LAMBDA: "L", // happened inside an AWS hosted function (lambda)
  PACKAGE: "P", // happened inside a Rivial package, takes precedence over all other enums including LAMBDA
};
