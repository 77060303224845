import { Auth } from "@aws-amplify/auth";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Col, Input, Label } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { useModal } from "../../../../hooks/views/useModal";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { useNewPassword } from "../../../../utils/SignIn/hooks/useNewPassword";

/**
 * @description This hook is used to change the password of a user.
 * @param {object} user - The user object
 * @param {string} user.id - The user id
 * @param {string} user.username - The username of the user
 * @returns {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useChangeUserPassword = ({ user } = {}) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSentSuccess, setCodeSentSuccess] = useState(null);

  /**
   * WARNING: We use the same hook for the user first sign in.
   */
  const changePassword = useNewPassword();

  /**
   * Check if the submit button should be enabled
   */
  const isSubmitEnabled =
    changePassword.isPasswordMatched &&
    changePassword.newPassword &&
    changePassword.reEnterPassword &&
    verificationCode;

  const onSubmit = async () => {
    await Auth.forgotPasswordSubmit(user?.username, verificationCode, changePassword?.newPassword)
      .then(async (data) => {
        await ItemMutation(generateGraphql("User", ["lastChangedPasswordDate"]).updateMutation, {
          id: user?.id,
          lastChangedPasswordDate: new Date(),
        });
        ErrorLogger(data);
        setVerificationCode("");
        setCodeSentSuccess(null);
        alert("Successfully changed password");
        modal.setModalIsOpen(false);
      })
      .catch((err) => ErrorLogger(err));
  };

  const modal = useModal(
    "Change Password",
    <div>
      {codeSentSuccess ? (
        <div>
          <Col md={12}>
            <Label>Verification Code</Label>
            <Input
              id={"verification-code"}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Col>
          <br />
          {changePassword.display}
          <Button variant={"contained"} className={"float-right"} disabled={!isSubmitEnabled} onClick={onSubmit}>
            Submit
          </Button>
        </div>
      ) : (
        <div>
          <Alert severity="info" style={{ marginBottom: "1em" }}>
            In order to change your password click the "Send Code" button to receive a verification code in your email.
          </Alert>
          <Button
            variant={"contained"}
            className={"float-right"}
            onClick={() => {
              Auth.forgotPassword(user?.username)
                .then(() => {
                  setCodeSentSuccess(true);
                  InfoLogger("Verification code sent successfully");
                })
                .catch((error) => {
                  ErrorLogger("Error sending verification code", error);
                  alert("Error sending verification code");
                  modal.setModalIsOpen(false);
                });
            }}
          >
            Send Code
          </Button>
        </div>
      )}
    </div>,
    <Button variant="text" size="small">
      Change
    </Button>,
  );

  return {
    ...modal,
  };
};
