import { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

import { useCard } from "@hooks/views/useCard";
import CustomSwitchInput from "@hooks/views/useForm/components/CustomSwitchInput";
import { useEvidenceEmailPreference } from "@views/Settings/SettingPages/InterfaceOptions/functions/useEvidenceEmailPreference";

/**
 * @description Allow users to control whether they want to receive emails when evidence is due
 */
const InterfaceEvidenceEmail = (): JSX.Element => {
  const { getValue, setValue } = useEvidenceEmailPreference();
  const [evidenceEmailOptIn, setEvidenceEmailOptIn] = useState(getValue() ?? true);

  const card = useCard({
    id: "evidence-email",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Receive emails when evidence is due </h4>
        <i id="evidence-email-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="evidence-email-icon-question">
          Warning, disabling this will prevent individual user notifications, it is possible to get a digest email of
          expiring evidences using an automation.
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <CustomSwitchInput
          disabled={false}
          value={evidenceEmailOptIn}
          onChange={(value) => {
            setEvidenceEmailOptIn(Boolean(value));
          }}
        />
        <br />
        <Button
          color="ghost-success"
          className="btn-pill"
          size="sm"
          onClick={async () => await setValue(evidenceEmailOptIn)}
        >
          Update Preference
        </Button>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceEvidenceEmail;
