import { useEffect } from "react";

/**
 * @description Hook that alerts clicks outside the passed ref
 * REFERENCE: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 * @param {React.RefObject} ref - A reference to an element where clicks won't be registered
 * @param {function} onClickOutside - function to call when clicked outside
 */
export const useOutsideAlerter = ({ ref, onClickOutside } = {}) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
