"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRiskControlOverrideFieldEnabled = void 0;
const isRiskControlOverrideFieldEnabled = (override, field) => {
    if (override?.enabledFields !== null && override?.enabledFields !== undefined) {
        if (override.enabledFields.includes(field)) {
            if (override[field] !== null) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    return false;
};
exports.isRiskControlOverrideFieldEnabled = isRiskControlOverrideFieldEnabled;
