"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWidgetTable = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const report_utils_1 = require("@rivial-security/report-utils");
const deRootifyQueryObject_1 = require("../customQueries/deRootifyQueryObject");
const runCustomQueryWithAppSync_1 = require("../customQueries/runCustomQueryWithAppSync");
const getCustomQuery_1 = require("../queries/getCustomQuery");
const formatTableWidgetData_1 = require("../tableWidgets/formatTableWidgetData");
const getTableWidgetFieldNameDictionary_1 = require("../tableWidgets/getTableWidgetFieldNameDictionary");
const getTableWidgetFields_1 = require("../tableWidgets/getTableWidgetFields");
/**
 * Creates the table SFDT block for a report table widget
 * @param {object} widget - widget object containing the settings data for the tale as well as its custom query
 * @param {object} context - the widget container settings
 * @param {function} handleTableWidget - a function which finishes the widget creation by applying generic settings onto the correct medium
 * @param {object} automationContext - object with context to use for smart values
 * @param {object} automationContext.item - the triggering item, if automation was triggered by a resource create/update
 * @param {object} automationContext.steps - result objects from previous automation steps that are referenced in the email template
 * @param {object} automationContext.loop - the loop object, if this email is being sent from a loop
 * @param {object} automationContext.webhookData - the webhook data from the incoming request, if automation was triggered by a webhook
 * @return {Promise<object|string|null>} - for reports, the SFDT block for the report table widget, for emails a string of html
 */
const createWidgetTable = async ({ widget, context, handleTableWidget, automationContext } = {}) => {
    console.log("[createWidgetTable] - createWidgetTable", widget);
    // get widget
    const widgetConfig = (0, func_utils_1.tryParse)(widget?.config);
    const currentSeries = widgetConfig?.series;
    let currentSeriesItem;
    if ((0, func_utils_1.isNonEmptyArray)(currentSeries)) {
        currentSeriesItem = currentSeries[0];
    }
    // get query
    const customQueryId = currentSeriesItem?.customQueryId;
    let customQuery;
    if (customQueryId) {
        customQuery = await (0, getCustomQuery_1.getCustomQuery)({ customQueryId });
    }
    console.log("[createWidgetTable] - ", { customQuery, widgetConfig, currentSeries });
    let { queryConfig: query } = customQuery ?? {};
    const { ownerGroup } = customQuery ?? {};
    // check arguments
    if (!query || !ownerGroup) {
        console.log("[createWidgetTable] - Cannot create a table widget without widget tag information! - ", JSON.stringify({ widget }));
        return null;
    }
    // Parse and run the query config
    query = JSON.parse(query);
    let rawData;
    try {
        rawData = await (0, runCustomQueryWithAppSync_1.runCustomQueryWithAppSync)({
            query,
            organizationID: ownerGroup,
            log: console.log,
        });
    }
    catch (e) {
        console.log("[createWidgetTable] - Error running custom query with AppSync", e);
        rawData = null;
    }
    const derootifiedQuery = (0, deRootifyQueryObject_1.deRootifyQueryObject)({ query });
    const rootTypename = derootifiedQuery?.typename;
    const listData = rawData?.[rootTypename]?.items;
    if (!Array.isArray(listData)) {
        return (0, report_utils_1.createText)("[No Data to Populate Widget Table]");
    }
    // [FORMAT DATA]
    // - header names
    const fieldNameDictionary = (0, getTableWidgetFieldNameDictionary_1.getTableWidgetFieldNameDictionary)({ seriesItem: currentSeriesItem });
    console.log("[createWidgetTable] after getTableWidgetFieldNameDictionary - ", {
        fieldNameDictionary,
        currentSeriesItem,
    });
    // - column order
    // - column visibility
    const fields = (0, getTableWidgetFields_1.getTableWidgetFields)({ query: customQuery, seriesItem: currentSeriesItem });
    console.log("[createWidgetTable] after getTableWidgetFields - ", {
        fields,
        customQuery,
        currentSeriesItem,
    });
    // - field transforms
    // - column filter
    // - sorting
    const data = (0, formatTableWidgetData_1.formatTableWidgetData)({ seriesItem: currentSeriesItem, data: listData, automationContext });
    // [CREATE TABLE]
    if (typeof handleTableWidget === "function") {
        return handleTableWidget({
            tableSettings: currentSeriesItem?.tableSettings,
            fieldSettings: currentSeriesItem?.fieldSettings,
            context,
            fields,
            fieldNameDictionary,
            data,
            automationContext,
        });
    }
    else {
        return null;
    }
};
exports.createWidgetTable = createWidgetTable;
