"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirst = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the first item in the array.
 * @param {*[]} items - array of items
 * @param {boolean} isNumeric - if true, only numeric items will be considered
 * @returns {number|null} - the first item or null if no items were passed
 */
const getFirst = ({ items, isNumeric = true }) => {
    let targetItems = items;
    if (isNumeric) {
        targetItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    }
    if (!Array.isArray(targetItems) || targetItems.length === 0) {
        return null;
    }
    else {
        return targetItems[0];
    }
};
exports.getFirst = getFirst;
