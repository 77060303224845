import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import useListQuery from "../../../hooks/graphql/useListQuery";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../hooks/views/useForm";
import { handleIcon } from "../../../utils/Functions/Icon/getIcon";
import Dashboard from "../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import CustomDashboardDisplay from "../components/CustomDashboardDisplay";
import CustomDashboardForm from "../components/CustomDashboardForm";

/**
 *
 * Queries CustomDashboards for an org with filters,
 * then provides a dropdown menu to select a dashboard.
 *
 * If the 'default' option is selected, then the defaultDashboard is displayed
 * If the 'create' option is selected, then a CustomDashboardForm is displayed
 * If a specific dashboard is selected, then the CustomDashboardDisplay is displayed
 *
 * @param {JSX.Element} defaultDashboard - the default dashboard to display. This is the default option in the dropdown
 * @param {string} toolID - the tool ID to filter the CustomDashboards by
 * @param {string} organizationID - the organization ID to filter the CustomDashboards by
 * @param {string} pageID - the page ID to filter the CustomDashboards by
 * @param {string} title - the title of the dashboard. Used for the dashboardDisplay UI
 * @param {string} icon - the icon of the dashboard. Used for the dashboardDisplay UI
 * @param {string} tourId - the tour ID of the dashboard. Used for the dashboardDisplay UI
 * @param {boolean} isTemplate - whether the tool is a template
 * @param {object[]} defaultWidgets - the default widgets to use for the dashboard layout
 * @param {boolean} disableDefaultDashboard - whether to disable the default dashboard
 * @returns {JSX.Element}
 * @constructor
 */
export const useCustomDashboardSelector = ({
  defaultDashboard,
  toolID,
  pageID,
  organizationID,
  title,
  icon,
  tourId,
  isTemplate,
  defaultWidgets,
  disableDefaultDashboard,
}) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.CUSTOM_DASHBOARD;

  const checkPermissions = useCheckPermissions({
    module,
    resource,
  });

  const { listQuery } = generateGraphql("CustomDashboard", ["name", "toolID", "pageID", "description"]);

  // query CustomDashboard objects,
  // perform filtering based on pageID
  // or perform filtering based on toolID
  const query = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
    otherFiltersInitial: {
      ...(pageID && {
        pageID: {
          eq: pageID,
        },
      }),
      ...(toolID
        ? {
            toolID: {
              eq: toolID,
            },
          }
        : {}),
    },
  });

  const dashboardFormData = [
    {
      text: "Default Dashboard",
      value: "default",
    },
  ];

  const queryList = query?.list;

  if (Array.isArray(queryList)) {
    dashboardFormData.push(
      ...(queryList?.map((dashboard) => ({
        text: dashboard.name,
        value: dashboard.id,
      })) ?? {}),
    );
  }

  if (checkPermissions.resource.create) {
    dashboardFormData.push({
      text: "➕ Add Custom Dashboard",
      value: "custom",
    });
  }

  // displays a dropdown menu to select a dashboard
  // always has a 'default' option
  // and the 'create' option allows a brand new one to be created
  const selectDashboardForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      customDashboard: {
        defaultValue: "default",
        inputType: "dropdown",
        dropdownConfig: {
          showClearButton: false,
          search: true,
          data: dashboardFormData,
        },
      },
    },
  });

  // the currently selected dashboard ID
  const selectedDashboard = selectDashboardForm?.input?.customDashboard;

  /**
   * Callback for when a new dashboard is created
   * This adds the new dashboard to the dropdown menu
   * and selects it
   * @param {object} newDashboard
   * @param {string} newDashboard.id
   * @param {string} newDashboard.name
   */
  const onCreateNewDashboard = (newDashboard) => {
    query.setList((list) => [...list, newDashboard]);

    selectDashboardForm.setInput({
      customDashboard: newDashboard.id,
    });
  };

  const onDeleteDashboard = (dashboardID) => {
    // find the index so we can select the preceding dashboard after deletion
    const index = query.list.findIndex((dashboard) => dashboard.id === dashboardID);

    // select the preceding dashboard
    const previousDashboard = query.list[index - 1];

    // if there is a preceding dashboard, select it
    if (previousDashboard) {
      selectDashboardForm.setInput({
        customDashboard: previousDashboard.id,
      });
    }
    // otherwise, select the default dashboard
    else {
      selectDashboardForm.setInput({
        customDashboard: "default",
      });
    }

    // reset the query so the deleted dashboard is removed from the list
    query.reset();
  };

  /**
   * Resets the list of dashboards when a dashboard is edited
   * @param dashboard
   */
  const onEditDashboard = (dashboard) => {
    if (dashboard?.id && dashboard?.name) {
      query.reset();
    }
  };

  // displays the correct component based on the selected dashboard state
  const display = () => {
    switch (selectedDashboard) {
      case "default":
        return isTemplate ? <span>Default Dashboard is not available in Template mode</span> : defaultDashboard;
      case "custom":
        return (
          <div style={{ width: "100%" }}>
            <DashboardCard
              title={"Create New Dashboard"}
              wrapperStyle={{
                width: "40vw",
                marginLeft: "auto",
              }}
            >
              <CustomDashboardForm
                callback={onCreateNewDashboard}
                toolID={toolID}
                pageID={pageID}
                organizationID={organizationID}
                isTemplate={isTemplate}
                defaultWidgets={defaultWidgets}
              />
            </DashboardCard>
          </div>
        );
      default:
        return (
          <CustomDashboardDisplay
            item={{ id: selectedDashboard }}
            organizationID={organizationID}
            onCreateNewDashboard={onCreateNewDashboard}
            onDeleteDashboard={onDeleteDashboard}
            onEditDashboard={onEditDashboard}
          />
        );
    }
  };

  const dashboardDisplay = (
    <Dashboard
      title={title}
      badge={handleIcon(icon)}
      tourId={tourId}
      headerButtons={[
        <span key={0} style={{ width: "300px" }}>
          {selectDashboardForm.display}
        </span>,
      ]}
    >
      {display()}
    </Dashboard>
  );

  return {
    form: selectDashboardForm,
    display: display(),
    dashboardDisplay,
    onCreateNewDashboard,
    onDeleteDashboard,
  };
};
