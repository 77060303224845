import { ErrorLogger } from "@utils/EventLogger";

export const priorityMap = ({ string, number, formattedText = false }) => {
  const stringToNumber = {
    low: 100,
    lowMedium: 200,
    medium: 300,
    mediumHigh: 400,
    high: 500,
    veryHigh: 600,
  };

  const numberToString = (number) => {
    if (number <= 100) {
      return formattedText ? "Low" : "low";
    }

    if (number <= 200) {
      return formattedText ? "Low - Medium" : "lowMedium";
    }

    if (number <= 300) {
      return formattedText ? "Medium" : "medium";
    }

    if (number <= 400) {
      return formattedText ? "Medium - High" : "mediumHigh";
    }

    if (number <= 500) {
      return formattedText ? "High" : "high";
    }

    if (number > 500) {
      return formattedText ? "Very High" : "veryHigh";
    }
  };

  if (string) {
    if (stringToNumber.hasOwnProperty(string)) {
      return stringToNumber[string];
    } else {
      ErrorLogger("Passed in string priority did not match any of the predefined values!");
      return -1;
    }
  } else if (number) {
    return numberToString(number);
  }
};
