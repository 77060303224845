import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useUIContext } from "@utils/Context/UIContext";

import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { invokeGraphqlFunction } from "../../../../utils/Functions/Graphql/invokeGraphqlFunction";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import Loader from "../../../../utils/LoadingComponents/Loader";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { VENDOR_REVIEW_STATUS } from "../constants/vendorReviewStatus";

/**
 * The GUI for the last step of the vendor review process, allows to toggle the 'status of the vendor review'
 * @param {string} vendorReviewID - the ID of the vendor review to edit
 * @param {function} [getUpdatedItem]- function to update the parent with new status when it is changed
 * @return {JSX.Element} - the UI for the step (view changes with status value and does its own query)
 */
const FinalizeReview = ({ vendorReviewID, getUpdatedItem }) => {
  const { vendorReview, addToContext } = useContext(WorkflowContext);
  const [item, setItem] = useState(null);

  const getData = async () => {
    setItem(null);
    const { getQuery } = generateGraphql("VendorReview", ["status"]);
    const vendorReview = await GetQuery({
      query: getQuery,
      variables: { id: vendorReviewID },
    });
    if (vendorReview?.id) {
      // default the status field to "draft"
      if (!vendorReview.status) {
        vendorReview.status = VENDOR_REVIEW_STATUS.draft;
      }
      setItem(vendorReview);
    } else {
      setItem({});
    }
  };
  useEffect(() => {
    getData();
  }, [vendorReviewID]);

  const { addToast, updateToast } = useUIContext();

  const toggleStatus = async () => {
    //Enter loading state
    setItem(null);

    //Prepare mutation and input
    const { updateMutation } = generateGraphql("VendorReview", ["status"]);
    const status =
      item?.status === VENDOR_REVIEW_STATUS.final ? VENDOR_REVIEW_STATUS.draft : VENDOR_REVIEW_STATUS.final;
    const completionDate = status === VENDOR_REVIEW_STATUS.final ? new Date() : null;

    //Perform mutation
    const updatedItem = await ItemMutation(updateMutation, {
      id: vendorReviewID,
      status,
      completionDate,
    });
    getUpdatedItem && getUpdatedItem(updatedItem);

    // Based on final item values begin automations
    const updatedItemStatus = updatedItem?.status;
    if (updatedItemStatus) {
      const vendorReviewWasFinalized = updatedItemStatus === VENDOR_REVIEW_STATUS.final;
      if (vendorReviewWasFinalized) {
        performToastOperation({
          addToast,
          updateToast,
          operation: async () => {
            invokeGraphqlFunction("Query", "runVendorReviewFinalizationActions", { vendorReviewID });
          },
          inProgressText: `Starting Vendor Review finalization actions...`,
          successText: `Vendor Review Finalization actions started successfully. Please wait a few minutes for resources to update.`,
          failedText: `Failed to start Vendor Review finalization actions`,
          iconColor: "success",
        });
      }

      setItem(updatedItem);
      addToContext("vendorReview", {
        ...vendorReview,
        status: updatedItemStatus,
      });
    } else {
      setItem({});
    }
  };

  /**
   * Convenience method for getting idf the report is finalized
   * @return {null|boolean}
   */
  const isFinal = () => {
    if (item) {
      return item.status === VENDOR_REVIEW_STATUS.final;
    } else {
      return null;
    }
  };

  // [GUI]
  if (item?.status != null) {
    return (
      <div>
        {isFinal() ? (
          <p>
            <span style={{ fontSize: "1.3em" }}>
              <strong>This vendor review has been finalized.</strong>
            </span>
            <br />
            You can make changes again if you turn it back into a draft.
          </p>
        ) : (
          <div>
            <p style={{ fontSize: "1.3em" }}>
              <strong>
                {" "}
                Would you like to finalize this vendor review?
                <br />
              </strong>
            </p>
            <br />
            <p style={{ fontSize: "1.1em", fontStyle: "bold" }}>
              <strong> This will: </strong>
            </p>
            <ul>
              <li>
                <p>Switch this vendor review into a read-only view</p>
              </li>
              <li>
                <p>Dispatch risk changes for any associated systems</p>
              </li>
            </ul>
          </div>
        )}
        <br />
        <Button color={isFinal() ? "error" : "primary"} variant="contained" onClick={() => toggleStatus()}>
          {isFinal() ? "Turn Review back into a Draft" : "Finalize Review"}
        </Button>
      </div>
    );
  } else if (item != null && !item.status) {
    return (
      <div>
        <span>{"Something went wrong. Please try to reload this step."}</span>
      </div>
    );
  } else {
    return (
      <div>
        <span>
          {"Loading Status... "}
          <Loader />
        </span>
      </div>
    );
  }
};

export default FinalizeReview;
