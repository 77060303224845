import React from "react";

/**
 * Displays timeline information about an Evidence being switched to the 'expiring soon' status
 * @returns {JSX.Element}
 * @constructor
 */
const ExpiringSoonActivity = (props) => {
  return <span style={{ fontSize: ".9em" }}>Evidence is Expiring Soon</span>;
};

export default ExpiringSoonActivity;
