import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useJobsGrid } from "../../Jobs/hooks/useJobsGrid";

/**
 * Displays a grid of Jobs for a single Automation effectively showing automation execution history
 * @param {string} itemId - the automation ID
 * @param {string} organizationID - the currently selected organization ID
 * @param {string} module  - the module name (e.g. "organizationManager")
 * @param {string} resource - the resource name (e.g. "Automation")
 * @returns {JSX.Element}
 */
const AutomationJobsGrid = ({ itemId, organizationID, module, resource }) => {
  return useJobsGrid({
    module,
    resource,
    organizationID,
    itemId,
    additionalFields: [
      {
        name: "completedAt",
        flex: 0.75,
        type: "date",
      },
      {
        name: "source",
        flex: 0.75,
        visible: false,
      },
    ],
  }).display;
};

export default withOrganizationCheck(AutomationJobsGrid);
