import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useVendorSubControlDetails } from "../hooks/useVendorSubControlDetails";

/**
 * Component instance of the useVendorSubControlDetails hook
 * @param props
 * @returns {*}
 * @constructor
 */
const VendorSubControlDetails = (props) => {
  return useVendorSubControlDetails(props)[props.tableDisplay ? "tableDisplay" : "display"];
};

export default withDetailsPage(VendorSubControlDetails, {
  title: "Vendor Sub-Control Details",
});
