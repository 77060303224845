export const listRisksControlCategoriesByOwnerGroup = /* GraphQL */ `
  query RiskControlCategoryByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRiskControlCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riskControlCategoryByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        description
        subControls {
          items {
            id
            name
            statementNumber
            costOfControl
            implementationRating
            strengthRating
          }
        }
      }
      nextToken
    }
  }
`;
