import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose } from "react-apollo";
import { Alert, Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { formattedDollar } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import {
  checkSystemForRiskOverride,
  findIndexOfRiskOverride,
  isRiskOverrideFieldEnabled,
} from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { GenericModalWithButton } from "../../../../../../utils/GenericComponents/GenericModalWithButton";
import LowerLossAmount from "../../SystemAccordion/ThreatAssessment/components/LowerLossAmount";
import StandardLossAmount from "../../SystemAccordion/ThreatAssessment/components/StandardLossAmount";
import { disableRiskOverride, enableRiskOverride, updateRiskOverride } from "../functions/OverrideFunctions";

const { updateMutation: updateSystem, getQuery: getSystem } = generateGraphql("System");

const RiskOverridesV2 = (props) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const checkPermissions = useCheckPermissions({ module, resource });

  const indexOfRiskOverride =
    props.system && props.system.riskOverrides ? findIndexOfRiskOverride(props.system.riskOverrides, props.risk) : -1;

  const initialValue =
    props.risk &&
    props.system &&
    props.system.riskOverrides &&
    props.system.riskOverrides.length > 0 &&
    indexOfRiskOverride !== -1 &&
    props.system.riskOverrides[indexOfRiskOverride] &&
    props.system.riskOverrides[indexOfRiskOverride][props.field];

  const [value, setValue] = useState(initialValue ? initialValue : "");

  const doesOverrideExist =
    checkSystemForRiskOverride(props.system && props.system.riskOverrides, props.field) !== false;

  const isOverrideEnabled =
    doesOverrideExist && isRiskOverrideFieldEnabled(props.system.riskOverrides[indexOfRiskOverride], props.field);

  const overrideValue =
    doesOverrideExist &&
    props.system.riskOverrides[indexOfRiskOverride] &&
    props.system.riskOverrides[indexOfRiskOverride][props.field];

  const updateSystem = async (input) => {
    const data = props.updateSystem(input);
    setTimeout(() => {
      props.resetFunction && props.resetFunction();
    }, 1000);
    return data;
  };

  return (
    <>
      {!isOverrideEnabled ? (
        props.field === "confidenceIntervalUpper" ? (
          <StandardLossAmount system={props.system} risk={props.risk} systemRiskLink={props.systemRiskLink} />
        ) : props.field === "confidenceIntervalLower" ? (
          <LowerLossAmount system={props.system} risk={props.risk} systemRiskLink={props.systemRiskLink} />
        ) : props.field === "annualRateOfOccurrence" ? (
          props.risk && props.risk.annualRateOfOccurrence && `${(props.risk.annualRateOfOccurrence * 100).toFixed(2)}%`
        ) : (
          "No Value"
        )
      ) : null}
      {checkPermissions.resource.update && (
        <GenericModalWithButton
          disableEdits={!checkPermissions.resource.update}
          title={`Risk ID: ${props.risk.id}`}
          modalButton={
            <Badge
              color={isOverrideEnabled ? "success" : "ghost-secondary"}
              pill
              style={{ cursor: props.isAdmin ? "pointer" : undefined }}
              title="Adjust Overrides"
            >
              {doesOverrideExist ? (
                isOverrideEnabled ? (
                  props.field === "annualRateOfOccurrence" ? (
                    overrideValue && `${(overrideValue * 100).toFixed(2)}%`
                  ) : (
                    formattedDollar(overrideValue)
                  )
                ) : (
                  <i className="icon-settings" />
                )
              ) : (
                <i className="icon-settings" />
              )}
            </Badge>
          }
          body={
            <>
              {isOverrideEnabled ? (
                <Alert color="success">
                  This Override is currently Enabled
                  <Button
                    color="danger"
                    className="float-lg-right"
                    onClick={() => disableRiskOverride(props.system, props.risk, props.field, updateSystem)}
                  >
                    Disable
                  </Button>
                </Alert>
              ) : (
                <Alert color="danger">
                  This Override is currently Disabled
                  <Button
                    color="success"
                    className="float-right"
                    onClick={() => enableRiskOverride(props.system, props.risk, props.field, updateSystem)}
                  >
                    Enable
                  </Button>
                </Alert>
              )}
              <hr />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <strong>{` ${props.field} `}</strong>
                  </InputGroupText>
                </InputGroupAddon>
                <Input value={value} onChange={(e) => setValue(e.target.value)} />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => updateRiskOverride(props.system, props.risk, props.field, value, updateSystem)}
                  >
                    Update
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </>
          }
        />
      )}
    </>
  );
};

RiskOverridesV2.propTypes = {
  risk: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  disableEdits: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default compose(graphqlMutation(gql(updateSystem), gql(getSystem), "System"))(RiskOverridesV2);
