import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useAssessmentVulnerabilities } from "../hooks/useAssessmentVulnerabilities";

/**
 * @description Display Findings list for an Assessment component
 * @param {object} props - component props
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentVulnerabilities = (props) => {
  const display = useAssessmentVulnerabilities(props).gridDisplay;
  return <div style={{ height: "30em" }}>{display}</div>;
};

export default withOrganizationCheck(AssessmentVulnerabilities);
