import { formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Returns information on whether the risk curve is above the loss tolerance threshold at the residual loss line.
 * @param {number} lossToleranceDelta - the difference between the loss tolerance and the risk curve (delta = risk - tolerance)
 * @return {{type: string, message: string} || undefined}
 */
export const generateRiskCurveAtResidualLossWarning = ({ lossToleranceDelta }) => {
  //Check arguments
  if (isNullOrUndefined(lossToleranceDelta)) {
    return;
  }

  //Format the delta value for the result string
  const stringDelta = formattedDollar(Math.abs(lossToleranceDelta));

  //Generate the result string
  if (lossToleranceDelta < 0) {
    return {
      type: "success",
      message: `The risk curve is below the risk tolerance curve at the residual risk line by ${stringDelta} of annual risk.`,
    };
  } else {
    return {
      type: "warning",
      message: `The risk curve is above the risk tolerance curve at the residual risk line by ${stringDelta} of annual risk.`,
    };
  }
};
