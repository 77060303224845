import { Redirect, useRouteMatch } from "react-router-dom";

interface RouteParams {
  id: string;
}

interface DynamicRedirectProps {
  to: string;
}

const DynamicRedirect = ({ to }: DynamicRedirectProps) => {
  const match = useRouteMatch<RouteParams>();
  return <Redirect to={to.replace(":id", match.params.id)} />;
};

export default DynamicRedirect;
