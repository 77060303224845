import { getFunctionFieldName } from "@rivial-security/widget-utils";

import { getFieldDefinition } from "../../../../../../../../../definitions/functions/fields/getFieldDefinition";
import { getResourceAttribute } from "../../../../../../../../../definitions/functions/getResourceAttribute";
import { fieldContexts } from "../../../../../../../../../enums/fieldContexts";
import CustomQueryNestedFieldDetails from "../../../components/CustomQueryNestedFieldDetails";
import CustomQueryNestedFieldGrid from "../../../components/CustomQueryNestedFieldGrid";

/**
 * Returns the `fields` and 'customFields` configuration property for data grids based on the
 * @param {object} query - the current nested part of a query object created with query builder
 * @return {{newCustomFields: *[], newFields: *[]}}
 */
export const generateDetailsFieldConfigFromQuery = ({ query }) => {
  const newFields = [];
  const newCustomFields = [];
  const { fields, functions, typename } = query || {};
  const resourceDefinitionFields = getResourceAttribute({
    typename,
    attribute: "fields",
  });

  if (typeof fields === "object") {
    for (const fieldName in fields) {
      const field = fields[fieldName];
      newFields.push(fieldName);

      const fieldDefinition = getFieldDefinition({
        typename,
        fieldName,
        fieldContext: fieldContexts.GRID,
      });
      const DisplayComponent = fieldDefinition?.DisplayComponent;

      if (!field?.isNested) {
        newCustomFields.push({
          field: fieldName,
          type: DisplayComponent ? "custom" : "text",
          component: DisplayComponent ? <DisplayComponent /> : null,
        });
      } else {
        if (field?.hasMany) {
          newCustomFields.push({
            field: fieldName,
            type: "custom",
            component: DisplayComponent ? (
              <DisplayComponent />
            ) : (
              <CustomQueryNestedFieldGrid query={field} field={fieldName} />
            ),
          });
        } else {
          newFields.push({
            field: fieldName,
            type: "custom",
            component: DisplayComponent ? <DisplayComponent /> : <CustomQueryNestedFieldDetails query={field} />,
          });
        }
      }
    }
  }

  //Add function fields
  if (typeof functions === "object") {
    for (const functionName in functions) {
      const functionData = functions[functionName];
      const functionFieldName = getFunctionFieldName({
        pathElement: functionData,
      });
      if (functionFieldName) {
        newFields.push(functionFieldName);
        newCustomFields.push({
          field: functionFieldName,
          type: "text",
        });
      }
    }
  }

  return { newFields, newCustomFields };
};
