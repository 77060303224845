import { isNonEmptyArray } from "@rivial-security/func-utils";

import { genericResources } from "../../../genericResources";

export interface CustomQueryPathElement {
  typename?: string;
}

export interface GetNestedFieldFunctionResultParams {
  config: {
    path: CustomQueryPathElement[];
    aggregationFunction?: string;
  };
}

export interface CustomQueryFunctionResult {
  typename?: string;
  name: string;
  description: string;
}

export const getNestedFieldFunctionResult = ({
  config,
}: GetNestedFieldFunctionResultParams): CustomQueryFunctionResult => {
  const { path: functionPath, aggregationFunction } = config || {};

  let lastFunctionPathElement: CustomQueryPathElement | undefined;
  if (Array.isArray(functionPath) && functionPath.length > 0) {
    lastFunctionPathElement = functionPath[functionPath.length - 1];
  }

  //Figure out the return typename based on the inputs
  let typename = lastFunctionPathElement?.typename ?? genericResources.DYNAMIC;
  if (isNonEmptyArray(functionPath)) {
    const passThroughAggregationFunctions = ["firstValue", "lastValue"];
    const stringResultFunctions = ["combineAsBulletPoints", "combineAsText", "combineNotes"];

    if (aggregationFunction) {
      typename = genericResources.INT;
    }

    if (aggregationFunction && passThroughAggregationFunctions.includes(aggregationFunction)) {
      typename = lastFunctionPathElement?.typename ?? genericResources.DYNAMIC;
    } else if (aggregationFunction && stringResultFunctions.includes(aggregationFunction)) {
      typename = genericResources.STRING;
    }
  }

  return {
    typename,
    name: "nestedValue",
    description: "The value for the nested object, aggregated if necessary.",
  };
};
