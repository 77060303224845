import { formatDistance, isBefore } from "date-fns";
import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { ACTION_STATUSES, actionStatuses } from "../../actionStatuses";

/**
 * Displays the Status for an Action Item
 * @param {object} item - The Action Item to display the Status for.
 * @param {function} updateItemById - The function to update the Action Item in the parent component.
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const Status = ({ item, updateItemById, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;
  const field = "status";

  /**
   * Keeping this for future coloring
   */
  // const actionStatuses = {
  //   proposed: "secondary",
  //   inProgress: "primary",
  //   onHold: "warning",
  //   pastDue: "danger",
  //   complete: "success"
  // }

  const options = actionStatuses.map((status) => {
    return {
      text: convertCamelCaseToSentence(status),
      value: status,
    };
  });

  /**
   * Applies special string formatting to the status field
   * Specifically changing status from camel to sentence case and appending the due date
   * @return {string} the formatted string for the field in question
   */
  const customFormat = () => {
    const statusString = convertCamelCaseToSentence(item.status);

    let pastDueDateString = "";
    if (item.status === ACTION_STATUSES.PAST_DUE && item.dueDate && isBefore(new Date(item.dueDate), new Date())) {
      pastDueDateString = ` (${formatDistance(new Date(item.dueDate), new Date())})`;
    }

    let completedDateString = "";
    if (item.status === ACTION_STATUSES.COMPLETE && item.completionDate) {
      completedDateString = ` (${new Date(item.completionDate).toLocaleDateString()})`;
    }

    return statusString + pastDueDateString + completedDateString;
  };

  /**
   * Sets the completion date if this is marked as `complete`
   * Sets the start date if this action is marked as `inProgress`
   * @param input
   * @returns {*}
   */
  const updateMutationFunction = (input) => {
    if (input.status === "complete") {
      input.completionDate = new Date();
    }

    if (input.status === "inProgress") {
      input.startDate = new Date();
    }

    return input;
  };

  /**
   * Check if user wants to send an email
   */
  const confirmFunction = (input) => {
    if (input === ACTION_STATUSES.IN_PROGRESS || input === ACTION_STATUSES.PAST_DUE) {
      return window.confirm(`This status change will send an email to the point of contact. Do you want to continue?`);
    } else {
      return true;
    }
  };

  return (
    <GenericEditFieldV3
      item={item}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: options,
      }}
      customFormat={customFormat}
      displayConfig={{
        forceSentenceCase: false,
      }}
      confirmFunction={confirmFunction}
      updateMutationFunction={updateMutationFunction}
      field={field}
      mutation={generateGraphql("GenericActionItem", [field, "module"]).updateMutation}
      updateItemById={updateItemById}
      resource={resource}
      module={module}
      {...props}
    />
  );
};

export default Status;
