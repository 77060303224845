import CircularGaugeChart from "../../../../../utils/GenericComponents/Charts/CircularGaugeChart";
import { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Compliance Rating component
 */
const BlackKiteComplianceRating = ({ ComplianceRating }: BlackKiteResponse): JSX.Element => (
  <CircularGaugeChart value={ComplianceRating} valueFormat={"{value}%"} title={"Compliance Rating"} />
);

export default BlackKiteComplianceRating;
