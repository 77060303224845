import { API, graphqlOperation } from "@aws-amplify/api";
import gql from "graphql-tag";

import { checkArguments, isNullOrUndefined } from "@rivial-security/func-utils";

import { InvalidParamError } from "../../../analytics/CustomError";
import { ErrorLogger } from "../../EventLogger";

/**
 * Creates an entry if it doesn't exist and updates if its already in the database
 * @param {string} createMutation
 * @param {string} updateMutation
 * @param {object} input - new data to put into the database, must contain item id
 * @return {Promise<void>}
 * @constructor
 */
export const UpsertMutation = async ({ createMutation, updateMutation, input }) => {
  try {
    checkArguments(
      { createMutation, updateMutation, input },
      {
        createMutation: { type: "string" },
        updateMutation: { type: "string" },
        input: { type: "object" },
      },
    );
  } catch (e) {
    ErrorLogger(e);
  }

  let result;
  try {
    const createResult = await ItemMutation(createMutation, input);
    if (createResult?.id) {
      throw Error("Creating object failed in upsert mutation!");
    }
    result = createResult;
  } catch (e) {
    try {
      const updateResult = await ItemMutation(updateMutation, input);
      if (updateResult?.id) {
        ErrorLogger("Failed to upsert the item!", updateResult);
      }
      result = updateResult;
    } catch (e) {
      ErrorLogger(e);
    }
  }

  return result;
};

/**
 * @description This performs the mutation logic for an upsert operation, the error handling is done by the outside function
 * @param mutation
 * @param input
 * @returns {Promise<*>}
 * @constructor
 */
export const ItemMutation = async (mutation, input) => {
  if (isNullOrUndefined(mutation)) {
    throw new InvalidParamError("mutation", "mutation param cannot be null or undefined");
  }

  if (typeof mutation !== "string") {
    throw new InvalidParamError("mutation", "mutation param must be a string");
  }

  if (isNullOrUndefined(input)) {
    throw new InvalidParamError("input", "input cannot be null or undefined");
  }

  if (typeof input !== "object") {
    throw new InvalidParamError("mutation", "input param must be an object");
  }

  const func = ({ mutation, variables = {} }) => {
    return API.graphql(graphqlOperation(gql(mutation), { input: { ...variables.input } }));
  };

  return await func({
    mutation,
    variables: {
      input: { ...input },
    },
  });
};
