export const listReviewers_PointOfContactPolicys = `query ListReviewers(
  $filter: ModelReviewerFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reviewerPointOfContactId
      status
      reviewedDate
      ownerGroup
      policyVersionID
      policyVersion {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        policy {
          id
          name
          ownerGroup
        }
      }
    }
    nextToken
  }
}
`;
