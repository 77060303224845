import { precedenceTypes as PRECEDENCE_TYPES } from "@rivial-security/role-utils";

/**
 * Based on role precedence value return the text that corresponds to the role type
 * @param {number} precedence - the role precedence value
 * @return {string}
 */
export const getRolePrecedenceTypeText = ({ precedence }) => {
  switch (precedence) {
    case PRECEDENCE_TYPES.USER:
      return "User";
    case PRECEDENCE_TYPES.SUPERVISOR:
      return "Supervisor";
    case PRECEDENCE_TYPES.MANAGER:
      return "Manager";
    case PRECEDENCE_TYPES.ADMIN:
      return "Admin";
    case PRECEDENCE_TYPES.DEVELOPER:
      return "Developer";
    default:
      return "Unknown";
  }
};
