import { useEffect, useState } from "react";

import { TOOL_TYPES } from "@rivial-security/integration-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { getKnowBe4Training } from "../../../Tools/KnowBe4/Training/graphql/getKnowBe4Training";
import { useKnowBe4TrainingDataGrid } from "../../../Tools/KnowBe4/Training/hooks/useKnowBe4TrainingDataGrid";
import { fetchData } from "../../../Tools/Tool/functions/fetchData";

const activityByEvidence = /* GraphQL */ `
  query ActivityByEvidence(
    $evidenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEvidence(
      evidenceID: $evidenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        trainingID
      }
      nextToken
    }
  }
`;

/**
 * @description Display trainings for an evidence
 * @param item
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
export const useEvidenceTrainings = ({ item, organizationID }) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;

  const [isLoading, setIsLoading] = useState(false);

  const grid = useKnowBe4TrainingDataGrid({
    queryConfig: {
      queryExternalApi: null,
    },
    gridConfig: {
      gridHeight: "100%",
    },
    fields: [
      {
        name: "comment",
        flex: 1,
      },
    ],
  });

  useEffect(() => {
    if (item) {
      grid?.setIsLoading(true);
      ListQueryBy({
        query: activityByEvidence,
        variables: {
          evidenceID: item?.id,
        },
      }).then((data) => {
        setIsLoading(true);

        (async function () {
          const trainings = [];

          for (const activity of data) {
            if (activity?.trainingID) {
              await fetchData({
                toolType: toolType,
                input: {
                  query: getKnowBe4Training,
                  queryInput: {
                    id: activity?.trainingID,
                  },
                },
                organizationID,
              })
                .then((res) => {
                  if (activity?.comment) {
                    Object.assign(res, { comment: activity?.comment });
                  }
                  res["id"] = res?.campaign_id;
                  trainings.push(res);
                })
                .catch((err) => ErrorLogger(err));
            }
          }

          grid?.setData(trainings);
          grid?.setIsLoading(false);
          setIsLoading(false);
        })();
      });
    }
  }, [item]);

  const display = <div style={{ height: "100%" }}>{isLoading ? <LoadingSpinner /> : grid.gridDisplay}</div>;

  return {
    ...grid,
    display,
  };
};

const EvidenceTrainings = (props) => useEvidenceTrainings(props).display;

export default withOrganizationCheck(EvidenceTrainings);
