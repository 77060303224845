import { useEffect, useState } from "react";

/**
 * @description Combines the useState and useEffect hook.
 *
 * Works the same way as useState, with a built useEffect to update that state based on a passed in function
 *
 * @param initialData - the data to initialize the state with.
 * @param [dependencyArray] - optional, the dependency array for the useEffect hook.
 * @param [func] - optional, a function that returns the value to update the state with.
 * @param {boolean} isAsyncFunc - optional, if "true" makes an async call for the func
 * @example
 *   // use it like normal useState hook
 *   const [isDisabled, setIsDisabled] = useStateEffect(false);
 * @example
 *   // use the built in useEffect hook
 *   const [isDisabled, setIsDisabled] = useStateEffect(false, [loggedInUser], () => {
 *     if (loggedInUser) return true;
 *     else return false
 *   });
 */
export const useStateEffect = (initialData, dependencyArray = [], func, isAsyncFunc = false) => {
  /**
   * The state to return from this hook
   */
  const [state, setState] = useState(initialData);

  /**
   * Uses the func param to update the state variable on changes in the dependencyArray.
   */
  useEffect(() => {
    if (func && isAsyncFunc) {
      func(state, [...dependencyArray]).then((data) => setState(data));
    } else if (func) {
      setState((state) => func(state, [...dependencyArray]));
    }
  }, [...dependencyArray]);

  return [state, setState];
};
