/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n   query complianceTrendCoordinates($dashboardId: ID!, $from: DateTimeISO, $to: DateTimeISO) {\n    complianceTrendCoordinates(id: $dashboardId, from: $from, to: $to) {\n      x\n      y\n    }\n  }\n": types.ComplianceTrendCoordinatesDocument,
    "\n  query controlFrameworkDashboards($limit: Float, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n": types.ControlFrameworkDashboardsDocument,
    "\n  query controlFrameworkIdForDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n    }\n  }\n": types.ControlFrameworkIdForDashboardDocument,
    "\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n": types.CreateControlFrameworkDashboardDocument,
    "\n  query customControlFrameworkDashboardName($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      name\n    }\n  }\n": types.CustomControlFrameworkDashboardNameDocument,
    "\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n": types.DeleteControlFrameworkDashboardDocument,
    "\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n": types.ComplianceStatisticsDocument,
    "\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n": types.UpdateControlFrameworkDashboardDocument,
    "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n": types.UserCreateableFrameworkTemplatesDocument,
    "\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n": types.EvidencesForOrganizationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query complianceTrendCoordinates($dashboardId: ID!, $from: DateTimeISO, $to: DateTimeISO) {\n    complianceTrendCoordinates(id: $dashboardId, from: $from, to: $to) {\n      x\n      y\n    }\n  }\n"): (typeof documents)["\n   query complianceTrendCoordinates($dashboardId: ID!, $from: DateTimeISO, $to: DateTimeISO) {\n    complianceTrendCoordinates(id: $dashboardId, from: $from, to: $to) {\n      x\n      y\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query controlFrameworkDashboards($limit: Float, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query controlFrameworkDashboards($limit: Float, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query controlFrameworkIdForDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n    }\n  }\n"): (typeof documents)["\n  query controlFrameworkIdForDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n"): (typeof documents)["\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query customControlFrameworkDashboardName($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query customControlFrameworkDashboardName($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n"): (typeof documents)["\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n"): (typeof documents)["\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;