import { Link } from "@mui/material";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { useModal } from "../../../hooks/views/useModal";
import BookmarkList from "../../Bookmarks/components/BookmarkList";

/**
 * A customized modal for viewing all bookmarks for a single label
 * @param {object} label - general label info, with name and bookmarks array
 * @param {function} onBookmarksUpdated - callback when the list of bookmarks is modified from this UI
 * @returns {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useLabelBookmarks = ({ label, onBookmarksUpdated }) => {
  const getBookmarkAmount = () => {
    const bookmarks = label?.bookmarks;
    let bookmarkAmount = 0;
    if (isNonEmptyArray(bookmarks)) {
      bookmarkAmount = bookmarks.length;
    }
    return bookmarkAmount;
  };

  const getBookmarkAmountText = () => {
    const bookmarkAmount = getBookmarkAmount();

    if (bookmarkAmount === 0) {
      return "No Bookmarks";
    } else if (bookmarkAmount === 1) {
      return "1 Bookmark";
    } else {
      return `${bookmarkAmount} Bookmarks`;
    }
  };

  const modal = useModal(
    `Bookmarks for ${label?.name || ""} label`,
    <BookmarkList label={label} onBookmarksUpdated={onBookmarksUpdated} />,
    <Link
      component="button"
      variant="body2"
      color={"inherit"}
      title={"View all bookmarks for this label"}
      style={{ color: "grey" }}
    >
      {getBookmarkAmountText()}
    </Link>,
  );

  return {
    ...modal,
  };
};
