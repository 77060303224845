import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useRoleUserLinking } from "../hooks/useRoleUserLinking";

/**
 * Component for linking Users to a Role with a transfer list
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RoleUserLinking = (props) => useRoleUserLinking(props).display;

export default withOrganizationCheck(RoleUserLinking);
