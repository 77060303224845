import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

import { useGUID } from "../../../../hooks/functional/useGUID";

/**
 * A custom component allowing to choose a single option from an enum object
 * @param {object} input - current input from the user in the rest of the form
 * @param {object} data - the settings for the dropdown
 * @param {string} fieldName - name of the field that this enum selection is going into
 * @param {function} onChangeCallback - called when the selection changes
 * @returns {JSX.Element}
 */
const EnumFormField = ({ input, data, fieldName, onChangeCallback }) => {
  const { options, width, placeholder, disabled, defaultValue, showClearButton = true, onSelectedOption } = data || {};

  const [id] = useGUID();

  return (
    <>
      <Autocomplete
        disablePortal
        options={data?.options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || (disabled ? "Disabled" : "Select an item...")}
            variant={"standard"}
          />
        )}
        variant={"standard"}
        key={id}
        id={`enum-dropdown-${id}`}
        readOnly={disabled}
        getOptionLabel={(option) => option.text}
        disableClearable={!showClearButton}
        onChange={(event, selectedOption) => {
          if (typeof onChangeCallback === "function") {
            onChangeCallback(selectedOption?.value);
          }
        }}
        data-testid={`useForm-dropdown-input-${fieldName}`}
        width={width}
        // convert the flat value to the correct object for the form
        // defaults to null if the value is not found
        value={options?.find((item) => item.value === input?.[fieldName]) || null}
        // convert the flat value to the correct object for the form
        defaultValue={options?.find((item) => item.value === defaultValue) || null}
      />
    </>
  );
};

export default EnumFormField;
