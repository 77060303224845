import React, { useContext, useState } from "react";
import { Button, DropdownItem, Input } from "reactstrap";

import { ErrorLogger } from "@utils/EventLogger";

import { UIContext } from "../../utils/Context/UIContext";
import { GenericDeleteGQL } from "../../utils/Functions/Graphql/GenericDeleteGQL";
import { confirmDeleteAlert, handleDeleteClick } from "../../utils/GenericComponents/GenericDeleteButton";
import { useModal } from "../views/useModal";

import { useMutation } from "./useMutation/useMutation";
import { QueryGetItem } from "./useQueryGetItem";

/**
 * @description Hook to use the delete mutation
 * @param {object} item - The item to delete
 * @param {string} module - The module to delete the item from
 * @param {string} resource - The resource to delete the item from
 * @param {function} mutation - The mutation to use
 * @param {boolean} modalButton - The button to use to open the modal
 * @param {function} resetFunction - The function to reset the form
 * @param {function} toggleModal - The function to toggle the modal
 * @param {function} deleteFunction - The function to delete the item
 * @param {function} deleteGqlQuery - The query to use to delete the item
 * @param {boolean} disableRoleChecking - If the role checking should be disabled
 * @param {string} typename - The typename of the item to delete
 * @param {boolean} enableNormalButton - If the normal button should be enabled
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 * @example const {setModalIsOpen, modalButton, modalIsOpen, modal, toggleModal} = useDeleteMutation(item, module, resource, mutation, modalButton, resetFunction, toggleModal, deleteFunction, deleteGqlQuery, disableRoleChecking, typename, enableNormalButton)
 */
export const useDeleteMutation = ({
  item,
  module,
  resource,
  mutation,
  resetFunction,
  toggleModal,
  deleteFunction,
  deleteGqlQuery,
  disableRoleChecking,
  typename = "Item",
  enableNormalButton = false, // this switches the "Dropdown Item" style button to a normal "Button".
}) => {
  const [verifyText, setVerifyText] = useState("");

  const mutationHook = useMutation({
    module,
    resource,
    mutation,
    disableRoleChecking,
  });

  const { addToast, updateToast, safeDelete } = useContext(UIContext);

  const handleDelete = () => {
    const toastId = addToast({
      header: `Deleting Item..`,
      icon: "spinner",
      color: "danger",
    });

    if (mutation) {
      mutationHook.deleteItem({ id: item.id }).then((data) => {
        toggleModal?.();
        resetFunction?.();
        updateToast({
          id: toastId,
          header: `${item.__typename || typename || "Item"} Deleted!`,
          icon: "danger",
        });
        modalHook.setModalIsOpen(false);
      });
    } else if (deleteFunction) {
      handleDeleteClick(item, deleteFunction).then((data) => {
        toggleModal?.();
        resetFunction?.();
        updateToast({
          id: toastId,
          header: `${item.__typename || typename || "Item"} Deleted!`,
          icon: "danger",
        });
        modalHook.setModalIsOpen(false);
      });
    } else if (deleteGqlQuery) {
      QueryGetItem({ query: deleteGqlQuery, itemId: item.id }).then((objectToDelete) => {
        if (confirmDeleteAlert(objectToDelete)) {
          updateToast({
            id: toastId,
            header: `${objectToDelete.__typename || typename || "Item"} is deleting, please wait!`,
            icon: "spinner",
            color: "danger",
          });
          if (objectToDelete && Object.keys(objectToDelete).length > 0) {
            GenericDeleteGQL({ objectToDelete }).then(() => {
              resetFunction?.();
              updateToast({
                id: toastId,
                header: `${objectToDelete.__typename || typename || "Item"} Deleted!`,
                icon: "danger",
              });
            });
          }
        }
      });

      toggleModal?.();
      modalHook.setModalIsOpen(false);
    } else {
      ErrorLogger("Error: No Delete Function or Mutation available");
    }
  };

  const keyword = "delete";

  const isDisabled = () => {
    if (safeDelete) {
      return verifyText !== keyword;
    } else {
      return false;
    }
  };

  const modalHook = useModal(
    `Are you sure you want to delete this ${typename || "Item"}?`,
    <div>
      <h5>
        {safeDelete
          ? `Please type "${keyword}" to permanently delete this ${typename || "item"}.`
          : "Safe Delete is Disabled"}
      </h5>
      {safeDelete && <Input value={verifyText} onChange={(e) => setVerifyText(e.target.value)} />}
      <Button
        color="danger"
        disabled={isDisabled() || !mutationHook.resource.delete}
        onClick={() => handleDelete()}
        style={{ marginTop: "1em" }}
        title={
          mutationHook.resource.delete
            ? `Delete this ${typename || "Item"} permanently`
            : `You don't have permission to delete this ${typename || "Item"}`
        }
      >
        <i className="icon-trash" /> Delete
      </Button>
    </div>,
    enableNormalButton ? (
      <Button
        data-test="button-delete-generic"
        id={`button-delete-for-${item.id}`}
        color="ghost-danger"
        title={"Delete"}
      >
        <i style={{ color: "red", marginRight: "1em" }} className="icon-trash" />
        Delete
      </Button>
    ) : (
      <DropdownItem
        data-test="button-delete-generic"
        id={`button-delete-for-${item.id}`}
        color="ghost-danger"
        title={"Delete"}
      >
        <i style={{ color: "red" }} className="icon-trash" />
        Delete
      </DropdownItem>
    ),
  );

  return {
    ...modalHook,
  };
};
