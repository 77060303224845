import { isNullOrUndefined } from "@rivial-security/func-utils";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

import { selectMultipleItems } from "./selectMultipleItems";

/**
 * @description Reselects the proper row after the grid gets refreshed
 * @param {object} ref - reference of the grid component
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {object} lastSelectedItem - last selected item from the grid
 * @param {object} groupSettings -
 * @param {boolean} [enableCheckBoxes=false] - different selection settings apply when checkboxes are enabled and checked
 * items is a valid array
 * @param {object[]} itemsToCheck - all items that need to be found and selected in the grid
 * @param {function} setItemsToCheck - used to set checked items to empty array after the items have been checked
 * @param {number} dataBoundCount - number of times the databound event on the grid has been called
 */
export const gridOnRefresh = ({
  ref,
  disableUpdate,
  lastSelectedItem,
  groupSettings,
  gridScrollPosition,
  enableCheckBoxes = false,
  itemsToCheck,
  setItemsToCheck,
  selectedItems,
  isLoading,
  isCreated,
  dataBoundCount = 0,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    ref,
    disableUpdate,
    lastSelectedItem,
  };

  /**
   * Check params for null or undefined
   * NOTE: enableCheckBoxes, itemsToCheck and setItemsToCheck are optional
   */
  checkParams({ params });

  /**
   * Scroll the grid to a saved position
   * REFERENCE: https://www.syncfusion.com/forums/165081/save-the-scroll-position-in-grid
   */
  if (gridScrollPosition) {
    const contentNode = ref?.element?.querySelectorAll(".e-content");
    if (contentNode.length > 0) {
      contentNode[0].scrollTop = gridScrollPosition;
    }
  }

  disableUpdate.current = true;
  if (enableCheckBoxes && !isNullOrUndefined(itemsToCheck) && Array.isArray(itemsToCheck) && itemsToCheck.length > 0) {
    selectMultipleItems({
      ref,
      items: itemsToCheck,
      callback: () => {
        isCreated === true && isLoading === false && setItemsToCheck && setItemsToCheck(null);
      },
    });
  } else if (enableCheckBoxes && Array.isArray(selectedItems) && selectedItems.length > 0) {
    //reselect multiple rows if checkboxes are enabled
    selectMultipleItems({
      ref,
      items: selectedItems,
    });
  } else {
    if (ref?.currentViewData && Array.isArray(ref?.currentViewData) && lastSelectedItem?.id) {
      const foundIndex = ref.currentViewData.findIndex((x) => x?.id === lastSelectedItem?.id);
      if (foundIndex !== null && foundIndex !== undefined && foundIndex !== -1) {
        ref.selectRow(foundIndex);
      }
    }

    // Handle grouping auto-collapse
    // SEE: https://ej2.syncfusion.com/react/documentation/grid/how-to/collapse-grouped-rows-at-initial-render/
    // NOTE: blocked on the first data bound event because some UI is not yet available and sync fusion code throws
    // an error when it tries to set the style of a non existing object (was occurring with evidence details RISC-2067)
    if (groupSettings?.defaultCollapsed && dataBoundCount > 0) {
      ref.groupModule.collapseAll();
    }
  }

  disableUpdate.current = false;
};
