"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkSystemForRiskControlOverride = void 0;
const checkSystemForRiskControlOverride = (overridesArray, riskControl) => {
    if (overridesArray && overridesArray.length > 0) {
        // If a Specific RiskControl is included in the System.RiskControlOverrides array, find it
        return overridesArray.find((item) => item?.riskControlId === riskControl?.id);
    }
    return false;
};
exports.checkSystemForRiskControlOverride = checkSystemForRiskControlOverride;
