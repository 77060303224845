import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import EditButton from "../../../../utils/GenericComponents/buttons/EditButton";
import { useDetections } from "../../Plan/Detections/hooks/useDetections";

/**
 * Used to modify the an incident's detection type
 * @param {string} organizationID - the organization id of the incident
 * @param {object} item - the incident object from the database (must include id)
 * @param {function} resetFunction - function to call to update UI once mutation takes place
 * @return {{setModalIsOpen: function(*=): void, modalButton: *, modalIsOpen: boolean, modal: *}}
 */
export const useEditIncidentDetection = ({ organizationID, item, resetFunction }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;

  const { updateMutation } = generateGraphql("Incident");

  const detections = useDetections({ organizationID });
  const [detection, setDetection] = useState(null);

  useEffect(() => {
    setDetection(item?.detection);
    editForm.setInput({ detection: item?.detection });
  }, [item]);

  const editForm = useForm({
    item,
    module,
    resource,

    mutation: updateMutation,
    organizationID,

    toggleModal: () => editModal.setModalIsOpen(false),
    resetFunction,

    fieldConfig: {
      detection: {
        label: "Detection Type",
        tooltip: "How was this Incident detected?",
        inputType: "dropdown",
        dropdownConfig: {
          data: detections?.data?.map((item) => {
            return {
              text: `${item.name}`,
              value: item,
            };
          }),
        },
      },
    },
  });

  const editModal = useModal(
    "Edit Selected Detection Type",
    editForm.display,
    <EditButton data-testid={"button-edit-field-detection-type"} color="ghost-warning" title={`Edit detection type`} />,
  );

  return { ...editModal };
};
