import {
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { DrawerSize } from "../../Templates/Drawer/Drawer";

export interface DrawerRoute {
  id: string;
  title: string;
  isPinnable?: boolean;
  size?: DrawerSize;
  render?: (context: DrawerManagerContextParams) => ReactNode;
}

export interface DrawerManagerContextParams {
  stack: DrawerRoute[];
  push: (drawer: DrawerRoute) => void;
  pop: VoidFunction;
  popAll: VoidFunction;
  getTop: () => DrawerRoute | undefined;
  goToFirst: VoidFunction;
  replaceAll: (drawer: DrawerRoute) => void;
}

export const DrawerManagerContext = createContext<DrawerManagerContextParams | undefined>(undefined);

export const DrawerManagerProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [stack, setStack] = useState<DrawerRoute[]>([]);

  const push = useCallback((drawer: DrawerRoute): void => {
    setStack((prevStack) => [...prevStack, drawer]);
  }, []);

  const pop = useCallback((): void => {
    setStack((prevStack) => prevStack.slice(0, -1));
  }, []);

  const popAll = useCallback((): void => {
    setStack([]);
  }, []);

  const getTop = useCallback((): DrawerRoute | undefined => {
    return stack[stack.length - 1];
  }, [stack]);

  const goToFirst = useCallback((): void => {
    setStack((prevStack) => {
      if (prevStack.length === 0) return prevStack;
      return prevStack.slice(0, 1);
    });
  }, []);

  const replaceAll = useCallback((drawer: DrawerRoute): void => {
    setStack([drawer]);
  }, []);

  //Close all active drawers on route change
  const history = useHistory();
  useEffect(() => {
    const unlisten = history?.listen(() => {
      popAll();
    });

    return () => {
      unlisten?.();
    };
  }, [history]);

  const value = useMemo(
    () => ({
      stack,
      push,
      pop,
      popAll,
      getTop,
      goToFirst,
      replaceAll,
    }),
    [stack, push, pop, popAll, getTop, goToFirst, replaceAll],
  );

  return <DrawerManagerContext.Provider value={value}>{children}</DrawerManagerContext.Provider>;
};
