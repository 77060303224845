import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

export const useUpstreamProcedureChanges = ({ callback, initUpstreamChangesOn = false, item }) => {
  //Convert typename to resource
  let resource;
  if (item?.parentTypename) {
    const typename = item?.parentTypename;
    if (typename === "IncidentResponse") {
      resource = resources.RESPONSE_PLAN;
    } else if (typename === "ResponseSystemLink") {
      resource = resources.RESPONSE_PLAN;
    } else if (typename === "ResponseTeam") {
      resource = resources.RESPONSE_TEAM;
    } else if (typename === "Playbook") {
      resource = resources.PLAYBOOK;
    }
  }

  const checkPermissions = useCheckPermissions({
    module: modules.INCIDENT_RESPONSE,
    resource,
  });

  const [upstreamChangesOn, setUpstreamChangesOn] = useState(initUpstreamChangesOn);

  const display = (
    <div
      style={{
        display: checkPermissions.resource.update ? "inline" : "none",
        marginLeft: "1em",
      }}
    >
      <FormControlLabel
        style={{ position: "block", bottom: 0, right: 0 }}
        control={
          <Switch
            size="small"
            onClick={() => {
              callback &&
                callback({
                  enabled: !upstreamChangesOn,
                  parentID: item?.parentID,
                });
              setUpstreamChangesOn(!upstreamChangesOn);
            }}
            value={upstreamChangesOn}
            checked={upstreamChangesOn}
          />
        }
        label={
          <div
            style={{
              margin: ".5em .5em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, marginRight: ".5em" }}>Upstream changes</p>
            <span style={{ cursor: "pointer", marginTop: ".2em" }} id={`upstream-switch-${item?.parentID}`}>
              <i className="icon-question" />
            </span>
            <UncontrolledTooltip placement="right" target={`upstream-switch-${item?.parentID}`}>
              <p>{`Copy all checklist updates to the ${
                resource ? convertCamelCaseToSentence(resource) : "Response Plan"
              }`}</p>
            </UncontrolledTooltip>
          </div>
        }
      />
    </div>
  );

  return {
    display,
  };
};
