"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.concatNotes = void 0;
const eventLogger_1 = require("../Misc/eventLogger");
const parseTextFromHtml_1 = require("./parseTextFromHtml");
/**
 * Combines an array of Notes into a single period (.) separated string.
 * Parses the Note.content field to strip all HTML
 */
const concatNotes = (notes) => {
    const arr = [];
    if (notes === null) {
        const err = "[concatNotes] notes input cannot be null!";
        (0, eventLogger_1.eventLogger)(err);
        throw Error(err);
    }
    if (notes === undefined) {
        const err = "[concatNotes] notes input cannot be undefined!";
        (0, eventLogger_1.eventLogger)(err);
        throw Error(err);
    }
    /**
     * If a single Note is passed in, that's fine.
     */
    if (!Array.isArray(notes)) {
        arr.push(notes);
    }
    else {
        arr.push(...notes);
    }
    let str = "";
    if (arr && Array.isArray(arr)) {
        let count = 0;
        for (const note of arr) {
            count++;
            if (note && note.content) {
                const content = (0, parseTextFromHtml_1.parseTextFromHtml)(note.content);
                str = str.concat("", `${content}${count !== arr.length ? ". " : ""}`);
            }
        }
    }
    return str;
};
exports.concatNotes = concatNotes;
