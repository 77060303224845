import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Input } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useRichTextEditor } from "@hooks/editors/useRichTextEditor";

/**
 * Can be used instead of the normal "text" or "textarea" input types in a form.
 * @param {function} onChange - the handler for setting this field value with an HTML String
 * @param {string} value - the value of  the field
 * @returns {*}
 */
const CustomRichTextEditor = ({ onChange, value: initialValue = "", richTextEditorConfig = {} }) => {
  const [editorType, setEditorType] = useState(richTextEditorConfig?.editorType || "richTextEditor");
  const [showRichTextEditor, setShowRichTextEditor] = useState(showRichTextEditor?.enableEdits || false);
  const [value, setValue] = useState(initialValue);

  const [textAreaValue, setTextAreaValue] = useState(value);

  const { disableEditButtons = false, enableAllFeatures = false } = richTextEditorConfig || {};

  useEffect(() => {
    const enableEdits = richTextEditorConfig?.enableEdits;
    if (!isNullOrUndefined(enableEdits)) {
      setShowRichTextEditor(enableEdits);
    }
  }, [richTextEditorConfig?.enableEdits]);

  const { showEditButtons = true } = richTextEditorConfig || {};
  const useRichEditor = useRichTextEditor({
    initialValue,
    ignoreFocus: true,
    enableAllFeatures,
    onChange: (value) => {
      setValue(value);
      if (typeof onChange === "function") {
        onChange(value);
      }
    },
  });

  const getEditButtons = () => {
    if (!showEditButtons) {
      return null;
    }

    if (!showRichTextEditor) {
      return (
        <Button
          size="sm"
          color="ghost-warning"
          className="btn-pill"
          onClick={() => {
            editorType === "textarea" ? setTextAreaValue(value) : useRichEditor.setValue(value ? value : undefined);
            setShowRichTextEditor(true);
          }}
        >
          <i className="icon-pencil" />
        </Button>
      );
    }

    return (
      <ButtonGroup>
        <Button
          color="ghost-success"
          onClick={() => {
            setValue(editorType === "richTextEditor" ? useRichEditor.value : textAreaValue);
            setShowRichTextEditor(false);
          }}
        >
          Save
        </Button>
        <Button
          color="ghost-secondary"
          onClick={() => {
            useRichEditor.setValue(value ? value : undefined);
            setShowRichTextEditor(false);
          }}
        >
          Cancel
        </Button>
      </ButtonGroup>
    );
  };

  const getEditorContent = () => {
    if (!value && !showRichTextEditor) {
      return <p style={{ fontStyle: "italic", margin: 0 }}>No content</p>;
    }

    if (!showRichTextEditor) {
      return parse(value ? `<div>${value}</div>` : "");
    }

    if (editorType === "richTextEditor") {
      return useRichEditor.display;
    }

    return <Input type="textarea" value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)} />;
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          wordWrap: "break-word",
          backgroundColor: "rgb(252, 253, 253)",
          border: "1px solid rgb(200, 206, 211)",
          paddingTop: ".5em",
          paddingBottom: ".5em",
          paddingLeft: ".7em",
          paddingRight: ".7em",
          borderRadius: ".5em",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 8px 0px",
        }}
      >
        {getEditorContent()}
        {disableEditButtons !== true && getEditButtons()}
      </div>
    </div>
  );
};

export default CustomRichTextEditor;
