import React, { useEffect, useState } from "react";

import { updateObjectInArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";

import { useGUID } from "../../../../hooks/functional/useGUID";
import { useForm } from "../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { listToDropdownData } from "../../../../utils/GenericComponents/GenericEditFieldV3/functions/listToDropdownData";
import EvidenceDataGrid from "../../../Compliance/Evidence/components/EvidenceDataGrid";
import { getTemplateConfiguration } from "../../../Reports/DocumentEditor/functions/getTemplateConfiguration";
import { useReportAutomationTour } from "../tours/automationTours";

/**
 * UI for configuring a 'Generate Report' automation
 *
 * Note: commented out reports that don't have configs in place yet
 * @param {string} organizationID - ID of the organization
 * @param {function} toggleModal - function to toggle the modal
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to set the automations
 * @param {object} fieldConfig - the initial field config passed through to the use form
 * @param {AutomationStep} automationStep - the automation step that is being edited
 * @param {object} trigger - the trigger for the automation
 * @param {boolean} isTemplate - if TRUE will create the automation as a template
 * @param {object} formConfig - form configurations to merge into the main form
 * @returns {JSX.Element}
 * @constructor
 */
const ConfigureReportAutomation = ({
  organizationID,
  automations,
  setAutomations,
  fieldConfig,
  automationStep,
  toggleModal,
  trigger,
  isTemplate = false,
  formConfig,
}) => {
  /**
   * Pass default value of the item id if the trigger is based on listening for an item change
   * @param config
   * @returns {*}
   */
  const checkTriggeringType = (config) => {
    if (trigger?.type === "resourceUpdated" || trigger?.type === "resourceCreated") {
      Object.assign(config, { itemId: "{{item.id}}" });
      return config;
    } else {
      return config;
    }
  };

  /**
   * List of available report templates for automation
   */
  const defaultTemplates = [
    {
      id: REPORT_TEMPLATES.GENERAL_TEMPLATE,
      name: "General Template",
    },
    {
      id: REPORT_TEMPLATES.AUDIT_TEMPLATE,
      name: "Audit Report",
      getFields: (config) => {
        config = checkTriggeringType(config);
        return getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.AUDIT_TEMPLATE,
          config,
          isTemplate,
        });
      },
    },
    {
      id: REPORT_TEMPLATES.EXECUTIVE_SUMMARY_TEMPLATE,
      name: "Executive Summary Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.EXECUTIVE_SUMMARY_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.RA_TEMPLATE,
      name: "Risk Assessment Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.RA_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.BLUEPRINT_TEMPLATE,
      name: "Blueprint Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.BLUEPRINT_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE,
      name: "Vendor Review Report",
      getFields: (config) => {
        config = checkTriggeringType(config);
        return getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE,
          config,
          isTemplate,
        });
      },
    },
    {
      id: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE,
      name: "Compliance Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE,
      name: "Security Assessment Report",
      getFields: (config) => {
        config = checkTriggeringType(config);
        return getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE,
          config,
          isTemplate,
        });
      },
    },
    {
      id: REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE,
      name: "Vendor Review Controls Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.INCIDENT_RESPONSE_PLAN_TEMPLATE,
      name: "Incident Response Plan",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.INCIDENT_RESPONSE_PLAN_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE,
      name: "Incident Response Exercise Report",
      getFields: (config) => {
        config = checkTriggeringType(config);
        return getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE,
          config,
          isTemplate,
        });
      },
    },
    {
      id: REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE,
      name: "Incident Report",
      getFields: (config) => {
        config = checkTriggeringType(config);
        return getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE,
          config,
        });
      },
    },
    {
      id: REPORT_TEMPLATES.FINDINGS_TEMPLATE,
      name: "Findings Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.FINDINGS_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.TRAINING_TEMPLATE,
      name: "KnowBe4 Training Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.TRAINING_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.PHISHING_TEMPLATE,
      name: "KnowBe4 Phishing Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.PHISHING_TEMPLATE,
          config,
          isTemplate,
        }),
    },
    {
      id: REPORT_TEMPLATES.MEETING_TEMPLATE,
      name: "Meeting Report",
      getFields: (config) =>
        getTemplateConfiguration({
          templateType: REPORT_TEMPLATES.MEETING_TEMPLATE,
          config,
          isTemplate,
        }),
    },
  ];

  const handleTemplateConfig = (templateId) => {
    const foundTemplate = defaultTemplates.find((t) => t.id === templateId);
    if (typeof foundTemplate?.getFields === "function") {
      return foundTemplate?.getFields({});
    } else {
      return {};
    }
  };

  const [templates, setTemplates] = useState([]);

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  // Retrieve all custom report templates and append with built-in templates
  useEffect(() => {
    const { listQuery } = generateGraphql("ReportTemplate", ["name"]);

    ListQuery({
      query: listQuery,
      organizationID,
    }).then((items) => {
      setTemplates([...defaultTemplates, ...items]);
    });
  }, []);

  const submitFunction = async (input) => {
    const newAutomations = [...(automations || [])];

    const name = input?.name;
    const description = `${templates.find((a) => a.id === input.templateId).name}${
      input?.evidence?.length > 0
        ? ` (${input?.evidence?.length} Associated Evidence${input?.evidence?.length > 1 ? "s" : ""})`
        : ""
    }`;

    updateObjectInArray(newAutomations, {
      id: automationStep.id,
      name,
      description: description,
      config: {
        ...automationStep.config,
        ...input,
      },
    });

    setAutomations(newAutomations);
  };

  fieldConfig = {
    ...fieldConfig,
    templateId: {
      label: "Select a Report Template",
      inputType: "dropdown",
      defaultValue: selectedTemplateId || automationStep?.config?.templateId || REPORT_TEMPLATES.GENERAL_TEMPLATE,
      dropdownConfig: {
        data: listToDropdownData(templates),
      },
      onChangeFunction: (input) => setSelectedTemplateId(input.templateId),
    },
    /**
     * Add new config fields from the selected template
     */
    ...(selectedTemplateId ? handleTemplateConfig(selectedTemplateId) : {}),

    /**
     * Allows the user to select an Evidence to upload the report to
     */
    evidence: {
      label: "Applicable Evidence",
      tooltip: "Select Evidence to automatically attach this Report to",
      inputType: "multi-select",
      defaultValue: automationStep?.config?.evidence ? automationStep?.config?.evidence : [],
      multiSelectConfig: {
        multiSelect: true,
        typename: "Evidence",
        grid: <EvidenceDataGrid options={[]} />,
      },
    },
  };

  const [guid] = useGUID();

  const form = useForm({
    id: guid,
    automationStep: automationStep,
    submitFunction,
    disableRoleChecking: true,
    toggleModal,
    fieldConfig,
    showOverridesButton: true,
    disableResetButton: true,
    ...formConfig,
  });

  /**
   * Update fields config values based on the selected template
   */
  useEffect(() => {
    if (selectedTemplateId) {
      form?.addFieldConfigToInput(fieldConfig, true);
      form?.mergeInput({ name: form?.input?.name });
    }
  }, [selectedTemplateId]);

  // Welcome Tour
  useReportAutomationTour();

  return <div data-tourid={"configure-report-automation-container"}>{form.display}</div>;
};

export default withOrganizationCheck(ConfigureReportAutomation);
