import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useTable } from "../../../../../../hooks/views/useTable";
import { DontHavePermission } from "../../../../../../utils/AccessControl/components/DontHavePermission";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import NotEnoughData from "../../../../../../utils/GenericComponents/NotEnoughData";
import Loader from "../../../../../../utils/LoadingComponents/Loader";
import BlueprintRadarChart from "../../../../../ActionCenter/components/Charts/BlueprintRadarChart";
import { useOpenArticle } from "../../../../../HelpCenter/hooks/useOpenArticle";
import { getMaturityMatrix } from "../../../deprecated/VCISOMaturityMatrix";
import CreateProgramElement from "../../ProgramElement/components/CreateProgramElement";
import ProgramElementRow from "../../ProgramElement/components/ProgramElementRow";
import { DeleteMaturityMatrix } from "../functions/DeleteMaturityMatrix";

const { updateMutation: updateMaturityMatrix } = generateGraphql("MaturityMatrix");

/**
 *
 * Details component for displaying a Program Blueprint and MaturityLevelRadarChart
 *
 * @param itemId: id of a Program Blueprint
 * @param {function} resetFunction - function to reset the state of the component
 * @param {boolean} [isTemplate = false] - determines if this UI needs to be trimmed down to display as a Template
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - flag to disable role checking
 * @param {object} ...props - additional props to pass to the detailsHook
 * @returns {*}
 * @constructor
 */
const BlueprintDetails = ({
  itemId,
  resetFunction,
  isTemplate = false,
  module = modules.GOVERNANCE,
  resource = resources.BLUEPRINT,
  disableRoleChecking = false,
  helpCenterRoute = "blueprint",
  ...props
}) => {
  const openArticle = useOpenArticle({ helpCenterRoute });
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const checkPermissionsProgramElement = useCheckPermissions({
    module,
    resource: resources.PROGRAM_ELEMENT,
    disableRoleChecking,
  });

  const [isLoadedElements, setIsLoadedElements] = useState(false);
  const [maturityMatrix, setMaturityMatrix] = useState(null);
  const [programElementList, setProgramElementList] = useState([]);

  const getMaturityMatrixHook = useQueryGetItem({
    query: getMaturityMatrix,
    itemId: itemId,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    if (getMaturityMatrixHook.item) {
      setMaturityMatrix(getMaturityMatrixHook.item);
    }
  }, [getMaturityMatrixHook.item]);

  useEffect(() => {
    if (maturityMatrix && maturityMatrix.programElements && maturityMatrix.programElements.items) {
      setProgramElementList(maturityMatrix.programElements.items);
      setIsLoadedElements(true);
    }
  }, [maturityMatrix]);

  const tableHook = useTable({
    data: programElementList,
    rowComponent: (
      <ProgramElementRow
        isTemplate={isTemplate}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    resetFunction: getMaturityMatrixHook.reset,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    tableHook.setData(programElementList);
  }, [programElementList]);

  const AddSingleProgramElementHook = useModal(
    "Create a Program Element",
    <CreateProgramElement
      resetFunction={getMaturityMatrixHook.reset}
      maturityMatrix={maturityMatrix}
      organizationID={maturityMatrix?.ownerGroup}
      disableRoleChecking={disableRoleChecking}
      module={module}
      resource={resource}
    />,
    <Button
      data-testid={"create_item-element"}
      id={"create_item-element"}
      disabled={!maturityMatrix}
      size="sm"
      color="ghost-success"
      className="float-right btn-pill"
      title="Create a new Program Element"
    >
      <i className="icon-plus" /> Add a Program Element
    </Button>,
    {},
  );

  return (
    <Card data-testid="single-program-blueprint-details" id="single-program-blueprint-details">
      <CardBody>
        <span className="float-left">
          {maturityMatrix ? (
            <h3>
              <GenericEditField
                item={maturityMatrix}
                mutation={updateMaturityMatrix}
                module={module}
                resource={resource}
                field="name"
                required={true}
                deleteButton={
                  checkPermissions.resource.delete && maturityMatrix ? (
                    <Button
                      id="delete-item-blueprint"
                      data-testid="delete-item-blueprint"
                      size="sm"
                      color="ghost-danger"
                      className="btn-pill"
                      title="Delete Program Blueprint"
                      onClick={async () => {
                        if (
                          window.confirm(`Are you sure you want to remove ${maturityMatrix && maturityMatrix.name}?`)
                        ) {
                          await DeleteMaturityMatrix(maturityMatrix);
                          getMaturityMatrixHook.reset();
                          resetFunction && resetFunction();
                        }
                      }}
                    >
                      <i className="icon-trash" />
                    </Button>
                  ) : null
                }
              />
            </h3>
          ) : null}
        </span>
        <Button
          size="sm"
          color="ghost-warning"
          className="float-right btn-pill"
          title="Print Maturity Matrix"
          onClick={() => window.print()}
        >
          <i className="icon-printer" />
        </Button>
        <Button
          size="sm"
          outline
          color="primary"
          className="float-right btn-pill"
          title="Open Help Center Article"
          onClick={() => openArticle(helpCenterRoute)}
        >
          <i className="icon-question" />
        </Button>
        <Button
          size="sm"
          color="ghost-secondary"
          className="btn-pill float-sm-right"
          onClick={() => {
            getMaturityMatrixHook.reset();
          }}
          title="Refresh"
        >
          <i className="icon-reload" />
        </Button>
        {checkPermissionsProgramElement.resource.create ? AddSingleProgramElementHook.modalButton : null}
        {isLoadedElements ? (
          Array.isArray(programElementList) && programElementList.length > 0 ? (
            tableHook.display
          ) : (
            <Table responsive hover style={{ height: "100%" }}>
              <thead>
                <tr>
                  {checkPermissionsProgramElement.resource.create ? (
                    <NotEnoughData
                      message={"There are no program elements in this blueprint."}
                      callToAction={{
                        message: " to add a program element.",
                        function: () => {
                          AddSingleProgramElementHook.setModalIsOpen(true);
                        },
                      }}
                      itemName={"blueprint"}
                    />
                  ) : (
                    <NotEnoughData
                      message={
                        "There are no program elements in this blueprint and you do not have permissions to create new ones."
                      }
                    />
                  )}
                </tr>
              </thead>
            </Table>
          )
        ) : (
          <Loader />
        )}
        {checkPermissions.resource.read ? (
          maturityMatrix ? (
            <div
              id="charts"
              className="shadow"
              style={{
                backgroundColor: "white",
                height: "50vh",
                width: "100%",
              }}
            >
              <BlueprintRadarChart autoAdjustScale={true} blueprintData={maturityMatrix} />
            </div>
          ) : null
        ) : (
          <DontHavePermission module={module} resource={resource} />
        )}
      </CardBody>
    </Card>
  );
};

export default withDetailsPage(BlueprintDetails);
