import React, { useContext } from "react";
import { SketchPicker } from "react-color";
import { ListGroupItem, UncontrolledTooltip } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../../utils/Context/UIContext";
import ResetButton from "../../../../../utils/GenericComponents/buttons/ResetButton";
import { onHighlightColorChange } from "../functions/onHighlightColorChange";

/**
 * @description Interface Highlighted Rows Component - controls the user preference selection for the table row highlight colors.
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceHighlightedRows = () => {
  const context = useContext(OrganizationContext);
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const card = useCard({
    id: "highlighted-rows",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Highlighted Rows</h4>{" "}
        <i id="highlighted-rows-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="highlighted-rows-icon-question">
          Set the color of Highlighted Rows
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <ResetButton
          title="Reset to default color"
          resetFunction={() =>
            onHighlightColorChange({
              value: { hex: "rgba(255,190,0,0.08)" },
              context,
              addToast,
              preferences,
            })
          }
        />
        <SketchPicker
          color={context.selectedHighlightColor}
          onChangeComplete={(e) =>
            onHighlightColorChange({
              value: e,
              addToast,
              context,
              preferences,
            })
          }
        />
        <br />
        <h5>Example</h5>
        <ListGroupItem style={{ background: context.selectedHighlightColor }}>
          This is the color that a row will be when highlighted
        </ListGroupItem>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceHighlightedRows;
