import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useIncidentDetails } from "../hooks/useIncidentDetails";

/**
 * Incident details component with details page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IncidentDetails = (props) => {
  return useIncidentDetails(props).display;
};

export default withDetailsPage(IncidentDetails, { title: "Incident Details" });
