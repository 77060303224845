"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffleArray = void 0;
const isNonEmptyArray_1 = require("./isNonEmptyArray");
/**
 * Repositions items in the array randomly, in place
 * REFERENCE: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 * @param {*[]} array - An array of items that need to be randomly ordered
 * @returns {*[]} - the shuffled array
 */
const shuffleArray = (array) => {
    if (!(0, isNonEmptyArray_1.isNonEmptyArray)(array)) {
        return array;
    }
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
};
exports.shuffleArray = shuffleArray;
