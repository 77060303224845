"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formattedPercent = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Changes a proportion value to a formatted percentage text
 * @param value - a number representing a proportion (by default in decimal form)
 * @param toFixed - how many decimal places to show
 * @param isPercentInteger - should be true if giving the function a percentage value (not decimal)
 * @return formatted percent value
 */
const formattedPercent = (value, toFixed = 2, isPercentInteger = false) => {
    if (!(0, isNullOrUndefined_1.isNullOrUndefined)(value)) {
        let numericValue;
        if (typeof value === "string") {
            try {
                numericValue = parseFloat(value);
            }
            catch (e) {
                return "NaN%";
            }
        }
        else {
            numericValue = value;
        }
        if (isPercentInteger) {
            numericValue /= 100;
        }
        return `${(numericValue * 100).toFixed(toFixed)}%`;
    }
    else {
        return "NaN%";
    }
};
exports.formattedPercent = formattedPercent;
