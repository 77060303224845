import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Simple mutation to create a single RecommendationItem
 * @param input
 * @returns {Promise<*>}
 */
export const createRecommendation = (input) => {
  const { createMutation } = generateGraphql("RecommendationItem", ["name", "status", "rating", "difficulty"]);

  return ItemMutation(createMutation, { ...input });
};
