import { Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { GetS3Object } from "../../../utils/Functions/S3Storage/GetS3Object";
import { useRefState } from "../../functional/useRefState";

import PDFControls from "./components/PDFControls";
import PDFDocument from "./components/PDFDocument";

/**
 * A PDF viewer with adjustable features flags
 * @param {object} file - the s3 file object to display in the viewer
 * @param {string} organizationID - the current organization id
 * @param {object[]} annotations - all annotation to show on top of the document
 * @param {object} features - features to expose in the viewer
 * @param {boolean} features.annotations - annotation create and review UI feature flag  (default: true)
 * @param {function} onTextSelected - callback for when text is selected in the document
 * @param {object} [emphasizedAnnotation] - the annotation that the user needs to bring attention to (if any)
 * @param {JSX.Element} annotationComponent - the component used to render all annotations
 * @returns {{display: JSX.Element}}
 */
export const usePDFViewer = ({
  organizationID,
  file,
  annotations,
  features,
  onTextSelected,
  emphasizedAnnotation,
  annotationComponent,
}) => {
  const pageMargin = 8;
  const documentMarginRight = 8;

  const documentContainerRef = useRef(null);
  const scrollRef = useRef(null);
  const [document, setDocument] = useState(null);
  const [settings, setSettings] = useState(null);
  const [pdfJS, setPdfJS, pdfJSRef] = useRefState(null);
  const [renderError, setRenderError] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  //Using the file information download its data from s3 and initialize the viewer
  useEffect(() => {
    (async function () {
      try {
        let pdfJSInstance = pdfJSRef?.current;
        if (!pdfJSInstance) {
          pdfJSInstance = await import("pdfjs-dist/build/pdf");
          pdfJSInstance.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJSInstance?.version}/pdf.worker.min.js`;
          setPdfJS(pdfJSInstance);
        }

        if (file?.bucket && file?.key) {
          setIsDownloading(true);
          const data = await GetS3Object({
            bucketName: file?.bucket,
            objectKey: file?.key,
            organizationID,
          });

          const u8 = new Uint8Array(data?.Body);
          const document = await pdfJSInstance.getDocument({ data: u8 }).promise;
          setDocument(document);

          const numPages = document?.numPages || 0;
          if (numPages === 0) {
            throw new Error("No pages in document");
          }

          const firstPage = await document.getPage(1);
          const firstPageDimensions = firstPage.getViewport({ scale: 1 });

          //Get the dimensions of the document container through its ref
          const documentContainer = documentContainerRef?.current;
          const containerDimensions = documentContainer.getBoundingClientRect();

          //Calculate the scale needed to fit the document to fit both width and height of container
          const targetPageWidth = containerDimensions.width - pageMargin * 2 - documentMarginRight;
          const targetPageHeight = containerDimensions.height - pageMargin;
          const scale = Math.min(
            targetPageWidth / firstPageDimensions.width,
            targetPageHeight / firstPageDimensions.height,
          );
          setSettings({
            scale, //decimal scale
            numPages,
            curPage: 1,
            containerDimensions,
            fullPageHeight: firstPageDimensions.height * scale + pageMargin,
            pageMargin,
            documentMarginRight,
          });
        }
      } catch (e) {
        ErrorLogger("Error loading pdf document: ", e);
        setRenderError(e);
      } finally {
        setIsDownloading(false);
      }
    })();
  }, [JSON.stringify(file)]);

  //When a new emphasized annotation is passed in, scroll to it
  useEffect(() => {
    if (emphasizedAnnotation) {
      //Find the position to scroll to
      let minimumPageNumber = null;
      const pageBounds = emphasizedAnnotation?.pageBounds;
      if (pageBounds) {
        for (const pageNumber in pageBounds) {
          let convertedPageNumber;
          try {
            convertedPageNumber = Number.parseInt(pageNumber);
          } catch (e) {
            continue;
          }

          if (
            Number.isFinite(convertedPageNumber) &&
            (minimumPageNumber === null || minimumPageNumber > convertedPageNumber)
          ) {
            minimumPageNumber = convertedPageNumber;
          }
        }
      }

      if (!isNullOrUndefined(minimumPageNumber) && scrollRef?.current) {
        scrollRef.current.scrollTop = settings?.fullPageHeight * minimumPageNumber;
      }
    }
  }, [emphasizedAnnotation]);

  const onVerticalScroll = ({ scrollTop }) => {
    const pageHeight = settings?.fullPageHeight;
    const viewerHeight = scrollTop + pageHeight / 2;
    if (!pageHeight || !viewerHeight) {
      return;
    }

    const oldPage = settings?.curPage;
    const newPage = Math.floor(viewerHeight / pageHeight) + 1;
    if (oldPage !== newPage) {
      setSettings((settings) => {
        return { ...settings, curPage: newPage };
      });
    }
  };

  const display = (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      {renderError && <Alert severity={"error"}>Cannot load this document!</Alert>}
      <div
        id={"label-popover-target"}
        style={{
          position: "absolute",
        }}
      />
      {!renderError && settings && <PDFControls pdfJS={pdfJS} settings={settings} document={document} />}
      {!renderError && (
        <div ref={documentContainerRef} style={{ height: "100%", width: "100%" }}>
          <PDFDocument
            ref={scrollRef}
            pdfJS={pdfJS}
            settings={settings}
            document={document}
            annotations={annotations}
            emphasizedAnnotation={emphasizedAnnotation}
            onVerticalScroll={onVerticalScroll}
            onTextSelected={onTextSelected}
            annotationComponent={annotationComponent}
          />
        </div>
      )}
    </div>
  );

  return {
    display,
  };
};
