import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateTask } from "../hooks/useCreateTask";

/**
 * A component instance of the useCreateTask.js hook
 * @param props
 */
const CreateTask = (props) => {
  return useCreateTask(props).display;
};

export default withOrganizationCheck(CreateTask);
