import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import NotesV2 from "../../../../../utils/Notes/components/NotesV2";

/**
 * Displays Notes for a Control
 * @param item
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ControlNotes = ({ item, organizationID }) => {
  const { getQuery, updateMutation } = generateGraphql("Control", ["notes"], {
    notes: "{ id type ownerGroup author timeStamp content tag observationID}",
  });

  const module = modules.COMPLIANCE;
  const resource = resources.CONTROL;

  const query = useQueryGetItem({
    itemId: item.id,
    query: getQuery,
    module,
    resource,
  });

  return (
    <NotesV2
      item={query.item}
      module={module}
      resource={resource}
      maxInitialNotes={1}
      tooltip="General Notes for Control"
      organizationID={organizationID}
      mutation={updateMutation}
      resetFunction={query.reset}
      observationConnectionField={"complianceControlID"}
    />
  );
};

export default withOrganizationCheck(ControlNotes);
