import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Determines if a schema typefield is valid for use with the Query Builder.
 *
 * We are currently only supporting SCALAR and ENUM types (e.g. no connection or nested fields)
 * @param {object} typeField
 * @param {boolean} showConnections
 * @param {string} [typeField.name]
 * @param {object} [typeField.type]
 * @param {string} [typeField.type.name]
 * @param {object} [typeField.type.ofType]
 * @param {string} [typeField.type.ofType.name]
 * @returns {boolean}
 */
export const isValidFieldType = (typeField, showConnections = false) => {
  /**
   * If this typeField has no name or type attribute, return false
   */
  if (isNullOrUndefined(typeField.name) || isNullOrUndefined(typeField.type)) {
    return false;
  }

  if (showConnections && typeField?.type?.kind === "LIST") {
    return true;
  }

  /**
   * If this typeField kind is OBJECT (connection field), return true
   */
  if (typeField.type?.kind === "OBJECT") {
    return true;
  }

  /**
   * If this typeField is NON_NULL and is a SCALAR or an ENUM, return true
   */
  if (
    typeField?.type?.kind === "NON_NULL" &&
    (typeField?.type?.ofType?.kind === "SCALAR" ||
      typeField?.type?.ofType?.kind === "ENUM" ||
      typeField?.type?.ofType?.kind === "OBJECT")
  ) {
    return true;
  }

  /**
   * If this typeField is nullable, and is a SCALAR or an ENUM, return true
   */
  if (typeField?.type?.kind === "SCALAR" || typeField?.type?.kind === "ENUM") {
    return true;
  }

  /**
   * Else, not currently a valid field type so return false
   */
  return false;
};
