"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateNetProfit = void 0;
/**
 * Calculates the Net Profit from a change and a cost. Returns a dollar amount
 *
 * @function
 * @param {number} inherentRisk - dollar amount representing the loss before controls
 * @param {number} residualRisk - dollar amount representing the loss after controls
 * @param {number} costOfControls - dollar amount representing total cost of controls
 * @returns {number} - dollar amount representing total profit
 */
const calculateNetProfit = (inherentRisk, residualRisk, costOfControls) => {
    if (!costOfControls || costOfControls === 0)
        return 0;
    return inherentRisk - residualRisk - costOfControls;
};
exports.calculateNetProfit = calculateNetProfit;
