import { cloneDeep, pick } from "lodash";

import { isNullOrUndefined, omitDeep } from "@rivial-security/func-utils";

/**
 * Goes through all items and recalculates the search column that the user can search by in the grid's header
 * @param {object} ref - the grid reference, used to find which columns are active
 * @param {object} data - the current data that the grid holds (visible and not visible rows)
 * @param {object[]} fields - the fields config for the grid
 */
export const updateSearchColumn = ({ ref, data, fields }) => {
  // Get the fields currently shown in the grid
  const shownFields = [];
  if (Array.isArray(ref?.columnModel)) {
    ref.columnModel.forEach((column) => {
      if (column.visible && column.field && column.field !== "ℹ") {
        shownFields.push(column.field);
      }
    });
  }

  // Get all search normalization functions into a map
  const normalizers = {};
  for (const field of fields) {
    if (field?.searchNormalizer && field?.name) {
      normalizers[field?.name] = field.searchNormalizer;
    }
  }

  // Update the search columns of the data source
  data &&
    Array.isArray(data) &&
    data.forEach((item, index, array) => {
      //Only update the search column value if the row exists
      if (Array.isArray(array) && array.length > index) {
        if (!isNullOrUndefined(array[index])) {
          // Delete the previous values stored in the search column
          delete array[index].searchColumn;
        }

        if (item) {
          // Remove any occurrences of the 'id' field, even in nested objects
          let searchItem = omitDeep({
            obj: cloneDeep(item),
            field: "id",
          });

          // Form the search object out of currently shown fields
          searchItem = pick(searchItem, [...shownFields]);

          //Iterate over each field and normalize the value
          for (const field in searchItem) {
            if (normalizers.hasOwnProperty(field) && normalizers[field]) {
              searchItem[field] = normalizers[field](searchItem[field]);
            }
          }

          // Add the object's string value to the search column
          const searchString = getFlatObjectValues({ value: searchItem });
          array[index].searchColumn = searchString;
        }
      }
    });
};

/**
 * Allows to retrieve all values for an object, even those that are deeply nested
 * @param {object} value - the top level object for which to get all values
 * @return {*} a singe value on which to perform search operations
 */
const getFlatObjectValues = ({ value }) => {
  if (typeof value === "object") {
    const values = Object.values(value);
    for (let i = 0; i < values.length; i++) {
      if (typeof values[i] === "object" && values[i]) {
        values[i] = getFlatObjectValues({ value: values[i] });
      }
    }
    return values.join(" ");
  } else {
    return value;
  }
};
