/**
 * Parses for all overrides inside a system, includes risk control information with each override
 * @param {object} system - the system for which to get overrides (override information needs to be present)
 * @return {object[]} - list of all override data
 */
export const listSystemRiskOverrides = ({ system } = {}) => {
  // Check arguments
  if (!system) {
    return [];
  }

  // Get all active overrides
  const systemRiskControlOverrides = [];
  const riskControlOverrides = system?.riskControlOverrides;
  if (Array.isArray(riskControlOverrides)) {
    for (const riskControlOverride of riskControlOverrides) {
      if (Array.isArray(riskControlOverride?.enabledFields) && riskControlOverride.enabledFields.length > 0) {
        systemRiskControlOverrides.push({
          ...riskControlOverride,
          system,
        });
      }
    }
  }

  return systemRiskControlOverrides;
};
