import { generateGraphql } from "@rivial-security/generategraphql";

import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single TargetFindingLink item
 * @param findingTargetLink
 * @return {Promise<void>}
 */
export const deleteTargetFindingLink = async (findingTargetLink) => {
  const { getQuery } = generateGraphql("TargetFindingLink", ["__typename"]);
  return await deleteItem(getQuery, findingTargetLink);
};
