import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * @description Unlink Response Teams to an Exercise
 * @param {object[]} teams - response teams array
 * @param {object} exercise - exercise object
 * @returns {object} {Promise<void>}
 */
export const handleUnlinkResponseTeams = async (teams, exercise) => {
  /**
   * Check arguments
   */
  checkArguments(
    {
      teams,
      exercise,
    },
    {
      teams: { isArray: true },
      exercise: { type: "object" },
    },
  );

  const { deleteMutation } = generateGraphql("ExerciseResponseTeamLink");

  /**
   * Remove links
   */
  for (const team of teams) {
    const responseTeams = exercise?.responseTeams?.items;

    if (responseTeams) {
      for (const link of responseTeams) {
        if (link?.responseTeam?.id === team?.id) {
          await ItemMutation(deleteMutation, {
            id: link?.id,
          });
        }
      }
    }
  }
};
