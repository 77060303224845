import React, { useContext } from "react";
import { Badge, Button } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { useModal } from "../../../../hooks/views/useModal";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import CreateRiskControlChange from "../../RiskChanges/components/RiskControlChange/components/CreateRiskControlChange";
import { updateRiskRecommendation_recommendationList } from "../graphql/__riskRecommendationsGQL";

export const RiskRecommendationResolved = (props) => {
  const module = modules.RISK;
  const resource = resources.RECOMMENDATION;

  const context = useContext(OrganizationContext);

  const updateRiskRecommendationHook = useMutation({
    mutation: updateRiskRecommendation_recommendationList,
    module,
    resource,
    field: "resolved",
    typename: "Recommendations",
    disableRoleChecking: true,
  });

  const callback = (data) => {
    if (props.item && props.item.id) {
      updateRiskRecommendationHook
        .editItem({
          id: props.item.id,
          resolved: true,
        })
        .then(() => props.resetFunction && props.resetFunction());
    }
  };

  const riskChange = {
    name: props.item && props.item.name ? props.item.name : "",
    userEmail: context.userEmail,
    costChange: props.item && props.item.costChange ? props.item.costChange : 0,
    implementationChange: props.item && props.item.implementationChange ? props.item.implementationChange : 0,
    description: props.item && props.item.name ? props.item.name : "",
    reason: "The cost or implementation was changed",
  };

  const resolveRiskRecommendationModal = useModal(
    "Create Risk Change",
    <CreateRiskControlChange
      item={riskChange}
      riskControl={props.item && props.item.riskControl}
      callbackFunction={callback}
    />,
    <Button size="sm" color="ghost-success" className="btn-pill" title="Resolve Risk Recommendation">
      <i className="icon-check" />
    </Button>,
    {
      width: "80vw",
    },
  );

  return (
    <>
      {props.resolved ? (
        <Badge color="success">Resolved</Badge>
      ) : (
        <>
          <Badge color="danger">Not Resolved</Badge> {resolveRiskRecommendationModal.modalButton}
        </>
      )}
    </>
  );
};
