import ChevronRight from "@mui/icons-material/ChevronRight";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Refresh from "@mui/icons-material/Refresh";
import Backdrop from "@mui/material/Backdrop";
import Icon from "@mui/material/Icon";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { useUIContext } from "@utils/Context/UIContext";

import { useGUID } from "../../hooks/functional/useGUID";
import { handleIcon } from "../Functions/Icon/getIcon";

/**
 * Displays a simple dashboard
 * @param {object} dashboard
 * @param {string} [dashboard.id] - the id of the dashboard
 * @param {DashboardItem[]} [dashboard.item] - the items to display
 * @param {string} [dashboard.title] - the title of the dashboard
 * @param {React.ReactElement} [dashboard.children] - the content of the dashboard
 * @param {JSX.Element} [dashboard.badge] - the badge to display
 * @param {JSX.Element | string} [dashboard.icon] - the icon to display
 * @param {object} [dashboard.titleStyle] - the style of the title
 * @param {function} [dashboard.resetFunction] - the function to call to reset the dashboard
 * @param {DashboardAction[]} [dashboard.actions] - speed dial button actions
 * @param {object} [dashboard.actionButtonStyle] - the style of the action button
 * @param {JSX.Element[]} [dashboard.headerButtons] - the buttons to display in the header
 * @param {string} [dashboard.subTitle] - the subtitle of the dashboard
 * @param {string} [dashboard.tourId] - the tour id to start when the dashboard is loaded
 * @param {object} [dashboard.sx] - inline styles to use for different parts of the dashboard stored as element -> css styles object
 * @returns {JSX.Element}
 */
const Dashboard = ({
  id,
  item,
  title,
  children,
  badge,
  icon,
  titleStyle = {},
  resetFunction,
  actions = [],
  actionButtonStyle,
  headerButtons,
  subTitle,
  tourId,
  sx = {},
}) => {
  const dashboardActions = [...actions];

  if (resetFunction) {
    dashboardActions.push({
      name: "Refresh Data",
      icon: <Refresh />,
      onClick: resetFunction,
    });
  }

  const { isMobile } = useUIContext();
  const [guid] = useGUID();

  return (
    <div
      className={"dashboard-container"}
      id={id}
      data-tourid={tourId}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...(sx?.container || {}),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          {title && (
            <h4 className="dashboard-title" style={{ ...titleStyle, ...(sx?.title || {}) }}>
              {(badge || icon) && <span style={{ marginRight: "0.5em" }}>{handleIcon(badge || icon)}</span>}
              {title}
            </h4>
          )}
          {subTitle && (
            <div
              style={{
                flex: 1,
                fontSize: "1em",
                fontWeight: "700",
                textTransform: "uppercase",
                color: "#657581",
                marginBottom: "1em",
              }}
            >
              {subTitle}
            </div>
          )}
        </div>
        {Array.isArray(headerButtons) && (
          <div
            id={`${id}-header-buttons`}
            style={{
              display: "flex",
              margin: ".5em",
              justifyContent: "right",
              gap: ".5em",
              alignItems: "center",
              ...(sx?.headerButtonsContainer || {}),
            }}
          >
            {headerButtons?.map((button, index) => React.cloneElement(button, { item, key: guid + index }))}
          </div>
        )}
        <div>
          {dashboardActions && dashboardActions.length > 0 && (
            <DashboardActionButton
              id={id}
              actions={dashboardActions}
              style={actionButtonStyle || sx?.actionButton || {}}
            />
          )}
        </div>
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: "translateZ(100px)",
    flexGrow: 1,
    zIndex: 999,
  },
  speedDial: {
    position: "absolute",
    top: theme.spacing(-1),
    right: theme.spacing(-2),
  },
}));

/**
 * @typedef DashboardAction
 * @param {string} name
 * @param {function} onClick
 * @param {JSX.Element} icon
 */

/**
 * Floating Action Button for the Dashboard
 *
 * @param id
 * @param {DashboardAction[]} actions
 * @param style
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardActionButton = ({ id, actions, style = {} }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActionClick = (action) => {
    action?.onClick && action.onClick();
    handleClose();
  };

  return (
    <span>
      <Backdrop open={open} style={{ zIndex: 1000 }} />
      <SpeedDial
        id={`${id}quick-actions`}
        ariaLabel="Dashboard Actions Button"
        className={classes.speedDial}
        hidden={hidden}
        icon={
          style?.icon ||
          (open ? (
            <span>
              <FlashOnIcon />
              Quick Actions
            </span>
          ) : (
            <FlashOnIcon />
          ))
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={style?.direction || "up"}
        FabProps={{
          size: open ? "large" : "small",
          title: "Quick Actions",
          variant: open ? "extended" : undefined,
        }}
        style={{
          position: "fixed",
          bottom: "3em",
          right: 0,
          ...style,
        }}
      >
        {actions.map(
          (action) =>
            action && (
              <SpeedDialAction
                key={action.name}
                icon={
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      style={{
                        marginRight: "0.5em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {action.icon}
                    </Icon>
                    {action.name}
                  </Typography>
                }
                tooltipTitle={<ChevronRight />}
                onClick={() => handleActionClick(action)}
                TooltipClasses={classes}
                FabProps={{
                  variant: "extended",
                  fullWidth: true,
                  style: { justifyContent: "flex-start", padding: "1.5em" },
                }}
              />
            ),
        )}
      </SpeedDial>
    </span>
  );
};

export default Dashboard;
