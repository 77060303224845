import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useUIContext } from "@utils/Context/UIContext";

import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { tryFunction } from "../../../utils/Functions/tryFunction";
import { performToastOperation } from "../../../utils/Toasts/functions/toastOperation";

import { useCreateCustomQuery } from "./useCreateCustomQuery";

/**
 * A custom query create Ui configured to edit a query instead of creating a new one
 * @param {string} organizationID - the currently selected organization
 * @param {function} toggleModal - function callback to close the modal on save press
 * @param {function} resetFunction - function callback to refresh the parent after edit is complete
 * @param {object} query - the custom query to edit
 * @returns {{display: JSX.Element}}
 */
export const useCustomQueryEditor = ({ organizationID, toggleModal, resetFunction, query }) => {
  const { addToast, updateToast } = useUIContext();
  const createCustomQuery = useCreateCustomQuery({
    organizationID,
    loadedQuery: query,
  });

  const onPressSave = async () => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        const { updateMutation } = generateGraphql("CustomQuery", ["queryConfig"]);

        await ItemMutation(updateMutation, {
          id: query.id,
          queryConfig: JSON.stringify(createCustomQuery?.builder?.query),
        });
      },
      inProgressText: "Saving custom query...",
      successText: "Saved custom query successfully",
      failureText: "Failed to save custom query",
    });

    tryFunction(toggleModal, resetFunction);
  };

  const display = (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: ".5em",
      }}
    >
      <div style={{ flex: 1 }}>{createCustomQuery.display}</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }} />
        <Button variant={"contained"} color={"success"} startIcon={<SaveIcon />} onClick={() => onPressSave()}>
          Save
        </Button>
      </div>
    </div>
  );

  return {
    display,
  };
};
