"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaximumLossToleranceValue = void 0;
/**
 * Returns the maxiumum number from the array of loss tolerance values
 * @param {number[]} lossToleranceValues
 * @returns {number}
 */
const getMaximumLossToleranceValue = (lossToleranceValues) => {
    return Math.max(...lossToleranceValues);
};
exports.getMaximumLossToleranceValue = getMaximumLossToleranceValue;
