import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { get, isEqual } from "lodash";
import React, { useContext } from "react";

import { createWidgetActions } from "../../../../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../../../../useCreateWidget";
import { ChartDataBuilderContext } from "../../ChartWidgetDataGraphBuilder";
import { chartDataBuilderActions } from "../../constants/chartDataBuilderActions";

import "./SeriesField.css";

/**
 * Component representing a single series custom query input
 * @param {string} label - the series name or identifier to place above other inputs
 * @param {object} seriesItem - current state of the series
 * @param {string} fieldRequirements - once selection is started these are the requirements for the custom query field
 * @param {object[]} path - the detailed path to the field in a Custom Query
 * @param {object} sx - style overrides for different parts of the component
 * @returns {JSX.Element}
 */
const SeriesField = ({ label, seriesItem, fieldRequirements, path, sx = {} }) => {
  const { fieldSelection, dispatchChartDataBuilder } = useContext(ChartDataBuilderContext);

  // eslint-disable-next-line no-undef
  const { dispatch } = useContext(CreateWidgetContext);

  const fieldSelectionActive = fieldSelection?.seriesItem?.id === seriesItem?.id && isEqual(fieldSelection?.path, path);
  const fieldPathSelected = get(seriesItem, path);
  const fieldSelected =
    Array.isArray(fieldPathSelected) && fieldPathSelected.length > 0
      ? fieldPathSelected[fieldPathSelected.length - 1]
      : null;
  return (
    <div style={{ ...sx?.container }}>
      {label && <p style={{ margin: 0 }}>{label}</p>}
      <div
        className={"widget-series-field"}
        style={{
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: "1em",
          width: "100%",
          padding: ".5em",
          borderColor: fieldSelectionActive ? "blue" : fieldSelected ? "green" : "red",
          color: "grey",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => {
          if (fieldSelectionActive) {
            dispatchChartDataBuilder({
              type: chartDataBuilderActions.CANCEL_FIELD_SELECTION,
            });
          } else {
            dispatchChartDataBuilder({
              type: chartDataBuilderActions.BEGIN_FIELD_SELECTION,
              fieldSelectionInput: {
                seriesItem,
                path,
                fieldRequirements,
              },
            });
          }
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            {fieldSelectionActive ? "Select a Highlighted Field" : fieldSelected || "Begin Selection"}
          </div>
          {fieldSelected && (
            <div
              className={"delete-widget-series-field-icon"}
              onClick={(e) => {
                dispatch({
                  type: createWidgetActions.MERGE_SERIES_FIELDS,
                  mergeSeriesInput: {
                    seriesId: seriesItem?.id,
                    path,
                    value: undefined,
                  },
                });
                e.stopPropagation();
              }}
              title={"Unlink this Source Field"}
            >
              <DeleteOutlineOutlinedIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeriesField;
