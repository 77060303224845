/**
 * Combines the statement number for a control and its name to get a long combined name
 * @param {object} control - risk control data
 * @return {string} - the long risk control name
 */
export const getLongRiskControlName = ({ control } = {}) => {
  let riskControlName = control?.name ?? "Unknown Risk Control";
  if (riskControlName !== "Unknown Risk Control" && control?.statementNumber) {
    riskControlName = `${control.statementNumber} ${riskControlName}`;
  }
  return riskControlName;
};
