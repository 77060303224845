"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSum = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the sum of all items in the array.
 * @param {*[]} items - array of items that can be converted to numeric values or are already numbers
 * @returns {number|null} - the sum of all items or null if no valid items were passed
 */
const getSum = ({ items }) => {
    const numericItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    if (!Array.isArray(numericItems) || numericItems.length === 0) {
        return null;
    }
    else {
        return numericItems.reduce((acc, item) => acc + item, 0);
    }
};
exports.getSum = getSum;
