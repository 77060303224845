import { ErrorLogger } from "@utils/EventLogger";

import { mitreAttackApiUrl } from "../../../../../env-config";

/**
 * @description Get list of Mitre Attack Techniques
 * @returns {Promise<any>}
 */
export const getTechniquesList = async () => {
  return await fetch(`${mitreAttackApiUrl}/api/techniques`)
    .then((response) => {
      try {
        return response.json().then(async (data) => {
          return (
            Array.isArray(data) &&
            data.map((item) => {
              item["id"] = item?.stix?.id;
              return item;
            })
          );
        });
      } catch (e) {
        ErrorLogger(e);
        return [];
      }
    })
    .catch((error) => ErrorLogger(error));
};
