"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNonEmptyArray = void 0;
/**
 * Checks if a variable is a valid array with at least one element
 */
const isNonEmptyArray = (value) => {
    return Array.isArray(value) && value.length > 0;
};
exports.isNonEmptyArray = isNonEmptyArray;
