"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetCustomQueries = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const func_utils_1 = require("@rivial-security/func-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
const seriesType_1 = require("../../../constants/seriesType");
/**
 * Retrieves the custom query results required to populate the widget based on its config
 * @param {object} config - the parsed `config` field from a Custom Widget
 * @param {object} customQueriesToIgnore - a map of custom query ids to data of queries which should not be retrieved again (current state of a cache)
 * @param {function} runCustomQuery - function to run custom queries given a customQueryId
 * @returns {Promise<{}>}
 */
const getWidgetCustomQueries = async ({ config, customQueriesToIgnore, runCustomQuery }) => {
    //Check arguments
    if (!(0, func_utils_1.isObject)(customQueriesToIgnore)) {
        customQueriesToIgnore = {};
    }
    const newCustomQueryCache = { ...customQueriesToIgnore };
    if (typeof runCustomQuery !== "function") {
        return newCustomQueryCache;
    }
    const series = config?.series;
    if (!(0, func_utils_1.isNonEmptyArray)(series)) {
        return newCustomQueryCache;
    }
    //Retrieve a list of queries that are referenced by the series
    const customQueryIds = [];
    if (Array.isArray(series)) {
        for (const seriesItem of series) {
            const type = seriesItem?.type;
            if (type === seriesType_1.SERIES_TYPE.AUTO) {
                const customQueryId = seriesItem?.customQueryId;
                if (customQueryId) {
                    customQueryIds.push(customQueryId);
                }
            }
            else if (type === seriesType_1.SERIES_TYPE.MANUAL) {
                const categories = seriesItem?.categories;
                if (Array.isArray(categories)) {
                    for (const category of categories) {
                        const customQueryId = category?.customQueryId;
                        if (customQueryId) {
                            customQueryIds.push(customQueryId);
                        }
                    }
                }
            }
        }
    }
    //Remove duplicates
    const deduplicatedCustomQueryIds = [];
    for (const customQueryId of customQueryIds) {
        if (!deduplicatedCustomQueryIds.includes(customQueryId)) {
            deduplicatedCustomQueryIds.push(customQueryId);
        }
    }
    //Check if cache has the query data use that instead of performing the query
    for (const customQueryId of deduplicatedCustomQueryIds) {
        if ((0, func_utils_1.isNullOrUndefined)(newCustomQueryCache[customQueryId])) {
            const { getQuery } = (0, generategraphql_1.generateGraphql)("CustomQuery", ["name", "queryConfig"]);
            const customQueryDefinition = await (0, appsync_utils_1.GetQuery)({
                query: getQuery,
                variables: {
                    id: customQueryId,
                },
            });
            const queryConfig = (0, func_utils_1.tryParse)(customQueryDefinition?.queryConfig);
            const result = await runCustomQuery({
                customQueryId,
            });
            newCustomQueryCache[customQueryId] = {
                result,
                config: queryConfig,
            };
        }
    }
    return newCustomQueryCache;
};
exports.getWidgetCustomQueries = getWidgetCustomQueries;
