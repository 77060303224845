import React, { useEffect, useState } from "react";
import { Col, Collapse, Input, InputGroup, Label, ListGroupItem, ListGroupItemText, Row, Table } from "reactstrap";

import { numberWithCommas } from "@rivial-security/func-utils";

import AccordionIcon from "../../../../../../utils/GenericComponents/AccordionIcon";
import TotalNumberOfRecords from "../../../../Systems/components/SystemAccordion/SystemImpact/components/TotalNumberOfRecords";

export const useAdjustInformationAssets = ({ informationAssets = [] }) => {
  const [newInformationAssets, setNewInformationAssets] = useState([]);

  useEffect(() => {
    if (informationAssets) {
      setNewInformationAssets([...informationAssets]);
    }
  }, [informationAssets]);

  const display = (
    <>
      {informationAssets && (
        <>
          <Col>
            <Label for="assetSizeChange">Change In Information Assets</Label>
            <Row>
              <TotalNumberOfRecords
                system={{
                  informationAssets: { items: [...newInformationAssets] },
                }}
              />
            </Row>
            <Row>
              {informationAssets.map((item, index) => (
                <AdjustInformationAssetItem
                  key={index}
                  newInformationAssets={newInformationAssets}
                  setNewInformationAssets={setNewInformationAssets}
                  item={{
                    ...item.informationAsset,
                    informationAssetLink: item,
                  }}
                  isOpen={true}
                />
              ))}
            </Row>
          </Col>
        </>
      )}
    </>
  );

  return {
    display,
    newInformationAssets,
  };
};

const AdjustInformationAssetItem = (props) => {
  const { item } = props;

  const [isOpen, setIsOpen] = useState(!!props.isOpen);

  const dictionary = {
    high: "High",
    mediumHigh: "Medium-High",
    medium: "Medium",
    lowMedium: "Low-Medium",
    low: "Low",
  };

  const [oldNumberOfRecords, setOldNumberOfRecords] = useState(0);
  const [newNumberOfRecords, setNewNumberOfRecords] = useState(0);

  useEffect(() => {
    const num =
      item && item.informationAssetLink && item.informationAssetLink.numberOfRecords
        ? parseInt(item.informationAssetLink.numberOfRecords)
        : 0;
    setOldNumberOfRecords(num);
    setNewNumberOfRecords(num);
  }, []);

  useEffect(() => {
    if (newNumberOfRecords) {
      const informationAssets = [...props.newInformationAssets];

      const found = informationAssets.find((x) => x.id === item.informationAssetLink.id);

      if (found) {
        found.numberOfRecords = parseInt(newNumberOfRecords);
      }

      props.setNewInformationAssets([...informationAssets]);
    }
  }, [newNumberOfRecords]);

  return (
    <ListGroupItem>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
        }}
      >
        <h8>{item.name}</h8>
        <AccordionIcon isOpen={isOpen} />
      </div>
      <Collapse isOpen={isOpen}>
        <ListGroupItemText style={{ marginTop: "1em" }}>
          <Table>
            <tr>
              <th scope="row">Confidentiality Rating</th>
              <th scope="row">Integrity Rating</th>
              <th scope="row">Number of Records</th>
            </tr>
            <tr>
              <td>{dictionary[item.confidentiality]}</td>
              <td>{dictionary[item.integrity]}</td>
              <td>
                <Row>
                  <Col md={4}>
                    <>
                      {parseInt(newNumberOfRecords) === parseInt(oldNumberOfRecords) ? (
                        `${numberWithCommas(oldNumberOfRecords)} (No Change)`
                      ) : (
                        <>
                          <del title={`Old Number of Records: ${numberWithCommas(oldNumberOfRecords)}`}>
                            {numberWithCommas(oldNumberOfRecords)}
                          </del>
                          <span
                            title={`New Number of Records: ${numberWithCommas(newNumberOfRecords)}`}
                            style={{
                              color: newNumberOfRecords > oldNumberOfRecords ? "red" : "green",
                              marginLeft: "0.5em",
                            }}
                          >
                            {numberWithCommas(newNumberOfRecords)}
                            <br />[ {newNumberOfRecords > oldNumberOfRecords && "+"}{" "}
                            {(((newNumberOfRecords - oldNumberOfRecords) / oldNumberOfRecords) * 100).toFixed(2)}% ]
                          </span>
                        </>
                      )}
                    </>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Input
                        type="number"
                        id="numberOfCustomersChange"
                        value={newNumberOfRecords}
                        onChange={(e) => setNewNumberOfRecords(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </td>
            </tr>
          </Table>
        </ListGroupItemText>
      </Collapse>
    </ListGroupItem>
  );
};
