import Grid from "@mui/material/Grid";
import React from "react";

import { formattedPercent } from "@rivial-security/func-utils";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import { modules as MODULES, modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import AccessControl from "../../../../utils/AccessControl/components/AccessControl";
import AddAccessControl from "../../../../utils/AccessControl/components/AddAccessControl";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import GenerateReportModal from "../../../../utils/GenericComponents/GenerateReportModal";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";
import GroupPlaybook from "../../Plan/Playbooks/components/GroupPlaybook";
import { usePlaybookTotalCompletionTracker } from "../../Plan/Playbooks/hooks/usePlaybookTotalCompletionTracker";
import IncidentDocuments from "../components/IncidentDocuments";
import IncidentEscalation from "../components/IncidentEscalation";
import IncidentMeetingButton from "../components/IncidentMeetingButton";
import UploadIncidentDocumentButton from "../components/UploadIncidentDocumentButton";
import useConfigurePlaybookButton from "../components/useConfigurePlaybookButton";
import IncidentStatus from "../customFields/IncidentStatus";
import IncidentWhen from "../customFields/IncidentWhen";
import { getIncidentQuery } from "../graphql/getIncidentQuery";
import { updateIncidentMutation } from "../graphql/updateIncidentMutation";

/**
 * Displays Incident Details
 * @param {string} itemId - incident object id
 * @param {function} getUpdatedItem - get updated item function
 * @param {string} organizationID - selected organization id
 * @param {object} props - custom overrides
 */
export const useIncidentDetails = ({ itemId, getUpdatedItem, organizationID, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const fieldNameDictionary = {
    what: "What Happened",
    who: "Who's Involved",
  };

  const fields = ["status", "what", "when", "who", "where", "why"];

  const customFields = [
    {
      field: "status",
      component: <IncidentStatus />,
    },
    {
      field: "when",
      component: <IncidentWhen />,
    },
  ];

  const queryConfig = {
    query: getIncidentQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    updateMutation: updateIncidentMutation,
    fieldNameDictionary,
    customFields,
  };

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    updateItemById: getUpdatedItem,
    config: {
      enableNotes: true,
      observationConnectionField: "incidentID",
    },
    ...props,
  });

  const { configurePlaybookModalButton, setPlaybookBuilderModalIsOpen } = useConfigurePlaybookButton({
    item: detailsHook?.item,
    resetFunction: detailsHook?.reset,
    typename: "Incident",
    organizationID,
  });

  const { setTotalCompletion, completedDecimal } = usePlaybookTotalCompletionTracker({ item: detailsHook?.item });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Context of the Incident",
        component: detailsHook.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Escalation",
        subTitle: "Classification Tier and Detection Type",
        component: (
          <IncidentEscalation
            item={detailsHook?.item}
            resetFunction={detailsHook?.reset}
            organizationID={organizationID}
          />
        ),
        icon: "icon-directions",
      },
      {
        title: "Findings",
        subTitle: "Associated Observations, Recommendations and Actions",
        component: <FindingsCard incident={detailsHook?.item} organizationID={organizationID} />,
        icon: "icon-rocket",
      },
      {
        title: "Documents",
        subTitle: "Uploaded supplemental files",
        component: <IncidentDocuments item={detailsHook?.item} organizationID={organizationID} />,
        icon: "icon-docs",
        headerButtons: [
          <UploadIncidentDocumentButton
            item={detailsHook?.item}
            organizationID={organizationID}
            resetFunction={() => detailsHook?.reset()}
          />,
        ],
      },
      {
        title: "Playbook",
        subTitle: `Procedures to complete in response to the Incident
          ${completedDecimal !== 0 ? `(${formattedPercent(completedDecimal)} Done)` : ""}`,
        component: (
          <GroupPlaybook
            module={module}
            resource={resource}
            typename={"Incident"}
            item={{ ...detailsHook?.item, resetIndex: detailsHook?.resetIndex }}
            resetFunction={detailsHook.reset}
            setTotalCompletion={setTotalCompletion}
            setPlaybookBuilderModalIsOpen={setPlaybookBuilderModalIsOpen}
            observationConnectionField={"incidentID"}
            incidentID={itemId}
          />
        ),
        icon: "icon-book-open",
        headerButtons: [...(checkPermissionsHook?.resource?.update ? [configurePlaybookModalButton] : [])],
      },
    ],
    titleFlexBasis: "30%",
  });

  const display = (
    <AccessControl item={detailsHook?.item} resetFunction={detailsHook?.reset} typename={"Incident"}>
      <Dashboard
        item={detailsHook?.item}
        subTitle={detailsHook?.item?.what}
        headerButtons={[
          ...(checkPermissionsHook.resource.read
            ? [
                <GenerateReportModal
                  itemId={itemId}
                  organizationID={organizationID}
                  type={REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE}
                  module={MODULES.INCIDENT_RESPONSE}
                />,
              ]
            : []),
          ...(checkPermissionsHook.resource.update
            ? [
                <IncidentMeetingButton resetFunction={detailsHook.reset} />,
                <AddAccessControl
                  resetFunction={detailsHook?.reset}
                  typename={"Incident"}
                  accessControlEnabled={true}
                />,
                <AutomationsButton itemId={itemId} typename={"Incident"} />,
              ]
            : []),
        ]}
        resetFunction={detailsHook.reset}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Dashboard>
    </AccessControl>
  );

  return {
    ...detailsHook,
    display,
  };
};
