import { useVendorSubControlGrid } from "../hooks/useVendorSubControlGrid";

/**
 * @description Component instance of the useVendorSubControlGrid hook;
 * @returns {*}
 * @constructor
 */
const VendorSubControlGrid = (props) => useVendorSubControlGrid(props).display;

export default VendorSubControlGrid;
