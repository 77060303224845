/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5yi562fuyvey5f22tqeebek22y.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:63acfc71-7ef8-4398-9276-77cf650326a9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_n3o0xVBI5",
    "aws_user_pools_web_client_id": "3t3ttguutdlgjj9pedo2eqfv8f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "risc-prod-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dhar94d3vmkrq.cloudfront.net",
    "aws_user_files_s3_bucket": "evidence-storage-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
