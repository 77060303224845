import React, { useEffect } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useControlCategoriesGrid } from "../../../../../RiskConfig/ControlCategories/hooks/useControlCategoriesGrid";

/**
 * Allows user to select a subset of risk control categories, for which the control need to be marked as outsourced
 * @param {string} organizationID - currently selected organization's identifier in the database
 * @param {function} onChangeCallback - call back function when the selected rows have changed
 * @param {object} [system] - if provided with control category links will filter selectable control categories to system specific ones
 * @return {JSX.Element}
 * @constructor
 */
const SelectOutsourcedControlCategories = ({ organizationID, onChangeCallback, system }) => {
  const grid = useControlCategoriesGrid({
    organizationID,
    queryConfig: {
      query: generateGraphql("RiskControlCategory", ["name", "subControls"], {
        subControls: `(limit: 1000) { items { id __typename } nextToken }`,
      }).listQuery,
      normalizeData: (data) => {
        const controlCategoriesLinks = system?.controlCategories?.items;
        const controlCategoryIDs = controlCategoriesLinks?.map((link) => link?.riskControlCategory?.id);

        let newData = [];
        if (Array.isArray(controlCategoryIDs)) {
          newData = data.filter((item) => {
            return item?.id && controlCategoryIDs.includes(item?.id);
          });
        }

        return newData;
      },
    },
    gridConfig: {
      enableCheckBoxes: true,
      deleteFunction: null,
      createResourceComponent: null,
      gridHeight: "50vh",
    },
  });

  useEffect(() => {
    onChangeCallback && onChangeCallback(grid?.selectedItems || []);
  }, [grid?.selectedItems]);

  return <div>{grid?.gridDisplay}</div>;
};

export default withOrganizationCheck(SelectOutsourcedControlCategories);
