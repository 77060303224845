"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRiskToleranceConfig = void 0;
const contextType_1 = require("../contextType");
/**
 * Preset settings to use when rendering or displaying a risk tolerance widget
 * in different contexts (when there is no other config provided)
 */
exports.defaultRiskToleranceConfig = {
    [contextType_1.CONTEXT_TYPE.REPORT]: {
        layout: {
            width: "900px",
            height: "500px",
            margin: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.WEB]: {
        layout: {
            width: "100%",
            height: "100%",
            margin: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
    },
};
