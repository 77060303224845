import React from "react";

import { TEMPLATE } from "../../enums/TEMPLATE";
import Tag from "../Tags/components/Tag";

/**
 * Displays the type of template that the provided item represents by looking at the ownerGroup field
 * @param {string} ownerGroup - the owner group of the item based on which the tag is determined
 * @param {object} item - the grid item with all column data
 * @return {JSX.Element} - a tag component with the right of template type displayed
 */
const TemplateTypeField = ({ ownerGroup, item }) => {
  if (ownerGroup === TEMPLATE) {
    return (
      <Tag
        tag={{
          name: "Global",
          description: "A global template provided by Rivial",
          fontColor: "#FFFFFF",
          backgroundColor: "#448AB8",
        }}
        isSelected={true}
        isDisabled={false}
      />
    );
  } else {
    return (
      <Tag
        tag={{
          name: "Operation Team",
          description: "A private template managed by your operation team",
          fontColor: "#000000",
          backgroundColor: "#ffc500",
        }}
        isSelected={true}
        isDisabled={false}
      />
    );
  }
};

export default TemplateTypeField;
