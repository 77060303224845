import { isNullOrUndefined } from "@rivial-security/func-utils";

import { selectMultipleItems } from "./selectMultipleItems";

/**
 * Called when all grid elements finished loading into the dom
 * @param {object} ref - syncfusion grid reference object
 * @param {boolean} enableCheckBoxes - true if checkboxes need to be displayed next to the grid items
 * @param {object[]} itemsToCheck - all items marked to be checked upon grid loading
 * @param {function} setItemsToCheck - state control function for itemsToCheck (from useGrid.js)
 * @param isLoading - passed from useGrid, true when data is still loading (being queried, etc)
 */
export const gridOnCreate = ({ ref, enableCheckBoxes, itemsToCheck, setItemsToCheck, isLoading }) => {
  if (enableCheckBoxes && !isNullOrUndefined(itemsToCheck) && Array.isArray(itemsToCheck) && itemsToCheck.length > 0) {
    selectMultipleItems({
      ref,
      items: itemsToCheck,
      callback: () => {
        isLoading === false && setItemsToCheck && setItemsToCheck(null);
      },
    });
  }
};
