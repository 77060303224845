"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineNotes = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Takes an array of notes and combines all of them into a single string. This is useful for combining notes from multiple resources.
 * @param {[][]} noteCollections - array of arrays of notes
 * @returns {null|string} - the combined string or null if no notes were passed
 */
const combineNotes = ({ items: noteCollections }) => {
    if (Array.isArray(noteCollections)) {
        let allNotes = "";
        for (const noteCollection of noteCollections) {
            const currentNotes = (0, func_utils_1.concatNotes)(noteCollection);
            if (currentNotes) {
                if (allNotes.length > 0) {
                    allNotes += " ";
                }
                allNotes += currentNotes;
            }
        }
        return allNotes;
    }
    else {
        return null;
    }
};
exports.combineNotes = combineNotes;
