import { useThreatDataGrid } from "../../../../views/Risk/RiskConfig/Threats/hooks/useThreatDataGrid";

export const threatDefinition = {
  routes: {
    grid: "risk/threats",
    details: "risk/threats",
    helpCenter: "threats",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:target",

  //[COMPONENTS]
  gridHook: useThreatDataGrid,
};
