import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

const Enabled = ({ enabled, item, resetFunction }) => {
  const { updateMutation } = generateGraphql("MetricType", ["enabled"]);

  const checkPermissionsHook = useCheckPermissions({
    module: modules.METRICS,
    resource: resources.METRIC_TYPE,
    field: "enabled",
  });

  const onChange = (checked) => {
    checkPermissionsHook.field.update &&
      ItemMutation(updateMutation, {
        id: item.id,
        enabled: checked,
      });
  };

  return (
    <div>
      <SwitchComponent
        disabled={!checkPermissionsHook.field.update || isNullOrUndefined(item?.automation?.name)}
        checked={enabled}
        change={({ checked }) => onChange(checked)}
      />
    </div>
  );
};

export default Enabled;
