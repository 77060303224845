"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWhenSeenVulnerabilityGroup = void 0;
/**
 * Returns the earliest or latest date of a vulnerability group
 * Note: This function requires each TargetVulnerabilityLink to have a 'firstSeen' or 'lastSeen' date
 * @param {object[]} targetVulnerabilityLinks - list of target links attached to the vulnerability
 * @param {string} whenSeen - the field name for the vulnerability group seen to show
 * @returns {string | undefined}
 */
const getWhenSeenVulnerabilityGroup = ({ targetVulnerabilityLinks, whenSeen = "firstSeen" }) => {
    const links = targetVulnerabilityLinks?.items || targetVulnerabilityLinks || [];
    if (Array.isArray(links) && links?.length > 0) {
        /**
         * Extracting an array of 'firstSeen' or 'lastSeen' dates
         */
        const dates = links?.map((link) => (link?.[whenSeen] ? new Date(link?.[whenSeen]) : undefined));
        /**
         * Find the earliest or latest date
         */
        const foundDate = dates?.reduce((whenSeenDate, current) => {
            if (whenSeen === "firstSeen") {
                return current < whenSeenDate ? current : whenSeenDate;
            }
            else if (whenSeen === "lastSeen") {
                return current > whenSeenDate ? current : whenSeenDate;
            }
            return whenSeenDate;
        });
        if (foundDate instanceof Date) {
            return foundDate?.toISOString();
        }
    }
};
exports.getWhenSeenVulnerabilityGroup = getWhenSeenVulnerabilityGroup;
