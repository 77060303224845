"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.union = void 0;
/**
 * Returns an array that combines two arrays but prevents duplicates
 * @param a
 * @param b
 * @returns {Array}
 */
const not_1 = require("./not");
const union = (a, b) => {
    return [...a, ...(0, not_1.not)(b, a)];
};
exports.union = union;
