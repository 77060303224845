import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";

import { getFunctionFieldName } from "@rivial-security/widget-utils";

import { getResourceAttribute } from "../../../../../definitions/functions/getResourceAttribute";
import { getResourcePrimitiveTypes } from "../../../functions/getResourcePrimitiveTypes";

/**
 * A dynamic multi select component for choosing functions to apply once data has been retrieved
 * @param {string} typename - the typename of the field that is currently being configured
 * @param {object[]} path - the current chain of resources that the user is building in the query builder
 * @param {object} containerStyle - css styles to apply to the container of the UI
 * @param {object[]} selectedFunctions- object array of all functions currently configured at this element
 * @param {function} onSelectFunctions - callback function for a user adding a new function to the query
 * @param {function} onClickNestedFunction - callback on user click of a function chip, presumably to configure it
 * @return {JSX.Element} - a dropdown multi select for choosing functions to apply to the data once it is retrieved
 */
const ResourceFunctionsMultiSelect = ({
  typename,
  path,
  containerStyle,
  selectedFunctions,
  onSelectFunctions,
  onClickNestedFunction,
}) => {
  const [selectedFunctionNames, setSelectedFunctionNames] = useState([]);
  useEffect(() => {
    const newSelectedFunctionNames = [];
    for (const func of selectedFunctions) {
      const functionName = getFunctionFieldName({ pathElement: func });
      if (functionName) {
        newSelectedFunctionNames.push(functionName);
      }
    }
    setSelectedFunctionNames(newSelectedFunctionNames);
  }, [typename, path, selectedFunctions]);

  const [availableFunctions, setAvailableFunctions] = useState([]);
  useEffect(() => {
    //check if the particular resource type has any predefined functions
    const newDefaultAvailableFunctions = getResourceAttribute({
      typename,
      attribute: "queries.functions",
    });

    let newAvailableFunctions = [];
    if (Array.isArray(newDefaultAvailableFunctions)) {
      newAvailableFunctions = [...newDefaultAvailableFunctions];
    }

    //check if any generic functions match the last resource type in the path
    if (Array.isArray(path) && path.length > 0) {
      const lastPathElement = path[path.length - 1];
      const primitiveTypes = getResourcePrimitiveTypes({
        resource: lastPathElement,
      });
      if (Array.isArray(primitiveTypes) && primitiveTypes.length > 0) {
        for (const primitiveTypename of primitiveTypes) {
          if (typename !== primitiveTypename) {
            const newGenericAvailableFunctions = getResourceAttribute({
              typename: primitiveTypename,
              attribute: "queries.functions",
            });
            if (Array.isArray(newGenericAvailableFunctions)) {
              newAvailableFunctions.push(...newGenericAvailableFunctions);
            }
          }
        }
      }
    }

    //check if any custom renamed functions are in the selected functions, add them as options
    for (const selectedFunction of selectedFunctions) {
      const selectedFunctionName = getFunctionFieldName({
        pathElement: selectedFunction,
      });
      if (selectedFunctionName) {
        const existingFunction = newAvailableFunctions.find(
          (defaultFunction) => defaultFunction?.name === selectedFunctionName,
        );
        if (!existingFunction) {
          newAvailableFunctions.push(selectedFunction);
        }
      }
    }

    setAvailableFunctions(newAvailableFunctions);
  }, [typename, selectedFunctions]);

  /**
   * Handles user selecting a new function to apply to the data
   * @param {object} event - multi select dropdown change event
   */
  const handleChange = (event) => {
    const newSelectedFunctionNames = event?.target?.value || [];

    setSelectedFunctionNames(newSelectedFunctionNames);
    if (typeof onSelectFunctions === "function") {
      const newFunctions = [];
      for (const selectedFunctionName of newSelectedFunctionNames) {
        let func;
        if (Array.isArray(selectedFunctions)) {
          func = selectedFunctions.find((func) => getFunctionFieldName({ pathElement: func }) === selectedFunctionName);
        }

        if (!func && Array.isArray(availableFunctions)) {
          func = availableFunctions.find(
            (func) => getFunctionFieldName({ pathElement: func }) === selectedFunctionName,
          );
        }

        if (func) {
          newFunctions.push(cloneDeep(func));
        }
      }
      onSelectFunctions(newFunctions);
    }
  };

  return (
    <div
      style={{
        marginTop: "1em",
        display: !Array.isArray(availableFunctions) || availableFunctions.length === 0 ? "none" : "block",
        ...containerStyle,
      }}
    >
      <FormControl fullWidth sx={{ minWidth: 300 }}>
        <InputLabel id="resource-field-select-label">Functions</InputLabel>
        <Select
          id="resource-field-select"
          multiple
          value={selectedFunctionNames}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Functions" />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selected.map((functionName) => (
                <Chip
                  key={functionName}
                  label={functionName}
                  onMouseDown={(e) => {
                    const func = selectedFunctions.find((func) => {
                      return getFunctionFieldName({ pathElement: func }) === functionName;
                    });

                    if (func) {
                      if (typeof onClickNestedFunction === "function") {
                        onClickNestedFunction(func);
                      }
                    }

                    e.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
        >
          {Array.isArray(availableFunctions) &&
            availableFunctions.map((func, index) => {
              const name = getFunctionFieldName({ pathElement: func }) || `function${index}`;
              return (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <div style={{ height: "1em" }} />
    </div>
  );
};

export default ResourceFunctionsMultiSelect;
