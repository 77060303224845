import { TextField } from "@mui/material";
import { get } from "lodash";
import React, { useContext, useState } from "react";

import { useDebounce } from "../../../../../../../../../../hooks/functional/useDebounce";
import { createWidgetActions } from "../../../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../../../useCreateWidget";

/**
 * Component responsible for gathering the name of a Category for a manual series
 * @param {string} label - label to display above input
 * @param {string[]} path - path to the field in the series
 * @param {object} seriesItem - current state of the series to get current value
 * @returns {JSX.Element}
 */
export const CategoryNameField = ({ label, path, seriesItem }) => {
  const { dispatch } = useContext(CreateWidgetContext);

  const [realtimeValue, setRealtimeValue] = useState(get(seriesItem, path));
  useDebounce({
    value: realtimeValue,
    delay: 1000,
    func: (newValue) => {
      dispatch({
        type: createWidgetActions.MERGE_SERIES_FIELDS,
        mergeSeriesInput: {
          seriesId: seriesItem?.id,
          path,
          value: newValue,
        },
      });
    },
  });

  return (
    <TextField
      id="outlined-basic"
      label={label || "Category"}
      variant="outlined"
      value={realtimeValue}
      onChange={(e) => {
        setRealtimeValue(e.target.value);
      }}
      style={{ flex: 1 }}
    />
  );
};
