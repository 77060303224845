import "jspreadsheet-ce/dist/jspreadsheet.css";

import jspreadsheet from "jspreadsheet-ce";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader } from "reactstrap";

import { concatNotes } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useModal } from "../views/useModal";

/**
 * @description This hook sterilize data from table to CSV file
 * @param {object[]} listItems - list of items to export
 * @param {object[]} fields - array of string, fields that you want to get in CSV file
 * @param {string} csvFileName - specify filename of CSV file
 * @returns {object} - object with csv file
 */
export const useExport = ({ listItems, fields: fieldsInit, csvFileName, transformNotes = false }) => {
  const [list, setList] = useState([]);
  const [table, setTable] = useState(null);
  const [tableHeader, setTableHeader] = useState([]);

  const [fields, setFields] = useState(fieldsInit);

  /**
   * Parses the listItems into a csv string
   * @returns {object} {*[]}
   */
  const createList = () => {
    if (listItems && listItems.length > 0) {
      let itemArrays = [...listItems];

      let arrayJSON = [];

      /**
       * If param is true, try and transform any field titled 'notes'
       */
      if (transformNotes) {
        try {
          const temp = [];
          for (let itemArray of itemArrays) {
            itemArray = itemArray.map((item) => {
              return {
                ...item,
                notes: item.notes ? concatNotes(item.notes) : undefined,
              };
            });
            temp.push(itemArray);
          }
          itemArrays = [...temp];
        } catch (e) {
          ErrorLogger("Could not transform notes: ", e);
          itemArrays = listItems;
        }
      }

      try {
        const stringArray = JSON.stringify(itemArrays);
        arrayJSON = JSON.parse(stringArray);
      } catch (e) {
        ErrorLogger("Error! Can not parse listItems in useExport hook! ", e);
      }

      const newData = [].concat(...arrayJSON);

      for (const item of newData) {
        for (const key in item) {
          if (!fields.includes(key)) {
            delete item[key];
          }
        }
      }

      return newData;
    }
  };

  /**
   * Handles table headers
   */
  useEffect(() => {
    if (fields && fields.length > 0) {
      setTableHeader([]);

      const tableHeaderLocal = [];

      for (const field of fields) {
        tableHeaderLocal.push({
          type: "text",
          width: "300",
          title: field,
          name: field,
        });
      }

      setTableHeader(tableHeaderLocal);
    }
  }, [fields]);

  const CreateNewTable = () => {
    const newTable = jspreadsheet(document.getElementById("export-spreadsheet"), {
      data: createList(),
      minDimensions: [1, 1],
      tableWidth: "100%",
      csvFileName: csvFileName ? csvFileName : "listData",
      csvHeaders: true,
      columns: tableHeader,
      columnDrag: true,
    });

    setTable(newTable);
  };

  const display = (
    <Card>
      <CardHeader>
        <ButtonGroup className="float-right">
          <Button
            color="primary"
            size="sm"
            outline
            className="btn-pill"
            onClick={() => {
              CreateNewTable();
            }}
          >
            Start Export
          </Button>
          <Button
            disabled={!table}
            color="success"
            size="sm"
            className="btn-pill"
            outline
            onClick={() => table.download()}
          >
            Download
          </Button>
        </ButtonGroup>
      </CardHeader>
      <CardBody>
        <div id="export-spreadsheet" style={{ width: "100%", overflow: "auto" }} />
      </CardBody>
    </Card>
  );

  const exportTableModal = useModal(
    "Export to CSV",
    <>{display}</>,
    <Button
      size="sm"
      color="ghost-warning"
      className="float-right btn-pill"
      style={{ boxShadow: "none" }}
      title="Export to CSV"
    >
      <i className="icon-doc" />
    </Button>,
    {
      width: "95vw",
    },
  );

  return {
    ...exportTableModal,
    table,
    list,
    display,
    setFields,
    fields,
  };
};
