import { isNullOrUndefined } from "@rivial-security/func-utils";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";
import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";

/**
 * @description Handles row deselection
 * @param {object} args - object with arguments of the deselected item
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {JSX} detailsComponent - jsx component for displaying an item
 */
export const gridRowDeselected = ({
  args,
  setSelectedItems,
  detailsType,
  disableUpdate,
  detailsBar,
  detailsComponent,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    args,
    setSelectedItems,
    detailsType,
    disableUpdate,
    detailsBar,
    ...(detailsType !== DETAILS_TYPES.NONE && { detailsComponent }),
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  /**
   * Get and set selected items
   */
  if (args?.data?.id && setSelectedItems) {
    setSelectedItems((selectedItems) => {
      const tempArray = [...selectedItems];

      const index = tempArray.findIndex((item) => item.id === args.data.id);

      /**
       * Removes this item from selectedItems array
       * @type {number}
       */
      if (index !== -1) {
        tempArray.splice(index, 1);
      }

      return [...tempArray];
    });
  } else if (Array.isArray(args.data) && setSelectedItems) {
    setSelectedItems((selectedItems) => {
      /**
       * Remove multiple items on deselected
       */
      for (const item of args.data) {
        const index = selectedItems.findIndex((x) => x.id === item.id);

        /**
         * Removes this item from selectedItems array
         */
        if (index !== -1) {
          selectedItems.splice(index, 1);
        }
      }
      return [...selectedItems];
    });
  } else {
    setSelectedItems([]);
  }

  // Handle Chrome and Firefox
  const ariaSelected = args?.target?.ariaSelected || args.target?.attributes["aria-selected"]?.nodeValue;

  if (!isNullOrUndefined(ariaSelected)) {
    /**
     * Only closes the details bar when this row is explicitly deselected, not when selecting another row
     */
    if (ariaSelected === "true") {
      detailsType === DETAILS_TYPES.PANEL &&
        disableUpdate.current === false &&
        !detailsBar.pinned &&
        detailsComponent &&
        detailsBar &&
        detailsBar.setIsOpen(false);
    }
  }
};
