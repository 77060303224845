import React, { useEffect } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { DEFAULT_SMART_VALUES } from "../../../../utils/SmartValues/constants/DEFAULT_SMART_VALUES";
import { getAutomationTriggerSmartValues } from "../functions/getAutomationTriggerSmartValues";

/**
 * Custom hook for retrieving the list of smart values for the current Automation
 *
 * @param {object} item - the input object from useForm
 * @param {object} item.trigger
 * @param {string} item.trigger.type
 * @param {object} item.condition
 * @param {object[]} item.condition.filters
 * @param {string} item.condition.typename
 * @param {string[]} item.condition.fields
 * @returns {{setSmartValues: (value: (((prevState: string[]) => string[]) | string[])) => void, smartValues: string[]}}
 */
export const useAutomationSmartValues = ({ item }) => {
  const [smartValues, setSmartValues] = React.useState(DEFAULT_SMART_VALUES);

  // re-fetch suggested smart values based on the Automation configuration
  useEffect(() => {
    if (!isNullOrUndefined(item)) {
      try {
        // get trigger values
        const values = getAutomationTriggerSmartValues({ item });

        setSmartValues([...DEFAULT_SMART_VALUES, ...values]);
      } catch (e) {
        ErrorLogger("Error: Couldn't fetch Automation Smart Values", e);
      }
    }
  }, [JSON.stringify(item)]);

  return {
    smartValues,
    setSmartValues,
  };
};
