"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCommaString = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Creates a comma separated String from an array of objects
 *
 * @example
 *
 * createCommaString(
 * [
 *    {name: "Jacob"},
 *    {name: "Bob"}
 * ], "name")
 *
 * or
 *
 * createCommaString([
 *    {pointOfContact: {name: Jacob}},
 *    {pointOfContact: {name:"Bob"}}
 * ], "pointOfContact", "name")
 *
 * output: "Jacob, Bob"
 *
 * @param array
 * @param field
 * @param nestedField
 * @returns {string}
 */
const createCommaString = (array, field, nestedField) => {
    let str = "";
    if (Array.isArray(array)) {
        let index = 0;
        for (const val of array) {
            if (!(0, isNullOrUndefined_1.isNullOrUndefined)(field)) {
                if (typeof val === "object") {
                    if (!(0, isNullOrUndefined_1.isNullOrUndefined)(nestedField) && val?.[field]?.[nestedField]) {
                        str = str.concat(val[field][nestedField]);
                    }
                    else {
                        str = str.concat(val[field]);
                    }
                    if (index < array.length - 1)
                        str = str.concat(", ");
                }
            }
            index++;
        }
    }
    return str;
};
exports.createCommaString = createCommaString;
