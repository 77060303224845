import { customFieldPrefix } from "../constants/customFieldPrefix";

/**
 * The name of the custom field to use as id
 * @param {string} field - current custom field information
 * @returns {null|string}
 */
export const getCustomFieldName = (field) => {
  if (field?.name) {
    return `${customFieldPrefix}_${field.name}`;
  }
  return null;
};
