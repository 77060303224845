import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, { useEffect } from "react";

import { findObjectInArray, isNullOrUndefined } from "@rivial-security/func-utils";

import SelectButtonField from "../components/SelectButtonField";

// IMPORTANT: in order to prevent infinite loops any new custom addon columns need to be added to the disregardList in useGrid hook

/**
 * @description Custom fields for the grid
 * @param {object} ref - reference of the the grid component
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {boolean} enableQuickDetails - display "info" icon as a first row of the grid, on click opens a modal with item details
 * @param {JSX} detailsModal - modal component for displaying details of an object
 * @param {boolean} enableNoteIcon - enables note icon in the grid
 * @param {JSX} noteIconComponent - JSX component for notes
 * @param {string} noteIconColumnName - specify the noteIconColumnName for the grid
 * @param {boolean} enableOptions - enables a column with dropdown with options
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteMessage - message to display when deleting an item
 * @param {object} deleteConfirmModal - modal to confirm items to delete
 * @param {object[]} selectedItems - array of selected items in the grid
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {boolean} enableSelectButtons - enable select button for the grid
 * @param {function} selectButtonCallback - callback function for the select button
 * @param {object} checkPermissionsHook - checks permissions for the grid component
 */
export const useGridCustomColumns = ({
  ref,
  fields,
  enableQuickDetails,
  detailsModal,
  enableNoteIcon,
  noteIconComponent,
  noteIconColumnName,
  enableOptions,
  deleteFunction,
  deleteMessage,
  deleteConfirmModal,
  selectedItems,
  setSelectedItems,
  enableSelectButtons,
  selectButtonCallback,
  checkPermissionsHook,
}) => {
  /**
   * Add options for custom field in the grid
   */
  const options = () => {
    const array = [];

    array.push({
      id: "details",
      text: "Details",
      iconCss: "icon-share-alt",
    });

    checkPermissionsHook.resource.delete &&
      array.push({
        id: "delete",
        text: "Delete",
        iconCss: "icon-trash",
      });
    return array;
  };

  useEffect(() => {
    /**
     * Check fields array for items
     */
    const checkFields = ({ item, name }) => {
      if (item && name) {
        if (!findObjectInArray(fields, { name: name }, "name")) {
          fields.unshift(item);
        } else {
          const index = fields.findIndex((x) => x.name === name);
          if (index > -1) fields[index] = item;
        }
      }
    };

    /**
     * Check to make sure there isn't already this column so we don't add it multiple times.
     */
    if (!isNullOrUndefined(fields)) {
      /**
       * Add QuickDetails modal column to the grid
       */
      const detailsItem = {
        name: "ℹ",
        width: "50",
        minWidth: "50",
        allowFiltering: false,
        allowGrouping: false,
        component: detailsModal.modalButton,
        headerTextAlign: "Left",
        showInColumnChooser: false,
        headerTemplate: () => <InfoOutlinedIcon />,
      };

      enableQuickDetails && checkFields({ item: detailsItem, name: "ℹ" });

      const notesItem = {
        name: noteIconColumnName || "",
        width: "40",
        allowFiltering: false,
        allowGrouping: false,
        headerTemplate: () => {
          return <div />;
        },
        component: noteIconComponent,
        headerTextAlign: "Left",
      };

      enableNoteIcon && checkFields({ item: notesItem, name: noteIconColumnName });

      const selectItem = {
        name: "select",
        width: "100",
        allowFiltering: false,
        allowGrouping: false,
        headerTemplate: () => {
          return <div />;
        },
        component: <SelectButtonField onClickItem={selectButtonCallback} />,
        headerTextAlign: "Left",
      };

      enableSelectButtons && checkFields({ item: selectItem, name: "select" });

      /**
       * Add Options Dropdown column to the grid
       */

      const optionsItem = {
        name: "options",
        width: "50",
        component: (
          <DropDownButtonComponent
            id="options-dropdown"
            select={(args) => {
              if (args?.item.id === "details") {
                document.getElementById("details-page").click();
              } else if (args?.item.id === "delete") {
                deleteConfirmModal?.setModalIsOpen(true);
              }
            }}
            iconCss="icon-wrench"
            items={options()}
          >
            {" "}
          </DropDownButtonComponent>
        ),
      };

      enableOptions && checkFields({ item: optionsItem, name: "options" });
    }
  }, [fields]);
};
