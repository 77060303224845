import React, { useContext, useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import TemplateTypeField from "../../../../utils/CustomFields/TemplateTypeField";
import TemplateTypeFilterMenu from "../../../../utils/FilterMenus/TemplateTypeFilterMenu";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import { applyTemplates } from "../../functions/applyTemplates";
import { queryTemplates } from "../../functions/queryTemplates";
/**
 * Hook that allows the user to select a system template to apply to the organization.
 * Calls the 'applyTemplate' lambda with the selected template
 * @param organizationID
 * @param system
 * @param systemTemplate
 * @param resetFunction
 * @param toggleModal
 */
export const useApplySystemTemplate = ({ organizationID, system, resetFunction, toggleModal }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const { addToast, updateToast } = useContext(UIContext);
  const { loggedInUserId } = useContext(OrganizationContext);

  const [systemInput, setSystemInput] = useState(system);
  const [resetKey, setResetKey] = useState(0);

  const applySystemTemplate = async (systemTemplate, system = systemInput) => {
    const override = {};
    if (system?.name) override.name = system.name;
    if (system?.description) override.description = system.description;
    if (system?.availability) override.availability = system.availability;
    if (system?.hosting) override.hosting = system.hosting;
    override.systemPointOfContactId = system?.systemPointOfContactId ? system.systemPointOfContactId : undefined;

    toggleModal && toggleModal();

    await applyTemplates({
      input: {
        templatesToApply: [
          {
            id: systemTemplate?.id,
            override,
          },
        ],
        resource: resources.INFORMATION_SYSTEM,
        organizationID,
      },
      addToast,
      updateToast,
    });

    resetFunction && resetFunction();
  };

  const queryConfig = {
    query: null,
  };

  const systemTemplateList = useSystemDataGrid({
    module,
    resource,
    organizationID,
    queryConfig,
    gridConfig: {
      createResourceComponent: null,
      deleteFunction: null,
      detailsComponent: null,
      enableSelectButton: true,
      enableQuickDetails: false,
      onSelectCallback: applySystemTemplate,
      resetFunction: () => setResetKey(resetKey + 1),
      options: [],
      fields: [
        {
          name: "name",
          minWidth: 200,
          flex: 1,
          sort: {
            direction: "asc",
            priority: 1,
          },
        },
        {
          name: "description",
          minWidth: 200,
          flex: 2,
        },
        {
          name: "ownerGroup",
          friendlyName: "Template Type",
          component: <TemplateTypeField />,
          width: 200,
          filterTemplate: (props) => <TemplateTypeFilterMenu props={props} />,
        },
      ],
    },
  });

  // Manually queries templates through the lambda to avoid role checking
  useEffect(() => {
    systemTemplateList.setIsLoading(true);
    queryTemplates({ resource, userID: loggedInUserId })
      .then((templates) => systemTemplateList.setData(templates))
      .finally(() => systemTemplateList.setIsLoading(false));
  }, [resetKey]);

  const display = systemTemplateList.isLoading ? (
    <LoadingSpinner loadingMessage="Loading Information System Templates.. " />
  ) : (
    <div style={{ height: "70vh" }}>{systemTemplateList.gridDisplay}</div>
  );

  return {
    ...systemTemplateList,
    display,
    applySystemTemplate,
    systemInput,
    setSystemInput,
  };
};
