import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useToolCustomResourceTypes } from "../hooks/useToolCustomResourceTypes";

/**
 * Displays Custom Resource Types that are configured for a Tool
 * @param item
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ToolCustomResourceTypes = (props) => (
  <div style={{ height: "50vh" }}>{useToolCustomResourceTypes(props).display}</div>
);

export default withOrganizationCheck(ToolCustomResourceTypes);
