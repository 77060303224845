import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import CustomSwitch from "../../../../../utils/GenericComponents/GenericEditFieldV3/components/CustomSwitch";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import { useOrganizationConfig } from "../../../../AdminPanel/Organizations/hooks/useOrganizationConfig";

/**
 * Allows the user to adjust whether risk changes are created when a risk recommendation is marked as resolved/unresolved
 * @param {string} organizationID - currently selected organization
 * @return {JSX.Element}
 */
const RiskRecommendationSettings = ({
  organizationID,
  justShowOnResolvedSwitch = false,
  justShowOnUnresolvedSwitch = false,
}) => {
  const { addToast } = useUIContext();

  // Get the organization config for recommendation settings
  const riskRecommendationsConfig = useOrganizationConfig({
    organizationID,
    key: "riskRecommendationsConfig",
  });

  const checkPermissions = useCheckPermissions({
    module: modules.RISK,
    resource: resources.RISK_CONFIG,
  });

  //Handle initial state and store values
  const [riskChangeOnResolved, setRiskChangeOnResolved] = useState(false);
  const [riskChangeOnUnresolved, setRiskChangeOnUnresolved] = useState(false);
  useEffect(() => {
    const { riskChangeOnResolved: riskChangeOnResolvedInit, riskChangeOnUnresolved: riskChangeOnUnresolvedInit } =
      riskRecommendationsConfig?.value || {};
    setRiskChangeOnResolved(riskChangeOnResolvedInit);
    setRiskChangeOnUnresolved(riskChangeOnUnresolvedInit);
  }, [riskRecommendationsConfig?.value]);

  const onResolvedSwitch = (
    <CustomSwitch
      inputConfig={{
        type: justShowOnResolvedSwitch ? "checkbox" : "switch",
        disabled: !checkPermissions.resource.update,
      }}
      value={riskChangeOnResolved}
      setValue={async (input) => {
        setRiskChangeOnResolved(input);
        await riskRecommendationsConfig.setValue({
          riskChangeOnResolved: input,
          riskChangeOnUnresolved,
        });
        addToast({
          icon: "success",
          header: "Successfully updated risk recommendation settings.",
        });
      }}
    />
  );

  const onUnresolvedSwitch = (
    <CustomSwitch
      inputConfig={{
        type: justShowOnUnresolvedSwitch ? "checkbox" : "switch",
        disabled: !checkPermissions.resource.update,
      }}
      value={riskChangeOnUnresolved}
      setValue={async (input) => {
        setRiskChangeOnUnresolved(input);
        await riskRecommendationsConfig.setValue({
          riskChangeOnResolved,
          riskChangeOnUnresolved: input,
        });
        addToast({
          icon: "success",
          header: "Successfully updated risk recommendation settings.",
        });
      }}
    />
  );

  if (justShowOnResolvedSwitch) {
    return onResolvedSwitch;
  }

  if (justShowOnUnresolvedSwitch) {
    return onUnresolvedSwitch;
  }

  //Gui for changing the settings
  return (
    <DashboardCard title={"Risk Recommendation Settings"} icon={getIcon("simple-line-icons:tag")}>
      <DataLoader isLoading={riskRecommendationsConfig.isLoading} isEnoughData={true}>
        <ListGroup>
          <ListGroupItem>
            <ListGroupItemHeading>
              Create a Risk Change when Risk Recommendation is marked as resolved
            </ListGroupItemHeading>
            <ListGroupItemText>{onResolvedSwitch}</ListGroupItemText>
          </ListGroupItem>
          <ListGroupItem>
            <ListGroupItemHeading>
              Reverse Risk Change when a Risk Recommendation is switched back to unresolved
            </ListGroupItemHeading>
            <ListGroupItemText>{onUnresolvedSwitch}</ListGroupItemText>
          </ListGroupItem>
        </ListGroup>
      </DataLoader>
    </DashboardCard>
  );
};

export default withOrganizationCheck(RiskRecommendationSettings);
