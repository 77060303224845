import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { priorityScale } from "../../../../../Program/PriorityActions/CustomFields/PriorityBadge/getPriorityScaleSegment";
import { PriorityLevel } from "../../../../../Program/PriorityActions/CustomFields/PriorityBadge/types";
import { CheckedVulnerability, EditedVulnerability } from "../types";

export interface PerformManualPriorityUpdateParams {
  newItem: EditedVulnerability;
  updateItemById?: (item: CheckedVulnerability) => void;
}

export const performManualPriorityUpdate = async ({
  newItem,
  updateItemById,
}: PerformManualPriorityUpdateParams): Promise<CheckedVulnerability> => {
  const { updateMutation } = generateGraphql("Vulnerability", ["manualPriority"]) as { updateMutation: string };
  const selectedPrioritySegmentText = newItem?.manualPriority;
  const correctNewItem: CheckedVulnerability = { id: newItem.id };
  priorityScale.forEach((segment) => {
    if (selectedPrioritySegmentText && selectedPrioritySegmentText == segment.text) {
      correctNewItem.manualPriority = segment.maxPriority;
    }
  });

  if (selectedPrioritySegmentText === PriorityLevel.NONE) {
    correctNewItem.manualPriority = null;
  }

  const updatedItem = await ItemMutation({
    mutation: updateMutation,
    input: correctNewItem,
  });
  updateItemById?.(updatedItem);
  return updatedItem;
};
