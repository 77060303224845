import { useEffect, useState } from "react";

/**
 * Returns the built-in widgets for the widget selector
 * @param {object} widgetsSource - the source of the widgets
 * @param {function} onSelect - the callback for when a widget is selected
 * @return {*[]}
 */
export const useBuiltInWidgets = (widgetsSource, onSelect) => {
  const [builtInWidgets, setBuiltInWidgets] = useState([]);

  useEffect(() => {
    if (widgetsSource) {
      const result = Object.values(widgetsSource).map((widget) => ({
        id: widget.id,
        text: widget.name,
        icon: widget.icon,
      }));
      setBuiltInWidgets(result);
    }
  }, [widgetsSource, onSelect]);

  return builtInWidgets;
};
