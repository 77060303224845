import React from "react";

import { resources } from "@rivial-security/role-utils";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CreateRecommendation from "../../Recommendations/components/CreateRecommendation";
import RecommendationsDataGrid from "../../Recommendations/components/RecommendationsDataGrid";
import { deleteRecommendationActionLink } from "../../Recommendations/functions/deleteRecommendationActionLink";
import { useRecommendationsDataGrid } from "../../Recommendations/hooks/useRecommendationsDataGrid";
import { createRecommendationActionItemLink } from "../functions/createRecommendationActionItemLink";

/**
 * Displays recommendations for an Action Item
 * @param {object} item - the Action Item to show recommendations for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const ActionItemRecommendations = ({ item, organizationID }) => {
  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "recommendations",
    connectionField: "recommendation",
    typename: resources.RECOMMENDATION,
    parentTypename: resources.ACTION_ITEM,
    grid: <RecommendationsDataGrid />,
    gridHook: useRecommendationsDataGrid,
    form: <CreateRecommendation />,
    createFunction: createRecommendationActionItemLink,
    deleteFunction: deleteRecommendationActionLink,
  });

  return <div style={{ height: "30em" }}>{nestedDataCard?.gridDisplay}</div>;
};

export default withOrganizationCheck(ActionItemRecommendations);
