import Button from "@mui/material/Button";

import { generateGraphql } from "@rivial-security/generategraphql";
import { TOOL_TYPES } from "@rivial-security/integration-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useModal } from "../../../../hooks/views/useModal";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import ImportButton from "../../../../utils/GenericComponents/ImportButton";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { useMitreAttackTechniquesDataGrid } from "../../../Tools/MitreAttack/Techniques/hooks/useMitreAttackTechniquesDataGrid";
import CheckTool from "../../../Tools/Tool/components/CheckTool";

/**
 * @description Create Threat from Mitre Attack Techniques
 * @param {string} organizationID - selected organization ID
 * @param {function} resetFunction - function to reset the state
 * @param {function} toggleModal - function to toggle the external modal
 * @param {boolean} disableToolCheck - disable the tool check
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useCreateThreatsFromTechniques = ({ organizationID, resetFunction, toggleModal, disableToolCheck }) => {
  const { addToast, updateToast } = useUIContext();

  const toolType = TOOL_TYPES.MITRE_ATTACK;

  const techniquesGrid = useMitreAttackTechniquesDataGrid({
    organizationID,
    enableSelection: true,
  });

  /**
   * Create Threats from selected Techniques
   */
  const createThreatFromTechniques = async () => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        const selectedData = techniquesGrid?.selectedData;

        if (selectedData?.length > 0) {
          const threatPromises = [];

          const { createMutation } = generateGraphql("Threat", ["name"]);

          for (const technique of selectedData) {
            threatPromises.push(
              ItemMutation(createMutation, {
                name: technique?.stix?.name || technique?.name,
                techniqueID: technique.id,
                ownerGroup: organizationID,
              }),
            );
          }
          await Promise.allSettled(threatPromises);
          if (typeof resetFunction === "function") resetFunction();
          modal.setModalIsOpen(false);
          if (typeof toggleModal === "function") toggleModal();
        } else {
          alert("Please select at least one Technique");
        }
      },
      inProgressText: `Importing Mitre Att&ck Techniques...`,
      successText: `Successfully imported Mitre Att&ck Techniques`,
      failedText: `Failed to import Mitre Att&ck Techniques. Please try again.`,
      iconColor: "success",
    });
  };

  const display = (
    <div>
      <CheckTool toolType={toolType} organizationID={organizationID} disableToolCheck={disableToolCheck}>
        <div>
          <div style={{ height: "50em" }}>{techniquesGrid?.display}</div>
          <Button
            className="float-right"
            onClick={createThreatFromTechniques}
            title={"Create Threats from selected Techniques"}
          >
            Create Threats
          </Button>
        </div>
      </CheckTool>
    </div>
  );

  const modal = useModal(
    "Create Threats from Mitre Attack Techniques",
    display,
    <ImportButton text={"Import Mitre Techniques"} />,
    {
      width: "90vw",
    },
  );

  return modal;
};
