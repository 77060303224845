import React, { useState } from "react";

import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";

import NonStandardInput from "./NonStandardInput";

const ImplementationDetailsBody = ({ isNonStandard, system, item, override, style }) => {
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <>
      <div style={style.div}>
        <strong>
          Implementation Details
          <i
            className="icon-question"
            title="How is this Control implemented differently for this particular Information System?"
            style={style.icon}
          />
        </strong>
        <div style={style.field}>
          <NonStandardInput system={system} riskControl={item} field="implementationDetails" override={override} />
        </div>
      </div>
    </>
  );
};

const ImplementationDetails = (props) => {
  return (
    <OrganizationCheck {...props}>
      <ImplementationDetailsBody />
    </OrganizationCheck>
  );
};

export default ImplementationDetails;
