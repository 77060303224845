"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowType = exports.Visibility = exports.VendorSolutionContractStatus = exports.VendorReviewStatus = exports.VendorReviewRating = exports.ToolsType = exports.TaskType = exports.TaskStatus = exports.TargetVulnerabilityStatus = exports.TargetVulnerabilityHistoryType = exports.TagType = exports.SystemHosting = exports.SortDirection = exports.SsoProviderName = exports.RecommendationStatus = exports.RatingScale = exports.RatingLevel = exports.QuestionnaireStatus = exports.ObservationStatus = exports.ObservationDecision = exports.NumberCustomFieldFormat = exports.ModuleType = exports.ModelSortDirection = exports.MetricStatus = exports.MetricLevel = exports.MetricFrequency = exports.MetricDataFormat = exports.MetricCalculationType = exports.MaturityLevel = exports.KpiStatus = exports.IncidentStatus = exports.FeedbackTypes = exports.EvidenceType = exports.EvidenceStatus = exports.EvidenceActivityType = exports.EventType = exports.ErmRisk = exports.CustomFieldType = exports.CustomDashboardType = exports.Cognito_Mfa_Type = exports.AutomationType = exports.AutomationTriggerType = exports.AutomationStepType = exports.ArtifactStatus = exports.ActionItemStatus = exports.AvStatus = exports.AiService = exports.AiCompletionType = exports.AiCompletionStatus = void 0;
var AiCompletionStatus;
(function (AiCompletionStatus) {
    AiCompletionStatus["COMPLETED"] = "completed";
    AiCompletionStatus["FAILED"] = "failed";
    AiCompletionStatus["PENDING"] = "pending";
})(AiCompletionStatus || (exports.AiCompletionStatus = AiCompletionStatus = {}));
var AiCompletionType;
(function (AiCompletionType) {
    AiCompletionType["POLICY_SECTION"] = "policySection";
})(AiCompletionType || (exports.AiCompletionType = AiCompletionType = {}));
var AiService;
(function (AiService) {
    AiService["OPENAI"] = "openai";
})(AiService || (exports.AiService = AiService = {}));
var AvStatus;
(function (AvStatus) {
    AvStatus["CLEAN"] = "clean";
    AvStatus["ERROR_PROCESSING_FILE"] = "errorProcessingFile";
    AvStatus["FILE_TOO_BIG"] = "fileTooBig";
    AvStatus["INFECTED"] = "infected";
    AvStatus["PENDING"] = "pending";
})(AvStatus || (exports.AvStatus = AvStatus = {}));
var ActionItemStatus;
(function (ActionItemStatus) {
    ActionItemStatus["COMPLETE"] = "complete";
    ActionItemStatus["IN_PROGRESS"] = "inProgress";
    ActionItemStatus["ON_HOLD"] = "onHold";
    ActionItemStatus["PAST_DUE"] = "pastDue";
    ActionItemStatus["PROPOSED"] = "proposed";
})(ActionItemStatus || (exports.ActionItemStatus = ActionItemStatus = {}));
var ArtifactStatus;
(function (ArtifactStatus) {
    ArtifactStatus["NOT_VALID"] = "notValid";
    ArtifactStatus["PENDING_VALIDATION"] = "pendingValidation";
    ArtifactStatus["VALID"] = "valid";
})(ArtifactStatus || (exports.ArtifactStatus = ArtifactStatus = {}));
var AutomationStepType;
(function (AutomationStepType) {
    AutomationStepType["CREATE_RESOURCE"] = "createResource";
    AutomationStepType["CUSTOM_QUERY"] = "customQuery";
    AutomationStepType["GENERATE_REPORT"] = "generateReport";
    AutomationStepType["HTTP"] = "http";
    AutomationStepType["LOOP"] = "loop";
    AutomationStepType["SEND_EMAIL"] = "sendEmail";
    AutomationStepType["SEND_NOTIFICATION"] = "sendNotification";
    AutomationStepType["UPDATE_RESOURCE"] = "updateResource";
    AutomationStepType["WEBHOOK"] = "webhook";
})(AutomationStepType || (exports.AutomationStepType = AutomationStepType = {}));
var AutomationTriggerType;
(function (AutomationTriggerType) {
    AutomationTriggerType["MANUAL"] = "manual";
    AutomationTriggerType["RESOURCE_CREATED"] = "resourceCreated";
    AutomationTriggerType["RESOURCE_UPDATED"] = "resourceUpdated";
    AutomationTriggerType["SCHEDULED"] = "scheduled";
    AutomationTriggerType["WEBHOOK"] = "webhook";
})(AutomationTriggerType || (exports.AutomationTriggerType = AutomationTriggerType = {}));
var AutomationType;
(function (AutomationType) {
    AutomationType["EMAIL"] = "email";
})(AutomationType || (exports.AutomationType = AutomationType = {}));
var Cognito_Mfa_Type;
(function (Cognito_Mfa_Type) {
    Cognito_Mfa_Type["NONE"] = "NONE";
    Cognito_Mfa_Type["SMS"] = "SMS";
    Cognito_Mfa_Type["TOTP"] = "TOTP";
})(Cognito_Mfa_Type || (exports.Cognito_Mfa_Type = Cognito_Mfa_Type = {}));
var CustomDashboardType;
(function (CustomDashboardType) {
    CustomDashboardType["CONTROL_FRAMEWORK"] = "controlFramework";
})(CustomDashboardType || (exports.CustomDashboardType = CustomDashboardType = {}));
var CustomFieldType;
(function (CustomFieldType) {
    CustomFieldType["BOOLEAN"] = "boolean";
    CustomFieldType["DATE"] = "date";
    CustomFieldType["ENUM"] = "enum";
    CustomFieldType["MULTIPLE_SELECT"] = "multipleSelect";
    CustomFieldType["NUMBER"] = "number";
    CustomFieldType["OBJECT"] = "object";
    CustomFieldType["STRING"] = "string";
})(CustomFieldType || (exports.CustomFieldType = CustomFieldType = {}));
var ErmRisk;
(function (ErmRisk) {
    ErmRisk["COMPLIANCE"] = "compliance";
    ErmRisk["CONSUMER_DATA"] = "consumerData";
    ErmRisk["FINANCIAL"] = "financial";
    ErmRisk["OPERATIONAL"] = "operational";
    ErmRisk["REPUTATION"] = "reputation";
    ErmRisk["STRATEGIC"] = "strategic";
    ErmRisk["TRANSACTION"] = "transaction";
})(ErmRisk || (exports.ErmRisk = ErmRisk = {}));
var EventType;
(function (EventType) {
    EventType["ACTIVITY"] = "activity";
    EventType["NOTIFICATION"] = "notification";
    EventType["PROGRESS"] = "progress";
})(EventType || (exports.EventType = EventType = {}));
var EvidenceActivityType;
(function (EvidenceActivityType) {
    EvidenceActivityType["ATTESTED"] = "attested";
    EvidenceActivityType["AUDITED"] = "audited";
    EvidenceActivityType["CONTROL_LINKED"] = "controlLinked";
    EvidenceActivityType["CONTROL_UNLINKED"] = "controlUnlinked";
    EvidenceActivityType["DOCUMENT_UPLOAD"] = "documentUpload";
    EvidenceActivityType["EXPIRED"] = "expired";
    EvidenceActivityType["EXPIRING_SOON"] = "expiringSoon";
    EvidenceActivityType["EXTERNAL_URL"] = "externalUrl";
    EvidenceActivityType["FREQUENCY_CHANGED"] = "frequencyChanged";
    EvidenceActivityType["IN_PLACE"] = "inPlace";
    EvidenceActivityType["MEETING"] = "meeting";
    EvidenceActivityType["PENDING_VALIDATION"] = "pendingValidation";
    EvidenceActivityType["PHISHING"] = "phishing";
    EvidenceActivityType["POINT_OF_CONTACT_ASSIGNED"] = "pointOfContactAssigned";
    EvidenceActivityType["POINT_OF_CONTACT_UNASSIGNED"] = "pointOfContactUnassigned";
    EvidenceActivityType["POLICY"] = "policy";
    EvidenceActivityType["REPORT_UPLOAD"] = "reportUpload";
    EvidenceActivityType["RISK_CHANGE"] = "riskChange";
    EvidenceActivityType["SCREENSHOT"] = "screenshot";
    EvidenceActivityType["STATUS_CHANGED_MANUALLY"] = "statusChangedManually";
    EvidenceActivityType["TRAINING"] = "training";
    EvidenceActivityType["TYPE_CHANGED"] = "typeChanged";
    EvidenceActivityType["VALIDATED"] = "validated";
})(EvidenceActivityType || (exports.EvidenceActivityType = EvidenceActivityType = {}));
var EvidenceStatus;
(function (EvidenceStatus) {
    EvidenceStatus["EXPIRED"] = "expired";
    EvidenceStatus["EXPIRING_SOON"] = "expiringSoon";
    EvidenceStatus["IN_PLACE"] = "inPlace";
    EvidenceStatus["PENDING_VALIDATION"] = "pendingValidation";
})(EvidenceStatus || (exports.EvidenceStatus = EvidenceStatus = {}));
var EvidenceType;
(function (EvidenceType) {
    EvidenceType["ATTESTATION"] = "attestation";
    EvidenceType["DOCUMENT"] = "document";
    EvidenceType["EXTERNAL_URL"] = "externalUrl";
    EvidenceType["KEY_PERFORMANCE_INDICATOR"] = "keyPerformanceIndicator";
    EvidenceType["MEETING"] = "meeting";
    EvidenceType["PHISHING"] = "phishing";
    EvidenceType["POLICY"] = "policy";
    EvidenceType["REPORT"] = "report";
    EvidenceType["SCREENSHOT"] = "screenshot";
    EvidenceType["TRAINING"] = "training";
})(EvidenceType || (exports.EvidenceType = EvidenceType = {}));
var FeedbackTypes;
(function (FeedbackTypes) {
    FeedbackTypes["BUG"] = "bug";
    FeedbackTypes["SUGGESTION"] = "suggestion";
})(FeedbackTypes || (exports.FeedbackTypes = FeedbackTypes = {}));
var IncidentStatus;
(function (IncidentStatus) {
    IncidentStatus["CLOSED"] = "closed";
    IncidentStatus["OPEN"] = "open";
})(IncidentStatus || (exports.IncidentStatus = IncidentStatus = {}));
var KpiStatus;
(function (KpiStatus) {
    KpiStatus["FAILED"] = "failed";
    KpiStatus["PENDING"] = "pending";
    KpiStatus["SUCCESS"] = "success";
    KpiStatus["WARNING"] = "warning";
})(KpiStatus || (exports.KpiStatus = KpiStatus = {}));
var MaturityLevel;
(function (MaturityLevel) {
    MaturityLevel["ADVANCED"] = "advanced";
    MaturityLevel["BASELINE"] = "baseline";
    MaturityLevel["EVOLVING"] = "evolving";
    MaturityLevel["INNOVATIVE"] = "innovative";
    MaturityLevel["INTERMEDIATE"] = "intermediate";
})(MaturityLevel || (exports.MaturityLevel = MaturityLevel = {}));
var MetricCalculationType;
(function (MetricCalculationType) {
    MetricCalculationType["AVERAGE"] = "average";
    MetricCalculationType["MAX"] = "max";
    MetricCalculationType["MIN"] = "min";
    MetricCalculationType["MOST_RECENT"] = "mostRecent";
})(MetricCalculationType || (exports.MetricCalculationType = MetricCalculationType = {}));
var MetricDataFormat;
(function (MetricDataFormat) {
    MetricDataFormat["DECIMAL"] = "decimal";
    MetricDataFormat["DOLLAR"] = "dollar";
    MetricDataFormat["JSON"] = "json";
    MetricDataFormat["PERCENT"] = "percent";
    MetricDataFormat["STRING"] = "string";
})(MetricDataFormat || (exports.MetricDataFormat = MetricDataFormat = {}));
var MetricFrequency;
(function (MetricFrequency) {
    MetricFrequency["BI_WEEKLY"] = "biWeekly";
    MetricFrequency["DAILY"] = "daily";
    MetricFrequency["MONTHLY"] = "monthly";
    MetricFrequency["ONCE"] = "once";
    MetricFrequency["QUARTERLY"] = "quarterly";
    MetricFrequency["WEEKLY"] = "weekly";
    MetricFrequency["YEARLY"] = "yearly";
})(MetricFrequency || (exports.MetricFrequency = MetricFrequency = {}));
var MetricLevel;
(function (MetricLevel) {
    MetricLevel["GLOBAL"] = "global";
    MetricLevel["ORGANIZATION"] = "organization";
})(MetricLevel || (exports.MetricLevel = MetricLevel = {}));
var MetricStatus;
(function (MetricStatus) {
    MetricStatus["FAILED"] = "failed";
    MetricStatus["PENDING"] = "pending";
    MetricStatus["SUCCESS"] = "success";
    MetricStatus["WARNING"] = "warning";
})(MetricStatus || (exports.MetricStatus = MetricStatus = {}));
var ModelSortDirection;
(function (ModelSortDirection) {
    ModelSortDirection["ASC"] = "ASC";
    ModelSortDirection["DESC"] = "DESC";
})(ModelSortDirection || (exports.ModelSortDirection = ModelSortDirection = {}));
var ModuleType;
(function (ModuleType) {
    ModuleType["COMPLIANCE"] = "compliance";
    ModuleType["GOVERNANCE"] = "governance";
    ModuleType["INCIDENT_RESPONSE"] = "incidentResponse";
    ModuleType["PROGRAM"] = "program";
    ModuleType["RISK"] = "risk";
    ModuleType["TESTING"] = "testing";
    ModuleType["TOOLS"] = "tools";
    ModuleType["TRAINING"] = "training";
    ModuleType["VENDOR"] = "vendor";
    ModuleType["VENDORS"] = "vendors";
    ModuleType["VULNERABILITIES"] = "vulnerabilities";
})(ModuleType || (exports.ModuleType = ModuleType = {}));
var NumberCustomFieldFormat;
(function (NumberCustomFieldFormat) {
    NumberCustomFieldFormat["CURRENCY"] = "currency";
    NumberCustomFieldFormat["NUMBER"] = "number";
    NumberCustomFieldFormat["PERCENT"] = "percent";
})(NumberCustomFieldFormat || (exports.NumberCustomFieldFormat = NumberCustomFieldFormat = {}));
var ObservationDecision;
(function (ObservationDecision) {
    ObservationDecision["ACCEPT"] = "accept";
    ObservationDecision["AVOID"] = "avoid";
    ObservationDecision["MITIGATE"] = "mitigate";
    ObservationDecision["NONE"] = "none";
    ObservationDecision["TRANSFER"] = "transfer";
})(ObservationDecision || (exports.ObservationDecision = ObservationDecision = {}));
var ObservationStatus;
(function (ObservationStatus) {
    ObservationStatus["CLOSED"] = "closed";
    ObservationStatus["OPEN"] = "open";
    ObservationStatus["PAST_DUE"] = "pastDue";
})(ObservationStatus || (exports.ObservationStatus = ObservationStatus = {}));
var QuestionnaireStatus;
(function (QuestionnaireStatus) {
    QuestionnaireStatus["DONE"] = "done";
    QuestionnaireStatus["IN_PROGRESS"] = "inProgress";
    QuestionnaireStatus["SCHEDULED"] = "scheduled";
})(QuestionnaireStatus || (exports.QuestionnaireStatus = QuestionnaireStatus = {}));
var RatingLevel;
(function (RatingLevel) {
    RatingLevel["HIGH"] = "high";
    RatingLevel["INFO"] = "info";
    RatingLevel["LOW"] = "low";
    RatingLevel["LOW_MEDIUM"] = "lowMedium";
    RatingLevel["MEDIUM"] = "medium";
    RatingLevel["MEDIUM_HIGH"] = "mediumHigh";
})(RatingLevel || (exports.RatingLevel = RatingLevel = {}));
var RatingScale;
(function (RatingScale) {
    RatingScale["HIGH"] = "high";
    RatingScale["LOW"] = "low";
    RatingScale["LOW_MEDIUM"] = "lowMedium";
    RatingScale["MEDIUM"] = "medium";
    RatingScale["MEDIUM_HIGH"] = "mediumHigh";
})(RatingScale || (exports.RatingScale = RatingScale = {}));
var RecommendationStatus;
(function (RecommendationStatus) {
    RecommendationStatus["RESOLVED"] = "resolved";
    RecommendationStatus["UNRESOLVED"] = "unresolved";
    RecommendationStatus["WONT_RESOLVE"] = "wontResolve";
})(RecommendationStatus || (exports.RecommendationStatus = RecommendationStatus = {}));
var SsoProviderName;
(function (SsoProviderName) {
    SsoProviderName["AZURE"] = "AZURE";
    SsoProviderName["OKTA"] = "OKTA";
})(SsoProviderName || (exports.SsoProviderName = SsoProviderName = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "ASC";
    SortDirection["DESC"] = "DESC";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var SystemHosting;
(function (SystemHosting) {
    SystemHosting["HYBRID"] = "hybrid";
    SystemHosting["INTERNAL"] = "internal";
    SystemHosting["OUTSOURCED"] = "outsourced";
})(SystemHosting || (exports.SystemHosting = SystemHosting = {}));
var TagType;
(function (TagType) {
    TagType["SYSTEM"] = "system";
})(TagType || (exports.TagType = TagType = {}));
var TargetVulnerabilityHistoryType;
(function (TargetVulnerabilityHistoryType) {
    TargetVulnerabilityHistoryType["ASSESSMENT"] = "assessment";
    TargetVulnerabilityHistoryType["FALSE_POSITIVE"] = "falsePositive";
    TargetVulnerabilityHistoryType["INITIAL"] = "initial";
    TargetVulnerabilityHistoryType["PRIORITY"] = "priority";
    TargetVulnerabilityHistoryType["STATUS"] = "status";
    TargetVulnerabilityHistoryType["TAG"] = "tag";
})(TargetVulnerabilityHistoryType || (exports.TargetVulnerabilityHistoryType = TargetVulnerabilityHistoryType = {}));
var TargetVulnerabilityStatus;
(function (TargetVulnerabilityStatus) {
    TargetVulnerabilityStatus["EXCEPTION"] = "exception";
    TargetVulnerabilityStatus["FIXED"] = "fixed";
    TargetVulnerabilityStatus["NOT_FIXED"] = "notFixed";
})(TargetVulnerabilityStatus || (exports.TargetVulnerabilityStatus = TargetVulnerabilityStatus = {}));
var TaskStatus;
(function (TaskStatus) {
    TaskStatus["DONE"] = "done";
    TaskStatus["IN_PROGRESS"] = "inProgress";
    TaskStatus["PAST_DUE"] = "pastDue";
    TaskStatus["TODO"] = "todo";
})(TaskStatus || (exports.TaskStatus = TaskStatus = {}));
var TaskType;
(function (TaskType) {
    TaskType["ACCESS_REQUEST"] = "accessRequest";
    TaskType["CHECKLIST"] = "checklist";
    TaskType["CUSTOM"] = "custom";
    TaskType["FINDING_DECISION"] = "findingDecision";
    TaskType["QUESTIONNAIRE"] = "questionnaire";
    TaskType["TEMPLATE_UPDATE"] = "templateUpdate";
})(TaskType || (exports.TaskType = TaskType = {}));
var ToolsType;
(function (ToolsType) {
    ToolsType["BLACK_KITE"] = "blackKite";
    ToolsType["CUSTOM"] = "custom";
    ToolsType["KNOW_BE4"] = "knowBe4";
    ToolsType["MITRE_ATTACK"] = "mitreAttack";
    ToolsType["RAPID7"] = "rapid7";
})(ToolsType || (exports.ToolsType = ToolsType = {}));
var VendorReviewRating;
(function (VendorReviewRating) {
    VendorReviewRating["HIGH"] = "high";
    VendorReviewRating["LOW"] = "low";
    VendorReviewRating["LOW_MEDIUM"] = "lowMedium";
    VendorReviewRating["MEDIUM"] = "medium";
    VendorReviewRating["MEDIUM_HIGH"] = "mediumHigh";
})(VendorReviewRating || (exports.VendorReviewRating = VendorReviewRating = {}));
var VendorReviewStatus;
(function (VendorReviewStatus) {
    VendorReviewStatus["DRAFT"] = "draft";
    VendorReviewStatus["FINAL"] = "final";
    VendorReviewStatus["LEGACY"] = "legacy";
})(VendorReviewStatus || (exports.VendorReviewStatus = VendorReviewStatus = {}));
var VendorSolutionContractStatus;
(function (VendorSolutionContractStatus) {
    VendorSolutionContractStatus["CURRENT"] = "current";
    VendorSolutionContractStatus["EXPIRED"] = "expired";
    VendorSolutionContractStatus["EXPIRING_SOON"] = "expiringSoon";
    VendorSolutionContractStatus["NONE"] = "none";
})(VendorSolutionContractStatus || (exports.VendorSolutionContractStatus = VendorSolutionContractStatus = {}));
var Visibility;
(function (Visibility) {
    Visibility["PRIVATE"] = "private";
    Visibility["PUBLIC"] = "public";
})(Visibility || (exports.Visibility = Visibility = {}));
var WorkflowType;
(function (WorkflowType) {
    WorkflowType["RISK_ASSESSMENT"] = "riskAssessment";
    WorkflowType["VENDOR_REVIEW"] = "vendorReview";
})(WorkflowType || (exports.WorkflowType = WorkflowType = {}));
