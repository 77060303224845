"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleNumberOfCustomersChange = void 0;
const getAllSystems_1 = require("../getAllSystems");
const handleChange_1 = require("../handleChange");
/**
 * Gets all Systems for the Organization and calculates the total effect from a change in Number of Customers
 * @param change
 * @param organizationID
 * @param queryConfig
 */
const handleNumberOfCustomersChange = async (change, organizationID, queryConfig) => {
    if (change?.numberOfCustomersChange) {
        const associatedSystems = await (0, getAllSystems_1.getAllSystems)(organizationID);
        const changeItem = {
            numberOfCustomersChange: change.numberOfCustomersChange,
        };
        return await (0, handleChange_1.handleChange)(associatedSystems, changeItem, organizationID, queryConfig);
    }
    throw new Error("handleNumberOfCustomersChange is missing numberOfCustomersChange");
};
exports.handleNumberOfCustomersChange = handleNumberOfCustomersChange;
