import React from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * The meeting summary email content template, populated based on meeting info
 * @param {object} meeting - the meeting for which to generate the email content
 * @return {JSX.Element}
 */
export const MeetingSummaryContent = ({ meeting }) => {
  const agenda = meeting?.agenda;

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          margin: 0,
        }}
      >
        Meeting Summary
      </h2>
      <br />
      {/***********************************************************/}
      <h3
        style={{
          margin: 0,
        }}
      >
        {meeting?.name || "Unnamed Meeting"}
      </h3>
      <p style={{ margin: 0 }}>
        <strong>Date:</strong> {new Date(meeting && meeting.startTime).toLocaleDateString()}
      </p>
      <p style={{ margin: 0 }}>
        <strong>Time:</strong> {new Date(meeting && meeting.startTime).toLocaleTimeString()}
      </p>
      {meeting?.location && (
        <p style={{ margin: 0 }}>
          <strong>Location:</strong> {meeting?.location || "Unknown"}
        </p>
      )}
      {meeting?.description && (
        <p style={{ margin: 0 }}>
          <strong>Description:</strong> {meeting?.description || "No Description"}
        </p>
      )}
      <br />
      {/***********************************************************/}
      {isNonEmptyArray(agenda) && <h3 style={{ margin: 0 }}>Agenda:</h3>}
      {isNonEmptyArray(agenda) && (
        <ul style={{ margin: 0 }}>
          {agenda.map((item) => {
            return <li>{item?.content}</li>;
          })}
        </ul>
      )}
      {isNonEmptyArray(agenda) && <br />}
      {/***********************************************************/}
    </div>
  );
};
