import { getGridStringOperators, GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";

import { insertNotContainsOperator } from "@hooks/views/useDataGrid/functions/insertNotContainsOperator";
import { getObjectPropertyString } from "@utils/Functions/Array/getObjectPropertyString";

import { handleDataGridColumns } from "../functions/handleDataGridColumns/handleDataGridColumns";

/**
 * Uses useMemo to set the column configuration for the Data Grid.
 * - nested properties control the order and width of columns displayed in the grid
 * - appends `actions` column on the left side of the grid
 * - appends any custom dynamic fields on the right side of the grid (e.g. controls grid for different control sets)
 * @param {object} gridContext - grid context object
 * @param {object[]} fields - fields to display in the grid
 * @param {object} targetView - the data grid view that the user would like to see, either from preferences or adjusted through the UI
 * @param {object} detailsModal - an instance of the useModal hook for the grid, used to display the details of a row
 * @param {string[]} loadingFields - list of fields that are still loading
 * @returns {{columns: object[]}}
 */
export const useDataGridColumns = ({ gridContext, fields, targetView, detailsModal, loadingFields }) => {
  const defaultOperators = getGridStringOperators();
  const customStringFilterOperators = insertNotContainsOperator(defaultOperators);

  /**
   * Holds the column definition for the MUI DataGrid.
   * Using useMemo to prevent re-renders of this data
   *    See: https://mui.com/components/data-grid/columns/
   * @type {GridColDef[]}
   */
  const columns = useMemo(() => {
    const baseColumns = handleDataGridColumns({ gridContext, detailsModal, targetView, loadingFields });

    return baseColumns.map((column) => {
      if (column.type === "string" && !column.filterOperators) {
        return {
          ...column,
          filterOperators: customStringFilterOperators,
        };
      }
      return column;
    });
  }, [
    gridContext?.enableQuickDetails,
    getObjectPropertyString({
      array: fields,
      propertyNames: [["field", "name"], ["friendlyName"]],
    }),
    getObjectPropertyString({ array: gridContext?.customFields }),
    targetView?.columns,
    loadingFields,
    customStringFilterOperators,
  ]);

  return {
    columns,
  };
};
