import React, { useEffect } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import VendorSolutionSystemLinking from "../components/VendorSolutionSystemLinking";
import { unlinkSystemFromVendorSolution } from "../functions/unlinkSystemFromVendorSolution";

/**
 * Custom field displaying all systems linked to the vendor solution
 * @param {string} organizationID - the database identifier of the currently selected organization
 * @param {object} item - the vendor solution data
 * @param {function} resetFunction - function to reset the 'item' data after it has been updated
 * @return {JSX.Element}
 */
const VendorSolutionInformationSystems = ({ organizationID, item, resetFunction } = {}) => {
  const systemDataGrid = useSystemDataGrid({
    data: item?.systems?.items ?? [],
    resetFunction,
    queryConfig: {
      query: null,
    },
    gridConfig: {
      createResourceComponent: <VendorSolutionSystemLinking organizationID={organizationID} item={item} />,
    },
    additionalFields: [
      {
        name: "inherentRisk",
        visible: false,
        showInColumnChooser: false,
      },
      {
        name: "residualRisk",
        visible: false,
        showInColumnChooser: false,
      },
    ],
    createItemModalHeader: `Link and Unlink Information Systems`,
    createResourceButtonText: "Link",
    deleteButtonText: "Unlink",
    deleteFunction: unlinkSystemFromVendorSolution,
  });

  useEffect(() => {
    if (systemDataGrid?.setData) {
      systemDataGrid?.setData(item?.systems?.items ?? []);
    }
  }, [JSON.stringify(item)]);

  return <div style={{ height: "50vh" }}>{systemDataGrid?.display}</div>;
};

export default withOrganizationCheck(VendorSolutionInformationSystems);
