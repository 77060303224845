import { ErrorLogger } from "@utils/EventLogger";

import { fetchData } from "../../../../../../views/Tools/Tool/functions/fetchData";
import { normalizeRowIDs } from "../../normalizeRowIDs";

import { QueryExternalApi } from "./types";

export interface RunDataGridToolsQuery<T> {
  queryExternalApi?: QueryExternalApi;
  organizationID: string;
  normalizeData?: (data: Partial<T>[]) => T[];
}

export const runDataGridToolsQuery = async <T>({
  queryExternalApi,
  organizationID,
  normalizeData,
}: RunDataGridToolsQuery<T>): Promise<T[] | undefined> => {
  if (!queryExternalApi?.toolType || !queryExternalApi?.input) {
    ErrorLogger("Missing toolType and input for queryApi");
    return [];
  }

  try {
    let response = await fetchData<T>({
      toolType: queryExternalApi?.toolType,
      input: queryExternalApi?.input,
      organizationID,
    });

    if (!response || ("statusCode" in response && response?.statusCode)) {
      ErrorLogger("No data returned from external API - status code: ", { statusCode: response?.statusCode });
      return;
    }
    response = response as T[];

    let newData = response;
    if (typeof normalizeData === "function") {
      newData = normalizeData(response);
    }

    return normalizeRowIDs({
      items: newData,
      idField: queryExternalApi?.idField,
    }) as T[];
  } catch (e) {
    ErrorLogger("Error fetching data grid data", e as object);
  }

  return undefined;
};
