import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useActionItemDetailsAccordion } from "../hooks/useActionItemDetailsAccordion";

/**
 * Displays details for a specific Action Item
 * @param props
 * @returns {*}
 * @constructor
 */
const ActionItemDetails = (props) => {
  return useActionItemDetailsAccordion(props).display;
};

export default withDetailsPage(ActionItemDetails, {
  title: "Action Item Details",
});
