/**
 * Enums for Testing Assessment
 */

export const ASSESSMENT_STATUS = {
  SCHEDULED: "scheduled",
  IN_PROGRESS: "in-progress",
  DONE: "done",
};

export const ASSESSMENT_TYPE = {
  INTERNAL: "internal",
  EXTERNAL: "external",
};
