import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useControlSetDataGrid } from "../hooks/useControlSetDataGrid";

/**
 * @description Select a single control set
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectControlFramework = ({ organizationID, selectButtonCallback } = {}) => {
  const grid = useControlSetDataGrid({
    organizationID,
    gridConfig: {
      createItemComponent: null,
      enableSelectButton: true,
      onSelectCallback: selectButtonCallback,
    },
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(SelectControlFramework);
