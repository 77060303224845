import React from "react";

import { useFileViewer } from "../../../../utils/FilePreviews/useFileViewer";

/**
 * @description View Policy Version
 * @param {string} url
 * @return {JSX.Element}
 * @constructor
 */
export const ViewPolicyVersion = ({ url }) => {
  const fileViewer = useFileViewer({ url });

  return <>{fileViewer.display}</>;
};
