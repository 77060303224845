import { isNonEmptyArray } from "@rivial-security/func-utils";

import { normalizeVulnerability } from "./normalizeVulnerability";

/**
 * Normalizes an array of vulnerabilities - see normalizeVulnerability for more details
 * @param {object} input - input object
 * @param {object[]} input.vulnerabilities - vulnerabilities to normalize
 * @param {boolean} input.calculatePriority - if TRUE will also combine the link priority based on the algorithm used in the vulnerability grouping function from report utils
 * @returns {object[]}
 */
export const normalizeVulnerabilityData = ({ vulnerabilities, calculatePriority }) => {
  if (!isNonEmptyArray(vulnerabilities)) {
    return [];
  }

  // Iterate over each vulnerability and add the statusCounts property
  return vulnerabilities.map((vulnerability) => {
    return normalizeVulnerability({
      vulnerability,
      calculatePriority,
    });
  });
};
