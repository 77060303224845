import { usePointOfContactDataGrid } from "../../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";

/**
 * @description Select point of contact for an assessment
 * @param organizationID
 * @param input
 * @param setInput
 * @return {JSX.Element}
 * @constructor
 */
const AddAssessmentPointOfContact = ({ organizationID, input, setInput }) => {
  /**
   * Point of contact hook instance
   */
  const pointOfContactListHook = usePointOfContactDataGrid({
    organizationID: organizationID,
    enableSelection: true,
    onSelectedDataChange: (item) => {
      setInput((data) => {
        Object.assign(data, {
          pointOfContact: item.map((item) => item?.user?.name).join(", "),
        });
        return data;
      });
    },
  });

  return pointOfContactListHook?.display;
};

export default AddAssessmentPointOfContact;
