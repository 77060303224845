"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHtmlTableBody = void 0;
const createHtmlRow_1 = require("./createHtmlRow");
/**
 * Creates everything but the table headers of an html table as a string
 * @param {string[]} fields - the fields to show and in what order
 * @param {object} fieldConfig - field options
 * @param {object[]} data - the data for the row, properties are field names
 * @returns {string}
 */
const createHtmlTableBody = ({ fields = [], fieldConfig = {}, data = [] }) => {
    let res = "";
    for (const item of data) {
        res = res.concat((0, createHtmlRow_1.createHtmlRow)({ fields, fieldConfig, item }));
    }
    return `
    <tbody>
      ${res}
     </tbody>
  `;
};
exports.createHtmlTableBody = createHtmlTableBody;
