/**
 * Creates the initial toast for the saveChange operation.
 * If toasts are disabled, does not create a toast.
 *
 * @param {boolean} disableToast
 * @param {function} addToast
 * @returns {boolean|*}
 */
export const createInitialToast = ({ disableToast, addToast }) => {
  const toastId =
    !disableToast &&
    addToast({
      icon: "spinner",
      color: "warning",
      header: "Updating field..",
    });

  return toastId;
};
