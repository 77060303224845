import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { resources } from "@rivial-security/role-utils";
import { Audit } from "@rivial-security/schema-types";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * Deletes an Audit Item from the database
 */
export const deleteAudit = async (audit: Audit): Promise<void> => {
  if (!isValidAudit(audit)) {
    ErrorLogger("Error deleting audit - invalid audit");
    return;
  }
  const auditId: string = audit.id;
  const resourcesToDelete = getAuditResourcesToDeleteQuery();

  try {
    const auditToDelete = await getAuditToDelete(auditId, resourcesToDelete);
    await performAuditDeletion(auditToDelete);
  } catch (e) {
    ErrorLogger("Error deleting audit - ", e);
  }
};
const isValidAudit = (audit: Audit): boolean => {
  return !isNullOrUndefined(audit) && !isNullOrUndefined(audit.id);
};

const getAuditResourcesToDeleteQuery = (): string => {
  const nestedFields = {
    controls: `(limit: 1000) {
      items {
        id
        __typename
        evidenceLinks(limit: 1000) {
          items {
            id
            __typename
          }
          nextToken
        }
      }
      nextToken
    }`,
  };

  const { getQuery } = generateGraphql("Audit", ["controls", "__typename"], nestedFields);
  return getQuery;
};

const getAuditToDelete = async (itemId: string, query: string): Promise<Audit | null> => {
  return await QueryGetItem({
    query,
    itemId,
  });
};

const performAuditDeletion = async (audit: Audit | null): Promise<void> => {
  if (audit) {
    InfoLogger(`Deleting audit - ${audit?.id}`);
    return await deepDelete({
      typename: resources.AUDIT,
      item: audit,
    });
  }
};
