import { useTextField } from "../hooks/useTextField/useTextField";

/**
 * Displays a Text input field
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TextField = (props) => {
  return useTextField(props).display;
};

export default TextField;
