import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "@hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "@hooks/views/useDetailsCardV2";
import { getIcon } from "@utils/Functions/Icon/getIcon";
import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import TargetActionItems from "@views/Testing/Targets/components/TargetActionItems";
import TargetPointOfContact from "@views/Testing/Targets/components/TargetPointOfContact";

import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import TargetAssessments from "../components/TargetAssessments";
import TargetFalsePositives from "../components/TargetFalsePositives";
import TargetSystems from "../components/TargetSystems";
import TargetTimeline from "../components/TargetTimeline/components/TargetTimeline";
import TargetVulnerabilities from "../components/TargetVulnerabilities/TargetVulnerabilities";
import TargetVulnerabilitiesBySeverityLevel from "../components/TargetVulnerabilitiesBySeverityLevel";
import { isValidIp } from "../functions/isValidIp";
import { isValidMacAddress } from "../functions/isValidMacAddress";

/**
 * Display Target details hook
 * @param {string} itemId - target id
 * @param {object} item - target object
 * @param {string} organizationID - selected organization
 * @param {string} assessmentID - selected assessment
 * @param {object} props
 * @returns {{display: JSX.Element}}
 */
export const useTargetDetails = ({ itemId, item, organizationID, assessmentID, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.TARGET;

  const { getQuery, updateMutation } = generateGraphql(
    "Target",
    ["ip", "macAddress", "hostName", "disabled", "notes", "systems", "vulnerabilities", "pointOfContact"],
    {
      notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
      systems: `{ items { id system { id name} } }`,
      vulnerabilities: `(limit: 1000) {
        items {
          id
          vulnerability {
            id
            name
          }
        }
      }`,
      pointOfContact: `{ id firstName lastName }`,
    },
  );

  const fields = ["ip", "macAddress", "hostName", "pointOfContact", "disabled"];

  const detailsConfig = {
    fields,
    updateMutation,
  };

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const customFields = [
    {
      field: "ip",
      component: (
        <GenericEditFieldV3
          inputConfig={isValidIp}
          module={module}
          resource={resource}
          field="ip"
          mutation={updateMutation}
        />
      ),
    },
    {
      field: "macAddress",
      component: (
        <GenericEditFieldV3
          inputConfig={isValidMacAddress}
          module={module}
          resource={resource}
          field="macAddress"
          mutation={updateMutation}
        />
      ),
    },
    {
      field: "pointOfContact",
      component: <TargetPointOfContact module={module} resource={resource} />,
    },
    {
      field: "disabled",
      component: (
        <GenericEditFieldV3
          module={module}
          resource={resource}
          field="disabled"
          mutation={updateMutation}
          inputType={GENERIC_FIELD_TYPES.DROPDOWN}
          inputConfig={{ data: ["yes", "no"] }}
        />
      ),
      isHidden: true,
    },
  ];

  const details = useDetailsCard({
    organizationID,
    typename: "Target",
    queryConfig,
    detailsConfig,
    customFields,
    module,
    resource,
    config: {
      enableNotes: true,
    },
    fieldNameDictionary: {
      ip: "IP Address",
      pointOfContact: "Responsible",
    },
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "IP Address, Mac Address, Host Name, and other information",
        component: <span>{details.tableDisplay}</span>,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Vulnerabilities",
        subTitle: "Scan results that are associated with this Target",
        component: (
          <TargetVulnerabilities
            item={{ id: itemId, ...item }}
            assessmentID={assessmentID}
            organizationID={organizationID}
          />
        ),
        icon: "icon-fire",
      },
      {
        title: "False Positives",
        subTitle: "Vulnerabilities associated with this Target that are not applicable",
        component: <TargetFalsePositives item={{ id: itemId }} />,
        icon: getIcon("ic:baseline-browser-not-supported"),
      },
      {
        title: "Assessments",
        subTitle: "Security Assessments that engaged this Target",
        component: <TargetAssessments item={{ id: itemId }} />,
        icon: "icon-docs",
      },
      {
        title: "Information Systems",
        subTitle: "Risk Assessment systems associated with this Target",
        component: <TargetSystems item={{ id: itemId }} organizationID={organizationID} />,
        icon: "icon-screen-desktop",
      },
      {
        title: "Action Items",
        subTitle: "Action items associated with this Target",
        component: <TargetActionItems item={{ id: itemId }} />,
        icon: "icon-rocket",
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={details.reset}
      subTitle={details?.item?.hostName || details?.item?.ip || details?.item?.macAddress}
    >
      <div>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            {accordion.display}
          </Grid>
          <Grid item lg={4} xs={12}>
            <DashboardCard title={"Vulnerability Severity Levels"}>
              <TargetVulnerabilitiesBySeverityLevel item={{ id: itemId }} />
            </DashboardCard>
            <Box mt={2}>
              <TargetTimeline item={details.item} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Dashboard>
  );

  return {
    display,
  };
};
