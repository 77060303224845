import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";
import { useCustomQueryDataGrid } from "../hooks/useCustomQueryDataGrid";

/**
 * Displays a list of Custom Queries
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CustomQueryDataGrid = (props) => {
  return useCustomQueryDataGrid(props).display;
};

export default withOrganizationCheck(CustomQueryDataGrid);
