"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToolByType = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * @description Get tool object from the database
 * @param {string} toolType - tool type
 * @param {string} organizationID - organization id
 * @param {object} env - the environment variables from with AWS keys
 * @returns {object} {Promise<* | *[]>}
 */
const getToolByType = async ({ toolType, organizationID, env }) => {
    if (toolType && organizationID) {
        const toolsByOwnerGroupByType = /* GraphQL */ `
      query ToolsByOwnerGroupByType(
        $ownerGroup: String
        $type: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelToolFilterInput
        $limit: Int
        $nextToken: String
      ) {
        toolsByOwnerGroupByType(
          ownerGroup: $ownerGroup
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            type
            ownerGroup
          }
          nextToken
        }
      }
    `;
        return await (0, appsync_utils_1.ListQueryBy)({
            query: toolsByOwnerGroupByType,
            variables: {
                ownerGroup: organizationID,
                type: { eq: toolType },
            },
            env,
        }).then((data) => (data?.[0] ? data?.[0] : undefined));
    }
};
exports.getToolByType = getToolByType;
