import { useEffect, useState } from "react";

import { GetQuery } from "@rivial-security/appsync-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { getMany } from "../../utils/Functions/getMany";
import { getNestedItems } from "../../utils/Functions/getNestedItems";
import { useSetAsyncData } from "../functional/useSetAsyncData";
import { normalizeRowIDs } from "../views/useDataGrid/functions/normalizeRowIDs";

/**
 * Performs a Get Query and parses items for a 1-many connection or a many-to-many connection
 * @param {object} item - the item to query
 * @param {string} query - a GetQuery graphql string
 * @param {string} field - the item field for the connection
 * @param {string} [connectionField] - optional, if passed in assumes a many-to-many connection
 * @param {function} [normalizeData] - if provided will apply an additional function to the retrieved connections data
 * @param {string} [queryPath] - the path to the final object, this is an alternative method to using field and connection field
 * @param {boolean} [deduplicationField] - if provided will be used to deduplicate the final array of items
 * @returns {object} - the item with the parsed connections
 */
export const useNestedItems = ({
  item,
  query,
  field,
  connectionField,
  queryPath,
  deduplicationField,
  normalizeData,
}) => {
  const [items, setItems] = useState([]);
  const [parentItem, setParentItem] = useState(null);
  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      const newParentItem = await GetQuery({
        query,
        variables: {
          id: item?.id,
        },
      });

      if (!newParentItem?.id) {
        return null;
      }
      return newParentItem;
    },
    setData: (newParentItem) => {
      setParentItem(newParentItem);
    },
    dependencies: [item?.id],
  });

  useEffect(() => {
    if (!isNullOrUndefined(parentItem)) {
      try {
        let data = [];

        if (Array.isArray(queryPath)) {
          data = getNestedItems({
            path: queryPath,
            item: parentItem,
          });
        } else {
          data = getMany(parentItem || item, field, connectionField);

          if (typeof normalizeData === "function") {
            data = normalizeData(data);
          }
        }

        if (deduplicationField) {
          const deduped = [];
          const dedupedIds = {};
          for (const item of data) {
            if (!dedupedIds[item[deduplicationField]]) {
              deduped.push(item);
              dedupedIds[item[deduplicationField]] = true;
            }
          }
          data = deduped;
        }

        // filter out any data that doesn't have an ID
        const normalizedData = normalizeRowIDs({
          items: data,
        });

        setItems(normalizedData);
      } catch (e) {
        ErrorLogger("Error: Could not get nested items for this item", e);
      }
    }
  }, [parentItem]);

  return {
    setItem: setParentItem,
    item: parentItem,
    isLoading,
    resetFunction,
    items,
    setItems,
  };
};
