export const FORM_INPUT_TYPES = {
  CHECKBOXES: "checkboxes",
  COLOR_PICKER: "color-picker",
  CUSTOM: "custom",
  DATE_RANGE: "dateRange",
  DATE: "date",
  DROPDOWN: "dropdown",
  EMAIL: "email",
  FILE: "file",
  IMAGE: "image",
  ITEM_SELECT: "item-select",
  JSON: "json",
  MULTI_SELECT: "multi-select",
  PASSWORD: "password",
  PHONE_NUMBER: "phoneNumber",
  RADIO_BUTTON_GROUP: "radio-button-group",
  RICH_TEXT_EDITOR: "rich-text-editor",
  SPREADSHEET: "spreadsheet",
  STRING_ARRAY: "string-array",
  SWITCH: "switch",
  URL: "url",
};
