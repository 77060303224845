"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vulnerabilityIndexes = void 0;
exports.vulnerabilityIndexes = [
    {
        indexName: "vulnerabilitiesByOwnerGroup",
        queryField: "vulnerabilitiesByOwnerGroup",
        partitionKey: "ownerGroup",
        partitionKeyType: "String",
    },
];
