import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { AdjustPriority } from "@views/Program/Actions/hooks/useCreateAction/useCreateAction";

import { useUUID } from "../../hooks/functional/useUUID";

import FilterAndClearButtonGroup from "./components/FilterAndClearButtonGroup";

/**
 * @description filter for menu, based on priority
 * @param {object} props - props for the component
 */
const PriorityFilterMenu = ({ props }) => {
  const dropdownOptions = [
    {
      text: "Greater Than or Equal To",
      value: true,
    },
    {
      text: "Less Than or Equal To",
      value: false,
    },
  ];
  const [uuid] = useUUID();
  const [greaterOrEqual, setGreaterOrEqual] = useState(true);
  const [filterValue, setFilterValue] = useState(null);
  const [value, setValue] = useState(0);

  //Reference to the dropdown in the filter menu
  const dropdownRef = useRef(null);

  //On opening filter menu find if any active filters are applied to the column
  useEffect(() => {
    if (props?.column?.name) {
      const columnName = props?.column?.name;
      const activeFilters = props?.column?.parent?.filterModule?.actualPredicate;
      //Continue only if there's an active filter on this field
      if (activeFilters?.hasOwnProperty(columnName)) {
        const activeColumnFilters = activeFilters[columnName];
        for (const filter of activeColumnFilters) {
          if (filter?.operator === "greaterthanorequal") {
            setGreaterOrEqual(true);
          } else if (filter?.operator === "lessthanorequal") {
            setGreaterOrEqual(false);
          }
          setValue(filter?.value);
          setFilterValue(filter?.value);
        }
      }
    }
  }, []);

  //Filter the data upon pressing "Apply"
  const applyFilter = () => {
    if (props?.column?.name && value) {
      const columnName = props?.column?.name;
      props?.column?.parent?.filterByColumn(
        columnName,
        greaterOrEqual ? "greaterthanorequal" : "lessthanorequal",
        value,
        "and",
      );
      setFilterValue(value);
    }
  };

  //On pressing the "Clear" button remove all filters for the column and remove the current drop down value
  const clearFilter = () => {
    if (props?.column?.name) {
      setValue(0);
      setFilterValue(null);
      props?.column?.parent?.clearFiltering([props?.column?.name]);
    }
  };

  //The filter menu UI
  return (
    <div>
      <DropDownListComponent
        // identity
        id={`filter-menu-dropdown-${uuid}`}
        ref={dropdownRef}
        // style
        popupHeight="250px"
        // choices
        index={greaterOrEqual ? 0 : 1}
        dataSource={dropdownOptions}
        // filtering
        allowFiltering={false}
        // events
        change={({ itemData }) => {
          setGreaterOrEqual(itemData?.value);
        }}
      />
      <AdjustPriority value={value} onChangeCallback={(value) => setValue(value)} />
      <FilterAndClearButtonGroup
        onClickFilter={applyFilter}
        onClickClear={clearFilter}
        clearDisabled={isNullOrUndefined(filterValue)}
      />
    </div>
  );
};

export default PriorityFilterMenu;
