import { interpreter } from "../../Actions/functions/interpretActions";

/**
 * Determines the value for enableNotifications in the action importer context
 * @param {*} value - a single value to interpret from the importer
 * @returns {*|boolean}
 */
export const interpretEnableNotifications = (value) => {
  if (typeof value === "boolean") {
    return value;
  }

  const keywords = {
    true: ["yes", "true", "1", "enabled"],
    false: ["no", "false", "2", "0", "disabled"],
  };

  return interpreter(value, keywords);
};
