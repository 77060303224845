import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useGroupedRiskChangeDataGrid } from "../hooks/useGroupedRiskChangeDataGrid/useGroupedRiskChangeDataGrid";

/**
 * A component instance of the useGroupedRiskChangeDataGrid.js hook
 * @param {boolean} tableDisplay - if TRUE will display just the grid UI without heading and title
 * @param {string} height - css height style property to apply to grid container, when in table display
 * @param {object} props
 */
const GroupedRiskChangeDataGrid = ({ tableDisplay, height = "50vh", ...props }) => {
  const grid = useGroupedRiskChangeDataGrid({ ...props });

  if (tableDisplay) {
    return <div style={{ height }}> {grid.gridDisplay}</div>;
  } else {
    return grid.display;
  }
};

export default withOrganizationCheck(GroupedRiskChangeDataGrid);
