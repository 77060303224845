import React from "react";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";

/**
 * Displays the 'greatestBusinessRisk' field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.greatestBusinessRisk
 * @returns {JSX.Element}
 * @constructor
 */
const GreatestBusinessRisk = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={
        "The Greatest Business Risk is calculated based on the Risk Mapping from the Key Risk Indicator that represents the highest amount of Risk on a System"
      }
    >
      <span>
        {!isNullOrUndefined(item?.greatestBusinessRisk) ? convertCamelCaseToSentence(item.greatestBusinessRisk) : "N/A"}
      </span>
    </UnderlinedTooltip>
  );
};

export default GreatestBusinessRisk;
