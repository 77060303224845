import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";
import { useCreateCustomQuery } from "../hooks/useCreateCustomQuery";

/**
 * Component instance of the useCreateCustomQuery hook
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateCustomQuery = (props) => {
  return useCreateCustomQuery(props).display;
};

export default withOrganizationCheck(CreateCustomQuery);
