"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuiltInTemplateID = void 0;
/**
 * A list of all built in templates stored in this package and available to use
 * @type {{NEW_QUESTIONNAIRE_TASK_NOTIFICATION: string}}
 */
exports.BuiltInTemplateID = {
    NEW_QUESTIONNAIRE_TASK_NOTIFICATION: "newQuestionnaireTaskNotification",
};
