import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { createMutation } from "../graphql/customDashboardGraphql";

/**
 * Saves a copy of a Custom Dashboard
 * @param {string} name - the name of the new dashboard
 * @param {string} description - the description of the new dashboard
 * @param {object[]} layouts - the layouts of the new dashboard
 * @param {string} organizationID - the ID of the organization to save the dashboard to
 * @param {string} toolID - the ID of the tool to save the dashboard to
 * @param {string} pageID - the ID of the page to save the dashboard to
 * @param {boolean} isTemplate - whether or not the dashboard is a template
 * @param {function} setItemQuery - the function to set the item query
 * @param {function} onCreateNewDashboard - the function to run when a new dashboard is created
 * @returns {Promise<void>}
 */
export const saveDashboardCopy = async ({
  name = "Custom Dashboard",
  description,
  layouts = [],
  organizationID,
  toolID,
  pageID,
  isTemplate = false,
  setItemQuery = () => {},
  onCreateNewDashboard = () => {},
}) => {
  const newItem = await ItemMutation(createMutation, {
    name: `${name} (copy)`,
    description: description,
    layouts: layouts,
    ownerGroup: organizationID,
    toolID: toolID || undefined,
    pageID,
    isTemplate,
  });
  // select the newly saved dashboard
  setItemQuery(newItem);
  onCreateNewDashboard(newItem);
};
