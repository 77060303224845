export const documentDefinition = {
  routes: {
    grid: "documents/document",
    details: "documents/document",
  },

  //[APPEARANCE]
  icon: "carbon:document",

  fields: {
    name: {},
    createdAt: {},
    file: {
      default: {
        nestedFields: `{ bucket region key }`,
      },
    },
    accessControl: {
      default: {
        nestedFields: `{ password passwordOwnerEmail pointOfContacts roles }`,
      },
    },
    avStatus: {},
    lastAVCheck: {},
  },

  views: {
    grid: {
      queryFields: ["name", "createdAt", "file", "accessControl", "avStatus", "lastAVCheck"],
    },
  },
};
