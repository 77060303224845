import React, { useEffect, useRef, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

import { checkArguments, convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * Stateless component returning the row header/title html elements, used in useDetails hook
 * @param {string} fieldName - the field name from the item object
 * @param {object[]} customFields - array of custom field configs passed into the table
 * @param {object} fieldNameDictionary - standard object of entries with keys as itemFieldNames and values as friendly field names
 * @constructor
 */
const RowHeader = ({ fieldName, customFields, fieldNameDictionary }) => {
  //Checks arguments
  let isSafe = false;
  try {
    checkArguments(
      { fieldName, customFields, fieldNameDictionary },
      {
        fieldName: { type: "string" },
        fieldNameDictionary: { type: "object", validAsNull: true },
        customFields: { type: "object", isArray: true, validAsNull: true },
      },
    );
    isSafe = true;
  } catch (e) {
    ErrorLogger(e);
  }

  const ref = useRef(null);

  const [headerTooltip, setHeaderTooltip] = useState();
  useEffect(() => {
    //find the custom field config for the field
    const customField = customFields?.find((customField) => customField?.field === fieldName);
    setHeaderTooltip(customField?.headerTooltip);
  }, []);

  return (
    isSafe && (
      <th
        scope="row"
        style={{
          textTransform: "capitalize",
          display: "flex",
          flexDirection: "row",
        }}
        className="dashboard-title"
      >
        <div
          ref={ref}
          style={{
            cursor: headerTooltip && "pointer",
          }}
        >
          {fieldNameDictionary && fieldNameDictionary[fieldName]
            ? fieldNameDictionary[fieldName]
            : convertCamelCaseToSentence(fieldName)}
          {headerTooltip && (
            <>
              <i style={{ marginRight: "1em", marginLeft: "1em" }} className={"icon-question"} />
              <UncontrolledTooltip placement="auto" target={ref}>
                {headerTooltip}
              </UncontrolledTooltip>
            </>
          )}
        </div>
        <div style={{ flex: 1 }} />
      </th>
    )
  );
};

export default RowHeader;
