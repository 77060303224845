/**
 * All possible statuses for a questionnaire assignee (computed from task and questionnaire status)
 * @type {{IN_PROGRESS: string, RESPONDED: string, UNKNOWN: string, SCHEDULED: string}}
 */
export const questionnaireAssigneeStatus = {
  SCHEDULED: "scheduled",
  IN_PROGRESS: "inProgress",
  RESPONDED: "responded",
  UNKNOWN: "unknown",
};
