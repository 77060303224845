import { sortObjectsByField } from "@rivial-security/func-utils";

/**
 * Converts a list of Objects to be formatted for the useForm dropdown
 * @param list
 * @param idField
 * @param nameField
 * @param sortBy
 * @returns {Array}
 */
export const listToDropdownData = (list = [], idField = "id", nameField = "name", sortBy = "asc") => {
  const res = [];

  const getNameField = (item) => {
    if (typeof nameField === "function") {
      return nameField(item);
    } else {
      return item[nameField];
    }
  };

  for (const item of list) {
    res.push({
      value: item[idField],
      text: getNameField(item),
    });
  }

  return sortObjectsByField(res, "text", sortBy);
};
