import React, { useContext, useState } from "react";

import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import VulnerabilityStatusTotalsChart from "../../Dashboard/components/VulnerabilityStatusTotalsChart";
import { normalizeAssessmentVulnerabilities } from "../functions/normalizeAsessmentVulnerabilities";
import { listAssessmentVulnerabilityLinksByAssessmentId } from "../graphql/__assessmentGQL";

/**
 * Display chart for Vulnerabilities status
 * @param {object} item - assessment
 * @returns {JSX.Element}
 */
const AssessmentVulnerabilitiesStatusChart = ({ item }) => {
  const context = useContext(OrganizationContext);

  const [vulnerabilities, setVulnerabilities] = useState([]);

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      return await ListQueryBy({
        query: listAssessmentVulnerabilityLinksByAssessmentId,
        limit: 1000,
        variables: {
          assessmentID: item?.id,
        },
        normalizeData: (links) =>
          normalizeAssessmentVulnerabilities({
            links,
            showInfoVulnerabilities: context?.preferences?.testing?.showInfoVulnerabilities,
          }),
      });
    },
    setData: (newVulnerabilities) => {
      setVulnerabilities(newVulnerabilities);
    },
    dependencies: [item?.id],
  });

  return <VulnerabilityStatusTotalsChart isLoading={isLoading} vulnerabilities={vulnerabilities} />;
};

export default AssessmentVulnerabilitiesStatusChart;
