"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomAROInRange = void 0;
/**
 * Generates a random number between an ARO min and max
 *
 * @function
 * @param {number} aroMin
 * @param {number} aroMax
 * @returns {number}
 */
const getRandomAROInRange = (aroMin, aroMax) => {
    const rangeSize = aroMax - aroMin;
    return aroMin + rangeSize * Math.random();
};
exports.getRandomAROInRange = getRandomAROInRange;
