import React from "react";
import { Alert, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { GENERIC_FIELD_TYPES } from "../../constants/GENERIC_FIELD_TYPES";

import { applyConstraints } from "./functions/applyConstraints";

/**
 * A simple Text Input field
 *
 * @param inputType
 * @param {string} field - the field that is being updated
 * @param {string} value - the controlled value state for this input
 * @param {function} setValue - state setting function
 * @param {function} toggle - toggles the edit state, used with the enter key
 * @param {boolean} [autoFocus = true] - whether this input tries to grab autoFocus
 * @param {object} inputConfig - configuration for input
 * @returns {{display: JSX.Element}}
 */
export const useTextField = ({ inputType, field, value, setValue, toggle, autoFocus = true, inputConfig }) => {
  const getMaxLimit = () => {
    if (!isNullOrUndefined(inputConfig?.max) && !isNaN(inputConfig?.max)) return inputConfig.max;
    if (inputType === GENERIC_FIELD_TYPES.PERCENT) return 100;
    if (inputType === GENERIC_FIELD_TYPES.PERCENTAGE) return 1;
    return undefined;
  };

  const getMinLimit = () => {
    if (!isNullOrUndefined(inputConfig?.min) && !isNaN(inputConfig?.min)) return inputConfig.min;
    if (inputType === GENERIC_FIELD_TYPES.PERCENT) return 0;
    if (inputType === GENERIC_FIELD_TYPES.PERCENTAGE) return 0;
    return undefined;
  };

  const getStep = () => {
    if (!isNullOrUndefined(inputConfig?.step) && !isNaN(inputConfig?.step)) return inputConfig.step;
    if (inputType === GENERIC_FIELD_TYPES.PERCENT) return 1;
    if (inputType === GENERIC_FIELD_TYPES.PERCENTAGE) return 0.01;
    return undefined;
  };

  //Find constraints based on the type of input field
  const numeric =
    inputType === GENERIC_FIELD_TYPES.NUMERIC ||
    inputType === GENERIC_FIELD_TYPES.PERCENT ||
    inputType === GENERIC_FIELD_TYPES.PERCENTAGE ||
    inputType === GENERIC_FIELD_TYPES.DOLLAR;

  const minLimit = getMinLimit();
  const maxLimit = getMaxLimit();
  const step = getStep();

  /**
   * Allows the user to use the "Enter" key to save a field
   * @param {ChangeEvent<HTMLInputElement>} event - the dom event
   * @param {string} event.key - the key being pressed
   */
  const onEnterKey = (event) => {
    if (event.key === "Enter") {
      toggle && toggle(event);
    }
  };

  const display = (
    <div>
      <InputGroup>
        {inputType === GENERIC_FIELD_TYPES.DOLLAR && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>$</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          data-testid={`text-input-field-${field}`}
          autoFocus={autoFocus}
          value={value}
          onKeyPress={onEnterKey}
          onChange={(e) =>
            setValue(
              applyConstraints({
                value: e.target.value,
                isNumeric: numeric,
                minLimit,
                maxLimit,
                step,
              }),
            )
          }
          type={numeric ? "number" : ""}
          min={minLimit}
          max={maxLimit}
          step={step}
        />
        {inputType === GENERIC_FIELD_TYPES.PERCENT && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>%</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {inputConfig?.isValidInput && (
        <div>
          {inputConfig?.isValidInput(value) ? (
            <Alert color="success">
              {inputConfig?.validInputMessage ? inputConfig?.validInputMessage : "Valid Input"}
            </Alert>
          ) : (
            <Alert color="danger">
              {inputConfig?.invalidInputMessage ? inputConfig?.invalidInputMessage : "Invalid Input"}
            </Alert>
          )}
        </div>
      )}
    </div>
  );

  return {
    display,
  };
};
