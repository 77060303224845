import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Displays the Estimated Completion Date for an Observation
 * @param {object} item - the item to display
 * @param {function} updateItemById - callback to update the item in the parent component
 * @param {string} fieldContext - the field display location (grid, details, form and etc.)
 * @returns {JSX.Element}
 */
const EstimatedCompletionDate = ({ item, updateItemById, fieldContext }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;
  const field = "estimatedCompletionDate";

  return (
    <GenericEditFieldV3
      module={module}
      resource={resource}
      field={field}
      item={item}
      mutation={generateGraphql("Observation", ["estimatedCompletionDate"]).updateMutation}
      tooltip={
        "The date at which the Observation is expected to be resolved. After this date open observations will be marked as past due."
      }
      inputType={GENERIC_FIELD_TYPES.DATE}
      updateItemById={updateItemById}
      fieldContext={fieldContext}
      inputConfig={{
        dateOnly: true, //removes time from the date picker
      }}
      displayConfig={{
        dateOnly: true,
      }}
    />
  );
};

export default EstimatedCompletionDate;
