import { useEffect } from "react";

/**
 * A state updater method for one-many transfer list hook, populates the side with items that are not linked to the item yet
 * @param {object[]} manyItems - all items available to be linked
 * @param {function} setUnlinkedItems - function to update the state of the transfer list unlinked items
 * @param {object[]} existingItems - all items that are already linked
 */
export const usePopulateUnlinkedItems = ({ manyItems, setUnlinkedItems, existingItems } = {}) => {
  useEffect(() => {
    const alreadyLinked = {};
    if (Array.isArray(manyItems)) {
      for (const oneItem of manyItems) {
        const oneItemId = oneItem?.id;
        if (oneItemId) {
          alreadyLinked[oneItemId] = true;
        }
      }
    }

    const listItems = [];
    if (Array.isArray(existingItems)) {
      for (const oneItem of existingItems) {
        const oneItemId = oneItem?.id;
        if (!alreadyLinked.hasOwnProperty(oneItemId)) {
          listItems.push(oneItem);
        }
      }
    }

    if (typeof setUnlinkedItems === "function") setUnlinkedItems(listItems);
  }, [manyItems, existingItems]);
};
