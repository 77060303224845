import { useEffect, useRef } from "react";

/**
 * @description A useEffect hook that DOES NOT run on the initial render.
 * This hook will only run when a dep changes!
 * @param {function} func - The function to run on the dep change
 * @param {*[]} deps - an array of dependencies that will trigger the callback function
 * @returns {function} - a function that can be called to unmount the hook
 */
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount?.current) {
      if (typeof func === "function") {
        return func();
      }
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useDidMountEffect;
