import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateQuestionnaire } from "../hooks/useCreateQuestionnaire";

/**
 * A component instance of the useCreateQuestionnaire.js hook
 * @param {object} props
 */
const CreateQuestionnaire = (props) => {
  return useCreateQuestionnaire(props).display;
};

export default withOrganizationCheck(CreateQuestionnaire);
