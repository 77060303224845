export const nestedFieldsMeetingDetails = {
  agendaItems: ` (limit: 100) {
        items {
          id
          content
          ownerGroup
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
          createdAt
          updatedAt
        }
        nextToken
      }`,
  agenda: `{
    id
    content
    completed
    notes {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      tag
      observationID
    }
  }`,
  documents: ` (limit: 100) {
        items {
          id
          name
          owner
          visibility
          createdAt
          avStatus
          lastAVCheck
          file {
            bucket
            region
            key
          }
          accessControl {
            password
            passwordOwnerEmail
            pointOfContacts
            roles
          }
          ownerGroup
          meetingID
          updatedAt
        }
        nextToken
      }`,
  accessControl: `{
        password
        passwordOwnerEmail
        roles
        pointOfContacts
      }`,
  notes: `{
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }`,
  pointOfContacts: `(limit: 1000) {
    items {
      pointOfContact {
        email
      }
    }
  }`,
  tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
};

export const updateMeetingNotes = /* GraphQL */ `
  mutation UpdateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

export const updateAgendaItemMeeting = /* GraphQL */ `
  mutation UpdateAgendaItem($input: UpdateAgendaItemInput!) {
    updateAgendaItem(input: $input) {
      id
      content
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;
