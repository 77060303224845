import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";
import { GenericDeleteButton } from "../../../../../../../utils/GenericComponents/GenericDeleteButton";

/**
 * A button that deletes a SystemControlCategoryLink
 *
 * These links should be deleted automatically,
 * this is an admin level functionality for cleaning up old connections
 * @param item
 * @returns {*}
 * @constructor
 */
const RemoveSystemControlCategoryLinkButton = ({ item }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const deleteSystemControlCategoryLink = async (item) => {
    const { deleteMutation } = generateGraphql("SystemControlCategoryLink");
    await ItemMutation(deleteMutation, { id: item.id });
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    func: "adminDeleteSystemControlCategoryLink",
  });

  return (
    <>
      {checkPermissionsHook.function.isEnabled && (
        <GenericDeleteButton deleteFunction={(item) => deleteSystemControlCategoryLink(item)} item={item} />
      )}
    </>
  );
};

export default RemoveSystemControlCategoryLinkButton;
