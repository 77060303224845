import React from "react";
import { Button } from "reactstrap";

/**
 * Displays a green plus icon
 * @param {function} onClick
 * @param {object} style
 * @param {string} className
 * @param {string} color
 * @param {string} size
 * @param {string} iconClassName
 * @param {boolean} showIcon
 * @param {string} buttonText
 * @param {string} title
 * @param {string} dataTestId
 * @returns {*}
 */
const AddButton = ({
  onClick = () => {},
  style = {},
  className = "btn-pill",
  color = "success",
  size = "sm",
  iconClassName = "icon-plus",
  showIcon = true,
  buttonText,
  title = "Add a new Control Framework",
  dataTestId = "add-button",
}) => {
  return (
    <Button
      data-testid={dataTestId}
      onClick={() => onClick()}
      style={{ ...style }}
      className={className}
      color={color}
      size={size}
      title={title}
    >
      {showIcon && <i className={iconClassName} style={buttonText ? { marginRight: "1em" } : undefined} />}
      {buttonText}
    </Button>
  );
};

export default AddButton;
