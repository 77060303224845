import pluralize from "pluralize";
import React from "react";

import { useModal } from "../../../../../../hooks/views/useModal";
import AssessmentTargets from "../../../../Assessments/components/AssessmentTargets";
import AssessmentVulnerabilities from "../../../../Assessments/components/AssessmentVulnerabilities";

/**
 * Hook handling all modals for the assessment status timeline content
 * @param {string} assessmentID - the assessment id
 * @param {boolean} isFindingAttached - if TRUE viewing the component from target side (such as target details)
 * @param {Set<string>} processedLinkIds - the vulnerability target links touched by the assessment
 * @param {object} vulnerability - vulnerability object if viewing from the vulnerability side (vulnerability details)
 * @param {object} target - target object if viewing from the target side (target details)
 * @returns {{assessmentTargetsGridModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, handleClick: handleClick, determineTooltipText: ((function(): (string))|*), vulnerabilitiesGridModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}}
 */
export const useAssessmentModals = ({ assessmentID, isFindingAttached, processedLinkIds, vulnerability, target }) => {
  const determineTooltipText = () => {
    if (isFindingAttached) {
      return `Click to view the impacted ${pluralize("vulnerability", processedLinkIds.size)}`;
    } else {
      return `Click to view the impacted ${pluralize("target", processedLinkIds.size)}`;
    }
  };

  const assessmentLinksGridModal = useModal(
    `Assessment ${isFindingAttached ? "Vulnerabilities" : "Targets"}`,
    isFindingAttached ? (
      <AssessmentVulnerabilities item={{ id: assessmentID }} filterByTarget={target} />
    ) : (
      <AssessmentTargets item={{ id: assessmentID }} filterByVulnerability={vulnerability} />
    ),
    null,
    { width: "80vw" },
  );

  const handleClick = () => {
    assessmentLinksGridModal.setModalIsOpen(true);
  };

  return {
    assessmentLinksGridModal,
    handleClick,
    determineTooltipText,
  };
};
