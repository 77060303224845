import React from "react";
import { Button } from "reactstrap";

import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import ControlEvidenceLinkImporter from "../../../CsvImporters/ControlEvidenceLinkImporter/components/ControlEvidenceLinkImporter";

/**
 * Modal button for the ControlEvidenceLink importer
 * @param organizationID
 * @param controlOrganizationID
 * @param evidenceOrganizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ControlEvidenceLinkImporterButton = ({ organizationID, controlOrganizationID, evidenceOrganizationID }) => {
  const modal = useModal(
    "Import Control Evidence Links",
    <ControlEvidenceLinkImporter
      organizationID={organizationID}
      controlOrganizationID={controlOrganizationID}
      evidenceOrganizationID={evidenceOrganizationID}
    />,
    <Button color="link" className="float-right">
      Import
    </Button>,
    {
      width: "75vw",
    },
  );

  return <>{modal.modalButton}</>;
};

export default withOrganizationCheck(ControlEvidenceLinkImporterButton);
