"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutomationStepExecutionOrder = void 0;
const getAutomationIdentifier_1 = require("../utils/getAutomationIdentifier");
/**
 * Uses the automation step adjacency List to create a list representation of automations based on the execution order
 * and which automations depend on which other automations
 * @param {object} adjacencyList - the adjacency list of automations steps
 * @param {object} automation - the automation data with all steps and their id/names
 * @return {object[]} - the automation step identifiers sorted by execution order { name: string, executionOrder: string }
 */
const getAutomationStepExecutionOrder = ({ adjacencyList: originalAdjacencyList, automation }) => {
    //Check arguments
    const allSteps = automation?.automations;
    if (!originalAdjacencyList || !allSteps || !Array.isArray(allSteps)) {
        return [];
    }
    const adjacencyList = JSON.parse(JSON.stringify(originalAdjacencyList));
    const sortedAutomationSteps = [];
    let iterationOrder = 1;
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //Remove root nodes one at a time until there are no more roots
    while (Object.keys(adjacencyList).length > 0) {
        //Check if there are root nodes with no incoming edges
        const rootNodes = [];
        for (const nodeName in adjacencyList) {
            const node = adjacencyList[nodeName];
            if (Array.isArray(node?.incoming) && node.incoming.length === 0) {
                rootNodes.push(node);
            }
        }
        //Check if any root nodes were found (error if not)
        if (rootNodes.length === 0) {
            throw new Error("Could not sort automation steps by execution order because there are circular dependencies!");
        }
        //Add root nodes to sorted list and remove them from the adjacency list
        let rootOrder = 0;
        for (const rootNode of rootNodes) {
            let executionOrder = iterationOrder.toString();
            if (rootNodes.length > 1) {
                executionOrder += alphabet[rootOrder % 26];
            }
            sortedAutomationSteps.push({
                name: rootNode.name,
                executionOrder,
            });
            delete adjacencyList[rootNode.name];
            //Remove incoming edges from the root node to other nodes
            for (const outgoingNodeName of rootNode.outgoing) {
                const outgoingNode = adjacencyList[outgoingNodeName];
                if (Array.isArray(outgoingNode.incoming) && outgoingNode.incoming.length > 0) {
                    outgoingNode.incoming = outgoingNode.incoming.filter((incomingNodeName) => incomingNodeName !== rootNode.name);
                }
            }
            rootOrder += 1;
        }
        iterationOrder += 1;
    }
    //Run all remaining automation steps that were not in the adjacency list
    const remainingSteps = [];
    for (const automationStep of allSteps) {
        if (!sortedAutomationSteps.find((sortedAutomationStepName) => sortedAutomationStepName.name === (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep }))) {
            remainingSteps.push(automationStep);
        }
    }
    //Add remaining steps to the end of the list
    let rootOrder = 0;
    for (const remainingStep of remainingSteps) {
        let executionOrder = iterationOrder.toString();
        if (remainingSteps.length > 1) {
            executionOrder += alphabet[rootOrder % 26];
        }
        sortedAutomationSteps.push({
            name: (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep: remainingStep }),
            executionOrder: executionOrder,
        });
        rootOrder += 1;
    }
    return sortedAutomationSteps;
};
exports.getAutomationStepExecutionOrder = getAutomationStepExecutionOrder;
