import { useCustomSwitch } from "../hooks/useCustomSwitch";

/**
 * @description Component version for useCustomSwitch hook
 * @param {string} field - field name
 * @param {boolean} value - current value
 * @param {function} setValue - function to set new value
 * @param {object} inputConfig - configuration for switch
 * @returns {JSX.Element}
 * @constructor
 */
const CustomSwitch = ({ field, value, setValue, inputConfig }) => {
  return useCustomSwitch({ field, value, setValue, inputConfig }).display;
};

export default CustomSwitch;
