"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkArguments = void 0;
const isNullOrUndefined_1 = require("./isNullOrUndefined");
//TODO: throw a special type of error after Jacobs PR is merged and a rebase is done
/**
 * Utility function to check if all of the arguments provided to some other function are valid
 * Throws an error with a descriptive message if any of the requirements are not met
 * @param {object} args - all of the arguments provided to the initial function that need to be checked
 * @param {object} requirements - object of objects with parameter options to check (by default only checks if argument is null)
 * Options for argument requirements to check:
 *    {boolean} validAsNull - (optional) will not throw an error if the argument is null
 *    {string} type - (optional) the js type of the parameter - number, string, object, function etc
 *    {boolean} deepTypeCheck - (optional) when TRUE will check the values of arrays for correct type
 *    {boolean} isArray - (optional) when TRUE will check for argument to be an array
 */
const checkArguments = (args, requirements = {}) => {
    //Check args parameter
    if (!args || typeof args !== "object") {
        throw Error("Provided args argument to checkArguments needs to be an object or array");
    }
    for (const arg in args) {
        //Get requirements for current arg
        let require = {};
        if (requirements.hasOwnProperty(arg) && !(0, isNullOrUndefined_1.isNullOrUndefined)(requirements[arg])) {
            require = requirements[arg];
        }
        //Null Check
        if ((0, isNullOrUndefined_1.isNullOrUndefined)(args[arg])) {
            if (!require.validAsNull) {
                throw Error(`Error - argument '${arg}' is null`);
            }
            // no further checks on a needed on a null argument
            continue;
        }
        //Array Check
        if (require.isArray) {
            if (!Array.isArray(args[arg])) {
                throw Error(`Error - argument '${arg}' needs to be an array`);
            }
            else {
                //Perform deep type check if enabled
                if (require.deepTypeCheck && require.type) {
                    for (const item of args[arg]) {
                        if (typeof item !== require.type) {
                            throw Error(`Error - an element in array '${arg}' has the wrong type - ${typeof item} and not ${require.type}`);
                        }
                    }
                }
            }
        }
        //Type check
        const paramType = require.isArray ? "object" : require.type;
        if (paramType && typeof args[arg] !== paramType) {
            throw Error(`Error - argument '${arg}' is the wrong type - ${typeof args[arg]} and not ${paramType}`);
        }
    }
};
exports.checkArguments = checkArguments;
