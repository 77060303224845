import React, { useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Displays Documents for an Incident
 * @param props
 * @constructor
 */
const IncidentDocuments = ({
  item,
  organizationID,
  module = modules.INCIDENT_RESPONSE,
  resource = resources.INCIDENT,
}) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setDocuments(item?.documents?.items);
  }, [item?.documents]);

  const gridConfig = {
    gridHeight: "100%",
    allowPaging: false,
  };

  const queryConfig = {
    query: null,
  };

  useEffect(() => {
    grid?.setData(documents);
  }, [documents]);

  const grid = useDocumentDataGrid({
    organizationID,
    ...queryConfig,
    ...gridConfig,
    connectionInput: { incidentID: item?.id },
    module,
    resource,
    createResourceComponent: null,
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(IncidentDocuments);
