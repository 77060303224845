"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceAsync = void 0;
/**
 * Helper function for performing an async call for the matcher in the js string replace function
 * @param {string} str - the original string, parts of which will be replaced with dynamic (async) values
 * @param {string} regex - the regex to find matches against the string
 * @param {function} asyncFn - function to call to replace matches (will be passed the match as a parameter)
 * @return {Promise<string>} - a promise that resolves to the new string with all matches replaced
 */
const replaceAsync = async (str, regex, asyncFn) => {
    const promises = [];
    str.replace(regex, (match, ...args) => {
        const promise = asyncFn(match, ...args);
        promises.push(promise);
    });
    const data = await Promise.all(promises);
    return str.replace(regex, () => data.shift());
};
exports.replaceAsync = replaceAsync;
