import React, { useEffect, useState } from "react";
import { ListGroup } from "reactstrap";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import ResetButton from "../../../../../../../utils/GenericComponents/buttons/ResetButton";
import { LoadingSpinner } from "../../../../../../../utils/LoadingComponents/LoadingSpinner";
import { useMostRecentVendorReview } from "../../../../../../Vendor/VendorSolutionReviews/hooks/useMostRecentVendorReview/useMostRecentVendorReview";

import ControlCategoryItem from "./ControlCategoryItem";

/**
 * Displays all Control Categories for a System
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RiskControlCategories = (props) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;
  const disableRoleChecking = props.disableRoleChecking;

  const [list, setList] = useState([]);

  const systemID = props.system && props.system.id;

  const controlCategoriesQuery = useQueryGetItem({
    itemId: systemID,
    query: realTimeRiskGQL.getSystem_controlCategories,
    module,
    resource,
    disableRoleChecking,
  });

  const system = controlCategoriesQuery.item;

  const systemControlCategoryLinks =
    system && system.controlCategories && system.controlCategories.items ? system.controlCategories.items : [];

  useEffect(() => {
    const temp = [];
    for (const systemControlCategoryLink of systemControlCategoryLinks) {
      temp.push({
        ...systemControlCategoryLink.riskControlCategory,
        linkId: systemControlCategoryLink.id,
      });
    }

    setList([...temp]);
  }, [system]);

  useEffect(() => {
    if (!controlCategoriesQuery.isLoading) {
      controlCategoriesQuery.reset();
    }
  }, [props.resetKey]);

  const reset = () => {
    props.resetFunction && props.resetFunction();
    controlCategoriesQuery.reset && controlCategoriesQuery.reset();
  };

  const { vendorReview } = useMostRecentVendorReview({
    vendorSolutionID: props?.system?.vendorSolution?.id,
  });

  return (
    <>
      <strong>
        Control Categories
        <ResetButton resetFunction={controlCategoriesQuery.reset} />
      </strong>
      {!controlCategoriesQuery.item ? (
        <div>
          <LoadingSpinner loadingMessage="Loading Control Categories.. " />
        </div>
      ) : (
        <ListGroup style={{ marginTop: "0.5em" }}>
          {list && list.length > 0 ? (
            list
              .sort((a, b) => parseFloat(a.name.split(" ")[0]) - parseFloat(b.name.split(" ")[0]))
              .map((item, index) => (
                <ControlCategoryItem
                  Context={props.Context}
                  vendorReview={vendorReview}
                  viewType={props.viewType}
                  organizationID={props.organizationID}
                  key={JSON.stringify(item) + index}
                  item={item}
                  system={system}
                  resetFunction={() => reset()}
                  disableRoleChecking={disableRoleChecking}
                  isTemplate={props.isTemplate}
                />
              ))
          ) : (
            <span style={{ color: "lightgrey", fontStyle: "italic" }}>No Control Categories</span>
          )}
        </ListGroup>
      )}
    </>
  );
};

export default withOrganizationCheck(RiskControlCategories);
