import { Button } from "@mui/material";
import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { Container } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { getToolByType } from "../functions/getToolByType";

import CreateTool from "./CreateTool";

export interface CheckToolProps {
  organizationID: string;
  toolType: string; // Comes from TOOL_TYPES
  disableToolCheck?: boolean;
}

/**
 * @description Check if an integration have a tool configured
 */
const CheckTool = ({
  organizationID,
  toolType,
  disableToolCheck = false,
  ...props
}: PropsWithChildren<CheckToolProps>): ReactElement => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;

  const [tool, setTool] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const checkTool = (): void => {
    if (toolType && organizationID) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getToolByType({
        toolType,
        organizationID,
      })
        .then((data) => setTool(data))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    checkTool();
  }, [organizationID && toolType]);

  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
  });

  const getNewItem = (): void => {
    checkTool();
  };

  const createModal = useModal(
    `Set-up a ${toolType && convertCamelCaseToSentence(toolType)} Tool Integration`,
    <CreateTool toolType={toolType} getNewItem={getNewItem} name={`${toolType} Integration`} />,
    <Button color={"secondary"}>
      <i className={"icon-energy"} style={{ marginRight: "1em" }} />
      Set-Up Integration
    </Button>,
    { width: "60vw" },
  );

  const noIntegrationConfiguredComponent = (
    <div>
      <Container
        className="animated fade-in"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5em",
        }}
      >
        <div>
          <div>
            <h4>
              It looks like you don't have a {toolType && convertCamelCaseToSentence(toolType)} Integration set up yet.
            </h4>
          </div>
          {checkPermissionsHook.resource.create && (
            <div>
              <Container
                className="animated fade-in"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5em",
                }}
              >
                {createModal.modalButton}
              </Container>
            </div>
          )}
        </div>
      </Container>
    </div>
  );

  const toolComponent =
    !tool && !disableToolCheck
      ? noIntegrationConfiguredComponent
      : React.cloneElement(props.children as ReactElement, {
          ...props,
        });

  return <div style={{ height: "100%" }}>{isLoading ? <LoadingSpinner /> : toolComponent}</div>;
};

export default CheckTool;
