import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../hooks/views/useDataGrid/useDataGrid";
import StyledWrapper from "../../../utils/GenericComponents/StyledWrapper";
import CreateCustomQuery from "../components/CreateCustomQuery";
import CustomQueryDetails from "../components/CustomQueryDetails";
import { deleteCustomQuery } from "../functions/deleteCustomQuery";

export const useCustomQueryDataGrid = ({ organizationID, isInAdminTab = false, ...props }) => {
  const module = isInAdminTab ? modules.ADMINISTRATOR : modules.ORGANIZATION_MANAGER;
  organizationID = isInAdminTab ? "TEMPLATE" : organizationID;
  const resource = resources.CUSTOM_QUERY;
  const typename = resources.CUSTOM_QUERY;

  const queryFields = ["name", "description", "typename", "fields"];
  const { listQuery, updateMutation } = generateGraphql("CustomQuery", queryFields);

  const fields = [
    {
      name: "name",
      width: 250,
    },
    {
      name: "description",
      minWidth: 250,
      flex: 1,
    },
    {
      name: "typename",
      friendlyName: "Type Name",
      width: 150,
    },
  ];

  const roleConfig = {
    module,
    resource,
  };

  const queryConfig = {
    query: listQuery,
    organizationID,
  };

  const cardConfig = {
    title: "Custom Queries",
    headerIcon: "icon-check",
  };

  const gridConfig = {
    fields,
    typename,

    options: ["details", "delete"],
    deleteFunction: deleteCustomQuery,
    updateMutation,

    createResourceComponent: (
      <StyledWrapper wrapperStyle={{ height: "80vh" }}>
        <CreateCustomQuery enableSubmitButton={true} organizationID={organizationID} />
      </StyledWrapper>
    ),
    detailsComponent: <CustomQueryDetails tableDisplay={true} organizationID={organizationID} />,
    detailsTitle: "Custom Query Details",
    persistenceUUID: "a75e3007-4764-4d63-ab47-175724bea585",
  };
  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
  });

  return {
    ...gridCard,
  };
};
