import React from "react";

import { useUUID } from "../../../../../../../hooks/functional/useUUID";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import LossToleranceChart from "../../../../../LossTolerance/components/LossToleranceChart";

/**
 * Displays the Monte Carlo curves chart for a System
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemMonteCarloGraph = (props) => {
  const [id] = useUUID();

  return (
    <div key={id}>
      <props.Context>
        <LossToleranceChart />
      </props.Context>
    </div>
  );
};

export default withOrganizationCheck(SystemMonteCarloGraph);
