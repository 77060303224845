import React, { useContext, useEffect, useState } from "react";
import { Input, UncontrolledTooltip } from "reactstrap";

import { modules } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCard } from "../../../../../hooks/views/useCard";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { onChangeDefaultRoute } from "../functions/onChangeDefaultRoute";

/**
 * @description Interface Default Route
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceDefaultRoute = () => {
  const context = useContext(OrganizationContext);
  const { addToast, defaultRoute, setDefaultRoute } = useContext(UIContext);

  const preferences = usePreferences();

  const [currentValue, setCurrentValue] = useState(defaultRoute);
  useEffect(() => {
    if (!defaultRoute) {
      if (context?.isAdmin) {
        setCurrentValue("/admin_panel/organizations");
      } else {
        setCurrentValue("/my_dashboard/current");
      }
    } else {
      setCurrentValue(defaultRoute);
    }
  }, [defaultRoute]);

  //Find module permissions
  const actionCenterPermissions = useCheckPermissions({
    module: modules.ACTION_CENTER,
  }).module.isEnabled;
  const programPermissions = useCheckPermissions({ module: modules.GOVERNANCE }).module.isEnabled;
  const compliancePermissions = useCheckPermissions({
    module: modules.COMPLIANCE,
  }).module.isEnabled;
  const riskPermission = useCheckPermissions({ module: modules.RISK }).module.isEnabled;
  const responsePermissions = useCheckPermissions({
    module: modules.INCIDENT_RESPONSE,
  }).module.isEnabled;
  const testingPermissions = useCheckPermissions({
    module: modules.VULNERABILITIES,
  }).module.isEnabled;
  const vendorsPermissions = useCheckPermissions({ module: modules.VENDORS }).module.isEnabled;
  const metricsPermission = useCheckPermissions({ module: modules.METRICS }).module.isEnabled;

  const card = useCard({
    id: "default-route",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Default Route</h4>{" "}
        <i id="default-route-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="default-route-icon-question">
          Select where the app should navigate to at startup.
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <Input
          type="select"
          value={currentValue}
          onChange={(e) =>
            onChangeDefaultRoute({
              value: e.target.value,
              preferences,
              setDefaultRoute,
              addToast,
            })
          }
        >
          <option value="/my_dashboard">Personal Dashboard</option>
          {actionCenterPermissions && <option value="/action_dashboard">Action Center</option>}
          {context?.isAdmin === true && <option value="/admin_panel/organizations">Organization List</option>}
          {programPermissions && <option value="/governance/findings_dashboard">Findings Dashboard</option>}
          {compliancePermissions && (
            <option value="/continuous_compliance/dashboard">Compliance Module Dashboard</option>
          )}
          {riskPermission && <option value="/risk/dashboard">Risk Module Dashboard</option>}
          {responsePermissions && <option value="/response/dashboard">Response Module Dashboard</option>}
          {testingPermissions && <option value="/vulnerabilities/dashboard">Testing Module Dashboard</option>}
          {vendorsPermissions && <option value="/vendors/dashboard">Vendors Module Dashboard</option>}
          {metricsPermission && <option value="/metrics/metrics">Metrics Dashboard</option>}
        </Input>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceDefaultRoute;
