/**
 * Different categories of tasks the platform supports, these dictate the custom UI a user sees for completing a task
 * @type {{CHECKLIST: string, ACCESS_REQUEST: string, CUSTOM: string, TEMPLATE_UPDATE: string, QUESTIONNAIRE: string, FINDING_DECISION: string}}
 */
export const taskType = {
  ACCESS_REQUEST: "accessRequest",
  CUSTOM: "custom",
  CHECKLIST: "checklist",
  FINDING_DECISION: "findingDecision",
  TEMPLATE_UPDATE: "templateUpdate",
  QUESTIONNAIRE: "questionnaire",
};
