"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetConfig = void 0;
const getWidgetData_1 = require("../getWidgetData/getWidgetData");
const formatWidgetConfig_1 = require("./formatWidgetConfig");
/**
 * Entry function for changing the widget object into a formatted widget config,
 * this is the middle ware that correctly sets up widget properties
 * @param {object} widget - full widget information from the database
 * @param {string} contextType - the type of location this widget is displayed in (report, email, web, etc.)
 * @param {object} [data] - existing data if passed in data retrieval won't be attempted
 * @param {function} runCustomQuery - function to run custom queries
 * @return {Promise<object>} - formatted configuration object for the chosen widget
 */
const getWidgetConfig = async ({ widget, contextType, data, runCustomQuery }) => {
    let config = {};
    try {
        data = data ?? (await (0, getWidgetData_1.getWidgetData)({ widget, runCustomQuery }));
        // transforms the widget config (applies default context properties, creates dataSource)
        config = await (0, formatWidgetConfig_1.formatWidgetConfig)({ widget, data, contextType });
    }
    catch (e) {
        console.log(e);
    }
    return config;
};
exports.getWidgetConfig = getWidgetConfig;
