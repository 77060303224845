import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { useCreateThreatsFromTechniques } from "../../../../Response/Threats/hooks/useCreateThreatsFromTechniques";

/**
 * A form for creating a new Threat
 *
 * @param {string} organizationID - ownerGroup field applied to mutation
 * @param {object} ...props - all props passed to useForm hook
 */
export const useThreatForm = ({ organizationID, resetFunction, toggleModal, ...props }) => {
  const { createMutation: createThreat } = generateGraphql("Threat", ["name"]);

  const module = modules.RISK;
  const resource = resources.THREAT;

  const fieldConfig = {
    name: {
      required: true,
      label: "Name",
    },
  };

  /**
   * Import MITRE ATT&CK Techniques
   */
  const importTechniques = useCreateThreatsFromTechniques({
    organizationID,
    resetFunction,
    toggleModal,
    disableToolCheck: true,
  });

  const form = useForm({
    headerButtons: [importTechniques.modalButton],
    fieldConfig,
    mutation: createThreat,
    module,
    resource,
    organizationID,
    typename: "Threat",
    ...props,
  });

  return {
    ...form,
  };
};
