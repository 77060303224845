import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import GenericEditField from "../../../../../../../utils/GenericComponents/GenericEditFieldV2";

const CostOfControl = ({ item, resetFunction }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const { updateMutation: updateRiskControl } = generateGraphql("RiskControl", ["implementationRating"]);

  return (
    <span style={{ width: "100%" }}>
      <GenericEditField
        item={item}
        field="costOfControl"
        mutation={updateRiskControl}
        module={module}
        resource={resource}
        format="dollar"
        resetFunction={resetFunction}
        tooltip={"Estimated annual cost of implementing this Control"}
      />
    </span>
  );
};

export default withOrganizationCheck(CostOfControl);
