import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Regex class for regex operations and validation. This class is a wrapper for the native RegExp class.
 * It provides a set of methods to validate and manipulate regexes. It also provides a set of methods to match
 * and replace strings using regexes.
 * @param {string} email - the email string to check if a true email
 * @returns {boolean} - true if the email is a valid email
 */
export const CheckEmailRegex = (email) => {
  const emailRegex = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  );
  return emailRegex.test(email);
};

/**
 * Tests a given phone number to be valid for the platform
 * @param {string} phoneNumber - the text that represents a phone number toi test
 * @returns {boolean} - TRUE if the provided phone number is valid
 */
export const CheckPhoneNumberRegex = (phoneNumber) => {
  /**
   * All phone numbers can't be null to be considered a phone number
   */
  if (isNullOrUndefined(phoneNumber)) {
    return false;
  }

  /**
   * All cognito phone numbers
   *  - including international numbers that have a + at the start
   *  - have at least 10 digits that follow
   *  - have no more than 15 digits that follow
   *  - do not have anything else after the digits
   */
  const phoneNumberRegex = new RegExp("^\\+\\d{10,15}$");
  return phoneNumberRegex.test(phoneNumber);
};
