import { useContextMenu } from "../useContextMenu";

/**
 * A component instance of the useContextMenu.js hook
 * @param {object} props - the props to pass through to the useContextMenu hook
 */
const ContextMenu = (props) => {
  return useContextMenu(props).display;
};

export default ContextMenu;
