import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useRiskChangeDataGrid } from "../hooks/useRiskChangeDataGrid";

/**
 * A component instance of the useRiskChangeDataGrid.js hook
 * @param props
 */
const RiskChangeDataGrid = (props) => {
  return useRiskChangeDataGrid(props).display;
};

export default withOrganizationCheck(RiskChangeDataGrid);
