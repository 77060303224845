import { not } from "@rivial-security/func-utils";

/**
 * Handler for transferring items from the right side to the left side of the transfer list
 *
 * @param {object[]} leftItems - items currently on the left side of the list
 * @param {function} setLeftItems - handler for setting the left side items
 * @param {object[]} rightItems - items currently on the right side of the list
 * @param {function} setRightItems - handler for setting the right side items
 * @param {object[]} rightChecked - items on the right side of the list that are currently checked
 * @param {object[]} checkedItems - all checked items
 * @param {function} setCheckedItems - handler for setting checked items
 */
export const handleCheckedLeft = (
  leftItems = [],
  setLeftItems,
  rightItems = [],
  setRightItems,
  rightChecked = [],
  checkedItems = [],
  setCheckedItems,
) => {
  setLeftItems(leftItems.concat(rightChecked));
  setRightItems(not(rightItems, rightChecked));
  setCheckedItems(not(checkedItems, rightChecked));
};
