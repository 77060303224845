// Taken from here: https://github.com/sindresorhus/array-move/blob/44103d4d7f4761823aeafe6b9dc9708deb830d57/index.js#L12
// The array-move package isn't being used directly since it isn't compatible with Jest: https://github.com/sindresorhus/array-move/issues/25

function arrayMoveMutable(array: object[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item!);
  }
}

export function arrayMoveImmutable(array: object[], fromIndex: number, toIndex: number) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}
