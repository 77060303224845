import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useVendorCertificationDetails } from "../hooks/useVendorCertificationDetails";

/**
 * Details page for a Vendor Certification
 *
 * @constructor
 */
const VendorCertificationDetails = (props) => {
  return useVendorCertificationDetails(props).display;
};

export default withDetailsPage(VendorCertificationDetails, {
  title: "Pre-Qualified Certification Details",
});
