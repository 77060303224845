import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useSystemGrid } from "../hooks/useSystemGrid";

/**
 * @deprecated - use SystemDataGrid instead
 * @param organizationID
 * @param props
 * @returns {*|JSX.Element}
 * @constructor
 */
const SystemGrid = ({ organizationID, ...props }) => {
  return useSystemGrid({ organizationID, ...props }).display;
};

export default withOrganizationCheck(SystemGrid);
