import React from "react";

import { useGridContext } from "../hooks/useGridContext";

/**
 * @description Custom component for toolbar create item
 * @return {JSX.Element}
 * @constructor
 */
const GridCreateModalButton = () => {
  const { createItemModal } = useGridContext();

  return (
    <>
      {/*{createItemModal?.modal} if present creates a second modal needs to be tested*/}
      {createItemModal?.modalButton}
    </>
  );
};

export default GridCreateModalButton;
