export const getSystem_riskChanges = `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        riskChangeLinks {
          items {
            id
            ownerGroup
            systemID
            riskChangeID
            riskChange {
              id
              ownerGroup
              name
              type
              description
              date
              userEmail
              changeOwner
              reason
              change
              groupID
              systemLinks (limit: 1000) {
                items {
                  id
                  ownerGroup
                  system {
                    id
                    name
                    ownerGroup
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
