"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomCSSColor = void 0;
/**
 * Returns a random light css color to use with graphs and other visualizations
 * REFERENCE: https://stackoverflow.com/questions/23095637/how-do-you-get-random-rgb-in-javascript
 */
const getRandomCSSColor = () => {
    const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
    const r = randomBetween(100, 255);
    const g = randomBetween(100, 255);
    const b = randomBetween(100, 255);
    return `rgb(${r},${g},${b})`;
};
exports.getRandomCSSColor = getRandomCSSColor;
