import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  inOverflowMenu: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
/**
 * @description Displays a Toolbar with menu buttons that go into an overflow menu as the toolbar shrinks
 * @param {ReactNode} children - The items to be displayed in the toolbar
 * @param {string} className - The className to be applied to the overflow menu
 * @returns {JSX.Element|null} - A toolbar with an overflow menu
 */
export default function OverflowMenu({ children, className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  // On click, opens the overflow menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Makes sure to close the overflow menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        title={"Click for more Toolbar options"}
      >
        <MoreHoriz />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{ display: "flex", width: "300px", justifyContent: "center" }}
      >
        {children &&
          React.Children.map(children, (child) => {
            return (
              <span key={child} onClick={handleClose} style={{ flex: 1 }}>
                {React.cloneElement(child, {
                  className: classnames(child?.className, classes?.inOverflowMenu),
                })}
              </span>
            );
          })}
      </Menu>
    </div>
  );
}
