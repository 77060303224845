import React from "react";

import { removeObjectFromArray } from "@rivial-security/func-utils";

import TagsField from "../../../../../../utils/Tags/customFields/TagsField";

/**
 * Custom Component to preview the new state of Tags for an Item during a Tags Bulk Edit operation
 *
 * @param {object} item - the internal state 'item' to be bulk edited
 * @param {object} item.newValue - the internal state that holds tagsToAdd and tagsToRemove
 * @param {object[]} item.newValue.tagsToAdd - array of tags to add
 * @param {string} item.newValue.tagsToAdd[].id - ID of the tag to add
 * @param {string} item.newValue.tagsToAdd[].name - name of the tag to add
 * @param {object[]} item.newValue.tagsToRemove - array of tags to remove
 * @param {string} item.newValue.tagsToRemove[].id - the ID of the tag to remove
 * @param {string} item.newValue.tagsToRemove[].name - the name of the tag to remove
 * @param {object} item.tags - current tags link object for the 'item'
 * @param {object[]} item.tags.items - current tag items array for the 'item'
 * @param {string} item.tags.items[].id - ID of the tagLink
 * @param {object} item.tags.items[].tag - the tagLink.tag object
 * @param {string} item.tags.items[].tag.id - ID of the tagLink.tag
 * @param {string} item.tags.items[].tag.name - name of the tagLink.tag
 * @returns {JSX.Element}
 * @constructor
 */
const DataGridBulkEditTagsPreviewField = ({ item }) => {
  const getUpdatedTagList = ({ currentTags, tagsToAdd, tagsToRemove }) => {
    let result = [...currentTags];

    // first remove tags
    for (const tagToRemove of tagsToRemove) {
      result = removeObjectFromArray(result, tagToRemove, "id");
    }

    // then add tags
    for (const tagToAdd of tagsToAdd) {
      const isTagAlreadyAdded = currentTags.some((currentTag) => currentTag.id === tagToAdd.id);

      if (!isTagAlreadyAdded) {
        result.push(tagToAdd);
      }
    }

    return result.map((res) => ({
      ...res,
      tag: res,
    }));
  };

  /**
   * Updates the array of Current Tags, removes and adds selected tags
   * @type {(*&{tag: *})[]}
   */
  const items = getUpdatedTagList({
    currentTags: item.tags.items.map((tagLink) => tagLink.tag),
    tagsToAdd: item.newValue.tagsToAdd,
    tagsToRemove: item.newValue.tagsToRemove,
  });

  return (
    <TagsField
      item={{
        tags: {
          items,
        },
      }}
      tags={{
        items,
      }}
    />
  );
};

export default DataGridBulkEditTagsPreviewField;
