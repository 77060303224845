import { getFunctionDefinitionByName } from "./getFunctionDefinitionByName";

/**
 * Finds the expected return values and types given user inputs to the configure the function
 * @param {object[]} path - current path to the function that traverses the query config
 * @param {object} func - the function custom query element, contains config values
 * @returns {*|null} - the result(s) of the function described  in the same way as fields in the query config
 */
export const getFunctionDefinitionResult = ({ path, func }) => {
  if (!func?.name) {
    return null;
  }

  const functionResult = getFunctionDefinitionByName({
    name: func?.name,
  })?.result;

  let finalResult = functionResult;
  if (typeof functionResult === "function") {
    finalResult = functionResult({ path, config: func?.config });
  }

  return finalResult;
};
