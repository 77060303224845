import { modules } from "@rivial-security/role-utils";

import { createFullObservation } from "../../../../../Program/Observations/functions/createFullObservation";

interface CreateRiskRecommendationInput {
  input: {
    observation: string;
    isFinding: boolean;
    recommendation: string;
    rating: string;
  };
  organizationID: string;
  formattedRecommendationData: string;
  formattedRiskRecommendationData: {
    selectedRiskControl: {
      id: string;
    };
    changeInImplementation: number;
    changeInCost: number;
    selectedSystem: {
      id: string;
    };
    overallResidualChange: number;
    overallROIChange: number;
    systemChanges: {
      id: string;
      name: string;
      residualChange: number;
      returnOnInvestment: number;
    }[];
  };
  selectedRiskControl: {
    id: string;
  };
}

/**
 * Creates a new Risk Recommendation by first creating a new Observation and then adding a new Recommendation to it.
 */
export const createRiskRecommendation = async ({
  input,
  organizationID,
  formattedRecommendationData,
  formattedRiskRecommendationData,
  selectedRiskControl,
}: CreateRiskRecommendationInput) => {
  return await createFullObservation({
    name: input.observation,
    recommendations: [
      {
        name: input.recommendation,
        rating: input.rating,
        module: modules.RISK,
        status: "unresolved",
        ownerGroup: organizationID,

        // structured version of the data
        riskRecommendationData: formattedRiskRecommendationData,

        // legacy: unstructured version of the data
        data: formattedRecommendationData, // Monte Carlo Data for recommendation goes here
      },
    ],
    module: modules.RISK,
    ownerGroup: organizationID,
    riskControlID: selectedRiskControl?.id,
    ...input,
  });
};
