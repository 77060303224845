"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableWidgetFields = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const deRootifyQueryObject_1 = require("../customQueries/deRootifyQueryObject");
const getFunctionFieldName_1 = require("../customQueries/getFunctionFieldName");
/**
 * Retrieves the fields that should be shown in the table widget and their order
 * @param {object} query - the query that populates the widget table
 * @param {object} seriesItem - series configuration created by the user
 * @returns {*[]}
 */
const getTableWidgetFields = ({ query, seriesItem }) => {
    // - column order
    let fields = [];
    const columns = seriesItem?.viewSettings?.columns;
    if (Array.isArray(columns)) {
        fields = columns.map((column) => column.name);
        //filter out the default actions column which houses buttons but doesn't contain useful data for widgets
        fields = fields.filter((field) => field !== "Actions");
    }
    else {
        const queryConfig = (0, func_utils_1.tryParse)(query?.queryConfig);
        const derootifiedQueryConfig = (0, deRootifyQueryObject_1.deRootifyQueryObject)({ query: queryConfig });
        const queryConfigFields = derootifiedQueryConfig?.fields;
        const queryConfigFunctions = derootifiedQueryConfig?.functions;
        if (typeof queryConfigFields === "object") {
            fields = Object.keys(queryConfigFields);
        }
        if (typeof queryConfigFunctions === "object") {
            for (const funcName in queryConfigFunctions) {
                const func = queryConfigFunctions[funcName];
                const finalFuncName = (0, getFunctionFieldName_1.getFunctionFieldName)({ pathElement: func });
                fields.push(finalFuncName);
            }
        }
    }
    // - column visibility
    const columnVisibilityModel = seriesItem?.viewSettings?.columnVisibilityModel;
    if (Array.isArray(fields) && typeof columnVisibilityModel === "object") {
        fields = fields.filter((field) => columnVisibilityModel[field] !== false);
    }
    return fields;
};
exports.getTableWidgetFields = getTableWidgetFields;
