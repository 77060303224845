import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Constructs the configuration object for the X-Axis of the Loss Tolerance Chart
 * See Syncfusion documentation for more info
 * @param ratingMap
 * @param max
 * @param residualRisk
 * @param chartConfig
 */
export const getXAxisConfig = (ratingMap, max, residualRisk, systemResidualLoss, chartConfig = {}) => {
  const opacity = 0.2;

  const stripLines = ratingMap
    ? [
        {
          start: 0,
          end: ratingMap?.low * max,
          text: "Low",
          visible: true,
          opacity: opacity,
          color: "green",
        },
        {
          start: ratingMap?.low * max,
          end: ratingMap?.lowMedium * max,
          text: "Low-Medium",
          visible: true,
          opacity: opacity,
          color: "yellow",
        },
        {
          start: ratingMap?.lowMedium * max,
          end: ratingMap?.medium * max,
          text: "Medium",
          visible: true,
          opacity: 0.3,
          color: "gold",
        },
        {
          start: ratingMap?.medium * max,
          end: ratingMap?.mediumHigh * max,
          text: "Medium-High",
          visible: true,
          opacity: opacity,
          color: "darkOrange",
        },
        {
          start: ratingMap?.mediumHigh * max,
          end: ratingMap?.high * max,
          text: "High",
          visible: true,
          opacity: opacity,
          color: "red",
        },
      ]
    : [];

  if (!isNullOrUndefined(residualRisk)) {
    stripLines.push({
      start: residualRisk,
      size: 1,
      sizeType: "Pixel",
      dashArray: "10,5",
      color: "red",
      text: "KRI Residual Risk",
      verticalAlignment: "Start",
      horizontalAlignment: "Start",
      textStyle: {
        marginLeft: "2em",
      },
    });
  }

  if (!isNullOrUndefined(systemResidualLoss)) {
    stripLines.push({
      start: systemResidualLoss,
      size: 1,
      sizeType: "Pixel",
      dashArray: "10,5",
      color: "red",
      text: "System Residual Risk",
      verticalAlignment: "Start",
      horizontalAlignment: "Start",
      textStyle: {
        marginLeft: "2em",
      },
    });
  }

  return {
    title: "Risk ($)",
    labelFormat: "c0",
    stripLines,
    minimum: 0,
    maximum: max,
  };
};
