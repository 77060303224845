"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableWidgetFieldNameDictionary = void 0;
/**
 * Returns a mapping of field names to their header names
 * @param {object} seriesItem - the widget series configuration
 * @returns {object}
 */
const getTableWidgetFieldNameDictionary = ({ seriesItem }) => {
    const fieldNameDictionary = {};
    const fieldSettings = seriesItem?.fieldSettings;
    if (!fieldSettings) {
        console.log("[getTableWidgetFieldNameDictionary] Invalid fieldSettings - ", { fieldSettings });
        return fieldNameDictionary;
    }
    for (const fieldName in fieldSettings) {
        const fieldSetting = fieldSettings[fieldName];
        fieldNameDictionary[fieldName] = fieldSetting?.headerName;
    }
    return fieldNameDictionary;
};
exports.getTableWidgetFieldNameDictionary = getTableWidgetFieldNameDictionary;
