export const gql_createRiskControlChangeLink = /* GraphQL */ `
  mutation CreateRiskControlChangeLink($input: CreateRiskControlChangeLinkInput!) {
    createRiskControlChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      riskControlID
      costChange
      implementationChange
    }
  }
`;
