import React from "react";

import { formattedDollar } from "@rivial-security/func-utils";
import { getImpactCost } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import UnderlinedTooltip from "../../../../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";

/**
 * Displays Confidentiality cost calculation for a System
 * Finds the 'Confidentiality Risk' number based on Information Assets (Cost Per Record x number of records)
 *
 * @param module
 * @param resource
 * @param system
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Confidentiality = ({ module = modules.RISK, resource = resources.INFORMATION_SYSTEM, system, ...props }) => {
  const field = "confidentialityPerRecord";

  return (
    <>
      <div style={{ display: "inline-block", marginRight: "1em" }}>
        <h7>Confidentiality:</h7>
      </div>
      <div style={{ display: "inline-block" }}>
        <UnderlinedTooltip
          tooltip={`Confidentiality represents the Impact risk of a breach of confidential data based on number of records`}
        >
          <strong>{formattedDollar(getImpactCost(system, field))}</strong>
        </UnderlinedTooltip>
      </div>
    </>
  );
};

export default withOrganizationCheck(Confidentiality);
