import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useForm } from "../../../hooks/views/useForm/useForm";
import { useModal } from "../../../hooks/views/useModal";
import CreateButton from "../../GenericComponents/CreateButton";
import Tag from "../components/Tag";

/**
 * @description Creates a new tag, using create tag component.
 * @param {function} callback - Callback function to be called after tag is created.
 * @param {function} resetFunction - Function to be called after tag is created, resets the form.
 * @param {function} mutation - The mutation to use.
 * @param {object} item - The item to create a tag for.
 * @param {string} organizationID - The organization ID to create the tag for.
 * @returns {{display: JSX.Element, setReset: (value: unknown) => void,
 * modalHook: {setModalIsOpen: function(*): void, modalButton: *,
 * modalIsOpen: boolean, modal: *, toggleModal: function(): void}}}
 */
export const useCreateTag = ({
  organizationID,
  item,
  mutation,
  resetFunction,
  toggleModal,
  callback,
  typename,
  getNewItem,
}) => {
  const { createMutation: createTag } = generateGraphql("Tag", ["name", "description", "fontColor", "backgroundColor"]);

  const formHook = useForm({
    typename,
    item,
    mutation: mutation || createTag,
    organizationID,
    getNewItem,
    disableRoleChecking: true, // temp
    fieldConfig: {
      name: {
        label: "Tag Name",
        required: true,
      },
      description: {
        label: "Description",
      },
      backgroundColor: {
        label: "Background Color",
        inputType: "color-picker",
      },
      fontColor: {
        label: "Font Color",
        inputType: "color-picker",
      },
    },
    callback: () => {
      toggleModal && toggleModal();
      if (typeof resetFunction === "function" && typeof getNewItem !== "function") {
        resetFunction();
      }
      callback && callback();
    },
  });

  const display = (
    <div>
      <h6>Tag Preview</h6>
      <Tag tag={{ id: "tag-preview", ...formHook.input }} isSelected={true} />
      <hr />
      {formHook.display}
    </div>
  );

  const modalHook = useModal("Create a new Tag", display, <CreateButton typename={"Tag"} />);

  return {
    display,
    modalHook,
  };
};
