import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateAction } from "../hooks/useCreateAction/useCreateAction";

/**
 * Creates a new Action Item
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateAction = (props) => {
  return useCreateAction(props).display;
};

export default withOrganizationCheck(CreateAction);
