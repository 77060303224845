import jsonLogic from "json-logic-js";

import { createRule, getMostRecentMetric } from "@rivial-security/metric-utils";
import { KpiMetricTypeLink } from "@rivial-security/schema-types";

/**
 * Evaluates rule logic for a single metricTypeLink
 *
 * @param metricTypeLink
 */
export const evaluateLogic = (metricTypeLink: KpiMetricTypeLink): string => {
  if (metricTypeLink === null) {
    throw Error("metricTypeLink input cannot be null");
  }

  if (metricTypeLink === undefined) {
    throw Error("metricTypeLink input cannot be undefined");
  }

  if (getMostRecentMetric(metricTypeLink.metricType!)) {
    const rule = createRule(metricTypeLink);
    const result = jsonLogic.apply(rule);

    switch (result) {
      case true:
        return "success";
      case false:
        return "failed";
      default:
        return "warning";
    }
  }

  return "warning";
};
