import React from "react";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import ReportModule from "../../../../../Reports/customFields/ReportModule";
import { useDocumentEditor } from "../../../../../Reports/DocumentEditor/hooks/useDocumentEditor";
import { useReportDataGrid } from "../../../../../Reports/hooks/useReportDataGrid";

/**
 * Allows the user to select a report to upload for Evidence
 * @param organizationID
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
const ReportEvidenceForm = ({ organizationID, onSubmit }) => {
  const documentEditor = useDocumentEditor({ organizationID });

  const gridConfig = {
    gridHeight: "55vh",
    allowPaging: false,
    fields: [
      {
        name: "name",
        flex: 1,
      },
      {
        name: "createdAt",
        inputType: "date",
        friendlyName: "Upload Date",
        width: "300",
      },
      {
        name: "module",
        width: "300",
        component: <ReportModule />,
      },
    ],
    createResourceComponent: documentEditor.display,
    createResourceComponentWidth: "80vw",
    enableQuickDetails: true,
    selectionSettings: {
      type: "Single",
    },
    enableSelectButton: true,
    onSelectCallback: (value) => {
      const activityInput = {
        type: EVIDENCE_ACTIVITY_TYPES.REPORT_UPLOAD,
        report: {
          id: value?.id,
          name: value?.name,
        },
      };
      onSubmit && onSubmit(activityInput);
    },
  };

  const cardConfig = {
    title: "Select a Report",
  };

  const reportGrid = useReportDataGrid({
    organizationID,
    gridConfig,
    cardConfig,
  });

  return (
    <div>
      <div style={{ height: "50em" }}>{reportGrid.display}</div>
    </div>
  );
};

export default ReportEvidenceForm;
