import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useVendorSolutionDetailsAccordion } from "../hooks/useVendorSolutionDetailsAccordion";

/**
 * Vendor Solution Details component. Used for details page and grid details
 * @param props
 * @returns {*}
 * @constructor
 */
const VendorSolutionDetailsAccordion = (props) => {
  return useVendorSolutionDetailsAccordion(props).display;
};

export default withDetailsPage(VendorSolutionDetailsAccordion);
