import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, ButtonGroup } from "reactstrap";
import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useStateEffect } from "../../../../../hooks/functional/useStateEffect";
import { LoadingSpinner } from "../../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * Returns the current state of the details bar.
 * @returns {import("../types/useDetailsBar").DetailsBarState} The details bar state object.
 */
export const useDetailsBar = () => {
  const [isOpen, setIsOpenFunc] = useState(false);
  const [selectedComponent, setSelectedComponentFunc] = useState(null);
  const [pinned, setPinned] = useState(false);
  const [config, setConfig] = useState({});
  const [fullWindow, setFullWindow] = useState(false);
  const [lockFullWindow, setLockFullWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [route, setRoute] = useState(null);

  /**
   * Booleans indicating if the list has a next or previous item
   */
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [adjustItemIndexFunc, setAdjustItemIndexFunc] = useState({});

  const [disableRefresh, setDisableRefresh] = useState(false);

  const [columnSize, setColumnSize] = useState(4);

  /**
   * This forces the details component to refresh when the selected item changes
   */
  const [componentKey] = useStateEffect("details-bar-initial-key", [selectedComponent], () => {
    return uuid();
  });

  useHandleLocationChange(setFullWindow, pinned);

  const setIsOpen = (val) => {
    if (!disableRefresh) {
      setIsOpenFunc(val);
    }

    if (lockFullWindow) {
      setFullWindow(val);
    }
  };

  const setSelectedComponent = (val) => {
    if (!disableRefresh) {
      setSelectedComponentFunc(val);
    }
  };

  const close = () => {
    setIsOpen(false);
    setPinned(false);
    setFullWindow(false);
  };

  const [queryRef, setQueryRef] = useState("");

  const resetFunction = () => queryRef?.reset?.();

  const setColSize = () => {
    if (columnSize === 6) setColumnSize(8);
    else if (columnSize === 8) setColumnSize(4);
    else if (columnSize === 4) setColumnSize(6);
    else setColumnSize(6);
  };

  const openRoute = () => {
    if (!isNullOrUndefined(route)) {
      window.open(route);
    }
  };

  const sizeButtons = (
    <>
      <Button
        data-testid={"button-expand-details-bar"}
        size="sm"
        color={"ghost-secondary"}
        onClick={() => setFullWindow((fullWindow) => !fullWindow)}
        style={{ marginRight: "1em" }}
        title={fullWindow ? "Collapse" : "Expand"}
      >
        <i className={fullWindow ? "icon-size-actual" : "icon-size-fullscreen"} style={{ margin: "0 0.5em" }} />
      </Button>
      {!fullWindow && (
        <Button
          data-testid={"button-adjust-column-details-bar"}
          size="sm"
          color={"ghost-secondary"}
          onClick={() => setColSize()}
          style={{ marginRight: "1em" }}
          title="Adjust Column Size"
        >
          <i className={"fa fa-columns"} style={{ margin: "0 0.5em" }} />
        </Button>
      )}
    </>
  );

  const closeButton = <Button data-testid={"button-close-details-bar"} close className="float-right" onClick={close} />;

  const header = (
    <div className="e-card-header">
      <div className="e-card-header-caption">
        <div className="dashboard-title e-card-title">
          <h5>
            <i className={config?.headerIcon || "icon-info"} style={{ marginLeft: "3px", marginRight: "0.75em" }} />
            {config?.title || "Details"}
            {closeButton}
          </h5>
          <span>
            <ButtonGroup size="sm">
              <Button
                data-testid={"button-previous-details-bar"}
                color="ghost-secondary"
                title={hasPrevious ? "View the Previous Item in the List" : "This is the first item in the list"}
                disabled={hasPrevious === false}
                style={{ borderRadius: "16px 0 0 16px" }}
                onClick={() => adjustItemIndexFunc && adjustItemIndexFunc.func(-1)}
              >
                <i className="icon-arrow-left" />
              </Button>
              <Button
                data-testid={"button-next-details-bar"}
                color="ghost-secondary"
                title={hasNext ? "View the Next Item in the List" : "This is the last item in the list"}
                disabled={hasNext === false}
                style={{ borderRadius: "0 16px 16px 0" }}
                onClick={() => adjustItemIndexFunc && adjustItemIndexFunc.func(1)}
              >
                <i className="icon-arrow-right" />
              </Button>
            </ButtonGroup>
            <Button
              data-testid={"button-pin-details-bar"}
              className="btn-pill"
              size="sm"
              color={pinned ? "secondary" : "ghost-secondary"}
              onClick={() => setPinned((currentlyPinned) => !currentlyPinned)}
              style={{ marginRight: "1em" }}
              title={pinned ? "Un-Pin this component" : "Pin this component"}
            >
              <i className="icon-pin" />
              {pinned && <> Pinned</>}
            </Button>
            {!fullWindow && sizeButtons}
            {!isNullOrUndefined(route) && (
              <Button
                data-testid={"button-open-route-details-bar"}
                className="btn-pill"
                size="sm"
                color={"ghost-secondary"}
                onClick={() => openRoute()}
                style={{ marginRight: "1em" }}
                title={`Open in new Tab\n\n${route}`}
              >
                <i className={"icon-share-alt"} style={{ margin: "0 0.5em" }} />
              </Button>
            )}
            {!isNullOrUndefined(queryRef) && queryRef !== "" && resetFunction && (
              <Button
                data-testid={"button-refresh-details-bar"}
                className="btn-pill"
                size="sm"
                color={"ghost-secondary"}
                onClick={() => resetFunction()}
                style={{ marginRight: "1em" }}
                title={!isLoading ? "Refresh Data" : "Loading"}
              >
                {isLoading ? <LoadingSpinner /> : <i className={"icon-refresh"} style={{ margin: "0 0.5em" }} />}
              </Button>
            )}
          </span>
        </div>
      </div>
    </div>
  );

  const display = (
    <div className="e-card-detailsbar e-card" key={componentKey} style={{ height: "100vh" }}>
      {header}
      <div className="e-card-content" style={{ height: "100%" }}>
        {selectedComponent
          ? React.cloneElement(selectedComponent, { setQueryRef, setIsLoading })
          : "Nothing to see here.."}
      </div>
    </div>
  );

  return {
    isOpen,
    setIsOpen,
    selectedComponent,
    setSelectedComponent,
    display,
    pinned,
    setPinned,
    config,
    setConfig,
    fullWindow,
    setFullWindow,
    setHasNext,
    setHasPrevious,
    disableRefresh,
    setDisableRefresh,
    columnSize,
    setColumnSize,
    setLockFullWindow,
    lockFullWindow,
    isLoading,
    setIsLoading,
    route,
    setRoute,
    adjustItemIndexFunc,
    setAdjustItemIndexFunc,
    header,
    sizeButtons,
    closeButton,
  };
};

/**
 * A helper hook for closing the full window mode when the app route changes
 * @param {function} setFullWindow - function for setting the fullWindow state
 * @param {boolean} pinned - if pinned, don't close the component
 */
export const useHandleLocationChange = (setFullWindow, pinned = false) => {
  /**
   * Helper function that cancels Full Window when the route changes, unless Pinned.
   */
  const handleLocationChange = () => {
    if (!pinned) {
      setFullWindow(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    return handleLocationChange();
  }, [location]);
};
