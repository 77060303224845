export const gql_createSystemChangeLink = /* GraphQL */ `
  mutation CreateSystemChangeLink($input: CreateSystemChangeLinkInput!) {
    createSystemChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      systemID
    }
  }
`;
