import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { mergeAdditionalFields } from "@hooks/views/useGrid/functions/mergeAdditionalFields";

import { getResourceIcon } from "../../../../definitions/functions/getResourceIcon";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import {
  VULNERABILITY_SEVERITY_LEVEL,
  VULNERABILITY_TYPE,
} from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import DateFilterMenu from "../../../../utils/FilterMenus/DateFilterMenu";
import PriorityFilterMenu from "../../../../utils/FilterMenus/PriorityFilterMenu";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import CreateVulnerability from "../components/CreateVulnerability";
import VulnerabilityDetails from "../components/VulnerabilityDetails";
import { VulnerabilityStatus as VulnerabilityStatusEnum } from "../constants/VulnerabilityStatus";
import { getAssessmentsBinnedByVulnerability } from "../customFields/getAssessmentsBinnedByVulnerability";
import { getTargetsBinnedByVulnerability } from "../customFields/getTargetsBinnedByVulnerability";
import LinkedAssessmentsStats from "../customFields/LinkedAssessmentsStats";
import VulnerabilityTargets from "../customFields/VulnerabilityGroupTargets/VulnerabilityTargets";
import VulnerabilityPriority from "../customFields/VulnerabilityPriority/VulnerabilityPriority";
import VulnerabilityRawData from "../customFields/VulnerabilityRawData";
import { VulnerabilitySeverityLevel } from "../customFields/VulnerabilitySeverityLevel";
import { VulnerabilityStatusCustomField } from "../customFields/VulnerabilityStatusCustomField";
import { VulnerabilityType } from "../customFields/VulnerabilityType";
import { deleteVulnerability } from "../functions/deleteVulnerability";
import { normalizeVulnerabilityData } from "../functions/normalizeVulnerabilityData";
import { vulnerabilityStatusValueGetter } from "../functions/vulnerabilityStatusValueGetter";
import { vulnerabilityTargetValueGetter } from "../functions/vulnerabilityTargetValueGetter";

/**
 * Data grid for displaying ungrouped vulnerabilities
 * @param {string} organizationID - currently selected organization ID
 * @param {object} vulnerabilityGridConfig - configuration for the vulnerability grid
 * @param {object[]} additionalFields - additional fields to display in grid (added to the end)
 * @param {object} props - any properties to pass down to the useDataGrid hook
 * @param {ReactElement} createFormFooterPrefix - prefix for the create form footer
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object[]|Object, setData: function(*): void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useVulnerabilityDataGrid = ({
  organizationID,
  additionalFields,
  vulnerabilityGridConfig,
  createFormFooterPrefix,
  ...props
}) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.VULNERABILITY;
  const typename = "Vulnerability";
  const icon = getResourceIcon({ typename });

  const { updateMutation, listByQuery } = generateGraphql(
    typename,
    [
      "createdAt",
      "name",
      "type",
      "severityLevel",
      "ports",
      "protocols",
      "cves",
      "cvss",
      "pluginID",
      "impact",
      "solution",
      "solutionType",
      "summary",
      "exploitAvailable",
      "pluginID",
      "manualPriority",
      "targets",
    ],
    {
      targets: `(limit: 1000) {
         items {
          id createdAt status priority firstSeen lastSeen targetID vulnerabilityID
         }
       }`,
    },
    {
      indexName: "vulnerabilitiesByOwnerGroup",
      partitionKey: "ownerGroup",
    },
  );

  const gridFields = [
    {
      name: "name",
      description:
        "Primary key for the vulnerability resource information from all vulnerabilities named the same is merged into one item.",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 2,
      },
      disableEdits: true,
    },
    {
      name: "type",
      flex: 0.4,
      filter: {
        type: "CheckBox",
      },
      type: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      component: <VulnerabilityType />,
      valueOptions: enumToDropdownData({
        ENUM: VULNERABILITY_TYPE,
        valueFieldName: "value",
        textFieldName: "label",
      }),
      visible: false,
      bulkEdit: true,
    },
    {
      name: "priority",
      description:
        "An automatically determined importance value to fix this vulnerability. Can be overwritten by a manual value.",
      flex: 0.3,
      type: GENERIC_FIELD_TYPES.NUMERIC,
      filterTemplate: (props) => <PriorityFilterMenu props={props} />,
      component: <VulnerabilityPriority />,
      sort: {
        direction: "desc",
        priority: 1,
      },
      valueGetter: (_value, row) => {
        return row?.manualPriority || row?.priority;
      },
    },
    {
      name: "status",
      description:
        "An overview of the status of the vulnerability as shown by the percentage of target's that have it fixed.",
      flex: 0.3,
      filter: {
        type: "CheckBox",
      },
      component: <VulnerabilityStatusCustomField />,
      disableEdits: true,
      type: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      valueOptions: enumToDropdownData({
        ENUM: VulnerabilityStatusEnum,
        valueFieldName: "value",
        textFieldName: "label",
      }),
      sortComparator: (a, b, paramA, paramB) => {
        const notFixedPercentA = paramA.api.getRow(paramA.id)?.vulnerabilityStats?.notFixedPercent;
        const notFixedPercentB = paramB.api.getRow(paramB.id)?.vulnerabilityStats?.notFixedPercent;
        return notFixedPercentA - notFixedPercentB;
      },
      valueGetter: vulnerabilityStatusValueGetter,
      searchValueGetter: vulnerabilityStatusValueGetter,
    },
    {
      name: "severityLevel",
      flex: 0.3,
      filter: {
        type: "CheckBox",
      },
      component: <VulnerabilitySeverityLevel />,
      bulkEdit: true,
      valueOptions: Object.values(VULNERABILITY_SEVERITY_LEVEL),
      sortComparator: (a, b) => {
        const status = {
          high: 1,
          medium: 2,
          low: 3,
          info: 4,
        };
        return status[a] - status[b];
      },
      visible: false,
    },
    {
      name: "ports",
      flex: 0.3,
      disableEdits: true,
      plainText: true,
      valueGetter: (_value, row) => {
        return row?.ports?.join(", ");
      },
      visible: false,
    },
    {
      name: "protocols",
      flex: 0.3,
      plainText: true,
      valueGetter: (_value, row) => {
        return row?.protocols?.join(", ");
      },
      disableEdits: true,
      visible: false,
    },
    {
      name: "cves",
      flex: 0.3,
      plainText: true,
      valueGetter: (_value, row) => {
        return row?.cves?.join(", ");
      },
      disableEdits: true,
      visible: false,
    },
    {
      name: "cvss",
      type: GENERIC_FIELD_TYPES.NUMERIC,
      inputConfig: {
        min: 0,
        max: 10,
        step: 0.1,
      },
      flex: 0.3,
      visible: false,
      bulkEdit: true,
    },
    {
      name: "impact",
      flex: 0.3,
      visible: false,
    },
    {
      name: "exploitAvailable",
      type: GENERIC_FIELD_TYPES.SWITCH,
      flex: 0.3,
      visible: false,
      filter: {
        type: "CheckBox",
      },
      bulkEdit: true,
    },
    {
      name: "pluginID",
      flex: 0.3,
      disableEdits: true,
      visible: false,
    },
    {
      name: "solutionType",
      width: 100,
      visible: false,
      filter: {
        type: "CheckBox",
      },
      bulkEdit: true,
    },
    {
      name: "solution",
      width: 200,
      visible: false,
      bulkEdit: true,
    },

    {
      name: "firstSeen",
      width: 120,
      minWidth: 100,
      filterTemplate: (props) => <DateFilterMenu props={props} />,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: { dateOnly: true },
      displayConfig: { dateOnly: true },
      sort: {
        direction: "desc",
      },
      disableEdits: true,
      description: "The first time this vulnerability has been spotted on one of its associated targets",
    },
    {
      name: "lastSeen",
      width: 120,
      minWidth: 100,
      filterTemplate: (props) => <DateFilterMenu props={props} />,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: { dateOnly: true },
      displayConfig: { dateOnly: true },
      sort: {
        direction: "desc",
      },
      disableEdits: true,
      description: "The last time this vulnerability has been spotted on one of its associated targets",
    },
    {
      name: "targetsCustom",
      friendlyName: "Targets",
      disableEdits: true,
      valueGetter: vulnerabilityTargetValueGetter,
      searchValueGetter: vulnerabilityTargetValueGetter,
      component: <VulnerabilityTargets />,
      visible: false,
    },
    {
      name: "assessments",
      flex: 0.3,
      minWidth: 150,
      visible: false,
      component: <LinkedAssessmentsStats />,
      sortComparator: (assessmentA, assessmentB) => {
        return assessmentA?.length - assessmentB?.length;
      },
      disableEdits: true,
    },
    {
      name: "rawData",
      component: <VulnerabilityRawData />,
      description:
        "Tabular scan data for this vulnerability containing relational information on individual IP addresses, ports, protocols and CVE entries.",
      visible: false,
      disableEdits: true,
    },
  ];
  mergeAdditionalFields({ additionalFields, fields: gridFields });

  return useDataGrid({
    organizationID,
    module,
    resource,
    typename: "Vulnerability",
    fields: gridFields,
    icon,
    title: "Security Vulnerabilities",
    route: "#/vulnerabilities/vulnerabilities/",
    detailsComponent: <VulnerabilityDetails />,
    createResourceButtonText: vulnerabilityGridConfig?.createResourceButtonText,
    createItemModalHeader: vulnerabilityGridConfig?.createItemModalHeader || "Create a Vulnerability",
    createResourceComponent: vulnerabilityGridConfig?.createResourceComponent || (
      <CreateVulnerability footerPrefix={createFormFooterPrefix} />
    ),
    createResourceComponentWidth: "80vw",
    query: listByQuery,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    normalizeData: (data) => {
      return normalizeVulnerabilityData({
        vulnerabilities: data,
        calculatePriority: true,
      });
    },
    updateMutation,
    persistenceUUID: "data-grid-vulnerability",
    deleteFunction: vulnerabilityGridConfig?.deleteFunction || deleteVulnerability,

    deleteFunctionNote: "This will delete all data related to this vulnerability as grouped by its name.",
    deleteOptions: {
      deleteObservations: {
        label: "Delete Observations, Recommendations, and Action Items",
        tooltip:
          "This will try to delete all Observations, Recommendations, and Action Items associated with this Vulnerability. If any of these items are linked to other Vulnerabilities, they will not be deleted.",
        defaultValue: true,
        inputType: "switch",
      },
    },
    customFields: [
      {
        name: "targetsCustom",
        type: "query",
        getData: getTargetsBinnedByVulnerability,
      },
      {
        name: "assessments",
        type: "query",
        getData: getAssessmentsBinnedByVulnerability,
      },
    ],
    options: ["delete", "edit"],
    ...props,
  });
};
