import { formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Returns information on whether the risk curve is above the loss tolerance threshold.
 * @param {number} lossToleranceDelta - the difference between the loss tolerance and the risk curve (delta = risk - tolerance)
 * @param {boolean} systemResidualLossShown - TRUE if the system residual loss is shown (modifies wording)
 * @return {{type: string, message: string} || undefined}
 */
export const generateRiskCurveWarning = ({ lossToleranceDelta, systemResidualLossShown = false }) => {
  //Check arguments
  if (isNullOrUndefined(lossToleranceDelta)) {
    return;
  }

  //Format the delta value for the result string
  const stringDelta = formattedDollar(Math.abs(lossToleranceDelta));

  const lossToleranceCurve = "risk tolerance curve";
  let residualRiskCurve = "residual risk curve";
  if (systemResidualLossShown === true) {
    residualRiskCurve = `system ${residualRiskCurve}`;
  }

  //Generate the result string
  if (lossToleranceDelta < 0) {
    return {
      type: "success",
      message: `The average acceptable annual risk defined by the ${lossToleranceCurve}
      was not exceeded by the average annual risk simulated by the ${residualRiskCurve}.
      The average is lower by ${stringDelta}.`,
    };
  } else {
    return {
      type: "warning",
      message: `The average acceptable annual risk defined by the ${lossToleranceCurve}
      was exceeded by the average annual risk simulated by the ${residualRiskCurve}.
      The average is higher by ${stringDelta}.`,
    };
  }
};
