import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { useChecklist } from "../../../../../hooks/views/useChecklist/useChecklistV2";

/**
 * A task's checklist, items can be modified and checked off.
 * @param {object} task - the task data from database
 * @param {function} setTask - function to update the task state (also updates on the backend with debounce)
 * @param {boolean} enableEdits - TRUE if user has selected to edit the task work with a switch
 * @returns {JSX.Element}
 */
const ChecklistTaskWork = ({ task, setTask, enableEdits }) => {
  const [checklistGroup, setChecklistGroup] = useState(null);
  useEffect(() => {
    //Checklist groups can be supported in the future, for now a single checklist is supported
    const checklistGroups = task?.checklist?.groups;
    if (isNonEmptyArray(checklistGroups)) {
      setChecklistGroup(checklistGroups[0]);
    } else {
      setTask({
        ...(task || {}),
        checklist: {
          groups: [
            {
              id: uuid(),
              name: "Main List",
            },
          ],
        },
      });
    }
  }, [task]);

  const checklist = useChecklist({
    item: checklistGroup,
    field: "items",
    updateFunction: (value) => {
      setTask({
        ...(task || {}),
        checklist: {
          groups: [
            {
              ...checklistGroup,
              items: value,
            },
          ],
        },
      });
    },
    enableCheckbox: true,
    disableEdits: !enableEdits,
    checkboxFieldName: "completed",
    textFieldName: "content",
    enableNotes: true,
    stepButtonName: "Todo Item",
    disableRoleChecking: true,
  });

  return checklist.display;
};

export default ChecklistTaskWork;
