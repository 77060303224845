import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import React, { useContext, useEffect, useState } from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { InfoLogger } from "@utils/EventLogger";

import { UIContext } from "../../../../utils/Context/UIContext";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { useForm } from "../../useForm";
import { useModal } from "../../useModal";
import { STEP_STATUS, usePleaseWaitModal } from "../../usePleaseWaitModal";
import { useTable } from "../../useTable";

/**
 * Handles deletion of one or multiple items in the DataGrid
 *
 * Displays a 'Safe Delete' prompt, with a preview of all items that are being deleted.
 * Shows progress UI, then handles the DataGrid state update to remove items
 *
 * @param {object[]} selectedData - the array of selected data, each item is passed to the deleteFunction
 * @param {string} deleteButtonText - the word used to explain the process, usually is 'delete' or could be 'unlink'
 * @param {function} deleteFunction - an async function that handles deletion of each selectedData item
 * @param {object} deleteOptions - object matching useForm fieldConfig to pass as options to the
 * @param {function} [deleteCallback] - optional function to call once item is already deleted (takes in {item})
 * @param {function} resetFunction - for resetting the parent data state when complete
 * @param {string} deleteFunctionNote - a note to display above the 'Safe Delete' prompt
 * @param {boolean} deleteFunctionWithoutQueue - if TRUE will not display the toast informing users that the item will be deleted as part of a queue
 * @param {function} onDeleteCallback - do custom logic after delete function
 * @param {string} primaryField - the field to display in the preview items to be deleted
 * @param {string} typename - the typename of the items being deleted. Just used for display purposes.
 */
export const useDataGridDeleteItems = ({
  selectedData,
  deleteFunction,
  deleteButtonText = "Delete",
  deleteOptions,
  resetFunction,
  deleteFunctionNote,
  deleteFunctionWithoutQueue,
  onDeleteCallback,
  primaryField = "name",
  typename = "Item",
}) => {
  const modal = useModal(
    `${deleteButtonText} Selected ${pluralize(convertCamelCaseToSentence(typename), selectedData?.length)}`,
    <ModalBody
      selectedData={selectedData}
      deleteButtonText={deleteButtonText}
      deleteFunction={deleteFunction}
      deleteOptions={deleteOptions}
      resetFunction={resetFunction}
      onDeleteCallback={onDeleteCallback}
      deleteFunctionNote={deleteFunctionNote}
      deleteFunctionWithoutQueue={deleteFunctionWithoutQueue}
      primaryField={primaryField}
      typename={typename}
    />,
  );

  return {
    modal,
  };
};

/**
 * Handles the Deletion process and UI for selected Items
 * @param {object[]} selectedData - the array of selected data, each item is passed to the deleteFunction
 * @param {string} deleteButtonText - the word used to explain the process, usually is 'delete' or could be 'unlink'
 * @param {function} deleteFunction - an async function that handles deletion of each selectedData item
 * @param {object} deleteOptions - object matching useForm fieldConfig to pass as options to the deleteFunction
 * @param {function} toggleModal - for toggling an external modal when complete
 * @param {function} resetFunction - for resetting the parent data state when complete
 * @param {function} onDeleteCallback - do custom logic after delete function
 * @param {string} deleteFunctionNote - a note to display above the 'Safe Delete' prompt
 * @param {boolean} deleteFunctionWithoutQueue - if TRUE will not display the toast informing users that the item will be deleted as part of a queue
 * @param {string} primaryField - the field to display in the preview items to be deleted
 * @param {string} typename - the typename of the items being deleted. Just used for display purposes.
 * @returns {JSX.Element}
 */
const ModalBody = ({
  selectedData,
  deleteButtonText,
  deleteFunction,
  deleteOptions,
  toggleModal,
  resetFunction,
  deleteFunctionNote,
  deleteFunctionWithoutQueue,
  onDeleteCallback,
  primaryField = "name",
  typename = "Item",
}) => {
  const { addToast, updateToast } = useContext(UIContext);
  const [deleteOptionsInput, setDeleteOptionsInput] = useState(null);

  const pleaseWaitModal = usePleaseWaitModal({
    progressTotal: Array.isArray(selectedData) ? selectedData.length : 0,
    steps: [],
    autoClose: true,
    autoCloseInterval: 2,
    subTitle: `Processing Selected ${pluralize(convertCamelCaseToSentence(typename), selectedData?.length)}..`,
    toggleModal,
    resetFunction,
  });

  useEffect(() => {
    if (Array.isArray(selectedData)) {
      pleaseWaitModal.setSteps(
        selectedData.map((item, index) => {
          return {
            id: item?.id || index,
            text: item?.[primaryField] || item?.name || item?.id,
            status: STEP_STATUS.WAITING,
          };
        }),
      );
      pleaseWaitModal.setTotalProgress(selectedData.length);
    }
  }, [selectedData]);

  /**
   * List of typename(s) that should be added to the queue for deletion
   * Note: When we move all the delete functions to the queue, we can remove this
   */
  const TypenameForQueueArray = [
    "Assessment",
    "Target",
    "Vulnerability",
    "Observation",
    "RecommendationItem",
    "GenericActionItem",
    "Questionnaire",
    "QuestionnaireAssignee",
  ];

  const handleDelete = async () => {
    pleaseWaitModal.setModalIsOpen(true);

    /**
     * Use queue for deleting specific items from TypenameForQueueArray
     */
    const typenameForQueueFound = TypenameForQueueArray.includes(typename);
    if (typenameForQueueFound && deleteFunctionWithoutQueue !== true) {
      const niceTypename = pluralize(convertCamelCaseToSentence(typename), selectedData?.length);
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          for (let i = 0; i < selectedData.length; i++) {
            if (typeof deleteFunction === "function") {
              pleaseWaitModal.setStepStatus(i, STEP_STATUS.IN_PROGRESS);
              deleteFunction(selectedData[i], deleteOptionsInput)
                .then(() => {
                  InfoLogger(`Added ${niceTypename} to queue: ${selectedData?.[i]?.id}`);
                })
                .catch((e) => {
                  Sentry.captureException(e);
                  pleaseWaitModal.setStepStatus(i, STEP_STATUS.ERROR);
                });

              pleaseWaitModal.setStepStatus(i, STEP_STATUS.COMPLETE);
              pleaseWaitModal.incrementProgress();
            }
          }
        },
        inProgressText: `Adding ${niceTypename} to queue for deletion...`,
        successText: `Successfully added ${niceTypename} to queue for deletion,
        please note that it may take a few minutes for the queue to process.`,
        failedText: `Failed to add ${niceTypename} to queue`,
        iconColor: "success",
        timeoutDuration: 10,
      });
    } else {
      /**
       * Normal workflow for deleting items
       */
      for (let i = 0; i < selectedData.length; i++) {
        try {
          pleaseWaitModal.setStepStatus(i, STEP_STATUS.IN_PROGRESS);

          // run the delete function for this item, pass the options if they exist
          await deleteFunction(selectedData[i], deleteOptionsInput);

          pleaseWaitModal.setStepStatus(i, STEP_STATUS.COMPLETE);
        } catch (e) {
          Sentry.captureException(e);
          pleaseWaitModal.setStepStatus(i, STEP_STATUS.ERROR);
        } finally {
          pleaseWaitModal.incrementProgress();
        }
      }
    }

    pleaseWaitModal.setFinished(true);

    if (typeof onDeleteCallback === "function") {
      onDeleteCallback({ items: selectedData });
    }
  };

  return (
    <div>
      {Array.isArray(selectedData) && selectedData.length > 0 ? (
        <div>
          {deleteFunctionNote && <h6>{deleteFunctionNote}</h6>}
          Are you sure you want to {deleteButtonText}{" "}
          {pluralize(convertCamelCaseToSentence(typename), selectedData?.length, true)}?
          {deleteOptions && (
            <div style={{ marginTop: "2em" }}>
              <DeleteOptions deleteOptions={deleteOptions} setDeleteOptionsInput={setDeleteOptionsInput} />
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            <Button data-testid={"confirm-grid-delete-button"} onClick={handleDelete} style={{ marginTop: "2em" }}>
              Yes
            </Button>
          </div>
          <hr />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Preview</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <SelectedItemsPreview selectedData={selectedData} primaryField={primaryField} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        `There are no selected ${pluralize(
          convertCamelCaseToSentence(typename),
          selectedData?.length,
        )} to ${deleteButtonText}`
      )}
      {pleaseWaitModal.modal}
    </div>
  );
};

/**
 * Renders a form with the deleteOptions fields. Input is passed as config options to the deleteFunctions
 * @param {object} deleteOptions - object matching useForm fieldConfig to pass as options to the deleteFunction
 * @param {function} setDeleteOptionsInput - state handler for passing the form input up
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteOptions = ({ deleteOptions = {}, setDeleteOptionsInput }) => {
  const form = useForm({
    fieldConfig: {
      ...deleteOptions,
    },
    onChange: setDeleteOptionsInput,
    disableRoleChecking: true,
    disableSubmitButton: true,
    disableResetButton: true,
  });

  return form.display;
};

/**
 * Small UI for displaying a preview of selected data in a simple table
 * @param {object[]} selectedData - the array of selected data, each item is passed to the deleteFunction
 * @param {string} [primaryField = "name"] - the field to display in the preview items to be deleted
 * @returns {JSX.Element}
 * @constructor
 */
const SelectedItemsPreview = ({ selectedData, primaryField = "name" }) => {
  const nameField = typeof primaryField === "string" ? primaryField : "name";

  return useTable({
    fields: ["id", nameField],
    data: selectedData,
    disableRoleChecking: true,
  }).display;
};
