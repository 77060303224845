import { v4 as uuid } from "uuid";

import { convertCamelCaseToSentence, formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { ARTIFACT_STATUS } from "../../../../Artifact/ArtifactValidation/constants/ARTIFACT_STATUS";

/**
 * Creates a new artifact for an evidence
 * @param {string} organizationID - the currently selected organization
 * @param {object} evidenceActivity - the evidence activity that this artifact is being created for
 * @param {object} evidence - the evidence that this artifact is under
 * @param {object} pointOfContact - the point of contact submitting this evidence (local to the organization)
 * @param {string} artifactType - the type of artifact being created
 * @returns {Promise<void>}
 */
export const createEvidenceArtifact = async ({
  organizationID,
  evidenceActivity,
  evidence,
  pointOfContact,
  artifactType,
}) => {
  const { createMutation: createArtifactMutation } = generateGraphql("Artifact", ["id", "name", "status", "type"]);

  return await ItemMutation(createArtifactMutation, {
    name: `${new Date().toLocaleDateString()} - ${`${convertCamelCaseToSentence(artifactType)} - `}${
      formattedName({ pointOfContact }) || "Guest User"
    }`,
    status: ARTIFACT_STATUS.PENDING_VALIDATION,
    type: artifactType,
    ownerGroup: organizationID,
    notes: [
      {
        id: uuid(),
        content: `<p>
            ${evidenceActivity?.comment || "Artifact submitted without any user comments"}
          </p>`,
        author: pointOfContact?.email || "Rivial Platform User",
        ownerGroup: organizationID,
        timeStamp: new Date(),
      },
    ],
    evidenceActivityID: evidenceActivity?.id,
    evidenceID: evidence?.id,
  }).then(async (newArtifact) => {
    /**
     * Create a connection between the evidence activity and the new artifact
     */
    if (evidenceActivity?.id) {
      await ItemMutation(generateGraphql("EvidenceActivity").updateMutation, {
        id: evidenceActivity?.id,
        activityArtifactID: newArtifact?.id,
      });
    }

    return newArtifact;
  });
};
