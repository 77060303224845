import React from "react";

import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { useTransferList } from "../../../../hooks/views/useTransferList/useTransferList";
import { getMany } from "../../../../utils/Functions/getMany";
import { useCreateControl } from "../../Controls/Controls/hooks/useCreateControl/useCreateControl";
import { useControlSetDropdown } from "../../Controls/ControlSets/hooks/useControlSetDropdown";
import { updateControlOverrideType } from "../../Evidence/enums/updateControlOverrideType";
import { submitRiskComplianceSyncJob } from "../../Evidence/functions/submitRiskComplianceSyncJob";
import { handleLinkingControls } from "../functions/handleLinkingControls";
import { handleUnlinkingControls } from "../functions/handleUnlinkingControls";

import { usePopulateEvidenceControls } from "./usePopulateEvidenceControls";
import { usePopulateExistingControls } from "./usePopulateExistingControls";

/**
 * Allows the user to link Controls to an Evidence
 * @param props
 * @param {string} organizationID - the org id
 * @param {Evidence} evidence - the Evidence being linked to
 * @param {function} [resetFunction] - function handler for refreshing data after operation
 * @param {function} [toggleModal] - function handler for toggling modal after operation
 */
export const useEvidenceControlLinking = ({ organizationID, evidence, resetFunction, toggleModal }) => {
  const createControlCallback = (item) => {
    transferList.setRightItems((notLinked) => [...notLinked, item]);
    transferList.createResourceModal.setModalIsOpen(false);
  };

  const createControlHook = useCreateControl({
    organizationID,
    getNewItem: createControlCallback,
  });

  const pleaseWait = usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 1,
    confirmationText: "Congratulations, you've successfully Linked and Unlinked Controls!",
    steps: [
      {
        id: "unlink",
        text: "Unlinking Controls",
      },
      {
        id: "link",
        text: "Linking Controls",
      },
    ],
  });

  /**
   * Performs the operation that links and unlinks items
   */
  const handleSubmit = async () => {
    pleaseWait.setModalIsOpen(true);
    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    await handleUnlinkingControls(transferList.rightItems, evidence);

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    await handleLinkingControls(transferList.leftItems, evidence, organizationID);

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setFinished(true);
    resetFunction && resetFunction();
    toggleModal && toggleModal();
    // setIsSubmitEnabled(false)

    //Combine all the risk controls to check (duplicates removed automatically)
    const previousControls = getMany(evidence, "controls", "control");
    let controlsToCheck = transferList?.leftItems ?? [];
    controlsToCheck = controlsToCheck.concat(previousControls ?? []);
    submitRiskComplianceSyncJob({
      evidenceID: evidence?.id,
      organizationID,
      controlOverrides: controlsToCheck,
      overrideType: updateControlOverrideType.GENERAL_LINKS_UPDATED,
    });
  };

  const transferList = useTransferList({
    handleSubmit,
    leftTitle: "Linked Controls",
    rightTitle: "Available Controls",
    createResourceComponent: createControlHook.display,
    listTitleAdapter: (control) => `${control?.statementNumber}: ${control?.name}`,
    typename: "Control",
  });

  const controlSetDropdown = useControlSetDropdown({ organizationID });

  const controlsOrganizationID = organizationID;

  usePopulateExistingControls(
    evidence,
    transferList.setRightItems,
    controlsOrganizationID,
    controlSetDropdown.selectedControlSetId,
  );

  usePopulateEvidenceControls(evidence, transferList.setLeftItems, controlSetDropdown.selectedControlSetId);

  const display = (
    <span>
      <div style={{ marginBottom: "1em" }}>{controlSetDropdown.display}</div>
      <div>
        {pleaseWait.modalIsOpen && pleaseWait.modal}
        {transferList.display}
      </div>
    </span>
  );

  return {
    display,
  };
};
