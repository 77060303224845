"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.qualysScannerFields = void 0;
exports.qualysScannerFields = [
    {
        displayName: "Name",
        resourceFieldName: "name",
        defaultCSVFieldNames: ["Title"],
    },
    {
        displayName: "Type",
        resourceFieldName: "type",
        interpreterDescription: "This field should represent the entry type, if the field value is not 'Vuln' or 'Practice' the platform type will be marked as informational.",
    },
    {
        displayName: "Severity Level",
        resourceFieldName: "severityLevel",
        interpreterDescription: `This field should represent the severity level, scanner values ranging from 1 to 5 will be mapped to platform severity levels.`,
    },
    {
        displayName: "Port",
        resourceFieldName: "port",
        defaultCSVFieldNames: ["Port"],
    },
    {
        displayName: "Protocol",
        resourceFieldName: "protocol",
        defaultCSVFieldNames: ["Protocol"],
    },
    {
        displayName: "CVE",
        resourceFieldName: "cve",
        defaultCSVFieldNames: ["CVE ID"],
    },
    {
        displayName: "CVSS",
        resourceFieldName: "cvss",
        defaultCSVFieldNames: ["CVSS"],
    },
    {
        displayName: "Impact",
        resourceFieldName: "impact",
        defaultCSVFieldNames: ["Impact"],
    },
    {
        displayName: "Solution",
        resourceFieldName: "solution",
        defaultCSVFieldNames: ["Solution"],
    },
    {
        displayName: "Summary",
        resourceFieldName: "summary",
        defaultCSVFieldNames: ["Results"],
    },
    {
        displayName: "Host Name",
        resourceFieldName: "hostName",
        defaultCSVFieldNames: ["DNS"],
    },
    {
        displayName: "IP",
        resourceFieldName: "ip",
        defaultCSVFieldNames: ["IP"],
    },
];
