import { Chip } from "@mui/material";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Custom field displaying whether the KRI for which a control was changed impacts the system risk
 * @param {object} systemRiskValues - the system risk values object from the system calculation object
 * @return {JSX.Element}
 */
const KRIChangeImpactsSystemRisk = ({ systemRiskValues }) => {
  //Calculate whether the KRI impacts the system residual risk
  const {
    greatestKeyRiskIndicator,
    greatestRecommendationKeyRiskIndicator,
    residualRisk,
    recommendationResidualRisk,
    modifiedKeyRiskIndicator,
  } = systemRiskValues || {};

  let chip = { label: "N/A", color: "info" };
  const systemResidualRiskChanged =
    !isNullOrUndefined(recommendationResidualRisk) && residualRisk !== recommendationResidualRisk;

  const modifiedKRISameAsOriginalGreatestKRI =
    modifiedKeyRiskIndicator && modifiedKeyRiskIndicator === greatestKeyRiskIndicator;

  const modifiedKRISameAsRecommendationGreatestKRI =
    modifiedKeyRiskIndicator && modifiedKeyRiskIndicator === greatestRecommendationKeyRiskIndicator;

  if (
    systemResidualRiskChanged &&
    (modifiedKRISameAsOriginalGreatestKRI || modifiedKRISameAsRecommendationGreatestKRI)
  ) {
    if (modifiedKRISameAsOriginalGreatestKRI && modifiedKRISameAsRecommendationGreatestKRI) {
      chip = { label: "Yes", color: "success" };
    } else {
      chip = { label: "Partially", color: "warning" };
    }
  } else {
    chip = { label: "No", color: "error" };
  }

  return <Chip color={chip.color} label={chip.label} size={"small"} />;
};

export default KRIChangeImpactsSystemRisk;
