import React from "react";

import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import { useCustomWidgetDetails } from "../../WidgetDetails/hooks/useCustomWidgetDetails";

import WidgetPreview from "./ChartWidgetPreview";
import RenderTableWidget from "./RenderTableWidget";

/**
 * Renders the Custom widget for the React frontend based on the type of widget.
 *
 * - Table Widgets get rendered with useDataGrid
 * - Pie Chart Widgets get rendered with usePieChart
 * - Bar Chart Widgets get rendered with useBarChart
 * - Static Image Widgets get rendered with rich text viewer
 * - Dynamic Text Widgets get rendered with rich text viewer
 *
 * @param {string} itemId - the custom widget ID
 * @param {string} organizationID - the organization ID
 * @param {boolean} autoRender - whether to auto render the widget
 * @param {string} persistenceUUID - the persistence UUID for the widget
 * @param {string} singleViewType - the single view type for the widget, without allowing the user to switch
 * @returns {JSX.Element}
 */
const RenderCustomWidget = ({ itemId, organizationID, autoRender, persistenceUUID, singleViewType }) => {
  const details = useCustomWidgetDetails({
    itemId,
    organizationID,
  });

  let widget;
  const widgetType = details?.item?.type;
  switch (widgetType) {
    case WIDGET_TYPE.TABLE:
      widget = (
        <RenderTableWidget
          item={details.item}
          autoRender={autoRender}
          title={details?.item?.name}
          persistenceUUID={persistenceUUID}
          organizationID={organizationID}
          singleViewType={singleViewType}
        />
      );
      break;
    case WIDGET_TYPE.HISTORY_CHART:
    case WIDGET_TYPE.PIE_CHART:
    case WIDGET_TYPE.BAR_CHART:
      widget = <WidgetPreview widget={{ ...(details?.item || {}) }} />;
      break;
    default:
      widget = <div>Widget Not Supported</div>;
      break;
  }

  return widget;
};

export default RenderCustomWidget;
