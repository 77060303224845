/**
 * The GUI context of the displayed field, used for custom rendering of elements based on the location of a custom field
 * @type {{STANDALONE: string, FORM: string, DETAILS: string, GRID: string}}
 */
export const fieldContexts = {
  FORM: "form",
  GRID: "grid",
  DETAILS: "details",
  STANDALONE: "standalone",
};
