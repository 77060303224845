import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useTaskDataGrid } from "../hooks/useTaskDataGrid";

/**
 * A component instance of the useTaskDataGrid.js hook
 * @param props
 */
const TaskDataGrid = (props) => {
  return useTaskDataGrid(props).display;
};

export default withOrganizationCheck(TaskDataGrid);
