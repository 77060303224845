import { generateGraphql } from "@rivial-security/generategraphql";

import { useForm } from "../../../hooks/views/useForm/useForm";
import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import NodejsCipher from "../../Functions/NodejsCipher";

/**
 * @description Reset password for an item
 * @param {object} item - database object with an accessControls field
 * @param {function} toggleModal - close modal function
 * @param {function} resetFunction - reset state of the item
 * @param {string} typename - schema type name with a capital first letter
 * @returns {{input: {}, display: *}}
 */
export const useResetAccessControlPassword = ({ item, toggleModal, resetFunction, typename = "item" }) => {
  /**
   * Reset password workflow
   */
  const resetPasswordForm = useForm({
    header: "This item already protected by a password. Do you want to remove or reset the password?",
    fieldConfig: {
      removePassword: {
        inputType: "switch",
        label: "Remove Password: ",
        tooltip: `Remove password protection for this ${typename}`,
      },
      password: {
        inputType: "password",
        label: "Reset Password: ",
        tooltip: `Reset the password for this ${typename}`,
        passwordConfig: {
          confirmPassword: true,
        },
      },
    },
    submitFunction: async (input) => {
      /**
       * Check if "password" type Access Control exists
       */
      const password = item?.accessControl?.password;

      if (password) {
        /**
         * Remove password protection
         */
        if (input?.removePassword === true) {
          /**
           * Remove password from access control object
           */
          item?.accessControl?.password && delete item?.accessControl["password"];
          item?.accessControl?.passwordOwnerEmail && delete item?.accessControl["passwordOwnerEmail"];

          await ItemMutation(generateGraphql(typename).updateMutation, {
            id: item?.id,
            accessControl: {
              ...(item?.accessControl && item?.accessControl),
            },
          });
        }

        if (input?.password) {
          const encryptedPassword = await NodejsCipher({
            operation: "encrypt",
            dataToEncrypt: input?.password,
          });

          await ItemMutation(generateGraphql(typename).updateMutation, {
            id: item?.id,
            accessControl: {
              ...(item?.accessControl && item?.accessControl),
              password: encryptedPassword,
            },
          });
        }

        resetFunction && resetFunction();
        toggleModal && toggleModal();
      }
    },
  });

  return {
    ...resetPasswordForm,
  };
};
