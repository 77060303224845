import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useSubscription } from "../../../../hooks/graphql/useSubscription";
import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import CreateSystem from "../components/CreateSystem";
import SystemDetails from "../components/SystemDetails";
import SystemOwner from "../customFields/SystemOwner";
import { deleteSystem } from "../functions/deleteSystem";

const {
  listQuery,
  updateMutation: updateSystem,
  onCreate,
  onUpdate,
  onDelete,
} = generateGraphql("System", ["name", "description", "pointOfContact"], {
  pointOfContact: `{ id ownerGroup firstName lastName title email }`,
});

export const useSystemList = ({
  organizationID,
  queryConfig,
  dataCardConfig,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
}) => {
  const subscriptionHook = useSubscription({
    module,
    resource,
    organizationID,
    onCreate,
    onUpdate,
    onDelete,
  });

  const queryConfigInitial = {
    query: listQuery,
    organizationID,
    subscriptionHook,
  };

  const customFields = [
    {
      field: "pointOfContact.firstName",
      component: <SystemOwner />,
    },
  ];

  const fieldNameDictionary = {
    "pointOfContact.firstName": "System Owner",
    "pointOfContact.lastName": "System Owner - Last Name",
    "pointOfContact.title": "System Owner - Title",
    name: "System Name",
    description: "Description",
  };

  const dataCardConfigInitial = {
    fields: ["name", "description", "pointOfContact.firstName", "pointOfContact.lastName", "pointOfContact.title"],
    hideFields: ["pointOfContact.lastName", "pointOfContact.title"],
    customFields,
    fieldNameDictionary,
    detailsComponent: <SystemDetails organizationID={organizationID} module={module} resource={resource} />,
    route: "#/risk/systems/",
    header: "Information Systems",
    createResourceComponent: <CreateSystem organizationID={organizationID} module={module} resource={resource} />,
    options: ["details", "delete"],
    updateMutation: updateSystem,
    deleteFunction: deleteSystem,
  };

  const systemListHook = useQueryCard({
    queryConfig: { ...queryConfigInitial, ...queryConfig },
    dataCardConfig: { ...dataCardConfigInitial, ...dataCardConfig },
    module,
    resource,
  });

  return {
    ...systemListHook,
  };
};
