import React from "react";
import { SketchPicker } from "react-color";

/**
 * A custom color picker component
 * @param {function} onChange
 * @param {string} value
 * @return {JSX.Element}
 * @constructor
 */
const CustomColorPicker = ({ onChange, value }) => {
  return <SketchPicker color={value} onChangeComplete={(e) => onChange(e.hex)} />;
};

export default CustomColorPicker;
