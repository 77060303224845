import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import IconButton from "@mui/material/IconButton";
import React from "react";

const SingleSelectButton = ({ onSelectCallback, item }) => {
  const onClick = () => {
    if (typeof onSelectCallback === "function") {
      onSelectCallback(item);
    }
  };

  return (
    <IconButton onClick={onClick} title={"Select this Item"} size="large">
      <DoubleArrowIcon />
    </IconButton>
  );
};

export default SingleSelectButton;
