import { useTextAreaField } from "../hooks/useTextAreaField";

/**
 * Displays a TextArea input field
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TextAreaField = (props) => {
  return useTextAreaField(props).display;
};

export default TextAreaField;
