import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { addPageToDashboard } from "../functions/addPageToDashboard";
import { addWidgetToPage } from "../functions/addWidgetToPage";
import { editDashboardLayoutDetails } from "../functions/editDashboardLayoutDetails";
import { removePageFromDashboard } from "../functions/removePageFromDashboard";
import { removeWidgetFromPage } from "../functions/removeWidgetFromPage";
import { saveDashboardCopy } from "../functions/saveDashboardCopy";
import { saveDashboardLayout } from "../functions/saveDashboardLayout";
import { deleteMutation, updateMutation } from "../graphql/customDashboardGraphql";

import { useCustomDashboardQuery } from "./useCustomDashboardQuery";

/**
 * Custom hook for centrally handling querying and state for a CustomDashboard.
 *
 * Returns helper functions for:
 *
 * - saveAs (creates a copy)
 * - save (saves current dashboard layout)
 * - addPage
 * - removePage
 * - addWidget
 * - removeWidget
 * - deleteDashboard
 *
 * @param {string} itemId - ID of the custom dashboard
 * @param {string} organizationID - ID of the organization
 * @param {object[]} layoutElements - the elements of the layout to display
 * @param {number} layoutIndex - the index of the layout to display
 * @param {function} onCreateNewDashboard - callback function to create a new dashboard
 * @param {function} onDeleteDashboard - callback function to delete a dashboard
 *
 * @returns {{
 *    isLoading: boolean,
 *    setInputId: (value: (((prevState: *) => *) | *)) => void,
 *    inputId: string,
 *    item: object,
 *    setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void,
 *    saveAs: ((function(): Promise<void>)|*),
 *    resetIndex: number,
 *    addPage: ((function({name?: string}): Promise<void>)|*),
 *    save: ((function(*): Promise<void>)|*),
 *    reset: function(): void,
 *    setItem: (value: unknown) => void
 *  }}
 */
export const useCustomDashboardData = ({
  itemId,
  organizationID,
  layoutIndex,
  onCreateNewDashboard,
  onDeleteDashboard,
  onEditDashboard,
}) => {
  /**
   * Custom hook for querying a single CustomDashboard.
   * Acts as the source of truth for the UI state of the dashboard.
   * Any changes to the dashboard must be propagated using the setItem function.
   */
  const itemQuery = useCustomDashboardQuery({ itemId });

  /**
   * Removes a widget from the CustomDashboard layouts.elements array for the current layout
   * @param {string} elementID
   * @return {Promise<void>}
   */
  const removeWidget = async (elementID) => {
    return await removeWidgetFromPage({
      dashboardID: itemId,
      elementID,
      layoutIndex,
      setItemQuery: itemQuery.setItem,
    });
  };

  /**
   * Adds a new widget element to the CustomDashboard layouts.elements array for the current layout
   *
   * @param {string} selectedWidgetID - ID of the widget to add
   * @returns {Promise<void>}
   */
  const addWidget = async (selectedWidgetID) => {
    return await addWidgetToPage({
      dashboardID: itemId,
      widgetID: selectedWidgetID,
      layoutIndex,
      setItemQuery: itemQuery.setItem,
    });
  };

  /**
   * Saves the updated layout to the CustomDashboard
   * @param {object[]} elements - the elements of the layout to display
   * @param {string} layoutID - the ID of the layout to save
   * @returns {Promise<void>}
   */
  const save = async ({ layoutID, elements }) => {
    return await saveDashboardLayout({
      dashboardID: itemId,
      layoutID,
      elements,
      setItemQuery: itemQuery.setItem,
      currentLayouts: itemQuery?.item?.layouts,
    });
  };

  /**
   * Saves the CustomDashboard as a new CustomDashboard (follow up feature)
   * @return {Promise<void>}
   */
  const saveAs = async () => {
    return await saveDashboardCopy({
      name: itemQuery?.item?.name,
      description: itemQuery?.item?.description,
      layouts: itemQuery?.item?.layouts,
      organizationID,
      toolID: itemQuery?.item?.toolID || undefined,
      pageID: itemQuery?.item?.pageID,
      isTemplate: itemQuery?.item?.isTemplate,
      setItemQuery: itemQuery.setItem,
      onCreateNewDashboard,
    });
  };

  /**
   * Adds a new blank layout to the CustomDashboard
   * @param {string} name - the name of the new layout
   * @returns {Promise<void>}
   */
  const addPage = async ({ name = "test layout" }) => {
    return await addPageToDashboard({
      dashboardID: itemId,
      layoutName: name,
      setItemQuery: itemQuery.setItem,
    });
  };

  /**
   * Removes a layout from the CustomDashboard based on pageID
   * @param {string} pageID - the pageID of the layout to remove
   * @returns {Promise<void>}
   */
  const removePage = async ({ pageID }) => {
    return await removePageFromDashboard({
      dashboardID: itemId,
      pageID,
      setItemQuery: itemQuery.setItem,
    });
  };

  /**
   * Deletes the current custom dashboard.
   * Calls the onDeleteDashboard callback function afterwards to update selected dashboard state.
   * @returns {Promise<void>}
   */
  const deleteDashboard = async () => {
    const deletedItem = await ItemMutation(deleteMutation, {
      id: itemQuery?.item?.id,
    });
    onDeleteDashboard(deletedItem);
  };

  /**
   * Edits the name of the CustomDashboard
   *
   * @param {string} name - the new name of the dashboard
   * @returns {Promise<void>}
   */
  const editDashboardDetails = async ({ name }) => {
    if (name && name !== "") {
      const updatedDashboard = await ItemMutation(updateMutation, {
        id: itemId,
        name,
      });

      itemQuery.setItem(updatedDashboard);
      onEditDashboard(updatedDashboard);
    }
  };

  /**
   * Edits the name of a page in the CustomDashboard
   * @param {string} name - the new name of the page
   * @param {string} pageID - the pageID of the page to edit
   * @returns {Promise<void>}
   */
  const editPageDetails = async ({ name, pageID }) => {
    await editDashboardLayoutDetails({
      dashboardID: itemId,
      pageID: pageID,
      setItemQuery: itemQuery.setItem,
      name,
    });
  };

  return {
    ...itemQuery,
    save,
    addPage,
    removePage,
    addWidget,
    removeWidget,
    saveAs,
    deleteDashboard,
    editDashboardDetails,
    editPageDetails,
  };
};
