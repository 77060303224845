import React from "react";
import { Card, CardBody } from "reactstrap";

import { useDetails } from "../../../../../../../hooks/views/useDetails/hooks/useDetails";
import { useTable } from "../../../../../../../hooks/views/useTable";

const CustomRisks = ({ risks }) => {
  const CollapseComponent = (props) => {
    const subControlsTable = useTable({
      data: props.item.controlCategory.subControls,
      fields: ["statementNumber", "name", "costOfControl", "controlEffectiveness"],
      disableRoleChecking: true,
    });

    const customFields = [
      {
        field: "subControls",
        component: subControlsTable.display,
      },
    ];

    const controlCategoryDetails = useDetails({
      item: props.item.controlCategory,
      fields: ["name", "subControls"],
      customFields,
      disableRoleChecking: true,
    });

    return (
      <Card>
        <CardBody>{controlCategoryDetails.display}</CardBody>
      </Card>
    );
  };

  const details = useTable({
    data: risks,
    fields: ["name", "annualRateOfOccurrence", "confidenceIntervalLower", "confidenceIntervalUpper"],
    collapseComponent: <CollapseComponent />,
    disableRoleChecking: true,
  });

  return <div>{details.display}</div>;
};

const MonteCarloSummary = ({ monteCarloInput, monteCarloResults }) => {
  const customFields = [
    {
      field: "risks",
      component: <CustomRisks />,
    },
  ];

  const inputDetails = useDetails({
    item: monteCarloInput,
    fields: ["risks"],
    customFields: customFields,
    disableRoleChecking: true,
  });

  // const resultDetails = useDetails({
  //   item: monteCarloResults
  // });

  return (
    <div>
      <h2>Monte Carlo Inputs</h2>
      {inputDetails.display}
      {/*<h2>*/}
      {/*Monte Carlo Results*/}
      {/*</h2>*/}
      {/*{resultDetails.display}*/}
    </div>
  );
};

export default MonteCarloSummary;
