/**
 * @function
 * @description Get a Point of Contact full name
 * @param pointOfContact {Object}
 * @param includeEmail {boolean}
 * @param includeTitle {boolean}
 * @returns {string}
 */

export const pointOfContactName = (pointOfContact, includeEmail = false, includeTitle = false) => {
  let str = ``;

  if (pointOfContact) {
    str += pointOfContact.firstName;
    str += " ";
    str += pointOfContact.lastName;

    if (includeTitle) {
      str += " | ";
      str += pointOfContact.title ? pointOfContact.title : "";
    }

    if (includeEmail) {
      str += " | ";
      str += pointOfContact.email;
    }
  }

  return str;
};
