"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForStepNameUniqueness = void 0;
const getAutomationIdentifier_1 = require("../utils/getAutomationIdentifier");
/**
 * Checks all steps including nested ones for their identifier to be unique. A non-unique identifier may result
 * in unexpected behavior but is still permissible therefore is just displayed as a warning.
 * @param {object} automation - the parsed automation data including all referenced smart values even in nested steps
 * @returns {object} - status and message response, can be displayed in mui alert
 */
const checkForStepNameUniqueness = ({ automation }) => {
    if (!automation) {
        return {
            status: "warning",
            message: "Could not check for non-unique step names because the automation is invalid!",
        };
    }
    const getAutomationStepNames = ({ steps }) => {
        const allNames = [];
        if (Array.isArray(steps)) {
            for (const step of steps) {
                const identifier = (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep: step });
                if (identifier) {
                    allNames.push(identifier);
                }
                if (step?.type === "loop") {
                    const loopSteps = step?.config?.automations;
                    const loopStepNames = getAutomationStepNames({ steps: loopSteps });
                    allNames.push(...loopStepNames);
                }
            }
        }
        return allNames;
    };
    const uniqueNames = {};
    const repeatingNames = {};
    const allAutomationStepNames = getAutomationStepNames({ steps: automation.automations });
    for (const stepName of allAutomationStepNames) {
        if (!uniqueNames.hasOwnProperty(stepName)) {
            uniqueNames[stepName] = true;
        }
        else {
            delete uniqueNames[stepName];
            repeatingNames[stepName] = true;
        }
    }
    if (Object.keys(repeatingNames).length > 0) {
        return {
            status: "warning",
            message: `There are multiple automation steps with non-unique names (${Object.keys(repeatingNames).join(", ")}).`,
        };
    }
    else {
        return {
            status: "success",
            message: "All automation steps have unique names.",
        };
    }
};
exports.checkForStepNameUniqueness = checkForStepNameUniqueness;
