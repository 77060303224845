"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTableRow = void 0;
/**
 * @description Create a custom row for table
 * @param {object[]} cells
 * @return {object} {{cells: *, rowFormat: {allowBreakAcrossPages: boolean, gridBeforeWidthType: string, gridAfter: number, gridAfterWidth: number, heightType: string, borders: {diagonalDown: {}, horizontal: {}, top: {}, left: {}, bottom: {}, vertical: {}, right: {}, diagonalUp: {}}, isHeader: boolean, leftIndent: number, gridAfterWidthType: string, gridBefore: number, gridBeforeWidth: number, height: number}}}
 */
const createTableRow = ({ cells }) => {
    return {
        cells,
        rowFormat: {
            height: 0,
            allowBreakAcrossPages: true,
            heightType: "Auto",
            isHeader: false,
            borders: {
                top: {},
                left: {},
                right: {},
                bottom: {},
                diagonalDown: {},
                diagonalUp: {},
                horizontal: {},
                vertical: {},
            },
            gridBefore: 0,
            gridBeforeWidth: 0,
            gridBeforeWidthType: "Point",
            gridAfter: 0,
            gridAfterWidth: 0,
            gridAfterWidthType: "Point",
            leftIndent: 0,
        },
    };
};
exports.createTableRow = createTableRow;
