import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useTaskDetails } from "../hooks/useTaskDetails";

/**
 * A component instance of the useTaskDetails.js hook
 * @param {object} props
 */
const TaskDetails = (props) => {
  return useTaskDetails(props).display;
};

export default withDetailsPage(TaskDetails);
