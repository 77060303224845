import { Chip } from "@mui/material";
import React, { useEffect } from "react";

import { ErrorLogger } from "../../EventLogger";
import { copyToClipboard } from "../../GenericComponents/buttons/ClipboardButton";

/**
 * Displays a clickable Chip for selecting a suggested Smart Value.
 *
 * Clicking on the Chip either auto-inserts the Smart Value into the last focused input field or copies it to clipboard
 *
 * @param {string} value - The Smart Value to display
 * @returns {JSX.Element}
 * @constructor
 */
const SmartValueChip = ({ value }) => {
  const [copied, setCopied] = React.useState(false);

  /**
   * Copies the Smart Value to clipboard.
   *
   * Alternatively, if an input field is focused, the Smart Value is inserted into the input field.
   */
  const handleClick = () => {
    try {
      copyToClipboard(`{{${value}}}`);
      setCopied(true);
    } catch (e) {
      ErrorLogger("Error: could not copy to clipboard", e);
    }
  };

  /**
   * When a chip is clicked, shows a "Copied!" message for 2 seconds
   */
  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <Chip
      label={copied ? "Copied to Clipboard!" : `${value}`}
      variant="outlined"
      onClick={handleClick}
      style={{ marginTop: "0.5em", marginLeft: "0.5em" }}
    />
  );
};

export default SmartValueChip;
