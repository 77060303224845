import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { resources } from "@rivial-security/role-utils";

import EllipsisText from "../../../../../../utils/Text/components/EllipsisText";

import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";

/**
 * Timeline content to display when a vulnerability history item is a priority change
 * @param {object} item - the TargetVulnerabilityHistory item
 * @returns {JSX.Element}
 */
const PriorityChangeStatus = ({ item }) => {
  const isFindingAttached = !!item?.vulnerabilityName;
  const getPriorityWording = (item) => {
    const isOldPriorityAvailable = !isNullOrUndefined(item?.oldPriority);
    const isNewPriorityAvailable = !isNullOrUndefined(item?.newPriority);
    if (isOldPriorityAvailable && isNewPriorityAvailable) {
      return ` priority changed from ${item?.oldPriority} to ${item?.newPriority} because of an `;
    } else if (!isOldPriorityAvailable && isNewPriorityAvailable) {
      return ` priority changed to ${item?.newPriority} because of an `;
    } else if (!isNewPriorityAvailable) {
      return ` priority changed because of an `;
    }
  };
  if (isFindingAttached) {
    return (
      <span>
        {"Vulnerability "}
        <TimelineUnderlinedDetails modalType={resources.VULNERABILITY} item={item}>
          <EllipsisText text={item?.vulnerabilityName} maxLength={15} />
        </TimelineUnderlinedDetails>
        {getPriorityWording(item)}
        <TimelineUnderlinedDetails
          tooltipText="Click to view Assessment Details"
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      </span>
    );
  } else {
    return (
      <span>
        {"Target "}
        <TimelineUnderlinedDetails tooltipText="Click to view Target Details" modalType={resources.TARGET} item={item}>
          <EllipsisText text={item?.targetName} maxLength={15} />
        </TimelineUnderlinedDetails>
        {getPriorityWording(item)}
        <TimelineUnderlinedDetails
          tooltip={"Click to view Assessment Details"}
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      </span>
    );
  }
};
export default PriorityChangeStatus;
