import parser from "cron-parser";
import React from "react";

/**
 * @description Displays the next applicable date based on a cron expression
 * @param {string} cron - the cron expression to use
 * @constructor {JSX.Element}
 */
const CronDisplay = ({ cron }) => {
  return <span>{cron && new Date(parser.parseExpression(cron).next()).toLocaleDateString()}</span>;
};

export default CronDisplay;
