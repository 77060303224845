export const handleRiskChangePromises = async (associatedSystems, createFunction, organizationID, riskChange) => {
  const promises = [];

  for (const selectedSystem of associatedSystems) {
    if (selectedSystem && selectedSystem.id) {
      promises.push(
        createFunction({
          ownerGroup: organizationID,
          riskChangeID: riskChange.id,
          systemID: selectedSystem.id,
        }),
      );
    }
  }

  return Promise.allSettled(promises);
};
