import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useStateEffect } from "@hooks/functional/useStateEffect";
import useListQuery from "@hooks/graphql/useListQuery";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "@hooks/views/useForm";
import { useModal } from "@hooks/views/useModal";
import { useSelectTemplatesModal } from "@hooks/views/useSelectTemplates/hooks/useSelectTemplatesModal";
import { ErrorLogger } from "@utils/EventLogger";
import { arrayIncludes } from "@utils/Functions/Array/arrayIncludes";
import { ItemQuery } from "@utils/Functions/Graphql/ItemQuery";
import AddButton from "@utils/GenericComponents/buttons/AddButton";
import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";
import { getFormCustomFields } from "@views/CustomResources/CustomResourceFields/functions/getFormCustomFields";
import { isValidField } from "@views/CustomResources/CustomResourceFields/functions/isValidField";
import { MODULE_TYPES } from "@views/Program/moduleTypes";

import ControlFrameworkTemplateForm from "../../../../../Templates/ControlFrameworkTemplates/ControlFrameworkTemplateForm";
import CreateControlSet from "../../../ControlSets/components/CreateControlSet";
import { useImportControl } from "../useImportControl/useImportControl";

import { controlFields } from "./constants";

/**
 * A custom hook for creating a new Compliance Control
 * @param {string} organizationID - the organization/operation team for which to create a control
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {string} controlFrameworkID - the control framework for which to create a control
 * @param {function} resetFunction - optional function to call after many control are created to refresh all underlying data
 * @param {function} [toggleModal] - called to close containing modal right as the form is submitted
 * @param {function} [getNewItem] - callback for a new item (after creation)
 * @param {object} ...props - all props passed to useFormHook
 * @return {{display: JSX.Element}}
 */
export const useCreateControl = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,
  isTemplate = false,

  controlFrameworkID,
  resetFunction,
  toggleModal,
  getNewItem,

  disabledFields = [],

  ...props
}) => {
  ///[GENERAL SETUP]
  // - Constants
  const typename = resources.CONTROL;
  // - Queries
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["name", "customFields"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format }}`,
  });
  const { createMutation: createControlMutation } = generateGraphql(typename, controlFields);
  const { listQuery } = generateGraphql("ControlSet", ["name"]);
  const controlSets = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });
  // - Permissions
  const controlSetPermissions = useCheckPermissions({
    module,
    resource: isTemplate ? resources.CONTROL_FRAMEWORK_TEMPLATE : resources.CONTROL_FRAMEWORK,
    disableRoleChecking,
  });
  // - State
  const [selectedControlFrameworkId, setSelectedControlFrameworkId] = useState("");
  const [customFrameworkFields, setCustomFrameworkFields] = useStateEffect(
    [],
    [selectedControlFrameworkId],
    async () => {
      //Check to see if no custom framework is selected
      if (!selectedControlFrameworkId || selectedControlFrameworkId === "") {
        setCustomFrameworkFields([]);
        return;
      }

      //Retrieve the new framework custom fields
      try {
        const controlSet = await ItemQuery(getControlFrameworkQuery, selectedControlFrameworkId);
        if (controlSet?.customFields && Array.isArray(controlSet.customFields)) {
          setCustomFrameworkFields(controlSet.customFields);
        }
      } catch (e) {
        ErrorLogger("Could not retrieve control set data!", e);
        setCustomFrameworkFields([]);
      }
    },
  );

  //[UTILITY FUNCTIONS]
  const customFieldPrefix = "customField";
  const getCustomFieldName = (field) => {
    if (field?.name) {
      return `${customFieldPrefix}_${field.name}`;
    }
    return null;
  };

  /**
   * A pass through for the create form that converts all custom fields to a JSON that is stored in the database
   * @param {object} form input object - (custom fields prefixed with cus)
   * @return {object} for input object to use in the mutation (all custom fields in a single object)
   */
  const convertInputOnSubmit = (input) => {
    //if invalid input return itself
    if (!input || typeof input !== "object") return input;

    // save all custom fields that are under the currently selected control set
    const customFieldData = {};
    for (const fieldName in input) {
      const foundField =
        Array.isArray(customFrameworkFields) &&
        customFrameworkFields.find((item) => getCustomFieldName(item) === fieldName);
      if (fieldName.startsWith(customFieldPrefix) && foundField) {
        customFieldData[foundField.name] = input[fieldName];
      }
    }

    // delete all custom field entries in the object
    for (const field in input) {
      if (field.startsWith(customFieldPrefix)) {
        delete input[field];
      }
    }

    // add the saved custom fields into one property
    input.customFieldData = JSON.stringify(customFieldData);

    return input;
  };

  ///[CREATE FORM UI]
  const createControlSetModal = useModal(
    `Create a new Control Framework${isTemplate ? " Template" : ""}`,
    isTemplate ? (
      <ControlFrameworkTemplateForm organizationID={organizationID} formConfig={{ resetFunction: controlSets.reset }} />
    ) : (
      <CreateControlSet organizationID={organizationID} formConfig={{ resetFunction: controlSets.reset }} />
    ),
    <AddButton color="ghost-success" style={{ marginLeft: "1em" }} />,
  );

  //Allows to see create control custom field state after create modal is closed
  const getNewItemWithCustomFields = (newItem) => {
    if (newItem) {
      //Add all custom framework fields
      try {
        const customFrameworkFields = JSON.parse(newItem.customFieldData);
        for (const customField in customFrameworkFields) {
          newItem[customField] = customFrameworkFields[customField];
        }
      } catch (e) {
        ErrorLogger("Could not parse custom field data upon adding a control!", e);
      }

      getNewItem?.(newItem);
    }
  };

  //Control templates related
  const checkControlHook = useCheckPermissions({
    module: MODULE_TYPES.COMPLIANCE,
    resource: resources.CONTROL,
  });

  // - create the control IMPORTER
  const importControls = useImportControl({
    organizationID,
    resetFunction,
    toggleModal,
  });

  const selectControlTemplateModal = useSelectTemplatesModal({
    resource: resources.CONTROL,
    parents: [{ id: controlFrameworkID, typename: resources.CONTROL_FRAMEWORK }],
    onStartedDuplication: () => {
      toggleModal?.();
    },
    getNewItem: getNewItemWithCustomFields,
  });

  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    fieldConfig: {
      controlControlSetId: {
        inputType: "dropdown",
        // Include a Control Framework Form button,
        // if this field is not disabled,
        // and the user has permissions to create a Control Framework
        ...(!arrayIncludes(disabledFields, "controlControlSetId") &&
          controlSetPermissions.resource.create && {
            createItemComponent: createControlSetModal.modalButton,
          }),
        label: "Control Framework",
        required: true,
        onChangeFunction: (value) => setSelectedControlFrameworkId(value.controlControlSetId),
        defaultValue: controlFrameworkID,
        dropdownConfig: {
          data:
            controlSets.list &&
            controlSets.list.map(({ id, name }) => {
              return {
                value: id,
                text: name,
              };
            }),
        },

        disabled: arrayIncludes(disabledFields, "controlControlSetId"),
      },
      statementNumber: {
        label: "Statement Number",
        tooltip: "A unique identifier for this Control",
      },
      name: {
        label: "Declarative Statement",
        tooltip: "The name or description of the Control",
        required: true,
      },
      inPlace: {
        label: "Initial Compliance Status",
        tooltip: "Is this control currently In Place or Not In Place?",
        inputType: "switch",
        defaultValue: false,
        switchConfig: {
          onLabel: "In Place",
          offLabel: "Not In Place",
        },
      },
      isDisabled: {
        label: "Disabled Status",
        tooltip: "Disabled controls are not included in compliance calculations",
        inputType: "switch",
        defaultValue: false,
        switchConfig: {
          onLabel: "Enabled",
          offLabel: "Disabled",
        },
      },
      ...getFormCustomFields({
        customFields: customFrameworkFields,
      }),
    },
    mutation: createControlMutation,
    updateInputFunction: convertInputOnSubmit,
    getNewItem: getNewItemWithCustomFields,
    typename,
    toggleModal,
    header: <span>Create a Control</span>,
    headerButtons: [
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: ".5em",
        }}
      >
        {!isTemplate && checkControlHook.resource.create && selectControlTemplateModal.modalButton}
        {importControls?.modalButton}
      </div>,
    ],

    ...props,
  });

  //[USE EFFECTS]
  //Allows to set default values for newly added custom fields
  useEffect(() => {
    if (!customFrameworkFields || !Array.isArray(customFrameworkFields) || customFrameworkFields.length === 0) {
      return;
    }

    const newInputValues = {};
    for (const field of customFrameworkFields) {
      if (isValidField(field)) {
        const customFieldName = getCustomFieldName(field);
        if (!customFieldName) continue;

        if (field.type === CustomFieldType.BOOLEAN) {
          newInputValues[customFieldName] = false;
        } else {
          newInputValues[customFieldName] = "";
        }
      }
    }

    formHook.mergeInput(newInputValues, true);
  }, [customFrameworkFields]);

  const display = <div>{formHook.display}</div>;

  return { display };
};
