"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatHistoryChartWidgetData = void 0;
/**
 * Gets formatted data for a history chart widget
 * @param {object} config - the widget settings passed in from the front-end
 * @param {object} metricTypes - metric data for each metric type that is in the config (key is id of metric type)
 * @returns {object} - the datasource object to use with the history chart widget
 */
const formatHistoryChartWidgetData = ({ config, metricTypes } = {}) => {
    console.log("[formatHistoryChartWidgetData] - on start", { config, metricTypes });
    //Check if datasource already exists
    if (config?.dataSource) {
        return config?.dataSource;
    }
    //Check if metric configurations exists
    const dataSource = [];
    const seriesConfig = config?.series;
    if (Array.isArray(seriesConfig)) {
        for (const series of seriesConfig) {
            const seriesData = [];
            //Check if a metric type of series
            const metricTypeId = series?.metricTypeId;
            const metricType = metricTypes?.[metricTypeId];
            console.log("[formatHistoryChartWidgetData] retrieved metricType - ", { metricTypeId, metricType });
            if (metricTypeId && metricType?.metrics) {
                const metrics = metricTypes[metricTypeId].metrics;
                //If metric(s) for first series was found add its data to the chart
                if (metrics && Array.isArray(metrics)) {
                    for (const metric of metrics) {
                        const dateObject = new Date(metric.date);
                        const convertedYear = dateObject.getFullYear();
                        const convertedMonth = String(dateObject.getMonth() + 1).padStart(2, "0");
                        const convertedDay = String(dateObject.getDate()).padStart(2, "0");
                        seriesData.push({
                            x: `${convertedYear}-${convertedMonth}-${convertedDay}`,
                            y: Math.round(metric?.data?.value) || 0,
                        });
                    }
                }
                dataSource.push({
                    id: metricType?.name,
                    color: "#50a9cc",
                    data: seriesData,
                });
            }
        }
    }
    console.log("[formatHistoryChartWidgetData] retrieved data source - ", { dataSource });
    return dataSource;
};
exports.formatHistoryChartWidgetData = formatHistoryChartWidgetData;
