import { useThreatForm } from "../../Risk/RiskConfig/Threats/hooks/useThreatForm";

/**
 * Form component for creating Threat Templates
 *
 * @param {object} props - all props passed to useThreatForm hook
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatTemplateForm = (props) => {
  const form = useThreatForm(props);

  return form.display;
};

export default ThreatTemplateForm;
