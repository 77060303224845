"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSystem = void 0;
exports.updateSystem = `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      name
      ownerGroup
      monteCarloInputS3ObjectKey
      inherentRisk
      residualRisk
      riskMitigated
      riskRating
      inherentRiskRating
      residualRiskRating
      lossToleranceDelta
      greatestKeyRiskIndicator
      greatestBusinessRisk
      greatestEnterpriseRisk
      costOfControls
    }
  }
`;
