"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomQuery = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
/**
 * Retrieves information about a custom query from the database
 * @param {string} customQueryId - the custom query to retrieve
 * @returns {Promise<*>}
 */
const getCustomQuery = async ({ customQueryId }) => {
    if (!customQueryId)
        return null;
    const { getQuery } = (0, generategraphql_1.generateGraphql)("CustomQuery", ["name", "description", "queryConfig", "typename"]);
    return await (0, appsync_utils_1.GetQuery)({
        variables: { id: customQueryId },
        query: getQuery,
    });
};
exports.getCustomQuery = getCustomQuery;
