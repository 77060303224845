import { cloneDeep } from "lodash";
import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useUIContext } from "@utils/Context/UIContext";

import NotEnoughData from "../../../utils/GenericComponents/NotEnoughData";
import NotesV2 from "../../../utils/Notes/components/NotesV2";
import { useAccordion } from "../useAccordion/useAccordion";

import Checklist from "./Checklist";

/**
 * Displays grouped checklists seperated into accordion sections,
 * @param {string} module - app module for role checking
 * @param {string} typename - resource name for role checking
 * @param {string} organizationID - currently selected organization id from the database
 * @param {function} resetFunction - void function that re-queries the 'data' prop
 * @param {function} onUpdateNotes - function triggered when a note is created for an accordion group
 * @param {object[]} data - an array of ProcedureGroup types from the db
 * @param {string} field - the field in `data` objects that holds the groups data
 * @param {boolean} enableCheckbox - TRUE if need to show checkboxes next to steps
 * @param {boolean} enableStepNotes - TRUE if need to allow to write notes for each step
 * @param {boolean} enableGroupNotes - TRUE if need to allow to write notes for a whole group of steps
 * @param {boolean} disableEdits - TRUE if need to disallow adding or modifying step content
 * @param {boolean} disableCheckboxEdits - TRUE if need to disallow checking or unchecking steps
 * @param {function} updateFunction - callback when step content or order was updated
 * @param {string} textFieldName - the field within each step object that represents the text
 * @param {string} checkboxFieldName - the field name within each step object that the completion state
 * @param {boolean} [forceAccordion = false] - will show an accordion even if only one group is provided
 * @param {function} calculateCompletion - callback to update completion for the entire set of checklist groups
 * @param {function} getAccordionIcon - function to get a dynamic icon based on group content
 * @param {function} getAccordionDisabledState - function to determine if group needs to be disabled based on its content
 * @param {JSX.Element[]} groupAddonComponents - all custom components to put at the end of the checklist UI within each group
 * @param {object} notesProps - props to pass to the notes components
 */
export const useAccordionChecklist = ({
  module,
  typename,
  organizationID,
  resetFunction,
  onUpdateNotes,
  data,
  field,
  enableCheckbox,
  enableStepNotes,
  enableGroupNotes,
  disableEdits,
  disableCheckboxEdits,
  updateFunction,
  textFieldName,
  checkboxFieldName,
  forceAccordion = false,
  calculateCompletion,
  getAccordionIcon,
  groupAddonComponents,
  getAccordionDisabledState,
  notesProps,
}) => {
  //Converts the regular updateFunction message form a checklist into the group checklist updateFunction info object
  const updateFunctionAdapter = ({ updatedGroupID, updatedSteps, updatedStepID, updateOperation }) => {
    const foundGroup = data?.find((item) => item?.parentID === updatedGroupID);

    if (foundGroup && field) {
      const newData = cloneDeep(foundGroup);
      newData[field] = updatedSteps;
      updateFunction &&
        updateFunction({
          updatedGroup: newData,
          updatedGroupID,
          updatedStepID,
          updateOperation,
        });
    }
  };

  const accordion = useAccordion({
    items:
      data.length > 0
        ? data.map((item, index) => {
            return {
              title: item?.name ?? `Group #${index}`,
              component: (
                <ChecklistWithNotes
                  notesProps={notesProps}
                  enableCheckbox={enableCheckbox}
                  disableEdits={disableEdits}
                  disableCheckboxEdits={disableCheckboxEdits}
                  item={item}
                  field={field}
                  updateFunction={(updatedSteps, updatedStepID, updateOperation) =>
                    updateFunctionAdapter({
                      updatedGroupID: item?.parentID,
                      updatedSteps,
                      updatedStepID,
                      updateOperation,
                    })
                  }
                  textFieldName={textFieldName}
                  checkboxFieldName={checkboxFieldName}
                  enableStepNotes={enableStepNotes}
                  enableGroupNotes={enableGroupNotes}
                  module={module}
                  typename={typename}
                  organizationID={organizationID}
                  resetFunction={resetFunction}
                  groupAddonComponents={groupAddonComponents}
                  onUpdateNotes={(notes) => onUpdateNotes({ notes, updatedGroupID: item?.parentID })}
                />
              ),
              containerStyle: {
                height: "100%",
              },
              icon: getAccordionIcon ? getAccordionIcon(item) : "icon-list",
              disabled: getAccordionDisabledState ? getAccordionDisabledState(item) : false,
              completion: () => {
                if (item && calculateCompletion) {
                  return calculateCompletion(item);
                } else {
                  return 0;
                }
              },
            };
          })
        : [],
  });

  let display = <NotEnoughData />;
  if (!isNullOrUndefined(data) && data.length === 1 && !forceAccordion) {
    const item = data[0];
    display = (
      <ChecklistWithNotes
        enableCheckbox={enableCheckbox}
        disableEdits={disableEdits}
        disableCheckboxEdits={disableCheckboxEdits}
        item={item}
        field={field}
        updateFunction={(updatedSteps) =>
          updateFunctionAdapter({
            updatedGroupID: item?.parentID,
            updatedSteps,
          })
        }
        textFieldName={textFieldName}
        checkboxFieldName={checkboxFieldName}
        enableStepNotes={enableStepNotes}
        enableGroupNotes={enableGroupNotes}
        module={module}
        typename={typename}
        organizationID={organizationID}
        resetFunction={resetFunction}
        groupAddonComponents={groupAddonComponents}
        onUpdateNotes={(notes) => onUpdateNotes({ notes, updatedGroupID: item?.parentID })}
        notesProps={notesProps}
      />
    );
  } else if (data.length >= 2 || forceAccordion) {
    display = accordion.display;
  }

  return {
    display,
  };
};

/**
 * Combines ChecklistV2 and NotesUI components so that the code is not duplicated up above
 * NOTE: props are passed down from the main component
 * @returns {JSX.Element}
 */
const ChecklistWithNotes = ({
  enableCheckbox,
  disableEdits,
  disableCheckboxEdits,
  item,
  field,
  updateFunction,
  textFieldName,
  checkboxFieldName,
  enableStepNotes,
  enableGroupNotes,
  module,
  typename,
  organizationID,
  resetFunction,
  onUpdateNotes,
  groupAddonComponents,
  notesProps,
}) => {
  //When in mobile mode margin is removed from tab content and thus notes ui needs to add its own margin
  const { isMobile } = useUIContext();

  return (
    <div>
      <Checklist
        enableNotes={enableStepNotes}
        enableCheckbox={enableCheckbox}
        disableEdits={disableEdits}
        disableCheckboxEdits={disableCheckboxEdits}
        item={item}
        field={field}
        updateFunction={updateFunction}
        textFieldName={textFieldName}
        checkboxFieldName={checkboxFieldName}
        disableRoleChecking={true}
        addonComponents={groupAddonComponents}
        organizationID={organizationID}
        notesProps={notesProps}
      />
      {item && enableGroupNotes && (
        <div style={{ overflowY: "auto", ...(isMobile && { margin: "1em" }) }}>
          <NotesV2
            item={item}
            module={module}
            resource={typename}
            organizationID={organizationID || item?.ownerGroup}
            showHorizontalRule={true}
            resetFunction={resetFunction}
            onUpdate={onUpdateNotes}
          />
        </div>
      )}
    </div>
  );
};
