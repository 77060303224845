"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLowerCaseFirstLetter = void 0;
/**
 * Capitalize first letter of a string
 */
const toLowerCaseFirstLetter = (str) => {
    if (str) {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
    else {
        return null;
    }
};
exports.toLowerCaseFirstLetter = toLowerCaseFirstLetter;
