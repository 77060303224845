import { checkPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";

export interface CheckModulePermissionsParam {
  navigationModule: { moduleName: string };
  roleConfig: Record<string, unknown>;
}

export const checkModulePermissions = ({ navigationModule, roleConfig }: CheckModulePermissionsParam): boolean => {
  const permissionResult = checkPermissions({
    module: navigationModule.moduleName,
    roleConfig,
  }) as { module?: { isEnabled?: boolean } } | undefined;

  return permissionResult?.module?.isEnabled === true;
};
