import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Checks the aria-label attribute to determine if this column should be ignored
 * @param {object} target - object with aria-label attribute to determine if this column should be ignored
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @returns {boolean} - if returns TRUE then a target element is a child of a column that has a custom field
 */
export const gridCheckAriaLabel = ({ target, fields }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    fields,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  const ignoreColumns = [];

  const checkString = (label) => {
    const split = label.split("column header");
    if (!isNullOrUndefined(split[1])) {
      for (const column of ignoreColumns) {
        if (split[1].includes(column)) {
          return true;
        }
      }
    }
    return false;
  };

  Array.isArray(fields) &&
    fields.length > 0 &&
    fields.forEach((field) => {
      if (field.disablePropagation) {
        ignoreColumns.push(field.friendlyName || field.name);
        ignoreColumns.push(convertCamelCaseToSentence(field.name));
      }
    });

  // Chrome
  if (target?.ariaLabel) {
    return checkString(target.ariaLabel);
  }

  // Firefox
  if (target && target.attributes?.hasOwnProperty("aria-label") && target?.attributes["aria-label"]?.nodeValue) {
    return checkString(target?.attributes["aria-label"]?.nodeValue);
  }

  return false;
};
