import { useState } from "react";

export const useOverallChanges = () => {
  const [overallInherentChange, setOverallInherentChange] = useState(0);
  const [overallResidualChange, setOverallResidualChange] = useState(0);
  const [overallROIChange, setOverallROIChange] = useState(0);
  const [systemCalcs, setSystemCalcs] = useState([]);

  return {
    overallInherentChange,
    setOverallInherentChange,
    overallResidualChange,
    setOverallResidualChange,
    overallROIChange,
    setOverallROIChange,
    systemCalcs,
    setSystemCalcs,
  };
};
