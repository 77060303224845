import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getResourceAttribute } from "./getResourceAttribute";

/**
 * Finds a single field attribute from the resource definition files
 * @param {*} [override] - the value to return if it is not null or undefined
 * @param {string} typename - the name of the resource for which the attribute is being retrieved
 * @param {string} fieldContext - the location of the field being displayed
 * @param {string} fieldName - the name of the field inside the resource for which the attribute is being retrieved
 * @param {string} attribute - the field attribute being retrieved
 * @returns {*|null} - the most fitting value for the attribute based on field context
 */
export const getResourceFieldAttribute = ({ override, typename, fieldContext, fieldName, attribute }) => {
  if (!isNullOrUndefined(override)) {
    return override;
  }

  //Without a typename or attribute cannot retrieve the correct definition file value
  if (isNullOrUndefined(typename) || isNullOrUndefined(attribute)) {
    return null;
  }

  const fieldContextValue = getResourceAttribute({
    typename,
    attribute: `fields.${fieldName}.${fieldContext}.${attribute}`,
  });

  const defaultValue = getResourceAttribute({
    typename,
    attribute: `fields.${fieldName}.default.${attribute}`,
  });

  return fieldContextValue || defaultValue;
};
