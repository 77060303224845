"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListQueryBy = void 0;
const getEnvironment_1 = require("../getEnvironment");
const handleGraphqlClient_1 = require("../handleGraphqlClient");
const handleListQueryBy_1 = require("./handleListQueryBy");
/**
 * Performs a list graphql query.
 * @param appsyncEndpoint - the endpoint of the AppSync API
 * @param params - params for the function, including appsyncEndpoint
 */
const ListQueryBy = async ({ appsyncEndpoint, ...params }) => {
    const env = (0, getEnvironment_1.getEnvironment)({ appsyncEndpoint });
    const queryFunction = (0, handleGraphqlClient_1.handleGraphqlClient)({ env, appsyncEndpoint });
    return await (0, handleListQueryBy_1.handleListQueryBy)({ ...params, queryFunction });
};
exports.ListQueryBy = ListQueryBy;
