"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNestedFieldsGraphql = void 0;
/**
 * Generates the Graphql for nested fields recursively
 * @param {object} fieldData - an element of the current query (a single branch)
 * @param {number} indentationAmount - the number of spaces to indent the fields at the current level
 * @return {string} - the nested fields segment of the Graphql for fieldData fields
 */
const generateNestedFieldsGraphql = ({ fieldData, indentationAmount = 4 }) => {
    const { fields, hasMany = false, isRoot = false, isModel = true, hasIdField = true, hasOwnerGroupField = true, } = fieldData || {};
    if (typeof fields !== "object") {
        return "";
    }
    let fieldsGraphql = "";
    // determine the amount to indent for this part of the nested fields
    let baseIndentation = "";
    let bracketIndentation = "";
    for (let i = 0; i < indentationAmount; i++) {
        baseIndentation += " ";
        if (i < indentationAmount - 2) {
            bracketIndentation += " ";
        }
    }
    let fieldIndentation = baseIndentation;
    if (hasMany) {
        fieldIndentation = `${baseIndentation}  `;
    }
    // add default fields
    if (hasIdField) {
        fieldsGraphql += `${fieldIndentation}id\n`;
    }
    if (hasOwnerGroupField) {
        fieldsGraphql += `${fieldIndentation}ownerGroup\n`;
    }
    for (const field of Object.keys(fields)) {
        const nestedFieldData = fields[field];
        const nestedFields = nestedFieldData?.fields;
        if (typeof nestedFieldData === "object") {
            let nestedFieldGraphql = "";
            if (typeof nestedFields === "object" && Object.keys(nestedFields).length !== 0) {
                nestedFieldGraphql = (0, exports.generateNestedFieldsGraphql)({
                    fieldData: nestedFieldData,
                    indentationAmount: indentationAmount + (hasMany ? 4 : 2),
                });
            }
            fieldsGraphql += `${fieldIndentation}${field}`;
            if (nestedFieldGraphql) {
                fieldsGraphql += `${nestedFieldGraphql}`;
            }
            else {
                fieldsGraphql += "\n";
            }
        }
    }
    /**
     * Nested fields have different wrappers based on whether they
     * represent an array of items or a single item, also only array items are not
     * wrapped in `items` property unless they have a @model directive in the schema
     */
    if (hasMany && isModel) {
        if (isModel) {
            return (`${isRoot ? "" : ` (limit: 1000) {\n`}${baseIndentation}items {\n` +
                `${fieldsGraphql}` +
                `${baseIndentation}}\n` +
                `${baseIndentation}nextToken\n${isRoot ? "" : `${bracketIndentation}}\n`}`);
        }
    }
    else {
        return ` {\n` + `${fieldsGraphql}` + `${bracketIndentation}}\n`;
    }
};
exports.generateNestedFieldsGraphql = generateNestedFieldsGraphql;
