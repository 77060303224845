import { Timeline } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useState } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { useSetAsyncData } from "../../../../../../hooks/functional/useSetAsyncData";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import { getVulnerabilityTargetHistory } from "../../../functions/getVulnerabilityTargetHistory";
import { useVulnerabilityTargetHistoryChips } from "../../../hooks/useVulnerabilityTargetHistoryChips";
import { useGroupHistoryByAssessment } from "../hooks/useGroupHistoryByAssessment";

import VulnerabilityTimelineItem from "./VulnerabilityTimelineItem";

/**
 * Displays the Vulnerability timeline
 * @param {object} item - vulnerability item with all vulnerability target link ids
 * @returns {JSX.Element}
 */
const VulnerabilityTimeline = ({ item }) => {
  const { display: groupByAssessmentDisplay, isGrouped } = useGroupHistoryByAssessment();

  const [historyItems, setHistoryItems] = useState([]);
  const [historyNextToken, setHistoryNextToken] = useState(null);
  const { shownHistoryItems, chipsDisplay } = useVulnerabilityTargetHistoryChips({
    historyItems,
    isGrouped,
  });

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      if (!item?.id) {
        return [];
      }

      const vulnerabilityTargetLinkIds = [];
      const targets = {};
      for (const targetLink of item?.targets?.items ?? []) {
        if (!targetLink?.id) {
          continue;
        }

        vulnerabilityTargetLinkIds.push(targetLink.id);
        targets[targetLink.id] = targetLink?.target;
      }

      let vulnerabilityTargetHistory = [];
      let nextToken = null;
      const usedNextToken = historyNextToken;
      if (isNonEmptyArray(vulnerabilityTargetLinkIds)) {
        const result = await getVulnerabilityTargetHistory({
          vulnerabilityTargetLinkIds,
          groupByAssessment: isGrouped,
          nextToken: usedNextToken,
        });
        vulnerabilityTargetHistory = result?.vulnerabilityTargetHistory;
        nextToken = result?.nextToken;
      }

      for (const historyItem of vulnerabilityTargetHistory) {
        const linkID = historyItem?.targetVulnerabilityLinkID;
        historyItem.targetName = targets[linkID]?.ip;
        historyItem.targetID = targets[linkID]?.id;
      }

      return { vulnerabilityTargetHistory, nextToken, usedNextToken };
    },
    setData: ({ vulnerabilityTargetHistory, nextToken, usedNextToken }) => {
      const newItems = vulnerabilityTargetHistory || [];
      if (usedNextToken) {
        setHistoryItems((historyItems) => [...(historyItems || []), ...newItems]);
      } else {
        setHistoryItems(newItems);
      }
      setHistoryNextToken(nextToken);
    },
    dependencies: [isGrouped, item?.id],
  });

  return (
    <DashboardCard
      id={"vulnerability-activity-card"}
      title={"History"}
      icon={"icon-energy"}
      style={{ height: "65vh", overflowY: "auto" }}
      headerButtons={[groupByAssessmentDisplay]}
      headerContent={isGrouped !== true && <div>{chipsDisplay}</div>}
    >
      <DataLoader
        isEnoughData={historyItems?.length > 0}
        isLoading={isLoading}
        dataMessage={"There hasn't been any Vulnerability activity yet"}
        children={
          <Timeline align={"left"}>
            {shownHistoryItems.map((historyItem, index) => {
              return (
                <VulnerabilityTimelineItem
                  key={historyItem?.id}
                  isLastItem={index === shownHistoryItems.length - 1}
                  date={historyItem?.date}
                  resetFunction={() => {
                    setHistoryItems([]);
                    setHistoryNextToken(null);
                    resetFunction();
                  }}
                  type={historyItem?.type}
                  comment={historyItem?.comment}
                  item={historyItem}
                  vulnerability={item}
                  isGrouped={isGrouped}
                />
              );
            })}
            {historyNextToken && (
              <Button
                variant={"outlined"}
                title={"Older items available, click here to load them in"}
                onClick={() => {
                  resetFunction();
                }}
              >
                Load More
              </Button>
            )}
          </Timeline>
        }
      />
    </DashboardCard>
  );
};

export default VulnerabilityTimeline;
