import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKnowBe4TestRecipientDetails } from "../hooks/useKnowBe4TestRecipientDetails";

/**
 * Displays details for a single knowbe4 phishing test recipient
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const KnowBe4TestRecipientDetails = (props) => {
  return useKnowBe4TestRecipientDetails(props).display;
};

export default withOrganizationCheck(KnowBe4TestRecipientDetails);
