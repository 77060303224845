"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RATING_SORT_ORDER = void 0;
exports.RATING_SORT_ORDER = {
    info: 0,
    low: 1,
    lowMedium: 2,
    medium: 3,
    mediumHigh: 4,
    high: 5,
};
