import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import EditButton from "../../../../utils/GenericComponents/buttons/EditButton";

import EditAutomation from "./EditAutomation";
import TestAutomation from "./TestAutomation";

/**
 * Component allowing to display the automation that is populating the metric type
 * @param {object} item - the metric type item
 * @param {string} organizationID - organization id of current selected organization
 * @param {function} resetFunction - callback to re-fetch parent data from the database on change
 * @param {boolean} hideTriggerButton - if TRUE will hide the trigger automation button
 * @return {JSX.Element}
 */
const MetricAutomation = ({ item, organizationID, resetFunction, hideTriggerButton = false }) => {
  const automation = item?.automation;
  const checkPermissionsHook = useCheckPermissions({
    module: modules.METRICS,
    resource: resources.METRIC_TYPE,
    field: "automation",
  });

  const editAutomationModal = useModal(
    "Edit Automation",
    <EditAutomation item={item} organizationID={organizationID} resetFunction={resetFunction} />,
    <EditButton color="ghost-warning" />,
  );

  return (
    <span>
      {automation && automation.name ? (
        <span>{convertCamelCaseToSentence(automation.name)}</span>
      ) : (
        <span style={{ fontStyle: "italic" }}>No Automation is set up for this Metric Type.</span>
      )}{" "}
      {checkPermissionsHook.field.update && editAutomationModal.modalButton}
      {item?.automation && item?.automation?.name && item?.enabled && hideTriggerButton === false && (
        <TestAutomation item={item} />
      )}
    </span>
  );
};

export default withOrganizationCheck(MetricAutomation);
