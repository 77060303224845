import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateCustomTemplate } from "../hooks/useCreateCustomTemplate";

/**
 * Component for creating or editing a custom template
 * @param props
 * @returns {*}
 */
const CreateCustomTemplate = (props) => {
  return useCreateCustomTemplate(props).display;
};

export default withOrganizationCheck(CreateCustomTemplate);
