import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function onChangeAutoDismissDuration for Interface Notifications Component
 * @param {object} preferences - a hook for getting and setting user preferences
 * @param {object} autoDismissDuration - auto dismiss duration in seconds
 * @param {function} setAutoDismissDuration - function for setting after how many seconds a notification will dismiss
 * @param {function} addToast - function for displaying a toast for the user
 */
export const onChangeAutoDismissDuration = ({ preferences, autoDismissDuration, setAutoDismissDuration, addToast }) => {
  if (isNullOrUndefined(preferences)) {
    throw new Error(`[onChangeAutoDismissDuration.js] Param: "preferences" is null`);
  }

  if (isNullOrUndefined(autoDismissDuration)) {
    throw new Error(`[onChangeAutoDismissDuration.js] Param: "autoDismissDuration" is null`);
  }

  if (isNullOrUndefined(setAutoDismissDuration)) {
    throw new Error(`[onChangeAutoDismissDuration.js] Param: "setAutoDismissDuration" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[onChangeAutoDismissDuration.js] Param: "addToast" is null`);
  }

  try {
    preferences?.setPreference("interfaceOptions", {
      autoDismissDuration: autoDismissDuration,
    });
  } catch (e) {
    addToast({
      header: `Error! Notifications Duration was NOT updated`,
      icon: "danger",
    });

    throw new Error("[onChangeAutoDismissDuration.js] Can not set Preferences");
  }

  setAutoDismissDuration(autoDismissDuration);

  addToast({
    header: `Notifications Duration Updated to ${autoDismissDuration} seconds`,
    icon: "success",
  });
};
