"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullName = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Restructures standard inputs from custom query executor into formattedName utility
 * @param {object} element - resource object representing a point of contact
 * @param {object} _params - the parameters for the function, see PointOfContact definition for parameter details
 * @return {string} - the full name of the point of contact
 */
const getFullName = ({ element: pointOfContact, _params }) => {
    return (0, func_utils_1.formattedName)({ pointOfContact });
};
exports.getFullName = getFullName;
