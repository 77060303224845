import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { updateRiskControlOverride } from "../../../Risk/Systems/components/Overrides/functions/OverrideFunctions";

/**
 * Displays the non-standard control implementation details
 * @param {object} item - non standard control data
 */
const NonStandardControlImplementationDetails = ({ item, implementationDetails, updateItemById } = {}) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "implementationDetails";

  const handleEdit = async (newValue) => {
    const system = item?.system;
    const riskControl = item?.control;
    const value = newValue?.[field];

    await updateRiskControlOverride(system, riskControl, field, value, async ({ input }) => {
      const { updateMutation } = generateGraphql("System");
      return await ItemMutation(updateMutation, input);
    });

    updateItemById && updateItemById(newValue);
  };

  return (
    <div>
      {item?.system && item?.control ? (
        <GenericEditFieldV3
          mutationFunction={handleEdit}
          item={item}
          field={field}
          module={module}
          resource={resource}
        />
      ) : (
        (implementationDetails ?? "")
      )}
    </div>
  );
};

export default NonStandardControlImplementationDetails;
