"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateListQueryGraphql = void 0;
const generateListQueryGraphql = ({ typename, nestedFields }) => {
    return `
    query List${typename}s(
      $filter: Model${typename}FilterInput
      $limit: Int
      $nextToken: String
    ) {
      list${typename}s(filter: $filter, limit: $limit, nextToken: $nextToken) {
        ${nestedFields}
      }
    }
  `;
};
exports.generateListQueryGraphql = generateListQueryGraphql;
