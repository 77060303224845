import React from "react";

import VendorReviewControlsReport from "../../../Reports/VendorReviewControlsReport/components/VendorReviewControlsReport";

/**
 * @description Generate VendorReviewControls Report
 * @param props
 * @returns {{display: JSX.Element}}
 */
export const useVendorReviewControlsReport = (props) => {
  const display = <VendorReviewControlsReport {...props} />;

  return {
    display,
  };
};
