import React, { useEffect } from "react";

import { useBoolean } from "../../../../../../../hooks/functional/useBoolean";
import { useDataGrid } from "../../../../../../../hooks/views/useDataGrid/useDataGrid";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { CONTROL_MAPPING_CONFIG } from "../constants/CONTROL_MAPPING_CONFIG";

/**
 * Data Grid for showing the results of a Control Mapping Export.
 * The 'Export to CSV' option in this data grid is the primary means to export the data to CSV
 *
 * @param {string[]} selectedIDs - IDs of selected Controls to generate a mapping for
 * @param {string} mappingType - Type of mapping to generate, from CONTROL_MAPPING_TYPES.js
 * @returns {JSX.Element}
 * @constructor
 */
const ControlMappingExportDataGrid = ({ selectedIDs, mappingType }) => {
  const [loading, setIsLoading] = useBoolean(false);

  const controlDataGrid = useDataGrid({
    fields: CONTROL_MAPPING_CONFIG[mappingType]?.fields || [],
    disableRoleChecking: true,
    typename: "Control",
  });

  const loadMappingData = async () => {
    setIsLoading(true);

    const data = await CONTROL_MAPPING_CONFIG?.[mappingType]?.mappingFunction({
      selectedIDs,
    });

    controlDataGrid.setData(data);

    setIsLoading(false);
  };

  useEffect(() => {
    if (mappingType && selectedIDs?.length > 0) {
      loadMappingData();
    }
  }, [mappingType]);

  return (
    <DataLoader isLoading={loading} isEnoughData={selectedIDs?.length > 0 && mappingType} dataMessage={""}>
      <div
        style={{
          height: "75vh",
        }}
      >
        {controlDataGrid.display}
      </div>
    </DataLoader>
  );
};

export default withOrganizationCheck(ControlMappingExportDataGrid);
