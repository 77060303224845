/**
 * @description contains labels, values, and types for different operators
 * @type {[{types: string[], label: string, value: string},{types: string[], label: string, value: string},{types: string[], label: string, value: string},{types: string[], label: string, value: string},{types: string[], label: string, value: string},null,null,null,null,null,null,null,null,null,null,null]}
 */
const operators = [
  {
    label: "equal to",
    value: "equal",
    types: ["integer", "number", "radio", "select", "switch", "text"],
  },
  {
    label: "not equal to",
    // value: "not_equal",
    value: "notequal",
    types: ["integer", "number", "radio", "select", "switch", "text"],
  },
  {
    label: "contains",
    value: "contains",
    types: ["text"],
  },
  {
    label: "does not contain",
    value: "notcontains",
    types: ["text"],
  },
  {
    label: "starts with",
    value: "startswith",
    types: ["text"],
  },
  {
    label: "less than",
    value: "lessthan",
    types: ["number", "integer", "date"],
  },
  {
    label: "greater than",
    value: "greaterthan",
    types: ["number", "integer", "date"],
  },
  {
    label: "less than or equal to",
    value: "lessthanorequal",
    types: ["number", "integer"],
  },
  {
    label: "greater than or equal to",
    value: "greaterthanorequal",
    types: ["number", "integer"],
  },
  {
    label: "in",
    value: "in",
    types: ["multiselect"],
  },
  {
    label: "not in",
    // value: "not_in",
    value: "notin",
    types: ["multiselect"],
  },
  {
    label: "is null",
    // value: "null",
    value: "isnull",
    types: ["date", "integer", "number", "multiselect", "radio", "select", "switch", "text"],
  },
  {
    label: "is not null",
    // value: "not_null",
    value: "isnotnull",
    types: ["date", "integer", "number", "multiselect", "radio", "select", "switch", "text"],
  },
];

export default operators;
