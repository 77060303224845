import moment from "moment/moment";

import { DateRangeShortcut } from "@hooks/views/useForm/components/CustomDateRangeInput/constants/DateRangeShortcut";
import { MomentDateRange } from "@hooks/views/useForm/components/CustomDateRangeInput/types";

export const getShortcutDates = (shortcut: DateRangeShortcut): MomentDateRange => {
  switch (shortcut) {
    case DateRangeShortcut.CURRENT_YEAR:
      return [moment().startOf("year"), moment().endOf("year")];
    case DateRangeShortcut.CURRENT_QUARTER:
      return [moment().startOf("quarter"), moment().endOf("quarter")];
    case DateRangeShortcut.CURRENT_MONTH:
      return [moment().startOf("month"), moment().endOf("month")];
    case DateRangeShortcut.LAST_YEAR:
      return [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")];
    case DateRangeShortcut.LAST_QUARTER:
      return [moment().subtract(1, "quarter").startOf("quarter"), moment().subtract(1, "quarter").endOf("quarter")];
    case DateRangeShortcut.LAST_MONTH:
      return [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
    case DateRangeShortcut.RESET:
      return [null, null];
    default:
      return [null, null];
  }
};
