import { useEffect } from "react";

/**
 * Populates the list of all Organizations' resources and not including resources that are already linked to the item
 *
 * @param {Control} item - the parent item that resources are being linked to
 * @param {function} setItems - handler for setting the available resources
 * @param {object[]} existingItems - the organization's resources that are available for linking
 * @param {string} field - the item field that holds the connection object
 * @param {string} nestedField - the connection object field that holds the child object
 * @param {function} [normalizeLinkedItems] - a function to edit the currently linked items before they are displayed
 */
export const usePopulateExistingResources = (
  item,
  setItems,
  existingItems,
  field,
  nestedField,
  normalizeLinkedItems,
) => {
  return useEffect(() => {
    let existingLinkedResources = item?.[field]?.items?.map((link) => {
      return { ...link[nestedField], link };
    });

    if (normalizeLinkedItems) {
      existingLinkedResources = normalizeLinkedItems(existingLinkedResources);
    }

    if (!Array.isArray(existingLinkedResources)) {
      existingLinkedResources = [];
    }

    if (
      existingItems &&
      Array.isArray(existingItems) &&
      existingLinkedResources &&
      Array.isArray(existingLinkedResources)
    ) {
      const data = [];

      // don't include items that are already linking in the 'Available Resources' list
      for (const item of existingItems) {
        if (!existingLinkedResources?.some((a) => a.id === item.id)) {
          data.push(item);
        }
      }

      setItems(data);
    }
  }, [existingItems, item]);
};
