import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";

export const createRiskControlChangeLink = (riskControl, change) => {
  if (riskControl?.id && change?.id) {
    const { createMutation } = generateGraphql("RiskControlChangeLink");

    return ItemMutation(createMutation, {
      riskControlID: riskControl.id,
      riskChangeID: change.id,
      ownerGroup: riskControl.ownerGroup || change.ownerGroup,
    });
  }
};
