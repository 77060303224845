import { useBlueprintDataGrid } from "../../../../views/Program/VCISOMaturityMatrix/components/MaturityMatrix/hooks/useBlueprintDataGrid";

export const blueprintDefinition = {
  routes: {
    grid: "governance/blueprints",
    details: "governance/blueprints",
  },

  //[APPEARANCE]
  icon: "akar-icons:map",

  //[COMPONENTS]
  gridHook: useBlueprintDataGrid,
};
