import { generateGraphql } from "@rivial-security/generategraphql";

import { TargetVulnerabilityHistoryType } from "../../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Checks if the status of target vulnerability link should change, mutates the link, updates history
 * @param {object} oldItem - item with status and targetFindingLinkID fields of the link before the change
 * @param {object} newItem - item with status and targetFindingLinkID fields of the link after the change
 * @return {Promise<void>}
 */
export const addManualStatusChange = async ({ oldItem, newItem }) => {
  const updatedLink = await ItemMutation(updateTargetFindingLink, {
    id: oldItem?.targetFindingLinkID,
    status: newItem?.status,
  });

  await ItemMutation(createTargetVulnerabilityHistory, {
    targetVulnerabilityLinkID: updatedLink?.id,
    type: TargetVulnerabilityHistoryType.STATUS,
    description: `Status changed from ${oldItem?.status} to ${updatedLink?.status}`,
    date: new Date().toISOString(),
    ownerGroup: updatedLink?.ownerGroup,
    oldStatus: oldItem?.status,
    newStatus: newItem?.status,
  });
};

const { createMutation: createTargetVulnerabilityHistory } = generateGraphql("TargetVulnerabilityHistory", [
  "id",
  "type",
  "assessmentID",
  "description",
  "date",
  "targetVulnerabilityLinkID",
  "oldStatus",
  "newStatus",
]);

const { updateMutation: updateTargetFindingLink } = generateGraphql(
  "TargetFindingLink",
  ["id", "status", "targetID", "vulnerabilityID", "ownerGroup", "falsePositive", "history"],
  {
    history: `(limit: 1000) {
      items {
        id
        type
        ownerGroup
        description
        oldStatus
        newStatus
        date
        comment
        data
        targetVulnerabilityLinkID
      }
    }`,
  },
);
