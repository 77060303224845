import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useUIContext } from "@utils/Context/UIContext";
import { InfoLogger } from "@utils/EventLogger";

import { getMany } from "../../utils/Functions/getMany";
import { duplicateItem } from "../../utils/Functions/Graphql/duplicateItem";
import { duplicateItemConnections } from "../../utils/Functions/Graphql/duplicateItemConnections";
import { ItemQuery } from "../../utils/Functions/Graphql/ItemQuery";
import ClipboardButton from "../../utils/GenericComponents/buttons/ClipboardButton";
import Json from "../../utils/GenericComponents/Json";

import { useForm } from "./useForm/useForm";
import { useModal } from "./useModal";
import { usePleaseWaitModal } from "./usePleaseWaitModal";

/**
 * For facilitating the duplication of an array of items.
 * Presents a selection modal with a confirmation button,
 * then a please wait modal with progress bar.
 *
 * @param {object[]} items - the items to duplicate
 * @param {string} typename - typename of the items to duplicate
 * @param {object} duplicationSettings - settings for the duplication
 * @param {boolean} [duplicationSettings.enabled = false] - needs to be true to process duplication
 * @param {string} duplicationSettings.description - text that describes what exactly will be duplicated, users see it during confirmation prompt
 * @param {array} duplicationSettings.fields - an array of fields to duplicate. used in graphql
 * @param {object} duplicationSettings.nestedFields - nested field object, follows generateGraphql syntax. used in graphql
 * @param {object} duplicationSettings.fieldAdaptor - a mapping of field name to function that adapts a field for the mutation
 * @param {string} duplicationSettings.primaryField - a String field that automatically gets appended with ' (duplicated)'
 * @param {function} duplicationSettings.inputAdaptor - a function that may update the input right before the mutation
 * @param {object} duplicationSettings.connectionAdaptor - a mapping of field name to object that configures connection fields
 * @param {function} duplicationSettings.connectionAdaptor[field].mutationFunction - function that handles the connection field, overrides other connectionAdapter props
 * @param {string} duplicationSettings.connectionAdaptor[field].connectionTypename - the Typename for the connected objects that will be created
 * @param {string} duplicationSettings.connectionAdaptor[field].itemConnectionIDField - the ID field that links the new connected objects back to the parent
 * @param {string} duplicationSettings.connectionAdaptor[field].childConnectionIDField - the ID field that links the new connected objects to another object for many-to-many connections
 * @param {string} duplicationSettings.connectionAdaptor[field].childConnectionField - the resolver field that links the new connected objects to another object for many-to-many
 * @param {string[]} duplicationSettings.connectionAdaptor[field].fields - an array of fields on the many-to-many connection model to be copied over
 * @param organizationID
 * @param resetFunction
 */
export const useDuplicateItems = ({
  items = [],
  typename,
  duplicationSettings = {},
  organizationID,
  resetFunction,
}) => {
  const pleaseWaitModal = usePleaseWaitModal({
    confirmationText: "🥳 Duplication Successful!",
    autoClose: true,
  });

  const { addToast } = useUIContext();

  const submitFunction = async () => {
    InfoLogger("Beginning Duplication..");
    pleaseWaitModal.setSteps([{ id: "step1", text: `Duplicating ${items.length} ${typename}s..` }]);
    pleaseWaitModal.setTotalProgress(items.length);
    pleaseWaitModal.setModalIsOpen(true);

    const { getQuery } = generateGraphql(typename, duplicationSettings?.fields, duplicationSettings?.nestedFields);

    const promises = [];

    // handle root fields first
    for (const item of items) {
      InfoLogger(`Processing Item: ${JSON.stringify(item)}`);

      const fullItem = await ItemQuery(getQuery, item.id);

      let input = {};

      // add '(duplicated)' text if primaryField is supplied
      if (
        duplicationSettings?.primaryField &&
        duplicationSettings?.fields?.includes(duplicationSettings.primaryField) &&
        !isNullOrUndefined(fullItem[duplicationSettings.primaryField])
      ) {
        input[duplicationSettings.primaryField] = `${fullItem[duplicationSettings.primaryField]} (duplicated)`;
      }

      for (const [key, func] of Object.entries(duplicationSettings?.fieldAdaptor || {})) {
        input[key] = func(fullItem);
      }

      if (!isNullOrUndefined(duplicationSettings?.inputAdaptor)) {
        input = duplicationSettings.inputAdaptor(input, fullItem);
      }

      promises.push(
        duplicateItem({
          item: fullItem,
          organizationID,
          typename,
          input,
        }).then((newItem) => {
          if (duplicationSettings?.connectionAdaptor) {
            InfoLogger("Connection Adaptor found");

            const connectionFieldPromises = [];

            for (const [field, connectionAdaptor] of Object.entries(duplicationSettings.connectionAdaptor)) {
              if (!isNullOrUndefined(field) && !isNullOrUndefined(connectionAdaptor)) {
                InfoLogger(`Processing Connections for: ${field}`);

                const {
                  connectionTypename,
                  itemConnectionIDField,
                  childConnectionField,
                  childConnectionIDField,
                  fields,
                  mutationFunction,
                } = connectionAdaptor;

                // gets all connections
                const many = getMany(fullItem, field, childConnectionField, fields);

                const connectionMutationPromise = duplicateItemConnections(
                  connectionTypename,
                  newItem,
                  many,
                  itemConnectionIDField,
                  childConnectionIDField,
                  organizationID,
                  fields,
                  mutationFunction,
                );

                connectionFieldPromises.push(connectionMutationPromise);
              }
            }

            return Promise.allSettled(connectionFieldPromises);
          }
        }),
      );

      pleaseWaitModal.incrementProgress();
    }

    // after all mutations are complete
    Promise.allSettled(promises).then(() => {
      pleaseWaitModal.setModalIsOpen(false);
      pleaseWaitModal.setProgress(0);
      pleaseWaitModal.setTotalProgress(0);
      resetFunction?.();
      modal.setModalIsOpen(false);
      addToast({
        color: "success",
        header: `🥳 Successfully duplicated ${items?.length} ${typename}s!`,
      });
    });
  };

  // form used for confirmation
  const form = useForm({
    fieldConfig: {},
    disableResetButton: true,
    submitFunction: duplicationSettings?.enabled ? submitFunction : undefined,
    header: (
      <h4>
        Are you sure you want to duplicate {items?.length} {typename}s?
      </h4>
    ),
  });

  const display = (
    <div>
      {duplicationSettings?.description}
      <details style={{ marginTop: "1em", marginBottom: "1em" }}>
        <summary>View Fields</summary>
        <Json data={duplicationSettings.fields} />
      </details>
      <details style={{ marginTop: "1em", marginBottom: "1em" }}>
        <summary>
          Preview selected items
          <ClipboardButton data={JSON.stringify(items)} tooltip={"Copy selected items to the clipboard as JSON"} />
        </summary>
        <Json data={items} />
      </details>
      {form.display}
      {pleaseWaitModal.modal}
    </div>
  );

  const modal = useModal(`Duplicate ${items.length} ${typename}s`, display);

  return {
    ...modal,
    display,
  };
};
