import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const TestingDashboard = React.lazy(() => import("@views/Testing/Dashboard/components/TestingDashboard"));
const AssessmentsList = React.lazy(() => import("@views/Testing/Assessments/components/AssessmentDataGrid"));
const AssessmentDetailsCard = React.lazy(() => import("@views/Testing/Assessments/components/AssessmentDetails"));
const TargetsList = React.lazy(() => import("@views/Testing/Targets/components/TargetsGrid"));
const TargetDetailsCard = React.lazy(() => import("@views/Testing/Targets/components/TargetDetails"));
const VulnerabilityDetails = React.lazy(() => import("@views/Testing/Vulnerabilities/components/VulnerabilityDetails"));
const VulnerabilityDataGrid = React.lazy(
  () => import("@views/Testing/Vulnerabilities/components/VulnerabilityDataGrid"),
);

export const vulnerabilitiesNavigation: NavigationModule = {
  moduleName: modules.VULNERABILITIES,
  sectionName: NavigationSectionName.SERVICES,
  name: "Vulnerabilities",
  path: "/vulnerabilities",
  deprecatedPaths: ["/testing"],
  defaultRoutePath: "/vulnerabilities",
  icon: IconName.TESTING,
  routes: [
    {
      path: "/dashboard",
      name: "Vulnerabilities Dashboard",
      component: TestingDashboard,
      navigationBar: {
        name: "Dashboard",
      },
    },
    {
      path: "/assessments",
      name: "Assessments",
      component: AssessmentsList,
    },
    {
      path: "/assessments/:id",
      name: "Assessment Details",
      component: AssessmentDetailsCard,
    },
    {
      path: "/targets",
      name: "Targets",
      component: TargetsList,
    },
    {
      path: "/targets/:id",
      name: "Target Details",
      component: TargetDetailsCard,
    },
    {
      path: "/vulnerabilities",
      deprecatedPaths: ["/vulnerability_groups"],
      name: "Vulnerabilities",
      component: VulnerabilityDataGrid,
    },
    {
      path: "/vulnerabilities/:id",
      deprecatedPaths: ["/vulnerability_groups/:id"],
      name: "Vulnerability Details",
      component: VulnerabilityDetails,
    },
  ],
};
