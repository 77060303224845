import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useSystemDetails } from "../hooks/useSystemDetails/useSystemDetails";

/**
 * Component instance of the useSystemDetails hook
 * @param props
 * @returns {*}
 * @constructor
 */
const SystemDetails = (props) => {
  return useSystemDetails(props).display;
};

export default withDetailsPage(SystemDetails, { title: "System Details" });
