import { formattedName } from "@rivial-security/func-utils";

/**
 * Displays the point of contact for an artifact
 * @param {object} item - the artifact object with point of contact information extracted from its evidence activity
 * @returns {JSX.Element}
 */
const ArtifactPointOfContactCustomField = ({ item }) => {
  return <div>{formattedName({ pointOfContact: item?.pointOfContact })}</div>;
};

export default ArtifactPointOfContactCustomField;
