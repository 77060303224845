import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateMetricType } from "../hooks/useCreateMetricType";

/**
 * Component instance of the useCreateMetricType hook
 * @param {object} props
 * @param {function} [props.toggleModal]
 * @returns {JSX.Element}
 * @constructor
 */
const CreateMetricType = (props) => {
  const fields = ["name", "metrics", "automation"];
  const nestedFields = {
    metrics: "(limit: 200) {items {id name description date data { value format }}}",
    automation: `{ name description jsonPath config fetchConfig { url apiKey } }`,
  };

  return useCreateMetricType({ ...props, fields, nestedFields }).display;
};

export default withOrganizationCheck(CreateMetricType);
