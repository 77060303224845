/**
 * @description The AV status of a document
 */
export const AV_STATUS = {
  PENDING: "pending",
  CLEAN: "clean",
  INFECTED: "infected",
  FILE_TOO_BIG: "fileTooBig",
  ERROR_PROCESSING_FILE: "errorProcessingFile",
};
