import { Icon } from "@iconify/react";
import { Button } from "@mui/material";

import { useModal } from "../../../../../../../hooks/views/useModal";
import { useCustomQueryNestedFieldDetails } from "../hooks/useCustomQueryDetails/useCustomQueryNestedFieldDetails";

/**
 * A button that opens a modal with the details of a nested field from a query
 * @param {object} item - the entire parent object containing the field to be displayed
 * @param {string} field - the field name of the property in item containing the details data
 * @param {object} query - the part of custom query object from query builder that defines the nested field
 * @return {JSX.Element}
 */
const CustomQueryNestedFieldDetails = ({ item, field, query }) => {
  const details = useCustomQueryNestedFieldDetails({
    query,
    item: item?.[field],
  });

  const modal = useModal(
    "Nested Resources",
    details.display,
    <Button
      style={{
        fontSize: "0.8125rem",
      }}
      startIcon={<Icon icon={"clarity:info-standard-line"} />}
    >
      {"Details"}
    </Button>,
    {
      width: "80vw",
    },
  );

  return modal?.modalButton;
};

export default CustomQueryNestedFieldDetails;
