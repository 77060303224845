import { useIncidentDataGrid } from "../../../../views/Response/Incidents/hooks/useIncidentDataGrid";

export const incidentDefinition = {
  routes: {
    grid: "response/incidents",
    details: "response/incidents",
    helpCenter: "incident-response-incidents",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:flag",

  //[COMPONENTS]
  gridHook: useIncidentDataGrid,
};
