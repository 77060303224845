/**
 * An enum defining the types of schema objects
 * - MODEL a regular schema object with its own table per environment
 * - TYPE a object without a model but also not an enum
 * - ENUM a list of possible values for a field
 * @type {{ENUM: string, MODEL: string, TYPE: string}}
 */
export const schemaEntities = {
  MODEL: "Model",
  ENUM: "Enum",
  TYPE: "Type",
  GENERIC: "Generic",
};
