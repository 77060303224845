import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import TabCard from "../../../../utils/GenericComponents/TabCard";
import { useRecordEvidence } from "../hooks/useRecordEvidence";

import { useEvidenceDocuments } from "./EvidenceDocuments";
import { useEvidenceMeetings } from "./EvidenceMeetings";
import { useEvidencePhishing } from "./EvidencePhishing";
import { useEvidencePolicies } from "./EvidencePolicies";
import { useEvidenceReports } from "./EvidenceReports";
import { useEvidenceTrainings } from "./EvidenceTrainings";

/**
 * Displays resources associated with an Evidence in a TabCard format
 * @param {object} item - evidence data
 * @param {string} organizationID - currently selected organization
 * @param {function} resetFunction - reset function to get most up to date "item" after a change
 * @returns {JSX.Element} - UI to show evidence artifacts in grids
 */
const TabbedEvidenceCard = ({ item, organizationID, resetFunction }) => {
  const recordEvidence = useRecordEvidence({
    evidence: item,
    organizationID,
    resetFunction,
  });

  const documents = useEvidenceDocuments({ item, recordEvidence });
  const policies = useEvidencePolicies({ item, recordEvidence });
  const reports = useEvidenceReports({ item, recordEvidence });
  const meetings = useEvidenceMeetings({ item, recordEvidence });
  const trainings = useEvidenceTrainings({
    item,
    recordEvidence,
    organizationID,
  });
  const phishing = useEvidencePhishing({
    item,
    recordEvidence,
    organizationID,
  });

  return (
    <TabCard
      sx={{
        tabContentContainer: {
          height: "40em",
        },
        tabPanels: {
          height: "100%",
        },
      }}
      headerButtons={
        recordEvidence?.button
          ? [
              <div>
                {/*Need both modal and button because button is not passed in to the modal now*/}
                {recordEvidence?.button}
                {recordEvidence?.modal}
              </div>,
            ]
          : []
      }
      item={item}
      items={[
        {
          title: "Documents",
          component: documents.display,
          total: documents?.data?.length,
          icon: <i className={"icon-doc"} />,
        },
        {
          title: "Policies",
          component: policies.display,
          total: policies?.data?.length,
          icon: <i className={"icon-docs"} />,
        },
        {
          title: "Reports",
          component: reports.display,
          total: reports?.data?.length,
          icon: <i className={"icon-film"} />,
        },
        {
          title: "Meetings",
          component: meetings.display,
          total: meetings?.data?.length,
          icon: <i className={"icon-calendar"} />,
        },
        {
          title: "Trainings",
          component: trainings.display,
          total: trainings?.data?.length,
          icon: <i className={"icon-note"} />,
        },
        {
          title: "Phishing",
          component: phishing.display,
          total: phishing?.data?.length,
          icon: <i className={"icon-present"} />,
        },
      ]}
    />
  );
};

export default withOrganizationCheck(TabbedEvidenceCard);
