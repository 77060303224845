/**
 * Author: Bryan Caesar
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *
 * Description: ?
 *
 * TODO: Add description @bryan
 */

const AlertDefaultResults = (successfulRows, failedRows) => {
  const successMessage = `Process succeeded for  ${successfulRows.length} rows.`;
  const failedMessage = `Process failed for  ${failedRows.length} rows.`;
  let message = `Process Finished!\n${successMessage}`;

  if (failedRows.length > 0) message += `\n${failedMessage}`;

  alert(message);
};

export default AlertDefaultResults;
