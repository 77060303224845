import { passwordLength } from "../../env-config";

/**
 * @description Generate a password based on the passwordLength variable
 * @param {number} length - The length of the password to generate
 * @returns {string}
 * @constructor
 */
export const PasswordGenerator = (length = passwordLength) => {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz",
    uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    numericChars = "0123456789",
    specialChars = "@!-#$%^&+=";

  const allChars = lowercaseChars + uppercaseChars + numericChars + specialChars;
  let password = "";

  // Ensure at least one character from each category
  password += getRandomChar(lowercaseChars);
  password += getRandomChar(uppercaseChars);
  password += getRandomChar(numericChars);
  password += getRandomChar(specialChars);

  // Generate the remaining characters
  for (let i = 4; i < length; i++) {
    password += getRandomChar(allChars);
  }

  // Shuffle the password to randomize the positions of the required characters
  password = shuffle(password.split("")).join("");

  return password;
};
function getRandomChar(characters) {
  const randomIndex = Math.floor(Math.random() * characters.length);
  return characters[randomIndex];
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
