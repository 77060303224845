import { Alert } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Badge, Button, ListGroup, ListGroupItem, Progress, Spinner } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { tryFunction } from "../../utils/Functions/tryFunction";
import { LoadingSpinner } from "../../utils/LoadingComponents/LoadingSpinner";
import { useJobsTracker } from "../graphql/useJobTracker/useJobsTracker";

import { useModal } from "./useModal";

export const STEP_STATUS = {
  COMPLETE: "complete",
  IN_PROGRESS: "inProgress",
  WAITING: "waiting",
  ERROR: "error",
  SKIPPED: "skipped",
};

/**
 * Displays "Please wait" with a Spinner with ClickToggle disabled
 * @param {object} input - parameters passed into the hook
 * @param {object[]} input.initialSteps - an array of step configuration objects
 * @param {string} input.initialSteps[].text - the text to display for a step
 * @param {string} input.initialSteps[].status - the current status of a step [complete, inProgress, waiting]
 * @param {(JSX.Element|string)} input.confirmationText - the text to display when the operation is finished
 * @param {boolean} [input.autoClose] - determines if the modal should auto-close itself when complete
 * @param {string} [input.modalButton] - for overriding the default modal button
 * @param {number} [input.autoCloseInterval] - amount of seconds to wait before the modal auto-closes, if enabled
 * @param {number} [input.progressTotal] - if greater than 0 displays a progress bar
 * @param {string} [input.subTitle] - optional italic text to place near the top to describe the operation
 * @param {function}[input.toggleModal] - for toggling an external modal when complete
 * @param {function} [input.resetFunction] - for resetting the parent data state when complete
 * @param {string} [input.width = '40vw'] - the width of the modal
 * @param {function} [input.onClosed] - function to execute when the please wait modal is closed
 * @param {boolean} [input.disableCloseModal]=false - if TRUE user cannot close the modal
 */
export const usePleaseWaitModal = ({
  steps: initialSteps = [],
  confirmationText = "",
  autoClose: autoCloseInit = false,
  modalButton = "",
  autoCloseInterval: autoCloseIntervalInit = 5, // amount of seconds,
  progressTotal = 0,
  subTitle: subTitleInit = "",
  toggleModal,
  resetFunction,
  width = "40vw",
  onClosed,
  disableCloseModal = false,
} = {}) => {
  /**
   * @typedef PleaseWaitStep
   * @property {string} text - the text to display for a step
   * @property {string} status - the current status of a step
   */
  const [steps, setSteps] = useState(initialSteps);
  const [errorMessage, setErrorMessage] = useState("");
  const [finished, setFinished] = useState(false);
  const [autoClose, setAutoClose] = useState(autoCloseInit);
  const [autoCloseInterval, setAutoCloseInterval] = useState(autoCloseIntervalInit);
  const [subTitle, setSubTitle] = useState(subTitleInit);

  // The total number of progress steps to create a progress percentage
  const [totalProgress, setTotalProgress] = useState(progressTotal);

  // The current progress number to create a progress percentage
  const [progress, setProgress] = useState(0);

  /**
   * On closing modal, reset the state of please wait modal
   */
  const resetAll = () => {
    setTotalProgress(0);
    setFinished(false);
    setSteps((steps) => {
      if (Array.isArray(steps)) {
        const newSteps = cloneDeep(steps);
        for (const step of newSteps) {
          step.status = STEP_STATUS.WAITING;
        }
        return newSteps;
      } else {
        return [];
      }
    });
  };

  /**
   * Increments the current progress by 1
   */
  const incrementProgress = () => {
    setProgress((p) => p + 1);
  };

  /**
   * This functions prevents users from reloading or exiting the page
   */
  const disableUnload = () => {
    onbeforeunload = (e) => "Don't leave";
  };

  /**
   * This function allows users to reload or exit the page
   */
  const enableUnload = () => {
    onbeforeunload = null;
  };

  const finishWithError = ({ errorMessage = "The process encountered an error!" } = {}) => {
    //Mark any in progress steps as errors
    setSteps((steps) =>
      steps.map((step) => {
        if (step.status === STEP_STATUS.IN_PROGRESS) {
          return { ...step, status: STEP_STATUS.ERROR };
        }
        return step;
      }),
    );

    //Set the error message and reset auto close
    setAutoClose(false);
    setErrorMessage(errorMessage);
    setFinished(true);
  };

  const setStepStatus = (index, status) => {
    setSteps((steps) => {
      const tempSteps = [...steps];
      if (!isNullOrUndefined(tempSteps[index])) {
        tempSteps[index].status = status;
      }
      return [...tempSteps];
    });
  };

  const setStepsStatusById = ({ ids, status, startNextStep = false }) => {
    let lastModifiedStep = -1;
    if (Array.isArray(ids)) {
      for (const id of ids) {
        // Find the step index with the matching id
        let stepIndex = -1;
        if (Array.isArray(steps)) {
          stepIndex = steps.findIndex((step) => step.id === id);
        }

        // Set the step status
        if (stepIndex !== -1) {
          setStepStatus(stepIndex, status);
          if (lastModifiedStep < stepIndex) {
            lastModifiedStep = stepIndex;
          }
        }
      }
    }

    // If any step was modified check to see if the next step should be started
    const nextStep = lastModifiedStep + 1;
    if (lastModifiedStep !== -1 && startNextStep && nextStep < steps.length) {
      setStepStatus(nextStep, STEP_STATUS.IN_PROGRESS);
    }
  };

  /**
   * Track list of jobs
   */
  const jobsTracker = useJobsTracker();

  /**
   * If there are any jobs, disable auto-close
   */
  useEffect(() => {
    if (jobsTracker?.jobs?.length > 0) {
      setAutoClose(false);
    }
  }, [jobsTracker?.jobs]);

  const modal = useModal(
    "",
    <div>
      {jobsTracker?.jobs?.length > 0 ? (
        jobsTracker.display
      ) : (
        <div style={{ textAlign: "center" }}>
          <h1>{finished ? (errorMessage ? "Error!" : "Complete!") : "Please wait"}</h1>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {subTitle && <i>{subTitle}</i>}
          {totalProgress > 0 && (
            <span style={{ marginBottom: "1em" }}>
              <Progress value={progress} max={totalProgress} />
            </span>
          )}
          {steps && steps.length > 0 && <PleaseWaitSteps steps={steps} />}
          {finished ? (
            <div style={{ marginTop: "2em" }}>
              <h5>{confirmationText}</h5>
              <br />
              <Button onClick={() => modal.setModalIsOpen(false)}>
                Close
                {autoClose && ` (${autoCloseInterval})`}
              </Button>
            </div>
          ) : (
            <Spinner style={{ width: "3rem", height: "3rem", marginTop: "1em" }} color="primary" />
          )}
        </div>
      )}
    </div>,
    modalButton,
    {
      width,
      disableClickToggle: true,
      dataTestId: "please-wait-modal",
      onClosed: () => {
        tryFunction(onClosed);
      },
      disableCloseModal,
    },
  );

  /**
   * Prevent users from reloading or exiting the page when this modal is open
   */
  useEffect(() => {
    if (modal.modalIsOpen) {
      disableUnload();
    } else {
      enableUnload();
    }
  }, [modal.modalIsOpen]);

  /**
   * Handle the Auto-Close functionality
   */
  useEffect(() => {
    if (autoClose && finished) {
      if (autoCloseInterval > 0) {
        setTimeout(() => {
          setAutoCloseInterval((autoCloseInterval) => autoCloseInterval - 1);
        }, 600);
      } else {
        modal.setModalIsOpen(false);
        tryFunction(resetAll, toggleModal, resetFunction);
      }
    }
  }, [finished, autoCloseInterval]);

  /**
   * Sets a particular step to IN_PROGRESS, executes the handler, and then sets the step to COMPLETE or ERROR
   * @param {string} id - ID of the step to execute
   * @param {function} handler - async handler function to execute. If the handler throws an error, the step will be set to ERROR
   * @returns {Promise<void>}
   */
  const handleStepExecution = async ({ id, handler }) => {
    try {
      setStepsStatusById({
        ids: [id],
        status: STEP_STATUS.IN_PROGRESS,
      });
      await handler();
      setStepsStatusById({
        ids: [id],
        status: STEP_STATUS.COMPLETE,
      });
    } catch (error) {
      setStepsStatusById({
        ids: [id],
        status: STEP_STATUS.ERROR,
      });
    }
  };

  return {
    finished,
    setFinished,
    finishWithError,
    steps,
    setSteps,
    setStepStatus,
    setStepsStatusById,
    autoClose,
    autoCloseInterval,
    setAutoCloseInterval,
    incrementProgress,
    setProgress,
    progress,
    setTotalProgress,
    totalProgress,
    setSubTitle,
    handleStepExecution,
    addJob: jobsTracker.addJob,
    ...modal,
  };
};

const PleaseWaitSteps = ({ steps }) => {
  return (
    <ListGroup>
      {steps &&
        steps.map((step, index) => {
          step = { ...step, id: step?.id || index };
          return <PleaseWaitStep key={JSON.stringify(step)} step={step} />;
        })}
    </ListGroup>
  );
};

/**
 * Displays a single step in the PleaseWaitModal
 * @param {object} step - a step configuration object
 * @param {string} step.text - the text to display for a step
 * @param {string} step.status - the current status of a step [complete, inProgress, waiting]
 * @returns {JSX.Element}
 * @constructor
 */
const PleaseWaitStep = ({ step }) => {
  return (
    <ListGroupItem key={step?.id}>
      {(() => {
        switch (step.status) {
          case STEP_STATUS.COMPLETE:
            return (
              <Badge color="success">
                <i className="icon-check" />
              </Badge>
            );
          case STEP_STATUS.IN_PROGRESS:
            return (
              <Badge color="warning">
                <LoadingSpinner />
              </Badge>
            );
          case STEP_STATUS.WAITING:
            return (
              <Badge color="secondary">
                <i className="icon-options" />
              </Badge>
            );
          case STEP_STATUS.ERROR:
            return (
              <Badge color="danger">
                <i className="icon-close" />
              </Badge>
            );
          case STEP_STATUS.SKIPPED:
            return (
              <Badge color="primary">
                <i className="icon-close" />
              </Badge>
            );
          default:
            return (
              <Badge color="secondary">
                <i className="icon-options" />
              </Badge>
            );
        }
      })()}
      <span style={{ marginLeft: "1em", marginTop: "1em" }}>{step.text}</span>
    </ListGroupItem>
  );
};
