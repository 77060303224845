"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetricsForMetricType = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Gets a list of metric entries for a single metric type
 * @param {object} metricTypeId - the metric type id information with id property defined
 * @return {Promise<object[]|null>}
 */
const getMetricsForMetricType = async ({ metricTypeId } = {}) => {
    if (!metricTypeId)
        return null;
    return await (0, appsync_utils_1.ListQueryBy)({
        query: listMetricsByMetricType,
        variables: {
            metricTypeID: metricTypeId,
            sortDirection: "ASC",
        },
        limit: 1000,
    });
};
exports.getMetricsForMetricType = getMetricsForMetricType;
const listMetricsByMetricType = /* GraphQL */ `
  query MetricsByMetricType(
    $metricTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metricsByMetricType(
      metricTypeID: $metricTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        data {
          value
        }
      }
    }
  }
`;
