import AWS from "aws-sdk";

import { ErrorLogger } from "../../EventLogger";

import { GetOrgIamCredentials } from "./GetOrgIamCredentials";

/**
 * @description Upload a S3 object to a bucket
 * @param {string} bucket - bucket name to upload
 * @param {string} key - object key to upload
 * @param {string} body - object body to upload
 * @param {string} contentType - object content type to upload
 * @param {string} organizationID - organization ID to upload
 * @returns {Promise<*>}
 */
export const uploadS3Object = async ({ bucket, key, body, contentType, organizationID } = {}) => {
  /**
   * Validate the inputs
   */
  if (!bucket) {
    ErrorLogger("[uploadS3Object.js] No bucket name provided");
    return;
  }

  if (!key) {
    ErrorLogger("[uploadS3Object.js] No object key provided");
    return;
  }

  if (!body) {
    ErrorLogger("[uploadS3Object.js] No body provided");
    return;
  }

  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: "us-west-2",
  });

  /**
   * Upload the object
   */
  const params = {
    Bucket: bucket,
    Key: key,
    Body: body,
    ContentType: contentType,
  };

  return await s3.upload(params).promise();
};
