import React from "react";

import { CsvImporterModalButton } from "../../../../hooks/views/useCsvImporter";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorControlCategoryDataGrid } from "../hooks/useVendorControlCategoryDataGrid";

/**
 * Importer button for Vendor Control Categories.
 *
 * Defaults to adding 'isTemplate: true' and 'vendorReviewID": placeholder to the mutation
 *
 * @param {string} organizationID - organization id
 * @returns {JSX.Element}
 * @constructor
 */
const VendorControlCategoryImporterModalButton = ({ organizationID }) => {
  const fields = [
    {
      field: "name",
      name: "Name",
      required: true,
      width: 600,
    },
  ];

  const typename = "VendorControlCategory";

  const preProcessRow = async (row) => {
    return {
      ...row,
      isTemplate: true,
      vendorReviewID: "placeholder",
    };
  };

  return (
    <CsvImporterModalButton
      fields={fields}
      typename={typename}
      resultGrid={useVendorControlCategoryDataGrid}
      organizationID={organizationID}
      preProcessRow={preProcessRow}
    />
  );
};

export default withOrganizationCheck(VendorControlCategoryImporterModalButton);
