"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamoDatabaseIndexMap = void 0;
const evidenceIndexes_1 = require("./modules/Compliance/evidenceIndexes");
const systemIndexes_1 = require("./modules/Risk/systemIndexes");
const vulnerabilityIndexes_1 = require("./modules/Vulnerabilities/vulnerabilityIndexes");
exports.dynamoDatabaseIndexMap = {
    ["Vulnerability"]: vulnerabilityIndexes_1.vulnerabilityIndexes,
    ["Evidence"]: evidenceIndexes_1.evidenceIndexes,
    ["System"]: systemIndexes_1.systemIndexes,
};
