"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllSystemsFromRiskControl = void 0;
const isRiskControlNonStandard_1 = require("../../../createMonteCarloSystemInput/functions/isRiskControlNonStandard");
const getAllSystemsFromRiskControl = (riskControl) => {
    /**
     * Traverse RiskControl object to obtain all associated systems
     */
    if (riskControl !== null &&
        riskControl !== undefined &&
        typeof riskControl === "object" &&
        riskControl.id !== null &&
        riskControl.id !== undefined) {
        const controlCategory = riskControl.controlCategory;
        const systemLinks = controlCategory?.systems?.items;
        const systems = [];
        if (systemLinks) {
            for (const systemLink of systemLinks) {
                const system = systemLink.system;
                /**
                 * If RiskControl is overridden, don't add to array.
                 */
                if (!(0, isRiskControlNonStandard_1.isRiskControlNonStandard)(riskControl, system)) {
                    systems.push({ ...system });
                }
                else {
                    console.log("Risk Control is overridden for this system, skipping it..");
                }
            }
        }
        else {
            console.log("This Risk Control Category has no associated Systems");
        }
        /**
         * Return the systems array
         */
        return systems;
    }
    else {
        throw new Error("Error: Invalid RiskControl input");
    }
};
exports.getAllSystemsFromRiskControl = getAllSystemsFromRiskControl;
