"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetData = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const widgetType_1 = require("../../../constants/widgetType");
const getWidgetType_1 = require("../getWidgetType");
const getRiskToleranceData_1 = require("./builtInData/getRiskToleranceData/getRiskToleranceData");
const getWidgetCustomQueries_1 = require("./getWidgetCustomQueries");
const getWidgetMetricData_1 = require("./getWidgetMetricData");
/**
 * Retrieves the data required to render the widget
 * - NOTE: storing all old data allows widgets that utilize this function to preview widgets to not re-query the data
 * when the user goes back and form between queries or metrics
 * - WARNING: if this feature is to be modified make sure to review all uses of the function and modify them accordingly
 * @param {object} widget - the widget settings data from the database
 * @param {object} existingData - any data that has already been retrieved (doesn't need to be retrieved again)
 * @param {function} runCustomQuery - function to run custom queries given a customQueryId
 * @return {Promise<{metricTypes: {}, customQueries: {}}>}
 */
const getWidgetData = async ({ widget, existingData = {}, runCustomQuery }) => {
    console.log("[getWidgetData] start - ", { widget, existingData });
    const widgetType = (0, getWidgetType_1.getWidgetType)({ widget });
    const data = {
        ...(existingData || {}),
    };
    data.metricTypes = existingData?.metricTypes ?? {};
    data.customQueries = existingData?.customQueries ?? {};
    //find the widget's config object
    const config = (0, func_utils_1.tryParse)(widget?.config);
    //[METRIC DATA]
    if (config) {
        const newMetricTypes = await (0, getWidgetMetricData_1.getWidgetMetricData)({
            config,
            metricTypesToIgnore: existingData?.metricTypes,
        });
        data.metricTypes = { ...data?.metricTypes, ...newMetricTypes };
    }
    //[CUSTOM QUERIES]
    data.customQueries = await (0, getWidgetCustomQueries_1.getWidgetCustomQueries)({
        config,
        customQueriesToIgnore: existingData?.customQueries,
        runCustomQuery,
    });
    //[BUILT-IN QUERIES]
    try {
        if (!data.hasOwnProperty(widgetType)) {
            switch (widgetType) {
                case widgetType_1.WIDGET_TYPE.RISK_TOLERANCE_CHART:
                    data[widgetType] = await (0, getRiskToleranceData_1.getRiskToleranceData)({ config });
                    break;
                default:
                //Do nothing
            }
        }
    }
    catch (e) {
        console.error(e);
    }
    console.log("getWidgetData result - ", { data });
    return data;
};
exports.getWidgetData = getWidgetData;
