import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { InvalidParamError } from "../../../../analytics/CustomError";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { deleteVendorControlCategory } from "../../VendorControlCategories/functions/deleteVendorControlCategory";
import { deleteVendorDocument } from "../../VendorDocuments/functions/deleteVendorDocument";

/**
 * Deletes a VendorSolutionReview.
 * First deletes associated VendorControlCategories and VendorDocuments
 * @param {object} vendorSolutionReview
 * @param {string} vendorSolutionReview.id
 * @returns {Promise<void>}
 */
export const deleteVendorSolutionReview = async (vendorSolutionReview) => {
  if (isNullOrUndefined(vendorSolutionReview)) {
    throw new InvalidParamError("vendorSolutionReview");
  }

  // Setup graphql
  const { getQuery: getFullVendorReview, deleteMutation: deleteVendorReview } = generateGraphql(
    "VendorReview",
    ["controlCategories", "documents"],
    {
      controlCategories: `(limit: 100) { items { id } }`,
      documents: `(limit: 100) { items { id } }`,
    },
  );

  // Get full VendorReview
  const fullVendorReview = await ItemQuery(getFullVendorReview, vendorSolutionReview.id);

  // Get associated controlCategories and documents
  const controlCategories = fullVendorReview?.controlCategories?.items || [];
  const documents = fullVendorReview?.documents?.items || [];

  // delete VendorControlCategories
  for (const controlCategory of controlCategories) await deleteVendorControlCategory(controlCategory);

  // delete VendorDocuments
  for (const document of documents) await deleteVendorDocument(document);

  // finally, delete VendorReview
  await ItemMutation(deleteVendorReview, { id: vendorSolutionReview.id });
};
