import { CSSProperties } from "react";

export interface DashedLineBorderOverlayParams {
  enabled: boolean;
  style?: CSSProperties;
  children: JSX.Element;
}
/**
 * Creates a padded dashed line border around the children
 * @param enabled - if FALSE will not add the container
 * @param style - any additional styles to apply to the bordered container
 * @param children - the elements to wrap inside the bordered container
 */
const DashedLineBorderOverlay = ({ enabled, style, children }: DashedLineBorderOverlayParams): JSX.Element => {
  if (!enabled) {
    return children;
  } else {
    return (
      <div
        style={{
          border: "2px dashed lightgrey",
          borderRadius: "4px",
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
};

export default DashedLineBorderOverlay;
