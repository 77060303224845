import React, { useEffect } from "react";

import { isNullOrUndefined, removeDuplicateObjects } from "@rivial-security/func-utils";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Utility component for displaying Evidence Activity items in data grid format for an Evidence
 *
 * Gets associated items of a certain type through Evidence Activity, then removes duplicates
 * @param {object} item - evidence object
 * @param {string} typename - type name in the database
 * @param {object} grid - grid component
 * @param {object} gridHook - grid hook instance
 * @param {string} connectionField - field of nested resource
 * @param {object} recordEvidence - record evidence hook instance
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
export const useEvidenceNestedDataGrid = ({
  item,
  typename,
  grid,
  gridHook,
  connectionField,
  recordEvidence,
  organizationID,
}) => {
  useEffect(() => {
    if (recordEvidence?.key !== 0) nestedDataCard.resetFunction();
  }, [recordEvidence?.key]);

  const gridConfig = {
    createResourceComponent: null,
    deleteFunction: null,
  };

  const gridProps = {
    fields: [
      {
        name: "comment",
        width: 200,
        hidden: true,
      },
    ],
  };

  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "activity",
    connectionField,
    typename,
    parentTypename: "Evidence",
    grid,
    gridHook,
    fields: ["activity"],
    gridHookGridConfig: gridConfig,
    gridProps,
    nestedFields: {
      activity: `(limit: 1000) {
        items {
          id
          createdAt
          comment
          policyID
          policy { id name }
          meetingID
          meeting { id name description status startTime endTime isAllDay }
          reportID
          report { id name module type }
        }
      }`,
    },
    normalizeData: (items) => {
      const res = [];
      items.forEach((item) => {
        if (!isNullOrUndefined(item.name)) {
          /**
           * Assign a comment from the evidence activity
           */
          if (item?.link?.comment) {
            Object.assign(item, { comment: item?.link?.comment });
          }

          res.push(item);
        }
      });
      return removeDuplicateObjects(res);
    },
  });

  const display = <div style={{ height: "100%" }}>{nestedDataCard.gridDisplay}</div>;

  return {
    ...nestedDataCard,
    display,
  };
};

const EvidenceNestedDataGrid = (props) => useEvidenceNestedDataGrid(props).display;

export default withOrganizationCheck(EvidenceNestedDataGrid);
