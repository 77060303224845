"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Based on the event object given to a graphql lambda function referenced by @firehose directive dynamically
 * determine the operation that is being performed (read, create, delete, update)
 * @param {object} event - contains identifying info about the requests
 */
const getEventOperationType = ({ event }) => {
    //Check if event contains field name data (can be inside the info object or or a top level property)
    const fieldName = event?.info?.fieldName || event?.fieldName;
    if (!fieldName) {
        console.error("Event object did not have field name data in getEventOperationType");
        return null;
    }
    //Split the field name into individual words
    //Reference: https://stackoverflow.com/questions/54409164/how-to-split-a-camelcase-string-and-check-if-each-split-word-is-part-of-an-array
    const fieldNameParts = fieldName.split(/(?=[A-Z])/g);
    //Check the field name to have more that one word
    if (fieldNameParts.length <= 1) {
        console.error("fieldName to short in getEventOperationType - ", { fieldName });
        return null;
    }
    //Return TRUE if fieldNameParts make yp a listBy query
    const isListByQuery = (fieldNameParts) => {
        const foundPartIndex = fieldNameParts.findIndex((part) => part.toLowerCase() === "by");
        return foundPartIndex !== -1;
    };
    //Based on the first value in Query Type field name determine the resource being accessed
    switch (fieldNameParts[0]) {
        case "create":
        case "update":
        case "delete":
            return fieldNameParts[0];
        case "get":
        case "list":
            return "read";
        default:
            if (isListByQuery(fieldNameParts)) {
                return "read";
            }
            else {
                console.error("Cannot parse the operation type from fieldName in getEventOperationType - ", { fieldName });
                return null;
            }
    }
};
exports.default = getEventOperationType;
