import React from "react";

import { WarningLogger } from "@utils/EventLogger";

import RoleChangedNotificationBody from "../components/RoleChangedNotificationBody";
import RoleChangeRequestNotificationBody from "../components/RoleChangeRequestNotificationBody";
import { notificationType } from "../enums/notificationType";

/**
 * Returns the correct body component for the type of notification
 * @param {object} notificationData - custom data provided by each notification, determines the look of its body
 * @param {string} notificationHTML - string version of the html that is to be placed inside the notification
 * @param {function} toggleModal - if toast is in container can call this function to close the container if it is a modal
 * @param {function} removeToast - function that removes the toasts from view
 */
export const getNotificationToastBody = ({ notificationData, notificationHTML, toggleModal, removeToast }) => {
  //Check top-level arguments
  if (!notificationData) {
    WarningLogger("Invalid notificationData argument in getNotificationToastBody");
    return;
  }

  //Fet actual data value from the argument
  let data;
  try {
    data = JSON.parse(notificationData);
  } catch (e) {
    WarningLogger("Cannot parse notificationData argument in getNotificationToastBody");
    return;
  }

  //Check if data is valid
  if (!data?.type) {
    WarningLogger("No notification type information in data argument of getNotificationToastBody");
    return;
  }

  switch (data?.type) {
    case notificationType.ROLE_CHANGE_REQUEST:
      return (
        <RoleChangeRequestNotificationBody
          html={notificationHTML}
          data={data}
          toggleModal={toggleModal}
          removeToast={removeToast}
        />
      );
    case notificationType.ROLE_CHANGED:
      return <RoleChangedNotificationBody html={notificationHTML} data={data} />;
    default:
      //TODO: possibly add a default notification body
      return;
  }
};
