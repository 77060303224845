import { GetQuery as PackageGetQuery } from "@rivial-security/appsync-utils";

/**
 * This is a frontend function invokes the GetQuery function in the appsync-utils npm package
 * and uses this Amplify API scope for authentication
 * @param {object} params
 * @param {string} params.query - The raw graphql query string (the function has graphql-tag built in)
 * @param {object} params.variables - graph ql filters for the provided query
 * @returns {Promise<any>}
 */
export const GetQuery = async ({ query, variables }) => {
  /**
   * This performs the query logic from the NPM package in the same scope as the frontend project.
   * This utilizes our frontend Auth tokens without exposing them to the NPM package.
   */
  return await PackageGetQuery({ query, variables });
};
