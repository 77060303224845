import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

import { useGUID } from "../../../functional/useGUID";

/**
 * Custom Dropdown component for the useForm hook to display a dropdown
 * data: [
 *  {
 *    text: "Hello World",
 *    value: "helloWorld"
 *  }
 * ]
 * @param {object} input - the input object from the useForm hook
 * @param  {string} fieldName - the name of the field in the form
 * @param {object} dropdownConfig - the configuration for the dropdown
 * @param {string} placeholder - the placeholder text for the dropdown
 * @param {boolean} disabled - whether the dropdown is disabled
 * @param {string} defaultValue - the default value for the dropdown
 * @param {function} onChange - the onChange function for the dropdown
 * @returns {JSX.Element}
 * @constructor
 */
const CustomDropdown = ({ input, fieldName, dropdownConfig = {}, placeholder, disabled, defaultValue, onChange }) => {
  const { data, width, showClearButton = true, customFindValueFunction, onSelectedOption } = dropdownConfig;

  const [id] = useGUID();

  return (
    <>
      <Autocomplete
        disablePortal
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || (disabled ? "Disabled" : "Select an item..")}
            variant={"standard"}
          />
        )}
        variant="standard"
        key={id}
        id={`custom_dropdown${id}`}
        readOnly={disabled}
        getOptionLabel={(option) => option.text}
        disableClearable={!showClearButton}
        onChange={(event, value) => {
          if (typeof onSelectedOption === "function") {
            onSelectedOption(value?.value);
          }
          onChange(value?.value);
        }}
        data-testid={`useForm-dropdown-input-${fieldName}`}
        width={width}
        // convert the flat value to the correct object for the form
        // defaults to null? if the value is not found
        value={
          typeof customFindValueFunction === "function"
            ? customFindValueFunction({ data, value: input?.[fieldName] })
            : data?.find((item) => item.value === input?.[fieldName]) || null
        }
        // convert the flat value to the correct object for the form
        defaultValue={data?.find((item) => item.value === defaultValue) || null}
      />
    </>
  );
};

export default CustomDropdown;
