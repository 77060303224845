import Grid from "@mui/material/Grid";
import React from "react";

import { usePageList } from "../../hooks/views/usePageList";

import BetaOptions from "./SettingPages/BetaOptions/BetaOptions";
import DataTableOptions from "./SettingPages/DataTableOptions/DataTableOptions";
import InterfaceOptions from "./SettingPages/InterfaceOptions/components/InterfaceOptions";
import LoginOptions from "./SettingPages/LoginOptions/components/LoginOptions";
import WelcomeTourOptions from "./SettingPages/WelcomeTourOptions/WelcomeTourOptions";

/**
 * Settings component. Allows the user to adjust settings specific to their account and session.
 *
 * Also displays Application Info
 *
 * @return {JSX.Element}
 * @constructor
 */
const Settings = () => {
  const pageList = usePageList({
    pages: [
      {
        icon: "cil:screen-desktop",
        title: "Interface",
        component: <InterfaceOptions />,
      },
      {
        icon: "bx:table",
        title: "Data Tables",
        component: <DataTableOptions />,
      },
      {
        icon: "carbon:login",
        title: "Login Options",
        component: <LoginOptions />,
      },
      {
        icon: "icon-park-outline:tour-bus",
        title: "Welcome Tours",
        component: <WelcomeTourOptions />,
      },
      {
        icon: "icon-park-outline:experiment",
        title: "Beta Settings",
        component: <BetaOptions />,
      },
    ],
  });

  return (
    <div key={"settings"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {pageList.display}
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
