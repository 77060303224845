import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { roleConfigDefault } from "@rivial-security/role-utils";

import DashboardCardSection from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCardSection";

import ResourceOperationSwitches from "./ResourceOperationSwitches";

/**
 * Displays a section for a particular resource and its Read, Create, Delete, Update operations
 * @param {string} resource - the resource enum string
 * @param {object} operations - the resource object values
 * @param {function} handleResourceChange - change handler when toggling resource operation switches
 * @param {string} searchValue - the searchbar value, used to highlight text in relation to a search
 * @param {string} module - the module this resource is associated with
 * @param {boolean} disableEdits - whether edits for this resource section should be enabled/disabled
 * @constructor
 */
const ResourceSection = ({ resource, operations, handleResourceChange, searchValue, module, disableEdits }) => {
  const handleChange = (operations) => {
    handleResourceChange(resource, operations);
  };

  return (
    <DashboardCardSection
      title={roleConfigDefault?.resources[resource]?.name || convertCamelCaseToSentence(resource)}
      highlightedText={searchValue}
    >
      <i style={{ fontSize: "0.75em" }}>{roleConfigDefault?.resources?.[resource]?.description}</i>
      <ResourceOperationSwitches
        disableEdits={disableEdits}
        resource={resource}
        module={module}
        operations={operations}
        handleChange={handleChange}
      />
    </DashboardCardSection>
  );
};

export default ResourceSection;
