import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NestedDocumentsCard from "../../../Compliance/Documents/components/NestedDocumentsCard";

/**
 * @description Display Documents list for an Assessment component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentDocuments = ({ item, organizationID }) => {
  return (
    <NestedDocumentsCard
      item={item}
      module={modules.VULNERABILITIES}
      resource={resources.ASSESSMENT}
      parentTypename={resources.ASSESSMENT}
      connectionIDField={"documentAssessmentId"}
      organizationID={organizationID}
    />
  );
};

export default withOrganizationCheck(AssessmentDocuments);
