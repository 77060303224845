import { cloneDeep } from "lodash";
import React, { useState } from "react";

import useDidMountEffect from "@hooks/functional/useDidMountEffect";
import { useMutation } from "@hooks/graphql/useMutation/useMutation";
import { useUIContext } from "@utils/Context/UIContext";

import { withOrganizationCheck } from "../../Context/withOrganizationCheck";
import { removeNonStandardCharacters } from "../../Functions/String/removeNonStandardCharacters";

import NoteUI from "./NoteUI";

/**
 * Logic layer of Notes component
 * @param {string} [label] - label to be displayed above notes, next to tooltip, if not present will use "Notes"
 * @param {object} props - props passed from parent component
 * @param {object} item - item to be updated
 * @param {string} item.id - the ID of the item to be updated
 * @param {object[]} notes - notes to be displayed
 * @param {string} module - module for role checking
 * @param {string} resource - resource for role checking
 * @param {boolean} disableRoleChecking - if true wont check the user's role to display notes
 * @param {string} organizationID - the currently selected organization id
 * @param {function} resetFunction - function to call to requery notes
 * @param {string} mutation - mutation to be used to update notes
 * @param {string} tooltip - tooltip to be used
 * @param {number} maxInitialNotes - maximum number of notes to show before hiding the rest behind a "Show More" button, -1 for no limit
 * @param {boolean} disableTitle - if true wont display the title
 * @param {function} onUpdate - function to call to update the item
 * @param {string} observationConnectionField - the Observation field that will connect an Observation to the parent item
 * @param {JSXElement} observationFormOverride - optional JSX element to override the Observation form
 * @param {function} onCreateObservation - callback function that gets called when an observation is created from a Note
 * @param {string} auditID - for explicitly connecting Observations to an Audit
 * @param {string} evidenceID - for explicitly connecting Observations to an Evidence
 * @param {string} exerciseID - for explicitly connecting Observations to an Exercise
 * @param {string} complianceControlID - for explicitly connecting Observations to a Compliance Control
 * @param {string} riskControlID - for explicitly connecting Observations to a Risk Control
 * @param {string} vulnerabilityID - for explicitly connecting Observations to a Vulnerability
 * @param {string} incidentID - for explicitly connecting Observations to an Incident
 * @param {string} artifactID - for explicitly connecting Observations to an Artifact
 * @param {string} meetingID - for explicitly connecting Observations to a Meeting
 * @param {boolean} isLoading - if TRUE will display a loading spinner for the existing notes
 * @returns {JSXElement} - returns a component
 */
const NotesV2 = ({
  label = "Notes",
  item,
  notes,
  module,
  resource,
  disableRoleChecking,
  organizationID,
  resetFunction,
  showHorizontalRule = false,
  mutation,
  tooltip,
  maxInitialNotes = -1,
  disableTitle,
  onUpdate,
  observationConnectionField,
  observationFormOverride,
  disableEdits = false,
  onCreateObservation,
  auditID,
  evidenceID,
  exerciseID,
  complianceControlID,
  riskControlID,
  vulnerabilityID,
  incidentID,
  artifactID,
  meetingID,
  isLoading = false,
}) => {
  const { addToast } = useUIContext();

  const updateNotes = useMutation({
    mutation,
    resource,
    module,
    field: "notes",
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
  });

  const getCurrentNotes = () => {
    if (item && item.notes) {
      return [...item.notes];
    } else if (notes) {
      return [...notes];
    } else {
      return [];
    }
  };

  const [currentNotes, setCurrentNotes] = useState(getCurrentNotes());
  useDidMountEffect(() => {
    setCurrentNotes(getCurrentNotes());
  }, [JSON.stringify(item), notes]);

  const performUpdate = ({ oldNotes, newNotes }) => {
    if (onUpdate) {
      onUpdate(newNotes);
      resetFunction?.();
    } else {
      setCurrentNotes([...newNotes]);

      updateNotes
        .editItem(
          {
            id: item?.id,
            notes: [...newNotes],
          },
          true,
        )
        .then((item) => {
          if (typeof resetFunction === "function") {
            resetFunction();
          }
        })
        .catch((e) => {
          addToast({
            header: "Failed to edit the note.",
            icon: "danger",
          });
          setCurrentNotes(oldNotes);
        });
    }
  };

  const updateNote = (note) => {
    const oldNotesArray = cloneDeep(currentNotes);
    const tempNotesArray = cloneDeep(currentNotes);
    const index = tempNotesArray.findIndex((item) => item.id === note.id);

    if (index > -1) {
      tempNotesArray[index] = note;

      performUpdate({
        oldNotes: oldNotesArray,
        newNotes: tempNotesArray,
      });
    }
  };

  const createNote = (note) => {
    if (note) {
      // Update the note content to remove non-standard characters
      note.content = removeNonStandardCharacters(note?.content);
    }

    const oldNotesArray = cloneDeep(currentNotes);
    const tempNotesArray = cloneDeep(currentNotes);
    tempNotesArray.push(note);

    performUpdate({
      oldNotes: oldNotesArray,
      newNotes: tempNotesArray,
    });
  };

  const deleteNote = (note) => {
    const oldNotesArray = cloneDeep(currentNotes);
    const tempNotesArray = cloneDeep(currentNotes);
    const index = tempNotesArray.findIndex((item) => item.id === note.id);

    if (index > -1) {
      tempNotesArray.splice(index, 1);

      performUpdate({
        oldNotes: oldNotesArray,
        newNotes: tempNotesArray,
      });
    }
  };

  return (
    <NoteUI
      notes={currentNotes}
      maxInitialNotes={maxInitialNotes}
      label={label || "Notes"}
      isLoading={isLoading}
      createNote={createNote}
      updateNote={updateNote}
      deleteNote={deleteNote}
      module={module}
      resource={resource}
      organizationID={organizationID}
      disableRoleChecking={disableRoleChecking}
      tooltip={tooltip}
      disableTitle={disableTitle}
      showHorizontalRule={showHorizontalRule}
      observationConnectionField={observationConnectionField}
      observationFormOverride={observationFormOverride}
      item={item}
      disableEdits={disableEdits}
      onCreateObservation={onCreateObservation}
      auditID={auditID}
      evidenceID={evidenceID}
      exerciseID={exerciseID}
      complianceControlID={complianceControlID}
      riskControlID={riskControlID}
      vulnerabilityID={vulnerabilityID}
      incidentID={incidentID}
      artifactID={artifactID}
      meetingID={meetingID}
    />
  );
};

export default withOrganizationCheck(NotesV2);
