/**
 * handles the errors that can occur when signing in a user
 * @param {Object} err - error object
 * @param {Object} errorContext - error context object
 */
export const handleSignInError = (err, errorContext) => {
  const incorrectUserOrPasswordMessage = "Incorrect username or password";
  const signInPageErrorMessage = "Error! SignIn page";

  switch (err.code) {
    case "UserNotConfirmedException":
    case "PasswordResetRequiredException":
    case "NotAuthorizedException":
    case "UserNotFoundException":
      if (["NotAuthorizedException", "UserNotFoundException"].includes(err.code)) {
        err.message = incorrectUserOrPasswordMessage;
      }
      errorContext.error(err);
      errorContext.EventLogger(signInPageErrorMessage, err);
      break;

    default:
      err.message = incorrectUserOrPasswordMessage;
      errorContext.error(err);
      errorContext.EventLogger(signInPageErrorMessage, err);
      break;
  }
};
