"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMedian = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the mathematical median value in the array.
 * @param {*[]} items - array of items that can be converted to numeric values or are already numbers
 * @returns {number|null} - the median value or null if no valid items were passed
 */
const getMedian = ({ items }) => {
    const numericItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    if (!Array.isArray(numericItems) || numericItems.length === 0) {
        return null;
    }
    else {
        return numericItems[Math.floor(numericItems.length / 2)];
    }
};
exports.getMedian = getMedian;
