import React from "react";

import TextDisplay from "../../../../utils/GenericComponents/GenericEditFieldV3/components/TextDisplay";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import DepartmentDetails from "../../../OrganizationManager/Departments/components/DepartmentDetails";
import DepartmentGrid from "../../../OrganizationManager/Departments/components/DepartmentGrid";

/**
 * Shows the currently selected department based on linked department and point of contact
 * @param {object} item - the task for which to show the department
 * @param {object} props - additional props into the NestedField
 * @returns {JSX.Element}
 */
export const TaskDepartmentCustomField = ({ item: task, ...props }) => {
  if (task?.pointOfContact) {
    const pointOfContactDepartmentName = task?.pointOfContact?.department?.name || "";
    return <TextDisplay value={pointOfContactDepartmentName} />;
  }

  return (
    <NestedField
      item={task}
      parentTypename={"Task"}
      typename={"Department"}
      field={"department"}
      idField={"departmentID"}
      detailsComponent={<DepartmentDetails />}
      grid={<DepartmentGrid />}
      {...props}
    />
  );
};
