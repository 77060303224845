import React from "react";

import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import RiskControlOverridesV2 from "../../../../../Overrides/components/RiskControlOverridesV2";

const StrengthRatingBody = ({ item, isAdmin, system, resetFunction, style }) => {
  return (
    <div style={style.div}>
      <strong>
        Strength Rating
        <i
          className="icon-question"
          title="The maximum percentage that this control will reduce the likelihood of a risk occurring"
          style={style.icon}
        />
      </strong>
      <div style={style.field}>
        <RiskControlOverridesV2
          key={JSON.stringify(item)}
          isAdmin={isAdmin}
          riskControl={item}
          system={system}
          field="strengthRating"
          resetFunction={resetFunction}
        />
      </div>
    </div>
  );
};

const StrengthRating = (props) => {
  return (
    <OrganizationCheck {...props}>
      <StrengthRatingBody />
    </OrganizationCheck>
  );
};

export default StrengthRating;
