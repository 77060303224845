import { generateGraphql } from "@rivial-security/generategraphql";

import { ListQuery } from "../../Functions/Graphql/ListQuery";

/**
 * Fetches all CustomResourceTypes for a given organization and returns it formatted for the condition builder
 * @param {string} organizationID - the organization ID to fetch CustomResourceTypes for
 * @returns {Promise<void>}
 */
export const getCustomResourceTypes = async ({ organizationID }) => {
  const { listQuery } = generateGraphql("CustomResourceType", ["name", "description", "customFields"], {
    customFields: `{ name type options { label value } multipleSelect { label value } numberSettings { min max step format } }`,
  });

  // TODO JB-1: refactor to listQueryBy
  const customResourceTypes = await ListQuery({
    query: listQuery,
    organizationID,
    filters: {},
  });

  const res = {};

  // Loop through each custom resource type and add it to the result object as a key value pair
  for (const customResourceType of customResourceTypes) {
    const {
      // gets used as the typename for the condition builder
      id: customResourceTypeID,
      name: customResourceTypeName,
      description,
      customFields,
    } = customResourceType;

    res[customResourceTypeID] = {
      name: `(Custom) ${customResourceTypeName}`,
      customResourceTypeID: customResourceType.id,
      description,
      fields: {},
    };

    for (const customField of customFields) {
      const { name, type, options, required } = customField;
      res[customResourceTypeID].fields.customResourceTypeID = {
        type: "string",
        required: true,
      };

      res[customResourceTypeID].fields[name] = {
        type,
        options,
        required,
      };
    }
  }

  return res;
};
