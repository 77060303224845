"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTextFromHtml = void 0;
const eventLogger_1 = require("../Misc/eventLogger");
const decodeSpecialHtml_1 = require("./decodeSpecialHtml");
/**
 * Removes all HTML characters from a string.
 * This is useful for converting Rich Text Editor content to a text input form.
 * @param {string} html - the HTML to parse
 * @returns {string} - the inner text of the passed in html without tags or special characters
 */
const parseTextFromHtml = (html) => {
    //Check arguments
    if (html === null) {
        const err = "[parseTextFromHtml] html input cannot be null!";
        (0, eventLogger_1.eventLogger)(err);
        throw Error(err);
    }
    if (html === undefined) {
        const err = "[parseTextFromHtml] html input cannot be undefined!";
        (0, eventLogger_1.eventLogger)(err);
        throw Error(err);
    }
    if (typeof html !== "string") {
        const err = "[parseTextFromHtml] html input must be a string!";
        (0, eventLogger_1.eventLogger)(err);
        throw Error(err);
    }
    //Decode special html characters, such as &nbsp;
    let text = (0, decodeSpecialHtml_1.decodeSpecialHtml)(html);
    //Replace all html tags with blanks, such as <p>
    text = text.replace(/<[^>]*>?/gm, "");
    //Replace any generated multiple side-by-side new line characters with one space
    //REFERENCE: https://stackoverflow.com/a/34936253/7393636
    // eslint-disable-next-line no-control-regex
    return text.replace(/[\r\n\u000B\u000C\u0085\u2028\u2029]+/g, " ");
};
exports.parseTextFromHtml = parseTextFromHtml;
