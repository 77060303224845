"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRiskOverrideFieldEnabled = void 0;
const isRiskOverrideFieldEnabled = (override, field) => {
    if (override?.enabledFields !== null && override?.enabledFields !== undefined) {
        if (override?.enabledFields.includes(field)) {
            if (override[field] !== null) {
                return override[field];
            }
            else {
                return false;
            }
        }
    }
    return false;
};
exports.isRiskOverrideFieldEnabled = isRiskOverrideFieldEnabled;
