import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useSystemDataGrid } from "../hooks/useSystemDataGrid";

/**
 * Component instance of the useSystemDataGrid hook
 * @param {string} organizationID - selected organization
 * @param {object} props - all props passed to useSystemDataGrid hook
 * @returns {*}
 * @constructor
 */
const SystemDataGrid = ({ organizationID, ...props }) => {
  return useSystemDataGrid({ organizationID, ...props }).display;
};

export default withOrganizationCheck(SystemDataGrid);
