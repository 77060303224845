import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { fullObservationsByOwnerGroupByModule } from "../graphql/fullObservationsByOwnerGroupByModule";

/**
 * Gets a list of Observations with associated Recommendations and Action items to fill out the grid
 * @param {string} organizationID - current organization ID
 * @param {object} variables - variables to pass to the query
 * @param {object} filters - filters to use in the query
 * @returns {Promise<*>} the list by query promise
 */
export const getFullFindings = async ({ organizationID, variables, filters }) => {
  return ListQueryBy({
    query: fullObservationsByOwnerGroupByModule,
    organizationID,
    variables,
    filter: filters,
  });
};
