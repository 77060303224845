import React, { useEffect, useState } from "react";

import { concatNotes, convertToKebabCase, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql, generateGraphqlFields } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useDataGrid } from "../../../../../../hooks/views/useDataGrid/useDataGrid";
import EnabledDisabledButtonGroupField from "../../../../../../utils/CustomFields/EnabledDisabledButtonGroupField";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import { objectListSimilarByField } from "../../../../../../utils/Functions/objectListSimilarByField";
import StyledWrapper from "../../../../../../utils/GenericComponents/StyledWrapper";
import { createControlTagLink } from "../../../../../../utils/Tags/functions/createTagLinks/createControlTagLink";
import ControlDetails from "../../components/ControlDetails";
import ControlMappingExportModalButton from "../../components/ControlMappingExport/components/ControlMappingExportModalButton";
import ControlNotes from "../../components/ControlNotes";
import CreateControl from "../../components/CreateControl";
import IsDisabled from "../../customFields/IsDisabled";
import { deleteControl } from "../../functions/deleteControl";
import { generateGridCustomFields } from "../../functions/generateGridCustomFields";

import { getControlNestedFields } from "./constants";
import { useControlGridData } from "./hooks/useControlGridData";

/**
 * Custom hook to display a list of controls
 * @param {string} organizationID - the organization id from the database of current selected organization
 * @param {string} module=modules.COMPLIANCE - platform module for role checking
 * @param {string} resource=resources.CONTROL - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {string} frameworkOrganizationID - needed to show template control lists
 * @param {string} controlFrameworkID - "all" to retrieve all org controls, otherwise the control framework identifier from the database for which to show controls and display custom fields
 * @param {string[]} queryFields - filter which fields to display in the table by not retrieving certain properties
 * @param {string} nestedFields - provide this argument to retrieve control framework connection information as needed
 * @param {object} queryConfig - use if need to customize the get query for the grid completely
 * @param {object} gridConfig - grid settings/props see useGrid.js
 * @param {object} cardConfig - grid container settings
 * @param {object[]} otherFields - if additional custom fields are appended use this to declare the component used to display them and the header title
 * @param {boolean} [isInAudit=false] - if TRUE will turn off automatic query for controls under a control framework, to show only control audit links
 * @param {boolean} isTemplate - if TRUE a control template will be shown
 * @param {object} props - additional props to pass to the grid
 * @return {{customFrameworkFields: *[], gridDisplay: *, data: *, tagFilterApplied: boolean, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: *, isLoading: boolean, createResourceComponent: "preserve" | "react" | "react-native", ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: function(): void, customFrameworkFieldNames: *[], fields: Object[], setItemsToCheck: (value: (((prevState: undefined) => undefined) | undefined)) => void, selectedItems: []}}
 */
export const useControlDataGrid = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,
  frameworkOrganizationID,
  controlFrameworkID,
  queryFields = [
    "statementNumber",
    "name",
    "inPlace",
    "isDisabled",
    "tags",
    "notes",
    "controlControlSetId",
    "controlSet",
    "customFieldData",
  ],
  nestedFields,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  otherFields = [],
  isInAudit = false,
  isTemplate = false,
  ...props
}) => {
  ///[GENERAL SETUP]
  // - Constants
  const typename = resources.CONTROL;
  // - Queries
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["customFields", "name"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format } }`,
  });

  nestedFields = getControlNestedFields(nestedFields);
  const { updateMutation: controlUpdateMutation } = generateGraphql(typename, queryFields, nestedFields);
  // - Permissions
  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  const listControlsByControlSet = /* GraphQL */ `
    query ListControlsByControlSet(
      $ownerGroup: String
      $controlControlSetId: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelControlFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listControlsByControlSet(
        ownerGroup: $ownerGroup
        controlControlSetId: $controlControlSetId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${generateGraphqlFields(queryFields, nestedFields)}
        }
        nextToken
      }
    }
  `;

  /// [STATE]
  const [fields, setFields] = useState([
    {
      name: "statementNumber",
      friendlyName: "Statement Number",
      // Reference: https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
      sortComparator: (a, b) => {
        if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
          return 0; // a and b equal , if both are undefined
        } else if (isNullOrUndefined(a)) {
          return 1; //sort b before a, if a is undefined
        } else if (isNullOrUndefined(b)) {
          return -1; //sort a before b, if b is undefined
        }

        return a?.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
      width: 150,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      bulkEdit: true,
    },
  ]);
  const [customFrameworkFieldNames, setCustomFrameworkFieldNames] = useState([]);
  const [customFrameworkFields, setCustomFrameworkFields] = useState([]);
  const basePersistenceID = "c10661db-eaa6-4b4f-a7fc-60bed75b9egssd";
  const [persistenceUUID, setPersistenceUUID] = useState(basePersistenceID);
  /// [GRID SETUP]
  // - define which FIELDS are shown and the components to use for them
  useEffect(() => {
    let newFields = [
      {
        name: "statementNumber",
        friendlyName: "Statement Number",
        // Reference: https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
        sortComparator: (a, b) => {
          if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
            return 0; // a and b equal , if both are undefined
          } else if (isNullOrUndefined(a)) {
            return 1; //sort b before a, if a is undefined
          } else if (isNullOrUndefined(b)) {
            return -1; //sort a before b, if b is undefined
          }

          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        },
        width: 150,
        sort: {
          direction: "asc",
          priority: 1,
        },
        bulkEdit: true,
      },
      {
        name: "name",
        flex: 1,
        minWidth: 200,
        bulkEdit: true,
      },
      {
        name: "inPlace",
        friendlyName: "Status",
        component: (
          <EnabledDisabledButtonGroupField
            config={{
              typename,
              module,
              resource,
              disableRoleChecking,
              field: "inPlace",
              rightOffText: "Not in Place",
              leftOnText: "In Place",
            }}
          />
        ),
        disablePropagation: true,
        width: 200,
        bulkEdit: true,
        valueGetter: (_value, row) => !!row?.inPlace,
        type: "singleSelect",
        valueOptions: [
          { value: true, label: "In Place" },
          { value: false, label: "Not in Place" },
        ],
      },
      {
        field: "tags",
        name: "tags",
        description: "Tags that correspond to this Control across the entire Platform",
        createLinkFunction: createControlTagLink,
        flex: 0.4,
        bulkEdit: true,
      },
      {
        name: "isDisabled",
        friendlyName: "Disabled",
        description:
          "Disabled Controls are ignored for calculations, audits, and exports. " +
          "Disabled Controls will also be hidden from Data Grids by default. " +
          "These settings can be changed on the Compliance Config page.",
        component: (
          <IsDisabled
            module={module}
            resource={resource}
            disableRoleChecking={disableRoleChecking}
            fieldContext={"grid"}
          />
        ),
        disablePropagation: true,
        hidden: true,
        width: 150,
        bulkEdit: true,
        valueGetter: (_value, row) => !!row?.isDisabled,
        type: "singleSelect",
        valueOptions: [
          { value: true, label: "No" },
          { value: false, label: "Yes" },
        ],
      },
      {
        name: "notes",
        component: (
          <StyledWrapper wrapperStyle={{ width: "100%" }}>
            <ControlNotes disableTitle={true} />
          </StyledWrapper>
        ),
        width: 300,
        valueFormatter: (value) => {
          return Array.isArray(value) ? concatNotes(value) : value;
        },
        visible: false,
      },
      ...otherFields,
    ];

    newFields = newFields.concat(
      generateGridCustomFields({
        module,
        resource,
        disableRoleChecking,
        customFields: customFrameworkFields,
      }),
    );

    //TODO: check if this check is necessary
    //Check if change in fields is necessary (prevents infinite loop)
    if (!objectListSimilarByField({ listOne: fields, listTwo: newFields })) {
      setFields(newFields);
    }
  }, [JSON.stringify(customFrameworkFields)]);

  // - updates custom fields and persistenceID configuration for the selected control set
  const updateFrameworkSettings = async () => {
    //Retrieve data for the new control framework
    const controlSetCustomFieldNames = [];
    let newCustomFields = [];
    try {
      // - execute control framework query
      let controlFramework;
      if (controlFrameworkID) {
        controlFramework = await ItemQuery(getControlFrameworkQuery, controlFrameworkID);
      }

      // - check for custom the field property to be present
      if (Array.isArray(controlFramework?.customFields)) {
        newCustomFields = controlFramework.customFields;
      }

      // - get all control set custom field names
      if (newCustomFields && Array.isArray(newCustomFields)) {
        for (const customField of newCustomFields) {
          if (customField?.name) {
            controlSetCustomFieldNames.push(customField.name);
          }
        }
      }

      // - update persistenceID
      let newPersistenceUUID = basePersistenceID;
      const controlFrameworkSnakeCaseName = convertToKebabCase({
        text: controlFramework?.name,
      });

      if (controlFrameworkSnakeCaseName) {
        newPersistenceUUID = `${basePersistenceID}-${controlFrameworkSnakeCaseName}`;
      }
      setPersistenceUUID(newPersistenceUUID);
    } catch (e) {
      ErrorLogger("Failed to get custom control framework fields in control grid!", e);
    }
    setCustomFrameworkFields(newCustomFields);
    setCustomFrameworkFieldNames(controlSetCustomFieldNames);
  };

  // - Updates the custom field configuration when controlFramework changes
  useEffect(() => {
    if (!isNullOrUndefined(controlFrameworkID) && controlFrameworkID !== "") {
      updateFrameworkSettings();
    }
  }, [controlFrameworkID]);

  // - define general GRID settings as well as create/update/delete/details functions to use
  gridConfig = {
    // graphql operation functions
    options: ["details", "duplicate", "delete", "edit"],
    deleteFunction: deleteControl,
    createItemModalHeader: <div>{`Create a new Control${isTemplate ? " Template" : ""}`}</div>,
    createResourceComponent:
      controlFrameworkID === "all" || !controlFrameworkID ? null : (
        <CreateControl controlFrameworkID={controlFrameworkID} organizationID={organizationID} />
      ),
    updateMutation: controlUpdateMutation,

    // details component setup
    typename: "Control",
    detailsComponent: <ControlDetails tableDisplay={true} organizationID={organizationID} />,
    config: {
      width: "95vw",
    },

    // field and routes settings
    fields,

    route: `#/continuous_compliance/controls/`,
    columnSize: 8,

    // persistence settings
    persistenceUUID,

    organizationID,
    duplicationSettings,

    customOptions: [<ControlMappingExportModalButton />],

    ...gridConfig,
    ...props,
  };

  const [resetKey, setResetKey] = useState(1);
  queryConfig = {
    query: null,
    resetFunction: () => setResetKey((resetKey) => resetKey + 1),
    ...queryConfig,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
  });

  // - hydrates the controls gridCard manually
  useControlGridData({
    resetKey,
    organizationID,
    customFrameworkFieldNames,
    controlFrameworkID,
    gridCard,
    query: !isInAudit ? listControlsByControlSet : undefined,
    isTemplate,
  });

  return {
    ...gridCard,
    customFrameworkFields,
    customFrameworkFieldNames,
  };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates Controls, preserves Evidence linking. Does not preserve Recommendations, Audits, or Control Changes.",
  fields: [
    "statementNumber",
    "name",
    "controlControlSetId",
    "customFieldData",
    "evidences",
    "keyPerformanceIndicators",
    "inPlace",
    "isDisabled",
    "notes",
    "tags",
  ],
  nestedFields: {
    evidences: `(limit: 1000) { items { id ownerGroup evidenceID controlID evidence { id } control { id } } }`,
    keyPerformanceIndicators: `(limit: 1000) { items { id controlID keyPerformanceIndicatorID ownerGroup keyPerformanceIndicator { id } control { id } } }`,
    tags: `(limit: 1000) { items { __typename id ownerGroup controlID tagID control { id } tag { id }  } }`,
    notes: `{author content timeStamp ownerGroup observationID }`,
  },
  primaryField: "name",
  connectionAdaptor: {
    evidences: {
      connectionTypename: "ControlEvidenceLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "evidenceID",
      childConnectionField: "evidence",
    },
    keyPerformanceIndicators: {
      connectionTypename: "KPIComplianceControlLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "keyPerformanceIndicatorID",
      childConnectionField: "keyPerformanceIndicator",
    },
    tags: {
      connectionTypename: "ControlTagLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "tagID",
      childConnectionField: "tag",
    },
  },
};
