"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTableCell = void 0;
/**
 * @description Create a custom cell for row table
 * @param {string} textAlignment
 * @param {string} verticalAlignment
 * @param {string} fontColor
 * @param {string} backgroundColor
 * @param {string} text
 * @param {boolean} bold
 * @param {number} fontSize
 * @param cellWidth
 * @param columnIndex
 * @param styleName
 * @param {object[]} blocks
 * @return {object} {{cellFormat: {shading: {backgroundColor: *, foregroundColor: string, textureStyle: string}, columnSpan: number, rowSpan: number, preferredWidth: (*|number), cellWidth: (*|number), verticalAlignment: string, preferredWidthType: string}, blocks: [{paragraphFormat: {textAlignment: string, listFormat: {}, styleName: string}, characterFormat: {bold: string}, inlines: [{characterFormat: {bidi: boolean, fontSize: number, bold: string, fontSizeBidi: number, fontColor: string}, text: string}]}], columnIndex: *}}
 */
const createTableCell = ({ textAlignment = "Left", verticalAlignment = "Top", fontColor = "#000000", backgroundColor = "#FFFFFF", text = "", bold = "", fontSize = 11, cellWidth, columnIndex, styleName = "Normal", blocks, }) => {
    return {
        blocks: blocks
            ? [...blocks]
            : [
                {
                    paragraphFormat: {
                        textAlignment: textAlignment,
                        styleName: styleName,
                        listFormat: {},
                    },
                    characterFormat: {
                        bold: bold,
                    },
                    inlines: [
                        {
                            characterFormat: {
                                bold: bold,
                                bidi: false,
                                fontColor: fontColor,
                                fontSize: fontSize,
                                fontSizeBidi: fontSize,
                            },
                            text: text,
                        },
                    ],
                },
            ],
        cellFormat: {
            shading: {
                backgroundColor: backgroundColor,
                foregroundColor: "empty",
                textureStyle: "TextureNone",
            },
            preferredWidth: cellWidth || 156,
            preferredWidthType: "Point",
            cellWidth: cellWidth || 156,
            columnSpan: 1,
            rowSpan: 1,
            verticalAlignment: verticalAlignment,
        },
        columnIndex: columnIndex,
    };
};
exports.createTableCell = createTableCell;
