import List from "@mui/material/List";
import { makeStyles } from "@mui/styles";
import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";

import { OperationTeamListItem } from "./OperationTeamListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme?.palette?.background?.paper,
  },
}));

/**
 * @description Display list of operation teams for an organization
 * @param {object[]} operationTeams - operation teams array
 * @param {boolean} isLoading - loading state of the data
 * @returns {JSX.Element}
 * @constructor
 */
export const OperationTeamList = ({ operationTeams, isLoading = false } = {}) => {
  const isEnoughData = !isNullOrUndefined(operationTeams) && Array.isArray(operationTeams) && operationTeams.length > 0;
  const classes = useStyles();

  return (
    <DataLoader isEnoughData={isEnoughData} isLoading={isLoading} dataMessage={"No assigned Operation Teams"}>
      <List dense className={classes.root}>
        {Array.isArray(operationTeams) &&
          operationTeams.map((item) => <OperationTeamListItem key={`operation-team-${item?.id}`} item={item} />)}
      </List>
    </DataLoader>
  );
};
