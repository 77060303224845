export const getKnowBe4PhishingTest = /* GraphQL */ `
  query GetKnowBe4PhishingTest($query: QueryGetKnowBe4PhishingTestQueryInput!) {
    getKnowBe4PhishingTest(query: $query) {
      pst_id
      campaign_id
      name
      status
      started_at
      duration
      delivered_count
      opened_count
      clicked_count
      data_entered_count
      reported_count
      groups {
        group_id
        name
      }
    }
  }
`;
