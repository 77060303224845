import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKeyRiskIndicatorsDataGrid } from "../hooks/useKeyRiskIndicatorsDataGrid";

/**
 * Component instance of the useKeyRiskIndicatorsGrid
 * @param {object} props - all props passed to KeyRiskIndicatorsDataGrid component
 * @returns {JSX.Element}
 * @constructor
 */
const KeyRiskIndicatorsDataGrid = (props) => useKeyRiskIndicatorsDataGrid(props).dashboardDisplay;

export default withOrganizationCheck(KeyRiskIndicatorsDataGrid);
