import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import React, { useContext, useState } from "react";
import { CustomInput, Input, Progress } from "reactstrap";

import { ItemMutation } from "@rivial-security/appsync-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { AllowedFileUploadTypes } from "@definitions/constants/allowedFileUploadTypes";
import { QueryGetItem } from "@hooks/graphql/useQueryGetItem";
import { useAddAccessControl } from "@utils/AccessControl/hooks/useAddAccessControl";
import { UIContext } from "@utils/Context/UIContext";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { ErrorLogger } from "@utils/EventLogger";
import { generateS3ObjectKey } from "@utils/Functions/S3Storage/generateS3ObjectKey";
import { onS3UserErrorMessage } from "@utils/Functions/S3Storage/onS3UserErrorMessage";
import { s3MultiPartUpload } from "@utils/Functions/S3Storage/s3MultiPartUpload";
import { tryFunction } from "@utils/Functions/tryFunction";
import DashboardCardSection from "@utils/GenericComponents/DashboardCard/components/DashboardCardSection";
import { handleFileUploads } from "@utils/GenericComponents/Validation/FileValidation/FileUploadValidation/functions/handleFileUploads";
import { getOrganization_minimal } from "@views/AdminPanel/Organizations/graphql/__organizationGQL";

import { S3_BUCKET_REGION } from "../../../../env-config";
import { usePleaseWaitModal } from "../../usePleaseWaitModal";

/**
 * @typedef UploadDocumentProps
 * @param {string} organizationID - selected organization
 * @param {function} [getNewItem] - callback for a new item
 * @param {object} [connectionInput] - object with fields for a document connections
 * @param {string} [filePath] - path to a file in the S3 bucket. Default: "documents"
 * @param {string} [customBucket] - custom bucket name - when not using the organization default
 * @param {function} [resetFunction] - reset function from parent component
 * @param {function} [onSubmit] - on complete get created documents
 * @param {function} [toggleModal] - toggle modal function from parent component
 * @param {number} [maxFileSize] - max file size in bytes. Default: 524288000 (500 MB)
 * @param {string} [documentTypename] - document typename. Default: "Document", can be "VendorDocument"
 * @param {boolean} [disableAccessControl] - hide access control settings for file
 */

/**
 * Upload Document generic component
 * @param {UploadDocumentProps} props
 * @returns {ReactElement}
 */
const UploadDocument = ({
  organizationID,
  getNewItem,
  connectionInput = {},
  filePath,
  customBucket,
  resetFunction,
  onSubmit,
  toggleModal,
  maxFileSize,
  documentTypename = "Document",
  disableAccessControl,
}) => {
  const allowedExtensions = AllowedFileUploadTypes.join(",");
  const [name, setName] = useState("");
  const [isAccessControl, setIsAccessControl] = useState(false);
  const [progress, setProgress] = useState(0);

  const { addToast, updateToast } = useContext(UIContext);
  const pleaseWaitModal = usePleaseWaitModal({
    progressTotal: 100,
  });

  const handleSubmit = async () => {
    const toastId = addToast({
      header: `Uploading Document, please wait...`,
      icon: "spinner",
      color: "success",
    });

    pleaseWaitModal.setModalIsOpen(true);

    /**
     * Get organization S3 bucket
     */
    const organization = await QueryGetItem({
      query: getOrganization_minimal,
      itemId: organizationID,
    });

    const onprogress = (loaded) => {
      setProgress(loaded);
      if (loaded === 100) {
        updateToast({
          id: toastId,
          header: "Document was successfully uploaded",
          icon: "success",
        });
        pleaseWaitModal.setModalIsOpen(false);
      }
      pleaseWaitModal?.setProgress(loaded);
    };

    const onUploadError = async (err) => {
      const errorMessage = onS3UserErrorMessage({ error: err });

      updateToast({
        id: toastId,
        header: errorMessage,
        icon: "danger",
      });

      pleaseWaitModal.setModalIsOpen(false);
    };

    try {
      const region = S3_BUCKET_REGION;

      const bucketName = customBucket || organization?.s3BucketName;
      const { username: owner } = await Auth.currentUserInfo();

      const files = uploaderRef.getFilesData();
      const validFiles = handleFileUploads(files, AllowedFileUploadTypes);
      const documents = [];

      const fileUploadThreads = validFiles.map(async (f) => {
        const file = f?.rawFile;

        const fileName = file?.name;
        const objectKey = generateS3ObjectKey(`${filePath ? filePath : "documents"}/${fileName}`);

        const fileInfo = {
          bucket: bucketName,
          region: region,
          key: objectKey,
        };

        try {
          await s3MultiPartUpload({
            file,
            bucket: bucketName,
            key: objectKey,
            organizationID,
            uploadProgress: onprogress,
          });
        } catch (error) {
          await onUploadError(error);
          Sentry.captureMessage(`Failed to upload document: ${document?.name}. Error: ${error?.message}`);
          return;
        }

        const { createMutation } = generateGraphql(
          documentTypename,
          ["name", "owner", "visibility", "createdAt", "file"],
          { file: "{ bucket region key }" },
        );
        const document = await ItemMutation({
          mutation: createMutation,
          input: {
            name: name ? name : fileName,
            owner: owner,
            file: fileInfo,
            ownerGroup: organizationID,
            ...connectionInput,
          },
        });

        if (!isNullOrUndefined(document)) {
          documents.push(document);
        }

        if (disableAccessControl) {
          await addAccessControl?.submitFunction({ itemToAssign: document });
        }

        /**
         * Perform document security checks
         */
        if (document) {
          if (typeof getNewItem === "function") getNewItem(document);
        }
      }); // End forEach loop.

      await Promise.all(fileUploadThreads);
      pleaseWaitModal.setModalIsOpen(false);

      await onSubmit?.(documents);
    } catch (e) {
      ErrorLogger("Error: Could not upload documents!", e);
    } finally {
      tryFunction(resetFunction, toggleModal);
    }
  }; // End "handleSubmit" function.

  const [uploaderRef, setUploaderRef] = useState("");

  /**
   * Add access control to a document
   */
  const addAccessControl = useAddAccessControl({
    disableFormButtons: true,
    typename: documentTypename,
    organizationID,
  });

  return (
    <div className="animated fadeIn">
      {pleaseWaitModal.modal}
      {
        <div title="Upload Documents">
          <DashboardCardSection
            title={"Document Name"}
            content={
              <Input
                id="document-name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            }
          />
          {!disableAccessControl && (
            <DashboardCardSection
              title={"Access Control"}
              content={
                <>
                  <CustomInput
                    type="switch"
                    id="isAccessControl"
                    name="isAccessControl"
                    checked={isAccessControl}
                    onChange={() => setIsAccessControl(!isAccessControl)}
                  />
                  {isAccessControl && <div style={{ marginTop: "1em" }}>{addAccessControl.displayAccordion}</div>}
                </>
              }
            />
          )}
          <DashboardCardSection
            title={"Select Files"}
            content={
              <UploaderComponent
                autoUpload={false}
                allowedExtensions={allowedExtensions}
                beforeUpload={handleSubmit}
                ref={(r) => setUploaderRef(r)}
                maxFileSize={maxFileSize || 524288000} // 500 MB
              />
            }
          />
          <br />
          <Progress animated={progress !== 100} value={progress}>
            {parseInt(progress)}%
          </Progress>
        </div>
      }
    </div>
  );
};
export default withOrganizationCheck(UploadDocument);
