import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { TEMPLATE } from "../../enums/TEMPLATE";
import { useUUID } from "../../hooks/functional/useUUID";

import FilterAndClearButtonGroup from "./components/FilterAndClearButtonGroup";

/**
 * Filter menu designed for the TemplateTypeField custom field column
 * @param {object} props - column info passed in by syncfusion
 * @return {JSX.Element} - filter UI to modify which items are displayed
 */
const TemplateTypeFilterMenu = ({ props }) => {
  const dropdownOptions = [
    {
      text: "Global Templates",
      value: true,
    },
    {
      text: "Operation Team Templates",
      value: false,
    },
  ];

  const [dropdownValue, setDropdownValue] = useState(true);
  const [filterValue, setFilterValue] = useState(null);

  //Reference to the dropdown in the filter menu
  const dropdownRef = useRef(null);
  const [uuid] = useUUID();

  //On opening filter menu find if any active filters are applied to the column
  useEffect(() => {
    if (props?.column?.name) {
      const columnName = props?.column?.name;
      const activeFilters = props?.column?.parent?.filterModule?.actualPredicate;
      //Continue only if there's an active filter on this field
      if (activeFilters?.hasOwnProperty(columnName)) {
        const activeColumnFilters = activeFilters[columnName];
        for (const filter of activeColumnFilters) {
          if (filter?.operator === "equal") {
            setDropdownValue(true);
          } else if (filter?.operator === "notequal") {
            setDropdownValue(false);
          }
          setFilterValue(filter?.value);
        }
      }
    }
  }, []);

  //Filter the data upon pressing "Apply"
  const applyFilter = () => {
    if (props?.column?.name) {
      const columnName = props?.column?.name;
      props?.column?.parent?.filterByColumn(columnName, dropdownValue ? "equal" : "notequal", TEMPLATE, "and");
      setFilterValue(TEMPLATE);
    }
  };

  //On pressing the "Clear" button remove all filters for the column and remove the current dropdown value
  const clearFilter = () => {
    if (props?.column?.name) {
      setFilterValue(null);
      props?.column?.parent?.clearFiltering([props?.column?.name]);
    }
  };

  return (
    <div>
      <DropDownListComponent
        // identity
        id={`template-type-filter-menu-dropdown-${uuid}`}
        ref={dropdownRef}
        // style
        popupHeight="250px"
        // choices
        index={dropdownValue ? 0 : 1}
        dataSource={dropdownOptions}
        // filtering
        allowFiltering={false}
        // events
        change={({ itemData }) => {
          setDropdownValue(itemData?.value);
        }}
      />
      <FilterAndClearButtonGroup
        onClickFilter={applyFilter}
        onClickClear={clearFilter}
        clearDisabled={isNullOrUndefined(filterValue)}
      />
    </div>
  );
};

export default TemplateTypeFilterMenu;
