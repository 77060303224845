import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";
import { useResultFieldViewer } from "../hooks/useResultFieldViewer";

/**
 * A component instance of the useResultFieldViewer.js hook
 * @param {object} props
 */
const ResultFieldViewer = (props) => {
  return useResultFieldViewer(props).display;
};

export default withOrganizationCheck(ResultFieldViewer);
