import { Auth } from "@aws-amplify/auth";
import { Lambda } from "aws-sdk";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import awsmobile from "../../../../aws-exports";

export const AddUserToGroup = async ({ userName, userPoolGroupName, organizationID }) => {
  await Auth.currentCredentials().then(async (credentials) => {
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: "us-west-2",
    });

    await invokeLambda(lambda, userName, userPoolGroupName, organizationID)
      .then(() => InfoLogger("User Added to Group"))
      .catch((err) => ErrorLogger(`User was NOT added to Group${err}`));
  });
};

const invokeLambda = async (lambda, userName, userPoolGroupName, organizationID) => {
  const params = {
    FunctionName: "AddUserToCognitoGroup",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      userPoolId: awsmobile["aws_user_pools_id"],
      userName: userName,
      userPoolGroupName: userPoolGroupName
        ? userPoolGroupName
        : organizationID && organizationID !== "No Organization Selected"
          ? organizationID
          : undefined,
    }),
  };

  return lambda.invoke(params, function (err, data) {
    if (err) ErrorLogger(err, err.stack);
    else {
      InfoLogger(data);

      const result = JSON.parse(data.Payload);

      if (result && result.errorMessage) {
        alert(result.errorMessage);
      }
    }
  });
};
