import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a Recommendation Action Item link
 * @param {object} recommendation - the recommendation object
 * @param {object} actionItem - the action item object
 * @returns {Promise<*>}
 */
export const createRecommendationActionItemLink = (recommendation, actionItem) => {
  try {
    checkArguments(
      { recommendation, actionItem },
      {
        recommendation: { type: "object", validAsNull: false },
        actionItem: { type: "object", validAsNull: false },
      },
    );
  } catch (e) {
    ErrorLogger("Invalid recommendation or action item given to createRecommendationActionItemLink.", e.message);
  }

  const { createMutation } = generateGraphql("RecommendationActionLink");

  return ItemMutation(createMutation, {
    recommendationID: recommendation.id,
    actionID: actionItem.id,
    ownerGroup: recommendation.ownerGroup,
  });
};
