import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import NestedDocumentsCard from "../../../Compliance/Documents/components/NestedDocumentsCard";

/**
 * @description Display meeting documents
 * @param {object} item - meeting
 * @param {string} organizationID - current organization ID
 * @returns {*}
 * @constructor
 */
const MeetingDocuments = ({ item, organizationID }) => {
  return (
    <NestedDocumentsCard
      item={item}
      module={modules.GOVERNANCE}
      resource={resources.MEETING}
      parentTypename={resources.MEETING}
      connectionIDField={"meetingID"}
      organizationID={organizationID}
    />
  );
};

export default MeetingDocuments;
