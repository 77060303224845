import { combineFieldConfig } from "./combineFieldConfig";

/**
 * Adapts the mutation input to strip or update field values that will cause a graphql error, such as an empty string for a date.
 * @param {object} mutationInput - the input to adapt
 * @param {object} fieldConfig - the field config to use for adapting the input
 * @returns {*}
 */
export const handleEmptyFieldInputs = ({ mutationInput, fieldConfig }) => {
  if (!mutationInput) {
    return mutationInput;
  }

  const combinedFieldConfig = combineFieldConfig({ fieldConfig });
  if (!combinedFieldConfig) {
    return mutationInput;
  }

  // handle default or blank values that will cause graphql errors
  Object.entries(combinedFieldConfig).forEach(([fieldName, properties]) => {
    if (
      properties?.inputType === "date" &&
      (mutationInput?.[fieldName] === "" || mutationInput?.[fieldName] === null)
    ) {
      mutationInput[fieldName] = undefined;
    }
  });

  return mutationInput;
};
