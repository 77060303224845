import { useState } from "react";

import { useStateEffect } from "../../../../hooks/functional/useStateEffect";

export const useLoadingAndOutdated = (changeItem) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isOutdated, setIsOutdated] = useStateEffect(false, [changeItem], () => true);

  return {
    isLoading,
    setIsLoading,
    isOutdated,
    setIsOutdated,
  };
};
