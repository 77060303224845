import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useNestedItems } from "./useNestedItems";

/**
 * Wrapper around useNestedItems which allows for an external data management to be used instead
 * @param {object[]} [externalItems] - the external items to use if null or undefined will logic in useNestedItems
 * @param {function} [setExternalItems] - sets the external items
 * @param {function} [resetExternalItems] - refreshes the external items
 * @param {boolean} [isLoadingExternalItems] - if true means external items are loading
 * @param {object} props - all props to pass down to useNestedItems
 * @returns {{isLoading: (*|boolean), resetFunction: *, setItems: (*|((value: (((prevState: []) => []) | [])) => void)), items: (*|[])}}
 */
export const useNestedItemsWithExternalOption = ({
  externalItems,
  setExternalItems,
  resetExternalItems,
  isLoadingExternalItems,
  ...props
}) => {
  const { items, setItems, resetFunction, isLoading } = useNestedItems({
    ...props,
  });

  const isExternallyDriven = !isNullOrUndefined(externalItems);

  return {
    items: isExternallyDriven ? externalItems : items,
    setItems: isExternallyDriven ? setExternalItems : setItems,
    resetFunction: isExternallyDriven ? resetExternalItems : resetFunction,
    isLoading: isExternallyDriven ? isLoadingExternalItems : isLoading,
  };
};
