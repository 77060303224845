import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import useCreateProgramElement from "../hooks/useCreateProgramElement";

/**
 * Component instance of the useCreateProgramElement hook
 * @param {object} props
 */
const CreateProgramElement = (props) => {
  return useCreateProgramElement(props).display;
};

export default withOrganizationCheck(CreateProgramElement);
