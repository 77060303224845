export const observationsByOwnerGroup = /* GraphQL */ `
  query ObservationsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    observationsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        module
        isFinding
        ownerGroup
        risk
        formalResponse
        externalSource {
          id
          name
        }
        department {
          id
          name
        }
        decision
        needsDecision
        status
        insurancePolicyValidated
        estimatedCompletionDate
      }
      nextToken
    }
  }
`;
