import a from "indefinite";
import React, { useEffect } from "react";

import { convertCamelCaseToSentence, isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { getResourceQueryData } from "../../../definitions/functions/getResourceQueryData";
import { getListByFieldString } from "../../../definitions/functions/queries/getListByFieldString";
import { fieldContexts } from "../../../enums/fieldContexts";
import { deleteItem } from "../../../utils/Functions/Graphql/deleteItem";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import CreateLinking from "../../../utils/GenericComponents/CreateLinking";
import { useNestedItemsWithExternalOption } from "../../graphql/useNestedItemsWithExternalOption";

import { getConnectionsGridPersistenceID } from "./functions/getConnectionsGridPersistenceID";

/**
 * Displays a Grid of Nested Field objects from a 1-M or M-M connection.
 * Overloads the create resource component with a 'Create or Select' component as well.
 *
 * @param {object} item - the parent item
 * @param {string} [query] - optional full GetQuery string
 * @param {string} field - the field corresponding to the nested resource on the parent object
 * @param {string} connectionField - for M-M connections, the nested field corresponding to the actual child object
 * @param {string} connectionIDField - for 1-M connections, the field corresponding to the parent ID, e.g. 'sourceID'
 * @param {function} deleteFunction - delete function for the Connection Link
 * @param {string} typename - the typename of the Child resource
 * @param {string} parentTypename - the typename of the parent resource
 * @param {string} linkTypename - for M-M connections the typename of the link between resources
 * @param {string} linkParentConnectionIDField - for M-M connections the field corresponding to the parent ID,
 * e.g. 'observationID' when looking at recommendations in observation details
 * @param {string} linkChildConnectionIDField - for M-M connections the field corresponding to the child ID,
 * e.g. 'recommendationID' when looking at recommendations in observation details
 * @param {JSX.Element} grid - a component reference to use in the 'CreateOrSelect' component
 * @param {React.Hook} gridHook - a hook import reference to use in the displayed grid
 * @param {object} gridHookGridConfig - grid config parameters to pass into the gridHook grid
 * @param {object} gridProps - pass custom grid props
 * @param {string} [createItemModalHeader] - optional override for create modal
 * @param {JSX.Element} [createResourceComponent] - optional component to replace create/select component
 * @param {boolean} [disableLinking] - if true, disables the linking functionality
 * @param {function} [normalizeData] - if provided connections data will be go through this function, useful for custom filters
 * @param {JSX.Element} form - a form component using the useForm hook for creating new Child resources before connection
 * @param {function} createFunction - a function for creating the connection link
 * @param {string[]} fields - fields to use in the Query, if not using the query param directly
 * @param {object} nestedFields - nestedFields to use in the Query, if not using the query param directly
 * @param {boolean} flipCreateParams - flips the createFunction params around. From createFunction(childItem, parentItem) to createFunction(parentItem, childItem)
 * @param {string} organizationID - currently selected organization, used for queries
 * @param {string} selectItemAlert - alert to display when selecting an item
 * @param {object[]} [linkedItems] - optional array of items already linked to parent (not used for display of items)
 *
 * @param {object[]} externalItems - external items to display in the grid (take precedence over ones that could be returned by the query)
 * @param {function} setExternalItems - function to set external items
 * @param {function} resetExternalItems - function to reset external items
 * @param {boolean} isLoadingExternalItems - whether or not external items are loading
 * @returns {*}
 */
export const useNestedDataCard = ({
  item,
  query,
  field,
  connectionField,
  connectionIDField, // for 1-M
  deleteFunction,
  typename,
  parentTypename,
  linkTypename,
  linkParentConnectionIDField,
  linkChildConnectionIDField,

  grid,
  gridHook,
  gridHookGridConfig,
  gridProps,
  createItemModalHeader,
  createResourceComponent,
  disableLinking,
  normalizeData,
  form,
  createFunction,
  fields,
  nestedFields,
  flipCreateParams,
  organizationID,
  selectItemAlert,
  linkedItems,

  externalItems,
  setExternalItems,
  resetExternalItems,
  isLoadingExternalItems,
}) => {
  let finalQuery = null;
  if (isNonEmptyArray(externalItems)) {
    finalQuery = null;
  } else if (query) {
    // If a query is provided, use it
    finalQuery = query;
  } else if (fields && nestedFields) {
    // When query parts are provided, generate the query
    finalQuery = generateGraphql(parentTypename, fields, nestedFields).getQuery;
  } else if (typename && field) {
    // When only the field at the parent level is provided, generate the query
    const { queryFields, nestedFields } =
      getResourceQueryData({
        fieldContext: fieldContexts.GRID,
        typename,
      }) || {};

    //When definitions don't have query fields defined skip generating the query
    if (Array.isArray(queryFields)) {
      const fieldString = getListByFieldString({ queryFields, nestedFields });

      //Determine if it is a 1-M or M-M connection
      if (connectionField) {
        // - M-M connection
        finalQuery = generateGraphql(parentTypename, [field], {
          [field]: `(limit: 500) {
            items {
              id
              ownerGroup
              ${connectionField} {
                ${fieldString}
              }
            }
          }`,
        }).getQuery;
      } else {
        // - 1-M connection
        finalQuery = generateGraphql(parentTypename, [field], {
          [field]: `(limit: 500) {
            items {
              ${fieldString}
            }
          }`,
        }).getQuery;
      }
    }
  }

  const { items, setItems, resetFunction, isLoading } = useNestedItemsWithExternalOption({
    //useNestedItems props
    item,
    field,
    connectionField,
    query: finalQuery,
    normalizeData,

    //external item option
    externalItems,
    setExternalItems,
    resetExternalItems,
    isLoadingExternalItems,
  });

  const queryConfig = {
    query: null,
    resetFunction,
  };

  /**
   * For Many-To-Many connections, needs an outside deleteFunction param or linkTypename defined
   * For One-To-Many connections, can use the connectionIDField to automatically perform an updateMutation
   * @param itemToRemove
   * @returns {Promise<*>}
   */
  const deleteFunc = async (itemToRemove) => {
    // For M-M connections
    if (linkTypename) {
      const { getQuery } = generateGraphql(linkTypename, ["__typename"]);
      await deleteItem(getQuery, itemToRemove?.link);
    } else if (!isNullOrUndefined(deleteFunction) && typeof deleteFunction === "function") {
      await deleteFunction(itemToRemove?.link);
    }
    // For 1-M connections
    else if (!isNullOrUndefined(connectionIDField)) {
      const { updateMutation } = generateGraphql(typename, [connectionIDField]);
      return await ItemMutation(updateMutation, {
        id: itemToRemove.id,
        [connectionIDField]: null,
      });
    }

    //Remove the item entry from the display grid if its present
    const foundDeletedItemIndex = items.findIndex((item) => item?.id === itemToRemove?.id);
    if (foundDeletedItemIndex !== -1) {
      setItems((items) => {
        items.splice(foundDeletedItemIndex, 1);
        return items;
      });
    }
  };

  const gridConfig = {
    organizationID,
    deleteFunction: disableLinking === true ? null : deleteFunc,
    gridHeight: "100%",
    allowPaging: false,
    createResourceComponent:
      disableLinking === true
        ? null
        : (createResourceComponent ?? (
            <CreateLinking
              parentTypename={parentTypename}
              typename={typename}
              linkTypename={linkTypename}
              linkParentConnectionIDField={linkParentConnectionIDField}
              linkChildConnectionIDField={linkChildConnectionIDField}
              createFunction={createFunction}
              item={item}
              linkedItems={linkedItems || items}
              form={form}
              grid={grid}
              callback={resetFunction}
              flipCreateParams={flipCreateParams}
              connectionIDField={connectionIDField}
              organizationID={organizationID}
              selectItemAlert={selectItemAlert}
            />
          )),
    deleteFunctionNote: null, //removes any existing delete note since nested data card only removes links between resources
    createItemModalHeader: createItemModalHeader ?? `Attach ${a(convertCamelCaseToSentence(typename))}`,
    createResourceComponentWidth: "75vw",
    createResourceButtonText: "Link",
    deleteButtonText: "Unlink",
    persistenceUUID: getConnectionsGridPersistenceID({
      typename,
      parentTypename,
      field,
    }),
    typename, // ensures update mutation for nested items is generated correctly

    ...gridHookGridConfig,
  };

  useEffect(() => {
    if (items) {
      if (dataGrid?.setUnfilteredData) {
        dataGrid.setUnfilteredData(items);
      } else {
        dataGrid.setData(items);
      }
    }
  }, [items]);

  const dataGrid = gridHook({
    queryConfig,
    gridConfig,
    organizationID,
    isLoading,
    ...gridProps,
    ...gridConfig,
    ...queryConfig,
  });

  return dataGrid;
};
