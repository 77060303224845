"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForMissingDependencyInAdjacencyList = void 0;
const getAutomationIdentifier_1 = require("../utils/getAutomationIdentifier");
/**
 * Makes sure all smart values reference steps that exist in the automation returns empty string if no errors and error
 * string to display if there are errors
 * @param {object} adjacencyList - an object containing all nodes (automation steps) and their outgoing and incoming edges
 * see getSmartValueAdjacencyList for the format of the adjacency list
 * @param {object} automation - the automation data to check with full automation step data present
 * @param {object[]} ancestorSteps - the preceding step data with config and name info (sibling steps of a loop step for instance)
 * @param {function} log - function to log messages and errors
 * @returns {{message: string, status: string}} - the status and message for the check, can be displayed to user in an alert
 */
const checkForMissingDependencyInAdjacencyList = ({ adjacencyList, automation, ancestorSteps, log }) => {
    // Check arguments
    if (!adjacencyList || !automation) {
        return {
            status: "warning",
            message: "Could not check for missing automation step dependencies!",
        };
    }
    // Check if the automation data contains any steps (if any smart values are used)
    let automationSteps = Array.isArray(automation?.automations) ? automation?.automations : [];
    automationSteps = [...automationSteps, ...(ancestorSteps || [])];
    if (!Array.isArray(automationSteps) || automationSteps.length <= 0) {
        return {
            status: "error",
            message: "There are no valid automation steps but the smart values use dependencies!",
        };
    }
    // Check if all adjacency list nodes are in the automation steps
    for (const nodeName in adjacencyList) {
        const node = adjacencyList[nodeName];
        const foundAutomationStep = automationSteps.find((automationStep) => {
            return (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep }) === nodeName;
        });
        // - if an automation step is not found, return an error
        if (!foundAutomationStep) {
            let errorMessage = `The automation step '${nodeName}' does not exist`;
            if (Array.isArray(node?.outgoing) && node.outgoing.length > 0) {
                errorMessage += `, but is referenced by ${node.outgoing.length} Steps (${node.outgoing.join(", ")}).`;
            }
            else {
                errorMessage += ".";
            }
            return {
                status: "error",
                message: errorMessage,
            };
        }
    }
    return {
        status: "success",
        message: "All smart values reference valid automation steps!",
    };
};
exports.checkForMissingDependencyInAdjacencyList = checkForMissingDependencyInAdjacencyList;
