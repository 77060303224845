import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";

import { isNullOrUndefined, tryParse } from "@rivial-security/func-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { useGUID } from "../../../../hooks/functional/useGUID";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { customWidgetsByOwnerGroup } from "../../../CustomWidgets/WidgetDataGrid/graphql/customWidgetsByOwnerGroup";
import { defaultEmailTemplate } from "../constants/defaultEmailTemplate";
import { getMergeTags } from "../functions/getMergeTags";

/**
 * Universal email HTML generator
 * @param {object} item - the email to edit
 * @param {string} organizationID - the organization under which the email is being edited
 * @param {object} editorOptions - options to pass to the editor and default content
 * @param {string} editorOptions.minHeight - the minimum height of the editor
 * @param {string} editorOptions.contentWidth - the width of the content inside the editor
 * @param {string} contentHTML - string of the HTML to load into the editor at start, otherwise loads default
 * @return {{display: JSX.Element, isReady: boolean, exportHtml: (function(): *), exportDesign: (function(): *)}}
 */
export const useEmailEditor = ({ item, organizationID, editorOptions, contentHTML } = {}) => {
  const emailEditorRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  /**
   * Exports the template as HTML string to be used directly when sending emails
   * @returns {Promise<unknown>}
   */
  const exportHtml = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef?.current?.editor?.exportHtml((data) => {
        resolve(data.html);
      });
    });
  };

  /**
   * Exports the template as JSON, need to repopulate the editor
   * @returns {Promise<unknown>}
   */
  const exportDesign = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef?.current?.editor?.exportHtml((data) => {
        resolve(data.design);
      });
    });
  };

  const loadDefault = () => {
    emailEditorRef?.current?.editor?.loadDesign(
      defaultEmailTemplate({
        contentHTML,
        ...(editorOptions || {}),
      }),
    );
  };

  useEffect(() => {
    if (isReady) {
      loadDefault();
    }
  }, [contentHTML]);

  const onReady = async (unlayer) => {
    // editor is ready
    InfoLogger("Email Editor has been Initialized..");
    if (!isNullOrUndefined(item?.design)) {
      InfoLogger("Loading Existing Design..");
      try {
        const design = tryParse(item?.design);
        InfoLogger("got design:", design);
        emailEditorRef?.current?.editor?.loadDesign(design);
        emailEditorRef?.current?.editor?.showPreview("desktop");
      } catch (e) {
        ErrorLogger("Could not load design", e);
        loadDefault();
      }
    } else {
      loadDefault();
    }

    /**
     * Set the merge tags
     */
    try {
      const widgets = await ListQueryBy({
        query: customWidgetsByOwnerGroup,
        variables: {
          ownerGroup: organizationID,
        },
      });

      const tags = getMergeTags({ widgets });

      if (tags) {
        unlayer.setMergeTags(tags);
      }
    } catch (e) {
      ErrorLogger("Could not set merge tags on email editor!", e);
    }

    setIsReady(true);
  };

  const [id] = useGUID();

  const display = (
    <div
      id={"email-editor-body"}
      // eslint-disable-next-line react/no-unknown-property
      editorId={`${id}editor`}
      style={{ height: "100%" }}
    >
      <EmailEditor
        id={`${id}editor`}
        ref={emailEditorRef}
        onReady={onReady}
        minHeight={editorOptions?.minHeight ?? "500px"}
        options={{
          id: `${id}editor`,
          displayMode: "email",
          safeHtml: true,
        }}
      />
    </div>
  );

  return {
    display,
    isReady,
    exportHtml,
    exportDesign,
  };
};
