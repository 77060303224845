import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

import { numberWithCommas } from "@rivial-security/func-utils";

export const useAdjustNumberOfCustomers = ({ numberOfCustomers }) => {
  const [numberOfCustomersChange, setNumberOfCustomersChange] = useState(0);
  const [newNumberOfCustomers, setNewNumberOfCustomers] = useState(0);

  // Calculate the change in number of customers
  useEffect(() => {
    let res = 0;
    if (numberOfCustomers) {
      if (numberOfCustomersChange) {
        res = parseFloat(numberOfCustomers) + parseFloat(numberOfCustomersChange);
      } else {
        res = numberOfCustomers;
      }
    }
    setNewNumberOfCustomers(res);
  }, [numberOfCustomers, numberOfCustomersChange]);

  const display = (
    <>
      {numberOfCustomers && (
        <>
          <FormGroup>
            <Label for="numberOfCustomersChange">Change In Number of Customers</Label>
            <Row>
              <Col md={4}>
                <>
                  {newNumberOfCustomers === numberOfCustomers ? (
                    `${numberWithCommas(numberOfCustomers)} (No Change)`
                  ) : (
                    <>
                      <del title={`Old Number of Customers: ${numberWithCommas(numberOfCustomers)}`}>
                        {numberWithCommas(numberOfCustomers)}
                      </del>
                      <span
                        title={`New Number of Customers: ${numberWithCommas(newNumberOfCustomers)}`}
                        style={{
                          color: newNumberOfCustomers > numberOfCustomers ? "red" : "green",
                          marginLeft: "0.5em",
                        }}
                      >
                        {numberWithCommas(newNumberOfCustomers)}
                        <br />[ {newNumberOfCustomers > numberOfCustomers && "+"}{" "}
                        {(((newNumberOfCustomers - numberOfCustomers) / numberOfCustomers) * 100).toFixed(2)}% ]
                      </span>
                    </>
                  )}
                </>
              </Col>
              <Col>
                <InputGroup>
                  <Input
                    type="number"
                    id="numberOfCustomersChange"
                    value={numberOfCustomersChange}
                    onChange={(e) => setNumberOfCustomersChange(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </>
      )}
    </>
  );

  return {
    display,
    numberOfCustomersChange,
    newNumberOfCustomers,
  };
};
