"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSystemRiskLink = void 0;
exports.updateSystemRiskLink = `
  mutation UpdateSystemRiskLink($input: UpdateSystemRiskLinkInput!) {
    updateSystemRiskLink(input: $input) {
      id
      ownerGroup
      inherentRisk
      residualRisk
      riskMitigated
    }
  }
`;
