/**
 * Performs a shallow merge of additional fields by propertyName into the fields object (fields object is not cloned but used)
 * @typedef MergeAdditionalFieldsProps
 * @type {object}
 * @property {object[]} additionalFields - any new fields to add or replace old ones
 * @property {object[]} fields - the original fields for a grid
 * @property {string} [propertyName] - the name of the property to merge
 *
 * @param {MergeAdditionalFieldsProps} additionalFieldsProps
 */
export const mergeAdditionalFields = ({ additionalFields = [], fields, propertyName = "name" }) => {
  //If the fields argument is not array do not perform any changes, since this function modifies the references object
  if (!Array.isArray(fields)) {
    return;
  }

  if (Array.isArray(additionalFields)) {
    for (const field of additionalFields) {
      const foundFieldIndex = fields.findIndex((item) => {
        return item?.[propertyName] === field?.[propertyName];
      });
      if (foundFieldIndex !== -1) {
        //If column settings hide the field then delete it from the list, otherwise replace the settings
        if (field?.visible === false && field?.showInColumnChooser === false) {
          fields.splice(foundFieldIndex, 1);
        } else {
          fields[foundFieldIndex] = field;
        }
      } else {
        fields.push(field);
      }
    }
  }
};
