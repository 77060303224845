import { gql } from "@graphql/types";

export const CONTROL_FRAMEWORK_DASHBOARDS = gql(`
  query controlFrameworkDashboards($limit: Float, $nextToken: String, $sort: CustomDashboardSort) {
    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        name
        description
        controlFramework {
          id
          name
          customName
          dashboardLogo
          controlStatistics {
            countInPlace
            countControls
            compliancePercentage
          }
        }
      }
      nextToken
    }
  }
`);
