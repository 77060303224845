import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { usePolicyDetails } from "../hooks/usePolicyDetails/usePolicyDetails";

/**
 * Component displaying information about a single policy resource
 * @param {object} props - any properties to pass down to the details hook
 * @return {JSX.Element}
 */
const PolicyDetails = (props) => usePolicyDetails(props).display;

export default withDetailsPage(PolicyDetails);
