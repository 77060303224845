import React, { useEffect } from "react";

import { convertCamelCaseToSentence, isNonEmptyArray } from "@rivial-security/func-utils";

import TagsField from "../../../../../../utils/Tags/customFields/TagsField";
import { useTable } from "../../../../useTable";

import DataGridBulkEditTagsPreviewField from "./DataGridBulkEditTagsPreviewField";

/**
 * Small UI for displaying a preview of selected data in a simple table
 * @param {string} primaryField - the primary field to display in the table
 * @param {object[]} selectedData - the array of selected data, each item is passed to the table for preview
 * @param {string[]} fields - other fields to add to the table
 * @param {object[]} [customFieldsExternal] - custom fields to add to the table
 * @param {*} newValue - the updated value to apply to the preview
 * @returns {JSX.Element}
 * @constructor
 */
const DataGridBulkEditSelectedItemsPreview = ({
  primaryField,
  selectedData = [],
  selectedField = "name",
  customFields: customFieldsExternal,
  newValue,
}) => {
  // Toggle to show the ID field
  const SHOW_ID_FIELD = false;

  /**
   * Gets the list of fields to show for the preview table.
   * Displays ID always, and a 'name' column since most resources have a 'name'
   * @returns {*[]}
   */
  const getFields = () => {
    const fields = [];

    if (SHOW_ID_FIELD) {
      fields.push("id");
    }

    fields.push(primaryField);
    if (selectedField !== primaryField) {
      fields.push(selectedField);
    }

    fields.push("newValue");

    return fields;
  };

  /**
   * Gets the list of Custom Field configurations for the table.
   * Applies built-in component overrides for common columns like Tags
   * @returns {[{component: JSX.Element, field: string}]}
   */
  const getCustomFields = () => {
    const customFields = [
      {
        field: "tags",
        component: <TagsField />,
      },
    ];

    // if this is a 'tags' field update, override the 'newValue' column with the custom tags preview
    if (selectedField === "tags") {
      customFields.push({
        field: "newValue",
        component: <DataGridBulkEditTagsPreviewField />,
      });
    }

    if (isNonEmptyArray(customFieldsExternal)) {
      customFields.push(...customFieldsExternal);
    }

    return customFields;
  };

  const isStartOrEndDateField = selectedField === "startDate" || selectedField === "endDate";
  const table = useTable({
    fields: getFields(),
    fieldNameDictionary: {
      [selectedField]: `Current ${convertCamelCaseToSentence(selectedField)}`,
      newValue: `New ${convertCamelCaseToSentence(selectedField)}`,
    },
    data: Array.isArray(selectedData)
      ? selectedData.map((item) => ({
          ...item,
          newValue: isStartOrEndDateField ? new Date(newValue).toISOString() : newValue,
        }))
      : [],
    disableRoleChecking: true,
    customFields: getCustomFields(),
  });

  useEffect(() => {
    if (newValue || newValue === undefined) {
      table.setData(
        Array.isArray(selectedData)
          ? selectedData.map((item) => ({
              ...item,
              newValue: isStartOrEndDateField ? new Date(newValue).toISOString() : newValue,
            }))
          : [],
      );
    }
  }, [newValue]);

  return table.display;
};

export default DataGridBulkEditSelectedItemsPreview;
