import { useSystemTemplateDataGrid } from "../hooks/useSystemTemplateDataGrid";

/**
 * Custom component for displaying System Templates
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateDataGrid = (props) => {
  const grid = useSystemTemplateDataGrid(props);

  return <div style={{ height: "75vh" }}>{grid.display}</div>;
};

export default SystemTemplateDataGrid;
