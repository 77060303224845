/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createControlEvidenceLink = /* GraphQL */ `
  mutation CreateControlEvidenceLink($input: CreateControlEvidenceLinkInput!) {
    createControlEvidenceLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateControlEvidenceLink = /* GraphQL */ `
  mutation UpdateControlEvidenceLink($input: UpdateControlEvidenceLinkInput!) {
    updateControlEvidenceLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteControlEvidenceLink = /* GraphQL */ `
  mutation DeleteControlEvidenceLink($input: DeleteControlEvidenceLinkInput!) {
    deleteControlEvidenceLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const createControlSet = /* GraphQL */ `
  mutation CreateControlSet($input: CreateControlSetInput!) {
    createControlSet(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      controls {
        nextToken
      }
    }
  }
`;
export const updateControlSet = /* GraphQL */ `
  mutation UpdateControlSet($input: UpdateControlSetInput!) {
    updateControlSet(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      controls {
        nextToken
      }
    }
  }
`;
export const deleteControlSet = /* GraphQL */ `
  mutation DeleteControlSet($input: DeleteControlSetInput!) {
    deleteControlSet(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      controls {
        nextToken
      }
    }
  }
`;
export const createControlChange = /* GraphQL */ `
  mutation CreateControlChange($input: CreateControlChangeInput!) {
    createControlChange(input: $input) {
      id
      controlID
      ownerGroup
      field
      date
      userEmail
      change
      createdAt
      updatedAt
    }
  }
`;
export const updateControlChange = /* GraphQL */ `
  mutation UpdateControlChange($input: UpdateControlChangeInput!) {
    updateControlChange(input: $input) {
      id
      controlID
      ownerGroup
      field
      date
      userEmail
      change
      createdAt
      updatedAt
    }
  }
`;
export const deleteControlChange = /* GraphQL */ `
  mutation DeleteControlChange($input: DeleteControlChangeInput!) {
    deleteControlChange(input: $input) {
      id
      controlID
      ownerGroup
      field
      date
      userEmail
      change
      createdAt
      updatedAt
    }
  }
`;
export const createControlRecommendation = /* GraphQL */ `
  mutation CreateControlRecommendation(
    $input: CreateControlRecommendationInput!
  ) {
    createControlRecommendation(input: $input) {
      id
      observation
      recommendation
      rating
      controlID
      resolved
      dateResolved
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateControlRecommendation = /* GraphQL */ `
  mutation UpdateControlRecommendation(
    $input: UpdateControlRecommendationInput!
  ) {
    updateControlRecommendation(input: $input) {
      id
      observation
      recommendation
      rating
      controlID
      resolved
      dateResolved
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteControlRecommendation = /* GraphQL */ `
  mutation DeleteControlRecommendation(
    $input: DeleteControlRecommendationInput!
  ) {
    deleteControlRecommendation(input: $input) {
      id
      observation
      recommendation
      rating
      controlID
      resolved
      dateResolved
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      id
      subject
      body
      ownerGroup
      type
      date
      reporter
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback($input: UpdateFeedbackInput!) {
    updateFeedback(input: $input) {
      id
      subject
      body
      ownerGroup
      type
      date
      reporter
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback($input: DeleteFeedbackInput!) {
    deleteFeedback(input: $input) {
      id
      subject
      body
      ownerGroup
      type
      date
      reporter
      status
      createdAt
      updatedAt
    }
  }
`;
export const createHelpArticle = /* GraphQL */ `
  mutation CreateHelpArticle($input: CreateHelpArticleInput!) {
    createHelpArticle(input: $input) {
      id
      title
      content
      route
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateHelpArticle = /* GraphQL */ `
  mutation UpdateHelpArticle($input: UpdateHelpArticleInput!) {
    updateHelpArticle(input: $input) {
      id
      title
      content
      route
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelpArticle = /* GraphQL */ `
  mutation DeleteHelpArticle($input: DeleteHelpArticleInput!) {
    deleteHelpArticle(input: $input) {
      id
      title
      content
      route
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
      name
      status
      description
      type
      ownerGroup
      module
      documents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      name
      status
      description
      type
      ownerGroup
      module
      documents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      id
      name
      status
      description
      type
      ownerGroup
      module
      documents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReportDocument = /* GraphQL */ `
  mutation CreateReportDocument($input: CreateReportDocumentInput!) {
    createReportDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      report {
        id
        name
        status
        description
        type
        ownerGroup
        module
        createdAt
        updatedAt
      }
      reportDocumentReportId
      updatedAt
    }
  }
`;
export const updateReportDocument = /* GraphQL */ `
  mutation UpdateReportDocument($input: UpdateReportDocumentInput!) {
    updateReportDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      report {
        id
        name
        status
        description
        type
        ownerGroup
        module
        createdAt
        updatedAt
      }
      reportDocumentReportId
      updatedAt
    }
  }
`;
export const deleteReportDocument = /* GraphQL */ `
  mutation DeleteReportDocument($input: DeleteReportDocumentInput!) {
    deleteReportDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      report {
        id
        name
        status
        description
        type
        ownerGroup
        module
        createdAt
        updatedAt
      }
      reportDocumentReportId
      updatedAt
    }
  }
`;
export const createReportTemplate = /* GraphQL */ `
  mutation CreateReportTemplate($input: CreateReportTemplateInput!) {
    createReportTemplate(input: $input) {
      id
      name
      description
      type
      ownerGroup
      module
      creator
      key
      createdAt
      updatedAt
    }
  }
`;
export const updateReportTemplate = /* GraphQL */ `
  mutation UpdateReportTemplate($input: UpdateReportTemplateInput!) {
    updateReportTemplate(input: $input) {
      id
      name
      description
      type
      ownerGroup
      module
      creator
      key
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportTemplate = /* GraphQL */ `
  mutation DeleteReportTemplate($input: DeleteReportTemplateInput!) {
    deleteReportTemplate(input: $input) {
      id
      name
      description
      type
      ownerGroup
      module
      creator
      key
      createdAt
      updatedAt
    }
  }
`;
export const createCurve = /* GraphQL */ `
  mutation CreateCurve($input: CreateCurveInput!) {
    createCurve(input: $input) {
      id
      ownerGroup
      points {
        x
        y
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCurve = /* GraphQL */ `
  mutation UpdateCurve($input: UpdateCurveInput!) {
    updateCurve(input: $input) {
      id
      ownerGroup
      points {
        x
        y
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurve = /* GraphQL */ `
  mutation DeleteCurve($input: DeleteCurveInput!) {
    deleteCurve(input: $input) {
      id
      ownerGroup
      points {
        x
        y
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSimulationResults = /* GraphQL */ `
  mutation CreateSimulationResults($input: CreateSimulationResultsInput!) {
    createSimulationResults(input: $input) {
      id
      ownerGroup
      name
      inherentLoss
      residualLoss
      whatIfLoss
      currentCostOfControls
      whatIfCostOfControls
      currentROI
      whatIfOverallROI
      whatIfAdditionalROI
      inherentLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      residualLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      whatIfLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      snapshot {
        id
        ownerGroup
        name
        date
        snapshotSystemId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSimulationResults = /* GraphQL */ `
  mutation UpdateSimulationResults($input: UpdateSimulationResultsInput!) {
    updateSimulationResults(input: $input) {
      id
      ownerGroup
      name
      inherentLoss
      residualLoss
      whatIfLoss
      currentCostOfControls
      whatIfCostOfControls
      currentROI
      whatIfOverallROI
      whatIfAdditionalROI
      inherentLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      residualLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      whatIfLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      snapshot {
        id
        ownerGroup
        name
        date
        snapshotSystemId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSimulationResults = /* GraphQL */ `
  mutation DeleteSimulationResults($input: DeleteSimulationResultsInput!) {
    deleteSimulationResults(input: $input) {
      id
      ownerGroup
      name
      inherentLoss
      residualLoss
      whatIfLoss
      currentCostOfControls
      whatIfCostOfControls
      currentROI
      whatIfOverallROI
      whatIfAdditionalROI
      inherentLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      residualLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      whatIfLossCurve {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      snapshot {
        id
        ownerGroup
        name
        date
        snapshotSystemId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSnapshot = /* GraphQL */ `
  mutation CreateSnapshot($input: CreateSnapshotInput!) {
    createSnapshot(input: $input) {
      id
      ownerGroup
      name
      date
      systemStats {
        id
        ownerGroup
        name
        inherentLoss
        residualLoss
        whatIfLoss
        currentCostOfControls
        whatIfCostOfControls
        currentROI
        whatIfOverallROI
        whatIfAdditionalROI
        createdAt
        updatedAt
      }
      risksStats {
        nextToken
      }
      snapshotSystemId
      createdAt
      updatedAt
    }
  }
`;
export const updateSnapshot = /* GraphQL */ `
  mutation UpdateSnapshot($input: UpdateSnapshotInput!) {
    updateSnapshot(input: $input) {
      id
      ownerGroup
      name
      date
      systemStats {
        id
        ownerGroup
        name
        inherentLoss
        residualLoss
        whatIfLoss
        currentCostOfControls
        whatIfCostOfControls
        currentROI
        whatIfOverallROI
        whatIfAdditionalROI
        createdAt
        updatedAt
      }
      risksStats {
        nextToken
      }
      snapshotSystemId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSnapshot = /* GraphQL */ `
  mutation DeleteSnapshot($input: DeleteSnapshotInput!) {
    deleteSnapshot(input: $input) {
      id
      ownerGroup
      name
      date
      systemStats {
        id
        ownerGroup
        name
        inherentLoss
        residualLoss
        whatIfLoss
        currentCostOfControls
        whatIfCostOfControls
        currentROI
        whatIfOverallROI
        whatIfAdditionalROI
        createdAt
        updatedAt
      }
      risksStats {
        nextToken
      }
      snapshotSystemId
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      owner
      timeStamp
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      owner
      timeStamp
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      owner
      timeStamp
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes($input: CreateNotesInput!) {
    createNotes(input: $input) {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      itemId
      controlNotesId
      riskControlNotesId
      assessmentNotesId
      createdAt
      updatedAt
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes($input: UpdateNotesInput!) {
    updateNotes(input: $input) {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      itemId
      controlNotesId
      riskControlNotesId
      assessmentNotesId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes($input: DeleteNotesInput!) {
    deleteNotes(input: $input) {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      itemId
      controlNotesId
      riskControlNotesId
      assessmentNotesId
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
      name
      owner
      encrypted
      encryptionKey
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      action {
        id
        name
        dateCreated
        dateCompleted
        description
        status
        pastDueAt
        ownerGroup
        executionARN
        type
        priority
        module
        createdAt
        updatedAt
      }
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
      name
      owner
      encrypted
      encryptionKey
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      action {
        id
        name
        dateCreated
        dateCompleted
        description
        status
        pastDueAt
        ownerGroup
        executionARN
        type
        priority
        module
        createdAt
        updatedAt
      }
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      id
      name
      owner
      encrypted
      encryptionKey
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      action {
        id
        name
        dateCreated
        dateCompleted
        description
        status
        pastDueAt
        ownerGroup
        executionARN
        type
        priority
        module
        createdAt
        updatedAt
      }
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
    }
  }
`;
export const createSecurityAppliance = /* GraphQL */ `
  mutation CreateSecurityAppliance($input: CreateSecurityApplianceInput!) {
    createSecurityAppliance(input: $input) {
      id
      applianceID
      ip
      port
      openVasUsername
      openVasPassword
      status
      destination
      trackingNumber
      checkOutDate
      checkInDate
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateSecurityAppliance = /* GraphQL */ `
  mutation UpdateSecurityAppliance($input: UpdateSecurityApplianceInput!) {
    updateSecurityAppliance(input: $input) {
      id
      applianceID
      ip
      port
      openVasUsername
      openVasPassword
      status
      destination
      trackingNumber
      checkOutDate
      checkInDate
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecurityAppliance = /* GraphQL */ `
  mutation DeleteSecurityAppliance($input: DeleteSecurityApplianceInput!) {
    deleteSecurityAppliance(input: $input) {
      id
      applianceID
      ip
      port
      openVasUsername
      openVasPassword
      status
      destination
      trackingNumber
      checkOutDate
      checkInDate
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      username
      email
      phoneNumber
      ownerGroup
      config
      accountType
      roleLinks {
        nextToken
      }
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      username
      email
      phoneNumber
      ownerGroup
      config
      accountType
      roleLinks {
        nextToken
      }
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      name
      username
      email
      phoneNumber
      ownerGroup
      config
      accountType
      roleLinks {
        nextToken
      }
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const createUserRoleLink = /* GraphQL */ `
  mutation CreateUserRoleLink($input: CreateUserRoleLinkInput!) {
    createUserRoleLink(input: $input) {
      id
      userID
      roleID
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      role {
        id
        name
        roleConfig
        precedence
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRoleLink = /* GraphQL */ `
  mutation UpdateUserRoleLink($input: UpdateUserRoleLinkInput!) {
    updateUserRoleLink(input: $input) {
      id
      userID
      roleID
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      role {
        id
        name
        roleConfig
        precedence
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRoleLink = /* GraphQL */ `
  mutation DeleteUserRoleLink($input: DeleteUserRoleLinkInput!) {
    deleteUserRoleLink(input: $input) {
      id
      userID
      roleID
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      role {
        id
        name
        roleConfig
        precedence
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      name
      userLinks {
        nextToken
      }
      roleConfig
      precedence
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      name
      userLinks {
        nextToken
      }
      roleConfig
      precedence
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      id
      name
      userLinks {
        nextToken
      }
      roleConfig
      precedence
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createVcisoAction = /* GraphQL */ `
  mutation CreateVcisoAction($input: CreateVCISOActionInput!) {
    createVCISOAction(input: $input) {
      id
      name
      dateCreated
      dueDate
      globalStatus
      status
      description
      type
      ownerGroup
      priority
      maturityIndicator {
        id
        name
        isTrue
        ownerGroup
        createdAt
        updatedAt
      }
      module
      createdAt
      updatedAt
    }
  }
`;
export const updateVcisoAction = /* GraphQL */ `
  mutation UpdateVcisoAction($input: UpdateVCISOActionInput!) {
    updateVCISOAction(input: $input) {
      id
      name
      dateCreated
      dueDate
      globalStatus
      status
      description
      type
      ownerGroup
      priority
      maturityIndicator {
        id
        name
        isTrue
        ownerGroup
        createdAt
        updatedAt
      }
      module
      createdAt
      updatedAt
    }
  }
`;
export const deleteVcisoAction = /* GraphQL */ `
  mutation DeleteVcisoAction($input: DeleteVCISOActionInput!) {
    deleteVCISOAction(input: $input) {
      id
      name
      dateCreated
      dueDate
      globalStatus
      status
      description
      type
      ownerGroup
      priority
      maturityIndicator {
        id
        name
        isTrue
        ownerGroup
        createdAt
        updatedAt
      }
      module
      createdAt
      updatedAt
    }
  }
`;
export const createMaturityColumn = /* GraphQL */ `
  mutation CreateMaturityColumn($input: CreateMaturityColumnInput!) {
    createMaturityColumn(input: $input) {
      id
      name
      ownerGroup
      notes {
        nextToken
        observationID
      }
      maturityMatrixLevels {
        nextToken
      }
      maturityGoal
      createdAt
      updatedAt
    }
  }
`;
export const updateMaturityColumn = /* GraphQL */ `
  mutation UpdateMaturityColumn($input: UpdateMaturityColumnInput!) {
    updateMaturityColumn(input: $input) {
      id
      name
      ownerGroup
      notes {
        nextToken
        observationID
      }
      maturityMatrixLevels {
        nextToken
      }
      maturityGoal
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaturityColumn = /* GraphQL */ `
  mutation DeleteMaturityColumn($input: DeleteMaturityColumnInput!) {
    deleteMaturityColumn(input: $input) {
      id
      name
      ownerGroup
      notes {
        nextToken
        observationID
      }
      maturityMatrixLevels {
        nextToken
      }
      maturityGoal
      createdAt
      updatedAt
    }
  }
`;
export const createMaturityMatrixLevel = /* GraphQL */ `
  mutation CreateMaturityMatrixLevel($input: CreateMaturityMatrixLevelInput!) {
    createMaturityMatrixLevel(input: $input) {
      id
      name
      ownerGroup
      maturityColumn {
        id
        name
        ownerGroup
        maturityGoal
        createdAt
        updatedAt
      }
      maturityIndicators {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMaturityMatrixLevel = /* GraphQL */ `
  mutation UpdateMaturityMatrixLevel($input: UpdateMaturityMatrixLevelInput!) {
    updateMaturityMatrixLevel(input: $input) {
      id
      name
      ownerGroup
      maturityColumn {
        id
        name
        ownerGroup
        maturityGoal
        createdAt
        updatedAt
      }
      maturityIndicators {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaturityMatrixLevel = /* GraphQL */ `
  mutation DeleteMaturityMatrixLevel($input: DeleteMaturityMatrixLevelInput!) {
    deleteMaturityMatrixLevel(input: $input) {
      id
      name
      ownerGroup
      maturityColumn {
        id
        name
        ownerGroup
        maturityGoal
        createdAt
        updatedAt
      }
      maturityIndicators {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMaturityIndicator = /* GraphQL */ `
  mutation CreateMaturityIndicator($input: CreateMaturityIndicatorInput!) {
    createMaturityIndicator(input: $input) {
      id
      name
      isTrue
      ownerGroup
      maturityMatrixLevel {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      actions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMaturityIndicator = /* GraphQL */ `
  mutation UpdateMaturityIndicator($input: UpdateMaturityIndicatorInput!) {
    updateMaturityIndicator(input: $input) {
      id
      name
      isTrue
      ownerGroup
      maturityMatrixLevel {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      actions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaturityIndicator = /* GraphQL */ `
  mutation DeleteMaturityIndicator($input: DeleteMaturityIndicatorInput!) {
    deleteMaturityIndicator(input: $input) {
      id
      name
      isTrue
      ownerGroup
      maturityMatrixLevel {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      actions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting($input: CreateMeetingInput!) {
    createMeeting(input: $input) {
      id
      name
      description
      status
      meetingDate
      meetingTime
      pointOfContacts {
        nextToken
      }
      organizer
      location
      duration
      isAllDay
      startTime
      endTime
      agendaItems {
        nextToken
      }
      documents {
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      id
      name
      description
      status
      meetingDate
      meetingTime
      pointOfContacts {
        nextToken
      }
      organizer
      location
      duration
      isAllDay
      startTime
      endTime
      agendaItems {
        nextToken
      }
      documents {
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting($input: DeleteMeetingInput!) {
    deleteMeeting(input: $input) {
      id
      name
      description
      status
      meetingDate
      meetingTime
      pointOfContacts {
        nextToken
      }
      organizer
      location
      duration
      isAllDay
      startTime
      endTime
      agendaItems {
        nextToken
      }
      documents {
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createAgendaItem = /* GraphQL */ `
  mutation CreateAgendaItem($input: CreateAgendaItemInput!) {
    createAgendaItem(input: $input) {
      id
      content
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAgendaItem = /* GraphQL */ `
  mutation UpdateAgendaItem($input: UpdateAgendaItemInput!) {
    updateAgendaItem(input: $input) {
      id
      content
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgendaItem = /* GraphQL */ `
  mutation DeleteAgendaItem($input: DeleteAgendaItemInput!) {
    deleteAgendaItem(input: $input) {
      id
      content
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPointOfContactMeetingLink = /* GraphQL */ `
  mutation CreatePointOfContactMeetingLink(
    $input: CreatePointOfContactMeetingLinkInput!
  ) {
    createPointOfContactMeetingLink(input: $input) {
      id
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePointOfContactMeetingLink = /* GraphQL */ `
  mutation UpdatePointOfContactMeetingLink(
    $input: UpdatePointOfContactMeetingLinkInput!
  ) {
    updatePointOfContactMeetingLink(input: $input) {
      id
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePointOfContactMeetingLink = /* GraphQL */ `
  mutation DeletePointOfContactMeetingLink(
    $input: DeletePointOfContactMeetingLinkInput!
  ) {
    deletePointOfContactMeetingLink(input: $input) {
      id
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMeetingDocument = /* GraphQL */ `
  mutation CreateMeetingDocument($input: CreateMeetingDocumentInput!) {
    createMeetingDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      meetingDocumentMeetingId
      updatedAt
    }
  }
`;
export const updateMeetingDocument = /* GraphQL */ `
  mutation UpdateMeetingDocument($input: UpdateMeetingDocumentInput!) {
    updateMeetingDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      meetingDocumentMeetingId
      updatedAt
    }
  }
`;
export const deleteMeetingDocument = /* GraphQL */ `
  mutation DeleteMeetingDocument($input: DeleteMeetingDocumentInput!) {
    deleteMeetingDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      meetingDocumentMeetingId
      updatedAt
    }
  }
`;
export const createPolicy = /* GraphQL */ `
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      id
      name
      policyVersions {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updatePolicy = /* GraphQL */ `
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      id
      name
      policyVersions {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deletePolicy = /* GraphQL */ `
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      id
      name
      policyVersions {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createPolicyVersion = /* GraphQL */ `
  mutation CreatePolicyVersion($input: CreatePolicyVersionInput!) {
    createPolicyVersion(input: $input) {
      id
      description
      version
      status
      approvedBy
      approvalDate
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      reviewers {
        nextToken
      }
      policy {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updatePolicyVersion = /* GraphQL */ `
  mutation UpdatePolicyVersion($input: UpdatePolicyVersionInput!) {
    updatePolicyVersion(input: $input) {
      id
      description
      version
      status
      approvedBy
      approvalDate
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      reviewers {
        nextToken
      }
      policy {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deletePolicyVersion = /* GraphQL */ `
  mutation DeletePolicyVersion($input: DeletePolicyVersionInput!) {
    deletePolicyVersion(input: $input) {
      id
      description
      version
      status
      approvedBy
      approvalDate
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      reviewers {
        nextToken
      }
      policy {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createReviewer = /* GraphQL */ `
  mutation CreateReviewer($input: CreateReviewerInput!) {
    createReviewer(input: $input) {
      id
      reviewerPointOfContactId
      status
      reviewedDate
      ownerGroup
      policyVersionID
      policyVersion {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReviewer = /* GraphQL */ `
  mutation UpdateReviewer($input: UpdateReviewerInput!) {
    updateReviewer(input: $input) {
      id
      reviewerPointOfContactId
      status
      reviewedDate
      ownerGroup
      policyVersionID
      policyVersion {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReviewer = /* GraphQL */ `
  mutation DeleteReviewer($input: DeleteReviewerInput!) {
    deleteReviewer(input: $input) {
      id
      reviewerPointOfContactId
      status
      reviewedDate
      ownerGroup
      policyVersionID
      policyVersion {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMaturityMatrix = /* GraphQL */ `
  mutation CreateMaturityMatrix($input: CreateMaturityMatrixInput!) {
    createMaturityMatrix(input: $input) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMaturityMatrix = /* GraphQL */ `
  mutation UpdateMaturityMatrix($input: UpdateMaturityMatrixInput!) {
    updateMaturityMatrix(input: $input) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaturityMatrix = /* GraphQL */ `
  mutation DeleteMaturityMatrix($input: DeleteMaturityMatrixInput!) {
    deleteMaturityMatrix(input: $input) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProgramElement = /* GraphQL */ `
  mutation CreateProgramElement($input: CreateProgramElementInput!) {
    createProgramElement(input: $input) {
      id
      name
      description
      programSections {
        nextToken
      }
      maturityMatrixID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateProgramElement = /* GraphQL */ `
  mutation UpdateProgramElement($input: UpdateProgramElementInput!) {
    updateProgramElement(input: $input) {
      id
      name
      description
      programSections {
        nextToken
      }
      maturityMatrixID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgramElement = /* GraphQL */ `
  mutation DeleteProgramElement($input: DeleteProgramElementInput!) {
    deleteProgramElement(input: $input) {
      id
      name
      description
      programSections {
        nextToken
      }
      maturityMatrixID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createProgramSection = /* GraphQL */ `
  mutation CreateProgramSection($input: CreateProgramSectionInput!) {
    createProgramSection(input: $input) {
      id
      name
      description
      implementationLevels {
        nextToken
      }
      currentLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      goalLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      programElement {
        id
        name
        description
        maturityMatrixID
        ownerGroup
        createdAt
        updatedAt
      }
      programElementID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateProgramSection = /* GraphQL */ `
  mutation UpdateProgramSection($input: UpdateProgramSectionInput!) {
    updateProgramSection(input: $input) {
      id
      name
      description
      implementationLevels {
        nextToken
      }
      currentLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      goalLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      programElement {
        id
        name
        description
        maturityMatrixID
        ownerGroup
        createdAt
        updatedAt
      }
      programElementID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgramSection = /* GraphQL */ `
  mutation DeleteProgramSection($input: DeleteProgramSectionInput!) {
    deleteProgramSection(input: $input) {
      id
      name
      description
      implementationLevels {
        nextToken
      }
      currentLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      goalLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      programElement {
        id
        name
        description
        maturityMatrixID
        ownerGroup
        createdAt
        updatedAt
      }
      programElementID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createImplementationLevel = /* GraphQL */ `
  mutation CreateImplementationLevel($input: CreateImplementationLevelInput!) {
    createImplementationLevel(input: $input) {
      id
      description
      date
      level
      indicator
      frequency
      accountable
      consulted
      informed
      programSection {
        id
        name
        description
        programElementID
        ownerGroup
        createdAt
        updatedAt
      }
      programSectionID
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateImplementationLevel = /* GraphQL */ `
  mutation UpdateImplementationLevel($input: UpdateImplementationLevelInput!) {
    updateImplementationLevel(input: $input) {
      id
      description
      date
      level
      indicator
      frequency
      accountable
      consulted
      informed
      programSection {
        id
        name
        description
        programElementID
        ownerGroup
        createdAt
        updatedAt
      }
      programSectionID
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteImplementationLevel = /* GraphQL */ `
  mutation DeleteImplementationLevel($input: DeleteImplementationLevelInput!) {
    deleteImplementationLevel(input: $input) {
      id
      description
      date
      level
      indicator
      frequency
      accountable
      consulted
      informed
      programSection {
        id
        name
        description
        programElementID
        ownerGroup
        createdAt
        updatedAt
      }
      programSectionID
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createVendorSolution = /* GraphQL */ `
  mutation CreateVendorSolution($input: CreateVendorSolutionInput!) {
    createVendorSolution(input: $input) {
      id
      name
      description
      riskRating
      documents {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateVendorSolution = /* GraphQL */ `
  mutation UpdateVendorSolution($input: UpdateVendorSolutionInput!) {
    updateVendorSolution(input: $input) {
      id
      name
      description
      riskRating
      documents {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorSolution = /* GraphQL */ `
  mutation DeleteVendorSolution($input: DeleteVendorSolutionInput!) {
    deleteVendorSolution(input: $input) {
      id
      name
      description
      riskRating
      documents {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createVendorDocument = /* GraphQL */ `
  mutation CreateVendorDocument($input: CreateVendorDocumentInput!) {
    createVendorDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      vendorSolution {
        id
        name
        description
        riskRating
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateVendorDocument = /* GraphQL */ `
  mutation UpdateVendorDocument($input: UpdateVendorDocumentInput!) {
    updateVendorDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      vendorSolution {
        id
        name
        description
        riskRating
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteVendorDocument = /* GraphQL */ `
  mutation DeleteVendorDocument($input: DeleteVendorDocumentInput!) {
    deleteVendorDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      vendorSolution {
        id
        name
        description
        riskRating
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createEvidence = /* GraphQL */ `
  mutation CreateEvidence($input: CreateEvidenceInput!) {
    createEvidence(input: $input) {
      id
      name
      ownerGroup
      frequency
      reminder
      enableAutoReminders
      nextDate
      recentUploadDate
      documents {
        nextToken
      }
      controlLinks {
        nextToken
      }
      evidencePointOfContactId
      task
      createdAt
      updatedAt
      actions {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEvidence = /* GraphQL */ `
  mutation UpdateEvidence($input: UpdateEvidenceInput!) {
    updateEvidence(input: $input) {
      id
      name
      ownerGroup
      frequency
      reminder
      enableAutoReminders
      nextDate
      recentUploadDate
      documents {
        nextToken
      }
      controlLinks {
        nextToken
      }
      evidencePointOfContactId
      task
      createdAt
      updatedAt
      actions {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEvidence = /* GraphQL */ `
  mutation DeleteEvidence($input: DeleteEvidenceInput!) {
    deleteEvidence(input: $input) {
      id
      name
      ownerGroup
      frequency
      reminder
      enableAutoReminders
      nextDate
      recentUploadDate
      documents {
        nextToken
      }
      controlLinks {
        nextToken
      }
      evidencePointOfContactId
      task
      createdAt
      updatedAt
      actions {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createControl = /* GraphQL */ `
  mutation CreateControl($input: CreateControlInput!) {
    createControl(input: $input) {
      id
      name
      statementNumber
      assessmentFactor
      component
      maturityLevel
      validation
      controlSet {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      controlControlSetId
      subControls
      rivialControl
      basePriorityPoints
      isCompliant
      ownerGroup
      domain
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      evidenceLinks {
        nextToken
      }
      recommendations {
        nextToken
      }
      changes {
        nextToken
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
    }
  }
`;
export const updateControl = /* GraphQL */ `
  mutation UpdateControl($input: UpdateControlInput!) {
    updateControl(input: $input) {
      id
      name
      statementNumber
      assessmentFactor
      component
      maturityLevel
      validation
      controlSet {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      controlControlSetId
      subControls
      rivialControl
      basePriorityPoints
      isCompliant
      ownerGroup
      domain
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      evidenceLinks {
        nextToken
      }
      recommendations {
        nextToken
      }
      changes {
        nextToken
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
    }
  }
`;
export const deleteControl = /* GraphQL */ `
  mutation DeleteControl($input: DeleteControlInput!) {
    deleteControl(input: $input) {
      id
      name
      statementNumber
      assessmentFactor
      component
      maturityLevel
      validation
      controlSet {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      controlControlSetId
      subControls
      rivialControl
      basePriorityPoints
      isCompliant
      ownerGroup
      domain
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      evidenceLinks {
        nextToken
      }
      recommendations {
        nextToken
      }
      changes {
        nextToken
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
    }
  }
`;
export const createKeyPerformanceIndicator = /* GraphQL */ `
  mutation CreateKeyPerformanceIndicator(
    $input: CreateKeyPerformanceIndicatorInput!
  ) {
    createKeyPerformanceIndicator(input: $input) {
      id
      name
      ownerGroup
      description
      jsonLogic
      status
      getStatus
      history {
        date
        status
      }
      createdAt
      updatedAt
      metricTypes {
        nextToken
      }
      riskControls {
        nextToken
      }
    }
  }
`;
export const updateKeyPerformanceIndicator = /* GraphQL */ `
  mutation UpdateKeyPerformanceIndicator(
    $input: UpdateKeyPerformanceIndicatorInput!
  ) {
    updateKeyPerformanceIndicator(input: $input) {
      id
      name
      ownerGroup
      description
      jsonLogic
      status
      getStatus
      history {
        date
        status
      }
      createdAt
      updatedAt
      metricTypes {
        nextToken
      }
      riskControls {
        nextToken
      }
    }
  }
`;
export const deleteKeyPerformanceIndicator = /* GraphQL */ `
  mutation DeleteKeyPerformanceIndicator(
    $input: DeleteKeyPerformanceIndicatorInput!
  ) {
    deleteKeyPerformanceIndicator(input: $input) {
      id
      name
      ownerGroup
      description
      jsonLogic
      status
      getStatus
      history {
        date
        status
      }
      createdAt
      updatedAt
      metricTypes {
        nextToken
      }
      riskControls {
        nextToken
      }
    }
  }
`;
export const createKpiMetricTypeLink = /* GraphQL */ `
  mutation CreateKpiMetricTypeLink($input: CreateKPIMetricTypeLinkInput!) {
    createKPIMetricTypeLink(input: $input) {
      id
      keyPerformanceIndicatorID
      metricTypeID
      ownerGroup
      logic {
        operator
      }
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      metricType {
        id
        name
        description
        ownerGroup
        enabled
        frequency
        nextDate
        format
        calculationType
        maxAge
        createSecurityMetric
        getSecurityMetric
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateKpiMetricTypeLink = /* GraphQL */ `
  mutation UpdateKpiMetricTypeLink($input: UpdateKPIMetricTypeLinkInput!) {
    updateKPIMetricTypeLink(input: $input) {
      id
      keyPerformanceIndicatorID
      metricTypeID
      ownerGroup
      logic {
        operator
      }
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      metricType {
        id
        name
        description
        ownerGroup
        enabled
        frequency
        nextDate
        format
        calculationType
        maxAge
        createSecurityMetric
        getSecurityMetric
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteKpiMetricTypeLink = /* GraphQL */ `
  mutation DeleteKpiMetricTypeLink($input: DeleteKPIMetricTypeLinkInput!) {
    deleteKPIMetricTypeLink(input: $input) {
      id
      keyPerformanceIndicatorID
      metricTypeID
      ownerGroup
      logic {
        operator
      }
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      metricType {
        id
        name
        description
        ownerGroup
        enabled
        frequency
        nextDate
        format
        calculationType
        maxAge
        createSecurityMetric
        getSecurityMetric
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMetricType = /* GraphQL */ `
  mutation CreateMetricType($input: CreateMetricTypeInput!) {
    createMetricType(input: $input) {
      id
      name
      description
      ownerGroup
      enabled
      frequency
      nextDate
      format
      fieldConfig {
        fieldName
        tooltip
        label
        placeHolder
        defaultValue
      }
      calculationType
      maxAge
      automation {
        name
        description
        jsonPath
        config
      }
      createSecurityMetric
      getSecurityMetric
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      metrics {
        nextToken
      }
    }
  }
`;
export const updateMetricType = /* GraphQL */ `
  mutation UpdateMetricType($input: UpdateMetricTypeInput!) {
    updateMetricType(input: $input) {
      id
      name
      description
      ownerGroup
      enabled
      frequency
      nextDate
      format
      fieldConfig {
        fieldName
        tooltip
        label
        placeHolder
        defaultValue
      }
      calculationType
      maxAge
      automation {
        name
        description
        jsonPath
        config
      }
      createSecurityMetric
      getSecurityMetric
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      metrics {
        nextToken
      }
    }
  }
`;
export const deleteMetricType = /* GraphQL */ `
  mutation DeleteMetricType($input: DeleteMetricTypeInput!) {
    deleteMetricType(input: $input) {
      id
      name
      description
      ownerGroup
      enabled
      frequency
      nextDate
      format
      fieldConfig {
        fieldName
        tooltip
        label
        placeHolder
        defaultValue
      }
      calculationType
      maxAge
      automation {
        name
        description
        jsonPath
        config
      }
      createSecurityMetric
      getSecurityMetric
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      metrics {
        nextToken
      }
    }
  }
`;
export const createMetric = /* GraphQL */ `
  mutation CreateMetric($input: CreateMetricInput!) {
    createMetric(input: $input) {
      id
      name
      ownerGroup
      description
      status
      date
      level
      data {
        value
        format
      }
      metricTypeID
      createdAt
      updatedAt
    }
  }
`;
export const updateMetric = /* GraphQL */ `
  mutation UpdateMetric($input: UpdateMetricInput!) {
    updateMetric(input: $input) {
      id
      name
      ownerGroup
      description
      status
      date
      level
      data {
        value
        format
      }
      metricTypeID
      createdAt
      updatedAt
    }
  }
`;
export const deleteMetric = /* GraphQL */ `
  mutation DeleteMetric($input: DeleteMetricInput!) {
    deleteMetric(input: $input) {
      id
      name
      ownerGroup
      description
      status
      date
      level
      data {
        value
        format
      }
      metricTypeID
      createdAt
      updatedAt
    }
  }
`;
export const createKpiRiskControlLink = /* GraphQL */ `
  mutation CreateKpiRiskControlLink($input: CreateKPIRiskControlLinkInput!) {
    createKPIRiskControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      riskControlID
      ownerGroup
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateKpiRiskControlLink = /* GraphQL */ `
  mutation UpdateKpiRiskControlLink($input: UpdateKPIRiskControlLinkInput!) {
    updateKPIRiskControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      riskControlID
      ownerGroup
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteKpiRiskControlLink = /* GraphQL */ `
  mutation DeleteKpiRiskControlLink($input: DeleteKPIRiskControlLinkInput!) {
    deleteKPIRiskControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      riskControlID
      ownerGroup
      createdAt
      updatedAt
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createKpiComplianceControlLink = /* GraphQL */ `
  mutation CreateKpiComplianceControlLink(
    $input: CreateKPIComplianceControlLinkInput!
  ) {
    createKPIComplianceControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      controlID
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateKpiComplianceControlLink = /* GraphQL */ `
  mutation UpdateKpiComplianceControlLink(
    $input: UpdateKPIComplianceControlLinkInput!
  ) {
    updateKPIComplianceControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      controlID
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteKpiComplianceControlLink = /* GraphQL */ `
  mutation DeleteKpiComplianceControlLink(
    $input: DeleteKPIComplianceControlLinkInput!
  ) {
    deleteKPIComplianceControlLink(input: $input) {
      id
      keyPerformanceIndicatorID
      controlID
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
      keyPerformanceIndicator {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRiskAction = /* GraphQL */ `
  mutation CreateRiskAction($input: CreateRiskActionInput!) {
    createRiskAction(input: $input) {
      id
      name
      description
      dateCreated
      dueDate
      status
      globalStatus
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
    }
  }
`;
export const updateRiskAction = /* GraphQL */ `
  mutation UpdateRiskAction($input: UpdateRiskActionInput!) {
    updateRiskAction(input: $input) {
      id
      name
      description
      dateCreated
      dueDate
      status
      globalStatus
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
    }
  }
`;
export const deleteRiskAction = /* GraphQL */ `
  mutation DeleteRiskAction($input: DeleteRiskActionInput!) {
    deleteRiskAction(input: $input) {
      id
      name
      description
      dateCreated
      dueDate
      status
      globalStatus
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      id
      name
      description
      ownerGroup
      calculations {
        date
        expectedInherentLoss
        expectedResidualLoss
      }
      riskSnapshots {
        nextToken
      }
      hosting
      accessType
      confidentiality
      integrity
      availability
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        isCompliant
        costOfControl
        controlEffectiveness
        annualRateReduction
        strengthRating
        implementationRating
        outsourced
        implementationDetails
      }
      monteCarloInputS3ObjectKey
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      informationAssets {
        nextToken
      }
      components {
        nextToken
      }
      risks {
        nextToken
      }
      controlCategories {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      name
      description
      ownerGroup
      calculations {
        date
        expectedInherentLoss
        expectedResidualLoss
      }
      riskSnapshots {
        nextToken
      }
      hosting
      accessType
      confidentiality
      integrity
      availability
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        isCompliant
        costOfControl
        controlEffectiveness
        annualRateReduction
        strengthRating
        implementationRating
        outsourced
        implementationDetails
      }
      monteCarloInputS3ObjectKey
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      informationAssets {
        nextToken
      }
      components {
        nextToken
      }
      risks {
        nextToken
      }
      controlCategories {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem($input: DeleteSystemInput!) {
    deleteSystem(input: $input) {
      id
      name
      description
      ownerGroup
      calculations {
        date
        expectedInherentLoss
        expectedResidualLoss
      }
      riskSnapshots {
        nextToken
      }
      hosting
      accessType
      confidentiality
      integrity
      availability
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        isCompliant
        costOfControl
        controlEffectiveness
        annualRateReduction
        strengthRating
        implementationRating
        outsourced
        implementationDetails
      }
      monteCarloInputS3ObjectKey
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      informationAssets {
        nextToken
      }
      components {
        nextToken
      }
      risks {
        nextToken
      }
      controlCategories {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSystemInformationAssetLink = /* GraphQL */ `
  mutation CreateSystemInformationAssetLink(
    $input: CreateSystemInformationAssetLinkInput!
  ) {
    createSystemInformationAssetLink(input: $input) {
      id
      systemID
      informationAssetID
      numberOfRecords
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      informationAsset {
        id
        name
        confidentiality
        integrity
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSystemInformationAssetLink = /* GraphQL */ `
  mutation UpdateSystemInformationAssetLink(
    $input: UpdateSystemInformationAssetLinkInput!
  ) {
    updateSystemInformationAssetLink(input: $input) {
      id
      systemID
      informationAssetID
      numberOfRecords
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      informationAsset {
        id
        name
        confidentiality
        integrity
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSystemInformationAssetLink = /* GraphQL */ `
  mutation DeleteSystemInformationAssetLink(
    $input: DeleteSystemInformationAssetLinkInput!
  ) {
    deleteSystemInformationAssetLink(input: $input) {
      id
      systemID
      informationAssetID
      numberOfRecords
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      informationAsset {
        id
        name
        confidentiality
        integrity
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createInformationAsset = /* GraphQL */ `
  mutation CreateInformationAsset($input: CreateInformationAssetInput!) {
    createInformationAsset(input: $input) {
      id
      name
      confidentiality
      integrity
      ownerGroup
      createdAt
      updatedAt
      systems {
        nextToken
      }
    }
  }
`;
export const updateInformationAsset = /* GraphQL */ `
  mutation UpdateInformationAsset($input: UpdateInformationAssetInput!) {
    updateInformationAsset(input: $input) {
      id
      name
      confidentiality
      integrity
      ownerGroup
      createdAt
      updatedAt
      systems {
        nextToken
      }
    }
  }
`;
export const deleteInformationAsset = /* GraphQL */ `
  mutation DeleteInformationAsset($input: DeleteInformationAssetInput!) {
    deleteInformationAsset(input: $input) {
      id
      name
      confidentiality
      integrity
      ownerGroup
      createdAt
      updatedAt
      systems {
        nextToken
      }
    }
  }
`;
export const createComponent = /* GraphQL */ `
  mutation CreateComponent($input: CreateComponentInput!) {
    createComponent(input: $input) {
      id
      name
      description
      ownerGroup
      notes {
        nextToken
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateComponent = /* GraphQL */ `
  mutation UpdateComponent($input: UpdateComponentInput!) {
    updateComponent(input: $input) {
      id
      name
      description
      ownerGroup
      notes {
        nextToken
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteComponent = /* GraphQL */ `
  mutation DeleteComponent($input: DeleteComponentInput!) {
    deleteComponent(input: $input) {
      id
      name
      description
      ownerGroup
      notes {
        nextToken
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSystemRiskLink = /* GraphQL */ `
  mutation CreateSystemRiskLink($input: CreateSystemRiskLinkInput!) {
    createSystemRiskLink(input: $input) {
      id
      ownerGroup
      cia {
        confidentiality
        integrity
        availability
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      probabilityModifier
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      risk {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      threats {
        nextToken
      }
    }
  }
`;
export const updateSystemRiskLink = /* GraphQL */ `
  mutation UpdateSystemRiskLink($input: UpdateSystemRiskLinkInput!) {
    updateSystemRiskLink(input: $input) {
      id
      ownerGroup
      cia {
        confidentiality
        integrity
        availability
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      probabilityModifier
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      risk {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      threats {
        nextToken
      }
    }
  }
`;
export const deleteSystemRiskLink = /* GraphQL */ `
  mutation DeleteSystemRiskLink($input: DeleteSystemRiskLinkInput!) {
    deleteSystemRiskLink(input: $input) {
      id
      ownerGroup
      cia {
        confidentiality
        integrity
        availability
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      probabilityModifier
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      risk {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      threats {
        nextToken
      }
    }
  }
`;
export const createRisk = /* GraphQL */ `
  mutation CreateRisk($input: CreateRiskInput!) {
    createRisk(input: $input) {
      id
      name
      classification
      description
      annualRateOfOccurrence
      annualRateOfOccurrenceMax
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      riskSystemId
      ownerGroup
      controlCategoryID
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      createdAt
      updatedAt
      systems {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRisk = /* GraphQL */ `
  mutation UpdateRisk($input: UpdateRiskInput!) {
    updateRisk(input: $input) {
      id
      name
      classification
      description
      annualRateOfOccurrence
      annualRateOfOccurrenceMax
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      riskSystemId
      ownerGroup
      controlCategoryID
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      createdAt
      updatedAt
      systems {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRisk = /* GraphQL */ `
  mutation DeleteRisk($input: DeleteRiskInput!) {
    deleteRisk(input: $input) {
      id
      name
      classification
      description
      annualRateOfOccurrence
      annualRateOfOccurrenceMax
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      riskSystemId
      ownerGroup
      controlCategoryID
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      createdAt
      updatedAt
      systems {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const createThreat = /* GraphQL */ `
  mutation CreateThreat($input: CreateThreatInput!) {
    createThreat(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      systemRiskLinks {
        nextToken
      }
    }
  }
`;
export const updateThreat = /* GraphQL */ `
  mutation UpdateThreat($input: UpdateThreatInput!) {
    updateThreat(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      systemRiskLinks {
        nextToken
      }
    }
  }
`;
export const deleteThreat = /* GraphQL */ `
  mutation DeleteThreat($input: DeleteThreatInput!) {
    deleteThreat(input: $input) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      systemRiskLinks {
        nextToken
      }
    }
  }
`;
export const createSystemRiskThreatLink = /* GraphQL */ `
  mutation CreateSystemRiskThreatLink(
    $input: CreateSystemRiskThreatLinkInput!
  ) {
    createSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      systemRiskLink {
        id
        ownerGroup
        probabilityModifier
        createdAt
        updatedAt
      }
      threat {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSystemRiskThreatLink = /* GraphQL */ `
  mutation UpdateSystemRiskThreatLink(
    $input: UpdateSystemRiskThreatLinkInput!
  ) {
    updateSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      systemRiskLink {
        id
        ownerGroup
        probabilityModifier
        createdAt
        updatedAt
      }
      threat {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSystemRiskThreatLink = /* GraphQL */ `
  mutation DeleteSystemRiskThreatLink(
    $input: DeleteSystemRiskThreatLinkInput!
  ) {
    deleteSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
      createdAt
      updatedAt
      systemRiskLink {
        id
        ownerGroup
        probabilityModifier
        createdAt
        updatedAt
      }
      threat {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRiskControlCategory = /* GraphQL */ `
  mutation CreateRiskControlCategory($input: CreateRiskControlCategoryInput!) {
    createRiskControlCategory(input: $input) {
      id
      name
      ownerGroup
      description
      createdAt
      updatedAt
      systems {
        nextToken
      }
      subControls {
        nextToken
      }
    }
  }
`;
export const updateRiskControlCategory = /* GraphQL */ `
  mutation UpdateRiskControlCategory($input: UpdateRiskControlCategoryInput!) {
    updateRiskControlCategory(input: $input) {
      id
      name
      ownerGroup
      description
      createdAt
      updatedAt
      systems {
        nextToken
      }
      subControls {
        nextToken
      }
    }
  }
`;
export const deleteRiskControlCategory = /* GraphQL */ `
  mutation DeleteRiskControlCategory($input: DeleteRiskControlCategoryInput!) {
    deleteRiskControlCategory(input: $input) {
      id
      name
      ownerGroup
      description
      createdAt
      updatedAt
      systems {
        nextToken
      }
      subControls {
        nextToken
      }
    }
  }
`;
export const createSystemControlCategoryLink = /* GraphQL */ `
  mutation CreateSystemControlCategoryLink(
    $input: CreateSystemControlCategoryLinkInput!
  ) {
    createSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
      riskControlCategoryID
      systemID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSystemControlCategoryLink = /* GraphQL */ `
  mutation UpdateSystemControlCategoryLink(
    $input: UpdateSystemControlCategoryLinkInput!
  ) {
    updateSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
      riskControlCategoryID
      systemID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSystemControlCategoryLink = /* GraphQL */ `
  mutation DeleteSystemControlCategoryLink(
    $input: DeleteSystemControlCategoryLinkInput!
  ) {
    deleteSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
      riskControlCategoryID
      systemID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRiskControl = /* GraphQL */ `
  mutation CreateRiskControl($input: CreateRiskControlInput!) {
    createRiskControl(input: $input) {
      id
      name
      statementNumber
      costOfControl
      strengthRating
      implementationRating
      outsourced
      ownerGroup
      controlCategoryID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
      recommendations {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
    }
  }
`;
export const updateRiskControl = /* GraphQL */ `
  mutation UpdateRiskControl($input: UpdateRiskControlInput!) {
    updateRiskControl(input: $input) {
      id
      name
      statementNumber
      costOfControl
      strengthRating
      implementationRating
      outsourced
      ownerGroup
      controlCategoryID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
      recommendations {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
    }
  }
`;
export const deleteRiskControl = /* GraphQL */ `
  mutation DeleteRiskControl($input: DeleteRiskControlInput!) {
    deleteRiskControl(input: $input) {
      id
      name
      statementNumber
      costOfControl
      strengthRating
      implementationRating
      outsourced
      ownerGroup
      controlCategoryID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
      recommendations {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
    }
  }
`;
export const createStatistic = /* GraphQL */ `
  mutation CreateStatistic($input: CreateStatisticInput!) {
    createStatistic(input: $input) {
      id
      name
      ownerGroup
      date
      data
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateStatistic = /* GraphQL */ `
  mutation UpdateStatistic($input: UpdateStatisticInput!) {
    updateStatistic(input: $input) {
      id
      name
      ownerGroup
      date
      data
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatistic = /* GraphQL */ `
  mutation DeleteStatistic($input: DeleteStatisticInput!) {
    deleteStatistic(input: $input) {
      id
      name
      ownerGroup
      date
      data
      type
      createdAt
      updatedAt
    }
  }
`;
export const createHistoricalPicture = /* GraphQL */ `
  mutation CreateHistoricalPicture($input: CreateHistoricalPictureInput!) {
    createHistoricalPicture(input: $input) {
      id
      dateTime
      statistics
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateHistoricalPicture = /* GraphQL */ `
  mutation UpdateHistoricalPicture($input: UpdateHistoricalPictureInput!) {
    updateHistoricalPicture(input: $input) {
      id
      dateTime
      statistics
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistoricalPicture = /* GraphQL */ `
  mutation DeleteHistoricalPicture($input: DeleteHistoricalPictureInput!) {
    deleteHistoricalPicture(input: $input) {
      id
      dateTime
      statistics
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const createRiskRecommendation = /* GraphQL */ `
  mutation CreateRiskRecommendation($input: CreateRiskRecommendationInput!) {
    createRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRiskRecommendation = /* GraphQL */ `
  mutation UpdateRiskRecommendation($input: UpdateRiskRecommendationInput!) {
    updateRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRiskRecommendation = /* GraphQL */ `
  mutation DeleteRiskRecommendation($input: DeleteRiskRecommendationInput!) {
    deleteRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRiskChange = /* GraphQL */ `
  mutation CreateRiskChange($input: CreateRiskChangeInput!) {
    createRiskChange(input: $input) {
      id
      ownerGroup
      name
      type
      description
      date
      userEmail
      changeOwner
      reason
      change
      createdAt
      updatedAt
      riskControlLinks {
        nextToken
      }
      systemLinks {
        nextToken
      }
    }
  }
`;
export const updateRiskChange = /* GraphQL */ `
  mutation UpdateRiskChange($input: UpdateRiskChangeInput!) {
    updateRiskChange(input: $input) {
      id
      ownerGroup
      name
      type
      description
      date
      userEmail
      changeOwner
      reason
      change
      createdAt
      updatedAt
      riskControlLinks {
        nextToken
      }
      systemLinks {
        nextToken
      }
    }
  }
`;
export const deleteRiskChange = /* GraphQL */ `
  mutation DeleteRiskChange($input: DeleteRiskChangeInput!) {
    deleteRiskChange(input: $input) {
      id
      ownerGroup
      name
      type
      description
      date
      userEmail
      changeOwner
      reason
      change
      createdAt
      updatedAt
      riskControlLinks {
        nextToken
      }
      systemLinks {
        nextToken
      }
    }
  }
`;
export const createRiskControlChangeLink = /* GraphQL */ `
  mutation CreateRiskControlChangeLink(
    $input: CreateRiskControlChangeLinkInput!
  ) {
    createRiskControlChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      riskControlID
      costChange
      implementationChange
      createdAt
      updatedAt
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRiskControlChangeLink = /* GraphQL */ `
  mutation UpdateRiskControlChangeLink(
    $input: UpdateRiskControlChangeLinkInput!
  ) {
    updateRiskControlChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      riskControlID
      costChange
      implementationChange
      createdAt
      updatedAt
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRiskControlChangeLink = /* GraphQL */ `
  mutation DeleteRiskControlChangeLink(
    $input: DeleteRiskControlChangeLinkInput!
  ) {
    deleteRiskControlChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      riskControlID
      costChange
      implementationChange
      createdAt
      updatedAt
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSystemChangeLink = /* GraphQL */ `
  mutation CreateSystemChangeLink($input: CreateSystemChangeLinkInput!) {
    createSystemChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      systemID
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSystemChangeLink = /* GraphQL */ `
  mutation UpdateSystemChangeLink($input: UpdateSystemChangeLinkInput!) {
    updateSystemChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      systemID
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSystemChangeLink = /* GraphQL */ `
  mutation DeleteSystemChangeLink($input: DeleteSystemChangeLinkInput!) {
    deleteSystemChangeLink(input: $input) {
      id
      ownerGroup
      riskChangeID
      systemID
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskChange {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      shortName
      numSimulations
      orgType
      riskRating
      lossToleranceCurve {
        amount
        probability
      }
      complianceRating
      createdAt
      documents {
        nextToken
      }
      enabledStatistics
      groups
      assetSize
      numberOfEmployees
      numberOfCustomers
      riskSetup {
        high
        mediumHigh
        medium
        lowMedium
        low
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      s3BucketName
      publicRSAKey
      config
      updatedAt
      actions {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      shortName
      numSimulations
      orgType
      riskRating
      lossToleranceCurve {
        amount
        probability
      }
      complianceRating
      createdAt
      documents {
        nextToken
      }
      enabledStatistics
      groups
      assetSize
      numberOfEmployees
      numberOfCustomers
      riskSetup {
        high
        mediumHigh
        medium
        lowMedium
        low
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      s3BucketName
      publicRSAKey
      config
      updatedAt
      actions {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
      name
      shortName
      numSimulations
      orgType
      riskRating
      lossToleranceCurve {
        amount
        probability
      }
      complianceRating
      createdAt
      documents {
        nextToken
      }
      enabledStatistics
      groups
      assetSize
      numberOfEmployees
      numberOfCustomers
      riskSetup {
        high
        mediumHigh
        medium
        lowMedium
        low
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      s3BucketName
      publicRSAKey
      config
      updatedAt
      actions {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction($input: CreateActionInput!) {
    createAction(input: $input) {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      pastDueAt
      ownerGroup
      executionARN
      type
      documents {
        nextToken
      }
      priority
      module
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      pastDueAt
      ownerGroup
      executionARN
      type
      documents {
        nextToken
      }
      priority
      module
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input) {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      pastDueAt
      ownerGroup
      executionARN
      type
      documents {
        nextToken
      }
      priority
      module
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
    }
  }
`;
export const createActionItem = /* GraphQL */ `
  mutation CreateActionItem($input: CreateActionItemInput!) {
    createActionItem(input: $input) {
      id
      name
      dateCreated
      dueDate
      status
      description
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
      emails {
        nextToken
      }
    }
  }
`;
export const updateActionItem = /* GraphQL */ `
  mutation UpdateActionItem($input: UpdateActionItemInput!) {
    updateActionItem(input: $input) {
      id
      name
      dateCreated
      dueDate
      status
      description
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
      emails {
        nextToken
      }
    }
  }
`;
export const deleteActionItem = /* GraphQL */ `
  mutation DeleteActionItem($input: DeleteActionItemInput!) {
    deleteActionItem(input: $input) {
      id
      name
      dateCreated
      dueDate
      status
      description
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
      emails {
        nextToken
      }
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail($input: CreateEmailInput!) {
    createEmail(input: $input) {
      id
      subject
      recipients
      body
      status
      frequency
      dueDate
      executionARN
      type
      module
      ownerGroup
      createdAt
      updatedAt
      actionItem {
        id
        name
        dateCreated
        dueDate
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      id
      subject
      recipients
      body
      status
      frequency
      dueDate
      executionARN
      type
      module
      ownerGroup
      createdAt
      updatedAt
      actionItem {
        id
        name
        dateCreated
        dueDate
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail($input: DeleteEmailInput!) {
    deleteEmail(input: $input) {
      id
      subject
      recipients
      body
      status
      frequency
      dueDate
      executionARN
      type
      module
      ownerGroup
      createdAt
      updatedAt
      actionItem {
        id
        name
        dateCreated
        dueDate
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment($input: CreateAssessmentInput!) {
    createAssessment(input: $input) {
      id
      name
      createdAt
      scheduledDate
      includedListTargets
      excludedListTargets
      securityAppliance {
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        status
        destination
        trackingNumber
        checkOutDate
        checkInDate
        ownerGroup
        createdAt
        updatedAt
      }
      assessmentPointOfContactId
      penTester
      penTesterEmail
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      documents {
        nextToken
      }
      type
      status
      scanStatus
      taskId
      scoreCVSS
      ownerGroup
      updatedAt
      findingLinks {
        nextToken
      }
      targets {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      id
      name
      createdAt
      scheduledDate
      includedListTargets
      excludedListTargets
      securityAppliance {
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        status
        destination
        trackingNumber
        checkOutDate
        checkInDate
        ownerGroup
        createdAt
        updatedAt
      }
      assessmentPointOfContactId
      penTester
      penTesterEmail
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      documents {
        nextToken
      }
      type
      status
      scanStatus
      taskId
      scoreCVSS
      ownerGroup
      updatedAt
      findingLinks {
        nextToken
      }
      targets {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment($input: DeleteAssessmentInput!) {
    deleteAssessment(input: $input) {
      id
      name
      createdAt
      scheduledDate
      includedListTargets
      excludedListTargets
      securityAppliance {
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        status
        destination
        trackingNumber
        checkOutDate
        checkInDate
        ownerGroup
        createdAt
        updatedAt
      }
      assessmentPointOfContactId
      penTester
      penTesterEmail
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      documents {
        nextToken
      }
      type
      status
      scanStatus
      taskId
      scoreCVSS
      ownerGroup
      updatedAt
      findingLinks {
        nextToken
      }
      targets {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAssessmentFindingLink = /* GraphQL */ `
  mutation CreateAssessmentFindingLink(
    $input: CreateAssessmentFindingLinkInput!
  ) {
    createAssessmentFindingLink(input: $input) {
      id
      assessmentID
      findingID
      ownerGroup
      createdAt
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAssessmentFindingLink = /* GraphQL */ `
  mutation UpdateAssessmentFindingLink(
    $input: UpdateAssessmentFindingLinkInput!
  ) {
    updateAssessmentFindingLink(input: $input) {
      id
      assessmentID
      findingID
      ownerGroup
      createdAt
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAssessmentFindingLink = /* GraphQL */ `
  mutation DeleteAssessmentFindingLink(
    $input: DeleteAssessmentFindingLinkInput!
  ) {
    deleteAssessmentFindingLink(input: $input) {
      id
      assessmentID
      findingID
      ownerGroup
      createdAt
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTarget = /* GraphQL */ `
  mutation CreateTarget($input: CreateTargetInput!) {
    createTarget(input: $input) {
      id
      hostName
      ip
      macAddress
      createdAt
      lastTestedDate
      targetAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      disabled
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      findings {
        nextToken
      }
    }
  }
`;
export const updateTarget = /* GraphQL */ `
  mutation UpdateTarget($input: UpdateTargetInput!) {
    updateTarget(input: $input) {
      id
      hostName
      ip
      macAddress
      createdAt
      lastTestedDate
      targetAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      disabled
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      findings {
        nextToken
      }
    }
  }
`;
export const deleteTarget = /* GraphQL */ `
  mutation DeleteTarget($input: DeleteTargetInput!) {
    deleteTarget(input: $input) {
      id
      hostName
      ip
      macAddress
      createdAt
      lastTestedDate
      targetAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      disabled
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      findings {
        nextToken
      }
    }
  }
`;
export const createFinding = /* GraphQL */ `
  mutation CreateFinding($input: CreateFindingInput!) {
    createFinding(input: $input) {
      id
      name
      type
      severityLevel
      port
      protocol
      cvss
      cve
      nvtOID
      impact
      solutionType
      summary
      status
      findingTargetId
      findingAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      createdAt
      updatedAt
      assessmentLinks {
        nextToken
      }
      target {
        id
        hostName
        ip
        macAddress
        createdAt
        lastTestedDate
        targetAssessmentId
        ownerGroup
        disabled
        updatedAt
      }
    }
  }
`;
export const updateFinding = /* GraphQL */ `
  mutation UpdateFinding($input: UpdateFindingInput!) {
    updateFinding(input: $input) {
      id
      name
      type
      severityLevel
      port
      protocol
      cvss
      cve
      nvtOID
      impact
      solutionType
      summary
      status
      findingTargetId
      findingAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      createdAt
      updatedAt
      assessmentLinks {
        nextToken
      }
      target {
        id
        hostName
        ip
        macAddress
        createdAt
        lastTestedDate
        targetAssessmentId
        ownerGroup
        disabled
        updatedAt
      }
    }
  }
`;
export const deleteFinding = /* GraphQL */ `
  mutation DeleteFinding($input: DeleteFindingInput!) {
    deleteFinding(input: $input) {
      id
      name
      type
      severityLevel
      port
      protocol
      cvss
      cve
      nvtOID
      impact
      solutionType
      summary
      status
      findingTargetId
      findingAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      createdAt
      updatedAt
      assessmentLinks {
        nextToken
      }
      target {
        id
        hostName
        ip
        macAddress
        createdAt
        lastTestedDate
        targetAssessmentId
        ownerGroup
        disabled
        updatedAt
      }
    }
  }
`;
export const createTestingAction = /* GraphQL */ `
  mutation CreateTestingAction($input: CreateTestingActionInput!) {
    createTestingAction(input: $input) {
      id
      name
      description
      dueDate
      priority
      type
      status
      globalStatus
      module
      ownerGroup
      createdAt
      updatedAt
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTestingAction = /* GraphQL */ `
  mutation UpdateTestingAction($input: UpdateTestingActionInput!) {
    updateTestingAction(input: $input) {
      id
      name
      description
      dueDate
      priority
      type
      status
      globalStatus
      module
      ownerGroup
      createdAt
      updatedAt
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTestingAction = /* GraphQL */ `
  mutation DeleteTestingAction($input: DeleteTestingActionInput!) {
    deleteTestingAction(input: $input) {
      id
      name
      description
      dueDate
      priority
      type
      status
      globalStatus
      module
      ownerGroup
      createdAt
      updatedAt
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPointOfContact = /* GraphQL */ `
  mutation CreatePointOfContact($input: CreatePointOfContactInput!) {
    createPointOfContact(input: $input) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
      meetings {
        nextToken
      }
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      evidences {
        nextToken
      }
      systems {
        nextToken
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      emails {
        nextToken
      }
      assessments {
        nextToken
      }
    }
  }
`;
export const updatePointOfContact = /* GraphQL */ `
  mutation UpdatePointOfContact($input: UpdatePointOfContactInput!) {
    updatePointOfContact(input: $input) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
      meetings {
        nextToken
      }
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      evidences {
        nextToken
      }
      systems {
        nextToken
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      emails {
        nextToken
      }
      assessments {
        nextToken
      }
    }
  }
`;
export const deletePointOfContact = /* GraphQL */ `
  mutation DeletePointOfContact($input: DeletePointOfContactInput!) {
    deletePointOfContact(input: $input) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
      meetings {
        nextToken
      }
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      evidences {
        nextToken
      }
      systems {
        nextToken
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      emails {
        nextToken
      }
      assessments {
        nextToken
      }
    }
  }
`;
