import { generateGraphqlFields } from "@rivial-security/generategraphql";

export const listResponsePlansSystems = /* GraphQL */ `
  query ResponseByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responseByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${generateGraphqlFields(["id", "systems"], {
          systems: `(limit: 1000) {
              items {
                id
                system {
                  id
                  name
                  description
                  pointOfContact { id ownerGroup firstName lastName title email }
                  hosting
                  adminPointOfContact { id ownerGroup firstName lastName title email }
                  vendorSolution {id name vendorReviews (limit: 500) { items { id name rating } } }
                }
              }
            }`,
        })}
      }
      nextToken
    }
  }
`;
