"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_TEMPLATES = void 0;
/**
 * The available 'Built In' templates made by developers
 */
exports.REPORT_TEMPLATES = {
    AUDIT_TEMPLATE: "AUDIT_TEMPLATE",
    RA_TEMPLATE: "RA_TEMPLATE",
    EXECUTIVE_SUMMARY_TEMPLATE: "EXECUTIVE_SUMMARY_TEMPLATE",
    BLUEPRINT_TEMPLATE: "BLUEPRINT_TEMPLATE",
    VENDOR_REVIEW_TEMPLATE: "VENDOR_REVIEW_TEMPLATE",
    GENERAL_TEMPLATE: "GENERAL_TEMPLATE",
    COMPLIANCE_REPORT_TEMPLATE: "COMPLIANCE_REPORT_TEMPLATE",
    SECURITY_TESTING_TEMPLATE: "SECURITY_TESTING_TEMPLATE",
    VENDOR_REVIEW_CONTROLS_TEMPLATE: "VENDOR_REVIEW_CONTROLS_TEMPLATE",
    INCIDENT_RESPONSE_PLAN_TEMPLATE: "INCIDENT_RESPONSE_PLAN_TEMPLATE",
    INCIDENT_RESPONSE_EXERCISE_TEMPLATE: "INCIDENT_RESPONSE_EXERCISE_TEMPLATE",
    INCIDENT_RESPONSE_INCIDENT_TEMPLATE: "INCIDENT_RESPONSE_INCIDENT_TEMPLATE",
    FINDINGS_TEMPLATE: "FINDINGS_TEMPLATE",
    PHISHING_TEMPLATE: "PHISHING_TEMPLATE",
    TRAINING_TEMPLATE: "TRAINING_TEMPLATE",
    MEETING_TEMPLATE: "MEETING_TEMPLATE",
};
