import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description onSuccess function, called when user.getDevice() call succeeds
 * @param {object} result - result object from the user.getDevice() function
 * @param {function} callback - Callback function
 */
export const onSuccess = (result, callback) => {
  if (isNullOrUndefined(result) || isNullOrUndefined(callback)) {
    throw new Error("[getRememberedDeviceStatus.js -> onSuccess] Invalid params");
  }

  if (result?.Device?.DeviceAttributes && Array.isArray(result?.Device?.DeviceAttributes)) {
    const deviceName = result?.Device?.DeviceAttributes.find((x) => x.Name === "device_name");
    deviceName?.Value ? callback(deviceName?.Value) : callback("Not Assigned");
  } else {
    callback("Not Assigned");
  }
};

/**
 * @description onFailure function called when the user.getDevice() fails
 * @param {object} err - error object from the user.getDevice() function
 * @param {function} callback - Callback function
 */
export const onFailure = (err, callback) => {
  if (isNullOrUndefined(err) || isNullOrUndefined(callback)) {
    throw new Error("[getRememberedDeviceStatus.js -> onFailure] Invalid params");
  }

  callback("Not Assigned");
};

/**
 * @description Function for getting remembered device status for a Cognito user
 * @param {object} user - Cognito user object
 * @param {function} callback - Callback function for getting device value
 */
export const getRememberedDeviceStatus = ({ user, callback }) => {
  if (isNullOrUndefined(user) || isNullOrUndefined(callback)) {
    throw new Error("[getRememberedDeviceStatus.js] Invalid params");
  }

  user.getDevice({
    onSuccess: (result) => onSuccess(result, callback),
    onFailure: (err) => onFailure(err, callback),
  });
};
