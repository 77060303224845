import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { DeleteS3Object } from "../../../../utils/Functions/S3Storage/DeleteS3Object";

/**
 * Deletes a meeting document from database
 * @returns {Promise<void>}
 * @constructor
 * @param meetingDocument
 */

export const DeleteMeetingDocument = async (meetingDocument) => {
  const deleteMeetingDocument = /* GraphQL */ `
    mutation DeleteMeetingDocument($input: DeleteMeetingDocumentInput!) {
      deleteMeetingDocument(input: $input) {
        id
      }
    }
  `;

  if (meetingDocument && meetingDocument.id && meetingDocument.file) {
    if (meetingDocument.file.bucket && meetingDocument.file.key)
      await DeleteS3Object({
        bucketName: meetingDocument.file.bucket,
        objectKey: meetingDocument.file.key,
        organizationID: meetingDocument.ownerGroup,
      });

    if (meetingDocument && meetingDocument.id) {
      await ItemMutation(deleteMeetingDocument, {
        id: meetingDocument.id,
      });
    }
  }
};
