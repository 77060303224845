import React from "react";

/**
 * A component used for highlighting a certain key phrase in a string.
 *
 * Sourced from: https://stackoverflow.com/a/47939543
 * @param {string} text - the original text
 * @param {string} highlight - the string to highlight
 * @returns {JSX.Element}
 * @constructor
 */
const HighlightedText = ({ text = "", highlight = "", style = {} }) => {
  const escapeRegExp = (str = "") => str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <strong key={i} style={style}>
              {part}
            </strong>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </span>
  );
};

export default HighlightedText;
