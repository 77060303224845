import { API, graphqlOperation } from "@aws-amplify/api";
import * as Sentry from "@sentry/react";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * Author: Anatoli Railean
 * Created: 08/22/2019
 * Last Edit:
 *
 * Description: This function make a call to the database and gets the Loss Tolerance curve for an Organization
 *
 */
import { getLossTolerance } from "../../RiskConfig/RiskTolerance/graphql/__lossToleranceGQL";

const GetLossTolerance = async (organizationID) => {
  return API.graphql(
    graphqlOperation(getLossTolerance, {
      id: organizationID,
    }),
  )
    .then((data) => {
      const getOrganization = data.data.getOrganization;

      const curve = [];

      if (getOrganization?.lossToleranceCurve?.length > 0) {
        [...getOrganization.lossToleranceCurve]
          .sort((a, b) => b.probability - a.probability)
          .forEach((row) => curve.push({ x: row.amount, y: row.probability }));
      }

      return curve;
    })
    .catch((e) => {
      ErrorLogger("Error while fetching loss tolerance data: ", e);
      Sentry.captureException(e);
    });
};

export default GetLossTolerance;
