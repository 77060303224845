import { isDate } from "date-fns";
import React from "react";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import { dateValueGetter } from "@hooks/views/useDataGrid/functions/dateValueGetter";

import { getResourceFieldAttribute } from "../../../../../definitions/functions/getResourceFieldAttribute";
import { fieldContexts } from "../../../../../enums/fieldContexts";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import TagsField from "../../../../../utils/Tags/customFields/TagsField";
import { convertTags } from "../../../../../views/Compliance/Controls/Controls/hooks/useControlGrid/functions/convertTags";
import DataGridActionButton from "../../components/DataGridActionButton";
import SingleSelectButton from "../../components/SingleSelectButton";
import { tagOperators } from "../../constants/tagOperators";

import { processCustomFields } from "./functions/processCustomFields";
import { processLoadingFields } from "./functions/processLoadingFields";

/**
 * Converts the 'fields' input object work with the material-ui DataGridPro column definitions.
 * See: https://mui.com/components/data-grid/columns/
 *
 * Closely follows the MUI column definitions, with some extra options to more closely match our other grids
 * @param {object} gridContext - grid context object
 * @param {boolean} gridContext.enableQuickDetails - determines if the quick details button is enabled
 * @param {string[]} gridContext.visibleFields - if present, limits fields to those in this array
 * @param {object[]} gridContext.fields - array of field configs
 * @param {string} gridContext.fields[].name - field name
 * @param {string} gridContext.fields[].field - field name (alternate, for legacy purposes)
 * @param {string} gridContext.fields[].friendlyName - friendly name for the field
 * @param {number} gridContext.fields[].width - width of the field
 * @param {string} gridContext.fields[].format - format of the field
 * @param {JSX.Element} gridContext.fields[].component - component to render for the field
 * @param {string} gridContext.fields[].module - the module this grid belongs to for role checking
 * @param {string} gridContext.fields[].resource - the resource this grid belongs to for role checking
 * @param {string} gridContext.fields[].typename - the typename this grid belongs to for correct customized of messages
 * @param {function} gridContext.fields[].updateItemById - function to update item data in the grid by its id
 * @param {object} detailsModal - reference to the detailsModal hook
 * @param {object} targetView - user's custom settings for the grid column width, order, etc.
 * @param {string[]} loadingFields - list of fields that are still loading
 * @returns {[]}
 */
export const handleDataGridColumns = ({ gridContext, detailsModal, targetView, loadingFields }) => {
  const {
    fields,
    customFields,
    customFieldsReadOnly,
    enableQuickDetails,
    checkboxSelection,
    enableSelectButton,
    onSelectCallback,
    updateMutation,
    module,
    resource,
    typename,
    resetFunction,
    disableRoleChecking,
    updateItemById,
    visibleFields,
  } = gridContext;

  const dataGridColumns = [];

  // Handles the Single Select
  // Decided to keep this in the first column to prevent issues with smaller screens.
  if (enableSelectButton === true) {
    dataGridColumns.push({
      field: "Select",
      headerName: "Select",
      description: "Select a Single Item from the Grid",
      width: 75, // NOTE: width of the single select column cannot be changed
      resizable: false,
      disableExport: true,
      filterable: false,
      sortable: false,
      hide: enableSelectButton,
      renderCell: (params) => {
        return <SingleSelectButton onSelectCallback={onSelectCallback} item={params.row} />;
      },
    });
  }

  // Handles the "Actions" button.
  // Decided to keep this in the first column to prevent issues with smaller screens.
  if (enableQuickDetails === true) {
    dataGridColumns.push({
      field: "Actions",
      headerName: "Actions",
      description: "Perform various actions such as opening the details page and deleting an item",
      width: 110, // NOTE: width of the action's column cannot be changed
      resizable: false,
      disableExport: true,
      filterable: false,
      sortable: false,
      hide: checkboxSelection,
      renderCell: (params) => {
        return (
          <DataGridActionButton
            gridContext={gridContext}
            item={params.row}
            module={module}
            resource={resource}
            disableRoleChecking={disableRoleChecking}
            detailsModal={detailsModal}
          />
        );
      },
    });
  }

  /**
   * Retrieves view settings for a single column, if its available
   * @param {string} fieldName - the name of the field for which to retrieve settings
   * @return {object|null}
   */
  const getTargetViewColumn = ({ fieldName }) => {
    if (!Array.isArray(targetView?.columns) || !fieldName) {
      return null;
    }
    return targetView?.columns?.find((column) => column?.name === fieldName);
  };

  /**
   * Processes the field parameter input and converts it to MUI data grid ColDef
   */
  if (Array.isArray(fields)) {
    const allFields = [...fields];

    processCustomFields({
      allFields,
      customFields,
      customFieldsReadOnly,
      module,
      resource,
    });

    //If the 'tags' field is present add some default settings (override them with values from the field array)
    const tagFieldIndex = allFields.findIndex((field) => field.field === "tags");
    if (tagFieldIndex !== -1) {
      const targetViewTagSettings = getTargetViewColumn({ fieldName: "tags" });
      const tagFieldWidth = targetViewTagSettings?.width || 100;

      allFields[tagFieldIndex] = {
        field: "tags",
        headerName: "Tags",
        description: `Tags that correspond to this ${typename || "resource"} across the entire Platform`,
        minWidth: tagFieldWidth,
        component: (
          <TagsField
            module={module}
            resource={resource}
            createLinkFunction={allFields[tagFieldIndex]?.createLinkFunction}
            onChangeLinksCallback={allFields[tagFieldIndex]?.onChangeLinksCallback}
          />
        ),
        // Allows the export and filtering to work nicely, but breaks sorting
        valueFormatter: (value) => convertTags({ tags: value }),
        sortable: false,

        // Allows the export and sorting to work nicely, but breaks filtering
        // valueGetter: (value) => convertTags({ tags: value }),

        // Allows search functionality to work with tags
        searchKeys: ["tags.items.*.tag.name"],
        filterOperators: tagOperators,
        ...allFields[tagFieldIndex],
      };
    }

    for (const fieldInput of allFields) {
      // Check if 'visibleFields' have been passed in, and if so, only use those
      if (Array.isArray(visibleFields)) {
        if (!visibleFields?.includes(fieldInput.field) && !visibleFields?.includes(fieldInput.name)) {
          // If not in the visibleFields array, skip this field entirely
          continue;
        }
      }

      const {
        name,
        field,
        friendlyName,
        width,
        type,
        format = type, // If 'type' is passed in but not 'format', this will use 'type' as the format
        component,
        propName,
        plainText = false,
        flex,
        description: descriptionInit,
        ...other
      } = fieldInput;

      // Map the generic edit field type to the correct MUI column type
      let muiColumnType = "string";
      if (
        type === GENERIC_FIELD_TYPES.PERCENT ||
        type === GENERIC_FIELD_TYPES.PERCENTAGE ||
        type === GENERIC_FIELD_TYPES.DOLLAR ||
        type === GENERIC_FIELD_TYPES.NUMERIC ||
        type === "number"
      ) {
        muiColumnType = "number";
      } else if (type) {
        muiColumnType = type;
      }

      const persistenceUUID = gridContext?.persistenceUUID;
      const fieldName = field || name;

      // Target view settings
      const targetViewColumnSettings = getTargetViewColumn({
        fieldName,
      });

      //Check the definition file for field description
      const description = getResourceFieldAttribute({
        override: descriptionInit,
        typename,
        fieldContext: fieldContexts.GRID,
        fieldName,
        attribute: "description",
      });

      const columnHeaderPersistenceUUID = persistenceUUID ? `-${persistenceUUID}` : "";

      // https://v4.mui.com/api/data-grid/grid-col-def/
      // NOTE: the first headerClassName is used here to allow to locate the column header in testing tools and tours, not for styling
      const column = {
        field: fieldName,
        headerName: friendlyName || convertCamelCaseToSentence(fieldName),
        headerClassName: `MuiDataGrid-columnHeader${columnHeaderPersistenceUUID}-${fieldName}`,
        headerAlign: "left",
        width: targetViewColumnSettings?.width || width || 150,
        flex: targetViewColumnSettings?.width ? undefined : flex,
        valueFormatter: (value) => formatValue({ value, format }),
        valueGetter: (value) => defaultValueGetter({ value, format }),
        type: muiColumnType,
        description,
        ...other,
      };

      if (component) {
        column.renderCell = (params) => {
          const item = params.row;
          const field = params.field;
          const value = params.value;
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                textAlign: "left",
              }}
              onKeyDown={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
            >
              {React.cloneElement(component, {
                item,
                field,
                value,
                [propName || field]: value,
                updateItemById,
                resetFunction,
                fieldContext: fieldContexts.GRID,
                gridPersistenceUUID: persistenceUUID,
                ...params,
                ...other,
              })}
            </div>
          );
        };
      } else if (!plainText && updateMutation) {
        column.renderCell = (params) => {
          const item = params.row;
          const field = params.field;
          return (
            <GenericEditFieldV3
              item={item}
              field={field}
              mutation={updateMutation}
              module={module}
              resource={resource}
              typename={typename}
              disableRoleChecking={disableRoleChecking}
              updateItemById={updateItemById}
              resetFunction={resetFunction}
              inputType={format}
              fieldContext={fieldContexts.GRID}
              {...params}
              {...other}
            />
          );
        };
      }

      dataGridColumns.push(column);
    }
  }

  /**
   * If target view columns are available, sync the saved order of columns with the generated columns
   * NOTE: in case a field is not found in the active view it gets added to the end of the list
   * this means any new fields added to a grid will appear in the back for all non-default user views
   */
  if (Array.isArray(dataGridColumns) && Array.isArray(targetView?.columns)) {
    dataGridColumns.sort((columnA, columnB) => {
      //When select buttons are enabled the "Select" column is added to the start of the list
      if (columnA.field === "Select") {
        return -1;
      } else if (columnB.field === "Select") {
        return 1;
      }

      const columnAIndex = targetView?.columns.findIndex((column) => column.name === columnA.field);
      const columnBIndex = targetView?.columns.findIndex((column) => column.name === columnB.field);
      const columnAFound = columnAIndex !== -1;
      const columnBFound = columnBIndex !== -1;
      if (columnAFound && columnBFound) {
        // If both columns are found in the target view, sort by the index
        return columnAIndex - columnBIndex;
      } else if (columnAFound) {
        // If only columnA is found in the target view, sort it first
        return -1;
      } else if (columnBFound) {
        // If only columnB is found in the target view, sort it first
        return 1;
      } else {
        // If neither column is found in the target view, sort by  how they were generated
        return 0;
      }
    });
  }

  processLoadingFields({
    dataGridColumns,
    loadingFields,
  });

  return dataGridColumns;
};

const formatValue = ({ value, format }) => {
  switch (format) {
    case "sentence":
      return convertCamelCaseToSentence(value);
    case "date":
      return !isNullOrUndefined(value) && value !== "" && isDate(new Date(value))
        ? new Date(value).toLocaleString()
        : "No Date";
    default:
      return value;
  }
};

const defaultValueGetter = ({ value, format }) => {
  switch (format) {
    case "date":
      return dateValueGetter({ value });
    default:
      return value;
  }
};
