import React from "react";

import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import RiskControlOverridesV2 from "../../../../../Overrides/components/RiskControlOverridesV2";

const ControlEffectivenessBody = ({ item, isAdmin, system, resetFunction, style }) => {
  return (
    <div style={style.div}>
      <strong>
        Control Effectiveness
        <i
          className="icon-question"
          title="Percent chance that this control will reduce the likelihood of an event. Strength Rating X Implementation Rating"
          style={style.icon}
        />
      </strong>
      <div style={style.field}>
        <RiskControlOverridesV2
          key={JSON.stringify(item)}
          isAdmin={isAdmin}
          riskControl={item}
          system={system}
          field="controlEffectiveness"
          resetFunction={resetFunction}
        />
      </div>
    </div>
  );
};

const ControlEffectiveness = (props) => {
  return (
    <OrganizationCheck {...props}>
      <ControlEffectivenessBody />
    </OrganizationCheck>
  );
};

export default ControlEffectiveness;
