import { Button as MuiButton } from "@mui/material";
import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useModal } from "../../../../hooks/views/useModal";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { linkUnlinkItems } from "../../../../utils/Functions/Graphql/linkUnlinkItems";
import AddOrRemoveIcon from "../../../../utils/GenericComponents/AddOrRemoveIcon";
import { updateControlOverrideType } from "../../Evidence/enums/updateControlOverrideType";
import { submitRiskComplianceSyncJob } from "../../Evidence/functions/submitRiskComplianceSyncJob";
import SelectNonStandardControls from "../components/SelectNonStandardControls";
import { linkEvidenceNonStandardRiskControl } from "../functions/linkEvidenceNonStandardRiskControl";
import { listEvidenceSystemRiskControlOverrides } from "../functions/listEvidenceSystemRiskControlOverrides";

/**
 * hook allowing to link new non-standard risk controls to an evidence
 * @param {object} evidence - the evidence to link the non-standard risk controls to
 * @param {function} resetFunction - callback to call after new controls are linked
 * @param {object} [buttonStyle={}] - optional modal button style
 * @return {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useEvidenceNonStandardRiskControlLinking = ({ evidence, resetFunction, buttonStyle = {} } = {}) => {
  const [linkedNonStandardRiskControls, setLinkedNonStandardRiskControls] = useState([]);
  const pleaseWaitModal = usePleaseWaitModal({
    confirmationText: "Finished linking non-standard risk controls to evidence!",
    autoClose: true,
    steps: [
      {
        id: "step1",
        status: "waiting",
        text: `Linking new non-standard controls...`,
      },
      {
        id: "step2",
        status: "waiting",
        text: `Starting evidence continuous compliance check...`,
      },
    ],
  });

  const onSubmitCallback = async ({ selectedItems } = {}) => {
    selectedItems = selectedItems ?? [];
    pleaseWaitModal.setFinished(false);
    pleaseWaitModal.setStepStatus(0, "inProgress");
    pleaseWaitModal.setStepStatus(1, "waiting");
    pleaseWaitModal.setModalIsOpen(true);

    //Create a new generic function which will return the objects to link, and unlink
    const { linkItems } = await linkUnlinkItems({
      oldItems: linkedNonStandardRiskControls,
      newItems: selectedItems,
      linkFunction: async ({ item }) => {
        await linkEvidenceNonStandardRiskControl({ evidence, item });
      },

      //TODO: Implement when grid supports preselecting items in a grouped grid
      //unlinkFunction: async ({item}) => await unlinkEvidenceNonStandardRiskControl({evidence, item}),

      primaryKeyField: "id",
      parallelSafe: false,
    });
    resetFunction && resetFunction();

    pleaseWaitModal.setStepStatus(0, "complete");
    pleaseWaitModal.setStepStatus(1, "inProgress");

    // Only submit the Risk Compliance Sync Job if Evidence is enabled
    if (evidence?.enabled) {
      submitRiskComplianceSyncJob({
        evidenceID: evidence?.id,
        organizationID: evidence?.ownerGroup,
        riskControlOverrides: linkItems
          .filter((item) => {
            return !isNullOrUndefined(item?.control);
          })
          .map((item) => {
            return {
              ...item?.control,
              system: item?.system,
            };
          }),
        overrideType: updateControlOverrideType.EVIDENCE_LINKED,
      });
    }

    pleaseWaitModal.setStepStatus(1, "complete");
    pleaseWaitModal.setFinished(true);

    //Close all modals and reset the parent state
    linkingModal.setModalIsOpen(false);
  };

  //Find all currently linked items
  useEffect(() => {
    const data = listEvidenceSystemRiskControlOverrides({ evidence });
    setLinkedNonStandardRiskControls(data);
  }, [evidence]);

  const linkingModal = useModal(
    "Create a non-standard Risk Control Linking",
    <SelectNonStandardControls linkedItems={linkedNonStandardRiskControls} onSubmitCallback={onSubmitCallback} />,
    <MuiButton
      style={buttonStyle}
      className="float-right"
      title="Link / Unlink Non Standard Risk Controls to this Evidence"
    >
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "70vw",
    },
  );

  const modalButton = (
    <div>
      {linkingModal.modalButton}
      {pleaseWaitModal.modal}
    </div>
  );

  return {
    ...linkingModal,
    modalButton,
  };
};
