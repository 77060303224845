import { isNullOrUndefined, sortObjectsByField } from "@rivial-security/func-utils";

import { isValidFieldType } from "./isValidFieldType";
import { isValidType } from "./isValidType";
import { isValidTypename } from "./isValidTypename";

/**
 * @typedef ParsedType
 * @property {string} typename
 * @property {object} fields
 * @example
 {
      typename: "Control",
      fields: {
        id: {
          type: "string"
        },
        statementNumber: {
          type: "string"
        },
        name: {
          type: "string"
        },
        isCompliant: {
          type: "boolean"
        }
      }
   }
 */

/**
 * Parses the schema.json file to be used with the QueryBuilder
 * @param {object} schema - full database introspection query result
 * @param {boolean} showConnections - if TRUE, will include fields that link resources to each other
 * @returns {object[]} - array of resources and their field information
 */
export const parseSchemaTypes = ({ schema, showConnections = false }) => {
  if (isNullOrUndefined(schema)) {
    throw Error("Invalid Schema Input, cannot be null or undefined!");
  }

  /**
   * The resulting types array. Adapted to be used with the QueryBuilder
   */
  const types = [];

  /**
   * Get the types ARRAY from schema.json
   */
  const schemaTypes = schema?.data?.__schema?.types;
  if (!schemaTypes) {
    return [];
  }

  /**
   * Loop through the schema types
   */
  for (const type of schemaTypes) {
    if (isValidType(type, showConnections)) {
      const typename = type.name;
      const fields = {};
      const typeFields = type.fields;

      /**
       * Loop through each field of this schema type
       */
      for (const typeField of typeFields) {
        if (isValidFieldType(typeField, showConnections)) {
          const fieldName = typeField.name;
          const fieldType = getFieldType(typeField?.type?.name || typeField?.type?.ofType?.name);

          //When the value is an enum parse the available options
          const isEnum = typeField?.type?.kind === "ENUM";
          const enumValues = [];
          if (isEnum) {
            const enumName = typeField?.type?.name;
            const foundTypeField = schemaTypes.find((schemaType) => schemaType.name === enumName);
            if (foundTypeField) {
              const fullEnumValues = foundTypeField?.enumValues;
              if (Array.isArray(fullEnumValues)) {
                for (const fullEnumValue of fullEnumValues) {
                  enumValues.push(fullEnumValue.name);
                }
              }
            }
          }

          //Record whether the id field is present in the type
          let hasIdField = false;
          let hasOwnerGroupField = false;
          const isNested = typeField?.type?.kind === "OBJECT" || typeField?.type?.ofType?.kind === "OBJECT";
          if (isNested) {
            //Some nested types dont have id fields
            const foundTypeField = schemaTypes.find((schemaType) => schemaType.name === fieldName);

            if (foundTypeField) {
              const foundTypeFields = foundTypeField?.fields;
              if (Array.isArray(foundTypeFields)) {
                const foundIdField = foundTypeFields.findIndex((foundTypeField) => foundTypeField.name === "id");
                const foundOwnerGroupField = foundTypeFields.findIndex(
                  (foundTypeField) => foundTypeField.name === "ownerGroup",
                );
                hasIdField = foundIdField !== -1;
                hasOwnerGroupField = foundOwnerGroupField !== -1;
              }
            }
          }

          fields[fieldName] = {
            parentTypename: typename,
            name: fieldName,
            type: fieldType,
            typename: typeField?.type?.name || typeField?.type?.ofType?.name,
            isModel: typeof typeField?.type?.name === "string" && typeField?.type?.name?.includes("Model"),
            isNested,
            hasMany: typeField?.type?.kind === "LIST" || typeField?.args?.length > 0,
            hasIdField,
            hasOwnerGroupField,
            isEnum,
            enumValues,
          };
        }
      }

      /**
       * Finally, push the parsed type to the array
       */
      if (isValidTypename(typename)) {
        types.push({
          typename,
          fields,
        });
      }
    }
  }

  return sortObjectsByField(types, "typename");
};

/**
 * Converts the schema field type to the syncfusion QueryBuilder type
 * @param {string} fieldTypeName
 * @returns {string}
 */
const getFieldType = (fieldTypeName) => {
  if (fieldTypeName === "String") {
    return "string";
  }

  if (fieldTypeName === "Float") {
    return "number";
  }

  if (fieldTypeName === "Int") {
    return "number";
  }

  return "OBJECT";
};
