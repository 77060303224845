import ResourceFormField from "../../components/generic/resource/ResourceFormField";
import { genericResources } from "../genericResources";
import { schemaEntities } from "../schemaEntities";

export const resourceDefinition = {
  //[TYPE]
  isPrimitive: true,
  underlyingType: genericResources.STRING,
  schemaEntity: schemaEntities.GENERIC,

  //[APPEARANCE]
  InputComponent: ResourceFormField,
};
