import { EnhancedCustomField } from "../../../../../../typedefs/Utility/customFields";

export interface GetQueryCustomFieldDataInput {
  customField: Partial<EnhancedCustomField>;
  organizationID: string;
}

export const getQueryCustomFieldData = async ({
  customField,
  organizationID,
}: GetQueryCustomFieldDataInput): Promise<Record<string, unknown>[]> => {
  const getData = customField?.getData;
  return getData ? await getData({ organizationID }) : [];
};
