"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidDate = void 0;
/**
 * @description Check if a date is valid
 */
const isValidDate = (date) => {
    if (!date) {
        return false;
    }
    return !isNaN(Date.parse(date));
};
exports.isValidDate = isValidDate;
