import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, { useEffect, useState } from "react";
import { Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { setRivialDefaultKRIMapping } from "../functions/setRivialDefaultKRIMapping";

/* Mapping properties: dataBreach, systemDisruption, facilityDisruption, fraud, malware, vendor, compliance */

const DefaultRiskMapping = ({
  item,
  updateMutation,
  fieldName = "defaultRiskMapping",
  resetFunction,
  isInsideSystem = false,
  disabled = false,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [dataBreach, setDataBreach] = useState(false);
  const [systemDisruption, setSystemDisruption] = useState(false);
  const [facilityDisruption, setFacilityDisruption] = useState(false);
  const [fraud, setFraud] = useState(false);
  const [malware, setMalware] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [compliance, setCompliance] = useState(false);

  useEffect(() => {
    let riskMapping = item && item.defaultRiskMapping;
    if (isInsideSystem) {
      riskMapping = item && item.riskMapping;
    }

    if (riskMapping) {
      setDataBreach(riskMapping.dataBreach);
      setSystemDisruption(riskMapping.systemDisruption);
      setFacilityDisruption(riskMapping.facilityDisruption);
      setFraud(riskMapping.fraud);
      setMalware(riskMapping.malware);
      setVendor(riskMapping.vendor);
      setCompliance(riskMapping.compliance);
    }
  }, [item]);

  const getDefaultMapping = () => {
    return {
      dataBreach: false,
      systemDisruption: false,
      facilityDisruption: false,
      fraud: false,
      malware: false,
      vendor: false,
      compliance: false,
      ...(item && item.risk && item.risk.defaultRiskMapping),
    };
  };

  const handleUpdate = async (field, value, disableRefresh = false) => {
    if (!item) {
      ErrorLogger("Error [DefaultRiskMapping.js]: Could not find item to update defaultRiskMapping!");
      return;
    }

    const currentValues = {
      dataBreach,
      systemDisruption,
      facilityDisruption,
      fraud,
      malware,
      vendor,
      compliance,
    };
    const input = {
      id: item.id,
      [fieldName]: {
        ...currentValues,
        [field]: value,
      },
    };

    await ItemMutation(updateMutation, input);

    switch (field) {
      case "dataBreach":
        setDataBreach(value);
        break;
      case "systemDisruption":
        setSystemDisruption(value);
        break;
      case "facilityDisruption":
        setFacilityDisruption(value);
        break;
      case "fraud":
        setFraud(value);
        break;
      case "malware":
        setMalware(value);
        break;
      case "vendor":
        setVendor(value);
        break;
      case "compliance":
        setCompliance(value);
        break;
      default:
        break;
    }

    if (!disableRefresh) {
      resetFunction?.();
    }
  };

  const style = {
    height: "1.5em",
    width: "1.5em",
  };

  const inputStyle = {
    backgroundColor: "white",
  };

  const setRivialDefaults = () => {
    setRivialDefaultKRIMapping(item, updateMutation, isInsideSystem).then(() => {
      resetFunction?.();
    });
  };

  const setOrganizationDefaults = async () => {
    if (window.confirm("Are you sure you want to reset these to the organization defaults?")) {
      const input = {
        id: item.id,
        riskMapping: { ...getDefaultMapping() },
      };

      await ItemMutation(updateMutation, input);
      resetFunction?.();
    }
  };

  return (
    <Col>
      <InputGroup>
        <Input disabled value="Data Breach" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={dataBreach}
              onChange={() => handleUpdate("dataBreach", !dataBreach)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="System Disruption" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={systemDisruption}
              onChange={() => handleUpdate("systemDisruption", !systemDisruption)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="Facility Disruption" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={facilityDisruption}
              onChange={() => handleUpdate("facilityDisruption", !facilityDisruption)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="Fraud" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={fraud}
              onChange={() => handleUpdate("fraud", !fraud)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="Malware" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={malware}
              onChange={() => handleUpdate("malware", !malware)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="Vendor" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={vendor}
              onChange={() => handleUpdate("vendor", !vendor)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input disabled value="Compliance" style={inputStyle} />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Input
              disabled={disabled}
              addon
              type="checkbox"
              checked={compliance}
              onChange={() => handleUpdate("compliance", !compliance)}
              style={style}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <Button
        disabled={disabled}
        style={{ marginTop: "1em" }}
        size="sm"
        color={optionsOpen ? "ghost-danger" : "ghost-secondary"}
        className="btn-pill"
        title="Toggle Options"
        onClick={() => setOptionsOpen((optionsOpen) => !optionsOpen)}
      >
        <i className="icon-settings" /> {optionsOpen ? "Hide" : "Open"} Options
      </Button>
      {optionsOpen ? (
        <>
          <ProgressButtonComponent
            enableProgress={true}
            duration={200}
            style={{ margin: "1em" }}
            onClick={() => setRivialDefaults()}
          >
            Set to Rivial Defaults
          </ProgressButtonComponent>
          {item && item.risk && item.risk.defaultRiskMapping && (
            <ProgressButtonComponent
              enableProgress={true}
              duration={200}
              style={{ margin: "1em" }}
              onClick={() => setOrganizationDefaults()}
            >
              Set to Organization Defaults
            </ProgressButtonComponent>
          )}
        </>
      ) : (
        <></>
      )}
    </Col>
  );
};

export default DefaultRiskMapping;
