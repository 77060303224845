import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

/**
 * @description Next and Previous Buttons for details modal
 * @param {boolean} hasPrevious - boolean value if selected grid item has previous item
 * @param {boolean} hasNext - boolean value if selected grid item has next item
 * @param {function} adjustSelectedItemIndex - change selected item index in the grid
 * @return {JSX.Element}
 * @constructor
 */
export const GridNextAndPreviousButtons = ({ hasPrevious, hasNext, adjustSelectedItemIndex }) => {
  return (
    <div style={{ display: "inline-block", marginRight: "1em" }}>
      <Pagination size="sm">
        <PaginationItem
          title={hasPrevious ? "View the Previous Item in the List" : "This is the first item in the list"}
          disabled={hasPrevious === false}
        >
          <PaginationLink onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(-1)} previous />
        </PaginationItem>
        <PaginationItem
          title={hasNext ? "View the Next Item in the List" : "This is the last item in the list"}
          disabled={hasNext === false}
        >
          <PaginationLink onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(1)} next />
        </PaginationItem>
      </Pagination>
    </div>
  );
};
