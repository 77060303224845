"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFriendlyRating = void 0;
const getFriendlyRating = (value, isUpper = false) => {
    const mappedValues = {
        info: "Info",
        low: "Low",
        lowMedium: "Low-Medium",
        medium: "Medium",
        mediumHigh: "Medium-High",
        high: "High",
    };
    let curResult = "";
    if (mappedValues.hasOwnProperty(value)) {
        curResult = mappedValues[value];
        if (isUpper) {
            curResult = curResult.toUpperCase();
        }
    }
    return curResult;
};
exports.getFriendlyRating = getFriendlyRating;
