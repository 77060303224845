import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays an item sub-field text
 *
 * @example
 * const control = { controlSet: { name: "ACET" } }
 * <ObjectField item={control} field="controlSet" subField="name" />
 *
 * will display: 'ACET'
 *
 * @param {object} item - the root object
 * @param {object} field - the object field, that is also an object
 * @param {field} subField - the name of the subfield of the object field
 * @param {boolean} [required=false] - determines what kind of placeholder to use if the field doesn't exist
 * @returns {*|JSX.Element}
 * @constructor
 */
export const ObjectField = ({ item, field, subField, required = false }) => {
  return !isNullOrUndefined(item) &&
    !isNullOrUndefined(field) &&
    !isNullOrUndefined(subField) &&
    !isNullOrUndefined(item[field]) &&
    !isNullOrUndefined(item[field][subField]) ? (
    item[field][subField]
  ) : required ? (
    <invalidPlaceholder />
  ) : (
    <emptyPlaceholder />
  );
};
