/**
 * Same as getAllProcedureGroupResponseSteps but instead of combining the steps returns an array with the steps separate
 * @param {object} item - the object from database that holds the `[ProcedureGroup]` property
 * @param {string} procedureName - a procedure property that possibly has response steps in one of the procedure groups
 * NOTE: it is important to return null in this function because the logic that creates new groups & procedures depends
 * on this functionality, since undefined represents that the data was not queried but null that the data doesnt exist
 */
export const getSeparatedProcedureGroupResponseSteps = ({ item, procedureName }) => {
  //Check arguments
  if (!item || !procedureName) {
    return null;
  }

  //Parse object
  const groups = [];
  const procedureGroups = item?.procedures;
  if (Array.isArray(procedureGroups)) {
    let groupCount = 1;
    for (const procedureGroup of procedureGroups) {
      const currentGroupResponseSteps = procedureGroup?.[procedureName]?.responseSteps ?? [];
      const currentGroupResponseNotes = procedureGroup?.[procedureName]?.notes ?? [];
      if (Array.isArray(currentGroupResponseSteps) && procedureGroup?.parentID) {
        groups.push({
          parentID: procedureGroup?.parentID,
          linkID: procedureGroup?.linkID,
          name: procedureGroup?.name ?? `Group #${groupCount}`,
          parentTypename: procedureGroup?.parentTypename,
          responseSteps: currentGroupResponseSteps,
          notes: currentGroupResponseNotes,
        });
      }

      groupCount++;
    }
  } else {
    return null;
  }

  return groups;
};
