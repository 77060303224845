"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulletListTypeLevels = exports.numericListTypeLevels = void 0;
exports.numericListTypeLevels = {
    levels: [
        {
            characterFormat: {},
            paragraphFormat: {
                leftIndent: 36,
                firstLineIndent: -18,
                listFormat: {},
            },
            followCharacter: "Tab",
            listLevelPattern: "Arabic",
            numberFormat: "%1.",
            restartLevel: 0,
            startAt: 1,
        },
        {
            characterFormat: {},
            paragraphFormat: {
                leftIndent: 54,
                firstLineIndent: -18,
                listFormat: {},
            },
            followCharacter: "Tab",
            listLevelPattern: "LowLetter",
            numberFormat: "%2.",
            restartLevel: 1,
            startAt: 1,
        },
        {
            characterFormat: {},
            paragraphFormat: {
                leftIndent: 90,
                firstLineIndent: -9,
                listFormat: {},
            },
            followCharacter: "Tab",
            listLevelPattern: "LowRoman",
            numberFormat: "%3.",
            restartLevel: 2,
            startAt: 1,
        },
    ],
};
exports.bulletListTypeLevels = {
    levels: [
        {
            characterFormat: {
                fontFamily: "Symbol",
            },
            paragraphFormat: {
                leftIndent: 36,
                firstLineIndent: -18,
                listFormat: {},
            },
            followCharacter: "Tab",
            listLevelPattern: "Bullet",
            numberFormat: "",
            restartLevel: 0,
            startAt: 0,
        },
    ],
};
