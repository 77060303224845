import { Alert } from "@mui/material";

/**
 * Displays an external URL referenced in an evidence artifact submission
 * @param {string} url - the external URL to display
 * @returns {JSX.Element}
 */
const EvidenceExternalURLViewer = ({ url }) => {
  return (
    <Alert severity="info">
      <p>The user has submitted an external URL as an artifact for this evidence.</p>
      <br />
      <strong>URL: </strong>
      <a href={url}>{url}</a>
    </Alert>
  );
};

export default EvidenceExternalURLViewer;
