import { useTargetsGrid } from "../../../../views/Testing/Targets/hooks/useTargetsGrid";

export const targetDefinition = {
  routes: {
    grid: "vulnerabilities/targets",
    details: "vulnerabilities/targets",
    helpCenter: "create-a-target",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:target",

  //[COMPONENTS]
  gridHook: useTargetsGrid,
};
