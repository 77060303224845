"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMonteCarloSystemRiskLinkData = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const updateSystemRiskLink_1 = require("../graphql/updateSystemRiskLink");
/**
 * Loops over each risk from the calculation result and updates monte carlo related fields
 */
const updateMonteCarloSystemRiskLinkData = async ({ riskStats, }) => {
    console.log("[updateMonteCarloSystemRiskLinkData] Updating SystemRiskLink fields...");
    const promises = [];
    for (const riskStat of riskStats) {
        const mutationPromise = (0, appsync_utils_1.ItemMutation)({
            mutation: updateSystemRiskLink_1.updateSystemRiskLink,
            input: {
                id: riskStat.systemRiskLinkId,
                inherentRisk: riskStat.inherentRisk,
                residualRisk: riskStat.residualRisk,
                inherentRiskRating: riskStat.inherentRiskRating,
                residualRiskRating: riskStat.residualRiskRating,
                riskMitigated: riskStat.riskMitigated,
            },
            appsyncEndpoint: process.env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
        });
        promises.push(mutationPromise);
    }
    console.log("[updateMonteCarloSystemRiskLinkData] Finished Updating SystemRiskLink fields.");
    await Promise.allSettled(promises);
};
exports.updateMonteCarloSystemRiskLinkData = updateMonteCarloSystemRiskLinkData;
