import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a ObservationTagLink object
 * @param {object} observation - the observation that needs to be attached to the tag
 * @param {object} tag - the tag that needs to be attached to the observation
 * @param {string} organizationID - the organization identifier of the observation in the database
 * @return {Promise<*>}
 */
export const createObservationTagLink = (observation, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(observation?.id)) {
    ErrorLogger("Invalid input!");
  }

  const { createMutation: createObservationTagLink } = generateGraphql("ObservationTagLink", [
    "observationID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createObservationTagLink, {
    observationID: observation.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
