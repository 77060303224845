import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import CreateEmailTemplate from "../components/CreateEmailTemplate";
import EmailTemplateDetails from "../components/EmailTemplateDetails";
import { deleteEmailTemplate } from "../functions/deleteEmailTemplate";

export const useEmailTemplateGrid = ({ organizationID, ...props }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.EMAIL_TEMPLATE;
  const typename = "Email";

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "subject",
      flex: 1,
      bulkEdit: true,
    },
  ];

  const { listQuery, updateMutation } = generateGraphql("Email", ["name", "subject", "recipients"]);

  return useDataGrid({
    organizationID,
    persistenceUUID: "email-template-data-grid",
    module,
    resource,
    typename,
    fields,
    query: listQuery,
    updateMutation,
    createResourceComponent: <CreateEmailTemplate organizationID={organizationID} />,
    createResourceComponentWidth: "80vw",
    detailsComponent: <EmailTemplateDetails organizationID={organizationID} />,
    enableQuickDetails: true,
    deleteFunction: deleteEmailTemplate,
    options: ["delete", "duplicate", "edit"],
    duplicationSettings: {
      description: "Duplicates an Email Template. Copies over Subject, Recipients, and Email HTML Body",
      enabled: true,
      fields: ["name", "subject", "recipients", "replyTo", "body", "design"],
      primaryField: "name",
      fieldAdaptor: {
        name: (item) => `${item.name}-duplicated`,
      },
    },
    ...props,
  });
};
