import { isObjectLike, mapValues } from "lodash";

/**
 * Strips the data recursively for arrays and objects for saving to the database
 * @param {object} item
 * @param {object} item.data - the data to parse through recursively
 * @param {number} item.defaultDecimalPlaces - amount of decimal places to keep for numeric fields
 * @return {object}
 */
export const recursiveRecommendationDataStripper = ({ data, defaultDecimalPlaces }) => {
  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => recursiveRecommendationDataStripper({ data: item, defaultDecimalPlaces }));
  }

  if (isObjectLike(data)) {
    const currentData = mapValues(data, (value) => {
      if (Array.isArray(value)) {
        return value.map((item) =>
          recursiveRecommendationDataStripper({
            data: item,
            defaultDecimalPlaces,
          }),
        );
      }

      if (isObjectLike(value)) {
        return recursiveRecommendationDataStripper({
          data: value,
          defaultDecimalPlaces,
        });
      }

      //Remove decimal places that are unneeded
      if (typeof value === "number") {
        return parseFloat(value.toFixed(defaultDecimalPlaces));
      }

      return value;
    });

    delete currentData.whatIfLoss;
    delete currentData.whatIfCostOfControls;
    delete currentData.whatIfOverallROI;
    delete currentData.whatIfAdditionalROI;
    delete currentData.currentNetProfit;
    delete currentData.lossToleranceCurve;
    delete currentData.lossExceedanceCurve;
    delete currentData.subControls;

    return currentData;
  }

  return data;
};
