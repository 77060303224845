import { recursiveRecommendationDataStripper } from "../../../functions/recursiveRecommendationDataStripper";
import { stripRiskRecommendationCalculationDataForSaving } from "../../../functions/stripRiskRecommendationCalculationDataForSaving";

export interface RiskRecommendationData {
  selectedRiskControl: {
    id: string;
  };
  changeInImplementation: number;
  changeInCost: number;
  selectedSystem: {
    id: string;
    name: string;
  };
  overallResidualChange: number;
  overallROIChange: number;
  systemChanges: {
    id: string;
    name: string;
    residualChange: number;
    returnOnInvestment: number;
  }[];
}

export interface FormatRecommendationDataInput {
  riskRecommendationData: RiskRecommendationData;
  organizationID: string;
}

/**
 * Gets the custom data field for the risk recommendation
 * Returns a stringified version of the risk recommendation data
 */
export const formatRecommendationData = ({
  riskRecommendationData,
  organizationID,
}: FormatRecommendationDataInput): string => {
  // Strip extra decimal places and fields
  riskRecommendationData = recursiveRecommendationDataStripper({
    data: riskRecommendationData,
    defaultDecimalPlaces: 4,
  });

  const calculations = stripRiskRecommendationCalculationDataForSaving({
    calculations: riskRecommendationData?.systemChanges,
  });

  const savedSystemChanges: {
    id: string;
    name: string;
    residualChange: number;
    returnOnInvestment: number;
  }[] = [];

  if (Array.isArray(calculations)) {
    for (const systemChange of calculations) {
      if (systemChange.residualChange !== "Not Applicable") {
        savedSystemChanges.push(systemChange);
      }
    }
  }

  return JSON.stringify({
    riskControl: riskRecommendationData?.selectedRiskControl,
    riskControlID: riskRecommendationData?.selectedRiskControl?.id,
    implementationChange: riskRecommendationData?.changeInImplementation / 100,
    costChange: riskRecommendationData?.changeInCost,
    ownerGroup: organizationID,
    riskRecommendationSystemId: riskRecommendationData?.selectedSystem
      ? riskRecommendationData?.selectedSystem?.id
      : undefined,
    residualChange: riskRecommendationData?.overallResidualChange,
    returnOnInvestment: riskRecommendationData?.overallROIChange,
    systemChanges: [...savedSystemChanges],
    system: riskRecommendationData?.selectedSystem
      ? {
          id: riskRecommendationData?.selectedSystem.id,
          name: riskRecommendationData?.selectedSystem.name,
        }
      : undefined,
  });
};
