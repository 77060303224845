"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTableOfContents = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const generateTableOfContentsHeadingBlock_1 = require("./generateTableOfContentsHeadingBlock");
/**
 * Checks if a table of contents placeholder is present and updates it to contain three layers of headings
 * @param {object} sfdt - the generate sfdt for the report that might be containing a table of contents placeholder
 * @returns {object} sfdt - the updated sfdt, intact sfdt if placeholder is not found
 */
const updateTableOfContents = ({ sfdt }) => {
    //Check if the argument is null
    if (!sfdt) {
        return sfdt;
    }
    //Check sfdt to contain the placeholder
    const sfdtString = JSON.stringify(sfdt);
    const placeholderIndex = sfdtString.indexOf("TABLE_OF_CONTENTS_PLACEHOLDER");
    const containsPlaceholder = placeholderIndex > -1;
    //Skip if table of contents update if placeholder is not present
    if (!containsPlaceholder) {
        return sfdt;
    }
    //Identify all headings with nesting info and find placeholder location
    const headings = [];
    let placeholderSection;
    let placeholderBlock;
    const sections = sfdt?.sections;
    let sectionIndex = 0;
    if (Array.isArray(sections)) {
        for (const section of sections) {
            let blockIndex = 0;
            const blocks = section?.blocks;
            if (Array.isArray(blocks)) {
                for (const block of blocks) {
                    // - determine if this is a toc heading block, if so add it to the list
                    const styleName = block?.paragraphFormat?.styleName;
                    const isHeading = styleName?.startsWith("Heading");
                    const isTOCHeading = isHeading && styleName !== "Heading 4";
                    if (isTOCHeading) {
                        // - try to find the text for this heading
                        const inlines = block?.inlines;
                        let text = "";
                        if (Array.isArray(inlines)) {
                            for (const inline of inlines) {
                                if (inline?.text) {
                                    text += inline.text;
                                }
                            }
                        }
                        headings.push({
                            sectionIndex,
                            blockIndex,
                            styleName,
                            text,
                        });
                    }
                    // - determine if this is the placeholder block, if so save the location
                    const blockString = JSON.stringify(block);
                    const placeholderIndex = blockString.indexOf("TABLE_OF_CONTENTS_PLACEHOLDER");
                    const containsPlaceholder = placeholderIndex > -1;
                    if (containsPlaceholder) {
                        placeholderSection = sectionIndex;
                        placeholderBlock = blockIndex;
                    }
                    blockIndex++;
                }
            }
            sectionIndex++;
        }
    }
    //Skip update if placeholder location is not found
    if ((0, func_utils_1.isNullOrUndefined)(placeholderSection) || (0, func_utils_1.isNullOrUndefined)(placeholderBlock)) {
        return sfdt;
    }
    //When there are no headings replace placeholder with a message
    if (headings.length === 0) {
        sfdt.sections[placeholderSection].blocks[placeholderBlock] = {
            paragraphFormat: {
                styleName: "Normal",
                listFormat: {},
            },
            characterFormat: {},
            inlines: [
                {
                    characterFormat: {},
                    text: "No headings found in the document. Table of contents was not populated.",
                },
            ],
        };
    }
    //Create table of contents sfdt updating the anchors used with every heading
    const tableOfContentsBlocks = [];
    const validHeadings = [];
    let headingIndex = 0;
    for (const heading of headings) {
        const headingData = (0, generateTableOfContentsHeadingBlock_1.generateTableOfContentsHeadingBlock)({ heading, headingIndex });
        const { sfdt, anchor } = headingData || {};
        if (!anchor || !sfdt) {
            continue;
        }
        else {
            const newHeading = {
                ...heading,
                anchor,
            };
            validHeadings.push(newHeading);
            tableOfContentsBlocks.push(sfdt);
        }
        headingIndex++;
    }
    //If there are no valid headings replace the placeholder with a message
    if (validHeadings.length === 0) {
        sfdt.sections[placeholderSection].blocks[placeholderBlock] = {
            paragraphFormat: {
                styleName: "Normal",
                listFormat: {},
            },
            characterFormat: {},
            inlines: [
                {
                    characterFormat: {},
                    text: "No valid headings found in the document. Table of contents was not populated.",
                },
            ],
        };
    }
    //Navigate to the heading's locations and add anchor inlines
    // - done before all other updates to preserve block and section indexes
    for (const heading of validHeadings) {
        const { sectionIndex, blockIndex, anchor } = heading;
        const block = sfdt.sections[sectionIndex].blocks[blockIndex];
        const inlines = block?.inlines;
        if (Array.isArray(inlines) && anchor) {
            inlines.unshift({
                characterFormat: {},
                bookmarkType: 0,
                name: anchor,
            });
            inlines.push({
                characterFormat: {},
                bookmarkType: 1,
                name: anchor,
            });
        }
    }
    //Add table of contents settings to the first table of contents block inline
    if (Array.isArray(tableOfContentsBlocks) && tableOfContentsBlocks.length > 0) {
        const inlines = tableOfContentsBlocks[0].inlines;
        if (Array.isArray(inlines)) {
            inlines.unshift(...[
                {
                    characterFormat: {},
                    fieldType: 0,
                    hasFieldEnd: true,
                },
                {
                    characterFormat: {},
                    text: 'TOC \\o "1-3" \\h \\z',
                },
                {
                    characterFormat: {},
                    fieldType: 2,
                },
            ]);
        }
        else {
            return sfdt;
        }
    }
    else {
        return sfdt;
    }
    //Replace placeholder with table of contents sfdt
    sfdt.sections[placeholderSection].blocks.splice(placeholderBlock, 1, ...tableOfContentsBlocks);
    return sfdt;
};
exports.updateTableOfContents = updateTableOfContents;
