import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import React from "react";
import { Button } from "reactstrap";

import { useModal } from "../../../../../../hooks/views/useModal";
import MeetingDetails from "../../../../../Program/Meetings/components/MeetingDetails";
import { getFullName } from "../functions/getFullName";

/**
 * @description Displays timeline information about a point of contact uploading a Report for an Evidence
 * @param {object} meeting - referenced meeting data for this activity, should have id property present
 * @param {PointOfContact} pointOfContact - point of contact object
 * @param {string} pointOfContact.firstName - point of contact first name
 * @param {string} pointOfContact.lastName - point of contact last name
 * @returns {JSX.Element}
 */
const MeetingActivity = ({ pointOfContact, meeting }) => {
  const meetingDetailsModal = useModal(
    "Meeting Details",
    <MeetingDetails item={meeting} />,
    <Button color={"success"}>
      <i className={"icon-eye"} style={{ marginRight: "1em" }} />
      View Meeting
    </Button>,
    {
      width: "80vw",
    },
  );

  const fullName = getFullName({ pointOfContact });

  return (
    <span style={{ fontSize: ".9em" }}>
      {meetingDetailsModal.modal}
      {fullName || "Someone"} referenced a{" "}
      <LightTooltip arrow interactive title={<div>{meetingDetailsModal.modalButton}</div>}>
        <span
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none;",
            cursor: "pointer",
          }}
        >
          Meeting
        </span>
      </LightTooltip>
    </span>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default MeetingActivity;
