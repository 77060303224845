"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalizeFirstLetter = void 0;
/**
 * Capitalize first letter of a string
 */
const capitalizeFirstLetter = (str) => {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    else {
        return null;
    }
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
