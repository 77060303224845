import { generateGraphql } from "@rivial-security/generategraphql";

import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single RecommendationItem object along with associated Many-To-Many connections
 * @param observationRecommendationLink
 */
export const deleteObservationRecommendationLink = async (observationRecommendationLink) => {
  const { getQuery } = generateGraphql("ObservationRecommendationLink", ["__typename"]);

  return await deleteItem(getQuery, observationRecommendationLink);
};
