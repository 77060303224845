import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import React from "react";

import { useConfirmExternalUrl } from "../../../../../../hooks/views/useConfirmExternalUrl";
import { getFullName } from "../functions/getFullName";

/**
 * @description Display External URL ui with a confirmation page
 * @param {string} externalUrl - external URL
 * @param {object} pointOfContact - point of contact
 * @returns {JSX.Element}
 */
const ExternalUrlActivity = ({ externalUrl, pointOfContact }) => {
  const confirmExternalUrl = useConfirmExternalUrl({ externalUrl });

  const fullName = getFullName({ pointOfContact });

  return (
    <span>
      {confirmExternalUrl.modal}
      {fullName || "Someone"} referenced an{" "}
      <LightTooltip arrow interactive title={<div>{confirmExternalUrl.display}</div>}>
        <span
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none;",
            cursor: "pointer",
          }}
        >
          External Link
        </span>
      </LightTooltip>
    </span>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default ExternalUrlActivity;
