import { Button } from "@mui/material";
import React, { useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { checkPermissions, modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";

import RolePreviewToast from "./RolePreviewToast";

/**
 * Button that allows a user to temporarily assume a role for previewing purposes
 * @param item
 * @param setRoleConfig
 * @param roleConfig
 * @returns {JSX.Element}
 * @constructor
 */
const RolePreviewButton = ({ item, roleConfig }) => {
  const history = useHistory();

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const { addToast, updateToast } = useUIContext();

  const context = useContext(OrganizationContext);

  const startPreview = () => {
    /**
     * Check if user can preview role
     */
    if (typeof roleConfig === "object" && Object.keys(roleConfig).length !== 0) {
      const resourceAllow = checkPermissions({
        module: modules.ORGANIZATION_MANAGER,
        resource: resources.ROLE,
        roleConfig,
      });

      if (!resourceAllow?.module?.isEnabled || !resourceAllow?.resource?.read) {
        history.push("/");
      }
    }

    context.setRoleConfig(roleConfig);

    addToast({
      toastId: "role-preview-toast",
      icon: "success",
      header: "Role Preview",
      body: <RolePreviewToast item={item} />,
      isPermanent: true,
    });
  };
  return (
    <span>
      <Button
        style={{ border: "1px dashed gold" }}
        data-testid="button-preview-role"
        size="sm"
        color="warning"
        title={"Temporarily assumes this Role. Refresh the Page to go back to your own Role"}
        onClick={() => {
          startPreview();
          forceUpdate();
        }}
      >
        <i className={"icon-eye"} style={{ marginRight: "1em" }} />
        Preview Role
      </Button>
    </span>
  );
};

export default RolePreviewButton;
