import AWS from "aws-sdk";

import { ErrorLogger, InfoLogger } from "../../EventLogger";
import { ItemQuery } from "../Graphql/ItemQuery";

import { GetOrgIamCredentials } from "./GetOrgIamCredentials";

const getOrganization_s3BucketName = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      s3BucketName
    }
  }
`;

/**
 * @description Upload a S3 object to a bucket
 *
 * @param {object} file - file to upload
 * @param {string} fileType - file type
 * @param {string} bucketName - bucket name to upload
 * @param {string} objectKey - object key to upload
 * @param {function} onprogress - progress callback
 * @param {string} organizationID - organization ID to upload
 * @param {function} onError - error callback
 * @returns {Promise<void>}
 * @constructor
 */

export const PutS3Object = async ({ file, fileType, bucketName, objectKey, onprogress, organizationID, onError }) => {
  let s3BucketName = null;

  if (organizationID) {
    const org = await ItemQuery(getOrganization_s3BucketName, organizationID);
    s3BucketName = org?.s3BucketName;
  }

  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: "us-west-2",
  });

  const params = {
    Body: file,
    ContentType: fileType || file.type,
    Bucket: s3BucketName || bucketName,
    Key: objectKey,
  };

  return await new Promise((resolve, reject) => {
    s3.putObject(params)
      .on("httpUploadProgress", function (evt) {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        onprogress?.(uploaded);
        InfoLogger(`File uploaded: ${uploaded}%`);
      })
      .send(function (err, data) {
        if (err) {
          if (err.message === "Network Failure") {
            alert("Network Failure, please try again");
          }

          ErrorLogger("Error! ", err);
          onError?.(err);
          throw Error("Error! Failed to upload Document", err);
        } else {
          InfoLogger(data);
          resolve(data);
        }
      });
  });
};
