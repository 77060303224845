"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nessusScannerFields = void 0;
exports.nessusScannerFields = [
    {
        displayName: "Name",
        resourceFieldName: "name",
        defaultCSVFieldNames: ["Plugin Name", "Name"],
    },
    {
        displayName: "Type",
        resourceFieldName: "type",
        interpreterDescription: "This field should represent the risk level, if the field value is 'None' the platform type will be marked as informational.",
    },
    {
        displayName: "CVSS",
        resourceFieldName: "cvss",
        defaultCSVFieldNames: ["CVSS", "CVSS v2.0 Base Score"],
    },
    {
        displayName: "Severity Level",
        resourceFieldName: "severityLevel",
        interpreterDescription: "This field should represent the risk level, standard scanner values will be mapped to platform severity levels.",
    },
    {
        displayName: "Summary",
        resourceFieldName: "summary",
        interpreterDescription: "This field will be copied into summary field, otherwise will combine content from 'Description' and 'Synopsis' fields.",
    },
    {
        displayName: "Exploit Available",
        resourceFieldName: "exploitAvailable",
        interpreterDescription: "This field will be checked for content, if there is any, and will mark an exploit as available. Otherwise checks 'Metasploit', 'Core Impact', and 'CANVAS' fields by default.",
    },
    {
        displayName: "Port",
        resourceFieldName: "port",
        defaultCSVFieldNames: ["Port"],
    },
    {
        displayName: "Protocol",
        resourceFieldName: "protocol",
        defaultCSVFieldNames: ["Protocol"],
    },
    {
        displayName: "CVE",
        resourceFieldName: "cve",
        defaultCSVFieldNames: ["CVE"],
    },
    {
        displayName: "Solution",
        resourceFieldName: "solution",
        defaultCSVFieldNames: ["Solution"],
    },
    {
        displayName: "Plugin ID",
        resourceFieldName: "pluginID",
        defaultCSVFieldNames: ["Plugin ID", "Plugin"],
    },
    {
        displayName: "Host Name",
        resourceFieldName: "hostName",
        interpreterDescription: "This field will be checked for a valid host name, by default checks 'DNS Name' and 'Host' fields instead.",
    },
    {
        displayName: "IP",
        resourceFieldName: "ip",
        interpreterDescription: "This field will be checked for a valid IP address, if not found will also attempt to extract it using the 'Plugin Output' field.",
    },
];
