import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import { moduleTypes } from "../../moduleTypes";

/**
 * Displays the Module field for an Action Item.
 * @param item
 * @param updateItemById
 * @constructor
 */
const Module = ({ item, updateItemById }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;
  const field = "module";

  const options = moduleTypes.map((type) => {
    return {
      text: convertCamelCaseToSentence(type),
      value: type,
    };
  });

  return (
    <GenericFieldDropdown
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql("GenericActionItem", [field]).updateMutation}
      options={options}
      format="sentence"
      updateItemById={updateItemById}
    />
  );
};

export default Module;
