import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Update Grid row from a custom field component
 * @param item - updated object to update the grid data
 * @param {object} ref - reference of the the grid component
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {boolean} [disableRefresh = false] - prevents the grid from refreshing after this function is called
 * @param {function} updateItemById - an external updateItemById function that allows to parent components to hook into
 * the grid's updateItemById function
 */
export const gridUpdateItemById = ({
  item,
  ref,
  disableUpdate,
  setGridScrollPosition,
  saveScrollPosition = true,
  disableRefresh = false,
  updateItemById,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    item,
    ref,
  };
  checkParams({ params });

  //Saving the scroll position of the grid if that option is enabled
  //https://www.syncfusion.com/forums/165081/save-the-scroll-position-in-grid
  if (setGridScrollPosition) {
    if (saveScrollPosition === true) {
      const contentNode = ref?.element?.querySelectorAll(".e-content");
      if (contentNode?.length > 0) {
        const contentScrollPosition = contentNode[0]?.scrollTop;
        setGridScrollPosition(contentScrollPosition);
      } else {
        setGridScrollPosition(0);
      }
    } else {
      setGridScrollPosition(0);
    }
  }

  if (ref?.dataSource && Array.isArray(ref?.dataSource) && item?.id) {
    const foundIndex = ref.dataSource.findIndex((x) => x?.id === item.id);

    if (foundIndex !== null && foundIndex !== undefined && foundIndex !== -1) {
      const newData = [...ref.dataSource];
      newData[foundIndex] = {
        ...ref.dataSource[foundIndex],
        ...item,
      };

      if (disableUpdate) {
        disableUpdate.current = true;
      }

      if (disableRefresh === false) {
        ref.setProperties && ref.setProperties({ dataSource: newData });
      }

      //Call the external updateItemById in case it is provided
      updateItemById && updateItemById(item);

      return { dataSource: newData };
    }
  }
};
