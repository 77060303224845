import React from "react";

import { resources } from "@rivial-security/role-utils";

import EllipsisText from "../../../../../../utils/Text/components/EllipsisText";

import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";

/**
 * Timeline content to display when a vulnerability history item is a tag status
 * @param {object} item - the TargetVulnerabilityHistory item
 * @returns {JSX.Element}
 */
const TagStatus = ({ item }) => {
  const isFindingAttached = !!item?.vulnerabilityName;
  if (isFindingAttached) {
    return (
      <span>
        {"Vulnerability "}
        <TimelineUnderlinedDetails modalType={resources.VULNERABILITY} item={item}>
          <EllipsisText text={item?.vulnerabilityName} maxLength={15} />
        </TimelineUnderlinedDetails>
        {" was assigned a new tag during an "}
        <TimelineUnderlinedDetails
          tooltipText="Click to view Assessment Details"
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      </span>
    );
  } else {
    return (
      <span>
        {"Target "}
        <TimelineUnderlinedDetails tooltipText="Click to view Target Details" modalType={resources.TARGET} item={item}>
          <EllipsisText text={item?.targetName} maxLength={15} />
        </TimelineUnderlinedDetails>
        {" was assigned a new tag during an "}
        <TimelineUnderlinedDetails
          tooltipText="Click to view Assessment Details"
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      </span>
    );
  }
};

export default TagStatus;
