"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmailWidget = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const widgetType_1 = require("../../constants/widgetType");
const handleEmailTableWidget_1 = require("../emailWidgets/tables/handleEmailTableWidget");
const createWidgetTable_1 = require("../reportWidgets/createWidgetTable");
const getWidgetType_1 = require("../utils/getWidgetType");
/**
 * Creates a html string for any type of widget being placed inside an email.
 *
 * @param {object} widget - widget information and settings
 * @param {object} context - the widget container settings
 * @param {object} automationContext - object with context to use for smart values
 * @param {object} automationContext.item - the triggering item, if automation was triggered by a resource create/update
 * @param {object} automationContext.steps - result objects from previous automation steps that are referenced in the email template
 * @param {object} automationContext.loop - the loop object, if this email is being sent from a loop
 * @param {object} automationContext.webhookData - the webhook data from the incoming request, if automation was triggered by a webhook
 * @returns {Promise<string>}
 */
const getEmailWidget = async ({ widget, context, automationContext }) => {
    console.log("[getEmailWidget] - ", JSON.stringify({ widget }));
    const type = (0, getWidgetType_1.getWidgetType)({ widget });
    console.log("[getEmailWidget] - ", { type });
    const config = (0, func_utils_1.tryParse)(widget?.config);
    console.log("[getEmailWidget] - ", { config });
    switch (type) {
        case widgetType_1.WIDGET_TYPE.TABLE:
            return await (0, createWidgetTable_1.createWidgetTable)({
                widget,
                context,
                handleTableWidget: handleEmailTableWidget_1.handleEmailTableWidget,
                automationContext,
            });
        case widgetType_1.WIDGET_TYPE.STATIC_TEXT:
        case widgetType_1.WIDGET_TYPE.DYNAMIC_TEXT:
        case widgetType_1.WIDGET_TYPE.IMAGE:
        case widgetType_1.WIDGET_TYPE.RISK_TOLERANCE_CHART:
        case widgetType_1.WIDGET_TYPE.HISTORY_CHART:
        case widgetType_1.WIDGET_TYPE.PIE_CHART:
        case widgetType_1.WIDGET_TYPE.RADAR_CHART:
        case widgetType_1.WIDGET_TYPE.PROGRESS_BAR:
        case widgetType_1.WIDGET_TYPE.GAUGE:
        case widgetType_1.WIDGET_TYPE.UNSUPPORTED:
        default:
            return "<p>This email widget type is unsupported!</p>";
    }
};
exports.getEmailWidget = getEmailWidget;
