import { Icon } from "@iconify/react";
import { TreeItem } from "@mui/x-tree-view";
import { isEqual } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { applyWidgetFieldTransforms, tableFieldTransformType } from "@rivial-security/widget-utils";

import { useForm } from "../../../../../../../../hooks/views/useForm";
import GridViewTree from "../../../../../../../../hooks/views/useGrid/components/GridViewTree";
import { withOrganizationCheck } from "../../../../../../../../utils/Context/withOrganizationCheck";
import FeatureAlert from "../../../../../../../../utils/GenericComponents/FeatureAlert";
import { useOrganizationContext } from "../../../../../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { QUERY_RUN_TYPE } from "../../../../../../../CustomQueries/hooks/useCustomQueryRunner/hooks/useCustomQueryRunnerExecutionLogic/useCustomQueryRunnerExecutionLogic";
import { useCustomQueryRunner } from "../../../../../../../CustomQueries/hooks/useCustomQueryRunner/useCustomQueryRunner";
import { getGridFieldsToMerge } from "../../../../../../WidgetDetails/hooks/useCustomQueryWidgetRunner/functions/getGridFieldsToMerge";
import { useSetEditedSeriesQueryForWidgetTable } from "../../../../../../WidgetDetails/hooks/useCustomQueryWidgetRunner/hooks/useSetEditedSeriesQueryForWidgetTable";
import { createWidgetActions } from "../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../useCreateWidget";
import { TemplateQueryAlert } from "../../TemplateQueryAlert";
import { getInitialTableSettingsFormInput } from "../functions/getInitialTableSettingsFormInput";

import TableWidgetPreview from "./TableWidgetPreview";

const editSettings = {
  TABLE: "table",
  FIELD: "field",
  NONE: "none",
};

/**
 * Component for adjusting how a table looks by default, part of the widget creation flow
 * @param {string} organizationID - the currently selected organization
 * @returns {JSX.Element}
 */
const CustomizeTableWidgetVisuals = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [editedSetting, setEditedSetting] = useState(editSettings.TABLE);
  const [editedField, setEditedField] = useState(null);
  const { editedSeries, queryConfig } = useSetEditedSeriesQueryForWidgetTable({
    widget,
    dependencies: [widget?.config?.series],
  });

  const tableSettingsForm = useForm({
    item: editedSeries?.tableSettings,
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      showLinkToResource: {
        label: "Show Link to Resource",
        tooltip: "Should the table show a link to the resource next to each row?",
        inputType: "switch",
        default: true,
      },
      detailsRoutePrefix: {
        label: "Details Route Prefix",
        tooltip:
          "The URL path to the displayed resource in the platform. You can find this text before the id of the resource in the URL when looking at the details pages of the queried type.",
        isHidden: ({ input }) => {
          return input?.showLinkToResource !== true;
        },
      },
    },
  });

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    dispatch({
      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
      seriesInput: {
        ...editedSeries,
        tableSettings: {
          ...tableSettingsForm?.input,
        },
      },
    });
  }, [tableSettingsForm?.input]);

  const fieldSettingsForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fields: ["headerName", "transformType", "emailColumnWidth"],

    fieldConfig: {
      headerName: {
        label: "Header Name",
        tooltip: "Should this field name be displayed as something different? Ex. 'isCompliant' -> 'Compliance Status'",
        inputType: "text",
        defaultValue: convertCamelCaseToSentence(editedField),
        id: `header-name-${editedField}`,
      },
      transformType: {
        label: "Field Value Transform Type",
        tooltip: "Choose a pre-defined transform",
        inputType: "dropdown",
        dropdownConfig: {
          data: [
            {
              text: "Dollar Amount",
              value: tableFieldTransformType.DOLLAR,
            },
            {
              text: "Percentage",
              value: tableFieldTransformType.PERCENT,
            },
            {
              text: "Locale Date",
              value: tableFieldTransformType.LOCALE_DATE,
            },
            {
              text: "Camel Case to Sentence",
              value: tableFieldTransformType.SENTENCE_CASE,
            },
          ],
        },
      },
      emailColumnWidth: {
        id: `email-column-width-${editedField}`,
        defaultValue: null,
        label: "Column Width (Emails)",
        tooltip:
          "The width in pixels that this field's column should have when the widget is inserted into an email. May be scaled down proportionally if the total width of all columns exceeds the email width.",
        inputType: "number",
        numberConfig: {
          min: 10,
          max: 900,
          step: 1,
        },
      },
    },
  });
  //On update of the field settings form, update widget config
  useEffect(() => {
    dispatch({
      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
      seriesInput: {
        ...editedSeries,
        fieldSettings: {
          ...editedSeries?.fieldSettings,
          [editedField]: fieldSettingsForm?.input,
        },
      },
    });
  }, [fieldSettingsForm?.input]);

  const onSelectField = ({ field }) => {
    const fieldSettings = editedSeries?.fieldSettings?.[field];
    if (!field) {
      fieldSettingsForm.getInitialState();
      setEditedSetting(editSettings.NONE);
    } else {
      fieldSettingsForm.setInput({
        headerName: fieldSettings?.headerName || convertCamelCaseToSentence(field),
        transformType: fieldSettings?.transformType,
        emailColumnWidth: isNullOrUndefined(fieldSettings?.emailColumnWidth) ? "" : fieldSettings?.emailColumnWidth,
      });
      setEditedSetting(editSettings.FIELD);
    }

    setEditedField(field);
  };

  const runner = useCustomQueryRunner({
    query: queryConfig,
    organizationID,
    runType: QUERY_RUN_TYPE.LOCAL,
    disableTopLevelFunctions: true,
    onColumnHeaderClick: onSelectField,
    normalizeData: (items) => {
      const newData = applyWidgetFieldTransforms({
        data: items,
        seriesItem: editedSeries,
      });

      return newData;
    },
    gridFieldsToMerge: getGridFieldsToMerge({ editedSeries }),
    enableQuickDetails: editedSeries?.tableSettings?.showLinkToResource,
    detailsLink: editedSeries?.tableSettings?.detailsRoutePrefix,
  });

  //On update of the grid view, update widget config
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    dispatch({
      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
      seriesInput: {
        ...editedSeries,
        viewSettings: runner?.grid?.targetView,
      },
    });
  }, [runner?.grid?.targetView]);

  useEffect(() => {
    if (!isInitialized && editedSeries && queryConfig) {
      const changeTargetView = runner?.grid?.changeTargetView;
      const viewSettings = editedSeries?.viewSettings;

      if (typeof changeTargetView === "function" && typeof viewSettings === "object") {
        changeTargetView(viewSettings);
      }

      // Update the default route when the query changes, only show the new route if one is defined
      const tableSettings = getInitialTableSettingsFormInput({
        editedSeries,
        queryConfig,
      });
      tableSettingsForm.setInput({
        ...tableSettings,
      });
    }
  }, [editedSeries, isInitialized, queryConfig]);

  //Check if ui state has been initialized
  useEffect(() => {
    //The edited series item has not been loaded yet
    if (!editedSeries || !queryConfig) {
      return;
    }

    //Check if there is anything to initialize
    const targetView = runner?.grid?.targetView;
    const viewSettings = editedSeries?.viewSettings;
    const tableSettings = getInitialTableSettingsFormInput({
      editedSeries,
      queryConfig,
    });
    if (!viewSettings && !tableSettings) {
      setIsInitialized(true);
      return;
    }

    //Table form not yet set to the currently edited value
    if (!isEqual(tableSettings, tableSettingsForm?.input)) {
      return;
    }

    //Form not yet set to the currently edited value
    if (typeof viewSettings === "object" && !isEqual(targetView, viewSettings)) {
      return;
    }

    //Dispatches table settings in case the defaults override empty settings
    setIsInitialized(true);
  }, [runner?.grid?.targetView, tableSettingsForm?.input, editedSeries, queryConfig]);

  const { organizationName } = useOrganizationContext();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
        <GridViewTree
          queryConfig={queryConfig}
          targetView={runner?.grid?.targetView}
          onSelectedField={onSelectField}
          prefixTreeItems={[
            <TreeItem
              nodeId={"general-table-settings"}
              label={"Table Settings"}
              collapseIcon={<Icon icon={"simple-line-icons:list"} />}
              endIcon={<Icon icon={"simple-line-icons:list"} />}
              onClick={() => setEditedSetting(editSettings.TABLE)}
            />,
          ]}
        />
        {editedSetting !== editSettings.NONE && (
          <div
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "lightGrey",
            }}
          />
        )}
        {editedSetting === editSettings.FIELD && !isNullOrUndefined(editedField) && (
          <div>
            <b>{`${editedField} field settings:`}</b>
            {fieldSettingsForm.display}
          </div>
        )}
        {editedSetting === editSettings.TABLE && (
          <div>
            <b>{"Table settings:"}</b>
            {tableSettingsForm.display}
          </div>
        )}
        <div style={{ maxWidth: "20em", marginTop: "auto" }}>
          <FeatureAlert
            header={"Tip!"}
            text={
              "Press on any column name above to access individual field settings. " +
              "Go back to the table settings by pressing on the first item in the tree."
            }
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "70vh",
          padding: ".5em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {organizationID === "TEMPLATE" && <TemplateQueryAlert />}
        <TableWidgetPreview widget={widget} runner={runner} />
      </div>
    </div>
  );
};

export default withOrganizationCheck(CustomizeTableWidgetVisuals);
