import { useEffect, useState } from "react";

import { useDetails } from "../../../../../../../../hooks/views/useDetails/hooks/useDetails";

import { generateDetailsFieldConfigFromQuery } from "./functions/generateDetailsFieldConfigFromQuery";

/**
 * A useDetails wrapper for showing a has one connection from a nested field in a custom query
 * @param {object} query - the part of custom query object from query builder that defines the nested field
 * @param {object} item - the data for the useDetails UI
 * @return {{display: *}}
 */
export const useCustomQueryNestedFieldDetails = ({ query, item }) => {
  const [fields, setFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const { newFields, newCustomFields } = generateDetailsFieldConfigFromQuery({
      query,
    });
    setFields(newFields);
    setCustomFields(newCustomFields);
  }, [JSON.stringify(query?.fields), JSON.stringify(query?.functions)]);

  const details = useDetails({
    item,
    fields,
    customFields,
    disableRoleChecking: true,
  });

  return details;
};
