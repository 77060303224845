import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { useRoleDataGrid } from "../../Roles/hooks/useRoleDataGrid";
import { cognitoRoleGroups } from "../../Users/functions/cognitoRoleGroups";

/**
 * @description Assign a user to a role
 * @param {string} organizationID - organization id
 * @param {object} user - user to link
 * @param {function} resetFunction - fetch item from the database
 * @returns {object} {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}
 */
export const useAddUserToRole = ({ organizationID, user, resetFunction }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.USER;

  /**
   * Check Permissions for the 'User' update functionality
   */
  const checkRolePermissions = useCheckPermissions({ module, resource });

  const context = useContext(OrganizationContext);
  const pleaseWaitModal = usePleaseWaitModal();

  //Prevents data from being queried again on reopening the modal
  const [dataRetrieved, setDataRetrieved] = useState(false);

  /**
   * Callback function when a role is selected from the Role Grid for a user
   * @param {object} role - the role being selected
   * @returns {Promise<void>}
   */
  const onSelectCallback = async (role) => {
    if (user && role) {
      pleaseWaitModal.setModalIsOpen(true);
      await cognitoRoleGroups({
        type: "assignUsers",
        userIDs: [user?.id],
        role,
      });
      pleaseWaitModal.setModalIsOpen(false);
    }
    resetFunction && resetFunction();
    assignToRole.setModalIsOpen(false);
  };

  const roleGrid = useRoleDataGrid({
    disableEdits: true,
    module,
    resource,
    organizationID,
    queryConfig: {
      query: null,
    },
    gridConfig: {
      enableSelectButton: true,
      onSelectCallback,
    },
  });

  const disableButton = !user || Object.keys(user).length === 0 || !checkRolePermissions.resource.update;

  const assignToRole = useModal(
    "Assign to a role",
    <>
      {roleGrid.display}
      {pleaseWaitModal.modal}
    </>,
    <Button size="sm" color="ghost-primary" className="btn-pill" title="Change the user role" disabled={disableButton}>
      Change
    </Button>,
    {
      width: "50vw",
    },
  );

  /**
   * Queries the list of roles for this Organization to populate the grid with after the modal is open
   */
  useEffect(() => {
    //Do not retrieve data a second time or if the modal is not yet open
    if (dataRetrieved || assignToRole?.modalIsOpen !== true) {
      return;
    }

    if (roleGrid && context?.role) {
      const precedence = context?.role?.precedence;

      const { listQuery } = generateGraphql("Role", ["name", "precedence"]);
      ListQuery({
        query: listQuery,
        organizationID,
      }).then((list) => {
        roleGrid?.setData(list.filter((x) => x.precedence >= precedence));
        setDataRetrieved(true);
      });
    }
  }, [assignToRole?.modalIsOpen]);

  return {
    ...assignToRole,
  };
};
