import React, { useEffect } from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { hasModuleAccess } from "@rivial-security/role-utils";

import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { moduleDictionary } from "../../../../../../utils/Dictionaries/moduleDictionary";
import AccordionCard from "../../../../../../utils/GenericComponents/AccordionCard/components/AccordionCard";
import HighlightedText from "../../../../../../utils/GenericComponents/HighlightedText";

import ResourceOperationSwitch from "./ResourceOperationSwitch";
import ResourceSections from "./ResourceSections";

/**
 * Displays a section for a particular module and it's resources
 *
 * @param {string} module - the module enum string
 * @param {object} roleConfig - the roleConfig object from the database
 * @param {function} handleModuleChange - handler for enabled or disabling a module
 * @param {function} handleResourceChange - handler for enabled or disabling certain operations for a resource
 * @param {number} precedence - current precedence of the role
 * @param {string} searchValue - current search string used for highlighting
 * @param {boolean} disableEdits - whether this section is disabled for editing
 * @constructor
 */
const ModuleSection = ({
  module,
  roleConfig = {},
  handleModuleChange,
  handleResourceChange,
  precedence,
  searchValue,
  disableEdits,
}) => {
  const handleChange = () => {
    if (typeof handleModuleChange === "function") {
      handleModuleChange(module, !isEnabled);
    }
  };

  const [isEnabled, setIsEnabled] = useBoolean();

  useEffect(() => {
    if (module && typeof roleConfig === "object" && Object.keys(roleConfig).length !== 0) {
      try {
        setIsEnabled(hasModuleAccess({ module, roleConfig }));
      } catch (e) {
        //
      }
    }
  }, [module, roleConfig]);

  const moduleTitle = convertCamelCaseToSentence(moduleDictionary(module));

  const title = (
    <span style={{ marginBottom: "-1em" }}>
      <span style={{ marginLeft: "4px" }} onClick={(e) => e.stopPropagation()}>
        <ResourceOperationSwitch
          module={module}
          disableEdits={disableEdits}
          checked={isEnabled}
          handleChange={handleChange}
        />
      </span>
      <HighlightedText text={moduleTitle} highlight={searchValue} style={{ color: "#ff5151" }} />
    </span>
  );

  return (
    <AccordionCard title={title} disabledSummary={!isEnabled}>
      <ResourceSections
        moduleDisabled={!isEnabled}
        disableEdits={disableEdits}
        searchValue={searchValue}
        roleConfig={roleConfig}
        handleResourceChange={handleResourceChange}
        precedence={precedence}
        module={module}
      />
    </AccordionCard>
  );
};

export default ModuleSection;
