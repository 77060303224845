"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareMonteCarloResults = void 0;
/**
 * Compares two monte carlo result objects and returns the difference in inherentRisk, residualRisk, and ROI
 * @param {monteCarloCalculationResult} oldMonteCarloResults
 * @param {monteCarloCalculationResult} newMonteCarloResults
 */
const compareMonteCarloResults = (oldMonteCarloResults, newMonteCarloResults) => {
    const oldInherentLoss = oldMonteCarloResults.systemStats.inherentRisk;
    const newInherentLoss = newMonteCarloResults.systemStats.inherentRisk;
    const oldResidualLoss = oldMonteCarloResults.systemStats.residualRisk;
    const newResidualLoss = newMonteCarloResults.systemStats.residualRisk;
    const oldRoi = oldMonteCarloResults.systemStats.currentROI;
    const newRoi = newMonteCarloResults.systemStats.currentROI;
    return {
        id: oldMonteCarloResults.systemID,
        changeInInherentRisk: newInherentLoss - oldInherentLoss,
        changeInResidualRisk: newResidualLoss - oldResidualLoss,
        changeInROI: newRoi - oldRoi,
    };
};
exports.compareMonteCarloResults = compareMonteCarloResults;
