import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useSystemRecommendationsGrid } from "../hooks/useSystemRecommendationsGrid";

/**
 * Component instance of the useSystemRecommendationsGrid hook
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemRecommendationsGrid = (props) => {
  return useSystemRecommendationsGrid(props).gridDisplay;
};

export default withOrganizationCheck(SystemRecommendationsGrid);
