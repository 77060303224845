import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useToolDataGrid } from "../../Tools/Tool/hooks/useToolDataGrid";

import ToolTemplateDetails from "./ToolTemplateDetails";
import ToolTemplateForm from "./ToolTemplateForm";

/**
 * Tool Templates Grid
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the grid
 * @returns {JSX.Element}
 */
const ToolTemplateGrid = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.TOOL,
  disableRoleChecking = false,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  additionalFields = [],
  ...props
}) => {
  const visibleFields = ["name", "description"];
  if (Array.isArray(additionalFields)) {
    additionalFields.forEach((field) => {
      if (field?.name) {
        visibleFields.push(field.name);
      }
    });
  }

  const grid = useToolDataGrid({
    organizationID,
    persistenceUUID: "b9f903a6-3762-4bcd-bde4-123445-tool",
    detailsTitle: "Integration Template Details",
    detailsComponent: <ToolTemplateDetails organizationID={organizationID} module={module} isTemplate={true} />,
    createResourceComponent: <ToolTemplateForm organizationID={organizationID} module={module} />,
    createItemModalHeader: "Create an Integration Template",
    route: null,
    resource,
    module,
    disableRoleChecking,
    isTemplate: true,
    visibleFields,
    additionalFields,
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...props,
  });

  return <div style={{ height: "75vh" }}>{grid.display}</div>;
};

export default ToolTemplateGrid;
