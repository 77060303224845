"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLowerLossAmountWithOverride = void 0;
const checkSystemForRiskOverride_1 = require("./checkSystemForRiskOverride");
const findIndexOfRiskOverride_1 = require("./findIndexOfRiskOverride");
const getLowerLossAmount_1 = require("./getLowerLossAmount");
const isRiskOverrideFieldEnabled_1 = require("./isRiskOverrideFieldEnabled");
const getLowerLossAmountWithOverride = (system, systemRiskLink) => {
    const indexOfRiskOverride = system?.riskOverrides
        ? (0, findIndexOfRiskOverride_1.findIndexOfRiskOverride)(system.riskOverrides, systemRiskLink?.risk)
        : -1;
    // @ts-expect-error Fix me, needs better typing
    const doesOverrideExist = (0, checkSystemForRiskOverride_1.checkSystemForRiskOverride)(system?.riskOverrides, "confidenceIntervalLower") !== false;
    const isOverrideEnabled = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskOverrideFieldEnabled_1.isRiskOverrideFieldEnabled)(system.riskOverrides[indexOfRiskOverride], "confidenceIntervalLower");
    const overrideValue = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        system.riskOverrides[indexOfRiskOverride]?.confidenceIntervalLower;
    let value = 0;
    if (isOverrideEnabled) {
        if (overrideValue && !isNaN(overrideValue)) {
            value = overrideValue;
        }
        else {
            value = 0.0;
        }
    }
    else {
        value = (0, getLowerLossAmount_1.getLowerLossAmount)(system, systemRiskLink);
    }
    return value;
};
exports.getLowerLossAmountWithOverride = getLowerLossAmountWithOverride;
