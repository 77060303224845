"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganizationS3BucketName = exports.getSignedObjectUrl = void 0;
const client_s3_1 = require("@aws-sdk/client-s3");
const s3_request_presigner_1 = require("@aws-sdk/s3-request-presigner");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Gets an authenticated link to download a file from an organization's S3 bucket
 * @param bucketName - name of the S3 bucket
 * @param objectKey - key of the object in the S3 bucket
 * @param callbackFunction - function to call with the signed URL
 * @param organizationID - ID of the organization
 * @param appsyncEndpoint - GQL endpoint for appsync
 * @param env - environment to use
 */
const getSignedObjectUrl = async ({ bucketName, objectKey, callbackFunction, organizationID, appsyncEndpoint, }) => {
    let s3BucketName = "";
    // Get organization's S3 bucket name
    if (bucketName) {
        s3BucketName = bucketName;
    }
    else if (organizationID) {
        const org = await (0, appsync_utils_1.GetQuery)({
            appsyncEndpoint,
            query: exports.getOrganizationS3BucketName,
            variables: { id: organizationID },
        });
        s3BucketName = org.s3BucketName ?? "";
    }
    else {
        throw new Error("[getSignedObjectUrl] Error: No bucket name found");
    }
    console.log("[getSignedObjectUrl] Found S3 Bucket Name: ", s3BucketName);
    // Get the signed URL from the bucket
    const s3 = new client_s3_1.S3({
        region: process.env.AWS_REGION,
    });
    const params = { Bucket: s3BucketName || bucketName, Key: objectKey, Expires: 3600 };
    return await (0, s3_request_presigner_1.getSignedUrl)(s3, new client_s3_1.GetObjectCommand(params))
        .then((url) => {
        callbackFunction?.(url);
        return url;
    })
        .catch((err) => {
        console.error("[getSignedObjectUrl] Error getting signed URL: ", err);
    });
};
exports.getSignedObjectUrl = getSignedObjectUrl;
exports.getOrganizationS3BucketName = `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      s3BucketName
    }
  }
`;
