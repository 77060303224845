"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskAmount_KRI = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Returns the residual risk value from monteCarloResults
 *
 * @param {Object} risk - Required
 * @param {string} risk.name
 * @param {Object} monteCarloResults - Required
 * @param {Object} monteCarloResults.riskStats
 * @param {Object} monteCarloResults.riskStats.name
 * @param {Object} monteCarloResults.riskStats.residualRisk
 * @param {Object} monteCarloResults.riskStats.inherentRisk
 * @param field - "residualRisk" or "inherentRisk"
 * @returns {number}
 */
const getRiskAmount_KRI = ({ risk, monteCarloResults, field = "residualRisk" }) => {
    // For getting the rating from the KRIs instead of the System
    if (!(0, func_utils_1.isNullOrUndefined)(monteCarloResults)) {
        const riskStats = monteCarloResults.riskStats;
        const findRiskStat = riskStats && riskStats.find((item) => item.name === risk.name);
        if (findRiskStat && findRiskStat[field] && !isNaN(findRiskStat[field])) {
            return findRiskStat[field];
        }
        else {
            return 0;
        }
    }
    else {
        (0, func_utils_1.eventLogger)("Could not get Risk Amount for KRI because monteCarloResults is null or undefined.");
    }
};
exports.getRiskAmount_KRI = getRiskAmount_KRI;
