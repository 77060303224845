import React, { useEffect, useState } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import SelectionTiles from "../../../../utils/GenericComponents/SelectionTiles";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { useCustomWidgetDataGrid } from "../../WidgetDataGrid/hooks/useCustomWidgetDataGrid";

/**
 * Displays the selection tiles for the custom widgets
 *
 * @param {string} organizationID - the organization ID
 * @param {function} onSelect - the callback for when a widget is selected
 * @param {string} filteredWidgetType - the type of widget to filter by
 * @returns {JSX.Element}
 * @constructor
 */
const CustomWidgetSelectionTiles = ({ organizationID, onSelect, filteredWidgetType }) => {
  const [customWidgets, setCustomWidgets] = useState([]);

  const customWidgetGrid = useCustomWidgetDataGrid({
    organizationID,
    type: filteredWidgetType,
  });

  // load in the custom widget selection
  useEffect(() => {
    if (Array.isArray(customWidgetGrid?.data)) {
      const allWidgetsTiles = customWidgetGrid.data.map((widget) => ({
        id: widget.id,
        text: widget.name,
        type: widget.type,
        icon: "simple-line-icons:list",
        onSelect,
      }));

      setCustomWidgets(allWidgetsTiles);
    }
  }, [customWidgetGrid?.data]);

  return (
    <DataLoader isLoading={customWidgetGrid.isLoading} isEnoughData={customWidgetGrid.data?.length > 0}>
      <SelectionTiles items={customWidgets} onSelect={onSelect} />
    </DataLoader>
  );
};

export default withOrganizationCheck(CustomWidgetSelectionTiles);
