import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";

import { tryParse } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { getToolByType } from "./getToolByType";

export interface FetchDataLambdaParams {
  query: string;
  queryInput: Record<string, unknown>;
}

interface FetchDataLambdaResponse extends GraphQLResult {
  data: {
    getTool: {
      fetchData: string;
    };
  };
}

export interface FetchDataParams {
  toolType?: string;
  organizationID?: string;
  input?: FetchDataLambdaParams;
}

export type FetchDataResponse<T> = T[] | { statusCode: number } | undefined;

/**
 *  Fetch data though the fetchData lambda using a secret
 * @param toolType - tool type ex: knowBe4
 * @param organizationID - selected organization
 * @param input - input for the fetchData lambda function
 */
export const fetchData = async <T>({
  toolType,
  organizationID,
  input,
}: FetchDataParams): Promise<FetchDataResponse<T>> => {
  if (!toolType) ErrorLogger("Missing toolType in the fetchData function");
  if (!organizationID) ErrorLogger("Missing organizationID in the fetchData function");
  if (!input) ErrorLogger("Missing input in the fetchData function");

  /**
   * Get the Tool id based on the tool type
   */
  const tool = await getToolByType({
    organizationID,
    toolType,
  });

  /**
   * Query to make a fetchData lambda call
   */
  const getTool = `
    query GetTool($id: ID!, $input: AWSJSON) {
      getTool(id: $id) {
        id
        fetchData(input: $input)
      }
    }
  `;

  const response = (await API.graphql(
    graphqlOperation(getTool, {
      id: tool?.id,
      input: JSON.stringify(input),
    }),
  )) as FetchDataLambdaResponse;

  return tryParse(response?.data?.getTool?.fetchData) as FetchDataResponse<T>;
};
