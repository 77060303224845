import { createMetricType } from "../../../Metrics/MetricTypes/functions/createDefaultMetrics";

/**
 * Auto-creates Metric Types based on a Configuration Array Object. Matches the MetricType parameters
 * @param configs[]
 * @param configs[].name
 * @param configs[].description
 * @param configs[].calculationType
 * @param configs[].format
 * @param configs[].sortBy
 * @param configs[].sortDirection
 * @param configs[].url
 * @param configs[].exclusions
 * @param configs[].inclusions
 * @param configs[].jsonPath
 * @param toolID
 * @param organizationID
 * @returns {Promise<void>}
 */
export const autoCreateToolMetricTypes = async ({ configs = [], toolID, organizationID }) => {
  for (const config of configs) {
    await createMetricType({
      name: config.description,
      description: config.description,
      calculationType: config.calculationType || "mostRecent",
      format: config.format || "decimal",
      automation: {
        name: config.name,
        sortBy: config.sortBy,
        sortDirection: config.sortDirection,
        fetchConfig: {
          url: config.url,
        },
        exclusions: config.exclusions,
        inclusions: config.inclusions,
        jsonPath: config.jsonPath,
      },
      organizationID,
      toolID,
    });
  }
};
