"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateRoleConfig = void 0;
/**
 * Checks the general user permissions object to have the required properties
 * @param {object} roleConfig - the roleConfig object containing a single permissions setup in one object
 */
const validateRoleConfig = (roleConfig) => {
    //Check the role config object itself
    if (!roleConfig) {
        return false;
    }
    //Check role config properties to exists
    if (!roleConfig.modules || !roleConfig.resources || !roleConfig.functions) {
        return false;
    }
    //If the object passed above checks return that it is valid
    return true;
};
exports.validateRoleConfig = validateRoleConfig;
