"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleMonteCarloChangeCalculation = void 0;
const createMonteCarloSystemInput_1 = require("../../createMonteCarloSystemInput/createMonteCarloSystemInput");
const monteCarloCalculation_1 = require("../../monteCarloCalculation/monteCarloCalculation");
const compareMonteCarloResults_1 = require("./compareMonteCarloResults");
/**
 * Runs Monte Carlo calculations twice and returns both results
 */
const handleMonteCarloChangeCalculation = async (system, change, organizationID, queryConfig) => {
    // Create the 1st  MonteCarloCalculation input
    console.log("Creating 1st Monte Carlo System Input for: ", system, organizationID);
    // @ts-expect-error Fix me, needs second parameter
    const oldMonteCarloSystemInput = await (0, createMonteCarloSystemInput_1.createMonteCarloSystemInput)({
        system,
        organizationID,
        queryConfig,
    });
    console.log("Successfully Created the 1st MonteCarloSystemInput");
    // Create the 2nd  MonteCarloCalculation input with change
    console.log("Creating 2nd Monte Carlo System Input (with change) for: ", system, organizationID);
    // @ts-expect-error Fix me, needs second parameter
    const newMonteCarloSystemInput = await (0, createMonteCarloSystemInput_1.createMonteCarloSystemInput)({
        system,
        organizationID,
        change,
        queryConfig,
        riskConfig: oldMonteCarloSystemInput.riskConfig,
    });
    console.log("Successfully Created the 2nd MonteCarloSystemInput");
    // Run the 1st calculation
    console.log("Performing 1st Monte Carlo Calculation..");
    const { calculation: oldMonteCarloResult, reusableRandoms } = await (0, monteCarloCalculation_1.monteCarloCalculation)({
        system: { ...oldMonteCarloSystemInput.system },
        lossCurveMaxX: oldMonteCarloSystemInput.system.assetSize * 0.2,
    });
    console.log("Successfully performed 1st Monte Carlo Calculation");
    /**
     * Convert reusableRandoms back to a Map
     */
    const map = new Map(Object.entries(reusableRandoms));
    // Run the 2nd calculation, using the reusable randoms from the first one
    console.log("Performing 2nd Monte Carlo Calculation..");
    const { calculation: newMonteCarloResult } = await (0, monteCarloCalculation_1.monteCarloCalculation)({
        system: { ...newMonteCarloSystemInput.system },
        lossCurveMaxX: newMonteCarloSystemInput.system.assetSize * 0.2,
        reusableRandoms: map,
    });
    console.log("Successfully performed 1st Monte Carlo Calculation");
    /**
     * Compare the Results
     */
    const monteCarloResultsComparison = (0, compareMonteCarloResults_1.compareMonteCarloResults)(oldMonteCarloResult, newMonteCarloResult);
    return {
        oldMonteCarloResult,
        newMonteCarloResult,
        oldMonteCarloSystemInput,
        newMonteCarloSystemInput,
        ...monteCarloResultsComparison,
    };
};
exports.handleMonteCarloChangeCalculation = handleMonteCarloChangeCalculation;
