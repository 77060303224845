import { ListQuery as PackageListQuery } from "@rivial-security/appsync-utils";

/**
 * This is a frontend function invokes the ListQuery function in the appsync-utils npm package
 * and uses this Amplify API scope for authentication
 *
 * @function
 * @async
 * @author Jacob Blazina
 * @param {string} query - The raw graphql query string (the function has graphql-tag built in)
 * @param {string} organizationID - The organization ID to get the items from. Maps to the ownerGroup field
 * @param {object} [filters] - optional filters to AND with the ownerGroup filter
 * @returns {Promise<Array>}
 * @constructor
 */
export const ListQuery = async ({ query, organizationID, filters }) => {
  /**
   * This performs the query logic from the NPM package in the same scope as the frontend project.
   * This utilizes our frontend Auth tokens without exposing them to the NPM package.
   */
  return await PackageListQuery({ query, organizationID, otherFilters: filters });
};
