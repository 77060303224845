import { useEffect } from "react";

import { resources } from "@rivial-security/role-utils";

import { generateNestedGraphql } from "../../../../definitions/functions/queries/generateNestedGraphql";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useNestedItems } from "../../../../hooks/graphql/useNestedItems";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useActionDataGrid } from "../../Actions/hooks/useActionDataGrid";

/**
 * Displays Action items for an Observation
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const ObservationActionItems = ({ item }) => {
  const queryPath = [
    { name: "recommendations", hasMany: true },
    { name: "recommendation", hasMany: false },
    { name: "actionItems", hasMany: true },
    { name: "action", hasMany: false },
  ];

  const { getQuery } = generateNestedGraphql({
    startTypename: resources.OBSERVATION,
    endTypename: resources.ACTION_ITEM,
    path: queryPath,
    fieldContext: fieldContexts.GRID,
  });

  const { resetFunction, isLoading, items } = useNestedItems({
    item,
    field: "recommendations",
    connectionField: "recommendation",
    queryPath,
    deduplicationField: "id",
    query: getQuery,
  });

  useEffect(() => {
    if (Array.isArray(items)) {
      actionItems.setData(items);
    }
  }, [items]);

  const queryConfig = {
    query: null,
    resetFunction,
  };

  const gridConfig = {
    gridHeight: "100%",
    allowPaging: false,
    createResourceComponent: null,
  };

  const actionItems = useActionDataGrid({
    ...queryConfig,
    ...gridConfig,
    isLoading,
  });

  return actionItems.gridDisplay;
};

export default withOrganizationCheck(ObservationActionItems);
