"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropertiesFromObject = void 0;
/**
 * Returns an array corresponding to all root level keys in an object
 * @param obj
 * @param showMetaData
 */
const getPropertiesFromObject = (obj, showMetaData = false) => {
    if (obj === null || obj === undefined || typeof obj !== "object") {
        throw Error("Invalid object input");
    }
    const res = [];
    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            // Only include 'ownerGroup' and 'id' fields if showMetaData is true
            if (property === "ownerGroup" || property === "id") {
                if (showMetaData === true) {
                    res.push(property);
                }
            }
            else {
                res.push(property);
            }
        }
    }
    return res;
};
exports.getPropertiesFromObject = getPropertiesFromObject;
