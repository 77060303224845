"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageDimensionsByFitType = exports.FIT_TYPE = void 0;
/**
 * The scaling methodology applicable to an image inside a container
 * - CONTAIN: The image is scaled evenly (same aspect ratio) to the largest size where both width and height fit inside the container
 */
exports.FIT_TYPE = {
    CONTAIN: "contain",
};
/**
 * Returns the target dimensions of an image based on the fit type and the container dimensions
 * @param {object} image - image object containing {dimensions: {width, height}}
 * @param {number} maxWidth - maximum width of the container
 * @param {number} maxHeight - maximum height of the container
 * @param {string} fitType - the scaling methodology to use
 * @returns {{finalWidth: number, finalHeight: number}}
 */
const getImageDimensionsByFitType = ({ image, maxWidth, maxHeight, fitType = exports.FIT_TYPE.CONTAIN }) => {
    const { width, height } = image?.dimensions || {};
    if (!width || !height) {
        return { finalWidth: 0, finalHeight: 0 };
    }
    //Fallback to contain fitment type
    if (!exports.FIT_TYPE.hasOwnProperty(fitType)) {
        fitType = exports.FIT_TYPE.CONTAIN;
    }
    let finalWidth;
    let finalHeight;
    if (fitType === exports.FIT_TYPE.CONTAIN) {
        const scaleFactorWidth = maxWidth / width;
        const scaleFactorHeight = maxHeight / height;
        const finalScaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
        finalWidth = finalScaleFactor * width;
        finalHeight = finalScaleFactor * height;
    }
    else {
        return { finalWidth: 0, finalHeight: 0 };
    }
    return {
        finalWidth,
        finalHeight,
    };
};
exports.getImageDimensionsByFitType = getImageDimensionsByFitType;
