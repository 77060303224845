import { API, graphqlOperation } from "@aws-amplify/api";

import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

const { deleteMutation: deleteReport } = generateGraphql("Report", ["name"]);

const DeleteReport = (report) => {
  API.graphql(
    graphqlOperation(deleteReport, {
      input: {
        id: report.id,
      },
    }),
  ).then(() => InfoLogger(`Report ${report.name} was Successfully Deleted`));
};

export default DeleteReport;
