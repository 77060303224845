import React from "react";
import { Button, ButtonGroup } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { fieldContexts } from "../../../../../enums/fieldContexts";
import { useSync } from "../../../../../hooks/functional/useSync";
import { useMutation } from "../../../../../hooks/graphql/useMutation/useMutation";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import CustomTooltip from "../../../../../utils/GenericComponents/CustomTooltip";

/**
 * Displays the isDisabled field of a Control.
 *
 * For user intuitiveness, reverses the logic to ask 'Include in Audits?' instead of 'Don't Include in Audits'?
 * @param {string} module=modules.COMPLIANCE - platform module for role checking
 * @param {string} resource=resources.CONTROL - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {object} item - the control that this field is a part of
 * @param {string} fieldContext - the context of the field
 * @param {function} updateItemById - callback on item value change
 * @param {boolean} disabled - if TRUE will
 * @param {function} submitFunction - async ({value, field}) bypasses the built-in submit logic
 * @return {JSX.Element}
 */
const IsDisabled = ({
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,
  item,
  fieldContext,
  updateItemById,
  disabled,
  submitFunction,
}) => {
  const field = "isDisabled";

  const [rSelected, setRSelected] = useSync(item?.isDisabled);

  const { updateMutation: updateControl } = generateGraphql("Control", ["isDisabled"]);

  const updateControlMutationHook = useMutation({
    mutation: updateControl,
    module,
    resource,
    disableRoleChecking,
    field,
  });

  const update = (value) => {
    //Do not update in case the value is already selected
    if (rSelected === value) return;

    //Update the state
    setRSelected(value);

    // If submitFunction is passed, bypass
    if (typeof submitFunction === "function") {
      (async () => await submitFunction({ value, field }))();
      return;
    }

    //Update the database
    updateControlMutationHook
      .editItem({
        id: item.id,
        isDisabled: value,
      })
      .then(async (updatedControl) => {
        updateItemById && updateItemById(updatedControl);
      });
  };

  const isEnabled = !disabled && (updateControlMutationHook.resource.update || disableRoleChecking);

  return (
    <>
      <ButtonGroup size="sm">
        <Button
          className="btn-ghost-primary"
          onClick={() => {
            update(true);
          }}
          active={rSelected === true || rSelected === null || rSelected === undefined}
          size="sm"
          disabled={!isEnabled}
          title={!isEnabled ? `You don't have permission to edit Field` : ""}
        >
          Yes
        </Button>
        <Button
          className="btn-ghost-danger"
          onClick={() => {
            update(false);
          }}
          active={rSelected === false}
          size="sm"
          disabled={!isEnabled}
          title={!isEnabled ? `You don't have permission to edit Field` : ""}
        >
          No
        </Button>
      </ButtonGroup>
      {fieldContext !== fieldContexts.GRID && (
        <span className={"float-right"} style={{ padding: "0.25em" }}>
          <CustomTooltip
            tooltip={
              "Disabled Controls are ignored for calculations, " +
              "audits, and exports. Disabled Controls will also be hidden from Data Grids by default. " +
              "These settings can be changed on the Compliance Config page."
            }
          />
        </span>
      )}
    </>
  );
};

export default withOrganizationCheck(IsDisabled);
