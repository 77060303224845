import { withOrganizationCheck } from "../../Context/withOrganizationCheck";
import { useCreateLabel } from "../hooks/useCreateLabel";

/**
 * A component instance of the useCreateLabel.js hook
 * @param {object} props - all props passed to the hook
 */
const CreateLabel = (props) => {
  return useCreateLabel(props).display;
};

export default withOrganizationCheck(CreateLabel);
