import React from "react";

import RenderBuiltInWidget from "../components/RenderBuiltInWidget";
import RenderCustomWidget from "../components/RenderCustomWidget";
import { BUILT_IN_WIDGETS } from "../constants/BUILT_IN_WIDGETS";

/**
 * Renders the Widget based on the ID. First checks for IDs matching built in widgets to render first,
 * then checks for custom widgets to render dynamically
 *
 * @param {string} itemId - the custom widget ID
 * @param {string} organizationID - the organization ID
 * @param {boolean} autoRender - whether to auto render the widget
 * @param {string} persistenceUUID - the persistence UUID for the widget
 * @param {string} singleViewType - the single view type for the widget, without allowing the user to switch
 */
export const useRenderWidget = ({ itemId, organizationID, autoRender = false, persistenceUUID, singleViewType }) => {
  // first see if this is built in, before we try to get the details
  const builtInWidget = Object.values(BUILT_IN_WIDGETS).find((builtInWidget) => builtInWidget.id === itemId);

  let widget = <div>Loading..</div>;

  // if this is a built-in widget, render it
  if (builtInWidget) {
    widget = <RenderBuiltInWidget itemId={itemId} organizationID={organizationID} />;
  } else {
    widget = (
      <RenderCustomWidget
        itemId={itemId}
        organizationID={organizationID}
        autoRender={autoRender}
        persistenceUUID={persistenceUUID}
        singleViewType={singleViewType}
      />
    );
  }

  const display = <>{widget}</>;

  return {
    widget,
    display,
  };
};
