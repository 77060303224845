"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findWidgetTagInBlock = void 0;
/**
 * Finds a Widget Tag in a Block using regex.
 * @param block
 * @returns {RegExpMatchArray}
 */
const findWidgetTagInBlock = (block) => {
    const blockString = JSON.stringify(block);
    return blockString.match(/<<(.*?)>>/);
};
exports.findWidgetTagInBlock = findWidgetTagInBlock;
