import { handleModuleChange } from "../functions/handleModuleChange";
import { handleResourceChange } from "../functions/handleResourceChange";

/**
 * Utility hook for supplying the state update functions for module and resource changes
 * @param setRoleConfig
 * @param setIsChanged
 * @returns {{handleModuleChange: (function(*=, *=): void), handleResourceChange: (function(*=, *=): void)}}
 */
export const useHandleChanges = ({ setRoleConfig, setIsChanged }) => {
  const resourceChange = (resourceName, operations) => {
    return handleResourceChange(resourceName, operations, setRoleConfig, setIsChanged);
  };

  const moduleChange = (moduleName, enabled) => {
    return handleModuleChange(moduleName, enabled, setRoleConfig, setIsChanged);
  };

  return {
    handleResourceChange: resourceChange,
    handleModuleChange: moduleChange,
  };
};
