import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import CustomTooltip from "../../CustomTooltip";

/**
 * If GenericEditField context has a tooltip param, displays a custom tooltip next to the field
 * @param context - GenericEditFieldContext
 * @param context.tooltip - the tooltip string
 * @returns {JSX.Element}
 */
export const handleTooltip = (context) => {
  if (!isNullOrUndefined(context?.tooltip)) {
    return (
      <span className={"float-right"}>
        <CustomTooltip tooltip={context.tooltip} />
      </span>
    );
  }
};
