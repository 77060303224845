import React, { useContext, useEffect, useState } from "react";

import { isNullOrUndefined, numberWithCommas } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";
import { useMonteCarloSummary } from "../../MonteCarlo/components/useMonteCarloSummary";

/**
 * Displays the Residual Risk for a KRI from a system Monte Carlo calculation
 * @param {object} risk - data about the key risk indicator
 * @param {boolean} printView - if true, the component will render in a print-friendly format (no modal)
 * @returns {JSX.Element}
 */
const ResidualRiskMonteCarlo = ({ risk, printView }) => {
  const monteCarloContext = useContext(MonteCarloContext);

  const [residualRiskMonteCarlo, setResidualRiskMonteCarlo] = useState(0.0);

  useEffect(() => {
    if (monteCarloContext.monteCarloResults && risk) {
      const riskStats = monteCarloContext.monteCarloResults.riskStats;

      const findRiskStat = riskStats && riskStats.find((item) => item.name === risk.name);

      setResidualRiskMonteCarlo(findRiskStat && findRiskStat.residualRisk);
    }
  }, [monteCarloContext.monteCarloResults]);

  const monteCarloResults = useMonteCarloSummary();

  return (
    <>
      <DataLoader
        isLoading={monteCarloContext?.isLoading}
        isEnoughData={!isNullOrUndefined(residualRiskMonteCarlo)}
        dataMessage={"Could not calculate Monte Carlo Residual Risk"}
      >
        {!printView && (
          <strong>
            Residual Risk{" "}
            <i
              className="icon-question"
              title="Click to view Monte Carlo Details"
              onClick={() => monteCarloResults.setModalIsOpen(true)}
              style={{ cursor: "pointer" }}
            />
            {monteCarloResults.modal}
          </strong>
        )}
        <div>${numberWithCommas(residualRiskMonteCarlo && residualRiskMonteCarlo.toFixed(2))}</div>
      </DataLoader>
    </>
  );
};

export default withOrganizationCheck(ResidualRiskMonteCarlo);
