/**
 * Used to subscribe to update, create and delete events on a specific job (by id)
 */
export const onJobUpdateSubscription = /* GraphQL */ `
  subscription OnJobUpdate($id: ID!) {
    onJobUpdate(id: $id) {
      id
      name
      completedAt
      status
      type
      logs
      result
      error
      success
      emails
      source
      sourceID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
