import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import GenericEditFieldV3 from "../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

const ImplementationLevelDate = ({ item, resetFunction }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.IMPLEMENTATION_LEVEL;

  return (
    <GenericEditFieldV3
      module={module}
      resource={resource}
      field={"date"}
      item={item}
      mutation={updateImplementationLevel}
      inputType="date"
      resetFunction={resetFunction}
    />
  );
};

const { updateMutation: updateImplementationLevel } = generateGraphql(
  "ImplementationLevel",
  ["level", "indicator", "frequency", "pointOfContact", "date", "accountable", "consulted", "informed"],
  {
    pointOfContact: `{
      id
      firstName
      lastName
      ownerGroup
    }`,
  },
);

export default ImplementationLevelDate;
