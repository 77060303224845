import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useObservationDetails } from "../hooks/useObservationDetails";

/**
 * Displays the details for a single Observation
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ObservationDetails = (props) => useObservationDetails(props).display;

export default withDetailsPage(ObservationDetails, {
  title: "Observation Details",
});
