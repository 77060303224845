import {
  handleDefaultOperations,
  handleRoleTypes,
  modules,
  resources,
  roleConfigDefault,
} from "@rivial-security/role-utils";

import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { useCreateRole } from "../../../OrganizationManager/Roles/hooks/useCreateRole";

/**
 * Allows a Rivial Admin to create a Role Template that can be used by different organizations
 * @param {string} organizationID - the organization that this template is a part of
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will not perform role checking
 * @param {object} props - other properties to pass down tot the useCreateRole hook
 * @returns {{input: {}, display: JSX.Element}}
 */
export const useRoleTemplateForm = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.ROLE,
  disableRoleChecking = false,
  ...props
}) => {
  /**
   * Override the form submitFunction with lambda call for creating new Role
   * @param {object} input - input object from the form
   * @returns {Promise<Array>}
   */
  const updateInputFunction = (input) => {
    // Filters roleConfig object based on Role Type (precedence)
    const roleConfig_withTypeFilter = handleRoleTypes(roleConfigDefault, input.precedence);

    // Sets the default Resource-level permissions based on Role Type (precedence)
    const roleConfig_withDefaults = handleDefaultOperations(roleConfig_withTypeFilter, input.precedence);

    tryFunction(props?.toggleModal);
    tryFunction(props?.resetFunction);

    return {
      ...input,
      roleConfig: JSON.stringify(roleConfig_withDefaults),
    };
  };

  const formConfig = {
    submitFunction: null,
    updateInputFunction,
    enableToast: false,
    enableTemplates: false,
  };

  return useCreateRole({
    module,
    resource,
    formConfig,
    disableRoleChecking,
    highestPrecedence: 2,
    ...props,
    organizationID,
    submitFunction: null,
  });
};
