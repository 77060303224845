import { API, graphqlOperation } from "@aws-amplify/api";
import { useEffect, useState } from "react";

import { InfoLogger } from "@utils/EventLogger";

import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";

/**
 * A hook to get Subscriptions.
 *
 * @author Anatoli Railean - 12/16/19
 * @param onCreateSubscription {string} is the subscription for listening when a new item was created,
 * this subscription has to be graphql type constant. ex: onCreateControl
 * @param onUpdateSubscription {string} is the subscription for listening when a item was updated,
 * this subscription has to be graphql type constant. ex: onUpdateControl
 * @param onDeleteSubscription {string} is the subscription for listening when a item was deleted,
 * this subscription has to be graphql type constant. ex: onDeleteControl
 * @param onCreate
 * @param onUpdate
 * @param onDelete
 * @param organizationID {string} is the organization id of current selected organization
 * @param listToSubscribe {Array} is a list of items with a specific type and it should match with subscriptions type.
 * 1. Usually you would use this functionality if you have a parent object for example Control and it have a list of Evidences
 * and you want subscribe to the list of Evidences for specific parent Control.
 * 2. Or you can use it without parent object and get any changes for this list.
 * @param parentObject {Object} is the parent object that has subarray list that you want to be subscribed
 * @param parentTypeName {string} is a String of the type name of the parent object. ex: "Control"
 * @param module {string} pass a Module name
 * @param resource {string} pass a Resource name for Module
 * @param disableRoleChecking {boolean} disable role check
 * @param disabled {boolean} if true, disables all subscriptions
 * @returns {object} {{
 *    setOnCreate: type function, its a setter function, use this if you need to reset OnCreate field,
 *    onCreate: type object, you get an item that was created as a result of onCreate subscription,
 *    onDelete: type object, you get an item that was deleted as a result of onDelete subscription, ,
 *    setOnDelete: type function, its a setter function, use this if you need to reset OnDelete field,
 *    listFilteredByParent: type array, get list of filtered objects by the parent object if specified,
 *    list: type array, get an updated list of objects based on subscriptions,
 *    onUpdate: type object, you get an item that was updated as a result of onUpdate subscription,
 *    setOnUpdate: type function, its a setter function, use this if you need to reset OnUpdate field,
 *      }}
 */

export const useSubscription = ({
  onCreateSubscription,
  onUpdateSubscription,
  onDeleteSubscription,
  onCreate: onCreateAlias,
  onUpdate: onUpdateAlias,
  onDelete: onDeleteAlias,
  organizationID,
  listToSubscribe,
  parentObject,
  parentTypeName,
  module,
  resource,
  disableRoleChecking,
  // disabled = false,
}) => {
  // force disable all subscriptions
  const disabled = true;
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const [onCreate, setOnCreate] = useState(null);
  const [onUpdate, setOnUpdate] = useState(null);
  const [onDelete, setOnDelete] = useState(null);
  const [list, setList] = useState(listToSubscribe);
  const [listFilteredByParent, setListFilteredByParent] = useState(listToSubscribe);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      setList(listToSubscribe);
      setListFilteredByParent(listToSubscribe);
    }
  }, [listToSubscribe]);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      const subscriptionResult = API.graphql(graphqlOperation(onCreateSubscription || onCreateAlias)).subscribe({
        next: (data) => {
          const itemOnCreate = data.value.data[Object.keys(data.value.data)[0]];
          setOnCreate(itemOnCreate);
        },
      });
      return function cleanup() {
        subscriptionResult.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      const subscriptionResult = API.graphql(graphqlOperation(onUpdateSubscription || onUpdateAlias)).subscribe({
        next: (data) => {
          const itemOnUpdate = data.value.data[Object.keys(data.value.data)[0]];
          setOnUpdate(itemOnUpdate);
        },
      });
      return function cleanup() {
        subscriptionResult.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      const subscriptionResult = API.graphql(graphqlOperation(onDeleteSubscription || onDeleteAlias)).subscribe({
        next: (data) => {
          const itemOnDelete = data.value.data[Object.keys(data.value.data)[0]];
          setOnDelete(itemOnDelete);
        },
      });
      return function cleanup() {
        subscriptionResult.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      if (onCreate && Array.isArray(list)) {
        if ((onCreate[parentTypeName] && onCreate[parentTypeName].id) === (parentObject && parentObject.id)) {
          const res = listFilteredByParent && listFilteredByParent.find((item) => item.id === onCreate.id);
          if (!res) {
            InfoLogger(`Subscription: Item ${onCreate?.id} was created, adding item to the listFilteredByParent.`);
            setListFilteredByParent([...listFilteredByParent, onCreate]);
          }
        } else {
          const res = list && list.find((item) => item.id === onCreate.id);
          if (!res) {
            InfoLogger(`Subscription: Item ${onCreate?.id} was created, adding item to the list.`);
            setList([...list, onCreate]);
          }
        }
      }
    }
  }, [onCreate]);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      if (onUpdate && Array.isArray(list)) {
        if ((onUpdate[parentTypeName] && onUpdate[parentTypeName].id) === (parentObject && parentObject.id)) {
          const itemIndexInOriginalList =
            listFilteredByParent && listFilteredByParent.findIndex((item) => item.id === onUpdate.id);
          if (itemIndexInOriginalList > -1) {
            InfoLogger(
              `Subscription: Item ${onUpdate && onUpdate.id} was updated, updating item in the listFilteredByParent.`,
            );
            listFilteredByParent[itemIndexInOriginalList] = onUpdate;
            setListFilteredByParent([...listFilteredByParent]);
          }
        } else {
          const itemIndexInOriginalList = list && list.findIndex((item) => item.id === onUpdate.id);
          if (itemIndexInOriginalList > -1) {
            InfoLogger(`Subscription: Item ${onUpdate?.id} was updated, updating item in the list.`);
            list[itemIndexInOriginalList] = {
              ...list[itemIndexInOriginalList],
              ...onUpdate,
            };
            setList([...list]);
          }
        }
      }
    }
  }, [onUpdate]);

  useEffect(() => {
    if (!disabled && checkPermissions.module.isEnabled && checkPermissions.resource.read) {
      if (onDelete && Array.isArray(list)) {
        if ((onDelete[parentTypeName] && onDelete[parentTypeName].id) === (parentObject && parentObject.id)) {
          const itemFoundIndex =
            listFilteredByParent && listFilteredByParent.findIndex((item) => item.id === onDelete.id);
          if (itemFoundIndex > -1) {
            InfoLogger(`Subscription: Item ${onDelete?.id} was deleted, deleting item from the listFilteredByParent.`);
            listFilteredByParent.splice(itemFoundIndex, 1);
            setListFilteredByParent([...listFilteredByParent]);
          }
        } else {
          const itemFoundIndex = list && list.findIndex((item) => item.id === onDelete.id);
          if (itemFoundIndex > -1) {
            InfoLogger(`Subscription: Item ${onDelete?.id} was deleted, deleting item from the list.`);
            list.splice(itemFoundIndex, 1);
            setList([...list]);
          }
        }
      }
    }
  }, [onDelete]);

  return {
    onCreate,
    setOnCreate,
    onUpdate,
    setOnUpdate,
    onDelete,
    setOnDelete,
    list,
    listFilteredByParent,
  };
};
