/**
 * Vulnerability status options, based on the overall statuses of the target links
 * @type {{NOT_FIXED: string, MOSTLY_FIXED: string, FIXED: string, UNKNOWN: string, PARTIALLY_FIXED: string}}
 */
export const VulnerabilityStatus = {
  UNKNOWN: "unknown", // no target link info available
  NOT_FIXED: "notFixed", // no target link has a status of fixed
  PARTIALLY_FIXED: "partiallyFixed", // at least 20% of target links are fixed
  MOSTLY_FIXED: "mostlyFixed", // at least 50% of target links are fixed
  FIXED: "fixed", // all target links have a status of fixed
};
