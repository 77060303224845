import React, { useContext } from "react";

import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";

import { AllowedFileUploadTypes } from "@definitions/constants/allowedFileUploadTypes";

import { useForm } from "../../../../hooks/views/useForm";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { createPolicyVersion } from "../functions/createPolicyVersion";
import { validatePolicyFiles } from "../functions/validatePolicyFiles";

/**
 * Add a Single Policy Version to the database.
 * @param {string} organizationID - currently selected organization
 * @param {string} policyID - the id of the policy to which to add the version
 * @param {function} toggleModal - function to close containing modal if there is any
 * @param {function} resetFunction - function to update parent data when this components mutation is complete
 * @param {number} highestVersionNumber - the version number of the previous highest version policy
 * @return {JSX.Element}
 */
const AddSinglePolicyVersion = ({ organizationID, policyID, toggleModal, resetFunction, highestVersionNumber }) => {
  const { addToast, updateToast } = useContext(UIContext);
  const context = useContext(OrganizationContext);

  const policyVersionForm = useForm({
    //Mutation
    submitFunction: async (input) => {
      const { description, files } = input;

      tryFunction(toggleModal);
      const { validFiles, invalidFiles, validationMessage } = validatePolicyFiles(files);
      if (isNonEmptyArray(invalidFiles)) {
        addToast({
          color: "warning",
          header: validationMessage,
        });
      }

      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          const bucketName = context?.selectedOrganizationObjectMinimal?.s3BucketName;
          await createPolicyVersion({
            policyID,
            description,
            files: validFiles,
            version: !isNullOrUndefined(highestVersionNumber) ? highestVersionNumber + 1 : 1,
            organizationID: organizationID,
            bucketName,
            addToast,
            updateToast,
          });
        },
        inProgressText: `Creating a new Policy Version...`,
        failedText: `Creating a new Policy Version Failed`,
        successText: `Successfully created a new Policy Version`,
        iconColor: `success`,
      });

      tryFunction(resetFunction);
    },

    //Fields
    fieldConfig: {
      description: {
        inputType: "text",
        label: "Version Description",
        defaultValue: null,
        required: true,
      },
      files: {
        inputType: "file",
        label: "Select New Policy Version",
        tooltip: `Allowed file types: ${AllowedFileUploadTypes.join(",")}`,
        required: true,
        fileInputConfig: {
          acceptMultiple: true,
          acceptFileTypes: AllowedFileUploadTypes.join(","),
        },
      },
    },
  });

  return <>{policyVersionForm.display}</>;
};

export default AddSinglePolicyVersion;
