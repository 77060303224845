import React from "react";

import { useSystemDataGrid } from "../../../../views/Risk/Systems/hooks/useSystemDataGrid";

const SystemOwner = React.lazy(() => import("@views/Risk/Systems/customFields/SystemOwner"));
const SystemAdmin = React.lazy(() => import("@views/Risk/Systems/customFields/SystemAdmin"));

export const systemDefinition = {
  routes: {
    grid: "risk/systems",
    details: "risk/systems",
    helpCenter: "information-systems",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:screen-desktop",

  //[COMPONENTS]
  gridHook: useSystemDataGrid,

  //[FIELDS]
  fields: {
    name: {},
    description: {},
    pointOfContact: {
      default: {
        DisplayComponent: SystemOwner,
        nestedFields: `{ id ownerGroup firstName lastName title email }`,
      },
    },
    hosting: {},
    adminPointOfContact: {
      default: {
        DisplayComponent: SystemAdmin,
        nestedFields: `{ id ownerGroup firstName lastName title email }`,
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    inherentRisk: {},
    residualRisk: {},
    riskRating: {},
    greatestEnterpriseRisk: {},
    greatestBusinessRisk: {},
    greatestKeyRiskIndicator: {},
  },

  views: {
    grid: {
      queryFields: [
        "name",
        "description",
        "pointOfContact",
        "hosting",
        "adminPointOfContact",
        "tags",
        "inherentRisk",
        "residualRisk",
        "riskRating",
        "greatestEnterpriseRisk",
        "greatestBusinessRisk",
        "greatestKeyRiskIndicator",
      ],
    },
  },
};
