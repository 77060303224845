import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useSystemVendorReviewsGrid } from "../hooks/useSystemVendorReviewsGrid";

/**
 * @description Component instance of the useSystemVendorReviewsGrid hook
 * @param props
 * @constructor
 */
const SystemVendorReviewsGrid = (props) => {
  return useSystemVendorReviewsGrid(props).display;
};

export default withOrganizationCheck(SystemVendorReviewsGrid);
