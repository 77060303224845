import React from "react";

/**
 * Displays timeline information about a point of contact switching an Evidence to pending validation
 * @param {string} validatedBy - name of the person who switched the evidence to pending validation the evidence
 * @returns {JSX.Element}
 * @constructor
 */
const PendingValidationActivity = ({ validatedBy }) => {
  let finalMessage = "Evidence status marked as 'Pending Validation'";
  if (validatedBy) {
    finalMessage += ` by ${validatedBy}`;
  }

  return <span style={{ fontSize: ".9em" }}>{finalMessage}</span>;
};

export default PendingValidationActivity;
