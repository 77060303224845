import { useWelcomeTour } from "../../../../hooks/views/useWelcomeTour/useWelcomeTour";

/**
 * Hook for running a tour on the Vendor Dashboard
 */

export const useVendorReviewTour = () => {
  const vendorReviewTourSteps = [
    {
      selector: ".modal-content",
      title: "Welcome to the Vendor Review Interface ",
      content: "This interface provides a simple way to work through every step of the Vendor Review Process.",
    },
    {
      selector: "[data-tourid=Vendor-Review-progress-tracker]",
      title: "Workflow Tabs ",
      content:
        "These buttons access the designated page for each step. They are organized in the order most Vendor Reviews are completed. However, there is no restriction to doing it out of order.",
    },
    {
      selector: "#step_1",
      title: "Relevant Documents ",
      content:
        "The first step is where external documents can be uploaded to the Review. This is also where Prequalified Certifications can be applied.",
    },
    {
      selector: "#step_2",
      title: "Evaluate Controls",
      content:
        'This step contains the "In-Place" and "Audited" statuses of each individual Vendor Category and Sub-Control. Each can be changed based on the material being evaluated.',
    },
    {
      selector: "#step_3",
      title: "Set Rating",
      content:
        "This step contains a brief overview of each Control Category based on the previously evaluated Controls. This can be used to set an overall Rating.",
    },
    {
      selector: "#step_4",
      title: "Summary",
      content: "This step is where a Summary paragraph can be generated and edited using data from the previous steps.",
    },
    {
      selector: "#step_5",
      title: "Full Report",
      content: "A Full Vendor Review Report can be generated and edited here.",
    },
    {
      selector: "#step_6",
      title: "Controls Report",
      content: "A Report focusing only on the Evaluated Vendor Controls can be created too.",
    },
    {
      selector: "#step_finalize_review",
      title: "Finalize Review",
      content:
        'The final step is to "Finalize" the Vendor Review. This sets it to Read-Only mode and enables the other modules of the Platform to use the results of the Review. (This step can be undone)',
    },
  ];

  const optionKey = "vendorReviewTour1";

  return useWelcomeTour({ steps: vendorReviewTourSteps, optionKey });
};
