import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";

import { convertCamelCaseToSentence, isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";

import ImportButton from "../../../../utils/GenericComponents/ImportButton";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { useModal } from "../../useModal";
import { usePleaseWaitModal } from "../../usePleaseWaitModal";
import { useWorkflow } from "../../useWorkflow";
import BackButton from "../components/BackButton";
import ColumnCards from "../components/ColumnCards";
import ColumnMappingPreview from "../components/ColumnMappingPreview";
import ContinueButton from "../components/ContinueButton";
import ResultGrids from "../components/ResultGrids";
import { processRow as processRowDefault } from "../functions/processRow";

import { useAutoDetectColumns } from "./useAutoDetectColumns";
import { useColumnMapping } from "./useColumnMapping";
import { useCsvSelector } from "./useCsvSelector";

/**
 * @typedef {object} ImporterField
 * @param {string} name - the name of the field
 * @param {string[]} keywords - an array of strings used to match the CSV header to the Resource Field
 * @param {boolean} removeFromMutation - if true, this field will not be included in the mutation
 * @param {string} field - the name of the field in the Resource
 * @param {boolean} required - if true, this field will be required in the mutation. mutation on a single row will fail
 * @param {IMPORTER_FIELD_FORMATS} format - the custom format of the field
 * @param {object[] | string[]} options - an array of options for the field if using 'dropdown' or 'autocomplete'
 * @param {object[]} connectionItemList - an array of items to be used for the connection
 * @param {function} interpreter - a function that is used to interpret the value of the field
 * @param {string} defaultValue - the default value for the field
 */

/**
 * @typedef {object} CSVImporterParams
 * @param {string} organizationID - the ID of the organization to create the objects in
 * @param {ImporterField[]} fields - field configuration for the importer
 * @param {ImporterField[]} nestedFields - field configuration for nested fields
 * @param {function} [processRow] - the async function for running mutations for every row in the file, after all column mapping
 * @param {boolean} [isAsync] - whether the processRow function is async or not
 * @param {object} [resultGrid] - a useDataGrid or useGrid or useGridCard hook that is used to display resulting objects
 * @param {string} [connectionIDField] - the name of the field that is used to connect the objects to the connection
 * @param {string} [connectionID] - the ID of the connection to connect the objects to
 * @param {string} [typename] - the model schema name of the type of object to create
 * @param {function} [preProcessRow] - a function that is called right before the row input is fed into the mutation
 * @param {boolean} [isLoading] - whether the importer is currently loading
 * @param {boolean} [allowMultiSelect] - TRUE when multiple files can be selected but the file content cannot be edited
 * @param {object} csvSelectorParams - parameters for the CSV selector
 * @param {File[]} [files] - the list of selected CSV files
 * @param {object[]} [startSteps] - the list of steps to start with
 * @param {string[]} [hideSteps] - the list of steps to hide
 * @param {string} [sizeLimitMessage] - the message to display when the file size limit is exceeded
 */

export const ImporterStep = {
  CHOOSE_CSV: "choose_csv",
  VALIDATE_COLUMNS: "validate_columns",
  FINISH_IMPORT: "finish_import",
};

/**
 * A CSV importer that can be used to batch create objects in the database.
 * Includes a UI and logic for handling custom Mapping between CSV Headers and Available Resource Fields
 * Once the mapping is complete, uses the 'processRow' function input to perform the mutations
 *
 * NOTE: if allowMultiSelect is TRUE - the file upload and processing has to be done on the backend (not by the importer)
 *
 * @param {CSVImporterParams} input
 * @returns {{display: ReactElement, files: Files[], scanner: string, columnMap: Record<string, string>}}
 */
export const useCsvImporter = ({
  organizationID,
  fields,
  nestedFields,
  processRow: processRowFunc,
  isAsync = false,
  resultGrid,
  connectionIDField,
  connectionID,
  typename,
  preProcessRow,
  isLoading = false,
  allowMultiSelect = false,
  files,
  startSteps = [],
  hideSteps = [],
  sizeLimitMessage,
}) => {
  // Used to determine which 'processRow' function to use
  const processRow = typeof processRowFunc === "function" ? processRowFunc : processRowDefault;

  // Handles state for mapping CSV headers to available Resource Fields
  const { columnMap, setColumnMapping, resetColumnMapping } = useColumnMapping();

  // Handles state and UI for the File Selector and corresponding Spreadsheet UI
  const { data, currentHeaders, getData, ...csvSelector } = useCsvSelector({
    fields,
    importButtonText: "Continue with Import",
    isLoading,
    allowMultiSelect,
    files,
    sizeLimitMessage,
  });

  // Handles the state for the different steps of the Import Workflow
  let steps = [
    ...(startSteps || []),
    {
      id: ImporterStep.CHOOSE_CSV,
      text: "Select CSV File",
    },
    {
      id: ImporterStep.VALIDATE_COLUMNS,
      text: "Map Columns",
    },
    {
      id: ImporterStep.FINISH_IMPORT,
      text: "Results",
    },
  ];
  steps = steps.filter((step) => !hideSteps.includes(step.id));
  const workflow = useWorkflow({ steps });

  // Handles state and logic for auto-detecting and mapping CSV headers to available Resource fields
  useAutoDetectColumns(fields, currentHeaders, columnMap, setColumnMapping, resetColumnMapping);

  // Saves the import results here to display at the end
  const [results, setResults] = useState([]);

  // Modal displays during data processing
  const pleaseWaitModal = usePleaseWaitModal({
    steps: [{ text: "Processing CSV Import" }],
    confirmationText: "Successfully Imported Resources!",
    enableProgressBar: true,
    autoClose: true,
  });

  // Updates the progress bar max input after the file loads
  useEffect(() => {
    if (Array.isArray(data)) {
      pleaseWaitModal.setTotalProgress(data.length);
    }
  }, [data, pleaseWaitModal]);

  const getAlertText = () => {
    const currentStepID = workflow?.progressTracker?.currentStep?.id;
    if (currentStepID === ImporterStep.CHOOSE_CSV) {
      return "Select a CSV File to upload, or use the built-in editor. Use the 'Download Example CSV' button for a pre-formatted file";
    } else if (currentStepID === ImporterStep.VALIDATE_COLUMNS) {
      return "Map your CSV headers to the available Resource Fields. We've tried to auto-detect the correct mapping based on common CSV headers";
    } else if (currentStepID === ImporterStep.FINISH_IMPORT) {
      return "Import complete! You can view the results below";
    }
  };

  const display = (
    <Grid container spacing={2}>
      <Grid container item lg={12} xs={12} spacing={1} justifyContent="center" alignItems={"center"}>
        <Grid item lg={12} xs={12}>
          {getAlertText() && (
            <Alert severity="info" style={{ marginBottom: "1em" }}>
              {getAlertText()}
            </Alert>
          )}
        </Grid>
        <Grid item lg={2} xs={2}>
          <BackButton workflow={workflow} />
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          {workflow?.progressTracker?.stepper.display}
        </Grid>
        <Grid item lg={2} xs={2}>
          <ContinueButton
            startSteps={startSteps}
            workflow={workflow}
            input={data}
            getData={getData}
            pleaseWaitModal={pleaseWaitModal}
            isAsync={isAsync}
            processRow={processRow}
            preProcessRow={preProcessRow}
            columnMap={columnMap}
            organizationID={organizationID}
            fields={fields}
            nestedFields={nestedFields}
            setResults={setResults}
            connectionIDField={connectionIDField}
            connectionID={connectionID}
            typename={typename}
            allowMultiSelect={allowMultiSelect}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        {startSteps?.map((step) => {
          return (
            <Collapse key={step.id} isOpen={workflow?.progressTracker?.currentStep?.id === step.id}>
              {step.component}
            </Collapse>
          );
        })}
        <Collapse isOpen={workflow?.progressTracker?.currentStep?.id === ImporterStep.CHOOSE_CSV}>
          {csvSelector.display}
        </Collapse>
        <Collapse isOpen={workflow?.progressTracker?.currentStep?.id === ImporterStep.VALIDATE_COLUMNS}>
          <Grid container spacing={2}>
            <Grid item lg={9}>
              <DataLoader isLoading={false} isEnoughData={isNonEmptyArray(data)}>
                {isNonEmptyArray(data) && (
                  <ColumnCards
                    row={data?.[0]}
                    columnMap={columnMap}
                    setColumnMapping={setColumnMapping}
                    fields={fields}
                    headers={currentHeaders}
                  />
                )}
              </DataLoader>
            </Grid>
            <Grid item lg={3}>
              {pleaseWaitModal.modal}
              <ColumnMappingPreview columnMap={columnMap} data={data} />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse isOpen={workflow?.progressTracker?.currentStep?.id === ImporterStep.FINISH_IMPORT}>
          {!isNullOrUndefined(resultGrid) && Array.isArray(results) && (
            <ResultGrids results={results} resultGrid={resultGrid} organizationID={organizationID} />
          )}
        </Collapse>
      </Grid>
    </Grid>
  );

  return {
    ...csvSelector,
    columnMap,
    display,
  };
};

/**
 * Helper component that bundles the CSV importer with a Modal.
 *
 * Doing it this way prevents the CSV importer from being initialized whenever the button is.
 * This makes it so that the CSV importer only initializes when the modal is opened, not when the button is rendered
 *
 * All props forwarded to useCsvImporter hook
 *
 * @param {string} organizationID - the ID of the organization to create the objects in
 * @param {ImporterField[]} fields - field configuration for the importer
 * @param {function} processRow - the async function for running mutations for every row in the file, after all column mapping
 * @param {boolean} isAsync - whether the processRow function is async or not
 * @param {object} resultGrid - a useDataGrid or useGrid or useGridCard hook that is used to display resulting objects
 * @param {string} connectionIDField - the name of the field that is used to connect the objects to the connection
 * @param {string} connectionID - the ID of the connection to connect the objects to
 * @param {string} typename - the model schema name of the type of object to create
 * @param {function} preProcessRow - a function that is called right before the row input is fed into the mutation
 * @param {object} ...props - all other props passed to importer
 * @returns {ReactElement}
 */
export const CsvImporterModalButton = ({
  organizationID,
  fields,
  processRow,
  isAsync,
  resultGrid,
  connectionIDField,
  connectionID,
  typename,
  preProcessRow,
  ...props
}) => {
  const csvImporterModal = useModal(
    typename ? `Import CSV: ${convertCamelCaseToSentence(typename)}` : "Import CSV",
    <Importer
      {...{
        organizationID,
        fields,
        processRow,
        isAsync,
        resultGrid,
        connectionIDField,
        connectionID,
        typename,
        preProcessRow,
        ...props,
      }}
    />,
    <ImportButton />,
    { width: "90vw" },
  );

  return csvImporterModal.modalButton;
};

/**
 * Wraps the hook in a component to work better with conditional rendering
 * @param {object} props
 * @returns {ReactElement}
 */
const Importer = (props) => {
  return useCsvImporter(props).display;
};
