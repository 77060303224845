/**
 * @description Gets menu items for the 'View' menu
 * @param {boolean} enablePersistence - enable persistence functionality for the grid
 * @param {string} persistenceUUID - unique grid identifier in UUID format
 * @return {object} {[]}
 */
export const gridGetViewMenuItems = ({ enablePersistence, persistenceUUID }) => {
  const res = [];

  if (enablePersistence && persistenceUUID) {
    res.push({
      id: "reset_local_storage",
      text: "Reset to Default View",
    });
  }

  return res;
};
