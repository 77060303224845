import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemQuery } from "../../../../../../../utils/Functions/Graphql/ItemQuery";

/**
 * Generates a mapping of Controls to their mapped Evidences
 * @param {string[]} selectedIDs - IDs of selected Controls to generate a mapping for
 * @returns {Promise<object[]>}
 */
export const controlEvidenceMapping = async ({ selectedIDs = [] }) => {
  const { getQuery } = generateGraphql("Control", ["id", "statementNumber", "name", "evidences"], {
    evidences: `(limit: 500)
        {
          items {
            evidence {
              id
              name
              itemNumber
            }
          }
        }
      `,
  });

  const data = [];

  const promises = [];

  for (const controlID of selectedIDs) {
    const fullControlPromise = ItemQuery(getQuery, controlID);

    promises.push(fullControlPromise);
  }

  const promiseResults = await Promise.allSettled(promises);

  for (const result of promiseResults) {
    if (result.status === "fulfilled") {
      const control = result.value;
      const { evidences } = control;
      for (const evidenceLink of evidences.items) {
        const {
          evidence: { id, name, itemNumber },
        } = evidenceLink;
        data.push({
          controlID: control.id,
          controlStatementNumber: control.statementNumber,
          controlName: control.name,
          evidenceID: id,
          evidenceName: name,
          evidenceItemNumber: itemNumber,
        });
      }
    }
  }

  return data;
};
