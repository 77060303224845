"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxAvailability = void 0;
/**
 * Gets the availability value from a system and a rating scale
 * @function
 * @param system
 * @param system.availability - a string representing the Availability rating of a System ["high", "medium", "low"]
 * @param ratingScale - a map of availability rating strings to decimal numbers
 * @returns value - the number corresponding to the selected system availability
 */
const getMaxAvailability = (system, ratingScale) => {
    if (!system) {
        throw Error("Error getting Availability from System. Invalid System Input");
    }
    if (!system.availability) {
        // Default the availability to 'low'
        system.availability = "low";
        console.log("Error getting Availability from System. Invalid System.Availability Input. Setting Availability to low.");
    }
    if (!ratingScale) {
        throw Error("Error getting Availability from System. Invalid 'ratingScale' Input");
    }
    const availability = system?.availability;
    return ratingScale[availability];
};
exports.getMaxAvailability = getMaxAvailability;
