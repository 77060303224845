"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validateRoleConfig_1 = require("../utils/validateRoleConfig");
/**
 * Updates the specified module enabled state in the role config object
 * @param {object} roleConfig - general role object containing user permission settings
 * @param {string} moduleName - the module name to edit in the role config object (will do nothing if module doesn't exists)
 * @param {boolean} [enabled=true] - TRUE if need to enable the module permission and false if disable
 * @return {object} - modified roleConfig object with the requested permissions
 */
const updateModulePermissions = ({ roleConfig = {}, moduleName, enabled = true }) => {
    //If role config is originally invalid return the invalid value
    if (roleConfig === null || roleConfig === undefined) {
        return roleConfig;
    }
    // Doing this so that we don't mutate the passed in object
    const tempConfig = { ...roleConfig };
    //Check arguments
    if (!(0, validateRoleConfig_1.validateRoleConfig)(tempConfig) ||
        moduleName === undefined ||
        moduleName === null ||
        typeof moduleName !== "string" ||
        enabled === undefined ||
        enabled === null ||
        typeof enabled !== "boolean") {
        console.log("Unable to update module permissions due to invalid arguments");
        return tempConfig;
    }
    //Modify the role config object to have requested permissions
    tempConfig.modules[moduleName] = enabled;
    return tempConfig;
};
exports.default = updateModulePermissions;
