import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "../../EventLogger";
import { tagOperators } from "../components/TagFilterOperatorButtonGroup";

import { itemContainsAllTags } from "./itemContainsAllTags";
import { itemContainsAtLeastOneTag } from "./itemContainsAtLeastOneTag";

/**
 * Function handling tag filtering for grids
 * @param {object} grid - grid containing data and ref object with data to filter by tags
 * @param {object[]} unfilteredData - all of the grids data
 * @param {object[]} selectedTags - an array of tag objects that are currently selected (such as in the filter menu)
 * @param {string} tagsOperator - AND, OR, or NOT (controls what logic is used for filtering the items
 */
export const filterItemsBasedOnTagOperator = ({ grid, unfilteredData, selectedTags, tagsOperator }) => {
  if (!isNullOrUndefined(grid?.ref?.dataSource)) {
    if (!isNullOrUndefined(selectedTags) && Array.isArray(selectedTags) && selectedTags.length > 0) {
      switch (tagsOperator) {
        case tagOperators.OR:
          grid.setData(unfilteredData.filter((item) => itemContainsAtLeastOneTag(item, selectedTags)));
          break;
        case tagOperators.AND:
          grid.setData(unfilteredData.filter((item) => itemContainsAllTags(item, selectedTags)));
          break;
        case tagOperators.NOT:
          grid.setData(unfilteredData.filter((item) => !itemContainsAtLeastOneTag(item, selectedTags)));
          break;
        default:
          ErrorLogger(`Unsupported tag operator used - ${tagsOperator}`);
          grid.setData(unfilteredData);
      }
    } else {
      grid.setData(unfilteredData);
    }
  } else {
    grid.setData(unfilteredData);
  }
};
