import React, { useContext, useState } from "react";

import { checkArguments, isNonEmptyArray } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useForm } from "../../../../hooks/views/useForm";
import CustomMultiSelect from "../../../../hooks/views/useForm/components/CustomMultiSelect";
import { UIContext } from "../../../../utils/Context/UIContext";
import ObservationsDataGrid from "../../Observations/components/ObservationsDataGrid";
import TaskDataGrid from "../../Tasks/components/TaskDataGrid";
import ScheduleMeetingAddPointOfContact from "../customFields/ScheduleMeeting/ScheduleMeetingAddPointOfContact";
import { linkItemToMeeting } from "../functions/linkItemToMeeting";
import { scheduleMeeting } from "../functions/scheduleMeeting";

/**
 * @description Schedule a meeting hook
 * @param {string} startTimeProp - meeting start time
 * @param {string} endTimeProp - meeting end time
 * @param {string} organizationID - selected organization
 * @param {function} getNewItem - get new meeting callback function
 * @param {function} callback - callback function
 * @param {function} toggleModal - function to toggle the modal
 * @param {object} inputProp - external input for a new meeting
 * @param {object} props - all other props passed to useForm hook
 * @return {object} {{input: {}, display: *}}
 */
export const useMeetingForm = ({
  startTime: startTimeProp,
  endTime: endTimeProp,
  organizationID,
  getNewItem,
  callback,
  toggleModal,
  fieldConfig = {},
  enableTemplates = true,
  ...props
}) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  const uiContext = useContext(UIContext);

  const [selected, setSelected] = useState({
    observation: [],
    task: [],
  });

  const createSelectionComponent = (typename, gridComponent) => (
    <CustomMultiSelect
      multiSelectConfig={{
        grid: gridComponent,
        typename,
      }}
      onChange={(items) => setSelected((prev) => ({ ...prev, [typename.toLowerCase()]: items }))}
    />
  );

  const taskSelection = createSelectionComponent(resources.TASK, <TaskDataGrid organizationID={organizationID} />);
  const observationSelection = createSelectionComponent(
    resources.OBSERVATION,
    <ObservationsDataGrid organizationID={organizationID} />,
  );

  /**
   * Callback to link items to a meeting
   * @param {string} typename - item typename from the schema that is being linked to the meeting (Task/Observation/etc.)
   * @param {object[]} selectedItems - the selected items (tasks/observations/etc.) to link the meeting to
   * @param {object} meetingItem - the meeting item
   * @return {*[]}
   */
  const linkCallback = async ({ typename, selectedItems, meetingItem }) => {
    try {
      checkArguments(
        { meetingItem, selectedItems, typename },
        {
          meetingItem: { type: "object", validAsNull: false },
          selectedItems: { isArray: true },
          typename: { type: "string", validAsNull: false },
        },
      );
    } catch (e) {
      ErrorLogger(`Error: `, e);
      return [];
    }
    if (typename === resources.OBSERVATION) {
      await linkItemToMeeting({
        meeting: meetingItem,
        selectedItems,
        organizationID,
        typename: resources.OBSERVATION,
      });
    } else if (typename === resources.TASK) {
      await linkItemToMeeting({
        meeting: meetingItem,
        selectedItems,
        organizationID,
        typename: resources.TASK,
      });
    }
  };

  const form = useForm({
    fieldConfig: {
      title: {
        label: "Title",
        required: true,
      },
      description: {
        label: "Description",
      },
      startTime: {
        label: "Start Time",
        defaultValue: startTimeProp ? new Date(startTimeProp) : new Date(),
        inputType: "date",
      },
      endTime: {
        label: "End Time",
        defaultValue: endTimeProp ? new Date(endTimeProp) : new Date(),
        inputType: "date",
      },
      isAllDay: {
        label: "All Day",
        inputType: "switch",
      },
      organizer: {
        label: "Organizer",
      },
      location: {
        label: "Location",
      },
      pointOfContacts: {
        label: "Select participants for this meeting",
        inputType: "custom",
        customConfig: {
          component: <ScheduleMeetingAddPointOfContact organizationID={organizationID} />,
        },
      },
      observations: {
        label: "Link Observations to this meeting",
        tooltip: "Select one or multiple Observations to link this meeting to.",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: observationSelection,
        },
      },
      tasks: {
        label: "Link Tasks to this meeting",
        tooltip: "Select one or multiple Tasks to link this meeting to.",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: taskSelection,
        },
      },

      ...fieldConfig,
    },
    module,
    resource,
    organizationID,
    toggleModal,
    callback,
    submitFunction: async (input) =>
      await scheduleMeeting({
        input,
        getNewItem,
        organizationID,
        uiContext,
      }).then((newMeeting) => {
        if (isNonEmptyArray(selected.observation)) {
          linkCallback({
            typename: resources.OBSERVATION,
            selectedItems: selected.observation,
            meetingItem: newMeeting,
          });
        }
        if (isNonEmptyArray(selected.task)) {
          linkCallback({
            typename: resources.TASK,
            selectedItems: selected.task,
            meetingItem: newMeeting,
          });
        }
      }),
    enableTemplates,
    getNewItem,
    ...props,
  });

  return {
    ...form,
  };
};
