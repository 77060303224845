"use strict";
/**
 * Enums for Tools
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOOL_TYPES = void 0;
exports.TOOL_TYPES = {
    KNOW_BE_4: "knowBe4",
    BLACK_KITE: "blackKite",
    MITRE_ATTACK: "mitreAttack",
    // RAPID7: "rapid7", disabling until implemented
    CUSTOM: "custom",
};
