"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskScores_enterpriseRisks = void 0;
const getRiskScores_enterpriseRisk_1 = require("./getRiskScores_enterpriseRisk");
/**
 * Gets all Enterprise Risk Scores
 * @param system
 * @param riskMapping
 * @param monteCarloResults
 * @param ratingScale
 * @returns {Promise<{residualRisk: number, enterpriseRisks: [], greatestEnterpriseRisk: {}, inherentRisk: number, riskRating: string}>}
 */
const getRiskScores_enterpriseRisks = async ({ system, riskMapping, monteCarloResults, ratingScale }) => {
    let greatestEnterpriseRisk = {};
    const enterpriseRisks = [];
    let residualRisk = 0;
    let inherentRisk = 0;
    let riskRating = "No Risk";
    const ermRiskItems = [
        {
            name: "compliance",
            businessRisks: [],
        },
        {
            name: "reputation",
            businessRisks: [],
        },
        {
            name: "financial",
            businessRisks: [],
        },
        {
            name: "operational",
            businessRisks: [],
        },
        {
            name: "consumerData",
            businessRisks: [],
        },
        {
            name: "strategic",
            businessRisks: [],
        },
        {
            name: "transaction",
            businessRisks: [],
        },
    ];
    for (const enterpriseRiskItem of ermRiskItems) {
        const riskScore_enterpriseRisk = await (0, getRiskScores_enterpriseRisk_1.getRiskScores_enterpriseRisk)({
            system,
            enterpriseRisk: enterpriseRiskItem,
            riskMapping,
            monteCarloResults,
            ratingScale,
        });
        enterpriseRisks.push(riskScore_enterpriseRisk);
    }
    const res = Math.max.apply(Math, enterpriseRisks.map((o) => {
        return o.residualRisk;
    }));
    const obj = enterpriseRisks.find((o) => {
        return o.residualRisk === res;
    });
    if (obj) {
        greatestEnterpriseRisk = obj;
        inherentRisk = obj.inherentRisk;
        residualRisk = obj.residualRisk;
        riskRating = obj.riskRating;
    }
    return {
        greatestEnterpriseRisk,
        enterpriseRisks,
        residualRisk,
        inherentRisk,
        riskRating,
    };
};
exports.getRiskScores_enterpriseRisks = getRiskScores_enterpriseRisks;
