import { Inject } from "@syncfusion/ej2-react-base";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  Crosshair,
  DataLabel,
  Export,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
  Zoom,
} from "@syncfusion/ej2-react-charts";
import React from "react";

import { formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";

import { useGUID } from "../../../../hooks/functional/useGUID";

const AnnualLossChart = ({ lossToleranceExceedance }) => {
  const [id] = useGUID();

  const tooltip = {
    header: "<b>Minimal annual risk from events costing ${point.x} or higher</b>",
    enable: true,
    shared: true,
    format: "<b>${series.name} : ${point.y}</b>",
  };

  const zoomSettings = {
    enableSelectionZooming: true,
    enableScrollbar: true,
    enablePan: true,
  };

  const legendSettings = {
    visible: true,
    position: "Bottom",
  };

  const margin = {
    right: 50,
  };

  const crosshair = {
    enable: true,
  };

  let averagesDelta = 0;
  if (
    lossToleranceExceedance?.residualLossAnnualLossCurveAverage &&
    lossToleranceExceedance?.lossToleranceAnnualLossCurveAverage
  ) {
    averagesDelta =
      lossToleranceExceedance?.residualLossAnnualLossCurveAverage -
      lossToleranceExceedance?.lossToleranceAnnualLossCurveAverage;
  }

  //Average Lines setup
  const stripLines = [];
  if (!isNullOrUndefined(lossToleranceExceedance?.lossToleranceAnnualLossCurveAverage)) {
    stripLines.push({
      start: lossToleranceExceedance?.lossToleranceAnnualLossCurveAverage,
      size: 1,
      sizeType: "Pixel",
      dashArray: "10,5",
      color: "green",
      text: `Average Risk Tolerance Acceptable Risk: ${formattedDollar(
        lossToleranceExceedance?.lossToleranceAnnualLossCurveAverage,
      )}`,
      verticalAlignment: averagesDelta > 0 ? "Start" : "End",
      horizontalAlignment: "End",
      textStyle: {
        marginLeft: "2em",
      },
    });
  }
  if (!isNullOrUndefined(lossToleranceExceedance?.residualLossAnnualLossCurveAverage)) {
    stripLines.push({
      start: lossToleranceExceedance?.residualLossAnnualLossCurveAverage,
      size: 1,
      sizeType: "Pixel",
      dashArray: "10,5",
      color: "red",
      text: `Average Minimal Residual Risk: ${formattedDollar(
        lossToleranceExceedance?.residualLossAnnualLossCurveAverage,
      )}`,
      verticalAlignment: averagesDelta <= 0 ? "Start" : "End",
      horizontalAlignment: "End",
      textStyle: {
        marginLeft: "2em",
      },
    });
  }

  //Create Axes Settings
  const yAxis = {
    title: "Minimal Annual Risk ($)",
    labelFormat: "c0",
    minimum: 0,
    stripLines,
  };

  const xAxis = {
    title: "Event Cost ($)",
    labelFormat: "c0",
  };

  return (
    <ChartComponent
      id={`annual-loss-chart${id}`}
      zoomSettings={zoomSettings}
      legendSettings={legendSettings}
      primaryXAxis={xAxis}
      primaryYAxis={yAxis}
      useGroupingSeparator={true}
      tooltip={tooltip}
      crosshair={crosshair}
      margin={margin}
      selectedDataIndexes={{ series: 0, point: 0 }}
      selectionMode={"Series"}
    >
      <Inject
        services={[
          ColumnSeries,
          Legend,
          Tooltip,
          DataLabel,
          Category,
          StripLine,
          SplineSeries,
          Crosshair,
          Zoom,
          Export,
          LineSeries,
        ]}
      />
      <SeriesCollectionDirective>
        {Array.isArray(lossToleranceExceedance?.lossToleranceAnnualLossCurve) && (
          <SeriesDirective
            name="Risk Tolerance"
            dataSource={lossToleranceExceedance.lossToleranceAnnualLossCurve}
            xName="x"
            yName="y"
            type="Spline"
          />
        )}
        {Array.isArray(lossToleranceExceedance?.residualLossAnnualLossCurve) && (
          <SeriesDirective
            name="Simulated Residual Risk"
            dataSource={lossToleranceExceedance.residualLossAnnualLossCurve}
            xName="x"
            yName="y"
            type="Spline"
          />
        )}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default AnnualLossChart;
