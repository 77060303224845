import React, { useEffect, useState } from "react";

import { isNonEmptyArray, removeObjectFromArray } from "@rivial-security/func-utils";

import DashboardCardSection from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCardSection";
import TagsField from "../../../../../../utils/Tags/customFields/TagsField";
import OpenArticleButton from "../../../../../../views/HelpCenter/components/OpenArticleButton";

/**
 * Custom Bulk Edits component used to select Tags to Add and Tags to Remove.
 * @param {string} organizationID - current org ID, used to query list of tags
 * @param {object} submitFunction - submitFunction from useDataGridEditItems
 * @returns {JSX.Element}
 * @constructor
 */
const DataGridBulkEditTags = ({ organizationID, submitFunction }) => {
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);

  // reformats the tagsToRemove array to be used by the TagsField component (which expects a tagLink object with a tag property)
  const tagsToRemoveItems = tagsToRemove.map((tag) => ({
    ...tag,
    tag: {
      ...tag, // recreates tag link structure for QuickPickTag to work properly
    },
  }));

  // reformats the tagsToAdd array to be used by the TagsField component (which expects a tagLink object with a tag property)
  const tagsToAddItems = tagsToAdd.map((tag) => ({
    ...tag,
    tag: {
      ...tag, // recreates tag link structure for QuickPickTag to work properly
    },
  }));

  /**
   * Automatically call the submitFunction when the tagsToAdd or tagsToRemove arrays change.
   * This will auto-update the Preview Table with the most recent Tags selections.
   */
  useEffect(() => {
    if (isNonEmptyArray(tagsToAdd) || isNonEmptyArray(tagsToRemove)) {
      submitFunction({
        field: "tags",
        value: {
          tagsToAdd,
          tagsToRemove,
        },
      });
    }
  }, [tagsToAdd?.length, tagsToRemove?.length]);

  return (
    <div>
      <OpenArticleButton className={"float-right"} helpCenterRoute={"bulk-editing-tags"} />
      <DashboardCardSection
        title={"Select Tags to Add"}
        tooltip={<p>Select one or more Tags to add to the selected Resources</p>}
        style={{
          paddingTop: "1em",
        }}
      >
        <TagsField
          item={{
            id: "none",
            tags: {
              items: tagsToAddItems,
            },
          }}
          tags={{
            items: tagsToAddItems,
          }}
          organizationID={organizationID}
          disableRoleChecking={true}
          createLinkFunction={(item, tag) => {
            setTagsToAdd((curr) => [...curr, tag]);
          }}
          deleteLinkFunction={(item) => {
            setTagsToAdd((curr) => {
              const newArray = removeObjectFromArray(curr, { ...item, id: item.tagID }, "id");
              return newArray;
            });
            return item;
          }}
        />
      </DashboardCardSection>

      <DashboardCardSection
        title={"Select Tags to Remove"}
        tooltip={<p>Select one or more Tags to remove from the selected Resources</p>}
        style={{
          paddingTop: "1em",
        }}
      >
        <TagsField
          item={{
            id: "none",
            tags: {
              items: tagsToRemoveItems,
            },
          }}
          tags={{
            items: tagsToRemoveItems,
          }}
          organizationID={organizationID}
          disableRoleChecking={true}
          createLinkFunction={(item, tag) => {
            setTagsToRemove((curr) => [...curr, tag]);
          }}
          deleteLinkFunction={(item) => {
            setTagsToRemove((curr) => {
              const newArray = removeObjectFromArray(curr, { ...item, id: item.tagID }, "id");
              return newArray;
            });
            return item;
          }}
        />
      </DashboardCardSection>
    </div>
  );
};

export default DataGridBulkEditTags;
