import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";

/**
 * @description Configurable DatePicker component (used in Generic Edit Field)
 * @param {string} field - the name of the field being displayed (should be present as a property in item)
 * @param {string} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed (pass in the current state of the input)
 * @param {object} [inputConfig={}] - holds settings for the date component
 * @param {boolean} [inputConfig.dateOnly=false] - if true, only the date will be displayed (strips time)
 * @param {Date} [inputConfig.minDate] - the minimum date that can be selected
 * @param {Date} [inputConfig.maxDate] - the maximum date that can be selected
 */
export const useCustomDate = ({ field, value, setValue, inputConfig = {} }) => {
  const id = uuid();

  // If a null value is provided, set it to current time
  useEffect(() => {
    if (!value) {
      setValue(new Date());
    }
  }, []);

  // https://mui.com/x/react-date-pickers/date-time-picker/
  const display = (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {inputConfig?.dateOnly === true ? (
        <DesktopDatePicker
          id={`custom_input_date_${id}`}
          value={value ? new Date(value) : new Date()}
          onChange={setValue}
          minDate={inputConfig?.minDate}
          maxDate={inputConfig?.maxDate}
          renderInput={(params) => <TextField size={"small"} variant={"standard"} {...params} />}
        />
      ) : (
        <DateTimePicker
          id={`custom_input_date_${id}`}
          size={"small"}
          value={value ? new Date(value) : new Date()}
          onChange={setValue}
          minDate={inputConfig?.minDate}
          maxDate={inputConfig?.maxDate}
          renderInput={(params) => <TextField size={"small"} variant={"standard"} {...params} />}
        />
      )}
    </LocalizationProvider>
  );

  return {
    display,
  };
};
