import { ReactElement } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { TooltipIcon } from "@components/Atoms/Tooltip/TooltipIcon";

import IconWarning from "../../../../assets/svg/functional/icon-warning.svg";
import styles from "../GenericEditFieldV3.module.scss";

interface WarningContext {
  warning?: {
    message?: string;
  };
}

/**
 * Displays a custom warning icon with a tooltip when the generic field is supplied with a warning
 */
export const handleWarning = (context: WarningContext): ReactElement | null => {
  const warning = context?.warning;
  const { message } = warning || {};
  if (!isNullOrUndefined(warning)) {
    return (
      <TooltipIcon
        content={message ?? ""}
        slotProps={{
          content: {
            className: styles["generic-edit-field__tooltip"],
          },
        }}
      >
        <IconWarning className={styles["generic-edit-field__warning-icon"]} />
      </TooltipIcon>
    );
  } else {
    return null;
  }
};
