import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

import { routeType } from "../../../../../../definitions/constants/routeTypes";
import { getResourceDisplayName } from "../../../../../../definitions/functions/getResourceDisplayName";
import { getResourceRoute } from "../../../../../../definitions/functions/getResourceRoute";

/**
 * Displays a success message after a User submits a Response
 * @param {string} completionTypename - the typename for the completion resource
 * @param {string} returnTypename - the typename to return to
 * @param {object} item - the item to return to on success
 * @param {boolean} isGuest - if TRUE displays success alert suitable for unauthenticated users
 * @returns {JSX.Element} - the success message
 */
const ResponseSuccess = ({ completionTypename = "Item", returnTypename, item = {}, isGuest = false }) => {
  const returnRouteType = item?.id ? routeType.DETAILS : routeType.GRID;
  const itemDetailsRoute = getResourceRoute({
    typename: returnTypename,
    routeType: returnRouteType,
    prefix: "/",
    suffix: returnRouteType === routeType.GRID ? "" : `${item?.id}`,
  });

  const completionDisplayName = getResourceDisplayName({
    typename: completionTypename,
  });

  const returnDisplayName = getResourceDisplayName({
    typename: returnTypename,
  });

  return (
    <Container
      className="animated fade-in"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5em",
      }}
    >
      <h4>
        {`Great work, you've completed this ${completionDisplayName}!`}
        <p
          style={{
            fontSize: "5em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          👍
        </p>
        {!isGuest && itemDetailsRoute && (
          <Link
            to={itemDetailsRoute}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2em",
            }}
          >
            {`Return to ${returnDisplayName} Details`}
          </Link>
        )}
      </h4>
    </Container>
  );
};

export default ResponseSuccess;
