import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules } from "@rivial-security/role-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { createNote } from "../../../../utils/Notes/functions/createNote";
import { createActionItem } from "../../Actions/functions/createActionItem";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import {
  determineActionItemPointOfContact,
  interpretDecision,
  interpretModule,
  interpretRating,
} from "../../Actions/functions/interpretActions";
import { ACTION_STATUSES } from "../../actionStatuses";
import { createObservation } from "../../Observations/functions/createObservation";
import { createObservationRecommendationLink } from "../../Observations/functions/createObservationRecommendationLink";
import { RECOMMENDATION_RATING } from "../../Recommendations/enums/RECOMMENDATION_RATING";
import { RecommendationStatus } from "../../Recommendations/enums/RecommendationStatus";
import { createRecommendation } from "../../Recommendations/functions/createRecommendation";

/**
 * Custom processing function for CSV importer due to connections
 * @param {object} row - The row of data from the CSV file
 * @param {object} columnMap - The mapping between column names in the CSV and the fields in the database
 * @param {string} organizationID - The ID of the organization
 * @param {object[]} fieldConfigs - The configuration for each field
 * @param {object} nestedFields - The configuration for each nested field
 * @param {string} connectionIDField - The field to use to connect the imported data to the source
 * @param {string} connectionID - The ID of the source to connect the imported data to
 * @returns {Promise<void>}
 */
export const processFindingsRow = async (
  row,
  columnMap,
  organizationID,
  fieldConfigs = [],
  nestedFields = {},
  connectionIDField,
  connectionID,
) => {
  // Action Item input object with Defaults
  const actionItem = {
    module: modules.GOVERNANCE,
    status: ACTION_STATUSES.PROPOSED,
    priority: 0,
  };

  // Recommendation input object with Defaults
  const recommendation = {
    module: modules.GOVERNANCE,
    status: RecommendationStatus.UNRESOLVED,
    rating: RECOMMENDATION_RATING.INFO,
    difficulty: RECOMMENDATION_RATING.INFO,
  };

  // Observation Input object with defaults
  const observation = {
    module: modules.GOVERNANCE,
    risk: RECOMMENDATION_RATING.INFO,
    externalSourceID: !isNullOrUndefined(connectionID) ? connectionID : undefined,
    needsDecision: false,
    status: "open",
  };

  // For each column of the row, map the value to the input objects for mutations
  // Note: row = {csvHeader1: value, csvHeader2: value}
  for (const csvHeader in row) {
    const columnName = columnMap[csvHeader];

    const fieldConfig = fieldConfigs.find((config) => config.name === columnName);

    const value = row[csvHeader];

    switch (columnName) {
      case "Observation Name":
        observation.name = value;
        break;
      case "Observation Description":
        observation.description = value;
        break;
      case "Observation Module":
        observation.module = interpretModule(value) || fieldConfig.defaultValue;
        break;
      case "Observation Is Finding":
        const interpretedIsFinding = fieldConfig.interpreter(value);
        observation.isFinding = !isNullOrUndefined(interpretedIsFinding)
          ? interpretedIsFinding
          : fieldConfig.defaultValue;
        break;
      case "Observation Management Response":
        observation.formalResponse = value;
        break;
      case "Observation Risk Level":
        observation.risk = interpretRating(value) || RECOMMENDATION_RATING.INFO;
        break;
      case "Observation Decision":
        observation.decision = interpretDecision(value) || fieldConfig.defaultValue;
        break;
      case "Observation Notes":
        observation.notes = value ? [createNote(value, "Import Wizard", organizationID)] : [];
        break;
      case "Recommendation Name":
        recommendation.name = value;
        break;
      case "Recommendation Status":
        recommendation.status = fieldConfig.interpreter(value) || fieldConfig.defaultValue;
        break;
      case "Recommendation Module":
        recommendation.module = interpretModule(value) || fieldConfig.defaultValue;
        break;
      case "Recommendation Rating":
        recommendation.rating = fieldConfig.interpreter(value) || fieldConfig.defaultValue;
        break;
      case "Recommendation Difficulty":
        recommendation.difficulty = fieldConfig.interpreter(value) || fieldConfig.defaultValue;
        break;
      case "Recommendation Notes":
        recommendation.notes = value ? [createNote(value, "Import Wizard", organizationID)] : [];
        break;
      case "Action Item Name":
        actionItem.name = value;
        break;
      case "Action Item Description":
        actionItem.description = value;
        break;
      case "Action Item Status":
        actionItem.status = fieldConfig.interpreter(value) || fieldConfig.defaultValue;
        break;
      case "Action Item Module":
        actionItem.module = interpretModule(value) || fieldConfig.defaultValue;
        break;
      case "Action Item Point of Contact":
        await handleActionItemPointOfContact(value, organizationID, actionItem);
        break;
      case "Action Item Due Date":
        actionItem.dueDate = fieldConfig.interpreter(value);
        break;
      case "Action Item Priority":
        const interpretedPriority = fieldConfig.interpreter(value);
        actionItem.priority = !isNullOrUndefined(interpretedPriority) ? interpretedPriority : fieldConfig.defaultValue;
        break;
      case "Action Item Completion Date":
        actionItem.completionDate = fieldConfig.interpreter(value);
        break;
      case "Action Item Notes":
        actionItem.notes = value ? [createNote(value, "Import Wizard", organizationID)] : [];
        break;
      case "Action Item Enable Notifications":
        actionItem.enableNotifications = fieldConfig.interpreter(value) || fieldConfig.defaultValue;
        break;
      default:
        ErrorLogger("Could not find matching column");
    }
  }

  let obs;
  let rec;
  let action;

  // If an observation exists, create it.
  if (observation?.name) {
    InfoLogger("Creating Observation Item..");
    obs = await createObservation({
      ...observation,
      ownerGroup: organizationID,
    });
  }

  if (recommendation?.name) {
    InfoLogger("Creating Recommendation Item..");
    rec = await createRecommendation({
      ...recommendation,
      ownerGroup: organizationID,
    });
    if (rec && obs) {
      InfoLogger("Creating Link between Observation and Recommendation Item");
      await createObservationRecommendationLink(obs, rec);
    }
  }

  if (actionItem?.name) {
    InfoLogger("Creating Action Item..");
    action = await createActionItem({
      status: "proposed",
      priority: 0,
      ...actionItem,
      ownerGroup: organizationID,
    });
    if (action && rec) {
      InfoLogger("Creating Link between Recommendation and Action Item");
      await createRecommendationActionItemLink(rec, action);
    }
  }

  return obs;
};

const handleActionItemPointOfContact = async (value, organizationID, actionItem) => {
  // check for point of contact value
  const pointOfContact = determineActionItemPointOfContact(value);

  // if a point of contact value exists, try to find existing point of contact or create new one.
  if (
    !isNullOrUndefined(pointOfContact) &&
    !isNullOrUndefined(pointOfContact?.firstName) &&
    pointOfContact?.firstName !== ""
  ) {
    const pointOfContacts = await ListQuery({
      query: generateGraphql("PointOfContact", ["firstName", "lastName", "email"]).listQuery,
      organizationID,
    });

    const existingPointOfContact = pointOfContacts.find((item) => {
      if (item.lastName === pointOfContact.lastName && item.firstName === pointOfContact.firstName) {
        return true;
      }

      if (item.email === pointOfContact.email) {
        return true;
      }

      return false;
    });

    if (existingPointOfContact) {
      InfoLogger("Found existing point of contact! ", existingPointOfContact);
      actionItem.genericActionItemPointOfContactId = existingPointOfContact.id;
    }
    // Point of contact doesn't already exist. Create a new one
    else {
      try {
        const newPointOfContact = await ItemMutation(
          generateGraphql("PointOfContact", ["firstName", "lastName", "email"]).createMutation,
          {
            ...pointOfContact,
            ownerGroup: organizationID,
          },
        );

        pointOfContacts.push(newPointOfContact);

        actionItem.genericActionItemPointOfContactId = newPointOfContact.id;
      } catch (e) {
        ErrorLogger("Error creating point of contact! ", e);
      }
    }
  }
};
