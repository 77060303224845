import React from "react";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CreateRecommendation from "../../Recommendations/components/CreateRecommendation";
import RecommendationsDataGrid from "../../Recommendations/components/RecommendationsDataGrid";
import { deleteObservationRecommendationLink } from "../../Recommendations/functions/deleteObservationRecommendationLink";
import { useRecommendationsDataGrid } from "../../Recommendations/hooks/useRecommendationsDataGrid";
import { createObservationRecommendationLink } from "../functions/createObservationRecommendationLink";

/**
 * Displays Recommendations for an Observation
 * @param {object} item - the Observation object to show recommendations for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const ObservationRecommendations = ({ item, organizationID }) => {
  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "recommendations",
    connectionField: "recommendation",
    typename: "RecommendationItem",
    parentTypename: "Observation",
    grid: <RecommendationsDataGrid dashboardDisplay={false} />,
    gridHook: useRecommendationsDataGrid,
    form: <CreateRecommendation />,
    createFunction: createObservationRecommendationLink,
    deleteFunction: deleteObservationRecommendationLink,
    flipCreateParams: true,
  });

  return nestedDataCard.gridDisplay;
};

export default withOrganizationCheck(ObservationRecommendations);
