import React from "react";
import { Button } from "reactstrap";

/**
 * @deprecated use MuiSubmitButton instead
 * @description Displays a green plus icon
 * @param {function} onClick - function to call when the button is clicked
 * @param {object} style - style object to apply to the button
 * @param {string} className - class name to apply to the button
 * @param {string} color - color to apply to the button
 * @param {string} size - size to apply to the button
 * @param {string} iconClassName - class name to apply to the icon
 * @param {boolean} showIcon - whether to show the icon
 * @param {string} buttonText - text to display on the button
 * @param {string} title - title to display on the button
 * @returns {*} - returns a button
 */
const SubmitButton = ({
  onClick = () => {},
  style = {},
  className,
  color = "success",
  size = "sm",
  iconClassName = "icon-check",
  showIcon = true,
  disabled = false,
  buttonText = "Submit",
  title = "Submit",
}) => {
  return (
    <Button
      onClick={() => onClick()}
      style={{ ...style }}
      className={className}
      color={color}
      size={size}
      title={title}
      disabled={disabled}
    >
      {showIcon && <i className={iconClassName} style={buttonText ? { marginRight: "1em" } : undefined} />}
      {buttonText}
    </Button>
  );
};

export default SubmitButton;
