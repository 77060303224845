import { useEventListener } from "../../../functional/useEventListener";
import { useStateEffect } from "../../../functional/useStateEffect";

/**
 * @description Edit grid fields
 * @param {object} ref - reference of the the grid component
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 * @return {object} {{mappedFields: *}}
 */
export const useGridEditFields = ({ ref, fields, rightClick }) => {
  const [mappedFields] = useStateEffect([], [fields], () => {
    const obj = {};
    if (fields && Array.isArray(fields) && fields.length > 0) {
      for (const field of fields) {
        obj[field?.name] = {
          ...field,
        };
      }
    }
    return obj;
  });

  useEventListener("mousedown", (args) => {
    if (rightClick) {
      rightClick.current = args.which === 3;
    }
  });

  return {
    mappedFields,
  };
};
