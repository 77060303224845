/**
 * A tooltip with text explaining what the "Impacts System Risk" column means in KeyRiskIndicatorRiskChanges
 * @return {JSX.Element}
 */
const KRIChangeImpactsSystemRiskTooltip = () => {
  return (
    <div>
      Even though the residual risk of a modified KRI changes, the overall system residual risk is not guaranteed to
      change. This column represents whether the change in residual risk for the KRI impacts the system residual risk.
      <br />
      <br />
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#2E7D32",
              color: "white",
              padding: ".2em",
            }}
          >
            {"Yes"}
          </span>{" "}
          - the entire KRI residual risk change is applied to the system residual risk because this KRI is the highest
          risk KRI for the system before and after the change
        </span>
      </p>
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#D32F2F",
              color: "white",
              padding: ".2em",
            }}
          >
            {"No"}
          </span>{" "}
          - none of the shown KRI residual risk change is applied to the system because this KRI is not the highest risk
          KRI for the system before and after the change
        </span>
      </p>
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#ED6C02",
              color: "white",
              padding: ".2em",
            }}
          >
            {"Partially"}
          </span>{" "}
          - only a portion of the shown KRI residual risk change is applied to the system since this KRI was the highest
          risk KRI either before or after the change but not both
        </span>
      </p>
    </div>
  );
};

export default KRIChangeImpactsSystemRiskTooltip;
