import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateRole } from "../hooks/useCreateRole";

/**
 * Component for creating a new Role.
 * @param props
 * @constructor
 */
const CreateRole = (props) => {
  return useCreateRole(props).display;
};

export default withOrganizationCheck(CreateRole);
