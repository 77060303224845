import { generateGraphql } from "@rivial-security/generategraphql";
import { Job, UpdateJobInput } from "@rivial-security/schema-types";

import { OptionalNullable } from "../../../../typedefs/Utility/mutations";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

export const updateJob = async ({
  id,
  name,
  completedAt,
  status,
  type,
  logs,
  error,
  success,
  emails = [],
  source,
  ownerGroup,
  ...input
}: UpdateJobInput): Promise<OptionalNullable<Job> | null> => {
  if (!id) {
    return null;
  }

  return await ItemMutation(updateMutation, {
    id,
    name,
    completedAt,
    status,
    type,
    logs,
    error,
    success,
    emails,
    source,
    ownerGroup,
    ...input,
  });
};

const { updateMutation } = generateGraphql("Job", [
  "id",
  "name",
  "completedAt",
  "status",
  "type",
  "logs",
  "error",
  "success",
  "emails",
  "source",
  "ownerGroup",
  "createdAt",
  "updatedAt",
]);
