import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import React, { useState } from "react";

import { getIcon } from "../../../Functions/Icon/getIcon";

import DrawerItemHeadline from "./DrawerItemHeadline";

//TODO: put styles in separate file

/**
 * A single option listed in the slide in drawer
 * @param {object} item - drawer item settings see useRivialReportTemplatesMapping.js for an example
 * @return {JSX.Element}
 */
const DrawerItem = ({ item } = {}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const icon = item?.icon ?? getIcon("fa-solid:paper-plane");

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <Button
        sx={{
          textTransform: "none",
          textAlign: "left",
          lineHeight: "1.5em",
          marginBottom: "1em",
          flex: 1,
          justifyContent: "start",
        }}
        variant="text"
        disabled={!item?.onClick}
        onClick={item?.onClick}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            margin: ".5em",
            marginRight: "1em",
          }}
        >
          <div
            style={{
              marginTop: ".2em",
              marginRight: ".5em",
              borderRadius: ".3em",
              backgroundColor: item?.iconBackgroundColor || "rgb(68,138,184)",
              padding: ".15em .35em",
              alignSelf: "flex-start",
              color: "white",
              display: "block",
              fontSize: "1.7em",
              lineHeight: "100%",
            }}
          >
            {icon}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DrawerItemHeadline text={item?.headline} showBadge={item?.showBadge} />
            <span style={{ fontSize: "1em", color: "grey", fontWeight: "400" }}>{item?.subtitle ?? "Description"}</span>
          </div>
        </div>
      </Button>
      {Array.isArray(item?.options) && item.options.length > 0 && (
        <IconButton
          aria-label="more"
          id="long-button"
          size={"small"}
          sx={{ height: "1.5em" }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOptionsOpen((optionsOpen) => !optionsOpen);
            e.stopPropagation();
          }}
        >
          {getIcon("carbon:overflow-menu-vertical")}
        </IconButton>
      )}
      {Array.isArray(item?.options) && item.options.length > 0 && (
        <Menu
          id={"template-menu"}
          variant="menu"
          anchorEl={anchorEl}
          onClose={(e) => {
            setAnchorEl(null);
            setOptionsOpen(false);
          }}
          open={optionsOpen}
        >
          <MenuList>
            {item.options.map((item) => {
              return (
                <MenuItem
                  key={item.label}
                  onClick={() => {
                    item?.onClick && item?.onClick();
                    setOptionsOpen(false);
                    setAnchorEl(null);
                  }}
                >
                  {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
                  <ListItemText>{item?.label}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </div>
  );
};

export default DrawerItem;
