"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RATING_SCALE = void 0;
/*
    The rating scale used for various inputs
 */
exports.RATING_SCALE = {
    HIGH: "high",
    MEDIUM_HIGH: "mediumHigh",
    MEDIUM: "medium",
    LOW_MEDIUM: "lowMedium",
    LOW: "low",
};
