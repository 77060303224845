"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableFieldTransformType = void 0;
/**
 * All the types of transforms that can be applied to a widget table field
 * @type {{LOCALE_DATE: string, PERCENT: string, SENTENCE_CASE: string, DOLLAR: string}}
 */
exports.tableFieldTransformType = {
    DOLLAR: "dollar",
    PERCENT: "percent",
    LOCALE_DATE: "localeDate",
    SENTENCE_CASE: "convertCamelCaseToSentence",
};
