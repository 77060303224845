import parse from "html-react-parser";
import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useRichTextEditor } from "../../../hooks/editors/useRichTextEditor";
import { useGUID } from "../../../hooks/functional/useGUID";
import { QueryGetItem } from "../../../hooks/graphql/useQueryGetItem";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useNotesHamburgerMenu } from "../hooks/useNotesHamburgerMenu";

/**
 * @description Display a Note
 * @param {object} note - The note to display
 * @param {function} updateNote - Function to update the note
 * @param {function} deleteNote - Function to delete the note
 * @param {string} module - The module the note is from
 * @param {string} resource - The resource the note is from
 * @param {boolean} disableRoleChecking - Whether to disable role checking
 * @param {object} useRichEditor - The rich editor
 * @param {string} author - The author of the note
 * @param {string} organizationID - The organization ID
 * @param {boolean} disableEdits - if true will not allow to edit note
 * @param {function} onCreateObservation - callback function when creating an observation from a note
 * @param {JSXElement} observationFormOverride - optional JSX element to override the Observation form
 * @param {string} auditID - for explicitly connecting Observations to an Audit
 * @param {string} evidenceID - for explicitly connecting Observations to an Evidence
 * @param {string} exerciseID - for explicitly connecting Observations to an Exercise
 * @param {string} complianceControlID - for explicitly connecting Observations to a Compliance Control
 * @param {string} riskControlID - for explicitly connecting Observations to a Risk Control
 * @param {string} vulnerabilityID - for explicitly connecting Observations to a Vulnerability
 * @param {string} incidentID - for explicitly connecting Observations to an Incident
 * @param {string} artifactID - for explicitly connecting Observations to an Artifact
 * @param {string} meetingID - for explicitly connecting Observations to a Meeting
 * @returns {JSX.Element}
 */

const Note = ({
  note,
  updateNote,
  deleteNote,
  module,
  resource,
  disableRoleChecking = false,
  author,
  organizationID,
  observationConnectionField,
  observationFormOverride,
  disableEdits = false,
  item,
  onCreateObservation,
  auditID,
  evidenceID,
  exerciseID,
  complianceControlID,
  riskControlID,
  vulnerabilityID,
  incidentID,
  artifactID,
  meetingID,
}) => {
  const [observationWasDeleted, setObservationWasDeleted] = useState(false);
  const parsedContent = useMemo(() => {
    if (note?.content) {
      return parse(note.content);
    } else {
      return <div style={{ fontStyle: "italic", marginBottom: "1em" }}>Blank Note</div>;
    }
  }, [note?.content]);

  useEffect(() => {
    const checkObservationExists = async () => {
      const query = generateGraphql(`Observation`).getQuery;
      const observation = await QueryGetItem({
        query,
        itemId: note?.observationID,
      });
      if (!observation?.id) {
        setObservationWasDeleted(true);
      }
    };
    if (note?.observationID) {
      checkObservationExists();
    }
  }, [note?.observationID]);
  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const [isEditNote, setIsEditNote] = useState(false);

  const [guid] = useGUID();

  const useRichEditor = useRichTextEditor({
    id: `richTextEditor${note?.id || guid}`,
    editorStyle: {
      height: "15em",
    },
  });

  const notesHamburgerMenu = useNotesHamburgerMenu({
    updateNote,
    deleteNote,
    note,
    useRichEditor,
    setIsEditNote,
    checkPermissionsHook,
    disableRoleChecking,
    organizationID,
    module,
    observationConnectionField,
    observationFormOverride,
    observationWasDeleted,
    setObservationWasDeleted,
    item,
    disableEdits,
    onCreateObservation,
    auditID,
    evidenceID,
    exerciseID,
    complianceControlID,
    riskControlID,
    vulnerabilityID,
    incidentID,
    artifactID,
    meetingID,
  });

  const observationExists = !observationWasDeleted && note?.observationID;
  return (
    <div data-testid={`noteui-note-${note?.id}`} style={{ width: "100%" }}>
      {!isEditNote && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            wordWrap: "break-word",
            backgroundColor: "rgb(252, 253, 253)",
            border: "1px solid rgb(200, 206, 211)",
            paddingTop: ".5em",
            paddingBottom: ".5em",
            paddingLeft: ".7em",
            paddingRight: ".7em",
            borderRadius: ".5em",
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 8px 0px",
          }}
        >
          <span>{notesHamburgerMenu.display}</span>

          {parsedContent}

          <>
            <small className="text-muted">
              {note.author} {new Date(note.timeStamp).toLocaleString()}
              {observationExists && notesHamburgerMenu?.observationDetailsModal?.modalButton}
            </small>{" "}
          </>
        </div>
      )}
      {isEditNote ? (
        <div>
          {useRichEditor.display}
          <Button
            size="sm"
            color="ghost-success"
            className="btn-pill float-right"
            title={"Save this Note"}
            onClick={() => {
              const editedNote = cloneDeep(note);

              editedNote["content"] = useRichEditor.getValue();
              editedNote["timeStamp"] = new Date();
              editedNote["author"] = author;

              useRichEditor.setValue("");
              updateNote && updateNote(editedNote);
              setIsEditNote(false);
            }}
          >
            Save
          </Button>
          <Button
            color="ghost-danger"
            className="btn-pill float-right"
            size="sm"
            title={"Cancel this Note"}
            onClick={() => {
              if (window.confirm("Are you sure you want to cancel this note?")) {
                setIsEditNote(false);
                useRichEditor.setValue("");
              }
            }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Note;
