import { withDetailsPage } from "../../utils/Context/withDetailsPage";

import { useReportDetails } from "./hooks/useReportDetails";

/**
 * @description Report Details component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const ReportDetails = (props) => useReportDetails(props).display;

/**
 * NOTE: providing no title as the report details contains a subtitle with the report name and version
 * this should be sufficient to understand the context and also leaves more space for the document editor
 */

export default withDetailsPage(ReportDetails, { title: null });
