import { generateGraphql } from "@rivial-security/generategraphql";

import { deleteItem } from "../../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes as KRI from the database with systemLinks and threatLinks and controlCategory
 *
 * NOTE: ALSO DELETES RISK CONTROL CATEGORY, SUB CONTROLS, AND ALL LINKS
 *
 * Objects to be deleted:
 *
 * - Risk
 *    - ThreatLinks (m-m)
 *    - SystemLinks (m-m)
 *    - Risk Control Category (object)
 *      - SystemLinks (m-m
 *      - RiskControls (object)
 *        - RiskControlChangeLinks (m-m)
 *        - KPIRiskControlLinks (m-m)
 *        - RiskControlEvidenceLink (m-m)
 * @param {string} risk - the KRI to delete
 * @param {string} risk.id - the ID of the KRI to delete
 * @returns {Promise<*>}
 */
export const deleteRisk = async (risk) => {
  const { getQuery } = generateGraphql("Risk", ["__typename", "systems", "threatLinks"], {
    systems: `(limit: 1000) { items { id __typename } }`,
    threatLinks: `(limit: 1000) { items { id __typename } }`,
    controlCategory: `{
      id
      __typename
      systems (limit: 1000) {
        items {
          id
          __typename
        }
      }
      subControls (limit: 1000) {
        items {
          id
          __typename
          evidences (limit: 1000) {
            items {
              id
              __typename
            }
          }
          riskChangeLinks (limit: 1000) {
            items {
              id
              __typename
            }
          }
          keyPerformanceIndicators (limit: 1000) {
            items {
              id
              __typename
            }
          }
        }
      }
    }`,
  });

  return await deleteItem(getQuery, risk);
};
