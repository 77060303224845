/**
 * Validate mac address for a target for GenericEditFieldV3
 */
export const isValidMacAddress = {
  isValidInput: (macAddress) => {
    const macRegex = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
    return macRegex.test(macAddress);
  },
  validInputMessage: "Valid MAC Address",
  invalidInputMessage: "Please, make sure your MAC address is correct. Ex: 3D:F2:C9:A6:B3:4F",
};
