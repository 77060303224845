import { useSystemOverview } from "../../../Risk/Systems/components/SystemAccordion/SystemOverview/hooks/useSystemOverview";

/**
 * A version of SystemOverview for templates, does not include Assigned Points of Contact
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateOverview = (props) => {
  const fields = ["name", "description", "hosting"];
  return useSystemOverview({ fields, ...props }).display;
};

export default SystemTemplateOverview;
