"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceDynamicField = void 0;
const getOrganizationField_1 = require("../queries/getOrganizationField");
/**
 * Handles replacing the string with the dynamic content
 * @param {string} field - the type of field to replace
 * @param {string} organizationID - the organization for which to retrieve a field
 * @returns {Promise<string>} - the dynamic field text
 */
const replaceDynamicField = async ({ field, organizationID }) => {
    //Check arguments
    if (!field) {
        console.log("replaceDynamicField: string is undefined");
        return "";
    }
    //Retrieve the field
    switch (field) {
        case "currentDate":
            return new Date().toLocaleString();
        case "organizationName":
            return await (0, getOrganizationField_1.getOrganizationField)({ field: "name", organizationID });
        case "organizationShortName":
            return await (0, getOrganizationField_1.getOrganizationField)({ field: "shortName", organizationID });
        case "organizationType":
            return await (0, getOrganizationField_1.getOrganizationField)({ field: "orgType", organizationID });
        case "numberOfEmployees":
            return await (0, getOrganizationField_1.getOrganizationField)({ field: "numberOfEmployees", organizationID });
        case "numberOfCustomers":
            return await (0, getOrganizationField_1.getOrganizationField)({ field: "numberOfCustomers", organizationID });
        default:
            return "[Unsupported Field]";
    }
};
exports.replaceDynamicField = replaceDynamicField;
