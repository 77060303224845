"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventLogger = void 0;
const eventLogger = () => {
    const events = [];
    const addEvent = (...msg) => {
        events.push(msg);
        console.log(...msg);
    };
    const addError = (msg, error) => {
        events.push(`${msg} - ${error}`);
        console.error(`${msg} - ${error}`);
    };
    return {
        events,
        logs: events,
        addEvent,
        log: addEvent,
        error: addError,
    };
};
exports.eventLogger = eventLogger;
