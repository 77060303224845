export const vulnerabilityDefinition = {
  routes: {
    grid: "vulnerabilities/vulnerabilities",
    details: "vulnerabilities/vulnerabilities",
    helpCenter: "vulnerabilities",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:fire",
};
