import { getSingleConnectionID } from "./getSingleConnectionID";

/**
 * Used to add proper connectionItem field and value to the input row
 * @param {object} input - input row from the CSV importer to be updated
 * @param {string} connectionItemID - explicitly passed in ID to use for the connection.
 * @param {object[]} connectionItemList - array of potential connection items for the CSV importer dropdown menu.
 * @param {string} connectionItemField - field name to use for the connection
 * @returns {Promise<*&{controlCategoryName: undefined}>}
 */
export const preProcessSingleConnectionRow = async ({
  input,
  connectionItemID,
  connectionItemList,
  connectionItemField,
}) => {
  const result = {
    ...input,
    [connectionItemField]: getSingleConnectionID({
      connectionItemName: input[connectionItemField],
      connectionItemID: connectionItemID,
      connectionItemList: connectionItemList,
    }),
  };

  return result;
};
