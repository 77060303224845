/**
 * Returns the default data grid view settings for a single grid, either with an initial view or not
 * @param {object} initialView - the initial selected view to use for the grid
 * @param {boolean} selectView - whether to select the initial view or not
 * @return {object}
 */
export const getDefaultDataGridViewsData = ({ initialView, selectView = true }) => {
  if (initialView?.id) {
    return {
      selectedViewID: selectView ? initialView?.id : null,
      views: [initialView],
    };
  } else {
    return {
      selectedViewID: null,
      views: [],
    };
  }
};
