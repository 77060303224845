import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function submitTimeout for Login Inactivity Logout Component
 * @param {object} preferences - a hook for getting and setting user preferences
 * @param {string} timeoutDuration - Specify timeout in seconds
 * @param {object} context - Organization context
 * @param {function} addToast - Function for adding a toast
 * @return {object} {Promise<void>}
 */
export const submitTimeout = async ({ preferences, timeoutDuration, context, addToast }) => {
  if (isNullOrUndefined(preferences)) {
    throw new Error(`[submitTimeout.js] Param: "preferences" is null`);
  }

  if (isNullOrUndefined(timeoutDuration)) {
    throw new Error(`[submitTimeout.js] Param: "timeoutDuration" is null`);
  }

  if (isNullOrUndefined(context)) {
    throw new Error(`[submitTimeout.js] Param: "context" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[submitTimeout.js] Param: "addToast" is null`);
  }

  try {
    preferences.setPreference("loginOptions", { timeoutDuration });
  } catch (e) {
    addToast({
      header: `Error! Timeout Duration was NOT changed`,
      icon: "danger",
    });

    throw new Error("[submitTimeout.js] Can not set Preferences");
  }

  try {
    await context.setTimeoutDuration(timeoutDuration);
  } catch (e) {
    addToast({
      header: `Error! Timeout Duration was NOT changed`,
      icon: "danger",
    });

    throw new Error("[submitTimeout.js] Can not set TimeoutDuration");
  }

  addToast({
    header: `Inactivity Logout Timeout was changed to ${timeoutDuration / 60} minutes`,
    icon: "success",
  });
};
