import React from "react";

import { convertCamelCaseToSentence, formattedDollar, formattedPercent } from "@rivial-security/func-utils";

import { emptyPlaceholder, invalidPlaceholder } from "../../Placeholders";
import { GENERIC_FIELD_TYPES } from "../constants/GENERIC_FIELD_TYPES";

/**
 * Displays a plain Text field
 * @param {string} type - the type of the field
 * @param {string} value - the text value to display
 * @param {boolean} forceSentenceCase - if TRUE will attempt to convert the value string into `Sentence Case`
 * @param {function} customFormat - if passed in, formats the value before displaying
 * @returns {JSX.Element}
 * @constructor
 */
const TextDisplay = ({ type = GENERIC_FIELD_TYPES.TEXT, value, forceSentenceCase = false, customFormat }) => {
  //Accept boolean values
  if (value && typeof value === "boolean") {
    value = value.toString();
  }

  const getTextValue = () => {
    if (forceSentenceCase) {
      value = convertCamelCaseToSentence(value);
    }

    if (type === GENERIC_FIELD_TYPES.DOLLAR) {
      value = formattedDollar(value);
    }

    // USED FOR PERCENT INTEGERS
    if (type === GENERIC_FIELD_TYPES.PERCENT) {
      value = formattedPercent(value, 2, true);
    }

    // USED FOR PERCENT DECIMALS
    if (type === GENERIC_FIELD_TYPES.PERCENTAGE) {
      value = formattedPercent(value, 2);
    }

    // USED FOR CUSTOM FORMATTING
    if (typeof customFormat === "function") {
      value = customFormat(value);
    }

    return value;
  };

  return (
    <>
      {(() => {
        if (!value || value === "" || (Array.isArray(value) && value.length === 0)) {
          return emptyPlaceholder;
        }

        if (typeof value === "string" || typeof value === "number" || Array.isArray(value)) {
          return getTextValue();
        }

        return invalidPlaceholder;
      })()}
    </>
  );
};

export default TextDisplay;
