"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRating_KRI = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const formatLossToleranceCurve_1 = require("../../createMonteCarloSystemInput/functions/formatLossToleranceCurve");
const getLossCurveRating_1 = require("./getLossCurveRating");
const getRiskAmount_KRI_1 = require("./getRiskAmount_KRI");
/**
 * Gets the Risk Rating of a KRI based on MonteCarloResults
 *
 * @param {{name: string}} risk - Required
 * @param {string} risk.name
 * @param {MonteCarloCalculationResult} monteCarloResults - Required
 * @param {lossToleranceCurve} monteCarloResults.lossToleranceCurve
 * @param {RatingScale} [ratingScale] - Optional
 * @param {string} field
 * @returns {RatingScale}
 */
const getRating_KRI = ({ risk, monteCarloResults, ratingScale, field = "residualRisk" }) => {
    // get the residual/inherent risk of the KRI
    const loss = (0, getRiskAmount_KRI_1.getRiskAmount_KRI)({ risk, monteCarloResults, field });
    if ((0, func_utils_1.isNullOrUndefined)(monteCarloResults?.lossToleranceCurve)) {
        throw Error("Could not get Risk Rating without loss tolerance curve!");
    }
    // compare the residual/inherent risk to the loss tolerance curve and rating scale
    // convert loss tolerance curve from {amount, probability} to {x, y}
    const lossToleranceCurve = (0, formatLossToleranceCurve_1.formatLossToleranceCurve)(monteCarloResults?.lossToleranceCurve);
    return (0, getLossCurveRating_1.getLossCurveRating)({ ratingScale, lossToleranceCurve, riskAmount: loss });
};
exports.getRating_KRI = getRating_KRI;
