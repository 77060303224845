import { useContext, useEffect, useState } from "react";

import { findObjectInArray, flattenArrayOfObjects, formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { UIContext } from "../../../../utils/Context/UIContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { useCsvImporter } from "../../../CsvImporters/hooks/useCsvImporter";
import { usePointOfContactList } from "../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactList";
import { queryTemplates } from "../../../Templates/functions/queryTemplates";
import { useApplySystemTemplate } from "../../../Templates/SystemTemplates/hooks/useApplySystemTemplate";

const { createMutation: createSystem } = generateGraphql("System");

export const useUploadSystems = ({ organizationID, toggleModal, resetFunction }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const { addToast, updateToast } = useContext(UIContext);

  const [systemTemplates, setSystemTemplates] = useState([]);
  const [pointOfContacts, setPointOfContacts] = useState([]);

  /**
   * Query system templates using the organizationID: "TEMPLATE"
   * Note: Only admins can query system templates
   * TODO: RISC-2907 Refactor to the new importer
   */
  const pointOfContactsList = usePointOfContactList({ organizationID });

  const [systemTemplateList, setSystemTemplateList] = useState([]);
  useSetAsyncData({
    getData: async () => {
      return await queryTemplates({
        resource,
      });
    },
    setData: (systemTemplates) => {
      setSystemTemplateList(systemTemplates);
    },
    dependencies: [],
  });

  const hostingTypes = ["outsourced", "hybrid", "internal"];

  const headerObjects = [
    {
      title: "name",
      type: "text",
    },
    {
      title: "description",
      type: "text",
    },
    {
      title: "systemTemplate",
      type: "dropdown",
      source: systemTemplates,
    },
    {
      title: "systemOwner",
      type: "dropdown",
      source: pointOfContacts,
    },
    {
      title: "hostingType",
      fieldTitle: "hosting",
      type: "dropdown",
      source: hostingTypes,
    },
    {
      title: "systemAdmin",
      type: "dropdown",
      source: pointOfContacts,
    },
  ];

  const systemFields = ["id", "name", "description", "ownerGroup"];
  const nestedSystemFields = {
    pointOfContact: `{
      id
      firstName
      lastName
    }`,
    adminPointOfContact: `{
      id
      firstName
      lastName
    }`,
  };
  const { listQuery } = generateGraphql(
    "System",
    [...systemFields, "hosting", "pointOfContact", "adminPointOfContact"],
    nestedSystemFields,
  );
  const { updateMutation } = generateGraphql("System", systemFields);

  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Information Systems",
    passThroughDuplicateScanner: true,
  });

  //Set original data for duplicates scanner
  const missingSourceFields = ["systemTemplate"];
  useEffect(() => {
    //Create rename function for system owner names
    const systemOwnerField = (system) => {
      if (!system || !system.pointOfContact) {
        return "";
      } else {
        return formattedName({ pointOfContact: system?.pointOfContact });
      }
    };

    const systemAdminField = (system) => {
      if (!system || !system.adminPointOfContact) {
        return "";
      } else {
        return formattedName({ pointOfContact: system?.adminPointOfContact });
      }
    };

    csvImporter.initSourceQuery(listQuery, organizationID, [], missingSourceFields, {
      systemOwner: systemOwnerField,
      systemAdmin: systemAdminField,
    });
  }, []);

  const getPointOfContactId = (name) => {
    const pointsOfContact = pointOfContactsList?.list;
    if (!Array.isArray(pointsOfContact)) {
      return undefined;
    }

    const pointOfContact = pointsOfContact.find((item) => formattedName({ pointOfContact: item }) === name);
    if (pointOfContact && pointOfContact.id) {
      return pointOfContact.id;
    }
    return undefined;
  };

  const createSystemMutation = useMutation({
    module,
    resource,
    mutation: createSystem,
    typename: "Information System",
    disableToast: true,
  });

  const getFieldValue = (object, field) => {
    return object && object[field] ? object[field] : undefined;
  };

  const applySystemTemplateHook = useApplySystemTemplate({
    organizationID,
    disableToast: true,
  });

  const createSystemsFromCsv = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      //Update GUI
      toggleModal?.();
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;
      addToast({
        id: "csv_toast_upload_systems",
        icon: "spinner",
        header: `Information System CSV Upload Started... (${totalOperations} System Operations)`,
      });

      const existingSystems = await ListQuery({
        query: listQuery,
        organizationID,
      });

      //Perform all system updates
      for (const operationItem of operations.update) {
        //Check if item to mutate exists
        const itemFound = existingSystems.some(
          (originalItem) => originalItem.id != null && operationItem.id != null && operationItem.id === originalItem.id,
        );

        if (itemFound) {
          //Updating system owner
          if (operationItem.hasOwnProperty("systemOwner")) {
            operationItem.systemPointOfContactId = getPointOfContactId(getFieldValue(operationItem, "systemOwner"));
            delete operationItem.systemOwner; //not part of correct input
            delete operationItem.pointOfContact; //contains old data and not part of input
          }

          if (operationItem.hasOwnProperty("systemAdmin")) {
            operationItem.systemAdminPointOfContactId = getPointOfContactId(
              getFieldValue(operationItem, "systemAdmin"),
            );
            delete operationItem.systemAdmin; //not part of correct input
            delete operationItem.adminPointOfContact; //contains old data and not part of input
          }

          //TODO: add support to update system template - need to implement delete of systems first

          //deleting object properties not part of input
          delete operationItem.systemTemplate;
          //removing empty enumerator fields
          if (operationItem.hosting === "") {
            delete operationItem.hosting;
          }

          await ItemMutation(updateMutation, operationItem);
        }
      }

      //TODO: correctly perform all system deletions (remove all links)

      //Perform all system additions
      for (const operationItem of operations.add) {
        if (operationItem.name || getFieldValue(operationItem, "systemTemplate")) {
          // set up input
          const input = {};
          input.name = operationItem.name || getFieldValue(operationItem, "systemTemplate");
          input.description = getFieldValue(operationItem, "description");
          input.hosting = getFieldValue(operationItem, "hostingType");
          input.systemPointOfContactId = getPointOfContactId(getFieldValue(operationItem, "systemOwner"));
          input.systemAdminPointOfContactId = getPointOfContactId(getFieldValue(operationItem, "systemAdmin"));
          input.ownerGroup = organizationID;

          // perform mutation
          const existingSystemTemplate = findObjectInArray(
            systemTemplateList,
            { name: getFieldValue(operationItem, "systemTemplate") },
            "name",
          );

          if (existingSystemTemplate) {
            await applySystemTemplateHook.applySystemTemplate(existingSystemTemplate, { ...input });
          } else {
            await createSystemMutation.createItem({ ...input }).then(async (newSystem) => {});
          }
        } else {
          ErrorLogger("Error: Systems need a name!");
        }
      }

      resetFunction?.();
    }
  };

  // Format the Dynamic Dropdown inputs
  useEffect(() => {
    if (systemTemplateList) {
      setSystemTemplates([...flattenArrayOfObjects(systemTemplateList, "name")]);
    }

    const pointsOfContact = pointOfContactsList?.list;
    if (Array.isArray(pointsOfContact)) {
      const firstNames = flattenArrayOfObjects(pointsOfContact, "firstName");
      const lastNames = flattenArrayOfObjects(pointsOfContact, "lastName");

      const temp = [];
      for (let i = 0; i < firstNames.length; i++) {
        temp.push(`${firstNames[i] ? firstNames[i] : ""} ${lastNames[i] ? lastNames[i] : ""}`);
      }
      setPointOfContacts([...temp]);
    }

    csvImporter.reset();
  }, [pointOfContactsList?.list, systemTemplateList]);

  // Perform the creations

  useEffect(() => {
    if (csvImporter.dataJSONReady) {
      createSystemsFromCsv(csvImporter.dataJSONReady).then(() => {
        updateToast({
          id: "csv_toast_upload_systems",
          icon: "success",
          header: `Information System CSV Upload is complete!`,
        });
      });
    }
  }, [csvImporter.dataJSONReady]);

  return {
    ...csvImporter,
  };
};
