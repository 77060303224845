import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { updateMutation } from "../graphql/customDashboardGraphql";

import { getCurrentDashboard } from "./getCurrentDashboard";
import { getCurrentDashboardLayout } from "./getCurrentDashboardLayout";

/**
 * Removes a widget from a page based on dashboardID, elementID, and layoutIndex
 *
 * @param {string} dashboardID - the dashboardID of the dashboard to remove the page from
 * @param {string} elementID - the elementID of the element to remove
 * @param {number} layoutIndex - the index of the layout to remove the element from
 * @param {function} setItemQuery - the setItem function from the useQueryGetItem hook
 * @returns {Promise<void>}
 */
export const removeWidgetFromPage = async ({ dashboardID, elementID, layoutIndex = 0, setItemQuery }) => {
  const currentDashboard = await getCurrentDashboard({ itemId: dashboardID });
  const currentDashboardLayout = getCurrentDashboardLayout({
    currentDashboard,
    layoutIndex,
  });

  const updatedElements = currentDashboardLayout?.elements.filter((element) => element.id !== elementID);

  currentDashboard.layouts[layoutIndex].elements = updatedElements;

  const updatedItem = await ItemMutation(updateMutation, {
    id: dashboardID,
    layouts: currentDashboard.layouts,
  });

  setItemQuery(updatedItem);
};
