import { useCustomDate } from "../hooks/useCustomDate";

/**
 * A component instance of the useCustomDate.js hook
 * @param {string} fieldName - the name of the field being displayed (should be present as a property in item)
 * @param {string} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed (pass in the current sate of the input)
 * @param {object[]} inputConfig - holds settings for the date component
 */
const CustomDate = ({ field, value, setValue, inputConfig }) => {
  return useCustomDate({ field, value, setValue, inputConfig }).display;
};

export default CustomDate;
