import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useFindingsCard } from "../../../Program/Findings/components/FindingsCard/useFindingsCard";

/**
 * Displays findings for an audit
 * @returns {{display: JSX.Element}}
 */
export const useAuditFindings = ({ item, dataCallback, organizationID }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;

  const checkPermissions = useCheckPermissions({ module, resource });

  const findingsCard = useFindingsCard({
    audit: item,
    readOnly: !checkPermissions.resource.update,
    dataCallback,
    organizationID,
    observationHiddenFields: ["externalSource"],
    module,
  });

  return {
    ...findingsCard,
  };
};
