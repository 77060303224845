"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getControlStats = void 0;
/**
 * Gets the aggregated control information from an array of controls.
 * @param controls
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlStats = (controls) => {
    let totalCost = 0;
    let totalEffectiveness = 0;
    let totalRecommendationControlEffectiveness = 0;
    if (controls && controls.length > 0) {
        for (const control of controls) {
            if (control) {
                if (!isNaN(control.costOfControl)) {
                    totalCost += control.costOfControl;
                }
                if (!isNaN(control.controlEffectiveness)) {
                    totalEffectiveness += control.controlEffectiveness;
                }
                if (control.recommendationControlEffectiveness !== undefined &&
                    control.recommendationControlEffectiveness !== null) {
                    totalRecommendationControlEffectiveness += control.recommendationControlEffectiveness;
                }
                else {
                    totalRecommendationControlEffectiveness += control.controlEffectiveness;
                }
            }
        }
    }
    return {
        cost: totalCost,
        effectiveness: totalEffectiveness,
        recommendationEffectiveness: totalRecommendationControlEffectiveness,
    };
};
exports.getControlStats = getControlStats;
