import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const VendorDashboard = React.lazy(() => import("@views/Vendor/VendorDashboard/VendorDashboard"));
const VendorList = React.lazy(() => import("@views/Vendor/Vendor/components/VendorDataGrid"));
const VendorDetails = React.lazy(() => import("@views/Vendor/Vendor/components/VendorDetails"));
const VendorSolutionsList = React.lazy(
  () => import("@views/Vendor/VendorSolutions/components/VendorSolutionsDataGrid"),
);
const VendorSolutionDetailsAccordion = React.lazy(
  () => import("@views/Vendor/VendorSolutions/components/VendorSolutionDetailsAccordion"),
);
const VendorSolutionReviewDetails = React.lazy(
  () => import("@views/Vendor/VendorSolutionReviews/components/VendorSolutionReview"),
);
const VendorSolutionReviewList = React.lazy(
  () => import("@views/Vendor/VendorSolutionReviews/components/VendorReviewDataGrid"),
);
const VendorControlCategoryList = React.lazy(
  () => import("@views/Vendor/VendorControlCategories/components/VendorControlCategoryDataGrid"),
);
const VendorControlCategoryDetails = React.lazy(
  () => import("@views/Vendor/VendorControlCategories/components/VendorControlCategoryDetails"),
);

const VendorSubControlDetails = React.lazy(
  () => import("@views/Vendor/VendorSubControls/components/VendorSubControlDetails"),
);

const VendorCertification = React.lazy(
  () => import("@views/Vendor/VendorCertification/components/VendorCertificationGrid"),
);
const VendorCertificationDetails = React.lazy(
  () => import("@views/Vendor/VendorCertification/components/VendorCertificationDetails"),
);
const VendorReviewImport = React.lazy(() => import("@views/Vendor/Vendor/components/ImportVendors"));
const VendorConfig = React.lazy(() => import("@views/Vendor/VendorConfig/VendorConfig"));

export const vendorNavigation: NavigationModule = {
  moduleName: modules.VENDORS,
  sectionName: NavigationSectionName.SERVICES,
  name: "Vendors",
  path: "/vendors",
  defaultRoutePath: "/dashboard",
  icon: IconName.VENDOR,
  routes: [
    {
      path: "/dashboard",
      name: "Vendor Dashboard",
      component: VendorDashboard,
      navigationBar: {
        name: "Dashboard",
      },
    },
    {
      path: "/vendor",
      name: "Vendors",
      component: VendorList,
      navigationBar: {
        name: "Vendor List",
      },
    },
    {
      path: "/vendor/:id",
      name: "Vendor Details",
      component: VendorDetails,
    },

    {
      path: "/solutions",
      name: "Vendor Solutions",
      component: VendorSolutionsList,
    },
    {
      path: "/solutions/:id",
      name: "Vendor Solution Details",
      component: VendorSolutionDetailsAccordion,
    },
    {
      path: "/reviews",
      name: "Vendor Solution Reviews",
      component: VendorSolutionReviewList,
      navigationBar: {
        name: "Vendor Reviews",
      },
    },
    {
      path: "/reviews/:id",
      name: "Vendor Solution Review Details",
      component: VendorSolutionReviewDetails,
    },

    {
      path: "/controls",
      name: "Vendor Control Categories",
      component: VendorControlCategoryList,
      navigationBar: {
        name: "Vendor Controls",
      },
    },
    {
      path: "/controls/:id",
      name: "Vendor Control Category Details",
      component: VendorControlCategoryDetails,
    },
    {
      path: "/subControls/:id",
      name: "Vendor Sub Control Details",
      component: VendorSubControlDetails,
    },
    {
      path: "/certification/",
      name: "Vendor Certification",
      component: VendorCertification,
      navigationBar: {
        name: "Pre-Qualified Certs",
      },
    },
    {
      path: "/certification/:id",
      name: "Vendor Certification Details",
      component: VendorCertificationDetails,
    },
    {
      path: "/config",
      name: "Vendor Configuration",
      component: VendorConfig,
      navigationBar: {
        name: "Configuration",
      },
    },
    {
      path: "/import_vendor",
      name: "Import Vendor Reviews",
      component: VendorReviewImport,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
