import Grid from "@mui/material/Grid";
import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import CustomDateField from "../../Email/customFields/CustomDateField";
import ActionItemAssociatedResources from "../components/ActionItemAssociatedResources";
import ActionItemDocuments from "../components/ActionItemDocuments";
import ActionItemObservations from "../components/ActionItemObservations";
import ActionItemRecommendations from "../components/ActionItemRecommendations";
import ActionItemCompletionDate from "../customFields/ActionItemCompletionDate";
import ActionItemCustomData from "../customFields/ActionItemCustomData";
import ActionItemEnableNotifications from "../customFields/ActionItemEnableNotifications";
import ActionItemNotes from "../customFields/ActionItemNotes";
import ActionPointOfContact from "../customFields/ActionPointOfContact";
import DueDate from "../customFields/DueDate";
import LandingPageUrl from "../customFields/LandingPageUrl";
import Module from "../customFields/Module";
import Priority from "../customFields/Priority";
import PriorityHistory from "../customFields/PriorityHistory";
import Status from "../customFields/Status";
import { createActionItemTagLink } from "../functions/createActionItemTagLink";

/**
 * Action Item details
 * @param {string} itemId - the id of the action item
 * @param {string} module - module name
 * @param {string} resource - resource name
 * @param {string} organizationID - organization id
 * @param {object} config - config overrides
 */
export const useActionItemDetailsAccordion = ({
  itemId,
  module = modules.GOVERNANCE,
  resource = resources.ACTION_ITEM,
  organizationID,
  ...config
}) => {
  const typename = resources.ACTION_ITEM;
  const route = "#/governance/action_items/";

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });

  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const customFields = [
    {
      field: "priority",
      component: <Priority />,
    },
    {
      field: "priorityHistory",
      component: <PriorityHistory />,
    },
    {
      field: "pointOfContact",
      component: <ActionPointOfContact />,
    },
    {
      field: "status",
      component: <Status />,
    },
    {
      field: "completionDate",
      component: <ActionItemCompletionDate />,
    },
    {
      field: "module",
      component: <Module />,
    },
    {
      field: "dueDate",
      component: <DueDate />,
    },
    {
      field: "landingPageUrl",
      component: <LandingPageUrl />,
    },
    {
      field: "data",
      component: <ActionItemCustomData />,
    },
    {
      field: "createdAt",
      component: <CustomDateField />,
    },
    {
      field: "enableNotifications",
      component: <ActionItemEnableNotifications />,
    },
    {
      field: "notes",
      component: <ActionItemNotes />,
    },
    {
      field: "name",
      inputType: "textarea",
    },
    {
      field: "description",
      inputType: "textarea",
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createActionItemTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];

  const fieldNameDictionary = {
    landingPageUrl: "Landing Page",
    data: "Linked Resource",
  };

  const detailsConfig = {
    fields: displayFields,
    nestedFields,
    customFields,
    fieldNameDictionary,
    updateMutation,
  };

  const configs = {
    route,
    header: "Action Item Details",
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    config: configs,
    module,
    resource,
    route,
    ...config,
  });

  const accordion = useAccordion({
    details: detailsCard,
    items: [
      {
        title: "Observations",
        icon: "icon-eye",
        component: (
          <div style={{ height: "30em" }}>
            <ActionItemObservations item={detailsCard?.item} />
          </div>
        ),
        defaultExpanded: true,
      },
      {
        title: "Recommendations",
        icon: "icon-check",
        defaultExpanded: true,
        component: (
          <div style={{ height: "30em" }}>
            <ActionItemRecommendations item={detailsCard?.item} />
          </div>
        ),
      },
      {
        title: "Documents",
        subTitle: "Documents associated with this Action Item",
        icon: "icon-docs",
        component: (
          <div style={{ height: "30em" }}>
            <ActionItemDocuments item={detailsCard?.item} disableTitle={true} resetFunction={detailsCard?.reset} />
          </div>
        ),
      },
      {
        title: "Associated Resources",
        icon: "icon-link",
        component: (
          <div style={{ height: "30em" }}>
            <ActionItemAssociatedResources item={detailsCard?.item} />
          </div>
        ),
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={detailsCard?.reset}
      headerButtons={[<AutomationsButton key={0} itemId={itemId} typename={"GenericActionItem"} />]}
    >
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"General Information"} style={{ height: "100%" }} icon={"icon-list"}>
              {detailsCard.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Notes"} style={{ height: "100%" }} icon={"icon-note"}>
              <DataLoader isEnoughData={detailsCard?.item}>
                <ActionItemNotes item={detailsCard?.item} disableTitle={true} />
              </DataLoader>
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    updateMutation,
    ...detailsCard,
    display,
  };
};
