import { gql } from "@graphql/types";

export const COMPLIANCE_OVERVIEW_STATISTICS = gql(`
   query complianceStatistics($dashboardID: ID) {
    complianceStatistics(dashboardID: $dashboardID) {
      countAudits
      countArtifacts
      countEvidencesByStatus {
        expired
        pendingValidation
        expiringSoon
        inPlace
      }
      countFrameworksAdded
      controlStatistics {
        countInPlace
        countNotInPlace
        countControls
        compliancePercentage
      }
    }
  }
`);
