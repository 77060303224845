import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import Button from "@mui/material/Button";
import React from "react";

import UploadDocument from "../../../../hooks/views/useDocumentGrid/components/UploadDocument";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Modal button for uploading a document to an exercise
 * @param organizationID
 * @param item
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const UploadExerciseDocumentButton = ({ organizationID, item, resetFunction }) => {
  const modal = useModal(
    "Upload Documents for this Exercise",
    <UploadDocument
      organizationID={organizationID}
      connectionInput={{ exerciseID: item?.id }}
      resetFunction={resetFunction}
    />,
    <Button title={"Upload Documents for this Exercise"}>
      <PublishOutlinedIcon />
    </Button>,
  );

  return modal.modalButton;
};

export default withOrganizationCheck(UploadExerciseDocumentButton);
