export const listFalsePositives = /* GraphQL */ `
  query ListTargetFindingLinks($filter: ModelTargetFindingLinkFilterInput, $limit: Int, $nextToken: String) {
    listTargetFindingLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        falsePositive
        ownerGroup
        finding {
          id
          name
        }
        target {
          id
          ip
        }
      }
      nextToken
    }
  }
`;
