import { Auth } from "@aws-amplify/auth";
import React, { useEffect, useState } from "react";

import { useForm } from "../../../../hooks/views/useForm";
import Dashboard from "../../../GenericComponents/Dashboard";

import MFASetupSMS from "./MFASetupSMS";
import MFASetupTOTP from "./MFASetupTOTP";

/**
 * @description Setup preferred MFA for a user
 * @param {function} changeState - The function to change the sign in state.
 * @returns {JSX.Element}
 * @constructor
 */
const MFASetup = ({ changeState }) => {
  const [selectedMFA, setSelectedMFA] = useState("SMS");
  const [user, setUser] = useState(null);

  /**
   * Get the current user
   */
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      user?.getCachedDeviceKeyAndPassword();
      setUser(user);
    });
  }, []);

  const form = useForm({
    fieldConfig: {
      preferredMFA: {
        inputType: "radio-button-group",
        defaultValue: selectedMFA,
        onChangeFunction: ({ preferredMFA }) => setSelectedMFA(preferredMFA),
        options: [
          {
            label: "SMS",
            value: "SMS",
          },
          {
            label: "Authenticator App",
            value: "TOTP",
          },
        ],
      },
    },

    disableSubmitButton: true,
    disableResetButton: true,
  });

  return (
    <div>
      <Dashboard data-tourid={"setup-mfa-container"} tourId={"setup-mfa-container"}>
        <div>
          {form.display}
          {(() => {
            switch (selectedMFA) {
              case "SMS":
                return <MFASetupSMS changeState={changeState} user={user} />;
              case "TOTP":
                return <MFASetupTOTP changeState={changeState} user={user} />;
              default:
                return null;
            }
          })()}
        </div>
      </Dashboard>
    </div>
  );
};

export default MFASetup;
