import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { Badge } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useGUID } from "../../hooks/functional/useGUID";
import { handleIcon } from "../Functions/Icon/getIcon";

import ResetButton from "./buttons/ResetButton";
import DashboardCard from "./DashboardCard/components/DashboardCard";

/**
 *
 * A DashboardCard that has Tabs at the top to switch between views
 *
 * item = {
 *   icon,
 *   title,
 *   total,
 *   component
 * }
 *
 * @param {object} [item] - optional, used to pass an item directly to the children components
 * @param {object[]} items - the configuration items, see example above
 * @param {JSX.Element} items[].icon - the icon for each tab button
 * @param {string} items[].title - the tab button text
 * @param {number} items[].total - the number totals badge for each button
 * @param {JSX.Element} items[].component - the react component to render in the body based on selected Tab
 * @param {JSX.Element[]} items[].headerButtons - optional header buttons to add to the card when the item is active
 * @param {object} tabProps - optional props to pass to the Tab component
 * @param {object} tabButtonProps - optional props to pass to the Tab Button component
 * @param {function} [resetFunction] - if present, adds a reset button to the header of the card
 * @param {JSX.Element[]} [headerButtons] - if present, adds header buttons to the tab card
 * @param {string} [badgeColor] - if provided will override the dynamic badge color
 * @param {boolean} [tabDisplay] - if TRUE will not place the tabs into a Card container
 * @param {object} sx - optional styling for the tab card
 * @param {function} onTabChange - optional callback function when the tab changes
 * @param {boolean} disableChipColor - if TRUE chip color will be grey
 * @returns {JSX.Element}
 * @constructor
 */
const TabCard = ({
  item,
  items,
  tabProps = {},
  tabButtonProps = {},
  resetFunction,
  headerButtons = [],
  badgeColor,
  disableChipColor,
  tabDisplay,
  sx,
  onTabChange,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (typeof onTabChange === "function") {
      const tabName = items[newValue]?.title;
      onTabChange(tabName);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getHeaderButtons = () => {
    const buttons = [];

    // First, put the Header Buttons associated with the TabCard in general
    if (Array.isArray(headerButtons)) {
      buttons.push(...headerButtons);
    }

    // then, if the selected view has its own header buttons, add those
    if (Array.isArray(items?.[value]?.headerButtons)) {
      buttons.push(...(items?.[value]?.headerButtons ?? {}));
    }

    // finally, if there's a reset function, add a reset button
    if (!isNullOrUndefined(resetFunction)) {
      buttons.push(<ResetButton resetFunction={resetFunction} />);
    }

    return buttons;
  };

  const [guid] = useGUID();

  const tabs = (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        flex: 1,
        ...(sx?.tabContainer || {}),
      }}
    >
      <Tabs
        indicatorColor="primary"
        scrollButtons="auto"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="observation recommendation and action item tabs"
        {...tabProps}
      >
        {items &&
          items.map((item, index) => (
            <TabButton
              key={`tab-button-item${guid}${index}`}
              total={item?.total}
              icon={handleIcon(item?.icon)}
              title={item.title}
              style={{ minWidth: item?.minWidth }}
              badgeColor={disableChipColor ? "grey" : badgeColor}
              disableChipColor={disableChipColor}
              {...a11yProps(index)}
              {...tabButtonProps}
            />
          ))}
      </Tabs>
    </Box>
  );

  const tabsContent = (
    <div style={{ ...(sx?.tabContentContainer || {}) }}>
      {items &&
        items.map((curr, index) => (
          <TabPanel value={value} index={index} key={`tabpanel-${guid}`} style={{ ...(sx?.tabPanels || {}) }}>
            {curr.component &&
              React.cloneElement(curr?.component, {
                item: !isNullOrUndefined(item) ? item : undefined,
                ...(curr?.forcedComponentProps || {}),
              })}
          </TabPanel>
        ))}
    </div>
  );

  if (tabDisplay === true) {
    return (
      <div style={{ ...(sx?.container || {}) }}>
        {tabs}
        {tabsContent}
      </div>
    );
  } else {
    return (
      <DashboardCard headerButtons={getHeaderButtons()} style={{ height: "100%" }} title={tabs}>
        {tabsContent}
      </DashboardCard>
    );
  }
};

/**
 * The Tab button in the top of the card to switch tabs
 * @param {string} title - the tab button text
 * @param {string} subTitle - the tab button sub text
 * @param {boolean} isLoading - if TRUE, will show a spinner in the tab button
 * @param {JSX.Element} icon - the icon for each tab button
 * @param {number} total - the number totals badge for each button
 * @param {string} badgeColor - if provided will override the dynamic badge color
 * @param {boolean} disableChipColor - if TRUE chip color will be grey
 * @param {object} props - optional props to pass to the Tab component
 * @returns {JSX.Element}
 * @constructor
 */
const TabButton = ({ title, subTitle, isLoading, icon, total, badgeColor, disableChipColor = false, ...props }) => {
  const getBadgeColor = (total) => {
    if (badgeColor) {
      return badgeColor;
    }

    if (total > 10) return "danger";
    if (total > 0) return "warning";
    if (total === 0) return "success";
    else return "secondary";
  };
  return (
    <Tab
      label={
        <span style={{ display: "inline-block", width: "100%", textAlign: "left" }}>
          <span style={{ marginRight: "1em" }} className={"float-left"}>
            {icon}
          </span>
          <span>{title}</span>
          <span className={"float-right"} style={{ marginLeft: "1em", marginTop: "-0.15em" }}>
            {!isNullOrUndefined(total) && (
              <Badge
                color={disableChipColor ? undefined : getBadgeColor(total)}
                sx={{
                  color: disableChipColor ? getBadgeColor(total) : undefined,
                }}
              >
                {total}
              </Badge>
            )}
          </span>
        </span>
      }
      {...props}
    />
  );
};

/**
 * The body content of the card based on selected Tab
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabCard.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array.isRequired,
  tabProps: PropTypes.object,
  tabButtonProps: PropTypes.object,
  resetFunction: PropTypes.func,
  headerButtons: PropTypes.array,
  badgeColor: PropTypes.string,
  disableChipColor: PropTypes.bool,
  tabDisplay: PropTypes.bool,
  sx: PropTypes.object,
  onTabChange: PropTypes.func,
};

TabButton.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  total: PropTypes.number,
  badgeColor: PropTypes.string,
  disableChipColor: PropTypes.bool,
  props: PropTypes.object,
};

export default TabCard;
