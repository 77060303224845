"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllLinkedItems = void 0;
/**
 * Gets an array of items connected to links, without any duplicates
 * @param {object[]} links - all links to aggregate into one array
 * @param {string} connectionField -
 */
const getAllLinkedItems = ({ links, connectionField }) => {
    //Return zero items for any null or empty links array or connection field
    if (!Array.isArray(links) || links.length === 0 || !connectionField) {
        return [];
    }
    const idMap = {};
    const items = [];
    for (const link of links) {
        //Skip any links that dont have id or the provided connection field
        if (!link?.[connectionField]?.id) {
            continue;
        }
        //Skip any item that has already been recorded
        if (idMap.hasOwnProperty(link[connectionField].id)) {
            continue;
        }
        idMap[link[connectionField].id] = true;
        items.push(link[connectionField]);
    }
    return items;
};
exports.getAllLinkedItems = getAllLinkedItems;
