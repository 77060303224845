import { RESOURCE_TYPE_CONFIG } from "../../../../utils/ResourceTypes/RESOURCE_TYPE_CONFIG";

/**
 * Gets platform Resources and fields formatted to be used with the QueryBuilder
 * @param {boolean} manyToMany - displays fields that are a many-to-many connection
 * @param {boolean} oneToMany - displays fields that are a one-to-many connection
 * @param {object} customResourceTypes - custom resource types for the org, formatted into an object to match RESOURCE_TYPE_CONFIG
 * @returns {[]}
 */
export const getConditionBuilderResources = ({ manyToMany = true, oneToMany = true, customResourceTypes = {} }) => {
  const res = [];

  for (const [typename, config] of Object.entries({
    ...RESOURCE_TYPE_CONFIG,
    ...customResourceTypes,
  })) {
    const fieldConfig = config.fields;

    if (fieldConfig) {
      for (const [fieldName, properties] of Object.entries(fieldConfig)) {
        if (properties.type === "manyToMany") {
          if (manyToMany !== true) {
            delete fieldConfig[fieldName];
          }
        }

        if (properties.type === "oneToMany") {
          if (oneToMany !== true) {
            delete fieldConfig[fieldName];
          }
        }
      }
    }

    res.push({
      name: config.name,
      typename,
      fields: fieldConfig,
      customResourceTypeID: config.customResourceTypeID,
    });
  }

  return res;
};
