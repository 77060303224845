import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm";

import { useCreateThreatsFromTechniques } from "./useCreateThreatsFromTechniques";

/**
 * For creating a single Threat
 * @param {string} organizationID - The ID of the organization
 * @param {function} getNewItem - A function that is called when a new item is created
 * @param {function} resetFunction - A function to reset parent state
 * @param {function} toggleModal - A function to toggle the parent modal
 * @param props
 */
export const useCreateThreat = ({ organizationID, getNewItem, resetFunction, toggleModal, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.THREAT;

  const { createMutation } = generateGraphql("Threat", ["name", "monitoring", "forensics", "detection", "response"], {
    monitoring: `{description}`,
    forensics: `{description logLocation logRetention}`,
    detection: `{description}`,
    response: `{description steps { description } }`,
  });

  /**
   * Import MITRE ATT&CK Techniques
   */
  const importTechniques = useCreateThreatsFromTechniques({
    organizationID,
    resetFunction,
    toggleModal,
  });

  const form = useForm({
    headerButtons: [importTechniques.modalButton],
    enableTemplates: true,
    mutation: createMutation,
    module,
    resource,
    organizationID,
    getNewItem,
    disableResetFunction: true,
    fields: ["name"],
    fieldConfig: {
      name: {
        label: "Threat Name",
        required: true,
      },
    },
    toggleModal,
    ...props,
  });

  return {
    ...form,
  };
};
