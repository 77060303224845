import { getResourceAttribute } from "./getResourceAttribute";

/**
 * Convenience method for getting the icon of a resource from the definition objects
 * @param {string} typename - the name of the resource for which to grab an icon
 * @return {*}
 */
export const getResourceIcon = ({ typename }) => {
  return getResourceAttribute({ typename, attribute: "icon" });
};
