import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorSolutionSystemLinking } from "../hooks/useVendorSolutionSystemLinking";

/**
 * A component instance of the useVendorSolutionSystemLinking.js hook
 * @param props
 */
const VendorSolutionSystemLinking = (props) => {
  return useVendorSolutionSystemLinking(props).display;
};

export default withOrganizationCheck(VendorSolutionSystemLinking);
