import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";

import { convertCamelCaseToSentence, formattedDollar } from "@rivial-security/func-utils";

import { useTable } from "../../../../../../../hooks/views/useTable";
import OrganizationCheck from "../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";

const BusinessRiskItemBody = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const keyRiskIndicators = item && item.keyRiskIndicators;

  const fieldNameDictionary = {
    residualRisk: "Residual Risk",
    riskRating: "Risk Rating",
  };

  const ResidualRisk = ({ residualRisk }) => {
    return formattedDollar(residualRisk);
  };

  const RiskRating = ({ riskRating }) => {
    return <p>{convertCamelCaseToSentence(riskRating)}</p>;
  };

  const customFields = [
    {
      field: "residualRisk",
      component: <ResidualRisk />,
    },
    {
      field: "riskRating",
      component: <RiskRating />,
    },
  ];

  const risksTable = useTable({
    data: keyRiskIndicators,
    fields: ["name", "residualRisk", "riskRating"],
    customFields,
    fieldNameDictionary,
    disableRoleChecking: true,
  });

  useEffect(() => {
    risksTable.setData(
      [...keyRiskIndicators].sort((a, b) => {
        return b.residualRisk - a.residualRisk;
      }),
    );
  }, [item]);

  return (
    <>
      <tr
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
          border: isOpen ? "solid 2px lightBlue" : "rgba(0,0,0,.125)",
          padding: ".75rem 1.25rem",
          zIndex: isOpen ? 1000 : undefined,
        }}
        title="View Business Risk Details"
        className={isOpen ? "shadow" : undefined}
      >
        <td>{convertCamelCaseToSentence(item.name)}</td>
        <td>
          {item.greatestKeyRiskIndicator && item.greatestKeyRiskIndicator.name ? (
            item.greatestKeyRiskIndicator.name
          ) : (
            <i style={{ color: "grey" }}>There are no Key Risk Indicators associated with this Business Risk</i>
          )}
        </td>
        <td>{formattedDollar(item.residualRisk)}</td>
        <td>{convertCamelCaseToSentence(item.riskRating)}</td>
      </tr>
      <tr style={{ display: !isOpen ? "none" : undefined }}>
        <td colSpan={4}>
          <Collapse isOpen={isOpen}>
            <Card className={isOpen ? "shadow" : undefined}>
              <CardBody>
                <strong>Key Risk Indicators</strong>
                <hr />
                {keyRiskIndicators && keyRiskIndicators.length > 0 ? (
                  risksTable.display
                ) : (
                  <i style={{ color: "grey" }}>There are no Key Risk Indicators associated with this Business Risk</i>
                )}
              </CardBody>
            </Card>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const BusinessRiskItem = (props) => {
  return (
    <OrganizationCheck {...props}>
      <BusinessRiskItemBody />
    </OrganizationCheck>
  );
};

export default BusinessRiskItem;
