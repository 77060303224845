import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useUploadVulnerabilities } from "../../../Vulnerabilities/hooks/useUploadVulnerabilities";

/**
 * @description Upload vulnerabilities for an assessment
 * @param item
 * @param organizationID
 * @return {JSX.Element}
 * @constructor
 */
const AssessmentDetailsUploadVulnerabilities = ({ item, organizationID }) => {
  const uploadFindingsCSV = useUploadVulnerabilities({
    organizationID,
    assessment: item,
    module: modules.VULNERABILITIES,
    resource: resources.ASSESSMENT,
    disableReportSettings: true,
  });

  return <>{uploadFindingsCSV.modalButton}</>;
};

export default withOrganizationCheck(AssessmentDetailsUploadVulnerabilities);
