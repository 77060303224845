import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import TabCard from "../../../../utils/GenericComponents/TabCard";
import { useEvidenceControlLinkingModal } from "../../EvidenceControlLinks/hooks/useEvidenceControlLinkingModal";
import { useEvidenceNonStandardRiskControlLinking } from "../../EvidenceRiskControlLinks/hooks/useEvidenceNonStandardRiskControlLinking";
import { useEvidenceRiskControlLinking } from "../../EvidenceRiskControlLinks/hooks/useEvidenceRiskControlLinking";

import AssociatedControls from "./AssociatedControls/AssociatedControls";
import EvidenceNonStandardRiskControls from "./EvidenceNonStandardRiskControls";
import EvidenceStandardRiskControls from "./EvidenceStandardRiskControls";

/**
 * A TabCard that display Compliance and Risk Controls separately for an Evidence
 * @param {string} organizationID - the organization/operation team for which to show controls
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {object} item - the evidence item
 * @param {function} resetFunction - function to re-query item
 * @return {JSX.Element}
 */
const TabbedControlsCard = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  isTemplate = false,

  item,
  resetFunction,
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });
  const evidenceControlLinking = useEvidenceControlLinkingModal({
    organizationID,
    item,
    resetFunction,
    isTemplate,
  });
  const evidenceRiskControlLinking = useEvidenceRiskControlLinking({
    organizationID,
    item,
    resetFunction,
  });
  const evidenceNonStandardRiskControlsLinking = useEvidenceNonStandardRiskControlLinking({
    organizationID,
    evidence: item,
    resetFunction,
    buttonStyle: { height: "100%", width: "100%" },
  });

  return (
    <TabCard
      style={{ height: "70em" }}
      item={item}
      items={[
        {
          icon: <i className={"icon-shield"} />,
          title: "Compliance Controls",
          component: (
            <AssociatedControls
              organizationID={organizationID}
              disableRoleChecking={disableRoleChecking}
              isTemplate={isTemplate}
              resetFunction={resetFunction}
            />
          ),
          headerButtons: checkPermissions.resource.update && [evidenceControlLinking.modalButton],
        },
        {
          icon: <i className={"icon-energy"} />,
          title: "Standard Risk Controls",
          component: (
            <EvidenceStandardRiskControls
              organizationID={organizationID}
              evidenceRiskControlLinking={evidenceRiskControlLinking}
              resetFunction={resetFunction}
            />
          ),
          headerButtons: checkPermissions.resource.update && [evidenceRiskControlLinking.modalButton],
        },
        ...(!isTemplate // non-standard controls not supported for template evidence
          ? [
              {
                icon: <i className={"icon-equalizer"} />,
                title: "Non-Standard Risk Controls",
                component: (
                  <EvidenceNonStandardRiskControls
                    organizationID={organizationID}
                    evidenceRiskControlLinking={evidenceRiskControlLinking}
                    resetFunction={resetFunction}
                  />
                ),
                headerButtons: checkPermissions.resource.update && [evidenceNonStandardRiskControlsLinking.modalButton],
                minWidth: "25em",
              },
            ]
          : []),
      ]}
    />
  );
};

export default withOrganizationCheck(TabbedControlsCard);
