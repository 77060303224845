//Defines the type of overrides for this function each representing a different reason for checking for control changes
//NOTE: Until a package solution is implemented ctrl+f any occurrence of this enum and update simultaneously
export const updateControlOverrideType = {
  NONE: "none",
  EVIDENCE_DELETED: "evidenceDeleted",
  EVIDENCE_UNLINKED: "evidenceUnlinked",
  EVIDENCE_LINKED: "evidenceLinked",
  EVIDENCE_ENABLED: "evidenceEnabled",
  EVIDENCE_DISABLED: "evidenceDisabled",
  GENERAL_LINKS_UPDATED: "generalLinksUpdated",
  GENERAL: "general",
};
