import React from "react";

/**
 * @description Used to navigate to the details page
 * @param {string} route - route of the selected item
 * @param {object} lastSelectedItem - last selected item from the grid
 * @return {JSX.Element}
 * @constructor
 */
const GridDetailsContextLink = ({ route, lastSelectedItem }) => {
  return (
    <a
      id={`details-page-${route}-${lastSelectedItem?.id}`}
      href={route + lastSelectedItem?.id}
      style={{ visibility: "hidden" }}
    />
  );
};

export default GridDetailsContextLink;
