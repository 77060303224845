import { Orientation } from "@syncfusion/ej2-react-charts";
import { PointerDirective, PointersDirective } from "@syncfusion/ej2-react-circulargauge";
import {
  AxesDirective,
  AxisDirective,
  LinearGaugeComponent,
  RangeDirective,
  RangesDirective,
} from "@syncfusion/ej2-react-lineargauge";

import { useUUID } from "../../../hooks/functional/useUUID";

import { ChartParams } from "./types";

export interface LinearGaugeChartParams extends ChartParams {
  subTitle: string;
  orientation?: Orientation;
}

/**
 * @description Generic Linear Gauge Chart component
 */
const LinearGaugeChart = ({
  title,
  subTitle,
  value = 0,
  valueFormat,
  height,
  width,
  minimum = 0,
  maximum = 1,
  animate = true,
  orientation,
  resetKey,
}: LinearGaugeChartParams): JSX.Element => {
  const [uuid] = useUUID();

  return (
    <div>
      {title && (
        <h5
          style={{
            color: "#657581",
            marginTop: "1em",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {title}
        </h5>
      )}
      {subTitle && <p style={{ color: "#657581", marginTop: "0.5em", textAlign: "center" }}>{subTitle}</p>}
      <LinearGaugeComponent
        id={`linear-gauge-${uuid}`}
        data-testid={`linear-gauge-${uuid}`}
        key={`linear-gauge-${uuid}-container`}
        height={height ?? "70em"}
        width={width ?? "100%"}
        orientation={orientation ?? "Horizontal"}
        format="n2"
      >
        <AxesDirective>
          <AxisDirective
            minimum={minimum}
            maximum={maximum}
            labelStyle={{
              format: valueFormat ?? "{value}",
            }}
          >
            <PointersDirective>
              <PointerDirective
                key={resetKey}
                animation={{
                  enable: animate,
                  duration: 1500,
                }}
                value={value}
                radius="75%"
                cap={{
                  radius: 8,
                  color: "white",
                  border: {
                    color: "#007DD1",
                    width: 5,
                  },
                }}
                pointerWidth={10}
              />
            </PointersDirective>
            <RangesDirective>
              <RangeDirective start={0.8} end={1} color="red" />
              <RangeDirective start={0.6} end={0.8} color="darkOrange" />
              <RangeDirective start={0.4} end={0.6} color={"#fabd43"} />
              <RangeDirective start={0.2} end={0.4} color={"#2cc257"} />
              <RangeDirective start={0} end={0.2} color="green" />
            </RangesDirective>
          </AxisDirective>
        </AxesDirective>
      </LinearGaugeComponent>
    </div>
  );
};

export default LinearGaugeChart;
