import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * This is used to normalize the IDs for external id fields.
 * For nested grids with name field, this sets the ID to an array so it can be viewed.
 *
 * If a row doesn't have an 'id' field it will cause the grid to crash,
 * so this function removes that row from the data.
 * @param {object} input
 * @param {*[]} input.items - The data to handle IDs for
 * @param {string} [input.idField = null] - optional alternative field to use as an ID field
 * @returns {object[]}
 */
export const normalizeRowIDs = ({ items, idField }) => {
  const data = [];

  // if there is an id field defined for external data, remap the id field
  if (idField) {
    if (Array.isArray(items)) {
      for (const item of items) {
        if (!isNullOrUndefined(idField)) {
          item.id = item[idField];
        }
        data.push(item);
      }
    } else {
      // do nothing, return an empty array, throw an error
    }
  }
  // if there is not id field defined for external data return the original array
  else {
    data.push(...items);
  }

  // finally, parse through the data, removing any rows that don't have an ID
  return data.filter((item) => !isNullOrUndefined(item.id));
};
