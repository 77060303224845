import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { checkArguments } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * A component that allows for multiple inputs to be added, removed, and edited
 * @param {function} onChange - callback for when the inputs change
 * @param {object[]} initialValues - the initial values for the inputs
 * @param {boolean} isEditingFields - whether the fields are being edited or not
 * @param {string} fieldName - the name of the field to be edited (the field that stores options)
 * @return {JSX.Element}
 */
const MultiInputs = ({ onChange, initialValues, isEditingFields = true, fieldName }) => {
  const [inputs, setInputs] = useState(
    initialValues && Array.isArray(initialValues?.[fieldName])
      ? initialValues?.[fieldName].map((option) => ({
          id: option?.value || uuid(),
          label: option?.label,
          value: option?.value || uuid(),
          isDisabled: true,
          isEditing: false,
        }))
      : [
          {
            id: uuid(),
            label: "",
            value: uuid(),
            isDisabled: false,
            isEditing: false,
          },
        ],
  );

  try {
    checkArguments(
      {
        onChange,
        inputs,
        initialValues,
        isEditingFields,
      },
      {
        onChange: { type: "function" },
        inputs: { isArray: true },
        initialValues: { type: "object", validAsNull: true },
        isEditingFields: { type: "boolean", optional: true },
      },
    );
  } catch (error) {
    ErrorLogger("Error in MultiInputs.js", error);
  }

  /**
   * Finds the index of an input by its id
   * @param {string} id - the id of the input to find
   * @return {number}
   */
  const findInputIndexById = (id) => {
    if (typeof id !== "string") {
      ErrorLogger("Valid id is required");
      return -1;
    }
    return inputs.findIndex((input) => input?.id === id);
  };

  const handleInputChange = (id, event) => {
    const index = findInputIndexById(id);
    if (index >= 0) {
      const values = [...inputs];
      values[index].label = event.target.value;
      setInputs(values);
      if (isEditingFields) {
        if (initialValues) {
          onChange({
            name: initialValues?.name,
            type: initialValues?.type,
            [fieldName]: values
              .filter((input) => input?.label.trim() !== "")
              .map((input) => ({
                label: input?.label,
                value: input?.value,
              })),
          });
        } else {
          onChange(
            values.map((input) => ({
              label: input?.label,
              value: input?.value,
            })),
          );
        }
      }
    } else {
      ErrorLogger(`Invalid id provided: ${id}`);
    }
  };
  const handleAddInput = () => {
    if (inputs[inputs.length - 1]?.label.trim() !== "") {
      const updatedInputs = inputs.map((input, idx, arr) =>
        idx === arr.length - 1 ? { ...input, isDisabled: true } : input,
      );
      setInputs([
        ...updatedInputs,
        {
          id: uuid(),
          label: "",
          value: uuid(),
          isDisabled: false,
          isEditing: false,
        },
      ]);
    }
  };
  const handleRemoveInput = (id) => {
    if (!id) {
      ErrorLogger(`Invalid or missing id: ${id}`);
      return;
    }
    const index = findInputIndexById(id);
    const values = [...inputs];
    values.splice(index, 1);
    if (index === values.length) {
      values[values.length - 1].isDisabled = false;
    }
    setInputs(values);
    if (isEditingFields) {
      if (initialValues) {
        onChange({
          name: initialValues?.name,
          type: initialValues?.type,
          [fieldName]: values
            .filter((input) => input?.label.trim() !== "")
            .map((input) => ({
              label: input?.label,
              value: input?.value,
            })),
        });
      } else {
        onChange(
          values.map((input) => ({
            label: input?.label,
            value: input?.value,
          })),
        );
      }
    }
  };

  const handleEditInput = (id) => {
    if (!id) {
      ErrorLogger(`Invalid or missing id: ${id}`);
      return;
    }
    const index = findInputIndexById(id);
    const values = [...inputs];
    values[index].isDisabled = false;
    values[index].isEditing = true;
    setInputs(values);
  };

  const handleSaveInput = (id) => {
    if (!id) {
      ErrorLogger(`Invalid or missing id: ${id}`);
      return;
    }
    const index = findInputIndexById(id);
    const values = [...inputs];
    values[index].isDisabled = true;
    values[index].isEditing = false;
    setInputs(values);
    if (isEditingFields) {
      if (initialValues) {
        onChange({
          name: initialValues?.name,
          type: initialValues?.type,
          [fieldName]: values
            .filter((input) => input?.label.trim() !== "")
            .map((input) => ({
              label: input?.label,
              value: input?.value,
            })),
        });
      } else {
        onChange(
          values.map((input) => ({
            label: input?.label,
            value: input?.value,
          })),
        );
      }
    }
  };

  return (
    <>
      {Array.isArray(inputs) &&
        inputs.map((input, index) => (
          <Grid container spacing={1} alignItems="center" key={input?.id}>
            <Grid item xs sx={{ margin: "0.5rem" }}>
              <TextField
                fullWidth
                required
                value={input?.label}
                onChange={(event) => handleInputChange(input?.id, event)}
                disabled={input?.isDisabled}
                inputProps={{
                  style: { padding: ".4rem", height: "1.4em" },
                  maxLength: 50,
                }}
                helperText={input?.value.length === 50 ? "Max length reached: 50" : ""}
              />
            </Grid>
            {index === inputs.length - 1 && input?.value.trim() !== "" && inputs.length > 1 ? (
              <>
                <Grid item>
                  <IconButton variant="outlined" color="success" onClick={handleAddInput}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    variant="outlined"
                    color="info"
                    onClick={() => (input.isEditing ? handleSaveInput(input?.id) : handleEditInput(input?.id))}
                    disabled={input?.value.trim() === ""}
                  >
                    {input?.isEditing ? <SaveIcon /> : <EditIcon />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton variant="outlined" color="error" onClick={() => handleRemoveInput(input?.id)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </>
            ) : index === inputs.length - 1 ? (
              <Grid item>
                <IconButton
                  variant="outlined"
                  color="success"
                  onClick={handleAddInput}
                  disabled={input?.value.trim() === ""}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <IconButton
                    variant="outlined"
                    color="info"
                    onClick={() => (input?.isEditing ? handleSaveInput(input?.id) : handleEditInput(input?.id))}
                    disabled={input?.value.trim() === ""}
                  >
                    {input?.isEditing ? <SaveIcon /> : <EditIcon />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton variant="outlined" color="error" onClick={() => handleRemoveInput(input?.id)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
        ))}

      {inputs.length === 0 && (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs sx={{ margin: "0.5rem" }}>
            <TextField
              fullWidth
              required
              value={""}
              onChange={(event) => handleInputChange(uuid(), event)}
              disabled={false}
              inputProps={{ style: { padding: ".4rem", height: "1.4em" } }}
            />
          </Grid>
          <Grid item>
            <IconButton variant="outlined" color="success" onClick={handleAddInput} disabled={true}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MultiInputs;
