import { generateGraphql } from "@rivial-security/generategraphql";

export const getOrganization_minimal = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    shortName
    orgType
    s3BucketName
    publicRSAKey
    assetSize
    riskSetup {
      high
      mediumHigh
      medium
      lowMedium
      low
    }
  }
}
`;

const { updateMutation } = generateGraphql("Organization", ["name"]);

/**
 * Since Organization does not have an 'ownerGroup' field, manually remove from the graphql strings
 */
export const updateOrganization = updateMutation.replace(/ownerGroup/g, "");
