import { not } from "@rivial-security/func-utils";

/**
 * Handler for transferring items from the left side to the right side of a transfer list
 * @param rightItems
 * @param setRightItems
 * @param leftItems
 * @param setLeftItems
 * @param leftChecked
 * @param checkedItems
 * @param setCheckedItems
 */
export const handleCheckedRight = (
  rightItems = [],
  setRightItems,
  leftItems = [],
  setLeftItems,
  leftChecked = [],
  checkedItems = [],
  setCheckedItems,
) => {
  setRightItems(rightItems.concat(leftChecked));
  setLeftItems(not(leftItems, leftChecked));
  setCheckedItems(not(checkedItems, leftChecked));
};
