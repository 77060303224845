import React from "react";

import { resources } from "@rivial-security/role-utils";

import { generateNestedGraphql } from "../../../../definitions/functions/queries/generateNestedGraphql";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useResetEffect } from "../../../../hooks/functional/useResetEffect";
import { useNestedItems } from "../../../../hooks/graphql/useNestedItems";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useObservationDataGrid } from "../../Observations/hooks/useObservationDataGrid";

/**
 * Displays Observations for an Action Item
 * @param {object} item - the Action Item to display Observations for
 */
const ActionItemObservations = ({ item }) => {
  const queryPath = [
    { name: "recommendations", hasMany: true },
    { name: "recommendation", hasMany: false },
    { name: "observations", hasMany: true },
    { name: "observation", hasMany: false },
  ];
  const { getQuery } = generateNestedGraphql({
    startTypename: resources.ACTION_ITEM,
    endTypename: resources.OBSERVATION,
    path: queryPath,
    fieldContext: fieldContexts.GRID,
  });

  const { resetFunction, isLoading, items } = useNestedItems({
    item,
    field: "recommendations",
    connectionField: "recommendation",
    queryPath,
    deduplicationField: "id",
    query: getQuery,
  });

  const handleData = () => {
    observations.setData(items);
  };

  useResetEffect(handleData, [items]);

  const queryConfig = {
    query: null,
    resetFunction,
  };

  const gridConfig = {
    gridHeight: "100%",
    allowPaging: false,
    createResourceComponent: null,
  };

  const observations = useObservationDataGrid({
    ...queryConfig,
    ...gridConfig,
    isLoading,
  });

  return <div style={{ height: "30em" }}>{observations?.gridDisplay}</div>;
};

export default withOrganizationCheck(ActionItemObservations);
