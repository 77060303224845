/**
 * Retrieves all response steps from all groups under one type of procedure
 * @param {object} item - the object from database that holds the `[ProcedureGroup]` property
 * @param {string} procedureName - a procedure property that possibly has response steps in one of the procedure groups
 */
export const getAllProcedureGroupResponseSteps = ({ item, procedureName }) => {
  //Check arguments
  if (!item || !procedureName) {
    return [];
  }

  //Parse object
  let responseSteps = [];
  const procedureGroups = item?.procedures;
  if (Array.isArray(procedureGroups)) {
    for (const procedureGroup of procedureGroups) {
      const currentGroupResponseSteps = procedureGroup?.[procedureName]?.responseSteps;
      if (Array.isArray(currentGroupResponseSteps)) {
        responseSteps = responseSteps.concat(currentGroupResponseSteps);
      }
    }
  }

  return responseSteps;
};
