import { List, ListSubheader, Slide, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import useDidMountEffect from "../../../../hooks/functional/useDidMountEffect";
import { useElementSize } from "../../../../hooks/functional/useElementSize";
import { getIcon } from "../../../Functions/Icon/getIcon";

import DrawerItem from "./DrawerItem";

/**
 * A controlled-only Drawer with tabs inside for different jobs
 * @param {boolean} open - if TRUE will open the drawer
 * @param {object[]} tabs - the custom items to display in the tab drawer
 * @param {function} onTabChange - called with tab index when a tab has changed
 * @param {boolean} showCloseTab - if TRUE will show the close tab button
 * @param {function} onClosePressed - called when the close tab button is clicked
 * @param {object} config - settings for the drawer (see below for defaults)
 * @param {number} [defaultTabIndex = 0] - default doc editor drawer tab. 0 = create, 1 = open, 2 = save
 * @return {JSX.Element}
 */
const TabbedDrawer = ({
  isOpen = false,
  tabs = [],
  onTabChange = () => {},
  showCloseTab = false,
  onClosePressed = () => {},
  config = {},
  defaultTabIndex = 0,
  containerRef,
} = {}) => {
  const { width = "26em", direction = "right", matchSyncFusionEditor = true } = config;

  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const handleChange = (event, newValue) => {
    if (newValue === -1) {
      onClosePressed();
    } else {
      setTabIndex(newValue);
    }
  };

  const { height } = useElementSize({ ref: containerRef });

  useDidMountEffect(() => {
    onTabChange && onTabChange(tabIndex);
  }, [tabIndex]);

  const getTabStyle = () => {
    return matchSyncFusionEditor == false
      ? {
          height: "40px",
          minHeight: "40px",
        }
      : {
          paddingTop: "2px",
          height: "78px",
          minHeight: "78px",
          backgroundColor: "#fafafa",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: "1px",
          borderColor: "rgba(0, 0, 0, 0.08)",
          textTransform: "none",
        };
  };

  return (
    <Slide direction={direction} in={!isNullOrUndefined(height) && isOpen} mountOnEnter unmountOnExit>
      <div
        style={{
          width,
          minWidth: width,
          height: height ? `${height}px` : "max(80vh, 50em)",
          display: "flex",
          flexDirection: "column",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box>
          <Tabs centered value={tabIndex} onChange={handleChange} aria-label="drawer-tabs" sx={getTabStyle()}>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  sx={getTabStyle()}
                  label={tab?.label || `Tab ${index + 1}`}
                  value={index}
                  icon={tab?.icon}
                />
              );
            })}
            {showCloseTab && <Tab key={-1} sx={getTabStyle()} label="Close" value={-1} icon={getIcon("ep:close")} />}
          </Tabs>
        </Box>
        {
          <div style={{ flex: 1, overflow: "hidden" }}>
            <List
              style={{
                padding: "0em .8em",
                width: "100%",
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              <ListSubheader style={{ paddingBottom: ".8em" }}>{tabs[tabIndex]?.prefixComponent}</ListSubheader>
              {Array.isArray(tabs) &&
              tabs.length > tabIndex &&
              Array.isArray(tabs[tabIndex]?.items) &&
              tabs[tabIndex].items.length > 0 ? (
                tabs[tabIndex].items
                  .filter((item) => item?.visible !== false)
                  .map((item, index) => {
                    return <DrawerItem key={index} item={item} />;
                  })
              ) : (
                <div style={{ padding: ".8em" }}>No content</div>
              )}
            </List>
          </div>
        }
      </div>
    </Slide>
  );
};

export default TabbedDrawer;
