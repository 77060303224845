import { Redirect } from "react-router-dom";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { Route } from "@application/Layout/hooks/types/Navigation";
import { NavigationModule } from "@application/Navigation/types";

export interface AddDefaultModuleRoutesParam {
  navigationModule: NavigationModule;
  newRoutes: Route[];
}

export const addDefaultModuleRoutes = ({ navigationModule, newRoutes }: AddDefaultModuleRoutesParam): void => {
  const routes = navigationModule.routes;
  const modulePath = navigationModule.path;
  const defaultRoutePath = navigationModule.defaultRoutePath;
  const moduleDefaultRoute = routes?.find((route) => route.path === defaultRoutePath);
  if (!moduleDefaultRoute) {
    return;
  }

  const fullPath = `${modulePath}${defaultRoutePath}`;
  newRoutes.push({
    name: moduleDefaultRoute.name,
    path: modulePath,
    component: () => <Redirect to={fullPath} />,
  });

  const deprecatedModulePaths = navigationModule.deprecatedPaths;
  if (!isNonEmptyArray(deprecatedModulePaths)) {
    return;
  }
  for (const deprecatedModulePath of deprecatedModulePaths!) {
    newRoutes.push({
      name: moduleDefaultRoute.name,
      path: deprecatedModulePath,
      component: () => <Redirect to={fullPath} />,
    });
  }
};
