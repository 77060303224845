import React from "react";

import { getAllLinkedItems } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useAssessmentDataGrid } from "../../../../../../Testing/Assessments/hooks/useAssessmentDataGrid";
import { listSystemTargetLinksBySystemID_assessments } from "../queries/listSystemTargetLinksBySystemID";

/**
 * Displays all vulnerabilities associated with a systems through a target
 */
const SystemAssessments = ({ itemRef, organizationID }) => {
  const item = itemRef?.current;

  const grid = useAssessmentDataGrid({
    organizationID,
    createResourceComponent: null,
    enableMenu: false,
    query: listSystemTargetLinksBySystemID_assessments,
    variables: {
      systemID: item?.id,
    },
    normalizeData: (systemTargetLinks) => {
      //Collecting all assessment links
      const targets = getAllLinkedItems({
        links: systemTargetLinks,
        connectionField: "target",
      });
      const assessmentLinks = [];
      for (const target of targets) {
        const targetAssessments = target?.assessments?.items;
        if (Array.isArray(targetAssessments)) {
          assessmentLinks.push(...targetAssessments);
        }
      }
      const assessments = getAllLinkedItems({
        links: assessmentLinks,
        connectionField: "assessment",
      });

      //Making conversions for proper filtering of items
      const newAssessments = [];
      for (const item of assessments) {
        let newPOC = "";
        if (item.pointOfContact) {
          newPOC = JSON.stringify(item.pointOfContact);
        }
        newAssessments.push({
          ...item,
          pointOfContact: newPOC,
          assessmentDate: new Date(item?.assessmentDate),
        });
      }
      return newAssessments;
    },
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(SystemAssessments);
