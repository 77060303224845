import FactCheckIcon from "@mui/icons-material/FactCheck";
import Note from "@mui/icons-material/Note";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React, { useEffect, useState } from "react";

import { concatNotes, createCommaString } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { tryFunction } from "@utils/Functions/tryFunction";
import StyledWrapper from "@utils/GenericComponents/StyledWrapper";
import AuditControlNotes from "@views/Compliance/Audit/components/AuditControlNotes";
import EvidenceField from "@views/Compliance/Audit/components/EvidenceField";

import useDidMountEffect from "../../../../hooks/functional/useDidMountEffect";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { createControlTagLink } from "../../../../utils/Tags/functions/createTagLinks/createControlTagLink";
import AuditControlDetails from "../components/AuditControlDetails";
import AuditControlIsCompliant from "../components/AuditControlIsCompliant";
import { deleteAuditControlLink } from "../functions/deleteAuditControlLink";

import { useAuditControlsData } from "./useAuditControlsData";
import { useControlFrameworkCustomFields } from "./useControlFrameworkCustomFields";
import { useEditAuditControls } from "./useEditAuditControls";

/**
 * Hook for the 'AuditControls' component for Audits
 * @param {string} organizationID - the organization id
 * @param {object} auditFindings - reference to the useFindingsCard hook for the audit
 * @param {object} auditDetails - reference to the useAuditDetails hook for the audit
 * @param {string} resetKey - the key to reset the audit details
 * @returns {{display: JSX.Element}}
 */
export const useAuditControls = ({ organizationID, resetKey, auditFindings, auditDetails }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;

  const { isLoading, resetFunction, item, setItem } = auditDetails;

  //Reset when parent calls for a refresh
  useDidMountEffect(() => {
    if (resetKey) {
      resetFunction();
    }
  }, [resetKey]);

  /**
   * Used to Fire off mutations but updating the entire central state locally.
   * Doing this to ensure snappy performance.
   * @param {string} typename - the resource type (name in the schema)
   * @param {string} id - the id of the resource to mutate
   * @param {string} field - the field to change in the audit item
   * @param {string} [nestedField] - the field to change inside the property represented by 'field'
   * @param {*} value - the value of the field being changed
   * @returns {Promise<void>}
   *
   * Reference Object:
   * audit: {
   *   __typename: "Audit"
   *   controls: {
   *     items: [
   *       {
   *          __typename: "AuditControlLink"
   *         id,
   *         control: {
   *           tags: {
   *             items: []
   *           }
   *         },
   *         notes: [],
   *         isCompliant: true,
   *         evidenceLinks: {
   *           items: [
   *             {
   *               __typename: "AuditControlEvidenceLink",
   *               id,
   *               reviewed,
   *               notes
   *             }
   *           ]
   *         }
   *
   *       }
   *     ]
   *   }
   *
   * }
   */
  const [resetIndex, setResetIndex] = useState(0);

  /**
   * Used to Fire off mutations but updating the entire central state locally.
   *
   * Handles the mutation based on the Typename and ID of the resource.
   *
   * Audit:
   * - Updates root fields of the Audit item directly
   *
   * AuditControlLink:
   * - Updates fields in an AuditControlLink item, such as isCompliant, interviewee, notes, etc.
   *
   * AuditControlEvidenceLink:
   * - Updates fields in an AuditControlEvidenceLink item, such as reviewed, notes, etc.
   *
   * @param {string} typename - the resource type (name in the schema). Should be one of: Audit, AuditControlLink, AuditControlEvidenceLink
   * @param {string} id - the id of the resource to mutate
   * @param {string} field - the field to change
   * @param {string} nestedField - the field to change inside the property represented by 'field'
   * @param {string || boolean} value - the value of the field being changed
   * @param {boolean} [mutation=true] - whether or not to fire off the mutation
   * @returns {Promise<void>}
   */
  const optimisticUpdate = async ({ typename, id, field, nestedField, value, mutation = true }) => {
    const { updateMutation } = generateGraphql(typename, [field]);

    setItem((item) => {
      /**
       * Handles Fields:
       *
       * - Name
       * - Audit Date
       * - Completion Percentage
       * - Is Complete
       */
      if (typename === "Audit") {
        if (mutation) {
          ItemMutation(updateMutation, {
            id,
            [field]: value,
          });
        }
        item[field] = value;
      } else if (typename === "AuditControlLink") {
        /**
         * Handles Fields:
         *
         * - Is Compliant
         * - Interviewee
         * - Auditor
         * - Notes
         * - Evidence Links
         * - Control Tags
         */
        // fetch current auditControlLinks state from useAuditDetails hook
        const auditControlLinks = item?.controls?.items || [];

        // find the auditControlLink that matches the id passed in to this function
        const found = auditControlLinks.find((x) => x.id === id);

        // if the auditControlLink is found, update the field value
        if (found) {
          // handle nested field
          if (nestedField && found[field]) {
            found[field][nestedField] = value;

            if (mutation === true) {
              ItemMutation(updateMutation, {
                id,
                [field]: {
                  [nestedField]: value,
                },
              });
            }
          }
          // handle non-nested field
          else {
            found[field] = value;

            if (mutation === true) {
              ItemMutation(updateMutation, {
                id,
                [field]: value,
              });
            }
          }
        }
      } else if (typename === "AuditControlEvidenceLink") {
        /**
         * Handles Fields:
         *
         * - Reviewed
         */
        const auditControlLinks = item?.controls?.items || [];

        for (const auditControlLink of auditControlLinks) {
          const auditControlEvidenceLinks = auditControlLink?.evidenceLinks?.items || [];

          const found = auditControlEvidenceLinks.find((x) => x.id === id);

          if (found) {
            if (mutation) {
              ItemMutation(updateMutation, {
                id,
                [field]: value,
              });
            }
            found[field] = value;
          }
        }
      }

      return item;
    });
    setResetIndex((resetIndex) => resetIndex + 1);
  };

  // Handles bulk control editing custom options
  const editControls = useEditAuditControls({
    resetFunction,
    organizationID,
    audit: item,
    optimisticUpdate,
    auditFindings,
  });

  const fields = [
    {
      field: "statementNumber",
      headerName: "ID",
      description: "The Unique ID for this Control",
      width: 100,
      minWidth: 100,
      sortComparator: (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      field: "name",
      headerName: "Name",
      description: "The Declarative statement for this Control",
      width: 300,
      minWidth: 100,
    },
    {
      field: "isCompliant",
      headerName: "Compliant",
      description: "The current Compliance of this Control during the Audit",
      width: 100,
      minWidth: 100,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
        {
          value: "N/A",
          label: "N/A",
        },
      ],
      component: (
        <AuditControlIsCompliant
          resetFunction={resetFunction}
          orientation={"vertical"}
          optimisticUpdate={optimisticUpdate}
        />
      ),
      valueGetter: (value) => {
        if (value === true) {
          return "Yes";
        } else if (value === false) {
          return "No";
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "evidenceLinks",
      headerName: "Evidence",
      description: "Evidence that is reviewed for each Control in the Audit",
      width: 300,
      minWidth: 100,
      component: <EvidenceField optimisticUpdate={optimisticUpdate} audit={item} />,
      valueGetter: (value) => {
        return createCommaString(value?.items, "evidence", "name");
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      description: "Control Notes that are specific to this Audit",
      minWidth: 300,
      flex: 1,
      component: (
        <StyledWrapper wrapperStyle={{ width: "100%" }}>
          <AuditControlNotes
            optimisticUpdate={optimisticUpdate}
            auditID={item?.id}
            onCreateObservation={() => tryFunction(auditFindings.resetFunction)}
          />
        </StyledWrapper>
      ),
      valueGetter: (value) => {
        return concatNotes(value);
      },
    },
    {
      field: "tags",
      name: "tags",
      description: "Tags that correspond to this Control across the entire Platform",
      createLinkFunction: createControlTagLink,
      flex: 0.4,
      //NOTE: some properties provided by default in handleDataGridColumns.js
      bulkEdit: true,
    },
  ];

  const { customFields } = useControlFrameworkCustomFields({
    controlFrameworkID: item?.controlSetID,
  });

  const dataGrid = useDataGrid({
    persistenceUUID: "audit-controls-data-grid",
    organizationID,
    module,
    resource,
    fields,
    enableTags: true,
    detailsComponent: <AuditControlDetails audit={item} optimisticUpdate={optimisticUpdate} />,
    deleteFunction: deleteAuditControlLink,
    options: ["edit", "delete"],
    customFields,
    customFieldsReadOnly: true,
    customActions: [
      {
        text: "Set Compliance",
        icon: <FactCheckIcon />,
        onClick: (item) => {
          editControls.setSelectedControls((s) => [item]);
          editControls.statusModal.setModalIsOpen(true);
        },
      },
      {
        text: "Add Observation",
        icon: <RemoveRedEyeIcon />,
        onClick: (item) => {
          editControls.setSelectedControls([item]);
          editControls.observationModal.setModalIsOpen(true);
        },
      },
      {
        text: "Add Note",
        icon: <Note />,
        onClick: (item) => {
          editControls.setSelectedControls((s) => [item]);
          editControls.notesModal.setModalIsOpen(true);
        },
      },
    ],
    customOptions: [
      {
        component: editControls.statusModal.modalButton,
      },
      {
        component: editControls.observationModal.modalButton,
      },
      {
        component: editControls.notesModal.modalButton,
      },
    ],
    detailsTitle: "Audit Control Details",
    deleteButtonText: "Unlink",
    deleteButtonTitle: "Unlinks this Control from this particular Audit. Does not delete the Control completely",
    config: {
      limit: 100,
    },
    isLoading,
    resetFunction,
  });

  // Set the editControls state when selected items change
  useEffect(() => {
    if (Array.isArray(dataGrid.selectedData)) {
      editControls.setSelectedControls(dataGrid.selectedData);
    }
  }, [dataGrid.selectedData]);

  // Handles custom Control Grid formatting
  useAuditControlsData({ item, dataGrid, resetIndex });

  const display = <div style={{ height: "85vh" }}>{dataGrid.display}</div>;

  return {
    display,
  };
};
