import { usePointOfContactDashboard } from "../../../hooks/usePointOfContactDashboard";

const PointOfContactDashboard = ({ item, resetFunction, updateItemById, module, resource }) => {
  return usePointOfContactDashboard({
    item,
    resetFunction,
    updateItemById,
    module,
    resource,
  }).display;
};

export default PointOfContactDashboard;
