"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLast = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the last item in the array.
 * @param {*[]} items - array of items
 * @param {boolean} isNumeric - if true, only numeric items will be considered
 * @returns {*|number|null}
 */
const getLast = ({ items, isNumeric = true }) => {
    let targetItems = items;
    if (isNumeric) {
        targetItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    }
    if (!Array.isArray(targetItems) || targetItems.length === 0) {
        return null;
    }
    else {
        return targetItems[targetItems.length - 1];
    }
};
exports.getLast = getLast;
