"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineAsText = void 0;
/**
 * Combines array of strings into one string using a comma and space as a delimiter
 * @param {object} items - array of items that can be converted to string
 * @returns {null|string} - the combined string or null if no items were passed
 */
const combineAsText = ({ items }) => {
    if (Array.isArray(items)) {
        let text = "";
        for (const item of items) {
            try {
                const stringItem = item.toString();
                if (!stringItem) {
                    continue;
                }
                if (text.length > 0) {
                    text += ", ";
                }
                text += stringItem;
            }
            catch (e) {
                console.log(e);
            }
        }
        return text;
    }
    else {
        return null;
    }
};
exports.combineAsText = combineAsText;
