import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { Draggable, Container as DraggableContainer } from "react-smooth-dnd";

import Context from "./context";
import Field from "./Field";
import Operator from "./Operator";
import Value from "./Value";

/**
 * @description The props of the Rule component.
 * @param {object} props - The props passed into the component.
 * @returns {{removeButton: {marginRight: *, marginTop: *}, removeIcon: {fill: string}}}
 */
const removeIconStyles = (t) => ({
  removeButton: {
    marginRight: t.spacing(-1),
    marginTop: t.spacing(0.75),
  },
  removeIcon: {
    fill: "rgba(255, 0, 0, 0.9)",
  },
});

const useRuleStyles = makeStyles((t) => {
  return {
    ...removeIconStyles(t),
    container: {
      "& > div": {
        marginBottom: t.spacing(0.5),
        marginTop: t.spacing(0.5),
      },
      cursor: "move",
    },
    valueGridItem: {
      flex: "auto",
    },
    group: {
      marginLeft: "2em",
    },
  };
});

const Rule = (props) => {
  const classes = useRuleStyles();
  const context = React.useContext(Context);

  const { id, level, position, rule, readonly } = props;
  const { combinator, field, operator, rules, value } = rule;

  const { dispatch } = context;

  const testId = `${level}-${position}`;

  return combinator ? (
    <RuleGroup combinator={combinator} id={id} level={level + 1} rules={rules} />
  ) : (
    <div
      className={classes.container}
      data-testid={`rule-${testId}`}
      style={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: "1em",
        marginTop: "1em",
      }}
    >
      <div>
        <IconButton
          className={classes.removeButton}
          data-testid={`rule-${testId}-remove`}
          size="small"
          onClick={() => {
            dispatch({ type: "remove-node", id });
          }}
          disabled={readonly}
        >
          <RemoveIcon className={classes.removeIcon} />
        </IconButton>
      </div>
      <div style={{ flex: 3, minWidth: "10em" }}>
        <Field field={field} id={id} testId={testId} readonly={readonly} />
      </div>
      <div style={{ flex: 3, minWidth: "10em" }}>
        <Operator field={field} id={id} operator={operator} testId={testId} readonly={readonly} />
      </div>
      <div style={{ flex: 4, minWidth: "15em" }} className={classes.valueGridItem}>
        <Value field={field} id={id} operator={operator} testId={testId} value={value} readonly={readonly} />
      </div>
    </div>
  );
};

Rule.propTypes = {
  id: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  rule: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
};

const useRuleGroupStyles = makeStyles((t) => ({
  actionButton: {
    "& svg": {
      marginRight: t.spacing(0.5),
      marginTop: t.spacing(0.25),
    },
    textTransform: "none",
  },
  combinator: {
    height: 36,
    padding: t.spacing(0, 1.5),
  },
  group: {
    borderLeft: (props) => (props.level > 0 ? `2px solid ${t.palette.divider}` : "none"),
    paddingLeft: t.spacing(1.5),
    marginBottom: t.spacing(0.5),
    marginTop: (props) => (props.level > 0 ? t.spacing(0.5) : "none"),
  },
  ...removeIconStyles(t),
}));

const RuleGroup = (props) => {
  const classes = useRuleGroupStyles(props);
  const context = React.useContext(Context);

  const { label, combinator, combinators, id, level, rules } = props;
  const testId = `group-${level}`;

  const { dispatch, maxLevels, readonly } = context;

  return level <= maxLevels ? (
    <Grid container className={classes.group} data-testid={testId} direction="column" spacing={1}>
      <Grid item>
        <Grid container spacing={2}>
          {/*Input Label*/}
          {label && (
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {label}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {level !== 0 && (
              <IconButton
                className={classes.removeButton}
                data-testid={`${testId}-remove`}
                disabled={readonly || level === 0}
                size="small"
                onClick={() => {
                  dispatch({ type: "remove-node", id });
                }}
              >
                <RemoveIcon className={level > 0 ? classes.removeIcon : null} />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={combinator}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "set-combinator", id, value });
                }
              }}
            >
              {combinators.map((item) => (
                <ToggleButton
                  key={item.value}
                  data-testid={`${testId}-combinator-${item.value}`}
                  className={classes.combinator}
                  value={item.value}
                  disabled={readonly}
                >
                  <Typography variant="body2">{item.label}</Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              color="primary"
              data-testid={`${testId}-add-rule`}
              onClick={() => {
                dispatch({ type: "add-rule", id });
              }}
              disabled={readonly}
            >
              <AddIcon />
              Rule
            </Button>
          </Grid>
          {level < maxLevels && (
            <Grid item>
              <Button
                className={classes.actionButton}
                color="primary"
                data-testid={`${testId}-add-group`}
                onClick={() => {
                  dispatch({ type: "add-group", id });
                }}
                disabled={readonly}
              >
                <AddIcon />
                Group
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {rules?.length > 0 && (
        <Grid item>
          <DraggableContainer
            onDrop={({ addedIndex, removedIndex }) => {
              dispatch({ type: "move-rule", addedIndex, id, removedIndex });
            }}
          >
            {rules.map((rule, position) =>
              readonly ? (
                <Rule id={rule.id} level={level} position={position} rule={rule} readonly={readonly} />
              ) : (
                <Draggable key={rule.id}>
                  <Rule id={rule.id} level={level} position={position} rule={rule} readonly={readonly} />
                </Draggable>
              ),
            )}
          </DraggableContainer>
        </Grid>
      )}
    </Grid>
  ) : (
    <span />
  );
};

RuleGroup.defaultProps = {
  combinator: "and",
  combinators: [
    { label: "AND", value: "and" },
    { label: "OR", value: "or" },
  ],
  rules: [],
};

RuleGroup.propTypes = {
  combinator: PropTypes.string,
  combinators: PropTypes.array,
  id: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  rules: PropTypes.array,
};

export default RuleGroup;
