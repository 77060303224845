/**
 * Helper function that checks if an array includes a value.
 * First checks if a valid array before checking for includes, to avoid runtime errors
 *
 * @param {*[]} array - the array to do an includes check on
 * @param {*} value - the value to look for
 * @returns {boolean}
 */
export const arrayIncludes = (array, value) => {
  // If input is not an array, return false
  if (!Array.isArray(array)) {
    return false;
  }

  // If input is an array and includes the value, return true
  if (array?.includes(value)) {
    return true;
  }

  // By default, return false
  return false;
};
