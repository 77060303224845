import React from "react";

import { getAllLinkedItems } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { normalizeVulnerabilityData } from "../../../../../../Testing/Vulnerabilities/functions/normalizeVulnerabilityData";
import { useVulnerabilityDataGrid } from "../../../../../../Testing/Vulnerabilities/hooks/useVulnerabilityDataGrid";
import { listSystemTargetLinksBySystemID_vulnerabilities } from "../queries/listSystemTargetLinksBySystemID";

/**
 * Displays all vulnerabilities associated with a systems through a target
 */
const SystemVulnerabilities = ({ itemRef, organizationID }) => {
  const item = itemRef?.current;

  const grid = useVulnerabilityDataGrid({
    organizationID,
    createResourceComponent: null,
    query: listSystemTargetLinksBySystemID_vulnerabilities,
    variables: {
      systemID: item?.id,
    },
    persistenceUUID: "acb142cd-90dc-4164-82e7-b8659dad6fee",
    normalizeData: (systemTargetLinks) => {
      const targets = getAllLinkedItems({
        links: systemTargetLinks,
        connectionField: "target",
      });

      const allTargetVulnerabilityLinks = [];
      for (const target of targets) {
        const targetVulnerabilityLinks = target?.vulnerabilities?.items;
        if (Array.isArray(targetVulnerabilityLinks)) {
          allTargetVulnerabilityLinks.push(...targetVulnerabilityLinks);
        }
      }

      const vulnerabilities = getAllLinkedItems({
        links: allTargetVulnerabilityLinks,
        connectionField: "vulnerability",
      });

      return normalizeVulnerabilityData({
        vulnerabilities,
        calculatePriority: true,
      });
    },
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(SystemVulnerabilities);
