import { getResourceAttribute } from "./getResourceAttribute";

export interface GetResourceRouteParams {
  item?: object;
  override?: string | (({ item }) => string);
  routeType: string;
  typename: string;
  prefix?: string | number;
  suffix?: string | number;
  applyPrefixToOverride?: boolean;
  applySuffixToOverride?: boolean;
}

/**
 * Finds and formats the destination string for a resource based on available definition information
 * @param item - the item for which a route is being retrieved (passed into override function - if a function)
 * @param override - the value to return if it is not null or undefined
 * @param routeType - the location where the route is supposed to take the user
 * @param typename - the name of the resource for which a route is being retrieved
 * @param [prefix=""] - the string to prepend to the original route
 * @param [suffix=""] - the string to append to the original route
 * @param [applyPrefixToOverride=false] - if TRUE prefix will be added to override at start
 * @param [applySuffixToOverride=false] - if TRUE suffix will be added to override at end
 */
export const getResourceRoute = ({
  item,
  override,
  routeType,
  typename,
  prefix = "",
  suffix = "",
  applyPrefixToOverride = false,
  applySuffixToOverride = false,
}: GetResourceRouteParams): string => {
  const suffixString = String(suffix);
  const prefixString = String(prefix);

  if (typeof override === "function") {
    return override({ item });
  } else if (override) {
    const overrideSuffix = applySuffixToOverride ? suffixString : "";
    const overridePrefix = applyPrefixToOverride ? prefixString : "";
    const currentRoute = `${overridePrefix}${override}`;

    return applySuffix({ currentRoute, suffix: overrideSuffix });
  }

  const definitionRoute = getResourceAttribute({
    typename,
    attribute: `routes.${routeType}`,
  });

  const currentRoute = definitionRoute ? `${prefix}${definitionRoute}` : "";

  return applySuffix({ currentRoute, suffix: suffixString });
};

const applySuffix = ({ currentRoute, suffix }: { currentRoute: string; suffix: string }): string => {
  if (!currentRoute || !suffix) {
    return currentRoute;
  }

  const leadingSlashesRemovedSuffix = suffix.replace(/^\/+/, "");
  const addSlash = !currentRoute.endsWith("/");

  return `${currentRoute}${addSlash ? "/" : ""}${leadingSlashesRemovedSuffix}`;
};
