"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSmartValues = void 0;
const smartValueFilters_1 = require("../../constants/smartValueFilters");
/**
 * Performs custom filters on an array of smart value templates
 * @param {string[]} smartValues - the smart value list to filter
 * @param {string[]} filters - smart value filters to apply, see constants -> smartValueFilters.js
 * @return {string[]} - the filtered smart value list
 */
const filterSmartValues = ({ smartValues, filters = [] }) => {
    let filteredSmartValues = [...smartValues];
    //Perform automation step filter - all smart values that start with `steps.`
    if (filters.includes(smartValueFilters_1.smartValueFilters.onlyAutomationSteps)) {
        filteredSmartValues = filteredSmartValues.filter((smartValue) => {
            return smartValue.startsWith("steps.");
        });
    }
    return filteredSmartValues;
};
exports.filterSmartValues = filterSmartValues;
