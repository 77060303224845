import { useEffect, useState } from "react";

import { isNullOrUndefined, sortObjectsByField } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Fetches an array of Risk Controls that are Non-Standard for a specific System
 * @param {string} selectedSystemID - preselected system for a risk change
 * @param {object[]} systems - all the systems available for risk changes
 * @param {string} organizationID - currently selected organization
 * @param {object} system - system data to check for changes to refresh selection options
 * @param {function} filterRiskControls - custom filter function through which to pass through risk controls
 * that are available for selection
 * @returns {{riskControls: *[]}}
 */
export const useSystemNonStandardControls = ({
  selectedSystemID,
  systems,
  organizationID,
  system,
  filterRiskControls,
}) => {
  const [riskControls, setRiskControls] = useState([]);

  const [isLoading, setIsLoading] = useBoolean(false);

  // When a system is selected, query full information for non-standard controls for dropdown
  useEffect(() => {
    const getControlData = async () => {
      setIsLoading(true);

      if (!isNullOrUndefined(selectedSystemID)) {
        const systemObject = systems.find((item) => item.id === selectedSystemID);

        if (Array.isArray(systemObject?.riskControlOverrides)) {
          let res = [];

          const { getQuery } = generateGraphql("RiskControl", [
            "statementNumber",
            "name",
            "implementationRating",
            "costOfControl",
            "strengthRating",
          ]);

          const riskControlRequests = [];
          for (const riskControlOverride of systemObject.riskControlOverrides) {
            if (!isNullOrUndefined(riskControlOverride?.riskControlId)) {
              const requestFunction = async () => {
                const controlData = await ItemQuery(getQuery, riskControlOverride.riskControlId);
                res.push({
                  ...controlData,
                  ...riskControlOverride,
                });
              };
              riskControlRequests.push(requestFunction());
            }
          }

          await Promise.allSettled(riskControlRequests);

          //filter the risk controls if a filter function is provided
          if (typeof filterRiskControls === "function") {
            res = filterRiskControls(res);
          }

          setRiskControls(res);
        }
      } else {
        const res = await ListQueryBy({
          query: riskControlByOwnerGroup,
          limit: 1000,
          variables: {
            ownerGroup: organizationID,
          },
        });
        let sortedRes = res;
        try {
          sortedRes = sortObjectsByField(res, "statementNumber");
        } catch (e) {
          ErrorLogger("Unable to sort objects by statement number for RiskControls", e);
        }

        //filter the risk controls if a filter function is provided
        if (typeof filterRiskControls === "function") {
          sortedRes = filterRiskControls(sortedRes);
        }

        setRiskControls(sortedRes);
      }

      setIsLoading(false);
    };

    getControlData();
  }, [selectedSystemID, system, systems]);

  return {
    riskControls,
    isLoading,
  };
};

const riskControlByOwnerGroup = /* GraphQL */ `
  query RiskControlByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRiskControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riskControlByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statementNumber
        name
        implementationRating
        costOfControl
        strengthRating
      }
      nextToken
    }
  }
`;
