import { useContext, useEffect, useState } from "react";

import { OrganizationContext } from "./OrganizationContext";

/**
 * Hook that pulls Operation Team User information from Context
 *
 * @returns {{
 *  operationTeamID: (null|string),
 *  isOperationTeamUser: (() => boolean),
 *  accountType: (string)
 *  isOperationTeamUserState: (boolean),
 * }}
 */
export const useOperationTeamCheck = () => {
  const context = useContext(OrganizationContext);

  const accountType = context.accountType;
  const operationTeamID = context.operationTeamID;
  const isInOperationTeamGroup = context.isInOperationTeamGroup;

  /**
   * Checks if the user is an operation team member.
   *
   * Pulls info out of context, must have:
   * - valid operationTeamID
   * - isInOperationTeamGroup = true
   * - accountType = "operationTeamMember"
   *
   * @returns {boolean}
   */
  const isOperationTeamUser = () => {
    return operationTeamID && isInOperationTeamGroup && accountType === "operationTeamMember";
  };

  const [isOperationTeamUserState, setIsOperationTeamUserState] = useState(false);
  useEffect(() => {
    setIsOperationTeamUserState(isOperationTeamUser());
  }, [operationTeamID, accountType, isInOperationTeamGroup]);

  return {
    context,
    accountType,
    operationTeamID,
    isInOperationTeamGroup,
    isOperationTeamUser,
    isOperationTeamUserState,
  };
};
