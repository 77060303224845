import { makeStyles } from "@mui/styles";

/**
 * Custom Styles for the dashboards
 * @type {(props?: any) => ClassNameMap<string>}
 */
export const useCustomDashboardStyles = makeStyles({
  customCursor: {
    cursor: "pointer",
  },
});
