import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import DashboardCardSection from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCardSection";
import { useAutomationsGrid } from "../hooks/useAutomationsGrid";

/**
 * Re-usable button component to display and configure Automations for a specific resource item
 * @param {string} itemId - the ID of the resource to display automations for (like an Evidence, System, etc)
 * @param {string} organizationID
 * @param {string} typename - the typename of the resource, used to find related automations
 * @returns {JSX.Element}
 * @constructor
 */
const AutomationsButton = ({ itemId, organizationID, typename, style = {} }) => {
  const modal = useModal(
    `Automations for ${convertCamelCaseToSentence(typename)}: ${itemId}`,
    <ItemAutomationDetails itemId={itemId} organizationID={organizationID} typename={typename} />,
    <Button style={style} startIcon={<i className={"icon-energy"} />}>
      Automations
    </Button>,
    { width: "75vw" },
  );

  return <span>{modal.modalButton}</span>;
};

const ItemAutomationDetails = ({ itemId, organizationID, typename, ...props }) => {
  const [filter, setFilter] = useState({
    itemId: { eq: itemId },
    typename: { eq: typename },
  });

  const grid = useAutomationsGrid({
    organizationID,
    itemId,
    filter,
    typename,
    query: null,
    ...props,
  });

  const form = useForm({
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      filterByItemId: {
        label: `Filter by This particular ${convertCamelCaseToSentence(typename)}`,
        inputType: "switch",
        defaultValue: true,
      },
      filterByTypename: {
        label: `Filter by ${convertCamelCaseToSentence(typename)}`,
        inputType: "switch",
        defaultValue: true,
      },
    },
  });

  useEffect(() => {
    if (form?.input && typeof form?.input === "object" && Object.keys(form?.input).length !== 0) {
      const { listQuery } = generateGraphql("Automation", ["name", "itemId", "enabled"]);

      ListQuery({
        query: listQuery,
        organizationID,
        filters: {
          itemId: form?.input?.filterByItemId
            ? {
                eq: itemId,
              }
            : undefined,
          typename: form?.input?.filterByTypename
            ? {
                eq: typename,
              }
            : undefined,
        },
      }).then((data) => {
        grid.setData([...data]);
      });
    }
  }, [JSON.stringify(form?.input)]);

  return (
    <div>
      <DashboardCardSection>
        <div>
          {form.display}
          <div style={{ height: "40em" }}>{grid.display}</div>
        </div>
      </DashboardCardSection>
    </div>
  );
};

export default withOrganizationCheck(AutomationsButton);
