import React from "react";

import { useUIContext } from "@utils/Context/UIContext";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { GenericDeleteButton } from "../../../../../../../utils/GenericComponents/GenericDeleteButton";
import { performToastOperation } from "../../../../../../../utils/Toasts/functions/toastOperation";
import { updateSystemVendorSolution } from "../functions/updateSystemVendorSolution";

import AddVendorSolutionToSystem from "./AddVendorSolutionToSystem";
import SystemVendorReviewsGrid from "./SystemVendorReviewsGrid";

/**
 * @description System Accordion list item
 * @param {object} system - a system
 * @param {function} resetFunction - function to fetch data from database
 * @param {boolean} isLoading - true if data is loading
 * @returns {JSX.Element}
 * @constructor
 */
const SystemVendorReviews = ({ system, resetFunction, isLoading }) => {
  const { addToast, updateToast } = useUIContext();

  return (
    <div>
      <div>
        Vendor Solution: <strong>{system?.vendorSolution?.name ?? "None"}</strong>
        {system?.vendorSolution ? (
          <GenericDeleteButton
            confirmationMessage={"Are you sure you want to unlink this vendor solution?"}
            buttonTitle="Unlink Vendor Solution from this Information System"
            deleteFunction={async () => {
              await performToastOperation({
                addToast,
                updateToast,
                operation: async () => {
                  await updateSystemVendorSolution({
                    system,
                    vendorSolution: null,
                    resetFunction,
                  });
                },
                inProgressText: "Unlinking Vendor Solution...",
                failedText: "Failed to unlink the vendor solution",
                successText: "Successfully unlinked the vendor solution",
                iconColor: "danger",
              });
            }}
          />
        ) : (
          <AddVendorSolutionToSystem system={system} reset={resetFunction} />
        )}
      </div>
      <br />
      {system?.vendorSolution ? (
        <div style={{ height: "30em" }}>
          <SystemVendorReviewsGrid item={system} resetFunction={resetFunction} isLoading={isLoading} />
        </div>
      ) : (
        <p style={{ fontStyle: "italic", fontSize: ".9em" }}>No Vendor Solution Attached</p>
      )}
    </div>
  );
};

export default withOrganizationCheck(SystemVendorReviews);
