import { useContext } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import {
  handleDefaultOperations,
  handleRoleTypes,
  modules,
  precedenceTypes as PRECEDENCE_TYPES,
  resources,
  roleConfigDefault,
} from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { cognitoRoleGroups } from "../../Users/functions/cognitoRoleGroups";
import { getPrecedenceDescription } from "../functions/getPrecedenceDescription";

/**
 * @description Allows the user to create a new Role
 * @param {string} organizationID - the Organization ID to display the role for
 * @param {string} module - the module name
 * @param {string} resource - the resource name
 * @param {function} toggleModal - the function to toggle the modal
 * @param {function} resetFunction - the function to reset the form
 * @param {function} getNewItem - the function to get a new item
 * @param {object} formConfig - the form configuration
 * @param {int} highestPrecedence - the highest precedence level role to show in the selection grid (can use enum in role-utils)
 * @param {object} props - the props to pass to the component
 * @returns {{input: {}, display: JSX.Element}}
 */
export const useCreateRole = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.ROLE,
  toggleModal,
  resetFunction,
  getNewItem,
  formConfig = {},
  highestPrecedence,
  ...props
}) => {
  const context = useContext(OrganizationContext);

  /**
   * Override the form submitFunction with lambda call for creating new Role
   * @param input
   * @returns {Promise<Array>}
   */
  const submitFunctionInternal = async (input) => {
    // Filters roleConfig object based on Role Type (precedence)
    const roleConfig_withTypeFilter = handleRoleTypes(roleConfigDefault, input.precedence);

    // Sets the default Resource-level permissions based on Role Type (precedence)
    const roleConfig_withDefaults = handleDefaultOperations(roleConfig_withTypeFilter, input.precedence);

    const params = {
      type: "createRole",
      role: {
        ...input,
        ownerGroup: organizationID,
        roleConfig: JSON.stringify(roleConfig_withDefaults),
      },
    };

    try {
      /**
       * Create a new Role
       */
      const res = await cognitoRoleGroups(params);

      const json = JSON.parse(res);
      getNewItem?.(json?.body);
      resetFunction?.();
      toggleModal?.();

      return json?.body;
    } catch (e) {
      ErrorLogger("Can not parse response from the cognitoRoleGroups lambda");
    }
  };

  return useForm({
    typename: "Role",
    enableToast: true,
    enableTemplates: true,
    submitFunction: submitFunctionInternal,
    organizationID,
    module,
    resource,
    toggleModal,
    resetFunction,
    mutation: generateGraphql("Role", ["name", "description", "precedence"]).createMutation,
    fieldConfig: {
      name: {
        label: "Role Name",
        tooltip: "Enter a name",
        required: true,
      },
      description: {
        label: "Description",
        tooltip: "Enter a description",
      },
      precedence: {
        inputType: "dropdown",
        label: "Role Type",
        tooltip: `Please select a role type`,
        required: true,
        dropdownConfig: {
          data: Object.entries(PRECEDENCE_TYPES)
            .filter(([type, value]) => value >= context?.role?.precedence)
            .filter(([type, value]) => {
              if (isNullOrUndefined(highestPrecedence)) return true; // If highestPrecedence is null, return all role types
              return value >= highestPrecedence;
            }) // Filter out roles with a precedence lower than the highest precedence
            .map(([type, value]) => {
              return {
                value: value,
                text: `${type} - ${getPrecedenceDescription(value)}`,
              };
            }),
        },
      },
    },
    ...formConfig,
  });
};
