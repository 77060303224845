import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Converts an importer field value into a decimal number for the database.
 *
 * Converts whole numbers to decimal (e.g. ' 75'  →  0.75)
 *
 * Accepts a whole number with percent % symbol (e.g. ‘75%’ → 0.75)
 *
 * Parses decimal numbers directly (e.g. ‘0.75’ → 0.75)
 *
 * If not required and NaN, returns a blank value
 *
 * If required and NaN, stops row process
 *
 *
 * @param {string|number} value
 * @returns {string|number|*}
 */
export const handlePercentFormat = ({ value }) => {
  if (!isNullOrUndefined(value)) {
    if (typeof value === "string") {
      // handle string with percent symbol: '75%'
      // strip percent symbol, convert whole number to decimal
      if (value.includes("%")) {
        const removedPercentSymbol = value.replaceAll("%", "");
        const wholeNumber = parseFloat(removedPercentSymbol);
        const convertedToDecimal = wholeNumber / 100;

        // Check if proper number
        if (!isNaN(convertedToDecimal)) {
          return convertedToDecimal;
        }
        // If improper number, just returns a blank string. The other part of the function will pick up on required fields
        else {
          return "";
        }
      }
      // handle string that is a whole number, assume it needs to be converted to decimal: '75'
      else if (!value.includes(".")) {
        const wholeNumber = parseFloat(value);
        const convertedToDecimal = wholeNumber / 100;

        // Check if proper number
        if (!isNaN(convertedToDecimal)) {
          return convertedToDecimal;
        }
        // If improper number, just returns a blank string. The other part of the function will pick up on required fields
        else {
          return "";
        }
      }
      // handle string that is decimal, just parse directly to decimal
      else {
        const parsedDecimal = parseFloat(value);

        // Check if proper number
        if (!isNaN(parsedDecimal)) {
          return parsedDecimal;
        }
        // If improper number, just returns a blank string. The other part of the function will pick up on required fields
        else {
          return "";
        }
      }
    }

    // handle direct decimal input
    // Check if proper number
    if (!isNaN(value)) {
      return value;
    }
    // If improper number, just returns a blank string. The other part of the function will pick up on required fields
    else {
      return "";
    }
  }
};
