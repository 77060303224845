import { Alert } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Button, CardBody, Col, CustomInput, Form, FormGroup, Input, Label } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, precedenceTypes as PRECEDENCE_TYPES, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { getCognitoPhoneNumber } from "../../../../utils/Functions/Number/getCognitoPhoneNumber";
import { PasswordGenerator } from "../../../../utils/Functions/PasswordGenerator";
import { MuiSubmitButton } from "../../../../utils/GenericComponents/buttons/MuiSubmitButton";
import { CheckEmailRegex, CheckPhoneNumberRegex } from "../../../../utils/Regex/Regex";
import { useRoleDataGrid } from "../../Roles/hooks/useRoleDataGrid";
import { createPointOfContact } from "../functions/createPointOfContact";
import { useUploadPointOfContact } from "../hooks/useUploadPointOfContact";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *  - 7/6/20  JB: Added a check for Existing Users
 *
 * Description: A Card component.
 *              A Form for adding a single Point Of Contact to the DB for an Organization.
 */
const AddSinglePointOfContact = (props) => {
  const { organizationID, toggleModal, resetFunction } = props;

  const context = useContext(OrganizationContext);

  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.POINT_OF_CONTACT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const csvImporter = useUploadPointOfContact({
    organizationID,
    toggleModal,
    resetFunction,
  });

  const roleGrid = useRoleDataGrid({
    module,
    resource,
    organizationID: props.organizationID,
    queryConfig: {
      query: null,
    },
    gridConfig: {
      enableSelectButton: true,
      onSelectCallback: (role) => setSelectedRole(role),
    },
    height: "30em",
  });

  useEffect(() => {
    if (roleGrid && context?.role) {
      roleGrid.setIsLoading(true);
      const precedence = context?.role?.precedence;
      const { listQuery } = generateGraphql("Role", ["name", "precedence"]);
      ListQuery({
        query: listQuery,
        organizationID,
      }).then((list) => {
        roleGrid?.setData(list.filter((x) => precedence <= PRECEDENCE_TYPES.MANAGER || x.precedence > precedence));
        roleGrid.setIsLoading(false);
      });
    }
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState(PasswordGenerator());
  const [addNewUser, setAddNewUser] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);

  useEffect(() => {
    setValidEmail(CheckEmailRegex(email));
  }, [email]);

  useEffect(() => {
    setValidPhoneNumber(CheckPhoneNumberRegex(getCognitoPhoneNumber(phoneNumber)));
  }, [phoneNumber]);

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setTitle("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
  };

  const isSubmitEnabled = firstName !== "" && lastName !== "";

  const { addToast, updateToast } = useContext(UIContext);

  const handleAddNewUser = async () => {
    if (addNewUser) {
      setAddNewUser(false);
    } else {
      setAddNewUser(!addNewUser);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <p>Add a Point of Contact</p>
        </div>
        <span className="float-right">{csvImporter.modalButton}</span>
      </div>
      <CardBody style={{ padding: 0 }}>
        <Form className="form-horizontal">
          <FormGroup row>
            <Col xs="12">
              <Label for="firstName">
                First Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="off"
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label for="lastName">
                Last Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="off"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                autoComplete="off"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                id="phone_number"
                placeholder="always include area code, include country code starting with a '+' if an international number"
                value={phoneNumber}
                onChange={(data) => setPhoneNumber(data && data.target && data.target.value)}
                autoComplete="off"
                required
              />
            </Col>
          </FormGroup>
          {phoneNumber && email ? null : "Email and Phone number are required for Application Access"}
          {checkPermissionsHook.resource.create && phoneNumber && email && validEmail && validPhoneNumber && (
            <>
              <FormGroup row>
                <Col xs="12">
                  <Label for="add-user">Add Platform Access</Label>
                  <CustomInput
                    type="switch"
                    id="add-user"
                    name="add-user"
                    checked={addNewUser}
                    onChange={() => handleAddNewUser()}
                  />
                </Col>
              </FormGroup>
              {addNewUser ? (
                <div>
                  <Alert severity={"warning"} style={{ marginBottom: "1em" }}>
                    Warning! If the switch is on, a new User will be created and they will have login credentials to
                    login into to the app.
                  </Alert>
                  {selectedRole ? (
                    <SelectedRole selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
                  ) : (
                    roleGrid.display
                  )}
                </div>
              ) : null}
            </>
          )}
        </Form>
      </CardBody>
      <div
        style={{
          marginTop: ".5em",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <MuiSubmitButton
          id="button-submit-pointOfContact"
          type="submit"
          disabled={!isSubmitEnabled}
          onClick={async () => {
            const toastId = addToast({
              header: `Creating Point Of Contact...`,
              icon: "spinner",
              color: "success",
            });

            await createPointOfContact({
              firstName,
              lastName,
              title,
              email,
              phoneNumber: getCognitoPhoneNumber(phoneNumber),
              password,
              addNewUser: addNewUser,
              organizationID: props.organizationID,
              role: selectedRole,
              resetFunction,
              sentryTrace: context?.sentryTrace,
            }).then((pointOfContact) => {
              if (pointOfContact?.id) {
                updateToast({
                  id: toastId,
                  header: `Point Of Contact ${`${pointOfContact.firstName ? pointOfContact.firstName : ""} ${
                    pointOfContact.lastName ? pointOfContact.lastName : ""
                  }`} was successfully created!`,
                  icon: "success",
                });
                props.getNewItem && props.getNewItem(pointOfContact);
              } else {
                updateToast({
                  id: toastId,
                  header: `Point Of Contact was NOT successfully created!`,
                  icon: "danger",
                });
              }
            });
            resetState();
            props.toggleModal && props.toggleModal();
          }}
        >
          <i className="fa fa-dot-circle-o" /> Submit
        </MuiSubmitButton>
      </div>
    </div>
  );
};

/**
 * UI that shows the currently selected Role for the form, with an option to reset the selection
 * @param selectedRole
 * @param setSelectedRole
 * @returns {JSX.Element}
 * @constructor
 */
const SelectedRole = ({ selectedRole, setSelectedRole }) => {
  return (
    <div>
      Selected Role:{" "}
      <strong>
        {selectedRole?.name}
        <Button
          title={"Resets the currently selected Role"}
          className={"float-right"}
          size={"sm"}
          onClick={() => setSelectedRole(undefined)}
        >
          Reset Role
        </Button>
      </strong>
    </div>
  );
};

AddSinglePointOfContact.propTypes = {
  organizationID: PropTypes.string.isRequired,
};

export default withOrganizationCheck(AddSinglePointOfContact);
