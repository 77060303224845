import {
  Category,
  ChartComponent,
  ColumnSeries,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import React, { useEffect, useState } from "react";

/**
 *
 * @param item {object} GenericActionItem
 * @return {JSX.Element}
 * @constructor
 */
const PriorityHistory = ({ item }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (item.priorityHistory && Array.isArray(item.priorityHistory)) {
      const sortedArray = item.priorityHistory.sort((a, b) => b.date - a.date);
      const dataArray = [];
      for (const history of sortedArray) {
        dataArray.push({
          date: new Date(history?.date).toLocaleString(),
          value: history?.value,
        });
      }

      setChartData(dataArray);
    }
  }, [item && item.priorityHistory && JSON.stringify(item.priorityHistory)]);

  return (
    <div style={{ width: "100%" }}>
      {chartData && chartData.length > 0 ? (
        <ChartComponent
          id="action-priority-history-chart"
          data-testid={`action-priority-history-chart-${item?.id}`}
          primaryXAxis={{ valueType: "Category" }}
          tooltip={{ enable: true }}
        >
          <Inject services={[ColumnSeries, Tooltip, LineSeries, Category]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="date"
              yName="value"
              name="History"
              animation={{ enable: true, duration: 1200, delay: 100 }}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      ) : (
        ""
      )}
    </div>
  );
};

export default PriorityHistory;
