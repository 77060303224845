import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../hooks/views/useModal";
import KeyPerformanceIndicatorDetails from "../../../../../Metrics/KeyPerformanceIndicators/components/KeyPerformanceIndicatorDetails";

/**
 * Displays timeline information about an Evidence being switched to the 'expired' status
 * @returns {JSX.Element}
 * @constructor
 */
const ExpiredActivity = ({ keyPerformanceIndicator }) => {
  //Key performance indicator specific
  const checkKPIPermissions = useCheckPermissions({
    module: modules.METRICS,
    resource: resources.KEY_PERFORMANCE_INDICATOR,
  });
  const kpiPreviewModal = useModal(
    "Key Performance Indicator Details",
    <KeyPerformanceIndicatorDetails item={keyPerformanceIndicator} tableDisplay={true} />,
    null,
    {
      width: "70vw",
    },
  );

  return (
    <span style={{ fontSize: ".9em" }}>
      <span>Evidence status switched to 'Not in Place' </span>
      {keyPerformanceIndicator && <span>because of </span>}
      {keyPerformanceIndicator && checkKPIPermissions.resource.read && (
        <span
          onClick={() => {
            kpiPreviewModal.setModalIsOpen(true);
          }}
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          a KPI
        </span>
      )}
      {keyPerformanceIndicator && !checkKPIPermissions.resource.read && <span>a KPI</span>}
      {kpiPreviewModal.modal}
    </span>
  );
};

export default ExpiredActivity;
