import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Finds the most recently created action item for a point of contact and returns it
 * @param {object[]} actionItems - the action items to search through
 * @param {string} [evidenceID] - the evidence ID to filter by
 * @return {null|object} - the most recent action item matching the evidence id
 */
export const getPointOfContactMostRecentActionItem = ({ actionItems, evidenceID }) => {
  // check arguments
  if (!Array.isArray(actionItems)) {
    return null;
  }

  // filter action items that are related to this evidence
  let filteredActionItems = [];
  if (evidenceID) {
    filteredActionItems = actionItems.filter((actionItem) => {
      const actionItemData = actionItem?.data && JSON.parse(actionItem?.data);

      return !isNullOrUndefined(actionItemData) && actionItemData?.evidenceID === evidenceID;
    });
  } else {
    filteredActionItems = actionItems;
  }

  // sort the filtered action items by created date (ascending)
  filteredActionItems.sort((a, b) => {
    const aDate = new Date(a?.createdAt);
    const bDate = new Date(b?.createdAt);

    return bDate - aDate;
  });

  // if one exists set the first item as the action item
  if (filteredActionItems.length > 0) {
    return filteredActionItems[0];
  } else {
    return null;
  }
};
