"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatBarChartWidgetData = void 0;
const formatPieChartWidgetData_1 = require("./formatPieChartWidgetData");
/**
 * Formats the data source for the bar widget from custom queries defined in the config
 * @param {object} config - the parsed widget config object from Custom Widget
 * @param {object[]} customQueries - converts gathered raw data into the data source used by the bar widget
 * @returns {*[]|null} - null if data is not available, otherwise the data source for the bar widget
 */
const formatBarChartWidgetData = ({ config, customQueries }) => {
    const pieDataSource = (0, formatPieChartWidgetData_1.formatPieChartWidgetData)({ config, customQueries });
    if (!pieDataSource) {
        return null;
    }
    //While Stacked Bar Chart is not Supported we can use the Pie Chart data to create a Bar Chart data
    const barDataSource = [];
    for (const item of pieDataSource) {
        const { id, label, value, color } = item || {};
        barDataSource.push({
            id,
            name: label,
            values: [
                {
                    key: "value",
                    value: Math.round(value),
                    color,
                },
            ],
        });
    }
    return barDataSource;
};
exports.formatBarChartWidgetData = formatBarChartWidgetData;
