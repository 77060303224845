import { usePointOfContactDataGrid } from "../../../../views/OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";
import { genericResources } from "../../genericResources";

export const pointOfContactDefinition = {
  routes: {
    grid: "organization_manager/contacts",
    details: "organization_manager/contacts",
    helpCenter: "points-of-contact",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:people",

  //[COMPONENTS]
  gridHook: usePointOfContactDataGrid,

  //[QUERIES]
  queries: {
    functions: [
      {
        name: "getFullName",
        description: "Get the full name of the point of contact",
        result: {
          type: genericResources?.STRING,
          name: "fullName",
          description: "The full name of the point of contact",
        },
      },
    ],
  },
};
