import cronParser from "cron-parser";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { EVIDENCE_STATUS } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { createActionItem } from "../../../Program/Actions/functions/createActionItem";
import { createObservation } from "../../../Program/Observations/functions/createObservation";
import { createRecommendation } from "../../../Program/Recommendations/functions/createRecommendation";

const actionsByPointOfContact = /* GraphQL */ `
  query ActionsByPointOfContact(
    $genericActionItemPointOfContactId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGenericActionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsByPointOfContact(
      genericActionItemPointOfContactId: $genericActionItemPointOfContactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        module
        data
        genericActionItemPointOfContactId
        ownerGroup
      }
      nextToken
    }
  }
`;

/**
 * @description This function is called when a point of contact gets assigned to an evidence.
 * @param {object} pointOfContact - The point of contact to assign.
 * @param {object} evidence - The evidence object.
 * @returns {Promise<void>}
 */
export const evidenceOnSelectPointOfContactCreateActions = async ({
  pointOfContact: propsPointOfContact,
  evidence: propsEvidence,
}) => {
  if (!propsPointOfContact?.id) {
    ErrorLogger("[evidenceOnSelectPointOfContactCreateActions.js] Missing pointOfContact");
    return;
  }

  if (!propsEvidence?.id) {
    ErrorLogger("[evidenceOnSelectPointOfContactCreateActions.js] Missing evidence");
    return;
  }

  const { getQuery: getEvidence } = generateGraphql(
    "Evidence",
    ["name", "status", "enabled", "frequency", "observations"],
    {
      observations: `(limit: 100) { nextToken items { id module resource field recommendations (limit: 100) { items { recommendation { id } } } } }`,
    },
  );

  const evidence = await GetQuery({
    query: getEvidence,
    variables: {
      id: propsEvidence?.id,
    },
  });

  /**
   * Check if evidence status is expired or expired soon and create an action item
   */
  if (
    evidence?.enabled &&
    (evidence?.status === EVIDENCE_STATUS.EXPIRING_SOON || evidence?.status === EVIDENCE_STATUS.EXPIRED)
  ) {
    const actions = await ListQueryBy({
      query: actionsByPointOfContact,
      variables: {
        genericActionItemPointOfContactId: propsPointOfContact?.id,
      },
    });

    const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["user"], {
      user: `{ id }`,
    });

    /**
     * Get point of contact user
     */
    const pointOfContact = await GetQuery({
      query: getPointOfContact,
      variables: {
        id: propsPointOfContact?.id,
      },
    });

    let foundActionItem = false;
    if (Array.isArray(actions) && actions?.length > 0) {
      /**
       * Try to find an existing action item
       */
      for (const action of actions) {
        try {
          if (action?.module === "compliance") {
            const actionData = JSON.parse(action?.data);
            if (actionData?.evidenceID === evidence?.id) {
              InfoLogger(
                `[evidenceOnSelectPointOfContactCreateActions.js] Found action item for point of contact: ${action?.id}, skipping creation.`,
              );
              foundActionItem = true;
            }
          }
        } catch (error) {
          ErrorLogger("[evidenceOnSelectPointOfContactCreateActions.js] Cannot get action item from point of contact");
        }
      }
    }

    /**
     * If action item is not found, create it
     */
    if (foundActionItem === false) {
      InfoLogger(
        `[evidenceOnSelectPointOfContactCreateActions.js] Creating action item for point of contact: ${pointOfContact?.id}`,
      );

      /**
       * Generate action item name based on Evidence type
       */
      let actionItemName = "";

      switch (evidence?.type) {
        case "document":
          actionItemName = `Upload a document for Evidence: "${evidence?.name}"`;
          break;
        case "policy":
          actionItemName = `Upload a policy for Evidence: "${evidence?.name}"`;
          break;
        case "report":
          actionItemName = `Upload a report for Evidence: "${evidence?.name}"`;
          break;
        case "attestation":
          actionItemName = `Attestation for Evidence: "${evidence?.name}"`;
          break;
        case "screenshot":
          actionItemName = `Upload a screenshot for Evidence: "${evidence?.name}"`;
          break;
        default:
          actionItemName = `Upload Evidence for "${evidence?.name}"`;
      }

      let dueDate = new Date();
      try {
        /**
         * Calculate dueDate based on the cron expression
         */
        const interval = cronParser?.parseExpression(evidence?.frequency);

        /**
         * Calculate next date based on the cron expression
         */
        dueDate = interval?.next();
      } catch (e) {
        throw Error("Can not parse dueDate");
      }

      /**
       * Create action item
       */
      const newActionItem = await createActionItem({
        name: actionItemName,
        description: `Go to "${evidence?.name}" evidence Landing Page`,
        priority: 300,
        status: "inProgress",
        module: "compliance",
        dueDate,
        data: JSON.stringify({ evidenceID: evidence?.id }),
        ownerGroup: evidence?.ownerGroup,
        genericActionItemPointOfContactId: pointOfContact?.id,
        landingPageUrl: pointOfContact?.user?.id
          ? "#/continuous_compliance/evidence_response/"
          : "#/guest/continuous_compliance/evidence_response/",
        automationType: "email",
        enableNotifications: true,
      });

      /**
       * Check if a new action item was created
       */
      if (newActionItem?.id) {
        /**
         * Link new action item to a recommendation
         */
        if (Array.isArray(evidence?.observations?.items)) {
          const observations = evidence?.observations?.items;

          /**
           * Fields that tell us if this observation is a compliance cycle observation
           */
          const resource = "evidence";
          const field = "status";

          const complianceObservations = observations.filter((x) => x?.resource === resource && x?.field === field);

          /**
           * Link compliance cycle observation -> recommendations to a new action item
           */
          if (complianceObservations?.length > 0) {
            for (const observation of complianceObservations) {
              const recommendationsLinks = observation?.recommendations?.items;
              for (const link of recommendationsLinks) {
                if (link?.recommendation?.id) {
                  const { createMutation } = generateGraphql("RecommendationActionLink");
                  await ItemMutation(createMutation, {
                    recommendationID: link?.recommendation?.id,
                    actionID: newActionItem?.id,
                    ownerGroup: evidence?.ownerGroup,
                  });
                }
              }
            }
          } else if (complianceObservations?.length === 0) {
            /**
             * Create new observation
             */
            const observation = await createObservation({
              name: `${evidence.name} evidence is ${convertCamelCaseToSentence(evidence.status, false)}`,
              module: "compliance",
              resource: resource,
              field: field,
              isFinding: false,
              customStatus: evidence.status,
              ownerGroup: evidence.ownerGroup,
              evidenceID: evidence.id,
            });

            /**
             * Create a recommendation
             */
            const recommendation = await createRecommendation({
              name: `Upload the most recent version of ${evidence.name} evidence`,
              status: "unresolved",
              module: "compliance",
              rating: "lowMedium",
              data: JSON.stringify({ evidenceID: evidence.id }),
              ownerGroup: evidence.ownerGroup,
            });

            /**
             * Link observation to a recommendation
             */
            const { createMutation } = generateGraphql("ObservationRecommendationLink", [
              "observationID",
              "recommendationID",
            ]);
            await ItemMutation(createMutation, {
              observationID: observation?.id,
              recommendationID: recommendation?.id,
              ownerGroup: evidence?.ownerGroup,
            });

            /**
             * Link recommendation to an action item
             */
            const { createMutation: createRecommendationActionLink } = generateGraphql("RecommendationActionLink", [
              "recommendationID",
              "actionID",
            ]);
            await ItemMutation(createRecommendationActionLink, {
              recommendationID: recommendation?.id,
              actionID: newActionItem?.id,
              ownerGroup: evidence?.ownerGroup,
            });
          }
        }
      }
    }
  }
};
