import { ErrorLogger } from "@utils/EventLogger";

/**
 * Creates the inner section of a list by query, requires a list of fields and nested fields to be pre-computed
 * @param {string[]} queryFields - the fields to include in the query response
 * @param {object} nestedFields - map of query fields to their nested fields
 * @returns {string|null}
 */
export const getListByFieldString = ({ queryFields, nestedFields }) => {
  //Check arguments
  if (!Array.isArray(queryFields) || typeof nestedFields !== "object") {
    ErrorLogger("Invalid arguments passed to getListByFieldString", {
      queryFields,
      nestedFields,
    });
    return null;
  }

  //Create field strings for the query
  const fieldStrings = ["id", "ownerGroup"];
  for (const queryField of queryFields) {
    if (nestedFields[queryField]) {
      fieldStrings.push(`${queryField} ${nestedFields[queryField]}`);
    } else {
      fieldStrings.push(queryField);
    }
  }
  return fieldStrings.join(" ");
};
