import FormGroup from "@mui/material/FormGroup";
import React from "react";

import { roleConfigDefault } from "@rivial-security/role-utils";

import ResourceOperationSwitch from "./ResourceOperationSwitch";

/**
 * Displays the CRUD switches for a Resource
 * @param props
 * @param {object} operations - the read/create/update/delete operation map
 * @param {function} handleChange - sets a particular operation value to true or false
 * @param {boolean} disableEdits - whether the switches are disabled or not
 * @param {string} resource - the resource that these switches are for
 * @returns {JSX.Element}
 * @constructor
 */
const ResourceOperationSwitches = ({ operations = {}, handleChange, disableEdits, resource }) => {
  const onChange = (value, operation) => {
    handleChange({
      [operation]: value,
    });
  };

  return (
    <FormGroup row>
      {filterResourceOperations(roleConfigDefault, resource).map((operation, index) => (
        <ResourceOperationSwitch
          key={`resource-operation-switch-${index}`}
          name={operation}
          checked={operations?.[operation]}
          handleChange={onChange}
          disableEdits={disableEdits}
          resource={resource}
        />
      ))}
    </FormGroup>
  );
};

/**
 * Returns an array of operations that are available for a particular resource.
 * Resource operations may be limited in the roleConfig object. If the 'operations' field is empty, returns all operations by default.
 * @param roleConfig
 * @param resource
 * @returns {[string, string, string, string]|*}
 */
const filterResourceOperations = (roleConfig, resource) => {
  const defaultOperations = ["read", "update", "create", "delete"];

  const foundResource = roleConfig.resources[resource];

  if (foundResource?.operations) {
    return foundResource.operations;
  } else {
    return defaultOperations;
  }
};

export default ResourceOperationSwitches;
