import React from "react";

/**
 * @description Wrapper hook for Syncfusion Card Component
 * @param {Object} props - The properties for the card
 * @param {string} props.id - id of the card
 * @param {JSX.Element} props.title - title of the card
 * @param {JSX.Element} props.body - body of the card
 * @return {{display: JSX.Element}}
 */
export const useCard = ({ id, title, body }) => {
  const display = <CardSyncfusion id={id} title={title} body={body} />;

  return {
    display,
  };
};

/**
 * @description Syncfusion Card Component
 * @param {string} id - id of the card
 * @param {string} title - title of the card
 * @param {string} body - body of the card
 * @return {JSX.Element}
 * @constructor
 */
const CardSyncfusion = ({ id, title = "", body = "" }) => {
  return (
    <div className="e-card" id={`card-syncfusion-${id}`} data-testid={`card-syncfusion-test-${id}`}>
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-title">{title}</div>
        </div>
      </div>
      <div className="e-card-content">{body}</div>
    </div>
  );
};

export default CardSyncfusion;
