import { ComponentClass, FunctionComponent, LazyExoticComponent } from "react";
import { RouteComponentProps } from "react-router-dom";

import { IconName } from "@components/Ions/icons/components/Icons";

export type GetPageTitleFunction = ({ resourceID }: { resourceID?: string }) => Promise<string | null>;

export enum NavigationSectionName {
  SERVICES = "services",
  TOOLS = "tools",
  OTHER = "other",
  MANAGEMENT = "management",
}

export interface NavigationModule {
  moduleName: string;
  sectionName: NavigationSectionName;
  name: string;
  icon: IconName;
  path: string;
  defaultRoutePath: string;
  deprecatedPaths?: string[];
  routes: NavigationRoute[];
}

export interface NavigationRoute {
  path: string;
  deprecatedPaths?: string[];
  name: string;
  component: LazyExoticComponent<ComponentClass | FunctionComponent>;
  requiredModules?: string[];
  isDynamic?: boolean;
  navigationBar?: {
    name?: string;
    hidden?: boolean;
  };
  getTitle?: GetPageTitleFunction;
}

export type RouteComponentWithID = RouteComponentProps<{ id: string }>;
