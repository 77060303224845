import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useEvidenceControlLinking } from "../hooks/useEvidenceControlLinking";

/**
 * UI for linking evidence and controls
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceControlLinking = (props) => {
  return useEvidenceControlLinking(props).display;
};

export default withOrganizationCheck(EvidenceControlLinking);
