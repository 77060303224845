import { useDocumentViewer } from "../useDocumentViewer";

/**
 * @description Display a document using Syncfusion document editor
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentViewer = (props) => useDocumentViewer(props).display;

export default DocumentViewer;
