"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyFilterOnArray = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const lodash_1 = require("lodash");
const applyRuleOnArray_1 = require("./applyRuleOnArray");
/**
 * Applies a filter object from MUI builder
 * @param {object[]} items - the items to filter
 * @param {object} filter - filter object from MUI builder
 * @returns {*|*[]}
 */
const applyFilterOnArray = ({ items, filter }) => {
    if (!(0, func_utils_1.isNonEmptyArray)(items)) {
        return items;
    }
    if (!filter) {
        console.log("[applyFilterOnArray] - Invalid filter object - ", { filter });
        return items;
    }
    const ruleCombinator = filter?.combinator || "and";
    const rules = filter?.rules || [];
    if (!(0, func_utils_1.isNonEmptyArray)(rules)) {
        return items;
    }
    if (ruleCombinator === "and") {
        let filteredItems = items;
        let newFilteredItems = [];
        for (const rule of rules) {
            const field = rule?.field;
            const operator = rule?.operator;
            const filterValue = rule?.value;
            if (!field || !operator) {
                continue;
            }
            newFilteredItems = (0, applyRuleOnArray_1.applyRuleOnArray)({
                items: filteredItems,
                field,
                operator,
                filterValue,
            });
            filteredItems = newFilteredItems;
            newFilteredItems = [];
        }
        return filteredItems;
    }
    else if (ruleCombinator === "or") {
        const itemsToKeep = new Set();
        const unfilteredItems = (0, lodash_1.cloneDeep)(items);
        for (const unfilteredItem of unfilteredItems) {
            unfilteredItem.uniqueRivialFilterID = unfilteredItem.uniqueRivialFilterID || unfilteredItem.id || Math.random();
        }
        for (const rule of rules) {
            const field = rule?.field;
            const operator = rule?.operator;
            const filterValue = rule?.value;
            if (!field || !operator) {
                continue;
            }
            const newFilteredItems = (0, applyRuleOnArray_1.applyRuleOnArray)({
                items: unfilteredItems,
                field,
                operator,
                filterValue,
            });
            for (const item of newFilteredItems) {
                itemsToKeep.add(item?.uniqueRivialFilterID);
            }
        }
        const filteredItems = unfilteredItems.filter((unfilteredItem) => itemsToKeep.has(unfilteredItem?.uniqueRivialFilterID));
        return filteredItems.map((item) => {
            delete item.uniqueRivialFilterID;
            return item;
        });
    }
    return items;
};
exports.applyFilterOnArray = applyFilterOnArray;
