import React from "react";

import styles from "../css/PDFAnnotation.module.css";

/**
 * Component displaying custom annotations on the PDF viewer, each annotation may have several graphics
 * @param {object} annotation - all data needed to draw the annotation
 * @param {object} drawDimensions - the canvas dimensions to draw the annotation on
 * @param {number} pageNumber - the page number the annotation is displayed on (a single annotation can span multiple pages)
 * @param {boolean} isEmphasized - TRUE if the user is trying to find the annotation/separate it from the rest of the document
 * @returns {JSX.Element}
 */
const PDFAnnotation = ({ annotation, drawDimensions, pageNumber, isEmphasized = false }) => {
  const { pageBounds } = annotation || {};
  const { width, height } = drawDimensions || {};

  const annotationElements = [];
  const annotationBounds = pageBounds[pageNumber];
  if (Array.isArray(annotationBounds) && Number.isFinite(width) && Number.isFinite(height)) {
    for (const bound of annotationBounds) {
      if (!bound.left || !bound.top || !bound.right || !bound.bottom) {
        continue;
      }

      const left = bound.left * width;
      const top = bound.top * height;
      const annotationElementWidth = (bound.right - bound.left) * width;
      const annotationElementHeight = (bound.bottom - bound.top) * height;

      annotationElements.push(
        <div
          key={`annotation_part_${left}_${top}`}
          className={styles["highlight-annotation"]}
          style={{
            backgroundColor: annotation?.color || "yellow",
            left: `${left}px`,
            top: `${top}px`,
            width: `${annotationElementWidth}px`,
            height: `${annotationElementHeight}px`,
          }}
        />,
      );
    }
  }

  return (
    <div className={styles["annotation-container"]}>
      {Array.isArray(annotationElements) && annotationElements.length > 0 && annotationElements}
    </div>
  );
};

export default PDFAnnotation;
