import React, { useContext, useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../../hooks/views/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { useFilteringChips } from "../../../../utils/GenericComponents/TabbedDrawer/hooks/useFilteringChips";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import CreateCustomTemplate from "../../CustomTemplates/components/CreateCustomTemplate";
import { deleteReportTemplate } from "../../CustomTemplates/functions/deleteReportTemplate";
import { useCustomTemplateList } from "../../CustomTemplates/hooks/useCustomTemplateList";
import { DocumentEditorChipEnums } from "../constants/documentEditorChipEnums";
import { generateCustomTemplate } from "../functions/generateCustomTemplate";

import { useRivialReportTemplatesMapping } from "./useRivialReportTemplatesMapping";

/**
 * Displays the UI for the 'create' tab in document editor file menu
 * @param {string} organizationID - currently selected organization
 * @param {boolean} preserveTags - whether to keep tags or to fill them in with widgets
 * @param {function} onJobStarted - callback to notify the document editor that a server job has started (takes in job object)
 * @param {function} handleTemplateClick - callback for creating a template with a form configuration
 * @param {function} generateFromTemplate - callback for normal loading of sfdt into the document editor
 * @return {{display: JSX.Element, tab: {icon: JSX.Element, label: string, prefixComponent: (*|JSX.Element), items: *[]}, resetFunction: resetFunction, generateReport: ((function({reportId: *, config: *}): Promise<void>)|*)}}
 */
export const useDocumentEditorCreateTab = ({
  organizationID,
  preserveTags,
  onJobStarted,
  handleTemplateClick,
  generateFromTemplate,
} = {}) => {
  /// [CONTEXT]
  const { addToast, updateToast } = useContext(UIContext);
  const { sentryTrace } = useContext(OrganizationContext);

  /// [PERMISSION CHECK]
  const checkPermissions = useCheckPermissions({
    module: modules.REPORTS,
    resource: resources.REPORT,
  });

  /// [BUILT-IN TEMPLATES]
  const rivialTemplates = useRivialReportTemplatesMapping({
    organizationID,
    handleTemplateClick,
    generateFromTemplate,
    onJobStarted,
  });

  /// [CUSTOM TEMPLATES]
  // - edit custom template UI
  const [editedCustomTemplate, setEditedCustomTemplate] = useState(null);
  const editCustomTemplateModal = useModal(
    "Edit Custom Template",
    <CreateCustomTemplate item={editedCustomTemplate} />,
    null,
    {
      width: "50vw",
      onClosed: () => reportTemplateListHook.resetFunction(),
    },
  );

  // - create options
  const reportTemplateListHook = useCustomTemplateList({ organizationID });
  const [customTemplates, setCustomTemplates] = useState([]);
  useEffect(() => {
    if (Array.isArray(reportTemplateListHook.list)) {
      setCustomTemplates(
        reportTemplateListHook.list.map((item) => {
          return {
            headline: item.name,
            subtitle: item.description ?? "",
            icon: getIcon("carbon:template"),
            iconBackgroundColor: "#ff6a00",
            chips: [DocumentEditorChipEnums.CUSTOM_TEMPLATE],
            options: [
              {
                label: "Edit",
                icon: getIcon("carbon:edit"),
                onClick: async () => {
                  setEditedCustomTemplate(item);
                  editCustomTemplateModal.setModalIsOpen(true);
                },
              },
              {
                label: "Delete",
                icon: getIcon("carbon:trash-can"),
                onClick: async () => {
                  await performToastOperation({
                    addToast,
                    updateToast,
                    operation: async () => await deleteReportTemplate(item),
                    inProgressText: "Deleting Report Template...",
                    failedText: "Failed to delete the report template.",
                    successText: "Successfully deleted the report template.",
                    iconColor: "danger",
                  });
                  reportTemplateListHook.resetFunction();
                },
              },
            ],
            onClick: async () => {
              await generateCustomTemplate({
                templateId: item.id,
                organizationID,
                preserveTags,
                onJobStarted,
                sentryTrace,
              });
            },
          };
        }),
      );
    }
  }, [reportTemplateListHook.list, preserveTags]);

  /// [OPTION QUERYING]
  // - State
  const [allItems, setAllItems] = useState([]);
  useEffect(() => {
    let currentItems = [];
    if (Array.isArray(rivialTemplates?.mapping) && rivialTemplates.mapping.length > 0) {
      currentItems = [...rivialTemplates.mapping];
    }
    if (Array.isArray(customTemplates) && customTemplates.length > 0) {
      currentItems = [...currentItems, ...customTemplates];
    }
    setAllItems(currentItems);
  }, [rivialTemplates?.mapping, customTemplates]);

  const [drawerItems, setDrawerItems] = useState([]);

  // - Searching
  const [searchValue, setSearchValue] = useState("");
  const useSearchForm = useForm({
    fieldConfig: {
      searchValue: {
        defaultValue: "",
        placeholder: "Search Templates...",
        onChangeFunction: (newValue) => {
          setSearchValue(newValue?.searchValue || "");
        },
        inputType: "text",
        label: null,
      },
    },
    disableResetButton: true,
    disableSubmitButton: true,
  });

  // - Filtering
  const chips = useFilteringChips({
    chipOptions: DocumentEditorChipEnums,
    items: drawerItems,
    oneAtATime: true,
  });

  // - Input Processing
  useEffect(() => {
    if (!checkPermissions?.resource?.create) {
      setDrawerItems([
        {
          headline: "No Permissions",
          subtitle: "You don't have permissions to create reports from templates.",
          icon: getIcon("codicon:warning"),
          iconBackgroundColor: "#be0909",
        },
      ]);
      return;
    }

    //For each item, check if either headline or subtitle matches the search value
    let filteredItems = allItems;
    if (searchValue) {
      filteredItems = allItems.filter((item) => {
        return (
          (item?.headline && item.headline.toLowerCase().includes(searchValue.toLowerCase())) ||
          (item?.subtitle && item.subtitle.toLowerCase().includes(searchValue.toLowerCase()))
        );
      });
    }

    //Filter by chips
    const selectedChips = chips?.selected;
    if (Array.isArray(selectedChips) && selectedChips.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        const itemChips = item?.chips || [];
        return (
          Array.isArray(itemChips) && itemChips.length > 0 && itemChips.some((chip) => chips.selected.includes(chip))
        );
      });
    }

    setDrawerItems(filteredItems);
  }, [allItems, searchValue, chips?.selected]);

  /// [MANUAL REPORT GENERATION]
  // - this is used for specific routes and modals where report configs are preconfigured
  const generateReport = async ({ reportId, config }) => {
    //Find the report to generate with the passed in config
    const rivialTemplate = rivialTemplates?.mapping?.find((template) => template.id === reportId);

    //Perform some checks
    if (!rivialTemplate?.onClick) {
      ErrorLogger(`Cannot generate report no OnClick function for id: ${reportId}`);
      return;
    }
    if (!checkPermissions?.resource?.create) {
      ErrorLogger("Cannot generate report no create permission report resource");
      return;
    }

    await rivialTemplate?.onClick(config);

    //TODO: implement manual calling of custom templates
  };

  const tab = {
    label: "Create",
    icon: getIcon("codicon:new-file"),
    prefixComponent: checkPermissions?.resource?.create && (
      <div style={{ paddingTop: ".8em" }}>
        {useSearchForm.display}
        {chips?.display}
      </div>
    ),
    items: drawerItems,
  };

  return {
    resetFunction: () => {
      reportTemplateListHook?.resetFunction?.();
    },
    generateReport,
    tab,
    display: editCustomTemplateModal?.modal,
  };
};
