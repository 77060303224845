"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventLogger = void 0;
/**
 * A passthrough for console.log that has some extra functionality
 * @param logs
 */
const eventLogger = (...logs) => {
    console.log(...logs);
};
exports.eventLogger = eventLogger;
