import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Performs mutation to create a new Policy
 * @param {object} input - the input to pass to the mutation
 * @return {Promise<*>}
 */
export const createPolicy = async ({ input }) => {
  const { createMutation } = generateGraphql("Policy", ["name", "module"]);

  return await ItemMutation({
    mutation: createMutation,
    input,
  });
};
