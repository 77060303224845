import { useRoleDataGrid } from "../../../../views/OrganizationManager/Roles/hooks/useRoleDataGrid";

export const roleDefinition = {
  routes: {
    grid: "organization_manager/roles",
    details: "organization_manager/roles",
    helpCenter: "role-management",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:user-female",

  //[COMPONENTS]
  gridHook: useRoleDataGrid,
};
