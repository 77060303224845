import React, { useEffect } from "react";

import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { useTargetsGrid } from "../../../Targets/hooks/useTargetsGrid";

/**
 * @description Select targets for an assessment
 * @param organizationID
 * @param input
 * @param setInput
 * @return {JSX.Element}
 * @constructor
 */
const AddAssessmentTargets = ({ organizationID, input, setInput }) => {
  const targetListHook = useTargetsGrid({
    organizationID: organizationID,
    enableSelection: true,
  });

  useEffect(() => {
    const targetsIpArrayLocal = [];

    targetListHook?.selectedData?.map((target) => {
      if (target?.ip) {
        targetsIpArrayLocal.push(target.ip);
      }
    });

    setInput((data) => {
      Object.assign(data, {
        includedListTargets: targetsIpArrayLocal.join(", "),
      });
      return data;
    });
  }, [JSON.stringify(targetListHook?.selectedData)]);

  const scanConfigForm = useForm({
    fieldConfig: {
      customScan: {
        inputType: "switch",
        switchConfig: {
          label: "Custom Scan Config",
        },
      },
    },
    disableSubmitButton: true,
    disableResetButton: true,
  });

  const customScanForm = useForm({
    fieldConfig: {
      includedListTargets: {
        label: "Custom IP Range",
        tooltip: (
          <p>
            <span>
              {" "}
              Scan a subnet example: 192.168.0.1/24 <br />
              For a specific range: 192.168.0.1-192.168.0.49, 192.168.0.51-192.168.0.200
            </span>
          </p>
        ),
        defaultValue: input?.includedListTargets,
      },
      login: {
        label: "Credentials Login",
        tooltip:
          "Configuring credentials allows to perform a wider variety of checks that result in more accurate scan results.",
        defaultValue: input?.login,
      },
      password: {
        label: "Credentials Password",
        tooltip:
          "Configuring credentials allows to perform a wider variety of checks that result in more accurate scan results.",
        defaultValue: input?.password,
      },
    },
    disableSubmitButton: true,
  });

  useEffect(() => {
    scanConfigForm.input?.customScan && Object.assign(input, { ...customScanForm?.input });
  }, [JSON.stringify(customScanForm?.input)]);

  return (
    <>
      {scanConfigForm.display}
      {scanConfigForm.input?.customScan ? (
        <>{customScanForm.display}</>
      ) : (
        <div style={{ height: "30em" }}>{targetListHook.display}</div>
      )}
    </>
  );
};

export default AddAssessmentTargets;
