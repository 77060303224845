import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useStateEffect } from "@hooks/functional/useStateEffect";

import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import ChangeInImplementation from "../../../customFields/ChangeInImplementation";

/**
 * Input for the 'Change in Implementation' field for a Risk Recommendation
 * @param {string} selectedRiskControlID
 * @param {object[]} riskControls
 * @param {function} onChangeCallback
 * @param {number} defaultValue
 * @returns {JSX.Element}
 */
const ChangeInImplementationField = ({ selectedRiskControlID, riskControls, onChangeCallback, defaultValue }) => {
  const [changeAmount, setChangeAmount] = useState(defaultValue || 0);
  const [newImplementationRating, setNewImplementationRating] = useState(0);

  const [riskControl] = useStateEffect(null, [riskControls, selectedRiskControlID], () => {
    if (!isNullOrUndefined(riskControls) && !isNullOrUndefined(selectedRiskControlID)) {
      return riskControls.find((item) => item.id === selectedRiskControlID);
    }
  });
  const implementationRating = Number.isFinite(riskControl?.implementationRating)
    ? riskControl?.implementationRating
    : 0;
  const implementationRatingPercent = implementationRating * 100;
  const maxChange = 100 - implementationRatingPercent;
  const minChange = -implementationRatingPercent;

  // Calculate the change in implementation
  useEffect(() => {
    let result = 0;
    const changeAmountDecimal = (changeAmount ?? 0) / 100;
    if (Number.isFinite(implementationRating)) {
      if (Number.isFinite(changeAmountDecimal)) {
        result = implementationRating + changeAmountDecimal;
      } else {
        result = implementationRating;
      }
    } else if (Number.isFinite(changeAmountDecimal)) {
      result = changeAmountDecimal;
    }

    setNewImplementationRating(result);
  }, [implementationRating, maxChange, minChange, changeAmount]);

  useEffect(() => {
    onChangeCallback?.(changeAmount);
  }, [onChangeCallback, changeAmount]);

  const handleOnChange = (e) => {
    const newValueString = e.target.value;
    if (newValueString === "") {
      setChangeAmount(null);
      return;
    }

    let newValue = parseFloat(newValueString);
    if (!Number.isFinite(newValue)) {
      newValue = 0;
    }

    if (newValue < minChange) {
      newValue = minChange;
    } else if (newValue > maxChange) {
      newValue = maxChange;
    }

    setChangeAmount(newValue);
  };

  return (
    <FormGroup>
      <DataLoader isLoading={isNullOrUndefined(riskControl)} isEnoughData={!!selectedRiskControlID}>
        <Row>
          <Col md={4}>
            <ChangeInImplementation newPercent={newImplementationRating} oldPercent={implementationRating} />
          </Col>
          <Col>
            <InputGroup>
              <Input
                type="number"
                step={1}
                min={minChange}
                max={maxChange}
                id="implementationChange"
                value={changeAmount}
                onChange={handleOnChange}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </DataLoader>
    </FormGroup>
  );
};

export default ChangeInImplementationField;
