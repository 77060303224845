import React from "react";

/**
 * Component to display a date
 * @param {object} value - the date to show
 * @param {boolean} dateOnly - if true, only the date will be displayed (strips time)
 * @returns {JSX.Element}
 */
const DateDisplay = ({ value, dateOnly }) => {
  if (!value || isNaN(new Date(value).getTime())) {
    return <span>Not Available</span>;
  }

  let dateString;
  if (dateOnly === true) {
    dateString = new Date(value).toLocaleDateString();
  } else {
    dateString = new Date(value).toLocaleString();
  }
  return <span>{dateString}</span>;
};

export default DateDisplay;
