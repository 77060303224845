import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateVendor } from "../hooks/useCreateVendor";

/**
 * Component instance of the useCreateVendor hook
 * @param {object} props
 * @param {function} [props.toggleModal]
 * @returns {JSX.Element}
 * @constructor
 */
const CreateVendor = (props) => {
  return useCreateVendor(props).display;
};

export default withOrganizationCheck(CreateVendor);
