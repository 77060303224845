import React, { useContext, useEffect, useState } from "react";

import { formattedName, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { arrayIncludes } from "../../../../utils/Functions/Array/arrayIncludes";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { useCreateMetric } from "../../Metrics/hooks/useCreateMetric";
import MetricTypeDataGrid from "../../MetricTypes/components/MetricTypeDataGrid";

/*
enum MetricDataFormat {
  decimal # ex. "0.1234".
  string  # for non-numerical data
  dollar  # USD form (with or without the commas and/or dollar sign). ex. "$123.50", "$1,234.00", "12356.50"
  percent # if input is already in percentage form (with or without the percent sign). ex. "75%", "36.0"
  json    # JSON.stringified, will be JSON.parsed
}
 */

/**
 * Displays a form for inputting a single Metric Instance. Needs to be tied to a MetricType
 *
 * If no MetricType is provided, then displays a selection UI for choosing a MetricType.
 *
 * @param {object} metricType - the MetricType to use for the parent of this Metric
 * @param {string} organizationID - the organizationID to use for the Metric
 * @param {function} toggleModal - function to toggle the external modal after form submit
 * @param {function} resetFunction - function to reset external data after form submit
 * @param {object} item - the Metric to edit if being used for an update form
 * @param {string[]} disabledFields - optional. array of field names that will be disabled (but not hidden) in the form
 * @param {object} ...props - all other props passed to useForm hook
 */
export const useMetricForm = ({
  metricType,
  organizationID,
  toggleModal,
  resetFunction,
  item,
  disabledFields = [],
  ...props
}) => {
  let inputType = "text";

  switch (metricType?.format) {
    case "decimal":
      inputType = "number";
      break;
    case "string":
      inputType = "text";
      break;
    case "dollar":
      break;
    case "percent":
      break;
    case "json":
      break;
    default:
      inputType = "number";
      break;
  }

  const { loggedInPointOfContactId } = useContext(OrganizationContext);

  const [pointOfContact, setPointOfContact] = useState({});

  useEffect(() => {
    if (loggedInPointOfContactId) {
      const { getQuery } = generateGraphql("PointOfContact", ["firstName", "lastName"]);
      ItemQuery(getQuery, loggedInPointOfContactId).then((data) => data && setPointOfContact({ ...data }));
    }
  }, [loggedInPointOfContactId]);

  const fieldConfig = {
    metricTypeID: {
      label: "Metric Type",
      inputType: "item-select",
      defaultValue: item?.metricTypeID || metricType?.id,
      itemSelectConfig: {
        grid: <MetricTypeDataGrid />,
        typename: "MetricType",
      },
      isHidden: !isNullOrUndefined(metricType),
      disabled: arrayIncludes(disabledFields, "metricTypeID"),
    },
    name: {
      label: "Metric Name",
      defaultValue: item?.name || `Metric Instance for: ${metricType?.name || "Metric Type"}`,
    },
    description: {
      label: "Metric Description",
      defaultValue:
        item?.description ||
        `Inputted by ${pointOfContact?.firstName} ${pointOfContact?.lastName} at ${new Date().toLocaleString()}`,
    },
    inputField: {
      label: metricType?.name || "Metric Data",
      tooltip: metricType?.description,
      inputType: metricType?.format,
      defaultValue: item?.data?.value,
    },
    date: {
      label: "Date",
      tooltip:
        "The date this metric was inputted. Defaults to today, but may set in the past for loading of existing data",
      inputType: "date",
      defaultValue: new Date(),
    },
  };

  useEffect(() => {
    let metricDescription = "";
    if (metricType?.description) {
      metricDescription = metricType?.description;
    } else {
      const pointOfContactName = formattedName({ pointOfContact });
      if (pointOfContactName) {
        metricDescription = `Inputted by ${pointOfContactName} on ${new Date().toLocaleDateString()}.`;
      } else {
        metricDescription = `Inputted by an automation.`;
      }
    }

    formHook.setInput((input) => {
      return {
        ...input,
        description: metricDescription,
      };
    });
  }, [pointOfContact]);

  const updateInputFunction = (input) => {
    return {
      ...input,
      date: input?.date || new Date().toISOString(),
      data: {
        value: input?.inputField || item?.data?.value,
        format: "decimal",
      },
      inputField: undefined,
      metricTypeID: input?.metricTypeID || metricType?.id,
      ownerGroup: organizationID,
    };
  };

  const formHook = useCreateMetric({
    organizationID,
    fieldConfig,
    updateInputFunction,
    toggleModal,
    resetFunction,
    item,
    ...props,
  });

  const display = formHook.display;

  return {
    display,
  };
};
