import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useTargetsGrid } from "../hooks/useTargetsGrid";

/**
 * @description: A Card components
 *              Displays list of Targets for an OrganizationID.
 * @param {object} props
 * @returns {JSX.Element}
 */
const TargetsGrid = (props) => useTargetsGrid({ ...props }).display;

export default withOrganizationCheck(TargetsGrid);
