import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";

import ChangeInImplementation from "../../../../Recommendations/customFields/ChangeInImplementation";

export const useAdjustKeyRiskIndicatorFields = ({ selectedKeyRiskIndicator }) => {
  const [annualRateOfOccurrenceChange, setAnnualRateOfOccurrenceChange] = useState(0);
  const [newAnnualRateOfOccurrence, setNewAnnualRateOfOccurrence] = useState(0);

  // Calculate the change in ARO
  useEffect(() => {
    let res = 0;
    if (selectedKeyRiskIndicator && selectedKeyRiskIndicator.annualRateOfOccurrence) {
      if (annualRateOfOccurrenceChange) {
        res =
          parseFloat(selectedKeyRiskIndicator.annualRateOfOccurrence) + parseFloat(annualRateOfOccurrenceChange / 100);
      } else {
        res = selectedKeyRiskIndicator.annualRateOfOccurrence;
      }
    }
    setNewAnnualRateOfOccurrence(res);
  }, [selectedKeyRiskIndicator, annualRateOfOccurrenceChange]);

  const display = (
    <>
      {selectedKeyRiskIndicator && (
        <>
          <FormGroup>
            <Label for="implementationChange">Change In Annual Rate of Occurrence</Label>
            <Row>
              <Col md={4}>
                <ChangeInImplementation
                  newPercent={newAnnualRateOfOccurrence}
                  oldPercent={selectedKeyRiskIndicator.annualRateOfOccurrence}
                />
              </Col>
              <Col>
                <InputGroup>
                  <Input
                    type="number"
                    step={1}
                    id="annualRateOfOccurrenceChange"
                    value={annualRateOfOccurrenceChange}
                    onChange={(e) => setAnnualRateOfOccurrenceChange(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </>
      )}
    </>
  );

  return {
    display,
    annualRateOfOccurrenceChange,
    newAnnualRateOfOccurrence,
  };
};
