import { Auth } from "@aws-amplify/auth";
import AWSAppSyncClient from "aws-appsync";
import localForage from "localforage";

import awsconfig from "../../../aws-exports";
import { S3_BUCKET_NAME, S3_BUCKET_REGION } from "../../../env-config";

const GRAPHQL_API_REGION = awsconfig.aws_appsync_region;
const GRAPHQL_API_ENDPOINT_URL = awsconfig.aws_appsync_graphqlEndpoint;

/**
 * Instantiates the AppSync client used for GraphQL queries and mutations.
 * @returns {AWSAppSyncClient<NormalizedCacheObject>}
 */
export const initializeAppsyncClient = () =>
  new AWSAppSyncClient({
    url: GRAPHQL_API_ENDPOINT_URL,
    region: GRAPHQL_API_REGION,
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS",
      // Get the currently logged in users credential.
      jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    storage: {
      bucket: S3_BUCKET_NAME,
      region: S3_BUCKET_REGION,
    },
    offlineConfig: {
      storage: localForage,
    },
    // Amplify uses Amazon IAM to authorize calls to Amazon S3. This provides the relevant IAM credentials.
    complexObjectsCredentials: () => Auth.currentCredentials(),
  });
