import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../../../hooks/views/useDataGrid/useDataGrid";
import MaturityMatrixDetailsCard from "../components/BlueprintDetails";
import CreateMaturityMatrix from "../components/CreateMaturityMatrix";
import { DeleteMaturityMatrix } from "../functions/DeleteMaturityMatrix";

const { listQuery, updateMutation } = generateGraphql("MaturityMatrix", ["name", "description"]);

/**
 * @description Display list of Blueprints
 * @param {string} organizationID - the ownerGroup to use for creating/querying blueprints
 * @param {object} config - the config to use for the grid
 * @param {object} queryConfig - the query config to use for the grid
 * @param {object} cardConfig - the card config to use for the grid
 * @param {object} gridConfig - the grid config to use for the grid
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param disableRoleChecking - disable role checking
 * @param {object} props
 * @returns {object} {{gridDisplay: JSX.Element, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, createResourceComponent: JSX, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, setItemsToCheck: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, selectedItems: [] | undefined}}
 */
export const useBlueprintDataGrid = ({
  organizationID,
  config = {},
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
  module = modules.GOVERNANCE,
  resource = resources.BLUEPRINT,
  disableRoleChecking = false,
  ...props
}) => {
  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: listQuery,
    organizationID: organizationID,
    ...queryConfig,
  };

  cardConfig = {
    title: "Blueprints List",
    headerIcon: "icon-layers",
    enableSticky: true,
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      flex: 1,
      bulkEdit: true,
    },
  ];

  gridConfig = {
    fields,
    deleteFunction: DeleteMaturityMatrix,
    createResourceComponent: (
      <CreateMaturityMatrix
        organizationID={organizationID}
        disableRoleChecking={disableRoleChecking}
        module={module}
        resource={resource}
      />
    ),
    createItemModalHeader: "Create a New Program Blueprint",
    detailsComponent: (
      <MaturityMatrixDetailsCard
        organizationID={organizationID}
        disableRoleChecking={disableRoleChecking}
        module={module}
        resource={resource}
      />
    ),
    route: "#/governance/blueprints/",
    persistenceUUID: "program-blueprint-data-grid",
    options: ["details", "delete", "edit"],
    updateMutation,
    module,
    resource,
    disableRoleChecking,
    ...gridConfig,
    ...props,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...gridCard };
};
