import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";
import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";

/**
 * @description Handles row selection
 * @param {object} args - object with arguments of the selected item
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 * @param {function} updateItemById - update an item by id in the grid
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {JSX} detailsModal - modal component for displaying details of an object
 */
export const gridRowSelected = ({
  args,
  setSelectedItems,
  detailsBar,
  detailsComponent,
  detailsType,
  rightClick,
  updateItemById,
  setLastSelectedItem,
  disableUpdate,
  detailsModal,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    args,
    setSelectedItems,
    detailsBar,
    ...(detailsType !== DETAILS_TYPES.NONE && { detailsComponent }),
    detailsType,
    rightClick,
    updateItemById,
    setLastSelectedItem,
    disableUpdate,
    detailsModal,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  /**
   * Get and set selected items
   */
  if (args?.data?.id && setSelectedItems) {
    setSelectedItems((selectedItems) => {
      const itemIndex = selectedItems.findIndex((x) => {
        if (isNullOrUndefined(x)) {
          return false;
        }
        return x.id === args?.data.id;
      });

      if (itemIndex > -1) {
        selectedItems[itemIndex] = args?.data;
        return selectedItems;
      } else {
        return [...selectedItems, args?.data];
      }
    });
  } else if (Array.isArray(args?.data) && setSelectedItems) {
    /**
     * If shift+clicking, override selection array
     */
    const nullItemPresent = args?.data.findIndex((item) => isNullOrUndefined(item)) !== -1;
    if (!nullItemPresent) {
      setSelectedItems([...args.data]);
    }
  }

  /**
   * If multiple items are selected, close the details pane
   */
  if (args?.data?.length > 1) {
    !detailsBar?.pinned && detailsBar?.setIsOpen(false);
  } else if (detailsComponent && detailsBar && !args.rowIndexes) {
    /**
     * Handle single selected item
     */
    setLastSelectedItem && setLastSelectedItem(args?.data);

    if (rightClick?.current === false) {
      detailsType === DETAILS_TYPES.PANEL &&
        !detailsBar?.pinned &&
        detailsBar?.setSelectedComponent(
          React.cloneElement(detailsComponent, {
            item: args?.data,
            updateItemById,
          }),
        );

      if (disableUpdate.current === false && !detailsBar?.isOpen) {
        /**
         * Handle PANEL type
         */
        if (detailsType === DETAILS_TYPES.PANEL) {
          detailsBar?.setIsOpen(true);
        } else if (detailsType === DETAILS_TYPES.MODAL) {
          /**
           * Handle MODAL type
           */
          detailsModal?.setModalIsOpen(true);
        }
      }
    }
  }
};
