import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import UserPreferredMFA from "../components/UserPreferredMFA";
import UserRoleField from "../customFields/UserRoleField";

/**
 * A details card hook for displaying the details of a particular User
 * @param props
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useUserDetails = (props) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.USER;

  const { getQuery: getUser } = generateGraphql(
    "User",
    [
      "name",
      "username",
      "email",
      "phoneNumber",
      "accountType",
      "roleLinks",
      "preferredMFA",
      "lastChangedPasswordDate",
      "acceptanceDate",
    ],
    {
      roleLinks: `{ items { id role { id ownerGroup name } } }`,
    },
  );

  const fieldNameDictionary = {
    name: "Name",
    username: "Username",
    email: "Email",
    phoneNumber: "Phone Number",
    accountType: "account Type",
    roleLinks: "Role",
  };

  const fields = [
    "name",
    "username",
    "email",
    "phoneNumber",
    "accountType",
    "roleLinks",
    "preferredMFA",
    "lastChangedPasswordDate",
    "acceptanceDate",
  ];

  const customFields = [
    {
      field: "roleLinks",
      component: <UserRoleField />,
    },
    {
      field: "preferredMFA",
      component: <UserPreferredMFA />,
    },
  ];

  const queryConfig = {
    query: getUser,
    itemId: props.itemId,
  };

  const config = {
    header: "User Details",
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    config,
    module,
    resource,
    fields,
    customFields,
    fieldNameDictionary,
  });

  return {
    ...detailsCard,
  };
};
