import React from "react";
import { Button } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import CreateLinking from "../../../../utils/GenericComponents/CreateLinking";
import { emptyPlaceholder } from "../../../../utils/GenericComponents/Placeholders";
import UnderlinedTooltip from "../../../../utils/Tooltips/UnderlinedTooltip";
import CreateObservation from "../../../Program/Observations/components/CreateObservation";
import ObservationDetails from "../../../Program/Observations/components/ObservationDetails";
import ObservationsDataGrid from "../../../Program/Observations/components/ObservationsDataGrid";

/**
 * Displays the details for a single Observation
 * @param {object} item - vulnerability object with an observation
 * @param {string} organizationID - id of the organization
 * @param {function} resetFunction - function to reset the state
 * @returns {JSX.Element}
 */
const VulnerabilityObservation = ({ item, organizationID, resetFunction }) => {
  const checkPermissions = useCheckPermissions({
    module: modules.VULNERABILITIES,
    resource: resources.VULNERABILITY,
  });

  const detailsModal = useModal(
    `Observation Details`,
    <ObservationDetails item={item?.observation} />,
    <span>
      <UnderlinedTooltip text={item?.observation?.name} title={`Click to view full Observation details`}>
        {item?.observation?.name}
      </UnderlinedTooltip>
    </span>,
    {
      width: "75vw",
    },
  );

  const linkingModal = useModal(
    "Create or Link Observation",
    <CreateLinking
      organizationID={organizationID}
      typename="Observation"
      parentTypename="Vulnerability"
      item={item}
      form={<CreateObservation />}
      grid={<ObservationsDataGrid />}
      queryConfig={{ normalizeData: undefined }}
      createFunction={(item, itemToLink) => {
        ItemMutation(generateGraphql("Vulnerability").updateMutation, {
          id: itemToLink?.id,
          observationID: item?.id,
        }).finally(() => resetFunction && resetFunction());
      }}
    />,
    <Button color="ghost-warning" className="btn-pill" size="sm">
      <i className="icon-pencil" />
    </Button>,
    {
      width: "50vw",
    },
  );

  return (
    <div>
      {item?.observation ? <>{detailsModal.modalButton}</> : <>{emptyPlaceholder}</>}
      {checkPermissions?.resource?.update && linkingModal.modalButton}
    </div>
  );
};

export default withOrganizationCheck(VulnerabilityObservation);
