import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Add new item to the grid
 * @param {object} item - new created item object
 * @param {object} ref - reference of the the grid component
 */
export const gridGetNewItem = ({ item, ref }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = { item, ref };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  if (ref?.dataSource && Array.isArray(ref.dataSource) && item?.id) {
    ref.dataSource.unshift(item);
    ref.refresh && ref.refresh();
    return ref;
  }
};
