import { modules, resources } from "@rivial-security/role-utils";

import { useFeatureFlagsContext } from "../../../../featureFlags/FeatureFlagsProvider";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useOrganizationConfig } from "../../../AdminPanel/Organizations/hooks/useOrganizationConfig";

export interface UsePolicyGenerationAvailableParams {
  organizationID: string;
}

export interface UsePolicyGenerationAvailableResult {
  isVisible: boolean;
  hasPermission: boolean;
  hasOptedIn: boolean;
  isLoading: boolean;
  isAiEnabled?: boolean;
}

export const usePolicyGenerationAvailable = ({
  organizationID,
}: UsePolicyGenerationAvailableParams): UsePolicyGenerationAvailableResult => {
  const { flags } = useFeatureFlagsContext();

  const isAiEnabled = flags?.isAiEnabled;

  const module = modules.GOVERNANCE;
  const resource = resources.POLICY;

  const { value: aiOptIn, isLoading } = useOrganizationConfig({
    organizationID,
    key: "aiOptIn",
  });
  const hasOptedIn = aiOptIn === true;

  const permissions = useCheckPermissions({
    module,
    resource,
  });
  const hasPermission = permissions.module.isEnabled && permissions.resource.create;

  const isVisible = hasOptedIn && hasPermission && isAiEnabled === true;

  return {
    isVisible,
    hasPermission,
    hasOptedIn,
    isLoading,
    isAiEnabled,
  };
};
