import React, { useContext } from "react";
import { Button } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { UIContext } from "../../../../utils/Context/UIContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * @description Update a secret for a tool
 * @param {object} item - tool
 * @param {string} organizationID - organization ID
 * @param {string} text - text to display on button
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateSecret = ({ item, organizationID, text = "Update" }) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;
  const typename = resources.TOOL;

  const { addToast, updateToast } = useContext(UIContext);

  const submitFunction = async (input) => {
    const toastId = addToast({
      header: `Updating a Secret...`,
      icon: "spinner",
      color: "info",
    });

    const { secret } = input;

    const updateTool = /* GraphQL */ `
      mutation UpdateTool($input: UpdateToolInput!) {
        updateTool(input: $input) {
          id
          updateSecret(secret: "${secret}")
        }
      }
    `;

    ItemMutation(updateTool, {
      id: item?.id,
    })
      .then(() =>
        updateToast({
          id: toastId,
          header: "Successfully updated a Secret",
          icon: "success",
        }),
      )
      .catch(() =>
        updateToast({
          id: toastId,
          header: "Failed to update a Secret",
          icon: "danger",
        }),
      )
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .finally(() => modal?.toggleModal());
  };

  const form = useForm({
    typename,
    fieldConfig: {
      secret: {
        required: true,
        label: "Secret",
        tooltip: "A secret key for accessing a 3rd party integration",
        inputType: "textarea",
      },
    },
    submitFunction,
    organizationID,
    module,
    resource,
  });

  const modal = useModal(
    `${text} Secret`,
    form.display,
    <Button color={"primary"} size={"sm"} className={"btn-pill"}>
      {text}
    </Button>,
    {},
  );

  return modal?.modalButton;
};

export default UpdateSecret;
