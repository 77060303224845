import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { automationWebHooksUrl } from "../../../../env-config";
import { useForm } from "../../../../hooks/views/useForm";
import { UIContext } from "../../../../utils/Context/UIContext";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import ClipboardButton from "../../../../utils/GenericComponents/buttons/ClipboardButton";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";

/**
 * Api gateway URL for the lambda function that handles webhooks
 * @type {string}
 */
const LAMBDA_URL = `${automationWebHooksUrl}/`;

/**
 * @description A form for configuring a webhook trigger for an Automation
 * @param {object} item - automation item
 * @param {string} automationID - custom passed automation id
 * @param {function} onChangeCallback - useForm field callback function
 * @param {string} organizationID - ID of the organization
 * @returns {*}
 * @constructor
 */
const ConfigureWebhookTrigger = ({ item, automationID, onChangeCallback, organizationID }) => {
  const generateWebhookUrl = () => {
    return `${LAMBDA_URL}?automation=${item?.id || automationID}`;
  };

  useEffect(() => {
    if (!isNullOrUndefined(automationID) && typeof onChangeCallback === "function") {
      onChangeCallback({
        webhookUrl: generateWebhookUrl(),
      });
    }
  }, [automationID]);

  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    disableRoleChecking: true,
    fieldConfig: {
      webhookUrl: {
        label: "Webhook URL",
        disabled: true,
        onChangeFunction: onChangeCallback,
        defaultValue: generateWebhookUrl(),
        inputType: "custom",
        customConfig: {
          component: (
            <div
              style={{
                width: "80%",
              }}
            >
              <ClipboardButton
                preview={true}
                data={generateWebhookUrl()}
                tooltip={"Copy this Webhook URL to the clipboard"}
              />
            </div>
          ),
        },
        tooltip:
          "This URL is automatically generated and associated with this particular Automation. Using this URL in a POST request will allow you to trigger this Automation. The POST request body may contain a JSON object that can be accessed using {{webhookData.field1}} notation",
      },
      apiKey: {
        label: "API Key",
        inputType: "custom",
        tooltip: `Include "x-api-key" header with this value in the POST request to trigger this Automation`,
        customConfig: {
          component: <DisplayApiKey organizationID={organizationID} />,
        },
      },
      notice: {
        removeFromInput: true,
        inputType: "custom",
        customConfig: {
          component: (
            <Alert severity="info" title={"Webhook URL"}>
              You can use this URL to trigger this Automation from an external source. For example, you can use this URL
              in a webhook integration in a third-party application.
              <br />
              <br />
              If the external source sends data with the request, you can access that data using Smart Value notation.
              For example, if the external source sends a JSON object with a property called "name", you can access that
              property downstream using
              {" {{webhookData.name}} "} notation.
              {
                // TODO: add a link to the documentation page that explains this in more detail
              }
            </Alert>
          ),
        },
      },
    },
  });

  return form.display;
};

export default withOrganizationCheck(ConfigureWebhookTrigger);

/**
 * @description Displays the API key for the organization if not exists, creates one
 * @param {string} organizationID - ID of the organization
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayApiKey = ({ organizationID }) => {
  const { addToast, updateToast } = useContext(UIContext);
  const [apiKey, setApiKey] = useState(null);

  /**
   * Fetches the API key from ApiGateway
   */
  const getApiKey = async () => {
    if (!organizationID) {
      return;
    }

    await performToastOperation({
      addToast,
      updateToast,
      inProgressText: "Fetching API Key",
      successText: "API Key fetched successfully",
      errorText: "Failed to fetch API Key",
      operation: async () => {
        const getOrganization = /* GraphQL */ `
          query GetOrganization($id: ID!) {
            getOrganization(id: $id) {
              id
              getAutomationApiKey
            }
          }
        `;

        await GetQuery({
          query: getOrganization,
          variables: {
            id: organizationID,
          },
        }).then((res) => {
          const apiKey = JSON.parse(res?.getAutomationApiKey);
          setApiKey({ ...apiKey });
        });
      },
    });
  };

  return (
    <span>
      {apiKey?.value ? (
        <ClipboardButton preview={true} data={apiKey?.value} tooltip={"Copy this Webhook API Key to the clipboard"} />
      ) : (
        <Button onClick={getApiKey}>Show API Key</Button>
      )}
    </span>
  );
};
