import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Sets up default Metric Types for an Organization.
 *
 * - Compliance Totals (daily)
 * - Residual Risk (daily)
 * - Inherent Risk (daily)
 * - Overall Blueprint Score (daily)
 * - Number of Findings (daily)
 * @param organizationID
 */
export const createDefaultMetrics = async ({ organizationID }) => {
  // Compliance Percentage
  await createMetricType({
    name: "Compliance Percentage",
    description: "The percentage of Compliant controls to all Controls",
    automation: {
      name: "compliancePercentage",
    },
    format: "percent",
    organizationID,
  });

  // Average Inherent Risk
  await createMetricType({
    name: "Average Inherent Risk",
    description: "The average Inherent Risk across all Systems",
    automation: {
      name: "averageInherentRisk",
    },
    format: "dollar",
    organizationID,
  });

  // Average Residual Risk
  await createMetricType({
    name: "Average Residual Risk",
    description: "The average Residual Risk across all Systems",
    automation: {
      name: "averageResidualRisk",
    },
    format: "dollar",
    organizationID,
  });

  // Blueprint Score
  await createMetricType({
    name: "Program Blueprint Score",
    description: "The average Program Blueprint Score across all Program elements",
    automation: {
      name: "blueprintScore",
    },
    format: "decimal",
    organizationID,
  });
};

export const createMetricType = async ({
  name,
  description,
  automation,
  format = "decimal",
  organizationID,
  toolID,
  calculationType = "mostRecent",
}) => {
  const { createMutation } = generateGraphql("MetricType");

  return await ItemMutation(createMutation, {
    name,
    description,
    format,
    ownerGroup: organizationID,
    enabled: true,
    automation,
    frequency: "daily",
    toolID,
    calculationType,
  });
};
