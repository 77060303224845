import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useState } from "react";
import Lightbox from "react-awesome-lightbox";

const CustomImagePicker = ({ onChange, value }) => {
  const [uploaderRef, setUploaderRef] = useState("");
  const [image, setImage] = useState(value);

  useEffect(() => {
    onChange(image);
  }, [image]);

  const onSelect = (e) => {
    const reader = new FileReader();
    const file = e.filesData[0].rawFile;

    reader.onloadend = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };
  return (
    <div>
      <UploaderComponent
        id="previewfileupload"
        type="file"
        ref={(upload) => setUploaderRef(upload)}
        selected={onSelect}
      />
      <Lightbox image={image} title={"Image Preview"} />
    </div>
  );
};

export default CustomImagePicker;
