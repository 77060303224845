import React, { useContext } from "react";
import { CustomInput, UncontrolledTooltip } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { toggleSafeDelete } from "../functions/toggleSafeDelete";

/**
 * @description Interface Safe Delete Component
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceSafeDelete = () => {
  const { safeDelete, setSafeDelete, addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const card = useCard({
    id: "safe-delete",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Safe Delete</h4> <i id="SDExplanation" className="icon-question" />
        <UncontrolledTooltip placement="right" target="SDExplanation">
          Safe Delete forces you to type 'delete' before you may delete an item
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <CustomInput
          id="safe-delete-switch"
          value={safeDelete}
          title="Safe Delete forces you to type 'delete' before you may delete an item"
          type="switch"
          checked={safeDelete}
          onChange={() =>
            toggleSafeDelete({
              safeDelete,
              preferences,
              setSafeDelete,
              addToast,
            })
          }
        />
      </>
    ),
  });

  return card?.display;
};

export default InterfaceSafeDelete;
