import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { PointOfContact, QuestionnaireSystemLink, System } from "@rivial-security/schema-types";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import { SystemContactRole } from "@views/OrganizationManager/Questionnaires/functions/handleSystemContactAssignment";

export interface UnassignQuestionnaireAssigneeParams {
  system: System;
  systemQuestionnaires: QuestionnaireSystemLink[];
  pointOfContact: PointOfContact;
  role: SystemContactRole;
}

export const unassignQuestionnaireAssignee = async ({
  system,
  systemQuestionnaires,
  pointOfContact,
  role,
}: UnassignQuestionnaireAssigneeParams): Promise<void> => {
  const { deleteMutation } = generateGraphql("QuestionnaireAssignee", ["pointOfContactID"]);

  for (const link of systemQuestionnaires ?? []) {
    const questionnaire = link?.questionnaire;
    const assignees = questionnaire?.assignees?.items ?? [];
    const questionnaireAssignee = assignees.find((assignee) => assignee?.pointOfContactID === pointOfContact?.id);

    if (questionnaireAssignee) {
      const stillHasRole = hasRemainingSystemRole({
        system,
        pointOfContact,
        roleBeingRemoved: role,
      });
      if (!stillHasRole) {
        try {
          await ItemMutation<Partial<QuestionnaireSystemLink>>({
            mutation: deleteMutation,
            input: {
              id: questionnaireAssignee?.id,
            },
          });
        } catch (e) {
          ErrorLogger("Error deleting QuestionnaireAssignee:", e);
        } finally {
          InfoLogger(`Removed assignee from questionnaire: ${questionnaire?.name}`);
        }
      }
    }
  }
};

interface HasRemainingSystemRoleParams extends Pick<UnassignQuestionnaireAssigneeParams, "system" | "pointOfContact"> {
  roleBeingRemoved: SystemContactRole;
}

const hasRemainingSystemRole = ({
  system,
  pointOfContact,
  roleBeingRemoved,
}: HasRemainingSystemRoleParams): boolean => {
  const isStillAdmin = system.adminPointOfContact?.id === pointOfContact.id;
  const isStillOwner = system.pointOfContact?.id === pointOfContact.id;

  if (roleBeingRemoved === SystemContactRole.Admin) {
    return isStillOwner;
  } else if (roleBeingRemoved === SystemContactRole.Owner) {
    return isStillAdmin;
  }

  return false;
};
