"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareRiskAmount = void 0;
/**
 * Helper function for comparing if an amount is between two numbers
 * Returns true if amount is gte AND lt the numbers in range
 * @param {number} amount - e.g. 650
 * @param {number[]} range - e.g. [500, 1000]
 * @returns {boolean}
 */
const compareRiskAmount = (amount, range) => {
    return amount >= range[0] && amount < range[1];
};
exports.compareRiskAmount = compareRiskAmount;
