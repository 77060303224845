import { useAutomationsGrid } from "../../../../views/OrganizationManager/Automations/hooks/useAutomationsGrid";

export const automationDefinition = {
  routes: {
    grid: "organization_manager/automations",
    details: "organization_manager/automations",
    helpCenter: "automations",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:energy",

  gridHook: useAutomationsGrid,
};
