import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateAudit } from "../hooks/useCreateAudit/useCreateAudit";

/**
 * Component for creating an Audit
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateAudit = (props) => {
  return useCreateAudit(props).display;
};

export default withOrganizationCheck(CreateAudit);
