/**
 * Author: Bryan Caesar
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *  - 7/29/19 JB: Added toString()'s to error outputs
 *
 * Description: A Card Component.
 *              A Generic CSV Importer component.
 *              Must be wrapped by a component to handle the logic of a Specific resource to import.
 */

import PropTypes from "prop-types";
import React, { Component } from "react";
import CSVReader from "react-csv-reader";
import { Button, Card, CardBody, CardFooter, CardHeader, Collapse, Form, Label } from "reactstrap";

import { ErrorLogger } from "@utils/EventLogger";

import AlertDefaultResults from "../functions/AlertDefaultResults";

class GenericCsvImporter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.parseFile = this.parseFile.bind(this);
    this.setProgressText = props.setProgressText && props.setProgressText.bind(this);
    this.setProgressTotal = props.setProgressText && props.setProgressTotal.bind(this);
  }

  getInitialState = () => ({
    collapseIsOpen: true,
    collapseButton: "icon-arrow-left",
    collapseInstructionsIsOpen: false,
    matrixFromFile: null, // A 2D array of Strings that represents the data from the .CSV file ([row][col]).
    fileReadMessage: "Waiting for file input...",
    hasStartedUpload: false,
  });

  toggleCollapse() {
    this.setState({
      collapseIsOpen: !this.state.collapseIsOpen,
      collapseButton: this.state.collapseIsOpen ? "icon-arrow-left" : "icon-arrow-down",
    });
  }

  setProgressTotal = (total) => {
    this.setState({
      progressTotal: total,
    });
  };

  parseFile(matrix, fileName) {
    let fileMessage = "Failed to read rows from file.";

    if (matrix !== undefined && matrix !== null && Array.isArray(matrix)) {
      this.setState({ matrixFromFile: matrix });
      fileMessage = `Read ${matrix.length} rows from "${fileName}".`;
    }

    this.setState({ fileReadMessage: fileMessage });
    this.props.setProgressTotal(matrix.length);
  }

  toggleInstructions() {
    this.setState({
      collapseInstructionsIsOpen: !this.state.collapseInstructionsIsOpen,
    });
  }

  static trimRowContents(originalRow) {
    const trimmedArray = new Array(originalRow.length);

    for (let i = 0; i < originalRow.length; i++) trimmedArray[i] = originalRow[i].trim();

    return trimmedArray;
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ hasStartedUpload: true });

    if (this.state.matrixFromFile.length < 2) {
      alert(
        "No items created/updated. The file must have at least 2 rows. (1 row for column headers, and at least one row of data.",
      );
      return;
    }

    const headerRow = this.state.matrixFromFile.shift(); // Removes and returns the first row.

    if (this.props.checkHeader(headerRow)) {
      const successRows = [];
      const failedRows = [];

      for (let row of this.state.matrixFromFile) {
        row = GenericCsvImporter.trimRowContents(row);

        if (this.props.checkRow(row)) {
          await this.props
            .processRow(row)
            .then((row) => successRows.push(row))
            .catch((err) => {
              failedRows.push(row);
              ErrorLogger("FAILED ROW INPUT:");
              this.setProgressText("FAILED ROW INPUT:");
              ErrorLogger(row);
              this.setProgressText(row);
              ErrorLogger("FAILED ROW ERROR:");
              this.setProgressText("FAILED ROW ERROR:");
              ErrorLogger(err.toString());
              this.setProgressText(err.toString());
            });
        } else {
          failedRows.push(row);
        }
      }

      this.props.alertResults(successRows, failedRows);
      this.setState({
        fileReadMessage: "Operation complete!",
        matrixFromFile: null,
        inProgress: false,
      });
    } else {
      alert(
        `The first row of the file doesn't contain the expected column headers. The file will not be processed.${(
          <small>
            Possible causes: A.) The first row of your file does not contain column headers. B.) The column headers are
            misspelled. C.) The column headers do not appear in the expected order. D.) The data in your file does not
            match the data needed for this operation.
          </small>
        )}`,
      );
    }
  } // End "handleSubmit" function.

  render() {
    const isSubmitEnabled =
      this.state.matrixFromFile !== null &&
      this.state.matrixFromFile !== undefined &&
      Array.isArray(this.state.matrixFromFile) &&
      this.state.matrixFromFile.length >= 2 &&
      !this.state.hasStartedUpload &&
      !this.props.uploadDisabled;

    // Styles
    //-----------------------------------------------------------------
    const Style_InstructionBlock = {
      textAlign: "center",
    };

    const Style_InstructionButton = {
      backgroundColor: "transparent",
    };

    const Style_CVSReaderBlock = {
      textAlign: "center",
      padding: 15,
      margin: 10,
    };

    const Style_FileSelection = {
      border: 1,
      borderStyle: "solid",
      borderRadius: 8,
      padding: 10,
    };
    //-----------------------------------------------------------------
    // End Styles

    return (
      <Card>
        <CardHeader>
          <i className="icon-cloud-upload" />
          {this.props.cardTitle}
          <Button color="ghost-secondary" size="sm" onClick={this.toggleCollapse} className="float-right">
            <i className={this.state.collapseButton} />
          </Button>
        </CardHeader>

        <Collapse isOpen={this.state.collapseIsOpen}>
          <CardBody>
            {this.props.instructions ? (
              <div id="instructionsBlock" style={Style_InstructionBlock}>
                <Button style={Style_InstructionButton} onClick={this.toggleInstructions}>
                  <h2>Instructions:</h2>
                  <Collapse isOpen={this.state.collapseInstructionsIsOpen}>
                    <ol style={{ textAlign: "left" }}>{<li>step</li> + <br />}</ol>
                  </Collapse>
                </Button>
              </div>
            ) : (
              ""
            )}

            <Form encType="multipart/form-data">
              <div id="fileReader" style={Style_CVSReaderBlock}>
                <Label for="CSVReaderBlock">
                  <h4>Select a .CSV File:</h4>
                  <p>Format: CSV UTF-8 (Comma Delimited)</p>
                </Label>

                <CSVReader
                  cssClass="csv-reader-input"
                  onFileLoaded={this.parseFile}
                  inputStyle={Style_FileSelection}
                  fileEncoding="UTF-8"
                />

                <Label>{this.state.fileReadMessage}</Label>
              </div>
            </Form>
          </CardBody>

          <CardFooter>
            <Button onClick={this.handleSubmit} disabled={!isSubmitEnabled} size="sm" color="primary">
              <i className="upload" />
              Upload
            </Button>
          </CardFooter>
        </Collapse>
      </Card>
    );
  }
}

GenericCsvImporter.propTypes = {
  setProgressText: PropTypes.func.isRequired,
  setProgressTotal: PropTypes.func.isRequired,
};

GenericCsvImporter.defaultProps = {
  checkHeader: (header) => {
    return false;
  },
  checkRow: (row) => {
    return false;
  },
  alertResults: AlertDefaultResults,
  processRow: (row) => {
    alert("Error: Using default row handler.");
  },
  cardTitle: "Import Data From .CSV File",
  // Optional prop named "instructions":
  //  An array of strings that describes the steps for using the importer.
};

export default GenericCsvImporter;
