/**
 * Converts database point of contact object to a readable string
 * @param {object} pointOfContact - POC object from the database
 * @return {string} - the string representation of the name of the point of contact
 */
export const getPointOfContactName = (pointOfContact) => {
  let name = "";
  if (pointOfContact?.firstName) {
    name += pointOfContact.firstName;
  }
  if (pointOfContact?.lastName) {
    name += `${name === "" ? "" : " "}${pointOfContact.lastName}`;
  }

  if (name === "") {
    return "No Name";
  }
  return name;
};
