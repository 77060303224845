import { v4 as uuid } from "uuid";

/**
 * @description Formats a Note object
 * @param {string} content - The content of the note
 * @param {string} author - The author of the note
 * @param {string} organizationID - The organization ID of the note
 * @returns {{timeStamp: Date, author: *, ownerGroup: *, id: *, content: *}}
 */
export const createNote = (content, author, organizationID) => {
  return {
    id: uuid(),
    content: content,
    author: author,
    ownerGroup: organizationID,
    timeStamp: new Date(),
  };
};
