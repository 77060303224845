/**
 * Validate ip address for a target for GenericEditFieldV3
 */
export const isValidIp = {
  isValidInput: (ip) => {
    const ipRegex = new RegExp(
      "^(?=\\d+\\.\\d+\\.\\d+\\.\\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\\.?){4}$",
    );
    return ipRegex.test(ip);
  },
  validInputMessage: "Valid IP Address",
  invalidInputMessage: "Please, make sure your IP address is correct. Ex: 192.168.0.1",
};
