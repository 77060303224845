import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function onChangeLoaderStyle for Interface Loader Style Component
 * @param {string} value - a name of the loader type
 * @param {object} preferences - a hook for getting and setting user preferences
 * @param setLoaderStyle - function for setting selected loader
 * @param {function} addToast - function for displaying a toast for the user
 */
export const onChangeLoaderStyle = ({ value, preferences, setLoaderStyle, addToast }) => {
  if (isNullOrUndefined(value)) {
    throw new Error(`[onChangeLoaderStyle.js] Param: "value" is null`);
  }

  if (isNullOrUndefined(preferences)) {
    throw new Error(`[onChangeLoaderStyle.js] Param: "preferences" is null`);
  }

  if (isNullOrUndefined(setLoaderStyle)) {
    throw new Error(`[onChangeLoaderStyle.js] Param: "setLoaderStyle" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[onChangeLoaderStyle.js] Param: "addToast" is null`);
  }

  try {
    preferences.setPreference("interfaceOptions", { loaderStyle: value });
  } catch (e) {
    addToast({
      header: `Error! Loader Style was NOT changed`,
      icon: "danger",
    });

    throw new Error("[onChangeLoaderStyle.js] Can not set Preferences");
  }

  setLoaderStyle(value);
  addToast({
    header: `Loader Style was changed to ${convertCamelCaseToSentence(value)}`,
    icon: "success",
  });
};
