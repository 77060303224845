import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateSystemTemplate } from "../hooks/useCreateSystemTemplate";

/**
 * ADMIN ONLY
 * Allows an admin to create a new System Template
 * @returns {JSX.Element}
 * @constructor
 */
const CreateSystemTemplate = (props) => useCreateSystemTemplate(props).display;

export default withOrganizationCheck(CreateSystemTemplate);
