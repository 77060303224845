"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryIntegrationData = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const getToolByType_1 = require("./getToolByType");
/**
 * @description Get data for an integration
 * @param {string} toolType - tool type
 * @param {string} organizationID - organization id
 * @param {object} input - params to get data
 * @param {object} env - the environment variables from with AWS keys
 * @returns {Promise<any[]>}
 */
const queryIntegrationData = async ({ toolType, organizationID, input, env }) => {
    if (!toolType)
        throw Error("Missing toolType in the queryIntegrationData function");
    if (!organizationID)
        throw Error("Missing organizationID in the queryIntegrationData function");
    if (!input)
        throw Error("Missing input in the queryIntegrationData function");
    /**
     * Get the Tool id based on the tool type
     */
    const tool = await (0, getToolByType_1.getToolByType)({
        organizationID,
        toolType,
        env,
    });
    /**
     * Query to make a fetchData lambda call
     * @type {string}
     */
    const getTool = /* GraphQL */ `
    query GetTool($id: ID!, $input: AWSJSON) {
      getTool(id: $id) {
        id
        fetchData(input: $input)
      }
    }
  `;
    if (tool) {
        return await (0, appsync_utils_1.GetQuery)({
            query: getTool,
            variables: {
                id: tool?.id,
                input: JSON.stringify(input),
            },
            env,
        }).then((item) => {
            return JSON.parse(item?.fetchData);
        });
    }
};
exports.queryIntegrationData = queryIntegrationData;
