"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evidenceIndexes = void 0;
exports.evidenceIndexes = [
    {
        indexName: "evidencesByOwnerGroup",
        queryField: "evidencesByOwnerGroup",
        partitionKey: "ownerGroup",
        partitionKeyType: "String",
    },
];
