import React from "react";

import ContextMenu from "../../../../../hooks/views/useContextMenu/components/ContextMenu";
import PDFAnnotation from "../../../../../hooks/views/usePDFViewer/components/PDFAnnotation";

/**
 * A wrapper around normal annotations component that adds a custom actions for label annotations
 * @param {function} onDelete - callback when an annotation is deleted
 * @param {object} annotation - the annotation data
 * @param {object} props - any other props to pass down to the PDFAnnotation
 * @returns {JSX.Element}
 */
const ArtifactLabelAnnotation = ({ onDelete, annotation, ...props }) => {
  return (
    <ContextMenu
      id={"label-annotation-options-menu"}
      enableLeftClick={true}
      menuItems={[
        {
          icon: "mdi:trash-outline",
          label: "Delete Bookmark",
          onClick: async () => {
            if (typeof onDelete === "function") {
              await onDelete({ deletedAnnotation: annotation });
            }
          },
        },
      ]}
    >
      <PDFAnnotation annotation={annotation} {...props} />
    </ContextMenu>
  );
};

export default ArtifactLabelAnnotation;
