import { usePDFLabelAnnotator } from "../hooks/usePDFLabelAnnotator";

/**
 * A component instance of the usePDFLabelAnnotator.js hook
 * @param {object} props - props passed down to the usePDFLabelAnnotator hook
 * @returns {JSX.Element}
 */
const PDFLabelAnnotator = (props) => {
  return usePDFLabelAnnotator(props).display;
};

export default PDFLabelAnnotator;
