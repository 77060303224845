import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Converts a string into the proper shape for the Notes field mutation
 *
 * @param {string|number} value
 * @param {string} organizationID - used when creating a new note
 * @returns {string|number|*}
 */
export const handleNotesFormat = ({ value, organizationID }) => {
  if (!isNullOrUndefined(value)) {
    if (typeof value === "string") {
      const convertedToObject = [
        {
          id: uuid(),
          ownerGroup: organizationID,
          author: "Import Wizard",
          timeStamp: new Date(),
          content: `<p>${value.replace(/[^A-Za-z0-9\s.,;:'"!?-`~$#-+={}]/g, "")}</p>`, //regex that sanitizes the string to remove any non-standard special characters
        },
      ];

      return convertedToObject;
    }
  } else {
    return [];
  }
};
