/**
 * Generates the `columns` part of data grid views object based on the current state of the data grid exposed through the data grid API
 * @param {object} apiRef - the mui data grid api reference
 * @return {object[]}
 */
export const getColumnsObjectFromGridAPI = ({ apiRef }) => {
  const columns = apiRef?.current?.getAllColumns();

  if (!Array.isArray(columns)) {
    return null;
  } else {
    return columns.reduce((accumulator, column) => {
      const { field, width } = column;
      if (Array.isArray(accumulator)) {
        accumulator.push({
          name: field,
          width,
        });
      } else {
        accumulator = [
          {
            name: field,
            width,
          },
        ];
      }

      return accumulator;
    }, {});
  }
};
