interface GetVariationOfAorAnParams {
  value: string;
  format?: string;
  lng?: string;
}

export const getVariationOfAorAn = ({ value, format, lng }: GetVariationOfAorAnParams): string => {
  if (format === "startsWithVowel" && lng === "en") {
    return /[aeiou]/i.exec(value.charAt(0)) ? `an ${value}` : `a ${value}`;
  }
  return value;
};
