import { objectDefinition } from "../constants/generic/object/object";
import { pointOfContactDefinition } from "../constants/platform/organization/pointOfContact";

/**
 * Finds the expected parameters and return values of a query function by checking the resource definitions
 * @param {string} name - the original name of the function for which to retrieve the definition
 * @returns {*} - the function definition
 */
export const getFunctionDefinitionByName = ({ name }) => {
  const definitionsWithFunctions = [objectDefinition, pointOfContactDefinition];
  const definitionFunctions = [];
  definitionsWithFunctions.forEach((definition) => {
    definitionFunctions.push(...(definition?.queries?.functions || []));
  });

  return definitionFunctions.find((definitionFunction) => {
    return definitionFunction?.name === name;
  });
};
