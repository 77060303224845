import { ReactElement } from "react";

import { LayoutToastsHook } from "@application/Toasts/types/LayoutToastsHook";

export const emptyLayoutToastsHook: LayoutToastsHook = {
  display: (): ReactElement => {
    return <></>;
  },
  newNotificationAmount: 0,
  totalNotificationAmount: 0,
  newActivityAmount: 0,
  totalActivityAmount: 0,
  markAllToastsAsSeen: () => Promise.resolve(),
  toasts: [],
  setToasts: () => undefined,
  addToast: () => undefined,
  updateToast: () => undefined,
  removeToast: () => Promise.resolve(),
  clearToasts: () => Promise.resolve(),
};
