import { v4 as uuid } from "uuid";

import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { updateMutation } from "../graphql/customDashboardGraphql";

import { getCurrentDashboard } from "./getCurrentDashboard";

/**
 * Adds a page to a dashboard based on dashboardID and layoutName.
 * @param {string} dashboardID - the dashboardID of the dashboard to add the page to
 * @param {string} layoutName - the name of the layout to add
 * @param {function} setItemQuery - the setItem function from the useQueryGetItem hook
 * @returns {Promise<void>}
 */
export const addPageToDashboard = async ({ dashboardID, layoutName, setItemQuery }) => {
  // first, perform a clean fetch of the CustomDashboardLayouts
  const customDashboardQuery = await getCurrentDashboard({
    itemId: dashboardID,
  });

  // get layouts from CustomDashboard query
  const layouts = customDashboardQuery?.layouts || [];

  // Update layouts array
  const updatedLayouts = [
    ...layouts,

    // add new layout
    {
      id: uuid(),
      name: layoutName,
    },
  ];

  // perform a mutation to update the CustomDashboard object in the db with the new layouts
  const updatedItem = await ItemMutation(updateMutation, {
    id: dashboardID,
    layouts: updatedLayouts,
  });

  if (typeof setItemQuery === "function") {
    setItemQuery(updatedItem);
  }
};
