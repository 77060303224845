import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useRoleDataGrid } from "../../../OrganizationManager/Roles/hooks/useRoleDataGrid";
import RoleTemplateDetails from "../components/RoleTemplateDetails";
import RoleTemplateForm from "../components/RoleTemplateForm";

/**
 * A Role grid with the ownerGroup and configs setup for Role Templates. Used by Rivial Admins to create Role Templates.
 * @param {string} organizationID - the organization that these templates are a part of
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will not perform role checking
 * @param {object} props - other properties to pass down tot the useRoleDataGrid hook
 * @returns {{selectedData: [], isLoading: *, setIsLoading: (function(((function(*): *)|*)): void), data: *[], setData: (function(((function(*[]): *[])|*[])): void), resetFunction: (function(): void), display: JSX.Element, selectedIDs: []}}
 */
export const useRoleTemplateGrid = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.ROLE,
  disableRoleChecking = false,
  ...props
}) => {
  const cardConfig = {
    title: "Role Templates",
  };

  const gridConfig = {
    persistenceUUID: "b9f903a6-3762-4bcd-bde4-0dafb8bf0579",
    route: "#/admin_panel/role_templates/",
    headerButtons: [],
    createResourceComponent: <RoleTemplateForm organizationID={organizationID} />,
    detailsComponent: <RoleTemplateDetails />,
  };

  const roleGrid = useRoleDataGrid({
    organizationID,
    cardConfig,
    gridConfig,
    module,
    resource,
    disableRoleChecking,
    ...props,
  });

  return {
    ...roleGrid,
  };
};
