import React from "react";

/**
 * @description Displays a Boolean input field
 * @param {boolean} value - current value
 * @returns {JSX.Element}
 * @constructor
 */
const BooleanDisplay = ({ value }) => {
  return <span>{value ? "Yes" : "No"}</span>;
};

export default BooleanDisplay;
