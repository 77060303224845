import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 *
 * @param assessments {object[]}
 * @param assessment {object}
 * @param findings {object[]}
 * @param finding {object}
 * @param target {object}
 * @param organizationID {string}
 * @returns {Promise<void>}
 * @constructor
 */

export const createAssessmentTargetLink = async ({ assessment, target, organizationID }) => {
  const { createMutation } = generateGraphql("AssessmentTargetLink", ["assessmentID", "targetID"]);

  return await ItemMutation(createMutation, {
    assessmentID: assessment?.id,
    targetID: target?.id,
    ownerGroup: organizationID,
  }).then(() => InfoLogger("AssessmentTargetLink was successfully created."));
};
