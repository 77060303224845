import React from "react";
import { CustomInput } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";
import { ItemMutation } from "../../../../../../../../../utils/Functions/Graphql/ItemMutation";
import {
  disableNonStandardRiskControl,
  enableNonStandardRiskControl,
} from "../../../../../Overrides/functions/OverrideFunctions";

const updateSystem = /* GraphQL */ `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      name
      description
      ownerGroup
    }
  }
`;

/**
 * Switches a Sub-Control to standard/non-standard in the context of a system
 * @param system
 * @param riskControl
 * @param resetFunction
 * @param style
 * @param isNonStandard
 * @param setIsNonStandard
 * @param disableRoleChecking
 * @returns {JSX.Element}
 * @constructor
 */
const NonStandardSwitch = ({
  system,
  riskControl,
  resetFunction,
  style,
  isNonStandard,
  setIsNonStandard,
  isOutsourced,
  disableRoleChecking = false,
}) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const updateSystemCallback = async ({ input }) => {
    const result = await ItemMutation(updateSystem, input);
    if (typeof resetFunction === "function") {
      resetFunction();
    }
    return result;
  };

  const toggleOn = () => {
    if (!isNonStandard) {
      enableNonStandardRiskControl(system, riskControl, updateSystemCallback);
      setIsNonStandard(true);
    }
  };

  const toggleOff = () => {
    if (isNonStandard) {
      disableNonStandardRiskControl(system, riskControl, updateSystemCallback);
      setIsNonStandard(false);
    }
  };

  return (
    <>
      {system && riskControl && (
        <div style={style.div}>
          <strong>
            Non-Standard
            <i
              className="icon-question"
              title={
                isOutsourced
                  ? "Because this control is marked as 'Outsourced', it is automatically a Non-Standard Control and doesn't share Cost and Implementation values with the Organization-Level controls "
                  : "A Non-Standard control doesn't share Cost and Implementation values with the Organization-Level controls."
              }
              style={style.icon}
            />
          </strong>
          <div style={style.field}>
            <CustomInput
              disabled={!checkPermissions.resource.update || isOutsourced === true}
              type="switch"
              id={`non_standard_switch${system.id}${riskControl.id}`}
              name={`non_standard_switch${system.id}${riskControl.id}`}
              checked={isNonStandard}
              onChange={() => (isNonStandard ? toggleOff() : toggleOn())}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withOrganizationCheck(NonStandardSwitch);
