import { createInlineText, createText } from "@rivial-security/report-utils";

export const generateTypeOfReport = () => {
  return [
    createText("Type of Report", "Normal", { underline: true }),

    createText([
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" SOC 1 Report", "Normal", { bold: true }),
      createInlineText(" – Internal controls over financial reporting"),
    ]),
    createText([
      createInlineText("            ", "Normal"),
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" Type I"),
      createInlineText("            ", "Normal"),
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" Type II"),
    ]),

    createText([
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" SOC 2 Report", "Normal", { bold: true }),
      createInlineText(" – Controls over security, availability, processing integrity, confidentiality/privacy"),
    ]),
    createText([
      createInlineText("            ", "Normal"),
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" Type I"),
      createInlineText("            ", "Normal"),
      createInlineText("☐", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" Type II"),
    ]),

    createText([
      createInlineText("☒", "Normal", { fontFamily: "MS Gothic" }),
      createInlineText(" Other", "Normal", { bold: true }),
      createInlineText(" – PCIDSS Certification"),
    ]),
  ];
};
