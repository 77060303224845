/**
 * Finds axes form input settings based on widget config axes parameter
 * @param {object} axes - the axes object from a Custom Widget config
 * @returns {{yAxisName: *, xAxisName: *}}
 */
export const getInitialSettingsFormInput = ({ axes }) => {
  const xAxisName = axes?.x?.label;
  const yAxisName = axes?.y?.label;

  return {
    xAxisName,
    yAxisName,
  };
};
