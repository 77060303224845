import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useVendorSubControlDataGrid } from "../../VendorSubControls/hooks/useVendorSubControlDataGrid";
import AddControlsToCertification from "../components/AddControlsToCertification";
import { DeleteVendorCertificationControlLink } from "../functions/DeleteVendorCertificationControlLink";

/**
 * Display Vendor Certification details
 * @param props
 * @returns {{display: JSX.Element}}
 */
export const useVendorCertificationDetails = (props) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_CERTIFICATION;

  const [vendorCertification, setVendorCertification] = useState(null);
  const [controls, setControls] = useState([]);

  const { getQuery, updateMutation } = generateGraphql("VendorCertification", ["name", "controlLinks"], {
    controlLinks: `(limit: 100) {
    items {
       id
       control {
        id
        name
        statementNumber
       }
      }
      nextToken
    }`,
  });

  const geVendorCertificationHook = useQueryGetItem({
    query: getQuery,
    itemId: props.itemId,
    module,
    resource,
  });

  useEffect(() => {
    props.setQueryRef && props.setQueryRef(geVendorCertificationHook);
  }, []);

  useEffect(() => {
    props?.setIsLoading?.(geVendorCertificationHook.isLoading);
  }, [geVendorCertificationHook.isLoading]);

  useEffect(() => {
    if (geVendorCertificationHook && geVendorCertificationHook.item) {
      setVendorCertification(geVendorCertificationHook.item);
    }

    if (
      geVendorCertificationHook &&
      geVendorCertificationHook.item &&
      geVendorCertificationHook.item.controlLinks &&
      geVendorCertificationHook.item.controlLinks.items &&
      Array.isArray(geVendorCertificationHook.item.controlLinks.items)
    ) {
      const controlsLocal = [];

      for (const link of geVendorCertificationHook.item.controlLinks.items) {
        if (link && link.control) {
          controlsLocal.push(link.control);
        }
      }
      setControls(controlsLocal);
    }
  }, [geVendorCertificationHook && geVendorCertificationHook.item]);

  const DeleteFunction = (item) => {
    vendorCertification &&
      vendorCertification.controlLinks &&
      vendorCertification.controlLinks.items &&
      vendorCertification.controlLinks.items.map((link) => {
        if (link?.control?.id === item.id) {
          DeleteVendorCertificationControlLink(link).then(() => geVendorCertificationHook.reset());
        }
      });
  };

  const vendorSubControlGrid = useVendorSubControlDataGrid({
    organizationID: props.organizationID,
    queryConfig: { query: null },
    cardConfig: {
      title: "Control List",
    },
    data: controls,
    resetFunction: geVendorCertificationHook.reset,
    createResourceComponent: (
      <AddControlsToCertification item={vendorCertification} organizationID={props.organizationID} />
    ),
    createItemModalHeader: "Select Controls to add to this Pre-Qualified Certification",
    deleteFunction: DeleteFunction,
    deleteButtonText: "Unlink",
    createResourceButtonText: "Link",
  });

  useEffect(() => {
    if (controls) {
      vendorSubControlGrid.setData(controls);
    }
  }, [controls]);

  const detailsCard = useDetailsCard({
    organizationID: props.organizationID,
    module,
    resource,
    queryConfig: {
      query: getQuery,
      itemId: vendorCertification?.id,
    },
    fields: ["name"],
    updateMutation,
  });

  const display = (
    <Dashboard
      id="certification-details-dashboard"
      subTitle={vendorCertification?.name ?? undefined}
      resetFunction={geVendorCertificationHook?.reset}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <DashboardCard id="certification-details-card" title={"General Information"} icon={"icon-list"}>
            {detailsCard.tableDisplay}
          </DashboardCard>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <DashboardCard
            id="certification-controls-card"
            title={"Vendor Controls"}
            icon={"icon-list"}
            style={{ height: "70vh" }}
          >
            {vendorSubControlGrid.display}
          </DashboardCard>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    display,
  };
};
