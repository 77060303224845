import { ReactElement } from "react";

/**
 * @description This component is used to add or remove an icon from a button
 * @param {boolean} isInline - TRUE if the icon needs to have the inline style applied
 */
const AddOrRemoveIcon = ({ isInline }: { isInline?: boolean }): ReactElement => {
  const buttonElements = [
    <i className="icon-plus" style={{ marginRight: "0.2em" }} />,
    "/",
    <i className="icon-minus" style={{ marginLeft: "0.2em" }} />,
  ];

  return <div style={{ ...(isInline && { display: "inline" }) }}>{buttonElements}</div>;
};

export const AddIcon = ({ isInline }: { isInline?: boolean }): ReactElement => {
  return (
    <div style={{ ...(isInline && { display: "inline" }) }}>
      <i className="icon-plus" style={{ marginRight: "0.2em" }} />
    </div>
  );
};

export default AddOrRemoveIcon;
