import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Delete a single Vulnerability Target History item from the database
 * @param {object} item - vulnerability target history item
 * @returns {Promise<*>}
 */
export const deleteVulnerabilityTargetHistory = async (item) => {
  const { deleteMutation } = generateGraphql("TargetVulnerabilityHistory");
  return await ItemMutation(deleteMutation, { id: item?.id });
};
