import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useControlCategoriesDataGrid } from "../hooks/useControlCategoriesDataGrid";

/**
 * Component instance of the useControlCategoriesGrid
 * @param {object} props - all props passed to ControlCategoriesDataGrid component
 * @returns {JSX.Element}
 * @constructor
 */
const ControlCategoriesDataGrid = (props) => useControlCategoriesDataGrid(props).dashboardDisplay;

export default withOrganizationCheck(ControlCategoriesDataGrid);
