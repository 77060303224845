import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { tryItemFunction } from "../../../../utils/Functions/tryFunction";

import { addAgendaItemsToMeeting } from "./addAgendaItemsToMeeting";
import { attachContactsToMeeting } from "./attachContactsToMeeting";

/**
 * Schedule a meeting function
 * @param {object} input
 * @param {function} getNewItem
 * @param {string} organizationID
 * @param {object} uiContext
 */
export const scheduleMeeting = async ({ input, getNewItem, organizationID, uiContext }) => {
  if (isNullOrUndefined(input) || isNullOrUndefined(uiContext) || organizationID === "") {
    throw new Error("[scheduleMeeting.js] Invalid function input");
  }

  const { addToast, updateToast } = uiContext;
  const toastId = `schedule_meeting-${uuid()}`;

  addToast({
    id: toastId,
    icon: "spinner",
    header: `Creating a new meeting..`,
  });

  const { createMutation: createMeeting } = generateGraphql("Meeting", [
    "name",
    "description",
    "startTime",
    "endTime",
    "isAllDay",
    "organizer",
    "location",
    "status",
    "module",
  ]);

  let newMeeting;
  try {
    newMeeting = await ItemMutation(createMeeting, {
      name: input?.title,
      description: input?.description,
      ownerGroup: organizationID,
      startTime: input?.startTime,
      endTime: input?.endTime,
      isAllDay: input?.isAllDay,
      organizer: input?.organizer,
      location: input?.location,
      status: "scheduled",
      module: modules.GOVERNANCE,
    });

    if (!newMeeting?.id) {
      throw new Error("[scheduleMeeting.js] id not found after mutation");
    }
  } catch (err) {
    updateToast({
      id: toastId,
      icon: "danger",
      header: `Error! Could not create a meeting!`,
    });
    throw new Error(`[scheduleMeeting.js] Mutation error${JSON.stringify(err)}`);
  }

  try {
    await attachContactsToMeeting({
      contacts: input?.pointOfContacts,
      newMeeting,
      organizationID,
    });
  } catch (e) {
    ErrorLogger("[scheduleMeeting.js] Cannot attach contacts to a meeting", e);
  }

  try {
    await addAgendaItemsToMeeting({
      agendaItems: input?.agendaItems,
      newMeeting,
      organizationID,
    });
  } catch (e) {
    ErrorLogger("[scheduleMeeting.js] Cannot add agenda items to a meeting", e);
  }

  updateToast({
    id: toastId,
    icon: "success",
    header: `Meeting was successfully created`,
  });

  tryItemFunction({ item: newMeeting, getNewItem });
  return newMeeting;
};
