import { AssessmentTargetLink } from "@rivial-security/schema-types";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { assessmentTargetLinksFalsePositivesByAssessmentId } from "../graphql/__assessmentGQL";

export const getAssessmentTargetLinksFalsePositives = async (assessmentID: string): Promise<AssessmentTargetLink[]> => {
  return ListQueryBy({
    query: assessmentTargetLinksFalsePositivesByAssessmentId,
    variables: { assessmentID },
    limit: 200,
  });
};
