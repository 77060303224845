import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { isTemplateOrganizationID } from "../../../Compliance/utils/isTemplateOrganizationID";
import CustomWidgetConfigField from "../../CustomFields/CustomWidgetConfigField";
import CustomWidgetTypeField from "../../CustomFields/CustomWidgetTypeField";
import RenderWidget from "../../WidgetRenderer";

/**
 * Show a single query details
 * @param itemId
 * @param organizationID
 * @param tableDisplay
 * @param updateItemById
 * @param props
 */
export const useCustomWidgetDetails = ({ itemId, organizationID, tableDisplay, updateItemById, ...props }) => {
  const isTemplateWidget = isTemplateOrganizationID(organizationID);
  const module = isTemplateWidget ? modules.ADMINISTRATOR : modules.ORGANIZATION_MANAGER;
  const resource = resources.CUSTOM_WIDGET;
  const typename = "CustomWidget";

  //SAMPLE FIELD CONFIG
  const fields = ["name", "description", "type", "config"];
  const fieldNameDictionary = {
    config: "Configuration",
  };
  const nestedFields = {
    customQuery: `{ id ownerGroup name description queryConfig }`,
  };

  const { getQuery, updateMutation } = generateGraphql(typename, fields, nestedFields);

  const queryConfig = {
    itemId,
    organizationID,
    query: getQuery,
  };

  const detailsCardHook = useDetailsCard({
    typename,
    nestedFields,

    module,
    resource,
    fields,
    fieldNameDictionary,

    queryConfig,
    detailsConfig: {
      updateMutation,
      customFields: [
        {
          field: "type",
          component: <CustomWidgetTypeField />,
        },
        {
          field: "config",
          component: <CustomWidgetConfigField updateItemById={updateItemById} organizationID={organizationID} />,
        },
      ],
    },
    config: {
      header: "Widget Details",
    },

    tableDisplay,
    updateItemById,
    ...props,
  });

  const display = (
    <Grid container spacing={2}>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <DashboardCard
          icon={"icon-list"}
          title="General Information"
          style={{ height: "100%" }}
          wrapperStyle={{ height: "100%" }}
        >
          {detailsCardHook.tableDisplay}
        </DashboardCard>
      </Grid>

      <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
        <DashboardCard
          icon={"icon-eye"}
          title="Widget Preview"
          style={{ height: "100%" }}
          wrapperStyle={{ height: "70vh" }}
        >
          <RenderWidget itemId={itemId} organizationID={organizationID} autoRender={true} />
        </DashboardCard>
      </Grid>
    </Grid>
  );

  return {
    ...detailsCardHook,
    display,
  };
};
