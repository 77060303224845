/**
 * Returns an array of all items (including duplicates) by following the provided path
 * @param {object|array|*} item - the retrieved item from database to get nested items from
 * @param {object[]} path - the fields to traverse to get to the leaf resource from the root resource
 * @returns {*[]}
 */
export const getNestedItems = ({ item, path }) => {
  let items = [];

  if (!Array.isArray(path) || path.length === 0) {
    items = [];
  } else if (Array.isArray(path) && path.length === 1) {
    const { name, hasMany } = path[0];

    if (hasMany && Array.isArray(item[name].items)) {
      //add non-null or undefined items to items
      for (const itemToAdd of item[name].items) {
        if (itemToAdd) {
          if (!Array.isArray(items)) {
            items = [];
          }
          items.push(itemToAdd);
        }
      }
    } else if (!hasMany && typeof item[name] === "object") {
      if (item[name]) {
        items = [item[name]];
      }
    } else {
      items = [];
    }
  } else {
    // recursive case go deeper for each array entry or single object
    const { name, hasMany } = path[0];
    const nextPath = path.slice(1);

    let nextItem;
    if (hasMany) {
      nextItem = item[name].items;
    } else {
      nextItem = item[name];
    }

    if (hasMany && Array.isArray(nextItem)) {
      nextItem.forEach((item) => {
        const newItems = getNestedItems({ item, path: nextPath });
        if (!Array.isArray(items)) {
          items = [];
        }
        items = items.concat(newItems);
      });
    } else if (!hasMany && typeof nextItem === "object") {
      items = getNestedItems({ item: nextItem, path: nextPath });
    } else {
      items = [];
    }
  }

  return items;
};
