"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Rivial platform modules enum list
 */
const MODULE_TYPES = {
    ACCOUNT_MANAGER: "accountManager",
    ACTION_CENTER: "actionCenter",
    ADMINISTRATOR: "administrator",
    COMPLIANCE: "compliance",
    DEVELOPER: "developer",
    HELP_CENTER: "helpCenter",
    INCIDENT_RESPONSE: "incidentResponse",
    METRICS: "metrics",
    ORGANIZATION_MANAGER: "organizationManager",
    OPERATION_PANEL: "operationPanel", // NOTE: THIS DOES NOT GET PUT INTO DEFAULT ROLE CONFIG
    PLATFORM: "platform",
    PROGRAM: "program", // deprecated, use GOVERNANCE instead
    GOVERNANCE: "governance",
    REPORTS: "reports",
    RISK: "risk",
    TESTING: "testing", // deprecated, use VULNERABILITIES instead
    VULNERABILITIES: "vulnerabilities",
    TOOLS: "tools",
    TRAINING: "training",
    VENDORS: "vendors",
};
exports.default = MODULE_TYPES;
