import { modules, resources } from "@rivial-security/role-utils";

/**
 * @description Configuration of available Typenames and Fields to be used in QueryBuilder components
 *
 * Evidence
 * System
 * Incident
 * Exercise
 * PointOfContact
 * Meeting
 * RiskControl
 * VendorReview
 * Assessment
 * PolicyVersjion
 * Audit
 * Control
 * KeyPerformanceIndicator
 * Metric
 * GenericActionItem
 * RecommendationItem
 * Observation
 * Risk
 */
export const RESOURCE_TYPE_CONFIG = {
  [resources.EVIDENCE]: {
    name: "Evidence",
    description: "An Item that shows that Security Controls(s) are in place. E.g. “Anti-Virus Monitoring Report”",
    module: modules.COMPLIANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      enabled: {
        type: "boolean",
        default: false,
      },
      status: {
        type: "enum",
        required: true,
        default: "inPlace",
        options: {
          inPlace: "In Place",
          expired: "Not in Place",
          expiringSoon: "Expiring Soon",
          pendingValidation: "Pending Validation",
        },
      },
      type: {
        type: "enum",
        required: true,
        default: "document",
        options: {
          document: "Document",
          report: "Report",
        },
      },
      pointOfContacts: {
        type: "manyToMany",
        typename: resources.POINT_OF_CONTACT,
        connectionField: "pointOfContact",
      },
      activity: {
        type: "oneToMany",
        typename: "EvidenceActivity",
      },
      frequency: {
        type: "cron",
      },
      notes: {
        type: "array",
      },
      task: {
        type: "string",
      },
      tags: {
        type: "manyToMany",
        typename: "Tag",
        connectionField: "tags",
      },
    },
    // frequency, itemNumber, createdAt, updatedAt, riskControls, observations, controls, keyPerformanceIndicator
  },
  [resources.POINT_OF_CONTACT]: {
    name: "Point of Contact",
    description: "A person that is important to the Organization",
    module: modules.ORGANIZATION_MANAGER,
    fields: {
      firstName: {
        type: "string",
      },
      lastName: {
        type: "string",
      },
      email: {
        type: "string",
      },
      evidences: {
        type: "manyToMany",
        connectionField: "evidence",
        typename: "Evidence",
      },
      title: {
        type: "string",
      },
      phone: {
        type: "string",
      },
    },
  },
  [resources.INFORMATION_SYSTEM]: {
    name: "Information System",
    module: modules.RISK,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      hosting: {
        type: "enum",
        options: {
          outsourced: "Outsourced",
          hybrid: "Hybrid",
          internal: "Internal",
        },
      },
      availability: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
        },
      },
      inherentRisk: {
        type: "number",
      },
      residualRisk: {
        type: "number",
      },
      riskRating: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
        },
      },
      lossToleranceDelta: {
        type: "number",
      },
      greatestEnterpriseRisk: {
        type: "enum",
        options: {
          compliance: "Compliance",
          reputation: "Reputation",
          financial: "Financial",
          operational: "Operational",
          strategic: "Strategic",
          transaction: "Transaction",
        },
      },
      greatestBusinessRisk: {
        type: "enum",
        options: {
          dataBreach: "Data Breach",
          systemDisruption: "System Disruption",
          facilityDisruption: "Facility Disruption",
          fraud: "Fraud",
          malware: "Malware",
          vendor: "Vendor",
          compliance: "Compliance",
        },
      },
      greatestKeyRiskIndicator: {
        type: "string",
      },
    },
  },
  [resources.INCIDENT]: {
    name: "Incident",
    module: modules.INCIDENT_RESPONSE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      what: {
        type: "string",
        required: true,
      },
      who: {
        type: "string",
      },
      when: {
        type: "string",
      },
      where: {
        type: "string",
      },
      why: {
        type: "string",
      },
      status: {
        type: "enum",
        options: {
          open: "Open",
          closed: "Closed",
        },
      },
    },
  },
  [resources.EXERCISE]: {
    name: "Exercise",
    module: modules.INCIDENT_RESPONSE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      scenario: {
        type: "string",
      },
    },
  },
  [resources.OBSERVATION]: {
    name: "Observation",
    module: modules.OBSERVATION,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      module: {
        type: "string",
      },
      isFinding: {
        type: "boolean",
      },
      evidenceID: {
        type: "string",
      },
      exerciseID: {
        type: "string",
      },
      complianceControlID: {
        type: "string",
      },
      riskControlID: {
        type: "string",
      },
      vulnerabilityID: {
        type: "string",
      },
      incidentID: {
        type: "string",
      },
      externalSourceID: {
        type: "string",
      },
      auditID: {
        type: "string",
      },
      formalResponse: {
        type: "string",
      },
      estimatedCompletionDate: {
        type: "date",
      },
      risk: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
          info: "Info",
        },
      },
      needsDecision: {
        type: "boolean",
      },
      insurancePolicyValidated: {
        type: "boolean",
      },
      decision: {
        type: "enum",
        options: {
          accept: "Accept",
          mitigate: "Mitigate",
          transfer: "Transfer",
          avoid: "Avoid",
        },
      },
      status: {
        type: "enum",
        options: {
          open: "Open",
          closed: "Closed",
        },
      },
    },
  },
  [resources.RECOMMENDATION]: {
    name: "Recommendation",
    module: modules.GOVERNANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      status: {
        type: "enum",
        options: {
          resolved: "Resolved",
          unresolved: "Unresolved",
          wontResolve: "Wont Resolve",
        },
      },
      rating: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
          info: "Info",
        },
      },
      resolvedAt: {
        type: "date",
      },
      difficulty: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
          info: "Info",
        },
      },
    },
  },
  [resources.ACTION_ITEM]: {
    name: "Action Item",
    module: modules.GOVERNANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "enum",
        options: {
          proposed: "Proposed",
          inProgress: "In Progress",
          onHold: "On Hold",
          pastDue: "Past Due",
          complete: "Complete",
        },
      },
      dueDate: {
        type: "date",
      },
      completionDate: {
        type: "date",
      },
      startDate: {
        type: "date",
      },
      priority: {
        type: "number",
      },
      module: {
        type: "string",
      },
      landPageUrl: {
        type: "string",
      },
      enableNotifications: {
        type: "string",
      },
    },
  },
  [resources.TASK]: {
    name: "Task",
    module: modules.GOVERNANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "enum",
        options: {
          todo: "To Do",
          inProgress: "In Progress",
          pastDue: "Past Due",
          done: "Done",
        },
      },
      type: {
        type: "enum",
        options: {
          accessRequest: "Access Request",
          custom: "Custom",
          checklist: "Checklist",
          findingDecision: "Finding Decision",
          templateUpdate: "Template Update",
          questionnaire: "Questionnaire",
        },
      },
      module: {
        type: "string",
      },
      dueDate: {
        type: "date",
      },
    },
  },
  [resources.METRIC]: {
    name: "Metric",
    module: modules.ORGANIZATION_MANAGER,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      scenario: {
        type: "string",
      },
    },
  },
  [resources.KEY_PERFORMANCE_INDICATOR]: {
    name: "Key Performance Indicator",
    module: modules.ORGANIZATION_MANAGER,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "enum",
        options: {
          failed: "Failed",
          success: "Success",
          warning: "Warning",
          pending: "Pending",
        },
      },
    },
  },
  [resources.CONTROL]: {
    name: "Control",
    module: modules.COMPLIANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      statementNumber: {
        type: "string",
      },
      isDisabled: {
        type: "boolean",
      },
      inPlace: {
        type: "boolean",
      },
      customFieldData: {
        type: "string",
      },
      controlControlSetID: {
        type: "string",
      },
    },
  },
  [resources.ASSESSMENT]: {
    name: "Assessment",
    module: modules.VULNERABILITIES,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      includedListOfTargets: {
        type: "string",
      },
      excludedListOfTargets: {
        type: "string",
      },
      scheduledDate: {
        type: "date",
      },
      penTester: {
        type: "string",
      },
      penTesterEmail: {
        type: "string",
      },
      type: {
        type: "string",
      },
      scanStatus: {
        type: "string",
      },
      importStatus: {
        type: "string",
      },
      scanner: {
        type: "string",
      },
      scoreCVSS: {
        type: "string",
      },
    },
  },
  [resources.VENDOR_SOLUTION]: {
    name: "Vendor Solutions",
    module: modules.VENDORS,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      contractDetails: {
        type: "string",
      },
      contractStatus: {
        type: "enum",
        options: {
          none: "none",
          current: "current",
          expired: "expired",
          expiringSoon: "expiringSoon",
        },
      },
      contractExpirationDate: {
        type: "date",
      },
    },
  },
  [resources.VENDOR_REVIEW]: {
    name: "Vendor Review",
    module: modules.VENDORS,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      vendorSolutionID: {
        type: "string",
        required: true,
      },
      rating: {
        type: "enum",
        options: {
          high: "High",
          mediumHigh: "Medium-High",
          medium: "Medium",
          lowMedium: "Low-Medium",
          low: "Low",
        },
      },
      summary: {
        type: "string",
      },
      completedBy: {
        type: "string",
      },
    },
  },
  [resources.MEETING]: {
    name: "Meeting",
    module: modules.GOVERNANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "string",
      },
    },
  },
  [resources.REPORT]: {
    name: "Report",
    module: modules.REPORTS,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "string",
      },
      type: {
        type: "string",
      },
    },
  },
  PolicyVersion: {
    name: "Policy Version",
    module: modules.GOVERNANCE,
    fields: {
      id: {
        type: "string",
        required: true,
        unique: true,
      },
      name: {
        type: "string",
        required: true,
      },
      description: {
        type: "string",
      },
      status: {
        type: "string",
      },
      version: {
        type: "number",
      },
      approvedBy: {
        type: "string",
      },
      approvalDate: {
        type: "date",
      },
    },
  },
};
