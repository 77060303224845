import React from "react";
import { withRouter } from "react-router-dom";

import { modules, resources } from "@rivial-security/role-utils";

import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useSystemAvailabilityChangeDetails } from "../hooks/useSystemAvailabilityChangeDetails";

const SystemAvailabilityChangeDetailsBody = ({ itemId, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const availabilityChangeDetails = useSystemAvailabilityChangeDetails({
    itemId,
    module,
    resource,
    tableDisplay,
  });

  return <>{availabilityChangeDetails.display}</>;
};

const SystemAvailabilityChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  return (
    <OrganizationCheck {...props}>
      <SystemAvailabilityChangeDetailsBody itemId={itemId} />
    </OrganizationCheck>
  );
};

export default withRouter(SystemAvailabilityChangeDetails);
