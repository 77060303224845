import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useGridCard } from "../../../../../hooks/views/useGridCard";

/**
 * Grid for getting ClassificationTiers for an org
 * @param organizationID
 * @param gridConfig
 * @param cardConfig
 * @param queryConfig
 */
export const useClassificationGrid = ({ organizationID, gridConfig = {}, cardConfig = {}, queryConfig = {} }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.CLASSIFICATION_TIER;
  const typename = "ClassificationTier";

  const queryFields = ["name", "description", "level"];

  const fields = [
    {
      name: "name",
    },
    {
      name: "description",
    },
    {
      name: "level",
    },
  ];

  const { listQuery } = generateGraphql(typename, queryFields);

  queryConfig = {
    query: listQuery,
    organizationID,
    ...queryConfig,
  };

  gridConfig = {
    module,
    resource,
    typename,
    fields,
    ...gridConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  return useGridCard({
    cardConfig,
    queryConfig,
    gridConfig,
    roleConfig,
  });
};
