import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import { gql_getRiskControlAssociatedSystems } from "../../../../RiskChanges/graphql/gql_getRiskControlAssociatedSystems";

export const getRiskControlAssociatedSystems = async (riskControl, system) => {
  const res = [];
  if (system && riskControl) {
    res.push(system);
  } else if (riskControl) {
    const queryItem = await ItemQuery(gql_getRiskControlAssociatedSystems, riskControl.id);
    if (queryItem) {
      const controlCategory = queryItem.controlCategory;
      const systemLinks = controlCategory && controlCategory.systems && controlCategory.systems.items;
      const systems = [];
      if (systemLinks) {
        for (const systemLink of systemLinks) {
          systems.push({ ...systemLink.system });
        }
      }
      res.push(...systems);
    }
  }

  return res;
};
