"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAValue = void 0;
const getMaxAvailability_1 = require("./getMaxAvailability");
/**
 * Gets the availability value (in dollars) from a system.
 * Based on ratingScale, assetSize, and availabilityRating on the System
 * @function
 * @param system
 * @param ratingScale
 * @param assetSize
 * @returns {number}
 */
const getAValue = (system, ratingScale, assetSize) => {
    const maxA = system && ratingScale ? (0, getMaxAvailability_1.getMaxAvailability)(system, ratingScale) : 0;
    return maxA * assetSize;
};
exports.getAValue = getAValue;
