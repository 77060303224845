import React from "react";

import { useModal } from "../../../../../hooks/views/useModal";
import ArtifactValidation from "../../ArtifactValidation/ArtifactValidation";

/**
 * Modal for displaying an artifact
 * @param {object} artifact - artifact object
 * @returns {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useArtifactDetailsModal = ({ artifact } = {}) => {
  const modal = useModal(
    "Artifact Details",
    <ArtifactValidation item={artifact} />,
    <span
      style={{
        borderBottom: "2px dashed",
        textDecoration: "none",
        cursor: "pointer",
        marginLeft: "0.5rem",
      }}
    >
      View Artifact
    </span>,
    { width: "90vw" },
  );

  const display = <span>{artifact?.file?.key && <span>{modal.modalButton}</span>}</span>;

  return {
    ...modal,
    display,
  };
};
