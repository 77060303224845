"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllSystemsFromKeyRiskIndicator = void 0;
const getAllSystemsFromKeyRiskIndicator = (keyRiskIndicator) => {
    /**
     * Traverse KRI object to obtain all associated systems
     */
    if (keyRiskIndicator !== null &&
        keyRiskIndicator !== undefined &&
        typeof keyRiskIndicator === "object" &&
        keyRiskIndicator.id !== null &&
        keyRiskIndicator.id !== undefined) {
        const systemLinks = keyRiskIndicator?.systems?.items;
        const systems = [];
        if (systemLinks) {
            for (const systemLink of systemLinks) {
                const system = systemLink.system;
                systems.push({ ...system });
            }
        }
        else {
            console.log("This KRI has no associated Systems");
        }
        /**
         * Return the systems array
         */
        return systems;
    }
    else {
        throw new Error("Error: Invalid KRI input");
    }
};
exports.getAllSystemsFromKeyRiskIndicator = getAllSystemsFromKeyRiskIndicator;
