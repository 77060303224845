import { generateGraphql } from "@rivial-security/generategraphql";

const fields = ["name", "type", "description", "date", "userEmail", "changeOwner", "reason", "change"];

export const getRiskChange_riskChangeDetails = /* GraphQL */ `
  query GetRiskChange($id: ID!) {
    getRiskChange(id: $id) {
      id
      ownerGroup
      name
      type
      description
      date
      userEmail
      changeOwner
      reason
      change
      riskControlLinks {
        items {
          id
          ownerGroup
          riskChangeID
          riskControlID
          costChange
          implementationChange
          riskControl {
            id
            name
            ownerGroup
            statementNumber
          }
          riskChange {
            id
            ownerGroup
            name
            type
            description
            date
            userEmail
            changeOwner
            reason
            change
          }
        }
      }
      systemLinks {
        items {
          id
          ownerGroup
          system {
            id
            name
            ownerGroup
          }
        }
      }
    }
  }
`;

export const updateRiskChange_riskChangeDetails = generateGraphql("RiskChange", fields).updateMutation;
