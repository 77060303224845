import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";

import { convertCamelCaseToSentence, isNonEmptyArray } from "@rivial-security/func-utils";

import { TargetVulnerabilityHistoryType } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { useFilteringChips } from "../../../../utils/GenericComponents/TabbedDrawer/hooks/useFilteringChips";

/**
 * Filtering chips for vulnerability target history
 * @param {object[]} historyItems - the history items to filter
 * @param {boolean} isGrouped - if TRUE the history items are already grouped on the backend, no need to filter
 * @returns {{shownHistoryItems: *[], chipsDisplay: JSX.Element}}
 */
export const useVulnerabilityTargetHistoryChips = ({ historyItems, isGrouped }) => {
  const chipOptions = cloneDeep(TargetVulnerabilityHistoryType);
  delete chipOptions["FALSE_POSITIVE"];
  delete chipOptions["ASSESSMENT"];

  const [shownHistoryItems, setShownHistoryItems] = useState([]);
  const filteringChips = useFilteringChips({
    chipOptions,
    sx: {
      chipsContainer: {
        display: "flex",
        justifyContent: "center",
        lineHeight: "1.3em",
      },
    },
    oneAtATime: false,
    showCounts: false,
    valueFormatter: (value) => {
      return convertCamelCaseToSentence(value);
    },
  });

  //Update shown entries based on chip filters
  useEffect(() => {
    const selectedChips = filteringChips?.selected;
    if (isNonEmptyArray(selectedChips) && isGrouped !== true) {
      const newHistoryItems = [];
      for (const historyItem of historyItems) {
        const type = historyItem?.type;
        if (selectedChips.includes(type)) {
          newHistoryItems.push(historyItem);
        }
      }
      setShownHistoryItems(newHistoryItems);
    } else {
      setShownHistoryItems(historyItems);
    }
  }, [JSON.stringify(filteringChips?.selected), historyItems, isGrouped]);

  return {
    chipsDisplay: filteringChips.display,
    shownHistoryItems,
  };
};
