import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateTool } from "../hooks/useCreateTool";

/**
 * @description: Add single tool component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateTool = (props) => useCreateTool(props).display;

export default withOrganizationCheck(CreateTool);
