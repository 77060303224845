"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getXAxisConfig = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Constructs the configuration object for the X-Axis of the Loss Tolerance Chart
 * See Syncfusion documentation for more info
 * @param {object} ratingMap - organization.riskSetup data
 * @param {number} max - the max of risk tolerance
 * @param {number} residualRisk - if provided will add a vertical line at the organization residual risk
 * @param {number} systemResidualLoss - if provided will add a vertical line at the system residual risk
 */
const getXAxisConfig = ({ ratingMap, max, residualRisk, systemResidualLoss } = {}) => {
    const opacity = 0.2;
    const stripLines = ratingMap
        ? [
            {
                start: 0,
                end: ratingMap?.low * max,
                text: "Low",
                visible: true,
                opacity: opacity,
                color: "green",
            },
            {
                start: ratingMap?.low * max,
                end: ratingMap?.lowMedium * max,
                text: "Low-Medium",
                visible: true,
                opacity: opacity,
                color: "yellow",
            },
            {
                start: ratingMap?.lowMedium * max,
                end: ratingMap?.medium * max,
                text: "Medium",
                visible: true,
                opacity: 0.3,
                color: "gold",
            },
            {
                start: ratingMap?.medium * max,
                end: ratingMap?.mediumHigh * max,
                text: "Medium-High",
                visible: true,
                opacity: opacity,
                color: "darkOrange",
            },
            {
                start: ratingMap?.mediumHigh * max,
                end: ratingMap?.high * max,
                text: "High",
                visible: true,
                opacity: opacity,
                color: "red",
            },
        ]
        : [];
    if (!(0, func_utils_1.isNullOrUndefined)(residualRisk)) {
        stripLines.push({
            start: residualRisk,
            size: 1,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "red",
            text: "KRI Residual Risk",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
            textStyle: {
                marginLeft: "2em",
            },
        });
    }
    if (!(0, func_utils_1.isNullOrUndefined)(systemResidualLoss)) {
        stripLines.push({
            start: systemResidualLoss,
            size: 1,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "red",
            text: "System Residual Risk",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
            textStyle: {
                marginLeft: "2em",
            },
        });
    }
    return {
        title: "Acceptable Risk ($)",
        labelFormat: "c0",
        stripLines,
        minimum: 0,
        maximum: max,
    };
};
exports.getXAxisConfig = getXAxisConfig;
