import { useEffect, useState } from "react";

import { useUIContext } from "@utils/Context/UIContext";

import { useWindowResize } from "../../../../application/Layout/hooks/useWindowResize";
import TabbedDrawer from "../../../../utils/GenericComponents/TabbedDrawer/components/TabbedDrawer";

import { useDocumentEditorCreateTab } from "./useDocumentEditorCreateTab";
import { useDocumentEditorOpenTab } from "./useDocumentEditorOpenTab";
import { useDocumentEditorSaveTab } from "./useDocumentEditorSaveTab";

/**
 * Custom configured tab drawer that displays the file menu for the document editor
 * @param {object} documentEditorContainerRef - the document editor container reference
 * @param {string} organizationID - currently selected organization
 * @param {boolean} preserveTags - whether to keep tags or to fill them in with widgets
 * @param {function} onJobStarted - callback to notify the document editor that a server job has started (takes in job object)
 * @param {function} handleTemplateClick - callback for creating a template with a form configuration
 * @param {function} generateFromTemplate - callback for normal loading of sfdt into the document editor
 * @param {string} [initWidth="22em"] - the width to use for the drawer style
 * @param {number} [defaultTabIndex = 0] - default doc editor drawer tab. 0 = create, 1 = open, 2 = save
 * @return {{isOpen: boolean, setIsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void, display: JSX.Element, generateReport: ((function({reportId: *, config: *}): Promise<void>)|*)}}
 */
export const useDocumentEditorDrawer = ({
  documentEditorContainerRef,
  organizationID,
  preserveTags,
  onJobStarted,
  handleTemplateClick,
  generateFromTemplate,
  initWidth = "22em",
  defaultTabIndex = 0,
  documentEditorWrapperRef,
} = {}) => {
  const { isMobile } = useUIContext();
  const getDrawerWidth = () => {
    return isMobile ? "100%" : initWidth;
  };
  const [drawerWidth, setDrawerWidth] = useState(getDrawerWidth());
  useEffect(() => {
    setDrawerWidth(getDrawerWidth());
  }, [isMobile]);
  const [isOpen, setIsOpen] = useState(!isMobile);

  // Adjust document editor size when drawer expands/collapses or window resizes
  const { width: windowWidth } = useWindowResize();
  useEffect(() => {
    const ref = documentEditorContainerRef?.current;
    if (ref) {
      const availableWidthEditor = document.getElementById("document-editor-div-container")?.clientWidth;
      if (availableWidthEditor) {
        ref.resize(availableWidthEditor, "100%");
      }
    }
  }, [documentEditorContainerRef?.current, isOpen, windowWidth]);

  const create = useDocumentEditorCreateTab({
    organizationID,
    preserveTags,
    onJobStarted,
    handleTemplateClick,
    generateFromTemplate,
  });
  const open = useDocumentEditorOpenTab({
    documentEditorContainerRef,
    organizationID,
  });
  const save = useDocumentEditorSaveTab({
    documentEditorContainerRef,
    organizationID,
  });

  const display = (
    <div
      id={"document-editor-drawer"}
      data-tourid={"document-editor-file-options"}
      style={{
        maxHeight: "100%",
        width: isMobile ? "100%" : initWidth,
        display: isOpen ? "block" : "none",
      }}
    >
      {create?.display}
      <TabbedDrawer
        isOpen={isOpen}
        showCloseTab={isMobile}
        onClosePressed={() => setIsOpen(false)}
        tabs={[create?.tab, open?.tab, save?.tab]}
        onTabChange={(tabIndex) => {
          //Reset the items of the 'create' tab when navigating back to it
          if (tabIndex === 0) {
            create?.resetFunction && create.resetFunction();
          }
        }}
        config={{
          width: drawerWidth,
          direction: "left",
        }}
        defaultTabIndex={defaultTabIndex}
        containerRef={documentEditorWrapperRef}
      />
    </div>
  );

  return {
    display,
    isOpen,
    setIsOpen,
    generateReport: create?.generateReport,
  };
};
