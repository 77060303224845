export const getControlNestedFields = (nestedFields) => {
  if (!nestedFields || typeof nestedFields !== "object") {
    nestedFields = {};
  }

  return {
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
    notes: `{ content observationID }`,
    controlSet: `{id name}`,
    ...nestedFields,
  };
};
