import React from "react";
import { NavLink } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { GridNextAndPreviousButtons } from "./GridNextAndPreviousButtons";

/**
 * @description Header for the Details Modal
 * @param {boolean} hasPrevious - boolean value if selected grid item has previous item
 * @param {boolean} hasNext - boolean value if selected grid item has next item
 * @param {function} adjustSelectedItemIndex - change selected item index in the grid
 * @param {object} detailsItem - item for details component
 * @param {string} route - route of the item type
 * @param {object} config - configuration for grid details
 * @param {string} typename - the name of an item type
 * @param {string} [detailsTitle] - optional, overrides the details modal title
 * @return {JSX.Element}
 * @constructor
 */
export const GridDetailsModalHeader = ({
  hasPrevious,
  hasNext,
  adjustSelectedItemIndex,
  detailsItem,
  route,
  config = {},
  typename,
  detailsTitle,
  toggleModal,
}) => {
  return (
    <div style={{ marginBottom: "-1.0em" }}>
      <GridNextAndPreviousButtons
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        adjustSelectedItemIndex={adjustSelectedItemIndex}
      />
      {detailsTitle || (typename ? `${convertCamelCaseToSentence(typename)} ` : "")}
      {!detailsTitle && "Details"}
      {detailsItem && route && !config.disableDetails && (
        <NavLink
          onClick={() => {
            //WARNING: this is done intentionally to prevent memory leaks with document editor in modals
            toggleModal && toggleModal();
          }}
          style={{ display: "inline-block" }}
          href={route + detailsItem?.id}
          className="icon-share-alt"
          title={route + detailsItem?.id}
        />
      )}
      {/*
          Disabling this for now to remove redundant headers
      */}
      {/*<br/>*/}
      {/*<small>{detailsItem?.name}</small>*/}
    </div>
  );
};
