export const listKnowBe4Trainings = `
      query ListKnowBe4Trainings($query: QueryListKnowBe4TrainingsQueryInput!) {
        listKnowBe4Trainings(query: $query) {
          campaign_id
          name
          status
          start_date
          end_date
          completion_percentage
        }
      }
    `;
