"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvailability = void 0;
const AVAILABILITY_MAPPING_1 = require("../../constants/AVAILABILITY_MAPPING");
const getImpactCost_1 = require("./getImpactCost");
/**
 * Gets the availability value (in dollars) from a system.
 * Based on the selected availabilityRating (high - low)
 * applied as a percentage to the current Confidentiality or Integrity rating (whichever is greatest).
 *
 * Note: any improper inputs will return a $0 availability rating
 * @function
 * @param system
 */
const getAvailability = (system) => {
    // Get the current Confidentiality value
    const confidentiality = (0, getImpactCost_1.getImpactCost)(system, "confidentialityPerRecord");
    // Get the current Integrity value
    const integrity = (0, getImpactCost_1.getImpactCost)(system, "integrityPerRecord");
    // Get the max between Confidentiality and Integrity
    const max = Math.max(confidentiality, integrity);
    // Get the currently selected Availability rating
    // options are: high, mediumHigh, medium, lowMedium, low
    const availability = system.availability;
    if (availability === null || availability === undefined) {
        return 0;
    }
    if (max === null || max === undefined) {
        return 0;
    }
    // Get the Percentage based on the mapping of rating to percentage values
    const mappedRatingPercentage = AVAILABILITY_MAPPING_1.AVAILABILITY_MAPPING[availability];
    if (mappedRatingPercentage === null || mappedRatingPercentage === undefined) {
        return 0;
    }
    // Multiply mapping percentage to the C or I value (whichever is greatest)
    const result = mappedRatingPercentage * max;
    if (isNaN(result)) {
        return 0;
    }
    return result;
};
exports.getAvailability = getAvailability;
