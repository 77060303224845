import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getResponsePlan } from "../../functions/getResponsePlan";

/**
 * Queries detections for the organization from the Response Plan
 * @param organizationID
 * @returns {{data: *[]}}
 */
export const useDetections = ({ organizationID }) => {
  const [detections, setDetections] = useState([]);

  useEffect(() => {
    if (!isNullOrUndefined(organizationID)) {
      getResponsePlan(organizationID).then((plan) => {
        setDetections(plan?.detections || []);
      });
    }
  }, []);

  return {
    data: detections,
  };
};
