import React from "react";
import { Input } from "reactstrap";

/**
 * @description Drag and drop files component
 * @param {string} acceptFileFormat - the file format to accept
 * @param {function} callback - the function to call when a file is dropped
 * @param {number} sizeLimit - the size limit of the file
 * @param {string} sizeLimitMessage - the message to display when the file size is too big
 * @param {boolean} allowMultiSelect - allow multiple files to be selected
 * @returns {JSX.Element}
 * @example <DragAndDrop acceptFileFormat="image/*" callback={this.handleFile}/>
 */
const DragAndDrop = ({ acceptFileFormat, callback, sizeLimit, sizeLimitMessage, allowMultiSelect = true }) => {
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFiles = (files) => {
    const validFiles = [];

    for (const file of files) {
      const fileFormatFromName = file.name.split(".")[1];

      // Check if the file format is accepted
      if (acceptFileFormat) {
        if (acceptFileFormat === fileFormatFromName) {
          /**
           * Check if the file size is less than the size limit
           */
          if (sizeLimit) {
            if (file.size <= sizeLimit) {
              validFiles.push(file);
            } else {
              alert(
                sizeLimitMessage ||
                  `File size for file ${file.name} is too big ${file?.size}, max size is ${sizeLimit} bytes`,
              );
            }
          }
        } else {
          alert(`Wrong file format for file ${file.name} accepting only ${acceptFileFormat} format`);
        }
      }
    }

    if (acceptFileFormat) {
      callback && callback(validFiles);
    } else {
      callback && callback(files);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    validateFiles(files);
  };

  const inputOnChange = (e) => {
    const files = e.target.files;
    validateFiles(files);
  };

  return (
    <div
      className={"drag-drop-zone"}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0",
        width: "100%",
        height: "10em",
        border: "3px dashed #4aa1f3",
      }}
      onDrop={handleDrop}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <div style={{ textAlign: "center" }}>
        <Input
          type="file"
          multiple={allowMultiSelect}
          name="file"
          id="uploadFile"
          accept={`.${acceptFileFormat}`}
          onChange={inputOnChange}
        />
      </div>
      <p style={{ margin: 0, marginTop: ".5em", color: "grey", fontSize: ".9em" }}>
        Drag file here to upload. {acceptFileFormat ? `Format: ${acceptFileFormat}` : ""}
      </p>
    </div>
  );
};
export default DragAndDrop;
