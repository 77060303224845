import React from "react";

import { CsvImporterModalButton } from "../../../../hooks/views/useCsvImporter";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorDataGrid } from "../hooks/useVendorDataGrid";

/**
 * Displays a Modal Button that opens the Vendor Solution CSV IMporter
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const VendorImporterModalButton = ({ organizationID }) => {
  const typename = "Vendor";

  const fields = [
    {
      field: "name",
      name: "Name",
      required: true,
      width: 400,
    },
  ];

  return (
    <CsvImporterModalButton
      fields={fields}
      organizationID={organizationID}
      typename={typename}
      resultGrid={useVendorDataGrid}
    />
  );
};

export default withOrganizationCheck(VendorImporterModalButton);
