import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
} from "@apollo/client";

import { isLocalhost } from "@utils/Functions/isLocalhost";

import { GRAPHQL_ENDPOINTS_ENV_URL, GRAPHQL_ENDPOINTS_ENV_URL_LOCALHOST } from "./env-config";

const link = (): ApolloLink => {
  const httpLink = new HttpLink({
    uri: isLocalhost() ? GRAPHQL_ENDPOINTS_ENV_URL_LOCALHOST : GRAPHQL_ENDPOINTS_ENV_URL,
  });

  const authLink = new ApolloLink((operation: Operation, forward: NextLink): Observable<FetchResult> => {
    const accessToken = localStorage.getItem("accessToken") ?? "";

    operation.setContext({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    });

    return forward(operation);
  });

  return ApolloLink.from([authLink, httpLink]);
};

const client = new ApolloClient({
  link: link(),
  cache: new InMemoryCache(),
});

export default client;
