import React, { useEffect, useState } from "react";
import { Button, Row } from "reactstrap";

import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { withOrganizationCheck } from "../../Context/withOrganizationCheck";
import { ItemQuery } from "../../Functions/Graphql/ItemQuery";
import { ListQuery } from "../../Functions/Graphql/ListQuery";
import NotEnoughData from "../../GenericComponents/NotEnoughData";
import Loader from "../../LoadingComponents/Loader";

import Tag from "./Tag";

const NoTagsMessage = ({ totalTags, createCallback }) => {
  const createNotEnoughDataMessage = () => {
    if (totalTags === 0) {
      return "Your organization doesn't have any tags.";
    }
    return "All tags are already assigned.";
  };

  return (
    <NotEnoughData
      message={createNotEnoughDataMessage()}
      callToAction={{
        message: totalTags === 0 ? " to make the first tag!" : " to make more tags!",
        size: "sm",
        placeOnNewLine: true,
        function: () => createCallback?.(),
      }}
    />
  );
};

const TagRow = ({ availableTags, addTagToItem }) => (
  <>
    {availableTags.map((tag, index) => (
      <Row
        key={tag?.id || index}
        style={{
          marginBottom: index !== availableTags.length - 1 ? ".5em" : "0em",
        }}
      >
        <Button
          style={{ marginLeft: "1em", marginRight: "1em" }}
          size="sm"
          color="success"
          className="btn-pill"
          onClick={() => addTagToItem(index)}
          title="Add tag to this item"
        >
          <i className="icon-plus" />
        </Button>
        <Tag isSelected={true} tag={tag} />
        <div style={{ marginLeft: "1em" }} />
      </Row>
    ))}
  </>
);

const Wrapper = ({ children }) => <div style={{ width: "250px" }}>{children}</div>;

/**
 * Allows for a quick way to add tags to an item
 * @param {object} item - the item to which to add tags
 * @param {string} typename - the string name of the items schema type
 * @param {function} updateCallback - called with the latest selected tag information on successful addition in the back end
 * @param {function} createCallback - called when a new tag has been created from within the quick pick tag UI
 * @param {object} itemTags - tags that are already on the item needs to have object.tags.items.tag.id structure
 * @param {object[]} organizationTags - custom tag pool from which the item tags can be selected
 * @param {function} createLinkFunction - because tags attached to resources are many to many links,
 * need to provide a custom function to attach tags with this UI. This function will be given 3 positional parameters -
 * (item, tag, organizationID)
 * @param {function} [getItemTagsQueryFunction] - optional override for the query function for getting the tags for the item
 */
const QuickPickTag = ({
  item,
  typename,
  itemTags,
  updateCallback,
  createCallback,
  organizationID,
  createLinkFunction,
  getItemTagsQueryFunction,
}) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [totalTags, setTotalTags] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const listOrganizationTagsQuery = generateGraphql("Tag", [
    "name",
    "description",
    "fontColor",
    "backgroundColor",
    "systemTagID",
  ]).listQuery;

  const getItemTagsQuery = generateGraphql(typename, ["tags"], {
    tags: `(limit: 100) {items { tag { id }}}`,
  }).getQuery;

  const retrieveRemainingTags = async () => {
    try {
      setIsLoading(true);
      if (item?.id && organizationID) {
        let currentItemTags;
        if (getItemTagsQueryFunction) {
          currentItemTags = await getItemTagsQueryFunction(item);
        } else if (!itemTags && getItemTagsQuery && item?.id) {
          currentItemTags = await ItemQuery(getItemTagsQuery, item?.id);
        } else {
          currentItemTags = itemTags;
        }

        let tagsList = await ListQuery({
          query: listOrganizationTagsQuery,
          organizationID,
        });

        tagsList = tagsList.filter((tag) => !tag.systemTagID);
        setTotalTags(tagsList.length);

        if (
          tagsList &&
          Array.isArray(tagsList) &&
          currentItemTags?.tags?.items &&
          Array.isArray(currentItemTags.tags.items)
        ) {
          tagsList = tagsList.filter(
            (tag) => !currentItemTags.tags.items.some((itemTag) => itemTag?.tag?.id === tag.id),
          );
        }

        tagsList.sort((a, b) => a.name.localeCompare(b.name));

        setAvailableTags(tagsList);
      }
    } catch (error) {
      ErrorLogger("Error retrieving tags", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Determining all available tags on initial render
  useEffect(() => {
    retrieveRemainingTags();
  }, []);

  const addTagToItem = async (index) => {
    if (availableTags.length > index && item?.id && organizationID && createLinkFunction) {
      const tagToAdd = availableTags[index];
      const itemTagLink = await createLinkFunction(item, tagToAdd, organizationID);

      if (updateCallback && itemTagLink?.id) {
        updateCallback(
          {
            ...tagToAdd,
            tagLinkID: itemTagLink?.id,
            tagLinkTypename: itemTagLink?.__typename,
          },
          availableTags.length === 1,
        );
      }

      setAvailableTags((prevTags) => prevTags.filter((_, i) => i !== index));
    }
  };
  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isNonEmptyArray(availableTags) ? (
        <TagRow availableTags={availableTags} addTagToItem={addTagToItem} />
      ) : (
        <NoTagsMessage availableTags={availableTags} createCallback={createCallback} totalTags={totalTags} />
      )}
    </Wrapper>
  );
};

export default withOrganizationCheck(QuickPickTag);
