import { fieldIsHidden } from "./fieldIsHidden";

/**
 * Generates the `columnVisibilityModel` part of the `Default` data grid view based on field configurations for the grid
 * @param {object[]} fields - fields prop passed through from useDataGrid
 * @param {object[]} customFields - customFields prop passed through from useDataGrid
 * @return {object} - the default visibility object for the data grid
 */
export const getDefaultDataGridColumnVisibilityModel = ({ fields, customFields }) => {
  const newColumnVisibilityModel = {};

  // Handle column visibility of static fields
  if (Array.isArray(fields)) {
    for (const field of fields) {
      const fieldName = field.name || field.field;
      newColumnVisibilityModel[fieldName] = !fieldIsHidden({
        field,
        defaultValue: false,
      });
    }
  }

  // Handle column visibility of custom fields
  if (Array.isArray(customFields)) {
    for (const customField of customFields) {
      const fieldName = customField.name || customField.field;
      newColumnVisibilityModel[fieldName] = !fieldIsHidden({
        field: customField,
        defaultValue: true,
      });
    }
  }

  return newColumnVisibilityModel;
};
