import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Gets list of targets IPs or MAC addresses from vulnerability
 * @param {string} _value - unused
 * @param {object} row - row data
 * @param {object[]} row.targetsCustom - list of targets attached to the vulnerability
 * @return {string}
 */
export const vulnerabilityTargetValueGetter = (_value, row) => {
  const targetsObject = row?.targetsCustom;
  if (!targetsObject) {
    return "";
  }

  const targets = Object.values(targetsObject);
  if (!isNonEmptyArray(targets)) {
    return "";
  }

  return targets.map((target) => target.ip || target.macAddress).join(", ");
};
