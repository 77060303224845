import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import CreateIncident from "../components/CreateIncident";
import IncidentDetails from "../components/IncidentDetails";
import IncidentStatus from "../customFields/IncidentStatus";
import IncidentWhen from "../customFields/IncidentWhen";
import { deleteIncident } from "../functions/deleteIncident";

/**
 * @description Displays a list of Incidents for an Org
 * @param {string} organizationID - The ID of the Organization to display Incidents for
 * @param {object} cardConfig - The configuration for the card
 * @param {object} queryConfig - The configuration for the query
 * @param {object} gridConfig - The configuration for the grid
 * @param {object} props -  The props to pass to the grid
 */
export const useIncidentDataGrid = ({
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  additionalFields = [],
  ...props
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;
  const route = "#/response/incidents/";

  cardConfig = {
    title: "Incidents",
    headerIcon: "icon-target",
    ...cardConfig,
  };

  const { listQuery } = generateGraphql(
    "Incident",
    ["who", "what", "when", "where", "why", "status", "accessControl"],
    {
      accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
    },
  );

  queryConfig = {
    organizationID,
    query: listQuery,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "status",
      width: 125,
      component: <IncidentStatus />,
      disablePropagation: true,
      allowFiltering: true,
      type: "singleSelect",
      valueOptions: [
        { value: "open", label: "Open" },
        { value: "closed", label: "Closed" },
      ],
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      flex: 1,
      name: "what",
      minWidth: 300,
    },
    {
      name: "when",
      component: <IncidentWhen />,
      disablePropagation: true,
      width: 200,
    },
    {
      name: "who",
      width: 200,
    },
    {
      name: "where",
      width: 200,
    },
    {
      name: "why",
      width: 200,
    },
  ];

  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    fields,
    route,
    deleteFunction: deleteIncident,
    primaryField: "what",
    detailsComponent: <IncidentDetails tableDisplay={true} />,
    createResourceComponent: <CreateIncident />,
    persistenceUUID: "764292bf-e6e7-45e4-ad92-586c67d7aa7e",
    accessControlFieldName: "what",
    enablePersistence: true,
    typename: "Incident",
    organizationID,
    options: ["details", "delete"],
    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...cardConfig,
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
  });

  return { ...grid };
};
