import React from "react";
import { Alert } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useWidget } from "@rivial-security/widget-utils";

import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { runCustomQueryOnLambda } from "../../../CustomQueries/hooks/useCustomQueryRunner/functions/runCustomQueryOnLambda";

/**
 * Displays how the chart widget will look like if it was created with current input
 * @param {object} widget - the widget config
 * @param {string} title - text for the title if need to show a small label on top of widget
 * @return {JSX.Element}
 */
const WidgetPreview = ({ widget: initWidget, title = "" }) => {
  const { widget, isLoading } = useWidget({
    widget: initWidget,
    runCustomQuery: ({ customQueryId }) => runCustomQueryOnLambda({ customQueryID: customQueryId }),
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {title && <p style={{ fontWeight: "bold" }}>Chart Preview:</p>}
      <DataLoader
        isEnoughData={!isNullOrUndefined(widget)}
        isLoading={isLoading}
        notEnoughDataChildren={<Alert color={"warning"}>Current widget doesn't have enough data</Alert>}
        style={{ width: "100%", height: "100%" }}
      >
        {widget ?? <div />}
      </DataLoader>
    </div>
  );
};

export default WidgetPreview;
