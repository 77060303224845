import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useForm } from "../../../../hooks/views/useForm";
import { arrayIncludes } from "../../../../utils/Functions/Array/arrayIncludes";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import VendorSolutionImporterModalButton from "../components/VendorSolutionImporterModalButton";

/**
 * Custom hook for creating a new VendorSolution
 * @param {string} organizationID - The ID of the organization to create the VendorSolution in
 * @param {function} toggleModal - Function to toggle the parent modal
 * @param {string} vendorID - The ID of the Vendor to associate the new VendorSolution with
 * @param {function} getNewItem - callback to return the item after creation
 * @param {string} module - The module to check for permissions
 * @param {string} resource - The resource to check for permissions
 * @param {string[]} disabledFields - optional. array of field names that will be disabled (but not hidden) in the form
 * @param {object} ...props - Additional props to pass to the useForm hook
 */
export const useVendorSolutionForm = ({
  organizationID,
  toggleModal,
  vendorID,
  getNewItem,
  module = modules.VENDORS,
  resource = resources.VENDOR_SOLUTION,
  disabledFields = [],
  ...props
}) => {
  const typename = "VendorSolution";

  const { createMutation } = generateGraphql(typename, [
    "name",
    "description",
    "ownerGroup",
    "vendorID",
    "contractDetails",
    "contractStatus",
    "contractExpirationDate",
  ]);

  //Get full list of available vendors
  const { listQuery } = generateGraphql("Vendor", ["name"]);
  const [vendorList, setVendorList] = useState([]);

  //Allows user to select the vendor to which this vendor solution belongs (defaults to current vendor if vendorID provided)
  const retrieveVendors = async () => {
    const vendorData = await ListQuery({ query: listQuery, organizationID });

    if (!vendorData) {
      ErrorLogger("Failed to retrieve vendor data for create vendor solution hook!");
      return;
    }

    // Only keep vendors with valid names and ids
    const formattedVendors = vendorData
      .filter((vendor) => vendor.name && vendor.id)
      .map((vendor) => ({
        text: vendor.name,
        value: vendor.id,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));

    // Add "No Vendor" at the beginning
    setVendorList([{ text: "No Vendor", value: "No Vendor" }, ...formattedVendors]);
  };
  useEffect(() => {
    retrieveVendors();
  }, []);

  // Makes sure that the default vendor is selected after retrieving the vendor list
  // TODO: may not want to do this every time the vendor list changes
  useEffect(() => {
    formHook.mergeInput({ vendorID });
  }, [vendorList]);

  const formHook = useForm({
    mutation: createMutation,
    toggleModal: toggleModal,
    organizationID,
    getNewItem,
    module,
    resource,
    typename,
    fieldConfig: {
      name: {
        label: "Vendor Solution Name",
        required: true,
      },
      description: {
        label: "Description",
      },
      contractDetails: {
        label: "Contract Details",
        inputType: "textarea",
      },
      contractStatus: {
        label: "Contract Status",
        inputType: "dropdown",
        defaultValue: "none",
        dropdownConfig: {
          data: [
            { text: "None", value: "none" },
            { text: "Current", value: "current" },
            { text: "Expired", value: "expired" },
            { text: "Expiring Soon", value: "expiringSoon" },
          ],
        },
      },
      contractExpirationDate: {
        label: "Contract Expiration Date",
        inputType: "date",
      },
      contractExpiringSoonOffset: {
        label: "Contract Expiring Soon Offset (Days)",
        tooltip:
          "Number of days before contract expiration to mark this Vendor Solution as Expiring Soon. Setting this to 0 will disable this feature.",
        inputType: "number",
        defaultValue: 30,
        numberConfig: {
          min: 0, // skips
          max: 365,
        },
      },
      vendorID: {
        label: "Vendor",
        tooltip: "The entity providing this solution",
        inputType: "dropdown",
        dropdownConfig: {
          data: [...vendorList],
        },
        defaultValue: vendorID || "No Vendor",
        disabled: arrayIncludes(disabledFields, "vendorID") || !!vendorID,
        required: true,
      },
    },
    header: <span>Create a Vendor Solution</span>,
    headerButtons: [<VendorSolutionImporterModalButton organizationID={organizationID} vendorID={vendorID} />],
    ...props,
  });

  return { ...formHook };
};
