import React from "react";
import { CustomInput } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";
import { ItemMutation } from "../../../../../../../../../utils/Functions/Graphql/ItemMutation";
import {
  disableNonStandardRiskControl,
  enableNonStandardRiskControl,
} from "../../../../../Overrides/functions/OverrideFunctions";

const { updateMutation: updateSystem } = generateGraphql("System");

const OutsourcedSwitch = ({
  item,
  riskControl,
  system,
  resetFunction,
  style,
  disableRoleChecking = false,
  setIsNonStandard,
  isOutsourced,
  setIsOutsourced,
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;
  const field = "outsourced";

  const updateSystemCallback = async ({ input }) => {
    const data = await ItemMutation(updateSystem, input);
    return { data };
  };

  const toggleOn = () => {
    if (!isOutsourced) {
      enableNonStandardRiskControl(system, riskControl, updateSystemCallback, {
        outsourced: true,
      });
      setIsNonStandard && setIsNonStandard(true);
      setIsOutsourced(true);
    }
  };

  const toggleOff = () => {
    if (isOutsourced) {
      disableNonStandardRiskControl(system, riskControl, updateSystemCallback);
      setIsNonStandard && setIsNonStandard(false);
      setIsOutsourced(false);
    }
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  return (
    <div style={style.div}>
      <strong>
        Outsourced
        <i
          className="icon-question"
          title={`Indicates whether this control is maintained internally or by a third-party. Current value = ${isOutsourced}`}
          style={style.icon}
        />
      </strong>
      {checkPermissionsHook.resource.read ? (
        <div style={style.field}>
          <CustomInput
            id={`outsourcedSwitch${JSON.stringify(item)}`}
            type="switch"
            name="outsourcedSwitch"
            checked={isOutsourced}
            onChange={() => (isOutsourced ? toggleOff() : toggleOn())}
            title={
              checkPermissionsHook.resource.update
                ? "Indicates whether this control is maintained internally or by a third-party"
                : "You don't have Update Permissions on Field: Outsourced"
            }
            disabled={!checkPermissionsHook.resource.update}
          />
        </div>
      ) : (
        <strong> You don't have Read Permissions on Resource: Risk Control Category</strong>
      )}
    </div>
  );
};

export default withOrganizationCheck(OutsourcedSwitch);
