"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortAutomationStepsByExecutionOrder = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const smartValueFilters_1 = require("../../constants/smartValueFilters");
const getAutomationIdentifier_1 = require("../utils/getAutomationIdentifier");
const filterMappedSmartValues_1 = require("./filterMappedSmartValues");
const getAutomationSmartValues_1 = require("./getAutomationSmartValues");
const getAutomationStepExecutionOrder_1 = require("./getAutomationStepExecutionOrder");
const getSmartValueAdjacencyList_1 = require("./getSmartValueAdjacencyList");
/**
 * Sorts the automation steps within an automation by their execution order
 * @param {object} automation - the automation data
 * @returns {object[]} - the sorted automation steps from the automation
 */
const sortAutomationStepsByExecutionOrder = ({ automation }) => {
    //Determine the execution order of steps
    const mappedSmartValues = (0, getAutomationSmartValues_1.getAutomationSmartValues)({ automation });
    const filteredSmartValues = (0, filterMappedSmartValues_1.filterMappedSmartValues)({
        mappedSmartValues,
        filters: [smartValueFilters_1.smartValueFilters.onlyAutomationSteps],
    });
    const adjacencyList = (0, getSmartValueAdjacencyList_1.getSmartValueAdjacencyList)({ mappedSmartValues: filteredSmartValues });
    const automationStepExecutionOrder = (0, getAutomationStepExecutionOrder_1.getAutomationStepExecutionOrder)({ adjacencyList, automation });
    //Sort the automation steps based on the execution order
    const automations = automation?.automations || [];
    const sortedAutomationSteps = [...automations];
    sortedAutomationSteps.sort((a, b) => {
        let aStepIndex = automationStepExecutionOrder.findIndex((automationStepExecutionOrder) => {
            return automationStepExecutionOrder?.name === (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep: a });
        });
        let bStepIndex = automationStepExecutionOrder.findIndex((automationStepExecutionOrder) => {
            return automationStepExecutionOrder?.name === (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep: b });
        });
        if (aStepIndex === -1) {
            aStepIndex = 100000;
        }
        if (bStepIndex === -1) {
            bStepIndex = 100000;
        }
        return aStepIndex - bStepIndex;
    });
    //Attach execution order to each step (if it exists)
    for (const automationStep of sortedAutomationSteps) {
        const stepExecutionOrder = automationStepExecutionOrder.find((automationStepExecutionOrder) => {
            return automationStepExecutionOrder?.name === (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep });
        })?.executionOrder;
        if (!(0, func_utils_1.isNullOrUndefined)(stepExecutionOrder)) {
            automationStep.executionOrder = stepExecutionOrder;
        }
    }
    return sortedAutomationSteps;
};
exports.sortAutomationStepsByExecutionOrder = sortAutomationStepsByExecutionOrder;
