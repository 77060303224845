import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useHistory } from "react-router-dom";

/**
 * Displays an icon and tooltip explaining that this point of contact has a currently past due evidence
 * @param pointOfContact
 * @param actionItem
 * @returns {JSX.Element}
 * @constructor
 */
const PastDueStanding = ({ pointOfContact = {}, actionItem = {} }) => {
  const history = useHistory();

  return (
    <Tooltip
      arrow
      interactive
      title={
        <div style={{ fontSize: "1.2em", textAlign: "center" }}>
          <div>{pointOfContact?.firstName} is out of date on their Evidence</div>
          <br />
          <span
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none;",
              cursor: "pointer",
              marginTop: "1.5em",
              marginBottom: "1.0em",
            }}
            onClick={() => history.push(`/continuous_compliance/evidence_response/${actionItem.id}`)}
          >
            Go to landing page
          </span>
        </div>
      }
    >
      <i className="icon-minus" />
    </Tooltip>
  );
};

export default PastDueStanding;
