import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { usePlaybookBuilder } from "../hooks/usePlaybookBuilder/usePlaybookBuilder";

/**
 * A component instance of the usePlaybookBuilder.js hook
 * @param props
 */
const PlaybookBuilder = (props) => {
  return usePlaybookBuilder(props).display;
};

export default withOrganizationCheck(PlaybookBuilder);
