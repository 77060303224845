import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Check if an item is restricted (has access control) or not
 * @param {object} item - database object with an accessControl field
 * @return {boolean}
 */
const isRestricted = (item) => {
  const { password, passwordOwnerEmail, roles, pointOfContacts } = item?.accessControl || {};

  return (
    !isNullOrUndefined(password) ||
    !isNullOrUndefined(passwordOwnerEmail) ||
    isNonEmptyArray(roles) ||
    isNonEmptyArray(pointOfContacts)
  );
};

export default isRestricted;
