import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useMeetingsDataGrid } from "../hooks/useMeetingsDataGrid";

/**
 * Displays a list of meetings for an org
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MeetingsDataGrid = (props) => {
  return useMeetingsDataGrid(props).dashboardDisplay;
};

export default withOrganizationCheck(MeetingsDataGrid);
