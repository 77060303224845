import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Resets this Grid in local storage if being persisted
 * @param ref
 * @param {function} getGridID - The unique ID for this grid component
 * @param {function} setEnablePers - sets value for disable persistence to reset the grid
 * @param {function} setKey - sets key to reset the grid to initial state
 * @param {string} persistenceUUID - unique id for persistence
 * @param {function} setPreference - set user preference
 * @param {function} getPreference - get user preference
 */
export const gridResetPersistence = ({ ref, setEnablePers, setKey, persistenceUUID, setPreference, getPreference }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    ref,
    setEnablePers,
    setKey,
    persistenceUUID,
    setPreference,
    getPreference,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  if (setPreference && persistenceUUID) {
    /**
     * Get config for the grid
     */
    const allGridConfig = getPreference("general", "grid_persistence_config");

    /**
     * Find grid config
     */
    if (allGridConfig && allGridConfig[persistenceUUID]) {
      delete allGridConfig[persistenceUUID];
      setPreference("general", { grid_persistence_config: allGridConfig });
      alert("Please refresh the page");
    }
  }
};
