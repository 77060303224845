"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSystemKeyRiskIndicatorStats = void 0;
/**
 * Parses through key risk indicator calculation statistics to find ones with the greatest risk
 * @param {object} calculation - results of monteCarloCalculation function
 * @param {string} [modifiedRiskControlID] - the id of the risk control that was modified as part of the calculation
 * @returns {object} statistics about the system risk as calculated by the greatest key risk indicators
 */
const getSystemKeyRiskIndicatorStats = ({ calculation, modifiedRiskControlID }) => {
    try {
        // Check required inputs
        const riskStats = calculation?.riskStats;
        if (!Array.isArray(riskStats) || riskStats.length === 0) {
            throw Error("Was unable to retrieve system risk values due to missing riskStats from the passed in calculation");
        }
        // Find risk data with the highest residual risk
        let greatestKeyRiskIndicator;
        for (const riskStat of riskStats) {
            if (!greatestKeyRiskIndicator || riskStat?.residualRisk > greatestKeyRiskIndicator?.residualRisk) {
                greatestKeyRiskIndicator = riskStat;
            }
        }
        // Find risk data with the highest recommended residual risk
        let greatestRecommendationKeyRiskIndicator;
        for (const riskStat of riskStats) {
            if (!greatestRecommendationKeyRiskIndicator ||
                riskStat?.recommendationResidualLoss > greatestRecommendationKeyRiskIndicator?.recommendationResidualLoss) {
                greatestRecommendationKeyRiskIndicator = riskStat;
            }
        }
        // Find the modified key risk indicator (this can be neither the greatest nor the greatest recommendation KRI)
        let modifiedKeyRiskIndicator;
        if (modifiedRiskControlID) {
            for (const riskStat of riskStats) {
                const riskControls = riskStat?.controlCategory?.subControls;
                if (Array.isArray(riskControls)) {
                    for (const riskControl of riskControls) {
                        if (riskControl?.id === modifiedRiskControlID) {
                            modifiedKeyRiskIndicator = riskStat;
                            break;
                        }
                    }
                }
            }
        }
        // Expose inherentRisk, residualRisk, and name as separate properties
        return {
            inherentRisk: greatestKeyRiskIndicator?.inherentRisk,
            residualRisk: greatestKeyRiskIndicator?.residualRisk,
            greatestKeyRiskIndicatorData: greatestKeyRiskIndicator,
            greatestKeyRiskIndicator: greatestKeyRiskIndicator?.name,
            recommendationInherentRisk: greatestRecommendationKeyRiskIndicator?.inherentRisk,
            recommendationResidualRisk: greatestRecommendationKeyRiskIndicator?.recommendationResidualLoss,
            greatestRecommendationKeyRiskIndicatorData: greatestRecommendationKeyRiskIndicator,
            greatestRecommendationKeyRiskIndicator: greatestRecommendationKeyRiskIndicator?.name,
            modifiedInherentRisk: modifiedKeyRiskIndicator?.inherentRisk,
            modifiedResidualRisk: modifiedKeyRiskIndicator?.residualRisk,
            modifiedKeyRiskIndicatorData: modifiedKeyRiskIndicator,
            modifiedKeyRiskIndicator: modifiedKeyRiskIndicator?.name,
        };
    }
    catch (e) {
        console.error("[getSystemKeyRiskIndicatorStats] unable to calculate system risk values -", e);
    }
    return {
        inherentRisk: 0,
        residualRisk: 0,
        greatestKeyRiskIndicatorData: { name: "None" },
        greatestKeyRiskIndicator: "None",
        recommendationInherentRisk: 0,
        recommendationResidualRisk: 0,
        greatestRecommendationKeyRiskIndicatorData: { name: "None" },
        greatestRecommendationKeyRiskIndicator: "None",
        modifiedInherentRisk: 0,
        modifiedResidualRisk: 0,
        modifiedKeyRiskIndicatorData: { name: "None" },
        modifiedKeyRiskIndicator: "None",
    };
};
exports.getSystemKeyRiskIndicatorStats = getSystemKeyRiskIndicatorStats;
