import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Counts the statuses of vulnerability links
 * @param {object[]} targetLinks - the links for which to count statuses
 * @returns {object} - key is status and value is count of that status
 */
export const getStatusCounts = (targetLinks) => {
  if (!isNonEmptyArray(targetLinks)) {
    return {};
  }

  return targetLinks.reduce((acc, link) => {
    const { status } = link || {};
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});
};
