import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";

import { resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";
import AccordionIcon from "../../../../../../../../../utils/GenericComponents/AccordionIcon";
import { useSelectKeyPerformanceIndicator } from "../../../../../../../../Metrics/KeyPerformanceIndicators/hooks/useSelectKeyPerformanceIndicator";
import RiskControlDetails from "../../../../../../../RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";
import KeyPerformanceIndicators from "../../../../../../../RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";
import { getRiskControlOverride, isNonStandardRiskControl } from "../../../../../Overrides/functions/OverrideFunctions";
import { getOutsourced } from "../functions/getOutsourced";

import ControlEffectiveness from "./ControlEffectiveness";
import ImplementationDetails from "./ImplementationDetails";
import ImplementationRating from "./ImplementationRating";
import MaximumCostOfControl from "./MaximumCostOfControl";
import MonteCarloLossMitigated from "./MonteCarloLossMitigated";
import NonStandardSwitch from "./NonStandardSwitch";
import OutsourcedSwitch from "./OutsourcedSwitch";
import RiskControlEvidenceList from "./RiskControlEvidenceList";
import StrengthRating from "./StrengthRating";
import SubControlNotes from "./SubControlNotes";
import SystemRiskControlVendorControl from "./SystemRiskControlVendorControl";

/**
 * Displays the details of a single sub control for System Details
 *
 * @param {object} item - The sub control item
 * @param {string[]} fields - The fields to display
 * @param {object} system - The system the sub control is attached to
 * @param {function} resetFunction - The function to call to reset the parent query when the sub control updates
 * @param {string} organizationID - The ID of the organization the sub control is attached to
 * @param {string} viewType - The type of view the sub control is being displayed in
 * @param {React.Context} Context - The system details context to use for the sub control
 * @param {object} [vendorReview]- latest finalized vendor review for the system
 * @param {boolean} disableRoleChecking - Whether or not to disable role checking
 * @param {boolean} isTemplate - Whether or not the sub control is a template
 * @returns {JSX.Element}
 * @constructor
 */
const SubControlItem = ({
  item,
  fields,
  system,
  resetFunction,
  organizationID,
  viewType,
  Context,
  vendorReview,
  disableRoleChecking = false,
  isTemplate,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const style = {
    div: {
      margin: "1.0em",
    },
    field: {
      //marginLeft: "1em"
    },
    icon: {
      marginLeft: "0.5em",
    },
  };

  const [isNonStandard, setIsNonStandard] = useState(false);
  const [isOutsourced, setIsOutsourced] = useState(false);

  const [override, setOverride] = useState(null);

  const [vendorControl, setVendorControl] = useState(null);
  useEffect(() => {
    if (vendorReview?.riskControls?.hasOwnProperty(item.id)) {
      setVendorControl(vendorReview.riskControls[item.id]);
    } else {
      setVendorControl(null);
    }
  }, [vendorReview]);

  useEffect(() => {
    const check = async () => {
      if (system && item) {
        if (await isNonStandardRiskControl(system, item)) {
          setIsNonStandard(true);
          setOverride(getRiskControlOverride(system, item));
        } else {
          setIsNonStandard(false);
        }
      }
    };

    check();
  }, [system, item]);

  useEffect(() => {
    setIsOutsourced(getOutsourced({ system, riskControl: item }) ? true : false);
  }, [item]);

  const [showKPIs, setShowKPIs] = useState(false);
  const selectKPIHook = useSelectKeyPerformanceIndicator({
    organizationID,
    riskControl: item,
  });
  const kpiPermissionsHook = useCheckPermissions({
    module: module.RISK,
    resource: resources.RISK_CONTROL_CATEGORY,
    field: "keyPerformanceIndicators",
    disableRoleChecking,
  });

  const detailsModal = useModal(
    "Risk Control Details",
    <RiskControlDetails item={item} organizationID={organizationID} />,
    <Button color={"primary"} size={"sm"} className={"float-right"}>
      <i className={"icon-info"} /> Details
    </Button>,
    {
      width: "80vw",
    },
  );

  return (
    <>
      {isTemplate}
      <tr
        style={{
          cursor: "pointer",
          // backgroundColor: isOpen ? "lightBlue" : undefined,
          border: isOpen ? "solid 2px lightBlue" : "solid 1px rgba(0,0,0,.125)",
          borderRadius: ".25rem",
          padding: ".75rem 1.25rem",
        }}
        className={isOpen ? "shadow" : undefined}
        onClick={() => setIsOpen(!isOpen)}
      >
        {fields.map((field, index) => (
          <td key={field + index}>{item[field]}</td>
        ))}
        <td>
          <AccordionIcon isOpen={isOpen} />
        </td>
      </tr>
      <>
        <tr style={{ display: !isOpen ? "none" : undefined }}>
          <td colSpan={2}>
            <Collapse isOpen={isOpen}>
              <hr />
              <Card className={isOpen ? "shadow" : undefined} style={{ colSpan: 2 }}>
                <CardBody>
                  {!isTemplate && !showKPIs && kpiPermissionsHook.field.read && (
                    <Button
                      className="float-right"
                      color="ghost-primary"
                      size="sm"
                      onClick={() => setShowKPIs(!showKPIs)}
                    >
                      {showKPIs ? "Hide" : "Show"} KPIs
                      <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
                    </Button>
                  )}
                  {!isTemplate && detailsModal.modal}
                  {!isTemplate && detailsModal.modalButton}
                  <Row>
                    <Col>
                      <NonStandardSwitch
                        system={system}
                        riskControl={item}
                        resetFunction={resetFunction}
                        style={style}
                        isNonStandard={isNonStandard}
                        setIsNonStandard={setIsNonStandard}
                        isOutsourced={isOutsourced}
                        disableRoleChecking={disableRoleChecking}
                      />
                      {isNonStandard && (
                        <ImplementationDetails
                          item={item}
                          system={system}
                          override={override}
                          style={style}
                          disableRoleChecking={disableRoleChecking}
                        />
                      )}
                      {system?.vendorSolution?.id && (
                        <OutsourcedSwitch
                          item={item}
                          system={system}
                          riskControl={item}
                          resetFunction={resetFunction}
                          style={style}
                          setIsNonStandard={setIsNonStandard}
                          disableRoleChecking={disableRoleChecking}
                          isOutsourced={isOutsourced}
                          setIsOutsourced={setIsOutsourced}
                        />
                      )}
                      {isOutsourced && (
                        <SystemRiskControlVendorControl
                          vendorControl={vendorControl}
                          style={style}
                          disableRoleChecking={disableRoleChecking}
                        />
                      )}
                      <MaximumCostOfControl
                        item={item}
                        system={system}
                        resetFunction={resetFunction}
                        style={style}
                        isNonStandard={isNonStandard}
                        override={override}
                      />
                      <ImplementationRating
                        item={item}
                        system={system}
                        resetFunction={resetFunction}
                        style={style}
                        isNonStandard={isNonStandard}
                        override={override}
                      />
                      {viewType === "analysis" && (
                        <>
                          <StrengthRating item={item} system={system} resetFunction={resetFunction} style={style} />
                          <ControlEffectiveness
                            item={item}
                            system={system}
                            resetFunction={resetFunction}
                            style={style}
                          />
                          {Context ? (
                            <Context>
                              <MonteCarloLossMitigated
                                item={item}
                                system={system}
                                style={style}
                                isNonStandard={isNonStandard}
                                override={override}
                              />
                            </Context>
                          ) : (
                            <MonteCarloLossMitigated
                              item={item}
                              system={system}
                              style={style}
                              isNonStandard={isNonStandard}
                              override={override}
                            />
                          )}
                          {
                            <RiskControlEvidenceList
                              override={override}
                              system={system}
                              riskControl={item}
                              style={style}
                              isNonStandard={isNonStandard}
                            />
                          }
                        </>
                      )}
                      <SubControlNotes item={item} system={system} isNonStandard={isNonStandard} />
                    </Col>
                    {showKPIs && kpiPermissionsHook.field.read && (
                      <Col lg={5}>
                        {showKPIs && (
                          <Button
                            style={{ width: "100%" }}
                            color="ghost-primary"
                            size="sm"
                            onClick={() => setShowKPIs(!showKPIs)}
                          >
                            {showKPIs ? "Hide" : "Show"} KPIs
                            <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
                          </Button>
                        )}
                        <h6>
                          Key Performance Indicators
                          {kpiPermissionsHook.field.update && selectKPIHook.modalButton}
                        </h6>
                        <KeyPerformanceIndicators riskControl={item} />
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
              <hr />
            </Collapse>
          </td>
        </tr>
      </>
    </>
  );
};

export default withOrganizationCheck(SubControlItem);
