import React from "react";

import NumberChange from "../../../../utils/CustomFields/NumberChange";

const OverallAROChange = ({ item }) => {
  const value = item && item.annualRateOfOccurrenceChange ? item.annualRateOfOccurrenceChange : 0;

  return (
    <>
      <NumberChange value={value} format="percent" negativeNumber="green" positiveNumber="red" />
    </>
  );
};

export default OverallAROChange;
