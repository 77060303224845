import useDidMountEffect from "./useDidMountEffect";
import { useRefState } from "./useRefState";

/**
 * @description A hook that allows for debounce behavior.
 * Will call a callback function with the passed in value after a certain delay, which gets reset
 * @param {*} value - The value to debounce
 * @param {function} func  - The callback function to call after the delay
 * @param {number} delay - The delay in milliseconds
 * @param {array} deps - The dependencies to watch for changes
 * @param {function} startTimerFunc - A function to call when the timer starts
 * @example const debouncedFunc = useDebounce(value, func, delay); debouncedFunc(value); // will call func with value after delay
 */
export const useDebounce = ({ value, func, delay, deps, startTimerFunc }) => {
  const [timer, setTimer, timerRef] = useRefState(null);

  useDidMountEffect(
    () => {
      const timeoutTimer = setTimeout(() => {
        if (typeof func === "function") {
          func(value);
        }
      }, delay || 500);

      if (typeof startTimerFunc === "function") {
        startTimerFunc();
      }
      setTimer(timeoutTimer);

      return () => {
        clearTimeout(timeoutTimer);
      };
    },
    deps || [value, delay],
  );

  return { value, timer, timerRef };
};
