/**
 * The available Built-In KnowBe4 Metric Types
 * @type {{AVERAGE_TRAINING_COMPLETION: string, LATEST_PHISH_PRONE_PERCENTAGE: string, AVERAGE_PHISH_PRONE_PERCENTAGE: string, AVERAGE_PHISHING_CLICKS: string, AVERAGE_PHISHING_DATA_ENTERED: string, LATEST_PHISHING_OPENED: string, LATEST_PHISHING_DATA_ENTERED: string, AVERAGE_PHISHING_REPORTED: string, LATEST_PHISHING_REPORTED: string, AVERAGE_PHISHING_DELIVERED: string, LATEST_TRAINING_COMPLETION: string, LATEST_PHISHING_DELIVERED: string, AVERAGE_PHISHING_OPENED: string, LATEST_PHISHING_CLICKS: string}}
 */
export const KNOW_BE_4_METRIC_TYPES = {
  // Phishing
  LATEST_PHISH_PRONE_PERCENTAGE: {
    name: "customAutomation",
    description: "KnowBe4 Latest Phish Prone Percentage",
    sortBy: "started_at",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "phish_prone_percentage",
    exclusions: "status=Error",
  },
  AVERAGE_PHISH_PRONE_PERCENTAGE: {
    name: "customAutomation",
    description: "KnowBe4 Average Phish Prone Percentage",
    type: "average",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "phish_prone_percentage",
  },

  LATEST_PHISHING_CLICKS: {
    name: "customAutomation",
    description: "KnowBe4 Latest Phishing Click Count",
    sortBy: "started_at",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "clicked_count",
  },

  AVERAGE_PHISHING_CLICKS: {
    name: "customAutomation",
    description: "KnowBe4 Average Phishing Click Count",
    calculationType: "average",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "clicked_count",
  },

  LATEST_PHISHING_DELIVERED: {
    name: "latestPhishingDelivered",
    description: "KnowBe4 Latest Phishing Delivery Count",
    sortBy: "started_at",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "delivered_count",
    exclusions: "status=Error",
  },
  AVERAGE_PHISHING_DELIVERED: {
    name: "averagePhishingDelivered",
    description: "KnowBe4 Average Delivery Count",
    sortBy: "started_at",
    calculationType: "average",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "delivered_count",
    exclusions: "status=Error",
  },

  LATEST_PHISHING_OPENED: {
    name: "latestPhishingOpened",
    description: "KnowBe4 Latest Phishing Opened Count",
    sortBy: "started_at",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "opened_count",
    exclusions: "status=Error",
  },
  AVERAGE_PHISHING_OPENED: {
    name: "averagePhishingOpened",
    description: "KnowBe4 Average Phishing Opened Count",
    sortBy: "started_at",
    calculationType: "average",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "opened_count",
    exclusions: "status=Error",
  },

  LATEST_PHISHING_REPORTED: {
    name: "latestPhishingReported",
    description: "KnowBe4 Latest Phishing Reported Count",
    sortBy: "started_at",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "reported_count",
    exclusions: "status=Error",
  },
  AVERAGE_PHISHING_REPORTED: {
    name: "averagePhishingReported",
    description: "KnowBe4 Average Phishing Reported Count",
    sortBy: "started_at",
    calculationType: "average",
    url: "https://us.api.knowbe4.com/v1/phishing/security_tests",
    jsonPath: "reported_count",
    exclusions: "status=Error",
  },

  // // Training
  LATEST_TRAINING_COMPLETION: {
    name: "latestTrainingCompletion",
    description: "KnowBe4 Latest Training Completion",
    calculationType: "mostRecent",
    url: "https://us.api.knowbe4.com/v1/training/campaigns",
    jsonPath: "completion_percentage",
    exclusions: "status=Created",
  },
  AVERAGE_TRAINING_COMPLETION: {
    name: "averageTrainingCompletion",
    description: "KnowBe4 Average Training Completion",
    calculationType: "average",
    url: "https://us.api.knowbe4.com/v1/training/campaigns",
    jsonPath: "completion_percentage",
    exclusions: "status=Created",
  },
};
