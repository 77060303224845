import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";

import { isNonEmptyArray, isNullOrUndefined, isObject } from "@rivial-security/func-utils";

import { MuiSubmitButton } from "../../../../utils/GenericComponents/buttons/MuiSubmitButton";

/**
 * The stepper, submit and reset button group for useForm hooks
 * @param {object} stepper - instance of use stepper component specific to this form
 * @param {string[]} [fields] - list of strings that represents the fields to show in the for
 * @param {object[]} fieldConfig - the individual field settings impacting which buttons are shown or disabled
 * @param {function} checkInvalid - function to check if current user's input for the fields is invalid
 * @param {boolean} externalFormInvalid - optional bool representing if form is invalid based on external checks
 * @param {boolean} disableResetButton - TRUE if reset button should not be shown
 * @param {boolean} disableSubmitButton - TRUE if submit button should not be shown
 * @param {boolean} submitDisabled -TRUE if submit button should not be clickable
 * @param {function} submit - the onClick handler for the submit button
 * @param {function} reset - the onClick handler for the reset button
 * @param {function} deleteItem - internal form function handling the delete if a delete was pressed
 * @param {object} item - currently edit item (if any)
 * @param {function} [deleteFunction] - optional function to allow to delete an existing item if it is an update form
 * @param {object} input - current user's input in the form
 * @param {string} [submitButtonText] - text to display on the submit button
 * @param {JSX.Element} [footerPrefix] - optional JSX element to display before the buttons
 * @returns {JSX.Element}
 */
const FormButtons = ({
  stepper,
  fields,
  fieldConfig,
  checkInvalid,
  externalFormInvalid,
  disableResetButton,
  disableSubmitButton,
  submitDisabled,
  submit,
  reset,
  deleteItem,
  item,
  deleteFunction,
  input,
  submitButtonText,
  footerPrefix,
}) => {
  //Create a grey button theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#d9d9d9",
      },
    },
  });

  const {
    stepperAvailable = false,
    isOnFirstStep,
    isOnLastStep,
    activeStep,
    steps,
    handleNext,
    handlePrevious,
  } = stepper || {};

  const [nextStepDisabled, setNextStepDisabled] = React.useState(false);
  useEffect(() => {
    let disabled = false;
    const fieldConfig = steps?.[activeStep]?.fields;

    if (!isObject(fieldConfig)) {
      return;
    }

    Object.entries(fieldConfig).forEach(([fieldName, properties]) => {
      // If fields array is included, only do this check for selected fields
      if (isNonEmptyArray(fields)) {
        if (fields.includes(fieldName)) {
          if (checkInvalid(fieldName) || externalFormInvalid) {
            disabled = true;
          }
        }
      }
      // If fields array is not included, do this check for all fields
      else {
        if (checkInvalid(fieldName) || externalFormInvalid) {
          disabled = true;
        }
      }
    });

    setNextStepDisabled(disabled);
  }, [input, externalFormInvalid]);

  const submitButton = (
    <MuiSubmitButton
      onClick={submit}
      disabled={submitDisabled}
      dataTestId={"useForm-submit-button"}
      text={submitButtonText || (isNullOrUndefined(item?.id) ? "Submit" : "Update")}
      title={submitDisabled ? "Please fill in all required fields" : "Submit this form"}
    />
  );

  const resetButton = (
    <ThemeProvider theme={theme}>
      <Button
        variant={"contained"}
        startIcon={<RotateLeftOutlinedIcon />}
        data-testid="useForm-reset-button"
        onClick={() => reset()}
      >
        Reset
      </Button>
    </ThemeProvider>
  );

  const previousButton = (
    <Button variant={"contained"} startIcon={<ArrowBackRoundedIcon />} onClick={handlePrevious}>
      Previous
    </Button>
  );

  const nextButton = (
    <Button
      variant={"contained"}
      endIcon={<ArrowForwardRoundedIcon />}
      onClick={handleNext}
      disabled={nextStepDisabled}
    >
      Next
    </Button>
  );

  const deleteButton = (
    <Button
      variant={"contained"}
      color={"error"}
      title={"Delete this item"}
      onClick={deleteItem}
      startIcon={<DeleteRoundedIcon />}
    >
      Delete
    </Button>
  );

  const showDeleteButton = typeof item?.id === "string" && typeof deleteFunction === "function";
  const showResetButton = !disableResetButton;
  const showSubmitButton = isOnLastStep && stepperAvailable && !disableSubmitButton;
  const showPreviousButton = !isOnFirstStep && stepperAvailable;
  const showNextButton = !isOnLastStep && stepperAvailable;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        gap: ".5em",
      }}
    >
      {footerPrefix}
      {showDeleteButton && deleteButton}

      {showPreviousButton && previousButton}
      {showNextButton && nextButton}
      {showSubmitButton && submitButton}
      {showResetButton && resetButton}
    </div>
  );
};

export default FormButtons;
