import { useEffect } from "react";

/**
 * @description Enable grid editing
 * @param {object} ref - reference of the the grid component
 * @param {boolean} enableEdit - enable edit functionality for the grid
 * @param {function} enableEditing - add event listener for the grid row mouse click
 */
export const useGridEnableEdit = ({ ref, enableEdit, enableEditing }) => {
  useEffect(() => {
    if (enableEdit && ref?.element) {
      return () => {
        enableEditing && enableEditing();
      };
    }
  }, [ref?.element]);
};
