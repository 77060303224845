import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";
import { getFunctionFieldName } from "@rivial-security/widget-utils";

import schema from "../../../../graphql/schema.json";
import useDidMountEffect from "../../../../hooks/functional/useDidMountEffect";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";
import { getCurrentTypeFields } from "../../../../views/CustomQueries/functions/getCurrentTypeFields";
import { getFieldsAtDepth } from "../../../../views/CustomQueries/functions/getFieldsAtDepth";
import { getFunctionsAtDepth } from "../../../../views/CustomQueries/functions/getFunctionsAtDepth";
import { parseSchemaTypes } from "../../../../views/CustomQueries/functions/parseSchemaTypes";
import ResourceFilterForm from "../../../../views/CustomQueries/hooks/useResultFieldBuilder/components/ResourceFilterForm";
import ResultFieldPath from "../../../../views/CustomQueries/hooks/useResultFieldBuilder/components/ResultFieldPath";

/**
 * Input component for selecting a schema path, starting from a specific resource type
 * @param {object} input - the initial value of form or resource holding the input field
 * @param {string} fieldName - the name of the property in `input` which contains current path information
 * @param {function} onChangeCallback - callback when path changes based on user input
 * @return {JSX.Element}
 */
const ResourcePathFormField = ({ input, fieldName, onChangeCallback }) => {
  //[STATE]
  const [typename, setTypename] = useState();
  const [path, setPath] = useState([]);
  const [types] = useStateEffect(parseSchemaTypes({ schema }));
  //[SIDE EFFECTS]
  //Form the initial path if rootField or types change
  useDidMountEffect(() => {
    if (fieldName && input?.[fieldName]) {
      setPath(input[fieldName]);
    }
  }, [input?.rootTypename, types]);

  //Update the field and functions that are selectable as the next step in the path
  const [allFields] = useStateEffect([], [typename, input], () => {
    const { query, queryPath } = input || {};
    if (query && queryPath) {
      //in query path remove the last element since it is the function itself
      let finalPath = path;
      if (Array.isArray(queryPath) && queryPath.length > 1) {
        finalPath = [...queryPath.filter((_, i) => i !== queryPath.length - 1), ...path];
      }

      const resultFields = getFieldsAtDepth({
        path: finalPath,
        resultFields: query?.fields,
        depth: finalPath.length,
      });

      const resultFunctions = getFunctionsAtDepth({
        path: finalPath,
        resultFields: query?.fields,
        resultFunctions: query?.functions,
        depth: finalPath.length,
      });

      const newOptions = [...resultFields];
      resultFunctions.forEach((functionElement) => {
        const functionName = getFunctionFieldName({
          pathElement: functionElement,
        });
        if (
          !newOptions.find((existingOption) => {
            return existingOption === functionName;
          }) &&
          path.length > 0 // same level functions not allowed
        ) {
          newOptions.push(functionElement);
        }
      });

      if (Array.isArray(newOptions) && newOptions.length > 0) {
        return newOptions;
      } else {
        return [];
      }
    } else {
      return getCurrentTypeFields({ typename });
    }
  });

  //When path changes update the current typename
  useEffect(() => {
    if (typeof onChangeCallback === "function") {
      onChangeCallback(path);
    }

    //check for the path to be valid
    if (!Array.isArray(path) || path.length === 0) {
      setTypename(input?.rootTypename);
      return;
    }

    const currentPathElement = path[path.length - 1];
    const currentTypename = currentPathElement?.typename || "None";
    setTypename(currentTypename);
  }, [path]);

  const lastPathElementIsArray = () => {
    if (!isNonEmptyArray(path)) {
      return false;
    }

    if (path[path.length - 1]?.hasMany === true) {
      return true;
    }

    return false;
  };

  // [RENDER]
  return (
    <div style={{ width: "100%" }}>
      {/* path to field (if path is formed) */}
      {typename && <ResultFieldPath path={path} setPath={setPath} />}
      {/* next field select (if any is available) */}
      <FormControl style={{ marginTop: "1em", width: "100%" }}>
        <InputLabel id="field-select-label">Next Field</InputLabel>
        <Select
          labelId="field-select-label"
          id="field-select"
          value={""}
          label="Next Field"
          onChange={(event) => {
            if (!Array.isArray(allFields)) {
              return;
            }

            const field = allFields.find((field) => {
              let fieldName = field?.name;
              if (field?.isFunction === true) {
                fieldName = getFunctionFieldName({ pathElement: field });
              }
              return fieldName === event?.target?.value;
            });
            if (field) {
              setPath([...path, field]);
            }
          }}
        >
          {allFields.map((field) => {
            const fieldName = field?.isFunction === true ? getFunctionFieldName({ pathElement: field }) : field.name;
            return <MenuItem value={fieldName}>{fieldName}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {/*Filter for the current field (if it is an array) */}
      {lastPathElementIsArray() && (
        <ResourceFilterForm
          path={path}
          isShownOverride={true}
          freeSoloFieldInput={true}
          sx={{ container: { paddingTop: ".5em" } }}
          onFilterChange={(newFilter) => {
            if (!isNonEmptyArray(path)) {
              return;
            }

            const lastPathElement = cloneDeep(path[path.length - 1]);
            lastPathElement.filters = newFilter;
            path[path.length - 1] = lastPathElement;
            setPath([...path]);
          }}
        />
      )}
    </div>
  );
};

export default ResourcePathFormField;
