import { CreateJobInput, Job } from "@rivial-security/schema-types";

import { OptionalNullable } from "../../../../typedefs/Utility/mutations";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

export const createJob = async ({
  name,
  completedAt,
  status,
  type,
  logs,
  error,
  success,
  emails = [],
  source,
  ownerGroup,
  ...props
}: CreateJobInput): Promise<OptionalNullable<Job> | null> => {
  return await ItemMutation(createJobMutation, {
    name,
    completedAt,
    status,
    type,
    logs,
    error,
    success,
    emails,
    source,
    ownerGroup,
    ...props,
  });
};

const createJobMutation = /* GraphQL */ `
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      name
      completedAt
      status
      type
      logs
      error
      success
      emails
      source
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
