import { useWelcomeTour } from "../../../../hooks/views/useWelcomeTour/useWelcomeTour";

export const useAuditWelcomeTour = () =>
  useWelcomeTour({
    optionKey: "audit-details-tour",
    steps: [
      {
        highlightedSelectors: ["#audit-general-details", "#audit-pie-chart", "#audit-bar-chart"],
        title: "Welcome to the Audit Page!",
        content: "Here you can perform a Point-in-Time Evaluation of a particular Control Framework",
      },
      {
        selector: "#audit-details-general-informationdashboard-card",
        title: "General Information",
        content:
          "This section shows basic details for this Audit. You can also jot down some general Notes and keep track of your progress.",
      },
      {
        selector: "#audit-details-controls",
        title: "Controls",
        content:
          "The most important aspect of an Audit is the Control Evaluation process. The Rivial Platform will help you walk through the steps necessary to evaluate each Control.",
      },
      {
        highlightedSelectors: [
          "#audit-control-compliance-buttons",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-isCompliant",
        ],
        title: "Control Compliance",
        content:
          "This field is used to represent whether a Control is currently In Compliance or Not. There are many ways to determine Compliance, but we have some suggestions to get started.",
      },
      {
        highlightedSelectors: [
          "#evidence-reviewed-field",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-evidenceLinks",
        ],
        title: "Evidence Review",
        content:
          "One of the best ways to evaluate a Control is to look at Supporting Evidence. Evidence is some sort of Artifact like a Document, Report, or Meeting that may be used to prove that a Control is being operated effectively.",
      },
      {
        highlightedSelectors: [
          "#evidence-reviewed-field",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-evidenceLinks",
        ],
        title: "Control Compliance - Evidence Review",
        content:
          "If this Organization has been using Continuous Compliance, things just got a lot easier for you. There may already be up-to-date Evidence Artifacts here, just waiting to be reviewed! If not, no worries. You can easily upload and track supplied Evidence directly from this screen.",
      },
      {
        highlightedSelectors: [
          "#evidence-reviewed-field",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-evidenceLinks",
        ],
        title: "Control / Evidence Mapping",
        content:
          "To get you started, the Rivial Platform initializes the Audit with Controls and Evidence mapped the same way as in Continuous Compliance. But for ultimate flexibility, you may change this mapping during the Audit without affecting anything outside of the Audit.",
      },
      {
        highlightedSelectors: [
          "#evidence-reviewed-field",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-evidenceLinks",
        ],
        title: "View or Upload Evidence Artifacts",
        content:
          "Once Evidence is mapped, you may now quickly view or upload all Evidence Artifacts that are associated with a particular Control. Remember, a single Evidence may apply to many Controls, which can really speed things up.",
      },
      {
        highlightedSelectors: [
          "#evidence-reviewed-field",
          ".MuiDataGrid-columnHeader-audit-controls-data-grid-evidenceLinks",
        ],
        title: "Mark Evidence as 'Reviewed' for one or multiple Controls",
        content:
          "Now for the fun part. As you are going through the Controls, use these checkboxes to quickly mark an Evidence as 'Reviewed' for a particular Control. Shift+Click to mark an Evidence as 'Reviewed' for ALL Controls!",
      },
      {
        selector: "#data-grid-columns-button-audit-controls-data-grid",
        title: "Column Selection",
        content:
          "Control Frameworks are organized in different ways. Use the Column Selector to determine what Control information you'd like to view at any given time.",
      },
      {
        selector: "#data-grid-options-buttons-audit-controls-data-grid",
        title: "Column Filtering",
        content:
          "To easily sift through large Control Frameworks, you may apply filters here. Even special columns like Evidence and Tags can be filtered this way!",
      },
      {
        selector: "#data-grid-options-buttons-audit-controls-data-grid",
        title: "Row Selection and Bulk Updates",
        content:
          "In some cases, you may want to apply some bulk updates to multiple Controls at once. Use this option to enable the checkboxes, and once you've selected some Controls, you will then be able to set Compliance, add Notes, and create Findings for multiple Controls at the same time.",
      },
      {
        selector: "#audit-details-findings",
        title: "Control Findings",
        content:
          "When you find a gap in a Control or have a Recommendation to make, you can create 'Observations' here. These Observations may then be elevated to 'Findings', updated with Recommendations, and dispatched with Action Items for resolution.",
      },
      {
        selector: "#audit-details-docs",
        title: "Document Download",
        content:
          "This section allows you to quickly download all uploaded Evidence Documents in a nifty ZIP file. This folder may be auto-arranged into sub-folders corresponding to Control Numbers, Evidence Names, Tags, or even Points of Contact",
      },
      {
        selector: "#audit-export-buttons",
        title: "Audit Reporting",
        content:
          "Finally, when you are all done, wrap up the Audit by generating a comprehensive Audit Report, a simplified Findings Report, or even just export the results to a Spreadsheet!",
      },
      {
        highlightedSelectors: ["#audit-general-details", "#audit-pie-chart", "#audit-bar-chart"],
        title: "The End",
        content:
          "This is the end of the Tutorial. If you want to see it again, use the 'Reset All Tours' option in your User Preferences. Have fun Auditing!",
      },
    ],
  });
