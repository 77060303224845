import React, { useEffect } from "react";

import { isNullOrUndefined, removeDuplicateObjects } from "@rivial-security/func-utils";

import { useSync } from "../../../../hooks/functional/useSync";
import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";

/**
 * Custom Field for displaying Systems that are associated with a Threat
 * @param item
 * @param updateItemById
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemRiskLinks = ({ item, updateItemById, resetFunction, isLoading, ...props }) => {
  const [systemsAndRisks] = useSync(item, () => {
    if (!isNullOrUndefined(item?.systemRiskLinks?.items)) {
      let systems = [];
      let risks = [];

      item.systemRiskLinks.items.map((systemRiskThreatLink) => {
        systemRiskThreatLink?.systemRiskLink?.system &&
          systems.push({
            ...systemRiskThreatLink?.systemRiskLink?.system,
            systemRiskLink: systemRiskThreatLink.systemRiskLink,
          });

        systemRiskThreatLink?.systemRiskLink?.risk &&
          risks.push({
            ...systemRiskThreatLink?.systemRiskLink?.risk,
            systemRiskLink: systemRiskThreatLink.systemRiskLink,
            probabilityModifier: systemRiskThreatLink.systemRiskLink.probabilityModifier,
          });
      });

      systems = removeDuplicateObjects(systems);
      risks = removeDuplicateObjects(risks);

      return {
        systems,
        risks,
      };
    } else {
      return {
        systems: [],
        risks: [],
      };
    }
  });

  const systemsGrid = useSystemDataGrid({
    queryConfig: {
      query: null,
    },
    cardConfig: {
      enableSticky: false,
    },
    gridConfig: {
      detailsType: DETAILS_TYPES.NONE,
      enableQuickDetails: true,
      gridHeight: null,
      enablePersistence: false,
      persistenceUUID: "073a2b14-62c4-411f-b0be-c0353f3ee2614566",
      enableMenu: false,
      createResourceComponent: undefined,
      deleteFunction: undefined,
      resetFunction,
      isLoading,
      sortSettings: {
        columns: [{ field: "name", direction: "Ascending" }],
      },
    },
  });

  useEffect(() => {
    systemsGrid.setData(systemsAndRisks?.systems || []);
  }, [systemsAndRisks]);

  return <div style={{ height: "30em" }}>{systemsGrid.gridDisplay}</div>;
};

export default SystemRiskLinks;
