import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { deleteVendorSolutionReview } from "../../VendorSolutionReviews/functions/deleteVendorSolutionReview";

/**
 * Deletes a VendorSolution.
 * First deletes all associated VendorReviews.
 * @param {object} vendorSolution
 * @param {string} vendorSolution.id
 * @returns {Promise<void>}
 */
export const deleteVendorSolution = async (vendorSolution) => {
  // Query full VendorSolution, with all VendorReviews
  const { getQuery: getFullVendorSolution, deleteMutation } = generateGraphql("VendorSolution", ["vendorReviews"], {
    vendorReviews: `(limit: 100) { items { id } }`,
  });
  const fullVendorSolution = await ItemQuery(getFullVendorSolution, vendorSolution.id);
  const vendorReviews =
    fullVendorSolution && fullVendorSolution.vendorReviews && fullVendorSolution.vendorReviews.items
      ? fullVendorSolution.vendorReviews.items
      : [];

  // delete Vendor Reviews
  for (const vendorReview of vendorReviews) await deleteVendorSolutionReview(vendorReview);

  // then delete Vendor Solution
  await ItemMutation(deleteMutation, { id: vendorSolution.id });
};
