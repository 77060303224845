import { useState } from "react";

import { GetQuery } from "@rivial-security/appsync-utils";
import { convertCamelCaseToSentence, isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { TargetVulnerabilityStatus } from "@rivial-security/schema-types";

import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import TabCard from "../../../../utils/GenericComponents/TabCard";

import { VulnerabilityTargets } from "./VulnerabilityTargets";

/**
 * Displays a list of targets for a vulnerability in a tab card format based on the status of the target
 * @param {string} organizationID - the currently selected organization id
 * @param {string} itemId - the vulnerability id
 * @param {object} item - the vulnerability data
 * @param {function} resetFunction - resets the tab card
 * @param {object} props - additional props
 * @returns {JSX.Element}
 */
const TargetsTabCard = ({ organizationID, itemId, item, ...props }) => {
  const [activeTabName, setActiveTabName] = useState(TargetVulnerabilityStatus.NOT_FIXED);
  const convertTitleToTechnicalName = (title) => {
    switch (title) {
      case convertCamelCaseToSentence(TargetVulnerabilityStatus.NOT_FIXED):
        return TargetVulnerabilityStatus.NOT_FIXED;
      case convertCamelCaseToSentence(TargetVulnerabilityStatus.FIXED):
        return TargetVulnerabilityStatus.FIXED;
      case convertCamelCaseToSentence(TargetVulnerabilityStatus.EXCEPTION):
        return TargetVulnerabilityStatus.EXCEPTION;
      default:
        return title;
    }
  };

  const [targets, setTargets] = useState([]);
  const { resetFunction, isLoading } = useSetAsyncData({
    getData: async () => {
      const vulnerability = await GetQuery({
        query: getVulnerabilityQuery,
        variables: { id: itemId },
      });
      if (!vulnerability?.id) {
        return [];
      }

      const vulnerabilityTargetLinks = vulnerability?.targets?.items || [];
      const targets = [];
      for (const targetLink of vulnerabilityTargetLinks) {
        let target = targetLink?.target;

        //only add to list if there's a target specified
        if (!target) continue;

        //only add to final list if the target does not already exist and the link is not marked as a false positive
        const found = targets.find((x) => x.id === target?.id);
        if (isNullOrUndefined(found) && !targetLink?.falsePositive) {
          target = {
            ...target,
            targetFindingLinkID: targetLink?.id,
            status: targetLink?.status,
            priority: targetLink?.priority,
            link: { id: targetLink?.id },
            pointOfContact: targetLink?.pointOfContact,
          };

          targets.push(target);
        }
      }

      return targets;
    },
    setData: (targets) => {
      setTargets(targets);
    },
    dependencies: [itemId, props.filterCondition],
  });

  /**
   * Returns a list of filtered targets to show based on the tab status
   * @param {string} status - the status of targets being shown in the tab
   * @returns {object[]} - a list of targets
   */
  const getTargetsForTab = ({ status }) => {
    if (!isNonEmptyArray(targets)) {
      return [];
    }

    return targets.filter((x) => x?.status === status);
  };

  const handleTabChange = (tabName) => {
    const technicalName = convertTitleToTechnicalName(tabName);
    setActiveTabName(technicalName);
  };
  return (
    <div style={{ flex: 1 }}>
      <TabCard
        item={{ ...(item || {}), id: itemId }}
        onTabChange={handleTabChange}
        items={[
          {
            title: convertCamelCaseToSentence(TargetVulnerabilityStatus.NOT_FIXED),
            total: getTargetsForTab({
              status: TargetVulnerabilityStatus.NOT_FIXED,
            }).length,
            component: (
              <VulnerabilityTargets
                organizationID={organizationID}
                item={{ ...(item || {}), id: itemId }}
                targets={getTargetsForTab({
                  status: TargetVulnerabilityStatus.NOT_FIXED,
                })}
                setTargets={setTargets}
                linkedItems={targets}
                status={activeTabName}
                resetFunction={resetFunction}
                isLoading={isLoading}
                {...props}
              />
            ),
            icon: <i className={"icon-eye"} />,
          },
          {
            title: convertCamelCaseToSentence(TargetVulnerabilityStatus.FIXED),
            total: getTargetsForTab({
              status: TargetVulnerabilityStatus.FIXED,
            }).length,
            component: (
              <VulnerabilityTargets
                organizationID={organizationID}
                item={{ ...(item || {}), id: itemId }}
                targets={getTargetsForTab({
                  status: TargetVulnerabilityStatus.FIXED,
                })}
                setTargets={setTargets}
                linkedItems={targets}
                status={activeTabName}
                resetFunction={resetFunction}
                isLoading={isLoading}
                {...props}
              />
            ),
            icon: <i className={"icon-eye"} />,
          },
          {
            title: convertCamelCaseToSentence(TargetVulnerabilityStatus.EXCEPTION),
            total: getTargetsForTab({
              status: TargetVulnerabilityStatus.EXCEPTION,
            }).length,
            component: (
              <VulnerabilityTargets
                organizationID={organizationID}
                item={{ ...(item || {}), id: itemId }}
                targets={getTargetsForTab({
                  status: TargetVulnerabilityStatus.EXCEPTION,
                })}
                setTargets={setTargets}
                linkedItems={targets}
                status={activeTabName}
                resetFunction={resetFunction}
                isLoading={isLoading}
                {...props}
              />
            ),
            icon: <i className={"icon-eye"} />,
          },
        ]}
        resetFunction={resetFunction}
        disableChipColor={true}
      />
    </div>
  );
};

const { getQuery: getVulnerabilityQuery } = generateGraphql("Vulnerability", ["targets"], {
  targets: `(limit: 1000) {
    items {
      id
      ownerGroup
      falsePositive
      status
      priority
      pointOfContact {
        id
        firstName
        lastName
      }
      target {
        id
        ownerGroup
        hostName
        ip
        macAddress
      }
      history {
        items {
          id
          type
          ownerGroup
          description
          oldStatus
          newStatus
          date
          comment
          data
        }
      }
    }
  }`,
});

export default TargetsTabCard;
