import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import AuditDetails from "../components/AuditDetails";
import AuditIsComplete from "../components/AuditIsComplete";
import CreateAudit from "../components/CreateAudit";
import ControlSetName from "../customFields/ControlSetName";
import { deleteAudit } from "../functions/deleteAudit";

/**
 * @description Displays a list of audits for an organization
 * @param {object} cardConfig - custom card settings
 * @param {object} gridConfig - custom grid settings
 * @param {object} queryConfig - custom query settings
 * @param {string} organizationID - the organization for the audits
 */

export const useAuditDataGrid = ({ cardConfig = {}, gridConfig = {}, queryConfig = {}, organizationID, ...props }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;
  const typename = "Audit";
  const queryFields = ["name", "controlSet", "isComplete", "completionPercentage"];
  const nestedFields = {
    controlSet: `{id name}`,
  };

  const route = "#/continuous_compliance/audits/";

  const { listQuery, updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    organizationID,
    query: listQuery,
    ...queryConfig,
  };

  cardConfig = {
    title: "Audits",
    headerIcon: "icon-film",
    ...cardConfig,
  };

  gridConfig = {
    organizationID,
    detailsComponent: <AuditDetails />,
    createResourceComponent: <CreateAudit />,
    createResourceComponentWidth: "80vw",
    updateMutation,
    enableQuickDetails: true,
    config: {
      width: "95vw",
    },
    typename,
    module,
    resource,
    fields: [
      {
        name: "name",
        flex: 1,
        minWidth: 200,
      },
      {
        name: "controlSet",
        friendlyName: "Framework",
        component: <ControlSetName />,
        flex: 1,
        minWidth: 100,
        valueGetter: (_value, row) => {
          return row?.controlSet?.name;
        },
      },
      {
        name: "isComplete",
        friendlyName: "Is Complete",
        component: <AuditIsComplete />,
        disablePropagation: true,
        width: 300,
        type: "boolean",
        headerAlign: "left",
        valueOptions: [
          {
            value: "yes",
            label: "Yes",
          },
          {
            value: "no",
            label: "No",
          },
        ],
      },
      {
        name: "completionPercentage",
        friendlyName: "Completion",
        inputType: GENERIC_FIELD_TYPES.NUMERIC,
        width: 300,
        type: "number",
        headerAlign: "left",
      },
    ],
    options: ["details", "duplicate", "delete"],
    deleteFunction: deleteAudit,
    route,
    persistenceUUID: "ee1e2aea-fd27-497d-8285-beba35cb30a0",
    ...gridConfig,
    ...props,
  };

  const grid = useDataGrid({
    roleConfig,
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    duplicationSettings,
  });

  return {
    ...grid,
  };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates Audits. Preserves Audit Notes, completion, and Control linking (copies Notes, Compliance Status, and Evidence Reviews). Does not copy Control Recommendations.",
  fields: ["name", "controlSetID", "controls", "completionPercentage", "isComplete", "auditDate", "notes"],
  nestedFields: {
    controls: `(limit: 1000) { items { id ownerGroup auditID controlID evidenceLinks(limit: 200) { items { evidenceID reviewed } } control { id } isCompliant interviewee auditor notes { author content timeStamp ownerGroup observationID } } }`,
    notes: `{author timeStamp content ownerGroup observationID}`,
  },
  primaryField: "name",
  connectionAdaptor: {
    controls: {
      connectionTypename: "AuditControlLink",
      itemConnectionIDField: "auditID",
      childConnectionIDField: "controlID",
      childConnectionField: "control",
      fields: ["notes", "isCompliant", "interviewee", "auditor"],

      /**
       * Duplicate the control, as well as its evidenceLinks
       * @param {object} connectedItem - an AuditControlLink
       * @param {object} item - the Audit
       * @param {string} organizationID - the currently selected organization
       */
      mutationFunction: async ({ item, connectedItem, organizationID }) => {
        // graphql mutation for creating new AuditControlLink
        const { createMutation: createAuditControlLinkMutation } = generateGraphql("AuditControlLink", [
          "auditID",
          "controlID",
          "isCompliant",
          "interviewee",
          "auditor",
        ]);

        // graphql mutation for creating new Control Evidence Link
        const { createMutation: createAuditControlEvidenceLinkMutation } = generateGraphql("AuditControlEvidenceLink", [
          "auditControlLinkID",
          "evidenceID",
          "reviewed",
        ]);

        // create the new AuditControlLink (only create the link of controlID is not null)
        if (isNullOrUndefined(connectedItem?.link?.controlID)) {
          return;
        }

        const auditControlLink = await ItemMutation(createAuditControlLinkMutation, {
          auditID: item.id,
          controlID: connectedItem.link.controlID, // needs to be the id of the control, not the ID of the link
          interviewee: connectedItem.interviewee,
          auditor: connectedItem.auditor,
          isCompliant: connectedItem.isCompliant,
          ownerGroup: organizationID,
          notes: connectedItem.notes || [],
        });

        // allow promises to run in parallel
        const evidenceLinkPromises = [];

        // now create evidence links to the new control
        const evidenceLinks = connectedItem?.link?.evidenceLinks?.items || [];

        for (const evidenceLink of evidenceLinks) {
          if (evidenceLink?.evidenceID) {
            // create the new evidence link
            const evidenceLinkPromise = ItemMutation(createAuditControlEvidenceLinkMutation, {
              auditControlLinkID: auditControlLink.id,
              evidenceID: evidenceLink.evidenceID,

              reviewed: evidenceLink.reviewed,

              ownerGroup: organizationID,
            });

            evidenceLinkPromises.push(evidenceLinkPromise);
          }
        }

        // await all the evidence link promises so they don't get skipped
        await Promise.allSettled(evidenceLinkPromises);
      },
    },
  },
};
