"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEmptyArray = void 0;
/**
 * Easily check if a variable is a valid array that is empty
 * @param {*[]} value - the array to check
 * @returns {boolean} - TRUE if the value is a valid array with length of zero, FALSE otherwise
 */
const isEmptyArray = (value) => {
    return Array.isArray(value) && value.length === 0;
};
exports.isEmptyArray = isEmptyArray;
