import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ASSESSMENT_STATUS, ASSESSMENT_TYPE } from "../../../../typedefs/Testing/Assessment/Assessment";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import CreateLinking from "../../../../utils/GenericComponents/CreateLinking";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import AddSingleAssessment from "../../Assessments/components/AddSingleAssessment";
import AssessmentGrid from "../../Assessments/components/AssessmentDataGrid";
import { createAssessmentTargetLink } from "../../Assessments/functions/createAssessmentTargetLink";
import { useAssessmentDataGrid } from "../../Assessments/hooks/useAssessmentDataGrid";
import { listAssessmentTargetLinksByTargetId } from "../graphql/__targetsGQL";

/**
 * @description Display list assessments for a target
 * @param {object} item - target
 * @param {string} organizationID
 * @constructor
 */
const TargetAssessments = ({ item, organizationID }) => {
  const fields = [
    {
      name: "name",
      flex: 1,
    },
    {
      name: "type",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_TYPE }),
      },
    },
    {
      name: "status",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_STATUS }),
      },
    },
  ];

  const deleteFunction = async (item) => {
    if (item?.connectionIDField) {
      await ItemMutation(generateGraphql("AssessmentTargetLink").deleteMutation, { id: item?.connectionIDField });
    }
  };

  const grid = useAssessmentDataGrid({
    organizationID,
    /**
     * Config to get data from database
     */
    query: listAssessmentTargetLinksByTargetId,
    variables: {
      targetID: item?.id,
    },
    normalizeData: (links) => {
      const assessments = [];
      for (const link of links) {
        const assessment = link?.assessment;
        if (assessment) {
          const found = assessments.find((x) => x.id === assessment?.id);
          if (!found) {
            Object.assign(assessment, { connectionIDField: link?.id });
            assessments.push(assessment);
          }
        }
      }
      return assessments;
    },

    fields,
    gridHeight: "100%",
    deleteFunction,
    createItemModalHeader: "Attach an Assessment",
    createResourceComponent: (
      <CreateLinking
        typename={"Assessment"}
        parentTypename={"Target"}
        form={<AddSingleAssessment />}
        grid={<AssessmentGrid fields={fields} />}
        item={item}
        queryConfig={{ normalizeData: undefined }}
        createFunction={(item, itemToLink) =>
          createAssessmentTargetLink({
            target: itemToLink,
            assessment: item,
            organizationID,
          })
        }
      />
    ),
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(TargetAssessments);
