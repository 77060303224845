import Grid from "@mui/material/Grid";
import { ReactElement } from "react";

import PermissionsOverlay from "../../../Overlays/PermissionsOverlay";

import SelectionTile, { SelectionTileConfig } from "./SelectionTile";

export interface SelectionTilesProps {
  items: SelectionTileConfig[];
  onSelect?: (item: SelectionTileConfig) => Promise<void> | void;
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";
  checkIsSelected?: (item: SelectionTileConfig) => boolean;
}

/**
 * Utility component for displaying a Grid of Square Buttons with Text and Icon
 * Useful for user selection of set options where it's useful for all options to
 * be seen at once.
 *
 * @param organizationID - The ID of the organization
 * @param items - tile configuration items
 * @param onSelect - callback to be called when an item is selected
 * @param alignItems - alignment of items in the grid
 * @param justifyContent - alignment of items in the grid
 * @param checkIsSelected - function to check if an item is selected, should return a boolean
 * @returns {JSX.Element}
 */
const SelectionTiles = ({
  items = [],
  onSelect,
  checkIsSelected,
  alignItems = "center",
  justifyContent = "center",
}: SelectionTilesProps): ReactElement => {
  return (
    <Grid
      container
      spacing={3}
      alignItems={alignItems}
      justifyContent={justifyContent}
      data-testid={"selection-tiles-wrapper"}
    >
      {Array.isArray(items) &&
        items.map((item: SelectionTileConfig, index) => {
          // disable the role checking if at least the module to check is not provided
          const disableRoleChecking = !item?.module;

          return (
            <Grid item key={`type${index}`}>
              <PermissionsOverlay
                disableRoleChecking={disableRoleChecking}
                module={item?.module}
                resource={item?.resource}
                operationType={"read"}
                isTooltipWarning={true}
              >
                <SelectionTile
                  item={item}
                  onSelect={onSelect}
                  selected={typeof checkIsSelected === "function" ? checkIsSelected(item) : false}
                />
              </PermissionsOverlay>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default SelectionTiles;
