import { Button } from "@mui/material";
import a from "indefinite";
import React from "react";

import { useModal } from "../../../hooks/views/useModal";

/**
 * A utility Button that has a built in modal
 * @param {JSX.Element} children - component displayed in the modal
 * @param {string} typename - typename, used in the modal header
 * @param config
 * @returns {JSX.Element}
 */
const MUICreateModalButton = ({ children, typename, disabled, icon, text, modalConfig = {} }) => {
  const modal = useModal(
    `Create ${a(typename)}`,
    children,
    <Button startIcon={<i className={icon} />} title={`Create ${a(typename) ?? "a Resource"}`} disabled={disabled}>
      {text}
    </Button>,
    modalConfig,
  );

  return <div>{modal.modalButton}</div>;
};

export default MUICreateModalButton;
