"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getControlEffectiveness = void 0;
const checkSystemForRiskControlOverride_1 = require("./checkSystemForRiskControlOverride");
const findIndexOfRiskControlOverride_1 = require("./findIndexOfRiskControlOverride");
const isRiskControlOverrideFieldEnabled_1 = require("./isRiskControlOverrideFieldEnabled");
/**
 * Gets the Control Effectiveness for a RiskControl on a particular system.
 * This function handles the override checks, and either returns the actual value or the overridden value,
 * depending on if the override is enabled.
 *
 * @param {subControl} riskControl
 * @param {system} system
 * @returns {number}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlEffectiveness = (riskControl, system) => {
    const riskControlOverrides = system?.riskControlOverrides;
    const indexOfRiskControlOverride = (0, findIndexOfRiskControlOverride_1.findIndexOfRiskControlOverride)(riskControlOverrides, riskControl);
    const doesOverrideExist = (0, checkSystemForRiskControlOverride_1.checkSystemForRiskControlOverride)(riskControlOverrides, riskControl) !== false;
    const isStrengthOverrideEnabled = indexOfRiskControlOverride !== -1 &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled)(riskControlOverrides[indexOfRiskControlOverride], "strengthRating");
    const isImplementationOverrideEnabled = indexOfRiskControlOverride !== -1 &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled)(riskControlOverrides[indexOfRiskControlOverride], "implementationRating");
    const strengthOverrideValue = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        riskControlOverrides[indexOfRiskControlOverride]?.strengthRating;
    const implementationOverrideValue = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        riskControlOverrides[indexOfRiskControlOverride]?.implementationRating;
    const getControlStrengthRating = () => {
        if (isStrengthOverrideEnabled) {
            // @ts-expect-error Fix me, needs better typing
            if (strengthOverrideValue !== null && strengthOverrideValue !== undefined && !isNaN(strengthOverrideValue)) {
                return strengthOverrideValue;
            }
            else {
                return 0.0;
            }
        }
        else {
            return riskControl?.strengthRating;
        }
    };
    const getControlImplementationRating = () => {
        if (isImplementationOverrideEnabled !== null &&
            isImplementationOverrideEnabled !== undefined &&
            isImplementationOverrideEnabled) {
            if (implementationOverrideValue !== false &&
                implementationOverrideValue !== null &&
                implementationOverrideValue !== undefined &&
                !isNaN(implementationOverrideValue)) {
                return implementationOverrideValue;
            }
            else {
                return 0.0;
            }
        }
        else {
            return riskControl?.implementationRating;
        }
    };
    const controlStrengthRating = getControlStrengthRating();
    const controlImplementationRating = getControlImplementationRating();
    const controlEffectiveness = controlStrengthRating * controlImplementationRating;
    return controlEffectiveness;
};
exports.getControlEffectiveness = getControlEffectiveness;
