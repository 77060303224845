interface FormatRiskRecommendationDataInput {
  data: string;
}

interface SystemChange {
  systemRiskValues: object | undefined;
}

/**
 * Formats the current unstructured recommendation 'data' field to be used in the structured 'riskRecommendationData' field
 */
export const formatRiskRecommendationData = ({ data }: FormatRiskRecommendationDataInput) => {
  // the data is a string, so we need to parse it
  const parsedData = JSON.parse(data);

  // remove the ownerGroup field
  delete parsedData.ownerGroup;

  if (parsedData.systemChanges) {
    // remove the systemRiskValues field from systemChanges
    parsedData.systemChanges = parsedData.systemChanges.map((systemChange: SystemChange) => {
      delete systemChange.systemRiskValues;
      return systemChange;
    });
  }

  if (parsedData.riskControl) {
    // remove the extra riskControl fields that aren't needed
    delete parsedData.riskControl.enabledFields;
    delete parsedData.riskControl.implementationDetails;
    delete parsedData.riskControl.riskControlId;
  }

  return parsedData;
};
