"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForAutomationTriggeringItself = void 0;
/**
 * Checks whether the combination of the trigger and automation steps will cause an infinite loop.
 * @param {object} automation - automation data from the database containing both the automation step and trigger data
 * @return {object} - returns an object with a status and a message, these can be used in a material UI alert
 */
const checkForAutomationTriggeringItself = ({ automation }) => {
    // Check parameters
    const triggers = automation?.triggers;
    const conditions = automation?.conditions;
    if (!Array.isArray(triggers) || !Array.isArray(automation?.automations) || !Array.isArray(conditions)) {
        return {
            status: "warning",
            message: "Could not check if the automation triggers itself because the automation is invalid!",
        };
    }
    // collect all nested steps that are inside of loops
    const getNestedSteps = ({ steps }) => {
        let currentSteps = [];
        for (const step of steps) {
            currentSteps.push(step);
            if (step?.type === "loop") {
                const loopSteps = step?.config?.automations;
                const nestedSteps = getNestedSteps({ steps: loopSteps });
                currentSteps = currentSteps.concat(nestedSteps);
            }
        }
        return currentSteps;
    };
    const steps = getNestedSteps({ steps: automation?.automations });
    // Collect all trigger urls
    const webHookEndpoints = [];
    for (const trigger of triggers) {
        if (trigger?.type === "webhook") {
            webHookEndpoints.push(trigger?.webhookUrl);
        }
    }
    // Check to not call the same http endpoint as the triggering http endpoint
    for (const step of steps) {
        if (step?.type === "http") {
            const url = step?.config?.url;
            const foundMatch = webHookEndpoints.find((webHook) => webHook && typeof webHook === "string" && webHook.includes(url));
            if (foundMatch) {
                return {
                    status: "error",
                    message: `HTTP Automation step '${step?.name || step?.description}' is trying to make a request to the webhook url that triggers this automation causing an infinite loop!`,
                };
            }
        }
    }
    // Create trigger resources
    const createTriggerResources = [];
    for (const trigger of triggers) {
        if (trigger?.type === "resourceCreated") {
            for (const condition of conditions) {
                if (condition?.typename) {
                    createTriggerResources.push(condition?.typename);
                }
            }
        }
    }
    // Check to not create the same resource that triggers this automation
    for (const step of steps) {
        if (step?.type === "createResource") {
            const typename = step?.config?.typename;
            const foundMatch = createTriggerResources.find((resource) => resource === typename);
            if (foundMatch) {
                return {
                    status: "error",
                    message: `Create resource automation step '${step?.name || step?.description}' is trying to create a resource of the same type that triggers this automation causing a possible infinite loop!`,
                };
            }
        }
    }
    // Update trigger resources
    const updateTriggerResources = [];
    for (const trigger of triggers) {
        if (trigger?.type === "resourceUpdated") {
            for (const condition of conditions) {
                if (condition?.typename) {
                    updateTriggerResources.push(condition?.typename);
                }
            }
        }
    }
    // Check to not update the same resource type as the type used in this automation's update trigger
    for (const step of steps) {
        if (step?.type === "updateResource") {
            const typename = step?.config?.typename;
            const foundMatch = updateTriggerResources.find((resource) => resource === typename);
            if (foundMatch) {
                return {
                    status: "error",
                    message: `Update resource automation step '${step?.name || step?.description}' is trying to update a resource of the same type that triggers this automation causing a possible infinite loop!`,
                };
            }
        }
    }
    return {
        status: "success",
        message: "Automation does not trigger itself!",
    };
};
exports.checkForAutomationTriggeringItself = checkForAutomationTriggeringItself;
