import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a many-to-many connection link object
 * @param {string} parentID - the item ID for the many to many connection
 * @param {string} childID - the second item ID for the many-to-many connection
 * @param organizationID
 * @param {string} typename - typename for the connection model object
 * @param {string} parentConnectionIDField - field name for the itemID
 * @param {string} childConnectionIDField - field name for the linkedItemID
 * @param {function} [updateLinkFunction] - a function that can be called right before the mutation, takes the mutation input and returns the mutation input with updates
 */
export const createLink = async (
  parentID,
  childID,
  organizationID,
  typename,
  parentConnectionIDField,
  childConnectionIDField,
  updateLinkFunction,
) => {
  const { createMutation } = generateGraphql(typename);

  let input = {
    [parentConnectionIDField]: parentID,
    [childConnectionIDField]: childID,
    ownerGroup: organizationID,
  };

  if (!isNullOrUndefined(updateLinkFunction) && typeof updateLinkFunction === "function") {
    input = updateLinkFunction(input);
  }

  return ItemMutation(createMutation, input);
};
