import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * @typedef {object} UseSetAsyncDataParams
 * @param {function} getData - function to call to retrieve data from the backend
 * @param {*} [defaultValue] - value to set if the backend returns null or an error
 * @param {function} setData - state update function to update with new data
 * @param {function} [normalizeData] - function to call to modify the data before setting it in the state
 * @param {function} [setIsLoadingExternal] - callback for change in loading state
 * @param {boolean} [isLoadingExternal] - external isLoading state passed down to the parent
 * @param {function} [resetFunction] - callback for resetting the parent (if an external reset function exists)
 * @param {*[]} dependencies - optional dependencies to re-query data when they change
 * @param {function} skipUpdate - function called to check if update should be skipped
 */

/**
 * Hook used to generically populate a data grid
 * @param {UseSetAsyncDataParams} input
 * @returns {{isLoading: boolean, resetFunction: () => void}}
 */
export const useSetAsyncData = ({
  getData,
  defaultValue,
  setData,
  normalizeData,
  setIsLoading: setIsLoadingExternal,
  isLoading: isLoadingExternal,
  resetFunction,
  dependencies = [],
  skipUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const retrieveData = async () => {
    try {
      setIsLoading(true);
      let data = defaultValue;
      try {
        if (typeof getData === "function") {
          data = await getData();
        }
      } catch (e) {
        ErrorLogger("Could not get data", e);
      }

      if (typeof normalizeData === "function") {
        data = normalizeData(data);
      }

      if (typeof setData === "function" && data) {
        setData(data);
      }
    } catch (e) {
      ErrorLogger("Failed to get data!", e);
    } finally {
      setIsLoading(false);
    }
  };

  // Resetting data when dependencies change
  useEffect(() => {
    if (skipUpdate?.(...dependencies)) {
      return;
    }

    retrieveData();
  }, [...dependencies]);

  // Setting the loading state of parent based on internal and external loading state
  useEffect(() => {
    if (typeof setIsLoadingExternal === "function") {
      setIsLoadingExternal((isLoadingExternal ?? false) || isLoading);
    }
  }, [isLoadingExternal, isLoading]);

  return {
    isLoading,
    resetFunction: resetFunction ?? (() => retrieveData()),
  };
};
