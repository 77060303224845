"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCount = void 0;
/**
 * Returns the count of items in the array
 * @param {*[]} items - array of items
 * @returns {*|null} - the count of items or null if no items is not an array
 */
const getCount = ({ items }) => {
    if (Array.isArray(items)) {
        return items.length;
    }
    else {
        return null;
    }
};
exports.getCount = getCount;
