"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMostRecentMetric = void 0;
/**
 * Gets the most recent entry for a given metric type that has a value (doesn't query data)
 * @param metricType Metric type with metric entries
 * @return If found, returns a valid recent metric, otherwise null
 */
const getMostRecentMetric = (metricType) => {
    const badValues = ["Error", null, undefined];
    const hasValue = (value) => !badValues.includes(value);
    const items = metricType.metrics?.items;
    if (!items) {
        return null;
    }
    const nonErrorItems = items.filter((item) => hasValue(item?.data?.value));
    if (nonErrorItems.length === 0) {
        return null;
    }
    return nonErrorItems.reduce((a, b) => {
        const dateA = new Date(a?.date || 0);
        const dateB = new Date(b?.date || 0);
        return dateA > dateB ? a : b;
    });
};
exports.getMostRecentMetric = getMostRecentMetric;
