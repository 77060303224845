import { isNonEmptyArray, isNullOrUndefined, isObject } from "@rivial-security/func-utils";

import { combineFieldConfig } from "./combineFieldConfig";

/**
 * Computes the initial and reset state of the form inputs
 * @param {object} item - item being edited by the form, defines initial inputs if any
 * @param {object | object[]} fieldConfigInit - the field settings for each step or the entire form
 * @param {object} input - the current user's data entry state (also pre-filled with defaults and item)
 * @param {function} setInput - function to update the "input" state
 * @param {number} activeStep - the index of the current active form step
 */
export const getInitialState = ({ item, fieldConfig: fieldConfigInit, input, setInput, activeStep }) => {
  let fieldConfig = combineFieldConfig({ fieldConfig: fieldConfigInit });
  if (!isNullOrUndefined(activeStep) && isNonEmptyArray(fieldConfigInit)) {
    fieldConfig = fieldConfigInit?.[activeStep]?.fields;
  }

  if (!isObject(fieldConfig)) {
    return;
  }

  const newInput = isNullOrUndefined(activeStep) ? {} : { ...input };
  const itemToUpdate = item || {};
  Object.entries(fieldConfig).forEach(([fieldName, properties]) => {
    if (!isNullOrUndefined(fieldName) && !isNullOrUndefined(properties) && !properties.removeFromInput) {
      /**
       * If there is an 'item' prop passed in, try and use it's field first.
       * Then try the 'defaultValue' property.
       * Defaults to an empty string
       */
      if (itemToUpdate[fieldName] !== null && itemToUpdate[fieldName] !== undefined) {
        newInput[fieldName] = itemToUpdate[fieldName];
      } else if (properties.defaultValue !== null && properties.defaultValue !== undefined) {
        newInput[fieldName] = properties.defaultValue;
      } else {
        newInput[fieldName] = "";
      }
    }
  });

  /**
   * If there is an 'item' prop passed in, and has an id field, set that for the input.
   * This ensures it will be an update function instead of a create function
   */
  if (item && item.id) {
    newInput["id"] = item.id;
  }

  setInput({ ...newInput });
};
