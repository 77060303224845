import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { resources } from "@rivial-security/role-utils";
import { TargetVulnerabilityStatus } from "@rivial-security/schema-types";

import EllipsisText from "../../../../../../utils/Text/components/EllipsisText";

import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";

/**
 * Timeline content to display when a vulnerability history item is a target status
 * @param {object} item - the TargetVulnerabilityHistory item
 * @returns {JSX.Element}
 */
const TargetStatus = ({ item }) => {
  const isFindingAttached = !!item?.vulnerabilityName;

  const getStatusColor = ({ status }) => {
    switch (status) {
      case TargetVulnerabilityStatus.NOT_FIXED:
        return "red";
      case TargetVulnerabilityStatus.FIXED:
        return "green";
      case TargetVulnerabilityStatus.EXCEPTION:
        return "orange";
      default:
        return undefined;
    }
  };

  return (
    <span>
      {isFindingAttached ? "Vulnerability " : "Target "}
      <TimelineUnderlinedDetails modalType={isFindingAttached ? resources.VULNERABILITY : resources.TARGET} item={item}>
        <EllipsisText text={isFindingAttached ? item?.vulnerabilityName : item?.targetName} maxLength={15} />
      </TimelineUnderlinedDetails>
      {" changed status from "}
      <span
        style={{
          color: getStatusColor({ status: item?.oldStatus }),
        }}
      >
        {convertCamelCaseToSentence(item?.oldStatus) || "None"}
      </span>
      {" to "}
      <span
        style={{
          color: getStatusColor({ status: item?.newStatus }),
        }}
      >
        {convertCamelCaseToSentence(item?.newStatus)}
      </span>
      {isNullOrUndefined(item?.assessmentID) ? " manually" : " because of an "}
      {!isNullOrUndefined(item?.assessmentID) && (
        <TimelineUnderlinedDetails
          tooltip={"Click to view Assessment Details"}
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      )}
    </span>
  );
};

export default TargetStatus;
