export const gql_getRiskControlAssociatedSystems = /* GraphQL */ `
  query GetRiskControl($id: ID!) {
    getRiskControl(id: $id) {
      id
      name
      ownerGroup
      statementNumber
      controlCategory {
        id
        name
        ownerGroup
        systems(limit: 250) {
          items {
            id
            ownerGroup
            riskControlCategoryID
            systemID
            system {
              id
              name
              description
              ownerGroup
            }
          }
        }
      }
    }
  }
`;
