import { Button } from "@mui/material";
import React from "react";

/**
 * Button for saving roleConfig changes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SaveChangesButton = ({ style = {}, onClick }) => {
  return (
    <Button
      color={"primary"}
      title={"Save Role Configuration Changes"}
      style={style}
      size={"sm"}
      onClick={onClick}
      startIcon={<i className={"icon-check"} />}
    >
      Save Changes
    </Button>
  );
};

export default SaveChangesButton;
