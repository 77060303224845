"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImpactCost = void 0;
const getImpactFromAssetLink_1 = require("./getImpactFromAssetLink");
/**
 * Returns a dollar amount representing the Confidentiality or Integrity cost of a System.
 * Loops through all Information Assets on a System and gets cost based on Per Record values and Number of Records
 * If the SystemInformationAssetLink object holds 'confidentialityPerRecord' or 'integrityPerRecord' data, uses those numbers as overrides,
 * else it uses the 'confidentialityPerRecord' and 'integrityPerRecord' fields from the InformationAsset itself.
 *
 * @param system - the system object to calculate for
 * @param field - either 'confidentialityPerRecord' or 'integrityPerRecord'
 */
const getImpactCost = (system, field = "confidentialityPerRecord") => {
    // get confidentiality cost per information asset
    const assetLinks = system?.informationAssets?.items;
    // grab high-water mark information asset
    let highestCost = 0;
    if (assetLinks) {
        for (const assetLink of assetLinks) {
            const cost = (0, getImpactFromAssetLink_1.getImpactFromAssetLink)(assetLink, field);
            if (cost > highestCost) {
                highestCost = cost;
            }
        }
    }
    return highestCost;
};
exports.getImpactCost = getImpactCost;
