import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useDocumentDataGrid } from "../hooks/useDocumentDataGrid";

/**
 * @description This is a wrapper component for the DocumentDataGrid component. It is used to check if the user has an organization
 * @param {object} props - The props for the component
 * @returns {JSX.Element}
 */
const DocumentDataGrid = (props) => {
  return useDocumentDataGrid(props).display;
};

export default withOrganizationCheck(DocumentDataGrid);
