"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskToleranceData = void 0;
const risk_calc_utils_1 = require("@rivial-security/risk-calc-utils");
const getOrganization_1 = require("./getOrganization");
const getXAxisConfig_1 = require("./getXAxisConfig");
/**
 * Retrieves data (and chart settings) for the risk tolerance chart widget
 * @param {string} config.organizationID - The ID of the organization to for which to retrieve the risk data
 * @return {object} - config data for the risk tolerance chart widget
 */
const getRiskToleranceData = async ({ config } = {}) => {
    //Get the organization id from the config
    const organizationID = config?.organizationID;
    let lossToleranceCurve;
    let primaryXAxis;
    let primaryYAxis;
    //Get raw data
    try {
        // get y-axis data
        primaryYAxis = {
            title: "Probability of Risk (%)",
            minimum: 0,
            maximum: 1.0,
            labelFormat: "p0",
        };
        // get the risk tolerance curve
        const defaultRatingMap = {
            low: 0.2,
            lowMedium: 0.4,
            medium: 0.6,
            mediumHigh: 0.8,
            high: 1.0,
        };
        const organization = await (0, getOrganization_1.getOrganization)({ organizationID });
        const ratingMap = organization?.riskSetup || defaultRatingMap;
        lossToleranceCurve = organization?.lossToleranceCurve;
        lossToleranceCurve = lossToleranceCurve
            .sort((a, b) => b.probability - a.probability)
            .map((row) => ({ x: row.amount, y: row.probability }));
        const lossToleranceValues = (0, risk_calc_utils_1.getLossToleranceCurveXValues)(lossToleranceCurve);
        const max = (0, risk_calc_utils_1.getMaximumLossToleranceValue)(lossToleranceValues);
        // get x-axis data
        primaryXAxis = (0, getXAxisConfig_1.getXAxisConfig)({ ratingMap, max });
    }
    catch (e) {
        console.error(e);
    }
    //Return final data
    return {
        organizationID,
        lossToleranceCurve,
        primaryXAxis,
        primaryYAxis,
    };
};
exports.getRiskToleranceData = getRiskToleranceData;
