import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Sort classification by order number
 * @param {object[]} currentClassificationTiers - objects with a 'level' property
 */
export const sortClassificationTiers = (currentClassificationTiers) => {
  //Do not sort if classifications are null or not array
  if (isNullOrUndefined(currentClassificationTiers) || !Array.isArray(currentClassificationTiers)) {
    return [];
  }

  const newClassificationTiers = currentClassificationTiers.sort((a, b) => {
    if (isNullOrUndefined(a?.level) && isNullOrUndefined(b?.level)) {
      return 0;
    } else if (isNullOrUndefined(a?.level)) {
      return -1;
    } else if (isNullOrUndefined(b?.level)) {
      return 1;
    } else if (a.level === b.level) {
      return 0;
    } else {
      return a.level > b.level ? 1 : -1;
    }
  });

  return newClassificationTiers;
};
