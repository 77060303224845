"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleChange = void 0;
const handleMonteCarloChangeCalculation_1 = require("./handleMonteCarloChangeCalculation");
const handleChange = async (associatedSystems, change, organizationID, queryConfig) => {
    /**
     * Default Values
     */
    let inherentChange = 0;
    let residualChange = 0;
    let roiChange = 0;
    const systems = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calculationPromises = [];
    /**
     * Loop over all Associated Systems, sum up the changes from each one.
     */
    for (const associatedSystem of associatedSystems) {
        const calculationPromise = (0, handleMonteCarloChangeCalculation_1.handleMonteCarloChangeCalculation)(associatedSystem, change, organizationID, queryConfig);
        calculationPromises.push(calculationPromise);
    }
    await Promise.allSettled(calculationPromises).then((results) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        results.forEach((result) => {
            console.log({ result });
            const { value } = result;
            console.log({ value });
            const systemID = value.oldMonteCarloSystemInput?.system?.id
                ? value.oldMonteCarloSystemInput.system.id
                : "Could Not Find System ID";
            const systemName = value.oldMonteCarloSystemInput?.system?.name
                ? value.oldMonteCarloSystemInput.system.name
                : "Could Not Find System Name";
            inherentChange += value.changeInInherentRisk;
            residualChange += value.changeInResidualRisk;
            roiChange += value.changeInROI;
            systems.push({
                id: systemID,
                name: systemName,
                inherentChange: value.changeInInherentRisk,
                residualChange: value.changeInResidualRisk,
                roiChange: value.changeInROI,
            });
        });
    });
    return {
        inherentChange,
        residualChange,
        roiChange,
        systems,
    };
};
exports.handleChange = handleChange;
