import React from "react";

import { emptyPlaceholder } from "../../../../utils/GenericComponents/Placeholders";

/**
 * @description Display a control set name component
 * @param {object} item - audit object
 * @returns {JSX.Element}
 * @constructor
 */
const ControlSetName = ({ item }) => {
  return <div>{item?.controlSet?.name ? item?.controlSet?.name : emptyPlaceholder}</div>;
};

export default ControlSetName;
