import { getFunctionDefinitionResult } from "../../../definitions/functions/getFunctionDefinitionResult";

/**
 * Finds the most basic type of schema field,
 * - enums are string
 * - models are objects
 * - functions are the result type
 * @param {object[]} path - the path to the field in a custom query
 * @param {object} field - the final field
 * @returns {string}
 */
export const getUnderlyingFieldType = ({ path, field }) => {
  let underlyingType = field?.type?.toLowerCase();

  //All enums are actually strings
  if (underlyingType === "object" && !field?.isModel) {
    underlyingType = "string";
  }

  //For all functions use the result type
  if (field?.isFunction) {
    const functionResult = getFunctionDefinitionResult({ path, func: field });
    underlyingType = functionResult?.typename?.toLowerCase();
  }

  //Capitalize the first letter
  if (underlyingType) {
    underlyingType = underlyingType?.charAt(0).toUpperCase() + underlyingType?.slice(1);
  }

  return underlyingType;
};
