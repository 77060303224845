import { modules, resources } from "@rivial-security/role-utils";

/**
 * The Observation type with common configurations
 */
export const OBSERVATION = {
  typename: "Observation",
  route: "#/governance/observations/",
  module: modules.GOVERNANCE,
  resource: resources.OBSERVATION,
  fields: {
    id: "id",
    name: "name",
    description: "description",
    ownerGroup: "ownerGroup",
    module: "module",
    resource: "resource",
    field: "field",
    source: "source",
    isFinding: "isFinding",
    recommendations: "recommendations",
    evidenceID: "evidenceID",
    evidence: "evidence",
    exerciseID: "exerciseID",
    exercise: "exercise",
    decision: "decision",
    needsDecision: "needsDecision",
    risk: "risk",
    status: "status",
    insurancePolicyValidated: "insurancePolicyValidated",
  },
  fieldConfig: {
    name: {
      name: "name",
      label: "A short name for the observation",
    },
    decision: {
      name: "decision",
      description: "The decision made about this Observation",
      inputType: "select",
      valueOptions: ["none", "accept", "transfer", "mitigate", "avoid"],
    },
    isFinding: {
      name: "isFinding",
      description: "An Observation may be elevated to a 'Finding' to be tracked at a higher level",
      inputType: "boolean",
    },
    status: {
      name: "status",
      description: "Indicates if this Observation is active or archived",
      inputType: "select",
      valueOptions: ["open", "closed", "pastDue"],
    },
    needsDecision: {
      name: "needsDecision",
      description: "Indicates if the Observation requires a Decision to be made by the Department Head or other entity",
      inputType: "switch",
    },
    risk: {
      name: "risk",
      description: "The risk level of the Observation",
      inputType: "select",
      valueOptions: ["info", "low", "lowMedium", "medium", "mediumHigh", "high"],
    },
    insurancePolicyValidated: {
      name: "insurancePolicyValidated",
      description:
        "Indicates if the cyber insurance policy is validated for this risk. Only applicable to Transfer decisions",
      inputType: "switch",
    },
    module: {
      name: "module",
      description: "The module that this Observation belongs to",
    },
    externalSource: {
      name: "externalSource",
      description: "The external source that this Observation belongs to",
    },
    formalResponse: {
      name: "formalResponse",
      description: "The management response to this Observation",
    },
    department: {
      name: "department",
      description: "The department that this Observation belongs to",
    },
  },
};

/**
 * The Observation type with common configurations
 *   id: ID!
 *   name: String!         # Primary text. e.g. "Port 3002 left open", "Control ABC is not compliant", "50% users are untrained"
 *   description: String   # Optional, more descriptive text. e.g. "Port 3002 left open", "Control ABC is not compliant", "50% users are untrained"
 *   module: ModuleType!   # the module that this observation is associated with
 *   resource: String      # the resource that this observation is associated with
 *   field: String         # the field that this observation is associated with
 *   source: String        # e.g. "RITA Scan", "External Import"
 *   customStatus: String  # for flexbility, if a customStatus exists, uses this intead of the enum
 *   isFinding: Boolean    # default: false. Elevates this observation to a "Finding" that gets reported to the board
 *   recommendations: [ObservationRecommendationLink] @connection(keyName: "byObservation", fields: ["id"])
 *   ownerGroup: String!
 *   evidenceID: ID
 *   evidence: Evidence @connection(fields: ["evidenceID"])
 *   exerciseID: ID
 *   exercise: Exercise @connection(fields: ["exerciseID"])
 *   complianceControlID: ID
 *   complianceControl: Control @connection(fields: ["complianceControlID"])
 *   riskControlID: ID
 *   riskControl: RiskControl @connection(fields: ["riskControlID"])
 *
 *   vulnerabilityID: ID # deprecated
 *   vulnerability: Finding @connection(fields: ["vulnerabilityID"]) # deprecated
 *   vulnerabilities: [Finding] @connection(keyName: "byObservation", fields: ["id"])
 *
 *   incidentID: ID
 *   incident: Incident @connection(fields: ["incidentID"])
 *   externalSourceID: ID
 *   externalSource: Source @connection(fields: ["externalSourceID"])
 *   auditID: ID
 *   audit: Audit @connection(fields: ["auditID"])
 *   notes: [Note]
 *   formalResponse: String
 *   risk: RatingLevel
 *
 *   status: ObservationStatus
 *   decision: ObservationDecision
 *   needsDecision: Boolean
 *   insurancePolicyValidated: Boolean # checkbox, only relevate for 'Transfer' decisions
 *
 *   departmentID: ID
 *   department: Department @connection(fields: ["departmentID"])
 */
