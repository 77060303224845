import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useMetricForm } from "../hooks/useMetricForm";

/**
 * Displays a form for inputting a single Metric instance.
 * @param {object} props - all props passed to useMetricForm hook
 */
const MetricForm = (props) => useMetricForm(props).display;

export default withDetailsPage(MetricForm);
