import { API, graphqlOperation } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";

import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { InvalidParamError } from "../../../../analytics/CustomError";
import { S3_BUCKET_REGION } from "../../../../env-config";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { PutS3Object } from "../../../../utils/Functions/S3Storage/PutS3Object";
import { PolicyVersionStatus } from "../constants/PolicyVersionStatus";

/**
 * Creates a single policy version under a policy id
 * @param {string} policyID - id of policy object in database under which to create the new version
 * @param {string} description - policy version description
 * @param {string} status - policy version status
 * @param {string} approvedBy - policy version approved by
 * @param {string} approvalDate - policy version approval date
 * @param {FileList} files - the files to upload under this policy version
 * @param {number} version - the version number to use
 * @param {string} organizationID - organization under which to create the policy version
 * @param {string} bucketName - the bucket of the selected organization
 */
export const createPolicyVersion = async ({
  policyID,
  description,
  status,
  approvedBy,
  approvalDate,
  files,
  version = 0,
  organizationID,
  bucketName,
}) => {
  //[CHECK ARGUMENTS]
  //Check critical parameters for null values
  checkArguments(
    { organizationID, bucketName, files },
    {
      organizationID: { type: "string" },
      bucketName: { type: "string" },
      files: { isArray: true },
    },
  );
  //Check files parameter to have at least one file
  if (files.length === 0) {
    throw new InvalidParamError("files", "cannot have a length of zero");
  }

  //[CREATE CONSTANTS]
  const { createMutation: createPolicyVersion } = generateGraphql("PolicyVersion");
  const region = S3_BUCKET_REGION;
  const visibility = "private";
  let filesUploadedSuccessfully = 0;
  const { username: owner } = await Auth.currentUserInfo();

  //[UPLOAD SELECTED FILES]
  const fileUploadThreads = Array.from(files).map(async (file) => {
    const fileName = file.name;
    const key = generateS3ObjectKey(`policies/${fileName}`);

    await PutS3Object({
      file,
      objectKey: key,
      bucketName,
      organizationID: organizationID,
    }).then(() => {
      const fileInfo = {
        bucket: bucketName,
        region: region,
        key: key,
      };

      return API.graphql(
        graphqlOperation(createPolicyVersion, {
          input: {
            description,
            version: version || 1,
            status: status || PolicyVersionStatus.DRAFT,
            approvedBy,
            approvalDate,
            owner,
            visibility,
            createdAt: new Date(),
            file: fileInfo,
            ownerGroup: organizationID,
            policyVersionPolicyId: policyID ? policyID : undefined,
          },
        }),
      )
        .then(({ data }) => {
          filesUploadedSuccessfully++;
          InfoLogger(`Policy Version ${data.createPolicyVersion.id} was Successfully Created`);
        })
        .catch((err) => {
          ErrorLogger(`${file.name} failed to upload. ${JSON.stringify(err)}`);
        }); // If the upload fails.
    });
  });

  //Wait for all files to be recorded in the backend
  await Promise.all(fileUploadThreads);

  //Update toast based on file upload status
  if (filesUploadedSuccessfully !== files.length) {
    if (filesUploadedSuccessfully !== 0) {
      throw Error("Some files failed to upload");
    } else {
      throw Error("All files failed to upload");
    }
  }
};
