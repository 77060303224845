import React from "react";

import { useForm } from "../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import { SelectItemGrid } from "../../../../utils/GenericComponents/SelectItemGrid";
import CreateControlCategorySubControl from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/CreateControlCategorySubControl";
import RiskControlDetails from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";
import RiskControlGrid from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlGrid";
import { useControlCategoriesGrid } from "../../../Risk/RiskConfig/ControlCategories/hooks/useControlCategoriesGrid";

/**
 * Custom field displaying the risk control linked this vendor sub control
 * @param {object} item - the vendor control info with the risk control field populated
 * @param {string} organizationID - currently selected organization
 * @param {function} resetFunction - function to call when the link has changed and 'item' needs to be required
 * @return {JSX.Element}
 */
const VendorSubControlRiskControl = ({ item, organizationID, resetFunction }) => {
  const prepareSelectionForm = useForm({
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      controlCategoryID: {
        inputType: "item-select",
        label: "Risk Control Category",
        tooltip: "Select which risk control category contains the risk control you want to link.",
        required: true,
        itemSelectConfig: {
          grid: <SelectItemGrid organizationID={organizationID} gridHook={useControlCategoriesGrid} />,
          hideSelectButton: true,
        },
      },
    },
  });

  return (
    <NestedField
      typename={"RiskControl"}
      parentTypename={"VendorSubControl"}
      item={item}
      field={"riskControl"}
      idField={"riskControlID"}
      parentIdField={"vendorSubControlID"}
      displayField={"name"}
      prepareSelectionForm={prepareSelectionForm}
      detailsComponent={<RiskControlDetails />}
      form={<CreateControlCategorySubControl />}
      grid={<RiskControlGrid />}
      biDirectional={true}
      resetFunction={resetFunction}
    />
  );
};

export default withOrganizationCheck(VendorSubControlRiskControl);
