/**
 * Action options for the widget creation UI reducer
 * @type {{UPSERT_WIDGET_SERIES_INPUT: string, UPDATE_WIDGET_INPUT: string, UPSERT_WIDGET_SOURCE_INPUT: string}}
 */
export const createWidgetActions = {
  UPDATE_WIDGET_INPUT: "UPDATE_WIDGET_INPUT",
  MERGE_WIDGET_CONFIG_INPUT: "MERGE_WIDGET_CONFIG_INPUT",
  UPSERT_WIDGET_SERIES_INPUT: "UPSERT_WIDGET_SERIES_INPUT",
  MERGE_SERIES_FIELDS: "MERGE_SERIES_FIELDS",
  UPSERT_WIDGET_SOURCE_INPUT: "UPSERT_WIDGET_SOURCE_INPUT",
  DELETE_WIDGET_SERIES: "DELETE_WIDGET_SERIES",
  DELETE_WIDGET_SOURCE: "DELETE_WIDGET_SOURCE",
};
