import React from "react";

import { useModal } from "../../../../../hooks/views/useModal";

import ComplianceHistory from "./ComplianceHistory";

/**
 * @description Display control changes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ControlChanges = (props) => {
  const controlChangesModal = useModal(
    "Changes in Compliance",
    <ComplianceHistory control={props.item} />,
    <i
      className="icon-graph"
      style={{ marginLeft: "1em", cursor: "pointer" }}
      title="Click to show changes in compliance"
    />,
    {
      onModalButtonClick: props.resetFunction ? props.resetFunction : undefined,
    },
  );

  return controlChangesModal.modalButton;
};

export default ControlChanges;
