import { isNullOrUndefined } from "@rivial-security/func-utils";

export interface ApplyConstraintsParams {
  value: string;
  isNumeric: boolean;
  minLimit?: number;
  maxLimit?: number;
  step?: number;
}

/**
 * Based on the constraints determine at start formats the text values
 */
export const applyConstraints = ({
  value,
  isNumeric,
  minLimit,
  maxLimit,
  step,
}: ApplyConstraintsParams): number | string => {
  let newValue: number | string = value;

  try {
    if (isNumeric) {
      let numberValue = parseFloat(value);

      //Returning original value if parser cannot determine the number
      if (isNaN(numberValue)) {
        return value;
      }

      if (!isNullOrUndefined(minLimit)) {
        minLimit = minLimit!;
        numberValue = Math.max(minLimit, numberValue);
      }
      if (!isNullOrUndefined(maxLimit)) {
        maxLimit = maxLimit!;
        numberValue = Math.min(maxLimit, numberValue);
      }

      if (!isNullOrUndefined(step)) {
        step = step!;
        numberValue = Math.round(numberValue / step) * step;
      }

      newValue = numberValue;
    }
  } catch (e) {
    return value;
  }
  return newValue;
};
