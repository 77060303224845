import { formattedPercent } from "@rivial-security/func-utils";

import { completionType } from "./completionType";

/**
 * Checks each sub-control of a control category for completion state of a certain type
 * @param {string} property - the boolean property name of a subcontrol in the category
 * @param {VendorControlCategory} category - the category to check the completion status of
 * @returns {{percentage: string|number, status: string}}
 */
export const getCategoryCompletion = ({ property, category }) => {
  //Check data
  if (!Array.isArray(category?.subControls?.items)) {
    return { status: completionType.NONE, percentage: 0 };
  }

  //Iterate over all controls check for below conditions
  let numberComplete = 0;
  for (const control of category.subControls.items) {
    if (control?.[property] != null && control[property] === true) {
      numberComplete++;
    }
  }

  //Return correct completion type
  const totalCount = category.subControls.items.length;
  if (totalCount === numberComplete) {
    return { status: completionType.FULLY, percentage: formattedPercent(1) };
  } else if (numberComplete > 0) {
    return {
      status: completionType.PARTIALLY,
      percentage: formattedPercent(numberComplete / totalCount),
    };
  } else {
    return { status: completionType.NONE, percentage: formattedPercent(0) };
  }
};
