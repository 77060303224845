import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

import { keywordSort } from "@rivial-security/func-utils";

import HighlightedText from "../../../../utils/GenericComponents/HighlightedText";
import { handleToggle } from "../functions/handleToggle";
import { handleToggleAll } from "../functions/handleToggleAll";
import { numberOfChecked } from "../functions/numberOfChecked";

/**
 * Used for each side of the Transfer List
 *
 * @param title
 * @param items
 * @param classes
 * @param handleToggle
 * @param handleToggleAll
 * @param numberOfChecked
 * @param searchInput
 * @param checked
 * @param {function} [listTitleAdapter] - a function that updates the text in the list item
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CheckboxList = ({
  title,
  items = [],
  classes,
  searchInput,
  checkedItems = [],
  setCheckedItems,
  listTitleAdapter,
}) => (
  <Card>
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <Checkbox
          onClick={() => handleToggleAll(items, checkedItems, setCheckedItems)}
          checked={numberOfChecked(items, checkedItems) === items.length && items.length !== 0}
          indeterminate={
            numberOfChecked(items, checkedItems) !== items.length && numberOfChecked(items, checkedItems) !== 0
          }
          disabled={items.length === 0}
          inputProps={{ "aria-label": "all items selected" }}
        />
      }
      title={title}
      subheader={`${numberOfChecked(items, checkedItems)}/${items.length} selected`}
    />
    <Divider />
    <List className={classes.list} dense component="div" role="list">
      {keywordSort(items, searchInput, "name", "desc", listTitleAdapter).map((item) => {
        const labelId = `transfer-list-all-item-${item?.id}-label`;

        return (
          <ListItem
            key={item.id}
            role="listitem"
            button
            onClick={() => handleToggle(item, checkedItems, setCheckedItems)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checkedItems.findIndex((a) => a.id === item.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={
                <HighlightedText highlight={searchInput} text={listTitleAdapter ? listTitleAdapter(item) : item.name} />
              }
            />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  </Card>
);

export default CheckboxList;
