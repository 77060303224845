import React, { useEffect } from "react";
import { Button, Collapse } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import Json from "../../../../utils/GenericComponents/Json";
import { useCsvImporter } from "../../../../views/CsvImporters/hooks/useCsvImporter";
import { useBoolean } from "../../../functional/useBoolean";

/**
 * Allows the user to input a CSV and get the input converted to an array of objects in the form
 * @param {function} onChange - the function to call when the data is ready
 * @param {string }defaultValue - the default value to use
 * @param {string} value - the value to use
 * @param {object} spreadsheetConfig - the configuration for the spreadsheet
 * @returns {JSX.Element}
 * @constructor
 */
const CustomSpreadsheetInput = ({ onChange, defaultValue, value = defaultValue, spreadsheetConfig = {} }) => {
  const csvImporter = useCsvImporter({
    headerObjects: spreadsheetConfig.headerObjects || [],
    title: spreadsheetConfig.title || "CSV Input",
    passThroughDuplicateScanner: false,
  });
  const { dataJSONReady, display, reset } = csvImporter;

  const [visible, setVisible] = useBoolean(false);

  useEffect(() => {
    if (dataJSONReady) {
      setVisible(false);
    }

    onChange && onChange(dataJSONReady);
  }, [dataJSONReady]);

  return (
    <div>
      <div>
        {!isNullOrUndefined(dataJSONReady) && (
          <span>
            <Json data={dataJSONReady} />
            <Button onClick={reset}>reset</Button>
          </span>
        )}
      </div>
      {!visible && !dataJSONReady && (
        <Button color={"link"} onClick={() => setVisible(!visible)}>
          Add CSV Data
        </Button>
      )}
      <Collapse isOpen={visible}>{display}</Collapse>
    </div>
  );
};

export default CustomSpreadsheetInput;
