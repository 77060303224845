import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useSubscription } from "../../../../../../../hooks/graphql/useSubscription";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { useQueryCard } from "../../../../../../../hooks/views/useQueryCard";
import CreateNewRisk from "../../../../../RiskConfig/KeyRiskIndicators/components/AddRisksToOrganization/CreateNewRisk";

const {
  listQuery: listRisks,
  onDelete: onDeleteRisk,
  onUpdate: onUpdateRisk,
  onCreate: onCreateRisk,
} = generateGraphql(
  "Risk",
  ["name", "description", "annualRateOfOccurrence", "controlCategoryID", "defaultRiskMapping", "threatLinks"],
  {
    defaultRiskMapping: `{ dataBreach systemDisruption facilityDisruption fraud malware vendor compliance  }`,
    threatLinks: `(limit: 1000) { items { id threat { id } } }`,
  },
);

export const useListKeyRiskIndicators = ({
  module = modules.RISK,
  resource = resources.KEY_RISK_INDICATOR,
  disableRoleChecking = false,
  ...props
}) => {
  const subscriptionHook = useSubscription({
    onCreateSubscription: onCreateRisk,
    onDeleteSubscription: onDeleteRisk,
    onUpdateSubscription: onUpdateRisk,
    organizationID: props.organizationID,
    module,
    resource,
  });

  const queryConfig = {
    query: listRisks,
    organizationID: props.organizationID,
    subscriptionHook: subscriptionHook,
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const createKRIModal = useModal(
    "Add a new Key Risk Indicator",
    <CreateNewRisk
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      system={props.system}
      organizationID={props.organizationID}
    />,
    <Button
      size="sm"
      color="ghost-success"
      className="btn-pill"
      disabled={!checkPermissionsHook.resource.create}
      title={
        checkPermissionsHook.resource.create
          ? "Create a new Key Risk Indicator"
          : "You don't have Create Permissions on Resource: Key Risk Indicator"
      }
    >
      <i className="icon-plus" /> Add New Key Risk Indicator
    </Button>,
  );

  const dataCardConfig = {
    fields: ["name"],
    header: "Key Risk Indicators",
    headerButtons: [createKRIModal.modalButton],
    config: {
      limit: 25,
    },
  };

  const queryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    queryCard.setSortField("name", "asc");
  }, []);

  return { ...queryCard };
};
