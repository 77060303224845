import React, { useState } from "react";

import { GetQuery, ItemMutation } from "@rivial-security/appsync-utils";
import { enumToDropdownData, isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { useForm } from "@hooks/views/useForm";
import { ErrorLogger } from "@utils/EventLogger";
import { useCustomFieldsTable } from "@views/Compliance/Controls/ControlSets/hooks/useCustomFieldsTable";
import QuestionnaireFieldMapping from "@views/OrganizationManager/Questionnaires/components/CustomFields/QuestionnaireFieldMapping";
import { linkQuestionnaireToSystems } from "@views/OrganizationManager/Questionnaires/functions/linkQuestionnaireToSystems";
import { useSystemDataGrid } from "@views/Risk/Systems/hooks/useSystemDataGrid";

import QuestionnaireDataGrid from "../components/QuestionnaireDataGrid";
import { questionnaireStatus } from "../constants/questionnaireStatus";

/**
 * Create form for the Questionnaire type
 * @param {string} organizationID - the organization in which to create the new questionnaire
 * @param {string} moduleInit - platform module to use for role checking
 * @param {string} resourceInit - platform module to use for role checking
 * @param {object} [itemInit] - the item to update if need to update a questionnaire through the form
 * @param {object} props - any additional props to pass down to the useFormHook
 * @returns {{input: {}, display: *, setInput: Function}}
 */
export const useCreateQuestionnaire = ({
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  item: itemInit,
  ...props
}) => {
  const typename = resources.QUESTIONNAIRE;
  const module = moduleInit || modules.ORGANIZATION_MANAGER;
  const resource = resourceInit || resources.QUESTIONNAIRE;

  const [fieldMapping, setFieldMapping] = useState({});

  const systemDataGrid = useSystemDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
  });

  const selectedSystems = systemDataGrid?.selectedData;

  const item = {
    ...(itemInit || {}),
  };

  // Create a new custom resource type for the questionnaire
  const createCustomResourceType = async (input) => {
    const { createMutation: createResourceTypeMutation } = generateGraphql(
      "CustomResourceType",
      ["name", "customFields"],
      { customFields: "{name type}" },
    );
    const customResourceType = await ItemMutation({
      mutation: createResourceTypeMutation,
      input: {
        name: input?.name || "Questionnaire Resource Type",
        description: "Automatically created to store responses to a Questionnaire.",
        customFields: customFieldsTable.customFields,
        ownerGroup: organizationID,
      },
    });

    if (customResourceType?.id) {
      return customResourceType.id;
    } else {
      ErrorLogger("Failed to create custom resource type for questionnaire");
    }
  };

  const submitFunction = async (input) => {
    // Remove the questions from the input as they are stored in the custom resource type
    delete input.questions;
    delete input.templateQuestionnaireID;

    const createQuestionnaire = async (input) => {
      const { createMutation: createQuestionnaireMutation } = generateGraphql("Questionnaire");

      return await ItemMutation({
        mutation: createQuestionnaireMutation,
        input: {
          ...input,
          fieldMapping: JSON.stringify(fieldMapping),
          ownerGroup: organizationID,
        },
      });
    };

    if (isNonEmptyArray(selectedSystems)) {
      for (const system of selectedSystems) {
        const customResourceTypeID = await createCustomResourceType(input);

        const newQuestionnaire = await createQuestionnaire({
          ...input,
          name: `${input.name} - ${system.name}`,
          customResourceTypeID,
        });

        await linkQuestionnaireToSystems({
          questionnaireID: newQuestionnaire?.id,
          selectedSystems: [system],
          organizationID,
        });
      }
    } else {
      const customResourceTypeID = await createCustomResourceType(input);
      await createQuestionnaire({
        ...input,
        customResourceTypeID,
      });
    }

    props?.resetFunction();
  };

  const customFieldsTable = useCustomFieldsTable({
    disableRoleChecking: true,
    enableBackendUpdate: false,
    forceTableVisibility: true,
    nameFieldLabel: "Question",
    customFieldName: "question",
    customFieldParentName: "questionnaire",
  });

  const fieldConfig = [
    {
      id: "add-general-information",
      stepName: "Add General Information",
      fields: {
        name: {
          label: "Name",
          required: true,
        },
        description: {
          label: "Description",
        },
        status: {
          label: "Status",
          inputType: "dropdown",
          defaultValue: questionnaireStatus.SCHEDULED,
          dropdownConfig: {
            data: enumToDropdownData({ ENUM: questionnaireStatus }),
          },
          isHidden: true, // all questionnaires start as scheduled by default
        },
        startDate: {
          label: "Start Date",
          inputType: "date",
        },
        endDate: {
          label: "End Date",
          inputType: "date",
          validationFunction: (input, formInput) => {
            const { startDate, endDate } = formInput || {};
            if (!startDate || !endDate) return true;
            return new Date(startDate) < new Date(endDate);
          },
          validationText: "End date must come after start date",
        },
        allowUnauthenticatedSubmissions: {
          required: true,
          label: "Allow Guest Submissions",
          inputType: "switch",
          defaultValue: false,
        },
        systems: {
          label: "Link Systems",
          inputType: "custom",
          removeFromInput: true,
          customConfig: {
            component: (
              <div style={{ height: "30em", display: "flex", flexDirection: "column" }}>{systemDataGrid?.display}</div>
            ),
          },
        },
      },
    },
    {
      id: "create-questions",
      stepName: "Create Questions",
      fields: {
        templateQuestionnaireID: {
          label: "Duplicate Questionnaire",
          tooltip: "If you would like to copy questions from an existing questionnaire, select it here.",
          inputType: "item-select",
          itemSelectConfig: {
            grid: <QuestionnaireDataGrid organizationID={organizationID} />,
          },
        },
        questions: {
          label: "Questions",
          tooltip: "Add questions for users to answer and their input constraints.",
          createItemComponent: customFieldsTable.createButton,
          inputType: "custom",
          customConfig: {
            component: (
              <>
                {customFieldsTable.display}
                {isNonEmptyArray(systemDataGrid?.selectedData) && (
                  <QuestionnaireFieldMapping
                    fieldMapping={fieldMapping}
                    setFieldMapping={setFieldMapping}
                    questionnaireFields={customFieldsTable?.customFields}
                    organizationID={organizationID}
                  />
                )}
              </>
            ),
          },
        },
      },
    },
  ];

  const form = useForm({
    item,
    organizationID,
    typename,
    module,
    resource,
    submitFunction,
    fieldConfig,
    ...props,
  });

  //When a template questionnaire is selected, copy the questions from it
  useSetAsyncData({
    getData: async () => {
      const templateQuestionnaireID = form?.input?.templateQuestionnaireID;
      if (!templateQuestionnaireID) {
        return;
      }

      const { getQuery: getTemplateQuestionnaireQuery } = generateGraphql(
        "Questionnaire",
        ["customResourceType", "fieldMapping"],
        {
          customResourceType: `{
            id
            name
            description
            customFields {
              name
              type
              description
              options { label value }
              multipleSelect { label value }
              numberSettings { min max step format }
              required
            }
          }`,
        },
      );

      return await GetQuery({
        query: getTemplateQuestionnaireQuery,
        variables: {
          id: templateQuestionnaireID,
        },
      });
    },
    setData: (templateQuestionnaire) => {
      const templateCustomFields = templateQuestionnaire?.customResourceType?.customFields ?? [];
      if (isNonEmptyArray(templateCustomFields)) {
        customFieldsTable.setCustomFields(templateCustomFields);
      }
      if (templateQuestionnaire?.fieldMapping) {
        setFieldMapping(JSON.parse(templateQuestionnaire?.fieldMapping ?? "{}"));
      }
    },
    dependencies: [form?.input?.templateQuestionnaireID],
  });

  return { ...form };
};
