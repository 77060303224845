import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { compose } from "react-apollo";
import { Input, Spinner } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

const { listQuery: listReviewers, updateMutation: updateReviewer } = generateGraphql(
  "Reviewer",
  ["status", "reviewedDate", "pointOfContact"],
  {
    pointOfContact: `{ id ownerGroup firstName lastName }`,
  },
);

/**
 * Author: Jacob Blazina
 * Created At: 12/17/2019
 * Edits:
 *
 * Description: The checkbox component used by a point of contact to Acknowledge a security policy
 */
const ReviewPolicyCheckbox = (props) => {
  const [loading, setLoading] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [reviewedDate, setReviewedDate] = useState(props.reviewer && props.reviewer.reviewedDate);

  useEffect(() => {
    if (props.reviewer && props.reviewer.status && props.reviewer.status === "reviewed") {
      setReviewed(true);
    }
  }, [props.reviewer]);

  const check = () => {
    if (!reviewed) {
      setLoading(true);

      props
        .updateReviewer({
          input: {
            id: props.reviewer.id,
            status: "reviewed",
            reviewedDate: new Date(),
          },
        })
        .then(({ data }) => {
          setLoading(false);
          setReviewed(true);
          setReviewedDate(data.updateReviewer && data.updateReviewer.reviewedDate);
        })
        .catch(() => alert("Oops. Something went wrong. Please contact a Rivial Administrator for help."));
    }
  };

  const contact = props.reviewer && props.reviewer.pointOfContact ? props.reviewer.pointOfContact : {};

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "2em", height: "2em", marginRight: "1em" }}>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <Input
            disabled={reviewed}
            checked={reviewed}
            color="primary"
            style={{ width: "2em", height: "2em", cursor: "pointer", padding: 0, margin: 0, position: "static" }}
            type="checkbox"
            onChange={() => check()}
          />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <p style={{ fontSize: "1rem", margin: 0 }}>
          {reviewed
            ? `This security policy was read and acknowledged by ${contact.firstName} ${contact.lastName} on ${new Date(
                reviewedDate,
              ).toLocaleString()}`
            : `I, ${contact.firstName} ${contact.lastName}, acknowledge that I have read and understand this security program policy.`}
        </p>
      </div>
    </div>
  );
};

ReviewPolicyCheckbox.propTypes = {
  reviewer: PropTypes.object.isRequired,
};

ReviewPolicyCheckbox.defaultProps = {
  reviewer: {},
};

export default compose(graphqlMutation(gql(updateReviewer), gql(listReviewers), "Reviewer"))(ReviewPolicyCheckbox);
