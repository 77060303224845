import React, { useContext, useEffect, useRef, useState } from "react";

import { isNullOrUndefined, updateItemById } from "@rivial-security/func-utils";
import { generateGraphql, generateGraphqlFields } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { mergeAdditionalFields } from "../../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { useGridCard } from "../../../../../../hooks/views/useGridCard";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import EnabledDisabledButtonGroupField from "../../../../../../utils/CustomFields/EnabledDisabledButtonGroupField";
import TagFilterMenu from "../../../../../../utils/FilterMenus/TagFilterMenu";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import { objectListSimilarByField } from "../../../../../../utils/Functions/objectListSimilarByField";
import { hideFilterMenuUI } from "../../../../../../utils/Functions/Views/grid/hideFilterMenuUI";
import { tagOperators } from "../../../../../../utils/Tags/components/TagFilterOperatorButtonGroup";
import TagsField from "../../../../../../utils/Tags/customFields/TagsField";
import { createControlTagLink } from "../../../../../../utils/Tags/functions/createTagLinks/createControlTagLink";
import { filterItemsBasedOnTagOperator } from "../../../../../../utils/Tags/functions/filterItemsBasedOnTagOperator";
import ControlDetails from "../../components/ControlDetails";
import ControlNotes from "../../components/ControlNotes";
import CreateControl from "../../components/CreateControl";
import IsDisabled from "../../customFields/IsDisabled";
import { deleteControl } from "../../functions/deleteControl";
import { generateGridCustomFields } from "../../functions/generateGridCustomFields";

import { getControlNestedFields } from "./constants";
import { convertTags } from "./functions/convertTags";
import { useControlGridData } from "./hooks/useControlGridData";

/**
 * Custom hook to display a list of controls
 * @param {string} organizationID - the organization id from the database of current selected organization
 * @param {string} controlFrameworkID - the control framework identifier from the database for which to show controls and display custom fields
 * @param {string[]} queryFields - filter which fields to display in the table by not retrieving certain properties
 * @param {string} nestedFields - provide this argument to retrieve control framework connection information as needed
 * @param {object} queryConfig - use if need to customize the get query for the grid completely
 * @param {object} gridConfig - grid settings/props see useGrid.js
 * @param {object} cardConfig - grid container settings
 * @param {object[]} otherFields - if additional custom fields are appended use this to declare the component used to display them and the header title
 * @param {boolean} isTemplate=false - if TRUE will adapt the shown UI only show parts of controls resource that are copied as part of a template
 * @param {boolean} [isInAudit=false] - if TRUE will turn off automatic query for controls under a control framework, to show only control audit links
 * @return {{customFrameworkFields: *[], gridDisplay: *, data: *, tagFilterApplied: boolean, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: *, isLoading: boolean, createResourceComponent: "preserve" | "react" | "react-native", ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: function(): void, customFrameworkFieldNames: *[], fields: Object[], setItemsToCheck: (value: (((prevState: undefined) => undefined) | undefined)) => void, selectedItems: []}}
 */
export const useControlGrid = ({
  organizationID,
  disableRoleChecking = false,
  controlFrameworkID,
  queryFields = [
    "statementNumber",
    "name",
    "inPlace",
    "isDisabled",
    "tags",
    "notes",
    "controlControlSetId",
    "controlSet",
    "customFieldData",
  ],
  nestedFields,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  otherFields = [],
  isTemplate = false,
  isInAudit = false,
  ...props
}) => {
  ///[GENERAL SETUP]
  const context = useContext(OrganizationContext);
  // - Constants
  const typename = "Control";
  const module = isTemplate ? modules.ADMINISTRATOR : modules.COMPLIANCE;
  const resource = isTemplate ? resources.CONTROL_TEMPLATE : resources.CONTROL;
  const originOrganizationID = context?.role?.ownerGroup;
  // - Queries
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["customFields"], {
    customFields: `{name type}`,
  });
  nestedFields = getControlNestedFields(nestedFields);
  const { updateMutation: controlUpdateMutation } = generateGraphql(typename, queryFields, nestedFields);
  // - Permissions
  const roleConfig = {
    module,
    resource,
  };

  const listControlsByControlSet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
         ${generateGraphqlFields(queryFields, nestedFields)}
      }
      nextToken
    }
  }
`;

  //[STATE]
  const [fields, setFields] = useState([{ name: "Loading..." }]);
  const [customFrameworkFieldNames, setCustomFrameworkFieldNames] = useState([]);
  const [customFrameworkFields, setCustomFrameworkFields] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  ///[GRID SETUP]
  //- tag filter menu values
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsOperator, setTagsOperator] = useState(tagOperators.OR);
  const [tagFilterApplied, setTagFilterApplied] = useState(false);
  const selectedTagsRef = useRef([]);
  const tagsOperatorRef = useRef(tagsOperator);
  useEffect(() => {
    selectedTagsRef.current = selectedTags;
    tagsOperatorRef.current = tagsOperator;
    if (selectedTags.length > 0) {
      setTagFilterApplied(true);
    } else {
      setTagFilterApplied(false);
    }
  }, [selectedTags, tagsOperator]);

  // - define which FIELDS are shown and the components to use for them
  useEffect(() => {
    let newFields = [
      {
        name: "statementNumber",
        friendlyName: "Statement Number",
        // Reference: https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
        sortComparer: (a, b) => {
          if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
            return 0; // a and b equal , if both are undefined
          } else if (isNullOrUndefined(a)) {
            return 1; //sort b before a, if a is undefined
          } else if (isNullOrUndefined(b)) {
            return -1; //sort a before b, if b is undefined
          }

          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        },
      },
      {
        name: "name",
      },
      {
        name: "inPlace",
        friendlyName: "Status",
        component: (
          <EnabledDisabledButtonGroupField
            config={{
              typename,
              module,
              resource,
              disableRoleChecking,
              field: "inPlace",
              rightOffText: "Not in Place",
              leftOnText: "In Place",
            }}
          />
        ),
        disablePropagation: true,
      },
      {
        name: "isDisabled",
        friendlyName: "Include in Audits",
        component: <IsDisabled module={module} resource={resource} disableRoleChecking={disableRoleChecking} />,
        disablePropagation: true,
        visible: false,
      },
      {
        name: "tags",
        type: "string",
        allowSorting: false,
        visible: !isTemplate,
        component: (
          <TagsField
            module={module}
            resource={resource}
            createLinkFunction={createControlTagLink}
            organizationID={isTemplate ? originOrganizationID : organizationID}
          />
        ),
        exportConvert: convertTags,
        filterTemplate: (props) => {
          return (
            <TagFilterMenu
              tagsOperatorRef={tagsOperatorRef}
              selectedTagsRef={selectedTagsRef}
              setTagsOperator={setTagsOperator}
              setSelectedTags={setSelectedTags}
              organizationID={organizationID}
            />
          );
        },
        disablePropagation: true,
      },
      {
        name: "notes",
        friendlyName: "Notes",
        component: <ControlNotes />,
        disablePropagation: true,
        visible: false,
      },
      {
        name: "tagList",
        visible: false,
        showInColumnChooser: false,
      },
    ];
    mergeAdditionalFields({ additionalFields: otherFields, fields: newFields });

    newFields = newFields.concat(
      generateGridCustomFields({
        module,
        resource,
        customFields: customFrameworkFields,
      }),
    );

    //TODO: check if this check is necessary
    //Check if change in fields is necessary (prevents infinite loop)
    if (!objectListSimilarByField({ listOne: fields, listTwo: newFields })) {
      setFields(newFields);
    }
  }, [customFrameworkFields]);

  // - define the default SORTING setting for the grid
  const sortSettings = {
    columns: [{ field: "statementNumber", direction: "Ascending" }],
  };

  // - updates custom fields configuration for selected control set
  const updateCustomFields = async () => {
    //Retrieve custom fields for the new control framework
    const controlSetCustomFieldNames = [];
    let newCustomFields = [];
    try {
      // - retrieve control framework custom fields from db
      let controlFramework;
      if (controlFrameworkID) {
        controlFramework = await ItemQuery(getControlFrameworkQuery, controlFrameworkID);
      }

      // - check for the custom fields property to be present
      if (controlFramework?.customFields) {
        newCustomFields = controlFramework.customFields;
      }

      // - get all control set custom field names
      if (newCustomFields && Array.isArray(newCustomFields)) {
        for (const customField of controlFramework.customFields) {
          if (customField?.name) {
            controlSetCustomFieldNames.push(customField.name);
          }
        }
      }
    } catch (e) {
      ErrorLogger("Failed to get custom control framework fields in control grid!", e);
    }
    setCustomFrameworkFields(newCustomFields);
    setCustomFrameworkFieldNames(controlSetCustomFieldNames);
  };

  // - Updates the custom field configuration when controlFramework changes
  useEffect(() => {
    if (!isNullOrUndefined(controlFrameworkID) && controlFrameworkID !== "") {
      updateCustomFields();
    }
  }, [controlFrameworkID]);

  // - define the grid CARD settings
  cardConfig = {
    title: "Compliance Controls",
    headerIcon: "icon-list",
    enableSticky: true,
    ...cardConfig,
  };

  // - define general GRID settings as well as create/update/delete/details functions to use
  gridConfig = {
    // graphql operation functions
    deleteFunction: deleteControl,
    createItemModalHeader: <div>{`Create a new Control${isTemplate ? " Template" : ""}`}</div>,
    createResourceComponent: <CreateControl controlFrameworkID={controlFrameworkID} organizationID={organizationID} />,
    updateMutation: controlUpdateMutation,

    // details component setup
    typename: "Control",
    detailsComponent: <ControlDetails tableDisplay={true} organizationID={organizationID} />,
    enableQuickDetails: true,
    config: {
      width: "95vw",
    },

    actionComplete: (args) => hideFilterMenuUI({ args, hideAllFields: ["tags"] }),

    // field and routes settings
    fields,
    sortSettings,
    route: isTemplate ? `#/admin_panel/controls/` : `#/continuous_compliance/controls/`,
    columnSize: 8,

    // option flags
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    enablePrint: true,
    enableMenu: true,
    enableNoteIcon: true,

    // persistence settings
    persistenceUUID: `c10661db-eaa6-4b4f-a7fc-60bed75b9egssd${controlFrameworkID}`,

    //On unfiltered item change, update the data (allows for proper filtering of tags without grid refresh)
    updateItemById: (item) => {
      updateItemById(setUnfilteredData, item);
    },

    organizationID,
    disableRoleChecking,
    duplicationSettings,

    helpCenterUrl: "https://rivial-helpcenter.scrollhelp.site/hcenter/Controls.1252589580.html",
    ...gridConfig,
    ...props,
  };

  queryConfig = {
    query: null,
    ...queryConfig,
  };

  const gridCard = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  //handles active filtering by tags
  useEffect(() => {
    filterItemsBasedOnTagOperator({
      grid: gridCard,
      unfilteredData,
      selectedTags,
      tagsOperator,
    });
  }, [selectedTags, tagsOperator, unfilteredData]);

  // - hydrates the controls gridCard manually
  useControlGridData({
    organizationID,
    customFrameworkFieldNames,
    controlFrameworkID,
    gridCard,
    query: !isInAudit ? listControlsByControlSet : undefined,
    //Upon switching control frameworks update the unfiltered controls data
    newDataCallback: (data) => setUnfilteredData(data),
  });

  return {
    ...gridCard,
    customFrameworkFields,
    customFrameworkFieldNames,
    tagFilterApplied,
    setUnfilteredData,
  };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates Controls, preserves Evidence linking. Does not preserve Recommendations, Audits, or Control Changes.",
  fields: [
    "statementNumber",
    "name",
    "controlControlSetId",
    "customFieldData",
    "evidences",
    "keyPerformanceIndicators",
    "inPlace",
    "isDisabled",
    "notes",
    "tags",
  ],
  nestedFields: {
    evidences: `(limit: 1000) { items { id ownerGroup evidenceID controlID evidence { id } control { id } } }`,
    keyPerformanceIndicators: `(limit: 1000) { items { id controlID keyPerformanceIndicatorID ownerGroup keyPerformanceIndicator { id } control { id } } }`,
    tags: `(limit: 1000) { items { __typename id ownerGroup controlID tagID control { id } tag { id }  } }`,
    notes: `{author content timeStamp ownerGroup observationID}`,
  },
  primaryField: "name",
  connectionAdaptor: {
    evidences: {
      connectionTypename: "ControlEvidenceLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "evidenceID",
      childConnectionField: "evidence",
    },
    keyPerformanceIndicators: {
      connectionTypename: "KPIComplianceControlLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "keyPerformanceIndicatorID",
      childConnectionField: "keyPerformanceIndicator",
    },
    tags: {
      connectionTypename: "ControlTagLink",
      itemConnectionIDField: "controlID",
      childConnectionIDField: "tagID",
      childConnectionField: "tag",
    },
  },
};
