import React from "react";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description A grid for displaying Non-Standard Risk Controls
 * @param organizationID - Organization ID
 * @param data - Data to display in the grid
 * @param cardConfig - Configuration for the card
 * @param gridConfig - Configuration for the grid
 * @param roleConfig - Role configuration
 * @param queryConfig - Query configuration
 * @return {JSX.Element}
 * @constructor
 */
const NonStandardRiskControlGrid = ({
  organizationID,
  data,
  cardConfig,
  gridConfig,
  roleConfig,
  queryConfig,
  resetFunction,
}) => {
  const nonStandardControlSystemsGrid = useDataGrid({
    organizationID,
    data,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
    ...queryConfig,
    resetFunction,
  });
  return (
    <div
      style={{
        minHeight: "40em",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {nonStandardControlSystemsGrid.gridDisplay}
    </div>
  );
};
export default NonStandardRiskControlGrid;
