import { ReactElement } from "react";
import { Badge } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getPriorityScaleSegment } from "./getPriorityScaleSegment";
import { PriorityScaleSegment } from "./types";

export interface PriorityBadgeProps {
  priority?: number | null;
}

export const PriorityBadge = ({ priority }: PriorityBadgeProps): ReactElement => {
  const prioritySegment: PriorityScaleSegment = getPriorityScaleSegment({ priority });

  return (
    <Badge
      color={prioritySegment.color}
      title={`Priority Score: ${!isNullOrUndefined(priority) ? priority : "Unknown"}`}
    >
      {prioritySegment.text}
    </Badge>
  );
};
