"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfMetricSeries = void 0;
/**
 * Retrieve the total number of series in this widget
 * @param config - all custom widget item information
 */
const getNumberOfMetricSeries = ({ config } = {}) => {
    if (!config) {
        return 0;
    }
    const { seriesConfig } = config;
    if (!Array.isArray(seriesConfig)) {
        return 0;
    }
    return seriesConfig?.length ?? 0;
};
exports.getNumberOfMetricSeries = getNumberOfMetricSeries;
