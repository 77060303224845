import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";

import { handleDeleteClick } from "../../GenericComponents/GenericDeleteButton";
import { deleteTagLink } from "../functions/deleteTagLink";

/**
 * @description This component is used to display a single tag.
 * @param {object} tag - the tag item
 * @param {string} tag.id - the id of the tag
 * @param {string} tag.name - the name of the tag
 * @param {string} tag.description - the description of the tag
 * @param {string} tag.fontColor - the font color of the tag
 * @param {string} tag.backgroundColor - the background color of the tag
 * @param {object} [item] - can be optionally used instead of the 'tag' param
 * @param {function} [selectTag] - optional onClick handler for selecting a tag
 * @param {function} [deselectTag] - optional onClick handler for deselecting a tag
 * @param {boolean} [isSelectedInit = false] - optional boolean for the default selection state
 * @param {boolean} [enableDelete = false] - optional boolean for enabling the delete UI
 * @param {function} [deleteLinkFunction] - optional function to override the built-in delete function
 * @param {function} [deleteCallback] - optional callback function when using the delete UI
 * @param {boolean} [isDisabled] - optional external flag for disabling the UI from a parent
 * @returns {JSX.Element}
 * @constructor
 */
const Tag = ({
  tag,
  item,
  selectTag,
  deselectTag,
  isSelected: isSelectedInit = false,
  enableDelete = false,
  deleteLinkFunction,
  deleteCallback,
  isDisabled,
}) => {
  const {
    id,
    name = "Error Initializing Tag",
    description = "Error Initializing Tag",
    fontColor = "#070000",
    backgroundColor = "#CBD3F9",
  } = tag || item || {};

  const [isSelected, setIsSelected] = useState(isSelectedInit);

  /**
   * Handles select/deselect behavior if the select and deselect functions are available
   */
  const handleClick = () => {
    if (!isDisabled) {
      if (deselectTag && isSelected) {
        deselectTag(tag);
        setIsSelected(false);
      } else if (selectTag && !isSelected) {
        selectTag(tag);
        setIsSelected(true);
      }
    }
  };

  /**
   * Handles delete behavior if deletion is enabled
   */
  const handleDelete = async (e) => {
    if (!isDisabled) {
      e.stopPropagation();
      const tagLinkID = item?.tagLinkID || tag?.tagLinkID;
      const tagLinkTypename = item?.tagLinkTypename || tag?.tagLinkTypename;
      handleDeleteClick(
        {
          id: tagLinkID,
          __typename: tagLinkTypename,
          tagID: tag?.id || item?.id,
        },
        deleteLinkFunction || deleteTagLink, // if a 'deleteLinkFunction' is passed, use this instead
        true,
      ).then((res) => {
        res && deleteCallback(item || tag);
      });
    }
  };

  const linkRef = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, [linkRef.current]);

  return (
    <>
      <Badge
        id={`tag${id}`}
        onClick={handleClick}
        style={{
          backgroundColor,
          color: fontColor,
          marginLeft: "1px",
          marginRight: "1px",
          marginBottom: "3px",
          opacity: isSelected ? 1.0 : 0.4,
          cursor: !isDisabled && "pointer",
          padding: "0em",
        }}
        ref={linkRef}
        title={description}
      >
        <ButtonGroup size="sm">
          <Button
            style={{
              backgroundColor: "transparent",
              color: fontColor,
              border: "none",
              opacity: 1.0,
            }}
            disabled
          >
            {name}
          </Button>
          {!isDisabled && enableDelete && (
            <Button
              size="sm"
              style={{ display: "inline-block", marginRight: "0.2em" }}
              close
              onClick={async (e) => await handleDelete(e)}
            />
          )}
        </ButtonGroup>
      </Badge>
      {ready && id && (
        <UncontrolledTooltip placement="auto" target={linkRef.current}>
          {description}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default Tag;
