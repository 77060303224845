import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { useGridCard } from "../../../../hooks/views/useGridCard";
import CreateThreat from "../components/CreateThreat";
import ThreatDetails from "../components/ThreatDetails";
import { deleteThreat } from "../functions/deleteThreat";

/**
 * @deprecated - use useThreatDataGrid instead
 * Displays a list of Threats for an Org
 * @param organizationID
 * @param additionalFields
 * @param cardConfig
 * @param queryConfig
 * @param gridConfig
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 */
export const useThreatGrid = ({
  organizationID,
  additionalFields = [],
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  module = modules.INCIDENT_RESPONSE,
  resource = resources.THREAT,
  disableRoleChecking = false,
  ...props
}) => {
  const route = "#/risk/threats/";

  cardConfig = {
    title: "Threats",
    headerIcon: "icon-target",
    enableSticky: true,
    ...cardConfig,
  };

  const { listQuery } = generateGraphql("Threat", ["name"]);

  queryConfig = {
    organizationID,
    query: listQuery,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    fields,
    route,
    detailsComponent: <ThreatDetails tableDisplay={true} />,
    detailsType: DETAILS_TYPES.PANEL,
    detailsTitle: "Threat Details",
    persistenceUUID: "e329dcd6-1df4-466c-9a56-1523b1fb939c",
    createResourceComponent: <CreateThreat />,
    typename: "Threat",
    columnWidth: 8,
    enableContextMenu: true,
    enableSearch: true,
    deleteFunction: deleteThreat,
    sortSettings: {
      columns: [{ field: "name", direction: "Ascending" }],
    },
    module,
    resource,
    disableRoleChecking,
    ...gridConfig,
    ...props,
  };

  const grid = useGridCard({
    queryConfig,
    cardConfig,
    roleConfig,
    gridConfig,
  });

  return { ...grid };
};
