import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { TargetVulnerabilityHistoryType, TargetVulnerabilityStatus } from "@rivial-security/schema-types";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a single many-to-many link between vulnerability and target, and records the vulnerability history
 * @param {object} input
 * @param {object} [input.vulnerability] - vulnerability data with id & ownerGroup fields
 * @param {object} [input.target] - target data with id & ownerGroup fields
 * @param {string} [input.organizationID] - id of selected organization
 * @param {string} [input.status] - status of the target vulnerability link
 * @returns {Promise<void>}
 */
export const createTargetFindingLink = async ({
  target,
  vulnerability,
  status = TargetVulnerabilityStatus.NOT_FIXED,
  organizationID,
}) => {
  const argumentRequirements = {
    target: { type: "object", validAsNull: false },
    vulnerability: { type: "object", validAsNull: false },
    organizationID: { type: "string", validAsNull: true },
  };
  const { createMutation: createTargetFindingLink } = generateGraphql("TargetFindingLink", [
    "targetID",
    "vulnerabilityID",
    "status",
  ]);
  const { createMutation: createTargetVulnerabilityHistory } = generateGraphql("TargetVulnerabilityHistory", [
    "id",
    "type",
    "description",
    "date",
    "comment",
    "targetVulnerabilityLinkID",
  ]);

  const orgID = organizationID || vulnerability?.ownerGroup || target?.ownerGroup;
  try {
    checkArguments({ target, vulnerability, organizationID }, argumentRequirements);
    const targetVulnerabilityLinkData = await createTargetVulnerabilityLinkData({
      status,
      target,
      vulnerability,
      orgID,
      createMutation: createTargetFindingLink,
    });
    await recordTargetVulnerabilityHistory({
      targetVulnerabilityLinkData,
      orgID,
      createMutation: createTargetVulnerabilityHistory,
    });
    InfoLogger("TargetFindingLink was successfully created.");
  } catch (error) {
    ErrorLogger(`Error in createTargetFindingLink: ${error}`);
  }
};

const createTargetVulnerabilityLinkData = async ({ target, vulnerability, status, orgID, createMutation }) => {
  return await ItemMutation(createMutation, {
    vulnerabilityID: vulnerability?.id,
    targetID: target?.id,
    falsePositive: false,
    status,
    ownerGroup: orgID,
  });
};

const recordTargetVulnerabilityHistory = async ({ targetVulnerabilityLinkData, orgID, createMutation }) => {
  await ItemMutation(createMutation, {
    targetVulnerabilityLinkID: targetVulnerabilityLinkData?.id,
    type: TargetVulnerabilityHistoryType.INITIAL,
    description: "Vulnerability created",
    comment: "Target and vulnerability link was created manually",
    date: new Date().toISOString(),
    ownerGroup: orgID,
  });
};
