import React, { useState } from "react";

import { DocumentPreview } from "../../../../hooks/views/useDocumentGrid/customFields/DocumentPreview";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * @description Compliance Document Preview Hook
 * @param {string} organizationID - selected organization
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, onFileClick: onFileClick, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useComplianceDocumentPreview = ({ organizationID }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);

  const filePreviewModal = useModal(
    "File Preview",
    <DocumentPreview document={selectedDocument} organizationID={organizationID} />,
    null,
    {
      width: "90vw",
    },
  );

  const onFileClick = (nodes) => {
    if (nodes?.file?.key) {
      setSelectedDocument(nodes);
      filePreviewModal.setModalIsOpen(true);
    }
  };

  return {
    ...filePreviewModal,
    onFileClick,
  };
};
