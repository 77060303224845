import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { getPointOfContact_minimal } from "../graphql/__pointOfContactGQL";

import PointOfContactDashboard from "./PointOfContactDashboard/components/PointOfContactDashboard";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *  - 8-5-19 BC: Made delete button not display for standard users.
 *               Delete button now uses custom Graphql, so that the returned deletion-event object doesn't contain Organization data.
 *  - 2/27/20 BC: Refactored to use the useDetailsCard hook.
 *
 * Description: A Card component.
 *              Displays info for a single Point of Contact resource.
 *              Queries `getPointOfContact` with :id from URL
 *
 */
const PointOfContactDetails = ({
  itemId,
  disableRoute,
  tableDisplay,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.POINT_OF_CONTACT,
  updateItemById,
  disableRoleChecking = false,
}) => {
  const queryConfig = {
    query: getPointOfContact_minimal,
    itemId: itemId,
  };

  const config = {
    header: "Point of Contact Details",
    route: "#/organization_manager/contacts/",
    disableRoute: disableRoute,
    detailsComponent: (
      <PointOfContactDashboard
        tableDisplay={tableDisplay}
        updateItemById={updateItemById}
        module={module}
        resource={resource}
      />
    ),
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    config,
    module,
    resource,
    tableDisplay,
    disableRoleChecking,
  });

  return detailsCard?.tableDisplay;
};

export default withDetailsPage(PointOfContactDetails, {
  title: "Point of Contact Details",
});
