import "jspreadsheet-ce/dist/jspreadsheet.css";

import jspreadsheet from "jspreadsheet-ce";
import { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";

import { isNonEmptyArray, removeNonStandardCharactersFromString } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { locationType } from "../../../analytics/constants/locationType";
import { operationType } from "../../../analytics/constants/operationType";
import { reasonType } from "../../../analytics/constants/reasonType";
import { useDuplicateScanner } from "../../../hooks/views/useDuplicatesScanner";
import { useModal } from "../../../hooks/views/useModal";
import { ListQuery } from "../../../utils/Functions/Graphql/ListQuery";
import ImportButton from "../../../utils/GenericComponents/ImportButton";

import DisplayHeaders from "./customFields/DisplayHeaders";

const Style_FileSelection = {
  border: 1,
  borderStyle: "solid",
  borderRadius: 8,
  padding: 10,
};

/**
 *
 * @deprecated - use src/hooks/views/useCsvImporter instead
 *
 * @param {string[]} headersProps - string array of headers of CSV file if passed data source will be validated
 * @param {object[]} headerObjects - objects array of headers if passed data source will be validated
 * NOTE: headerObjects should contain every header title from your CSV and will be validated based on this
 * @example
 * export const headerObjectsUploadCsvOpenVas = [
 {
    title: "IP",
    fieldTitle: "ip" //corresponding database field title for duplicate scanner
    type: "text"
  },
 {
    title: "Hostname",
    type: "text"
  },
 {
    title: "Port",
    type: "text"
  },
 {
    title: "Port Protocol",
    type: "text"
  },
 {
    title: "CVSS",
    type: "text"
  }
 ];
 * @param dictionary {Object} key:value pairs, key should be header from CSV and value is the field name to which it will be mapped to
 * example:
 * export const dictionaryUploadCsvOpenVas = {
  "CVEs": "cve",
  "CVSS": "cvss",
  "Severity": "severityLevel",
  "IP": "ip",
  "Hostname": "hostName",
  "Port Protocol": "protocol",
  "Port": "port",
  "NVT Name": "name",
  "NVT OID": "nvtOID",
  "Impact": "impact",
  "Solution Type": "solutionType",
  "Summary": "summary"
};
 * @param {object} customFields - if item from CSV doesn't have a fields.
 * @example
 * export const customFieldsUploadCsvOpenVas = {
  "type" : (finding) => {
    return finding.severityLevel ? finding.severityLevel === 'Log' ? 'Information' : 'Vulnerability' : undefined;
  },
  "severityLevel": (finding) => {
    return finding.severityLevel ? finding.severityLevel === 'Log' ? 'Info' : finding.severityLevel : undefined;
  }
};

 * @param {function} getDataCallback - returns parsed and shaped data based on dictionary and custom fields
 * @param {boolean} passThroughDuplicateScanner - FALSE (default) if duplicate scanner should not be allowed, TRUE if should
 * NOTE: passThroughDuplicateScanner will change the structure of the dataJSONReady state
 * @example
 * passThroughDuplicateScanner as FALSE: [[1:1,1:2,1:3][2:1,2:2,2:3]] (all add)
 * passThroughDuplicateScanner as TRUE: {add:[[1:1,1:2,1:3][2:1,2:2,2:3]], update:[], delete:[]}
 * @param {string} title - Modal header title
 * @param {number} headerRowNumber - Specify the header row number. Defaults to 0
 * @returns {object} {{setModalIsOpen: function(*=): void, modalButton: *, modalIsOpen: boolean, setDataJSONReady: React.Dispatch<React.SetStateAction<unknown>>, dataJSONReady: unknown, display: *, reset: reset, modal: *}}
 */

export const useCsvImporter = ({
  headersProps,
  headerObjects,
  dictionary = {},
  customFields,
  getDataCallback,
  passThroughDuplicateScanner = false,
  title = "Import from CSV file",
  headerRowNumber = 0,
}) => {
  const [table, setTable] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [data, setData] = useState([]);
  const [dataJSONReady, setDataJSONReady] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [validate, setValidate] = useState(false);
  const [duplicateScanEnabled, setDuplicateScanEnabled] = useState(false);

  const [resetVal, setResetVal] = useState(0);

  const reset = () => {
    setResetVal((resetVal) => resetVal + 1);
  };

  useEffect(() => {
    if (
      (headerObjects && Array.isArray(headerObjects) && headerObjects.length > 0) ||
      (headersProps && Array.isArray(headersProps) && headersProps.length > 0) ||
      (headers && headers.length > 0)
    ) {
      const tableHeaderLocal = [];

      if (headers && Array.isArray(headers) && headers.length > 0) {
        for (const header of headers) {
          tableHeaderLocal.push({
            type: "text",
            title: header,
            width: "200",
          });
        }
      } else if (headerObjects && Array.isArray(headerObjects) && headerObjects.length > 0) {
        for (const headerObject of headerObjects) {
          tableHeaderLocal.push({
            type: "text",
            width: "200",
            ...headerObject,
          });
        }
      } else if (headersProps && Array.isArray(headersProps) && headersProps.length > 0) {
        for (const header of headersProps) {
          tableHeaderLocal.push({
            type: "text",
            title: header,
            width: "200",
          });
        }
      }
      setTableHeader(tableHeaderLocal);
    }
  }, [headers, resetVal, JSON.stringify(headerObjects)]);

  //Updates the data table and duplicate scanner incoming data
  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const newTable = jspreadsheet(document.getElementById("spreadsheet"), {
        data: data,
        tableOverflow: true,
        columns: tableHeader,
        columnDrag: true,
        tableHeight: "50vh",
      });

      duplicateScanner.setItems(false, formatItemArray());
      setTable(newTable);
    }
  }, [data && Array.isArray(data) && data.length > 0]);

  /**
   * Converts matrix (array of arrays) into an item object array
   * IMPORTANT: assumes data columns are in correct order (aligned with headers)
   * Data Example [[bla, blabla], [bla, blabla]]
   */
  const formatItemArray = () => {
    const allItems = [];

    for (const row of data) {
      let rowHasContent = false;
      if (row.length !== headers.length) {
        continue;
      }

      const newItem = {};
      for (let i = 0; i < headers.length; i++) {
        newItem[headers[i]] = row[i];
        if (row[i] != undefined && row[i] != "") {
          rowHasContent = true;
        }
      }

      if (rowHasContent) {
        allItems.push(newItem);
      }
    }

    return allItems;
  };

  /**
   * The same as formatItemArray but treats data as an array of objects whose keys are indexes
   * Data Example [{0: bla, 1:blabla}, {0:bla, 1:blabla}]
   * @param headers
   * @param data
   */
  const formatItemObjectArray = (headers, data) => {
    const allItems = [];

    for (const row of data) {
      let rowHasContent = false;
      if (Object.keys(row).length !== headers.length) {
        continue;
      }

      const newItem = {};

      for (let i = 0; i < headers.length; i++) {
        newItem[headers[i]] = row[i];
        if (row[i] != undefined && row[i] != "") {
          rowHasContent = true;
        }
      }

      if (rowHasContent) {
        allItems.push(newItem);
      }
    }

    return allItems;
  };

  const requiredHeaders = () => {
    if (headerObjects) {
      const headers = [];
      headerObjects.map(
        (item) => item && (item.fieldTitle || item.title) && headers.push(item.fieldTitle || item.title),
      );
      return headers;
    } else if (headersProps) {
      return headersProps;
    } else {
      return [];
    }
  };

  const duplicateScanner = useDuplicateScanner({
    allFields: requiredHeaders(),
    getIncomingData: () => {
      if (table) {
        return formatItemObjectArray(requiredHeaders(), table.getJson());
      } else {
        return [];
      }
    },
  });

  //Stores and initializes source information for duplicates scanner when it is opened
  const [sourceListQuery, setSourceListQuery] = useState();
  const [sourceOrganizationID, setSourceOrganizationID] = useState();
  const [sourceRenameMap, setSourceRenameMap] = useState([]);
  const [computedFields, setComputedFields] = useState({});
  const [missingSourceFields, setMissingSourceFields] = useState([]);
  const [isFirstTimeScannerOpen, setIsFirstTimeScannerOpen] = useState(true);

  const getComputedField = (field, item) => {
    if (field != null && computedFields[field] != null && typeof computedFields[field] === "function" && item != null) {
      return computedFields[field](item);
    }
    return null;
  };
  const initSourceItems = async () => {
    if (!sourceListQuery) {
      ErrorLogger("No source query to retrieve data for duplicate scanner", {
        location: locationType.FUNCTION,
        operation: operationType.LIST,
        reason: reasonType.INVALID_PARAM,
      });
      return;
    }

    try {
      let sourceItems = [];
      if (typeof sourceListQuery === "object") {
        sourceItems = await sourceListQuery;
      } else {
        //Assuming provided a string instead
        sourceItems = await ListQuery({
          query: sourceListQuery,
          organizationID: sourceOrganizationID,
        });
      }

      //TODO: optimize computed fields by only computing them for conflicts and not all source items
      //Create new fields based on current item fields to match incoming CSV data
      for (const renameOperation of sourceRenameMap) {
        for (const item of sourceItems) {
          if (
            item.hasOwnProperty(renameOperation.oldFieldName) &&
            renameOperation.map.hasOwnProperty(item[renameOperation.oldFieldName])
          ) {
            item[renameOperation.newFieldName] = renameOperation.map[item[renameOperation.oldFieldName]];
          }
        }
      }

      //Create empty properties for all fields listed as missing
      for (const field of missingSourceFields) {
        for (const item of sourceItems) {
          item[field] = "";
        }
      }

      //Create any fields that are made with custom functions
      for (const field in computedFields) {
        for (const item of sourceItems) {
          const curComputedField = getComputedField(field, item);
          if (curComputedField != null) {
            item[field] = curComputedField;
          }
        }
      }

      duplicateScanner.setItems(true, sourceItems);
    } catch (e) {
      ErrorLogger("Retrieving source data for CSV importer has failed", e);
    }
  };

  /**
   * Set the information on how to retrieve source data already in the database for duplicate scanner
   * @param listQuery {string|Promise} - query to get all items against which to compare incoming data
   * @param organizationID
   * @param renameMap.oldFieldName {string} - the field in the list query to rename
   * @param renameMap.newFieldName {string} - the field to add during renaming
   * @param renameMap.map {object} - keys are oldFieldNames, values are the respective newFieldNames
   * @param missingFields {string[]} - array of fieldNames from the headers passed into the csvImporter that cannot be retrieved by "listQuery"
   * @param computedFields {object} - object with fieldName as a key and a function for the value that computes the field given an item from the "listQuery"
   */
  const initSourceQuery = (
    listQuery,
    organizationID,
    renameMap = [],
    missingSourceFields = [],
    computedFields = [],
  ) => {
    setSourceListQuery(listQuery);
    setSourceOrganizationID(organizationID);

    //TODO: Refactor all different type of computed fields to just use functions if not less effecient

    setSourceRenameMap(renameMap);
    setMissingSourceFields(missingSourceFields);
    setComputedFields(computedFields);

    //If source was already retrieved before immediately retrieve the new source info
    if (!isFirstTimeScannerOpen) {
      initSourceItems();
    }
  };

  const CreateNewTable = () => {
    resetTable();

    const newTable = jspreadsheet(document.getElementById("spreadsheet"), {
      data: [[]],
      minDimensions: [1, 10],
      tableOverflow: true,
      columns: tableHeader,
      columnDrag: true,
      tableHeight: "50vh",
    });

    if (headersProps) {
      setHeaders(headersProps);
    } else if (headerObjects) {
      const headerStringArray = [];
      for (const obj of headerObjects) {
        if (obj.title) {
          headerStringArray.push(obj.title);
        }
      }
      setHeaders(headerStringArray);
    }

    setTable(newTable);
  };

  //Removes any null unicode characters from the data string fields
  const removeNullCharactersFromData = ({ data }) => {
    if (isNonEmptyArray(data)) {
      for (const item of data) {
        for (const key in item) {
          const value = item[key];
          if (typeof value === "string") {
            item[key] = removeNonStandardCharactersFromString({
              stringContent: value,
              removeNullCharacters: true,
            });
          }
        }
      }
    }
    return data;
  };

  const getData = (data) => {
    if (headers && Array.isArray(headers)) {
      data.map((item) => {
        headers.map((head, index) => {
          item[head] = item[index];
          delete item[index];
        });
      });
    }

    if (dictionary) {
      for (const item of data) {
        for (const key in dictionary) {
          if (item.hasOwnProperty(key)) {
            Object.defineProperty(item, dictionary[key], Object.getOwnPropertyDescriptor(item, key));
            delete item[key];
          }
        }
      }
    }

    if (customFields) {
      for (const item of data) {
        for (const key in customFields) {
          item[key] = customFields[key](item);
        }
      }
    }

    if (passThroughDuplicateScanner) {
      let itemChanges = { add: [], delete: [], update: [] };
      if (
        duplicateScanEnabled &&
        duplicateScanner.conflicts &&
        Array.isArray(duplicateScanner.conflicts) &&
        duplicateScanner.conflicts.length
      ) {
        itemChanges = duplicateScanner.getFinalItemChanges();
      } else {
        //if duplicate scanner is not open all items are marked as to be "added"
        itemChanges = { add: data, delete: [], update: [] };
      }
      if (isNonEmptyArray(itemChanges?.add)) {
        itemChanges.add = removeNullCharactersFromData({
          data: itemChanges.add,
        });
      }

      setDataJSONReady(itemChanges);
      getDataCallback?.(itemChanges);
    } else {
      data = removeNullCharactersFromData({ data });

      setDataJSONReady(data);
      getDataCallback?.(data);
    }
  };

  //Checks if the loaded csv file contains all of the required header fields
  useEffect(() => {
    if (validate && validate === true) {
      if (headers && Array.isArray(headers) && headers.length > 0) {
        for (const header of headers) {
          if (headersProps && Array.isArray(headersProps)) {
            if (!headersProps.includes(header)) {
              setErrorMessage(
                `Error, please select correct data source - CSV header '${header}' could not be validated`,
              );
            }
          } else if (headerObjects && Array.isArray(headerObjects)) {
            for (const item of headerObjects) {
              if (item?.title === header) {
                setErrorMessage("");
                break;
              } else {
                setErrorMessage(
                  `Error, please select correct data source - CSV header '${header}' could not be validated`,
                );
              }
            }
          }
        }
      }
    }
  }, [headers, validate]);

  const parseFile = (matrix) => {
    setHeaders([]);
    setData([]);
    setTableHeader([]);
    const spreadsheetNode = document.getElementById("spreadsheet");
    if (spreadsheetNode) {
      spreadsheetNode.innerHTML = "";
    }

    if (matrix !== undefined && matrix !== null && Array.isArray(matrix)) {
      // Map headers to field names using dictionary
      let mappedHeaders = [];

      // If headerRowNumber is specified, use that row to map headers
      if (matrix?.[headerRowNumber] && Array.isArray(matrix[headerRowNumber])) {
        mappedHeaders = matrix[headerRowNumber]?.map((header) => dictionary?.[header] || header);
      }
      // If headerRowNumber is not specified, use the first row to map headers
      else if (matrix[0] && Array.isArray(matrix[0])) {
        mappedHeaders = matrix[0].map((header) => dictionary?.[header] || header);
      }

      setHeaders(mappedHeaders);
      setData(matrix.slice(headerRowNumber ? headerRowNumber + 1 : 1, -1));
    }
  };

  const updateDuplicateScanEnabled = async (isEnabled) => {
    if (isEnabled) {
      if (isFirstTimeScannerOpen) {
        setIsFirstTimeScannerOpen(false);
        initSourceItems();
      }

      if (table) {
        duplicateScanner.setItems(false, formatItemObjectArray(requiredHeaders(), table.getJson()));
      }
    }

    duplicateScanner.scannerIsEnabled(isEnabled);
    setDuplicateScanEnabled(isEnabled);
  };

  const resetTable = () => {
    setHeaders([]);
    setData([]);
    setTableHeader([]);
    duplicateScanner.setItems(false, []);
    const tableNode = document.getElementById("spreadsheet");
    if (tableNode) {
      //TODO: table.getJson(); still returns cleared table data after below line - find how to clear it
      document.getElementById("spreadsheet").innerHTML = "";
    }
    setErrorMessage("");
  };

  const display = (
    <Card>
      <CardHeader>
        <Button
          size="sm"
          color="ghost-success"
          className="btn-pill"
          onClick={() => CreateNewTable()}
          title="Create a new table"
        >
          Create Table
        </Button>{" "}
        <Button size="sm" color="ghost-danger" className="btn-pill" onClick={resetTable} title="Start from scratch">
          Reset Table
        </Button>{" "}
        <Button
          color="ghost-info"
          size="sm"
          className="btn-pill"
          id="headers-toggler"
          title="Click to see list of headers for CSV importer"
        >
          Headers
        </Button>{" "}
        {validate && passThroughDuplicateScanner ? (
          <Button
            size="sm"
            color={duplicateScanEnabled ? "ghost-secondary" : "ghost-primary"}
            className="btn-pill"
            onClick={() => {
              updateDuplicateScanEnabled(!duplicateScanEnabled);
            }}
          >
            {duplicateScanEnabled ? "Close" : "Open"} Duplicate Scanner
          </Button>
        ) : (
          <></>
        )}
        <UncontrolledCollapse toggler="#headers-toggler">
          <Card>
            <CardBody>
              <DisplayHeaders headerObjects={headerObjects} headersProps={headersProps} />
              <br />
              <CustomInput
                type="switch"
                id="validate-csv-data"
                name="validate-csv-data"
                checked={validate}
                onChange={() => setValidate(!validate)}
                label="Enable header validation"
              />
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      </CardHeader>
      <CardBody>
        {errorMessage ? (
          <Alert color="danger">{errorMessage}</Alert>
        ) : (
          <>
            <>
              {headers && headers.length > 0 ? (
                <>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">Edit Headers:</InputGroupAddon>
                    <select id="columnNumber">
                      {headers.map((item, index) => (
                        <option value={index}>{item}</option>
                      ))}
                    </select>
                    <input
                      type="button"
                      value="Set"
                      onClick={() => table?.setHeader(document.getElementById("columnNumber").value)}
                    />
                    <Button
                      size="sm"
                      color="ghost-secondary"
                      className="btn-pill float-sm-right"
                      onClick={() => {
                        setHeaders(table.getHeaders().split(","));
                      }}
                      title="Refresh Card Data"
                    >
                      <i className="icon-reload" />
                    </Button>
                  </InputGroup>
                </>
              ) : null}
            </>
            <div id="spreadsheet" style={{ width: "100%", overflow: "auto" }} />
          </>
        )}
        <br />
        <CSVReader
          cssClass="csv-reader-input"
          onFileLoaded={parseFile}
          inputStyle={Style_FileSelection}
          fileEncoding="UTF-8"
        />
        <br />
        <span>Note: Headers are required for CSV file upload</span>{" "}
        <span style={{ cursor: "pointer" }} id="header-question">
          <i className="icon-question" />
        </span>
        <UncontrolledTooltip placement="right" target="header-question">
          <DisplayHeaders headerObjects={headerObjects} headersProps={headersProps} />
        </UncontrolledTooltip>
        <br />
        {duplicateScanner.display}
      </CardBody>
      <CardFooter>
        <Button
          size="sm"
          color="success"
          className="btn-pill"
          disabled={!table}
          onClick={() => {
            const data = table?.getJson();
            if (data) {
              getData(data);
              resetTable();
            } else {
              ErrorLogger("Error! Can not get data from the table!");
            }
          }}
        >
          <i className="icon-cloud-upload" /> Upload CSV
        </Button>
      </CardFooter>
    </Card>
  );

  const csvImporterModel = useModal(title, display, <ImportButton />, {
    width: "90vw",
  });

  return {
    display,
    ...csvImporterModel,
    dataJSONReady,
    setDataJSONReady,
    initSourceQuery,
    reset,
  };
};
