"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSystemRiskResults = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const getRating_KRI_1 = require("./getRating_KRI");
/**
 * Returns the Risk Scores for the KRIs of a particular system.
 * Note: Does not return the Risk Scores for the system itself
 *
 * @param {MonteCarloCalculationResult} monteCarloResults  - required
 * @param {Object} [ratingScale] -  Optional
 * @returns {Array}
 */
const getSystemRiskResults = async ({ monteCarloResults, ratingScale }) => {
    const res = [];
    if (monteCarloResults && Array.isArray(monteCarloResults?.riskStats)) {
        // For each KRI in the system, calculate ratings
        for (const riskStat of monteCarloResults.riskStats) {
            // Get the rating from the KRI
            const rating = (0, getRating_KRI_1.getRating_KRI)({
                risk: riskStat,
                monteCarloResults,
                ratingScale,
                field: "residualRisk",
            });
            res.push({
                id: riskStat.id,
                name: riskStat.name,
                residualRisk: riskStat?.residualRisk,
                inherentRisk: riskStat?.inherentRisk,
                controlImplementation: riskStat.currentControlEffectiveness,
                riskRating: (0, func_utils_1.convertCamelCaseToSentence)(rating),
                residualLossCurve: riskStat?.lossExceedanceCurve?.residualLossCurve,
                inherentLossCurve: riskStat?.lossExceedanceCurve?.inherentLossCurve,
            });
        }
    }
    else {
        (0, func_utils_1.eventLogger)("Error: Could not calculate systemRiskResults with invalid monteCarloResults");
    }
    return res;
};
exports.getSystemRiskResults = getSystemRiskResults;
