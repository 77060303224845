import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

import VendorControlStatusBadge from "./VendorControlStatusBadge";

/**
 * Risk control field in system details used to display any associated vendor control information for outsourced controls
 * @param {object} vendorControl - vendor control information associated with the risk control
 * @param {object} style - css inline styles passed down to every risk control field for consistent styles
 * @param {boolean} disableRoleChecking - flag to disable role checking for this component on the front end
 * @return {JSX.Element}
 */
const SystemRiskControlVendorControl = ({ vendorControl, style, disableRoleChecking } = {}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <div style={style.div}>
      <strong>
        Vendor Control
        <i
          className="icon-question"
          title={
            "This is the status of the Vendor Sub Control mapped to this Risk Control from the most recent Vendor Review of the attached Vendor Solution."
          }
          style={style.icon}
        />
      </strong>
      {checkPermissionsHook.resource.read ? (
        <div style={style.field}>
          {vendorControl ? (
            <span>
              <VendorControlStatusBadge
                value={vendorControl?.inPlace}
                trueColor="ghost-primary"
                trueText="In Place"
                falseText="Not In Place"
              />{" "}
              <VendorControlStatusBadge
                value={vendorControl?.audited}
                trueColor="ghost-success"
                trueText="Audited"
                falseText="Not Audited"
              />
            </span>
          ) : (
            <span style={{ fontStyle: "italic", color: "lightGrey" }}>No Associated Vendor Control</span>
          )}
        </div>
      ) : (
        <strong> You don't have Read Permissions on Resource: Risk Control Category</strong>
      )}
    </div>
  );
};

export default SystemRiskControlVendorControl;
