import { cloneDeep } from "lodash";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../../utils/Functions/Graphql/ItemQuery";
import { getResponsePlan } from "../../../Plan/functions/getResponsePlan";
import { getProceduresNestedFields } from "../getProceduresNestedFields";

import { getItemRequests } from "./functions/getItemRequests";

/**
 * Takes result from the playbook builder and updates the passed in item procedures based on its existing procedures
 * @param {object} item - the resource to update with playbooks copied from different response plan items
 * @param {string} typename - the schema model name of the type that will hold the aggregated playbook data
 * @param {boolean} [includeGeneral] - if TRUE will grab teh general procedure steps from the response plan and copy
 * them into the new playbook
 * @param {object} [teams=[]] - the response teams to grab procedures from into the new playbook
 * @param {object} [systems=[]] - the system response links to grab procedures from into the new playbook
 * @param {object} [playbooks=[]] - the playbooks to grab procedures from into the new playbook
 * @param {string} organizationID - selected organization id
 * @returns {object} the updated resource with the new procedures
 */
export const configurePlaybook = async ({
  item,
  typename,
  includeGeneral,
  teams = [],
  systems = [],
  playbooks = [],
  organizationID,
}) => {
  //Check arguments
  if (!item?.id || item?.procedures === undefined || !typename) {
    throw Error("Cannot update item procedures because the arguments are invalid");
  }

  let newProcedureGroups = [];
  const existing = {};
  const added = {};

  //Get the most up-to-date version of the items (so nothing gets overridden)
  const { getQuery, updateMutation } = generateGraphql(typename, ["procedures"], getProceduresNestedFields());
  const existingItem = await ItemQuery(getQuery, item.id);

  //Collect a set of ids of procedure groups that are already existing on the item
  if (Array.isArray(existingItem?.procedures)) {
    //iterate over all existing procedure groups to get parent ids
    for (const procedureGroup of existingItem.procedures) {
      if (procedureGroup?.parentID) {
        existing[procedureGroup?.parentID] = true;
      }
    }

    //copy existing procedures into the new procedures list
    newProcedureGroups = cloneDeep(existingItem.procedures);
  }

  //Automatically mark General: {typename} procedures (manually added) to keep in the final list (no matter if present or not)
  added[item.id] = true;

  //Add general procedures if the setting is provided
  const procedureGroupRequests = [];
  if (includeGeneral === true) {
    const responsePlan = await getResponsePlan(organizationID);
    if (responsePlan?.id && !existing.hasOwnProperty(responsePlan?.id)) {
      procedureGroupRequests.push({
        id: responsePlan?.id,
        __typename: "IncidentResponse",
        name: "General: Response Plan",
        procedures: responsePlan?.procedures || [],
      });
    }
    added[responsePlan?.id] = true;
  }

  //Get any missing procedure data (teams, systems, playbooks)
  const { getQuery: getTeamQuery } = generateGraphql(
    "ResponseTeam",
    ["name", "procedures", "__typename"],
    getProceduresNestedFields(),
  );
  const { getQuery: getPlaybookQuery } = generateGraphql(
    "Playbook",
    ["name", "procedures", "__typename"],
    getProceduresNestedFields(),
  );
  const { getQuery: getSystemQuery } = generateGraphql("ResponseSystemLink", ["system", "procedures", "__typename"], {
    ...getProceduresNestedFields(),
    system: `{id name}`,
  });

  procedureGroupRequests.push(...getItemRequests({ grid: teams, getQuery: getTeamQuery, existing, added }));
  procedureGroupRequests.push(
    ...getItemRequests({
      grid: playbooks,
      getQuery: getPlaybookQuery,
      existing,
      added,
    }),
  );
  procedureGroupRequests.push(
    ...getItemRequests({
      grid: systems,
      getQuery: getSystemQuery,
      existing,
      added,
      //ID used from system not the link to select items in the grid
      getAddedID: (item) => {
        return item?.systemID;
      },
      //Shown system grid selected items need to be converted within getItemRequests
      getSelectedItems: (items) => {
        if (Array.isArray(items)) {
          const newItems = [];
          for (const item of items) {
            if (item?.responseLinkID) {
              newItems.push({
                id: item.responseLinkID,
                systemID: item?.id,
              });
            }
          }
          return newItems;
        } else {
          return [];
        }
      },
    }),
  );

  //Wait for all items to be retrieved
  const requestResults = await Promise.allSettled(procedureGroupRequests);

  //Populate the new procedures object to include the procedures from the retrieved items
  for (const requestResult of requestResults) {
    //note only the first procedure is taken into account
    if (requestResult?.status === "fulfilled" && requestResult?.value?.id) {
      const queryItem = requestResult.value;

      //Find name
      let groupName = queryItem?.name;
      if (queryItem?.__typename === "ResponseSystemLink" && queryItem?.system?.name) {
        groupName = queryItem.system.name;
      }

      //Find ids
      let groupID;
      let linkID;
      if (queryItem?.__typename === "ResponseSystemLink") {
        groupID = queryItem?.system?.id;
        linkID = queryItem?.id;
      } else if (queryItem?.id) {
        groupID = queryItem.id;
      }

      if (!isNullOrUndefined(groupID)) {
        //Find procedure group (taking just the first one)
        let procedureGroup = [];
        if (Array.isArray(queryItem?.procedures) && queryItem?.procedures?.length > 0) {
          procedureGroup = queryItem?.procedures[0];
        }
        delete procedureGroup.parentID;
        delete procedureGroup.linkID;
        delete procedureGroup.parentTypename;
        delete procedureGroup.name;

        newProcedureGroups.push({
          parentID: groupID,
          linkID,
          parentTypename: queryItem?.__typename,
          name: groupName,
          ...procedureGroup,
        });
      } else {
        throw Error("GroupID could not be found when updating a playbook!");
      }
    }
  }

  //Remove any procedures that are in `existing` but not `added` set
  newProcedureGroups = newProcedureGroups.filter((procedureGroup) => {
    return procedureGroup?.parentID && added.hasOwnProperty(procedureGroup.parentID);
  });

  //Perform database update of the playbook
  return await ItemMutation(updateMutation, {
    id: item?.id,
    procedures: newProcedureGroups,
  });
};
