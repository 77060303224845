import React, { useEffect, useState } from "react";

import ChangeInCost from "../../Recommendations/customFields/ChangeInCost";

const AssetSizeChange = ({ item }) => {
  const [oldAssetSize, setOldAssetSize] = useState(0);
  const [newAssetSize, setNewAssetSize] = useState(0);

  useEffect(() => {
    const change = item && JSON.parse(item.change);
    const oldAssetSize = change && change.oldAssetSize ? change.oldAssetSize : 0;
    const newAssetSize = change && change.newAssetSize ? change.newAssetSize : 0;

    setOldAssetSize(oldAssetSize);
    setNewAssetSize(newAssetSize);
  }, []);

  return (
    <td colSpan={2}>
      <h5>Change in Asset Size</h5>
      <ChangeInCost newCost={newAssetSize} oldCost={oldAssetSize} />
    </td>
  );
};

export default AssetSizeChange;
