import React, { useEffect } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import RiskControlDataGrid from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDataGrid";
import { createRiskControlEvidenceLink } from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/functions/createRiskControlEvidenceLink";
import { deleteRiskControlEvidenceLink } from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/functions/deleteRiskControlEvidenceLink";
import { useRiskControlDataGrid } from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/hooks/useRiskControlDataGrid";

/**
 * Displays a grid of RiskControls attached to an Evidence in a m-m connection
 * @param item - the Risk Control
 * @param {object} evidenceRiskControlLinking - instance of the evidenceRiskControlLinking hook
 * @param {function} resetFunction - reset parent state
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceStandardRiskControls = ({ item, evidenceRiskControlLinking, resetFunction, organizationID }) => {
  /**
   * This code gets triggered when the evidenceRiskControlLinking submit function called
   */
  useEffect(() => {
    if (evidenceRiskControlLinking?.key !== 0) dataCard?.resetFunction();
  }, [evidenceRiskControlLinking?.key]);

  const { getQuery } = generateGraphql("Evidence", ["riskControls", "enabled"], {
    riskControls: `(limit: 1000) {
      items {
        id
        riskControl {
          id
          ownerGroup
          name
          statementNumber
          implementationRating
          strengthRating
          keyControl
          controlCategory {
            id
            name
          }
        }
        system {
          id
        }
      }
    }`,
  });

  const groupSettings = {
    columns: ["controlCategory"],
    showDropArea: false,
    captionTemplate: "${key} - ${count} Items",
    defaultCollapsed: true,
  };

  // Not including the form because Risk Controls should be created through Risk Control Categories
  const dataCard = useNestedDataCard({
    item,
    organizationID,
    query: getQuery,
    field: "riskControls",
    connectionField: "riskControl",
    typename: "RiskControl",
    parentTypename: "Evidence",
    grid: <RiskControlDataGrid />,
    gridHook: useRiskControlDataGrid,
    createFunction: createRiskControlEvidenceLink,
    deleteFunction: async (linkToDelete) => {
      const removedLink = await deleteRiskControlEvidenceLink(linkToDelete, item, organizationID);
      resetFunction && resetFunction();
      return removedLink;
    },
    flipCreateParams: false,
    gridHookGridConfig: {
      groupSettings,
      allowGrouping: true,
      enableContextMenu: true,
      createResourceComponent: null,
    },
    normalizeData: (data) => {
      //Add control category name to the top level object
      let newData = data.map((item) => {
        return {
          ...item,
          controlCategory: item?.controlCategory?.name,
        };
      });

      //Filter out any items that are non-standard controls, these have a system defined
      newData = newData.filter((item) => {
        if (item?.link?.system?.id) {
          return false;
        }
        return true;
      });

      return newData;
    },
  });

  return <div style={{ height: "45em" }}>{dataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(EvidenceStandardRiskControls);
