import { Icon } from "@iconify/react";
import Button from "@mui/material/Button";
import pluralize from "pluralize";
import React from "react";

/**
 * A button that opens a modal for importing data
 * @param {string} [typename] - optional typename to include in the button text
 * @param {string} [text] - optional override for the button text
 * @param {object} props - any additional props to pass into the MUI button
 * @returns {JSX.Element}
 */
const ImportButton = ({ typename, text, ...props }) => {
  return (
    <Button startIcon={<Icon icon={"uil:import"} />} variant={"outlined"} size={"medium"} {...props}>
      {text || `Bulk Import${typename ? ` ${pluralize(typename)}` : ""}`}
    </Button>
  );
};

export default ImportButton;
