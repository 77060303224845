import React from "react";

import OrganizationCheck from "../../../../utils/Context/OrganizationCheck/OrganizationCheck";

/**
 * Displays the boolean logic that is used as the KPI trigger
 * @param item
 * @param metricType
 * @param logic
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const LogicBody = ({ item, metricType = {}, logic, organizationID }) => {
  // TODO: show the logic in a read-only view that can be edited
  // const kpiLogicBuilder = useKPILogicBuilder({organizationID, readonly: false, metricType});

  return <div>{JSON.stringify(metricType.logic)}</div>;
};

const Logic = (props) => {
  return (
    <OrganizationCheck {...props}>
      <LogicBody />
    </OrganizationCheck>
  );
};

export default Logic;
