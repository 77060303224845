import React from "react";

import { formattedDollar, formattedPercent } from "@rivial-security/func-utils";

const ReturnOnInvestment = ({ amount, cost }) => {
  const net = amount - cost;

  const decimal = net / cost;

  return (
    <p>
      {decimal * 100 > 0 ? (
        <span style={{ color: "green" }}>
          {formattedDollar(net)}
          <br />[ + {formattedPercent(decimal)} ]
        </span>
      ) : (
        <span style={{ color: "red" }}>
          {`- ${formattedDollar(net * -1)}`}
          <br />[ {formattedPercent(decimal)} ]
        </span>
      )}
    </p>
  );
};

export default ReturnOnInvestment;
