import { Alert } from "@mui/material";
import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NotEnoughData from "../../../../utils/GenericComponents/NotEnoughData";
import { useMultipleArtifactValidationModal } from "../../Artifact/ArtifactValidation/useMultipleArtifactValidationModal";
import { useArtifactsPendingValidation } from "../../Artifact/General/hooks/useArtifactsPendingValidation";

import TabbedEvidenceCard from "./TabbedEvidenceCard";

/**
 * Displays custom UI for all evidence artifacts, lets the user know if there are
 * any that are available for validation
 * @param {object} evidence - the evidence for which to display artifacts
 * @param {object} artifacts - the artifacts to check for validation
 * @param {string} organizationID - the currently selected organization ID
 * @param {function} resetFunction - a function to refresh the artifacts and evidence props
 * @param {boolean} isLoading - TRUE if the artifacts or evidence props are still loading
 * @returns {JSX.Element}
 */
const EvidenceArtifacts = ({ item: evidence, artifacts, organizationID, resetFunction, isLoading }) => {
  const {
    artifactsPendingValidation,
    resetFunction: _resetFunction,
    isLoading: _isLoading,
  } = useArtifactsPendingValidation({
    artifacts: artifacts ?? evidence?.artifacts,
    resetFunction,
    isLoading,
  });

  const validationInterface = useMultipleArtifactValidationModal({
    artifacts: artifactsPendingValidation,
    resetFunction: _resetFunction,
    isLoading: _isLoading,
    module: modules.COMPLIANCE,
    resource: resources.EVIDENCE,
  });

  /**
   * Check if the user has permission to read artifacts
   */
  const module = modules.COMPLIANCE;
  const resource = resources.ARTIFACT;
  const checkPermissions = useCheckPermissions({ module, resource });

  return (
    <div>
      {checkPermissions.resource.read && (
        <div>
          {Array.isArray(artifactsPendingValidation) && artifactsPendingValidation.length > 0 && (
            <Alert severity="info">
              <NotEnoughData
                message={`There ${artifactsPendingValidation.length === 1 ? "is" : "are"} ${
                  artifactsPendingValidation.length
                } ${artifactsPendingValidation.length === 1 ? "artifact" : "artifacts"} available for validation.`}
                callToAction={{
                  message: " to begin the validation process!",
                  size: "md",
                  function: () => {
                    validationInterface.setModalIsOpen(true);
                  },
                }}
              />
            </Alert>
          )}
          {validationInterface?.modal}
        </div>
      )}
      <TabbedEvidenceCard item={evidence} organizationID={organizationID} resetFunction={_resetFunction} />
    </div>
  );
};

export default withOrganizationCheck(EvidenceArtifacts);
