import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

/**
 *
 * @param toolType
 * @param organizationID
 * @returns {Promise<* | *[]>}
 */
export const getToolByType = async ({ toolType, organizationID }) => {
  if (toolType && organizationID) {
    const toolsByOwnerGroupByType = /* GraphQL */ `
      query ToolsByOwnerGroupByType(
        $ownerGroup: String
        $type: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelToolFilterInput
        $limit: Int
        $nextToken: String
      ) {
        toolsByOwnerGroupByType(
          ownerGroup: $ownerGroup
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            type
            ownerGroup
          }
          nextToken
        }
      }
    `;

    return ListQueryBy({
      query: toolsByOwnerGroupByType,
      variables: {
        ownerGroup: organizationID,
        type: { eq: toolType },
      },
    }).then((data) => data?.[0]);
  }
};
