import { getDetailedRiskControlItem } from "./getDetailedRiskControlItem";
import { listEvidenceNonStandardRiskControlLinks } from "./listEvidenceNonStandardRiskControlLinks";
import { listSystemRiskOverrides } from "./listSystemRiskOverrides";

/**
 * Gets a list of all override information for evidence, risk control, system links
 * @param evidence - the evidence to get the override information for
 * @return {*[]} - see title
 */
export const listEvidenceSystemRiskControlOverrides = ({ evidence } = {}) => {
  //Check arguments
  if (!evidence) {
    return [];
  }

  // Get all non-standard risk control links
  const nonStandardEvidenceRiskControlLinks = listEvidenceNonStandardRiskControlLinks({ evidence });

  // Parse the evidence item for any attached non-standard risk controls
  const data = [];
  for (const nonStandardEvidenceRiskControlLink of nonStandardEvidenceRiskControlLinks) {
    const standardRiskControl = nonStandardEvidenceRiskControlLink?.riskControl;
    const system = nonStandardEvidenceRiskControlLink?.system;

    // create an entry for each system with an active override for the current standard control
    if (system && standardRiskControl) {
      const riskControlOverrides = listSystemRiskOverrides({ system });
      if (Array.isArray(riskControlOverrides) && riskControlOverrides.length > 0) {
        for (const riskControlOverride of riskControlOverrides) {
          // only add the item if part matches to current risk standard risk control
          if (standardRiskControl?.id === riskControlOverride?.riskControlId) {
            data.push(
              getDetailedRiskControlItem({
                standardRiskControl,
                riskControlOverride,
              }),
            );
            break;
          }
        }
      }
    }
  }

  return data;
};
