import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import NotesV2 from "../../../../../../../utils/Notes/components/NotesV2";
import CreateRecommendation from "../../../../../Recommendations/components/CreateRecommendation";

const updateControlCategoryLinkNotes = /* GraphQL */ `
  mutation UpdateSystemControlCategoryLink($input: UpdateSystemControlCategoryLinkInput!) {
    updateSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

const getControlCategoryLinkNotes = /* GraphQL */ `
  query GetSystemControlCategoryLink($id: ID!) {
    getSystemControlCategoryLink(id: $id) {
      id
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

const ControlCategoryNotes = ({ item, organizationID, disableRoleChecking = false, system }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const query = useQueryGetItem({
    itemId: item?.id,
    query: getControlCategoryLinkNotes,
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <NotesV2
      item={query.item}
      module={module}
      resource={resource}
      tooltip="These are Notes specific to this Control Category in the context of this Information System"
      organizationID={organizationID}
      mutation={updateControlCategoryLinkNotes}
      resetFunction={query.reset}
      disableRoleChecking={disableRoleChecking}
      observationFormOverride={<CreateRecommendation organizationID={organizationID} system={system} />}
    />
  );
};

export default withOrganizationCheck(ControlCategoryNotes);
