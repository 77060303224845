import { useEffect, useState } from "react";

import { getDefaultDataGridColumnVisibilityModel } from "./functions/getDefaultDataGridColumnVisibilityModel";

/**
 * Holds the column visibility info in state for 'controlled' visibility
 * REFERENCE: https://mui.com/components/data-grid/columns/#controlled-visible-columns
 *  - all 'fields' default to true and 'customFields' default to false
 *  - a 'visible' boolean may be passed in within a field config to override above behaviour
 * @param {object} targetView - the data grid view that the user would like to see, either from preferences or adjusted through the UI
 * @param {object[]} fields - fields prop passed through from useDataGrid
 * @param {object[]} customFields - customFields prop passed through from useDataGrid
 * @param {boolean} enableSelectButton - enableSelectButton prop passed through from useDataGrid
 * @return {{columnVisibilityModel: object}}
 */
export const useDataGridColumnVisibility = ({ targetView, fields, customFields, enableSelectButton }) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    ownerGroup: false,
  });

  // Configures the column visibility for all fields and custom fields
  useEffect(() => {
    let newColumnVisibilityModel = targetView?.columnVisibilityModel;
    if (!newColumnVisibilityModel) {
      newColumnVisibilityModel = getDefaultDataGridColumnVisibilityModel({
        fields,
        customFields,
      });
    }

    // When select buttons are used always show the "Select" column
    if (enableSelectButton) {
      newColumnVisibilityModel["Select"] = true;
    }

    setColumnVisibilityModel(newColumnVisibilityModel);
  }, [fields?.length, customFields?.length, targetView?.columnVisibilityModel]);

  return {
    columnVisibilityModel,
  };
};
