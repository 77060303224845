import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Input } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import useListQuery from "../../../hooks/graphql/useListQuery";
import { useMutation } from "../../../hooks/graphql/useMutation/useMutation";
import { useSubscription } from "../../../hooks/graphql/useSubscription";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import OrganizationCheck from "../../Context/OrganizationCheck/OrganizationCheck";
import { OrganizationContext } from "../../Context/OrganizationContext";
import DeleteNotes from "../functions/DeleteNotes";

const {
  listQuery: listNotess,
  createMutation: createNotes,
  updateMutation: updateNotes,
} = generateGraphql("Notes", ["timeStamp", "content", "author", "itemId"]);

const NotesBody = ({ organizationID, item, module, resource, disableRoleChecking }) => {
  const [notes, setNotes] = useState("");
  const [editNote, setEditNote] = useState("");
  const [editNoteId, setEditNoteId] = useState("");
  const context = useContext(OrganizationContext);
  const notesEndRef = useRef(null);

  const queryConfig = {
    query: listNotess,
    organizationID,
    otherFiltersInitial: { itemId: { eq: item && item.id } },
    module,
    resource,
    field: "notes",
    disableRoleChecking,
  };

  const notesQuery = useListQuery(queryConfig);

  const { onCreate, onUpdate, onDelete } = generateGraphql("Notes", [
    "type",
    "author",
    "timeStamp",
    "content",
    "itemId",
    "controlNotesId",
    "riskControlNotesId",
    "assessmentNotesId",
    "createdAt",
    "updatedAt",
  ]);

  const notesSubscription = useSubscription({
    onCreateSubscription: onCreate,
    onUpdateSubscription: onUpdate,
    onDeleteSubscription: onDelete,
    organizationID,
    listToSubscribe: notesQuery.list,
    disableRoleChecking: true,
  });

  const [notesArray, setNotesArray] = useState(notesQuery.list || []);

  useEffect(() => {
    setNotesArray([...notesQuery.list]);
  }, [notesQuery.list]);

  useEffect(() => {
    const newItem = notesSubscription && notesSubscription.onCreate;
    if (newItem && newItem.itemId === item.id) {
      const alreadyExists = notesArray.findIndex((item) => item.id === newItem.id);
      if (alreadyExists === -1) {
        setNotesArray((notesArray) => [...notesArray, newItem]);
      }
    }
  }, [notesSubscription.onCreate]);

  useEffect(() => {
    const newItem = notesSubscription && notesSubscription.onUpdate;
    if (newItem && newItem.itemId === item.id) {
      const existingIndex = notesArray.findIndex((item) => item.id === newItem.id);
      if (existingIndex !== -1) {
        const temp = [...notesArray];
        temp.splice(existingIndex, 1, newItem);
        setNotesArray([...temp]);
      }
    }
  }, [notesSubscription.onUpdate]);

  useEffect(() => {
    const newItem = notesSubscription && notesSubscription.onDelete;
    if (newItem && newItem.itemId === item.id) {
      const existingIndex = notesArray.findIndex((item) => item.id === newItem.id);
      if (existingIndex !== -1) {
        const temp = [...notesArray];
        temp.splice(existingIndex, 1);
        setNotesArray([...temp]);
      }
    }
  }, [notesSubscription.onDelete]);

  const scrollToNotesBottom = () => {
    notesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (editNote.length > 0) scrollToNotesBottom();
  }, [editNote]);

  const createNoteMutation = useMutation({
    mutation: createNotes,
    disableRoleChecking: true,
  });
  const updateNoteMutation = useMutation({
    mutation: updateNotes,
    disableRoleChecking: true,
  });

  const createNote = () => {
    const input = {
      itemId: item.id,
      content: notes,
      author: context.userEmail,
      ownerGroup: organizationID,
      timeStamp: new Date(),
    };

    createNoteMutation.createItem(input);
  };

  const updateNote = () => {
    const input = {
      id: editNoteId,
      author: context.userEmail,
      content: editNote,
      timeStamp: new Date(),
    };

    updateNoteMutation.editItem(input);
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field: "notes",
    disableRoleChecking,
  });

  return (
    <>
      {checkPermissionsHook.field.read && (
        <>
          <strong>Notes</strong>
          <br />
          <br />
          {notesQuery.isLoading && <div>Loading Notes..</div>}
          {!notesQuery.isLoading &&
            notesArray &&
            notesArray
              .sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))
              .map((note) => (
                <div key={note.id}>
                  <small className="text-muted">
                    {note.author} {new Date(note.timeStamp).toLocaleString()}
                  </small>
                  {checkPermissionsHook.field.update && (
                    <Button size="sm" color="ghost-danger" onClick={() => DeleteNotes(note)}>
                      Delete
                    </Button>
                  )}
                  <Alert
                    className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
                    color="secondary"
                    draggable="true"
                    onDragStart={(e) => e.dataTransfer.setData("data", JSON.stringify(note))}
                  >
                    {note && note.content ? note.content : null}
                    {checkPermissionsHook.field.update && (
                      <Button
                        id={note.id}
                        size="sm"
                        color="ghost-warning"
                        className="btn-pill"
                        onClick={() => {
                          setEditNote(note.content);
                          setEditNoteId(note.id);
                        }}
                      >
                        <i className="icon-pencil" />
                      </Button>
                    )}
                  </Alert>
                </div>
              ))}
          <div ref={notesEndRef} />
          {editNote ? <Alert color="danger">Edit Note</Alert> : null}
          {checkPermissionsHook.field.update && (
            <>
              <Input
                type="textarea"
                value={editNote ? editNote : notes}
                rows="4"
                cols="50"
                onChange={(e) => (editNote ? setEditNote(e.target.value) : setNotes(e.target.value))}
                placeholder="Leave a note ..."
              />
              <p> </p>
              <Button
                onClick={() => {
                  if (editNote) {
                    updateNote();
                    setEditNote("");
                    setEditNoteId("");
                  } else {
                    createNote();
                    setNotes("");
                  }
                }}
                color="primary"
              >
                Save Note
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

const Notes = (props) => {
  return (
    <OrganizationCheck {...props}>
      <NotesBody />
    </OrganizationCheck>
  );
};

Notes.propTypes = {
  organizationID: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  module: PropTypes.string,
  resource: PropTypes.string,
  disableRoleChecking: PropTypes.bool,
};

export default Notes;
