import { cloneDeep } from "lodash";

/**
 * @description A front end helper for remapping permissions for nested components.
 * Example: Meeting documents list in meeting details needs to use meeting resource UPDATE permissions for its CREATE button.
 * @param {object} permissions - results from a useCheckPermissionsHook
 * @param {object} customPermissionMapping - which operations to map to which ones target (key) : source (value)
 * @return {object} same as 'permissions' but with operation permissions in the resource and field properties remapped
 */
export const remapPermissions = ({ permissions, customPermissionMapping }) => {
  //Remap permissions based on the custom mappings if any
  const finalResult = cloneDeep(permissions);
  if (customPermissionMapping) {
    const permissionMapping = {
      read: "read",
      create: "create",
      update: "update",
      delete: "delete",
      ...customPermissionMapping,
    };

    if (permissions.module.isEnabled) {
      finalResult.resource.read = permissions.resource[permissionMapping.read];
      finalResult.resource.create = permissions.resource[permissionMapping.create];
      finalResult.resource.update = permissions.resource[permissionMapping.update];
      finalResult.resource.delete = permissions.resource[permissionMapping.delete];
      finalResult.field.read = permissions.resource[permissionMapping.read];
      finalResult.field.update = permissions.resource[permissionMapping.update];
    }
  }

  return finalResult;
};
