import React from "react";

import Loader from "./Loader";

/**
 * A centered loading message (useful for full screen loading)
 * @param {string} message - the text to display after the loader
 * @param {object} containerStyle - react css inline properties for the message wrapper
 */
const CenteredLoadingMessage = ({ message, containerStyle }) => {
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <span style={{ fontSize: "2em" }}>
        <Loader style={{ width: "1.3em", height: "1.3em" }} /> {message}
      </span>
    </div>
  );
};

export default CenteredLoadingMessage;
