import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useKPILogicBuilder } from "../../KPILogicBuilder/hooks/useKPILogicBuilder";

/**
 * Custom hook for creating a new Key Performance Indicator.
 * @author Jacob Blazina
 * @class
 // * @namespace useCreateKeyPerformanceIndicator
 * @param {string} organizationID - required (injected automatically if using the component version)
 * @param {function} getNewItem - comes from the component wrapper and passed by the grid, to populate new items as they are created
 * @param {function} toggleModal - comes from the component wrapper and passed by the grid, to open/close the create modal
 * @returns {{input: {}, display: *}}
 */
export const useCreateKeyPerformanceIndicator = ({ organizationID, getNewItem, toggleModal }) => {
  const module = modules.METRICS;
  const resource = resources.KEY_PERFORMANCE_INDICATOR;

  const typename = "KeyPerformanceIndicator";
  const fields = ["name", "description", "status"];

  /**
   * Instantiates a mutationHook to create the KPIMetricTypeLink many-to-many connections
   */
  const createKPIMetricTypeLink = useMutation({
    mutation: generateGraphql(
      "KPIMetricTypeLink",
      ["keyPerformanceIndicatorID", "metricTypeID", "keyPerformanceIndicator"],
      { keyPerformanceIndicator: "{ id name status getStatus }" },
    ).createMutation,
    disableRoleChecking: true,
    disableToast: true,
  });

  const kpiLogicBuilder = useKPILogicBuilder({
    organizationID,
    readonly: false,
  });

  /**
   * Creates the Many-to-Many connections, this is performed as a callback after the KPI is already created
   * @function
   * @param {object} newKPI - the newly created KPI
   * @param {string} newKPI.id - the newly created KPI ID
   * @param {object} rule - the rule object returned from the KPILogicBuilder
   * @param {object[]} rule.rules - an array of rules
   * @param {string} rule.rules[].field - corresponds with the MetricTypeID for the rule
   * @param {string} rule.rules[].operator - the syncFusion rule generated from their QueryBuilder component
   * @param {string} rule.rules[].type - the syncFusion data type (needs to be converted to our "format" type)
   * @param {string} rule.rules[].value - the value that the Metrics will be compared to
   * @returns {Promise<void>}
   */
  const handleKPILogic = async (newKPI, rule) => {
    if (rule && rule.rules) {
      for (const metricTypeRule of rule.rules) {
        await createKPIMetricTypeLink.createItem({
          ownerGroup: organizationID,
          metricTypeID: metricTypeRule.field,
          keyPerformanceIndicatorID: newKPI.id,
          logic: {
            operator: metricTypeRule.operator,
            data: {
              format: metricTypeRule.type === "number" ? "decimal" : "string",
              value: metricTypeRule.value,
            },
          },
        });
      }
    }
  };

  /**
   * Callback function passed to the useForm hook. Gets called after the initial mutation completes
   * @function
   * @callback
   * @param newKPI
   * @returns {Promise<void>}
   */
  const callback = async (newKPI) => {
    if (newKPI) {
      await handleKPILogic(newKPI, kpiLogicBuilder.getRule());
    }
  };

  /**
   * Configures an instance of the useForm hook for creating KPIs
   * @type {{display: *, input: {}}}
   */
  const formHook = useForm({
    typename,
    organizationID,
    mutation: generateGraphql(typename, fields).createMutation,
    module,
    resource,
    callback,
    getNewItem,
    toggleModal,
    fieldConfig: {
      name: {
        inputType: "string",
        label: "Name",
        required: true,
      },
      description: {
        label: "Description",
      },
      metricTypes: {
        removeFromInput: true,
        inputType: "custom",
        customConfig: {
          component: kpiLogicBuilder.display,
        },
      },
    },
  });

  return {
    ...formHook,
  };
};
