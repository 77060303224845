import LinearGaugeChart from "../../../../../utils/GenericComponents/Charts/LinearGaugeChart";
import { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Breach Index component
 */
const BlackKiteBreachIndex = ({ BreachIndex }: BlackKiteResponse): JSX.Element => {
  return (
    <LinearGaugeChart
      value={BreachIndex}
      title={"Breach Index "}
      subTitle={`Data Breach Index (DBI): ${BreachIndex}`}
    />
  );
};

export default BlackKiteBreachIndex;
