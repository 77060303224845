import React from "react";

/**
 * @description Component to display items property field
 * @param {object} item - The item to display
 * @param {object} field - The field to display
 * @param {string} displayProperty - The property to display
 * @returns {JSX.Element}
 * @constructor
 */
const ItemPropertyField = ({ item, field, displayProperty }) => {
  return <>{field && displayProperty && field.hasOwnProperty(displayProperty) ? field[displayProperty] : ""}</>;
};

export default ItemPropertyField;
