import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";

/**
 * @description A custom radio button group that can be used to select a single value from a list of options
 * @param {function} onChange - the handler for setting this field value
 * @param {string} initialValue - the initial value of the field
 * @param {object[]} options - the options to be displayed in the radio group
 * @param {string} defaultValue - the default value of the field
 * @returns {JSX.Element}
 * @constructor
 */
const CustomRadioButtonGroup = ({ onChange, value: initialValue = "", options = [], defaultValue } = {}) => {
  const [value, setValue] = useState(defaultValue || initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (typeof onChange === "function") onChange(event.target.value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange} defaultValue={defaultValue}>
      {Array.isArray(options) &&
        options?.map((option) => (
          <FormControlLabel
            value={option?.value}
            control={<Radio />}
            label={option?.label}
            disabled={option?.disabled}
          />
        ))}
    </RadioGroup>
  );
};

export default CustomRadioButtonGroup;
