"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.daysUntilDate = void 0;
/**
 * Calculates the date difference between selected date and today
 * @param {object} element - the element to transform
 * @param {object} params - the parameters for the function, see ResourcePath definition for path property details
 * @return {number|null} - number of days until the date field from today or null if date is invalid
 */
const daysUntilDate = ({ element, params }) => {
    //Check arguments
    const { dateField } = params || {};
    if (!element || !dateField) {
        return null;
    }
    try {
        if (!element?.[dateField])
            return null;
        // Get the dates to calculate day difference
        const targetDate = new Date(element[dateField]);
        const today = new Date();
        // Calculate the difference in days
        const timeDelta = targetDate - today;
        return Math.ceil(timeDelta / (1000 * 60 * 60 * 24));
    }
    catch (e) {
        return null;
    }
};
exports.daysUntilDate = daysUntilDate;
