import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function onHighlightColorChange for saving Interface Highlighted Rows color
 * @param {string} value.hex - selected color value in hex
 * @param {object} value - object with selected color
 * @param {object} context - organization context
 * @param {object} preferences - a hook for getting and setting user preferences
 * @param {function} addToast - function for displaying a toast for the user
 */
export const onHighlightColorChange = ({ value, context, preferences, addToast }) => {
  if (isNullOrUndefined(value)) {
    throw new Error(`[onHighlightColorChange.js] Param: "value" is null`);
  }

  if (isNullOrUndefined(context)) {
    throw new Error(`[onHighlightColorChange.js] Param: "context" is null`);
  }

  if (isNullOrUndefined(preferences)) {
    throw new Error(`[onHighlightColorChange.js] Param: "preferences" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[onHighlightColorChange.js] Param: "addToast" is null`);
  }

  try {
    context.setHighlightColor(value?.hex);
  } catch (e) {
    addToast({
      header: `Error! Can not set Highlight Color`,
      icon: "danger",
    });

    throw new Error("[onHighlightColorChange.js] Can not set setHighlightColor");
  }

  try {
    preferences?.setPreference("interfaceOptions", {
      highlightColor: value?.hex,
    });
  } catch (e) {
    addToast({
      header: `Error! Can not set Highlight Color`,
      icon: "danger",
    });

    throw new Error("[onHighlightColorChange.js] Can not set Preferences");
  }

  addToast &&
    addToast({
      header: `Highlighted Row color was changed`,
      icon: "success",
    });
};
