import React, { useContext, useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { TEMPLATE } from "../../../../../enums/TEMPLATE";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../../utils/Context/UIContext";
import TemplateTypeField from "../../../../../utils/CustomFields/TemplateTypeField";
import { hideFilterMenuUI } from "../../../../../utils/Functions/Views/grid/hideFilterMenuUI";
import Loader from "../../../../../utils/LoadingComponents/Loader";
import { applyTemplates } from "../../../../Templates/functions/applyTemplates";
import { queryTemplates } from "../../../../Templates/functions/queryTemplates";
import { useControlSetDataGrid } from "../../ControlSets/hooks/useControlSetDataGrid";

/**
 * @description Displays Control Frameworks Templates for an Organization
 * @param {string} organizationID - the organization id
 * @param {function} onSubmitCallback - callback to run when the user selects a template
 * @param {function} getNewItem - callback to run when the user selects a template
 * @returns {{display: JSX.Element}}
 */
export const useSelectControlTemplates = ({ organizationID, onSubmitCallback, getNewItem }) => {
  const { loggedInUserId } = useContext(OrganizationContext);

  //[STATE]
  const [isLoading, setIsLoading] = useState(false);

  // Get list of control framework templates to choose from start
  const getControlFrameworkTemplates = async () => {
    setIsLoading(true);
    const controlTemplates = await queryTemplates({
      resource: resources.CONTROL_FRAMEWORK,
      userID: loggedInUserId,
    });
    if (Array.isArray(controlTemplates)) {
      controlFrameworkTemplatesGrid?.setData([...controlTemplates]);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getControlFrameworkTemplates();
  }, []);

  //[PERMISSIONS]
  const module = modules.COMPLIANCE;
  const resource = resources.CONTROL;
  //Checks for proper permissions to duplicate control templates
  const checkDuplicatePermissionsHook = useCheckPermissions({
    module,
    resource,
  });

  const { addToast, updateToast } = useContext(UIContext);

  //[CONTROL FRAMEWORK GRID SETUP]
  // - grid component that displays available templates to duplicate
  const controlFrameworkTemplatesGrid = useControlSetDataGrid({
    organizationID: TEMPLATE,
    isTemplate: true,
    gridConfig: {
      disableRoleChecking: true,
      module: modules.COMPLIANCE,
      resource: resources.CONTROL_FRAMEWORK,
      enableSelectButton: true,
      enableSearch: true,
      persistenceUUID: "select-control-framework-templates-grid",
      onSelectCallback: async (item) => {
        await duplicateControlFrameworkControls({ item });
      },
      //Hide default tags column filter menu elements
      actionComplete: (args) => hideFilterMenuUI({ args, hideAllFields: ["ownerGroup"] }),
    },
    fields: [
      {
        name: "ownerGroup",
        friendlyName: "Template Type",
        width: 250,
        component: <TemplateTypeField />,
        filter: {
          type: "CheckBox",
        },
        type: "singleSelect",
        valueOptions: [
          {
            value: TEMPLATE,
            label: "Global Templates",
          },
          {
            value: !TEMPLATE,
            label: "Operation Team Templates",
          },
        ],
      },
    ],
    cardConfig: { title: "Control Framework Templates" },
    queryConfig: {},
  });

  //[SUBMIT OPERATION]
  // Makes the request to duplicate a certain control framework template to a lambda
  const duplicateControlFrameworkControls = async ({ item }) => {
    onSubmitCallback && onSubmitCallback();

    const createdItems = await applyTemplates({
      input: {
        templatesToApply: [{ id: item?.id }],
        resource: resources.CONTROL_FRAMEWORK,
        reportProgress: true,
        userID: loggedInUserId,
        organizationID,
      },
      resourceName: "Control Framework",
      addToast,
      updateToast,
    });

    if (typeof getNewItem === "function" && Array.isArray(createdItems)) {
      for (const controlFramework of createdItems) {
        getNewItem(controlFramework);
      }
    }
  };

  //[UI RENDER]
  const display = (
    <>
      {isLoading ? (
        <div style={{ marginTop: "1em" }}>
          <Loader />
          {" Loading Control Framework Templates.  Please Wait..."}
        </div>
      ) : (
        <div style={{ height: "30em" }}>{controlFrameworkTemplatesGrid.gridDisplay}</div>
      )}
    </>
  );

  return {
    display,
  };
};
