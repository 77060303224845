import { useContext } from "react";

import { OrganizationContext, OrganizationContextValues } from "../../../../utils/Context/OrganizationContext";

export const useOrganizationContext = (): OrganizationContextValues => {
  const context = useContext(OrganizationContext);

  const organizationName = context.selectedOrganizationObjectMinimal?.name ?? "Client Organization";
  const organizationShortName = context.selectedOrganizationObjectMinimal?.shortName ?? "Client";

  return {
    ...context,
    organizationName,
    organizationShortName,
  };
};
