import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, WarningLogger } from "@utils/EventLogger";

import { useDebouncedItems } from "../../hooks/functional/useDebouncedItems";
import { useInterval } from "../../hooks/functional/useInterval";
import { useStateEffect } from "../../hooks/functional/useStateEffect";
import ContextMenu from "../../hooks/views/useContextMenu/components/ContextMenu";
import { usePreferences } from "../../hooks/views/usePreferences/usePreferences";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { useOperationTeamCheck } from "../../utils/Context/useOperationTeamCheck";
import { ItemMutation } from "../../utils/Functions/Graphql/ItemMutation";
import { eventType } from "../../views/Notifications/enums/eventType";
import { addUserActivities } from "../../views/OrganizationManager/Users/functions/addUserActivities";

import LayoutToast from "./components/LayoutToast";
import { assignToastEndTime } from "./functions/assignToastEndTime";
import { listUserEvents } from "./functions/listUserEvents";
import { needToAutoDismissToast } from "./functions/needToAutoDismissToast";

/**
 * @description Handles retrieval and management of all event types (activity and notifications)
 * @returns {import('./types/LayoutToastsHook').LayoutToastsHook} The layout toasts hook object.
 */
export const useLayoutToasts = () => {
  //[HOOKS]
  // - routes
  const history = useHistory();
  // - state
  const [newNotificationAmount, setNewNotificationAmount] = useState(0);
  const [totalNotificationAmount, setTotalNotificationAmount] = useState(0);
  const [newActivityAmount, setNewActivityAmount] = useState(0);
  const [totalActivityAmount, setTotalActivityAmount] = useState(0);
  const [toasts, setToasts] = useState([]);
  // Note: using this state to handle unpredictable async behavior with context and state (holds the next toast to update)
  const [updateItem, setUpdateItem] = useState(null);
  // - user preferences
  const { getPreference, preferences } = usePreferences();
  const context = useContext(OrganizationContext);
  // - activity event updates
  const [activityEventsToUpdate, setActivityEventsToUpdate] = useState([]);

  /**
   * Checks if the user is an operation team member.
   */
  const operationTeamCheck = useOperationTeamCheck();

  // TODO: enable once enough toast information is available while doing RISC-1977
  const [debouncedItems] = useDebouncedItems({
    values: activityEventsToUpdate,
    nestedField: "header",
    delay: 3000,
  });
  useEffect(() => {
    //find which items are ready to be sent to the database (have not been updated recently)
    const stableActivity = [];
    for (const activity of activityEventsToUpdate) {
      if (debouncedItems.hasOwnProperty(activity?.id)) {
        stableActivity.push(activity);
      }
    }

    /**
     * Get organizationID based on context
     */
    const organizationID =
      operationTeamCheck.isOperationTeamUser() && context?.operationTeamID
        ? context?.operationTeamID
        : context?.selectedOrganization;
    const userID = context?.loggedInUserId;
    if (!organizationID || !userID) return;
    const updatedIds = addUserActivities({
      userID,
      organizationID,
      activityItemsToAdd: stableActivity,
    });

    if (updatedIds && Array.isArray(updatedIds) && updatedIds.length > 0) {
      for (const id of updatedIds) {
        const tempActivityEventsToUpdate = [...activityEventsToUpdate];
        const foundIndex = tempActivityEventsToUpdate.findIndex((item) => item?.id === id);
        if (foundIndex !== -1) {
          tempActivityEventsToUpdate.splice(foundIndex, 1);
        }
        setActivityEventsToUpdate(tempActivityEventsToUpdate);
      }
    }
  }, [debouncedItems]);

  /**
   * The duration (in seconds) that a user sets in their preferences.
   * If 0, toasts never appear. If -1, toasts never go away
   */
  const [durationPref] = useStateEffect(
    getPreference && getPreference("interfaceOptions", "autoDismissDuration"),
    [preferences],
    () => {
      return getPreference && getPreference("interfaceOptions", "autoDismissDuration");
    },
  );

  //[TOAST MANIPULATION]
  //Add a new toast to the screen and events card
  const addToast = (item) => {
    //Check for proper new toast info
    if (!item) {
      WarningLogger("A null toast was not added!", true);
      return;
    }

    assignToastEndTime(item, durationPref);

    //Create the new toast object
    const id = item.id || `toast_${Math.random()}`;
    const newToast = { ...item, id };

    // Default toast type is activity (if none provided)
    if (!newToast?.type) {
      newToast.type = eventType.ACTIVITY;
    }
    // Log the user activity under the user profile
    if (newToast.type === eventType.ACTIVITY) {
      try {
        setActivityEventsToUpdate((activityEventsToUpdate) => {
          return activityEventsToUpdate.concat([
            {
              id,
              userId: context.loggedInUserId,
              header: newToast.header,
              dbID: uuid(),
              data: newToast.data,
            },
          ]);
        });
      } catch (e) {
        ErrorLogger("Failed to create a user activity entry!", e);
      }
    }
    // Default toast body is not HTML (if none provided)
    if (!newToast?.isBodyHTML) {
      newToast.isBodyHTML = false;
    }

    setToasts((toasts) => [...toasts, { ...newToast }]);
    return id;
  };

  //Remove/clear a toast from the screen and events card
  const removeToast = async (toastID) => {
    let toastToRemove;

    setToasts((currentToasts) => {
      const index = currentToasts.findIndex((item) => item?.id === toastID);

      if (index !== -1) {
        toastToRemove = { ...currentToasts[index] };

        const temp = [...currentToasts];
        temp.splice(index, 1);
        return temp;
      } else {
        WarningLogger(`Cannot remove toast because no toast exists at index - ${index}`);
      }

      return currentToasts;
    });

    //If the toast event is a notification, remove it from the backend db
    if (toastToRemove?.type === eventType.NOTIFICATION) {
      const { deleteMutation } = generateGraphql("Event");
      await ItemMutation(deleteMutation, {
        id: toastID,
      });
    }
  };

  //Change an existing toast's content
  const updateToast = (item) => {
    //Set the item to update in the front end
    setUpdateItem(item);
  };

  //Same as 'removeToast' but removes all toasts at once
  const clearToasts = async (typeToRemove) => {
    if (!typeToRemove) {
      return;
    }

    // if removing all notifications remove them from the database
    if (typeToRemove === eventType.NOTIFICATION) {
      const { deleteMutation } = generateGraphql("Event");
      const allNotifications = toasts.filter((toast) => toast.type === eventType.NOTIFICATION);
      if (allNotifications && Array.isArray(allNotifications)) {
        const deleteRequests = [];
        for (const notification of allNotifications) {
          if (notification?.id) {
            deleteRequests.push(
              ItemMutation(deleteMutation, {
                id: notification?.id,
              }),
            );
          }
        }
        await Promise.allSettled(deleteRequests);
      }
    }

    let temp = [...toasts];
    temp = temp.filter((toast) => toast.type !== typeToRemove);
    setToasts([...temp]);
  };

  // Handles update to the total number of new activity and notifications
  const markAllToastsAsSeen = async (type) => {
    let newToasts = [];
    if (toasts && Array.isArray(toasts) && toasts.length > 0) {
      newToasts = [...toasts];
    }

    const seenNotificationsIds = [];
    for (const toast of newToasts) {
      if (toast?.type === type && toast?.isNew === true) {
        toast.isNew = false;
        if (toast?.type === eventType.NOTIFICATION) {
          seenNotificationsIds.push(toast.id);
        }
      }
    }

    //Mark all encountered notifications events as seen in the backend
    if (seenNotificationsIds.length > 0) {
      const { updateMutation } = generateGraphql("Event");

      const changeRequests = [];
      for (const notificationID of seenNotificationsIds) {
        try {
          changeRequests.push(
            ItemMutation(updateMutation, {
              id: notificationID,
              hasBeenSeen: true,
            }),
          );
        } catch (e) {
          ErrorLogger("Could not mark opened notification as seen!", e);
        }
      }

      await Promise.allSettled(changeRequests);
    }

    setToasts(newToasts);
  };

  // Removes all toasts from the main screen (not from the events card)
  const dismissAllToasts = () => {
    let needsUpdate = false;
    const newToasts = [];

    toasts.forEach((item, index) => {
      if (item && item.isOnScreen) {
        needsUpdate = true;
        item.isOnScreen = false;
      }
      newToasts.push(item);
    });

    if (needsUpdate) {
      setToasts(newToasts);
    }
  };

  //[TOAST UPDATES]
  //Interval timer that handles auto dismissing of toasts (checks every 5 seconds)
  useInterval(() => {
    let needUpdate = false;

    // find all toasts that reached the end of their duration
    for (let i = toasts.length - 1; i >= 0; i--) {
      if (needToAutoDismissToast(toasts[i])) {
        // remove toast from list if past its lifetime
        needUpdate = true;
        toasts[i].isOnScreen = false;
      }
    }

    // update view with remaining toasts
    if (needUpdate) {
      setToasts([...toasts]);
    }
  }, 5000);

  //Handles content update of a single toast when the update item state is not null
  useEffect(() => {
    if (updateItem) {
      //Update the activity logging data
      const index = activityEventsToUpdate.findIndex((activity) => activity?.id === updateItem?.id);
      if (index !== -1) {
        setActivityEventsToUpdate((activityEventsToUpdate) => {
          const tempActivityEventsToUpdate = [...activityEventsToUpdate];
          tempActivityEventsToUpdate[index] = {
            ...updateItem,
            dbID: tempActivityEventsToUpdate[index]?.dbID,
          };
          return tempActivityEventsToUpdate;
        });
      }

      const foundIndex = toasts.findIndex((element) => element.id === updateItem.id);
      if (foundIndex !== -1) {
        const temp = [...toasts];
        const newToast = { ...toasts[foundIndex], ...updateItem };

        //make sure replacement toast has an end time (for auto dismiss purposes)
        assignToastEndTime(newToast, durationPref);

        temp.splice(foundIndex, 1, newToast);

        setToasts([...temp]);
      }
      setUpdateItem(null);
    }
  }, [updateItem]);

  //Handles updates of counts for different toast types and seen values
  useEffect(() => {
    let newNotificationCount = 0;
    let totalNotificationCount = 0;
    let newActivityCount = 0;
    let totalActivityCount = 0;

    if (toasts && Array.isArray(toasts) && toasts.length > 0) {
      for (const toast of toasts) {
        if (toast?.type === eventType.NOTIFICATION) {
          totalNotificationCount++;
          if (toast?.isNew === true) {
            newNotificationCount++;
          }
        }
        if (toast?.type === eventType.ACTIVITY) {
          totalActivityCount++;
          if (toast?.isNew === true) {
            newActivityCount++;
          }
        }
      }
    }

    setNewNotificationAmount(newNotificationCount);
    setTotalNotificationAmount(totalNotificationCount);
    setNewActivityAmount(newActivityCount);
    setTotalActivityAmount(totalActivityCount);
  }, [JSON.stringify(toasts)]);

  const updateNotificationToasts = async ({ path, userID }) => {
    if (!userID || !path) {
      return;
    }

    //retrieve all recent notifications
    const notifications = await listUserEvents({
      userID,
      eventType: eventType.NOTIFICATION,
    });

    //check if any new toasts need to be added
    if (notifications && Array.isArray(notifications) && notifications.length > 0) {
      for (const notification of notifications) {
        //Can stop execution with the very first notification that is found since the notifications are sorted
        const found = toasts.find((toast) => toast.id === notification.id);
        if (found) {
          return;
        }

        //Otherwise add the request as a new toast
        addToast({
          id: notification.id,
          header: notification.header || "New Notification",
          ...(notification?.body && { body: notification.body }),
          icon: "primary",
          type: notification?.type,
          data: notification?.data,
          isNew: notification?.hasBeenSeen === false,
          isBodyHTML: true,
          createdAt: notification?.createdAt,
        });
      }
    }

    //add any missing toasts (with notification flag)
  };
  const [isUpdatingNotifications, setIsUpdatingNotifications] = useState(false);
  useEffect(() => {
    //Prevent simultaneous notifications updates
    if (isUpdatingNotifications) {
      return;
    }

    setIsUpdatingNotifications(true);
    updateNotificationToasts({
      path: history?.location?.pathname,
      userID: context?.loggedInUserId,
    }).then(() => setIsUpdatingNotifications(false));
  }, [history?.location?.pathname, context?.loggedInUserId]);

  //[TOASTS GUI]
  //A list of toasts
  const display = (isInNotificationTray, type, toggleModal) => {
    //Determine the positioning of the toasts
    const toastWrapperStyle = isInNotificationTray
      ? {
          zIndex: 100000,
          maxWidth: "100%",
          width: "100%",
        }
      : {
          zIndex: 100000,
          position: "fixed",
          right: "1em",
          top: "6em",
        };

    //Removes toasts from the screen when notification tray is opened
    if (isInNotificationTray) {
      dismissAllToasts();
    }

    let filteredToasts = [...toasts];
    if (type) {
      filteredToasts = toasts.filter((item) => item?.type === type);
    }

    return (
      <div
        style={{
          ...toastWrapperStyle,
          display: durationPref === 0 || durationPref === "0" ? "none" : undefined,
        }}
      >
        <ContextMenu
          id="layout_toasts_context_menu"
          data-testid={"layout_toasts_context_menu"}
          menuItems={[
            {
              icon: "ic:outline-layers-clear",
              label: "Clear Activity",
              onClick: () => clearToasts(eventType.ACTIVITY),
              dataTestId: "layout_toasts_context_menu-clear",
            },
          ]}
        >
          {filteredToasts &&
            Array.isArray(filteredToasts) &&
            filteredToasts.map((item, index) => {
              if ((!isInNotificationTray && item && item.isOnScreen) || isInNotificationTray) {
                return (
                  <LayoutToast
                    isFirst={index === 0}
                    key={JSON.stringify(item) + index}
                    item={item}
                    index={index}
                    removeToast={() => removeToast(item?.id)}
                    isInContainer={isInNotificationTray}
                    toggleModal={toggleModal}
                  />
                );
              }
            })}
        </ContextMenu>
      </div>
    );
  };

  return {
    display,
    newNotificationAmount,
    totalNotificationAmount,
    newActivityAmount,
    totalActivityAmount,
    markAllToastsAsSeen,
    toasts,
    setToasts,
    addToast,
    updateToast,
    removeToast,
    clearToasts,
  };
};
