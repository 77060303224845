import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useAutomationDetails } from "../hooks/useAutomationDetails/useAutomationDetails";

/**
 * Component instance to display Automation Details, with details page HOC
 * @param props
 * @returns {*|JSX.Element}
 * @constructor
 */
const AutomationDetails = (props) => useAutomationDetails(props).display;

export default withDetailsPage(AutomationDetails, {
  title: "Automation Details",
});
