import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useArtifactDataGrid } from "../hooks/useArtifactDataGrid";

/**
 * A component instance of the useArtifactDataGrid.js hook
 * @param {object} props
 */
const ArtifactDataGrid = (props) => {
  return useArtifactDataGrid(props).display;
};

export default withOrganizationCheck(ArtifactDataGrid);
