import { formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

import { createEvidenceActivity } from "./createEvidenceActivity";

/**
 * Creates an Evidence Activity item when an Evidence type changes
 * @param {string} evidenceID - the db identifier of the evidence
 * @param {string} oldType - the previous type of the evidence
 * @param {string} newType - the new type of the evidence
 * @param {string} organizationID - the owner group of the evidence
 * @param {string} pointOfContactID - the db identifier of the pont of contact
 * @param {string} userID - the db identifier of the user
 * @returns {Promise<void>}
 */
export const onEvidenceTypeChange = async ({
  evidenceID,
  oldType,
  newType,
  organizationID,
  pointOfContactID,
  userID,
}) => {
  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["firstName", "lastName"]);
  const pointOfContact = await GetQuery({
    query: getPointOfContact,
    variables: { id: pointOfContactID },
  });

  await createEvidenceActivity(
    {
      type: EVIDENCE_ACTIVITY_TYPES.TYPE_CHANGED,
      data: JSON.stringify({
        userID,
        pointOfContactName: formattedName({ pointOfContact }),
        oldType,
        newType,
      }),
    },
    evidenceID,
    organizationID,
  );
};
