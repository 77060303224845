import { Button as MuiButton } from "@mui/material";
import React, { useState } from "react";

import { resources } from "@rivial-security/role-utils";

import { useSetAsyncData } from "../../../../../../../hooks/functional/useSetAsyncData";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useAccordion } from "../../../../../../../hooks/views/useAccordion/useAccordion";
import { useManyLinking } from "../../../../../../../hooks/views/useManyToManyTransfer/hooks/useManyLinking";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { getMany } from "../../../../../../../utils/Functions/getMany";
import AddOrRemoveIcon from "../../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import CreateKeyPerformanceIndicator from "../../../../../../Metrics/KeyPerformanceIndicators/components/CreateKeyPerformanceIndicator";
import DeleteKPILinkButton from "../../../../../../Metrics/KeyPerformanceIndicators/components/DeleteKPILinkButton";
import KeyPerformanceIndicatorDetails from "../../../../../../Metrics/KeyPerformanceIndicators/components/KeyPerformanceIndicatorDetails";
import KPIStatus from "../../../../../../Metrics/KeyPerformanceIndicators/customFields/KPIStatus";
import { rollupKPIs } from "../../../../../../Metrics/KeyPerformanceIndicators/functions/rollupKPIs";
import { useKeyPerformanceIndicatorGrid } from "../../../../../../Metrics/KeyPerformanceIndicators/hooks/useKeyPerformanceIndicatorGrid";

/**
 * Shows all Key Performance Indicators that are attached to a resource or its children
 * @param {object} evidence - any kpi that shows up is always initially attached to an evidence, passing this in is one of the requirements for linking new kpis
 * @param {object} control - the compliance control for which to show attached kpis (control -> evidence -> KPIs)
 * @param {object} riskControl - the risk control for which to show attached kpis (riskControl -> evidence -> KPIs)
 * @param {object} riskControlCategory - the risk control category for which to show attached kpis (risk control category -> risk control -> evidence -> KPIs)
 * @param {object} system - the system for which to show attached kpis (system -> risk control category -> risk control -> evidence -> KPIs)

 * @param {string} organizationID - the currently selected organization
 * @param {string} module - the platform module being viewed (for role checking)
 * @param {string} resource -the platform resource being viewed (for role checking)
 *
 * @param {boolean} disableEdits - if true will not allow the user to edit the linked kpis even if all other requirements are met
 * @param {function} onLinkCallback - function called on each kpi that is linked to the resource (passed through to useManyLinking)
 * @param {function} onUnlinkCallback - function called on each kpi that is unlinked from the resource (passed through to useManyLinking)
 * @param {function} resetFunction - function to call to re-query the passed in resource after linking/unlinking (passed through to useManyLinking)
 * @return {JSX.Element} - the UI that displays attached KPIs and their status in an accordion + allows the user to unlink/link KPIs if
 *  - permissions for the module/resource allow it
 *  - the evidence resource is passed in
 *  - the disableEdits prop is false
 */
const KeyPerformanceIndicators = ({
  evidence,
  control,
  riskControl,
  riskControlCategory,
  system,

  organizationID,
  module,
  resource,

  disableEdits,
  onLinkCallback,
  onUnlinkCallback,
  resetFunction,
}) => {
  const checkPermissions = useCheckPermissions({ module, resource });

  const ableToEdit = checkPermissions?.resource?.update && !disableEdits && module && resource && evidence;
  const [data, setData] = useState([]);
  const { isLoading } = useSetAsyncData({
    getData: async () => {
      let newData;
      if (system) {
        newData = await rollupKPIs({ system });
      } else if (riskControlCategory) {
        newData = await rollupKPIs({ riskControlCategory });
      } else if (control) {
        newData = await rollupKPIs({ control });
      } else if (riskControl) {
        newData = await rollupKPIs({ riskControl });
      } else if (evidence) {
        newData = getMany(evidence, "keyPerformanceIndicators", "keyPerformanceIndicator");
      }
      return newData;
    },
    setData,
    dependencies: [system, riskControl, control, evidence],
  });

  // [LINKING LOGIC]
  // - existing resources data source
  const keyPerformanceIndicatorGrid = useKeyPerformanceIndicatorGrid({
    organizationID,
  });
  // - modal body
  const keyPerformanceIndicatorLinking = useManyLinking({
    organizationID,
    item: evidence,
    field: "keyPerformanceIndicators",
    nestedField: "keyPerformanceIndicator",
    typename: "KPIEvidenceLink",
    childTypename: resources.KEY_PERFORMANCE_INDICATOR,
    existingItems: keyPerformanceIndicatorGrid?.data,
    parentConnectionIDField: "evidenceID",
    childConnectionIDField: "keyPerformanceIndicatorID",
    toggleModal: () => linkingModal?.setModalIsOpen(false),
    createResourceComponent: <CreateKeyPerformanceIndicator />,
    resetFunction,
    onLinkCallback,
    onUnlinkCallback,
  });
  // - modal
  const headerText = "Link / Unlink Key Performance Indicators";
  const linkingModal = useModal(
    <span>{headerText}</span>,
    keyPerformanceIndicatorLinking.display,
    <MuiButton title={headerText} style={{ marginTop: ".5em" }}>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: ".5em" }}>
          <AddOrRemoveIcon />
        </div>
        Link / Unlink
      </div>
    </MuiButton>,
    {
      width: "60vw",
    },
  );

  const accordion = useAccordion({
    items: data
      ? data.map((item) => {
          return {
            title: <span>{item.name}</span>,
            titleClassName: "strong",
            subTitle: <span>{item.description}</span>,
            icon: <KPIStatus status={item.status} />,
            component: <KeyPerformanceIndicatorDetails key={item?.id} item={item} tableDisplay={true} />,
            headerButtons: ableToEdit && [
              <DeleteKPILinkButton
                item={item.link}
                typename={"KPIEvidenceLink"}
                resetFunction={() => {
                  if (typeof onUnlinkCallback === "function") onUnlinkCallback(item);
                  resetFunction();
                }}
              />,
            ],
          };
        })
      : [],
  });

  return (
    <div>
      <DataLoader
        isLoading={isLoading}
        isEnoughData={data?.length > 0}
        dataMessage={"No Key Performance Indicators are attached to this resource."}
        callToAction={
          ableToEdit && {
            function: () => {
              linkingModal?.setModalIsOpen(true);
            },
            message: " to link some.",
          }
        }
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {accordion.display}
          {ableToEdit && linkingModal.modalButton}
        </div>
      </DataLoader>
      {/*The check is here to avoid a double modal*/}
      {data?.length === 0 && linkingModal.modal}
    </div>
  );
};

export default withOrganizationCheck(KeyPerformanceIndicators);
