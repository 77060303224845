/**
 * Enums for Testing Vulnerability
 */
export const VULNERABILITY_TYPE = {
  INFORMATION: "information",
  VULNERABILITY: "vulnerability",
};


export const VULNERABILITY_SEVERITY_LEVEL = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  INFO: "info",
};

/**
 * @deprecated Use TargetVulnerabilityHistoryType from @rivial-security/schema-types instead
 */
export const TargetVulnerabilityHistoryType = {
  INITIAL: "initial",
  FALSE_POSITIVE: "falsePositive",
  STATUS: "status",
  PRIORITY: "priority",
  TAG: "tag",
  ASSESSMENT: "assessment",
};

export const NotFixedStatusChangeTrend = {
  INCREASED: "increased",
  DECREASED: "decreased",
  NOT_CHANGED: "notChanged",
}

