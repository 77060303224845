import React from "react";

import { useReportDataGrid } from "./hooks/useReportDataGrid";

/**
 * @description Reports list query by itemID field
 * @param {string} itemId - id of a linked item
 * @param {object} props - params
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsGridByItemID = ({ itemId, ...props }) => {
  const reportsByItemId = /* GraphQL */ `
    query ReportsByItemId(
      $itemID: String
      $sortDirection: ModelSortDirection
      $filter: ModelReportFilterInput
      $limit: Int
      $nextToken: String
    ) {
      reportsByItemID(
        itemID: $itemID
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          createdAt
          module
          ownerGroup
        }
        nextToken
      }
    }
  `;

  const reportGrid = useReportDataGrid({
    queryConfig: {
      query: reportsByItemId,
      variables: {
        itemID: itemId,
      },
    },
    ...props,
  });

  const display = <div style={{ height: "30em" }}>{reportGrid.gridDisplay}</div>;

  return display;
};

export default ReportsGridByItemID;
