export const deleteSystemRiskLink_custom = `mutation DeleteSystemRiskLink($input: DeleteSystemRiskLinkInput!) {
  deleteSystemRiskLink(input: $input) {
    id
    ownerGroup
    system {
      id
      name
    }
    risk {
      id
      name
    }
  }
}
`;
