import { getResourceAttribute } from "../getResourceAttribute";

/**
 * Returns field definition for a given field in a type taking into account the field's context
 * @param {string} typename - the type to use to find the field definitions
 * @param {string} fieldContext - the location where the field definition is used (grid, details, form, etc.)
 * @param {string} fieldName - the field for which to get details
 * @returns {*|{}|{}|null}
 */
export const getFieldDefinition = ({ typename, fieldContext, fieldName }) => {
  //Check arguments
  if (!typename) {
    return null;
  }

  const fieldDefinitions = getResourceAttribute({
    typename,
    attribute: "fields",
  });

  if (fieldDefinitions && fieldDefinitions.hasOwnProperty(fieldName)) {
    const allContextDefinition = fieldDefinitions?.[fieldName] || {};
    let fieldDefinition = allContextDefinition?.default || {};
    if (
      fieldContext &&
      allContextDefinition.hasOwnProperty(fieldContext) &&
      typeof allContextDefinition[fieldContext] === "object"
    ) {
      fieldDefinition = {
        ...fieldDefinition,
        ...allContextDefinition[fieldContext],
      };
    }
    return fieldDefinition;
  } else {
    return {};
  }
};
