export const ssoProviderDefinition = {
  displayName: "Provider",

  operations: {
    delete: {
      single: {
        confirmDeleteNote:
          "When you delete a provider connection, users that logged in with this provider will lose access to the platform. The provider and all of its configurations will be removed immediately. This can't be undone.",
      },
    },
  },
};
