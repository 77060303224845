export const listKnowBe4TrainingEnrollmentsByCampaign = /* GraphQL */ `
  query ListKnowBe4TrainingEnrollmentsByCampaign($query: QueryListKnowBe4TrainingEnrollmentsByCampaignQueryInput!) {
    listKnowBe4TrainingEnrollmentsByCampaign(query: $query) {
      enrollment_id
      content_type
      module_name
      campaign_name
      enrollment_date
      start_date
      completion_date
      status
      time_spent
      policy_acknowledged
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`;
