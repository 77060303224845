"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTableOfContentsHeadingBlock = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Creates a single line in the table of contents
 * @param {string} heading - the heading text to place at the start
 * @param {string} headingIndex - the heading order among other headings
 * @returns {null|{sfdt: {}, anchor: string}}
 */
const generateTableOfContentsHeadingBlock = ({ heading, headingIndex }) => {
    if (!heading?.styleName || (0, func_utils_1.isNullOrUndefined)(headingIndex)) {
        return null;
    }
    const headingLevels = ["Heading 1", "Heading 2", "Heading 3"];
    const level = headingLevels.indexOf(heading.styleName) + 1;
    if (level === 0) {
        return null;
    }
    // - create the style name to use for the table of content block
    const styleName = `Toc${level}`;
    // - create the anchor to use in the table of content block and in the heading inlines
    const anchorIndex = headingIndex + 6; // During reverse engineering, found that the anchor index always begins with 6 for toc
    let anchor = anchorIndex.toString(); // Now - 6
    anchor = anchor.padStart(9, "0"); // Now - 000000006
    anchor = `_Toc${anchor}`; // Now - _Toc000000006
    const sfdt = {
        paragraphFormat: {
            styleName: styleName,
            listFormat: {},
            tabs: [
                {
                    position: 468,
                    deletePosition: 0,
                    tabJustification: "Right",
                    // tabLeader: 'Dot', // enable to show dots between heading and page number
                },
            ],
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                fieldType: 0,
                hasFieldEnd: true,
            },
            {
                characterFormat: {},
                text: ` HYPERLINK \\l "${anchor}" `,
            },
            {
                characterFormat: {},
                fieldType: 2,
            },
            {
                characterFormat: {},
                text: heading?.text,
            },
            {
                characterFormat: {},
                text: "\t",
            },
            {
                characterFormat: {},
                fieldType: 0,
                hasFieldEnd: true,
            },
            {
                characterFormat: {},
                text: ` PAGEREF${anchor} \\h `,
            },
            {
                characterFormat: {},
                fieldType: 2,
            },
            {
                characterFormat: {},
                text: " ",
            },
            {
                characterFormat: {},
                fieldType: 1,
            },
            {
                characterFormat: {},
                fieldType: 1,
            },
        ],
    };
    return {
        sfdt,
        anchor,
    };
};
exports.generateTableOfContentsHeadingBlock = generateTableOfContentsHeadingBlock;
