import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "./convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";

/**
 * Gets the final vulnerability status enum value from the status counts
 * @param {string} _value - vulnerability status value
 * @param {object} row - row data
 * @param {object} row.statusCounts - count of statuses for each target finding link
 * @return {string}
 */
export const vulnerabilityStatusValueGetter = (_value, row) => {
  const statusCounts = row?.statusCounts;
  return convertTargetVulnerabilityStatusCountsToVulnerabilityStatus({
    statusCounts,
  }).vulnerabilityStatus;
};
