import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDeleteMutation } from "../../../../../hooks/graphql/useDeleteMutation";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import ConfidentialityPerRecord from "../customFields/ConfidentialityPerRecord";
import IntegrityPerRecord from "../customFields/IntegrityPerRecord";
import DeleteInformationAsset from "../functions/DeleteInformationAsset";

/**
 * Displays the details of a specific Information Asset for risk config
 * @param props
 */
export const useInformationAssetDetails = ({
  itemId,
  organizationID,
  resetFunction,
  module = modules.RISK,
  resource = resources.INFORMATION_ASSET,
  disableRoleChecking = false,
}) => {
  const fields = ["name", "confidentialityPerRecord", "integrityPerRecord"];

  const queryFields = ["name", "confidentialityPerRecord", "integrityPerRecord"];

  const { getQuery, updateMutation } = generateGraphql("InformationAsset", queryFields);

  const deleteMutation = useDeleteMutation({
    item: { id: itemId },
    deleteFunction: DeleteInformationAsset,
    module,
    resource,
    disableRoleChecking,
    resetFunction: resetFunction,
    enableNormalButton: true,
    typename: "Information Asset",
  });

  const customFields = [
    {
      field: "confidentialityPerRecord",
      component: <ConfidentialityPerRecord {...{ resetFunction, module, resource, disableRoleChecking }} />,
    },
    {
      field: "integrityPerRecord",
      component: <IntegrityPerRecord {...{ resetFunction, module, resource, disableRoleChecking }} />,
    },
  ];

  const queryConfig = {
    query: getQuery,
    itemId,
    organizationID,
  };

  const detailsConfig = {
    fields,
    customFields,
    updateMutation,
    resetFunction,
  };

  const config = {
    otherTableRows: <span>{deleteMutation.modalButton}</span>,
  };

  return useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    disableRoleChecking,
    config,
  });
};
