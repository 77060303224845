import { schemaEntities } from "../schemaEntities";

export const stringDefinition = {
  //[TYPE]
  isPrimitive: true,
  schemaEntity: schemaEntities.GENERIC,

  //[QUERIES]
  queries: {
    functions: [],
  },
};
