import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useTargetDetails } from "../hooks/useTargetDetails";

/**
 * @description Target details components for useTargetDetails hook
 * @param {object} props
 * @constructor
 */
const TargetDetails = (props) => useTargetDetails(props).display;

export default withDetailsPage(TargetDetails, { title: "Target Details" });
