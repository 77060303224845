import { Auth } from "@aws-amplify/auth";
import { Lambda } from "aws-sdk";

import { ErrorLogger, InfoLogger } from "../../EventLogger";

/**
 * Send a request to the Lambda function
 * @param {string} functionName - the name of the lambda function to invoke
 * @param {string} invocationType - choose async or sync version of the invocation
 * NOTE: RequestResponse vs Event type - https://docs.aws.amazon.com/lambda/latest/dg/API_Invoke.html#API_Invoke_RequestSyntax
 * @param {object} payload - the input arguments into the lambda function
 * @param {function} callbackFunction - the function to call with the response from th lambda
 * @returns {Promise<Request<Lambda.InvocationResponse, AWSError>>}
 */

export const SendRequestToLambda = async ({ functionName, invocationType = "Event", payload, callbackFunction }) => {
  const credentials = await Auth.currentCredentials();

  const lambda = new Lambda({
    credentials: Auth.essentialCredentials(credentials),
    region: "us-west-2",
    httpOptions: {
      connectTimeout: 2 * 1000, // time succeed in starting the call
      timeout: 900000, // 15 min
    },
  });

  const params = {
    FunctionName: functionName,
    InvocationType: invocationType,
    Payload: payload ? JSON.stringify(payload) : undefined,
  };

  InfoLogger(`Sending Request to the ${functionName} Lambda: ${JSON.stringify(params)}`);

  return lambda.invoke(params, function (err, data) {
    if (err) {
      ErrorLogger(err, err.stack);
      callbackFunction?.(err);
    } else {
      InfoLogger(`Got response form ${functionName} Lambda. Data: ${JSON.stringify(data)}`);
      callbackFunction?.(data["Payload"]);
    }
  });
};
