import { Storage } from "@aws-amplify/storage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, WarningLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../utils/Functions/Graphql/ItemMutation";

import { useModal } from "./useModal";
import { useViewPDF } from "./useViewPDF/useViewPDF";

/**
 * @description End User License Agreement Acceptance hook
 * @param {string} loggedInUserId - The ID of the logged in user
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useLicenseAgreement = ({ loggedInUserId }) => {
  const [licenseAgreementUrl, setLicenseAgreementUrl] = useState(null);

  /**
   * Displays the license agreement
   */
  const viewPDF = useViewPDF({ url: licenseAgreementUrl });

  /**
   * Fetches the license agreement from S3 bucket
   */
  useEffect(() => {
    Storage.get("licenseAgreements/licenseAgreement.pdf")
      .then((url) => {
        setLicenseAgreementUrl(url);
      })
      .catch((e) => {
        Sentry.captureMessage(`Cannot load user agreement: ${JSON.stringify(e)}`, {
          level: "error",
        });
        ErrorLogger("[useLicenseAgreement.js] Failed to fetch license agreement", e);
      });
  }, []);

  /**
   * Updates the user's acceptance date
   */
  const submit = async () => {
    if (loggedInUserId) {
      await ItemMutation(generateGraphql("User").updateMutation, {
        id: loggedInUserId,
        acceptanceDate: new Date(),
      }).finally(() => licenseAgreementModal?.setModalIsOpen(false));
    } else {
      WarningLogger("[useLicenseAgreement.js] Missing User ID");
    }
  };

  /**
   * Modal to display the license agreement
   */
  const licenseAgreementModal = useModal(
    "License Agreement",
    <div>
      <Alert severity="info">Please read and accept the license agreement below to continue.</Alert>
      <div
        style={{
          width: "100%",
          height: "70vh",
          overflowY: "auto",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        {viewPDF.display}
      </div>

      <div className={"float-right"}>
        <Button
          data-testid={"accept-user-licence-agreement-button"}
          variant="contained"
          startIcon={<CheckCircleOutlineIcon />}
          disabled={viewPDF?.error}
          onClick={submit}
        >
          I Accept
        </Button>
      </div>
    </div>,
    null,
    {
      width: "70vw",
      disableCloseModal: true,
    },
  );

  return {
    ...licenseAgreementModal,
  };
};
