import React from "react";

import { getLongRiskControlName } from "../functions/getLongRiskControlName";

/**
 * Displays risk control information in non-standard controls grid
 * @param {object} control - risk control data
 * @return {JSX.Element}
 */
const NonStandardControlRiskControl = ({ control } = {}) => {
  let riskControlName = "Unknown Risk Control";
  if (control) {
    riskControlName = getLongRiskControlName({ control });
  }

  return <span>{riskControlName}</span>;
};

export default NonStandardControlRiskControl;
