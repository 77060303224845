import { Card, CardContent, CardHeader } from "@mui/material";
import React, { createContext, useContext, useReducer } from "react";
import { v4 as uuid } from "uuid";

import { SERIES_TYPE } from "@rivial-security/widget-utils";

import { useCreateOrSelectModal } from "../../../../../../../../../hooks/views/useCreateOrSelectModal";
import CreateButton from "../../../../../../../../../utils/GenericComponents/CreateButton";
import StyledWrapper from "../../../../../../../../../utils/GenericComponents/StyledWrapper";
import CreateCustomQuery from "../../../../../../../../CustomQueries/components/CreateCustomQuery";
import CustomQueryDataGrid from "../../../../../../../../CustomQueries/components/CustomQueryDataGrid";
import { createWidgetActions } from "../../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../../useCreateWidget";

import OutputTableNode from "./components/OutputTableNode";
import SeriesNode from "./components/SeriesNode/SeriesNode";
import SourceNode from "./components/SourceNode/SourceNode";
import { sourceTypes } from "./constants/sourceTypes";
import { chartDataBuilderReducer } from "./reducers/chartDataBuilderReducer";

export const ChartDataBuilderContext = createContext(null);
/**
 * Allows users to populate widget data slots with custom queries
 * @param {string} organizationID - the organization from which to select data
 * @returns {{series: *, display: JSX.Element}}
 */
const ChartWidgetDataGraphBuilder = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);
  const [chartDataBuilder, dispatchChartDataBuilder] = useReducer(
    chartDataBuilderReducer,
    {
      fieldSelection: null,
    },
    (initialArgs) => {
      return {
        ...initialArgs,
      };
    },
  );

  // //Create or add inputs:
  const createInputHook = useCreateOrSelectModal({
    organizationID,
    typename: "Custom Query",
    form: (
      <StyledWrapper wrapperStyle={{ height: "80vh" }}>
        <CreateCustomQuery enableSubmitButton={true} />
      </StyledWrapper>
    ),
    grid: <CustomQueryDataGrid />,
    button: <CreateButton typename={"Source"} />,
    callback: (item) => {
      //TODO: check if query is already added, do not add if so (show user alert instead)
      dispatch({
        type: createWidgetActions.UPSERT_WIDGET_SOURCE_INPUT,
        sourceInput: { id: item?.id, type: sourceTypes.CUSTOM_QUERY },
      });
      createInputHook?.toggleModal();
    },
  });

  //TODO: add a way to remove series and source nodes, disable fieldSelection and update all field references

  const series = widget?.config?.series;
  return (
    <ChartDataBuilderContext.Provider value={{ ...chartDataBuilder, dispatchChartDataBuilder }}>
      <div
        style={{
          height: "70vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: ".5em",
        }}
      >
        <Card style={{ flex: 1, maxHeight: "100%", overflow: "auto" }} variant="outlined">
          <CardHeader style={{ paddingBottom: 0 }} title={"Source"} action={createInputHook?.modalButton} />
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5em",
              }}
            >
              {Array.isArray(widget?.config?.sources) &&
                widget.config.sources.map((source) => {
                  return <SourceNode key={source?.id} source={source} />;
                })}
            </div>
          </CardContent>
        </Card>
        <Card style={{ flex: 1, maxHeight: "100%", overflow: "auto" }} variant="outlined">
          <CardHeader
            style={{ paddingBottom: 0 }}
            title={"Series"}
            action={
              <CreateButton
                typename={"Series"}
                onClick={() => {
                  dispatch({
                    type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
                    seriesInput: { id: uuid(), type: SERIES_TYPE.AUTO },
                  });
                }}
              />
            }
          />
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5em",
              }}
            >
              {Array.isArray(series) &&
                series.map((seriesItem, index) => {
                  return <SeriesNode index={index} key={seriesItem?.id} seriesItem={seriesItem} />;
                })}
            </div>
          </CardContent>
        </Card>
        <Card style={{ flex: 1, maxHeight: "100%", overflow: "auto" }} variant="outlined">
          <CardHeader title={"Output"} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: ".5em",
            }}
          >
            <OutputTableNode series={series} />
          </div>
        </Card>
      </div>
    </ChartDataBuilderContext.Provider>
  );
};

export default ChartWidgetDataGraphBuilder;
