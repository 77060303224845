import { cloneDeep } from "lodash";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Clones array of system calculations and removes unnecessary data for saving
 * @param {object} calculations - the object containing system changes to prune for saving to dynamo db
 * @returns {*} a copy of the systems with duplicated or unneeded data removed from risk calculations removed
 */
export const stripRiskRecommendationCalculationDataForSaving = ({ calculations: initCalculations }) => {
  let calculations;

  if (!isNullOrUndefined(initCalculations)) {
    calculations = cloneDeep(initCalculations);
    for (const system of calculations) {
      delete system?.systemStats?.lossExceedanceCurve;
      delete system?.riskStats;
      delete system?.monteCarloSystemInput?.risks;

      delete system?.systemRiskValues?.greatestKeyRiskIndicatorData?.controlCategory?.subControls;
      delete system?.systemRiskValues?.greatestKeyRiskIndicatorData?.lossExceedanceCurve;

      delete system?.systemRiskValues?.greatestRecommendationKeyRiskIndicatorData?.controlCategory?.subControls;
      delete system?.systemRiskValues?.greatestRecommendationKeyRiskIndicatorData?.lossExceedanceCurve;

      delete system?.systemRiskValues?.modifiedKeyRiskIndicatorData?.controlCategory?.subControls;
      delete system?.systemRiskValues?.modifiedKeyRiskIndicatorData?.lossExceedanceCurve;
    }
  }

  return calculations;
};
