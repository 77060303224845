import { getFunctionFieldName } from "@rivial-security/widget-utils";

import { genericResources } from "../../../../../../../../../definitions/constants/genericResources";
import { getFieldDefinition } from "../../../../../../../../../definitions/functions/fields/getFieldDefinition";
import { getFunctionDefinitionResult } from "../../../../../../../../../definitions/functions/getFunctionDefinitionResult";
import { fieldContexts } from "../../../../../../../../../enums/fieldContexts";
import CustomQueryNestedFieldDetails from "../../../components/CustomQueryNestedFieldDetails";
import CustomQueryNestedFieldGrid from "../../../components/CustomQueryNestedFieldGrid";

/**
 * Returns the `fields` configuration property for data grids based on the
 * current part of a query object built with query builder
 * @param {object} query - the current nested part of a query object
 * @param {boolean} disableCustomFields - whether to disable custom fields
 * @param {object} gridFieldsToMerge - the fields to merge with the query fields
 * @return {object[]} - the `fields` configuration property for data grids showing results of a custom query
 */
export const generateDataGridFieldConfigFromQuery = ({ query, disableCustomFields, gridFieldsToMerge }) => {
  let newFields = [];
  const { fields, functions, typename } = query || {};

  // Add query fields
  if (typeof fields === "object") {
    for (const fieldName in fields) {
      const field = fields[fieldName];

      const fieldDefinition = getFieldDefinition({
        typename,
        fieldName,
        fieldContext: fieldContexts.GRID,
      });
      const DisplayComponent = disableCustomFields === true ? undefined : fieldDefinition?.DisplayComponent;

      if (!field?.isNested) {
        newFields.push({
          name: fieldName,
          width: 150,
          component: DisplayComponent ? <DisplayComponent /> : null,
        });
      } else {
        if (field?.hasMany) {
          newFields.push({
            name: fieldName,
            width: 150,
            component: DisplayComponent ? <DisplayComponent /> : <CustomQueryNestedFieldGrid query={field} />,
          });
        } else {
          newFields.push({
            name: fieldName,
            width: 150,
            component: DisplayComponent ? <DisplayComponent /> : <CustomQueryNestedFieldDetails query={field} />,
          });
        }
      }
    }
  }

  // Add function fields
  if (functions && typeof functions === "object") {
    for (const functionName in functions) {
      const functionData = functions[functionName];
      const result = getFunctionDefinitionResult({ path: [], func: functionData });
      const resultType = result?.typename;
      let fieldType;
      if (resultType === genericResources.INT || resultType === genericResources.FLOAT) {
        fieldType = "number";
      }

      const functionFieldName = getFunctionFieldName({
        pathElement: functionData,
      });
      if (functionFieldName) {
        newFields.push({
          name: functionFieldName,
          width: 150,
          type: fieldType,
        });
      }
    }
  }

  // Merge in external settings if they exists
  newFields = newFields.map((field) => {
    const gridFieldToMerge = gridFieldsToMerge?.[field.name];
    return gridFieldToMerge ? { ...field, ...gridFieldToMerge } : field;
  });

  return newFields;
};
