import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateTarget } from "../hooks/useCreateTarget";

/**
 * Component instance of the useCreateTarget hook
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateTarget = (props) => {
  return useCreateTarget(props).display;
};

export default withOrganizationCheck(CreateTarget);
