/**
 * Parses a single SFDT block and recursively applies missing properties for the newest document editor to properly open and export the documents
 * @param {object} block - the object to modify in place
 * @returns {*}
 */
export const adaptSFDTBlock = ({ block }) => {
  if (!block) {
    return;
  }

  //Check if the block has any inline elements
  const { inlines } = block || {};
  if (Array.isArray(inlines)) {
    for (const inline of inlines) {
      adaptSFDTBlock({ block: inline });
    }
  }

  //Check if the block has a text box, and adjust the block properties
  const { textFrame } = block || {};
  if (typeof textFrame === "object") {
    block.textWrappingStyle = "InFrontOfText";
    block.textWrappingType = "Both";

    //Check if the text box itself has any blocks
    const { blocks } = textFrame || {};
    if (Array.isArray(blocks)) {
      for (const block of blocks) {
        adaptSFDTBlock({ block });
      }
    }
  }

  //Check if a block has an image string and its required properties
  const { imageString } = block || {};
  if (typeof imageString === "string") {
    block.name = "1";
    block.visible = true;
    block.widthScale = 100;
    block.heightScale = 100;
    block.verticalPosition = 0;
    block.verticalOrigin = "Margin";
    block.verticalAlignment = "None";
    block.horizontalPosition = 0;
    block.horizontalOrigin = "Margin";
    block.horizontalAlignment = "None";
    block.allowOverlap = true;
    block.textWrappingStyle = "Inline";
    block.textWrappingType = "Both";
    block.distanceBottom = 0;
    block.distanceLeft = 9;
    block.distanceRight = 9;
    block.distanceTop = 0;
    block.layoutInCell = true;
    block.zOrderPosition = 2147483647;
  }

  return block;
};
