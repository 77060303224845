import { VulnerabilityStatus } from "../constants/VulnerabilityStatus";

/**
 * Convert target vulnerability status counts to vulnerability status + additional summary values
 * @param {object} statusCounts - count of statuses for each target finding link
 * @return {{notFixedPercent: number, vulnerabilityStatus: string, fixedPercent: number, exceptionPercent: number}}
 */
export const convertTargetVulnerabilityStatusCountsToVulnerabilityStatus = ({ statusCounts }) => {
  let fixedPercent = 0;
  let exceptionPercent = 0;
  let notFixedPercent = 0;

  const errorReturnValue = {
    vulnerabilityStatus: VulnerabilityStatus.UNKNOWN,
    fixedPercent,
    exceptionPercent,
    notFixedPercent,
  };

  try {
    if (!statusCounts) {
      throw new Error("statusCounts is undefined");
    }

    const totalStatuses = Object.values(statusCounts).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );

    if (!totalStatuses) {
      return {
        vulnerabilityStatus: VulnerabilityStatus.UNKNOWN,
        fixedPercent,
        exceptionPercent,
        notFixedPercent,
      };
    }

    fixedPercent = ((statusCounts["fixed"] || 0) / totalStatuses) * 100;
    exceptionPercent = ((statusCounts["exception"] || 0) / totalStatuses) * 100;
    notFixedPercent = ((statusCounts["notFixed"] || 0) / totalStatuses) * 100;

    notFixedPercent = Math.round(notFixedPercent);
    exceptionPercent = Math.round(exceptionPercent);
    fixedPercent = Math.round(fixedPercent);

    //Generate the text description
    let vulnerabilityStatus = VulnerabilityStatus.UNKNOWN;
    if (notFixedPercent === 100) {
      vulnerabilityStatus = VulnerabilityStatus.NOT_FIXED;
    } else if (notFixedPercent > 50) {
      vulnerabilityStatus = VulnerabilityStatus.PARTIALLY_FIXED;
    } else if (notFixedPercent > 0.001) {
      vulnerabilityStatus = VulnerabilityStatus.MOSTLY_FIXED;
    } else if (notFixedPercent === 0) {
      vulnerabilityStatus = VulnerabilityStatus.FIXED;
    }

    return {
      vulnerabilityStatus,
      notFixedPercent,
      exceptionPercent,
      fixedPercent,
    };
  } catch (e) {
    return errorReturnValue;
  }
};
