import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useMitreAttackDataSourcesDetails } from "../hooks/useMitreAttackDataSourcesDetails";

/**
 * @description Display Mitre Attack Detection details component
 * @param {object} props - React props for the component
 * @returns {JSX.Element}
 * @constructor
 */
const MitreAttackDataSourceDetails = (props) => useMitreAttackDataSourcesDetails(props).display;

export default withDetailsPage(MitreAttackDataSourceDetails, {
  title: "Detection Details",
});
