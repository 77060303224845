import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

export const setRivialDefaultKRIMapping = async (item, mutation, isInsideSystem) => {
  const currentValues = {
    dataBreach: false,
    systemDisruption: false,
    facilityDisruption: false,
    fraud: false,
    malware: false,
    vendor: false,
    compliance: false,
  };

  const setDataBreach = (res) => {
    currentValues.dataBreach = res;
  };

  const setSystemDisruption = (res) => {
    currentValues.systemDisruption = res;
  };

  const setFacilityDisruption = (res) => {
    currentValues.facilityDisruption = res;
  };

  const setFraud = (res) => {
    currentValues.fraud = res;
  };

  const setMalware = (res) => {
    currentValues.malware = res;
  };

  const setVendor = (res) => {
    currentValues.vendor = res;
  };

  const setCompliance = (res) => {
    currentValues.compliance = res;
  };

  const riskName = (isInsideSystem && item.risk && item.risk.name) || (!isInsideSystem && item.name);
  if (riskName) {
    switch (riskName) {
      case "01 Unauthorized Application Access":
        setDataBreach(true);
        setSystemDisruption(true);
        setFraud(true);
        setVendor(true);
        break;
      case "02 Unauthorized Database Access":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "03 Unauthorized Operating System Access":
        setDataBreach(true);
        setSystemDisruption(true);
        setVendor(true);
        break;
      case "04 Unauthorized VM/SAN Access":
        setDataBreach(true);
        setSystemDisruption(true);
        setVendor(true);
        break;
      case "05 Unauthorized Network Access":
        setDataBreach(true);
        setSystemDisruption(true);
        setVendor(true);
        break;
      case "06 Unauthorized Physical Access":
        setSystemDisruption(true);
        setFacilityDisruption(true);
        setVendor(true);
        break;
      case "07 Malicious Code":
        setDataBreach(true);
        setFraud(true);
        setMalware(true);
        setVendor(true);
        break;
      case "08 System Vulnerabilities":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "09 Application Software Weaknesses":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "10 Unapproved Changes":
        setDataBreach(true);
        setVendor(true);
        break;
      case "11 Disaster Events":
        setSystemDisruption(true);
        setFacilityDisruption(true);
        setVendor(true);
        break;
      case "12 Vendor Breach":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "13 Security Breach":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "14 User Error":
        setDataBreach(true);
        setFraud(true);
        setVendor(true);
        break;
      case "15 Confidential Data Loss":
        setDataBreach(true);
        setVendor(true);
        break;
      case "16 Unauthorized Devices and Software":
        setDataBreach(true);
        setVendor(true);
        break;
      case "17 Security Program Failure":
        setCompliance(true);
        break;
      default:
        return null;
    }

    let input = {
      id: item.id,
      defaultRiskMapping: {
        ...currentValues,
      },
    };
    if (isInsideSystem) {
      input = {
        id: item.id,
        riskMapping: {
          ...currentValues,
        },
      };
    }

    return await ItemMutation(mutation, input);
  } else {
    ErrorLogger("Unable to change KRI mappings to Rivial defaults!");
    return null;
  }
};
