import { generateGraphqlFields } from "@rivial-security/generategraphql";

import { ListQueryBy } from "../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Retrieves a list of the most recent notifications for a specific user
 * @param {string} userID - the database id field for the user for which to get notifications
 * @param {string} eventType - the type of event to get (ie activity or notification), see the schema for valid types
 * @param {string[]} fields - the fields to retrieve for the events list by default gets all fields
 */
export const listUserEvents = async ({
  userID,
  eventType,
  fields = ["id", "header", "body", "type", "hasBeenSeen", "data", "updatedAt", "createdAt"],
}) => {
  const listNotificationsByUser = /* GraphQL */ `
  query EventByUser(
    $userID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByUser(
      userID: $userID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${generateGraphqlFields(fields)}
      }
    }
  }`;

  if (!userID) {
    return [];
  }

  const result = await ListQueryBy({
    query: listNotificationsByUser,
    variables: {
      userID,
      sortDirection: "DESC",
    },
    limit: 100,
    filter: {
      type: {
        eq: eventType,
      },
    },
  });

  return result;
};
