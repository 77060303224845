"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganization = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
/**
 * Returns organization risk information for loss tolerance widget
 * @param {string} organizationID - the organization for which to retrieve data
 * @return {Promise<object|null>} - organization risk information, null if failed to get
 */
const getOrganization = async ({ organizationID }) => {
    if (!organizationID) {
        return null;
    }
    try {
        const { getQuery } = (0, generategraphql_1.generateGraphql)("Organization", ["config", "riskSetup", "lossToleranceCurve"], {
            riskSetup: `{
      high
      mediumHigh
      medium
      lowMedium
      low
    }`,
            lossToleranceCurve: `{
      amount
      probability
    }`,
        });
        return await (0, appsync_utils_1.GetQuery)({
            query: getQuery.replace(/ownerGroup/g, ""),
            variables: {
                id: organizationID,
            },
        });
    }
    catch (e) {
        console.log(e);
        return null;
    }
};
exports.getOrganization = getOrganization;
