import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import isRestricted from "../../../../utils/AccessControl/functions/isRestricted";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import AccessControlStatus from "../components/AccessControlStatus";
import MeetingDetails from "../components/MeetingDetails";
import ScheduleMeeting from "../components/MeetingForm";
import { MeetingIsAllDay } from "../customFields/MeetingIsAllDay";
import MeetingStatus from "../customFields/MeetingStatus";
import { MeetingTime } from "../customFields/MeetingTime";
import { createMeetingTagLink } from "../functions/createMeetingTagLink";
import { deleteMeeting } from "../functions/deleteMeeting";

const { updateMutation: updateMeeting, listByQuery: listMeetingsByOrganization } = generateGraphql(
  "Meeting",
  [
    "name",
    "description",
    "startTime",
    "endTime",
    "status",
    "isAllDay",
    "pointOfContacts",
    "organizer",
    "location",
    "duration",
    "linkedResources",
    "accessControl",
    "tags",
    "module",
  ],
  {
    pointOfContacts: `(limit: 100) { items {  id pointOfContact { id firstName lastName title email ownerGroup  }} }`,
    accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
  },
  {
    indexName: "meetingsByOwnerGroup",
  },
);

/**
 * Displays a list of meetings
 * @param {string} organizationID - the currently selected organization id
 * @param {object} gridConfig - configuration object for the grid
 * @param {object} cardConfig - configuration object for the grid container card
 * @param {object} queryConfig - configuration object for the grid query
 * @param {object[]} fields - fields to destructure into the default fields for the grid (appends but not replaces)
 * @param {object[]} additionalFields - fields to merge with the default fields by name
 * @param {string} module - the platform module to use for frontend role checking
 * @param {string} resource - the platform resource to use for frontend role checking
 * @param {object} props - any additional props to pass through to the useDataGrid hook
 * @returns {{gridDisplay: JSX.Element, data: *, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element, isLoading: boolean, createResourceComponent: "preserve" | "react" | "react-native", ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: (((prevState: undefined) => undefined) | undefined)) => void, selectedItems: []}}
 */
export const useMeetingsDataGrid = ({
  organizationID,
  gridConfig = {},
  cardConfig = {},
  queryConfig = {},
  additionalFields = [],
  fields = [],
  module = modules.GOVERNANCE,
  resource = resources.MEETING,
  ...props
}) => {
  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: listMeetingsByOrganization,
    organizationID,
    variables: {
      ownerGroup: organizationID,
    },
    module,
    resource,
    ...queryConfig,
  };

  cardConfig = {
    title: "Meetings",
    headerIcon: "icon-people",
    enableSticky: true,
    ...cardConfig,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      width: 300,
      minWidth: 300,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      width: 350,
      bulkEdit: true,
    },
    {
      name: "accessControl",
      friendlyName: "Access Control Status",
      hidden: true,
      width: 175,
      component: <AccessControlStatus />,
      valueGetter: (_value, row) => {
        return isRestricted(row) ? "Restricted" : "Not Restricted";
      },
    },
    {
      name: "status",
      component: <MeetingStatus />,
      disablePropagation: true,
      allowFiltering: true,
      filter: {
        type: "CheckBox",
      },
      width: 175,
      bulkEdit: true,
    },
    {
      name: "startTime",
      component: <MeetingTime timeFieldName={"startTime"} />,
      width: 300,
      bulkEdit: true,
      type: "date",
    },
    {
      name: "endTime",
      component: <MeetingTime timeFieldName={"endTime"} />,
      width: 300,
      bulkEdit: true,
      type: "date",
    },
    {
      name: "isAllDay",
      component: <MeetingIsAllDay />,
      hidden: true,
      width: 300,
      valueGetter: (value) => {
        return value ? "Yes" : "No";
      },
      bulkEdit: true,
      type: "boolean",
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createMeetingTagLink,
      width: 200,
      bulkEdit: true,
    },
    {
      name: "module",
      width: 150,
      type: GENERIC_FIELD_TYPES.MODULE,
      disablePropagation: true,
      defaultValue: module || modules.GOVERNANCE,
      filter: {
        type: "CheckBox",
      },
      isHidden: true,
    },

    ...fields,
  ];
  mergeAdditionalFields({ fields, additionalFields });
  gridConfig = {
    fields,
    options: ["details", "duplicate", "delete", "edit"],
    createResourceComponent: (
      <ScheduleMeeting organizationID={organizationID} enableTemplates={gridConfig?.enableTemplates} />
    ),
    createResourceComponentWidth: "60vw",
    createItemModalHeader: "Schedule Meeting",
    updateMutation: updateMeeting,
    deleteFunction: deleteMeeting,
    route: "#/governance/meetings/",
    detailsComponent: <MeetingDetails tableDisplay={true} />,
    detailsTitle: "Meeting Details",
    typename: "Meeting",
    duplicationSettings,
    persistenceUUID: "0c2d7c45-62b5-4228-a46a-9c564e9b4f1b",
    organizationID,
    ...gridConfig,
    ...props,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
  });

  return { ...queryCard };
};

/**
 * Configures duplicationSettings for this meeting grid
 */
const duplicationSettings = {
  description: "Duplicates a Meeting with its basic fields. Also includes Agenda Items, Notes, and Points of Contact.",
  enabled: true,
  fields: [
    "name",
    "description",
    "status",
    "startTime",
    "endTime",
    "isAllDay",
    "duration",
    "location",
    "organizer",
    "agendaItems",
    "agenda",
    "notes",
    "pointOfContacts",
  ],
  nestedFields: {
    pointOfContacts: ` (limit: 100) {
        items {
          id
          ownerGroup
          createdAt
          updatedAt
          pointOfContact {
            id
            firstName
            lastName
            title
            email
            phone
            ownerGroup
          }
        }
        nextToken
      }`,
    agendaItems: ` (limit: 100) {
        items {
          id
          content
          ownerGroup
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
        }
        nextToken
      }`,
    agenda: `{
      id
      content
      completed
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }`,
    notes: `{
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }`,
  },
  primaryField: "name",
  connectionAdaptor: {
    pointOfContacts: {
      connectionTypename: "PointOfContactMeetingLink",
      itemConnectionIDField: "pointOfContactMeetingLinkMeetingId",
      childConnectionField: "pointOfContact",
      childConnectionIDField: "pointOfContactMeetingLinkPointOfContactId",
    },
    agendaItems: {
      connectionTypename: "AgendaItem",
      itemConnectionIDField: "meetingAgendaItemsId",
    },
  },
};
