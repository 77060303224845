import { useState } from "react";

import { useModal } from "../../../../../hooks/views/useModal";
import { eventType } from "../../../../../views/Notifications/enums/eventType";
import EventsCard from "../../../../../views/Notifications/EventsCard";

/**
 * A modal used to display list of notifications and activities
 * @return {{setModalIsOpen: *, modalButton: *, modalIsOpen: *, modal: *, toggleModal: function(): void}}
 */
export const useNotificationsModal = () => {
  const [lastOpenedTab, setLastOpenedTab] = useState(eventType.ACTIVITY);
  const notificationModal = useModal("Events", <EventsCard onOpenTabCallback={setLastOpenedTab} />, null, {
    width: "32vw",
    cornerModal: true,
    clearBackdrop: true,
  });

  return notificationModal;
};
