import { useEffect, useState } from "react";

import { usePreferences } from "@hooks/views/usePreferences/usePreferences";

/** @returns {import('./types/useLayoutPreferences').LayoutPreferences} */
export const useLayoutPreferences = () => {
  const { preferences, getPreference, setPreference } = usePreferences();

  const [safeDelete, setSafeDelete] = useState(true);
  const [loaderStyle, setLoaderStyle] = useState("rivialLoader");
  const [defaultRoute, setDefaultRoute] = useState("/");

  useEffect(() => {
    if (preferences) {
      const safeDelete = getPreference("interfaceOptions", "safeDelete");
      if (safeDelete !== null) {
        setSafeDelete(safeDelete);
      }

      const loaderStylePref = getPreference("interfaceOptions", "loaderStyle");
      if (loaderStylePref !== null) {
        setLoaderStyle(loaderStylePref);
      }
    }

    const defaultRoutePref = getPreference("interfaceOptions", "defaultRoute");
    if (defaultRoutePref !== null) {
      setDefaultRoute(defaultRoutePref);
    }
  }, [preferences, getPreference]);

  return {
    safeDelete,
    setSafeDelete,
    loaderStyle,
    setLoaderStyle,
    defaultRoute,
    setDefaultRoute,
    getPreference,
    setPreference,
  };
};
