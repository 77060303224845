import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useCustomResourceEntryDetails } from "../hooks/useCustomResourceEntryDetails";

/**
 * Component for displaying the details of a Custom Resource Entry
 * @param props
 * @returns {*}
 * @constructor
 */
const CustomResourceEntryDetails = (props) => useCustomResourceEntryDetails(props).display;

export default withDetailsPage(CustomResourceEntryDetails);
