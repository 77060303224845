"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPieChart = void 0;
const createPieChart = ({ title = "", chartConfig = {}, data }) => {
    /*
          data: [
            {
              name: "High", // required
              value: 5,     // required
              fillColor: "red",  // optional. Chart will try to use fillColor / color / randomColor in that order
              lineColor: "red",  // optional. Chart will try to use lineColor / color / randomColor in that order
              color: "red         // optional. Chart will use this for fillColor and/or lineColor if one of those aren't specified
            },
            {
              name: "Medium",
              value: 10,
            }
          ]
     */
    const { gapWidth, height, width } = chartConfig;
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                chartLegend: {
                    position: "Bottom",
                    chartTitleArea: {
                        fontName: "+mn-lt",
                        fontSize: 9,
                        layout: {
                            layoutX: 0,
                            layoutY: 0,
                        },
                        dataFormat: {
                            fill: {
                                foreColor: "000000",
                                rgb: "#000000",
                            },
                            line: {
                                color: "808080",
                                rgb: "#808080",
                            },
                        },
                    },
                },
                chartTitleArea: {
                    fontName: "+mn-lt",
                    fontSize: 14,
                    layout: {
                        layoutX: 0,
                        layoutY: 0,
                    },
                    dataFormat: {
                        fill: {
                            foreColor: "000000",
                            rgb: "#000000",
                        },
                        line: {
                            color: "000000",
                            rgb: "#000000",
                        },
                    },
                },
                chartArea: {
                    foreColor: "#FFFFFFFF",
                },
                plotArea: {
                    foreColor: "#000000FF",
                },
                chartCategory: getChartCategoryData(data),
                chartSeries: [
                    {
                        dataPoints: getChartDataPoints(data),
                        seriesName: `${title}`,
                    },
                ],
                chartPrimaryCategoryAxis: {
                    chartTitle: null,
                    chartTitleArea: {
                        layout: {},
                        dataFormat: {
                            fill: {},
                            line: {},
                        },
                    },
                    categoryType: "Automatic",
                    fontSize: 11,
                    fontName: "Calibri",
                    numberFormat: "General",
                    maximumValue: 0,
                    minimumValue: 0,
                    majorUnit: 0,
                    hasMajorGridLines: false,
                    hasMinorGridLines: false,
                    majorTickMark: "TickMark_Outside",
                    minorTickMark: "TickMark_None",
                    tickLabelPosition: "TickLabelPosition_NextToAxis",
                },
                chartPrimaryValueAxis: {
                    chartTitle: null,
                    chartTitleArea: {
                        layout: {},
                        dataFormat: {
                            fill: {},
                            line: {},
                        },
                    },
                    fontSize: 11,
                    fontName: "Calibri",
                    maximumValue: 0,
                    minimumValue: 0,
                    majorUnit: 0,
                    hasMajorGridLines: false,
                    hasMinorGridLines: false,
                    majorTickMark: "TickMark_Outside",
                    minorTickMark: "TickMark_None",
                    tickLabelPosition: "TickLabelPosition_NextToAxis",
                },
                chartTitle: `${title}`,
                chartType: "Pie",
                gapWidth: gapWidth || 0,
                overlap: 0,
                height: height || 252,
                width: width || 432,
            },
            {
                characterFormat: {},
                bookmarkType: 0,
                name: "_GoBack",
            },
            {
                characterFormat: {},
                bookmarkType: 1,
                name: "_GoBack",
            },
        ],
    };
};
exports.createPieChart = createPieChart;
const getChartCategoryData = (data = []) => {
    const res = [];
    for (const item of data) {
        res.push({
            chartData: [{ yValue: item.value }],
            categoryXName: item.name,
        });
    }
    return res;
};
const getChartDataPoints = (data = []) => {
    const res = [];
    const splitColor = (str) => {
        return str && str.split("#") && str.split("#")[1] ? str.split("#")[1] : "";
    };
    for (const item of data) {
        const randomColor = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
        res.push({
            fill: {
                foreColor: splitColor(item.fillColor) || splitColor(item.color) || splitColor(randomColor),
                rgb: item.fillColor || item.color || randomColor,
            },
            line: {
                color: splitColor(item.lineColor) || splitColor(item.color) || splitColor(randomColor),
                rgb: item.lineColor || item.color || randomColor,
            },
        });
    }
    return res;
};
