import React from "react";

/**
 * @description place holder for an empty field
 * @type {JSX.Element}
 */
//Used with fields that have null or undefined values
export const emptyPlaceholder = (
  <span title="This field has no data" style={{ fontStyle: "italic", color: "lightGrey" }}>
    empty
  </span>
);
/**
 * @description place holder text for an invalid field
 * Used with fields that have null or undefined values.
 * @type {JSX.Element}
 */
//Used with fields that cause an error while being displayed
export const invalidPlaceholder = (
  <span title="This field has invalid data" style={{ fontStyle: "italic", color: "lightCoral" }}>
    invalid
  </span>
);
