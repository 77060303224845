import "react-grid-layout/css/styles.css";
import "./i18n/i18n";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { registerLicense } from "@syncfusion/ej2-base";
import { createBrowserHistory } from "history";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import log from "loglevel";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { ErrorLogger } from "@utils/EventLogger";

import client from "./apolloClient";
import App from "./application/App/App";
import { AmplifyEnv, LAUNCHDARKLY_CLIENT_SIDE_ID, SENTRY_DSN, SYNCFUSION_LICENSE_KEY } from "./env-config";

log.setLevel(AmplifyEnv === "prod" ? "warn" : "trace");

// Registering Syncfusion license key
registerLicense(SYNCFUSION_LICENSE_KEY);

const history = createBrowserHistory();

/**
 * Initialize Sentry.io error reporting.
 */
Sentry.init({
  dsn: SENTRY_DSN,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: AmplifyEnv === "prod" ? 0.3 : 1.0,
  environment: AmplifyEnv,
  release: `platform-${PLATFORM_VERSION}`,
  maxBreadcrumbs: 5,

  // Called for message and error events
  beforeSend(event) {
    if (AmplifyEnv === "prod") {
      return event;
    } else {
      return null;
    }
  },

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // react router setup
  integrations: [
    new Sentry.Replay({
      maskAllInputs: true,

      //TODO: remove once fixed, updating didn't fix issue for me
      //https://github.com/getsentry/sentry-javascript/issues/6923
      useCompression: false,
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation({ history }),
    }),
  ],

  ignoreErrors: [
    "Non-Error exception captured with keys: error, provider",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.", //Happens for every user, throughout the app but is not fatal. We should investigate further when more time is available.
    "Cannot read properties of undefined (reading 'querySelector')",
    "Non-Error promise rejection captured with value: No current user",
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:2, MethodName:update, ParamCount:4",
  ],
});

if (!AmplifyEnv) {
  const errorMessage = "AmplifyEnv is not set";
  Sentry.captureMessage(errorMessage);
  ErrorLogger(errorMessage);
}

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer!);

void (async (): Promise<void> => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID,
    options: {
      bootstrap: "localStorage",
    },
  });

  root.render(
    <StrictMode>
      <LDProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </LDProvider>
    </StrictMode>,
  );
})();
