import { Tooltip } from "@mui/material";

export interface DisabledWrapperType {
  dataTestID?: string; // data-test-id to apply to the wrapper
  message?: string; // "Read Only" - the tooltip message to display if disabled, empty string won't show a tooltip
  isDisabled?: boolean; // if TRUE will apply the greyed out filter and disable the element
  isInteractable?: boolean; // if TRUE and isDisabled is TRUE will still allow the element to be clicked even though it's disabled
  greyscalePercentage?: number; // value from 0 to 100 to apply to the css greyscale filter
  opacityPercentage?: number; // value from 0 to 100 to apply to the css opacity filter
  children: JSX.Element; // the children to wrap
}

/**
 * A wrapper to use around a single element that should be conditionally disabled (but still shown)
 */
export const DisabledWrapper = ({
  dataTestID,
  message = "Read Only",
  isDisabled = false,
  isInteractable = false, //only takes effect when isDisabled is true
  greyscalePercentage = 60,
  opacityPercentage = 100,
  children,
}: DisabledWrapperType): JSX.Element => {
  if (!isDisabled) {
    return (
      <div data-testid={dataTestID} style={{ height: "100%" }}>
        {children}
      </div>
    );
  }

  return (
    <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{message ?? "Read Only"}</div>}>
      <div data-testid={dataTestID} style={{ height: "100%" }}>
        <span
          style={{
            filter: `grayscale(${greyscalePercentage}%) opacity(${opacityPercentage}%)`,
            ...(!isInteractable && { pointerEvents: "none" }),
          }}
          onClick={(e) => {
            if (!isInteractable) {
              e.stopPropagation();
            }
          }}
        >
          {children}
        </span>
      </div>
    </Tooltip>
  );
};
