import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Get control framework
 * @param {object} controlFramework - control framework object
 * @param {function} setControlFramework - setter for control framework
 * @param {function} setFrameworkCustomFields - setter for framework custom fields
 * @param {function} setHeaderObjects - header object for csv importer
 */

export const getControlFramework = ({
  controlFramework,
  setControlFramework,
  setFrameworkCustomFields,
  setHeaderObjects,
}) => {
  /**
   * Validate params
   */
  if (isNullOrUndefined(controlFramework)) throw Error("Invalid param, missing controlFramework object");
  if (isNullOrUndefined(setControlFramework)) throw Error("Invalid param, missing setControlFramework");
  if (isNullOrUndefined(setFrameworkCustomFields)) throw Error("Invalid param, missing setFrameworkCustomFields");
  if (isNullOrUndefined(setHeaderObjects)) throw Error("Invalid param, missing setHeaderObjects");

  if (controlFramework) {
    setControlFramework(controlFramework);

    if (controlFramework.customFields) {
      setFrameworkCustomFields(controlFramework.customFields);

      const fields = [];

      fields.push(
        {
          title: "statementNumber",
          type: "text",
        },
        {
          title: "name",
          type: "text",
        },
        {
          title: "inPlace",
          type: "dropdown",
          source: ["true", "false"],
        },
      );

      /**
       * Build framework custom fields for the CSV importer
       */
      for (const field of controlFramework.customFields) {
        fields.push({ title: field.name });
      }

      /**
       * Add notes field to importer
       */
      fields.push({
        title: "notes",
        type: "text",
      });

      setHeaderObjects((headerObjects) => headerObjects.concat(fields));
    }
  }
};
