export const listReportsToOpenByOwnerGroup = /* GraphQL */ `
  query ReportsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        type
        module
        documents(limit: 100) {
          items {
            id
            name
            createdAt
            ownerGroup
            file {
              key
            }
          }
        }
        createdAt
        updatedAt
        ownerGroup
      }
      nextToken
    }
  }
`;
