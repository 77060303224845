import { format } from "date-fns";
import React, { useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { useOrganizationContext } from "../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { useCreateCustomTemplate } from "../../CustomTemplates/hooks/useCreateCustomTemplate";
import UploadReport from "../../UploadReport";

/**
 * Tab settings for the 'save' tab content in the document editor drawer file menu
 * @param {object} documentEditorContainerRef - the document editor container reference
 * @param {string} type - document type (shows up in the name field)
 * @param {string} module - module name for the document
 * @param {string} description - document description
 * @param {string} organizationID - currently selected organization
 */
export const useDocumentEditorSaveTab = ({
  documentEditorContainerRef,
  type = "Custom Report",
  module = "Custom",
  description = "A really cool Custom Report",
  organizationID,
} = {}) => {
  /// [STATE & CONTEXT]
  const organizationContext = useOrganizationContext();
  const [filename, setFilename] = useState(
    `${type} (${format(new Date(), "yyyy-MM-dd")}${
      organizationContext?.organizationShortName ? ` ${organizationContext.organizationShortName}` : ""
    })`,
  );

  /// [PERMISSION CHECKS]
  const checkPermissions = useCheckPermissions({
    module: modules.REPORTS,
    resource: resources.REPORT,
  });

  /// [EXPORT ACTIONS]
  const saveWordDoc = () => {
    const ref = documentEditorContainerRef?.current;
    return ref?.documentEditor && ref.documentEditor.save(filename, "Docx");
  };

  const saveSfdtDoc = () => {
    const ref = documentEditorContainerRef?.current;
    return ref?.documentEditor && ref.documentEditor.save(filename, "Sfdt");
  };

  const savePdfDoc = async () => {
    try {
      const ref = documentEditorContainerRef?.current;
      const win = window.open("", "print", `height=${window.outerHeight},width=${window.outerWidth},tabbar=no`);
      win.document.write(`<title>${filename}</title>`);
      ref?.documentEditor?.print(win);
    } catch (e) {
      ErrorLogger("Error! Can not export a document to PDF");
    }
  };

  // modal to allow the user to save a Report document to the database
  const saveReportModal = useModal(
    "Save a Report",
    <UploadReport
      name={filename}
      description={description || "A really cool Custom Report"}
      module={module || "Custom"}
      type={type || "Custom"}
    />,
  );

  // - save to custom templates
  const createCustomTemplate = useCreateCustomTemplate({
    ref: documentEditorContainerRef?.current,
    organizationID,
    toggleModal: () => {
      createCustomTemplateModal.setModalIsOpen(false);
    },
    defaultValues: {
      name: filename,
    },
  });
  const createCustomTemplateModal = useModal("Create a Custom Template", createCustomTemplate.display);

  /// [FILE NAME INPUT]
  const handleFilenameChange = (newValue) => {
    if (newValue?.filename === undefined || newValue?.filename === null) {
      return;
    }
    setFilename(newValue.filename);
  };

  const useSaveForm = useForm({
    fieldConfig: {
      filename: {
        defaultValue: filename,
        onChangeFunction: handleFilenameChange,
        inputType: "text",
        label: "File Name",
        tooltip: "The file name to use in exports and save operations.",
        tooltipPlacement: "bottom",
        required: true,
      },
    },
    disableResetButton: true,
    disableSubmitButton: true,
  });

  /// [TAB CONFIG]
  const tab = {
    label: "Save",
    icon: getIcon("fa-solid:save"),
    prefixComponent: (
      <div>
        {useSaveForm.display}
        {saveReportModal.modal}
        {createCustomTemplateModal.modal}
      </div>
    ),
    items: [
      {
        headline: "Save to the Reports Module",
        subtitle: "Saves a copy of the current report into the reports section of the Platform.",
        icon: getIcon("ion:documents-outline"),
        showBadge: true,
        visible: checkPermissions.resource.create,
        onClick: () => {
          saveReportModal.setModalIsOpen(true);
        },
      },
      {
        headline: "Save as a Custom Template",
        subtitle:
          "Saves the document to be reused in multiple contexts, the template will appear in the create file menu.",
        icon: getIcon("carbon:template"),
        iconBackgroundColor: "#ff6a00",
        showBadge: true,
        visible: checkPermissions.resource.create,
        onClick: () => {
          createCustomTemplateModal.setModalIsOpen(true);
        },
      },
      {
        headline: "Export as a Word Document",
        subtitle: "Exports the opened content as a Microsoft Word document onto your local computer.",
        icon: getIcon("bi:file-earmark-word"),
        iconBackgroundColor: "#2a5490",
        onClick: saveWordDoc,
      },
      {
        headline: "Print / Export as a PDF File",
        subtitle:
          "Allows to print a document or exports the opened content as a PDF document onto your local computer.",
        icon: getIcon("bi:file-earmark-pdf"),
        iconBackgroundColor: "#be0909",
        onClick: savePdfDoc,
      },
      {
        headline: "Export as a SFDT File",
        subtitle:
          "Exports the document in the Platform's native format. Use this only if you are planning to re-import the document into the Platform in the future.",
        icon: getIcon("carbon:import-export"),
        iconBackgroundColor: "#00b402",
        onClick: saveSfdtDoc,
      },
    ],
  };

  return {
    tab,
  };
};
