"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomQueryTypename = void 0;
/**
 * A function responsible for determining the root typename of the custom query. It uses all possible sources of data to determine the typename.
 * @param {object} query - query information as it is stored in the database
 * @param {object} queryConfig - the query configuration object created by the user with query builder
 * @param {string|null} fallback - the typename to use if no other source of data is available
 * @return {string|null} - the typename of the root query
 */
const getCustomQueryTypename = ({ query, queryConfig: initQueryConfig, fallback = null }) => {
    //Determine the typename data exists
    if (typeof query !== "object" && !initQueryConfig) {
        return fallback;
    }
    //Determine the config object to use
    let queryConfig = query?.queryConfig;
    if (!queryConfig && initQueryConfig) {
        queryConfig = initQueryConfig;
    }
    //Parse the config object if it is not already
    if (typeof queryConfig === "string") {
        try {
            queryConfig = JSON.parse(queryConfig);
        }
        catch (e) {
            queryConfig = {};
        }
    }
    if (typeof queryConfig !== "object") {
        queryConfig = {};
    }
    //If a root query it will have the typename as the only field in the first level of the config
    const fields = queryConfig?.fields || {};
    if (Object.keys(fields).length === 1) {
        return Object.keys(fields)[0];
    }
    //Fallback to the typename stored in the config object
    if (typeof queryConfig?.typename === "string") {
        return queryConfig?.typename;
    }
    //Final fallback to the typename stored in the query database object
    if (typeof query?.typename === "string") {
        return query?.typename;
    }
    return fallback;
};
exports.getCustomQueryTypename = getCustomQueryTypename;
