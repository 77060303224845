import React from "react";

import TableWidgetPreview from "../../WidgetBuilder/hooks/useCreateWidget/components/CustomizeVisualsStep/components/TableWidgetPreview";
import { useCustomQueryWidgetRunner } from "../../WidgetDetails/hooks/useCustomQueryWidgetRunner/useCustomQueryWidgetRunner";

/**
 * Uses the CustomQuery runner to render a table widget
 *
 * Takes the Table widget column configuration and folds it into the universal Query Runner grid.
 * @param {string} organizationID - the currently selected organization
 * @param {object} item - the CustomWidget item from the database, with configurations
 * @param {boolean} autoRender - if TRUE will retrieve widget data automatically at start
 * @param {string} title - optional title to display above the results grid
 * @param {string} persistenceUUID - persistence UUID for table widgets views persistence in dashboards
 * @param {string} [singleViewType] - optional view type to limit preview to one type of view
 */
const RenderTableWidget = ({ organizationID, item, autoRender = false, title, persistenceUUID, singleViewType }) => {
  const runner = useCustomQueryWidgetRunner({
    widget: item,
    organizationID,
    persistenceUUID,
    autoRender,
  });

  return (
    <TableWidgetPreview
      organizationID={organizationID}
      widget={item}
      runner={runner}
      title={title}
      singleViewType={singleViewType}
    />
  );
};

export default RenderTableWidget;
