import { checkArguments, tryParse } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * Data pre-processing function (allows to search and display custom fields)
 * @param {object} item - the current item for which to process data
 * @param {string[]} customFields - list of custom field names to add to the item
 * @param {object[]} queryCustomFieldData - results for custom field queries field name -> resource id -> data
 * @return {object} new item object with custom fields added
 */
export const normalizeCustomFieldData = ({ item, customFields, queryCustomFieldData }) => {
  // - check arguments
  try {
    checkArguments(
      { item, customFields },
      {
        item: { type: "object" },
        customFields: { type: "object", isArray: true, deepTypeCheck: true },
      },
    );
  } catch (e) {
    ErrorLogger("Invalid item entry given to preprocess.", e.message);
    return item;
  }

  // - create entries for each field based on the type of custom field
  try {
    const customFieldData = tryParse(item?.customFieldData);
    for (const field of customFields) {
      const fieldName = field?.name;
      const type = field?.type;

      switch (type) {
        case "query":
          item = {
            ...item,
            ...(queryCustomFieldData?.[fieldName]?.[item?.id] || {}),
          };
          break;
        default:
          if (fieldName && customFieldData && customFieldData.hasOwnProperty(fieldName)) {
            item[fieldName] = customFieldData[fieldName];
          }
          break;
      }
    }
  } catch (e) {
    ErrorLogger("Failed to generate full custom fields for an item in the controls grid.", e);
  }

  return item;
};
