import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { UncontrolledTooltip } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";

/**
 * @description Interface Button for Resetting all Welcome Tours
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceWelcomeTourReset = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const onClick = () => {
    if (window.confirm("Are you sure you want to reset all Welcome tours?")) {
      preferences.clearPreference("welcomeTours");
    }
  };

  const card = useCard({
    id: "welcome-tour-reset",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Reset Welcome Tours</h4>{" "}
        <i id="loader-style-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="loader-style-icon-question">
          Use this button to reset all Welcome Tours
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <Button onClick={onClick}>Reset Welcome Tours</Button>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceWelcomeTourReset;
