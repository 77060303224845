import { useEvidenceGeneralDetails } from "../../../Evidence/hooks/useEvidenceGeneralDetails";

/**
 * Evidence details for an artifact, retrieved from the evidence activity
 * @param {object} artifact - full artifact information from the database, including the attached evidence activity
 * @returns {JSX.Element}
 */
const ArtifactEvidence = ({ artifact }) => {
  const details = useEvidenceGeneralDetails({
    itemId: artifact?.evidence?.id,
    isTemplate: false,
    organizationID: artifact?.evidence?.ownerGroup,
    detailsConfig: {
      fields: [
        "name",
        "status",
        "frequency",
        "dueDate",
        "type",
        "task",
        "enabled",
        "tags",
        "allowUnauthenticatedSubmissions",
      ],
    },
  });

  return details?.display;
};
export default ArtifactEvidence;
