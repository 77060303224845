"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBarChart = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Creates a sync fusion sfdt bar chart
 * @param {string} [title=""] - the name of the chart (goes into the label above the chart
 * @param {object} [chartConfig={}] - various chart settings see below for defaults
 * @param {object[]} data - the chart data to display including series names
 * @param {string} [chartType="Column_Stacked"] - determines how the data is displayed, Column_Stacked (on top of each other), Bar_Clustered (side by side)
 * @param {boolean} [multiColor = false] - if TRUE will separate data into multiple series and color them (use overlap 100 in config)
 */
const createBarChart = ({ title = "", chartConfig = {}, data, chartType = "Column_Stacked", // Column_Stacked, Bar_Clustered
multiColor = false, }) => {
    /*
          data: [
            {
              series: "Compliant",
              series2: "Not Compliant,
              name: "Domain 1", // required
              name2:,        // optional, for stacked charts
              value: 5,     // required
              value2,       // optional, for stacked charts
              fillColor: "red",  // optional. Chart will try to use fillColor / color / randomColor in that order
              fillColor2: "blue",
              lineColor: "red",  // optional. Chart will try to use lineColor / color / randomColor in that order
              lineColor2: "blue",
              color: "red"         // optional. Chart will use this for fillColor and/or lineColor if one of those aren't specified
              color2: "blue"
            },
            {
              name: "Medium",
              value: 10,
            }
          ]
     */
    const { gapWidth = 5, overlap = 0, height = 252, width = 450, numberFormat = "General", hasMajorGridLines = false, hasMinorGridLines = false, hasMajorValueGridLines = false, hasMinorValueGridLines = false, hasLegend = true, axesConfig = {}, } = chartConfig;
    const chartHeight = height > 640 ? 640 : height;
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                chartLegend: {
                    position: hasLegend ? "Bottom" : null,
                    chartTitleArea: {
                        fontName: hasLegend ? "+mn-lt" : null,
                        fontSize: 9,
                        layout: {
                            layoutX: 0,
                            layoutY: 0,
                        },
                        dataFormat: {
                            fill: {
                                foreColor: "000000",
                                rgb: "#000000",
                            },
                            line: {
                                color: "808080",
                                rgb: "#808080",
                            },
                        },
                    },
                },
                chartTitleArea: {
                    fontName: "+mn-lt",
                    fontSize: 14,
                    layout: {
                        layoutX: 0,
                        layoutY: 0,
                    },
                    dataFormat: {
                        fill: {
                            foreColor: "000000",
                            rgb: "#000000",
                        },
                        line: {
                            color: "000000",
                            rgb: "#000000",
                        },
                    },
                },
                chartArea: {
                    foreColor: "#FFFFFFFF",
                },
                plotArea: {
                    foreColor: "#000000FF",
                },
                chartCategory: getChartCategoryData(data, multiColor),
                chartSeries: getChartSeries(data, multiColor),
                chartPrimaryCategoryAxis: {
                    chartTitle: axesConfig?.categoryAxis?.label?.text || null,
                    chartTitleArea: axesConfig?.categoryAxis?.label?.text
                        ? {
                            fontName: "+mn-lt",
                            fontSize: 10,
                            layout: {
                                layoutX: 0,
                                layoutY: 0,
                            },
                            dataFormat: {
                                fill: {
                                    foreColor: "000000",
                                    rgb: "#000000",
                                },
                                line: {
                                    color: "000000",
                                    rgb: "#000000",
                                },
                            },
                        }
                        : {
                            layout: {},
                            dataFormat: {
                                fill: {},
                                line: {},
                            },
                        },
                    categoryType: "Automatic",
                    fontSize: 11,
                    fontName: "Calibri",
                    numberFormat: numberFormat || "General",
                    maximumValue: axesConfig?.categoryAxis?.maxValue || 0,
                    minimumValue: axesConfig?.categoryAxis?.minValue || 0,
                    majorUnit: 0,
                    hasMajorGridLines: hasMajorGridLines || false,
                    hasMinorGridLines: hasMinorGridLines || false,
                    majorTickMark: "TickMark_Outside",
                    minorTickMark: "TickMark_None",
                    tickLabelPosition: "TickLabelPosition_NextToAxis",
                },
                chartPrimaryValueAxis: {
                    chartTitle: axesConfig?.valueAxis?.label?.text || null,
                    chartTitleArea: axesConfig?.valueAxis?.label?.text
                        ? {
                            fontName: "+mn-lt",
                            fontSize: 10,
                            layout: {
                                layoutX: 0,
                                layoutY: 0,
                            },
                            dataFormat: {
                                fill: {
                                    foreColor: "000000",
                                    rgb: "#000000",
                                },
                                line: {
                                    color: "000000",
                                    rgb: "#000000",
                                },
                            },
                        }
                        : {
                            layout: {},
                            dataFormat: {
                                fill: {},
                                line: {},
                            },
                        },
                    fontSize: 11,
                    fontName: "Calibri",
                    maximumValue: axesConfig?.valueAxis?.maxValue || 0,
                    minimumValue: axesConfig?.valueAxis?.minValue || 0,
                    majorUnit: 0,
                    hasMajorGridLines: hasMajorValueGridLines || false,
                    hasMinorGridLines: hasMinorValueGridLines || false,
                    majorTickMark: "TickMark_Outside",
                    minorTickMark: "TickMark_None",
                    tickLabelPosition: "TickLabelPosition_NextToAxis",
                },
                chartTitle: `${title}`,
                chartType: chartType,
                gapWidth: gapWidth || 5,
                overlap: overlap || 0,
                height: chartHeight || height || 252,
                width: width || 450,
            },
            {
                characterFormat: {},
                bookmarkType: 0,
                name: "_GoBack",
            },
            {
                characterFormat: {},
                bookmarkType: 1,
                name: "_GoBack",
            },
        ],
    };
};
exports.createBarChart = createBarChart;
const getChartCategoryData = (data = [], multiColor) => {
    const res = [];
    for (let i = 0; i < data.length; i++) {
        const chartData = [];
        if (multiColor) {
            for (let g = 0; g < data.length; g++) {
                if ((data[i].value || data[i].value === 0) && i === g) {
                    chartData.push({ yValue: data[i].value });
                }
                else {
                    chartData.push({ yValue: 0 });
                }
            }
        }
        else {
            if (data[i].value || data[i].value === 0)
                chartData.push({ yValue: data[i].value });
            if (data[i].value2 || data[i].value2 === 0)
                chartData.push({ yValue: data[i].value2 });
        }
        res.push({
            chartData: [...chartData],
            categoryXName: data[i].name || "",
        });
    }
    return res;
};
/**
 *
 * @param data: the bar chart color settings and counts
 * @param multiColor: TRUE if each bar in the chart has a custom color
 * @returns {object[]}
 */
const getChartSeries = (data = [], multiColor) => {
    const chartSeries = [];
    if (multiColor) {
        for (const item of data) {
            chartSeries.push({
                dataPoints: [getChartEntry(item, true)],
                seriesName: item.series || "",
            });
        }
    }
    else {
        const item = data[0];
        if (item) {
            chartSeries.push({
                dataPoints: [getChartEntry(item, true)],
                seriesName: item.series || item.name || "",
            });
            if (!(0, func_utils_1.isNullOrUndefined)(item.series2)) {
                chartSeries.push({
                    dataPoints: [getChartEntry(item, false)],
                    seriesName: item.series2 || "",
                });
            }
        }
    }
    return chartSeries;
};
/**
 * Allows to get a single bar design from a bar chard data item object
 * @param item: the settings and data of the bar item
 * @param useFirstVariant
 */
const getChartEntry = (item, useFirstVariant = true) => {
    const splitColor = (str) => {
        return str && str.split("#") && str.split("#")[1] ? str.split("#")[1] : "";
    };
    const randomColor = () => `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
    const randomColor2 = () => `${(((1 << 24) * Math.random()) | 0).toString(16)}`;
    if (useFirstVariant) {
        return {
            fill: {
                foreColor: splitColor(item.fillColor) || splitColor(item.color) || randomColor2(),
                rgb: item.fillColor || item.color || randomColor(),
            },
            line: {
                color: splitColor(item.lineColor) || splitColor(item.color) || randomColor2(),
                rgb: item.lineColor || item.color || randomColor(),
            },
        };
    }
    else {
        return {
            fill: {
                foreColor: splitColor(item.fillColor2) || splitColor(item.color2) || randomColor2(),
                rgb: item.fillColor2 || item.color2 || randomColor(),
            },
            line: {
                color: splitColor(item.lineColor2) || splitColor(item.color2) || randomColor2(),
                rgb: item.lineColor2 || item.color2 || randomColor(),
            },
        };
    }
};
