import { CSSProperties } from "react";
import { Spinner } from "reactstrap";

export interface LoadingSpinnerProps {
  loadingMessage?: string;
  size?: string;
  color?: string;
  style?: CSSProperties;
}

/**
 * @description viewing spinner loader for message
 * @param loadingMessage - the message to display
 * @param size - the size of the spinner
 * @param color - the color of the spinner
 * @param style - the style of the spinner
 */
export const LoadingSpinner = ({ loadingMessage = "", size = "sm", color = "primary", style = {} }): JSX.Element => {
  return (
    <span data-testid={"loading-spinner"}>
      {loadingMessage}
      <Spinner size={size} color={color} style={style} />
    </span>
  );
};
