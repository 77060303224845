import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

export const createRiskThreatLink = async (risk, threat) => {
  const { createMutation } = generateGraphql("RiskThreatLink");

  return await ItemMutation(createMutation, {
    riskID: risk.id,
    threatID: threat.id,
    ownerGroup: risk.ownerGroup || threat.ownerGroup,
  });
};
