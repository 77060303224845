import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";
import { TargetVulnerabilityStatus as TargetVulnerabilityStatusEnum } from "@rivial-security/schema-types";

import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { changeTargetLinkStatus } from "../functions/changeTargetLinkStatusOperations/changeTargetLinkStatus";

/**
 * Display the status of a target vulnerability link
 * @param {object} item - target with a link status
 * @param {string} module - module
 * @param {string} resource - resource
 * @param {string} field - field
 * @param {function} submitFunction - submit function
 * @param {function} resetFunction - reset function to refresh parent data
 * @param {object} props - other props
 * @returns {JSX.Element}
 */
export const TargetVulnerabilityStatus = ({
  item,
  module = modules.VULNERABILITIES,
  resource = resources.VULNERABILITY,
  field = "status",
  submitFunction,
  resetFunction,
  ...props
}) => {
  /**
   * Change the status of the target vulnerability link
   */
  const mutationFunction = async (newItem) => {
    await changeTargetLinkStatus({ oldItem: item, newItem });
    tryFunction(resetFunction);
  };

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutationFunction={mutationFunction}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: enumToDropdownData({
          ENUM: Object.values(TargetVulnerabilityStatusEnum),
        }),
      }}
      submitFunction={submitFunction}
      resetFunction={resetFunction}
      {...props}
    />
  );
};
