"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSmartValueAdjacencyList = void 0;
const getSmartValueNodeName_1 = require("./getSmartValueNodeName");
/**
 * Creates adjacency list representation of the directed graph created by the smart value dependencies
 * Includes the calculation of both outgoing and incoming edges for each node.
 * @param {object} mappedSmartValues - an object containing all smart values within a step to the step identifier
 * @returns {object} - the adjacency list of all automations steps
 * {
 *   stepIdentifier1: {
 *      name: string,
 *      outgoing: string[],
 *      incoming: string[],
 *   }
 * }
 */
const getSmartValueAdjacencyList = ({ mappedSmartValues }) => {
    // Check arguments
    if (!mappedSmartValues) {
        return {};
    }
    // A map of nodes to their edge configuration
    const adjacencyList = {};
    /**
     * Adds a node to the adjacency list if it doesn't already exist
     * @param {string} sourceNode - the dependency automation step identifier
     * @param {string} destinationNode - the dependent automation step identifier
     */
    const addEdgeToAdjacencyList = ({ sourceNode, destinationNode }) => {
        // - check arguments
        if (!sourceNode || !destinationNode) {
            return;
        }
        // - check if the nodes exists in the adjacency list
        if (!adjacencyList.hasOwnProperty(sourceNode)) {
            adjacencyList[sourceNode] = {
                outgoing: [],
                incoming: [],
                name: sourceNode,
            };
        }
        if (!adjacencyList.hasOwnProperty(destinationNode)) {
            adjacencyList[destinationNode] = {
                outgoing: [],
                incoming: [],
                name: destinationNode,
            };
        }
        // - add the value to the nodes' outgoing and incoming edges if they don't already exist
        if (!adjacencyList[sourceNode].outgoing.includes(destinationNode)) {
            adjacencyList[sourceNode].outgoing.push(destinationNode);
        }
        if (!adjacencyList[destinationNode].incoming.includes(sourceNode)) {
            adjacencyList[destinationNode].incoming.push(sourceNode);
        }
    };
    // Iterate over all smart values to create the adjacency list
    for (const automationStepName in mappedSmartValues) {
        if (!automationStepName) {
            continue;
        }
        const automationStepSmartValues = mappedSmartValues[automationStepName];
        if (Array.isArray(automationStepSmartValues)) {
            for (const smartValue of automationStepSmartValues) {
                const destinationNode = automationStepName;
                const sourceNode = (0, getSmartValueNodeName_1.getSmartValueNodeName)({ smartValue });
                addEdgeToAdjacencyList({ sourceNode, destinationNode });
            }
        }
    }
    return adjacencyList;
};
exports.getSmartValueAdjacencyList = getSmartValueAdjacencyList;
