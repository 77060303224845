import { isNullOrUndefined } from "@rivial-security/func-utils";

import { platformSettings } from "../../../../../../../platformSettings";
import { RISK_CHANGE_TYPE } from "../../../../constants/riskChangeType";
import { callCreateRiskChangeLambda } from "../../../../functions/callCreateRiskChangeLambda";

/**
 * Submits a risk control change to the backend
 * @param {object} input - the risk change form input
 * @param {string} organizationID - the currently selected organization
 * @param {object} riskRecommendationData - useRiskRecommendationData hook instance
 * @returns {Promise<void>}
 */
export const submitControlRiskChange = async ({ input, organizationID, riskRecommendationData }) => {
  /**
   * Determine if change impacts more than systemRecalculationThreshold systems
   *  - do not send system info if so
   *  - redo calculations on the backend
   */
  const systemThreshold = platformSettings.systemRecalculationThreshold;
  let calculationResults = riskRecommendationData?.calculationResults;
  const isLargeChange = Array.isArray(calculationResults) && calculationResults.length > systemThreshold;
  if (isLargeChange) {
    calculationResults = null;
  }

  await callCreateRiskChangeLambda({
    riskChangeFormInput: input,
    riskRecommendationData: {
      ...riskRecommendationData,
      calculationResults,
    },
    changeType: !isNullOrUndefined(riskRecommendationData.selectedSystemID)
      ? RISK_CHANGE_TYPE.NON_STANDARD_CONTROL
      : RISK_CHANGE_TYPE.ENTERPRISE_CONTROL,
    organizationID,
  });
};
