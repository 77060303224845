"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rapid7ScannerFields = void 0;
exports.rapid7ScannerFields = [
    {
        displayName: "Name",
        resourceFieldName: "name",
        defaultCSVFieldNames: ["Vulnerability Title"],
    },
    {
        displayName: "Type",
        resourceFieldName: "type",
        interpreterDescription: "This field should represent the severity level, if the field has no content the platform type will be marked as informational.",
    },
    {
        displayName: "Severity Level",
        resourceFieldName: "severityLevel",
        interpreterDescription: `This field should represent the severity level, scanner values ranging from 1 to 10 will be mapped to platform severity levels.`,
    },
    {
        displayName: "CVE",
        resourceFieldName: "cve",
        defaultCSVFieldNames: ["Vulnerability CVE IDs"],
    },
    {
        displayName: "Port",
        resourceFieldName: "port",
        defaultCSVFieldNames: ["Service Port"],
    },
    {
        displayName: "IP",
        resourceFieldName: "ip",
        defaultCSVFieldNames: ["Asset IP Address"],
    },
    {
        displayName: "Host Name",
        resourceFieldName: "hostName",
        defaultCSVFieldNames: ["Asset Names"],
    },
];
