import { Autocomplete, TextField } from "@mui/material";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useGUID } from "../../../../hooks/functional/useGUID";

/**
 * A MUI autofill component used by generic edit field for dropdowns/enums
 * @param {object} props
 * @param {string} props.fieldName - the name of the field being displayed (should be present as a property in item)
 * @param {string} props.value - the current or default value for the field
 * @param {function} props.setValue - callback for when data has changed (pass in the current state of the input)
 * @param {object[]} props.inputConfig - holds settings for the dropdown component (use enumToDropdownData.js for enums)
 * @param {boolean} [isEnabled=true] - whether the dropdown is disabled
 * @example
 * inputConfig = {
 *   data: [
 *     {value: orange, text: Orange}
 *     ...
 *   ]
 * }
 */
const CustomDropdown = ({
  field,
  value,
  setValue,
  inputConfig,
  isEnabled = true,
  label = "Select an item...",
  title,
}) => {
  const id = useGUID();

  const handleChange = (event, newValue) => {
    setValue(newValue?.value);
  };

  const options = [];
  let optionsInit = inputConfig?.data;
  if (!Array.isArray(optionsInit)) {
    optionsInit = [];
  }
  optionsInit.forEach((option) => {
    if (!isNullOrUndefined(option?.value) && !isNullOrUndefined(option?.text)) {
      options.push(option);
    }
  });

  const currentOption = options.find((option) => option.value === value) || null;
  return (
    <Autocomplete
      disablePortal
      disableClearable
      autoHighlight
      size={"small"}
      id={`custom-dropdown-input-${id}`}
      data-testid={`custom-dropdown-input-${field}`}
      value={currentOption}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => option.text}
      disabled={!isEnabled}
      title={title}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={!isNullOrUndefined(label) ? label : "Select an item..."}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default CustomDropdown;
