import React from "react";

/**
 * Shows some colored and crossed-through text based of one of three types
 * @parem {string} props.textDefault - the text to
 * @param {string} props.text - the value to format
 * @param {string} props.textType - one of the formatting type "info", "deleted" or "added"
 * @returns {*}
 * @constructor
 */
const FormattedText = (props) => {
  let textColor = "#000000";
  if (props.textType) {
    switch (props.textType) {
      case "info":
        textColor = "#3B90FF";
        break;
      case "deleted":
        textColor = "#D10000";
        break;
      case "added":
        textColor = "#00B449";
        break;
      default:
      // pass - black text color is default
    }
  }

  if (props && props.textType && props.textType === "deleted")
    return (
      <span style={{ color: textColor }}>
        <del>{typeof props.text === "object" ? JSON.stringify(props.text) : props.text || props.textDefault}</del>
      </span>
    );
  else {
    return (
      <span style={{ color: textColor }}>
        {typeof props.text === "object" ? JSON.stringify(props.text) : props.text || props.textDefault}
      </span>
    );
  }
};

export default FormattedText;
