import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { listRisksControlCategoriesByOwnerGroup } from "../graphql/listRisksControlCategoriesByOwnerGroup";

/**
 * Queries for all the risk controls inside of a single system and creates a map of (id : data) for each one
 * @param {string} organizationID - the system for which to get risk controls
 * @return {Promise<{object {}}>} - map of risk control id to risk control data
 */
export const listOrganizationRiskControlsMap = async ({ organizationID } = {}) => {
  if (!organizationID) {
    return {};
  }

  const riskControlCategories = await ListQueryBy({
    query: listRisksControlCategoriesByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    limit: 200,
  });

  // - create a map of all risk controls for the organization
  const organizationRiskControls = {};
  if (Array.isArray(riskControlCategories)) {
    for (const riskControlCategory of riskControlCategories) {
      const riskControls = riskControlCategory?.subControls?.items;
      if (Array.isArray(riskControls)) {
        for (const riskControl of riskControls) {
          if (riskControl?.id) {
            organizationRiskControls[riskControl.id] = {
              ...riskControl,
              controlCategoryName: riskControlCategory?.name ?? "Unknown Risk",
              controlCategoryID: riskControlCategory?.id,
            };
          }
        }
      }
    }
  }

  return organizationRiskControls;
};
