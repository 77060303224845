import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../Graphql/ItemMutation";

/**
 * @description Update the user's preferred MFA in the database.
 * Note: This is not the same as setting the user's preferred MFA in the cognito user pool.
 * @param {string} preferredMFA - The preferred MFA to set for the user.
 * @param {object} user - Cognito user object.
 * @param {string} userID - The user ID of the user to update.
 * @returns {Promise<*>}
 */
export const updateUserPreferredMFA = async ({ preferredMFA, user, userID } = {}) => {
  if (!preferredMFA) throw new Error("preferredMFA is required");
  if (!user && !userID) throw new Error("user or userID is required");

  return await ItemMutation(generateGraphql("User", ["preferredMFA"]).updateMutation, {
    id: userID || user?.attributes?.["custom:userID"],
    preferredMFA,
  });
};
