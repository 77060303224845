import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getMany } from "../../utils/Functions/getMany";
import { ItemQuery } from "../../utils/Functions/Graphql/ItemQuery";

/**
 * @description Utility for getting many-to-many items from a GetQuery
 * @param {string} query - GraphQL query
 * @param {string} item - item to get
 * @param {string} field - field to get
 * @param {string} connectionField - connection field to get
 * @param {function} callback - callback function
 * @param {function} setIsLoading - setIsLoading function
 * @returns {Promise<void>} - Promise that resolve when file is ready
 */
export const useGetManyEffect = ({ query, item, field, connectionField, callback, setIsLoading }) => {
  const [data, setData] = useState([]);

  const callbackFunction = (items) => {
    if (Array.isArray(items)) {
      setData([...items]);
      callback && callback(items);
    }
    if (typeof setIsLoading === "function") setIsLoading(false);
  };

  useEffect(() => {
    if (!isNullOrUndefined(item?.id) && field) {
      if (typeof setIsLoading === "function") setIsLoading(true);
      ItemQuery(query, item.id).then((data) => callbackFunction(getMany(data, field, connectionField)));
    }
  }, [item]);

  return [data, setData];
};
