import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { useState } from "react";

/**
 * @description TagFilterOperatorButtonGroup component
 * @param {object} props - props
 * @returns {JSX.Element} - TagFilterOperatorButtonGroup component
 * @type {{NOT: string, OR: string, AND: string}}
 */
export const tagOperators = {
  OR: "OR",
  AND: "AND",
  NOT: "NOT",
};

/**
 * Used by the filter menu to manipulate tag filtering logic
 * @param {object} tagOperationRef - reference to the current operation selected (used for initial value)
 * @param {function} setTagsOperator - function from useState, used to set a new operator value
 */
const TagFilterOperatorButtonGroup = ({ tagsOperatorRef, setTagsOperator }) => {
  const [localTagsOperator, setLocalTagsOperator] = useState(tagsOperatorRef?.current || tagOperators.OR);

  return (
    <div className="e-btn-group">
      <ButtonComponent
        title={"Filter out any items do not contain any of the selected tags.."}
        cssClass={localTagsOperator === tagOperators.OR ? "e-primary" : undefined}
        onClick={() => {
          setTagsOperator && setTagsOperator(tagOperators.OR);
          setLocalTagsOperator(tagOperators.OR);
        }}
      >
        {tagOperators.OR}
      </ButtonComponent>
      <ButtonComponent
        title={"Filter out any items that do not contain all of the selected tags."}
        cssClass={localTagsOperator === tagOperators.AND ? "e-primary" : undefined}
        onClick={() => {
          setTagsOperator && setTagsOperator(tagOperators.AND);
          setLocalTagsOperator(tagOperators.AND);
        }}
      >
        {tagOperators.AND}
      </ButtonComponent>
      <ButtonComponent
        title={"Filter out any items that contain any of the selected tags."}
        cssClass={localTagsOperator === tagOperators.NOT ? "e-primary" : undefined}
        onClick={() => {
          setTagsOperator && setTagsOperator(tagOperators.NOT);
          setLocalTagsOperator(tagOperators.NOT);
        }}
      >
        {tagOperators.NOT}
      </ButtonComponent>
    </div>
  );
};

export default TagFilterOperatorButtonGroup;
