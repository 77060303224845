import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import TabCard from "../../../../utils/GenericComponents/TabCard";
import { BUILT_IN_CARD_WIDGETS } from "../../WidgetRenderer/constants/BUILT_IN_WIDGETS";

import BuiltInWidgetSelectionTiles from "./BuiltInWidgetSelectionTiles";

/**
 * Displays a tabbed card for selecting a card widget
 *
 * @param {string} organizationID - The organization ID
 * @param {function} onSelect - The function to call when a widget is selected
 * @returns {JSX.Element}
 * @constructor
 */
const CardWidgetTabCard = ({ organizationID, onSelect }) => {
  return (
    <div>
      <TabCard
        items={[
          {
            icon: "icon-grid",
            title: "Built In",
            component: (
              <BuiltInWidgetSelectionTiles
                widgets={BUILT_IN_CARD_WIDGETS}
                organizationID={organizationID}
                onSelect={onSelect}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default withOrganizationCheck(CardWidgetTabCard);
