import { checkArguments, not, union } from "@rivial-security/func-utils";

import { numberOfChecked } from "./numberOfChecked";

/**
 * Checks all items in a list
 * @param {object[]} items - the items in the list
 * @param {object[]} checkedItems - the currently checked items
 * @param {function} setCheckedItems - function handler for setting checked items
 * @returns {function(): void}
 */
export const handleToggleAll = (items, checkedItems, setCheckedItems) => {
  checkArguments({
    items,
    checkedItems,
    setCheckedItems,
  });

  if (numberOfChecked(items, checkedItems) === items.length) {
    setCheckedItems(not(checkedItems, items));
  } else {
    setCheckedItems(union(checkedItems, items));
  }
};
