import { keyBy } from "lodash";
import { useEffect, useState } from "react";

import { usePreferences } from "../../usePreferences/usePreferences";

/**
 * @description Enable/Disable persistence based on user Interface Setting
 * @param {function} setEnablePers - sets value for disable persistence to reset the grid
 * @param {object} ref - grid instance
 * @param {string} persistenceUUID - get grid persistence id
 * @param {boolean} enablePersistence - grid level switch to enable persistence
 * @param {object[]} fields - the field settings to populate with saved preferences
 */
export const useGridGetPreference = ({ setEnablePers, ref, persistenceUUID, enablePersistence, fields }) => {
  const { getPreference, preferences } = usePreferences();

  //Used to only set the persistence properties once, otherwise all custom components are not rendered
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (preferences && ref) {
      const gridPersistence = getPreference && getPreference("general", "grid_persistence");

      /**
       * if grid_persistence enabled then pull grid_persistence config
       */
      if (gridPersistence && persistenceUUID && enablePersistence) {
        /**
         * Get config for the grid
         */
        const allGridConfig = getPreference("general", "grid_persistence_config");

        /**
         * Find grid config
         */
        if (initialRender === true) {
          if (allGridConfig && allGridConfig[persistenceUUID]) {
            const savedGridPreferences = allGridConfig[persistenceUUID];

            /**
             * Update column settings with the properties saved in preferences
             */

            if (Array.isArray(savedGridPreferences?.columns)) {
              const columnDictionary = keyBy(savedGridPreferences?.columns, "name");
              for (let i = 0; i < fields.length; i++) {
                if (fields[i]?.name && columnDictionary.hasOwnProperty(fields[i].name)) {
                  fields[i] = {
                    ...fields[i],
                    ...columnDictionary[fields[i].name],
                  };
                }
              }
            }

            //updated the remaining grid settings
            ref?.setProperties &&
              ref?.setProperties({
                ...(savedGridPreferences?.groupSettings && {
                  groupSettings: savedGridPreferences.groupSettings,
                }),
                ...(savedGridPreferences?.pageSettings && {
                  pageSettings: savedGridPreferences.pageSettings,
                }),
                ...(savedGridPreferences?.sortSettings && {
                  sortSettings: savedGridPreferences.sortSettings,
                }),
              });
          }
          setInitialRender(false);
        }
      }

      setEnablePers && setEnablePers(gridPersistence);
    }
  }, [preferences, ref, fields]);

  return {
    gridPreferencesRestored: !initialRender,
  };
};
