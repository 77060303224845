import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

import { cognitoApi, CognitoApiOperation } from "../../../../utils/Functions/cognitoApi";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

/**
 * Delete user from a database and Cognito user pool.
 * @param {object} params - the parameters object
 * @param {object} params.item - the User object to delete
 * @param {function} [params.callback] - the callback function to call after deletion
 * @param {boolean} [params.isOperationTeamUser] - whether the user is an Operation Team user
 */
export const deleteUser = async ({ item, callback, isOperationTeamUser }) => {
  const { getQuery } = generateGraphql("User", ["username", "__typename", "roleLinks"], {
    roleLinks: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
  });

  // Get user from a database with role links.
  const user = await GetQuery({
    query: getQuery,
    variables: {
      id: item?.id,
    },
  });

  if (!user?.id) {
    InfoLogger("[deleteUser] Invalid input! Missing user id.");
    return;
  }

  // Remove a user object with role links from a database.
  await GenericDeleteGQL({ objectToDelete: user });

  // Remove user from Cognito user pool.
  await cognitoApi({
    organizationID: user?.ownerGroup,
    operation: CognitoApiOperation.DELETE_COGNITO_USER,
    input: {
      username: user?.username,
    },
    isOperationTeamUser,
  });

  if (typeof callback === "function") callback(user);
  return user;
};
