import { GetQuery } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { TaskStatus } from "../../../Program/Tasks/constants/taskStatus";
import { questionnaireAssigneeStatus } from "../constants/questionnaireAssigneeStatus";
import { questionnaireStatus } from "../constants/questionnaireStatus";

/**
 * Returns a list of points of contact (assignees) for a single questionnaire
 * @param {object} params - the parameters for the query
 * @param {string | undefined} params.questionnaireID - the id of the questionnaire for which to get the assignees
 * @returns {Promise<object[]>}
 */
export const getQuestionnaireAssignedPointsOfContact = async ({ questionnaireID }) => {
  if (!questionnaireID) {
    return [];
  }

  const { getQuery } = generateGraphql("Questionnaire", ["assignees"], {
    assignees: `(limit: 1000) {
      items {
        id
        questionnaire {
          id
          status
        }
        pointOfContact {
          id
          firstName
          lastName
          email
        }
        tasks (limit: 1) {
          items {
            id
            status
          }
        }
        ownerGroup
      }
    }`,
  });
  const questionnaire = await GetQuery({
    query: getQuery,
    variables: {
      id: questionnaireID,
    },
  });

  const databaseAssignees = questionnaire?.assignees?.items;
  const newAssignees = [];
  if (isNonEmptyArray(databaseAssignees)) {
    for (const databaseAssignee of databaseAssignees) {
      const pointOfContact = databaseAssignee?.pointOfContact;
      let task;
      if (isNonEmptyArray(databaseAssignee?.tasks?.items)) {
        task = databaseAssignee?.tasks?.items[0];
      }
      const questionnaire = databaseAssignee?.questionnaire;
      if (!pointOfContact) {
        continue;
      }

      let questionnaireAssigneeStatusValue = questionnaireAssigneeStatus.UNKNOWN;
      const questionnaireStatusValue = questionnaire?.status;
      const taskStatusValue = task?.status;

      if (taskStatusValue === TaskStatus.DONE) {
        questionnaireAssigneeStatusValue = questionnaireAssigneeStatus.RESPONDED;
      } else if (taskStatusValue === TaskStatus.IN_PROGRESS || taskStatusValue === TaskStatus.TODO) {
        questionnaireAssigneeStatusValue = questionnaireAssigneeStatus.IN_PROGRESS;
      }

      if (questionnaireStatusValue === questionnaireStatus.SCHEDULED) {
        questionnaireAssigneeStatusValue = questionnaireAssigneeStatus.SCHEDULED;
      }

      newAssignees.push({
        ...(pointOfContact ?? {}),
        task,
        questionnaire,
        questionnaireAssigneeStatus: questionnaireAssigneeStatusValue,
        pointOfContactID: pointOfContact?.id,
        id: databaseAssignee?.id,
        ownerGroup: databaseAssignee?.ownerGroup,
      });
    }
  }

  return newAssignees;
};
