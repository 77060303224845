/**
 * @description Function for generating validationBy (which user) for the evidence modal.
 * @param {PointOfContact} pointOfContact - point of contact object
 * @returns {string} validatedBy - name of the person who validated the evidence
 */
export const generateValidatedBy = ({ pointOfContact }) => {
  let validatedBy = " ";
  if (pointOfContact?.firstName && pointOfContact?.lastName) {
    validatedBy = `${pointOfContact?.firstName} ${pointOfContact?.lastName}`;
  } else if (pointOfContact?.firstName && !pointOfContact?.lastName) {
    validatedBy = `${pointOfContact?.firstName}`;
  } else if (!pointOfContact?.firstName && pointOfContact?.lastName) {
    validatedBy = `${pointOfContact?.lastName}`;
  } else {
    validatedBy = undefined;
  }
  return validatedBy;
};
