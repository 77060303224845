import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useToolDetails } from "../hooks/useToolDetails";

/**
 * @description Display Tool details component
 * @param {object} props
 * @constructor
 */
const ToolDetails = (props) => useToolDetails(props).display;

export default withDetailsPage(ToolDetails, { title: "Integration Details" });
