import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { useGridCard } from "../../../../hooks/views/useGridCard";
import CreateVendorSubControl from "../components/CreateVendorSubControl";
import VendorSubControlDetails from "../components/VendorSubControlDetails";
import { DeleteVendorSubControls } from "../functions/DeleteVendorSubControls";

/**
 * @deprecated - use 'useVendorSubControlDataGrid' instead
 *
 * @description Custom hook for displaying a list of VendorSubControls
 * @param {string} organizationID - the organization for which
 * @param {function} resetFunction - a function to call to repopulate the items in the grid in-case it is populated from the outside
 * @param {object} cardConfig={} - custom configuration for th egrid wrapper
 * @param {object} queryConfig={} - custom configuration for the query that the grid uses
 * @param {object} config={} - custom configuration for the grid hook
 * @param {object[]} fields=[] - fields to display in the grid
 * @param {object[]} [vendorControlCategory] - if provided, will only show vendor sub controls for this vendor control category
 * @param {boolean} disableRoleChecking - if TRUE wil not do a frontend role check for ownergroup
 * @param {objects} props - any additional properties to pass down to grid config
 * @returns {object} {{gridDisplay: JSX.Element, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, createResourceComponent: JSX, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, setItemsToCheck: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, selectedItems: [] | undefined}}
 */
export const useVendorSubControlGrid = ({
  organizationID,
  resetFunction,
  gridConfig = {},
  cardConfig = {},
  queryConfig = {},
  config = {},
  fields = [],
  vendorControlCategory,
  disableRoleChecking,
  ...props
}) => {
  const typename = "VendorSubControl";
  const module = modules.VENDORS;
  const resource = resources.VENDOR_CONTROL;

  const { listQuery, updateMutation } = generateGraphql(typename, [
    "vendorControlCategoryID",
    "statementNumber",
    "name",
  ]);

  const normalizeDataExternal = queryConfig?.normalizeData;
  delete queryConfig?.normalizeData;
  queryConfig = {
    query: listQuery,
    organizationID: organizationID,
    module,
    resource,
    disableRoleChecking,
    normalizeData: (items) => {
      let newItems = items;
      if (newItems && normalizeDataExternal) {
        newItems = normalizeDataExternal(newItems);
      }

      const selectedCategoryID = vendorControlCategory?.id;
      if (selectedCategoryID && Array.isArray(newItems)) {
        return newItems.filter((item) => item.vendorControlCategoryID === selectedCategoryID);
      } else {
        return newItems;
      }
    },
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  cardConfig = {
    title: "Vendor Controls",
    headerIcon: "icon-list",
    ...cardConfig,
  };

  fields = [
    {
      name: "statementNumber",
      width: "75",
      sortComparer: (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
    },
    {
      name: "name",
    },
    ...fields,
  ];

  gridConfig = {
    fields,
    options: ["details", "delete"],
    createResourceComponent: (
      <CreateVendorSubControl
        vendorControlCategory={vendorControlCategory}
        disableRoleChecking={disableRoleChecking}
        organizationID={organizationID}
      />
    ),
    createItemModalHeader: "Create a new Vendor Control",
    deleteFunction: DeleteVendorSubControls,
    updateMutation,
    route: "#/vendors/subControls/",
    detailsComponent: <VendorSubControlDetails tableDisplay={true} />,
    detailsTitle: "Vendor Control Details",
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    detailsType: DETAILS_TYPES.PANEL,
    enablePrint: true,
    enableMenu: true,
    enableColumnChooser: false,
    gridHeight: "100%",
    typename,
    persistenceUUID: "e9e1c7d7-9698-435b-bd76-395edbe1r30e",
    disableRoleChecking,
    ...gridConfig,
    ...props,
  };

  const grid = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  return { ...grid };
};
