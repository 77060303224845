import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Badge } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import { useUIContext } from "@utils/Context/UIContext";

import HighlightedText from "../../HighlightedText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: "var(--sidebar-background)",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    width: "100%",
    display: "inline-block",
  },
  column: {
    flexBasis: "50%",
  },
  expanded: {
    margin: "2em",
  },
  summary: {
    background: ({ completion }) => {
      let percentage = 0;
      if (completion) {
        percentage = completion * 100;
      }
      percentage = 100 - percentage;
      let low = percentage - 10;
      if (percentage === 100) {
        low = 100;
      }

      return `linear-gradient(to left, white 0%, white ${low}%, #CCFFDD ${percentage}%, #CCFFDD 100%)`;
    },
  },
}));

/**
 * @description An accordion component that matches the DashboardCard component
 * @param {string} id - unique id for the accordion
 * @param {string} title - text to display as the header of the accordion
 * @param {string} accordionTitle - text to display as the header of the accordion
 * @param {string} [titleFlexBasis=50%] - determines the flex size of the title in an accordion,
 * can set to a lower number if all title is small so that the subtitles and header buttons get more space
 * @param {string} [subTitle] - optional subtitle text
 * @param {JSX.Element} children - components to display in the Accordion body when it's opened
 * @param {JSX.Element} [icon] - an optional Icon component
 * @param {string} [badgeContent] - optional badge content to display in top right of the icon
 * @param {JSX.Element[]} [headerButtons=[]] - optional header buttons
 * @param {string} [highlightedText] - substring of the accordion title to highlight
 * @param {boolean} unmountOnExit - if rendering expensive components in each accordion section, may want this to be TRUE
 * @param {boolean} defaultExpanded - if true, this accordion is open by default
 * @param {boolean} disabled - if true, this whole accordion is greyed out
 * @param {boolean} disabledSummary - if true, the summary portion of the accordion is greyed out
 * @param {function} completion - used to fetch CSS for completion colors
 * @param {boolean} [initialMount = true] - if true, allows the component to be mounted before opened (unmountOnExit has priority)
 */
export default function AccordionCard({
  id,
  title,
  accordionTitle,
  titleFlexBasis = "50%",
  subTitle,
  children,
  icon,
  badgeContent,
  headerButtons = [],
  highlightedText,
  unmountOnExit = true,
  defaultExpanded = false,
  disabled = false,
  disabledSummary = false,
  completion,
  initialMount,
}) {
  const classes = useStyles({ completion });

  const { isMobile } = useUIContext();

  // if initialMount is false, this is false, which means the accordion content won't be mounted until it's opened the first time
  // if initial mount is true, mounts the accordion content (but still honors unmountOnExit) logic
  const [firstOpen, setFirstOpen] = useState(initialMount);

  return (
    <>
      <Accordion
        id={id}
        defaultExpanded={defaultExpanded}
        classes={{ expanded: classes.expanded }}
        TransitionProps={{ unmountOnExit }}
        onClick={() => setFirstOpen(true)}
        style={{
          width: "100%",
          backgroundColor: disabled && "lightGrey",
          opacity: disabled && ".6",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.summary}
          style={{
            backgroundColor: disabledSummary && "lightGrey",
            opacity: disabledSummary && ".6",
          }}
        >
          <div style={{ flexBasis: isMobile ? "100%" : titleFlexBasis }}>
            <div className="e-card-header">
              <div className="e-card-header-caption">
                <div className={`${classes.heading} e-card-header-title`} style={{ fontSize: "16px" }}>
                  {icon && (
                    <Badge badgeContent={badgeContent} color="error" style={{ marginRight: "1em" }}>
                      <span>{icon}</span>
                    </Badge>
                  )}

                  <HighlightedText
                    text={accordionTitle || title}
                    highlight={highlightedText}
                    style={{ color: "#ff5151" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ display: isMobile ? "none" : "block", flex: 1 }}>
              <Typography className={classes.secondaryHeading}>{subTitle}</Typography>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              {headerButtons && headerButtons.map((button) => React.cloneElement(button))}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>{firstOpen && children}</AccordionDetails>
      </Accordion>
    </>
  );
}
