import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm";
import { useCustomFieldsTable } from "../../../Compliance/Controls/ControlSets/hooks/useCustomFieldsTable";

/**
 * A hook to create a new CustomResourceType
 *
 * @param {string} organizationID - organizationID to create the CustomResourceType for
 * @param {string} module - module used for role checking
 * @param {string} resource - resource used for role checking
 * @param {boolean} isTemplate - determines if this should be created for a template
 * @param {boolean} disableRoleChecking - disables role checking
 * @param {function} getNewItem - callback to add the new item to a parent grid
 * @param {object} formConfig - configs to pass to the form hook
 * @param {function} toggleModal - toggles a parent model
 * @param {object} ...props - all other props passed directly to useForm hook
 * @returns {{display: *, input: {}}}
 */
export const useCustomResourceTypeForm = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.TOOL,
  isTemplate = false,
  disableRoleChecking = false,

  getNewItem,
  formConfig = {},
  toggleModal,

  toolID,
  ...props
}) => {
  const customFieldsTable = useCustomFieldsTable({
    disableRoleChecking: true,
    enableBackendUpdate: false,
    forceTableVisibility: true,
  });

  /**
   * Modifies the input from the main form to include customFields
   * @param {object} input - object from the main form
   * @returns {object} - the full input object with custom fields attached
   */
  const onSubmit = (input) => {
    //Add the custom fields input
    input.customFields = customFieldsTable.customFields;
    if (toolID) {
      input.toolID = toolID;
    }
    return input;
  };

  const fieldConfig = {
    name: {
      required: true,
      label: "Name",
      tooltip: "The Name of this Resource, usually corresponding to a Table name in the Database",
    },
    description: {
      label: "Description",
    },
    customFields: {
      label: "Custom Fields",
      tooltip: "Add custom properties unique to this resource type.",
      createItemComponent: customFieldsTable.createButton,
      inputType: "custom",
      customConfig: {
        component: customFieldsTable.display,
      },
    },
  };

  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    fieldConfig,
    mutation: generateGraphql("CustomResourceType", ["name", "customFields", "toolID"], {
      customFields: "{name type}",
    }).createMutation,
    typename: "CustomResourceType",
    toggleModal,
    updateInputFunction: (input) => onSubmit(input),
    resetCallback: () => {
      customFieldsTable.setCustomFields([]);
    },
    ...formConfig,
    ...props,
  });

  return formHook;
};
