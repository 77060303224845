import React, { useEffect } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the results of the import after completion of an import
 * @param props
 * @param {object[]} results - the resulting objects to display in a grid
 * @param {function} resultGrid - a useGrid or useGridCard hook that is used to display results
 * @constructor
 */
const ResultGrids = ({ results, resultGrid: useResultGridHook, organizationID }) => {
  const queryConfig = {
    query: null,
    organizationID,
  };

  const grid = useResultGridHook({
    createResourceComponent: null,
    options: ["details"],
    organizationID,
    queryConfig,
    ...queryConfig,
  });

  useEffect(() => {
    if (Array.isArray(results)) {
      grid.setData([...results]);
    }
  }, [results]);

  return <div style={{ height: "75vh" }}>{grid.display}</div>;
};

export default withOrganizationCheck(ResultGrids);
