import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import { ReactElement } from "react";

import { DateRangeShortcut } from "@hooks/views/useForm/components/CustomDateRangeInput/constants/DateRangeShortcut";
import { calculateMinMaxDateRangeLimits } from "@hooks/views/useForm/components/CustomDateRangeInput/functions/calculateMinMaxDateRangeLimits";
import { getShortcutDates } from "@hooks/views/useForm/components/CustomDateRangeInput/functions/getShortcutDates";
import { MomentDateRange } from "@hooks/views/useForm/components/CustomDateRangeInput/types";

export interface CustomRangeInputProps {
  value: MomentDateRange;
  onChange: (value: MomentDateRange) => void;
  dateRangeConfig?: {
    maxIntervalInDays: number;
  };
}

/**
 * Allows the user to input a date range
 */
const CustomDateRangeInput = ({ value, onChange, dateRangeConfig: config }: CustomRangeInputProps): ReactElement => {
  const { maxIntervalInDays } = config ?? {};

  const shortcuts = {
    items: Object.values(DateRangeShortcut).map((shortcut) => {
      return {
        label: shortcut,
        getValue: () => getShortcutDates(shortcut),
      };
    }),
  };

  const { minDate, maxDate } = calculateMinMaxDateRangeLimits({
    value,
    maxIntervalInDays,
  });

  const handleChange = (event: MomentDateRange): void => {
    const startDateInit = event?.[0];
    const endDateInit = event?.[1];
    const startDate = startDateInit ? moment(startDateInit).startOf("day") : null;
    const endDate = endDateInit ? moment(endDateInit).endOf("day") : null;
    onChange([startDate, endDate]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        value={value ?? [null, null]}
        slotProps={{
          shortcuts,
        }}
        onChange={handleChange}
        localeText={{ start: "Start Date", end: "End Date" }}
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
};

export default CustomDateRangeInput;
