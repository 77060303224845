import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 *
 * @param item {object} VendorCertificationControlLink
 * @returns {Promise<null>}
 * @constructor
 */

export const DeleteVendorCertificationControlLink = async (item) => {
  if (!item || !item.id) {
    ErrorLogger("Error! Invalid input for DeleteVendorCertificationControlLink function!");
    return null;
  }

  const { deleteMutation } = generateGraphql("VendorCertificationControlLink");
  if (item && item.id) {
    await ItemMutation(deleteMutation, {
      id: item && item.id,
    }).then(() => InfoLogger("VendorCertificationControlLink successfully deleted!"));
  } else {
    ErrorLogger("VendorCertificationControlLink was NOT successfully deleted! Invalid Input");
  }
};
