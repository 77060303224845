export const getMaturityLevel = (programElement) => {
  if (!programElement || !programElement.programSections || !programElement.programSections.items) {
    return null;
  }

  const currentLevels = [];
  const goalLevels = [];

  const programSections = programElement.programSections.items.sort((a, b) => a.name.localeCompare(b.name));

  for (const programSection of programSections) {
    const level =
      programSection && programSection.currentLevel && programSection.currentLevel.level
        ? programSection.currentLevel.level
        : undefined;
    const goal =
      programSection && programSection.goalLevel && programSection.goalLevel.level
        ? programSection.goalLevel.level
        : undefined;

    if (level) {
      currentLevels.push(level);
    }

    if (goal) {
      goalLevels.push(goal);
    }
  }

  return [
    currentLevels.reduce((a, b) => a + b, 0) / currentLevels.length,
    goalLevels.reduce((a, b) => a + b, 0) / goalLevels.length,
  ];
};
