import { Icon } from "@iconify/react";
import { Chip } from "@mui/material";
import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { resources } from "@rivial-security/role-utils";

import { routeType } from "../../../../../../definitions/constants/routeTypes";
import { getResourceRoute } from "../../../../../../definitions/functions/getResourceRoute";
import { useModal } from "../../../../../../hooks/views/useModal";
import TaskDetails from "../../../../../Program/Tasks/components/TaskDetails";
import { questionnaireAssigneeStatus } from "../../../constants/questionnaireAssigneeStatus";

/**
 * Custom field for displaying the task status of a particular questionnaire assignee
 * @param {object} item - value of the entire questionnaire assignee row
 * @param {string} questionnaireAssigneeStatusValue - precomputed value when retrieving questionnaire assignee
 * @returns {JSX.Element}
 */
const QuestionnaireAssigneeResponseStatusField = ({
  item,
  questionnaireAssigneeStatus: questionnaireAssigneeStatusValue,
} = {}) => {
  const getChipColor = () => {
    if (questionnaireAssigneeStatusValue === questionnaireAssigneeStatus.RESPONDED) {
      return "success";
    } else if (questionnaireAssigneeStatusValue === questionnaireAssigneeStatus.IN_PROGRESS) {
      return "info";
    } else if (questionnaireAssigneeStatusValue === questionnaireAssigneeStatus.SCHEDULED) {
      return "warning";
    } else {
      return "error";
    }
  };

  const taskID = item?.task?.id;
  const routeToTask = getResourceRoute({
    typename: resources.TASK,
    routeType: routeType.DETAILS,
    prefix: "/",
    suffix: `${taskID}`,
  });

  const taskRouteAvailable = taskID && routeToTask;

  const taskDetailsModal = useModal("Questionnaire Assignee Task", <TaskDetails item={item?.task} />, null, {
    width: " 70vw",
  });

  return (
    <>
      <Chip
        color={getChipColor()}
        label={
          <span style={{ display: "flex", alignItems: "center" }}>
            {taskRouteAvailable && <Icon icon={"simple-line-icons:share-alt"} style={{ marginRight: ".5em" }} />}
            {convertCamelCaseToSentence(questionnaireAssigneeStatusValue)}
          </span>
        }
        size={"small"}
        style={taskRouteAvailable ? { cursor: "pointer" } : {}}
        title={taskRouteAvailable && "Navigate to User's Task"}
        onClick={
          taskRouteAvailable
            ? () => {
                taskDetailsModal.setModalIsOpen(true);
              }
            : null
        }
      />
      {taskDetailsModal.modal}
    </>
  );
};

export default QuestionnaireAssigneeResponseStatusField;
