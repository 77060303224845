import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * TODO: break out helper functions and write tests
 * Processes all of the data rows. Uses Promise.allSettled to allow for quickest runtime
 * @returns {Promise<void>}
 */
export const handleImport = async ({
  pleaseWaitModal,
  isAsync,
  processRow,
  preProcessRow,
  columnMap,
  organizationID,
  fields,
  nestedFields,
  setResults,
  workflow,
  input,
  connectionIDField,
  connectionID,
  typename,
}) => {
  pleaseWaitModal.setModalIsOpen(true);
  pleaseWaitModal.setStepStatus(0, "inProgress");

  const promises = [];

  // process using await. Much Slower, but makes sure there are no duplicates
  if (!isAsync) {
    for (const row of input) {
      promises.push(
        await processRow(
          row,
          columnMap,
          organizationID,
          fields,
          nestedFields,
          connectionIDField,
          connectionID,
          typename,
          preProcessRow,
        ).then((data) => {
          pleaseWaitModal.setProgress((progress) => progress + 1);
          if (!isNullOrUndefined(data))
            setResults((results) => {
              return [...results, data];
            });
        }),
      );
    }
    await Promise.allSettled(promises);
  }
  // If no Point of Contact column, proceed asynchronously.
  else {
    for (const row of input) {
      promises.push(
        processRow(
          row,
          columnMap,
          organizationID,
          fields,
          nestedFields,
          connectionIDField,
          connectionID,
          typename,
          preProcessRow,
        ).then((data) => {
          pleaseWaitModal.setProgress((progress) => progress + 1);
          if (!isNullOrUndefined(data)) setResults((results) => [...results, data]);
        }),
      );
    }
    await Promise.allSettled(promises);
  }

  workflow.progressTracker.setCurrentStep(workflow.progressTracker.steps[2]);
  pleaseWaitModal.setStepStatus(0, "complete");
  pleaseWaitModal.setFinished(true);
};
