import { useRecommendationsDataGrid } from "../../../../views/Risk/Recommendations/hooks/useRecommendationsDataGrid";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

export const recommendationDefinition = {
  routes: {
    grid: "governance/recommendations",
    details: "governance/recommendations",
    helpCenter: "recommendations",
  },

  schema: {
    indexNames: {
      byOwnerGroup: "recommendationsByOwnerGroup",
    },
  },

  //[APPEARANCE]
  displayName: "Recommendation",
  icon: "simple-line-icons:check",

  //[COMPONENTS]
  gridHook: useRecommendationsDataGrid,

  fields: {
    name: {},
    status: {},
    resolvedAt: {},
    module: {},
    rating: {},
    difficulty: {},
    createdAt: {},
    observations: {
      default: {
        nestedFields: `(limit: 100){
          items {
            id
            observation {
              name
              description
              isFinding
              module
            }
            proposedChange {
              typename
              field
              proposedValue
              completed
              effect
            }
          }
        }`,
      },
    },
    actionItems: {
      default: {
        nestedFields: `(limit: 100) {
          items {
            id
            action {
              id
              name
              description
            }
          }
        }`,
      },
    },
    data: {},
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    riskChanges: {
      default: {
        nestedFields: `(limit: 100) { items { id __typename } }`,
      },
    },
  },

  views: {
    grid: {
      queryFields: ["name", "rating", "status", "module", "observations", "difficulty", "tags"],
    },
    details: {
      displayFields: ["name", "status", "resolvedAt", "module", "rating", "difficulty", "createdAt", "tags"],
      queryFields: [
        "name",
        "status",
        "resolvedAt",
        "module",
        "rating",
        "difficulty",
        "createdAt",
        "actionItems",
        "riskChanges",
        "data",
        "notes",
        "tags",
      ],
    },
  },
};
