import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";

import schema from "../../../graphql/schema.json";
import { useStateEffect } from "../../../hooks/functional/useStateEffect";
import { useForm } from "../../../hooks/views/useForm";
import CustomLabel from "../../../hooks/views/useForm/components/CustomLabel";
import { parseSchemaTypes } from "../functions/parseSchemaTypes";

import { useResultFieldBuilder } from "./useResultFieldBuilder";
import { useResultFieldViewer } from "./useResultFieldViewer";

/**
 * Responsible for building out a 'query' object representing all nested fields
 * and post-query functions to apply on the data for a specific type, doesn't
 * include UI to run a query
 * @param {object} loadedQueryInit - The query to load initially
 * @return {{display: JSX.Element, query: *, graphql: string}}
 */
export const useCustomQueryBuilder = ({ loadedQuery: loadedQueryInit }) => {
  /// [STATE]
  const [types] = useStateEffect(parseSchemaTypes({ schema, showConnections: false }));
  const [loadedQuery, setLoadedQuery] = useState(loadedQueryInit);
  useEffect(() => {
    setLoadedQuery(loadedQueryInit);
  }, [JSON.stringify(loadedQueryInit)]);

  /// [HOOKS]
  // - Dropdown input for selecting a type to use for this Query
  const selectTypenameForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      typename: {
        label: "Select a Resource Type",
        tooltip: "You can perform a customized Query on a single Database Type",
        inputType: "dropdown",
        dataTestId: "select-custom-query-typename",
        dropdownConfig: {
          customFindValueFunction: ({ data, value }) => {
            const typename = value?.typename;
            if (!typename || !Array.isArray(data)) {
              return null;
            }

            for (const item of data) {
              if (item?.value?.typename === typename) {
                return item;
              }
            }

            return null;
          },
          onSelectedOption: (value) => {
            setLoadedQuery(null);
          },
          data: types
            .sort((a, b) => a.typename.localeCompare(b.typename))
            .map((type) => {
              return {
                text: type.typename,
                value: type,
              };
            }),
        },
      },
    },
  });
  // - UI for building out a single result field
  const resultFieldBuilder = useResultFieldBuilder({
    rootResource: selectTypenameForm?.input?.typename,
    loadedQuery,
  });

  // - UI for viewing and selecting out the created result fields
  const resultFieldViewer = useResultFieldViewer({
    resultFields: resultFieldBuilder?.query?.fields,
    resultFunctions: resultFieldBuilder?.query?.functions,
    setPath: resultFieldBuilder?.setPath,
  });

  //On new query loaded update the typename chosen
  useEffect(() => {
    const newTypename = loadedQuery?.typename;
    if (newTypename) {
      selectTypenameForm.setInput({
        typename: { typename: newTypename },
      });
    }
  }, [loadedQuery?.id]);

  /// [UI]
  const display = (
    <div style={{ height: "100%", overflowY: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
          {selectTypenameForm.display}
          <Collapse isOpen={selectTypenameForm?.input?.typename}>
            <CustomLabel
              targetId="selected-fields"
              label="Select Fields"
              tooltip="Please select one or more level of nested fields and function to use in the query"
            />
            {resultFieldBuilder.display}
          </Collapse>
        </Grid>
        <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
          <CustomLabel
            targetId="field-preview"
            label="Response Tree"
            tooltip="This is an example structure of the data that will be returned when you run the defined query."
          />
          {resultFieldViewer.display}
        </Grid>
      </Grid>
    </div>
  );

  /// [RETURN]
  return {
    display,
    query: resultFieldBuilder?.query,
  };
};
