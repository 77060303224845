import Grid from "@mui/material/Grid";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import TaskDataGrid from "../../../Program/Tasks/components/TaskDataGrid";
import DepartmentFindingsCard from "../components/DepartmentFindingsCard";
import DepartmentMembers from "../components/DepartmentMembers";
import { DEPARTMENT } from "../constants/DEPARTMENT";

/**
 * Displays the details of a single Department
 *
 * @param {string} itemId - the ID of the Department to query
 * @param {string} organizationID - the ID of the Organization that the Department belongs to
 * @param {object} props - all props passed to the useDetailsCard hook
 *
 * @see DEPARTMENT
 */
export const useDepartmentDetails = ({ itemId, organizationID, ...props }) => {
  const fields = [DEPARTMENT.fields.name, DEPARTMENT.fields.description, DEPARTMENT.fields.departmentHead];

  const queryFields = [
    DEPARTMENT.fields.name,
    DEPARTMENT.fields.description,
    DEPARTMENT.fields.departmentHead,
    DEPARTMENT.fields.departmentHeadID,
  ];

  const { getQuery, updateMutation } = generateGraphql(DEPARTMENT.typename, queryFields, DEPARTMENT.nestedFields);

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const customFields = [
    {
      field: DEPARTMENT.fields.departmentHead,
      component: DEPARTMENT.fieldConfig.departmentHead.component,
    },
  ];

  const details = useDetailsCard({
    itemId,
    organizationID,
    fields,
    customFields,
    nestedFields: DEPARTMENT.nestedFields,
    typename: DEPARTMENT.typename,
    resource: DEPARTMENT.resource,
    module: DEPARTMENT.module,
    queryConfig,
    updateMutation,
    ...props,
  });

  const accordion = useAccordion({
    details: details?.item,
    items: [
      {
        id: "department-details-team-members",
        title: "Team Members",
        subTitle: "Points of Contact part of this Department",
        component: <DepartmentMembers item={details.item} />,
        defaultExpanded: true,
      },
      {
        id: "evidence-details-findings",
        title: "Findings",
        subTitle: "Observations, Recommendations, and Action Items",
        component: <DepartmentFindingsCard organizationID={organizationID} itemId={itemId} />,
      },
      {
        id: "department-details-tasks",
        title: "Tasks",
        subTitle: "Tasks assigned to this Department and its members",
        component: (
          <StyledWrapper wrapperStyle={{ height: "50vh" }}>
            <TaskDataGrid
              normalizeData={(data) => {
                const newData = [];
                if (Array.isArray(data)) {
                  for (const task of data) {
                    let currentDepartmentID = task?.department?.id;
                    const pointOfContact = task?.pointOfContact;
                    if (!isNullOrUndefined(pointOfContact)) {
                      currentDepartmentID = pointOfContact?.department?.id;
                    }

                    if (currentDepartmentID === itemId) {
                      newData.push(task);
                    }
                  }
                }

                return newData;
              }}
              createFormProps={{
                item: {
                  departmentID: itemId,
                },
              }}
            />
          </StyledWrapper>
        ),
      },
    ],
  });

  const display = (
    <Dashboard>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <DashboardCard title={"General Information"} icon={"icon-list"}>
            {details.tableDisplay}
          </DashboardCard>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {accordion?.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
