import * as Sentry from "@sentry/react";
import React, { useContext, useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { UIContext } from "../../../../utils/Context/UIContext";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { useReportDataGrid } from "../../hooks/useReportDataGrid";
import { listReportsToOpenByOwnerGroup } from "../constants/listReportsToOpenByOwnerGroup";
import { openReportInEditor } from "../functions/openReportInEditor";

/**
 * Tab settings for the 'open' tab content in the document editor drawer file menu
 * @param {object} documentEditorContainerRef - the document editor container reference
 * @param {string} organizationID - currently selected organization
 * @return {{tab: {icon: JSX.Element, label: string, prefixComponent: JSX.Element, items: [{onClick: onClick, visible, subtitle: string, icon: JSX.Element, showBadge: boolean, headline: string},{onClick: onOpenLocalFile, subtitle: string, iconBackgroundColor: string, icon: JSX.Element, headline: string}]}}}
 */
export const useDocumentEditorOpenTab = ({ documentEditorContainerRef, organizationID } = {}) => {
  // [STATE & CONTEXT]
  const { addToast, updateToast } = useContext(UIContext);

  // [PERMISSION CHECKS]
  const checkPermissions = useCheckPermissions({
    module: modules.REPORTS,
    resource: resources.REPORT,
  });

  // [OPEN ACTIONS]
  const getOpenButtonElement = () => {
    //Find sync fusion button with id that ends in "_toolbar_open"
    const openButtons = document.querySelectorAll(`[id$="_toolbar_open"]`);

    //Get the first item if any
    let openButton;
    if (openButtons?.length > 0) {
      openButton = openButtons[0];
    }

    return openButton;
  };
  // - From local file
  const onOpenLocalFile = () => {
    const openButton = getOpenButtonElement();
    if (openButton) {
      openButton.click();
    } else {
      Sentry.captureMessage(
        "User tried opening a report from the document editor drawer but the open button wasn't found!",
        {
          level: "warning",
        },
      );
    }
  };
  //hide the sync fusion toolbar open button
  useEffect(() => {
    const openButton = getOpenButtonElement();
    const parent = openButton?.parentElement;
    if (parent?.style) {
      parent.style.display = "none";
    }
  }, []);

  // - From reports module
  const reportGrid = useReportDataGrid({
    organizationID,
    gridConfig: {
      enableSelectButton: true,
      onSelectCallback: async (report) => {
        const ref = documentEditorContainerRef?.current;
        await performToastOperation({
          addToast,
          updateToast,
          operation: async () => openReportInEditor({ ref, report, organizationID }),
          inProgressText: "Opening the selected report...",
          failedText: "Failed to open the report!",
          successText: "Successfully opened the report!",
          iconColor: "success",
        });
        reportsGridModal.toggleModal();
      },
      createResourceComponent: null,
    },
    queryConfig: {
      query: null,
    },
  });

  const reportsGridModal = useModal(
    "Open a Report",
    <div style={{ height: "50em" }}>{reportGrid.gridDisplay}</div>,
    null,
    {
      width: "70vw",
    },
  );

  //Get data for the select a report to open grid
  useEffect(() => {
    const getData = async () => {
      const data = await ListQueryBy({
        query: listReportsToOpenByOwnerGroup,
        variables: {
          ownerGroup: organizationID,
        },
      });

      //filter out all reports that do not have documents
      const reports = data.filter(
        (report) => Array.isArray(report?.documents?.items) && report.documents.items.length > 0,
      );

      reportGrid.setData(reports);
    };
    if (reportsGridModal?.modalIsOpen) {
      getData();
    }
  }, [reportsGridModal?.modalIsOpen]);

  // [TAB CONFIG]
  const tab = {
    label: "Open",
    icon: getIcon("fa6-regular:folder-open"),
    prefixComponent: <div>{reportsGridModal.modal}</div>,
    items: [
      {
        headline: "Open from the Reports Module",
        subtitle: "View and edit documents that were saved on the cloud within the Reports module.",
        icon: getIcon("ion:documents-outline"),
        visible: checkPermissions.resource.read,
        showBadge: true,
        onClick: () => {
          reportsGridModal.setModalIsOpen(true);
        },
      },
      {
        headline: "Open from Local Files",
        subtitle: "View and edit documents from your computer's storage.",
        icon: getIcon("system-uicons:harddrive"),
        iconBackgroundColor: "#00b402",
        onClick: onOpenLocalFile,
      },
    ],
  };

  return {
    tab,
  };
};
