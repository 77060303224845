import { isNullOrUndefined } from "@rivial-security/func-utils";

import { InfoLogger } from "../../EventLogger";

import { duplicateItem } from "./duplicateItem";

/**
 * Creates multiple many-to-many connection objects for an item.
 * @param {string} connectionTypename - the typename for the many-to-many link e.g. "EvidencePointOfContactLink"
 * @param {object} item - the parent item that we are adding connections to. e.g. an 'Evidence' item
 * @param {object[]} connectedItems - array of objects that we are connecting to. e.g. an array of 'Points of Contact'
 * @param {string} itemConnectionIDField - the connection ID field for the parent item. e.g. "evidenceID"
 * @param {string} childConnectionIDField - the connection ID field for the connected items. e.g. "pointOfContactID"
 * @param {string} organizationID - the organization ID field
 * @param {string[]} connectionFields - array of extra fields to be copied over to the many-to-many model itself
 * @param {function} mutationFunction - async function that fully handles the mutation of a connectionItem. takes precedence over built in mutation
 */
export const duplicateItemConnections = async (
  connectionTypename,
  item,
  connectedItems,
  itemConnectionIDField,
  childConnectionIDField,
  organizationID,
  connectionFields = [],
  mutationFunction,
) => {
  InfoLogger("Duplicating Item Connections..");

  const promises = [];

  for (const connectedItem of connectedItems) {
    // First, tries mutationFunction and skips the built in handler
    // if no mutationFunction is present, tries using the built in handler
    if (typeof mutationFunction === "function") {
      promises.push(
        mutationFunction({
          item,
          connectedItem,
          organizationID,
        }),
      );

      // breaks out of the loop
      continue;
    }

    // Sets up the parent ID input for the newly created connection item
    const input = {
      [itemConnectionIDField]: item.id,
    };

    // handles extra fields on the many-to-many connection object
    if (connectionFields) {
      for (const field of connectionFields) {
        input[field] = connectedItem[field];
      }
    }

    // If a childConnectionIDField is passed in, this is treated as a many-to-many connection Schema Type
    if (!isNullOrUndefined(childConnectionIDField) && !isNullOrUndefined(connectedItem?.id)) {
      input[childConnectionIDField] = connectedItem.id;
    }
    // If not childConnection, this is treated as a one-to-many relationship
    else {
      const ignoreList = ["ownerGroup", "id", "__typename"];

      for (const [field, value] of Object.entries(connectedItem)) {
        if (ignoreList.includes(field) === false) {
          input[field] = value;
        }
      }
    }

    const duplicated = duplicateItem({
      item: {},
      organizationID,
      typename: connectionTypename,
      input,
      connectionFields,
    });

    promises.push(duplicated);
  }

  return Promise.allSettled(promises);
};
