import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useEvidenceActivity } from "../hooks/useEvidenceActivity";
import { useGroupEvidenceActivitySwitch } from "../hooks/useGroupEvidenceActivitySwitch";
import { useRecordEvidence } from "../hooks/useRecordEvidence";

import EvidenceActivity from "./EvidenceActivity";

/**
 * Displays a list of evidence activities in a timeline inside a dashboard card
 * @param {object} item - the evidence data for which to display activity
 * @param {function} setItem - can be used to update the state of parent item
 * @param {*} resetIndex - reset key to update children on demand
 * @param {string} organizationID - currently selected organization identifier in the database
 * @param {function} resetFunction - function to call when 'item' needs to be updated
 * @param {string} module - platform module to use for role checking
 * @param {string} resource - platform resource to use for role checking
 * @return {JSX.Element} - the evidence activity UI component
 */
const EvidenceActivityCard = ({
  item,
  setItem,
  resetIndex,
  organizationID,
  resetFunction,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
}) => {
  const recordEvidence = useRecordEvidence({
    evidence: item,
    organizationID,
    resetFunction,
    enableHighPriorityItemCheck: true,
  });

  const checkPermissions = useCheckPermissions({ module, resource });

  const groupEvidenceActivitySwitch = useGroupEvidenceActivitySwitch();
  const { sortedActivity, isLoading } = useEvidenceActivity({
    item,
    setItem,
    key: resetIndex,
    isGrouped: groupEvidenceActivitySwitch?.isGrouped ?? false,
  });

  return (
    <DashboardCard
      id={"evidence-activity-card"}
      title={"Evidence Activity"}
      icon={"icon-energy"}
      style={{ height: "65vh", overflowY: "auto" }}
      headerButtons={[
        groupEvidenceActivitySwitch.display,
        checkPermissions.resource.update ? recordEvidence.button : <div />,
      ]}
    >
      {recordEvidence.modal}
      <EvidenceActivity
        item={{
          ...item,
          activity: {
            items: sortedActivity,
          },
        }}
        isLoading={isLoading}
        resetFunction={resetFunction}
      />
    </DashboardCard>
  );
};

export default withOrganizationCheck(EvidenceActivityCard);
