/**
 * Function used to aggregate residual change across many systems and risk changes
 * @param {object} seenSystems - systems that already have changes recorded from previous risk changes
 * @param {object} systemChanges - all individual system changes for this risk change
 * @param {string} systemID - the system for which the change is occurring
 * @return {number} - the final residual change for this system after the risk change is applied
 */
export const getCombinedSystemResidualChange = ({ seenSystems, systemChanges, systemID }) => {
  //No change if the parameters are not provided
  if (!seenSystems || !systemChanges || !systemID) {
    return 0;
  }

  //Find the changes that were applied before
  let previousChange = 0;
  if (seenSystems.hasOwnProperty(systemID)) {
    previousChange = seenSystems[systemID]?.residualChange ?? 0;
  }

  //Find the system change are applied as a result of the current risk change
  let currentChange = 0;
  const foundSystemChange = systemChanges.find((systemChange) => systemChange.id === systemID);
  if (foundSystemChange) {
    currentChange += foundSystemChange?.residualChange ?? 0;
  }

  return previousChange + currentChange;
};
