"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskScores_enterpriseRisk = void 0;
const getRiskScores_businessRisks_1 = require("./getRiskScores_businessRisks");
/**
 * Gets scores for a single Enterprise Risk
 * @param system
 * @param enterpriseRisk
 * @param riskMapping
 * @param monteCarloResults
 * @param ratingScale
 * @returns {Promise<{residualRisk: *, businessRisks: *, name: *, id: string, greatestBusinessRisk: ({keyRiskIndicators: ({residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string})[], residualRisk: number, name: string, greatestKeyRiskIndicator: {residualRisk: number, name: string, riskRating: string}, id: string, riskRating: string}|{keyRiskIndicators: [{residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}], residualRisk: number, name: string, greatestKeyRiskIndicator: {residualRisk: number, name: string, riskRating: string}, id: string, riskRating: string}|{keyRiskIndicators: [{residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}, {residualRisk: number, name: string, riskRating: string}], residualRisk: number, name: string, greatestKeyRiskIndicator: {residualRisk: number, name: string, riskRating: string}, id: string, riskRating: string}|{keyRiskIndicators: ({residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string}|{residualRisk: number, name: string, riskRating: string})[], residualRisk: number, name: string, greatestKeyRiskIndicator: {residualRisk: number, name: string, riskRating: string}, id: string, riskRating: string}|{}), inherentRisk: *, riskRating: *}>}
 */
const getRiskScores_enterpriseRisk = async ({ system, enterpriseRisk, riskMapping, monteCarloResults, ratingScale, }) => {
    const id = JSON.stringify(enterpriseRisk);
    const name = enterpriseRisk.name;
    const businessRisks = await (0, getRiskScores_businessRisks_1.getRiskScores_businessRisks)({
        system,
        enterpriseRisk,
        riskMapping,
        monteCarloResults,
        ratingScale,
    });
    return {
        id,
        name,
        greatestBusinessRisk: businessRisks.greatestBusinessRisk,
        residualRisk: businessRisks.residualRisk,
        inherentRisk: businessRisks.inherentRisk,
        riskRating: businessRisks.riskRating,
        businessRisks: businessRisks.businessRisks,
    };
};
exports.getRiskScores_enterpriseRisk = getRiskScores_enterpriseRisk;
