import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { InvalidParamError } from "../../../../analytics/CustomError";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";

/**
 * Gets all Vendor Control Categories with the property isTemplate = true
 * @param {string} organizationID
 * @returns {Promise<Array>}
 */
export const getControlCategoryTemplates = async (organizationID) => {
  if (isNullOrUndefined(organizationID)) {
    throw InvalidParamError("organizationID param cannot be null or undefined");
  }

  if (typeof organizationID !== "string") {
    throw InvalidParamError("organizationID param must be a string");
  }

  // get control category templates to duplicate into this review
  const { listQuery } = generateGraphql(
    "VendorControlCategory",
    ["name", "quantivateRiskCategory", "enableQuantivatePush", "subControls"],
    {
      subControls: `(limit: 100) { items { id ownerGroup statementNumber name vendorControlCategoryID } }`,
    },
  );

  return await ListQuery({
    query: listQuery,
    organizationID: organizationID,
    filters: {
      isTemplate: {
        eq: true,
      },
    },
  });
};
