"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scanner = void 0;
var Scanner;
(function (Scanner) {
    Scanner["OPENVAS"] = "openvas";
    Scanner["NESSUS"] = "nessus";
    Scanner["RAPID7"] = "rapid7";
    Scanner["QUALYS"] = "qualys";
})(Scanner || (exports.Scanner = Scanner = {}));
