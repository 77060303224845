import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateObservation } from "../hooks/useCreateObservation";

/**
 * Component for creating a new Observation
 * @returns {*}
 * @constructor
 * @param props
 */
const CreateObservation = (props) => {
  return useCreateObservation(props).display;
};

export default withOrganizationCheck(CreateObservation);
