"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.builtInTemplates = void 0;
const BuiltInTemplateID_1 = require("./BuiltInTemplateID");
const root_1 = require("./OrganizationManager/Automation/NewQuestionnaireTaskNotification/root");
exports.builtInTemplates = {
    [BuiltInTemplateID_1.BuiltInTemplateID.NEW_QUESTIONNAIRE_TASK_NOTIFICATION]: root_1.root,
    //Also can be an array of root templates
    //arrayTemplate: [rootTemplate1, rootTemplate2],
};
