"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSection = void 0;
const generateHeadersFooters_1 = require("./generateHeadersFooters");
/**
 * Function responsible for creating a report section, all pages in the section have the same size as well as header and footers
 * @param {object} reportContext - report and organization info to use in the section formatting
 * @param {object[]} blocks - the sfdt blocks to paste into the section
 * @param {string} reportType - the report title to display in the headers of the section
 * @param {boolean} [isPortrait = true] - if TRUE height is larger than width for each page of this section
 * @returns {sectionFormat: {}, blocks: [], headersFooters: {}}
 */
const generateSection = ({ reportContext, blocks, reportType, isPortrait = true }) => {
    return {
        sectionFormat: {
            pageWidth: isPortrait ? 612 : 792,
            pageHeight: isPortrait ? 792 : 612,
            leftMargin: 72,
            rightMargin: 72,
            topMargin: 72,
            bottomMargin: 72,
            differentFirstPage: false,
            differentOddAndEvenPages: false,
            headerDistance: 0,
            footerDistance: 0,
            bidi: false,
        },
        blocks: [...blocks],
        headersFooters: (0, generateHeadersFooters_1.generateHeadersFooters)({ reportContext, reportType, isPortrait }),
    };
};
exports.generateSection = generateSection;
