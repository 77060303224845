import { MenuComponent } from "@syncfusion/ej2-react-navigations";
import React, { useState } from "react";

/**
 * A custom hook around SyncFusions MenuComponent
 *
 * @author Jacob Blazina
 * @class
 * @param {function} [onClick]
 * @param {object[]} [initialMenuItems=[]]
 * @returns {{onClick: *, setMenuItems: *, display: *, menuItems: *, setOnClick: *}}
 */
export const useMenu = ({ onClick, initialMenuItems = [] }) => {
  const [menuItems, setMenuItems] = useState(initialMenuItems);

  /**
   * The onSelect event from MenuComponent
   * @function
   * @param {object} args - the full args object
   * @param {object} args.item
   * @param {object} args.properties
   * @param {string} args.properties.id - the id specified in the MenuItem object
   * @param {string} args.properties.text - the text specified in the MenuItem object (converts this to "name")
   */
  const onSelect = (args = {}) => {
    const { item = {} } = args;
    const { properties = {} } = item;
    const { id, text } = properties;
    onClick && onClick({ id, name: text });
  };

  const display = <MenuComponent items={menuItems} enableScrolling={true} select={(d) => onSelect(d)} />;

  return {
    display,
    menuItems,
    setMenuItems,
  };
};
