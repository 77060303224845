import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

import { CreateVendorCertificationControlLink } from "./CreateVendorCertificationControlLink";

/**
 *
 * @param name {string}
 * @param controls {object[]}
 * @param organizationID {string}
 * @returns {Promise<void>}
 * @constructor
 */

export const CreateVendorCertification = async ({ name, controls, organizationID }) => {
  const { createMutation } = generateGraphql("VendorCertification", ["name"]);

  return await ItemMutation(createMutation, {
    name,
    ownerGroup: organizationID,
  }).then(async (certification) => {
    if (certification && certification.id && controls && Array.isArray(controls) && controls.length > 0)
      await CreateVendorCertificationControlLink({
        controls,
        certification,
        organizationID,
      });
    return certification;
  });
};
