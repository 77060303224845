import { cloneDeep } from "lodash";
import React, { useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useStateEffect } from "../../../hooks/functional/useStateEffect";
import DataLoader from "../../LoadingComponents/DataLoader";
import MuiQueryBuilder from "../../QueryBuilder";

/**
 * A custom hook around our internal QueryBuilder component.
 * Used to create logical expressions
 *
 * @class
 * @param {object[]} columnDataInit - the initial data to be used as input options
 * @param {boolean} [readonly=false] - whether or not this component can be interacted with
 * @param {function} onChangeCallback - callback function that fires every time the queryBuilder gets updated
 * @param {boolean} freeSoloFieldInput - whether or not to allow free text input for the field selection
 * @param {string} itemId - if passed in, pre-sets the 'id' condition rule to 'id equals itemId'
 * @param {object} initialRule - optional, pass in the initial rule to prefill UI
 * @param {string} label - optional, label to display next to and or selection
 * @param {string[]} disabledOperators - optional, array of operators to disable
 * @returns {{setRule: *, readonly: *, getRule: *, display: *, columnData: *, rule: *, reset: *, setIsReadOnly: *, setColumnData: *}}
 */
export const useLogicBuilderV2 = ({
  columnDataInit = [],
  readonly = false,
  onChange: onChangeCallback,
  freeSoloFieldInput,
  itemId,
  initialRule: initialRuleParam,
  disabledOperators,
  label,
}) => {
  const [columnData, setColumnData] = useState(columnDataInit);

  const [isReadOnly, setIsReadOnly] = useState(readonly);

  const getInitialRule = () => {
    if (!isNullOrUndefined(initialRuleParam)) {
      return initialRuleParam;
    } else if (!isNullOrUndefined(itemId)) {
      return {
        combinator: "and",
        rules: [
          {
            field: "id",
            operator: "equal",
            value: itemId,
          },
        ],
      };
    } else {
      return {
        combinator: "and",
        rules: [],
      };
    }
  };

  const [filters, setFilters] = useStateEffect([], [columnData], () => {
    const result = [];

    const filter = {};

    filter.label = "Select a Field";

    filter.options = [];

    for (const column of columnData) {
      const option = {};

      option.label = column.label;
      option.value = column.field;
      option.type = column.type;

      if (column.type === "boolean") {
        option.type = "radio";
      }

      if (column.type === "string") {
        option.type = "text";
      }

      if (column.type === "enum") {
        option.type = "select";
        option.options = [];
        if (Array.isArray(column?.options)) {
          for (const enumOption of column.options) {
            const { value, label } = enumOption || {};
            option.options.push({
              value,
              label,
            });
          }
        }
      }

      filter.options.push(option);
    }

    result.push(filter);

    return result;
  });

  /**
   * Current state of the query builder
   */
  const [query, setQuery] = useState(getInitialRule());

  /**
   * Resets rule to initial state
   */
  const reset = () => {
    setQuery(getInitialRule());
  };

  /**
   * Function that returns the current rule
   */
  const getRule = () => {
    return query;
  };

  const onChange = (newQuery, valid) => {
    newQuery = cloneDeep(newQuery);
    const oldQueryString = JSON.stringify(query);
    const newQueryString = JSON.stringify(newQuery);

    //only dispatch change callback if the created query is different from the previous one
    if (oldQueryString !== newQueryString) {
      setQuery(newQuery);
      if (typeof onChangeCallback === "function") {
        onChangeCallback(newQuery);
      }
    }
  };

  const display = (
    <DataLoader isEnoughData={true} isLoading={false}>
      <MuiQueryBuilder
        freeSoloFieldInput={freeSoloFieldInput}
        key={JSON.stringify(filters)}
        filters={filters}
        query={query}
        onQueryChange={onChange}
        maxLevels={0}
        readonly={readonly}
        label={label}
        disabledOperators={disabledOperators}
      />
    </DataLoader>
  );

  return {
    columnData,
    setColumnData,
    display,
    filters,
    setFilters,
    getRule,
    reset,
    readonly: isReadOnly,
    setIsReadOnly: setIsReadOnly,
    query,
    setQuery,
  };
};
