import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  PasteCleanup,
  QuickToolbar,
  RichTextEditorComponent,
  Table,
  Toolbar,
  ToolbarType,
} from "@syncfusion/ej2-react-richtexteditor";
import { useEffect, useState } from "react";

import { useRefState } from "../functional/useRefState";

/**
 * RichTextEditor hook for editing HTML
 * @param {string} id="richTextEditor" - the id to assign to the component
 * @param {string} initialValue - Pass initial value in html format to the editor
 * @param {object} editorStyle - Style the editor height (ex.: editorStyle.height)
 * @param {number} maxEditorCharactersCount - Specify maximum editor characters count
 * @param {boolean} [ignoreFocus=false] - stops the editor from focusing on load
 * @param {boolean} [enableAllFeatures=false] - enables all features of the editor (more toolbar items)
 * @param {function} onChange - callback function to call when the editor value changes
 * @param {boolean} [disableToolbar=false] - disables the toolbar
 * @param {boolean} [readOnly=false] - makes the editor read only
 * @returns {{getValue: (function(): string|*), ref: string, display: JSX.Element, setValue: setValue}}
 * @example const {getValue, ref, display, setValue} = useRichTextEditor({initialValue: "", editorStyle: {height: "200px"}});
 */
export const useRichTextEditor = ({
  id = "richTextEditor",
  initialValue = "",
  onChange,
  editorStyle,
  maxEditorCharactersCount,
  ignoreFocus = false,
  enableAllFeatures = false,
  disableToolbar = false,
  readOnly = false,
}) => {
  const [data, setData] = useState("");
  const [ref, setRef] = useState("");

  useEffect(() => {
    if (initialValue) {
      setData(initialValue);
    }
  }, [initialValue]);

  const getValue = () => {
    return ref && ref.getHtml();
  };

  const setValue = (val = "") => {
    setData(val);
  };

  const onCreate = () => {
    if (!ignoreFocus) {
      ref && ref.focusIn();
    }
  };

  const hasContent = () => {
    const content = ref?.getHtml();
    return content && content !== "<p><br></p>";
  };

  //Shortcut CTRL + A implementation to select all content
  const [, setIsFocused, isFocusedRef] = useRefState(false);
  const handleKeyPress = (e) => {
    if (e.keyCode === 65 && e.ctrlKey && isFocusedRef?.current) {
      e.preventDefault();
      ref?.selectAll?.();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [ref]);

  //Rich Text Editor ToolbarSettings
  const toolbarSettings = {
    type: ToolbarType.Expand,
    enableFloating: true,
    enable: !disableToolbar, // switch to false to disable the toolbar completely
    ...(enableAllFeatures && {
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "SuperScript",
        "SubScript",
        "|",
        "Formats",
        "Alignments",
        "OrderedList",
        "UnorderedList",
        "Outdent",
        "Indent",
        "|",
        "CreateTable",
        "CreateLink",
        "Image",
        "|",
        "ClearFormat",
        "Print",
        "SourceCode",
        "FullScreen",
        "|",
        "Undo",
        "Redo",
      ],
    }),
  };
  //Removes certain style attributes on data change
  const displayEditor = (
    <RichTextEditorComponent
      //focus and blur events
      focus={() => {
        setIsFocused(true);
      }}
      readonly={readOnly}
      blur={() => {
        setIsFocused(false);
      }}
      id={id}
      ref={(r) => setRef(r)}
      value={data}
      enableHtmlSanitizer={true}
      maxLength={maxEditorCharactersCount || 200000}
      //Manages what styling to keep on a user performing a paste command (ctrl+v on windows)
      //REFERENCE: https://ej2.syncfusion.com/react/documentation/rich-text-editor/paste-cleanup/
      pasteCleanupSettings={{
        //NOTE: there doesn't seem to be an exclusion parameter for style attributes
        allowedStyleProps: [
          "background",
          "background-color",
          "border",
          "border-bottom",
          "border-left",
          "border-radius",
          "border-right",
          "border-style",
          "border-top",
          "border-width",
          "clear",
          "color",
          "cursor",
          "direction",
          "display",
          "float",
          "font",
          "font-family",
          "font-size",
          "font-weight",
          "font-style",
          "height",
          "left",
          "line-height",
          "overflow",
          "overflow-x",
          "overflow-y",
          "table-layout",
          "text-align",
          "text-decoration",
          "vertical-align",
          "visibility",
          "white-space",
        ],
      }}
      toolbarSettings={toolbarSettings}
      height={(editorStyle && editorStyle.height) || 600}
      created={() => onCreate()}
      change={(event) => {
        if (typeof onChange === "function") {
          onChange(event?.value);
        }
      }}
    >
      <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, PasteCleanup, Table]} />
    </RichTextEditorComponent>
  );

  return {
    ref,
    display: displayEditor,
    getValue,
    setValue,
    hasContent,
  };
};
