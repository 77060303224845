/**
 * All types of connection type that a resource can have to one of its fields
 * @type {{ALL: string, HAS_ONE: string, NO_CONNECTIONS: string, HAS_MANY: string}}
 */
export const connectionFilters = {
  NO_CONNECTIONS: "noConnections",
  HAS_ONE: "hasOne",
  HAS_MANY: "hasMany",
  ALL: "all",
};
