/**
 * Returns whether or not an item has one of the selected tags
 * @param {object} item - database object (can be a row in a grid) with a tags link field
 * @param {object[]} selectedTags - an array of tag objects that are currently selected (such as in the filter menu)
 * @return {boolean} TRUE if a selected tag is part of the item, FALSE if not
 */
export const itemContainsAtLeastOneTag = (item, selectedTags) => {
  if (
    item?.tags?.items &&
    Array.isArray(item?.tags?.items) &&
    item?.tags?.items.length > 0 &&
    selectedTags &&
    Array.isArray(selectedTags) &&
    selectedTags.length > 0
  ) {
    for (const tag of selectedTags) {
      const foundMatch = item?.tags?.items.findIndex((item) => {
        if (item?.tag?.id && tag?.id) {
          return tag.id === item.tag.id;
        } else {
          return false;
        }
      });

      if (foundMatch !== -1) {
        return true;
      }
    }
  } else {
    return false;
  }

  return false;
};
