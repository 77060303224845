import React, { useEffect, useState } from "react";
import { InputGroup, Spinner } from "reactstrap";

import { formattedDollar, numberWithCommas } from "@rivial-security/func-utils";
import { getAValue } from "@rivial-security/risk-calc-utils";

import SelectRating from "../../../../RiskConfig/Assets/components/InformationAssets/SelectRating";

export const useAdjustAvailabilityField = ({ newAvailability, setNewAvailability, ratingScale, assetSize }) => {
  const [aValue, setAValue] = useState(0);

  useEffect(() => {
    const system = {
      availability: newAvailability,
    };

    const val = getAValue(system, ratingScale, assetSize);

    setAValue(val);
  }, [newAvailability, ratingScale, assetSize]);

  const display = (
    <>
      {newAvailability && (
        <>
          <div style={{ display: "inline-block", marginRight: "1em" }}>
            <h7>Availability Value:</h7>
          </div>
          <div style={{ display: "inline-block" }}>
            {!ratingScale || !assetSize ? (
              <div>
                Loading Availability Rating..
                <Spinner size="sm" color="primary" />
              </div>
            ) : (
              <InputGroup>
                <SelectRating rating={newAvailability} setRating={setNewAvailability} ratingScale={ratingScale} />
                <div style={{ display: "inline-block" }}>
                  <strong>${numberWithCommas(aValue.toFixed(2))}</strong>{" "}
                  <i
                    className="icon-question"
                    title={`Availability Value = Availability Rating (${(ratingScale[newAvailability] * 100).toFixed(
                      4,
                    )}%) X Asset Size (${formattedDollar(assetSize)})`}
                  />
                </div>
              </InputGroup>
            )}
          </div>
        </>
      )}
    </>
  );

  return {
    display,
    availabilityChange: newAvailability,
  };
};
