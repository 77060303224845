import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useRecommendationsDataGrid } from "../hooks/useRecommendationsDataGrid";

/**
 * Component used in the Findings Dashboard to display Recommendations
 * @param {object}  props
 * @returns {JSX.Element}
 * @constructor
 */
const RecommendationsDataGrid = (props) => {
  return useRecommendationsDataGrid(props).display;
};

export default withOrganizationCheck(RecommendationsDataGrid);
