export const reportsByOwnerGroup = /* GraphQL */ `
  query ReportsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        createdAt
        type
        module
      }
      nextToken
    }
  }
`;

// export const reportsByOwnerGroup =
//   /* GraphQL */
//   `
//     query ReportsByOwnerGroup(
//       $ownerGroup: String
//       $sortDirection: ModelSortDirection
//       $filter: ModelPointOfContactFilterInput
//       $limit: Int
//       $nextToken: String
//     ) {
//       contactsByOwnerGroup(
//         ownerGroup: $ownerGroup
//         sortDirection: $sortDirection
//         filter: $filter
//         limit: $limit
//         nextToken: $nextToken
//       ) {
//         items {
//           id
//           firstName
//           lastName
//           ownerGroup
//           title
//           email
//           phone
//           meetings {
//             items {
//               id
//             }
//           }
//           user {
//             id
//             username
//             email
//             name
//             roleLinks {
//               items {
//                 id
//               }
//             }
//           }
//         }
//         nextToken
//       }
//     }
//   `;
