import { useAssessmentDataGrid } from "../../../../views/Testing/Assessments/hooks/useAssessmentDataGrid";

export const assessmentDefinition = {
  routes: {
    grid: "vulnerabilities/assessments",
    details: "vulnerabilities/assessments",
    helpCenter: "assessments",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:badge",

  //[COMPONENTS]
  gridHook: useAssessmentDataGrid,

  fields: {
    name: {},
    scheduledDate: {},
    status: {},
    type: {},
    penTester: {},
    penTesterEmail: {},
    scanStatus: {},
    importStatus: {},
    scanner: {},
    scoreCSVV: {},
    taskId: {},
  },
};
