"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIValue = void 0;
const getImpactCost_1 = require("./getImpactCost");
/**
 * Gets the Integrity Impact dollar amount for a System
 * @param system
 */
const getIValue = (system) => {
    const field = "integrityPerRecord";
    const maxI = system ? (0, getImpactCost_1.getImpactCost)(system, field) : 0;
    return maxI;
};
exports.getIValue = getIValue;
