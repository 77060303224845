import { modules, resources } from "@rivial-security/role-utils";

import { useCreateEmailTemplate } from "../../OrganizationManager/EmailTemplates/hooks/useCreateEmailTemplate";

/**
 * Custom form component for creating Global and Operation Team Email Templates
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the form
 * @returns {JSX.Element}
 */
const EmailTemplateForm = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.EMAIL_TEMPLATE,
  disableRoleChecking = false,
  ...props
}) => {
  const form = useCreateEmailTemplate({
    organizationID,
    module,
    resource,
    disableRoleChecking,
    isTemplate: true,
    hiddenFields: ["recipients", "reply-to"],
    ...props,
  });

  return form.display;
};

export default EmailTemplateForm;
