import React, { useContext, useEffect } from "react";
import { Progress } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

import { useStateEffect } from "../../../../hooks/functional/useStateEffect";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import Rating from "../customFields/Rating";

/**
 * Gets the percentage of controls that are 'inPlace' and 'audited' for a control category.
 * Returns a percentage value (already multiplied by 100)
 * @param controlCategory
 * @returns {*}
 */
const getCurrentPercentage = (controlCategory) => {
  if (controlCategory) {
    const subControls =
      controlCategory && controlCategory.subControls && controlCategory.subControls.items
        ? controlCategory.subControls.items
        : [];
    const total = subControls.length;
    let currentInPlace = 0;
    let currentAudited = 0;

    for (const subControl of subControls) {
      if (subControl.inPlace) currentInPlace++;
      if (subControl.audited) currentAudited++;
    }

    return {
      inPlacePercentage: ((currentInPlace / total) * 100).toFixed(0),
      auditedPercentage: ((currentAudited / total) * 100).toFixed(0),
    };
  } else {
    return 0;
  }
};

/**
 * Shows percentages of 'inPlace', 'audited' and 'not in place' for each control category
 * @param {object} data
 * @param config
 * @returns {*}
 * @constructor
 */
const ControlCategoryBulletChart = ({ data = [], config = {} }) => {
  return (
    <>
      {data.map((controlCategory) => (
        <div key={JSON.stringify(controlCategory)} style={{ marginBottom: "1em" }}>
          <strong>{controlCategory.controlCategory}</strong>
          <Progress multi max={100} style={{ marginBottom: "0.5em" }}>
            <Progress bar color="primary" value={controlCategory.inPlace}>
              {controlCategory.inPlace}% In Place
            </Progress>
            <Progress bar color="secondary" value={100 - controlCategory.inPlace}>
              {100 - controlCategory.inPlace}% Not in Place
            </Progress>
          </Progress>
          <Progress multi max={100}>
            <Progress bar color="success" value={controlCategory.audited}>
              {controlCategory.audited}% Audited
            </Progress>
            <Progress bar color="secondary" value={100 - controlCategory.audited}>
              {100 - controlCategory.audited}% Not Audited
            </Progress>
          </Progress>
        </div>
      ))}
    </>
  );
};

/**
 * Displays an overview of the Control Categories
 * @param controlCategories
 * @param controlCategory
 * @param vendorReview
 * @constructor
 */
const ControlOverview = ({ controlCategories, controlCategory, vendorReview }) => {
  /**
   * This is the data formatted for the progress bars
   */
  const [data, setData] = useStateEffect(null, [controlCategories], () => {
    if (controlCategory) {
      controlCategories.push(controlCategory);
    }
    if (controlCategories) {
      const res = [];
      for (const controlCategory of controlCategories.sort(({ name: nameA }, { name: nameB }) => {
        const x = nameA.split(" ")[0];
        const y = nameB.split(" ")[0];

        return x - y;
      })) {
        const { inPlacePercentage, auditedPercentage } = getCurrentPercentage(controlCategory);
        res.push({
          inPlace: inPlacePercentage,
          audited: auditedPercentage,
          controlCategory: `${controlCategory.name}`,
        });
      }
      return res;
    }
  });

  useEffect(() => {
    if (!controlCategories) {
      InfoLogger("Querying Vendor Review");
      const { getQuery } = generateGraphql("VendorReview", ["controlCategories"], {
        controlCategories: `(limit: 100) { items { id ownerGroup name subControls (limit: 100) { items { id name statementNumber ownerGroup inPlace audited } } } }`,
      });

      ItemQuery(getQuery, vendorReview.id).then((vendorReview) => {
        const controlCategories = vendorReview.controlCategories.items;
        const res = [];
        for (const controlCategory of controlCategories.sort(({ name: nameA }, { name: nameB }) => {
          const x = nameA.split(" ")[0];
          const y = nameB.split(" ")[0];

          return x - y;
        })) {
          const { inPlacePercentage, auditedPercentage } = getCurrentPercentage(controlCategory);
          res.push({
            inPlace: inPlacePercentage,
            audited: auditedPercentage,
            controlCategory: `${controlCategory.name}`,
          });
        }
        setData(res);
      });
    }
  }, [controlCategories]);

  return (
    <div style={{ paddingBottom: "3.0em" }}>
      {data ? (
        <ControlCategoryBulletChart data={data} config={{ height: `${30 * data.length}px` }} />
      ) : (
        <LoadingSpinner loadingMessage=" Loading Control Data.." />
      )}
    </div>
  );
};

/**
 * In order to access React Context where the `display` param is used,
 * the Display component can't be declared inside of the react hook.
 * @param rating
 * @returns {*}
 * @constructor
 */
const Display = ({ module, resource, disableRoleChecking }) => {
  const context = useContext(WorkflowContext);

  return (
    <div>
      <div className="e-card" id="basic">
        <div className="e-card-content">
          <div className="dashboard-title e-card-title">Set Risk Rating</div>
          <hr />
          <h5>Risk Rating: {convertCamelCaseToSentence(context.rating)}</h5>
          <Rating
            vendorReview={context.vendorReview}
            module={module}
            resource={resource}
            disableRoleChecking={disableRoleChecking}
          />
          <hr />
          <h5>Control Overview</h5>
          <ControlOverview controlCategories={context.controlCategories} vendorReview={context.vendorReview} />
        </div>
      </div>
    </div>
  );
};

/**
 * Allows the user to adjust the Rating value for the Vendor Review
 */
export const useSetRating = (props) => {
  return {
    display: <Display {...props} />,
  };
};
