import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

import OperationTeamsListItemDetails from "./OperationTeamsListItemDetails";

/**
 * @description Display a single operation team
 * @param {object} item - operation team
 * @returns {JSX.Element}
 * @constructor
 */
export const OperationTeamListItem = ({ item } = {}) => {
  const labelId = `operation-team-list-label-${item?.id}`;

  return (
    <>
      {item?.name && (
        <Tooltip title={<OperationTeamsListItemDetails item={item} />}>
          <ListItem key={item?.id} button>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "black" }}>
                <GroupsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText id={labelId} primary={`${item?.name}`} />
          </ListItem>
        </Tooltip>
      )}
    </>
  );
};
