import React from "react";

import { useImageViewer } from "./useImageViewer";

/**
 * @description basic component for viewing image from url
 * @param {object} props - props for the component
 */
const ImageViewer = (props) => {
  const imageViewer = useImageViewer(props.url);

  return <div>{imageViewer.display}</div>;
};

export default ImageViewer;
