import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useSourceDetailsAccordion } from "../hooks/useSourceDetailsAccordion";

/**
 * Component instance of the useSourceDetails hook
 * @param props
 * @returns {*}
 * @constructor
 */
const SourceDetails = (props) => useSourceDetailsAccordion(props).display;

export default withDetailsPage(SourceDetails, { title: "Source Details" });
