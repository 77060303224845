import { defaultEmailContentWidth } from "./defaultEmailContentWidth";

/**
 * Provides a starting design for the Rivial email templates, standard content may be replaced with custom html
 * @param {string} contentHTML - the starting message content (html string)
 * @param {number} contentWidth - the width of the content (default grabbed from constant)
 * @returns {object} - the starting design for the Rivial email templates
 */
export const defaultEmailTemplate = ({ contentWidth, contentHTML }) => {
  return {
    counters: {
      u_column: 3,
      u_row: 3,
      u_content_heading: 1,
      u_content_divider: 3,
      u_content_text: 1,
      u_content_image: 1,
    },
    body: {
      id: "G6agaQ8jJ9",
      rows: [
        {
          id: "6F4dqdqpLG",
          cells: [1],
          columns: [
            {
              id: "6kGyMgsrgx",
              contents: [
                {
                  id: "_RjCOXafgJ",
                  type: "divider",
                  values: {
                    width: "100%",
                    border: {
                      borderTopWidth: "4px",
                      borderTopStyle: "solid",
                      borderTopColor: "#2c4c93",
                    },
                    textAlign: "center",
                    containerPadding: "10px 0px",
                    anchor: "",
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_divider_2",
                      htmlClassNames: "u_content_divider",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
              values: {
                backgroundColor: "",
                padding: "0px",
                border: {},
                _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        {
          id: "X4-AbVBuOx",
          cells: [1],
          columns: [
            {
              id: "73FabQ-DV6",
              contents: [
                {
                  id: "Xw7BukExND",
                  type: "heading",
                  values: {
                    containerPadding: "10px 10px 5px",
                    anchor: "",
                    headingType: "h1",
                    fontFamily: {
                      label: "Arial",
                      value: "arial,helvetica,sans-serif",
                    },
                    fontSize: "25px",
                    color: "#50a9cc",
                    textAlign: "center",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_heading_1",
                      htmlClassNames: "u_content_heading",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: "<strong>RIVIAL PLATFORM NOTIFICATION</strong>",
                  },
                },
                {
                  id: "uUClxGmuvy",
                  type: "divider",
                  values: {
                    width: "100%",
                    border: {
                      borderTopWidth: "1px",
                      borderTopStyle: "solid",
                      borderTopColor: "#BBBBBB",
                    },
                    textAlign: "center",
                    containerPadding: "5px 40px 10px",
                    anchor: "",
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_divider_1",
                      htmlClassNames: "u_content_divider",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
                {
                  id: "IKjze0ANDU",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_text_1",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text:
                      contentHTML ||
                      '<p style="font-size: 14px; line-height: 140%; text-align: center;">Add Your Text Here.</p>',
                  },
                },
                {
                  id: "lIIsHJnTE_",
                  type: "divider",
                  values: {
                    width: "100%",
                    border: {
                      borderTopWidth: "4px",
                      borderTopStyle: "solid",
                      borderTopColor: "#2c4c93",
                    },
                    textAlign: "center",
                    containerPadding: "10px 0px",
                    anchor: "",
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_divider_3",
                      htmlClassNames: "u_content_divider",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
              values: {
                backgroundColor: "",
                padding: "0px",
                border: {},
                borderRadius: "0px",
                _meta: { htmlID: "u_column_3", htmlClassNames: "u_column" },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            padding: "0px",
            anchor: "",
            _meta: { htmlID: "u_row_3", htmlClassNames: "u_row" },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      values: {
        popupPosition: "center",
        popupWidth: "600px",
        popupHeight: "auto",
        borderRadius: "10px",
        contentAlign: "center",
        contentVerticalAlign: "center",
        contentWidth: `${contentWidth || defaultEmailContentWidth}px`,
        fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
        textColor: "#000000",
        popupBackgroundColor: "#FFFFFF",
        popupBackgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: true,
        },
        popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
        popupCloseButton_position: "top-right",
        popupCloseButton_backgroundColor: "#DDDDDD",
        popupCloseButton_iconColor: "#000000",
        popupCloseButton_borderRadius: "0px",
        popupCloseButton_margin: "0px",
        popupCloseButton_action: {
          name: "close_popup",
          attrs: {
            onClick: "document.querySelector('.u-popup-container').style.display = 'none';",
          },
        },
        backgroundColor: "#e7e7e7",
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        preheaderText: "",
        linkStyle: {
          body: true,
          linkColor: "#0000ee",
          linkHoverColor: "#0000ee",
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
      },
    },
    schemaVersion: 8,
  };
};
