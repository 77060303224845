import { useCustomFieldsTable } from "../hooks/useCustomFieldsTable";

export interface CustomFieldsTableProps {
  typename?: string;
  module: string;
  resource: string;
  disableRoleChecking: boolean;
  enableBackendUpdate: boolean;
  updateItemById: () => void;
  customFields: object[];
  updateMutation: string;
  item?: object;
  showAddButtonInit: boolean;
  forceTableVisibility: boolean;
  nameFieldLabel: string;
  customFieldName: string;
  customFieldParentName: string;
}

/**
 * A component instance of the useCustomFieldsTable.js hook
 * @param props - all props to pass down to the useCustomFieldsTable hook
 */
const CustomFieldsTable = (props: CustomFieldsTableProps): JSX.Element => {
  return useCustomFieldsTable(props).display;
};

export default CustomFieldsTable;
