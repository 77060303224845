import React from "react";
import { withRouter } from "react-router-dom";

import { modules, resources } from "@rivial-security/role-utils";

import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useManualChangeDetails } from "../hooks/useManualChangeDetails";

/**
 * Component wrapper for useManualChangeDetails
 * @param {string} itemId - the risk change id from database
 * @param {function} resetFunction - function that refreshes passed in data
 * @param {boolean} tableDisplay - if true will display the details as a table instead of a card
 * @return {JSX.Element}
 */
const ManualChangeDetailsBody = ({ itemId, resetFunction, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const manualChangeDetails = useManualChangeDetails({
    itemId,
    module,
    resource,
    resetFunction,
    tableDisplay,
  });

  return <>{manualChangeDetails.display}</>;
};

const ManualChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  return (
    <OrganizationCheck {...props}>
      <ManualChangeDetailsBody itemId={itemId} />
    </OrganizationCheck>
  );
};

export default withRouter(ManualChangeDetails);
