export const listAssessmentsByOwnerGroup = /* GraphQL */ `
  query ListAssessmentsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assessmentDate
        type
        pointOfContact {
          id
          firstName
          lastName
        }
        penTester
        status
        ownerGroup
      }
      nextToken
    }
  }
`;

export const listAssessmentVulnerabilityLinksByAssessmentId = /* GraphQL */ `
  query ListAssessmentVulnerabilityLinksByAssessmentID(
    $assessmentID: ID
    $vulnerabilityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentVulnerabilityLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentVulnerabilityLinksByAssessmentID(
      assessmentID: $assessmentID
      vulnerabilityID: $vulnerabilityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentID
        vulnerability {
          id
          createdAt
          name
          type
          severityLevel
          ports
          protocols
          cves
          cvss
          pluginID
          impact
          solution
          solutionType
          summary
          exploitAvailable
          pluginID
          manualPriority
          targets(limit: 1000) {
            items {
              id
              status
              priority
              firstSeen
              lastSeen
              targetID
              vulnerabilityID
            }
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listAssessmentTargetLinksByAssessmentId = /* GraphQL */ `
  query ListAssessmentTargetLinksByAssessmentId(
    $assessmentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentTargetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentTargetLinksByAssessmentID(
      assessmentID: $assessmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        target {
          id
          ip
          macAddress
          hostName
          ownerGroup
        }
      }
      nextToken
    }
  }
`;

export const assessmentTargetLinksFalsePositivesByAssessmentId = /* GraphQL */ `
  query ListAssessmentTargetLinksByAssessmentId(
    $assessmentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentTargetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentTargetLinksByAssessmentID(
      assessmentID: $assessmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        assessmentID
        targetID
      }
      nextToken
    }
  }
`;
