import { isNullOrUndefined } from "@rivial-security/func-utils";

import { InvalidParamError } from "../../analytics/CustomError";

/**
 * Used for One-to-many or Many-to-Many schema connections.
 * Handles null checking and returns an array of the desired type.
 * @param {Object} input - the input object
 * @param {string} field - the field representing the connection
 * @param {string} [connectionField] - optional, the nested field for many-to-many
 * @param {string[]} [connectionFields] - optional, fields to be gathered from the many-to-many model itself
 */
export const getMany = (input, field, connectionField, connectionFields = []) => {
  if (isNullOrUndefined(field)) {
    throw new InvalidParamError("field");
  }

  if (isNullOrUndefined(input)) {
    throw new InvalidParamError("input");
  }

  if (isNullOrUndefined(input[field]) || isNullOrUndefined(input[field].items) || !Array.isArray(input[field].items)) {
    throw new InvalidParamError(`input[field].items.[connectionField]`);
  }

  if (!isNullOrUndefined(connectionField) && input[field] && input[field].items && Array.isArray(input[field].items)) {
    return input[field].items.map((link) => {
      const obj = {
        ...link[connectionField], // spreads all fields from the linked object
        linkId: link.id, // for reference back to the linkId, since 'id' will be denoting the linked object
        link, // holds the link information
      };

      // handles extra fields on the connection object itself
      if (connectionFields) {
        for (const field of connectionFields) {
          obj[field] = link[field];
        }
      }

      return obj;
    });
  } else {
    return input[field].items;
  }
};
