import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useSetAsyncData } from "../../../../../hooks/functional/useSetAsyncData";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import { ListQueryBy } from "../../../../../utils/Functions/Graphql/ListQueryBy";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import CreateRiskChangeLandingPage from "../../components/CreateRiskChangeLandingPage";
import GroupedRiskChangeDetails from "../../components/GroupedRiskChangeDetails";
import ChangeType from "../../customFields/ChangeType";
import OverallEffectOfChange from "../../customFields/OverallEffectOfChange";
import { deleteGroupedRiskChanges } from "../../functions/deleteGroupedRiskChanges";

import { groupRiskChanges } from "./functions/groupRiskChanges";

/**
 * Grouped Risk Changes Data Grid, shows risk changes grouped by groupID
 * @param {string} organizationID - the organizationID for which to query changes
 * @param {object} queryConfig - if query property is null this hook won't populate the grid with all the organization risk changes
 * @param {object} gridConfig - parameters passed into the data grid
 * @param {boolean} isLoading - true if need to show the loading bar in the grid
 * @param {function} [resetFunction] - function to reset the grid data (if populated from the outside)
 * @param {string} height - css height constraint for the data grid
 * @param {object} props - props passed through to the data grid
 * @return {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: *, setData: setData, display: JSX.Element, dashboardDisplay: JSX.Element, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: function(): void, selectedIDs: [], setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useGroupedRiskChangeDataGrid = ({
  organizationID,
  queryConfig = {},
  gridConfig = {},
  isLoading = false,
  resetFunction: externalResetFunction,
  height = "85vh",
  ...props
}) => {
  // [PERMISSION CONFIG]
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;
  const roleConfig = {
    module,
    resource,
    disableRoleChecking: false,
  };

  // [CARD CONFIG
  const cardConfig = {
    title: "Real-Time Risk Changes",
    icon: "icon-graph",
    dashboardDisplay: true,
  };

  // [GRID CONFIG]
  const resetGridData = () => {
    resetFunction();
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 2,
      },
    },
    {
      name: "reason",
      flex: 1,
      minWidth: 200,
    },
    {
      name: "date",
      inputType: GENERIC_FIELD_TYPES.DATE,
      width: 200,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "changeOwner",
      visible: false,
      width: 150,
    },
    {
      name: "type",
      component: <ChangeType />,
      visible: false,
      width: 100,
    },
    {
      name: "change",
      component: (
        <OverallEffectOfChange
          residualChangeOverrideField={"totalResidualChange"}
          returnOnInvestmentOverrideField={"totalReturnOnInvestment"}
          costChangeOverrideField={"totalCostChange"}
        />
      ),
      width: 300,
    },
    {
      name: "totalSystems",
      friendlyName: "Systems Amount",
      width: 70,
      plainText: true,
    },
    {
      name: "totalChanges",
      friendlyName: "Changes Amount",
      width: 70,
      plainText: true,
    },
  ];

  gridConfig = {
    organizationID,
    fields,
    createItemModalHeader: "Make a Risk Change",
    createResourceComponent: <CreateRiskChangeLandingPage organizationID={organizationID} disableTitle={true} />,
    updateMutation: updateRiskChange,

    formatNewItem: (item) => {
      const groupedRiskChanges = groupRiskChanges({ riskChanges: [item] });
      if (Array.isArray(groupedRiskChanges) && groupedRiskChanges.length > 0) {
        return groupedRiskChanges[0];
      }
    },
    deleteFunction: deleteGroupedRiskChanges,
    resetFunction: resetGridData,
    options: ["details", "delete"],

    detailsTitle: "Risk Change Group Details",
    detailsComponent: <GroupedRiskChangeDetails tableDisplay={true} organizationID={organizationID} />,
    route: "#/risk/changes/",
    helpCenterRoute: "risk-changes",
    getRouteDisabled: (item) => {
      if (!item || item?.totalChanges === 0) {
        return "No Risk Change data available";
      }

      if (item?.totalChanges > 1) {
        return "Grouped Risk Changes cannot be viewed in a separate page";
      }

      return false;
    },
    createResourceComponentWidth: "80vw",

    persistenceUUID: "41ca9ea7-87f7-4eff-91f5-3523b935921e",
    ...gridConfig,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...roleConfig,
    ...cardConfig,
    ...gridConfig,
  });

  //Data retrieval for the grid
  const { resetFunction } = useSetAsyncData({
    getData:
      queryConfig?.query !== null &&
      (async () => {
        // perform query
        let riskChanges = [];
        try {
          riskChanges = await ListQueryBy({
            query: riskChangesByOwnerGroupForGrids,
            variables: {
              ownerGroup: organizationID,
            },
            normalizeData: queryConfig?.normalizeData,
            limit: 100,
          });
        } catch (e) {
          ErrorLogger("Could not retrieve data for Grouped Risk Changes grid", e);
        }

        // group items
        return groupRiskChanges({ riskChanges });
      }),
    setData: grid?.setData,
    resetFunction: externalResetFunction,
    setIsLoading: grid?.setIsLoading,
    isLoading,
  });

  return {
    ...grid,
    setData: (riskChanges) => {
      grid?.setData(groupRiskChanges({ riskChanges }));
    },
  };
};

export const riskChangesByOwnerGroupForGrids = /* GraphQL */ `
  query RiskChangesByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRiskChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riskChangesByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        date
        description
        reason
        changeOwner
        type
        groupID
        change
        userEmail
        source {
          vendorID
          vendorSolutionID
          vendorReviewID
          evidenceID
        }
        systemLinks(limit: 1000) {
          items {
            id
            ownerGroup
            system {
              id
              name
              ownerGroup
            }
          }
        }
      }
      nextToken
    }
  }
`;

const { updateMutation: updateRiskChange } = generateGraphql("RiskChange", [
  "name",
  "date",
  "reason",
  "changeOwner",
  "type",
  "change",
  "groupID",
]);
