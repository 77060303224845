import { useState } from "react";
import { v4 as uuid } from "uuid";

/**
 * @description Generates a UUID to use for a unique div ID.
 * Works with selectors because the first character is always a string, whereas UUID can be a number which causes errors.
 * @returns {[string, React.Dispatch<React.SetStateAction<string>>]}
 * @example const [id, setId] = useGUID(); // id is a string, setId is a function that takes a string as an argument.
 */
export const useGUID = () => {
  return useState(`guid-${uuid()}`);
};
