"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPageBreak = void 0;
const createPageBreak = () => {
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                text: "\f",
            },
        ],
    };
};
exports.createPageBreak = createPageBreak;
