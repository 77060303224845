import { ReactElement } from "react";

import { resources } from "@rivial-security/role-utils";

import TargetDetails from "@views/Testing/Targets/components/TargetDetails";
import VulnerabilityDetails from "@views/Testing/Vulnerabilities/components/VulnerabilityDetails";

export interface FalsePositiveDetailsProps {
  viewingFrom: string;
  item: {
    target: {
      id: string;
    };
    vulnerability: {
      id: string;
    };
  };
}

const FalsePositiveDetails = ({ viewingFrom, item, ...props }: FalsePositiveDetailsProps): ReactElement => {
  if (viewingFrom === resources.VULNERABILITY) {
    return <TargetDetails item={{ id: item?.target.id }} {...props} />;
  } else {
    return <VulnerabilityDetails item={{ id: item?.vulnerability?.id }} {...props} />;
  }
};

export default FalsePositiveDetails;
