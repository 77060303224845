import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";

export const updateRiskControlOverride = (system, riskControl, field, value, updateSystemCallback) => {
  InfoLogger(
    `Updating Control Override. System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Field: ${field}. Value: ${value}`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  InfoLogger("Fetching Risk Control Overrides...");

  InfoLogger("Checking if override exists...");

  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  // Check if Override Object already exists for this Risk Control
  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    InfoLogger(`Override already exists: ${JSON.stringify(override)}`);

    riskControlOverrides[overrideIndex][field] = value;

    //Check that the override field is in enabled fields
    const currentEnabledFields = riskControlOverrides[overrideIndex]?.enabledFields ?? [];
    if (!currentEnabledFields.includes(field)) {
      riskControlOverrides[overrideIndex].enabledFields = [...currentEnabledFields, field];
    }

    const input = {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    };

    updateSystemCallback({
      input,
    }).then(() => {
      InfoLogger("Risk Control Override Updated");
    });
  }

  // Else, add the override to the array
  else {
    ErrorLogger("Override Does Not Exist");
    // Create RiskControlOverride object
    const overrideObject = {
      enabledFields: [field],
      riskControlId: riskControl.id,
      [field]: value,
    };

    InfoLogger(`Created Override Object: ${JSON.stringify(overrideObject)}`);

    riskControlOverrides.push(overrideObject);

    // EventLogger("Added Object, new Risk Control Overrides Array: " + JSON.stringify(riskControlOverrides));

    updateSystemCallback({
      input: {
        id: system.id,
        riskControlOverrides: riskControlOverrides,
      },
    }).then(() => {
      InfoLogger("Risk Control Override Added");
    });
  }
};

export const getRiskControlOverride = (system, riskControl) => {
  const overridesArray = system && system.riskControlOverrides;

  if (overridesArray && overridesArray.length > 0) {
    // If a Specific RiskControl is included in the System.RiskControlOverrides array, find it
    const foundOverride = overridesArray.find((item) => item.riskControlId === riskControl.id);

    // Return the override object, else return undefined
    return foundOverride;
  }
};

export const isNonStandardRiskControl = async (system, riskControl) => {
  if (!system) {
    throw new Error("Invalid System Input");
  }

  if (!riskControl) {
    throw new Error("Invalid riskControl Input");
  }

  const getSystem = /* GraphQL */ `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        riskControlOverrides {
          enabledFields
          riskControlId
          isCompliant
          costOfControl
          controlEffectiveness
          annualRateReduction
          strengthRating
          implementationRating
          outsourced
          implementationDetails
        }
      }
    }
  `;

  const sys = await ItemQuery(getSystem, system.id);

  const overridesArray = sys && sys.riskControlOverrides;

  if (overridesArray && overridesArray.length > 0) {
    // If a Specific RiskControl is included in the System.RiskControlOverrides array, find it
    const foundOverride = overridesArray.find((item) => item.riskControlId === riskControl.id);

    if (foundOverride && foundOverride.enabledFields.length > 0) {
      // Return the override object, else return undefined
      return foundOverride;
    }
  }

  return false;
};

export const enableNonStandardRiskControl = (system, riskControl, updateSystemCallback, initOverrides = {}) => {
  InfoLogger(`Setting a Risk Control as Non Standard..`);
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  InfoLogger("Fetching Risk Control Overrides...");

  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  InfoLogger(`Checking if override exists...`);

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: ["implementationRating", "costOfControl", "implementationDetails", "outsourced"],
      implementationRating: override ? override.implementationRating : 0.0,
      costOfControl: override ? override.costOfControl : 0.0,
      implementationDetails: override ? override.implementationDetails : null,
      outsourced: override ? override.outsourced : false,
      ...initOverrides,
    };

    InfoLogger(`Override Exists: ${JSON.stringify(override)}`);
    riskControlOverrides[overrideIndex] = overrideObject;
  } else {
    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: ["implementationRating", "costOfControl", "implementationDetails", "outsourced"],
      implementationRating: 0.0,
      costOfControl: 0.0,
      implementationDetails: null,
      ...initOverrides,
    };

    riskControlOverrides.push(overrideObject);
  }

  if (updateSystemCallback) {
    updateSystemCallback({
      input: {
        id: system.id,
        riskControlOverrides: riskControlOverrides,
      },
    }).then(() => {
      InfoLogger(`Risk Control Overrides for Non Standard Control Enabled`);
    });
  }
  return riskControlOverrides;
};

export const disableNonStandardRiskControl = (system, riskControl, updateSystemCallback) => {
  InfoLogger(`Setting a Risk Control as Standard..`);
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  InfoLogger("Fetching Risk Control Overrides...");

  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  InfoLogger(`Checking if override exists...`);

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: [],
      implementationRating: override ? override.implementationRating : 0.0,
      costOfControl: override ? override.costOfControl : 0.0,
      implementationDetails: override ? override.implementationDetails : null,
    };

    InfoLogger(`Override Exists: ${JSON.stringify(override)}`);
    riskControlOverrides[overrideIndex] = overrideObject;
  } else {
    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: [],
      implementationRating: 0.0,
      costOfControl: 0.0,
      implementationDetails: null,
    };
    riskControlOverrides.push(overrideObject);
  }

  updateSystemCallback({
    input: {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    },
  }).then(() => {
    InfoLogger(`Risk Control Overrides for Non Standard Control Disabled`);
  });
};

// Risks

export const updateRiskOverride = (system, risk, field, value, updateSystemCallback) => {
  InfoLogger(
    `Updating Override. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}. Value: ${value}`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  InfoLogger("Fetching Risk Overrides..");
  InfoLogger("Checking if override exists...");

  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];

    InfoLogger(`Override already exists`, override);

    riskOverrides[overrideIndex][field] = value;

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      InfoLogger("Risk Override Updated");
    });
  }

  // Else, add the override to the array
  else {
    InfoLogger("Override Does Not Exist");
    // Create RiskControlOverride object
    const overrideObject = {
      enabledFields: [field],
      riskId: risk.id,
      [field]: value,
    };

    riskOverrides.push(overrideObject);

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      InfoLogger("Risk Override Added");
    });
  }
};

export const enableRiskOverride = (system, risk, field, updateSystemCallback) => {
  InfoLogger(
    `Enabling Override for field ${field}. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}.`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  InfoLogger(`Fetching Risk Control Overrides: ${JSON.stringify(riskOverrides)}`);
  InfoLogger(`Checking if override for ${field} exists...`);

  // Check if this override already exists
  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];
    InfoLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    if (indexOfField === -1) {
      riskOverrides[overrideIndex].enabledFields.push(field);

      updateSystemCallback({
        input: {
          id: system.id,
          riskOverrides: riskOverrides,
        },
      }).then(({ data }) => {
        InfoLogger(`Risk Override for ${field} Enabled`);
      });
    } else {
      InfoLogger("Override already enabled!");
    }
  } else {
    ErrorLogger(`Override for ${field} does not exist. No override to enable.`);
  }
};

export const disableRiskOverride = (system, risk, field, updateSystemCallback) => {
  InfoLogger(
    `Disabling Override for ${field}. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}.`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  InfoLogger(`Fetching Risk Overrides: ${JSON.stringify(riskOverrides)}`);
  InfoLogger(`Checking if override for ${field} exists...`);

  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];

    InfoLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    const enabledFields = riskOverrides[overrideIndex].enabledFields;

    enabledFields.splice(indexOfField, 1);

    InfoLogger(`Risk Overrides with field disabled: ${JSON.stringify(enabledFields)}`);

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      InfoLogger(`Risk Override for ${field} Disabled`);
    });
  } else {
    InfoLogger(`Override for ${field} does not exist. No override to disable.`);
  }
};
