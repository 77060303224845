import React from "react";

/**
 * @description Display header for CSV file upload
 * @param {object[]} headerObjects
 * @param {object[]} headersProps
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayHeaders = ({ headerObjects = [], headersProps = [] }) => {
  const displayHeaders = (data, field) => {
    return (
      <div>
        {data &&
          Array.isArray(data) &&
          data.map((item, index, arr) => {
            if (arr.length - 1 === index) {
              return (
                <span>
                  {arr.length !== 1 && " and "}
                  <span style={{ fontWeight: "bold" }}>{item[field] ? item[field] : item}</span>
                </span>
              );
            } else {
              return (
                <span>
                  <span style={{ fontWeight: "bold" }}>{item[field] ? item[field] : item}</span>
                  {arr.length - 2 !== index && ", "}
                </span>
              );
            }
          })}
      </div>
    );
  };

  return (
    <div>
      <div>Header(s):</div>
      {(Array.isArray(headersProps) || Array.isArray(headerObjects)) &&
        displayHeaders(headersProps.length > 0 ? headersProps : headerObjects, "title")}
    </div>
  );
};

export default DisplayHeaders;
