import { listEvidenceNonStandardRiskControlLinks } from "./listEvidenceNonStandardRiskControlLinks";

/**
 * Finds the risk control - evidence - system link from the evidence side (if it exists)
 * @param evidence - the evidence that the link is on
 * @param riskControlId - the risk control id that the link needs to have
 * @param systemId - the system id that the link needs to have
 * @return {undefined|{}}
 */
export const getEvidenceNonStandardRiskControlLink = ({ evidence, riskControlId, systemId } = {}) => {
  //Check arguments return empty array if not provided
  if (!evidence?.id || !riskControlId || !systemId) {
    return undefined;
  }

  //From evidence get all non-standard risk control links
  const nonStandardEvidenceRiskControlLinks = listEvidenceNonStandardRiskControlLinks({ evidence });

  //Find the specific link for the current risk control
  return nonStandardEvidenceRiskControlLinks.find((nonStandardEvidenceRiskControlLink) => {
    const riskIdMatches =
      nonStandardEvidenceRiskControlLink?.riskControlId === riskControlId ||
      nonStandardEvidenceRiskControlLink?.control?.id === riskControlId ||
      nonStandardEvidenceRiskControlLink?.riskControl?.id === riskControlId;

    const systemIdMatches =
      nonStandardEvidenceRiskControlLink?.systemId === systemId ||
      nonStandardEvidenceRiskControlLink?.system?.id === systemId;

    return riskIdMatches && systemIdMatches;
  });
};
