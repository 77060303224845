import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import CustomResourceTypeDataGrid from "../../../CustomResources/CustomResourceTypes/components/CustomResourceTypeDataGrid";
import CustomResourceTypeForm from "../../../CustomResources/CustomResourceTypes/components/CustomResourceTypeForm";
import { useCustomResourceTypeDataGrid } from "../../../CustomResources/CustomResourceTypes/hooks/useCustomResourceTypeDataGrid";

/**
 * Displays Custom Resource Types that are configured for a Tool
 * @param item
 * @param organizationID
 * @param isTemplate
 * @param callback
 * @returns {JSX.Element}
 * @constructor
 */
export const useToolCustomResourceTypes = ({ item, organizationID, isTemplate, callback }) => {
  const dataGrid = useNestedDataCard({
    title: "Custom Resource Types",
    module: modules.TOOLS,
    resource: resources.TOOL,
    item,
    field: "customResourceTypes",
    connectionIDField: "toolID",
    typename: "CustomResourceType",
    parentTypename: "Tool",
    grid: <CustomResourceTypeDataGrid organizationID={organizationID} isTemplate={isTemplate} />,
    gridHook: useCustomResourceTypeDataGrid,
    form: <CustomResourceTypeForm toolID={item?.id} organizationID={organizationID} isTemplate={isTemplate} />,
    fields: ["name", "description", "customResourceTypes"],
    nestedFields: {
      customResourceTypes: `(limit: 1000) { items { id name description customFields { name type } } }`,
    },
    organizationID,
    callback,
    isTemplate,
  });

  return dataGrid;
};
