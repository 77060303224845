"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeSystemStats = void 0;
const calculateROI_1 = require("./calculateROI");
const createLossCurve_1 = require("./createLossCurve");
/**
 * Gets the aggregate information for a System from all risk data
 * @param riskEvaluations
 * @param system
 */
const computeSystemStats = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
riskEvaluations, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
system, lossCurveXPoints) => {
    let inherentLossTotal = 0;
    let residualLossTotal = 0;
    let recommendationResidualLossTotal = 0;
    let totalCostOfCurrentControls = 0;
    const simulatedInherentLosses = [];
    const simulatedResidualLosses = [];
    for (const risk of riskEvaluations) {
        if (risk.inherentRisk && !isNaN(risk.inherentRisk)) {
            inherentLossTotal += risk.inherentRisk;
        }
        if (risk.residualRisk && !isNaN(risk.residualRisk)) {
            residualLossTotal += risk.residualRisk;
        }
        if (risk.recommendationResidualLoss !== null &&
            risk.recommendationResidualLoss !== undefined &&
            !isNaN(risk.recommendationResidualLoss)) {
            recommendationResidualLossTotal += risk.recommendationResidualLoss;
        }
        else if (risk.residualRisk && !isNaN(risk.residualRisk)) {
            recommendationResidualLossTotal += risk.residualRisk;
        }
        totalCostOfCurrentControls += risk.currentCostOfControls;
        for (let sim = 0; sim < risk.riskSimulatedInherentLosses.length; sim++) {
            if (simulatedInherentLosses[sim])
                simulatedInherentLosses[sim] += risk.riskSimulatedInherentLosses[sim];
            else
                simulatedInherentLosses[sim] = risk.riskSimulatedInherentLosses[sim];
            if (simulatedResidualLosses[sim])
                simulatedResidualLosses[sim] += risk.riskSimulatedResidualLosses[sim];
            else
                simulatedResidualLosses[sim] = risk.riskSimulatedResidualLosses[sim];
        }
    }
    return {
        name: system.name,
        ownerGroup: system.ownerGroup,
        // NOTE: These are the TOTALS across all KRIs and should not currently be used as the system's inherent and residual risk
        inherentRisk: inherentLossTotal,
        residualRisk: residualLossTotal,
        recommendationResidualLoss: recommendationResidualLossTotal,
        changeInInherentLoss: null, // if a riskChange is passed in
        currentCostOfControls: totalCostOfCurrentControls,
        currentROI: (0, calculateROI_1.calculateROI)(inherentLossTotal, residualLossTotal, totalCostOfCurrentControls),
        lossExceedanceCurve: {
            // @ts-expect-error Fix me, needs better typing
            inherentLossCurve: (0, createLossCurve_1.createLossCurve)(simulatedInherentLosses, lossCurveXPoints),
            // @ts-expect-error Fix me, needs better typing
            residualLossCurve: (0, createLossCurve_1.createLossCurve)(simulatedResidualLosses, lossCurveXPoints),
        },
    };
};
exports.computeSystemStats = computeSystemStats;
