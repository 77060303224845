import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useAssessmentDataGrid } from "../hooks/useAssessmentDataGrid";

/**
 * Component allowing to choose a single assessment
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectAssessment = ({ organizationID, selectButtonCallback } = {}) => {
  const grid = useAssessmentDataGrid({
    organizationID,
    createItemComponent: null,
    enableSelectButton: true,
    onSelectCallback: selectButtonCallback,
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(SelectAssessment);
