import { Icon } from "@iconify/react";
import { Box, Chip, FormControl, InputLabel, ListItemIcon, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useStateEffect } from "../../../hooks/functional/useStateEffect";
import { getCurrentTypeFields } from "../functions/getCurrentTypeFields";
import { getFieldIcon } from "../functions/getFieldIcon";
import { getFieldsAtDepth } from "../functions/getFieldsAtDepth";

/**
 * Allows user to select a subset of fields for a particular resource
 * @param {object} dropdownContainerStyle - css inline style for the dropdown container
 * @param {string} typename - the root typename for top level fields
 * @param {object[]} path - schema resource objects in the order that the user has selected (the last object is the current resource)
 * @param {object} resultFields - all the available fields at the top level
 * @param {object} selectedFields - the fields which were already selected by the user
 * @param {function} onSelectFields - callback when a field  was added ti the selected fields using the dropdown
 * @param {function} onClickNestedField - callback when one of the selected fields was clicked to dig deeper into the schema
 * @return {JSX.Element}
 */
export const ResourceFieldMultiSelect = ({
  dropdownContainerStyle,
  typename,
  path,
  resultFields,
  selectedFields,
  onSelectFields,
  onClickNestedField,
}) => {
  const [selectedFieldNames, setSelectedFieldNames] = useState([]);
  useEffect(() => {
    const newSelectedFieldNames = [];
    for (const field of selectedFields) {
      const fieldName = field?.name;
      if (fieldName) {
        newSelectedFieldNames.push(fieldName);
      }
    }
    setSelectedFieldNames(newSelectedFieldNames);
  }, [typename, path]);

  // All Available fields for the selected Type
  const [allFields] = useStateEffect([], [typename], () => {
    return getCurrentTypeFields({ typename });
  });

  /**
   * Handle selection of a new field from the result fields
   * @param {object} event - multi select dropdown event
   */
  const handleChange = (event) => {
    const newSelectedFieldNames = event?.target?.value || [];
    setSelectedFieldNames(newSelectedFieldNames);
    if (typeof onSelectFields === "function") {
      const fields = [];
      for (const selectedFieldName of newSelectedFieldNames) {
        const field = allFields.find((field) => field?.name === selectedFieldName);
        if (field) {
          fields.push(field);
        }
      }
      onSelectFields(fields);
    }
  };

  /**
   * Check if field has any nested fields which the user can click dig deeper to select
   * @param {string} fieldName - the name of the field to check at the current level of nesting
   * @return {boolean} - TRUE if the field has nested fields, FALSE otherwise
   */
  const isNestedField = (fieldName) => {
    return allFields?.find((field) => field?.name === fieldName)?.isNested || false;
  };

  /**
   * UI method determining what color the fields chip needs to be in the multiselect component
   * @param {string} fieldName - the name of the field that the chip is for
   * @return {string|undefined}
   */
  const getFieldChipColor = (fieldName) => {
    if (isNestedField(fieldName)) {
      const fieldsAtDepth = getFieldsAtDepth({
        path,
        resultFields,
        depth: path.length,
      });
      const field = fieldsAtDepth.find((field) => field?.name === fieldName);
      if (typeof field?.fields === "object" && Object.keys(field?.fields).length > 0) {
        return "success";
      } else {
        return "primary";
      }
    } else {
      return undefined;
    }
  };

  const fieldsAvailable = Array.isArray(allFields) && allFields.length > 0;
  return (
    <div style={!fieldsAvailable ? { height: 0, margin: 0, padding: 0 } : dropdownContainerStyle}>
      {fieldsAvailable && (
        <FormControl fullWidth sx={{ minWidth: 300 }}>
          <InputLabel id="resource-field-select-label">Fields</InputLabel>
          <Select
            id="resource-field-select"
            multiple
            value={selectedFieldNames || []}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Fields" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((fieldName) => (
                  <Chip
                    key={fieldName}
                    label={fieldName}
                    color={getFieldChipColor(fieldName)}
                    style={{
                      cursor: isNestedField(fieldName) ? "pointer" : "default",
                    }}
                    onMouseDown={(e) => {
                      const field = allFields?.find((field) => field?.name === fieldName);
                      const isNested = isNestedField(fieldName);
                      if (isNested && typeof onClickNestedField === "function") {
                        onClickNestedField(field);
                        e.stopPropagation();
                      }
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {Array.isArray(allFields) &&
              allFields.map((field, index) => {
                const name = field?.name || `field${index}`;
                return (
                  <MenuItem key={name} value={name}>
                    <ListItemIcon>
                      <Icon icon={getFieldIcon({ field })} />
                    </ListItemIcon>
                    {name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
