/**
 * Helper function to group evidence activities that occurred at the same time, NOTE uses groupID
 * @param {object[]} activities - list of all activities shown
 * @param {boolean} isGrouped - if TRUE will group activities if false will return original array
 * @return {*[]|*}
 */
export const groupEvidenceActivity = ({ activities, isGrouped = false } = {}) => {
  if (isGrouped === true) {
    const activityGroupIndexes = {};
    const newActivities = [];
    for (const activity of activities) {
      if (activity?.groupID) {
        if (activityGroupIndexes.hasOwnProperty(activity?.groupID)) {
          const groupIndex = activityGroupIndexes[activity?.groupID];
          if (Array.isArray(newActivities[groupIndex]?.activities)) {
            newActivities[groupIndex].activities.push(activity);
          } else {
            const oldActivity = newActivities[groupIndex];
            const type = oldActivity?.type;
            const date = oldActivity?.date;
            const evidenceID = oldActivity?.evidenceID;
            const evidenceName = oldActivity?.evidenceName;
            newActivities[groupIndex] = {
              type,
              date,
              evidenceID,
              evidenceName,
              groupID: activity?.groupID,
              activities: [oldActivity, activity],
            };
          }
        } else {
          activityGroupIndexes[activity?.groupID] = newActivities.length;
          newActivities.push(activity);
        }
      } else {
        newActivities.push(activity);
      }
    }
    return newActivities;
  } else {
    return activities;
  }
};
