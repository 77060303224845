import { ErrorLogger } from "@utils/EventLogger";

/**
 * Modifies the outermost node's fields of the resultField tree, using the path to get to the end, modification occurs in place
 * @param {object[]} path - the path of resources to use (by resource name) to find the leaf node inside newResultFields
 * @param {object} newResultFields - the current result fields representing the query setup
 * @param {object} newLeafFields -  the leaf fields to modify at the end of path
 */
export const addLeafFieldsToResultFields = ({ path, newResultFields, newLeafFields }) => {
  if (typeof newResultFields !== "object") {
    ErrorLogger("Something is wrong when updating selected fields!");
    return;
  }

  //Drill down to the leaf of the path to add selected fields
  if (Array.isArray(path) && path.length > 0) {
    const currentFieldName = path[0].name;
    if (!newResultFields.hasOwnProperty(currentFieldName)) {
      newResultFields[currentFieldName] = { ...path[0] };
    }
    const currentField = newResultFields[currentFieldName];

    if (typeof currentField?.fields !== "object") {
      currentField.fields = {};
    }

    addLeafFieldsToResultFields({
      path: path.slice(1),
      newResultFields: currentField.fields,
      newLeafFields,
    });
  } else {
    const leafFieldNames = [];
    for (const leafField of newLeafFields) {
      const leafFieldName = leafField.name;

      if (leafFieldName) {
        leafFieldNames.push(leafFieldName);
      }

      // if field already exist, will reuse its ata in the new list in case there are special settings
      if (!newResultFields.hasOwnProperty(leafFieldName)) {
        newResultFields[leafFieldName] = leafField;
      }
    }

    //remove any fields not present in the new list of leaf fields
    for (const resultFieldName in newResultFields) {
      if (typeof resultFieldName !== "string" || !leafFieldNames.includes(resultFieldName)) {
        delete newResultFields[resultFieldName];
      }
    }
  }
};
