"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateListByQueryGraphql = void 0;
const generateListByQueryGraphql = ({ typename, nestedFields, index, }) => {
    const { indexName = "", queryField = "", partitionKey = "ownerGroup", partitionKeyType = "String", sortKey = "", sortKeyType = "String", } = index;
    const queryName = queryField || indexName;
    const queryWithFirstLetterCapitalized = queryName.charAt(0).toUpperCase() + queryName.slice(1);
    return `
    query ${queryWithFirstLetterCapitalized}(
      $${partitionKey}: ${partitionKeyType}
      ${sortKey ? `$${sortKey}: ${sortKeyType}` : ""}
      $sortDirection: ModelSortDirection
      $filter: Model${typename}FilterInput
      $limit: Int
      $nextToken: String
    ) {
      ${queryName}(
        ${partitionKey}: $${partitionKey}
        ${sortKey ? `${sortKey}: $${sortKey}` : ""}
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        ${nestedFields}
      }
    }
  `;
};
exports.generateListByQueryGraphql = generateListByQueryGraphql;
