import React from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";
import GenericEditField from "../../../../utils/GenericComponents/GenericEditFieldV2";
import { getSummary } from "../hooks/useSummary";

/**
 * The Summary field for a Vendor Review
 * @param item
 * @param resetFunction
 * @param {function} [getSummaryText] - optional function for generating summary text
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param field
 * @returns {JSX.Element}
 * @constructor
 */
const Summary = ({
  item,
  resetFunction,
  getSummaryText,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  field = "summary",
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const { updateMutation } = generateGraphql("VendorReview", ["summary"]);

  const generateSummaryText = async () => {
    let summary;

    if (!isNullOrUndefined(getSummaryText)) {
      summary = await getSummaryText(item);
    } else {
      summary = await getSummary(item);
    }

    // null check and error handling
    ItemMutation(updateMutation, { id: item.id, summary }).then(() => resetFunction && resetFunction());
  };

  return (
    <>
      <h6>
        Summary
        <CustomTooltip targetId="summary-tooltip" tooltip={"Summarizes this Vendor Review."} />
        <Button
          disabled={!checkPermissions.resource.update}
          onClick={() => generateSummaryText()}
          color="link"
          size="sm"
          title="Auto-Generate Summary Text (this will overwrite the current summary)"
        >
          Generate
        </Button>
      </h6>
      <GenericEditField
        item={item}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        field={field}
        mutation={updateMutation}
        inputType={"textarea"}
      />
    </>
  );
};

export default Summary;
