import { resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useManyToOneLinking } from "../../../../hooks/views/useManyToOneLinking/useManyToOneLinking";
import { useCreateSystem } from "../../../Risk/Systems/hooks/useCreateSystem";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";

/**
 * GUI to link vendor solutions to systems
 * @param {string} organizationID - the database identifier of the currently selected organization
 * @param {object} item - the vendor solution data with all of its linked items
 * @param {function} [toggleModal] - callback to close the containing modal
 * @param {function} [resetFunction] - callback to re-query the 'item' info after an update
 * @return {{display: JSX.Element}}
 */
export const useVendorSolutionSystemLinking = ({ organizationID, item, toggleModal, resetFunction }) => {
  const systemsGrid = useSystemDataGrid({ organizationID });
  const { addToast, updateToast } = useUIContext();

  const linking = useManyToOneLinking({
    organizationID,
    manyItem: item,
    existingItems: systemsGrid?.data ?? [],
    createHook: useCreateSystem,

    oneTypename: resources.INFORMATION_SYSTEM,
    oneField: "vendorSolution",
    oneIdField: "vendorSolutionID",
    manyTypename: resources.VENDOR_SOLUTION,
    manyField: "systems",

    toggleModal,
    resetFunction: () => {
      resetFunction && resetFunction();

      // TODO: can uncomment when users will be able to select which controls to outsource after linking systems
      // performToastOperation({
      //   addToast,
      //   updateToast,
      //   operation: async () => {
      //     invokeGraphqlFunction("Query", "runVendorReviewFinalizationActions", {
      //       vendorSolutionID: item?.id,
      //       actionsToPerform: [
      //         vendorReviewFinalizationActionTypes.RISK_CHANGES,
      //       ],
      //       riskChangeReason: `Vendor Solution "${item?.name || "Untitled Solution"}" from the Vendor "${item?.vendor?.name || "Untitled Vendor"}" has been linked to the system.`,
      //     });
      //   },
      //   inProgressText: `Syncing outsourced controls...`,
      //   successText: `Started checking for risk changes. Please wait a few minutes for resources to update.`,
      //   failedText: `Failed to begin creating risk changes!`,
      //   iconColor: "success",
      // });
    },
    listTitleAdapter: (item) => {
      return item?.name ?? `Information System - ${item?.id}`;
    },
  });

  return {
    ...linking,
  };
};
