"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSecret = void 0;
const client_secrets_manager_1 = require("@aws-sdk/client-secrets-manager");
/**
 * @description Deletes a secret and all of its versions. You can specify a recovery window during which you can restore the secret. The minimum recovery window is 7 days. The default recovery window is 30 days.
 * @param {string} secretId - the name of the secret to delete.
 * @param {string} arn - the ARN of the secret to delete.
 * @param {number} recoveryWindowInDays - the number of days from 7 to 30 that Secrets Manager waits before permanently deleting the secret.
 * @returns {Promise<SecretsManager.DeleteSecretResponse & {$response: Response<SecretsManager.DeleteSecretResponse, Error & {code: string, message: string, retryable?: boolean, statusCode?: number, time: Date, hostname?: string, region?: string, retryDelay?: number, requestId?: string, extendedRequestId?: string, cfId?: string, originalError?: Error}>}>}
 */
const deleteSecret = async ({ secretId, arn, recoveryWindowInDays = 30 }) => {
    if (!secretId && !arn)
        throw Error("Missing secretId and arn");
    const secretsManager = new client_secrets_manager_1.SecretsManagerClient({ apiVersion: "2017-10-17" });
    const params = {
        RecoveryWindowInDays: recoveryWindowInDays,
        SecretId: secretId || arn,
    };
    const command = new client_secrets_manager_1.DeleteSecretCommand(params);
    return await secretsManager.send(command).catch((err) => {
        throw Error(`Could not delete secret: ${err}`);
    });
};
exports.deleteSecret = deleteSecret;
