import React, { useState } from "react";
import { Collapse, ListGroupItem, ListGroupItemText } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import AccordionIcon from "../../../../../../../utils/GenericComponents/AccordionIcon";
import KPIOverlay from "../../../../../../Metrics/KeyPerformanceIndicators/components/KPIOverlay";

import ControlCategoryNotes from "./ControlCategoryNotes";
import ControlCategoryThreats from "./ControlCategoryThreats";
import SubControls from "./Controls/components/SubControls";
import RemoveSystemControlCategoryLinkButton from "./RemoveSystemControlCategoryLinkButton";
import ReturnOnInvestmentControlCategory from "./ReturnOnInvestment";
import TotalCostOfControls from "./TotalCostOfControls";

const ControlCategoryItem = (props) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const { item, system, resetFunction, viewType, disableRoleChecking } = props;

  const subControls = item && item.subControls && item.subControls.items ? item.subControls.items : [];

  const [isOpen, setIsOpen] = useState(false);

  const kpiPermissionsHook = useCheckPermissions({
    module,
    resource,
    field: "keyPerformanceIndicators",
  });

  return (
    <>
      <ListGroupItem className={isOpen ? "shadow" : undefined} style={props.style}>
        <div
          onClick={() => setIsOpen(!isOpen)}
          style={{
            cursor: "pointer",
          }}
        >
          <h8
            onClick={() => setIsOpen(!isOpen)}
            style={{
              cursor: "pointer",
              marginBottom: "1em",
            }}
          >
            {item.name}
          </h8>
          {!props.disableDelete && <RemoveSystemControlCategoryLinkButton item={{ id: item.linkId }} />}
          <AccordionIcon isOpen={isOpen} />
        </div>
        <Collapse isOpen={isOpen}>
          <hr />
          <ListGroupItemText style={{ marginTop: "1em" }}>
            {isOpen && (
              <KPIOverlay
                allowRead={!props.isTemplate && kpiPermissionsHook.resource.read}
                allowUpdate={!props.isTemplate && kpiPermissionsHook.resource.update}
                btnClassName="float-right"
                riskControlCategory={item}
                component={
                  <>
                    {viewType === "analysis" ? (
                      <>
                        <TotalCostOfControls system={system} controlCategory={item} style={{ marginTop: "1em" }} />
                        {props.Context ? (
                          <props.Context>
                            <ReturnOnInvestmentControlCategory
                              system={system}
                              controlCategory={item}
                              style={{ marginTop: "1em" }}
                            />
                          </props.Context>
                        ) : (
                          <ReturnOnInvestmentControlCategory
                            system={system}
                            controlCategory={item}
                            style={{ marginTop: "1em" }}
                          />
                        )}
                        <SubControls
                          disableRoleChecking={disableRoleChecking}
                          Context={props.Context}
                          viewType={viewType}
                          organizationID={props.organizationID}
                          subControls={subControls}
                          system={system}
                          resetFunction={resetFunction}
                          style={{ marginTop: "1em" }}
                          isTemplate={props.isTemplate}
                          vendorReview={props.vendorReview}
                        />
                        <ControlCategoryNotes item={{ id: item?.linkId }} system={system} />
                      </>
                    ) : (
                      <>
                        <ControlCategoryNotes
                          disableRoleChecking={disableRoleChecking}
                          item={{ id: item.linkId }}
                          module={module}
                          resource="riskControlCategories"
                          tooltip="These are Notes specific to this Control Category in the context of this Information System"
                          system={system}
                        />
                        <SubControls
                          disableRoleChecking={disableRoleChecking}
                          Context={props.Context}
                          viewType={viewType}
                          organizationID={props.organizationID}
                          subControls={subControls}
                          system={system}
                          resetFunction={resetFunction}
                          style={{ marginTop: "1em" }}
                          isTemplate={props.isTemplate}
                          vendorReview={props.vendorReview}
                        />
                      </>
                    )}
                    <ControlCategoryThreats
                      organizationID={props.organizationID}
                      controlCategory={item}
                      system={system}
                      resetFunction={props.resetFunction}
                      disableRoleChecking={props.disableRoleChecking}
                      viewType={props.viewType}
                    />
                  </>
                }
              />
            )}
          </ListGroupItemText>
        </Collapse>
      </ListGroupItem>
    </>
  );
};

export default withOrganizationCheck(ControlCategoryItem);
