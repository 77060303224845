import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateMetricType } from "../hooks/useCreateMetricType";

/**
 * A component for editing an "automation" on a metric type.
 * This just uses the existing useCreateMetricType and overrides some functionality to perform updates
 * @param {string} organizationID
 * @param {object} item
 * @param {function} toggleModal
 * @param {function} resetFunction
 * @returns {*}
 */
const EditAutomation = ({ organizationID, item, toggleModal, resetFunction }) => {
  // can use the createMetricType hook for update purposes as well.
  const metricTypeHook = useCreateMetricType({
    organizationID,
    item,
    formConfig: {
      header: "Edit Automation configuration",
      fields: ["enabled", "calculationType", "frequency", "automation", "customAutomation"],
      mutation: generateGraphql("MetricType").updateMutation,
    },
    toggleModal,
    resetFunction,
  });

  return <div>{metricTypeHook.display}</div>;
};

export default withOrganizationCheck(EditAutomation);
