import { useEffect, useRef } from "react";

/**
 * Hook which exposes the cursor position in the browser window, useful for positioning popups
 * REFERENCE: https://www.joshwcomeau.com/snippets/react-hooks/use-mouse-position/
 * NOTE: could be expensive for performance, using reference to avoid re-rendering
 * @returns {{x: number|null, y: number|null}
 */
const useMousePosition = () => {
  const mousePositionRef = useRef({ x: 0, y: 0 });
  useEffect(() => {
    const updateMousePosition = (ev) => {
      mousePositionRef.current = { x: ev.clientX, y: ev.clientY };
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);
  return mousePositionRef;
};

export default useMousePosition;
