import React from "react";

import RiskRecommendationDetails from "../components/RiskRecommendationDetails";

/**
 * Custom field component for the RecommendationItem.data field
 * @param data
 * @param item
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const Data = ({ data, item, resetFunction }) => {
  return (
    <div>
      {(() => {
        if (data && JSON.parse(data)?.riskControlID) {
          return (
            <div>
              <h6>Risk Control Recommendation</h6>
              <RiskRecommendationDetails data={data} item={item} resetFunction={resetFunction} />
              <hr />
            </div>
          );
        }
      })()}
    </div>
  );
};

export default Data;
