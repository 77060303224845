"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateItemById = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Given a state update function finds the item in the array state and updates it using the provided setState function
 * @param {function} setState - state update function for array hosting the updated item
 * @param {object} item - new item data (must include the id property)
 */
const updateItemById = (setState, item) => {
    setState((data) => {
        if (data && Array.isArray(data) && item?.id) {
            const foundIndex = data.findIndex((x) => x?.id === item.id);
            if (!(0, isNullOrUndefined_1.isNullOrUndefined)(foundIndex) && foundIndex !== -1) {
                const newData = [...data];
                newData[foundIndex] = {
                    ...data[foundIndex],
                    ...item,
                };
                return newData;
            }
        }
        return data;
    });
};
exports.updateItemById = updateItemById;
