import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/21/19 JB: Added header signature
 *  - 8/7/19 JB: Added custom graphql
 *
 * Description: An Async Function.
 *              Creates a new ControlEvidenceLink item in the Database.
 *
 */
export const createControlEvidenceLink = async (controlID, evidenceID, organizationID) => {
  return ItemMutation(createMutation, {
    controlID,
    evidenceID,
    ownerGroup: organizationID,
  });
};

const { createMutation } = generateGraphql("ControlEvidenceLink");
