"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formattedMemorySize = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si -True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param dp - Number of decimal places to display.
 * @returns A human-readable version of the total bytes amount.
 * REFERENCE: https://stackoverflow.com/a/14919494/7393636
 */
const formattedMemorySize = ({ bytes = 0, si = false, dp = 1 }) => {
    if ((0, isNullOrUndefined_1.isNullOrUndefined)(bytes) || !isFinite(bytes) || Array.isArray(bytes))
        return "0 B";
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return `${bytes.toFixed(dp)} ${units[u]}`;
};
exports.formattedMemorySize = formattedMemorySize;
