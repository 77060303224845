export const listControlSets_simple = `query ListControlSets(
  $filter: ModelControlSetFilterInput
  $limit: Int
  $nextToken: String
) {
  listControlSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      ownerGroup
    }
    nextToken
  }
}
`;

export const listControlsByControlSetExportSpreadsheet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        statementNumber
        inPlace
        isDisabled
        customFieldData
        ownerGroup
        controlControlSetId
        notes {
          id
          type
          ownerGroup
          author
          timeStamp
          content
          tag
          observationID
        }
      }
      nextToken
    }
  }
`;
