import { cloneDeep } from "lodash";
import { useEffect } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useResponseTeamDataGrid } from "../../ResponseTeams/hooks/useResponseTeamDataGrid";

/**
 * @description Display linked Response Teams for an Exercise
 * @param {object} item - exercise
 * @param {string} organizationID - selected organization
 * @param {function} - function to refresh the exercise item
 * @returns {JSX.Element}
 * @constructor
 */
const ExerciseResponseTeams = ({ item, organizationID, resetFunction }) => {
  const grid = useResponseTeamDataGrid({
    organizationID,
    queryConfig: {
      query: null,
    },
    gridConfig: {
      createResourceComponent: undefined,
      gridHeight: "100%",
      allowPaging: false,
    },
  });

  const populateRows = () => {
    const tempResponseTeams = [];
    const responseTeams = item?.responseTeams?.items;

    if (responseTeams) {
      for (const link of responseTeams) {
        if (link?.responseTeam) {
          const responseTeam = cloneDeep(link?.responseTeam);
          if (responseTeam) {
            tempResponseTeams.push({
              ...responseTeam,
            });
          }
        }
      }
      grid.setData(tempResponseTeams);
    }
  };

  /**
   * TODO: Below is a workaround to populate the grid after it has been created fully it would be optimal to find the bug
   * in useCardGrid that removes the manually added items when they are added without a timeout.
   */
  useEffect(() => {
    setTimeout(() => {
      populateRows();
    }, 1000);
  }, [item]);

  return grid.gridDisplay;
};

export default withOrganizationCheck(ExerciseResponseTeams);
