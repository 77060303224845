import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a new Assessment resource in the DB.
 * @param {string} name - The name of the Assessment
 * @param {string} ownerGroup - organizationID of the owner group
 * @param {string} assessmentDate - The date the Assessment was performed
 * @param {string[]} includedListTargets - The list of targets to include in the Assessment
 * @param {string[]} excludedListTargets - The list of targets to exclude from the Assessment
 * @param {string} type - The type of the Assessment
 * @param {string} status - The status of the Assessment
 * @param {object} pointOfContact - pointOfContact object
 * @param {string} penTester - The name of the Pen Tester
 * @param {string} penTesterEmail - The email of the Pen Tester
 * @param {object} securityAppliance - securityAppliance object
 * @param {string} scanner - The name of the Scanner
 * @returns {Promise<void>}
 * @constructor
 */

export const createAssessment = async ({
  name,
  ownerGroup,
  assessmentDate,
  includedListTargets,
  excludedListTargets,
  type,
  status,
  pointOfContact,
  penTester,
  penTesterEmail,
  securityAppliance,
  scanner,
}) => {
  const { createMutation } = generateGraphql("Assessment", [
    "name",
    "assessmentDate",
    "includedListTargets",
    "excludedListTargets",
    "penTester",
    "penTesterEmail",
    "type",
    "status",
    "scanner",
  ]);

  return await ItemMutation(createMutation, {
    name: name,
    assessmentDate,
    includedListTargets,
    excludedListTargets,
    assessmentPointOfContactId: pointOfContact?.id ? pointOfContact.id : undefined,
    penTester,
    penTesterEmail,
    type: type,
    status: status,
    assessmentSecurityApplianceId: securityAppliance?.id ? securityAppliance.id : undefined,
    scanner,
    ownerGroup: ownerGroup,
  });
};
