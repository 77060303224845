import { useEffect, useState } from "react";

/**
 * @description Hook used to get a mapping of values that were not modified in the last x amount of milliseconds
 * Reference (similar hook): https://usehooks.com/useDebounce/
 * @param {object[]} values - all values that need to be tracked, unique id property required in each
 * @param {string} nestedField - a specific property in an object item of the values argument
 * @param {number} delay - the number of milliseconds to wait until declaring that an item has been debounced
 */
export const useDebouncedItems = ({ values, nestedField, delay }) => {
  const [debouncedValues, setDebouncedValues] = useState({});
  const [startedTimers, setStartedTimers] = useState({});

  const setDebouncedValue = ({ id, value }) => {
    setDebouncedValues((debouncedValues) => {
      return { ...debouncedValues, [id]: value };
    });
  };

  //Handles the deletion and creation of timeouts when the values array has changed
  useEffect(() => {
    const debouncedValuesToRemove = [];
    const timersToRemove = [];
    const newTimers = {};

    //Go through each item checking if the timer for that item needs to be started/restarted
    //NOTE: the tracked item must be an object with an id field
    if (values && Array.isArray(values)) {
      //Check values to delete (no longer present in the values array)
      for (const itemID in debouncedValues) {
        const foundIndex = values.findIndex((value) => value?.id === itemID);
        if (foundIndex === -1) {
          debouncedValuesToRemove.push(itemID);
        }
      }
      for (const itemID in startedTimers) {
        const foundIndex = values.findIndex((value) => value?.id === itemID);
        if (foundIndex === -1) {
          timersToRemove.push(itemID);
        }
      }

      //Check timers to restart
      for (const item of values) {
        if (item?.id && item.hasOwnProperty(nestedField)) {
          //Stop the existing timer if it has been already started and there's a new value for the item
          if (startedTimers.hasOwnProperty(item?.id) && startedTimers[item.id].value !== item[nestedField]) {
            clearTimeout(startedTimers[item?.id].timer);
          }

          //Create a new timer if the debounced value is not the same as the the current debounced value
          //OR doesn't yet exist in the debounced list)
          if (!startedTimers.hasOwnProperty(item?.id) || startedTimers[item.id].value !== item[nestedField]) {
            newTimers[item.id] = {
              timer: setTimeout(() => {
                setDebouncedValue({ id: item.id, value: item[nestedField] });
              }, delay),
              value: item[nestedField],
            };
          }
        }
      }
    }

    const tempStartedTimers = { ...startedTimers, ...newTimers };
    if (timersToRemove.length > 0) {
      for (const itemID of timersToRemove) {
        delete tempStartedTimers[itemID];
      }
    }
    setStartedTimers(tempStartedTimers);

    if (debouncedValuesToRemove.length > 0) {
      const tempDebouncedValues = { ...debouncedValues };
      for (const itemID of debouncedValuesToRemove) {
        delete tempDebouncedValues[itemID];
      }
      setDebouncedValues(tempDebouncedValues);
    }

    //NOTE: un-mounting logic to clear active timeouts is handled by a the useEffect hook below
  }, [values, delay, nestedField]);

  //Used exclusively to clear any outstanding timers when a component was unmounted
  // OR the delay or nestedField value has changed
  // BUT NOT when the values array has been changed
  useEffect(() => {
    return () => {
      for (const timer in startedTimers) {
        clearTimeout(startedTimers[timer]);
      }
    };
  }, [delay, nestedField]);

  return [debouncedValues];
};
