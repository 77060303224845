"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalNumberOfRecords = void 0;
/**
 * Gets the maximum number of records across all information assets that are attached to a system
 * @param system
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTotalNumberOfRecords = (system) => {
    const links = system?.informationAssets?.items ? system.informationAssets.items : [];
    const arrayOfRecords = [];
    for (const link of links) {
        if (link.numberOfRecords) {
            arrayOfRecords.push(link.numberOfRecords);
        }
    }
    return Math.max(...arrayOfRecords);
};
exports.getTotalNumberOfRecords = getTotalNumberOfRecords;
