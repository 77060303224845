import { useWelcomeTour } from "../../../../hooks/views/useWelcomeTour/useWelcomeTour";

/**
 * Welcome tour for the Document Editor. Note: this is only placed in the 'useCreateReport' page so that it doesn't trigger for auto-prompted reports
 */
export const useDocumentEditorTour = () => {
  const steps = [
    {
      selector: "[data-tourid=document-editor-container]",
      title: "Welcome to the Document Editor",
      content:
        "This page allows you to generate reports, policies, and documentation. You may use built-in templates or define your own custom templates.",
    },
    {
      selector: "[data-tourid=document-editor-file-options]",
      title: "File Options Pane",
      content: `This section is where you can generate Reports, Export the Documents, or save them into the Platform`,
    },
    {
      selector: "#document-editor-drawer > div > div.MuiBox-root.css-0 > div > div > div > button:nth-child(1)",
      title: "Create a Document",
      content: `Here you can generate a document using one of Rivial's built in Templates, or generate a document using your own custom Report Templates`,
    },
    {
      selector: "#document-editor-drawer > div > div.MuiBox-root.css-0 > div > div > div > button:nth-child(2)",
      title: "Open a Document",
      content:
        "Quickly open an existing document, either one that's already saved in the Rivial Platform, or any Word compatible document from your computer",
    },
    {
      selector: "#document-editor-drawer > div > div.MuiBox-root.css-0 > div > div > div > button:nth-child(3)",
      title: "Save a Document",
      content:
        "When you are ready, you can save a document directly into the Platform, or export it in Word or PDF format",
    },
    // {
    //   selector: "[data-tourid=document-editor-container]",
    //   title: "Save a Document to the Reports Module",
    //   content: "Save this document in the Rivial Platform. This allows you to share the document, create and track new versions, and upload it directly as Evidence"
    // },
    // {
    //   selector: "[data-tourid=document-editor-container]",
    //   title: "Save a Document as a Custom Template",
    //   content: "Turn this document into a Custom Template. Custom templates can be used by others in your Organization and may even be configured to populate dynamically"
    // },
    // {
    //   selector: "[data-tourid=document-editor-container]",
    //   title: "Export as a Word Document",
    //   content: "Download this file as a Word document"
    // },
    // {
    //   selector: "[data-tourid=document-editor-container]",
    //   title: "Export as a PDF Document",
    //   content: "Download this file as a PDF document"
    // },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "documentEditorWelcomeTour",
  });
};

/**
 * Welcome tour for the 'Report Details' page
 */
export const useReportDetailsTour = ({ disable } = {}) => {
  const steps = [
    {
      selector: "[data-tourid=report-details-container]",
      title: "Report Details",
      content:
        "This page allows you to view a Report that is saved in the Rivial Platform. You can download the document, or edit it and save new versions.",
    },
    {
      selector: "[data-tourid=report-details-version-name]",
      title: "Current Report Version",
      content: "View the currently selected Report Version",
    },
    {
      selector: "[data-tourid=report-details-download-button]",
      title: "Download Button",
      content: "Quickly download the currently selected Report Version as a Word document",
    },
    {
      selector: "[data-tourid=report-details-edit-button]",
      title: "Edit Mode",
      content:
        "This opens the Report in the Document Editor so that updates may be made, and new versions may be saved",
    },
    {
      selector: "[data-tourid=report-details-versions-button]",
      title: "Versions",
      content: "View and download previous versions of this Report",
    },
    {
      selector: "[data-tourid=report-details-save-button]",
      title: "Save a new Version",
      content:
        "Save a new version of this report to the Rivial Platform. The latest version will always be the default view when opening Reports",
    },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "reportDetailsWelcomeTour",
    disable,
  });
};
