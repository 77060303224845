"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStringArrayIntoOrderedList = void 0;
const eventLogger_1 = require("../Misc/eventLogger");
/**
 * Combines a list of strings into one string numbering the items
 * @param {string[]|object[]} items - the strings to combine
 * @param {string} itemProperty-
 * @example
 * ["aaaaa","bbbbb","ccccc"]
 * into
 * "1. aaaaa
 * 2. bbbbb
 * 3. ccccc"
 */
const convertStringArrayIntoOrderedList = (items, lvlOneProperty = "", lvlTwoProperty = "") => {
    const getItemValue = (item) => {
        if (typeof item === "string") {
            return item;
        }
        else if (lvlOneProperty !== "" && item.hasOwnProperty(lvlOneProperty)) {
            if (lvlTwoProperty !== "" && item[lvlOneProperty].hasOwnProperty(lvlTwoProperty)) {
                return item[lvlOneProperty][lvlTwoProperty];
            }
            return item[lvlOneProperty];
        }
        else {
            return "";
        }
    };
    if (!Array.isArray(items)) {
        (0, eventLogger_1.eventLogger)("Passed in argument is not a list, cannot be formatted!");
        return "";
    }
    if (items.length === 0) {
        return "";
    }
    if (items.length === 1) {
        return `1. ${getItemValue(items[0])}`;
    }
    let orderedList = "";
    for (const [index, item] of items.entries()) {
        orderedList += `${index + 1}. ${getItemValue(item)}${items.length !== index + 1 && "\n"}`;
    }
    return orderedList;
};
exports.convertStringArrayIntoOrderedList = convertStringArrayIntoOrderedList;
