import { Tooltip } from "@mui/material";
import React from "react";

//TODO: move styling to a separate file

/**
 * Displays the title text of a single drawer item
 * @param {string} text - The text to display
 * @param {boolean} showBadge - whether to display the "Managed By Rivial" badge
 * @return {JSX.Element}
 */
const DrawerItemHeadline = ({ text = "Headline", showBadge = false } = {}) => {
  //get the last word from string headline
  const headline = text;
  const lastWord = headline.split(" ").pop();
  const allButLastWord = headline.substr(0, headline.length - lastWord.length);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <p style={{ marginBottom: ".3em" }}>
          <span style={{ fontSize: "1.3em", fontWeight: "400", color: "black" }}>
            {allButLastWord}
            {/*Below GUI setup allows for badge to not wrap without the last word*/}
            <span style={{ alignSelf: "center", whiteSpace: "nowrap" }}>
              {lastWord}
              {showBadge && " "}
              {showBadge && (
                <Tooltip title="Managed By Rivial">
                  <img
                    style={{ height: ".9em" }}
                    src={"./assets/logos/colored_logo_icon.png"}
                    alt="Rivial Managed Icon"
                  />
                </Tooltip>
              )}
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default DrawerItemHeadline;
