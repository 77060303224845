import { API, graphqlOperation } from "@aws-amplify/api";

import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { DeleteImplementationLevel } from "../../ImplementationLevel/functions/DeleteImplementationLevel";

const { deleteMutation: deleteProgramSection } = generateGraphql("ProgramSection");

export const DeleteProgramSection = async (item) => {
  if (item && item.implementationLevels && item.implementationLevels.items) {
    for (const implementationLevel of item.implementationLevels.items) {
      await DeleteImplementationLevel(implementationLevel);
    }
  }

  return await API.graphql(
    graphqlOperation(deleteProgramSection, {
      input: {
        id: item.id,
      },
    }),
  )
    .then(({ data }) => {
      if (data && data.deleteProgramSection) {
        InfoLogger(`Program Section ${data.deleteProgramSection.id} was Successfully Deleted`);
      }
    })
    .catch((err) => ErrorLogger("Error! Program Section delete function: ", err));
};
