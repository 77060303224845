import { Auth } from "@aws-amplify/auth";
import Grid from "@mui/material/Grid";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Col, Input, Label } from "reactstrap";

import { useWorkflow } from "../../../hooks/views/useWorkflow";
import { ErrorLogger } from "../../EventLogger";
import { useNewPassword } from "../hooks/useNewPassword";

import LoginButton from "./LoginButton";
import MFAScreen from "./MFA/MFAScreen";
import MFASetup from "./MFA/MFASetup";

/**
 * @description This component is used for the first sign in of a user, handling a new password.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} - The forgot password component.
 * @constructor
 */
const FirstSignIn = ({ user, changeState }) => {
  /**
   * Require MFA for the user if the user is required to have MFA
   */
  const requireMFA = user?.challengeParam?.userAttributes?.["custom:requireMFA"] === "true";

  const workflow = useWorkflow({
    steps: [
      {
        id: "change_password",
        text: "Change Password",
      },
      {
        id: "setup_mfa",
        text: "Setup MFA Settings",
      },
    ],
  });

  const onNextStep = (stepNumber = 1) => {
    workflow.progressTracker.setCurrentStep(workflow.progressTracker.steps[stepNumber]);
  };

  return (
    <div>
      {!requireMFA ? (
        <FirstSignInPasswordChange changeState={changeState} requireMFA={requireMFA} user={user} />
      ) : (
        <div>
          <div style={{ width: "100%", marginBottom: "2em" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {workflow?.progressTracker?.stepper.display}
            </div>
          </div>
          <Grid item lg={12} xs={12} data-tourid={"first-login-workflow"}>
            {workflow?.progressTracker?.currentStep?.id === "change_password" && (
              <FirstSignInPasswordChange
                changeState={changeState}
                requireMFA={requireMFA}
                user={user}
                callback={() => onNextStep(1)}
              />
            )}

            {workflow?.progressTracker?.currentStep?.id === "setup_mfa" && <MFASetup changeState={changeState} />}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default FirstSignIn;

/**
 * @description This component is used for the first sign in of a user, handling a new password.
 * @param {function} changeState - The function to change the state of the user sign in process.
 * @param {object} user - The user object.
 * @param {boolean} requireMFA - Whether or not the user is required to have MFA.
 * @param {function} callback - The callback function to call after the user has completed password change.
 * @returns {JSX.Element}
 * @constructor
 */
const FirstSignInPasswordChange = ({ changeState, user, requireMFA, callback }) => {
  const passwordHook = useNewPassword({ enableResetButton: false });

  const [name, setName] = useState("");
  const [isMfaSetupComplete, setIsMfaSetupComplete] = useState(false);

  const isSubmitEnabled = passwordHook.isPasswordMatched && passwordHook.newPassword && passwordHook.reEnterPassword;

  const onSubmitNewPassword = async () => {
    if (passwordHook && passwordHook.newPassword && passwordHook.isPasswordMatched) {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        passwordHook.newPassword, // the new password
        // OPTIONAL, the required attributes
        {
          name,
        },
      )
        .then(async (response) => {
          if (response?.challengeName === "SMS_MFA" || response?.challengeName === "SOFTWARE_TOKEN_MFA") {
            /**
             * The MFA was already setup for a user, this will redirect user to enter MFA code
             */
            setIsMfaSetupComplete(true);
          } else {
            if (requireMFA) {
              /**
               * User has required MFA by organization, go to MFA setup
               */
              if (typeof callback === "function") {
                callback();
              }
            } else {
              /**
               * User does not have required MFA by organization, complete sign in
               */
              changeState("signedIn", response);
            }
          }
        })
        .catch((err) => {
          ErrorLogger("Fails to complete new password", err);
          alert("Fails to complete new password, please try again");
          Sentry.withScope((scope) => {
            scope.setTag("username", user?.username);
            Sentry.captureMessage(`Fails to complete new password.`);
          });
        });
    } else {
      alert("Please check if your password meets requirements");
    }
  };

  return (
    <div>
      {isMfaSetupComplete ? (
        <MFAScreen user={user} changeState={changeState} />
      ) : (
        <div>
          <Col md={12}>
            <Label htmlFor="name">Full Name</Label>
            <span> </span>
            <Input
              className="appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              key="name"
              name="name"
              type="text"
              autoFocus={true}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <p> </p>
          {passwordHook.display}
          <div style={{ padding: "0px 15px" }}>
            <LoginButton
              text={"Complete Registration"}
              buttonColor={"#83C6E3"}
              textColor={"black"}
              disabled={!isSubmitEnabled}
              title={"Complete the first sign in process"}
              onClick={onSubmitNewPassword}
            />
          </div>
        </div>
      )}
    </div>
  );
};
