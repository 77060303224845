"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortObjectsByField = void 0;
/**
 * @description Sort array of objects by field
 */
const sortObjectsByField = (array, field, direction = "asc") => {
    if (!array || (array && field === "")) {
        return null;
    }
    if (direction === "asc") {
        return array.sort((a, b) => a[field] - b[field]);
    }
    else if (direction === "desc") {
        return array.sort((a, b) => b[field] - a[field]);
    }
    else {
        return array;
    }
};
exports.sortObjectsByField = sortObjectsByField;
