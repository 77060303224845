import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useDepartmentGrid } from "../hooks/useDepartmentGrid";

/**
 * Displays a list of Departments for an org
 * @param {object} props - all props passed to the useDepartmentGrid hook
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentGrid = (props) => useDepartmentGrid(props).display;

export default withOrganizationCheck(DepartmentGrid);
