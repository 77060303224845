import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useUUID } from "../../../../hooks/functional/useUUID";
import { useForm } from "../../../../hooks/views/useForm";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import ConfigureAutomation from "../components/ConfigureAutomation";
import ConfigureTrigger from "../components/ConfigureTrigger";
import ConfigureTriggerCondition from "../components/ConfigureTriggerCondition";
import { formatAutomationStepForMutation } from "../functions/formatAutomationStepForMutation";
import { useCreateAutomationTour } from "../tours/automationTours";

/**
 * Custom form UI for creating a new Automation
 * @param {string} organizationID - ownerGroup to use for creating/querying resources
 * @param {string} itemId - id of the item that the Automation should be associated with
 * @param {string} typename - the typename of the item that the Automation should be associated with
 * @param {object} item - optionally pass an item directly and use this as an Edit form
 * @param {boolean} disableEdits - if TRUE will disable all editing
 * @param {function} toggleModal - function to close the modal
 * @param {function} resetFunction - function to reset the form
 * @param {boolean} isTemplate - if TRUE will create the automation as a template
 * @param {boolean} disableTemplates - if TRUE will disable the 'Create from Templates' button
 * @param {object} ...props - other props to pass to useForm hook
 * @returns {{display: *, input: {}}}
 */
export const useCreateAutomation = ({
  organizationID,
  itemId,
  typename,
  item,
  disableEdits,
  toggleModal,
  resetFunction,
  isTemplate = false,
  disableTemplates = false,
  toolID,
  ...props
}) => {
  /**
   * Handles the mutation for adding an automation into the database
   * @param input
   * @param input.trigger
   * @param input.trigger.type
   * @param input.name
   * @param input.condition
   * @param input.condition.filters
   * @param input.condition.typename
   * @param input.condition.fields
   * @param input.automations
   * @param input.automations[].id
   * @param input.automations[].config
   * @param input.automations[].type
   * @returns {Promise<*>}
   */
  const submitFunction = async (input) => {
    const { createMutation, updateMutation } = generateGraphql("Automation", ["name"]);

    // Remove unused fields from the input and stringify the config
    const steps =
      input?.automations?.map((step) => {
        return formatAutomationStepForMutation({ step });
      }) || [];

    // If using as an Edit form, use updateMutation
    if (!isNullOrUndefined(item)) {
      const itemId = input.itemId;
      const triggers = [input.trigger];
      const conditions = [input.condition];

      return await ItemMutation(updateMutation, {
        id: item.id,
        name: input.name,
        description: input.description,
        enabled: input.enabled,
        triggers,

        conditions,
        // NOTE: edited steps are saved in ConfigureAutomation
        itemId: itemId === "" ? undefined : itemId,
        typename: input?.condition?.typename,
        cooldownPeriod: input?.cooldownPeriod || undefined,
        enableCooldown: input?.enableCooldown || undefined,
        isTemplate,

        toolID: toolID || undefined,
      });
    }

    // Else handle creating a new automation
    else {
      const itemId = input.itemId;
      const triggers = [input.trigger];
      const conditions = [input.condition];

      return await ItemMutation(createMutation, {
        id: automationID, // Pass in a custom automationID for the new automation
        name: input.name,
        description: input.description,
        ownerGroup: organizationID,
        enabled: input.enabled,
        triggers,
        conditions,
        automations: steps,
        itemId: itemId === "" ? undefined : itemId,
        typename: input?.condition?.typename,
        cooldownPeriod: input?.cooldownPeriod || undefined,
        enableCooldown: input?.enableCooldown || undefined,
        isTemplate,

        toolID: toolID || undefined,
      });
    }
  };

  const [showConditions, setShowConditions] = useState(false);
  const [showCooldownPeriod, setShowCooldownPeriod] = useState(false);
  const [showCooldownPeriodSwitch, setShowCooldownPeriodSwitch] = useState(false);
  const [automationID] = useUUID();

  const form = useForm({
    item: {},
    organizationID,
    submitFunction,
    fieldConfig: {
      name: {
        label: "Name",
        required: true,
        defaultValue: item?.name,
        disabled: disableEdits,
      },
      description: {
        label: "Description",
        inputType: "textarea",
        defaultValue: item?.description,
      },
      enabled: {
        label: "Enabled",
        tooltip: "This Automation must be 'Enabled' in order to run",
        defaultValue: item?.enabled || false,
        inputType: "switch",
        disabled: disableEdits,
        isHidden: isTemplate, // hidden option when setting up Automation Templates (should always be false)
      },
      trigger: {
        label: "Trigger",
        inputType: "custom",
        defaultValue: {
          type: itemId ? "resourceUpdated" : item?.triggers?.[0]?.type || "manual",
        },
        tooltip: "Select the method that will Trigger this Automation",
        customConfig: {
          component: (
            <ConfigureTrigger
              item={item}
              automationID={automationID}
              itemId={itemId}
              disableEdits={disableEdits}
              isTemplate={isTemplate}
            />
          ),
        },
      },
      enableCooldown: {
        label: "Enable Cooldown Period",
        tooltip: "Configure this Automation so that it can only run again after a certain amount of time",
        defaultValue: item?.enableCooldown || false,
        inputType: "switch",
        isHidden: !showCooldownPeriodSwitch,
        disabled: disableEdits,
      },
      cooldownPeriod: {
        label: "Cooldown Period (minutes)",
        tooltip:
          "Configure the number of minutes that an Automation will be in cooldown. Note: 1 hour = 60 minutes, 1 day = 1440 minutes, 1 week = 10080 minutes, 1 month = 43800 minutes",
        defaultValue: item?.cooldownPeriod || false,
        inputType: "number",
        isHidden: !showCooldownPeriod,
        disabled: disableEdits,
      },
      condition: {
        isHidden: !showConditions,
        label: "Condition",
        tooltip: "Add Conditions that must be satisfied for this Automation to proceed",
        inputType: "custom",
        defaultValue: {},
        customConfig: {
          component: (
            <ConfigureTriggerCondition
              organizationID={organizationID}
              itemId={itemId}
              typename={typename}
              item={item}
              disableEdits={disableEdits}
              isTemplate={isTemplate}
            />
          ),
        },
      },
      automations: {
        label: "Steps",
        tooltip:
          "If all Conditions pass, this may run one or more Automation steps such as sending notifications, generating reports, or dispatching changes",
        inputType: "custom",
        defaultValue: item?.automations || [],
        customConfig: {
          component: (
            <ConfigureAutomation
              organizationID={organizationID}
              item={item}
              disableEdits={disableEdits}
              isTemplate={isTemplate}
              performUpdates={true}
            />
          ),
        },
      },
      itemId: {
        isHidden: true,
        defaultValue: itemId,
      },
    },
    disableSubmitButton: disableEdits,
    disableResetButton: true,
    toggleModal,
    resetFunction,
    enableTemplates: !disableTemplates && !isTemplate,
    ...props,
  });

  // Handles logic for showing / hiding certain form elements depending on what is selected
  useEffect(() => {
    // Only show 'Conditions' and 'cooldown' settings when resourceUpdated or resourceCreated
    if (
      item?.triggers?.[0]?.type === "resourceUpdated" ||
      form?.input?.trigger?.type === "resourceUpdated" ||
      item?.triggers?.[0]?.type === "resourceCreated" ||
      form?.input?.trigger?.type === "resourceCreated"
    ) {
      setShowConditions(true);
      setShowCooldownPeriodSwitch(true);
    } else {
      setShowConditions(false);
      setShowCooldownPeriodSwitch(false);
    }

    // Only show 'Cooldown Period Settings' when 'enableCooldown' is true
    if (item?.enableCooldown === true || form?.input?.enableCooldown === true) {
      setShowCooldownPeriod(true);
    }
  }, [form.input]);

  // welcome tour
  useCreateAutomationTour();

  const display = <div data-tourid={"create-automation-container"}>{form.display}</div>;

  return {
    ...form,
    display,
  };
};
