import React, { useEffect } from "react";

import { updateObjectInArray } from "@rivial-security/func-utils";

import { useForm } from "../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { AUTOMATION_TYPES } from "../enums/AUTOMATION_TYPES";
import { formatAutomationStepForMutation } from "../functions/formatAutomationStepForMutation";

import ConfigureAutomation from "./ConfigureAutomation";

/**
 * UI for configuring the AutomationStep of a 'loop'
 *
 * Allows the user to first select a source or path to reference incoming data, then try and loop over it.
 * For each automationStep in the loop, a set of Automation Steps can be ran. Data can be accessed in a loop using smart values like:
 *
 * {{currentData.field1}}
 *
 * @param {function} toggleModal - function callback to close the containing modal
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to update automation data in the parent when its updated here
 * @param {AutomationStep} item - the automation step
 * @param {object} automation - the parent automation itself, with trigger and condition info
 * @param {object} fieldConfig - the generic fields that are used by all automation steps
 * @param {string} organizationID - the ID of the organization
 * @param {object[]} ancestorSteps - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {object} formConfig - form configurations to merge for the main form
 * @param {boolean} readOnly - if TRUE will not allow to edit or delete the sub steps
 * @param {boolean} isTemplate - if TRUE will hide options that are not templatable
 * @returns {JSX.Element}
 */
const ConfigureLoopAutomation = ({
  toggleModal,
  automations,
  setAutomations,
  automationStep,
  automation,
  fieldConfig,
  organizationID,
  ancestorSteps,
  formConfig,
  readOnly,
  isTemplate,
}) => {
  const genericFieldsForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    disableRoleChecking: true,
    fieldConfig,
    formID: "a395a619-8f88-4c97-84cf-111a3a5b7642",
    item: automationStep,
  });

  const onFormSubmit = async (input) => {
    const newAutomations = [...(automations || [])];

    //Parse automations to include only db fields
    const subSteps = input?.automations?.map((step) => {
      return formatAutomationStepForMutation({ step });
    });

    updateObjectInArray(automations, {
      id: automationStep.id,
      name: genericFieldsForm?.input.name,
      description: input?.dataSource,
      config: {
        ...input,
        automations: subSteps,
      },
    });

    setAutomations(newAutomations);
    tryFunction(toggleModal);
  };

  const form = useForm({
    disableResetButton: true,
    disableRoleChecking: true,
    fieldConfig: {
      dataSource: {
        label: "Data Source",
        tooltip: "Use Smart values to reference data from the Trigger or other Automation Steps. ",
        placeholder: "Input a smart value that references a list of items. For example: {{ webhookData.payload }}",
      },
      iterationLimit: {
        label: "Iteration Limit",
        defaultValue: 1000,
        required: true,
        tooltip:
          "The maximum number entries to process from the data source. After reaching the limit the remaining entries will be skipped. Set to 0 to temporarily disable the loop steps.",
        inputType: "number",
        numberConfig: {
          min: 0,
          max: 100000,
          step: 1,
        },
      },
      automations: {
        label: "Steps",
        inputType: "custom",
        customConfig: {
          component: (
            <ConfigureAutomation
              organizationID={organizationID}
              disabledStepTypes={[AUTOMATION_TYPES.LOOP]}
              ancestorSteps={ancestorSteps}
              automation={automation}
              readOnly={readOnly}
              disableEdits={readOnly}
              isTemplate={isTemplate}
              showLoopSmartValues={true}
            />
          ),
        },
      },
    },
    submitFunction: onFormSubmit,
    ...formConfig,
  });

  useEffect(() => {
    const newItem = {
      dataSource: automationStep?.description,
    };

    let automations = automationStep?.config?.automations;
    if (Array.isArray(automations)) {
      automations = automations.map((automation) => {
        let config = {};
        if (typeof automation?.config === "string") {
          config = JSON.parse(automation?.config);
        } else if (typeof automation?.config === "object") {
          config = automation?.config;
        }
        return {
          ...automation,
          config,
        };
      });
    } else {
      automations = [];
    }

    newItem.automations = automations;
    newItem.iterationLimit = automationStep?.config?.iterationLimit || 1000;
    form.setInput(newItem);
  }, [automationStep]);

  return (
    <div>
      {genericFieldsForm.display}
      {form.display}
    </div>
  );
};

export default withOrganizationCheck(ConfigureLoopAutomation);
