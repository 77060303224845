import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import CreateChange from "../../../Risk/RiskChanges/components/CreateChange";
import RiskChangeDataGrid from "../../../Risk/RiskChanges/components/RiskChangeDataGrid";
import { riskChangesByOwnerGroupForGrids } from "../../../Risk/RiskChanges/hooks/useGroupedRiskChangeDataGrid/useGroupedRiskChangeDataGrid";
import { useRiskChangeDataGrid } from "../../../Risk/RiskChanges/hooks/useRiskChangeDataGrid";

/**
 * Displays list of risk changes linked to a recommendation item
 * @param {object} item - the recommendation item
 * @param {string} organizationID - the organization that the recommendation item belongs to
 * @return {JSX.Element}
 */
const NestedRiskChangesCard = ({ item, organizationID }) => {
  const parentTypename = resources.RECOMMENDATION;

  const dataGrid = useNestedDataCard({
    organizationID,
    item,
    parentTypename,
    fields: riskChangesByOwnerGroupForGrids,
    typename: resources.RISK_CHANGE,
    grid: (
      <StyledWrapper wrapperStyle={{ height: "70vh" }}>
        <RiskChangeDataGrid module={modules.GOVERNANCE} resource={parentTypename} />
      </StyledWrapper>
    ),
    query: generateGraphql(parentTypename, ["riskChanges"], {
      riskChanges: `(limit: 1000) {
        items {
          id
          ownerGroup
          name
          date
          description
          reason
          changeOwner
          type
          groupID
          change
          userEmail
          source {
            vendorID
            vendorSolutionID
            vendorReviewID
            evidenceID
          }
          systemLinks(limit: 1000) {
            items {
              id
              ownerGroup
              system {
                id
                name
                ownerGroup
              }
            }
          }
        }
        nextToken
      }`,
    }).getQuery,
    form: <CreateChange />,
    gridHook: useRiskChangeDataGrid,
    field: "riskChanges",
    connectionIDField: "recommendationID",
  });

  return <div style={{ height: "30em" }}>{dataGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(NestedRiskChangesCard);
