/**
 * Finds the indexes in the grid that correspond to the 'items' ids and selects them
 * @param {object} ref - syncfusion grid reference object
 * @param {object[]} items - the items to select
 * @param {function} callback - function to call once seleRows is about to be called
 */
export const selectMultipleItems = ({ ref, items, callback }) => {
  if (ref?.currentViewData && Array.isArray(ref?.currentViewData)) {
    const indexesToSelect = [];

    //Find all objects that need to be checked
    if (
      Array.isArray(ref?.currentViewData) &&
      ref?.currentViewData.length > 0 &&
      Array.isArray(items) &&
      items.length > 0
    ) {
      for (const row of items) {
        const foundItemIndex = ref?.currentViewData.findIndex((item) => item.id === row.id);
        if (foundItemIndex !== -1) {
          indexesToSelect.push(foundItemIndex);
        }
      }
    }

    //Reset items to check value (if empty array items will not be rechecked)
    callback && callback();
    ref?.selectRows(indexesToSelect);
  }
};
