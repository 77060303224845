"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.root = void 0;
const BuiltInTemplateID_1 = require("../../../BuiltInTemplateID");
const email_1 = require("./email");
exports.root = {
    id: "d06883ce-c792-4a80-8c8d-594f25ced382",
    automations: [
        {
            id: "guid-7943a985-6319-42dc-9a6c-17cc67315bf6",
            config: {
                emailTemplateID: "1e90d214-1791-48a2-94ce-f23fd9196042",
                emailTemplate: email_1.email,
                type: "email",
            },
            description: "Questionnaire Notification",
            name: "sendQuestionnaireNotification",
            type: "sendNotification",
        },
    ],
    conditions: [
        {
            rule: {
                id: 0.6289055510596901,
                combinator: "and",
                condition: "and",
                rules: [
                    {
                        id: 0.18438141373309302,
                        field: "type",
                        operator: "equal",
                        value: "questionnaire",
                    },
                    {
                        id: 0.759382399419642,
                        field: "status",
                        operator: "equal",
                        value: "todo",
                    },
                ],
            },
            typename: "Task",
        },
    ],
    createdAt: "2023-09-06T03:52:01.487Z",
    description: "When a questionnaire type task gets created, a message is sent to the user notifying that they need to respond.",
    enabled: false,
    isTemplate: true,
    name: "New Questionnaire Notification Automation",
    ownerGroup: "TEMPLATE",
    triggers: [
        {
            schedule: "",
            type: "resourceCreated",
            webhookUrl: "",
        },
    ],
    typename: "Automation",
    updatedAt: "2023-09-06T04:07:01.807Z",
    builtInTemplateID: BuiltInTemplateID_1.BuiltInTemplateID.NEW_QUESTIONNAIRE_TASK_NOTIFICATION,
    __typename: "Automation",
};
