import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../../../hooks/views/useForm/useForm";

/**
 * Custom hook for creating a program blueprint (maturity matrix) (Mainly used through the component Instance CreateMaturityMatrix.js)
 * @param {string} organizationID - the ownerGroup to use for creating/querying blueprints
 * @param {function} toggleModal - function to toggle the modal
 * @param {function} resetFunction - function to reset the form
 * @param {object} props - all other props passed directly to useForm hook
 * @returns {{input: {}, display: *}}
 */
const useCreateMaturityMatrix = ({ organizationID, toggleModal, resetFunction, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.BLUEPRINT;

  const { createMutation } = generateGraphql("MaturityMatrix", ["name", "description"]);

  const formHook = useForm({
    mutation: createMutation,
    organizationID,
    toggleModal,
    module,
    resource,
    resetFunction,
    header: "Create a New Program Blueprint",
    fieldConfig: {
      name: {
        label: "Name",
        inputType: "text",
        required: true,
      },
      description: {
        label: "Description",
        inputType: "text",
      },
    },
    ...props,
  });

  return { ...formHook };
};

export default useCreateMaturityMatrix;
