export const questionnaireDefinition = {
  routes: {
    grid: "organization_manager/questionnaires",
    details: "organization_manager/questionnaires",
  },

  icon: "simple-line-icons:speech",
  fields: {
    name: {
      default: {
        description: "The primary text of this Questionnaire, the title of the survey",
      },
    },
    description: {
      default: {
        description: "Any additional details regarding this Questionnaire",
      },
    },
    status: {
      default: {
        description: "The current completion state of this Questionnaire",
      },
    },
    startDate: {
      default: {
        description: "The date on which to send out initial invitations to complete the Questionnaire",
      },
    },
    endDate: {
      default: {
        description: "Date to use as the due date for any tasks related to this Questionnaire",
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    systems: {
      default: {
        description: "The systems that this Questionnaire will be associated with",
        nestedFields: `(limit: 100) { items { id systemID questionnaireID system { name  pointOfContact { email } adminPointOfContact { email } } } }`,
      },
    },
    allowUnauthenticatedSubmissions: {
      default: {
        description:
          "When enabled, anyone with a link to the landing page will be allowed to submit an answer to this questionnaire without signing in.",
      },
    },

    customResourceType: {
      default: {
        description:
          "The custom resource type that this Questionnaire will populate, all questions are based on the fields in this Custom Resource Type. This cannot be changed once a response has been submitted.",
        nestedFields: `{
          id
          name
          description
          customFields {
            name
            type
            description
            options { label value }
            multipleSelect { label value }
            numberSettings { min max step format }
            required
          }
        }`,
      },
    },
    customResourceTypeID: {
      default: {
        description:
          "The custom resource type that this Questionnaire will populate, all questions are based on the fields in this Custom Resource Type",
      },
    },

    assignees: {
      default: {
        description: "Points of contacts assigned to answer this Questionnaire",
        nestedFields: `(limit: 1000) {
          items {
            pointOfContact {
              id
              firstName
              lastName
              email
            }
            tasks (limit: 1) {
              items {
                id
                status
              }
            }
          }
        }`,
      },
    },
  },

  views: {
    grid: {
      queryFields: [
        "name",
        "description",
        "status",
        "startDate",
        "endDate",
        "tags",
        "customResourceType",
        "systems",
        "assignees",
        "allowUnauthenticatedSubmissions",
      ],
    },
    details: {
      displayFields: [
        "name",
        "description",
        "status",
        "startDate",
        "endDate",
        "tags",
        "allowUnauthenticatedSubmissions",
        "customResourceType",
      ],
      queryFields: [
        "name",
        "description",
        "status",
        "startDate",
        "endDate",
        "tags",
        "customResourceType",
        "assignees",
        "allowUnauthenticatedSubmissions",
      ],
    },
  },
};
