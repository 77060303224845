import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useKeyPerformanceIndicatorDetails } from "../hooks/useKeyPerformanceIndicatorDetails";

/**
 * Displays details for a single Key Performance Indicator
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const KeyPerformanceIndicatorDetails = (props) => {
  const detailsHook = useKeyPerformanceIndicatorDetails(props);

  return props.tableDisplay ? detailsHook.tableDisplay : detailsHook.display;
};

export default withDetailsPage(KeyPerformanceIndicatorDetails);
