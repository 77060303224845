import { useEffect } from "react";

/**
 * @description Check each field user role permissions
 * @param {object} ref - reference of the the grid component
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {object} checkPermissionsHook - checks permissions for the grid component
 */
export const useGridCheckFieldsRole = ({ ref, fields = [], checkPermissionsHook }) => {
  useEffect(() => {
    if (ref) {
      const notAllowed = [];

      if (fields && Array.isArray(fields) && fields.length > 0) {
        for (const field of fields) {
          if (!checkPermissionsHook.CheckFieldPermissionsRead(field.name)) {
            notAllowed.push(field.friendlyName || field.name);
          }
        }
      }

      // TODO: Remove in the future
      // TEMPORARILY! Gives access to the QuickDetails column
      if (notAllowed.length > 0 && notAllowed[0] === "ℹ") notAllowed.shift();

      ref.hideColumns(notAllowed);
    }
  }, [ref]);
};
