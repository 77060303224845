import React from "react";

const ChangeType = ({ item, type }) => {
  const typeNameDictionary = {
    asset_size_change: "Asset Size Change",
    risk_control_change: "Risk Control Change",
    key_risk_indicator_change: "Key Risk Indicator Change",
    number_of_customers_change: "Number of Customers/Members Change",
    manualEntry: "Manual Entry",
    system_availability_change: "System Availability Change",
    system_asset_change: "System Assets Change",
    enterpriseControl: "Enterprise Control Change",
    nonStandardControl: "Non-Standard Control Change",
  };

  return <>{type && typeNameDictionary[type] ? typeNameDictionary[type] : type}</>;
};

export default ChangeType;
