import { ErrorLogger } from "@utils/EventLogger";

import { schemaEntities } from "../../constants/schemaEntities";

import { getListByFieldString } from "./getListByFieldString";

/**
 * Generates a nested fields entry for a specific resource definition - useful normalizing nested fields
 * for one type across all parent resources (such as all notes entries)
 * @param {string} definition - the resource for which to generate the nested fields string
 * @param {boolean} hasMany - TRUE if generating nested fields string for a list of the provided resource
 * @returns {string|null}
 */
export const generatedNestedFieldsForDefinition = ({ definition, hasMany = false }) => {
  //Check arguments
  if (!definition) {
    ErrorLogger("Invalid arguments passed to generatedNestedFieldsForDefinition", { definition });
    return null;
  }

  //retrieve the fields of the nested string
  let queryFields = [];
  const resourceFields = definition?.fields;
  if (typeof resourceFields === "object") {
    queryFields = [];
    for (const field in resourceFields) {
      queryFields.push(field);
    }
  }

  const nestedFields = {};
  for (const queryField of queryFields) {
    // - get the default nested fields
    const fieldDefinition = resourceFields[queryField];
    const currentNestedFields = fieldDefinition?.default?.nestedFields;

    // - if nested fields exists add them to the nestedFields object
    if (currentNestedFields) {
      nestedFields[queryField] = currentNestedFields;
    }
  }

  const fieldString = getListByFieldString({ queryFields, nestedFields });
  if (!fieldString) {
    return null;
  }

  if (definition?.schemaEntity === schemaEntities.TYPE || hasMany === true) {
    return `{ ${fieldString} }`;
  } else {
    return `(limit: 500) { items { ${fieldString} } nextToken }`;
  }
};
