import { useEffect, useState } from "react";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

export const useCustomTemplateList = ({ organizationID }) => {
  const listReportTemplatesByOwnerGroup = /* GraphQL */ `
    query ReportTemplatesByOwnerGroup(
      $ownerGroup: String
      $sortDirection: ModelSortDirection
      $filter: ModelReportTemplateFilterInput
      $limit: Int
      $nextToken: String
    ) {
      reportTemplatesByOwnerGroup(
        ownerGroup: $ownerGroup
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          description
          ownerGroup
        }
        nextToken
      }
    }
  `;

  const [list, setList] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  useEffect(() => {
    const getData = async () => {
      const data = await ListQueryBy({
        query: listReportTemplatesByOwnerGroup,
        variables: {
          ownerGroup: organizationID,
        },
      });

      if (Array.isArray(data)) {
        setList(data);
      } else {
        setList([]);
      }
    };

    getData();
  }, [resetKey]);

  return {
    resetFunction: () => {
      setResetKey((resetKey) => resetKey + 1);
    },
    list,
  };
};
