import React, { useContext } from "react";
import { Button } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { DetailsTable } from "../../../hooks/views/useDetails/hooks/useDetails";
import { useModal } from "../../../hooks/views/useModal";
import { UIContext } from "../../Context/UIContext";
import { performToastOperation } from "../../Toasts/functions/toastOperation";
import UnderlinedTooltip from "../../Tooltips/UnderlinedTooltip";
import DashboardCard from "../DashboardCard/components/DashboardCard";
import CreateOrSelectInput from "../GenericEditFieldV3/components/CreateOrSelectInput";
import GenericEditFieldV3 from "../GenericEditFieldV3/GenericEditFieldV3";

import { updateNestedField } from "./functions/updateNestedField";

/**
 * Displays a single item nested field for a parent object.
 * Incorporates a generic edit field and the CreateOrSelect UI
 * @param {string} typename - the type of the item to link
 * @param {string} parentTypename - the type of the 'item' parameter
 * @param {object} item - the parent item information with the current link
 * @param {string} field - the field that will have the information about the linked item (child)
 * @param {string} idField - the id field for the child item on the parent item
 * @param {string} parentIdField - the id field for the parent on the child item
 * @param {string} displayField - the nested field to display in UI from the linked item
 * @param {function} displayFieldFunction - optional function to run the item[field] value through to get a custom display
 * @param {JSX.Element} detailsComponent - a component displays the details for a child resource
 * @param {JSX.Element} form - a create resource form for the child resource
 * @param {JSX.Element} grid - a grid form to display a list of child resources
 * @param {JSX.Element} displayComponent - a component allowing to
 * @param {object} modalConfig - configuration for the modal
 * @param {object} [prepareSelectionForm] - a form to display before a new item can created or selected
 * @param {boolean} disableEdits - if TRUE, then the link cannot be edited
 * @param {boolean} biDirectional - if TRUE, will attempt to manage deletion and updates of 1-1 links (needs parentIdField)
 * @param {function} [resetFunction] - function to use to re-query the parent item once a link has been changed
 * @param {object} props - any other props to pass to the GenericEditFieldV3
 * @returns {JSX.Element}
 * @constructor
 */
const NestedField = ({
  typename = "Child Item",
  parentTypename = "Parent Item",
  item,
  updateItemById,
  field,
  idField,
  parentIdField,
  useMany = false,
  displayField = "name",
  displayFieldFunction,
  detailsComponent,
  modalConfig,
  form,
  grid,
  prepareSelectionForm,
  disableEdits = false,
  biDirectional = false,
  resetFunction,
  ...props
}) => {
  const { addToast, updateToast } = useContext(UIContext);
  const { updateMutation } = generateGraphql(parentTypename, [idField]);

  const niceTypename = convertCamelCaseToSentence(typename);

  const detailsModal = useModal(
    modalConfig?.header || `${niceTypename} Details`,
    React.cloneElement(detailsComponent, { item: item?.[field] }),
    <span>
      <UnderlinedTooltip
        text={
          displayFieldFunction && typeof displayFieldFunction === "function"
            ? displayFieldFunction(item?.[field])
            : item?.[field]?.[displayField]
        }
        title={`Click to view full ${niceTypename} details`}
      >
        <ItemPreview item={item?.[field]} typename={niceTypename} />
      </UnderlinedTooltip>
    </span>,
    {
      width: "75vw",
      ...(modalConfig?.config ? { ...modalConfig?.config } : {}),
    },
  );

  const displayComponent = (
    <span>
      {detailsModal.modal}
      {item?.[field] ? detailsModal.modalButton : <i>No Associated {niceTypename}</i>}
    </span>
  );

  return (
    <span>
      {useMany ? (
        <DisplayMany
          item={item}
          field={field}
          displayField={displayField}
          typename={typename}
          niceTypename={niceTypename}
          idField={idField}
          detailsComponent={detailsComponent}
          modalConfig={modalConfig}
          disableEdits={disableEdits}
        />
      ) : (
        <GenericEditFieldV3
          {...props}
          mutationFunction={async (itemInput) => {
            const newChildId = itemInput?.[idField];
            await updateNestedField({
              field,
              idField,
              typename,
              parentIdField,
              parentTypename,
              biDirectional,
              disableEdits,
              item,
              newChildId,
            });
          }}
          updateItemById={updateItemById}
          resetFunction={resetFunction}
          mutation={updateMutation}
          disableRoleChecking={true}
          item={item}
          field={idField}
          disableEdits={disableEdits}
          customDisplayComponent={displayComponent}
          customInputComponent={
            <CreateOrSelectInput
              typename={niceTypename}
              resetFunction={resetFunction}
              form={form}
              grid={grid}
              displayComponent={displayComponent}
              prepareSelectionForm={prepareSelectionForm}
              displayField={displayField}
              displayFieldFunction={displayFieldFunction}
            />
          }
          deleteButton={
            field &&
            item.hasOwnProperty(field) &&
            item[field] && (
              <Button
                data-testid={`button-delete-field-${field}`}
                size="sm"
                color="ghost-danger"
                className="btn-pill"
                onClick={async () => {
                  await performToastOperation({
                    addToast,
                    updateToast,
                    operation: async () => {
                      await updateNestedField({
                        field,
                        idField,
                        typename,
                        parentIdField,
                        parentTypename,
                        biDirectional,
                        disableEdits,
                        item,
                        newChildId: null,
                      });
                    },
                    inProgressText: `Unlinking ${niceTypename}`,
                    successText: `Unlinked ${niceTypename}`,
                    failedText: `Failed to unlink ${niceTypename}`,
                    iconColor: "danger",
                  });
                  resetFunction?.();
                }}
                title="Delete this link"
              >
                <i className="icon-trash" />
              </Button>
            )
          }
        />
      )}
    </span>
  );
};

const ItemPreview = ({ item, typename }) => {
  return (
    <DashboardCard title={`${typename} Preview`}>
      <DetailsTable disableRoleChecking={true} item={item} />
    </DashboardCard>
  );
};

export default NestedField;

/**
 * @description Displays a list of items for an observation.
 * @param {object} item - observation item
 * @param {string} field - field name of the item
 * @param {string} displayField - display field
 * @param {string} typename - typename of the item
 * @param {string} niceTypename - typename
 * @param {string} idField - field name of the object
 * @param {object} detailsComponent - details component
 * @param {object} modalConfig - modal config object
 * @param {boolean} disableEdits - if true, disables the edit button
 */
const DisplayMany = ({
  item,
  field,
  displayField,
  typename,
  niceTypename,
  idField,
  detailsComponent,
  modalConfig,
  disableEdits,
}) => {
  const isAssociatedItems = Array.isArray(item?.[field]?.items) && item?.[field]?.items?.length > 0;

  const modal = useModal(
    `Associated ${niceTypename}`,
    <span>
      {Array.isArray(item?.[field]?.items) &&
        item?.[field]?.items?.map((item) => (
          <DisplaySingleItemForMany
            item={item}
            displayField={displayField}
            typename={typename}
            idField={idField}
            niceTypename={niceTypename}
            detailsComponent={detailsComponent}
            modalConfig={modalConfig}
            disableEdits={disableEdits}
          />
        ))}
    </span>,
    <span>
      <UnderlinedTooltip
        text={`View associated ${niceTypename}`}
        title={`Click to view all associated ${niceTypename}`}
      >
        View associated {niceTypename}
      </UnderlinedTooltip>
    </span>,
    { width: "50vw" },
  );

  return isAssociatedItems ? modal.modalButton : <i>No Associated {niceTypename}</i>;
};

/**
 * @description Displays a single item for many linked items for an observation.
 * @param {object} item - observation item
 * @param {string} parentTypename - typename of the parent object
 * @param {string} niceTypename - typename
 * @param {string} displayField - display field
 * @param {object} detailsComponent - details component
 * @param {object} modalConfig - modal config object
 * @returns {JSX.Element}
 * @constructor
 */
const DisplaySingleItemForMany = ({ item, niceTypename, displayField, detailsComponent, modalConfig }) => {
  const detailsModal = useModal(
    modalConfig?.header || `${niceTypename} Details`,
    React.cloneElement(detailsComponent, { item }),
    <span>
      <UnderlinedTooltip text={item?.[displayField]} title={`Click to view full ${niceTypename} details`}>
        <ItemPreview item={item} typename={niceTypename} />
      </UnderlinedTooltip>
    </span>,
    {
      width: "75vw",
      ...(modalConfig?.config ? { ...modalConfig?.config } : {}),
    },
  );

  return <div>{detailsModal.modalButton}</div>;
};
