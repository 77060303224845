import React from "react";
import { Badge } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { AV_STATUS } from "../../enums/AV_STATUS";

/**
 * Displays the AV status of a document
 * @param item - object containing the AV status
 * @param item.avStatus - the AV status of item
 */
const AVStatus = ({ item }: { item: { avStatus?: string } }): React.JSX.Element => {
  const supportedStatuses = {
    [AV_STATUS.PENDING]: "primary",
    [AV_STATUS.CLEAN]: "success",
    [AV_STATUS.FILE_TOO_BIG]: "warning",
    [AV_STATUS.ERROR_PROCESSING_FILE]: "danger",
    [AV_STATUS.INFECTED]: "danger",
  };

  const avStatus = item?.avStatus;
  if (avStatus && supportedStatuses[avStatus]) {
    return (
      <Badge data-testid={"av-scan-status-badge"} color={supportedStatuses[avStatus]} pill>
        {convertCamelCaseToSentence(avStatus)}
      </Badge>
    );
  }

  return (
    <Badge data-testid={"av-scan-status-badge"} color="warning" pill>
      Not Scanned
    </Badge>
  );
};

export default AVStatus;
