import React, { useRef, useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

import useMousePosition from "../../../../../../hooks/functional/useMousePosition";
import OutsideAlerter from "../../../../../../hooks/functional/useOutsideAlerter/components/OutsideAlerter";
import CreateButton from "../../../../../../utils/GenericComponents/CreateButton";
import { useCreateLabelModal } from "../../../../../../utils/Labels/hooks/useCreateLabelModal";
import ArtifactLabels from "../../components/ArtifactLabels";

/**
 * Displays a popover with a list of labels to select for a single bookmark
 * @param {string} organizationID - the id of the organization the bookmark belongs to
 * @param {object} artifact - the artifact to display labels for
 * @param {function} onLabelSelection - callback on label selected
 * @param {object} containerRef - container of the popover target to apply an offset so the popover is positioned correctly
 * @returns {{display: JSX.Element, openPopover: openPopover}}
 */
export const useSelectArtifactLabelPopover = ({ organizationID, artifact, onLabelSelection, containerRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const mousePosition = useMousePosition();
  const targetRef = useRef(null);

  const openPopover = () => {
    let offsetX = 0;
    let offsetY = 0;
    if (containerRef?.current) {
      const { left, top } = containerRef.current.getBoundingClientRect();
      offsetX = left;
      offsetY = top;
    }

    targetRef.current = document.getElementById("label-popover-target");
    if (targetRef.current) {
      targetRef.current.style.left = `${mousePosition?.current?.x - offsetX}px`;
      targetRef.current.style.top = `${mousePosition?.current?.y - offsetY}px`;
      setIsOpen(true);
    }
  };

  //Modal to link a new label to the artifact
  const createLabelHook = useCreateLabelModal({
    organizationID,
    getNewItem: (newLabel) => {
      //on label selection handles cases when labels are not yet created
      if (typeof onLabelSelection === "function") {
        onLabelSelection({ selectedLabel: newLabel });
      }
    },
  });

  const onCreateLabel = () => {
    setIsOpen(false);
    createLabelHook.setModalIsOpen(true);
  };

  const display = (
    <div>
      {createLabelHook?.modal}
      <Popover
        trigger="legacy"
        placement="bottom"
        isOpen={isOpen}
        target={targetRef?.current}
        style={{ overflow: "auto" }}
        hideArrow={true}
      >
        <OutsideAlerter onClickOutside={isOpen ? () => setIsOpen(false) : null}>
          <PopoverHeader>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>Select a Label</div>
              <div style={{ flex: 1 }} />
              <CreateButton typename={"Label"} onClick={onCreateLabel} />
            </div>
          </PopoverHeader>
          <PopoverBody>
            <ArtifactLabels
              artifact={artifact}
              organizationID={organizationID}
              onLabelClick={({ label }) => {
                setIsOpen(false);
                if (typeof onLabelSelection === "function") {
                  onLabelSelection({ selectedLabel: label });
                }
              }}
              onCreateLabel={onCreateLabel}
              disableDeleteLabel={true}
            />
          </PopoverBody>
        </OutsideAlerter>
      </Popover>
    </div>
  );

  return { display, openPopover };
};
