import React from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { shortenLongText } from "../../utils/Text/shortenLongText";

import { useModal } from "./useModal";
import { usePreferences } from "./usePreferences/usePreferences";

/**
 * @description Display Confirmation page when a user what to open an external link.
 * @param {string} externalUrl - external link
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useConfirmExternalUrl = ({ externalUrl }) => {
  const { getPreference, setPreference } = usePreferences();

  const disableConfirmationUrlPage = getPreference && getPreference("interfaceOptions", "disableUrlConfirmationPage");

  const openUrl = () => {
    /**
     * Check if the URL has protocol in the beginning since the window.open treats it as an internal route if not exists
     */
    if (!isNullOrUndefined(externalUrl)) {
      externalUrl = externalUrl.match(/^http[s]?:\/\//) ? externalUrl : `https://${externalUrl}`;
      window.open(externalUrl);
    } else {
      alert("Something went wrong, missing the Url");
    }

    detailsModal.setModalIsOpen(false);
  };

  const detailsModal = useModal(
    "Confirmation Page",
    <div style={{ textAlign: "center" }}>
      You are about to open an external link, are you sure you want to proceed?
      <div style={{ marginTop: "1em" }}>
        <Button
          color={"success"}
          style={{ marginRight: "1em" }}
          onClick={() => {
            setPreference &&
              setPreference("interfaceOptions", {
                disableUrlConfirmationPage: true,
              });
            openUrl();
          }}
        >
          Yes, do not ask me again
        </Button>
        <Button color={"success"} style={{ marginRight: "1em" }} onClick={openUrl}>
          Yes
        </Button>
        <Button onClick={() => detailsModal?.setModalIsOpen(false)}>No</Button>
      </div>
    </div>,
    <Button color={"link"} title={externalUrl}>
      {shortenLongText({ text: externalUrl, length: 30 })}
    </Button>,
    {
      width: "50vw",
    },
  );

  const OpenExternalUrl = () => (
    <Button color={"link"} title={externalUrl} onClick={openUrl}>
      {shortenLongText({ text: externalUrl, length: 30 })}
    </Button>
  );

  const display = disableConfirmationUrlPage ? <OpenExternalUrl /> : detailsModal.modalButton;

  return {
    ...detailsModal,
    display,
  };
};
