import React, { useEffect, useState } from "react";

import { formattedPercent } from "@rivial-security/func-utils";

import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";

import NonStandardInput from "./NonStandardInput";

const ImplementationRatingBody = ({
  item,
  isAdmin,
  system,
  resetFunction,
  style = {},
  disableTitle = false,
  isNonStandard,
  override,
}) => {
  const [formattedAmount, setFormattedAmount] = useState(formattedPercent(item && item.implementationRating));

  useEffect(() => {
    setFormattedAmount(formattedPercent(item && item.implementationRating));
  }, [item]);

  return (
    <div style={style.div}>
      {!disableTitle && (
        <strong>
          Implementation Rating
          <i
            className="icon-question"
            title="A percentage from 0% - 100% reflecting how well this control is implemented"
            style={style.icon}
          />
        </strong>
      )}
      <div style={style.field}>
        {isNonStandard ? (
          <NonStandardInput
            system={system}
            riskControl={item}
            field="implementationRating"
            resetFunction={resetFunction}
            override={override}
          />
        ) : (
          formattedAmount
        )}
      </div>
    </div>
  );
};

const ImplementationRating = (props) => {
  return (
    <OrganizationCheck {...props}>
      <ImplementationRatingBody />
    </OrganizationCheck>
  );
};

export default ImplementationRating;
