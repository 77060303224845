"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCValue = void 0;
const getImpactCost_1 = require("./getImpactCost");
/**
 * Gets the Confidentiality Impact dollar amount for a System.
 * @param system
 * @param ratingScale
 * @param assetSize
 * @param volume
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCValue = (system) => {
    const field = "confidentialityPerRecord";
    const maxC = system ? (0, getImpactCost_1.getImpactCost)(system, field) : 0;
    return maxC;
};
exports.getCValue = getCValue;
