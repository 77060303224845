import {
  checkSystemForRiskControlOverride,
  findIndexOfRiskControlOverride,
  isRiskControlOverrideFieldEnabled,
} from "@rivial-security/risk-calc-utils";

export const getOutsourced = ({ system, riskControl }) => {
  const field = "outsourced";
  const indexOfRiskControlOverride = system && findIndexOfRiskControlOverride(system.riskControlOverrides, riskControl);

  const doesOverrideExist =
    system && checkSystemForRiskControlOverride(system && system.riskControlOverrides, field) !== false;

  const isOverrideEnabled =
    system &&
    indexOfRiskControlOverride !== -1 &&
    isRiskControlOverrideFieldEnabled(system.riskControlOverrides[indexOfRiskControlOverride], field);

  const overrideValue =
    doesOverrideExist &&
    system.riskControlOverrides[indexOfRiskControlOverride] &&
    system.riskControlOverrides[indexOfRiskControlOverride][field];

  let value = 0;

  if (isOverrideEnabled) {
    if (overrideValue) {
      value = overrideValue;
    } else {
      value = false;
    }
  } else {
    value = riskControl[field];
  }

  return value;
};
