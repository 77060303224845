import { generateGraphql } from "@rivial-security/generategraphql";

import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes an AuditControlLink from the database
 * @param {object} item - the object to delete, either the auditControlLink itself, or the parent
 * @param {string} item.auditControlLink - if following the data structure from useAuditControls, will be formatted this way
 * @returns {Promise<*>}
 */
export const deleteAuditControlLink = async (item) => {
  const typename = "AuditControlLink";
  const fields = ["__typename", "evidenceLinks"];
  const nestedFields = {
    evidenceLinks: `(limit: 500) { items { id ownerGroup __typename } }`,
  };
  const { getQuery } = generateGraphql(typename, fields, nestedFields);

  // First try and do it through the auditControlLink, assuming this is formatted from useAuditControlsData
  if (item?.auditControlLink?.id) {
    return await deleteItem(getQuery, item.auditControlLink);
  } else if (item?.id) {
    return await deleteItem(getQuery, item);
  }
};
