import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Custom field component for the vendor review status field
 * @param {object} item - all vendor review data
 * @param {string} status - the current status of the vendor review
 * @return {JSX.Element} - te component to display in the grid/detail views
 */
const VendorReviewStatus = ({ item, status }) => {
  return <span>{status ? convertCamelCaseToSentence(status) : "Legacy"}</span>;
};

export default VendorReviewStatus;
