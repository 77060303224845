/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  *  - 10/18/19 JB: Added better styling
 *
 * Description: Allows User to create a new Program Blueprint level to be associated with a particular Column.
 *
 */

import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose } from "react-apollo";
import { Button, Card, CardBody, CardFooter, Input, InputGroup, InputGroupAddon } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

const { listQuery: listMaturityMatrixs } = generateGraphql("MaturityMatrix");

const { createMutation: createMaturityMatrixLevel } = generateGraphql("MaturityMatrixLevel", [
  "name",
  "maturityMatrixLevelMaturityColumnId",
]);

const CreateMaturityLevel = (props) => {
  const [name, setName] = useState("");

  const submit = () => {
    props.createMaturityMatrixLevel({
      input: {
        name: name,
        ownerGroup: props.maturityColumn && props.maturityColumn.ownerGroup,
        maturityMatrixLevelMaturityColumnId: props.maturityColumn && props.maturityColumn.id,
      },
    });

    props.toggleModal && props.toggleModal();
  };

  return (
    <Card>
      <CardBody>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Maturity Level Name:</InputGroupAddon>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </InputGroup>
      </CardBody>
      <CardFooter>
        <Button color="success" onClick={() => submit()}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

CreateMaturityLevel.propTypes = {
  maturityColumn: PropTypes.object.isRequired,
  toggleModal: PropTypes.func,
};

export default compose(graphqlMutation(gql(createMaturityMatrixLevel), gql(listMaturityMatrixs), "components"))(
  CreateMaturityLevel,
);
