import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateExercise } from "../hooks/useCreateExercise";

/**
 * @description Wrapper component for useCreateExercise hook
 * @param {object} props
 * @returns {*}
 * @constructor
 */
const CreateExercise = (props) => {
  return useCreateExercise(props).display;
};

export default withOrganizationCheck(CreateExercise);
