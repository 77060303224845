import { isNullOrUndefined } from "@rivial-security/func-utils";
import { Control, ControlSet, Evidence } from "@rivial-security/schema-types";

import { ErrorLogger } from "@utils/EventLogger";

import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { getControlSet } from "../graphql/getControlSet";

const fetchControlSet = async (controlSetId: string): Promise<ControlSet | null> => {
  return QueryGetItem({
    query: getControlSet,
    itemId: controlSetId,
  });
};

const extractEvidencesFromControls = (controls: Control[]): Evidence[] => {
  return controls
    .flatMap((control) => {
      const controlEvidenceLinks = control?.evidences?.items ?? [];
      return controlEvidenceLinks
        .map((item) => item?.evidence)
        .filter((evidence): evidence is Evidence => !isNullOrUndefined(evidence));
    })
    .filter((evidence, index, self) => index === self.findIndex((e) => e?.id === evidence?.id));
};

/**
 * fetch evidences by control framework (control set)
 */
export const fetchEvidenceBy = async ({
  controlSetId,
  setIsLoading,
}: {
  controlSetId: string;
  setIsLoading?: (isLoading: boolean) => void;
}): Promise<Evidence[]> => {
  if (!controlSetId) return [];

  try {
    setIsLoading?.(true);
    const controlSet: ControlSet | null = await fetchControlSet(controlSetId);
    const controls: Control[] =
      controlSet?.controls?.items?.filter((control): control is Control => !isNullOrUndefined(control)) ?? [];

    return extractEvidencesFromControls(controls);
  } catch (error) {
    ErrorLogger("Error fetching evidences:", { error });
    return [];
  } finally {
    setIsLoading?.(false);
  }
};
