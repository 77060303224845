"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allPermitted = exports.allDenied = void 0;
exports.allDenied = {
    module: {
        isEnabled: false,
    },
    resourceAlone: {
        //No module check
        read: false,
        create: false,
        delete: false,
        update: false,
    },
    resource: {
        read: false,
        create: false,
        delete: false,
        update: false, //NOTE: this field is new
    },
    function: {
        isEnabled: false,
    },
    functionAlone: {
        isEnabled: false, //No module check
    },
    field: {
        read: false,
        update: false,
    },
    fieldAlone: {
        //No module check
        read: false,
        update: false,
    },
};
exports.allPermitted = {
    module: {
        isEnabled: true,
    },
    resourceAlone: {
        //No module check
        read: true,
        create: true,
        delete: true,
        update: true,
    },
    resource: {
        read: true,
        create: true,
        delete: true,
        update: true, //NOTE: this field is new
    },
    function: {
        isEnabled: true,
    },
    functionAlone: {
        isEnabled: true, //No module check
    },
    field: {
        read: true,
        update: true,
    },
    fieldAlone: {
        //No module check
        read: true,
        update: true,
    },
};
