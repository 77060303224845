import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import CreateNewRisk from "../components/AddRisksToOrganization/CreateNewRisk";
import KeyRiskIndicatorDetails from "../components/KeyRiskIndicatorDetails";
import { deleteRisk } from "../functions/deleteRisk";

const { updateMutation: updateRisk } = generateGraphql("Risk");

/**
 * Displays a list of Key Risk Indicators for an Organization's Risk Config
 * @param organizationID
 * @param gridConfig
 * @param queryConfig
 * @param cardConfig
 * @param module
 * @param resource
 * @param disableRoleChecking
 */
export const useKeyRiskIndicatorsDataGrid = ({
  organizationID,
  gridConfig = {},
  queryConfig = {},
  cardConfig = {},
  module = modules.RISK,
  resource = resources.KEY_RISK_INDICATOR,
  disableRoleChecking = false,
  ...props
}) => {
  const fieldNameDictionary = {
    id: "ID",
    name: "Name",
    classification: "Classification",
    description: "Description",
    annualRateOfOccurrence: "Annual Rate of Occurrence",
    annualRateOfOccurrenceMax: "ARO Max",
    confidenceIntervalLower: "Confidence Interval Lower",
    confidenceIntervalUpper: "Confidence Interval Upper",
    confidenceInterval: "Confidence Interval",
    costOfControls: "Cost of Controls",
    controlEffectiveness: "Control Effectiveness",
  };

  queryConfig = {
    query: realTimeRiskGQL.risksByOwnerGroup_systemAccordion,
    variables: { ownerGroup: organizationID },
    organizationID,
    ...queryConfig,
  };

  const fields = [
    {
      name: "name",
      description: "Unique Identifier for this Key Risk Indicator",
      width: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "description",
      description: "More details regarding of this Key Risk Indicator",
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "annualRateOfOccurrence",
      description: "The estimated annual chance of this KRI being realized",
      width: 150,
      type: "number",
      format: GENERIC_FIELD_TYPES.PERCENTAGE,
      bulkEdit: true,
    },
  ];

  gridConfig = {
    header: "Key Risk Indicators",
    fields,
    options: ["details", "delete", "edit"],
    deleteFunction: deleteRisk,
    deleteFunctionNote:
      "⚠ Warning! This will delete the associated Risk Control Category, and all Risk Controls associated with it. This is a destructive action and could cause data loss.",
    route: "#/risk/risk_config/risks/",
    fieldNameDictionary,
    detailsComponent: (
      <KeyRiskIndicatorDetails organizationID={organizationID} fieldNameDictionary={fieldNameDictionary} />
    ),
    createResourceComponent: <CreateNewRisk organizationID={organizationID} />,
    updateMutation: updateRisk,
    typename: "Key Risk Indicator",
    persistenceUUID: "risk-key-risk-indicator-grid",
    module,
    resource,
    disableRoleChecking,
    organizationID,
    ...gridConfig,
  };

  const riskQueryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    module,
    resource,
    title: "Key Risk Indicators",
    headerIcon: getIcon("ant-design:fire-outlined"),
    ...cardConfig,
    ...props,
  });

  return { ...riskQueryCard };
};
