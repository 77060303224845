import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateRiskRecommendation } from "../hooks/useCreateRiskRecommendation/useCreateRiskRecommendation";

/**
 * Uses the createRiskRecommendation form as an Edit component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditRiskRecommendation = (props) => {
  const updateRiskRecommendation = useCreateRiskRecommendation({
    ...props,
  });

  return updateRiskRecommendation.display;
};

export default withOrganizationCheck(EditRiskRecommendation);
