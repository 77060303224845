import { Alert, Button } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Component wrapper for checking objects are valid, shows alerts instead of UI when item or items don't pass conditions
 * @param [item] - provide the item to check if its valid
 * @param [items] - provide multiple items to check if all of them are valid
 * @param [isLoading=false] - if true, will show loading message instead of warning
 * @param [resource] - the resource type displayed in the alerts
 * @param [resources] - use when a type needs to be provided for all the items to check
 * @param [requiredFields] - if provided will check for each item to have the required fields
 * @param [enableGoBack=false] - if true will show a button to go back to the previous page if an item is null or empty
 * @param children - the components that is being wrapped, usually an item details page content
 * @returns {ReactElement} - the wrapped component or an alert if the item is null or empty
 */
const ItemCheckOverlay = ({
  item: singleItem,
  items = [],
  isLoading = false,
  resource,
  resources,
  requiredFields = [],
  enableGoBack = false,
  children,
}) => {
  const history = useHistory();
  const goBackFunction = history?.goBack;

  const getResourceName = ({ index, fallback = "resource" }) => {
    if (!isNullOrUndefined(index) && Array.isArray(resources) && index < resources.length && resources[index]) {
      return convertCamelCaseToSentence(resources[index]);
    } else if (resource) {
      return convertCamelCaseToSentence(resource);
    } else {
      return fallback || "resource";
    }
  };

  //Check arguments
  if (!Array.isArray(items)) {
    items = [];
  }
  if (singleItem) {
    items.push(singleItem);
  }

  const [viewIncomplete, setViewIncomplete] = useState(false);

  // If item is still loading it is not necessary to check if it is incomplete or missing
  if (isLoading) {
    return <Alert severity={"info"}>Loading Details...</Alert>;
  }

  let count = 0;
  for (const item of items) {
    if (isNullOrUndefined(item) || typeof item !== "object" || Object.keys(item).length === 0) {
      return (
        <Alert
          severity={"error"}
          action={
            enableGoBack &&
            goBackFunction && (
              <Button
                color="inherit"
                size="small"
                style={{ minWidth: "6em" }}
                onClick={() => {
                  goBackFunction();
                }}
              >
                GO BACK
              </Button>
            )
          }
        >
          {`The requested ${getResourceName({
            index: count,
          })} could not be found. It is possible the item got deleted.`}
        </Alert>
      );
    }
    count++;
  }

  // Check if the item has the required fields
  if (!viewIncomplete && Array.isArray(requiredFields) && requiredFields.length > 0) {
    for (const requiredField of requiredFields) {
      let count = 0;
      for (const item of items) {
        if (typeof item[requiredField] === "undefined") {
          return (
            <Alert
              severity={"warning"}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setViewIncomplete(true);
                  }}
                >
                  CONTINUE
                </Button>
              }
            >
              {`The ${getResourceName({
                index: count,
                fallback: "item",
              })} you are trying to view is missing some data. This may cause issues.`}
            </Alert>
          );
        }
        count++;
      }
    }
  }

  return children;
};

export default ItemCheckOverlay;
