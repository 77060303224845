import React, { useEffect } from "react";

import ErrorBoundary from "../../_errorBoundaries";
import { DisabledWrapper } from "../../utils/GenericComponents/DisabledWrapper";
import { useStateEffect } from "../functional/useStateEffect";

import { useProgressTracker } from "./useProgressTracker";

/**
 * @typedef {object} Workflow
 *
 * @property {JSX.Element} display - the current WorkflowStep UI
 * @property {ProgressTracker} progressTracker - the ProgressTracker hook
 * @property {WorkflowStep[]} steps - the Workflow Steps
 * @property {function} setSteps - setter function to manually adjust workflow steps
 * @property {object} context - the context object used to share state across workflow steps
 */

/**
 * @typedef {object} WorkflowStep
 *
 * @property {string} id - a unique ID for this step
 * @property {string} text - the text that is displayed for this step
 * @property {WorkflowStepStatus} [status = "Not Started"] - the current status of this step
 * @property {function} [onClick] - an onClick function for clicking on this step
 * @property {string} [tooltip] - an optional tooltip
 * @property {boolean} [disabled = false] - if status changes for this step or disabled or enabled
 * @property {JSX.Element} [display] - an element to display as the current step
 */

/**
 * @typedef {string} WorkflowStepStatus
 * options: Not Started, In Progress, Complete
 */

/**
 * UI for a workflow. A workflow is a set of steps that the user can progress through.
 * The User may switch between steps, and the progress of each step can be tracked.
 *
 * Each step contains a display UI, and may contain stateful information.
 * Stateful information can then be shared throughout the workflow by using React Context
 *
 * @param {string} itemId="" - the resource item identifier that hosts the Workflow information
 * @param {object} progressGQL={} - object with mutation and query fot the steps of the workflow
 * @param {JSX.Element} [displayPrefix] - component to display opn top of each step GUI
 * @param {function} [isReadOnly] - function that can be used to tell if the UI should be disabled (grey and non-clickable)
 * @param {function} [isInteractable] - function that can tell if the step ui is clickable (context is passed ion)
 * only comes into effect if `isReadOnly` is defined and returns true
 * @param {WorkflowStep[]} initialSteps=[] - the Workflow steps to init this workflow with
 * @param {object} initialContext={} - the beginning context values to provide access to all steps in the workflow
 * @param {number} initialStepIndex=0 - the step to start with in the workflow
 * @param {number} [resetIndex=0] - a reset index that is passed down to every component in the workflow
 * @return {{progressTracker: ProgressTracker, setSteps: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element, context: *, steps: *, addToContext: addToContext}}
 */
export const useWorkflow = ({
  itemId = "",
  progressGQL = {},
  displayPrefix,
  isReadOnly,
  isInteractable,
  steps: initialSteps = [],
  context: initialContext = {},
  initialStepIndex = 0,
  resetIndex,
}) => {
  /**
   * The workflow steps
   */
  const [steps, setSteps] = useStateEffect(initialSteps);

  /**
   * When the initial steps change find the changed steps and update the displayed steps stored in state
   */
  useEffect(() => {
    const existingSteps = steps.map((step) => step?.id);
    const newSteps = initialSteps.map((step) => step?.id);

    const stepsToRemove = [];
    const stepsToAdd = [];

    initialSteps.forEach((step) => {
      if (!existingSteps.includes(step?.id)) {
        stepsToAdd.push(step);
      }
    });

    steps.forEach((step) => {
      if (!newSteps.includes(step?.id)) {
        stepsToRemove.push(step);
      }
    });

    //Remove steps that are no longer in the workflow
    const finalSteps = steps;
    for (const stepToRemove of stepsToRemove) {
      finalSteps.splice(finalSteps.indexOf(stepToRemove?.id), 1);
    }

    //Add new steps that are in the workflow
    for (const step of stepsToAdd) {
      finalSteps.push(step);
    }

    setSteps(finalSteps);
  }, [JSON.stringify(initialSteps?.map((step) => step?.id) || {})]);

  /**
   * Adds a key: value pair to the context object
   * @param key
   * @param val
   */
  const addToContext = (key, val) => {
    if (key !== null && val !== null) {
      setContext((context) => {
        return { ...context, [key]: val };
      });
    }
  };

  /**
   * Initialize the progressTracker to sync with this workflow
   */
  useEffect(() => {
    if (steps && Array.isArray(steps)) {
      progressTracker.setSteps(steps);
    }
  }, [steps]);

  /**
   * The Context object that is passed to each component in the workflow
   */
  const [context, setContext] = useStateEffect({});

  /**
   * Progress tracker instance.
   * @type {ProgressTracker}
   */
  const progressTracker = useProgressTracker({
    itemId,
    progressGQL,
    steps,
    initialStepIndex: initialStepIndex,
    addToContext,
    context,
    isReadOnly,
  });

  /**
   * Initialize the Context object based on the workflow steps
   */
  useEffect(() => {
    if (steps && Array.isArray(steps)) {
      let tempContext = { ...initialContext, addToContext };
      tempContext.triggerProgressUpdate = progressTracker?.triggerProgressUpdate;
      tempContext.setCurrentStepById = progressTracker?.setCurrentStepById;
      tempContext.currentStep = progressTracker?.currentStep;
      for (const step of steps) {
        if (step.context) {
          tempContext = { ...tempContext, ...step.context };
        }
      }
      setContext({ ...tempContext });
    }
  }, [steps]);

  /**
   *  Displays the current workflow step
   */
  const display = (
    <ErrorBoundary>
      <WorkflowContext.Provider value={{ ...context }}>
        {progressTracker.currentStep ? (
          <div style={{ height: "100%" }}>
            {displayPrefix}
            <DisabledWrapper
              isDisabled={typeof isReadOnly === "function" && isReadOnly({ context })}
              isInteractable={typeof isInteractable === "function" && isInteractable({ context })}
            >
              {React.cloneElement(progressTracker?.currentStep?.display || <div />, { resetIndex })}
            </DisabledWrapper>
          </div>
        ) : (
          <div>Loading Step</div>
        )}
      </WorkflowContext.Provider>
    </ErrorBoundary>
  );

  return {
    display,
    progressTracker,
    steps,
    setSteps,
    context,
    addToContext,
  };
};

export const WorkflowContext = React.createContext({});
