import { generateGraphql } from "@rivial-security/generategraphql";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Queries all "Admin" Evidences and creates a Map() of the evidence.name: evidence.id
 * This is used when duplicating Evidence from CSV to find already existing evidence.
 * To be Used with the DuplicateControl function.
 * @author Jacob Blazina
 * @async
 * @function
 * @returns {Promise<Map<unknown, unknown>>}
 * @constructor
 */
export const MapEvidenceNamesToIds = async (organizationID) => {
  const dictionary = new Map();

  const { listQuery } = generateGraphql("Evidence", ["name"]);

  const evidences = await ListQueryBy({
    query: listQuery,
    variables: {
      ownerGroup: organizationID,
    },
  });

  for (const evidence of evidences) {
    dictionary.set(evidence.name, evidence.id);
  }

  return dictionary;
};
