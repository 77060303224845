import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import { Inject } from "@syncfusion/ej2-react-grids";
import React from "react";
import { v4 as uuid } from "uuid";

import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * @description Display chart for testing module
 * @param {object[]} data - data for the chart
 * @param {boolean} disableAnimations - disable animation
 * @returns {JSX.Element}
 * @constructor
 */
const TestingTotalsChart = ({ data = [], disableAnimations = false }) => {
  return (
    <>
      {data ? (
        <AccumulationChartComponent
          id={`testing-pie-chart-${uuid()}`}
          legendSettings={{
            visible: true,
            position: "Top",
          }}
          enableSmartLabels={true}
          tooltip={{ enable: true }}
          enableAnimation={true}
          enableBorderOnMouseMove={true}
          highlightPattern="pacman"
        >
          <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              name="Project"
              dataSource={data}
              xName="x"
              yName="y"
              innerRadius="40%"
              startAngle={0}
              endAngle={360}
              radius="70%"
              explode={true}
              explodeOffset="10%"
              explodeIndex={3}
              pointColorMapping="fill"
              dataLabel={{
                visible: true,
                name: "text",
                position: "Inside",
                font: {
                  fontWeight: "600",
                  color: "#000000",
                },
              }}
              animation={{ enable: !disableAnimations }}
            ></AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      ) : (
        <LoadingSpinner loadingMessage="Loading Data.. " />
      )}
    </>
  );
};

export default TestingTotalsChart;
