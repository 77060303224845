import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useMetricTypeDataGrid } from "../hooks/useMetricTypeDataGrid";

/**
 * Components instance of the useMetricTypeDataGrid hook
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MetricTypeDataGrid = (props) => {
  return useMetricTypeDataGrid(props).display;
};

export default withOrganizationCheck(MetricTypeDataGrid);
