import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import { useToolDetails } from "../../Tools/Tool/hooks/useToolDetails";

/**
 * @description Component to display details for a single Tool Template
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const ToolTemplateDetails = (props) => useToolDetails(props).display;

export default withDetailsPage(ToolTemplateDetails);
