import LinearGaugeChart from "../../../../../utils/GenericComponents/Charts/LinearGaugeChart";
import { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Ransomware Index component
 */
const BlackKiteRansomwareIndex = ({ RansomwareIndex }: BlackKiteResponse): JSX.Element => (
  <LinearGaugeChart
    value={RansomwareIndex}
    title={"Ransomware Index"}
    subTitle={`Ransomware Susceptibility Index (RSI): ${RansomwareIndex}`}
  />
);

export default BlackKiteRansomwareIndex;
