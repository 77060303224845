/**
 * @description Get error message on S3 failure
 * @param {object} error - error object from S3
 * @returns {string}
 */
export const onS3UserErrorMessage = ({ error }) => {
  if (error?.message === "Network Failure") {
    return `Network Failure, please try again`;
  } else {
    return `Failed to upload Document, please try again`;
  }
};
