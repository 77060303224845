import { useState } from "react";
import { CustomInput as ReactstrapCustomInput } from "reactstrap";
import { v4 as uuid } from "uuid";

/**
 * Can be used as a true/false style input in a custom form
 * @param {Object} props - The properties for the CustomSwitchInput
 * @param {(value: boolean) => void} props.onChange - Function to call when the value changes
 * @param {boolean} [props.value=false] - the value of the input
 * @param {boolean} props.disabled - whether the input is disabled
 * @param {Object} [props.switchConfig={}] - configuration for the switch
 * @param {Object} [props.style={}] - style for the input
 * @returns {JSX.Element}
 */
const CustomSwitchInput = ({ onChange, value = false, disabled, switchConfig = {}, style = {} }) => {
  const { label } = switchConfig;
  const [id] = useState(uuid());

  return (
    <div style={{ ...style }}>
      <ReactstrapCustomInput
        id={id}
        checked={value}
        disabled={disabled}
        onChange={() => onChange(!value)}
        type="switch"
        name={`customSwitch${id}`}
        label={label}
      />
    </div>
  );
};

export default CustomSwitchInput;
