"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTEXT_TYPE = void 0;
/**
 * The location in which the widget it being rendered.
 */
exports.CONTEXT_TYPE = {
    EMAIL: "email",
    WEB: "web",
    REPORT: "report",
    ALL: "all",
};
