export const updateNotes = /* GraphQL */ `
  mutation UpdateRiskControl($input: UpdateRiskControlInput!) {
    updateRiskControl(input: $input) {
      id
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;
