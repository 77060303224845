import { useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useSystemDataGrid } from "../../../../Risk/Systems/hooks/useSystemDataGrid";
import { getResponsePlan } from "../../../Plan/functions/getResponsePlan";

import { listResponsePlansSystems } from "./queries/listResponsePlansSystems";

/**
 * Grid for displaying all systems linked to an organization's response plan
 * @param {string} organizationID - the current organization database identifier (id property)
 * @param {object} [gridConfig] - custom grid settings to inject
 * @return {{selectedData: [], isLoading: *, gridDisplay: *, setIsLoading: (function(((function(*): *)|*)): void), data: *, havePermission, setData: (function(((function(*): *)|*)): void), resetFunction: (function(): void), display: *, selectedIDs: []}}
 */
export const useResponseSystemsDataGrid = ({ organizationID, gridConfig }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_PLAN;
  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const queryConfig = {
    query: null,
  };

  const systemsGrid = useSystemDataGrid({
    organizationID,
    gridConfig,
    queryConfig,
  });

  //Query for current response plan systems
  useEffect(() => {
    systemsGrid?.setIsLoading(true);
    const getResponseSystems = async () => {
      const responsePlan = await getResponsePlan(organizationID, listResponsePlansSystems);

      const systems = [];
      if (Array.isArray(responsePlan?.systems?.items)) {
        const responseSystemLinks = responsePlan.systems.items;
        for (const responseSystemLink of responseSystemLinks) {
          if (responseSystemLink?.system) {
            responseSystemLink.system.responseLinkID = responseSystemLink?.id;
            systems.push(responseSystemLink?.system);
          }
        }
      }

      systemsGrid?.setData(systems ?? []);
      systemsGrid?.setIsLoading(false);
    };
    getResponseSystems();
  }, []);

  return {
    ...systemsGrid,
    havePermission: checkPermissionsHook.resource.read,
  };
};
