"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveMonteCarloSystemInputObject = void 0;
const __1 = require("../..");
const calculateRiskMitigated_1 = require("../../monteCarloCalculation/functions/calculateRiskMitigated");
const formatLossToleranceCurve_1 = require("./formatLossToleranceCurve");
const putS3Object_1 = require("./putS3Object");
const updateMonteCarloSystemData_1 = require("./updateMonteCarloSystemData");
const updateMonteCarloSystemRiskLinkData_1 = require("./updateMonteCarloSystemRiskLinkData");
/**
 * Saves a MonteCarloSystemInput object with reusableRandoms to S3,
 * and updates the System item in the DB to point to the key and new calculation
 * results. In some cases grabs the recommendation results for the mutation.
 * @param system - system information including an id property
 * @param monteCarloSystemInput - the inputs used for the calculation function
 * @param organizationID - the organization that the system is a part of
 * @param s3BucketName - common s3 bucket for storing monte carlo information for the org
 * @param env - environment details for mutations inside lambdas
 * @param calculation - final monte-carlo calculation result
 * @param calculationContext - the source of calculation request
 * @param reusableRandoms - the random keys used during simulations, saved to recreate the same results
 * @param {[recommendation] - the recommendation input into the calculation function
 * @returns {Promise<*>}
 */
const saveMonteCarloSystemInputObject = async ({ system, monteCarloSystemInput, organizationID, s3BucketName, env, calculation, calculationContext, reusableRandoms, recommendation, }) => {
    if (!system) {
        console.log("[saveMonteCarloSystemInputObject] Error saving system to s3. Invalid system input");
        return;
    }
    console.log("[saveMonteCarloSystemInputObject] Saving Monte Carlo Calculation Inputs and Results..", {
        systemStats: calculation?.systemStats,
        systemRiskValues: calculation?.systemRiskValues,
        riskStats: calculation?.riskStats,
        systemID: calculation?.systemID,
        riskRatings: calculation?.riskRatings,
        lossToleranceCurve: calculation?.lossToleranceCurve,
    });
    const objectKey = `monteCarloInputs/${system.id}/${new Date().toISOString()}.json`;
    try {
        console.log("[saveMonteCarloSystemInputObject] Getting System Risk Results...");
        let riskScore;
        let inherentRisk;
        let residualRisk;
        let riskMitigated;
        let riskRating;
        let inherentRiskRating;
        let residualRiskRating;
        let greatestKeyRiskIndicator;
        let greatestEnterpriseRisk;
        let greatestBusinessRisk;
        let lossToleranceDelta;
        let costOfControls;
        try {
            console.log("[saveMonteCarloSystemInputObject] Fetching Risk Score..");
            const lossToleranceCurve = (0, formatLossToleranceCurve_1.formatLossToleranceCurve)(calculation.lossToleranceCurve);
            riskScore = await (0, __1.getRiskScore)({
                system,
                organizationID,
                monteCarloResults: calculation,
                riskMapping: monteCarloSystemInput?.riskConfig?.riskMapping,
                ratingScale: monteCarloSystemInput?.ratingScale,
            });
            console.log("[saveMonteCarloSystemInputObject] Got Risk Score: ", riskScore);
            inherentRisk = riskScore?.greatestEnterpriseRisk?.inherentRisk;
            residualRisk = riskScore?.greatestEnterpriseRisk?.residualRisk;
            riskMitigated = (0, calculateRiskMitigated_1.calculateRiskMitigated)({
                inherentRisk,
                residualRisk,
            });
            riskRating = riskScore?.greatestEnterpriseRisk?.riskRating;
            greatestKeyRiskIndicator =
                riskScore?.greatestEnterpriseRisk?.greatestBusinessRisk?.greatestKeyRiskIndicator?.name;
            greatestBusinessRisk = riskScore?.greatestEnterpriseRisk?.greatestBusinessRisk?.name;
            greatestEnterpriseRisk = riskScore?.greatestEnterpriseRisk?.name;
            inherentRiskRating = (0, __1.getLossCurveRating)({
                ratingScale: monteCarloSystemInput?.ratingScale,
                lossToleranceCurve,
                riskAmount: inherentRisk,
            });
            residualRiskRating = (0, __1.getLossCurveRating)({
                ratingScale: monteCarloSystemInput?.ratingScale,
                lossToleranceCurve,
                riskAmount: residualRisk,
            });
            costOfControls = calculation.systemStats.currentCostOfControls;
            // if calculations were run for the risk change lambda save the recommendation values instead
            if (calculationContext === __1.CALCULATION_CONTEXT.RISK_CHANGE) {
                console.log("[saveMonteCarloSystemInputObject] Calculations were run for the risk change lambda. Saving recommendation values instead..");
                const systemRiskValues = (0, __1.getSystemKeyRiskIndicatorStats)({
                    calculation,
                    modifiedRiskControlID: recommendation?.riskControl?.id,
                });
                inherentRisk = systemRiskValues?.recommendationInherentRisk;
                residualRisk = systemRiskValues?.recommendationResidualRisk;
                greatestKeyRiskIndicator = systemRiskValues?.greatestRecommendationKeyRiskIndicator;
            }
            console.log("[saveMonteCarloSystemInputObject] Risk Values After Risk Score - ", JSON.stringify({ inherentRisk, residualRisk, greatestKeyRiskIndicator }));
        }
        catch (e) {
            console.error("[saveMonteCarloSystemInputObject] Error: Could not get risk score. ", e);
        }
        try {
            const greatestRiskCurves = (0, __1.getGreatestRiskCurves)({
                riskScore,
                monteCarloResults: calculation,
            });
            // @ts-expect-error Fix me, needs better typing
            lossToleranceDelta = (0, __1.findLossToleranceExceedanceAtResidualLoss)({
                // @ts-expect-error Fix me, needs better typing
                lossToleranceCurve: (0, formatLossToleranceCurve_1.formatLossToleranceCurve)(calculation.lossToleranceCurve),
                // @ts-expect-error Fix me, needs better typing
                residualLossCurve: greatestRiskCurves?.residualLossCurve,
                residualRisk,
            }).lossToleranceDelta;
        }
        catch (e) {
            console.log("[saveMonteCarloSystemInputObject] Error: Could not find loss tolerance delta.", e);
        }
        const rawJson = {
            ...monteCarloSystemInput,
            createdAt: new Date().toISOString(),
            monteCarloResults: { ...calculation },
            riskScore,
            inherentRisk,
            residualRisk,
            riskMitigated,
            riskRating,
            costOfControls,
            inherentRiskRating,
            residualRiskRating,
            lossToleranceDelta,
            greatestKeyRiskIndicator,
            greatestBusinessRisk,
            greatestEnterpriseRisk,
            reusableRandoms,
        };
        console.log("[saveMonteCarloSystemInputObject] Putting Object in S3 bucket: ", objectKey);
        await (0, putS3Object_1.putS3Object)({
            bucketName: s3BucketName,
            organizationID,
            objectKey,
            file: JSON.stringify({ ...rawJson }),
            appsyncEndpoint: process.env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
        });
        console.log("[saveMonteCarloSystemInputObject] Updating System fields...", {
            monteCarloInputS3ObjectKey: objectKey,
            inherentRisk,
            residualRisk,
            riskMitigated,
            riskRating,
            lossToleranceDelta,
            greatestKeyRiskIndicator,
            greatestBusinessRisk,
            greatestEnterpriseRisk,
        });
        const updateSystemDataResult = await (0, updateMonteCarloSystemData_1.updateMonteCarloSystemData)({
            env,
            input: {
                id: system.id,
                monteCarloInputS3ObjectKey: objectKey,
                inherentRisk,
                residualRisk,
                inherentRiskRating,
                residualRiskRating,
                riskRating,
                riskMitigated,
                lossToleranceDelta,
                greatestKeyRiskIndicator,
                greatestBusinessRisk,
                greatestEnterpriseRisk,
                costOfControls,
            },
        });
        // updates systemRiskLinks with current risk data
        await (0, updateMonteCarloSystemRiskLinkData_1.updateMonteCarloSystemRiskLinkData)({
            env,
            riskStats: calculation.riskStats,
        });
        console.log("[saveMonteCarloSystemInputObject] Successfully updated System fields: ", updateSystemDataResult);
        return {
            monteCarloCalculationResults: rawJson,
        };
    }
    catch (e) {
        console.log("[saveMonteCarloSystemInputObject] Error Saving Monte Carlo Calculation results: ", e);
        throw new Error("[saveMonteCarloSystemInputObject] Error Saving Monte Carlo Calculation results");
    }
};
exports.saveMonteCarloSystemInputObject = saveMonteCarloSystemInputObject;
