import React, { useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import ThreatMitreAttackTechniqueDetails from "../../../Tools/MitreAttack/Techniques/customFields/ThreatMitreAttackTechniqueDetails";
import { getTechniqueById } from "../../../Tools/MitreAttack/Techniques/functions/getTechniqueById";

import ThreatTechniqueDataSources from "./ThreatTechniqueDataSources";
import ThreatTechniqueMitigations from "./ThreatTechniqueMitigations";
import ThreatTechniqueSubTechniques from "./ThreatTechniqueSubTechniques";

/**
 * @description A Component to display a Threat's Mitre Attack Technique.
 * @param {object} item - Threat item
 * @param {string} organizationID - selected organization ID
 * @param {function} resetFunction - reset function
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatTechnique = ({ item, organizationID, resetFunction }) => {
  const module = modules.TOOLS;
  const resource = resources.MITRE_ATTACK_TECHNIQUE;

  const [technique, setTechnique] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Get technique by ID
   */
  useEffect(() => {
    if (item?.techniqueID) {
      setIsLoading(true);
      getTechniqueById({
        id: item?.techniqueID,
      })
        .then((data) => {
          setTechnique({ ...data });
        })
        .finally(() => setIsLoading(false));
    }
  }, [item]);

  const accordion = useAccordion({
    item,
    items: [
      {
        title: "Sub-Techniques",
        subTitle: "Mitre Att&ck Sub-Techniques",
        component: <ThreatTechniqueSubTechniques technique={technique} />,
        icon: "icon-layers",
      },
      {
        title: "Mitigations",
        subTitle: "Mitre Att&ck Mitigations",
        component: <ThreatTechniqueMitigations technique={technique} />,
        icon: "icon-wrench",
      },
      {
        title: "Data Sources",
        subTitle: "Mitre Att&ck Data Sources",
        component: <ThreatTechniqueDataSources technique={technique} />,
        icon: "icon-magnifier",
      },
    ],
  });

  useEffect(() => {
    details?.setItem({ ...technique });
  }, [technique]);

  const TechniqueName = () => {
    return (
      <ThreatMitreAttackTechniqueDetails
        threat={item}
        technique={technique}
        organizationID={organizationID}
        resetFunction={resetFunction}
      />
    );
  };

  const TechniqueSubTechnique = ({ item }) => {
    return <div>{item?.stix?.x_mitre_is_subtechnique ? "Yes" : "No"}</div>;
  };

  const TechniqueDescription = ({ item }) => {
    return <div>{item?.stix?.description}</div>;
  };

  const detailsConfig = {
    fields: ["name", "subTechnique", "description"],
    customFields: [
      {
        field: "name",
        component: <TechniqueName />,
        isDynamicCustomField: true,
      },
      {
        field: "subTechnique",
        component: <TechniqueSubTechnique />,
        isDynamicCustomField: true,
      },
      {
        field: "description",
        component: <TechniqueDescription />,
        isDynamicCustomField: true,
      },
    ],
  };

  const details = useDetailsCard({
    detailsConfig,
    tableDisplay: true,
    resource,
    module,
  });

  return (
    <div>
      {isLoading ? (
        <DataLoader isLoading={isLoading} isEnoughData={technique} />
      ) : (
        <div>
          {!technique ? (
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "0.5em" }}>Link a Mitre Technique:</span>
              <TechniqueName />
            </div>
          ) : (
            <div>
              {details?.display}
              {accordion.display}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withOrganizationCheck(ThreatTechnique);
