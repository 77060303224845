"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSecretValue = void 0;
const client_secrets_manager_1 = require("@aws-sdk/client-secrets-manager");
/**
 * @description Retrieves the contents of the encrypted fields SecretString or SecretBinary from the specified version of a secret, whichever contains content.
 * @param {string} secretId - the ARN or name of the secret to retrieve.
 * @param {string} versionId - the unique identifier of the version of the secret to retrieve.
 * If you include both this parameter and VersionStage, the two parameters must refer to the same secret version.
 * If you don't specify either a VersionStage or VersionId, then Secrets Manager returns the AWSCURRENT version.
 * @param {string} versionStage - the staging label of the version of the secret to retrieve.
 * @returns {Promise<SecretsManager.GetSecretValueResponse & {$response: Response<SecretsManager.GetSecretValueResponse, Error & {code: string, message: string, retryable?: boolean, statusCode?: number, time: Date, hostname?: string, region?: string, retryDelay?: number, requestId?: string, extendedRequestId?: string, cfId?: string, originalError?: Error}>}>}
 */
const getSecretValue = async ({ secretId, versionId, versionStage }) => {
    if (!secretId)
        throw Error("Missing secretId");
    const secretsManager = new client_secrets_manager_1.SecretsManagerClient({ apiVersion: "2017-10-17" });
    const params = {
        SecretId: secretId,
        VersionId: versionId,
        VersionStage: versionStage,
    };
    const command = new client_secrets_manager_1.GetSecretValueCommand(params);
    return await secretsManager.send(command).catch((err) => {
        throw Error(`Could not get secret: ${err}`);
    });
};
exports.getSecretValue = getSecretValue;
