import Tooltip from "@mui/material/Tooltip";
import React from "react";

/**
 * Displays an icon and tooltip explaining that this point of contact currently is on Hold for evidence gathering
 * @param pointOfContact
 * @returns {JSX.Element}
 * @constructor
 */
const OnHoldStanding = ({ pointOfContact = {} }) => {
  return (
    <Tooltip
      arrow
      interactive
      title={
        <div style={{ fontSize: "1.2em", textAlign: "center" }}>
          {pointOfContact?.firstName}'s evidence gathering is currently on Hold
        </div>
      }
    >
      <i className="icon-minus" />
    </Tooltip>
  );
};

export default OnHoldStanding;
