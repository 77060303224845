"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateReport = void 0;
const generateAbstractLists_1 = require("./generateAbstractLists");
const generateLists_1 = require("./generateLists");
const generateStyles_1 = require("./generateStyles");
/**
 * Creates SFDT document data for a report including all list settings, content and default styling
 * @param sections
 * @param [listTypes]
 * @return {Promise<{defaultTabWidth: number, paragraphFormat: {afterSpacing: number, bidi: boolean, rightIndent: number, lineSpacingType: string, leftIndent: number, textAlignment: string, listFormat: {}, beforeSpacing: number, lineSpacing: number, firstLineIndent: number}, abstractLists: *[], protectionType: string, comments: [], characterFormat: {highlightColor: string, fontFamily: string, underline: string, fontFamilyBidi: string, fontSize: number, bold: boolean, strikethrough: string, fontSizeBidi: number, italic: boolean, fontColor: string, baselineAlignment: string}, enforcement: boolean, sections: *[], hashValue: string, lists: ({listId: number, levelOverrides: [], abstractListId: number})[], saltValue: string, dontUseHTMLParagraphAutoSpacing: boolean, styles: *[], formatting: boolean}>}
 */
const generateReport = async (sections, listTypes) => {
    return {
        sections: [...sections],
        characterFormat: {
            bold: false,
            italic: false,
            fontSize: 11,
            fontFamily: "Calibri",
            underline: "None",
            strikethrough: "None",
            baselineAlignment: "Normal",
            highlightColor: "NoColor",
            fontColor: "#000000",
            fontSizeBidi: 11,
            fontFamilyBidi: "Calibri",
        },
        paragraphFormat: {
            leftIndent: 0,
            rightIndent: 0,
            firstLineIndent: 0,
            textAlignment: "Left",
            beforeSpacing: 0,
            afterSpacing: 0,
            lineSpacing: 1,
            lineSpacingType: "Multiple",
            listFormat: {},
            bidi: false,
        },
        defaultTabWidth: 36,
        enforcement: false,
        hashValue: "",
        saltValue: "",
        formatting: false,
        protectionType: "NoProtection",
        compatibilityMode: "Word2013",
        dontUseHTMLParagraphAutoSpacing: false,
        styles: [...(0, generateStyles_1.generateStyles)()],
        lists: [...(0, generateLists_1.generateLists)()],
        abstractLists: [...(0, generateAbstractLists_1.generateAbstractLists)(listTypes)],
        comments: [],
    };
};
exports.generateReport = generateReport;
