/**
 * Different states of completion for a TASK resource
 * @type {{IN_PROGRESS: string, TODO: string, DONE: string, PAST_DUE: string}}
 */
export const TaskStatus = {
  TODO: "todo",
  IN_PROGRESS: "inProgress",
  PAST_DUE: "pastDue",
  DONE: "done",
};
