import { useStateEffect } from "./useStateEffect";

/**
 * @description Syntactic Sugar for the useStateEffect hook.
 *
 * Creates a state variable that is synced to a JS variable.
 * Useful when a state variable needs to be updated based on
 * a props change, but you want to manage that state variable independently of props.
 *
 * @param {*} variable - the variable to stay in sync with. Any changes to this variable are reflected in this state.
 * @param {function} [callback] - a function that can update the variable input before it's saved in state.
 * @example
 * const updater = (number) => return (number * 100).toFloat(2)
 * const [sync] = useSync(props.number, updater)
 * @example
 * const [test] = useSync(item.isCompliant, (isCompliant) => {
    if (isCompliant === "true") {
      return "BLAH BLAH BLAH"
    }
    else {
      return "RA RA RA"
    }
  });
 */
export const useSync = (variable, callback) => {
  const stateEffect = useStateEffect(variable, [variable], (variable) => {
    return callback && typeof callback === "function" ? callback(variable) : variable;
  });

  return [...stateEffect];
};
