import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../../hooks/views/useForm";

import { useUploadSystems } from "./useUploadSystems";

const { createMutation: createSystem } = generateGraphql(
  "System",
  ["name", "description", "pointOfContact", "hosting", "availability"],
  { pointOfContact: `{ firstName lastName }` },
);

/**
 * Form hook used to create a new Information System
 *
 * Also includes:
 * - Templates Button
 * - Import Button
 *
 * @param {string} organizationID - ID of the organization to create the system in
 * @param {function} toggleModal - function to toggle the external modal
 * @param {function} resetFunction - function to reset the external data after creation
 * @param {object} formHookConfig - configuration passed directly to the useForm hook
 * @param {function} getNewItem - callback function that returns the new item after creation
 * @param {string} module - module to check permissions for
 * @param {string} resource - resource to check permissions for
 * @param {boolean} disableRoleChecking - disables role checking
 * @param {boolean} disableTemplates - disables templates button
 * @param {boolean} disableImporter - disables importer button
 * @returns {{display: JSX.Element}}
 */
export const useCreateSystem = ({
  organizationID,
  toggleModal,
  resetFunction,
  formHookConfig,
  getNewItem,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  disableTemplates = false,
  disableImporter = false,
}) => {
  const uploadSystemsHook = useUploadSystems({
    organizationID,
    toggleModal,
    resetFunction,
  });

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const formHook = useForm({
    organizationID,
    getNewItem,
    typename: "Information System",
    module,
    resource,
    disableRoleChecking,
    resetFunction,
    toggleModal,
    mutation: createSystem,
    fieldConfig: {
      name: {
        required: true,
        inputType: "text",
        label: "Name",
      },
      description: {
        inputType: "textarea",
        label: "Description",
      },
      hosting: {
        inputType: "dropdown",
        label: "Hosting",
        tooltip: "Determines if this System is hosted internally, by a third party, or a combination of both.",
        dropdownConfig: {
          data: [
            { value: "internal", text: "Internal" },
            { value: "outsourced", text: "Outsourced" },
            { value: "hybrid", text: "Hybrid" },
          ],
        },
        defaultValue: "internal",
      },
      availability: {
        inputType: "dropdown",
        label: "Availability",
        tooltip: "Determines the impact of this System going down for a period of time.",
        dropdownConfig: {
          data: [
            { value: "low", text: "Low" },
            { value: "lowMedium", text: "Low-Medium" },
            { value: "medium", text: "Medium" },
            { value: "mediumHigh", text: "Medium-High" },
            { value: "high", text: "High" },
          ],
        },
        defaultValue: "low",
      },
    },
    enableTemplates: !disableTemplates && checkPermissionsHook.resource.create,
    ...formHookConfig,
  });

  const display = (
    <>
      {!disableImporter && isNullOrUndefined(formHookConfig?.item) && (
        <div className="float-right">{uploadSystemsHook.modalButton}</div>
      )}
      {formHook.display}
    </>
  );

  return {
    display,
    checkPermissionsHook,
  };
};
