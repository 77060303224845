"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleListQuery = void 0;
/**
 * Handles the algorithm for retrieving the full list of appsync data.
 * Utilizes the queryFunction parameter to perform a series of nextToken queries.
 * @param queryFunction - the Appsync "graphql" function. Either from API.graphql, or appsyncClient.graphql
 * @param organizationID - maps with the "ownerGroup" field
 * @param query - The raw graphql query string (this function has graphql-tag built in)
 * @param [otherFilters] - Other graphql filters to add to the list query
 * @param [limit=250] - The limit to use for each nextToken iteration. Reduce this for large queries
 * @param disableOrganizationID - If true, the organizationID filter will not be included
 */
const handleListQuery = async ({ queryFunction, organizationID, query, otherFilters, limit = 250, disableOrganizationID = false, }) => {
    console.log(`Starting ListQuery function. Time: ${new Date().toISOString()}`);
    let nextToken = undefined;
    let allData = [];
    const getAllData = async () => {
        let filter = undefined;
        /**
         * If disableOrganizationID is true, don't include the filter
         */
        if (!disableOrganizationID) {
            filter = {
                ownerGroup: {
                    eq: organizationID,
                },
            };
        }
        /**
         * If otherFilters are provided, add to the filter
         */
        if (otherFilters) {
            if (filter) {
                filter = { ...filter, ...otherFilters };
            }
            else {
                filter = { ...otherFilters };
            }
        }
        const data = await queryFunction(query, {
            filter,
            limit,
            nextToken,
        });
        if (Array.isArray(data?.errors) && data.errors.length > 0) {
            console.error("[handleListQuery] Errors: ", JSON.stringify(data.errors, null, 2));
        }
        const typeName = Object.keys(data?.data)[0];
        if (!typeName) {
            console.error("[handleListQuery] Cannot get data, missing typeName");
            return;
        }
        const listArray = data?.data?.[typeName]?.items;
        if (!Array.isArray(listArray)) {
            console.error("[handleListQuery] Cannot get data, missing listArray");
            return;
        }
        allData = allData.concat(listArray);
        const nextTokenResult = data?.data?.[typeName]?.nextToken;
        if (nextTokenResult) {
            nextToken = nextTokenResult;
            await getAllData();
        }
    };
    try {
        await getAllData();
        return allData;
    }
    catch (error) {
        console.error("[handleListQuery] Error in handleListQuery: ", error);
    }
    finally {
        console.log(`Finished ListQuery function. Time: ${new Date().toISOString()}`);
    }
    return [];
};
exports.handleListQuery = handleListQuery;
