import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * @description Create controls from CSV file
 * @param {object} csvImporter - instance of CSV importer
 * @param {object[]} data.add - new raw controls data
 * @param {object[]} frameworkCustomFields - Framework custom fields
 * @param {object} controlFramework - Control framework object from DB
 * @param {function} addToast - UIContext addToast function
 * @param {function} updateToast - UIContext updateToast function
 * @param {string} organizationID - the organization for which the controls are being imported (the selected organization)
 */
export const handleUploadControls = async ({
  data,
  frameworkCustomFields,
  controlFramework,
  addToast,
  updateToast,
  organizationID,
}) => {
  /**
   * Validate params
   */
  if (isNullOrUndefined(data)) throw Error("Invalid param, missing data map");
  if (isNullOrUndefined(frameworkCustomFields) || !Array.isArray(frameworkCustomFields))
    throw Error("Invalid param, missing frameworkCustomFields");
  if (isNullOrUndefined(controlFramework)) throw Error("Invalid param, missing controlFramework");
  if (isNullOrUndefined(addToast)) throw Error("Invalid param, missing addToast");
  if (isNullOrUndefined(updateToast)) throw Error("Invalid param, missing updateToast");
  if (isNullOrUndefined(organizationID)) throw Error("Invalid param, missing organizationID");

  /**
   * Check if data is valid
   */
  const toastId = addToast({
    header: `Starting Import..`,
    icon: "spinner",
    color: "success",
  });

  const { createMutation, updateMutation } = generateGraphql("Control");

  /**
   * Loop over controls to update and perform the mutations
   */
  if (controlFramework?.id && Array.isArray(data?.update) && data.update.length > 0) {
    //Get existing controls for the control framework
    const existingControls = await ListQueryBy({
      query: listControlsByControlSet,
      variables: {
        ownerGroup: organizationID,
        controlControlSetId: { eq: controlFramework?.id },
      },
      limit: 1000,
    });

    //Update any controls that already exist
    if (Array.isArray(existingControls) && existingControls.length > 0) {
      const mutationRequests = [];
      for (const updatedControl of data.update) {
        const foundItem = existingControls.find((item) => {
          return (
            !isNullOrUndefined(item?.id) && !isNullOrUndefined(updatedControl?.id) && item.id === updatedControl.id
          );
        });
        if (!foundItem) continue;

        //Prepare the update input by removing invalid properties
        const mutationInput = { ...updatedControl };
        delete mutationInput.changes;
        delete mutationInput.controlControlSetId;
        delete mutationInput.controlSet;
        delete mutationInput.tags;
        delete mutationInput.customFieldData;
        delete mutationInput.createdAt;

        //When the update operation is selected the notes get appended to the existing notes
        const controlNotes = foundItem?.notes || [];
        if (mutationInput?.notes) {
          controlNotes.push({
            id: uuid(),
            content: mutationInput?.notes,
            author: "Importer",
            ownerGroup: organizationID,
            timeStamp: new Date(),
          });
        }
        mutationInput.notes = controlNotes;

        mutationRequests.push(ItemMutation(updateMutation, mutationInput));
      }

      await Promise.allSettled(mutationRequests);
    }
  }

  /**
   * Loop over raw controls to create
   */
  if (Array.isArray(data?.add) && data.add.length > 0) {
    for (const control of data.add) {
      const customFields = {};
      /**
       * Name and statementNumber are required
       */
      if (control.name && control.statementNumber) {
        /**
         * Check if raw control contains control framework fields
         */
        for (const field of frameworkCustomFields) {
          if (control[field?.name]) {
            customFields[field.name] = control[field.name];
          }
        }

        /**
         * Add notes from csv
         */
        const controlNotes = [];

        if (control.notes) {
          controlNotes.push({
            id: uuid(),
            content: control.notes,
            author: "Importer",
            ownerGroup: organizationID,
            timeStamp: new Date(),
          });
        }

        /**
         * Check for required fields to exist
         */
        if (!control?.name || !organizationID) {
          ErrorLogger(
            `Cannot create control, missing required fields - ${JSON.stringify({ control, organizationID })}`,
          );
          continue;
        }

        /**
         * Create a new control
         */
        try {
          const item = await ItemMutation(createMutation, {
            name: control.name,
            statementNumber: control.statementNumber,
            controlControlSetId: controlFramework?.id,
            customFieldData: JSON.stringify(customFields),
            inPlace: control.inPlace || false,
            isDisabled: control.isDisabled || false,
            notes: [...controlNotes],
            ownerGroup: organizationID,
          });
          InfoLogger(`Created Control object ${item.id}`);

          updateToast({
            id: toastId,
            header: `Control ${control?.name} created...`,
            icon: "spinner",
            color: "success",
          });
        } catch (err) {
          ErrorLogger(`Cannot create Control ${control}`, err);
          updateToast({
            id: toastId,
            header: `Failed to create control...`,
            icon: "spinner",
            color: "danger",
          });
        }
      }
    }
  }

  updateToast({
    id: toastId,
    header: "Control importing finished...",
    icon: "success",
  });
};

export const listControlsByControlSet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        notes {
          id
          type
          ownerGroup
          author
          timeStamp
          content
          tag
        }
      }
      nextToken
    }
  }
`;
