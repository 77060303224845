/**
 * Gets a list of field strings to use for the CSV importer from the fieldConfig objects
 *
 * @param {object[]} fieldConfig - array of field config objects
 * @param {string} fieldConfig[].field - field name to use for the mutation
 * @param {boolean} fieldConfig[].removeFromMutation - if true, will not add this field to the mutation
 * @returns {string[]}
 */
export const getResourceFields = (fieldConfig) => {
  const fields = [];

  for (const config of fieldConfig) {
    const field = config?.field;

    if (!fields.includes(field) && !config?.removeFromMutation) {
      fields.push(field);
    }
  }

  return fields;
};
