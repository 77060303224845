import React from "react";

/**
 * Handles the Modal Details body for the Grid Details Modal
 * @param {JSX.Element} detailsComponent - component to show when pressing on info action button
 * @param {object} detailsItem - information for the details component
 * @param {object} item - information for the details component (alternative to detailsItem)
 * @param {object} detailsHook - a hook to use to show the details of an item i nthe grid (alternative to detailsComponent)
 * must have the display property
 * @param {object} config - configuration for the grid details
 * @param {string} module - the platform module of the resource being displayed in the grid (and the details component)
 * @param {string} resource - the platform resource being displayed in the grid (also the typename in the schema if that exists)
 * @param {boolean} disableRoleChecking - if true, will not check users permissions to view the resource (passed down to detailComponent)
 * @param {function} updateItemById - callback function to update grid items when they are edited in the details UI
 * @returns {JSX.Element} - the informational view of a resource (its details page)
 */
export const DataGridModalDetailsComponent = ({
  detailsComponent,
  detailsItem,
  item,
  detailsHook,
  config,
  module,
  resource,
  disableRoleChecking,
  updateItemById,
  toggleModal,
}) => {
  return (
    <>
      {item && detailsComponent && !config?.disableDetails
        ? React.cloneElement(detailsComponent, {
            key: `details_modal_detail${(detailsItem || item).id}`,
            item: { ...(detailsItem || item) },
            module,
            resource,
            disableRoleChecking,
            updateItemById,
            toggleDetailsModal: toggleModal,
          })
        : detailsHook.display}
    </>
  );
};
