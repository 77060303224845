import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import GenericEditFieldV3 from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Custom field for displaying Key Control boolean field
 * @param {object} item - object with info pertaining to this Risk Control
 */
const KeyControl = ({ item, ...props }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const typename = "RiskControl";
  const fields = ["keyControl"];

  const { updateMutation } = generateGraphql(typename, fields);

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      inputType={"switch"}
      field={"keyControl"}
      mutation={updateMutation}
      friendlyName={"Key Control"}
      {...props}
    />
  );
};

export default withOrganizationCheck(KeyControl);
