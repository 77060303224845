"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyTableWidgetSorting = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Sorts data according the sort settings defined by the sort model from useDataGrid
 * @param {object} seriesItem - settings for the series item containing the sort model
 * @param {object[]} data - the data to be sorted
 * @returns {*} - the sorted data
 */
const applyTableWidgetSorting = ({ seriesItem, data }) => {
    //Check data to be valid
    if (!Array.isArray(data))
        return data;
    const sortModel = seriesItem?.viewSettings?.sortModel;
    if (Array.isArray(sortModel) && sortModel.length > 0) {
        const sortSettings = sortModel[0];
        const { field, sort } = sortSettings || {};
        if (field && sort) {
            const sortFunc = (aItem, bItem) => {
                const aValue = aItem[field];
                const bValue = bItem[field];
                if (aValue === bValue || (0, func_utils_1.isNullOrUndefined)(aValue && (0, func_utils_1.isNullOrUndefined)(bValue))) {
                    return 0;
                }
                if ((0, func_utils_1.isNullOrUndefined)(aValue) && !(0, func_utils_1.isNullOrUndefined)(bValue)) {
                    return sort === "asc" ? -1 : 1;
                }
                if (!(0, func_utils_1.isNullOrUndefined)(aValue) && (0, func_utils_1.isNullOrUndefined)(bValue)) {
                    return sort === "asc" ? 1 : -1;
                }
                if (aValue < bValue) {
                    return sort === "asc" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sort === "asc" ? 1 : -1;
                }
                return 0;
            };
            data = data.sort(sortFunc);
        }
    }
    return data;
};
exports.applyTableWidgetSorting = applyTableWidgetSorting;
