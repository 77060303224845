import { ItemQuery } from "../../../utils/Functions/Graphql/ItemQuery";
import { getQuery } from "../graphql/customDashboardGraphql";

/**
 * Fetches a CustomDashboard from the databased based on the ID
 * @param {string} itemId - The ID of the CustomDashboard to fetch
 * @returns {Promise<{
 *   id: string,
 *   name: string,
 *   layouts: object[],
 * }>}
 */
export const getCurrentDashboard = async ({ itemId }) => {
  const currentDashboard = await ItemQuery(getQuery, itemId);

  return currentDashboard;
};
