export const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  width: "100%",

  ...(isDragging && {
    background: "rgb(235,235,235)",
    width: "100%",
  }),
});
