"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findObjectInArray = void 0;
/**
 * @function
 * @description Checks if an object exists in an array of objects, using a specified field.
 *              if exists return found object
 *              if not exists return null
 *              note: if object does not have this field, this function does nothing.
 *
 * @param arr {Array}
 * @param obj {Object}
 * @param field {string}
 */
const findObjectInArray = (arr, obj, field = "id") => {
    if (arr?.length && obj && obj[field]) {
        const found = arr.find((item) => item[field] === obj[field]);
        if (found) {
            return found;
        }
    }
    return null;
};
exports.findObjectInArray = findObjectInArray;
