/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  *  - 10/18/19 JB: Added better styling
 *  *  - 10/18/19 JB: Has new disableDuplicate and hideEditButton functionality through props
 *  *  - 10/30/19 JB: Will now automatically select the first matrix from the query (if there is one)
 *  *  - 3/20/20 JB: Added permissions checking
 *
 * Description: Allows a User to select from a list of Program Blueprints based on `organizationID`
 *              Allows a User to Create a new Program Blueprint for `organizationID`
 *              On selection, will trigger a callback function through props to give the selected Program Blueprint object
 *                to a parent component.
 */

// import {Connect} from "aws-amplify-react";
import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { compose } from "react-apollo";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

const { updateMutation: updateMaturityMatrix, listQuery: listMaturityMatrixs } = generateGraphql("MaturityMatrix", [
  "defaultMatrix",
]);

const SelectMaturityMatrix = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.BLUEPRINT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const [selectedMatrix, setSelectedMatrix] = useState("none");
  const [initialRender, setInitialRender] = useState(false);
  const [defaultMatrix, setDefaultMatrix] = useState(false);
  const [matrixList, setMatrixList] = useState([]);

  useEffect(() => {
    if (selectedMatrix.defaultMatrix === "true") {
      setDefaultMatrix(true);
    }
  }, [selectedMatrix]);

  const setAsDefaultMatrix = () => {
    const currentDefaultMatrix = findDefaultMatrix();

    if (currentDefaultMatrix !== undefined && currentDefaultMatrix !== null) {
      props.updateMaturityMatrix({
        input: {
          id: currentDefaultMatrix.id,
          defaultMatrix: "false",
        },
      });
    }

    props
      .updateMaturityMatrix({
        input: {
          id: selectedMatrix.id,
          defaultMatrix: "true",
        },
      })
      .then(() => {
        window.location.reload();
      });
  };

  const findDefaultMatrix = () => {
    return matrixList.find((matrix) => matrix.defaultMatrix === "true");
  };

  return <div />;
};

SelectMaturityMatrix.propTypes = {
  organizationID: PropTypes.string.isRequired,
  setSelectedMatrix: PropTypes.func.isRequired,
  disableCreation: PropTypes.bool,
  disableDuplicate: PropTypes.bool,
  hideEditButton: PropTypes.bool,
};

SelectMaturityMatrix.defaultProps = {
  disableCreation: false,
  disableDuplicate: false,
  hideEditButton: false,
};

export default compose(graphqlMutation(gql(updateMaturityMatrix), gql(listMaturityMatrixs), "MaturityMatrix"))(
  SelectMaturityMatrix,
);
