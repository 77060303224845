import { gql } from "@graphql/types";

export const COMPLIANCE_TREND_COORDINATES = gql(`
   query complianceTrendCoordinates($dashboardId: ID!, $from: DateTimeISO, $to: DateTimeISO) {
    complianceTrendCoordinates(id: $dashboardId, from: $from, to: $to) {
      x
      y
    }
  }
`);
