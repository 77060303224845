import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";

const VendorControlCategoryNotes = ({
  item,
  organizationID,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  disableEdits,
}) => {
  const { updateMutation, getQuery } = generateGraphql("VendorControlCategory", ["notes"], {
    notes: `{
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }`,
  });

  const query = useQueryGetItem({
    disableRoleChecking,
    itemId: item.id,
    query: getQuery,
    module,
    resource,
  });

  return (
    <NotesV2
      item={query.item}
      module={module}
      resource={resource}
      tooltip="Notes for this Vendor Control Category"
      organizationID={organizationID}
      mutation={updateMutation}
      resetFunction={query.reset}
      disableRoleChecking={disableRoleChecking}
      disableEdits={disableEdits}
    />
  );
};

export default VendorControlCategoryNotes;
