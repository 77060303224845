import React from "react";

import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

import { useTargetFalsePositives } from "../hooks/useTargetFalsePositives";

/**
 * Display FalsePositive Findings list for Target component
 * @param {object} props
 * @returns {JSX.Element}
 */
const TargetFalsePositives = (props) => {
  const display = useTargetFalsePositives(props).gridDisplay;
  return <div style={{ height: "30em" }}>{display}</div>;
};

export default withOrganizationCheck(TargetFalsePositives);
