import { getDefaultDataGridViewsData } from "./getDefaultDataGridViewsData";
import { listDataGridViews } from "./listDataGridViews";

/**
 * Saves a data grid view to preferences
 * @param {string} gridID - the grid under which to save the view
 * @param {string} viewID - the view id to use (any matched view is overwritten)
 * @param {object} view - the view settings object to save
 * @param {object} preferencesHook - instance of the usePreferences hook
 * @param {boolean} selectSavedView - whether to select the saved view or not
 * @return {Promise<void>}
 */
export const saveDataGridView = async ({ gridID, viewID, view, preferencesHook, selectSavedView = true }) => {
  //Check arguments
  const { setPreference, getPreference } = preferencesHook || {};
  if (!gridID || !viewID || !view || typeof setPreference !== "function" || typeof getPreference !== "function") {
    throw new Error("saveDataGridView: invalid arguments", {
      gridID,
      view,
      getPreference,
      setPreference,
    });
  }

  //List the views from preferences and append the new view if not found
  const views = await listDataGridViews({ gridID, preferencesHook });
  const foundIndex = views.findIndex((view) => view.id === viewID);
  if (foundIndex >= 0) {
    // - overwrite the view if found
    views[foundIndex] = view;
  } else {
    // - save as last view if not found
    views.push(view);
  }

  //Create the data grid views data object
  let dataGridViewsData = getPreference("dataGridViews", gridID);
  if (!dataGridViewsData) {
    dataGridViewsData = getDefaultDataGridViewsData({
      initialView: view,
      selectView: selectSavedView,
    });
  } else {
    dataGridViewsData.views = views;
    if (selectSavedView) {
      dataGridViewsData.selectedViewID = viewID;
    }
  }

  //Save the view object to preferences
  await setPreference("dataGridViews", {
    [gridID]: dataGridViewsData,
  });
};
