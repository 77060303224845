import { cloneDeep } from "lodash";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getQueryDetailsRoute } from "../../../../../../../../definitions/functions/queries/getQueryDetailsRoute";

/**
 * Responsible for checking if the resource has a route defined and determining the default table widget table settings
 * @param {object} editedSeries - the series in the table tht is being modified
 * @param {object} queryConfigInit - the query config object of the series
 * @param {boolean} forceDefault - if true will not use the table settings that come from the series but instead will check query config
 * @returns {*|{showLinkToResource: boolean, detailsRoutePrefix: string}}
 */
export const getInitialTableSettingsFormInput = ({ editedSeries, queryConfig: queryConfigInit, forceDefault }) => {
  const queryConfig = cloneDeep(queryConfigInit);
  const queryDetailsRoutePrefix = getQueryDetailsRoute({ queryConfig });
  const tableSettings = editedSeries?.tableSettings;
  if (typeof tableSettings === "object" && !forceDefault) {
    return tableSettings;
  } else {
    const newShowLinkToResource = !isNullOrUndefined(queryDetailsRoutePrefix);

    return {
      detailsRoutePrefix: queryDetailsRoutePrefix,
      showLinkToResource: newShowLinkToResource,
    };
  }
};
