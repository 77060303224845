import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useExerciseDataGrid } from "../../../../views/Response/Exercises/hooks/useExerciseDataGrid";

/**
 * @description Component allowing to choose a single exercise
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectExercise = ({ organizationID, selectButtonCallback }) => {
  const grid = useExerciseDataGrid({
    organizationID,
    gridConfig: {
      createItemComponent: null,
      enableSelectButton: true,
      onSelectCallback: selectButtonCallback,
    },
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(SelectExercise);
