"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIdFromWidgetTag = void 0;
/**
 * Splits a widget tag string to get the ID out of it
 * @param widgetTag
 * @returns {string}
 */
const getIdFromWidgetTag = (widgetTag) => {
    return widgetTag.split("::")[1];
};
exports.getIdFromWidgetTag = getIdFromWidgetTag;
