/**
 * Helper function that checks a list of keywords against the CSV header name to determine if it matches a resource field
 * @param {string} columnName - the CSV header name
 * @param {object} columnMap - the current column mapping, in case it was pre-set
 * @param {string[]} keywords - array of strings that are used to auto-match a CSV header
 * @returns {boolean}
 */
export const checkKeywords = (columnName, columnMap, keywords = []) => {
  if (Array.isArray(keywords)) {
    for (const keyword of keywords) {
      if (keyword?.toLowerCase() === columnName?.toLowerCase()) {
        return true;
      }

      if (keyword?.toLowerCase() === columnMap?.[columnName]?.toLowerCase()) {
        return true;
      }
    }
  }
};
