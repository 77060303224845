import IconMicrosoft from "@assets/svg/logos/logo-microsoft.svg";
import IconOkta from "@assets/svg/logos/logo-okta.svg";

/**
 * A list of the display names for all SSOProviders selectable in the UI
 */
export const ssoProviderFullNames: Record<string, string> = {
  AZURE: "Microsoft Azure AD",
  OKTA: "Okta",
};

/**
 * A list of the icons for all SSOProviders selectable in the UI
 */
export const ssoProviderIcons: Record<string, JSX.Element> = {
  AZURE: <IconMicrosoft />,
  OKTA: <IconOkta />,
};
