import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { InvalidParamError } from "../../../../../../analytics/CustomError";
import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes the many-to-many connection object between an Evidence and a Point of Contact
 * @param linkId
 */
export const deleteEvidencePointOfContactLink = (linkId) => {
  if (isNullOrUndefined(linkId)) {
    throw new InvalidParamError("linkId");
  }

  const { deleteMutation } = generateGraphql("EvidencePointOfContactLink");

  return ItemMutation(deleteMutation, { id: linkId });
};
