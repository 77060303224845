import { NavigationBarModulesBySection, Route } from "@application/Layout/hooks/types/Navigation";
import { addDefaultModuleRoutes } from "@application/Layout/hooks/useNavigationLinks/functions/addDefaultModuleRoutes";
import { addNavigationRoute } from "@application/Layout/hooks/useNavigationLinks/functions/addNavigationRoute";
import { checkModulePermissions } from "@application/Layout/hooks/useNavigationLinks/functions/checkModulePermissions";
import { NavigationModule } from "@application/Navigation/types";
import { NavigationBarModule } from "@components/Molecules/NavigationBar/types";

export interface AddNavigationModuleParam {
  navigationModule: NavigationModule;
  newRoutes: Route[];
  newNavigation: NavigationBarModulesBySection;
  roleConfig: Record<string, unknown>;
}

export const addNavigationModule = ({
  navigationModule,
  newRoutes,
  newNavigation,
  roleConfig,
}: AddNavigationModuleParam): NavigationBarModule | undefined => {
  if (!checkModulePermissions({ navigationModule, roleConfig })) {
    return;
  }

  const routes = navigationModule.routes;
  const moduleSectionName = navigationModule.sectionName;
  const navigationBarPages = [];
  for (const route of routes) {
    addNavigationRoute({
      route,
      roleConfig,
      newRoutes,
      navigationBarPages,
      navigationModule,
    });
  }

  if (!Array.isArray(newNavigation[moduleSectionName])) {
    newNavigation[moduleSectionName] = [];
  }

  addDefaultModuleRoutes({ navigationModule, newRoutes });

  const modulePath = navigationModule.path;
  let moduleLink: string | undefined;
  if (modulePath && routes?.find((route) => route.path === "")) {
    moduleLink = modulePath;
  }

  const navigationBarModule: NavigationBarModule = {
    name: navigationModule.name,
    icon: navigationModule.icon,
    link: moduleLink,
    pages: navigationBarPages,
  };

  newNavigation[moduleSectionName].push(navigationBarModule);
  return navigationBarModule;
};
