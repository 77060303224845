import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { PointOfContact } from "@rivial-security/schema-types";

import { AdjustPriority } from "@views/Program/Actions/hooks/useCreateAction/useCreateAction";
import { moduleTypes } from "@views/Program/moduleTypes";

export interface GetActionItemFormFieldsParams {
  module?: string;
  hiddenFields?: string[];
  disableEdit?: boolean;
  pointOfContacts?: PointOfContact[];
  defaultValues?: Record<string, unknown>;
}

export const getActionItemFormFields = ({
  module,
  hiddenFields,
  disableEdit,
  pointOfContacts,
  defaultValues,
}: GetActionItemFormFieldsParams): Record<string, unknown> => {
  const fieldConfig = {
    name: {
      label: "Name",
      tooltip: "The primary text of this Action Item",
      required: true,
      disabled: disableEdit,
    },
    description: {
      label: "Description",
      tooltip: "Some more descriptive information regarding this Action Item",
      disabled: disableEdit,
    },
    module: {
      label: "Software Module",
      tooltip: "What module of the Rivial Software Platform should this be categorized into?",
      inputType: "dropdown",
      required: true,
      defaultValue: module || undefined,
      dropdownConfig: {
        data: moduleTypes.map((item) => {
          return {
            value: item,
            text: convertCamelCaseToSentence(item),
          };
        }),
      },
    },
    genericActionItemPointOfContactId: {
      label: "Point of Contact",
      tooltip: "You may assign someone to this Action Item",
      inputType: "dropdown",
      dropdownConfig: {
        data:
          pointOfContacts?.map((pointOfContact) => {
            return {
              value: pointOfContact.id,
              text: `${pointOfContact.firstName} ${pointOfContact.lastName}`,
            };
          }) ?? [],
      },
      disabled: disableEdit,
    },
    priority: {
      label: "Priority",
      inputType: "custom",
      defaultValue: 0,
      customConfig: {
        component: <AdjustPriority disabled={disableEdit} />,
      },
      disabled: disableEdit,
    },
    dueDate: {
      label: "Due Date",
      inputType: "date",
      disabled: disableEdit,
    },
    enableNotifications: {
      label: "Enable Notifications",
      tooltip: "Do you want to enable notifications for this Action Item?",
      inputType: "switch",
      defaultValue: false,
    },
  };

  //Hide unwanted fields
  for (const hiddenField of hiddenFields ?? []) {
    delete fieldConfig[hiddenField];
  }

  //Add default values
  for (const field in defaultValues ?? {}) {
    fieldConfig[field].defaultValue = defaultValues![field];
  }

  return fieldConfig;
};
