import { isNullOrUndefined } from "@rivial-security/func-utils";

import { createActionItem } from "../../Actions/functions/createActionItem";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import { createRecommendation } from "../../Recommendations/functions/createRecommendation";

import { createObservation } from "./createObservation";
import { createObservationRecommendationLink } from "./createObservationRecommendationLink";

/**
 * Creates an Observation object, along with creating associated Recommendations and ActionItems
 * @param input
 */
export const createFullObservation = async (input) => {
  const observation = await createObservation({
    name: input.name,
    description: input.description,
    module: input.module,
    source: input.source,
    isFinding: input.isFinding === true,
    complianceControlID: input?.complianceControlID,
    riskControlID: input?.riskControlID,
    vulnerabilityID: input?.vulnerabilityID || input?.vulnerability?.id,
    exerciseID: input?.exerciseID,
    incidentID: input?.incidentID,
    ownerGroup: input.ownerGroup,
    observationConnectionField: input.observationConnectionField,
    externalSourceID: input?.externalSourceID,
    evidenceID: input?.evidenceID,
    risk: input?.risk,
    auditID: input?.auditID,
    status: input?.status,
    decision: input?.decision,
    needsDecision: input?.needsDecision,
    departmentID: input?.departmentID,
    estimatedCompletionDate: input?.estimatedCompletionDate || undefined,
    formalResponse: input?.formalResponse,
    artifactID: input?.artifactID,
    assessmentID: input?.assessmentID,
    meetingID: input?.meetingID,
  });

  // Create recommendations
  if (Array.isArray(input.recommendations)) {
    for (const recommendation of input.recommendations) {
      let rec;

      if (recommendation.alreadyExists) {
        rec = recommendation;
      } else {
        rec = await createRecommendation({
          name: recommendation.name,
          status: "unresolved",
          rating: recommendation.rating || "low",
          ownerGroup: input.ownerGroup,
          module: recommendation.module || input.module,

          // legacy: unstructured version of the data
          data: recommendation.data || undefined,

          // structured version of the data
          riskRecommendationData: recommendation.riskRecommendationData || undefined,
        });

        if (
          !isNullOrUndefined(observation?.recommendations?.items) &&
          Array.isArray(observation.recommendations.items)
        ) {
          observation.recommendations.items.push(rec);
        }
      }

      await createObservationRecommendationLink(observation, rec);

      // Create Actions

      const actionItems = recommendation?.actionItems || [];

      for (const actionItem of actionItems) {
        if (!actionItem.alreadyExists) {
          const newActionItem = await createActionItem({
            name: actionItem.name,
            status: "proposed",
            priority: actionItem.priority || 0,
            description: actionItem.description,
            dueDate: new Date(actionItem.dueDate) || undefined,
            // automation: actionItem.automation,
            genericActionItemPointOfContactId: actionItem.genericActionItemPointOfContactId,
            data: JSON.stringify({
              vulnerabilityID: input?.vulnerabilityID || input?.vulnerability?.id,
              evidenceID: input?.evidenceID || input?.evidence?.id,
              incidentID: input?.incidentID || input?.incident?.id,
              auditID: input?.auditID || input?.audit?.id,
            }),
            ownerGroup: input.ownerGroup,
            module: recommendation?.module || input?.module,
          });

          await createRecommendationActionItemLink(rec, newActionItem);
        }
      }
    }
  }

  return observation;
};
