import { ReactElement } from "react";

import { modules, resources } from "@rivial-security/role-utils";
import { Task } from "@rivial-security/schema-types";

import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";

/**
 * Displays the Point of Contact for a Task.
 * Allows the User to adjust this field.
 */
const TaskPointOfContact = ({ item }: { item?: Task }): ReactElement => {
  const module = modules.GOVERNANCE;
  const resource = resources.TASK;
  const typename = resources.TASK;
  const field = "pointOfContact";
  const idField = "pointOfContactID";

  return (
    <PointOfContactField
      item={item}
      module={module}
      resource={resource}
      typename={typename}
      field={field}
      idField={idField}
    />
  );
};

export default TaskPointOfContact;
