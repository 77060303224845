import { useManyLinking } from "../../../../hooks/views/useManyToManyTransfer/hooks/useManyLinking";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useEvidenceDataGrid } from "../../Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";

/**
 * Many-to-many linking component for Evidences that are linked to AuditControlLinks
 * @param item
 * @param item.auditControlLink
 * @param organizationID
 * @param resetFunction
 * @param toggleModal
 * @returns {JSX.Element}
 * @constructor
 */
const AuditControlEvidenceLinking = ({ item, organizationID, resetFunction, toggleModal, optimisticUpdate }) => {
  const evidenceGrid = useEvidenceDataGrid({ organizationID });

  const onLinkCallback = (evidenceLink) => {
    const existingItems = item?.auditControlLink?.evidenceLinks?.items || [];

    const evidenceLinks = [...existingItems];

    const foundExisting = existingItems.find((x) => x?.evidence?.id === evidenceLink.evidence.id);

    if (!foundExisting) {
      evidenceLinks.push({
        id: evidenceLink.id,
        evidence: evidenceLink.evidence,
        notes: null,
        reviewed: false,
      });
    }

    optimisticUpdate({
      id: item.auditControlLink.id,
      typename: "AuditControlLink",
      field: "evidenceLinks",
      mutation: false,
      value: {
        items: evidenceLinks,
      },
    });
  };

  const onUnlinkCallback = (evidenceLink) => {
    const existingItems = item?.auditControlLink?.evidenceLinks?.items || [];

    const evidenceLinks = [];

    for (const existingItem of existingItems) {
      const foundExisting = existingItem?.id === evidenceLink?.id;

      // Push every 'non-matched' item back to the array
      if (!foundExisting) {
        evidenceLinks.push(existingItem);
      } else {
        // Since we are removing this existing item, do nothing (won't add it back to the array)
      }
    }

    optimisticUpdate({
      id: item.auditControlLink.id,
      typename: "AuditControlLink",
      field: "evidenceLinks",
      mutation: false,
      value: {
        items: evidenceLinks,
      },
    });
  };

  const linking = useManyLinking({
    organizationID,
    item: item?.auditControlLink,
    field: "evidenceLinks",
    nestedField: "evidence",
    existingItems: evidenceGrid.data,
    typename: "AuditControlEvidenceLink",
    parentTypename: "AuditControlLink",
    parentConnectionIDField: "auditControlLinkID",
    childTypename: "Evidence",
    childConnectionIDField: "evidenceID",
    resetFunction,
    toggleModal,
    onLinkCallback,
    onUnlinkCallback,
  });

  return linking.display;
};

export default withOrganizationCheck(AuditControlEvidenceLinking);
