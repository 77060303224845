import React, { useEffect, useState } from "react";

import { useTable } from "../../../../hooks/views/useTable";
import NumberChange from "../../../../utils/CustomFields/NumberChange";
import RiskControlDetails from "../../RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";

/**
 * Displays Risk Controls that are affected by a risk change
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const RiskControlAffectedList = ({ item }) => {
  const [riskControls, setRiskControls] = useState([]);

  useEffect(() => {
    const riskControlLinks = item?.riskControlLinks?.items || [];
    const temp = [];
    for (const riskControlLink of riskControlLinks) {
      riskControlLink &&
        temp.push({
          ...riskControlLink.riskControl,
          implementationChange: riskControlLink.implementationChange,
          costChange: riskControlLink.costChange,
        });
    }
    setRiskControls([...temp]);
  }, [item]);

  const customFields = [
    {
      field: "implementationChange",
      component: <NumberChange format="percent" field="implementationChange" />,
    },
    {
      field: "costChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" field="costChange" />,
    },
  ];

  const dataCardHook = useTable({
    typename: "RiskControl",
    detailsComponent: <RiskControlDetails />,
    data: riskControls,
    fields: ["statementNumber", "name", "costChange", "implementationChange"],
    disableRoleChecking: true,
    config: {
      borderless: true,
    },
    customFields,
  });

  useEffect(() => {
    dataCardHook.setData([...riskControls]);
  }, [riskControls]);

  return <>{dataCardHook.display}</>;
};

export default RiskControlAffectedList;
