import { useEffect } from "react";

/**
 * Populates the 'Linked Evidence' list when the control loads
 *
 * @param {Control} control - the parent control
 * @param {function} setItems - handler for setting the Evidences that are linked to the control
 */
export const usePopulateControlEvidences = (control, setItems) => {
  return useEffect(() => {
    if (control?.evidences?.items) {
      setItems(control.evidences.items.map((evidenceLink) => evidenceLink.evidence));
    }
  }, [control]);
};
