export const widgetDefinition = {
  routes: {
    grid: "reports/widgets",
    details: "reports/widget",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:pie-chart",

  //[COMPONENTS]
  // gridHook: useCustomWidgetDataGrid,
};
