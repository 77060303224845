import { useUsersDataGrid } from "../../../../views/OrganizationManager/Users/hooks/useUsersDataGrid";

export const userDefinition = {
  routes: {
    grid: "organization_manager/contacts",
    details: null,
  },

  //[APPEARANCE]
  icon: "simple-line-icons:user-following",

  //[COMPONENTS]
  gridHook: useUsersDataGrid,
};
