"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMonteCarloSystemData = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const updateSystem_1 = require("../graphql/updateSystem");
/**
 * Graphql Mutation on the System object to save monte carlo data
 */
const updateMonteCarloSystemData = async ({ input }) => {
    const mutation = await (0, appsync_utils_1.ItemMutation)({
        mutation: updateSystem_1.updateSystem,
        input,
        appsyncEndpoint: process.env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
    });
    return mutation;
};
exports.updateMonteCarloSystemData = updateMonteCarloSystemData;
