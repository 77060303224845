"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvVar = void 0;
const getEnvVar = (envVar, fallback) => {
    const value = process.env[envVar];
    if (!value && !fallback) {
        throw new Error(`Error! Missing required environment variable: ${envVar}`);
    }
    return value || fallback;
};
exports.getEnvVar = getEnvVar;
