import React, { useContext } from "react";

import { GetQuery, ListQueryBy } from "@rivial-security/appsync-utils";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import CreateLinking from "../../../../utils/GenericComponents/CreateLinking";
import CreateVulnerability from "../../Vulnerabilities/components/CreateVulnerability";
import VulnerabilityDataGrid from "../../Vulnerabilities/components/VulnerabilityDataGrid";
import { useVulnerabilityDataGrid } from "../../Vulnerabilities/hooks/useVulnerabilityDataGrid";
import { createAssessmentVulnerabilityLink } from "../functions/createAssessmentVulnerabilityLink";
import { normalizeAssessmentVulnerabilities } from "../functions/normalizeAsessmentVulnerabilities";
import { listAssessmentVulnerabilityLinksByAssessmentId } from "../graphql/__assessmentGQL";

/**
 * Display Vulnerability list for an Assessment
 * @param {object} item - assessment object
 * @param {string} organizationID - selected organization ID
 * @param {object} filterByTarget - a target object to filter the grid by,
 * only Vulnerabilities that are attached to both the assessment and target will be shown
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: (Object[]|Object), setData: (function(*): void), setSelectedData: (function(((function([]): [])|[])): void), display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: (function(*): void), modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: (function(): void)}, setIsLoading: (function(((function(*): *)|*)): void), resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (function(((function([]): [])|[])): void), setCheckboxSelection: (function(((function(boolean): boolean)|boolean)): void)}}
 */
export const useAssessmentVulnerabilities = ({ item, organizationID, filterByTarget }) => {
  const context = useContext(OrganizationContext);

  const deleteFunction = async (item) => {
    if (item?.connectionIDField) {
      await ItemMutation(generateGraphql("AssessmentVulnerabilityLink").deleteMutation, {
        id: item?.connectionIDField,
      });
    }
  };

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      let target = null;
      const targetVulnerabilityIds = new Set();
      if (!isNullOrUndefined(filterByTarget?.id)) {
        target = await GetQuery({
          query: getTargetQuery,
          variables: {
            id: filterByTarget?.id,
          },
        });

        const vulnerabilityTargetLinks = target?.vulnerabilities?.items;
        if (isNonEmptyArray(vulnerabilityTargetLinks)) {
          for (const link of vulnerabilityTargetLinks) {
            targetVulnerabilityIds.add(link?.vulnerability?.id);
          }
        }
      }

      //Retrieve all the assessment vulnerabilities
      const assessmentVulnerabilityLinks = await ListQueryBy({
        query: listAssessmentVulnerabilityLinksByAssessmentId,
        variables: {
          assessmentID: item?.id,
        },
        limit: 1000,
      });

      let assessmentVulnerabilities = normalizeAssessmentVulnerabilities({
        links: assessmentVulnerabilityLinks,
        showInfoVulnerabilities: context?.preferences?.testing?.showInfoVulnerabilities,
      });

      //If there are any target vulnerabilities, filter in only those that are attached both to target and assessent
      if (targetVulnerabilityIds.size > 0) {
        assessmentVulnerabilities = assessmentVulnerabilities.filter((vulnerability) =>
          targetVulnerabilityIds.has(vulnerability?.id),
        );
      }

      return assessmentVulnerabilities;
    },
    setData: (data) => {
      if (!data) {
        vulnerabilitiesDataGrid.setData([]);
        return;
      }
      vulnerabilitiesDataGrid.setData(data);
    },
    dependencies: [item?.id, filterByTarget],
  });

  const vulnerabilitiesDataGrid = useVulnerabilityDataGrid({
    organizationID,
    query: null,
    isLoading,
    resetFunction,
    persistenceUUID: "cbac6db2-8395-488b-a184-61bd582fed47",
    vulnerabilityGridConfig: {
      deleteFunction,
      createItemModalHeader: `Attach a Vulnerability`,
      createResourceButtonText: "Link",
      createResourceComponent: (
        <CreateLinking
          organizationID={organizationID}
          parentTypename={"Assessment"}
          typename={"Vulnerability"}
          item={item}
          form={<CreateVulnerability assessment={item} />}
          grid={<VulnerabilityDataGrid />}
          createFunction={(itemToLink, item) =>
            createAssessmentVulnerabilityLink({
              assessment: item,
              vulnerability: itemToLink,
              organizationID,
            })
          }
        />
      ),
    },
  });

  return vulnerabilitiesDataGrid;
};

const { getQuery: getTargetQuery } = generateGraphql("Target", ["vulnerabilities"], {
  vulnerabilities: `(limit: 1000) {
      items {
        id
        vulnerability {
          id
        }
      }
    }`,
});
