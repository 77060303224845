/**
 * Generates the name for an Automation Step.
 * Name consists of the step type and a number representing the number of steps of that type.
 *
 * If the step is a report, the name will be "report1" or "report2" etc.
 *
 * If something goes wrong, returns an empty string.
 *
 * @param {string} type - the selected type of the step, the type is used in the name of the step
 * @param {object[]} automations - the list of current automations, used to determine the number of steps of the given type
 * @param {string} automations[].type - the type of the automation, used to match the input type
 */
export const generateAutomationStepName = ({ type, automations = [] }) => {
  // If the type is not a string, return an empty string
  if (typeof type !== "string") {
    return "";
  }

  // If the automations input is invalid, return type1
  if (!Array.isArray(automations)) {
    return `${type}1`;
  }

  // Get the number of steps of the given type
  const count = automations.filter((automation) => automation.type === type)?.length || 0;

  return `${type}${count + 1}`;
};
