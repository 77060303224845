import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import CreateOrSelectInput from "../../../../utils/GenericComponents/GenericEditFieldV3/components/CreateOrSelectInput";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import UnderlinedTooltip from "../../../../utils/Tooltips/UnderlinedTooltip";
import CreateSource from "../../Sources/components/CreateSource";
import SourceDataGrid from "../../Sources/components/SourceDataGrid";
import SourceDetails from "../../Sources/components/SourceDetails";

/**
 * Displays the Source field for an Observation
 * @param {object} item - the item containing the source information under externalSourceID
 * @param {function} updateItemById - call back to update the state of parent component locally
 * @param {string} field - the field that the source data is under
 * @param {object} props - any additional props to pass to the GenericEditFieldV3
 * @returns {JSX.Element}
 */
const Source = ({ item, updateItemById, field, ...props }) => {
  const { updateMutation } = generateGraphql("Observation", ["externalSourceID"]);

  return (
    <span>
      <GenericEditFieldV3
        {...props}
        updateItemById={async (item) => {
          const id = item?.externalSourceID;
          const getSourceQuery = generateGraphql("Source", ["name"]).getQuery;
          const externalSource = await GetQuery({
            query: getSourceQuery,
            variables: { id },
          });

          if (externalSource?.id && typeof updateItemById == "function") {
            await updateItemById({
              ...item,
              externalSource,
            });
          }
        }}
        mutation={updateMutation}
        item={item}
        field={"externalSourceID"}
        customDisplayComponent={<SourceDisplayComponent />}
        customInputComponent={
          <CreateOrSelectInput
            typename={"Source"}
            form={<CreateSource />}
            grid={<SourceDataGrid />}
            displayComponent={<SourceDisplayComponent />}
          />
        }
      />
    </span>
  );
};

const SourceDisplayComponent = ({ item }) => {
  return (
    <span>
      {item?.externalSource ? (
        <UnderlinedTooltip text={item?.externalSource?.name}>
          <SourceDetails item={item?.externalSource} />
        </UnderlinedTooltip>
      ) : (
        <i>No Source</i>
      )}
    </span>
  );
};

export default withOrganizationCheck(Source);
