import { useRiskControlDataGrid } from "../../../../views/Risk/RiskConfig/ControlCategories/components/SubControls/hooks/useRiskControlDataGrid";

export const riskControlDefinition = {
  routes: {
    grid: "risk/risk_config/risk_controls",
    details: "risk/risk_config/risk_controls",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:list",

  //[COMPONENTS]
  gridHook: useRiskControlDataGrid,
};
