"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_JOB_TYPE = void 0;
/**
 * All the different jobs that can be started from the document editor
 */
exports.REPORT_JOB_TYPE = {
    GENERATE_TEMPLATE: "templateGeneration",
    RENDER_WIDGET: "widgetGeneration",
};
