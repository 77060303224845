"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateReportName = void 0;
const date_fns_1 = require("date-fns");
/**
 * Creates a formatted report name based on the Rivial convention.
 * @param {object} params
 * @param {string} [params.name] - the name of the report by itself (no date or other info, placed at the start)
 * @param {object} [params.organization] - the organization data (at least short name)
 * @return {string} - the formatted report name
 */
const generateReportName = ({ name, organization } = {}) => {
    return `${name || "Custom Report"} (${(0, date_fns_1.format)(new Date(), "yyyy-MM-dd")} ${organization?.shortName || "Organization"})`;
};
exports.generateReportName = generateReportName;
