import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useForm } from "@hooks/views/useForm";
import { getIcon } from "@utils/Functions/Icon/getIcon";
import DashboardCard from "@utils/GenericComponents/DashboardCard/components/DashboardCard";

/**
 * A Card for mapping a single CSV column to a single Resource Field
 * @param {object[]} fields - resource fields that are available for mapping. Only one field may be mapped at a time
 * @param {string} header - the current CSV column header to map
 * @param {object} columnMap - the mapping between CSV column headers and Resource Fields
 * @param {function} setColumnMapping - state setter for setting the mapping of column headers to resource fields
 * @param {object} row - the first row of the CSV file. used for generating a preview
 * @returns {JSX.Element}
 */
const ColumnCard = ({ fields, header, columnMap, setColumnMapping, row }) => {
  const [selected, setSelected] = useState(columnMap[header] || "n/a");
  const [remainingFields, setRemainingFields] = useState([]);

  useEffect(() => {
    const newRemainingFields = [];
    //Adding the non mapped field as an option to cancel selection
    newRemainingFields.push({
      value: "n/a",
      text: "None",
    });

    //Adding all fields that were not mapped yet
    if (Array.isArray(fields)) {
      for (const field of fields) {
        const columnMapValues = columnMap ? Object.values(columnMap) : {};

        if (field?.hidden !== true && field?.name && !columnMapValues.includes(field.name)) {
          newRemainingFields.push({
            value: field.name,
            text: field.name,
          });
        }
      }
    }

    //Adding the currently mapped field as an option to the list of remaining fields
    selected && selected !== "n/a" && newRemainingFields.push({ value: selected, text: selected });

    setRemainingFields(newRemainingFields);
  }, [fields, columnMap]);

  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      [header || "header"]: {
        inputType: "dropdown",
        defaultValue: columnMap[header] || "n/a",
        dropdownConfig: {
          data: remainingFields,
        },
      },
    },
  });

  // Pre-sets the Form input based on column mapping
  useEffect(() => {
    if (!isNullOrUndefined(header) && !isNullOrUndefined(columnMap)) {
      if (!isNullOrUndefined(columnMap[header]) && columnMap[header] !== "n/a") {
        form.setInput({
          [header]: columnMap[header],
        });
      }
    }
  }, [JSON.stringify(columnMap)]);

  // Updates the column mapping when the form selection changes
  useEffect(() => {
    if (form?.input?.[header] && form.input[header] !== "") {
      setColumnMapping(form.input[header], header, selected);
      setSelected(form.input[header]);
    }
  }, [form.input]);
  const interpreterDescription = fields?.find((field) => field.name === columnMap?.[header])?.interpreterDescription;
  return (
    <DashboardCard
      wrapperStyle={{ overflow: "visible" }}
      style={{ height: "100%" }}
      tooltip={interpreterDescription}
      title={header}
      subTitle={<pre>{row[header]} </pre>}
    >
      <Grid container spacing={1} style={{ marginTop: "-1em" }}>
        <Grid container item xs={12} lg={12} alignItems={"center"} justifyContent="center">
          <span style={{ textAlign: "center" }}>{getIcon("akar-icons:arrow-down")}</span>
        </Grid>
        <Grid item xs={12} lg={12}>
          {selected === "n/a" || isNullOrUndefined(selected) ? (
            form.display
          ) : (
            <strong>
              {selected}
              <Button close onClick={() => form.setInput({ [header]: "n/a" })} />
            </strong>
          )}
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default ColumnCard;
