import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateAutomation } from "../hooks/useCreateAutomation";

/**
 * Component instance of the useCreateAutomation hook
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateAutomation = (props) => {
  return useCreateAutomation(props).display;
};

export default withOrganizationCheck(CreateAutomation);
