import { removeDuplicateObjects } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { getMany } from "../../../../utils/Functions/getMany";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";

/**
 * Gets a list of associated KPIs from a Control Category or System. Ensures no duplicates exist. Prioritizes the system input.
 *
 * @author Jacob Blazina
 * @function
 */
export const rollupKPIs = async ({ system, riskControlCategory, control, riskControl }) => {
  const currentKPIs = [];

  if (system) {
    const { getQuery } = generateGraphql("System", ["controlCategories"], {
      controlCategories: `
        (limit: 200) {
          items {
            id
            ownerGroup
            riskControlCategory {
              id
              ownerGroup
              subControls (limit: 200) {
                items {
                  id
                  ownerGroup
                  evidences (limit: 200) {
                    items {
                      evidence {
                        keyPerformanceIndicators (limit: 200){
                          items {
                            id
                            ownerGroup
                            keyPerformanceIndicator {
                              id
                              name
                              description
                              ownerGroup
                              status
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
    });

    const systemItem = await ItemQuery(getQuery, system.id);

    const controlCategories = getMany(systemItem, "controlCategories", "riskControlCategory");

    for (const controlCategory of controlCategories) {
      const riskControls = controlCategory?.subControls?.items ? controlCategory.subControls.items : [];
      for (const riskControl of riskControls) {
        const evidences = getMany(riskControl, "evidences", "evidence");
        for (const evidence of evidences) {
          const kpis = getMany(evidence, "keyPerformanceIndicators", "keyPerformanceIndicator");
          currentKPIs.push(...kpis);
        }
      }
    }
  } else if (riskControlCategory) {
    const { getQuery } = generateGraphql("RiskControlCategory", ["subControls"], {
      subControls: `
        (limit: 200){
          items {
            id
            ownerGroup
            evidences (limit: 200) {
              items {
                evidence {
                  keyPerformanceIndicators (limit: 200){
                    items {
                      id
                      ownerGroup
                      keyPerformanceIndicator {
                        id
                        name
                        description
                        ownerGroup
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
    });

    const controlCategoryItem = await ItemQuery(getQuery, riskControlCategory.id);

    const riskControls = controlCategoryItem?.subControls?.items ? controlCategoryItem.subControls.items : [];
    for (const riskControl of riskControls) {
      const evidences = getMany(riskControl, "evidences", "evidence");
      for (const evidence of evidences) {
        const kpis = getMany(evidence, "keyPerformanceIndicators", "keyPerformanceIndicator");
        currentKPIs.push(...kpis);
      }
    }
  } else if (riskControl) {
    const { getQuery } = generateGraphql("RiskControl", ["evidences"], {
      evidences: `
        (limit: 200){
          items {
            id
            evidence {
              id
              ownerGroup
              keyPerformanceIndicators (limit: 200){
                items {
                  id
                  ownerGroup
                  keyPerformanceIndicator {
                    id
                    name
                    description
                    ownerGroup
                    status
                  }
                }
              }
            }
          }
        }`,
    });

    const riskControlItem = await ItemQuery(getQuery, riskControl.id);

    const evidence = getMany(riskControlItem, "evidences", "evidence");
    for (const evidenceItem of evidence) {
      const evidenceKpis = getMany(evidenceItem, "keyPerformanceIndicators", "keyPerformanceIndicator");
      currentKPIs.push(...evidenceKpis);
    }
  } else if (control) {
    const { getQuery } = generateGraphql("Control", ["evidences"], {
      evidences: `
        (limit: 200){
          items {
            id
            evidence {
              id
              ownerGroup
              keyPerformanceIndicators (limit: 200){
                items {
                  id
                  ownerGroup
                  keyPerformanceIndicatorID
                  keyPerformanceIndicator {
                    id
                    name
                    description
                    ownerGroup
                    status
                  }
                }
              }
            }
          }
        }`,
    });

    const controlItem = await ItemQuery(getQuery, control.id);

    const evidence = getMany(controlItem, "evidences", "evidence");

    for (const evidenceItem of evidence) {
      const evidenceKpis = getMany(evidenceItem, "keyPerformanceIndicators", "keyPerformanceIndicator");
      currentKPIs.push(...evidenceKpis);
    }
  }

  return removeDuplicateObjects(currentKPIs);
};

const rollup = (keyPerformanceIndicatorLinks) => {
  const res = [];
  if (keyPerformanceIndicatorLinks) {
    for (const keyPerformanceIndicatorLink of keyPerformanceIndicatorLinks) {
      res.push(keyPerformanceIndicatorLink.keyPerformanceIndicator);
    }
  }
  return res;
};
