"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMin = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the minimum value in the array.
 * @param {object} items - array of items that can be converted to numeric values or are already numbers
 * @returns {number|null} - the minimum value or null if no valid items were passed
 */
const getMin = ({ items }) => {
    const numericItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    if (!Array.isArray(numericItems) || numericItems.length === 0) {
        return null;
    }
    else {
        return Math.min(...numericItems);
    }
};
exports.getMin = getMin;
