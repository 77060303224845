import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";

import { getCustomFieldName } from "./getCustomFieldName";
import { isValidField } from "./isValidField";

/**
 * Returns custom fields inputs to display in a form
 * @param {object[]} customFields - the custom fields configurations
 * @param {boolean} convertLabelToCamelCase - if TRUE converts the form labels to camel case
 * @returns {object}
 */
export const getFormCustomFields = ({ customFields, convertLabelToCamelCase }) => {
  //Check for existence of any custom fields
  if (!customFields || !Array.isArray(customFields) || customFields.length === 0) {
    return {};
  }

  const customFieldsResult = {};
  for (const field of customFields) {
    //check if needed field properties are present
    if (!isValidField(field)) {
      continue;
    }

    let uniqueProperties = {};
    switch (field?.type) {
      case CustomFieldType.BOOLEAN:
        uniqueProperties = {
          inputType: "switch",
          defaultValue: false,
          switchConfig: {
            onLabel: "Value is set to 'true'",
            offLabel: "Value is set to 'false'",
          },
        };
        break;
      case CustomFieldType.DATE:
        uniqueProperties = {
          inputType: "date",
        };
        break;
      case CustomFieldType.ENUM:
        const options = (field?.options || [])?.map(({ label, value }) => {
          return {
            text: label,
            value,
          };
        });

        uniqueProperties = {
          inputType: "dropdown",
          dropdownConfig: {
            data: options,
          },
        };
        break;
      case CustomFieldType.MULTIPLE_SELECT:
        const multipleSelectOptions = (field?.multipleSelect || [])?.map(({ label, value }) => {
          return {
            label: label,
            key: value,
          };
        });

        uniqueProperties = {
          inputType: "checkboxes",
          inputConfig: {
            data: multipleSelectOptions,
          },
        };
        break;
      case CustomFieldType.NUMBER:
        uniqueProperties = {
          inputType: "number",
          numberConfig: {
            ...(field?.numberSettings || {}),
          },
        };
        break;
      default:
        uniqueProperties = {
          //any future properties specific to text based custom fields
        };
        break;
    }

    const customFieldName = getCustomFieldName(field);
    if (!customFieldName) continue;
    customFieldsResult[customFieldName] = {
      label: convertLabelToCamelCase ? convertCamelCaseToSentence(field?.name) : field?.name,
      sublabel: field?.description,
      ...uniqueProperties,
    };
  }

  return customFieldsResult;
};
