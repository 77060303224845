export const documentsByOwnerGroup = /* GraphQL */ `
  query DocumentsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        file {
          bucket
          region
          key
        }
        accessControl {
          password
          passwordOwnerEmail
          pointOfContacts
          roles
        }
        avStatus
        lastAVCheck
        ownerGroup
      }
      nextToken
    }
  }
`;
