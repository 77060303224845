import { getCombinedSystemResidualChange } from "./getCombinedSystemResidualChange";

/**
 * Aggregates a list of risk changes into grouped risk changes by their groupID field
 * @param {object[]} riskChanges - list of all risk changes to aggregate
 * @return {object[]} - grouped risk changes (formatted to be used with GroupedRiskChangeDataGrid and GroupedRiskChangeDetails)
 */
export const groupRiskChanges = ({ riskChanges }) => {
  const groupedRiskChanges = [];

  if (!Array.isArray(riskChanges)) {
    return groupedRiskChanges;
  }

  //Iterate over all risk changes and put the changes into bins by groupID property
  const groups = {};
  for (const riskChange of riskChanges) {
    //A risk change needs to have at least an ID to be shown
    if (!riskChange?.id) {
      continue;
    }

    //Risk changes without a group id are in a group of their own
    if (!riskChange.groupID) {
      riskChange.groupID = riskChange?.id;
    }

    const groupID = riskChange.groupID;
    if (groups.hasOwnProperty(groupID)) {
      groups[groupID].push(riskChange);
    } else {
      groups[groupID] = [riskChange];
    }
  }

  // For each group populate the grid rows with aggregated data
  for (const groupID in groups) {
    const riskChanges = groups[groupID];
    if (!Array.isArray(riskChanges)) {
      continue;
    }

    //Record the total number of changes in the group
    const totalChanges = riskChanges.length;

    //Record the total number of systems impacted across all changes in the group and their change in residual risk
    const seenSystems = {};
    for (const riskChange of riskChanges) {
      let systemChanges = {};
      try {
        const changeData = JSON.parse(riskChange?.change);
        systemChanges = changeData?.systemChanges;
      } catch (e) {
        //pass - no system changes
      }
      const systemLinks = riskChange?.systemLinks?.items;
      if (Array.isArray(systemLinks)) {
        for (const systemLink of systemLinks) {
          const systemID = systemLink?.system?.id;
          if (systemID) {
            const newSystemResidualChange = getCombinedSystemResidualChange({
              seenSystems,
              systemChanges,
              systemID,
            });
            if (seenSystems.hasOwnProperty(systemID)) {
              seenSystems[systemID].residualChange = newSystemResidualChange;
            } else {
              seenSystems[systemID] = {
                residualChange: newSystemResidualChange,
                name: systemLink?.system?.name,
                id: systemLink?.system?.id,
              };
            }
          }
        }
      }
    }
    const systemChanges = [];
    for (const systemID in seenSystems) {
      const system = seenSystems[systemID];
      if (system) {
        systemChanges.push(system);
      }
    }
    const totalSystems = Object.keys(seenSystems).length;

    //Take the most recent date as the date of the group
    let mostRecentDate;
    let mostRecentDateString;
    for (const riskChange of riskChanges) {
      if (!riskChange?.date) {
        continue;
      }

      let date, dateString;
      try {
        date = new Date(riskChange?.date);
        dateString = riskChange?.date;
      } catch (e) {
        //pass on this date
        continue;
      }

      if (date && (!mostRecentDate || date > mostRecentDate)) {
        mostRecentDate = date;
        mostRecentDateString = dateString;
      }
    }

    /**
     * Since changes are grouped by trigger the reason and name should be the same for all changes in the group.
     * Therefore, grabbing the values that are not null.
     */
    let reason = null;
    let name = null;
    let userEmail = null;
    let changeOwner = null;
    for (const riskChange of riskChanges) {
      if (!reason && riskChange?.reason) {
        reason = riskChange?.reason;
      }

      if (riskChange?.name && name !== "Mixed Risk Changes") {
        if (!name) {
          name = riskChange?.name;
        } else if (name !== riskChange?.name) {
          name = "Mixed Risk Changes";
        }
      }

      if (!userEmail) {
        userEmail = riskChange?.userEmail;
      }

      if (!changeOwner) {
        changeOwner = riskChange?.changeOwner;
      }
    }

    //Check if all changes are of the same type
    let type = null;
    for (const change of riskChanges) {
      //Bulking together the types of changes for the grouped details only
      let currentControlChangeType = change?.type;
      if (currentControlChangeType === "nonStandardControl" || currentControlChangeType === "enterpriseControl") {
        currentControlChangeType = "risk_control_change";
      }

      if (currentControlChangeType) {
        if (!type) {
          type = currentControlChangeType;
        } else if (type !== currentControlChangeType) {
          type = "Mixed";
          break;
        }
      }
    }

    // If no type was provided will be assuming the changes are of mixed type
    type = type || "Mixed";

    //Calculate the total overall effect of all changes (residual change and ROI)
    let totalResidualChange = 0;
    let totalCostChange = 0;
    let totalROI = 0;
    for (const riskChange of riskChanges) {
      if (!riskChange?.change) {
        continue;
      }

      let changeData;
      try {
        changeData = JSON.parse(riskChange?.change);
      } catch (e) {
        //pass - no change data
      }
      if (!changeData) {
        continue;
      }

      totalResidualChange += changeData?.residualChange ?? 0;
      totalCostChange += changeData?.costChange ?? 0;
    }

    //Calculate the total ROI of all changes
    const totalProfit = -totalResidualChange - totalCostChange;
    if (totalCostChange !== 0) {
      totalROI = totalProfit / Math.abs(totalCostChange);
    } else {
      totalROI = 0;
    }

    const riskChangeGroupRow = {
      id: groupID,
      totalChanges,
      totalSystems,
      date: mostRecentDateString,
      reason,
      name,
      userEmail,
      changeOwner,
      type,
      riskChanges,
      systemChanges,

      totalResidualChange: totalResidualChange,
      totalCostChange: totalCostChange,
      totalReturnOnInvestment: totalROI,
    };

    groupedRiskChanges.push(riskChangeGroupRow);
  }

  return groupedRiskChanges;
};
