import { generateGraphql } from "@rivial-security/generategraphql";

import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";

/**
 * Deletes a ResponseTeam object from the database.
 * @param {object} team - the team to remove
 * @param {string} team.id - the ID  of the team to remove
 * @returns {Promise<Array>}
 */
export const deleteResponseTeam = async (team) => {
  if (!team || !team.id) {
    return null;
  }

  // query to gather all resources that need deletion
  const { getQuery: query } = generateGraphql(
    "ResponseTeam",
    ["pointOfContactLinks", "classifications", "exercises", "__typename"],
    {
      pointOfContactLinks: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      classifications: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      exercises: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
    },
  );

  return await GenericDeleteGQL({
    query,
    id: team?.id,
  });
};
