"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResourceIndexByField = void 0;
const dynamoDatabaseIndexMap_1 = require("./dynamoDatabaseIndexMap");
const getResourceIndexByField = ({ resource, partitionKey, }) => {
    const indexList = dynamoDatabaseIndexMap_1.dynamoDatabaseIndexMap[resource];
    if (!indexList) {
        return null;
    }
    const foundIndex = indexList.find((index) => index.partitionKey === partitionKey);
    if (!foundIndex) {
        return null;
    }
    return foundIndex;
};
exports.getResourceIndexByField = getResourceIndexByField;
