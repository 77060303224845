import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Deletes a System resource and all connections in the database.
 *
 * Deletes the following Connection Objects as well:
 * - SystemRiskLink
 * - SystemInformationAssetLink
 * - SystemControlCategoryLink
 * - SystemChangeLink
 * - SystemTargetLink
 * - ResponseSystemLink
 * - SystemTagLink
 * - RiskControlEvidenceLink
 *
 * @param {object} system - the system object to delete
 * @param {string} system.id - the id of the system
 * @returns {Promise<void>}
 */
export const deleteSystem = async (system) => {
  //Check arguments
  const systemID = system?.id;
  const systemOwner = system?.pointOfContact?.id;
  const systemAdmin = system?.adminPointOfContact?.id;
  if (!systemID) {
    ErrorLogger("Error: Could not delete System. System or System.id input is null or undefined.");
    return null;
  }

  //Query to gather all resources that need deletion
  const { getQuery: resourcesToDelete } = generateGraphql(
    "System",
    [
      "risks", // many to many: SystemRiskLink
      "informationAssets", // many to many: SystemInformationAssetLink
      "controlCategories", // many to many: SystemControlCategoryLink
      //"riskChangeLinks", // many to many: SystemChangeLink (RETRIEVED SEPARATELY BELOW)
      "targets", // many to many: SystemTargetLink
      "responsePlans", // many to many: ResponseSystemLink
      "tags", // many to many: SystemTagLink
      "evidenceRiskControls", // many to many: RiskControlEvidenceLink
      "questionnaires",
      "__typename",
    ],
    {
      risks: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      informationAssets: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      controlCategories: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      targets: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      responsePlans: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      tags: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      evidenceRiskControls: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
      questionnaires: `(limit: 1000) {
        items {
          id
          system {
            id
            questionnaires {
              items {
                id
                __typename
                questionnaire {
                  id
                  assignees {
                    items {
                      id
                      __typename
                      pointOfContactID
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }`,
    },
  );

  //Retrieve risk change links separately as a list query to avoid limits
  const riskChangeLinks = await ListQueryBy({
    query: listSystemChangeLinksBySystemID,
    variables: {
      systemID,
    },
  });

  //Retrieve the rest of the system to delete
  const systemToDelete = await QueryGetItem({
    query: resourcesToDelete,
    itemId: systemID,
  });

  updateAssigneesToDelete({ systemToDelete, systemOwner, systemAdmin });

  //If at least the system id is available attempt to delete the system
  if (systemToDelete?.id) {
    InfoLogger(`Deleting System - ${systemToDelete?.id}`);
    systemToDelete.riskChangeLinks = riskChangeLinks;
    await GenericDeleteGQL({ objectToDelete: systemToDelete });
  } else {
    ErrorLogger(
      "Error: Could not identify system to delete. system.id is null or undefined. Could it be already deleted?",
    );
    return system;
  }
};

const updateAssigneesToDelete = ({ systemToDelete, systemOwner, systemAdmin }) => {
  const questionnaires = systemToDelete?.questionnaires?.items ?? [];

  questionnaires.forEach((item) => {
    const questionnaireLinks = item?.system?.questionnaires?.items ?? [];

    questionnaireLinks.forEach((link) => {
      const assignees = link?.questionnaire?.assignees?.items ?? [];

      link.questionnaire.assignees.items = assignees.filter(
        (assignee) => assignee?.pointOfContactID === systemOwner || assignee?.pointOfContactID === systemAdmin,
      );
    });
  });
};

const listSystemChangeLinksBySystemID = /* GraphQL */ `
  query ListSystemChangeLinksBySystemID(
    $systemID: ID
    $riskChangeID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemChangeLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemChangeLinksBySystemID(
      systemID: $systemID
      riskChangeID: $riskChangeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        __typename
      }
      nextToken
    }
  }
`;
