import { useEffect, useState } from "react";

import { TOOL_TYPES } from "@rivial-security/integration-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { getKnowBe4PhishingTest } from "../../../Tools/KnowBe4/Phishing/graphql/getKnowBe4PhishingTest";
import { useKnowBe4PhishingDataGrid } from "../../../Tools/KnowBe4/Phishing/hooks/useKnowBe4PhishingDataGrid";
import { fetchData } from "../../../Tools/Tool/functions/fetchData";

const activityByEvidence = /* GraphQL */ `
  query ActivityByEvidence(
    $evidenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEvidence(
      evidenceID: $evidenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phishingID
      }
      nextToken
    }
  }
`;
/**
 * @description Display phishing tests for an evidence
 * @param item
 * @param recordEvidence
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
export const useEvidencePhishing = ({ item, recordEvidence, organizationID }) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;

  const [isLoading, setIsLoading] = useState(false);

  const grid = useKnowBe4PhishingDataGrid({
    queryConfig: {
      queryExternalApi: null,
    },
    gridConfig: {
      gridHeight: "100%",
    },
    fields: [
      {
        name: "comment",
        flex: 1,
      },
    ],
  });

  useEffect(() => {
    if (item) {
      grid?.setIsLoading(true);
      ListQueryBy({
        query: activityByEvidence,
        variables: {
          evidenceID: item?.id,
        },
      }).then((data) => {
        setIsLoading(true);

        (async function () {
          const phishingTests = [];

          for (const activity of data) {
            if (activity?.phishingID) {
              await fetchData({
                toolType: toolType,
                input: {
                  query: getKnowBe4PhishingTest,
                  queryInput: {
                    id: activity?.phishingID,
                  },
                },
                organizationID,
              })
                .then((res) => {
                  /**
                   * Assign a comment to a phishing test
                   */
                  if (activity?.comment) {
                    Object.assign(res, { comment: activity?.comment });
                  }
                  res["id"] = res?.pst_id;
                  phishingTests.push(res);
                })
                .catch((err) => ErrorLogger(err));
            }
          }
          grid?.setData(phishingTests);
          grid?.setIsLoading(false);
          setIsLoading(false);
        })();
      });
    }
  }, [item]);

  const display = <div style={{ height: "100%" }}>{isLoading ? <LoadingSpinner /> : grid.gridDisplay}</div>;

  return {
    ...grid,
    display,
  };
};

const EvidencePhishing = (props) => useEvidencePhishing(props).display;

export default withOrganizationCheck(EvidencePhishing);
