import { useQueryGetItem } from "../../../hooks/graphql/useQueryGetItem";
import { getQuery } from "../graphql/customDashboardGraphql";

/**
 * Fetches a CustomDashboard from the databased based on the ID
 * @param {string} itemId - The ID of the CustomDashboard to fetch
 * @returns {{isLoading: boolean, setInputId: (value: (((prevState: *) => *) | *)) => void, inputId: *, item: unknown, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, resetIndex: number, reset: function(): void, setItem: (value: unknown) => void}}
 */
export const useCustomDashboardQuery = ({ itemId }) => {
  return useQueryGetItem({
    query: getQuery,
    itemId: itemId,
    disableRoleChecking: true,
  });
};
