"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlankLine = void 0;
/**
 * Creates a blank line break between two elements in the SFDT
 */
const createBlankLine = () => {
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {
                    bidi: false,
                },
                text: "",
            },
        ],
    };
};
exports.createBlankLine = createBlankLine;
