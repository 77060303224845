export const getNotes = /* GraphQL */ `
  query GetRiskControl($id: ID!) {
    getRiskControl(id: $id) {
      id
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;
