import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import { CustomPointOfContactFieldWithoutEvidenceChange } from "../../ProgramElement/components/CustomPointOfContactFieldWithoutEvidenceChange";
import ImplementationLevelDate from "../customFields/ImplmentationLevelDate";

const { updateMutation: updateImplementationLevel, getQuery: getImplementationLevel } = generateGraphql(
  "ImplementationLevel",
  ["level", "indicator", "frequency", "pointOfContact", "date", "accountable", "consulted", "informed"],
  {
    pointOfContact: `{
      id
      firstName
      lastName
      ownerGroup
    }`,
  },
);

const ImplementationLevelDetailsCard = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.IMPLEMENTATION_LEVEL;

  const fields = [
    "description",
    "level",
    "indicator",
    "frequency",
    "date",
    "accountable",
    "consulted",
    "informed",
    "date",
  ];

  if (!props.isTemplate) {
    fields.push("pointOfContact");
  }

  const fieldNameDictionary = {
    id: "ID",
    level: "Level",
    indicator: "Indicator",
    description: "Description",
    frequency: "Frequency (days)",
    pointOfContact: "Point of Contact",
    accountable: "Accountable",
    consulted: "Consulted",
    informed: "Informed",
    date: "Date",
  };

  const FrequencyComponent = (props) => {
    return (
      <GenericEditField
        inputType="number"
        item={props.item}
        module={module}
        resource={resource}
        field="frequency"
        mutation={updateImplementationLevel}
        minInputNumber={1}
        maxInputNumber={365}
        resetFunction={props.resetFunction}
      />
    );
  };

  const LevelComponent = (props) => {
    return (
      <GenericEditField
        inputType="number"
        item={props.item}
        module={module}
        resource={resource}
        field="level"
        mutation={updateImplementationLevel}
        minInputNumber={1}
        maxInputNumber={5}
        resetFunction={props.resetFunction}
      />
    );
  };

  const customFields = [
    {
      field: "pointOfContact",
      component: <CustomPointOfContactFieldWithoutEvidenceChange />,
    },
    {
      field: "date",
      component: <ImplementationLevelDate />,
    },
    {
      field: "frequency",
      component: <FrequencyComponent />,
    },
    {
      field: "level",
      component: <LevelComponent />,
    },
  ];

  const detailsConfig = {
    fields,
    customFields,
    fieldNameDictionary,
    updateMutation: updateImplementationLevel,
  };

  const queryConfig = {
    query: getImplementationLevel,
    itemId: props.itemId,
  };

  const config = {
    header: "Implementation Level Details",
    route: `#/governance/implementationLevels/`,
    headerButtons: [],
    disableRoute: props.isTemplate || props.disableRoute,
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    config,
    module,
    resource,
  });

  return <>{props.tableDisplay ? detailsCard.tableDisplay : detailsCard.display}</>;
};

export default withDetailsPage(ImplementationLevelDetailsCard);
