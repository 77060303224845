/**
 * Converts a boolean string into the proper input for a boolean field mutation
 *
 * @param value
 * @returns {string|boolean}
 */
export const handleBooleanFormat = ({ value }) => {
  const lowerCase = value?.toLowerCase();

  // handle TRUE cases
  if (lowerCase === "true" || lowerCase === "yes") {
    return true;
  }

  // handle FALSE cases
  if (lowerCase === "false" || lowerCase === "no") {
    return false;
  }

  // default return empty string
  return "";
};
