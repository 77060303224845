export const vendorControlDefinition = {
  routes: {
    grid: "vendors/subControls",
    details: "vendors/subControls",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:list",

  //[COMPONENTS]
  // gridHook: useVendorControlDataGrid,
};
