import { useEffect } from "react";
import { Input } from "reactstrap";
import { v4 as uuid } from "uuid";

/**
 * Can be used to select files for upload
 * @param {function} onChange - callback for tracking changes in this input (called on every new file selection)
 * @param {string} [fieldName = ""] - used to create a unique data test id
 * @param {object[] | string} [value = ""] - used to clear the files field (set to an empty string to do so)
 * @param {object} fileInputConfig - contain options such as `acceptMultiple` that allow to customize the input
 * @param {object} fileInputConfig.acceptFileTypes - a string of comma separated file types (e.g. ".pdf,.docx")
 */
const CustomFileInput = ({ onChange, value = "", fileInputConfig = {} }) => {
  //Unique id for this input in the form
  const id = `custom_file_input${uuid()}`;

  //Destructing the configuration parameter
  const { acceptMultiple = false, acceptFileTypes } = fileInputConfig;

  //Handles new file selection by calling the onChange callback
  const handleFileChange = () => {
    const element = document.getElementById(id);
    if (element) {
      onChange(Array.from(element.files));
    }
  };

  //Used to reset the selected files when a form is cleared
  useEffect(() => {
    if (value === "") {
      const element = document.getElementById(id);
      if (element) {
        element.value = "";
      }
    }
  }, [value]);

  return (
    <Input
      data-testid={"custom_input_file"}
      id={id}
      type="file"
      multiple={acceptMultiple}
      accept={acceptFileTypes}
      onChange={() => handleFileChange()}
    />
  );
};

export default CustomFileInput;
