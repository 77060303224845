import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useRecommendationDetailsAccordion } from "../hooks/useRecommendationDetailsAccordion";

/**
 * Component for viewing the details of a single Recommendation
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RecommendationDetails = (props) => useRecommendationDetailsAccordion(props).display;

export default withDetailsPage(RecommendationDetails, {
  title: "Recommendation Details",
});
