import React from "react";
import { Button } from "reactstrap";

/**
 * @description Display landing page button
 * @param {object} item - action item
 * @returns {JSX.Element}
 * @constructor
 */
const LandingPageUrl = ({ item }) => {
  return (
    <>
      {item.landingPageUrl ? (
        <Button size="sm" className="btn-pill" color="primary" href={`${item.landingPageUrl}${item?.id}`}>
          Go to Landing Page
        </Button>
      ) : (
        "No Landing Page"
      )}
    </>
  );
};

export default LandingPageUrl;
