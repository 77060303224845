"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllSystems = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const getSystem_1 = require("../../createMonteCarloSystemInput/graphql/getSystem");
/**
 * Returns all Systems for the Organization.
 * Used to calculate changes across all systems.
 * @param organizationID
 * @param queryConfig
 */
const getAllSystems = async (organizationID) => {
    const associatedSystems = [];
    if (organizationID !== null && organizationID !== undefined) {
        /**
         * Query all Systems for the Org
         */
        console.log("Querying All Systems for this Organization..");
        const queryItems = await (0, appsync_utils_1.ListQuery)({
            query: getSystem_1.listSystemDetails,
            organizationID,
        });
        console.log("Querying All Systems for this Organization Result: ", queryItems);
        /**
         * Traverse RiskControl object to obtain all associated systems
         */
        if (queryItems) {
            /**
             * Push these to the final array
             */
            associatedSystems.push(...queryItems);
        }
        else {
            console.log("Error: System Query Returned null");
        }
    }
    else {
        throw Error("Invalid OrganizationID Input!");
    }
    return associatedSystems;
};
exports.getAllSystems = getAllSystems;
