import { TimelineDot } from "@mui/lab";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { getDotColor } from "../../../Evidence/components/EvidenceActivityItem/functions/getDotColor";
import { getDotIcon } from "../../../Evidence/components/EvidenceActivityItem/functions/getDotIcon";

/**
 * Displays the artifact type
 * @param {object} item - the artifact object with type property extracted from its evidence activity
 * @returns {JSX.Element}
 */
const ArtifactTypeCustomField = ({ item }) => {
  const type = item?.type || item?.evidenceActivity?.type;
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <TimelineDot color={getDotColor(type)} sx={{ margin: 0 }}>
        {getDotIcon(type)}
      </TimelineDot>
      <div style={{ marginLeft: ".5em" }}>{convertCamelCaseToSentence(type)}</div>
    </div>
  );
};
export default ArtifactTypeCustomField;
