import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

/**
 * @description creating an info model
 * @param {object} props - props of the component
 * @returns {JSX.Element} - info modal
 * @constructor
 */
const InfoModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onClickHandler = (e) => {
    if (!props.disableEdits) {
      e.stopPropagation();
      setModalIsOpen(!modalIsOpen);
    }
  };

  return (
    <>
      {React.cloneElement(props.modalButton, {
        onClick: (e) => onClickHandler(e),
      })}
      <Modal style={{ minWidth: props.minWidth }} isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        {props.title || props.headerButtons ? (
          <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
            {props.title}
            {props.headerButtons}
          </ModalHeader>
        ) : null}
        <ModalBody>
          {props.body
            ? React.cloneElement(props.body, {
                toggleModal: () => setModalIsOpen(!modalIsOpen),
              })
            : "Nothing to see here.."}
        </ModalBody>
      </Modal>
    </>
  );
};

InfoModal.propTypes = {
  minWidth: PropTypes.string,
  title: PropTypes.string, // An optional title
  headerButtons: PropTypes.object, // Any buttons that you want present in the header
  body: PropTypes.object, // The body of the modal
  modalButton: PropTypes.object, // The passed in button is just for styling, no need to pass onClick to it.
  disableEdits: PropTypes.bool,
};

InfoModal.defaultProps = {
  minWidth: "80vw",
  // title: "Generic Modal",
  headerButtons: null,
  body: null,
  modalButton: (
    <i
      style={{ marginRight: "1em", marginLeft: "1em", cursor: "pointer" }}
      id={"details_modal"}
      className="icon-info"
    />
  ),
  disableEdits: false,
};

export default InfoModal;
