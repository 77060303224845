export const updateSystemRiskControlOverride = `mutation UpdateSystem($input: UpdateSystemInput!) {
  updateSystem(input: $input) {
     id
     riskControlOverrides {
       enabledFields
       riskControlId
       isCompliant
       costOfControl
       controlEffectiveness
       annualRateReduction
       __typename
     }
  }
}
`;
