"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStandardLossAmountWithOverride = void 0;
const checkSystemForRiskOverride_1 = require("./checkSystemForRiskOverride");
const findIndexOfRiskOverride_1 = require("./findIndexOfRiskOverride");
const getStandardLossAmount_1 = require("./getStandardLossAmount");
const isRiskOverrideFieldEnabled_1 = require("./isRiskOverrideFieldEnabled");
const getStandardLossAmountWithOverride = (system, systemRiskLink) => {
    const indexOfRiskOverride = system?.riskOverrides
        ? (0, findIndexOfRiskOverride_1.findIndexOfRiskOverride)(system.riskOverrides, systemRiskLink?.risk)
        : -1;
    const doesOverrideExist = (0, checkSystemForRiskOverride_1.checkSystemForRiskOverride)(system.riskOverrides, systemRiskLink?.risk) !== false;
    const isOverrideEnabled = doesOverrideExist &&
        (0, isRiskOverrideFieldEnabled_1.isRiskOverrideFieldEnabled)(system.riskOverrides?.[indexOfRiskOverride], "confidenceIntervalUpper");
    const overrideValue = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        system.riskOverrides[indexOfRiskOverride]?.confidenceIntervalUpper;
    let value = 0;
    if (isOverrideEnabled) {
        if (overrideValue && !isNaN(overrideValue)) {
            value = overrideValue;
        }
        else {
            value = 0.0;
        }
    }
    else {
        value = (0, getStandardLossAmount_1.getStandardLossAmount)(system, systemRiskLink);
    }
    return value;
};
exports.getStandardLossAmountWithOverride = getStandardLossAmountWithOverride;
