import React, { useEffect } from "react";

import { useForm } from "../../../../hooks/views/useForm";
import { useVendorSubControlDataGrid } from "../../VendorSubControls/hooks/useVendorSubControlDataGrid";
import { CreateVendorCertification } from "../functions/CreateVendorCertification";
import { useVendorSubControlTemplates } from "../hooks/useVendorSubControlTemplates";

/**
 * @description Add controls to a vendor certification
 * @param {string} organizationID - Organization ID
 * @param {function} toggleModal - Toggle modal function
 * @param {function} resetFunction - Reset function
 * @param {function} getNewItem - Get new item function
 * @returns {JSX.Element}
 * @constructor
 */

const AddVendorCertification = ({ organizationID, toggleModal, resetFunction, getNewItem }) => {
  const queryConfig = {
    query: null,
  };

  const vendorSubControlGrid = useVendorSubControlDataGrid({
    organizationID,
    queryConfig,
    enableSelection: true,
    resetFunction,
  });

  // get vendor sub control templates
  const { subControls } = useVendorSubControlTemplates(organizationID, vendorSubControlGrid?.setIsLoading);

  useEffect(() => {
    const res = [...subControls];
    res.sort((a, b) => a.statementNumber - b.statementNumber);
    vendorSubControlGrid.setData(res);
  }, [subControls]);

  const formHook = useForm({
    submitFunction: async (input) => {
      const item = await CreateVendorCertification({
        name: input.name,
        controls: vendorSubControlGrid?.selectedData,
        organizationID,
      });

      toggleModal && toggleModal();
      return item;
    },
    getNewItem,
    fieldConfig: {
      name: {
        required: true,
        label: "Pre-Qualified Certification Name",
        tooltip: "Ex. PCI, ISO, SOC 1, SOC 2",
      },
      selectedControls: {
        inputType: "custom",
        removeFromInput: true,
        label: "Associated Controls",
        tooltip: "Select Controls that this Pre-Qualified Certification will automatically satisfy",
        customConfig: {
          component: <div style={{ height: "30em" }}>{vendorSubControlGrid.display}</div>,
        },
      },
    },
  });

  return (
    <div>
      <div className="e-card" id="basic">
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-title">Add Vendor Certification</div>
          </div>
        </div>
        <div className="e-card-content">{formHook.display}</div>
      </div>
    </div>
  );
};

export default AddVendorCertification;
