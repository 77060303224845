import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

const NoPermissionIcon = ({ message }) => {
  const ref = useRef(null);

  return (
    <div>
      <i ref={ref} style={{ color: "black" }} className="icon-lock" />
      <UncontrolledTooltip placement="right" target={ref}>
        {message || "No permission"}
      </UncontrolledTooltip>
    </div>
  );
};

export default NoPermissionIcon;
