import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import { OBSERVATION } from "../constants/OBSERVATION";

/**
 * Displays the Risk field for an Observation.
 * @param {object} item - The Observation to display the Risk field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const Risk = ({ item, updateItemById, ...props }) => {
  const module = OBSERVATION.module;
  const resource = OBSERVATION.resource;
  const field = OBSERVATION.fields.risk;

  const options = OBSERVATION?.fieldConfig?.risk?.valueOptions?.map((rating) => {
    return {
      text: convertCamelCaseToSentence(rating),
      value: rating,
    };
  });

  return (
    <GenericFieldDropdown
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql(OBSERVATION.typename, [field]).updateMutation}
      options={options}
      format="sentence"
      updateItemById={updateItemById}
      {...props}
    />
  );
};

export default Risk;
