import { useEffect, useRef, useState } from "react";

/**
 * @description Acts as a useState hook but also returns a ref to the state (useful to avoid stale closures)
 * @param {*} initialState - The initial state of the variable
 * @example const [toasts, setToasts, toastsRef] = useRefState([]);
 * @returns {[*, function, *]}
 */
export const useRefState = (initialValue) => {
  const ref = useRef(initialValue);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value !== ref.current) {
      ref.current = value;
    }
  }, [value]);

  return [value, setValue, ref];
};
