import { useEffect, useState } from "react";

import { getDefaultDataGridPinnedColumns } from "./functions/getDefaultDataGridPinnedColumns";

/**
 * Holds the pinned columns' info in state for 'controlled' pinning, prioritizes grid view info as the source of truth
 * @param {object} targetView - the data grid view that the user would like to see, either from preferences or adjusted through the UI
 * @param {object[]} fields - fields prop passed through from useDataGrid
 * @return {{pinnedColumns: object}}
 */
export const useDataGridPinnedColumns = ({ targetView, fields }) => {
  const [pinnedColumns, setPinnedColumns] = useState({});

  // Configures the pinned columns for all fields
  useEffect(() => {
    let newPinnedColumns = targetView?.pinnedColumns;
    if (!newPinnedColumns) {
      newPinnedColumns = getDefaultDataGridPinnedColumns({ fields });
    }
    setPinnedColumns(newPinnedColumns);
  }, [targetView?.pinnedColumns]);

  return { pinnedColumns };
};
