/**
 * Simplifies the procedures nested field object to use in graphql (useful for get graphql)
 * @param {boolean} includeNotes - true if need to include procedure notes in the response
 * @return {{procedures: string}}
 */
export const getProceduresNestedFields = ({ includeNotes = false } = {}) => {
  const procedureQuery = `{
    name description content
    responseSteps {
      id
      description
      completed
      ${includeNotes === true ? "notes { id type ownerGroup author timeStamp content tag observationID }" : ""}
    }
    ${includeNotes === true ? "notes { id type ownerGroup author timeStamp content tag observationID }" : ""}
  }`;

  return {
    procedures: `{
      parentID
      linkID
      parentTypename
      name
      detect ${procedureQuery}
      analyze ${procedureQuery}
      contain ${procedureQuery}
      eradicate ${procedureQuery}
      recover ${procedureQuery}
      postIncident ${procedureQuery}
    }`,
  };
};

/**
 * Simplifies the procedures nested field string to use in graphql (useful for list graphql)
 * @param {boolean} includeNotes - true include procedure notes in the response
 * @return {string}
 */
export const getProceduresNestedFieldsString = ({ includeNotes = false } = {}) => {
  const procedureQuery = `{
    name description content
    responseSteps {
      id
      description
      completed
      ${includeNotes === true ? "notes { id type ownerGroup author timeStamp content tag observationID }" : ""}
    }
    ${includeNotes === true ? "notes { id type ownerGroup author timeStamp content tag observationID }" : ""}
  }`;

  return `procedures {
    parentID
    linkID
    parentTypename
    name
    detect ${procedureQuery}
    analyze ${procedureQuery}
    contain ${procedureQuery}
    eradicate ${procedureQuery}
    recover ${procedureQuery}
    postIncident ${procedureQuery}
  }`;
};

export const commonProcedures = ["detect", "analyze", "contain", "eradicate", "recover", "postIncident"];
