import { Badge } from "reactstrap";

import Loader from "../../../../utils/LoadingComponents/Loader";
import { USER_PREFERRED_MFA_TYPES } from "../enums/USER_PREFERRED_MFA_TYPES";

/**
 * Simple badge component for displaying the MFA_TYPE for a user
 * @param {object} item - the user object
 * @param {string} item.preferredMFA - TOTP, SMS, or NONE
 * @returns {JSX.Element}
 * @constructor
 */
const UserPreferredMFA = ({ item }) => {
  if (!item) {
    return <Loader />;
  }

  switch (item?.preferredMFA) {
    case USER_PREFERRED_MFA_TYPES.SMS:
      return <Badge color={"warning"}>Text Message</Badge>;
    case USER_PREFERRED_MFA_TYPES.TOTP:
      return <Badge color={"success"}>Authenticator</Badge>;
    case USER_PREFERRED_MFA_TYPES.NONE:
      return <Badge color={"danger"}>None</Badge>;
    default:
      return <Badge color={"secondary"}>Unknown</Badge>;
  }
};

export default UserPreferredMFA;
