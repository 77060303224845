/**
 * Returns a custom explanation for what the GreatestRiskKRIChange custom field represents
 * @return {JSX.Element}
 */
const GreatestRiskKRIChangeTooltip = () => {
  return (
    <div>
      The residual risk of an Information System is represented by the Key Risk Indicator (KRI) with the highest
      residual risk for that system. This column represents the change to the highest risk KRI, use the status to
      quickly gather information on the change applied to each system.
      <br />
      <br />
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#0288D1",
              color: "white",
              padding: ".2em",
            }}
          >
            {"Unchanged"}
          </span>{" "}
          - neither the highest risk KRI nor its residual risk have changed for the system
        </span>
      </p>
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#ED6C02",
              color: "white",
              padding: ".2em",
            }}
          >
            {"Updated"}
          </span>{" "}
          - the residual risk of the highest risk KRI has changed for the system
        </span>
      </p>
      <p>
        <span>
          <span
            style={{
              backgroundColor: "#2E7D32",
              color: "white",
              padding: ".2em",
            }}
          >
            {"New"}
          </span>{" "}
          - the residual risk of the highest risk KRI has changed enough for a different KRI to have a higher residual
          risk for the system
        </span>
      </p>
    </div>
  );
};

export default GreatestRiskKRIChangeTooltip;
