import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";

import { useModal } from "../../../../hooks/views/useModal";
import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { useTransferList } from "../../../../hooks/views/useTransferList/useTransferList";
import { useCreateResponseTeam } from "../../ResponseTeams/hooks/useCreateResponseTeam";
import { useResponseTeamDataGrid } from "../../ResponseTeams/hooks/useResponseTeamDataGrid";
import { handleLinkResponseTeams } from "../functions/handleLinkResponseTeams";
import { handleUnlinkResponseTeams } from "../functions/handleUnlinkResponseTeams";

/**
 * @description Display linking UI for an exercise and response teams
 * @param {object} item - exercise
 * @param {string} organizationID - selected organization
 * @param {function} resetFunction - re-fetch parent data
 * @returns {JSX.Element}
 * @constructor
 */
const ExerciseResponseTeamsButton = ({ item, organizationID, resetFunction }) => {
  const createResponseTeamCallback = (item) => {
    transferList.setRightItems((notLinked) => [...notLinked, item]);
    transferList.createResourceModal.setModalIsOpen(false);
  };

  const pleaseWait = usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 1,
    confirmationText: "Congratulations, you've successfully modified attached Response Teams!",
    steps: [
      {
        id: "unlink",
        text: "Unlinking Response Teams",
      },
      {
        id: "link",
        text: "Link Response Teams",
      },
    ],
  });

  const createResponseTeam = useCreateResponseTeam({
    organizationID,
    getNewItem: createResponseTeamCallback,
  });

  const handleSubmit = async () => {
    pleaseWait.setModalIsOpen(true);
    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    await handleUnlinkResponseTeams(transferList.rightItems, item);

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    await handleLinkResponseTeams(transferList.leftItems, item, organizationID);

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setFinished(true);
    resetFunction && resetFunction();
    modal.setModalIsOpen(false);
  };

  const transferList = useTransferList({
    handleSubmit,
    leftTitle: "Linked Response Teams",
    rightTitle: "Available Response Teams",
    createResourceComponent: createResponseTeam.display,
    listTitleAdapter: (team) => `${team?.name}`,
    typename: "ResponseTeam",
  });

  /**
   * Get Response Teams list
   */
  const responseTeamsGrid = useResponseTeamDataGrid({ organizationID });

  /**
   * Set data for the transfer list
   */
  useEffect(() => {
    const existingTeams = [];

    if (item?.responseTeams?.items) {
      existingTeams.push(...item.responseTeams.items.map((teamLink) => teamLink?.responseTeam));
    }

    /**
     * Get and set already linked teams
     */
    transferList.setLeftItems(existingTeams);

    const temp = [];

    for (const team of responseTeamsGrid.data) {
      if (!existingTeams.some((x) => x.id === team.id)) {
        temp.push(team);
      }
    }

    /**
     * Set all organization teams
     */
    transferList.setRightItems(temp);
  }, [item, responseTeamsGrid?.data]);

  const modal = useModal(
    "Assign Teams for this Exercise",
    <span>
      {pleaseWait.modal}
      {transferList.display}
    </span>,
    <Button title={"Assign Teams for this Exercise"}>
      <AddCircleOutlineOutlinedIcon />
    </Button>,
    {
      width: "90vw",
    },
  );

  return modal.modalButton;
};

export default ExerciseResponseTeamsButton;
