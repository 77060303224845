import { openReportDocument } from "./openReportDocument";

/**
 * @description Open a report document in the document editor
 * @param {string} id - report document id
 * @param {object[]} reportDocuments - report documents array
 * @param organizationID
 * @returns {Promise<object | null>}
 */
export const openDocumentVersion = async ({ id, reportDocuments, organizationID }) => {
  const reportDocument = reportDocuments?.find((x) => x.id === id);

  return await openReportDocument({
    reportDocument,
    organizationID,
  });
};
