"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupTargetVulnerabilityLinkPriority = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Groups together the priority value of unique target links
 * only looks at unfixed links
 * @param {object} uniqueTargetLinks - object with key as id of link and value as link object
 * @returns {number}
 */
const groupTargetVulnerabilityLinkPriority = ({ uniqueTargetLinks } = {}) => {
    const uniqueTargetLinksItems = uniqueTargetLinks ? Object.values(uniqueTargetLinks) : [];
    if (!(0, func_utils_1.isNonEmptyArray)(uniqueTargetLinksItems)) {
        return 0;
    }
    let priority = 0;
    const amountOfUniqueLinks = uniqueTargetLinksItems.length;
    if (amountOfUniqueLinks > 0) {
        let sumOfUnfixedPriorities = 0;
        let countOfUnfixedLinks = 0;
        for (const targetLinkKey in uniqueTargetLinks) {
            const targetLink = uniqueTargetLinks[targetLinkKey];
            //Skip links that are considered fixed
            const targetLinkStatus = targetLink?.status;
            if (targetLinkStatus !== "notFixed") {
                continue;
            }
            const targetLinkPriority = targetLink?.priority;
            if (targetLinkPriority) {
                sumOfUnfixedPriorities += targetLinkPriority;
            }
            countOfUnfixedLinks++;
        }
        //When all links are fixed, priority remains at 0, otherwise average of unfixed links
        if (countOfUnfixedLinks !== 0) {
            priority = sumOfUnfixedPriorities / countOfUnfixedLinks;
        }
    }
    return priority;
};
exports.groupTargetVulnerabilityLinkPriority = groupTargetVulnerabilityLinkPriority;
