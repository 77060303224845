import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Display and edit a actionItem completionDate
 * @param item
 * @param resetFunction
 * @param module
 * @param resource
 * @param field
 * @returns {JSX.Element}
 * @constructor
 */
const ActionItemCompletionDate = ({
  item,
  module = modules.GOVERNANCE,
  resource = resources.ACTION_ITEM,
  field = "completionDate",
}) => {
  return (
    <div key={`actionItem-completionDate-${item?.id}`}>
      <GenericEditFieldV3
        inputType={GENERIC_FIELD_TYPES.DATE}
        item={item}
        field={field}
        mutation={generateGraphql("GenericActionItem").updateMutation}
        resource={resource}
        module={module}
      />
    </div>
  );
};

export default ActionItemCompletionDate;
