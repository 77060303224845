import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const PointOfContactList = React.lazy(
  () => import("@views/OrganizationManager/PointOfContacts/components/PointOfContactDataGrid"),
);
const PointOfContactDetails = React.lazy(
  () => import("@views/OrganizationManager/PointOfContacts/components/PointOfContactDetails"),
);
const RiskTolerance = React.lazy(() => import("@views/Risk/RiskConfig/RiskTolerance/components/RiskTolerance"));

const RoleList = React.lazy(() => import("@views/OrganizationManager/Roles/components/RoleDataGrid"));
const RoleDetailsCard = React.lazy(() => import("@views/OrganizationManager/Roles/components/RoleDetailsCard"));

const GeneralConfig = React.lazy(() => import("@views/OrganizationManager/GeneralConfig/components/GeneralConfig"));

const Locations = React.lazy(() => import("@views/OrganizationManager/Locations/components/LocationsGrid"));
const LocationDetails = React.lazy(() => import("@views/OrganizationManager/Locations/components/LocationDetails"));

const Automations = React.lazy(() => import("@views/OrganizationManager/Automations/components/AutomationsDashboard"));
const AutomationDetails = React.lazy(
  () => import("@views/OrganizationManager/Automations/components/AutomationDetails"),
);

const EmailTemplates = React.lazy(
  () => import("@views/OrganizationManager/EmailTemplates/components/EmailTemplateDashboard"),
);
const EmailTemplateDetails = React.lazy(
  () => import("@views/OrganizationManager/EmailTemplates/components/EmailTemplateDetails"),
);

const Jobs = React.lazy(() => import("@views/OrganizationManager/Jobs/components/JobsGrid"));
const JobDetails = React.lazy(() => import("@views/OrganizationManager/Jobs/components/JobDetails"));

const Departments = React.lazy(() => import("@views/OrganizationManager/Departments/components/DepartmentGrid"));
const DepartmentDetails = React.lazy(
  () => import("@views/OrganizationManager/Departments/components/DepartmentDetails"),
);
const SecurityDashboard = React.lazy(
  () => import("@views/OrganizationManager/SecurityDashboard/components/SecurityDashboard"),
);

const Questionnaires = React.lazy(
  () => import("@views/OrganizationManager/Questionnaires/components/QuestionnaireDataGrid"),
);

const QuestionnaireDetails = React.lazy(
  () => import("@views/OrganizationManager/Questionnaires/components/QuestionnaireDetails"),
);

export const organizationManagerNavigation: NavigationModule = {
  moduleName: modules.ORGANIZATION_MANAGER,
  sectionName: NavigationSectionName.MANAGEMENT,
  name: "Organization",
  path: "/organization_manager",
  defaultRoutePath: "/contacts",
  icon: IconName.ORGANIZATION,
  routes: [
    // Points of Contact
    {
      path: "/contacts",
      name: "Points of Contact",
      component: PointOfContactList,
      navigationBar: {
        name: "Contacts",
      },
    },
    {
      path: "/contacts/:id",
      name: "Point of Contact Details",
      component: PointOfContactDetails,
    },

    // Departments
    {
      path: "/departments",
      name: "Departments",
      component: Departments,
    },
    {
      path: "/departments/:id",
      name: "Department Details",
      component: DepartmentDetails,
    },

    // Role Config
    {
      path: "/roles",
      name: "Role Management",
      component: RoleList,
    },
    {
      path: "/roles/:id",
      name: "Role Details Card",
      component: RoleDetailsCard,
    },

    // Security Dashboard
    {
      path: "/security_dashboard",
      name: "Security Dashboard",
      component: SecurityDashboard,
    },

    // Questionnaires
    {
      path: "/questionnaires",
      name: "Questionnaires",
      component: Questionnaires,
    },
    {
      path: "/questionnaires/:id",
      name: "Questionnaire Details",
      component: QuestionnaireDetails,
    },

    // Email Templates
    {
      path: "/email_templates",
      name: "Email Templates",
      component: EmailTemplates,
    },
    {
      path: "/email_templates/:id",
      name: "Email Template Details",
      component: EmailTemplateDetails,
    },

    // Automations
    {
      path: "/automations",
      name: "Automations",
      component: Automations,
    },
    {
      path: "/automations/:id",
      name: "Automation Details",
      component: AutomationDetails,
    },

    // General Config
    {
      path: "/general_config",
      name: "General Configuration",
      component: GeneralConfig,
      navigationBar: {
        name: "Configuration",
      },
    },

    // Jobs
    {
      path: "/jobs",
      name: "Jobs",
      component: Jobs,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/jobs/:id",
      name: "Job Details",
      component: JobDetails,
    },

    // Locations
    {
      path: "/locations",
      name: "Locations",
      component: Locations,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/locations/:id",
      name: "Location Details",
      component: LocationDetails,
    },

    {
      path: "/risk_tolerance",
      name: "Risk Tolerance",
      component: RiskTolerance,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
