import React, { useEffect } from "react";

import { useForm } from "../../../../../hooks/views/useForm/useForm";

/**
 * @description Get Penetration Tester name and email
 * @param input
 * @param setInput
 * @return {JSX.Element}
 * @constructor
 */
const AddAssessmentPenetrationTesterInfo = ({ input, setInput }) => {
  const form = useForm({
    fieldConfig: {
      penTester: {
        label: "Penetration Tester Name",
        defaultValue: input?.penTester,
      },
      penTesterEmail: {
        label: "Penetration Tester Email",
        tooltip: "Used to send an email when scan is done",
        defaultValue: input?.penTesterEmail,
      },
    },
    disableSubmitButton: true,
  });

  useEffect(() => {
    Object.assign(input, { ...form?.input });
  }, [JSON.stringify(form?.input)]);

  return <>{form.display}</>;
};

export default AddAssessmentPenetrationTesterInfo;
