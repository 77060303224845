import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { OBSERVATION } from "../constants/OBSERVATION";

/**
 * Displays the Needs insurancePolicyValidated boolean field for an Observation.
 * @param {OBSERVATION} item - The Observation to display the switch field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {object} ...props - The props to pass to GenericEditFieldV3.
 * @returns {JSX.Element}
 * @constructor
 */
const InsuranceValidated = ({ item, updateItemById, ...props }) => {
  const module = OBSERVATION.module;
  const resource = OBSERVATION.resource;
  const field = OBSERVATION.fields.insurancePolicyValidated;

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql(OBSERVATION.typename, [field]).updateMutation}
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      updateItemById={updateItemById}
      tooltip={OBSERVATION.fieldConfig.insurancePolicyValidated.description}
      {...props}
    />
  );
};

export default InsuranceValidated;
