"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHtmlRow = void 0;
/**
 * Creates a single row for an html table as a string
 * @param {string[]} fields - the fields to show and in what order
 * @param {object} fieldConfig - field options
 * @param {object} item - the data for the row, properties are field names
 * @returns {string} - the html for a single table row
 */
const createHtmlRow = ({ fields = [], fieldConfig = {}, item = {} }) => {
    let res = "";
    for (const field of fields) {
        let style = "border: 1px solid black;";
        const fieldWidth = fieldConfig?.[field]?.width;
        if (fieldWidth) {
            style += `width: ${fieldWidth};`;
        }
        res = res.concat(`
        <td style="${style}">
          ${item[field] ? item[field] : ""}
        </td>
      `);
    }
    return `
    <tr key={item && item.id}>
      ${res}
    </tr>
  `;
};
exports.createHtmlRow = createHtmlRow;
