import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useAssessmentDetailsAccordion } from "../hooks/useAssessmentDetailsAccordion";

/**
 * @description Assessment details components for useAssessmentDetails hook
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentDetails = (props) => useAssessmentDetailsAccordion(props).display;

export default withDetailsPage(AssessmentDetails, {
  title: "Assessment Details",
});
