import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import { ItemMutation } from "@utils/Functions/Graphql/ItemMutation";

/**
 * Create a link between a vulnerability and an assessment
 * @param {object} input
 * @param [input.assessments] {object[]}
 * @param [input.assessment] {object}
 * @param [input.vulnerabilities] {object[]}
 * @param [input.vulnerability] {object}
 * @param [input.target] {object}
 * @param [input.organizationID] {string}
 * @returns {Promise<void>}
 */
export const createTestingAssessmentVulnerabilityLink = async ({
  assessments,
  assessment,
  vulnerabilities,
  vulnerability,
  organizationID,
}) => {
  const { createMutation } = generateGraphql("AssessmentVulnerabilityLink", ["assessmentID", "vulnerabilityID"]);

  // pass an array of assessments and finding
  if (isNonEmptyArray(assessments) && vulnerability && vulnerability.id) {
    for (const item of assessments) {
      if (item?.id) {
        await ItemMutation(createMutation, {
          vulnerabilityID: vulnerability.id,
          assessmentID: item.id,
          ownerGroup: organizationID,
        })
          .then(() => InfoLogger("AssessmentVulnerabilityLink was successfully created."))
          .catch((e) => {
            ErrorLogger("Error! AssessmentVulnerabilityLink failed to create!", e);
          });
      }
    }
    // pass an array of findings and assessment
  } else if (isNonEmptyArray(vulnerabilities) && assessment?.id) {
    for (const item of vulnerabilities) {
      if (item && item.id) {
        await ItemMutation(createMutation, {
          vulnerabilityID: item.id,
          assessmentID: assessment.id,
          ownerGroup: organizationID,
        })
          .then(() => InfoLogger("AssessmentVulnerabilityLink was successfully created."))
          .catch((e) => {
            ErrorLogger("Error! AssessmentVulnerabilityLink failed to create!", e);
          });
      }
    }
    // pass finding and assessment
  } else if (assessment?.id && vulnerability?.id) {
    await ItemMutation(createMutation, {
      vulnerabilityID: vulnerability.id,
      assessmentID: assessment.id,
      ownerGroup: organizationID,
    })
      .then(() => InfoLogger("AssessmentVulnerabilityLink was successfully created."))
      .catch((e) => {
        ErrorLogger("Error! AssessmentVulnerabilityLink failed to create!", e);
      });
  } else {
    ErrorLogger("Error! Invalid input for AssessmentVulnerabilityLink function!");
  }
};
