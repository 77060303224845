import { useEffect } from "react";

import { getAutomationStepSmartValues } from "../functions/getAutomationStepSmartValues";
import { getAutomationTriggerSmartValues } from "../functions/getAutomationTriggerSmartValues";

/**
 * Custom hook that fetches the relevant smart values for the current step
 * @param {object} automationStep - the automation step item itself
 * @param {object[]} automations - all automations of the parent node
 * @param {object} automation - the parent automation item itself, with trigger and condition info
 * @param {function} setSmartValues - callback function to set the smart values
 * @param {boolean} [showLoopSmartValues = false] - if TRUE will show the smart values related to loop
 */
export const useAutomationStepSmartValues = ({
  automationStep,
  automation = {},
  automations,
  setSmartValues,
  showLoopSmartValues = false,
}) => {
  return useEffect(() => {
    if (automation || automationStep) {
      const values = [];

      // Get smart values based on the trigger and condition
      const triggerValues = getAutomationTriggerSmartValues({
        automationStep: {
          ...automation,

          // convert from array of triggers to singular trigger for now
          trigger:
            typeof automation.trigger === "object"
              ? automation?.trigger
              : Array.isArray(automation?.triggers)
                ? automation?.triggers?.[0]
                : undefined,

          // convert from array of conditions to singular condition for now
          condition:
            typeof automation?.condition === "object"
              ? automation.condition
              : Array.isArray(automation?.conditions)
                ? automation?.conditions?.[0]
                : undefined,
        },
      });

      if (showLoopSmartValues) {
        values.push(`loop.currentItem`);
        values.push(`loop.previousItem`);
        values.push(`loop.nextItem`);
        values.push(`loop.length`);
        values.push(`loop.index`);
      }

      if (Array.isArray(automations)) {
        // Get smart values based on automations (only includes 'loop' steps if a loop is the direct parent
        for (const ancestorStep of automations) {
          // don't get smart values for the current step
          if (ancestorStep?.id !== automationStep?.id) {
            // Handle loop steps
            if (ancestorStep.type === "loop") {
              // Add loop smart values if the loop is ALREADY a parent of this item
              // Add loop smart values if the flag is explicitly set to true (needed for adding new steps inside the loop before it's saved)
              if (
                ancestorStep?.config?.automations?.find((step) => step?.id === automationStep?.id) ||
                showLoopSmartValues
              ) {
                const loopValues = getAutomationStepSmartValues({
                  step: ancestorStep,
                });

                if (Array.isArray(loopValues)) {
                  values.push(...loopValues);
                }

                // stop processing this step so there aren't duplicate loop values
                continue;
              } else {
                // this is a loop that is not a parent, so we skip it
                continue;
              }
            }

            // Handle other steps
            const stepValues = getAutomationStepSmartValues({
              step: ancestorStep,
            });

            if (Array.isArray(stepValues)) {
              values.push(...stepValues);
            }
          }
        }
      }

      setSmartValues([...triggerValues, ...values]);
    }
  }, [JSON.stringify(automation), JSON.stringify(automations), showLoopSmartValues]);
};
