import React from "react";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import KnowBe4TrainingDataGrid from "../../../../../Tools/KnowBe4/Training/components/KnowBe4TrainingDataGrid";

/**
 * @description Allows the user to select a training campaign to upload for Evidence
 * @param {function} onSubmit - function to handle selected training
 * @returns {JSX.Element}
 * @constructor
 */
const TrainingEvidenceForm = ({ onSubmit }) => {
  const cardConfig = {
    title: "Select a Training",
    enableSticky: false,
  };

  const gridConfig = {
    selectionSettings: {
      type: "Single",
    },
    enableSelectButton: true,
    onSelectCallback: (data) => {
      onSubmit &&
        onSubmit({
          type: EVIDENCE_ACTIVITY_TYPES.TRAINING,
          training: {
            id: data?.campaign_id,
          },
        });
    },
  };

  return <KnowBe4TrainingDataGrid gridConfig={gridConfig} cardConfig={cardConfig} />;
};

export default TrainingEvidenceForm;
