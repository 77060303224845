import React from "react";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useControlDataGrid } from "../../Controls/hooks/useControlGrid/useControlDataGrid";

/**
 * Displays Controls for a Control Framework
 * @param {object} item - Control Framework
 * @param {string} organizationID - ID of the Organization
 * @param {boolean} isTemplate - if TRUE, will show controls for a Control Framework Template
 * @returns {JSX.Element}
 */
const ControlSetControls = ({ item, organizationID, isTemplate = false }) => {
  const controlGrid = useControlDataGrid({
    organizationID: organizationID,
    frameworkOrganizationID: organizationID,
    controlFrameworkID: item?.id,
    isTemplate,
  });

  return <div style={{ height: "30em" }}>{controlGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(ControlSetControls);
