import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useReportDataGrid } from "../../../Reports/hooks/useReportDataGrid";
import ReportDataGrid from "../../../Reports/ReportDataGrid";

import { useEvidenceNestedDataGrid } from "./EvidenceNestedDataGrid";

/**
 * Displays Reports associated with an Evidence through Activities
 * @param {object} item - evidence object
 * @param {object} recordEvidence - record evidence hook instance
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceReports = (props) => useEvidenceReports(props).display;

export const useEvidenceReports = ({ item, recordEvidence }) => {
  return useEvidenceNestedDataGrid({
    item,
    typename: "Report",
    connectionField: "report",
    grid: <ReportDataGrid />,
    gridHook: useReportDataGrid,
    recordEvidence,
  });
};

export default withOrganizationCheck(EvidenceReports);
