import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getResourceFieldAttribute } from "../../../../definitions/functions/getResourceFieldAttribute";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { DontHavePermission } from "../../../../utils/AccessControl/components/DontHavePermission";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";
import ItemCheckOverlay from "../../../../utils/Overlays/ItemCheckOverlay";
import { useCheckPermissions } from "../../../permissions/useCheckPermissions/useCheckPermissions";
import RowHeader from "../components/RowHeader";
import { getShownFields } from "../functions/getShownFields";
import { showFieldComponent } from "../functions/showFieldComponent";

/**
 * Custom hook that allows to show a database item's subset of fields in a table
 * @param {object} item - data for all of the item's fields as key value pairs
 * @param {boolean} enableNotes - TRUE if this item accepts notes to be appended at the end
 * @param {string[]} fields - the information to display about the item
 * @param {string} mutation - query to use to update the item in the database
 * @param {object[]} customFields - custom fields to display in the table
 * @param {object} fieldNameDictionary - human friendly names for the fields that are displayed (key must match field name)
 * @param {string} typename - the database schema name of the item displayed in the table
 * @param {string} module - platform module to which the displayed information pertains to
 * @param {string} resource - the resource inside the provided module that this information pertains to
 * @param {React.JSXElement} otherTableRows - additional table rows to append to the generic fields
 * @param {boolean} disableRoleChecking - TRUE if no need to check for permissions to access content
 * @param {function} resetFunction - for re-fetching the parent query
 * @param {string} observationConnectionField - the name of the field that contains the connection to the observations
 * @param {function} [updateItemById] - for updating the parent item without re-querying
 * @param {function} updateMutationFunction - function that updates the input right before a mutation takes place
 * @param {string[]} [requiredFields] - list of fields that would make up a complete item (an alert is displayed if any of these fields are missing)
 * @returns {object} {{display: *}}
 */
export const useDetails = ({
  item,
  enableNotes = false,
  fields,
  mutation,
  customFields = [],
  fieldNameDictionary,
  typename,
  module,
  resource,
  otherTableRows,
  disableRoleChecking = false,
  resetFunction,
  observationConnectionField,
  updateItemById,
  updateMutationFunction,
  requiredFields,
}) => {
  const display = (
    <DetailsTable
      item={item}
      enableNotes={enableNotes}
      fields={fields}
      mutation={mutation}
      module={module}
      resource={resource}
      customFields={customFields}
      fieldNameDictionary={fieldNameDictionary}
      disableRoleChecking={disableRoleChecking}
      observationConnectionField={observationConnectionField}
      resetFunction={resetFunction}
      updateItemById={updateItemById}
      updateMutationFunction={updateMutationFunction}
      typename={typename}
      requiredFields={requiredFields}
    />
  );

  return {
    display,
  };
};

/**
 * Component version of useDetails
 * @param {object} props
 * @param {JSX.Element} [props.appendComponent] - component to place above the table
 * @param {JSX.Element} [props.suffixComponent] - component to place below the table
 * @returns {JSX.Element}
 */
export const DetailsTable = (props) => {
  const {
    item,
    enableNotes,
    observationConnectionField,
    fields,
    mutation,
    customFields = [],
    fieldNameDictionary,
    module,
    resource,
    otherTableRows,
    disableRoleChecking,
    resetFunction,
    appendComponent,
    suffixComponent,
    updateItemById,
    updateMutationFunction,
    typename,
    requiredFields,
  } = props;

  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    disableRoleChecking,
  });

  const [shownFields, setShownFields] = useState([]);
  useEffect(() => {
    if (item) {
      setShownFields(
        getShownFields({
          item,
          fields,
          customFields,
          module,
          resource,
          disableRoleChecking,
          roleConfig: checkPermissionsHook.roleConfig,
        }),
      );
    }
  }, [item, fields]);

  /**
   * Returns a tooltip component for a field if the configuration for it exists
   * @param {string} key - the name of the field
   * @returns {JSX.Element}
   */
  const getFieldToolTip = (key) => {
    //Check if the tooltip exists
    if (!Array.isArray(customFields)) {
      return null;
    }

    const tooltipInit = customFields.find((field) => field.field === key)?.tooltip;
    const tooltip = getResourceFieldAttribute({
      override: tooltipInit,
      typename,
      fieldContext: fieldContexts.DETAILS,
      fieldName: key,
      attribute: "description",
    });

    if (typeof tooltip !== "string") {
      return null;
    }

    //Return the tooltip component
    return (
      <span style={{ padding: 0, margin: 0 }}>
        <CustomTooltip tooltip={tooltip} />
      </span>
    );
  };

  if (checkPermissionsHook.module.isEnabled && checkPermissionsHook.resource.read) {
    return (
      <ItemCheckOverlay
        item={item}
        isLoading={isNullOrUndefined(item)}
        resource={resource}
        requiredFields={requiredFields}
      >
        <div>
          {/* Prefix Component - placed above the table */}
          {appendComponent && React.cloneElement(appendComponent, { item })}

          {/* Table Component - shows all selected/permitted fields + added rows*/}
          <Table responsive>
            <tbody>
              {item &&
                shownFields.map((key, index) => (
                  <tr key={key}>
                    <RowHeader fieldName={key} customFields={customFields} fieldNameDictionary={fieldNameDictionary} />
                    <td>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          {showFieldComponent({
                            fieldName: key,
                            fieldIndex: index,
                            item,
                            customFields,
                            module,
                            resource,
                            mutation,
                            resetFunction,
                            updateItemById,
                            updateMutationFunction,
                            disableRoleChecking,
                            typename,
                            fieldNameDictionary,
                          })}
                        </div>
                        {getFieldToolTip(key)}
                      </div>
                    </td>
                  </tr>
                ))}
              {item &&
                otherTableRows &&
                React.cloneElement(otherTableRows, {
                  key: "other_row_for_table",
                  item: item,
                })}
            </tbody>
          </Table>

          {/* Suffix Component - placed below the table */}
          {suffixComponent && React.cloneElement(suffixComponent, { item })}

          {/* Notes Component - shows all notes for given item */}
          {item && enableNotes && (
            <>
              <hr />
              <NotesV2
                item={props.item}
                module={module}
                resource={resource}
                organizationID={props.item && props.item.ownerGroup}
                mutation={mutation}
                resetFunction={resetFunction}
                observationConnectionField={observationConnectionField}
                maxInitialNotes={-1}
                disableRoleChecking={disableRoleChecking}
              />
            </>
          )}
        </div>
      </ItemCheckOverlay>
    );
  } else {
    return <DontHavePermission module={module} resource={resource} />;
  }
};
