import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { useSetAsyncData } from "../../../../../../../hooks/functional/useSetAsyncData";
import { ListQueryBy } from "../../../../../../../utils/Functions/Graphql/ListQueryBy";
import { useOrganizationConfig } from "../../../../../../AdminPanel/Organizations/hooks/useOrganizationConfig";
import { normalizeControlRowData } from "../../../functions/normalizeControlRowData";

/**
 * A custom hook for populating Control data using Custom Fields and Selected Control Framework ID
 *
 * Takes a gridCard reference to automatically pass data to grid.
 *
 * @param {number} resetKey - a key to reset the grid data manually from the parent
 * @param {string} organizationID - org ID for the controls
 * @param {string} controlFrameworkID - the framework ID, this is required
 * @param {object[]} customFrameworkFieldNames
 * @param {ref} gridCard - a useGridCard hook instance reference
 * @param {string} query - the graphql query
 * @param {function} newDataCallback - function that takes in the updated data once it becomes avalible
 * @param {boolean} isTemplate - check if the control framework is a template
 */
export const useControlGridData = ({
  resetKey,
  organizationID,
  controlFrameworkID,
  customFrameworkFieldNames,
  gridCard,
  query,
  newDataCallback,
  isTemplate = false,
}) => {
  const { value: includeDisabledControlsInDataGrids } = useOrganizationConfig({
    organizationID,
    key: "includeDisabledControlsInDataGrids",
  });

  useSetAsyncData({
    setIsLoading: gridCard?.setIsLoading,
    getData: async () => {
      try {
        if (
          !isNullOrUndefined(organizationID) &&
          organizationID !== "" &&
          controlFrameworkID !== "" &&
          !isNullOrUndefined(controlFrameworkID) &&
          !isNullOrUndefined(customFrameworkFieldNames) &&
          !isNullOrUndefined(gridCard) &&
          !isNullOrUndefined(query)
        ) {
          gridCard?.setIsLoading(true);

          InfoLogger("Running Query for Control Grid");

          const items = await ListQueryBy({
            query,
            limit: 1000,
            variables: {
              ownerGroup: organizationID,
              controlControlSetId:
                !isNullOrUndefined(controlFrameworkID) && controlFrameworkID !== "all"
                  ? {
                      eq: controlFrameworkID,
                    }
                  : undefined,
            },
          });

          const res = [];
          if (!isNullOrUndefined(items) && Array.isArray(items)) {
            for (const item of items) {
              // Check if need to include disabled controls
              if (item?.isDisabled === true && includeDisabledControlsInDataGrids !== true && !isTemplate) {
                continue;
              }
              res.push(
                normalizeControlRowData({
                  control: item,
                  customFields: customFrameworkFieldNames,
                }),
              );
            }
          }
          return res;
        } else {
          return [];
        }
      } catch (e) {
        ErrorLogger("Error running query for Control Grid", e);
        return [];
      }
    },
    setData: (data) => {
      //When control framework is not defined at all data is controlled from outside the grid and should not be set
      if (isNullOrUndefined(controlFrameworkID)) {
        return;
      }

      if (Array.isArray(data)) {
        gridCard?.setData(data);
        newDataCallback?.(data);
      }
    },
    dependencies: [
      organizationID,
      JSON.stringify(customFrameworkFieldNames),
      controlFrameworkID,
      resetKey,
      includeDisabledControlsInDataGrids,
    ],
  });
};
