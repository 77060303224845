"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAndFilterOnlyNumericItems = void 0;
/**
 * Attempts to convert all items in the array to numeric values, otherwise filters them out.
 * @param {*[]} items - array of items that can be converted to numeric values
 * @returns {number[]} - array of all numerical values as number types
 */
const convertAndFilterOnlyNumericItems = ({ items }) => {
    if (!Array.isArray(items)) {
        console.log("[convertAndFilterOnlyNumericItems] items input was not a valid array!");
        return [];
    }
    let newItems = items.filter((item) => {
        return isFinite(item);
    });
    newItems = newItems.map((item) => {
        return parseFloat(item);
    });
    //Filter out not numbers
    newItems = newItems.filter((item) => {
        return !isNaN(item);
    });
    return newItems;
};
exports.convertAndFilterOnlyNumericItems = convertAndFilterOnlyNumericItems;
