import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

import { useModal } from "../../../../hooks/views/useModal";
import { AUTOMATION_TYPES, AUTOMATION_TYPES_CONFIG } from "../enums/AUTOMATION_TYPES";

import CreateAutomationStep from "./CreateAutomationStep";

/**
 * Modal with buttons for selecting an automation type
 * @param {function} onSelect - function to call when an automation type is selected
 * @param {string} organizationID -  the selected organization ID
 * @param {boolean} isTemplate - if TRUE the automation is a global or operation team template
 * @param {object[]} automations - the current automation steps
 * @param {string[]} disabledStepTypes - array of step types that should be disabled in the UI to create new steps
 * @param {object} trigger - the information about what triggers the start of this automation
 * @param {boolean} isTemplate - if TRUE will display only templatable fields
 * @param {object} smartValueContext - the smart value context
 * @param {object[]} ancestorSteps - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {object} automation - the parent automation item itself, with trigger and condition info
 * @param {boolean} showLoopSmartValues - if TRUE will show the smart values for the loop step
 * @returns {JSX.Element}
 */
const SelectAutomationModal = ({
  onSelect,
  organizationID,
  automations,
  disabledStepTypes,
  trigger,
  isTemplate,
  smartValueContext,
  ancestorSteps,
  automation,
  showLoopSmartValues,
}) => {
  const automationTypes = Object.values(AUTOMATION_TYPES_CONFIG);

  // The following automation steps are not supported
  // for Automation Templates
  const disabledWithTemplates = [
    // Report Templates are not yet supported for Automation Templates
    // https://rivialsecurity.atlassian.net/browse/RISC-3199
    AUTOMATION_TYPES.GENERATE_REPORT,
  ];

  // Filters the types of automation that can be selected
  const filterAutomationTypes = () => {
    let filteredTypes = automationTypes;

    if (Array.isArray(disabledStepTypes)) {
      filteredTypes = filteredTypes.filter((option) => !disabledStepTypes.includes(option.type));
    }

    if (isTemplate) {
      filteredTypes = filteredTypes.filter((option) => !disabledWithTemplates.includes(option?.type));
    }

    return filteredTypes;
  };

  const modal = useModal(
    "Select a Step Type",
    <CreateAutomationStep
      onSubmit={onSelect}
      automationTypes={filterAutomationTypes()}
      organizationID={organizationID}
      automations={automations}
      isTemplate={isTemplate}
      trigger={trigger}
      smartValueContext={smartValueContext}
      ancestorSteps={ancestorSteps}
      automation={automation}
      showLoopSmartValues={showLoopSmartValues}
    />,
    <ListItemButton
      key={"list-item-create"}
      color={"success"}
      style={{
        border: "1px dashed",
        borderRadius: "0.5em",
        borderColor: "grey",
      }}
    >
      <ListItemIcon>
        <i className={"icon-plus"} />
      </ListItemIcon>
      <ListItemText primary={"Add a Step"} />
    </ListItemButton>,
    {
      width: "80vw",
    },
  );

  return <span>{modal.modalButton}</span>;
};

export default SelectAutomationModal;
