import React, { forwardRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import Loader from "../../../../utils/LoadingComponents/Loader";
import styles from "../css/PDFDocument.module.css";

import PDFPage from "./PDFPage";

/**
 * A component displaying the PDF document pages and its layers (draw layer, text layer & annotations)
 * @param {object} pdfJS - PDF.js library object initialized with the worker
 * @param {object} settings - current viewer display parameters
 * @param {object} document - PDF.js opened document object
 * @param {object[]} annotations - array of annotations to display on the document
 * @param {function} onVerticalScroll - callback function to call when the user scrolls vertically
 * @param {function} onTextSelected - callback function to call when the user selects text on the document
 * @param {JSX.Element} annotationComponent - custom component to display all annotations
 * @param {object} emphasizedAnnotation - annotation to emphasize on the document, when this prop changes
 * the viewer will scroll to the annotation and separate it from the rest of the document
 * @type {(props: ({readonly settings?: *, readonly pdfJS?: *, readonly document?: *, readonly emphasizedAnnotation?: *, readonly onVerticalScroll?: *, readonly annotations?: *, readonly annotationComponent?: *, readonly onTextSelected?: *} & React.RefAttributes<unknown>)) => (React.ReactElement | null)}
 */
const PDFDocument = forwardRef(
  (
    {
      pdfJS,
      settings,
      document,
      annotations,
      onVerticalScroll,
      onTextSelected,
      annotationComponent,
      emphasizedAnnotation,
    },
    scrollRef,
  ) => {
    const getPages = () => {
      const annotationsPerPage = {};
      for (const annotation of annotations) {
        const { pageBounds } = annotation;
        for (const page in pageBounds) {
          if (!annotationsPerPage[page]) {
            annotationsPerPage[page] = [];
          }
          annotationsPerPage[page].push(annotation);
        }
      }

      const pages = [];
      for (let i = 0; i < (settings?.numPages ?? 0); i++) {
        pages.push(
          <PDFPage
            key={i}
            pdfJS={pdfJS}
            pdfDocument={document}
            settings={settings}
            pageNumber={i}
            onTextSelected={onTextSelected}
            annotations={annotationsPerPage[i] ?? []}
            emphasizedAnnotation={emphasizedAnnotation}
            annotationComponent={annotationComponent}
          />,
        );
      }
      return pages;
    };

    return (
      <div className={styles["document-container"]}>
        <PerfectScrollbar
          containerRef={(ref) => {
            if (scrollRef) {
              scrollRef.current = ref;
            }
          }}
          onScrollY={(container) => {
            onVerticalScroll({ scrollTop: container?.scrollTop });
          }}
        >
          <div className={styles["pages-column"]}>
            {!settings ? (
              <span>
                <Loader text="Initializing..." />
              </span>
            ) : (
              getPages()
            )}
          </div>
        </PerfectScrollbar>
      </div>
    );
  },
);

PDFDocument.displayName = "PDFDocument";

export default PDFDocument;
