"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Based on the event object given to a graphql lambda function referenced by @firehose directive dynamically
 * determine the resource that is being accessed
 * @param {object} event - contains identifying info about the requests
 */
const getEventResource = ({ event }) => {
    //Check if event contains field name data
    const fieldName = event?.info?.fieldName || event?.fieldName;
    if (!fieldName) {
        console.error("Event object did not have field name data in getEventResource");
        return null;
    }
    //Split the field name into individual words
    //Reference: https://stackoverflow.com/questions/54409164/how-to-split-a-camelcase-string-and-check-if-each-split-word-is-part-of-an-array
    const fieldNameParts = fieldName.split(/(?=[A-Z])/g);
    //Check the field name to have more that one word
    if (fieldNameParts.length <= 1) {
        console.error("fieldName to short in getEventResource - ", { fieldName });
        return null;
    }
    //Function that converts an array of individual words into a camel case version of the resource
    const getResourceFromParts = (fieldNameParts) => {
        let resourceName = fieldNameParts[0].charAt(0).toUpperCase() + fieldNameParts[0].slice(1);
        let count = 0;
        for (const word of fieldNameParts) {
            if (count !== 0) {
                resourceName += word;
            }
            count++;
        }
        return resourceName;
    };
    //Function to get ResourceName in camel case from a listBy query
    const getListByResource = (fieldNameParts) => {
        //if contains the part "by" then this a listBy query and resource name is at the start
        const foundPartIndex = fieldNameParts.findIndex((part) => part.toLowerCase() === "by");
        if (foundPartIndex !== -1) {
            fieldNameParts.length = foundPartIndex;
            return getResourceFromParts(fieldNameParts);
        }
        else {
            return null;
        }
    };
    //Based on the first value in Query Type field name determine the resource being accessed
    const fieldNamePartsNoOperation = [...fieldNameParts];
    fieldNamePartsNoOperation.shift();
    switch (fieldNameParts[0]) {
        case "get":
        case "create":
        case "update":
        case "delete":
            return getResourceFromParts(fieldNamePartsNoOperation);
        case "list":
            const resourceName = getResourceFromParts(fieldNamePartsNoOperation);
            //remove the 's' at the end of the list fieldName
            return resourceName.substring(0, resourceName.length - 1);
        default:
            const listByResource = getListByResource(fieldNameParts);
            if (listByResource) {
                return listByResource;
            }
            else {
                console.error("Cannot parse the resource name from fieldname in getEventResource - ", { fieldName });
                return null;
            }
    }
};
exports.default = getEventResource;
