import { useState } from "react";
import { v4 as uuid } from "uuid";

/**
 * @description keeps an unchanging uuid value in state, for instance when you need to use it in a child component
 * as well as in a submit function
 */
export const useUUID = (): [string] => {
  const [value] = useState(uuid());

  return [value];
};
