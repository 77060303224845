import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKnowBe4ModuleDataGrid } from "../hooks/useKnowBe4ModuleDataGrid";

/**
 * @description Component to display list of KnowBe4 Modules
 * @param {object} props - The props passed to the component, which include:
 * @returns {JSX.Element}
 */
const KnowBe4ModuleDataGrid = (props) => useKnowBe4ModuleDataGrid(props).display;

export default withOrganizationCheck(KnowBe4ModuleDataGrid);
