import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import AccessControl from "../../../../utils/AccessControl/components/AccessControl";
import AddAccessControl from "../../../../utils/AccessControl/components/AddAccessControl";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useDetailsCard } from "../../useDetailsCardV2";
import DocumentFile from "../customFields/DocumentFile";
import DocumentUploadDate from "../customFields/DocumentUploadDate";

/**
 * Displays the details page for a particular document
 * @param itemId
 * @param config
 */
export const useDocumentDetails = ({ itemId, ...config }) => {
  const typename = "Document";
  const module = modules.GOVERNANCE;
  const resource = resources.DOCUMENT;
  const route = "#/governance/documents/";

  const fields = ["name", "createdAt", "avStatus", "lastAVCheck", "file", "accessControl"];

  const queryFields = [...fields];

  const fieldNameDictionary = {
    createdAt: "Upload Date",
  };

  const nestedFields = {
    file: `{ bucket region key }`,
    accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
  };

  const customFields = [
    {
      field: "file",
      component: <DocumentFile />,
    },
    {
      field: "createdAt",
      component: <DocumentUploadDate />,
    },
  ];

  const { getQuery, updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    fieldNameDictionary,
    nestedFields,
    customFields,
    updateMutation,
  };

  const cardConfig = {
    route,
    header: "Document Details",
  };

  const fieldsDisplay = ["name", "createdAt", "encrypted", "file"];

  const detailsCard = useDetailsCard({
    fields: fieldsDisplay,
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: cardConfig,
    ...config,
  });

  const style = { height: "100%" };

  const display = (
    <AccessControl item={detailsCard?.item} resetFunction={detailsCard?.reset} typename={"Document"}>
      <Dashboard resetFunction={detailsCard?.reset}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <DashboardCard
                  title={"General Information"}
                  style={style}
                  icon={"icon-list"}
                  headerButtons={[
                    <AddAccessControl
                      item={detailsCard?.item}
                      resetFunction={detailsCard?.reset}
                      typename={"Document"}
                      accessControlEnabled={true}
                    />,
                  ]}
                >
                  {detailsCard.tableDisplay}
                </DashboardCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dashboard>
    </AccessControl>
  );

  return {
    ...detailsCard,
    display,
  };
};
