"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInlineText = void 0;
/**
 * Used to piece together multiple separate parts of text into a single line of text.
 * @param {string} text
 * @param {string} styleName
 * @param {object} config
 * @returns {{characterFormat: {bidi: boolean, fontFamily: *, underline: *, fontSize: (*|number), bold: *, italic: *, fontColor: (*|undefined)}, text: string}}
 */
const createInlineText = (text, styleName = "Normal", config = {}) => {
    return {
        characterFormat: {
            bidi: false,
            fontSize: (config && config.fontSize) || 11,
            fontFamily: config && config.fontFamily, //useful for creating symbols
            underline: config && config.underline,
            bold: config && config.bold,
            italic: config && config.italic,
            fontColor: (config && config.fontColor) || undefined,
        },
        text: `${text}`,
    };
};
exports.createInlineText = createInlineText;
