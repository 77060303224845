import { useForm } from "../../../hooks/views/useForm";

/**
 * Form for editing the name of a dashboard. May be extended to handle other fields in the future.
 * @param {object} item - the dashboard item
 * @param {function} editDashboardDetails - function to be called when the dashboard name is changed
 * @param {function} toggleModal - function to be called when the modal is closed
 * @param {function} callback - function to be called when the form is submitted
 * @returns {JSX.Element}
 * @constructor
 */
const CustomDashboardNameForm = ({ item, editDashboardDetails, toggleModal, callback }) => {
  const editDashboardNameForm = useForm({
    item,
    fieldConfig: {
      name: {
        required: true,
        label: "Dashboard Name",
        inputType: "text",
        defaultValue: item?.name,
      },
    },
    disableResetButton: true,
    submitFunction: editDashboardDetails,
    toggleModal,
    callback,
  });

  return editDashboardNameForm.display;
};

export default CustomDashboardNameForm;
