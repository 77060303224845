import React from "react";

import { defaultEmailTemplate } from "../../../../../../../OrganizationManager/EmailTemplates/constants/defaultEmailTemplate";
import { useEmailEditor } from "../../../../../../../OrganizationManager/EmailTemplates/hooks/useEmailEditor";

/**
 * A preview container for the html string that is used for email widgets
 * @param {string} organizationID - the currently selected organization
 * @param {string} htmlString - the html string of the table to preview
 */
const EmailWidgetPreview = ({ organizationID, htmlString }) => {
  if (!htmlString) return <div>No Preview Data</div>;

  const emailTemplateToLoad = defaultEmailTemplate({
    contentHTML: `<div style="display: flex; align-items: center; justify-content: center;"> ${htmlString}</div>`,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { display } = useEmailEditor({
    organizationID,
    item: {
      design: emailTemplateToLoad,
    },
    editorOptions: {
      minHeight: "100%",
    },
  });

  return display;
};

export default EmailWidgetPreview;
