/**
 * This enum field contains the overall operation that the user was trying to perform, more specific enums take precedence
 */
export const operationType = {
  GENERIC: "GEN", // will be chosen if no operation is provided
  CREATE: "CRT", // writing a new entry or entries to the database
  UPDATE: "UPT", // modifying an existing entry in the database
  DELETE: "DEL", // removing an existing entry or entries in the database
  GET: "GET", // reading a single item from the database - (eg. details pages)
  LIST: "LST", // reading multiple items from the database at once - (eg. grid pages)
  COGNITO: "COG", // working with user role, password and other AWS cognito fields
  LAMBDA: "LAM", // generic lambda operation can be
  S3: "STO", // generic s3 file manipulation

  // More specific operations below, these take precedence over the ones listed above
  DOCUMENT: "DOC", //adding/removing attached documents stored in S3 (eg. vendor review docs, evidence upload)
  REPORT_GENERATION: "REP", //creating a new report from the moment user presses generate to the moment the report is shown
  TEMPLATE_DUPLICATION: "TEM", //using Rivial templates to create the initial set of content populating the modules
};
