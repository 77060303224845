import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateWidget } from "../hooks/useCreateWidget/useCreateWidget";

/**
 * Component version of useCreateWidget hook
 * @param {object} props
 * @returns {JSX.Element}
 */
const CreateWidget = (props) => {
  return useCreateWidget(props).display;
};

export default withOrganizationCheck(CreateWidget);
