import {
  interpretActionItemDueDate,
  interpretActionItemPointOfContact,
  interpretActionItemPriority,
  interpretActionItemStatus,
  interpretRating,
  interpretRecommendationStatus,
} from "../../Actions/functions/interpretActions";
import { ACTION_STATUSES } from "../../actionStatuses";
import { moduleTypes } from "../../moduleTypes";
import { interpretEnableNotifications } from "../../Observations/functions/interpretEnableNotifications";
import { interpretIsFinding } from "../../Observations/functions/interpretObservations";
import { recommendationRatings } from "../../recommendationRatings";
import { RecommendationStatus } from "../../Recommendations/enums/RecommendationStatus";

const observationColumns = [
  {
    name: "Observation Name",
    typename: "Observation",
    field: "name",
    keywords: ["Observation Name", "Finding", "observation", "description"],
  },
  {
    name: "Observation Description",
    resource: "Observation",
    field: "description",
    keywords: ["Observation Description", "Observation Details", "Details"],
  },
  {
    name: "Observation Module",
    resource: "Observation",
    field: "module",
    keywords: [],
    options: moduleTypes,
    defaultValue: "governance",
  },
  {
    name: "Observation Is Finding",
    resource: "Observation",
    field: "isFinding",
    keywords: ["Observation Is Finding", "Is Finding", "isFinding"],
    defaultValue: true,
    interpreter: interpretIsFinding,
  },
  {
    name: "Observation Management Response",
    keywords: ["Observation Management Response", "Formal Response"],
  },
  {
    name: "Observation Risk Level",
    keywords: ["Observation Risk Level", "Risk", "Observation Risk", "Risk Rating"],
    options: recommendationRatings,
    defaultValue: "info",
  },
  {
    name: "Observation Notes",
    keywords: ["Finding Notes"],
  },
  {
    name: "Observation Decision",
    keywords: ["Observation Decision", "Decision"],
    defaultValue: "none",
  },
];

const recommendationColumns = [
  {
    name: "Recommendation Name",
    keywords: ["Recommendation"],
  },
  {
    name: "Recommendation Status",
    defaultValue: RecommendationStatus.UNRESOLVED,
    interpreter: interpretRecommendationStatus,
  },
  {
    name: "Recommendation Module",
    defaultValue: "governance",
    options: moduleTypes,
  },
  {
    name: "Recommendation Rating",
    keywords: ["Rating"],
    interpreter: interpretRating,
    defaultValueValue: "info",
  },
  {
    name: "Recommendation Difficulty",
    keywords: ["Difficulty"],
    interpreter: interpretRating,
    defaultValueValue: "info",
  },
  {
    name: "Recommendation Notes",
    keywords: [],
  },
];

const actionItemColumns = [
  {
    name: "Action Item Name",
  },
  {
    name: "Action Item Description",
  },
  {
    name: "Action Item Status",
    defaultValue: ACTION_STATUSES.PROPOSED,
    options: Object.keys(ACTION_STATUSES),
    interpreter: interpretActionItemStatus,
  },
  {
    name: "Action Item Module",
    defaultValue: "governance",
    options: moduleTypes,
  },
  {
    name: "Action Item Point of Contact",
    interpreter: interpretActionItemPointOfContact,
    keywords: ["Primary Contact"],
  },
  {
    name: "Action Item Due Date",
    interpreter: interpretActionItemDueDate,
    keywords: ["Target Date"],
  },
  {
    name: "Action Item Priority",
    interpreter: interpretActionItemPriority,
    defaultValue: 300,
  },
  {
    name: "Action Item Completion Date",
    interpreter: interpretActionItemDueDate,
    keywords: ["Completion Date"],
  },
  {
    name: "Action Item Notes",
  },
  {
    name: "Action Item Enable Notifications",
    keywords: ["Enabled", "Notifications", "Enable", "EnabledNotifications"],
    interpreter: interpretEnableNotifications,
    default: false,
  },
];

// All of the available fields to be used for mapping
const columns = [...observationColumns, ...recommendationColumns, ...actionItemColumns];

export default columns;
