import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useMetricTypeDetails } from "../hooks/useMetricTypeDetails";

const MetricTypeDetails = (props) => {
  const details = useMetricTypeDetails(props);

  return props.tableDisplay ? details.tableDisplay : details.display;
};

export default withDetailsPage(MetricTypeDetails);
