import { cognitoRoleGroups } from "../../Users/functions/cognitoRoleGroups";

/**
 * Deletes a Role from the database.
 *
 * @author Anatoli Railean 02/04/20
 * @param {object} item - the Role to delete.
 * @param {string} item.id - the ID Role to delete.
 * @returns {object} {Promise<void>}
 * @constructor
 */
const DeleteRole = async (item) => {
  if (!item || !item.id) {
    return null;
  }

  const input = {
    type: "deleteRole",
    role: {
      id: item.id,
    },
  };

  return cognitoRoleGroups(input);
};

export default DeleteRole;
