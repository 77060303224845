import { getConditionBuilderResources } from "./getConditionBuilderResources";

/**
 * Gets a list of smart values from the current automation configuration
 *
 * @param step.id - the id of the automation
 * @param step.config - the config of the automation
 * @param step.type - the type of the automation
 * @param step.name - the name of the automation
 * @param step.typename - the typename of the resource related to the automation step
 * @returns {string[]} - a list of smart values
 */
export const getAutomationStepSmartValues = ({ step }) => {
  const values = [];

  // only handle steps that have a valid name
  if (step?.name) {
    // handle createResource and updateResource steps
    if (step.type === "createResource" || step.type === "updateResource") {
      const typename = step.typename;

      /**
       * @typedef ParsedType
       * @property {string} typename
       * @property {object} fields
       */
      const types = getConditionBuilderResources({
        manyToMany: false,
        oneToMany: false,
      });

      const parsedType = types.find((type) => type.typename === typename);

      if (parsedType) {
        Object.keys(parsedType.fields).forEach((field) => {
          values.push(`steps.${step.name}.${field}`);
        });
      }
    }

    // handle HTTP steps
    if (step.type === "http") {
      values.push(`steps.${step.name}.result`);
      values.push(`steps.${step.name}.statusCode`);
    }

    // handle report steps
    if (step.type === "report") {
      values.push(`steps.${step.name}.reportId`);
    }
  }

  return values;
};
