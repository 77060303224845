import React from "react";

import { PolicyVersionStatus as PolicyVersionStatusEnum } from "../constants/PolicyVersionStatus";

/**
 * Custom status display for the status of a policy version
 * @param {object} version - all information available about this policy version from the database
 * @return {JSX.Element}
 */
const PolicyVersionStatus = ({ version }) => {
  const getStyle = () => {
    switch (version?.status) {
      case PolicyVersionStatusEnum.DRAFT:
        return {
          color: "orange",
          textTransform: "uppercase",
        };
      case PolicyVersionStatusEnum.APPROVED:
        return {
          color: "green",
          textTransform: "uppercase",
        };
      case PolicyVersionStatusEnum.OLD:
        return {
          color: "blue",
          textTransform: "uppercase",
        };
      default:
        return {
          color: "black",
          textTransform: "uppercase",
        };
    }
  };

  return <span style={getStyle()}>{version?.status || "Unknown"}</span>;
};

export default PolicyVersionStatus;
