import { useUIContext } from "@utils/Context/UIContext";

import { saveRoleConfig } from "../functions/saveRoleConfig";

/**
 * Handles the saveChange function with toasts
 * @param {Role} item - the Role object from the database to be updated
 * @param {ID} item.id - the ID of the Role object from the database to be updated
 * @param {RoleConfig} roleConfig - the roleConfig object for the mutation
 * @param {function} setIsChanged - function for setting isChanged state after the operation
 * @returns {{saveChange: (function(): Promise<void>), updateToast: (function()), addToast: (function())}}
 */
export const useSaveRoleConfig = ({ item, roleConfig, setIsChanged }) => {
  const { addToast, updateToast } = useUIContext();

  const saveChange = () => {
    return saveRoleConfig({
      item,
      roleConfig,
      setIsChanged,
      addToast,
      updateToast,
    });
  };

  return {
    saveChange,
    addToast,
    updateToast,
  };
};
