import { Timeline } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useState } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { useSetAsyncData } from "../../../../../../hooks/functional/useSetAsyncData";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import { useGroupHistoryByAssessment } from "../../../../Vulnerabilities/components/VulnerabilityTimelineContent/hooks/useGroupHistoryByAssessment";
import { getVulnerabilityTargetHistory } from "../../../../Vulnerabilities/functions/getVulnerabilityTargetHistory";
import { useVulnerabilityTargetHistoryChips } from "../../../../Vulnerabilities/hooks/useVulnerabilityTargetHistoryChips";

import TargetTimelineItem from "./TargetTimelineItem";

/**
 * Component for displaying the timeline of a target's history
 * @param {object} item - target object
 * @returns {JSX.Element}
 */
const TargetTimeline = ({ item }) => {
  const { display: groupByAssessmentDisplay, isGrouped } = useGroupHistoryByAssessment();

  const [historyItems, setHistoryItems] = useState([]);
  const [historyNextToken, setHistoryNextToken] = useState(null);
  const { shownHistoryItems, chipsDisplay } = useVulnerabilityTargetHistoryChips({
    historyItems,
    isGrouped,
  });

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      if (!item?.id) {
        return [];
      }

      const vulnerabilityTargetLinkIds = [];
      const vulnerabilities = {};
      for (const vulnerabilityLink of item?.vulnerabilities?.items ?? []) {
        if (!vulnerabilityLink?.id) {
          continue;
        }

        vulnerabilityTargetLinkIds.push(vulnerabilityLink.id);
        vulnerabilities[vulnerabilityLink.id] = vulnerabilityLink?.vulnerability;
      }

      let vulnerabilityTargetHistory = [];
      const nextToken = null;
      const usedNextToken = historyNextToken;
      if (isNonEmptyArray(vulnerabilityTargetLinkIds)) {
        const result = await getVulnerabilityTargetHistory({
          vulnerabilityTargetLinkIds,
          groupByAssessment: isGrouped,
          nextToken: usedNextToken,
        });
        vulnerabilityTargetHistory = result?.vulnerabilityTargetHistory;
      }

      for (const historyItem of vulnerabilityTargetHistory) {
        const linkID = historyItem?.targetVulnerabilityLinkID;
        historyItem.vulnerabilityName = vulnerabilities[linkID]?.name;
        historyItem.vulnerabilityID = vulnerabilities[linkID]?.id;
      }

      return { vulnerabilityTargetHistory, nextToken, usedNextToken };
    },
    setData: ({ vulnerabilityTargetHistory, nextToken, usedNextToken }) => {
      const newItems = vulnerabilityTargetHistory || [];
      if (usedNextToken) {
        setHistoryItems((historyItems) => [...(historyItems || []), ...newItems]);
      } else {
        setHistoryItems(newItems);
      }
      setHistoryNextToken(nextToken);
    },
    dependencies: [isGrouped, item?.id],
  });

  return (
    <DashboardCard
      id={"target-activity-card"}
      title={"History"}
      icon={"icon-energy"}
      style={{ height: "65vh", overflowY: "auto" }}
      headerButtons={[groupByAssessmentDisplay]}
      headerContent={isGrouped !== true && <div>{chipsDisplay}</div>}
    >
      <DataLoader
        isEnoughData={historyItems?.length > 0}
        isLoading={isLoading}
        dataMessage={"There hasn't been any Target activity yet"}
        children={
          <>
            <Timeline align={"left"}>
              {shownHistoryItems.map((historyItem, index) => {
                return (
                  <TargetTimelineItem
                    key={historyItem?.id}
                    item={historyItem}
                    isLastItem={index === shownHistoryItems.length - 1}
                    target={item}
                    type={historyItem?.type}
                    date={historyItem?.date}
                    comment={historyItem?.comment}
                    resetFunction={() => {
                      setHistoryItems([]);
                      setHistoryNextToken(null);
                      resetFunction();
                    }}
                    isGrouped={isGrouped}
                  />
                );
              })}
              {historyNextToken && (
                <Button
                  variant={"outlined"}
                  title={"Older items available, click here to load them in"}
                  onClick={() => {
                    resetFunction();
                  }}
                >
                  Load More
                </Button>
              )}
            </Timeline>
          </>
        }
      />
    </DashboardCard>
  );
};

export default TargetTimeline;
