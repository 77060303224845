import { adaptSFDTBlock } from "./adaptSFDTBlock";

/**
 * Updates older sfdt objects to a version that the current version of document editor supports
 * @param {object} sfdt - the full data of a report in sfdt json format, will be updated in place
 * @returns {*}
 */
export const adaptSFDT = ({ sfdt }) => {
  if (!sfdt) {
    return sfdt;
  }

  //Iterate over all sections of the document
  const { sections } = sfdt || {};
  if (Array.isArray(sections)) {
    for (const section of sections) {
      const { headersFooters, blocks } = section || {};

      //Iterate over all content of the section
      if (Array.isArray(blocks)) {
        for (const block of blocks) {
          adaptSFDTBlock({ block });
        }
      }

      //Fix the headers and footer blocks of the sections
      if (typeof headersFooters === "object") {
        for (const blockContainerKey in headersFooters) {
          const blockContainer = headersFooters[blockContainerKey];
          const { blocks } = blockContainer || {};
          if (Array.isArray(blocks)) {
            for (const block of blocks) {
              adaptSFDTBlock({ block });
            }
          }
        }
      }
    }
  }

  return sfdt;
};
