import React from "react";
import { Button, Row } from "reactstrap";

import SubmitButton from "./buttons/SubmitButton";

/**
 * Generic component to use on bottom of modals if not using the form hook
 * @param {function} onCancelCallback - on cancel callback
 * @param {function} onSubmitCallback - on submit callback
 * @param {function} onBothCallback - on both button's callback, useful to close the modal
 * @return {JSX.Element}
 */
const CancelOrSubmitRow = ({ onCancelCallback, onSubmitCallback, onBothCallback } = {}) => {
  return (
    <Row style={{ margin: "1em" }}>
      <div style={{ flex: 1 }} />
      <Button
        onClick={() => {
          onCancelCallback && onCancelCallback();
          onBothCallback && onBothCallback();
        }}
      >
        Cancel
      </Button>
      <div style={{ width: "1em" }} />
      <SubmitButton
        onClick={() => {
          onSubmitCallback && onSubmitCallback();
          onBothCallback && onBothCallback();
        }}
        size={""}
      >
        Submit
      </SubmitButton>
    </Row>
  );
};

export default CancelOrSubmitRow;
