import { Alert } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import React from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useUUID } from "../../../../../../hooks/functional/useUUID";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import MuiItemDownloadButton from "../../../../../../utils/GenericComponents/buttons/MuiItemDownloadButton";
import MuiItemPreviewButton from "../../../../../../utils/GenericComponents/buttons/MuiItemPreviewButton";
import { getFullName } from "../functions/getFullName";

/**
 * @description Displays timeline information about a point of contact uploading a Document for an Evidence
 * @param {PointOfContact} pointOfContact - point of contact object
 * @param {objects[]} documents - array of documents
 * @returns {JSX.Element}
 */
const DocumentActivity = ({ pointOfContact, documents = [] }) => {
  const [uuid] = useUUID();

  return (
    <span id={`document_activity${uuid}`}>
      {isNullOrUndefined(documents?.items) || documents.items.length < 1 ? (
        <NoDocument pointOfContact={pointOfContact} />
      ) : (
        <MultipleDocument documents={documents.items} pointOfContact={pointOfContact} />
      )}
    </span>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

/**
 * @description Placeholder when an activity do not have documents
 * @param {object} pointOfContact - point of contact object
 * @returns {JSX.Element}
 */
const NoDocument = ({ pointOfContact }) => {
  const fullName = getFullName({ pointOfContact });

  return (
    <span style={{ fontSize: ".9em" }}>
      {fullName || "Someone"} has uploaded a{" "}
      {
        <LightTooltip arrow interactive title={<Alert color={"error"}>Document Not Found</Alert>}>
          <span
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Document
          </span>
        </LightTooltip>
      }
    </span>
  );
};

/**
 * @description Displays the Preview/Download buttons for uploaded documents
 * @param {function} startTooltipCooldown - function to start the cooldown timer
 * @param {string} organizationID - organization ID
 * @param {object} document - document object
 * @returns {JSX.Element}
 */
const DocumentPreviewModalButtons = ({ organizationID, document }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
      <MuiItemPreviewButton item={document} organizationID={organizationID} />
      <MuiItemDownloadButton item={document} organizationID={organizationID} />
    </div>
  );
};

/**
 * @description Simple component that displays View/Download buttons for an array of documents
 * @param {object[]} documents - array of documents
 * @param {string} organizationID - organization ID
 * @param {function} startTooltipCooldown - function to start the cooldown timer
 * @returns {JSX.Element}
 */
const MultipleDocumentPreview = ({ documents, organizationID, startTooltipCooldown }) => {
  /**
   * @description Determines the Document name label
   * @param {object} document - document object
   * @returns {string|*}
   */
  const getDocumentName = (document) => {
    if (document && document.name && document.name !== "") {
      return document.name;
    } else if (document && document.file && document.file.key) {
      const split = document.file.key.split(/_(.+)/)[1];
      return split;
    } else {
      return "Document";
    }
  };

  return (
    <div>
      {documents &&
        documents.map((document, index) => (
          <span key={document.id + index}>
            <strong>
              ({index + 1}) {getDocumentName(document)}
            </strong>
            <DocumentPreviewModalButtons organizationID={organizationID} document={document} />
          </span>
        ))}
    </div>
  );
};

/**
 * @description Evidence Activity Timeline entry for Multiple Document upload
 * @param {object} pointOfContact - point of contact object
 * @param {objects[]} documents - array of documents
 * @param {string} organizationID - organization ID
 * @returns {JSX.Element}
 */
const MultipleDocument = ({ pointOfContact, documents = [], organizationID }) => {
  const checkPermissions = useCheckPermissions({
    module: modules.COMPLIANCE,
    resource: resources.DOCUMENT,
  });

  const documentsPreviewModal = useModal(
    "Preview Document Uploads",
    <MultipleDocumentPreview organizationID={organizationID} documents={documents} />,
    null,
  );

  const fullName = getFullName({ pointOfContact });
  const documentsCount = documents.length;

  return (
    <span style={{ fontSize: ".9em" }}>
      {documentsPreviewModal.modal}
      {fullName} has uploaded {documentsCount > 1 ? " multiple " : " a "}
      {checkPermissions.resource.read ? (
        <span
          style={{
            borderBottom: "2px dashed",
          }}
        >
          <Button
            variant="text"
            style={{
              textDecoration: "none",
              background: "none",
              color: "inherit",
              padding: 0,
              font: "inherit",
              cursor: "pointer",
              outline: "inherit",
              border: "none",
            }}
            onClick={() => {
              documentsPreviewModal.setModalIsOpen(true);
            }}
          >
            {documentsCount > 1 ? "Documents" : "Document"}
          </Button>
        </span>
      ) : (
        <span>Documents</span>
      )}
    </span>
  );
};

export default withOrganizationCheck(DocumentActivity);
