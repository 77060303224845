import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useMutation } from "../../../../../hooks/graphql/useMutation/useMutation";

const { createMutation: createRisk } = generateGraphql("Risk");

const { createMutation: createRiskControlCategory } = generateGraphql("RiskControlCategory");

export const useCreateKeyRiskIndicatorWithControlCategory = ({
  module = modules.RISK,
  resource = resources.RISK_CONTROL_CATEGORY,
  disableRoleChecking = false,
}) => {
  const controlCategoryMutation = useMutation({
    mutation: createRiskControlCategory,
    module,
    resource,
    typename: "Risk Control Category",
    disableRoleChecking: true,
  });
  const riskMutation = useMutation({
    mutation: createRisk,
    module,
    resource,
    typename: "Key Risk Indicator",
    disableRoleChecking: true,
  });

  const handleCreate = ({
    system,
    riskName,
    description,
    annualRateOfOccurrence,
    controlCategoryName,
    organizationID,
    callback,
  }) => {
    controlCategoryMutation
      .createItem({
        name: controlCategoryName,
        ownerGroup: organizationID,
      })
      .then((newRiskControl) => {
        riskMutation
          .createItem({
            name: riskName,
            description: description,
            annualRateOfOccurrence: annualRateOfOccurrence,
            riskSystemId: system ? system.id : undefined,
            ownerGroup: organizationID,
            controlCategoryID: newRiskControl.id,
          })
          .then(({ data }) => {
            callback && callback(data);
          });
      });
  };

  return {
    handleCreate,
  };
};
