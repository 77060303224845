import React, { useEffect, useState } from "react";
import { CustomInput } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { useGridCard } from "../../../../hooks/views/useGridCard";
import CreateRecommendation from "../components/CreateRecommendation";
import RecommendationDetails from "../components/RecommendationDetails";
import Difficulty from "../customFields/Difficulty";
import Rating from "../customFields/Rating";
import Resolved from "../customFields/Resolved";
import { deleteRecommendation } from "../functions/deleteRecommendation";
import { recommendationsByOwnerGroup } from "../graphql/recommendationsByOwnerGroup";

/**
 * A list of RecommendationItems
 * @param organizationID
 */
export const useRecommendationsGrid = ({
  organizationID,
  onShowResolved,
  initShowResolved,
  queryConfig = {},
  gridConfig = {},
  module: moduleParam,
}) => {
  const onlyShowFindings = false;

  const typename = "RecommendationItem";
  const module = modules.GOVERNANCE;
  const resource = resources.RECOMMENDATION;
  const route = "#/governance/recommendations/";

  const queryFields = ["name", "rating", "status", "resolvedAt", "observations", "difficulty"];

  const fields = [
    {
      name: "name",
    },
    {
      name: "status",
      component: <Resolved />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
    },
    {
      name: "resolvedAt",
      width: 75,
      inputType: "date",
      visible: false,
    },
    {
      name: "rating",
      component: <Rating />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
    },
    {
      name: "difficulty",
      component: <Difficulty />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: false,
    },
  ];

  const nestedFields = {
    observations: ` (limit: 100){
      items {
        id
        observation {
          name
          description
          isFinding
          module
        }
        proposedChange {
          typename
          field
          proposedValue
          completed
          effect
        }
      }
    }`,
    actionItems: `(limit: 100) {
      items {
        id
        action {
          id
          name
          description
        }
      }
    }`,
  };

  const [recommendations, setRecommendations] = useState([]);
  const [showResolved, setShowResolved] = useState(initShowResolved);
  useEffect(() => {
    onShowResolved && onShowResolved(showResolved);
  }, [showResolved]);

  const ShowResolved = () => {
    return (
      <CustomInput
        id="show-resolved-recommendations"
        type="switch"
        name="customSwitch"
        label="Show Resolved"
        checked={showResolved}
        onChange={() => setShowResolved(!showResolved)}
      />
    );
  };

  const { updateMutation } = generateGraphql("RecommendationItem", queryFields, nestedFields);

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: recommendationsByOwnerGroup,
    variables: { ownerGroup: organizationID },
    limit: 1000,
    normalizeData: (list) => {
      setRecommendations(list);
      return list.filter((x) => x.status !== "resolved");
    },
    ...queryConfig,
  };

  useEffect(() => {
    if (showResolved === true) {
      gridCard.setData(recommendations);
    } else {
      gridCard.setData(recommendations.filter((x) => x.status !== "resolved"));
    }
  }, [showResolved, recommendations]);

  const cardConfig = {
    title: "Recommendations",
    headerIcon: "icon-check",
    headerButtons: [<ShowResolved />],
  };

  gridConfig = {
    fields,
    typename,
    createResourceComponent: <CreateRecommendation moduleParam={moduleParam} />,
    options: ["details", "delete"],
    deleteFunction: deleteRecommendation,
    detailsComponent: <RecommendationDetails tableDisplay={true} />,
    route,
    updateMutation,
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    enablePrint: true,
    enableMenu: true,
    detailsTitle: "Recommendation Details",
    columnSize: 6,
    detailsType: DETAILS_TYPES.PANEL,
    persistenceUUID: "2e319121-35e9-43a0-aaf5-81e85aa035d4",
    enablePersistence: true,
    ...gridConfig,
  };

  const gridCard = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  /**
   * Handle isFinding filtering from switch
   */
  useEffect(() => {
    if (!isNullOrUndefined(onlyShowFindings) && !isNullOrUndefined(gridCard?.ref?.dataSource)) {
      if (onlyShowFindings === true) {
        const res = [];
        for (const recommendation of gridCard.data) {
          if (recommendation?.observations?.items) {
            for (const observationLink of recommendation.observations.items) {
              const observation = observationLink.observation;
              if (observation?.isFinding === true) {
                res.push(recommendation);
                break;
              }
            }
          }
        }

        gridCard.ref.dataSource = res;
      } else if (onlyShowFindings === false) {
        gridCard.ref.dataSource = gridCard.data;
      }
    }
  }, [onlyShowFindings, gridCard.data]);

  return {
    ...gridCard,
  };
};
