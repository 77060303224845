export const activityByEvidence = /* GraphQL */ `
  query ActivityByEvidence(
    $evidenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEvidence(
      evidenceID: $evidenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        type
        description
        attested
        validatedBy
        automationID
        oldStatus
        newStatus
        comment
        date
        externalUrl
        archived
        data
        control {
          id
          name
        }
        policy {
          id
        }
        policyID
        meeting {
          id
        }
        riskChange {
          id
          ownerGroup
          name
          type
          description
          date
          userEmail
          changeOwner
          reason
          change
          createdAt
          updatedAt
          groupID
          systemLinks(limit: 1000) {
            items {
              id
              ownerGroup
              system {
                id
                name
                ownerGroup
              }
            }
          }
        }
        keyPerformanceIndicator {
          id
          name
        }
        trainingID
        phishingID
        groupID
        report {
          id
          name
          documents(limit: 1000) {
            items {
              id
              ownerGroup
              avStatus
              createdAt
              file {
                bucket
                key
                region
              }
            }
          }
        }
        artifact {
          id
          name
          status
          reason
          file {
            bucket
            key
            region
          }
          ownerGroup
        }
        documents(limit: 1000) {
          items {
            id
            avStatus
            lastAVCheck
            createdAt
            ownerGroup
            file {
              bucket
              region
              key
            }
            accessControl {
              password
              passwordOwnerEmail
              pointOfContacts
              roles
            }
          }
        }
        pointOfContactID
        pointOfContact {
          id
          firstName
          lastName
          email
        }
      }
      nextToken
    }
  }
`;
