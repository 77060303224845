import { useContext } from "react";

import { ListQueryBy } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { VendorDocument } from "@rivial-security/schema-types";

import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { DataGridResult } from "@hooks/views/useDataGrid/types";
import { UIContext } from "@utils/Context/UIContext";
import { performToastOperation } from "@utils/Toasts/functions/toastOperation";
import { markCertificationControlsAsAudited } from "@views/Vendor/VendorDocuments/functions/markCertificationControlsAsAudited";
import { useVendorDocumentDataGrid } from "@views/Vendor/VendorDocuments/hooks/useVendorDocumentDataGrid";

export interface UseVendorReviewDocumentsDataGridProps {
  organizationID: string;
  vendorReviewID?: string;
  disableEdits?: boolean;
  disableRoleChecking?: boolean;
}

export const useVendorReviewDocumentsDataGrid = ({
  organizationID,
  vendorReviewID,
  disableEdits,
  disableRoleChecking,
}: UseVendorReviewDocumentsDataGridProps): DataGridResult<VendorDocument> => {
  const { addToast, updateToast } = useContext(UIContext);
  const { resetFunction, isLoading } = useSetAsyncData({
    getData: async () => {
      if (!vendorReviewID) return [];

      const { listByQuery } = generateGraphql(
        "VendorDocument",
        ["id", "name", "avStatus", "lastAVCheck", "ownerGroup", "file", "vendorCertification"],
        {
          file: "{ bucket region key }",
          vendorCertification: "{ name }",
        },
        {
          indexName: "vendorDocumentsByVendorReviewID",
          partitionKey: "vendorReviewID",
          partitionKeyType: "ID",
        },
      );

      return await ListQueryBy({
        query: listByQuery,
        variables: { vendorReviewID },
      });
    },
    setData: (documents: VendorDocument[]): void => {
      grid?.setData?.(documents);
    },
    dependencies: [vendorReviewID],
    defaultValue: [],
  });

  const grid = useVendorDocumentDataGrid({
    organizationID,
    resetFunction,
    isLoading,
    disableRoleChecking,
    connectionInput: {
      ownerGroup: organizationID,
      vendorReviewID,
    },
    onSubmit: async (documents) => {
      if (!vendorReviewID) return;
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          await markCertificationControlsAsAudited({
            documents,
            vendorReviewID,
          });
        },
        inProgressText: "Updating controls based on pre-qualified certification...",
        successText: "Controls updated based on pre-qualified certification",
        failedText: "Failed to update controls based on pre-qualified certification",
        iconColor: "success",
      });
    },
    ...(disableEdits
      ? {
          createResourceComponent: null,
          deleteFunction: null,
        }
      : {}),
  });

  return grid;
};
