"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findIndexOfRiskOverride = void 0;
/**
 * Finds the index of a riskOverride object in a system.riskOverrides array
 * @param riskOverrides - the system.riskOverrides array
 * @param risk - the risk to find an override for
 */
const findIndexOfRiskOverride = (riskOverrides, risk) => {
    return riskOverrides?.findIndex((item) => item?.riskId === risk.id);
};
exports.findIndexOfRiskOverride = findIndexOfRiskOverride;
