/**
 * Uses the export function defined in the field definitions to
 * @param {object} ref - the grid reference
 * @param {object[]} selectedItems - all items to export (might be missing object data)
 * @param {object[]} fields - the field definitions (contain the export formatting function)
 * @return {{dataSource: *[]}}
 */
export const prepareDataForExport = ({ ref, selectedItems, fields } = {}) => {
  const exportProperties = { dataSource: [] };

  // Prepare a data map to access item data quickly
  const dataMap = {};
  if (Array.isArray(ref?.dataSource)) {
    for (const item of ref.dataSource) {
      if (item.id) {
        dataMap[item.id] = item;
      }
    }
  }

  // Handle data conversion per field
  const exportData = [];
  for (const item of selectedItems) {
    const exportItem = {};
    for (const field of fields) {
      if (item[field.name] && field.exportConvert) {
        if (item?.id && dataMap.hasOwnProperty(item.id)) {
          exportItem[field.name] = field.exportConvert(dataMap[item.id], dataMap);
        } else {
          exportItem[field.name] = field.exportConvert(item, dataMap);
        }
      }
    }
    exportData.push({ ...item, ...exportItem });
  }
  exportProperties.dataSource = exportData;

  return exportProperties;
};
