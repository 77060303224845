import { GetQuery } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Get the points of contact participating in a meeting
 * @param {string} meetingID - the meeting for which to return participants
 * @return {Promise<*[]>}
 */
export const getMeetingParticipatingPointsOfContact = async ({ meetingID }) => {
  if (!meetingID) {
    return [];
  }

  const { getQuery } = generateGraphql("Meeting", ["pointOfContacts"], {
    pointOfContacts: ` (limit: 100) {
        items {
          id
          ownerGroup
          createdAt
          updatedAt
          pointOfContact {
            id
            firstName
            lastName
            title
            email
            phone
            ownerGroup
          }
        }
        nextToken
      }`,
  });

  const meeting = await GetQuery({
    query: getQuery,
    variables: {
      id: meetingID,
    },
  });

  const databaseParticipantsLinks = meeting?.pointOfContacts?.items || [];
  const newParticipants = [];
  for (const databaseParticipantLink of databaseParticipantsLinks) {
    const participant = databaseParticipantLink?.pointOfContact;

    if (!participant?.id) {
      continue;
    }

    const newPointOfContact = {
      ...participant,
      linkID: databaseParticipantLink?.id,
      id: participant?.id,
    };

    newParticipants.push(newPointOfContact);
  }

  return newParticipants;
};
