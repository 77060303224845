import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { Alert, Button } from "@mui/material";
import { cloneDeep } from "lodash";
import { createContext, useEffect, useReducer } from "react";

import { ItemMutation } from "@rivial-security/appsync-utils";
import { isNonEmptyArray, isNullOrUndefined, tryParse } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import { useUIContext } from "@utils/Context/UIContext";
import { ErrorLogger } from "@utils/EventLogger";

import { useStepper } from "../../../../../hooks/views/useStepper/useStepper";
import { tryFunction, tryItemFunction } from "../../../../../utils/Functions/tryFunction";
import { performToastOperation } from "../../../../../utils/Toasts/functions/toastOperation";

import AddDetailsStep from "./components/AddDetailsStep";
import CustomizeVisualsStep from "./components/CustomizeVisualsStep/CustomizeVisualsStep";
import ProvideDataStep from "./components/ProvideDataStep/ProvideDataStep";
import SelectTypeStep from "./components/SelectTypeStep";
import { createWidgetReducer } from "./reducers/createWidgetReducer";

export const CreateWidgetContext = createContext(null);

const createWidgetSteps = {
  SELECT_TYPE: "create-widget-select-type",
  PROVIDE_DATA: "create-widget-provide-data",
  CUSTOMIZE_VISUALS: "create-widget-customize-visuals",
  ADD_DETAILS: "create-widget-add-details",
};

/**
 * Hook to create and edit any type of custom widget
 * @param {object} initialWidget - widget to edit
 * @param {string} type - default type of widget to create
 * @param {function} getNewItem - callback on creation of new widget
 * @param {function} updateItemById - callback on edit of existing widget
 * @param {string} organizationID - the currently selected organization ID
 * @param {function} toggleModal - callback to toggle the modal once user input for operation is complete
 * @param {function} resetFunction - callback to reset parent component state after an update to the widget
 * @returns {{display: JSX.Element}}
 */
export const useCreateWidget = ({
  widget: initialWidget,
  type,
  getNewItem,
  updateItemById,
  organizationID,
  toggleModal,
  resetFunction,
}) => {
  const { addToast, updateToast } = useUIContext();
  const [state, dispatch] = useReducer(
    createWidgetReducer,
    {
      widget: { type },
      data: {},
    },
    (initialArgs) => {
      const args = {
        ...initialArgs,
      };

      if (initialWidget) {
        initialWidget = cloneDeep(initialWidget);
        initialWidget.config = tryParse(initialWidget?.config);
        args.widget = initialWidget;
      }
      return args;
    },
  );

  const steps = [
    {
      id: createWidgetSteps.SELECT_TYPE,
      text: "Select a Type",
      component: <SelectTypeStep />,
    },
    {
      id: createWidgetSteps.PROVIDE_DATA,
      text: "Provide Data",
      component: <ProvideDataStep organizationID={organizationID} />,
    },
    {
      id: createWidgetSteps.CUSTOMIZE_VISUALS,
      text: "Customize Visuals",
      component: <CustomizeVisualsStep organizationID={organizationID} />,
    },
    {
      id: createWidgetSteps.ADD_DETAILS,
      text: "Add Details",
      component: <AddDetailsStep />,
    },
  ];

  const getNextStepDisabled = () => {
    const activeStepID = steps?.[stepper?.activeStep]?.id;
    switch (activeStepID) {
      case createWidgetSteps.CUSTOMIZE_VISUALS:
        return false;
      case createWidgetSteps.SELECT_TYPE:
        return isNullOrUndefined(state?.widget?.type);
      case createWidgetSteps.ADD_DETAILS:
        const name = state?.widget?.name;
        return isNullOrUndefined(name) || name === "";
      case createWidgetSteps.PROVIDE_DATA:
        const series = state?.widget?.config?.series;
        if (!isNonEmptyArray(series)) {
          return true;
        }

        const widgetType = state?.widget?.type;
        switch (widgetType) {
          case WIDGET_TYPE.TABLE:
            //Table widget require at least one series to have query data
            return !series.some((seriesItem) => {
              const customQueryId = seriesItem?.customQueryId;
              return customQueryId && customQueryId !== "";
            });
          case WIDGET_TYPE.HISTORY_CHART:
            //History chart widget require at least one series to have metric type data
            return !series.some((seriesItem) => {
              const metricTypeId = seriesItem?.metricTypeId;
              return metricTypeId && metricTypeId !== "";
            });
          default:
            return false;
        }
      default:
        return true;
    }
  };

  const onPressSubmit = async () => {
    const widget = state?.widget;
    if (!widget?.config) {
      return;
    }

    const isUpdate = !isNullOrUndefined(widget?.id);

    if (typeof widget?.config !== "string") {
      try {
        widget.config = JSON.stringify(widget.config);
      } catch (e) {
        ErrorLogger("Invalid config when submitting a widget to create", e);
      }
    }
    const { createMutation, updateMutation } = generateGraphql("CustomWidget", [
      "name",
      "description",
      "type",
      "config",
    ]);
    await performToastOperation({
      addToast,
      updateToast,
      inProgressText: "Creating a Widget...",
      successText: "Widget Created Successfully!",
      failedText: "Widget Creation Failed!",
      iconColor: "success",
      operation: async () => {
        tryFunction(toggleModal);
        const createdWidget = await ItemMutation({
          mutation: isUpdate ? updateMutation : createMutation,
          input: {
            ...widget,
            ownerGroup: organizationID,
          },
        });
        if (!createdWidget) {
          throw Error("Failed to create widget!");
        }

        //When get new item available, create form assumed, no need to do full refresh
        if (getNewItem) {
          tryItemFunction({ item: createdWidget, getNewItem, updateItemById });
        } else {
          tryItemFunction({ item: createdWidget, updateItemById });
          tryFunction(resetFunction);
        }
      },
    });
  };

  const stepper = useStepper({
    steps,
  });
  // for update form start with provide data step
  useEffect(() => {
    if (initialWidget) {
      stepper.setActiveStep(2);
    }
  }, []);

  const display = (
    <CreateWidgetContext.Provider value={{ ...state, dispatch, stepper }}>
      <div>
        {stepper.display}
        <div style={{ padding: "1em" }}>
          {stepper?.steps[stepper?.activeStep]?.component || <Alert>Not Implemented Yet</Alert>}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1em",
            justifyContent: "center",
          }}
        >
          {!stepper.isOnFirstStep && (
            <Button variant="contained" startIcon={<ArrowBackRoundedIcon />} onClick={stepper.handlePrevious}>
              Previous
            </Button>
          )}
          {!stepper.isOnLastStep && (
            <Button
              variant="contained"
              endIcon={<ArrowForwardRoundedIcon />}
              disabled={getNextStepDisabled()}
              onClick={stepper.handleNext}
            >
              Next
            </Button>
          )}

          {stepper.isOnLastStep && (
            <Button
              color={"success"}
              variant="contained"
              endIcon={<ArrowUpwardRoundedIcon />}
              onClick={() => {
                onPressSubmit();
              }}
              disabled={getNextStepDisabled()}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </CreateWidgetContext.Provider>
  );

  return { display };
};
