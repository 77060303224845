"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createImage = void 0;
/**
 * Imports an image into the SFDT word document
 * @param imgString
 * @param height
 * @param width
 * @returns {{paragraphFormat: {listFormat: {}, styleName: string}, characterFormat: {}, inlines: [{imageString: *, characterFormat: {}, width, iscrop: boolean, height}]}}
 */
const createImage = (imgString, height, width) => {
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                imageString: imgString,
                isMetaFile: false,
                width: width || 471.375,
                height: height || 258.63075,
                name: "1",
                visible: true,
                verticalPosition: 0,
                verticalOrigin: "Margin",
                verticalAlignment: "None",
                horizontalPosition: 0,
                horizontalOrigin: "Margin",
                horizontalAlignment: "None",
                allowOverlap: true,
                textWrappingStyle: "Inline",
                textWrappingType: "Both",
                distanceBottom: 0,
                distanceLeft: 9,
                distanceRight: 9,
                distanceTop: 0,
                layoutInCell: true,
                zOrderPosition: 2147483647,
            },
        ],
    };
};
exports.createImage = createImage;
