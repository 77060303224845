import React, { useContext, useState } from "react";
import { Alert, Button, Progress } from "reactstrap";

import { useDetails } from "@hooks/views/useDetails/hooks/useDetails";
import { useModal } from "@hooks/views/useModal";
import { UIContext } from "@utils/Context/UIContext";

import { uploadVulnerabilities } from "../../../Vulnerabilities/functions/uploadVulnerabilities";
import { CsvFilesList } from "../../../Vulnerabilities/hooks/useUploadVulnerabilities";
import { createAssessment } from "../../functions/createAssessment";

/**
 * Submit a new Assessment
 * @param {string} organizationID
 * @param {object} input
 * @param {function} getNewItem
 * @param {function} toggleModal
 * @return {JSX.Element}
 */
const AddAssessmentSubmit = ({ organizationID, input, getNewItem, toggleModal }) => {
  const uiContext = useContext(UIContext);

  const [progress, setProgress] = useState(0);

  const AssessmentDate = () => {
    return <>{new Date(input?.assessmentDate).toLocaleString()}</>;
  };

  const SecurityAppliance = ({ item }) => {
    return <>{item?.securityAppliance?.applianceID}</>;
  };
  const PointOfContact = ({ item }) => {
    return (
      <>
        {item?.pointOfContact?.firstName} {item?.pointOfContact?.lastName}
      </>
    );
  };

  const details = useDetails({
    item: input,
    fields: [
      "name",
      "status",
      "type",
      "assessmentDate",
      "scanner",
      "securityAppliance",
      "pointOfContact",
      "penTester",
      "penTesterEmail",
      "includedListTargets",
      "login",
      "password",
      "csvScannerName",
      "files",
    ],
    customFields: [
      {
        field: "assessmentDate",
        component: <AssessmentDate />,
      },
      {
        field: "securityAppliance",
        component: <SecurityAppliance />,
      },
      {
        field: "pointOfContact",
        component: <PointOfContact />,
      },
      {
        field: "files",
        component: <CsvFilesList files={input?.files} disableRemoveItems={true} />,
      },
    ],
    fieldNameDictionary: {
      includedListTargets: "Targets",
      csvScannerName: "CSV Scanner Name",
    },
    disableRoleChecking: true,
  });

  const uploadCSVModal = useModal(
    "Uploading CSV files..",
    <div style={{ margin: "auto", textAlign: "center" }}>
      <p>Please wait while we are uploading your CSV files</p>
      <div className="text-center">{progress}%</div>
      <Progress value={progress} />
    </div>,
  );

  const isSubmitEnabled = input?.name;

  const submitFunction = async () => {
    const toastId = uiContext.addToast({
      header: `Creating new Assessment`,
      icon: "spinner",
      color: "success",
    });

    await createAssessment({
      name: input?.name,
      type: input?.type,
      status: input?.status,
      scanner: input?.csvScannerName,
      securityAppliance: input?.securityAppliance,
      assessmentDate: input?.assessmentDate,
      includedListTargets: input?.includedListTargets,
      pointOfContact: input?.pointOfContact,
      penTester: input?.penTester,
      penTesterEmail: input?.penTesterEmail,
      ownerGroup: organizationID,
    }).then(async (assessment) => {
      getNewItem && getNewItem(assessment);

      uiContext.updateToast({
        id: toastId,
        header: "Assessment was successfully created",
        icon: "success",
      });

      /**
       * Upload vulnerabilities modal
       */
      if (input?.files && Array.isArray(input?.files) && input?.files.length > 0 && input?.csvScannerName) {
        uploadCSVModal.setModalIsOpen(true);
        await uploadVulnerabilities({
          files: input?.files,
          organizationID,
          assessmentId: assessment?.id,
          scanner: input?.csvScannerName,
          columnMap: input?.columnMap,
          onprogress: setProgress,
          reportConfig: input.reportConfig,
        })
          .then(() => {
            uiContext.updateToast({
              id: toastId,
              header: (
                <span>
                  Uploading Findings ...
                  <a href={`#/vulnerabilities/assessments/${assessment?.id}`}>Assessment Details</a>
                </span>
              ),
              icon: "success",
            });
          })
          .finally(() => {
            uploadCSVModal.setModalIsOpen(false);
          });
      }

      toggleModal && toggleModal();
    });
  };

  return (
    <div>
      {!input?.name && <Alert color="danger">Missing Assessment Name. Name is required.</Alert>}
      {details.display}
      {uploadCSVModal.modal}
      <div className="float-right">
        <Button style={{ marginRight: "1em" }} disabled={!isSubmitEnabled} onClick={submitFunction} color="success">
          Submit
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddAssessmentSubmit;
