import React, { useContext } from "react";
import { Alert } from "reactstrap";

import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { VENDOR_REVIEW_STATUS } from "../constants/vendorReviewStatus";

/**
 * The vendor review workflow prefix component, shown to the user when the review is in the finalized state
 * @return {JSX.Element}
 */
const FinalizedReviewAlert = () => {
  const context = useContext(WorkflowContext);

  const goToFinalizeReviewStep = () => {
    context.setCurrentStepById && context.setCurrentStepById({ id: "step_finalize_review" });
  };

  const isAlertOpen = () => {
    const vendorReviewFinalized = context.vendorReview?.status === VENDOR_REVIEW_STATUS.final;

    const stepsNotToShowOn = ["step_finalize_review", "step_5", "step_6"];
    const currentStepIsNotFinalizeReview = !stepsNotToShowOn.includes(context.currentStep?.id);

    return vendorReviewFinalized && currentStepIsNotFinalizeReview;
  };

  return (
    <div>
      <Alert color="info" className="float-center" isOpen={isAlertOpen()}>
        This vendor review has been finalized and is no longer editable.{" "}
        <span style={{ cursor: "pointer" }} onClick={goToFinalizeReviewStep}>
          Click here to modify the review status.
        </span>
      </Alert>
    </div>
  );
};

export default FinalizedReviewAlert;
