"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeNonStandardCharactersFromString = void 0;
/**
 * Removes groups of characters from the input string
 * @param stringContent - the string to strip of non-standard characters
 * @param removeAllNonBasicCharacters - if true will remove most characters leaving only the most commonly used visible ones
 * @param removeAllControlCharacters if true will remove unicode control characters
 * @param removeNullCharacters - if true will remove invisible characters represented which cause issues in reports such as \u0000
 * @returns {*|string} - stringContent with non-standard characters removed
 */
const removeNonStandardCharactersFromString = ({ stringContent, removeAllNonBasicCharacters, removeAllControlCharacters, removeNullCharacters, }) => {
    if (typeof stringContent !== "string") {
        return stringContent;
    }
    let result = stringContent;
    if (removeNullCharacters) {
        // \u0000 - null character
        // eslint-disable-next-line no-control-regex
        result = result.replace(/\u0000/g, "");
    }
    if (removeAllControlCharacters) {
        // \u0000 - null character
        // \u001F - unit separator
        // \u007F - delete
        // \u009F - application program command
        // eslint-disable-next-line no-control-regex
        result = result.replace(/[\u0000-\u001F\u007F-\u009F]/g, "");
    }
    if (removeAllNonBasicCharacters) {
        result = result.replace(/[^A-Za-z0-9\s.,;:'"!?-`~$#-+={}]/g, "");
    }
    return result;
};
exports.removeNonStandardCharactersFromString = removeNonStandardCharactersFromString;
