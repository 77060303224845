import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";
import React from "react";
import { Button } from "reactstrap";

import { convertCamelCaseToSentence, removeObjectFromArray } from "@rivial-security/func-utils";

import { useStateSync } from "../../../functional/useStateSync";
import { useModal } from "../../useModal";

/**
 * Used to select an multiple items from a grid
 * @param {string} value - the id of selected item
 * @param {boolean} disabled - if true won't enable the select button
 * @param {function} onChange - function that's called when a new item is selected
 * @param {object} multiSelectConfig
 * @param {JSX.Element} multiSelectConfig.grid - Select[Resource]Grid.js component with overridable grid configuration
 * @param {string} multiSelectConfig.typename - the graphql model name of the item being selected
 * @return {JSX.Element}
 */
const CustomMultiSelect = ({ value, disabled, onChange, multiSelectConfig = {} } = {}) => {
  const { typename = "Resource" } = multiSelectConfig;

  const [currentItems, setCurrentItems] = useStateSync(value);

  //Modal with grid of items that can be selected
  const selectModal = useModal(
    `Select  ${convertCamelCaseToSentence(typename)}`,
    multiSelectConfig?.grid &&
      React.cloneElement(multiSelectConfig?.grid, {
        // new mui grid
        enableSelectButton: true,
        onSelectCallback: (item) => {
          setCurrentItems((curr = []) => {
            const res = [...(curr || []), { id: item.id, ownerGroup: item.ownerGroup, name: item.name }];
            onChange(res);
            return res;
          });
          selectModal.setModalIsOpen(false);
        },
        wrapperStyle: { minHeight: "30em" }, // added to fix squished grid when using this component

        // old syncfusion grid
        enableSelectButtons: true,
        selectButtonCallback: (item) => {
          setCurrentItems((curr = []) => {
            const res = [...(curr || []), { id: item.id, ownerGroup: item.ownerGroup, name: item.name }];
            onChange(res);
            return res;
          });
          selectModal.setModalIsOpen(false);
        },
      }),
    <Button size={"sm"} color={"primary"} disabled={disabled} style={{ width: "15em" }}>
      {!value ? `Select ${convertCamelCaseToSentence(typename)}` : `Add  ${convertCamelCaseToSentence(typename)}`}
    </Button>,
    {
      width: "70vw",
    },
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {currentItems &&
        currentItems.map((item) => {
          return (
            <p>
              <span style={{ fontWeight: "bold" }}>Selected: </span>
              <span>{item.name || item.id}</span>
              <IconButton
                size={"small"}
                color={"error"}
                onClick={() => {
                  onChange(null);
                  setCurrentItems((curr) => removeObjectFromArray(curr, item));
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </p>
          );
        })}
      {selectModal.modalButton}
    </div>
  );

  //Return the modal button and text of current item
};

export default CustomMultiSelect;
