import React from "react";
import { Button } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { invokeGraphqlFunction } from "../../../../utils/Functions/Graphql/invokeGraphqlFunction";
import NoPermissionIcon from "../../../OrganizationManager/Roles/components/NoPermissionIcon";

const TestAutomation = ({ item }) => {
  const module = modules.METRICS;
  const resource = resources.METRIC;
  const checkPermissions = useCheckPermissions({ module, resource });

  const getSecurityMetrics = async () => {
    return await invokeGraphqlFunction("MetricType", "createSecurityMetric", {
      id: item.id,
      data: "INPUT DATA",
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
      <Button
        size="sm"
        color="primary"
        onClick={() => getSecurityMetrics()}
        disabled={!checkPermissions.resource.create}
      >
        Trigger Automation
      </Button>
      <div style={{ marginLeft: ".5em" }}>
        {!checkPermissions.resource.create && (
          <NoPermissionIcon message={"No permission to create a Metric resource"} />
        )}
      </div>
    </div>
  );
};

export default TestAutomation;
