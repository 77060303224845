import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const OrganizationList = React.lazy(() => import("@views/AdminPanel/Organizations/components/OrganizationList"));
const OrganizationDetails = React.lazy(() => import("@views/AdminPanel/Organizations/components/OrganizationDetails"));
const UserDetailsCard = React.lazy(() => import("@views/OrganizationManager/Users/components/UserDetails"));
const CisspReviewVendors = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/Vendor/components/CisspVendorDataGrid"),
);
const CisspReviewVendorDetails = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/Vendor/components/CisspVendorDetails"),
);
const CisspReviewVendorSolutions = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/VendorSolution/components/CisspVendorSolutionDataGrid"),
);
const CisspReviewVendorSolutionDetails = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/VendorSolution/components/CisspVendorSolutionDetails"),
);
const CisspReviewVendorReviews = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/VendorReview/components/CisspVendorReviewDataGrid"),
);
const CisspReviewVendorReviewDetails = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/VendorReview/components/CisspVendorReview"),
);
const CisspReviewVendorControls = React.lazy(
  () => import("@views/AdminPanel/CisspReviews/VendorControl/components/CisspVendorControlCategoryDataGrid"),
);
const CisspReviewDashboard = React.lazy(() => import("@views/AdminPanel/CisspReviews/Dashboard/CisspReviewDashboard"));

// Operation Teams
const OperationTeamDetails = React.lazy(
  () => import("@views/AdminPanel/OperationTeams/components/OperationTeamDetails"),
);
const OperationTeamGrid = React.lazy(() => import("@views/AdminPanel/OperationTeams/OperationTeams"));

const TemplateManager = React.lazy(() => import("@views/AdminPanel/AdminTemplateManager/AdminTemplateManager"));

const RiskControlsImporter = React.lazy(
  () => import("@views/CsvImporters/RiskControlsImporter/components/RiskControlsImporter"),
);
const RisksImporter = React.lazy(() => import("@views/CsvImporters/RisksImporter/components/RisksImporter"));
const ControlsEvidenceLinkingImporter = React.lazy(
  () => import("@views/CsvImporters/ControlEvidenceLinkImporter/components/ControlEvidenceLinkImporter"),
);

export const administratorPanelNavigation: NavigationModule = {
  moduleName: modules.ADMINISTRATOR,
  sectionName: NavigationSectionName.MANAGEMENT,
  name: "Administrator",
  path: "/admin_panel",
  defaultRoutePath: "/organizations",
  icon: IconName.ADMIN,
  routes: [
    /* Main */
    {
      path: "/organizations",
      name: "Organizations",
      component: OrganizationList,
    },
    {
      path: "/organizations/:id",
      name: "Organization Details",
      component: OrganizationDetails,
    },
    {
      path: "/users/:id",
      name: "User Details",
      component: UserDetailsCard,
    },

    /* Templates */
    {
      path: "/template_manager",
      name: "Global Template Manager",
      component: TemplateManager,
      navigationBar: {
        name: "Global Templates",
      },
    },

    /* Operation Teams */
    {
      path: "/operation_teams",
      name: "Operation Teams",
      component: OperationTeamGrid,
    },
    {
      path: "/operation_teams/:id",
      name: "Operation Team Details",
      component: OperationTeamDetails,
    },

    /* CISSP Vendor Reviews */
    {
      path: "/cissp_reviews/vendors",
      name: "CISSP Reviews - Vendors",
      component: CisspReviewVendors,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/cissp_reviews/vendors/:id",
      name: "CISSP Reviews - Vendor Details",
      component: CisspReviewVendorDetails,
    },
    {
      path: "/cissp_reviews/solutions",
      name: "CISSP Reviews - Vendor Solutions",
      component: CisspReviewVendorSolutions,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/cissp_reviews/solutions/:id",
      name: "CISSP Reviews - Vendor Solution Details",
      component: CisspReviewVendorSolutionDetails,
    },
    {
      path: "/cissp_reviews/reviews",
      name: "CISSP Reviews - Reviews",
      component: CisspReviewVendorReviews,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/cissp_reviews/reviews/:id",
      name: "CISSP Reviews - Review Details",
      component: CisspReviewVendorReviewDetails,
    },
    {
      path: "/cissp_reviews/controls",
      name: "CISSP Reviews - Controls",
      component: CisspReviewVendorControls,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/cissp_reviews/controls/:id",
      name: "CISSP Reviews - Control Details",
      component: CisspReviewVendorControls,
    },
    {
      path: "/cissp_reviews",
      name: "CISSP Reviews - Dashboard",
      component: CisspReviewDashboard,
      navigationBar: {
        name: "CISSP Reviews",
      },
    },

    /* Importers */
    {
      path: "/controls_evidence_import",
      name: "Import Control Evidence Links",
      component: ControlsEvidenceLinkingImporter,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_controls_import",
      name: "Import Risk Controls",
      component: RiskControlsImporter,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risks_import",
      name: "Import Risks",
      component: RisksImporter,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
