import { generateGraphql } from "@rivial-security/generategraphql";

import { GetQuery } from "./GetQuery";
import { ItemQuery } from "./ItemQuery";

/**
 * Invokes a Graphql Lambda Function
 * @param {string} typename - the schema typename under which the function resides
 * @param {string} field - the field name of the lambda function in the type
 * @param {object} input - custom input into the lambda function
 * @returns {Promise<*>} - the response from the lambda function
 */
export const invokeGraphqlFunction = async (typename, field, input) => {
  if (typename === "Query") {
    const query = `query ${field}($input: AWSJSON) {
      ${field}(input: $input)
    }`;
    return await GetQuery({
      query,
      variables: {
        input: JSON.stringify(input),
      },
    });
  } else {
    const query = generateGraphql(typename).invokeFunction(field, input);
    return await ItemQuery(query, input?.id);
  }
};
