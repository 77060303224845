import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Converts text and label selection pair into a single label annotation
 * @param {string} userEmail - person creation the annotation
 * @param {object} label - the label that the annotation is a part of
 * @param {object} selection - selected text data
 * @param {string} bookmarkID - the bookmark that holds the data for this annotation
 * @returns {{bookmarkID, color: (*|string), pageBounds: {[p: number]: *}, type: string, labelLinkID: *, version: string}|null}
 */
export const convertSelectionToLabelAnnotation = ({ userEmail, label, selection, bookmarkID }) => {
  const { rects, pageNumber } = selection || {};

  //Check arguments
  if (!Array.isArray(rects) && !isNullOrUndefined(pageNumber)) {
    return null;
  }

  //Convert text bounds into the highlight annotation bounds
  // - separate text bounds into groups by page (page number starts at 0)
  const pageBounds = { [pageNumber]: rects };

  const annotation = {
    version: "0.0.1",
    type: "highlight",
    color: label?.backgroundColor || "yellow",
    labelLinkID: label?.labelLinkID,
    pageBounds,
    bookmarkID,
  };

  return annotation;
};
