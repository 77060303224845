import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Helper hook for a boolean state variable.
 * Includes toggle functions and has a useEffect callback built in.
 * @param {boolean} [initialState = false] - initial boolean state
 * @param {function} [effect] - an optional callback function that gets called on useEffect
 * @example const [isLoading, setIsLoading] = useBoolean(false); // isLoading = false and setIsLoading = function(bool)
 */
export const useBoolean = (initialState = false, effect = () => {}) => {
  const [bool, setBool] = useState(initialState);

  const toggle = (val) => (!isNullOrUndefined(val) ? setBool(val) : setBool((b) => !b));
  const toggleOn = () => setBool(true);
  const toggleOff = () => setBool(false);

  useEffect(() => {
    effect && effect();
  }, [bool]);

  return [bool, setBool, { toggle, toggleOn, toggleOff }];
};
