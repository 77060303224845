import { Alert } from "@mui/material";
import React, { useState } from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm";
import {
  VULNERABILITY_SEVERITY_LEVEL,
  VULNERABILITY_TYPE,
} from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { useAssessmentDataGrid } from "../../Assessments/hooks/useAssessmentDataGrid";
import { useTargetsGrid } from "../../Targets/hooks/useTargetsGrid";
import { createVulnerability } from "../functions/createVulnerability";

/**
 * Create Vulnerability hook
 * @param {string} organizationID - selected organization ID
 * @param {function} toggleModal - toggle modal function
 * @param {function} getNewItem - get new item function
 * @param {function} resetFunction - reset function to call after creating vulnerability
 * @param {ReactElement} footerPrefix - prefix for the footer
 * @param {object} props - other props for the hook
 * @param {boolean} props.isTargetDetailsView - if true, this hook was called from the target details view
 * @returns {{input: {}, display: *}}
 */
export const useCreateVulnerability = ({
  organizationID,
  toggleModal,
  getNewItem,
  resetFunction,
  footerPrefix,
  ...props
}) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.VULNERABILITY;

  const [assessment, setAssessment] = useState(props?.assessment);
  const [target, setTarget] = useState(props?.target);

  /**
   * Assessment grid
   */
  const assessmentListHook = useAssessmentDataGrid({
    organizationID,
    fields: [
      {
        name: "name",
        flex: 1,
      },
    ],
    enableSelectButton: true,
    onSelectCallback: (item) => item && setAssessment(item),
  });

  /**
   * Target grid
   */
  const targetListHook = useTargetsGrid({
    organizationID,
    fields: [
      {
        name: "ip",
        flex: 1,
      },
    ],
    enableSelectButton: !props?.isTargetDetailsView,
    onSelectCallback: (item) => item && setTarget(item),
  });

  const submitFunction = async (input) => {
    const ports = input.port ? [input.port] : [];
    const protocols = input.protocol ? [input.protocol] : [];
    const cves = input.cve ? [input.cve] : [];

    delete input.port;
    delete input.protocol;
    delete input.cve;
    delete input.assessment;
    delete input.target;

    const vulnerability = await createVulnerability({
      vulnerability: {
        ...input,
        ports,
        protocols,
        cves,
        ownerGroup: organizationID,
      },
      assessment,
      target,
      isTargetDetailsView: props?.isTargetDetailsView,
    });

    getNewItem?.(vulnerability);
    resetFunction?.();
    toggleModal?.();
  };

  const form = useForm({
    fieldConfig: {
      name: {
        required: true,
        label: "Name",
      },
      type: {
        required: true,
        label: "Type",
        inputType: "dropdown",
        defaultValue: VULNERABILITY_TYPE.VULNERABILITY,
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: VULNERABILITY_TYPE }),
        },
      },
      severityLevel: {
        required: true,
        label: "Severity Level",
        inputType: "dropdown",
        defaultValue: VULNERABILITY_SEVERITY_LEVEL.LOW,
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: VULNERABILITY_SEVERITY_LEVEL }),
        },
      },
      port: {
        label: "Port",
        inputType: "number",
      },
      protocol: {
        label: "Protocol",
      },
      cvss: {
        label: "CVSS",
      },
      cve: {
        label: "CVE",
      },
      impact: {
        label: "Impact",
      },
      solutionType: {
        label: "Solution Type",
      },
      solution: {
        label: "Solution",
      },
      summary: {
        label: "Summary",
      },
      exploitAvailable: {
        label: "Exploit Available",
        inputType: "switch",
        defaultValue: false,
        switchConfig: {
          onLabel: "Yes",
          offLabel: "No",
        },
      },
      ...(!props?.assessment && {
        assessment: {
          inputType: "custom",
          label: "Select Assessment",
          tooltip: "Select Assessment for the new Vulnerability",
          customConfig: {
            component: (
              <div style={{ height: "30em", marginBottom: "5em" }}>
                {assessment?.name && <Alert>Selected: {assessment?.name}</Alert>}
                {assessmentListHook.display}
              </div>
            ),
          },
        },
      }),
      ...((!props?.target || props?.isTargetDetailsView) && {
        target: {
          inputType: "custom",
          label: "Select Target",
          tooltip: "Select Target for the new Vulnerability",
          defaultValue: target?.id,
          customConfig: {
            component: (
              <div
                style={{
                  height: props?.isTargetDetailsView ? "1em" : "30em",
                  marginBottom: "5em",
                }}
              >
                {target?.ip && <Alert>Selected: {target.ip}</Alert>}
                {props?.isTargetDetailsView ? null : targetListHook.display}
              </div>
            ),
          },
        },
      }),
    },
    submitFunction,
    organizationID,
    getNewItem,
    module,
    resource,
    toggleModal,
    footerPrefix,
  });

  return {
    ...form,
  };
};
