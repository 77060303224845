import { Button } from "@mui/material";
import React from "react";

import { useModal } from "../../../../hooks/views/useModal";
import DocumentEditor from "../components/DocumentEditor";

/**
 * Helper hook for quick access to a Document Editor modal
 * @param {object} props - the props for this component
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useDocumentEditorModal = (props) => {
  return useModal(
    "Report Generator",
    <DocumentEditor {...props} />,
    props.button || <Button startIcon={<i className={"icon-doc"} />}>Generate Report</Button>,
    {
      width: "80vw",
    },
  );
};
