export const listVendorControlCategoriesByOwnerGroup = /* GraphQL */ `
  query VendorControlCategoriesByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorControlCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorControlCategoriesByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
      }
      nextToken
    }
  }
`;
