import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";
import StringListField from "@utils/CustomFields/StringListField";

import CustomResourceTypeDetails from "../components/CustomResourceTypeDetails";
import CustomResourceTypeForm from "../components/CustomResourceTypeForm";
import { deleteCustomResourceType } from "../functions/deleteCustomResourceType";

/**
 * A hook to create a new CustomResourceType
 * @param {object} ...props - props to pass to useDataGrid
 */
export const useCustomResourceTypeDataGrid = ({ ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL; // temp until role-utils is updated
  const typename = "CustomResourceType";
  const route = "#/organization_manager/custom_resource_types/";

  const { listQuery, updateMutation } = generateGraphql("CustomResourceType", ["name", "description", "customFields"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format } }`,
  });

  const fields = [
    {
      field: "name",
      flex: 1,
    },
    {
      field: "description",
      flex: 1,
    },
    {
      name: "customFields",
      friendlyName: "Custom Fields",
      component: <StringListField fieldName={"customFields"} deepProperty={"name"} />,
      minWidth: 200,
      flex: 1,
      valueGetter: (value) => Array.isArray(value) && value?.map((field) => field?.name)?.join(", "),
      searchKeys: ["customFields.*.name"],
    },
  ];

  const nestedFields = {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format }}`,
  };

  return useDataGrid({
    fields,
    typename,
    route,
    module,
    resource,
    nestedFields,
    updateMutation,
    createResourceComponent: <CustomResourceTypeForm />,
    detailsComponent: <CustomResourceTypeDetails />,
    query: listQuery,
    deleteFunction: deleteCustomResourceType,
    options: ["delete"],
    ...props,
  });
};
