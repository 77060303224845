import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKnowBe4RecipientDataGrid } from "../hooks/useKnowBe4RecipientDataGrid";

/**
 * @description Display list of groups of a phishing test
 * @param {string} itemId - phishing test ID
 * @returns {JSX.Element}
 * @constructor
 */
const KnowBe4PhishingRecipients = (props) => {
  return useKnowBe4RecipientDataGrid(props).display;
};

export default withOrganizationCheck(KnowBe4PhishingRecipients);
