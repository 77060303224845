"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberWithCommas = void 0;
/**
 * Get a number separated by commas every three digits (not localized)
 * @param value - converts a number to a comma separated string (not localized)
 * @returns 0 if value is null or undefined, number with commas otherwise
 */
const numberWithCommas = (value) => {
    const number = Number(value);
    if (!value || isNaN(number)) {
        return value ? "N/A" : "0";
    }
    const [integerPart, decimalPart] = value.toString().split(".");
    const formattedInteger = new Intl.NumberFormat().format(Number(integerPart));
    return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};
exports.numberWithCommas = numberWithCommas;
