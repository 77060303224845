import { useEffect, useState } from "react";

import { DisabledWrapper } from "../../../../utils/GenericComponents/DisabledWrapper";
import { getCustomResourceTypes } from "../../../../utils/ResourceTypes/functions/getCustomResourceTypes";
import { useAutomationConditionBuilder } from "../hooks/useAutomationConditionBuilder";

/**
 * Uses the Logic Builder to perform boolean logic on fields for the triggering resource
 * @param {string} organizationID - the id of currently selected organization
 * @param {function} onChangeCallback - useForm field callback function
 * @param {string} itemId - the automation if of the trigger being configured
 * @param {string} [typename]- optional for pre-setting the typename dropdown
 * @param {Automation} item - an existing Automation resource, to be edited using this UI
 * @param {boolean} disableEdits - determines if edits should be disabled
 * @param {boolean} isTemplate - determines if the form is being used to create a template
 * @returns {JSX.Element}
 */
const ConfigureTriggerCondition = ({
  organizationID,
  onChangeCallback,
  itemId,
  typename,
  item,
  disableEdits,
  isTemplate = false,
}) => {
  /**
   * Holds the list of CustomResourceTypes for the org to be added dynamically to the condition builder
   */
  const [customResourceTypes, setCustomResourceTypes] = useState({});

  /**
   * Get Custom Resource Types for org
   */
  useEffect(() => {
    if (organizationID) {
      getCustomResourceTypes({ organizationID }).then((types) => {
        setCustomResourceTypes(types);
      });
    }
  }, [organizationID]);

  const builder = useAutomationConditionBuilder({
    organizationID,
    onChangeCallback,
    itemId,
    typename,
    item,
    disableEdits,
    isTemplate,
    customResourceTypes,
  });

  return (
    <DisabledWrapper
      isDisabled={item?.enabled === true}
      message={"Cannot edit conditions while the automation is enabled!"}
    >
      {builder.display}
    </DisabledWrapper>
  );
};

export default ConfigureTriggerCondition;
