import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useEffect } from "react";

import { useDetails } from "../../useDetails/hooks/useDetails";
import { useModal } from "../../useModal";
import { GridDetailsModalHeader } from "../components/GridDetailsModalHeader";
import { gridAdjustSelectedItemIndex } from "../functions/gridAdjustSelectedItemIndex";

/**
 * @description Grid Item description hook
 * @param {object} ref - reference of the the grid component
 * @param {object} lastSelectedItem - last selected item from the grid
 * @param {string} module - module name of the grid data type
 * @param {string} resource - resource name of the grid data type
 * @param {boolean} disableRoleChecking - disable role checking for the grid
 * @param {string} typename - the name of an item type
 * @param {boolean} hasNext - boolean value if selected grid item has next item
 * @param {boolean} hasPrevious - boolean value if selected grid item has previous item
 * @param {string} route - route for the grid data type
 * @param {object} detailsItem - item to show in the details modal
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {function} setDetailsItem - sets the detailsItem object
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {object} config - grid configuration object
 * @param {boolean} isLoading - indicator for loading data from database
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {function} setHasPrevious - indicating if the list has a previous item
 * @param {function} setHasNext - function to set a flag if the selected item has next in the item in the grid
 * @param {function} updateItemById - update an item by id in the grid
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 * @param {string} [detailsTitle] - optional, overrides the details modal title
 * @return {object} {{detailsModal: {setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}}
 */
export const useGridItemDetails = ({
  ref,
  lastSelectedItem,
  module,
  resource,
  disableRoleChecking,
  typename,
  hasNext,
  hasPrevious,
  route,
  detailsItem,
  detailsComponent,
  setDetailsItem,
  detailsBar,
  detailsType,
  config,
  isLoading,
  setSelectedItems,
  setLastSelectedItem,
  disableUpdate,
  setHasPrevious,
  setHasNext,
  updateItemById,
  rightClick,
  detailsTitle,
}) => {
  /**
   * Details hook
   */
  const detailsHook = useDetails({
    item: lastSelectedItem,
    module: module || "",
    resource: resource || "",
    disableRoleChecking: !(module && resource && !disableRoleChecking),
    enableNotes: true,
  });

  const wrapperGridAdjustSelectedItemIndex = (indexDiff, reset) =>
    gridAdjustSelectedItemIndex({
      indexDiff,
      reset,
      ref,
      setSelectedItems,
      setLastSelectedItem,
      setHasNext,
      rightClick,
      disableUpdate,
      detailsComponent,
      detailsItem,
      detailsType,
      detailsBar,
      setHasPrevious,
      setDetailsItem,
      updateItemById,
      detailsModal,
    });

  /**
   * The Details Modal for this particular item in the list
   */
  const detailsModal = useModal(
    <GridDetailsModalHeader
      typename={typename}
      adjustSelectedItemIndex={wrapperGridAdjustSelectedItemIndex}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      route={route}
      detailsItem={detailsItem}
      config={config}
      detailsTitle={detailsTitle}
    />,
    detailsItem && detailsComponent && !config?.disableDetails
      ? React.cloneElement(detailsComponent, {
          key: `details_modal_detail${detailsItem.id}`,
          item: { ...detailsItem },
          getUpdatedItem: (item) => {
            updateItemById(item);
          },
        })
      : detailsHook.display,
    <span
      title={`Click to view ${typename}` || "item" + " details"}
      id={`details_modalwrapper_${detailsItem.id}`}
      data-testid={"details_modal_button_wrapper"}
    >
      <InfoOutlinedIcon style={{ color: "#25556a", cursor: "pointer" }} id={`details_modal_${detailsItem.id}`} />
    </span>,
    {
      width: config?.width || "90vw",
    },
  );

  /**
   * Adjust Next and Previous buttons when details modal is opened
   */
  useEffect(() => {
    if (detailsModal.modalIsOpen === true) {
      const data = ref?.getCurrentViewRecords && ref?.getCurrentViewRecords();

      if (data && Array.isArray(data) && detailsItem?.id) {
        /**
         * Gets the index of the currently selected details item in the array
         */
        const index = data.findIndex((x) => x?.id === detailsItem.id);

        if (index !== null && index !== undefined && index !== -1 && !isNaN(data.length)) {
          const hasNext = index < data.length - 1;
          const hasPrevious = index > 0;

          setHasNext && setHasNext(hasNext);
          setHasPrevious && setHasPrevious(hasPrevious);

          detailsBar?.setHasNext && detailsBar?.setHasNext(hasNext);
          detailsBar?.setHasPrevious && detailsBar?.setHasPrevious(hasPrevious);
        }
      }

      detailsBar?.setAdjustItemIndexFunc &&
        detailsBar?.setAdjustItemIndexFunc({
          func: wrapperGridAdjustSelectedItemIndex,
        });
    }
  }, [detailsModal.modalIsOpen, detailsItem]);

  /**
   * When the details modal is closed, reset the detailsItem state
   */
  useEffect(() => {
    if (lastSelectedItem && setDetailsItem && detailsModal.modalIsOpen === false) {
      setDetailsItem(lastSelectedItem);
    }
  }, [detailsModal?.modalIsOpen]);

  useEffect(() => {
    detailsBar?.setIsLoading && detailsBar.setIsLoading(isLoading);
  }, [isLoading]);

  /**
   * Set details item
   */
  useEffect(() => {
    if (lastSelectedItem && setDetailsItem) {
      setDetailsItem(lastSelectedItem);
    }
  }, [lastSelectedItem]);

  return {
    detailsModal,
  };
};
