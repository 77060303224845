/**
 * Formats the resulting value from the mutation
 * @param {function} customFormat - a function that updates the result
 * @param {string} finalValue - the value that is being updated by the customFormat function
 * @returns {*}
 */
export const formatResult = ({ customFormat, finalValue }) => {
  let resValue;

  /**
   * If the customFormat function is passed in, pipe the finalValue through it before the mutation
   */
  if (customFormat && typeof customFormat === "function") {
    resValue = customFormat(finalValue);
  } else {
    /**
     * If no customFormat function is passed in, send the finalValue directly
     */
    resValue = finalValue;
  }

  return resValue;
};
