import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useQuestionnaireDetails } from "../hooks/useQuestionnaireDetails";

/**
 * A component instance of the useQuestionnaireDetails.js hook
 * @param {object} props
 */
const QuestionnaireDetails = (props) => {
  return useQuestionnaireDetails(props).display;
};

export default withDetailsPage(QuestionnaireDetails);
