import React from "react";

import { useNestedDataCard } from "../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import ThreatGrid from "../../../../Response/Threats/components/ThreatGrid";
import { useThreatGrid } from "../../../../Response/Threats/hooks/useThreatGrid";
import ThreatTemplateForm from "../../../../Templates/ThreatTemplates/ThreatTemplateForm";
import ThreatTemplateGrid, { useThreatTemplateGrid } from "../../../../Templates/ThreatTemplates/ThreatTemplateGrid";
import CreateThreat from "../../Threats/components/CreateThreat";
import { createRiskThreatLink } from "../functions/createRiskThreatLink";
import { deleteRiskThreatLink } from "../functions/deleteRiskThreatLink";

/**
 * Displays a nested data card for KRI Threats (m-m connection).
 * @param {object} item - the KRI to display threats for
 * @param {string} organizationID - the ID of the organization
 * @param {boolean} isTemplate - whether the KRI is a template
 * @param {object} ...props - other props to pass to the grid hook
 * @returns {JSX.Element}
 * @constructor
 */
const KeyRiskIndicatorThreats = ({ item, organizationID, isTemplate, ...props }) => {
  const nestedCard = useNestedDataCard({
    item,
    organizationID,
    field: "threatLinks",
    connectionField: "threat",
    deleteFunction: deleteRiskThreatLink,
    typename: isTemplate ? "Threat Template" : "Threat",
    parentTypename: "Risk",
    fields: ["threatLinks"],
    nestedFields: {
      threatLinks: `(limit: 1000) { items { id threat { id name ownerGroup } } }`,
    },
    grid: isTemplate ? (
      <ThreatTemplateGrid organizationID={organizationID} {...props} />
    ) : (
      <ThreatGrid organizationID={organizationID} createResourceComponent={null} />
    ),
    gridHook: isTemplate ? useThreatTemplateGrid : useThreatGrid,
    form: isTemplate ? (
      <ThreatTemplateForm organizationID={organizationID} {...props} />
    ) : (
      <CreateThreat organizationID={organizationID} />
    ),
    createFunction: createRiskThreatLink,
    flipCreateParams: true,
    gridHookGridConfig: {
      detailsTitle: isTemplate ? "Threat Template Details" : "Threat Details",
      createItemModalHeader: isTemplate ? "Create a Threat Template" : "Create a Threat",
      createResourceButtonText: "Link",
      deleteButtonText: "Unlink",
      options: ["details", "delete"],
      ...props,
    },
    ...props,
  });

  return <div style={{ height: "75vh" }}>{nestedCard.gridDisplay}</div>;
};

export default withOrganizationCheck(KeyRiskIndicatorThreats);
