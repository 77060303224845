import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateRiskRecommendation } from "../hooks/useCreateRiskRecommendation/useCreateRiskRecommendation";

/**
 * Component for creating a new Risk Recommendation
 * @param organizationID
 * @param item
 * @param system
 * @param riskControl
 * @param toggleModal
 * @param resetFunction
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateRecommendation = (props) => useCreateRiskRecommendation(props).display;

export default withOrganizationCheck(CreateRecommendation);
