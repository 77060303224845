import { useVendorDataGrid } from "../../../../views/Vendor/Vendor/hooks/useVendorDataGrid";

export const vendorDefinition = {
  routes: {
    grid: "vendors/vendor",
    details: "vendors/vendor",
    helpCenter: "vendors",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:basket",

  //[COMPONENTS]
  gridHook: useVendorDataGrid,
};
