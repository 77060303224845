import React, { useEffect, useState } from "react";

/**
 * @description The AccordionIcon component is a generic component that renders an icon that can be used to expand and collapse a section of content.
 * @param {string} type - The type of icon to render.
 * @param {boolean} button - The button that will be used to expand and collapse the content.
 * @param {function} toggleFunction - The function that will be used to expand and collapse the content.
 * @param {object} style - The style of the icon.
 * @param {string} icon - The icon to render.
 * @param {boolean} isOpen - The state of the accordion.
 * @param {boolean} enableOnClick - If the icon should be enabled on click.
 * @returns {JSX.Element} - The AccordionIcon component.
 * @constructor - The AccordionIcon component.
 */
const AccordionIcon = ({ type = "arrow", button, toggleFunction, style = {}, icon, isOpen, enableOnClick = false }) => {
  const [className, setClassName] = useState(undefined);

  useEffect(() => {
    setClassName(getClassName());
  }, [isOpen]);

  const getClassName = () => {
    switch (type) {
      case "arrow":
        if (isOpen) return "icon-arrow-down";
        else return "icon-arrow-left";
      default:
        return null;
    }
  };

  return (
    <>
      {button ? (
        React.cloneElement(button, {
          onClick: toggleFunction ? () => toggleFunction() : undefined,
        })
      ) : (
        <i
          onClick={() => enableOnClick && toggleFunction()}
          style={{
            color: "lightGrey",
            cursor: enableOnClick ? "pointer" : undefined,
          }}
          className={`${className} ${style.className || "float-sm-right"}`}
        />
      )}
    </>
  );
};

export default AccordionIcon;
