import { WarningLogger } from "@utils/EventLogger";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

import { gridRowSelected } from "./gridRowSelected";

/**
 * @description Sets the selected "details item" based on array index.
 * @param {number} [indexDiff=1] - a positive or negative integer. Adjusts the current Array index by + or - values.
 * @param {boolean} reset - completed resets the selected details item
 * @param {object} ref - reference of the grid component
 * @param {object} detailsItem - item to show in the details modal
 * @param {function} setDetailsItem - sets the detailsItem object
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {function} setHasPrevious - indicating if the list has a previous item
 * @param {function} setHasNext - function to set a flag if the selected item has next in the item in the grid
 * @param detailsBar - details bar for UI context
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {JSX} detailsModal - modal component for displaying details of an object
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {function} updateItemById - update an item by id in the grid
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 */
export const gridAdjustSelectedItemIndex = ({
  indexDiff = 1,
  reset = false,
  ref,
  detailsItem,
  setDetailsItem,
  setLastSelectedItem,
  setSelectedItems,
  setHasPrevious,
  setHasNext,
  detailsBar,
  detailsType,
  detailsModal,
  disableUpdate,
  updateItemById,
  detailsComponent,
  rightClick,
}) => {
  /**
   * List of params to check for null
   */
  const params = {
    ref,
    detailsItem,
    setDetailsItem,
    setLastSelectedItem,
    setSelectedItems,
    setHasPrevious,
    setHasNext,
    detailsBar,
    detailsType,
    detailsModal,
    disableUpdate,
    updateItemById,
    detailsComponent,
    rightClick,
  };

  /**
   * Check for valid params
   */
  checkParams({ params });

  /**
   * If there is no tableData or detailsItem, do nothing.
   */
  if (ref?.currentViewData && detailsItem?.id) {
    /**
     * Gets the index of the currently selected details item in the array
     */
    const index = ref?.currentViewData.findIndex((x) => x.id === detailsItem?.id);

    if (index !== null && index !== undefined && index !== -1) {
      const newItem = ref?.currentViewData[index + indexDiff];
      if (newItem) {
        setDetailsItem({ ...newItem });

        gridRowSelected({
          args: { data: { ...newItem } },
          detailsBar,
          detailsType,
          setSelectedItems,
          setLastSelectedItem,
          detailsModal,
          disableUpdate,
          updateItemById,
          detailsComponent,
          rightClick,
        });

        ref?.selectRows([index + indexDiff]);
        setLastSelectedItem({ ...newItem });
        setSelectedItems([{ ...newItem }]);

        setHasPrevious(!!ref?.currentViewData[index - 1]);
        setHasNext(!!ref?.currentViewData[index + 1]);
      }
    } else {
      WarningLogger(
        "[gridAdjustSelectedItemIndex.js] Could not adjust selected Details Item index. Could not find tableData index.",
      );
    }
  }

  /**
   * If reset flag is passed, clear the selected item and set the detailsItem to the first in the list
   */
  if (reset && ref?.dataSource?.[0]) {
    setDetailsItem(ref.dataSource[0]);
  }
};
