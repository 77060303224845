import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Determines if a type from schema.json should be used with the Query Builder.
 *
 * @param {object} type
 * @param {string} type.kind
 * @param {string} type.name
 * @param {object[]} type.fields
 * @param showConnections
 * @returns {boolean}
 */
export const isValidType = (type, showConnections = false) => {
  /**
   * Type can't be null or undefined
   */
  if (isNullOrUndefined(type)) {
    return false;
  }

  /**
   * Type has to be an object
   */
  if (typeof type !== "object") {
    return false;
  }

  const typename = type.name;

  if (type.kind !== "OBJECT") return false;

  /**
   * ModelConnections only flag as VALID if we are looking at a nested field
   */
  if (typename.includes("Model") && showConnections) return true;

  if (typename.includes("Model")) return false;

  if (typename.includes("Connection")) return false;

  if (typename.includes("__")) return false;

  if (typename.includes("Query")) return false;

  if (typename.includes("Mutation")) return false;

  if (typename.includes("Subscription")) return false;

  /**
   * Only show non-model types if this is a nested field, since we can't query a type that isn't a model
   */
  if (!showConnections && !hasRequiredFields(type.fields)) return false;

  return true;
};

/**
 * Checks if there's an ID and ownerGroup field.
 * @param fields
 * @returns {boolean}
 */
const hasRequiredFields = (fields) => {
  let hasOwnerGroup = false;
  let hasId = false;

  fields.forEach((field) => {
    if (field.name === "id") {
      hasId = true;
    }

    if (field.name === "ownerGroup") {
      hasOwnerGroup = true;
    }
  });

  return hasId && hasOwnerGroup;
};
