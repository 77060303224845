import React from "react";

/**
 * Displays a simple Alert to the user that they are trying to view a System template in the wrong place.
 *
 * NOTE: this is not an ideal approach, and a redirect should be used instead.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateAlert = (props) => {
  return (
    <div>
      You are trying to view a system template in the wrong place. Please go to System Templates in the Admin Panel.
    </div>
  );
};

export default SystemTemplateAlert;
