"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CALCULATION_CONTEXT = void 0;
/**
 * Enum representing the source of the request for the calculation.
 * NOTE: eventually this should not be used in favor of separating
 * calculations completely from logic for saving results.
 * @type {{RISK_RECOMMENDATION: string, RISK_CHANGE: string, VIEW_SYSTEM: string}}
 */
exports.CALCULATION_CONTEXT = {
    RISK_RECOMMENDATION: "RiskRecommendation", //from front-end simulations
    RISK_CHANGE: "RiskChange", //from createRiskChange lambda
    VIEW_SYSTEM: "ViewSystem",
};
