export const getUser_app_init = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      acceptanceDate
      preferences
      config
      operationTeam {
        id
      }
      operationTeamID
      ownerGroup
      roleLinks {
        items {
          id
          role {
            id
            name
            roleConfig
            resources
            precedence
            ownerGroup
          }
          ownerGroup
        }
        nextToken
      }
    }
  }
`;
