import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 *
 * @param controls {object[]}
 * @param control {object}
 * @param certifications {object[]}
 * @param certification {object}
 * @param organizationID {string}
 * @returns {Promise<number>} -1 if all creations failed (bad input), 0 if all creations successful, 1+ for number of creation that were not successful
 * @constructor
 */

export const CreateVendorCertificationControlLink = async ({
  controls,
  control,
  certifications,
  certification,
  organizationID,
}) => {
  const { createMutation } = generateGraphql("VendorCertificationControlLink", ["certificationID", "controlID"]);

  let failedLinkCreations = 0;

  if (controls && Array.isArray(controls) && controls.length > 0 && certification && certification.id) {
    for (const item of controls) {
      try {
        if (item && item.id) {
          await ItemMutation(createMutation, {
            certificationID: certification.id,
            controlID: item.id,
            ownerGroup: organizationID,
          }).then(() => InfoLogger("VendorCertificationControlLink was successfully created."));
        }
      } catch (e) {
        failedLinkCreations++;
        ErrorLogger("VendorCertificationControlLink creation has failed.");
      }
    }
  } else if (certifications && Array.isArray(certifications) && certifications.length > 0 && control && control.id) {
    for (const item of certifications) {
      try {
        if (item && item.id) {
          await ItemMutation(createMutation, {
            certificationID: item.id,
            controlID: control.id,
            ownerGroup: organizationID,
          }).then(() => InfoLogger("VendorCertificationControlLink was successfully created."));
        }
      } catch (e) {
        failedLinkCreations++;
        ErrorLogger("VendorCertificationControlLink creation has failed.");
      }
    }
  } else if (control && control.id && certification && certification.id) {
    try {
      await ItemMutation(createMutation, {
        certificationID: certification.id,
        controlID: control.id,
        ownerGroup: organizationID,
      }).then(() => InfoLogger("VendorCertificationControlLink was successfully created."));
    } catch (e) {
      failedLinkCreations++;
      ErrorLogger("VendorCertificationControlLink creation has failed.");
    }
  } else {
    ErrorLogger("Error! Invalid input for CreateVendorCertificationControlLink function!");
    return -1;
  }

  return failedLinkCreations;
};
