import { ErrorLogger } from "@utils/EventLogger";

import { MonteCarloCalculationWithQueryLambda } from "../../../functions/MonteCarloCalculationWithQueryLambda";

/**
 * Runs the monte carlo function for a system and sets results to state
 * @param {object} system - the system to run monte carlo on
 * @param {string} organizationID - ownerGroup of the system
 * @param {function} setMonteCarloInput - function to set the monte carlo input
 * @param {function} setRiskScore - function to set the state of the risk score
 * @param {function} setMonteCarloResults - function to set the state of the monte carlo results
 * @param {function} updateItemById - function to call to update the underlying utilities upon finish of a calculation
 * @returns {Promise<*>}
 */
export const runMonteCarlo = async (
  system,
  organizationID,
  { setMonteCarloInput, setRiskScore, setMonteCarloResults },
  updateItemById,
) => {
  let result;

  if (system) {
    try {
      result = await MonteCarloCalculationWithQueryLambda({
        system,
        organizationID,
      });

      if (typeof updateItemById === "function" && result?.systemStats && system?.id) {
        const {
          residualRisk,
          inherentRisk,
          riskRating,
          lossToleranceDelta,
          greatestKeyRiskIndicator,
          greatestBusinessRisk,
          greatestEnterpriseRisk,
        } = result?.systemStats || {};

        const newFields = {
          id: system?.id,
          ...(residualRisk && { residualRisk }),
          ...(inherentRisk && { inherentRisk }),
          ...(riskRating && { riskRating }),
          ...(lossToleranceDelta && { lossToleranceDelta }),
          ...(greatestKeyRiskIndicator && { greatestKeyRiskIndicator }),
          ...(greatestBusinessRisk && { greatestBusinessRisk }),
          ...(greatestEnterpriseRisk && { greatestEnterpriseRisk }),
        };

        updateItemById(newFields);
      }

      if (result) {
        setMonteCarloInput(result.monteCarloSystemInput);
      }

      if (result?.riskRatings?.riskScores?.scores?.[0]) {
        setRiskScore(result?.riskRatings?.riskScores?.scores?.[0]);
      }
    } catch (e) {
      ErrorLogger(`Error: Could not run Monte Carlo Calculation lambda -${e.message}`);
    }
  }

  setMonteCarloResults(result);
  return result;
};
