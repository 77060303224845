import { API } from "@aws-amplify/api";
import { useEffect } from "react";

import { useUIContext } from "@utils/Context/UIContext";
import { InfoLogger } from "@utils/EventLogger";

import { useRefState } from "../../functional/useRefState";

import { onJobUpdateSubscription } from "./graphql/onJobUpdateSubscription";

/**
 * Hook allowing to track multiple jobs occurring at once (automatically unsubscribes on unmount or completion of a job)
 * @param {function} onStarted - called when a job gets added to the subscriptions list
 * @param {function} onComplete - called when job status changes to complete
 * @param {function} onProgress - called with new job object every time a job is updated
 * @param {function} onFailed - called when a job is failed
 * @return {{jobs: *[], addJob: ((function(object): Promise<void>)|*)}}
 */
export const useJobTracker = ({ onStarted, onComplete, onProgress, onFailed }) => {
  const { addToast, updateToast, removeToast } = useUIContext();
  const [jobSubscriptions, setJobSubscriptions, jobSubscriptionsRef] = useRefState([]);
  const [toasts, setToasts, toastsRef] = useRefState([]);

  /**
   * Adds a job to the list of subscriptions
   * @param {object} job - the job data with an id property
   * @return {Promise<void>}
   */
  const addJob = async ({ job } = {}) => {
    //Do not add a job if it doesn't have an id
    if (!job?.id) {
      return;
    }

    InfoLogger("JOB STARTED!", { job });
    if (onStarted) {
      const toast = await onStarted(job);
      if (toast) {
        const newToasts = [...toasts, toast];
        setToasts(newToasts);
        addToast(toast);
      }
    }

    //Subscribe to any new events
    const activeSubscription = await API.graphql({
      query: onJobUpdateSubscription,
      variables: {
        id: job.id,
      },
    }).subscribe({
      next: async (eventData) => {
        const job = eventData?.value?.data?.onJobUpdate;
        await processJobEvent({ job });
      },
    });
    setJobSubscriptions({ ...jobSubscriptions, [job.id]: activeSubscription });
  };

  /**
   * Process every single type of mutation on the tracked job objects
   * @param {object} job - updated job object
   */
  const processJobEvent = async ({ job }) => {
    if (job.status === "completed") {
      // need to use references because this function is in the subscribe function closure
      const toasts = toastsRef?.current;
      const jobSubscriptions = jobSubscriptionsRef?.current;

      InfoLogger("JOB COMPLETED!", { job });
      if (onComplete) {
        const toast = await onComplete(job);

        //check if there are any active toasts for the job
        const toastIndex = toasts.findIndex((toast) => toast?.id === job?.id);
        if (toastIndex !== -1) {
          const toastId = job?.id;
          if (toast) {
            updateToast(toast);
          } else {
            removeToast(toastId);
          }
          setToasts((toasts) => toasts.splice(toastIndex, 1));
        }
      }
      if (job?.id && jobSubscriptions[job.id]) {
        jobSubscriptions[job.id].unsubscribe();
        setJobSubscriptions({ ...jobSubscriptions, [job.id]: null });
      }
    } else if (job.status === "started" || job.status === "progress") {
      onProgress?.(job);
    } else if (job.status === "failed") {
      onFailed?.(job);
    }
  };

  //Unsubscribe from any remaining subscriptions when hook is unmounted
  useEffect(() => {
    return () => {
      Object.keys(jobSubscriptions).forEach((id) => {
        if (jobSubscriptions[id]) {
          jobSubscriptions[id].unsubscribe();
        }
      });
    };
  }, []);

  return {
    addJob,
    jobSubscriptions,
  };
};
