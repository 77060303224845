import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";

import { useMutation } from "../../../../../../../hooks/graphql/useMutation/useMutation";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useListThreats } from "../../../../../RiskConfig/Threats/hooks/useListThreats";

const AttachThreatToKeyRiskIndicator = (props) => {
  const disableRoleChecking = props.disableRoleChecking;

  const currentThreats = props.currentThreats || [];

  const listThreatsHook = useListThreats({
    organizationID: props.organizationID,
    disableRoleChecking,
  });

  useEffect(() => {
    listThreatsHook.setSelectedItems([...currentThreats]);
    listThreatsHook.setShowSelectBoxes(true);
  }, []);

  const addSystemRiskThreatLink = useMutation({
    mutation: realTimeRiskGQL.createSystemRiskThreatLink_minimal,
    disableRoleChecking: true,
    disableToast: true,
  });
  const removeSystemRiskThreatLink = useMutation({
    mutation: realTimeRiskGQL.deleteSystemRiskThreatLink_minimal,
    disableRoleChecking: true,
    disableToast: true,
  });

  const handleSubmit = () => {
    // Add threats to SystemRiskLink
    for (const i of listThreatsHook.selectedItems) {
      const index = currentThreats.findIndex((item) => item.id === i.id);
      // let index = -1;
      if (index === -1) {
        const input = {
          systemRiskThreatLinkSystemRiskLinkId: props.systemRiskLink.id,
          systemRiskThreatLinkThreatId: i.id,
          ownerGroup: props.organizationID,
        };
        addSystemRiskThreatLink.createItem(input);
      }
    }

    // Remove assets from system
    for (const i of currentThreats) {
      const index = listThreatsHook.selectedItems.findIndex((item) => item.id === i.id);
      if (index === -1) {
        const input = {
          id: i.linkId,
        };
        removeSystemRiskThreatLink.deleteItem(input);
      }
    }

    setTimeout(() => {
      props.resetFunction && props.resetFunction();
      props.toggleModal && props.toggleModal();
    }, 1500);
  };

  return (
    <>
      <Button color="success" onClick={() => handleSubmit()} style={{ marginBottom: "1em" }}>
        Submit
      </Button>
      {listThreatsHook.display}
    </>
  );
};

export default withOrganizationCheck(AttachThreatToKeyRiskIndicator);
