import { formattedDollar } from "@rivial-security/func-utils";

/**
 * Displays a change info between two dollar values
 * @param {number} newValue - the new value of the monetary field
 * @param {number} oldValue - the old value of the monetary field
 * @returns {JSX.Element}
 */
const CostChange = ({ oldValue, newValue }) => {
  //Get the old decimal value
  let oldCost = null;
  if (Number.isFinite(oldValue)) {
    oldCost = oldValue;
  }

  //Get the new decimal value
  let newCost = null;
  if (Number.isFinite(newValue)) {
    newCost = newValue;
  }

  //Instance Variables
  const noValueChange = oldCost === newCost || newCost === null;
  let percentChange = null;
  let signChange = "";
  if (noValueChange === false && oldCost !== null) {
    if (oldCost === 0) {
      percentChange = null;
    } else {
      percentChange = (newCost - oldCost) / oldCost;
      signChange = percentChange > 0 ? "+" : "-";
    }
  }

  return (
    <div>
      {/* old and new values side by side */}
      <div>
        {/* old percentage */}
        {oldCost !== null && (
          <p
            style={{
              textDecoration: noValueChange ? "none" : "line-through",
              display: "inline",
            }}
          >
            {formattedDollar(oldCost)}
          </p>
        )}{" "}
        {/* new percentage*/}
        {noValueChange ? (
          <p style={{ display: "inline" }}>(No Change)</p>
        ) : (
          <p
            style={{
              display: "inline",
              color: newCost > oldCost || !oldCost ? "red" : "green",
            }}
          >
            {formattedDollar(newCost)}
          </p>
        )}
      </div>

      {/* show percent change between old and new values below, unless there's no change */}
      {noValueChange === false && percentChange !== null && (
        <div>
          <p
            style={{
              color: newCost > oldCost ? "red" : "green",
            }}
          >
            {`[ ${signChange}${Math.abs(percentChange * 100).toFixed(2)}% ]`}
          </p>
        </div>
      )}
    </div>
  );
};

export default CostChange;
