import { createFilterOptions } from "@mui/base";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { usePointOfContactList } from "../../PointOfContacts/hooks/usePointOfContactList";

const filter = createFilterOptions();

/**
 * Allows uer to select Points of Contact or manually enter emails in a list to send an email to
 * @param organizationID
 * @param onChangeCallback
 * @param {object} item - the EmailTemplate item
 * @param {string} field - either 'recipients' or 'replyTo'
 * @returns {JSX.Element}
 * @constructor
 */
const EmailRecipients = ({ organizationID, onChangeCallback, item, field = "recipients" }) => {
  const [recipients, setRecipients] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    if (Array.isArray(recipients)) {
      onChangeCallback(recipients);
    }
  }, [recipients]);

  useEffect(() => {
    if (Array.isArray(item?.[field])) {
      setRecipients(item[field]);
    }
  }, [item]);

  const pointOfContactListHook = usePointOfContactList({
    organizationID: organizationID,
    showSelectBoxes: true,
  });

  return (
    <span style={{ marginTop: "0.2em", width: "100%" }}>
      <Autocomplete
        autoComplete="none"
        multiple
        id="email-recipients"
        size="small"
        options={pointOfContactListHook.data?.filter((d) => !isNullOrUndefined(d.email))}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.email;
        }}
        defaultValue={item?.[field]?.map((email) => {
          return {
            email,
          };
        })}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            // placeholder="Emails"
          />
        )}
        renderOption={(props, option) => <li {...props}>{option.email}</li>}
        freeSolo
        onChange={(event, newValue) => {
          if (Array.isArray(newValue)) {
            const reformatted = newValue.map((x) => {
              if (typeof x === "string") {
                return x;
              } else if (typeof x === "object") {
                return x.inputValue || x.email;
              }
            });
            setRecipients(reformatted);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.email);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              email: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
      />
    </span>
  );
};

export default withOrganizationCheck(EmailRecipients);
