import { useCustomQueryDataGrid } from "../../../../views/CustomQueries/hooks/useCustomQueryDataGrid";

export const queryDefinition = {
  routes: {
    grid: "reports/queries",
    details: "reports/query",
    helpCenter: "custom-queries",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:chemistry",

  //[COMPONENTS]
  gridHook: useCustomQueryDataGrid,
};
