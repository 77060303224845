import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { MEETING_STATUS } from "../../../../typedefs/Program/Meeting/Meeting";
import { isInSelectTemplatesGrid } from "../../../../utils/Functions/Views/grid/isInSelectTemplatesGrid";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Display and edit a meeting status
 * @param {object} item - the meeting object with data
 * @param {string} module - app module for front end role checking
 * @param {string} resource -  app resource for front end role checking
 * @param {string} field - the field that represents the meeting status
 * @param {function} updateItemById - update an item by id in the grid
 * @param {object} props - other props to pass through to GenericEditField
 * @returns {JSX.Element}
 */
const MeetingStatus = ({
  item,
  module = modules.GOVERNANCE,
  resource = resources.MEETING,
  field = "status",
  updateItemById,
  gridPersistenceUUID,
  ...props
}) => {
  return (
    <div key={`meeting-status-${item?.id}`}>
      <GenericEditFieldV3
        inputType={GENERIC_FIELD_TYPES.DROPDOWN}
        item={item}
        inputConfig={{
          data: enumToDropdownData({ ENUM: MEETING_STATUS }),
        }}
        field={field}
        mutation={
          !isInSelectTemplatesGrid({ gridPersistenceUUID }) && generateGraphql("Meeting", ["status"]).updateMutation
        }
        updateItemById={updateItemById}
        resource={resource}
        module={module}
        {...props}
      />
    </div>
  );
};

export default MeetingStatus;
