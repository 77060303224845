import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import PolicyDataGrid from "../../../Program/Policies/components/PolicyDataGrid";
import { usePolicyDataGrid } from "../../../Program/Policies/hooks/usePolicyDataGrid";

import { useEvidenceNestedDataGrid } from "./EvidenceNestedDataGrid";

/**
 * Displays Policies associated with an Evidence item through Activities
 * @param {object} item - evidence object
 * @param {object} recordEvidence - record evidence hook instance
 * @returns {JSX.Element}
 * @constructor
 */
export const useEvidencePolicies = ({ item, recordEvidence }) => {
  return useEvidenceNestedDataGrid({
    item,
    typename: "Policy",
    connectionField: "policy",
    grid: <PolicyDataGrid />,
    gridHook: usePolicyDataGrid,
    recordEvidence,
  });
};

const EvidencePolicies = (props) => useEvidencePolicies(props).display;

export default withOrganizationCheck(EvidencePolicies);
