/**
 *  TYPE DEFINITIONS
 *
 * @typedef {object} Evidence
 * @description A piece of data that an Organization uses to verify compliance with a Control.
 * @property {string} id - uuid for this type
 * @property {string} name - a descriptive name for this evidence. e.g. 'Information Security Policy'
 * @property {EvidenceFrequency} frequency - how often this evidence needs to be gathered
 * @property {EvidenceDueDate} dueDate - a day/month/year combination
 * @property {EvidenceStatus} status - the current status of this Evidence
 * @property {EvidenceActivity[]} [activity] - various activity items for this evidence, includes point of contact responses
 * @property {PointOfContact[]} [pointOfContacts] - the people responsible for maintaining this evidence
 * @property {Observation[]} [observations] - any observations made regarding this evidence
 * @property {Tag[]} [tags] - any relevant tags for this Evidence
 * @property {boolean} [isSubscribed = false] - determines if this Evidence is subscribed to updates from the template
 * @property {Evidence} [evidenceTemplate] - the Evidence template, if applicable
 *
 * @typedef {string} EvidenceDueDate
 * @description the cron expression used for Evidence scheduling. e.g. 0 0 1 *\/3 *
 *
 * @typedef {object} EvidenceActivity
 * @description An instance of activity happening to this Evidence, includes Responses
 * @property {string} id - uuid for this type
 * @property {string} date - the date of the response
 * @property {PointOfContact} pointOfContact - the point of contact that performed this response
 * @property {Note[]} notes - notes or comments about this response
 * @property {File[]} files - any files uploaded during this response
 * @property {EvidenceType} type - defaults to the parent Evidence.type field, but can be adjusted by the user
 * @property {Evidence} evidence - the evidence this response pertains to
 * @property {boolean} attested - defaults to false. Completed user response marks this as true
 * @property {EvidenceActivityType} type - the type of this activity
 *
 * @typedef {string} EvidenceActivityType
 * @description the type of Activity happening to an Evidence
 * @type {{
 *    documentUpload: string,
 *    reportUpload: string,
 *    expiringSoon: string,
 *    expired: string,
 *    attestation: string,
 *    validated: string
 *  }}
 *
 * @typedef {string} EvidenceType
 * @description determines configuration settings for an Evidence, e.g. for landing page details
 * @type {{
 *    DOCUMENT: string,
 *    POLICY: string,
 *    REPORT: string,
 *    ATTESTATION: string,
 *    SCREENSHOT: string,
 *    MEETING: string
 *  }}
 *
 *
 *  @typedef {string} EvidenceFrequency
 *  @description A human-readable frequency value for an evidence
 *  @type {{
 *    QUARTERLY: string,
 *    DAILY: string,
 *    MONTHLY: string
 *  }}
 *
 * @typedef EvidenceStatus
 * @description The current status of an evidence. Indicates if an Evidence is effectively proving a Control is in place
 * @type {{
 *    EXPIRING_SOON: string,
 *    EXPIRED: string,
 *    PENDING_VALIDATION: string,
 *    IN_PLACE: string,
 *    AUDITED: string
 * }}
 *
 */

// ENUMS
export const EVIDENCE_FREQUENCIES = [
  { id: "0 9 * * *", name: "Daily" },
  { id: "0 9 * * 1", name: "Weekly" },
  { id: "0 9 1 * *", name: "Monthly" },
  { id: "0 9 1 1,4,7,9 *", name: "Quarterly" },
  { id: "0 9 1 1,7 *", name: "BiAnnually" },
  { id: "0 9 1 1 *", name: "Annually" },
];

export const EVIDENCE_STATUS = {
  EXPIRED: "expired",
  EXPIRING_SOON: "expiringSoon",
  PENDING_VALIDATION: "pendingValidation",
  IN_PLACE: "inPlace",
};

export const EVIDENCE_TYPES = {
  DOCUMENT: "document",
  POLICY: "policy",
  REPORT: "report",
  ATTESTATION: "attestation",
  MEETING: "meeting",
  SCREENSHOT: "screenshot",
  KPI: "keyPerformanceIndicator",
  TRAINING: "training",
  PHISHING: "phishing",
  EXTERNAL_URL: "externalUrl",
};

export const EVIDENCE_ACTIVITY_TYPES = {
  DOCUMENT_UPLOAD: "documentUpload",
  REPORT_UPLOAD: "reportUpload",
  VALIDATED: "validated",
  EXPIRING_SOON: "expiringSoon",
  EXPIRED: "expired",
  MEETING: "meeting",
  ATTESTED: "attested",
  POLICY: "policy",
  SCREENSHOT: "screenshot",
  RISK_CHANGE: "riskChange",
  PENDING_VALIDATION: "pendingValidation",
  TRAINING: "training",
  PHISHING: "phishing",
  EXTERNAL_URL: "externalUrl",
  POINT_OF_CONTACT_ASSIGNED: "pointOfContactAssigned",
  POINT_OF_CONTACT_UNASSIGNED: "pointOfContactUnassigned",
  FREQUENCY_CHANGED: "frequencyChanged",
  STATUS_CHANGED_MANUALLY: "statusChangedManually",
  TYPE_CHANGED: "typeChanged",
  CONTROL_LINKED: "controlLinked",
  CONTROL_UNLINKED: "controlUnlinked",
  CREATED_AT: "createdAt",
};
