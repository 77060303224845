import { useRoleTemplateForm } from "../hooks/useRoleTemplateForm";

/**
 * Component instance of the useCreateRoleTemplate hook for the Admin Panel
 * @param {object} props - props to pass down from parent to create role template hook and the HOC component
 * @returns {JSX.Element}
 */
const RoleTemplateForm = (props) => {
  return useRoleTemplateForm(props).display;
};

export default RoleTemplateForm;
