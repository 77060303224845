export const getKnowBe4Training = /* GraphQL */ `
  query GetKnowBe4Training($query: QueryGetKnowBe4TrainingQueryInput!) {
    getKnowBe4Training(query: $query) {
      campaign_id
      name
      status
      groups {
        group_id
        name
      }
      duration_type
      start_date
      end_date
      relative_duration
      auto_enroll
      allow_multiple_enrollments
      completion_percentage
      content {
        name
        description
        duration
        retired
        retirement_date
        publish_date
        publisher
        store_purchase_id
      }
    }
  }
`;
