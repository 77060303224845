import { Link, Popover, Typography } from "@mui/material";
import React, { useState } from "react";

/**
 * Link button showing a hint popover on how to use artifact labels
 * @returns {JSX.Element}
 */
export const ArtifactLabelsHowToUse = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Link component="button" variant="body2" onClick={handleClick}>
        How to use labels?
      </Link>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          - <strong>Add a label</strong> by selecting some text in the document
          <br />- <strong>Navigate to a bookmark</strong> by pressing on a label that appears here
        </Typography>
      </Popover>
    </div>
  );
};
