import { API, graphqlOperation } from "@aws-amplify/api";

import { ErrorLogger, InfoLogger } from "../../EventLogger";

/**
 * Retrieves data from the database with the given parameters (can optionally pre-process the data)
 * @param {object} props - The properties object.
 * @param {string | undefined | null} props.query - The list by query to execute.
 * @param {object} [props.variables={}] - The variables to pass to the query, often the ownerGroup. Optional.
 * @param {object} [props.filter] - AppSync filters for the query. Optional.
 * @param {number} props.limit - The amount of items to get with each next token.
 * @param {string} [props.nextToken=""] - The next token to use for the query (if utilizing pagination). Optional, defaults to an empty string.
 * @param {function} [props.setNextToken] - Callback for setting the next page's token. Optional.
 * @param {number} [props.itemLimit] - Max number of items to retrieve before returning, will stop executing the query if reached this limit. Optional.
 * @param {function} [props.getDataByNextToken] - Function to use to receive data in chunks. Optional.
 * @param {function} [props.normalizeData] - A function which formats the entire retrieved data set. Optional.
 * @param {function} [props.normalizeRowData] - A function to transform database item data into a new item given as output. Optional.
 * @param {function} [props.setIsLoading] - A function to set the loading state. Optional.
 * @return {Promise<[]>} A promise that resolves to the list of retrieved items.
 */
export const ListQueryBy = async ({
  query,
  variables = {},
  filter,
  limit,
  nextToken = "",
  setNextToken,
  itemLimit,
  getDataByNextToken,
  normalizeData,
  normalizeRowData,
  setIsLoading,
}) => {
  InfoLogger(`Starting ListQueryBy function. Time: ${new Date().toISOString()}`);

  const list = [];

  const getData = async () => {
    setIsLoading?.(true);

    await API.graphql(
      graphqlOperation(query, {
        ...variables,
        filter,
        nextToken: nextToken ? nextToken : undefined,
        limit: limit ? limit : 1000,
      }),
    )
      .then(async ({ data }) => {
        const typeName = Object.keys(data)[0];
        const listArray = data && data[typeName] && data[typeName].items;
        if (listArray && Array.isArray(listArray) && listArray.length > 0) {
          if (getDataByNextToken && typeof getDataByNextToken === "function") {
            if (normalizeData && typeof normalizeData === "function") {
              getDataByNextToken(normalizeData(listArray));
            } else if (normalizeRowData && typeof normalizeRowData === "function") {
              const arr = [];
              for (const obj of listArray) {
                arr.push(normalizeRowData(obj));
              }
              getDataByNextToken(arr);
            } else {
              getDataByNextToken(listArray);
            }
          }
          list.push(...listArray);
        }

        if (data && data[typeName] && data[typeName].nextToken) {
          nextToken = data[typeName].nextToken;
          setNextToken?.(nextToken);

          //Stop recursively getting data if we've reached the item limit
          if (Array.isArray(list) && list.length >= itemLimit) {
            return;
          }

          return await getData();
        } else {
          nextToken = "";
          setNextToken?.("");
          setIsLoading?.(false);
        }
      })
      .catch((err) => ErrorLogger("Error! Cannot get data", err));
  };

  await getData();

  //Remove items from the end if over requested item limit
  if (itemLimit && Array.isArray(list) && list.length > itemLimit) {
    list.splice(itemLimit, list.length - itemLimit);
  }

  InfoLogger(`Finishing execution ListQueryBy function. Time: ${new Date().toISOString()}`);

  if (normalizeData && typeof normalizeData === "function") {
    return normalizeData(list);
  } else {
    return list;
  }
};
