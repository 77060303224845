import { ListQueryBy } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { Assessment, AssessmentVulnerabilityLink, Maybe, Vulnerability } from "@rivial-security/schema-types";

export interface GetAssessmentsBinnedByVulnerabilityInput {
  organizationID: string;
}

type OnlyAssessmentsRequiredVulnerability = Omit<Partial<Vulnerability>, "assessments"> & {
  assessments: Partial<Assessment>[];
};

export type GetAssessmentsBinnedByVulnerabilityOutput = Record<string, OnlyAssessmentsRequiredVulnerability>;

export const getAssessmentsBinnedByVulnerability = async ({
  organizationID,
}: GetAssessmentsBinnedByVulnerabilityInput): Promise<GetAssessmentsBinnedByVulnerabilityOutput> => {
  const vulnerabilities: GetAssessmentsBinnedByVulnerabilityOutput = {};

  if (!organizationID) return vulnerabilities;

  //Query all targets
  const assessments: Partial<Assessment>[] = await ListQueryBy({
    query: listAssessmentsByOwnerGroup,
    limit: 1000,
    variables: { ownerGroup: organizationID },
  });

  if (!isNonEmptyArray(assessments)) {
    return vulnerabilities;
  }

  //Bin targets into vulnerabilities object
  for (const assessment of assessments) {
    const vulnerabilityLinks: Maybe<AssessmentVulnerabilityLink>[] = assessment?.vulnerabilities?.items ?? [];
    if (!isNonEmptyArray(vulnerabilityLinks)) {
      continue;
    }

    for (const vulnerabilityLink of vulnerabilityLinks) {
      const vulnerabilityID = vulnerabilityLink?.vulnerabilityID;
      if (!vulnerabilityID) {
        continue;
      }

      if (!vulnerabilities[vulnerabilityID]) {
        vulnerabilities[vulnerabilityID] = {
          assessments: [assessment],
        };
      } else {
        vulnerabilities[vulnerabilityID]?.assessments.push(assessment);
      }
    }
  }

  return vulnerabilities;
};

const listAssessmentsByOwnerGroup = `
  query ListAssessmentsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vulnerabilities(limit: 1000) {
          items {
            id
            vulnerabilityID
          }
        }
      }
      nextToken
      __typename
    }
  }
`;
