"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSmartValueNodeName = void 0;
/**
 * Retrieves the automation step name from a smart value, if smart value
 * does not reference an automation step, returns null
 * @param {string} smartValue - the smart value to parse
 * @returns {string|null} - the automation step name within the smart value
 */
const getSmartValueNodeName = ({ smartValue }) => {
    //check smart values is a string
    if (typeof smartValue !== "string") {
        return null;
    }
    //check all smart values nodes start with "steps."
    if (!smartValue.startsWith("steps.")) {
        return null;
    }
    //check steps value must be at least 2 parts long
    const smartValueParts = smartValue.split(".");
    if (Array.isArray(smartValueParts) && smartValueParts.length < 2) {
        return null;
    }
    //the second part is the automation step name which is the node name
    return smartValueParts[1];
};
exports.getSmartValueNodeName = getSmartValueNodeName;
