"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutomationStepIdentifier = void 0;
/**
 * Convenience function that returns automation step name if avalible, else its id
 * @param {object} automationStep - the automation step data
 * @returns {string|null}
 */
const getAutomationStepIdentifier = ({ automationStep }) => {
    return automationStep?.name || automationStep?.id || null;
};
exports.getAutomationStepIdentifier = getAutomationStepIdentifier;
