import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useBoolean } from "../../../../../../../hooks/functional/useBoolean";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { useEvidenceDataGrid } from "../../../../../../Compliance/Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";
import { rollupEvidence } from "../functions/rollupEvidence";

/**
 * Displays Evidence that is related to a system by rolling up Evidence connections from:
 *
 * System -> Control Categories -> RiskControls -> Evidence links
 * @param props
 * @constructor
 */
const SystemEvidence = ({ item, organizationID }) => {
  const [evidence, setEvidence] = useState([]);

  const [isLoading, setIsLoading] = useBoolean(true);

  useEffect(() => {
    if (!isNullOrUndefined(item)) {
      setIsLoading(true);

      rollupEvidence({ system: item })
        .then((evidences) => {
          const evidenceArr = [];
          for (const link of evidences) {
            if (link?.link?.evidence) {
              evidenceArr.push(link?.link?.evidence);
            }
          }
          setEvidence(evidenceArr);
        })
        .finally(() => setIsLoading(false));
    }
  }, [item]);

  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    deleteFunction: null,
    gridHeight: "100%",
    createResourceComponent: null,
    duplicationSettings: { enabled: false },
  };

  const evidenceGrid = useEvidenceDataGrid({
    organizationID,
    ...queryConfig,
    ...gridConfig,
    disablePointOfContactAssign: true,
  });

  return (
    <div style={{ height: "30em" }}>
      <DataLoader
        isEnoughData={Array.isArray(evidence) && evidence.length > 0}
        isLoading={isLoading}
        dataMessage={
          "There are no Evidence items associated with this System. Attach Evidences to Risk Controls and they will show up here."
        }
      >
        {evidenceGrid.gridDisplay}
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(SystemEvidence);
