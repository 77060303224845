import { GetQuery } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Get data for vendor review controls report
 * @param {object} input - params to get report data
 * @returns {Promise<*&{reportType: string, vendorReview: any[], vendorControls: *[]}>}
 */
export const getVendorReviewControlsReportData = async ({ input }) => {
  const { itemId, vendorReviewID } = input?.config ?? null;

  if (!itemId && !vendorReviewID) throw Error("Missing itemId");

  const reportType = "Vendor Review Controls";

  const nestedFields = {
    controlCategories: `(limit: 100)
    {
      items {
        name
          subControls (limit: 1000) {
            items {
              id
              name
              statementNumber
              inPlace
              audited
            }
          }
          notes {
            content
            observationID
          }
        }
      }
    `,
  };

  const { getQuery } = generateGraphql("VendorReview", ["name", "controlCategories"], nestedFields);

  const vendorReview = await GetQuery({
    query: getQuery,
    variables: {
      id: itemId || vendorReviewID,
    },
    env: process.env,
  });

  const vendorControlCategories = vendorReview?.controlCategories?.items;
  let vendorControls = [];

  for (const category of vendorControlCategories) {
    const subControls = category?.subControls?.items;
    if (Array.isArray(subControls)) {
      for (const control of subControls) {
        const exists = vendorControls.find((x) => x.id === control.id);
        !exists &&
          vendorControls.push({
            ...control,
            controlCategory: category?.name || "N/A",
          });
      }
    }
  }

  /**
   * Sort Controls by statementNumber
   */
  vendorControls = vendorControls.sort((a, b) => {
    return a?.statementNumber?.localeCompare(b?.statementNumber, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });

  return {
    ...input,
    vendorControlCategories,
    reportType,
    vendorControls,
    vendorReview,
  };
};
