"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterMappedSmartValues = void 0;
const filterSmartValues_1 = require("./filterSmartValues");
/**
 * Filters all smart values that are stored as arrays under an object
 * @param {object} mappedSmartValues - an object with keys mapping to array of smart values
 * @param {string[]} filters - smart value filters to apply, see constants -> smartValueFilters.js
 * @return {object} - an object with keys mapping to array of smart values but with filters applied
 */
const filterMappedSmartValues = ({ mappedSmartValues, filters = [] }) => {
    const filteredSmartValues = { ...mappedSmartValues };
    for (const smartValuesKey in mappedSmartValues) {
        const smartValues = mappedSmartValues[smartValuesKey];
        if (Array.isArray(smartValues)) {
            filteredSmartValues[smartValuesKey] = (0, filterSmartValues_1.filterSmartValues)({ smartValues, filters });
        }
    }
    return filteredSmartValues;
};
exports.filterMappedSmartValues = filterMappedSmartValues;
