import Grid from "@mui/material/Grid";
import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { getResourceAttribute } from "../../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../../enums/fieldContexts";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import ReportsGridByItemID from "../../../../Reports/ReportsGridByItemID";
import ControlSetControls from "../components/ControlSetControls";
import CustomFieldsTable from "../components/CustomFieldsTable";
import FrameworkComplianceField from "../components/FrameworkComplianceField";
import GenerateControlFrameworkReport from "../components/GenerateControlFrameworkReport";

/**
 * Control Framework details hook

 * @param {string} organizationID - the organization of the control framework to view
 * @param {string} module=modules.ADMINISTRATOR - platform module for role checking
 * @param {string} resource=resources.CONTROL_TEMPLATE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {boolean} isTemplate=false - if TRUE will hide non template parts of the resource
 * @param {string} itemId - control framework id
 * @param {boolean} tableDisplay - if true will display the details as a table instead of a card
 * @param {function} updateItemById - function to get updated item
 * @param {object} props - additional properties passed through to useDetails hook
 * @return {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: JSX.Element, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useControlSetDetails = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL_FRAMEWORK,
  disableRoleChecking = false,
  isTemplate = false,

  itemId,
  tableDisplay,
  updateItemById,
  ...props
}) => {
  const typename = resources.CONTROL_FRAMEWORK;

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });

  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const fieldNameDictionary = {
    name: "Name",
    customFields: "Custom Fields",
    totalComplianceMetric: "Compliance Status",
  };

  const customFields = [
    {
      field: "customFields",
      component: <CustomFieldsTable showAddButtonInit={true} />,
    },
  ];

  //Only show metrics field for non template control frameworks
  if (!isTemplate) {
    displayFields.splice(2, 0, "totalComplianceMetric");
    customFields.push({
      field: "totalComplianceMetric",
      component: <FrameworkComplianceField />,
      friendlyName: "Compliance Status",
    });
  }

  const details = useDetailsCard({
    queryConfig,
    module,
    resource,
    disableRoleChecking,
    displayFields,
    detailsConfig: {
      header: `Control ${isTemplate ? "Template " : ""}Framework Details`,
      updateMutation,
      customFields,
      fieldNameDictionary,
    },
    typename,
    nestedFields,
    tableDisplay,
    updateItemById,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        component: details.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Controls",
        component: <ControlSetControls item={details?.item} organizationID={organizationID} isTemplate={isTemplate} />,
        icon: "icon-shield",
        defaultExpanded: true,
      },
      {
        title: "Reports",
        component: <ReportsGridByItemID itemId={itemId} />,
        icon: "icon-list",
        hidden: isTemplate,
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={details.reset}
      subTitle={details?.item?.name}
      headerButtons={
        !isTemplate && [<GenerateControlFrameworkReport itemId={itemId} organizationID={organizationID} />]
      }
    >
      <div>
        <Grid item lg={12} xs={12} container spacing={2}>
          {accordion.display}
        </Grid>
      </div>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
