"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPropertyToObjects = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
/**
 * Adds a property to each object in an array.
 * Can either be a static key, value pair or use a callback function
 * @param {object[]} objects - array of objects to add property to
 * @param {function | object} property - an object or a function that returns on object
 *
 * @example
 * const updatedObjects = addPropertyToObjects(objects, {hello: "world"});
 * const updatedObjects = addPropertyToObjects(objects, (item) => ({hello: item.name}));
 * @returns {object[]}
 */
const addPropertyToObjects = (objects, property) => {
    if (!(0, isNullOrUndefined_1.isNullOrUndefined)(objects) && Array.isArray(objects)) {
        return objects.map((obj) => {
            if (typeof property === "function") {
                return {
                    ...obj,
                    ...property(obj),
                };
            }
            else {
                return {
                    ...obj,
                    ...property,
                };
            }
        });
    }
    else {
        return objects;
    }
};
exports.addPropertyToObjects = addPropertyToObjects;
