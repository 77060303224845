// @ts-check
import moment from "moment/moment";
import { useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";
import { TargetVulnerabilityStatus as TargetVulnerabilityStatusType } from "@rivial-security/schema-types";

import { TargetVulnerabilityStatus } from "@views/Testing/Vulnerabilities/components/TargetVulnerabilityStatus";
import { getTargetVulnerabilityLinkPointOfContactField } from "@views/Testing/Vulnerabilities/constants/getTargetVulnerabilityLinkPointOfContactField";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { getObjectPropertyString } from "../../../../utils/Functions/Array/getObjectPropertyString";
import CreateTarget from "../../Targets/components/CreateTarget";
import TargetsGrid from "../../Targets/components/TargetsGrid";
import { isValidIp } from "../../Targets/functions/isValidIp";
import { isValidMacAddress } from "../../Targets/functions/isValidMacAddress";
import { useTargetsGrid } from "../../Targets/hooks/useTargetsGrid";
import { changeTargetLinkStatus } from "../functions/changeTargetLinkStatusOperations/changeTargetLinkStatus";
import { createTargetFindingLink } from "../functions/createTargetFindingLink";
import { deleteTargetFindingLink } from "../functions/deleteTargetFindingLink";

/**
 * @typedef {import('./VulnerabilityTargetsProps').VulnerabilityTargetsProps} VulnerabilityTargetsProps
 * Displays a grid of vulnerability targets with the ability to create, edit, and delete targets.
 * @param {VulnerabilityTargetsProps} props
 */
export const VulnerabilityTargets = ({
  item,
  organizationID,
  status,
  resetFunction,
  isLoading,
  targets,
  setTargets,
  linkedItems,
}) => {
  const gridHookGridConfig = {
    fields: [
      {
        name: "ip",
        flex: 1,
        inputConfig: isValidIp,
        sort: {
          direction: "desc",
          priority: 1,
        },
      },
      {
        name: "hostName",
        flex: 1,
      },
      {
        name: "macAddress",
        flex: 1,
        inputConfig: isValidMacAddress,
      },
      {
        name: "status",
        flex: 1,
        disablePropagation: true,
        filter: {
          type: "CheckBox",
        },
        component: <TargetVulnerabilityStatus />,
        bulkEdit: true,
        valueOptions: Object.values(TargetVulnerabilityStatusType),
        editFunction: async ({ item, value, field }) => {
          await changeTargetLinkStatus({
            oldItem: item,
            newItem: { ...item, [field]: value },
          });
        },
      },
      getTargetVulnerabilityLinkPointOfContactField({
        getMutationID: ({ item }) => {
          // @ts-expect-error - id is present
          return item?.link?.id;
        },
        getDefaultActionItemValues: ({ row }) => {
          return {
            name: `Fix ${item?.name || "Vulnerability"} on ${row?.ip || "Target"}`,
            description: item?.solution || "",
            module: modules.VULNERABILITIES,
            priority: row?.priority || 0,
            dueDate: moment().add(1, "months").toISOString(),
            enableNotifications: true,
          };
        },
      }),
    ],
    isLoading,
    resetFunction,
    deleteFunctionWithoutQueue: true,
    persistenceUUID: "aa70222b-5277-4757-ac32-11a6692f14c6",
  };

  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "targets",
    connectionField: "target",
    typename: resources.TARGET,
    parentTypename: resources.VULNERABILITY,
    grid: <TargetsGrid />,

    gridHook: useTargetsGrid,
    gridHookGridConfig,

    form: <CreateTarget />,
    createFunction: (itemToLink, item) => {
      return createTargetFindingLink({
        target: item,
        vulnerability: itemToLink,
        status,
        organizationID,
      });
    },
    deleteFunction: deleteTargetFindingLink,
    linkedItems,

    externalItems: targets,
    setExternalItems: setTargets,
    resetExternalItems: resetFunction,
    isLoadingExternalItems: isLoading,

    //since input is (parent, child) when true
    flipCreateParams: true,
  });
  const targetIds = getObjectPropertyString({ array: targets, propertyName: ["id"] });
  //When parent targets are updated, update the nested data card
  useEffect(() => {
    nestedDataCard.setData(targets || []);
  }, [targetIds]);

  return <div style={{ height: "30em" }}>{nestedDataCard.display}</div>;
};
