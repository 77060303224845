import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const OperationPanelTemplates = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelTemplateManager/OperationPanelTemplateManager"),
);
const OperationTeamOrganizations = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelOrganizations/OperationPanelOrganizations"),
);
const OperationTeamUsers = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelUsers/components/OperationPanelUsers"),
);
const OperationTeamUserDetails = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelUsers/components/OperationPanelUserDetails"),
);

const OperationPanelDashboard = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelDashboard/OperationPanelDashboard"),
);

const OperationPanelConfiguration = React.lazy(
  () => import("@views/OperationsPanel/OperationPanelConfiguration/OperationPanelConfiguration"),
);

export const operationPanelNavigation: NavigationModule = {
  moduleName: modules.OPERATION_PANEL,
  sectionName: NavigationSectionName.MANAGEMENT,
  name: "Operation Panel",
  path: "/operation_panel",
  defaultRoutePath: "/organizations",
  icon: IconName.ADMIN,
  routes: [
    {
      path: "/organizations",
      name: "Operation Team Organizations",
      component: OperationTeamOrganizations,
      navigationBar: {
        name: "Organizations",
      },
    },
    {
      path: "/users",
      name: "Operation Team Users",
      component: OperationTeamUsers,
      navigationBar: {
        name: "Users",
      },
    },
    {
      path: "/users/:id",
      name: "Operation Team User Details",
      component: OperationTeamUserDetails,
    },
    {
      path: "/template_manager",
      name: "Operation Team Template Manager",
      component: OperationPanelTemplates,
      navigationBar: {
        name: "Templates",
      },
    },
    {
      path: "/configuration",
      name: "Operation Team Configuration",
      component: OperationPanelConfiguration,
      navigationBar: {
        name: "Configuration",
      },
    },

    {
      path: "/dashboard",
      name: "Operation Team Dashboard",
      component: OperationPanelDashboard,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
