import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useStateEffect } from "../../../../../../hooks/functional/useStateEffect";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import DispatchChangeButton from "../../../../RiskChanges/components/DispatchChangeButton";
import RiskChangeTable from "../../../components/RiskChangeTable/RiskChangeTable";
import { calculateRecommendationMonteCarlo } from "../functions/calculateRecommendationMonteCarlo";
import { useOverallROIChange } from "../hooks/useOverallROIChange";

import DisplayMonteCarloTotals from "./DisplayMonteCarloTotals";
import MonteCarloStatusIndicator from "./MonteCarloStatusIndicator";
import RunCalculationButton from "./RunCalculationButton";

/**
 * Input fields and calculation UI for running Monte Carlo analysis for a Risk Recommendation
 * @param {string} organizationID - the selected organization ID for which to perform monte carlo changes
 * @param {object} riskRecommendationData - external settings for running monte carlo analysis
 * @param {object} [item] - existing risk recommendation, if using this as an edit form
 * @param {function} onSubmitCallback - callback for getting monte carlo change configurations and results
 * @param {boolean} [disableSimulationMessage] - if present will remove the simulate option and replace with a message to the user.
 * @returns {JSX.Element}
 */
const MonteCarloChanges = ({
  organizationID,
  riskRecommendationData,
  item,
  onSubmitCallback,
  disableSimulationMessage,
  showRiskChangeButton,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOutdated, setIsOutdated] = useStateEffect(
    true,
    [riskRecommendationData?.implementationData, riskRecommendationData?.selectedRiskControl],
    () => setIsOutdated(true),
  );

  const {
    overallResidualChange,
    setOverallResidualChange,
    setOverallROIChange,
    changeInImplementation,
    changeInCost,
    selectedRiskControl,
    selectedSystem,
    systemChanges,
    setSystemChanges,
    setCalculationResults,
  } = riskRecommendationData;

  const { overallROIChange } = useOverallROIChange(overallResidualChange, changeInCost);

  useEffect(() => {
    setOverallROIChange(overallROIChange);
  }, [overallROIChange]);

  const buttonIsDisabled = isNullOrUndefined(changeInImplementation) || changeInImplementation === 0;

  const onCalculateChangeEffect = async () => {
    return await calculateRecommendationMonteCarlo({
      setIsOutdated,
      setIsLoading,
      setOverallResidualChange,
      setCalculationResults,
      system: selectedSystem,
      selectedRiskControl,
      changeInImplementation,
      changeInCost,
      organizationID,
      setSystemChanges,
    });
  };

  return (
    <div style={{ textAlign: "center", marginBottom: "1em" }}>
      <MonteCarloStatusIndicator
        isLoading={isLoading}
        isOutdated={isOutdated}
        messageOverride={disableSimulationMessage}
      />
      <br />
      {!isLoading && !disableSimulationMessage && (
        <>
          {!isOutdated && showRiskChangeButton ? (
            <DispatchChangeButton
              onClick={async () => {
                onSubmitCallback && (await onSubmitCallback());
              }}
            />
          ) : (
            <RunCalculationButton
              isLoading={isLoading}
              isOutdated={isOutdated}
              onClick={async () => await onCalculateChangeEffect()}
              isDisabled={buttonIsDisabled}
            />
          )}
        </>
      )}
      <DataLoader dataMessage={""} isEnoughData={systemChanges?.length > 0} isLoading={isLoading}>
        <div>
          <hr />
          <DisplayMonteCarloTotals
            isOutdated={isOutdated}
            overallResidualChange={overallResidualChange}
            overallROIChange={overallROIChange}
          />
          <hr />
          <RiskChangeTable systemChanges={systemChanges} />
        </div>
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(MonteCarloChanges);
