import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";

import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { FlipCard } from "../../../../utils/GenericComponents/FlipCard/components/FlipCard";
import { useFindingsBarChart } from "../../../Program/Findings/hooks/useFindingsBarChart";

import { useAuditControlStatusChart } from "./useAuditControlStatusChart";
import { useAuditEvidenceReviewedChart } from "./useAuditEvidenceReviewedChart";

/**
 * Displays Audit summary info and graphs for a user inside of audit details
 * @param {object} item - the Audit item from the useAuditDetails hook
 * @param {object} item.controls - the Audit item 'controls' field (m-m nested field)
 * @param {object[]} item.controls.items - the AuditControlLinks array
 * @param {object} item.controls.items[].control - the associated Control for an AuditControlLink
 * @param {boolean} item.controls.items[].isCompliant - isCompliant field for an AuditControlLink
 * @param {JSX.Element} tableDisplay - details table from the useAuditDetails hook
 * @param {object} auditFindings - instance of the useAuditFindings hook, instantiated at the top level of the audit details page
 * @param {object} auditFindings.observationsGrid - instance of the useObservationGrid hook
 * @param {object} auditFindings.observationsGrid.data - data from the useObservationGrid hook
 * @param {object} auditFindings.recommendationGrid - instance of the useRecommendationGrid hook
 * @param {object} auditFindings.recommendationGrid.data - data from the useRecommendationGrid hook
 * @param {object} auditFindings.actionItemsGrid - instance of the useActionItemGrid hook
 * @param {object} auditFindings.actionItemsGrid.data - data from the useActionItemGrid hook
 * @returns {{display: JSX.Element}}
 */
export const useAuditGeneralInformation = ({ item, tableDisplay, auditFindings }) => {
  // Control Status pie chart, renders based on data state in the auditDetails.item object
  const controlStatusChart = useAuditControlStatusChart({
    item,
  });

  const evidenceStatusChart = useAuditEvidenceReviewedChart({
    item,
  });

  const findingsBarChart = useFindingsBarChart({});

  /**
   * Updates the findings bar chart with the latest data from the useAuditFindings hook
   */
  useEffect(() => {
    if (auditFindings) {
      findingsBarChart.setFindingsData({
        observations: auditFindings?.observationsGrid.data || [],
        recommendations: auditFindings?.recommendationGrid.data || [],
        actionItems: auditFindings?.actionItemsGrid.data || [],
      });
    }
  }, [auditFindings?.observationsGrid?.data, auditFindings?.recommendationGrid?.data]);

  const display = (
    <div>
      <Grid container spacing={2}>
        <Grid id={"audit-general-details"} item lg={4} xs={12}>
          <DashboardCard
            id={"audit-details-general-information"}
            title={"Details"}
            icon={"icon-info"}
            style={{ height: "100%" }}
          >
            {tableDisplay}
          </DashboardCard>
        </Grid>
        <Grid id={"audit-pie-chart"} item lg={4} xs={12}>
          <FlipCard
            icon={<i className={"icon-pie-chart"} />}
            title={"Control Status"}
            flipTitle={"Evidence Reviewed"}
            flipContent={evidenceStatusChart.display}
            content={controlStatusChart.display}
            style={{ height: "40vh" }}
          />
        </Grid>
        <Grid id={"audit-bar-chart"} item lg={4} xs={12}>
          <FlipCard
            icon={<i className={"icon-chart"} />}
            title={"Observations"}
            flipTitle={"Recommendations"}
            flipContent={findingsBarChart.display}
            content={findingsBarChart.display}
            style={{ height: "40vh" }}
            onFlip={() =>
              findingsBarChart.setView((view) => (view === "observations" ? "recommendations" : "observations"))
            }
          />
        </Grid>
      </Grid>
    </div>
  );

  return {
    display,
  };
};
