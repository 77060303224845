import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { fieldContexts } from "../../../../enums/fieldContexts";
import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import { OBSERVATION } from "../constants/OBSERVATION";

import InsuranceValidated from "./InsuranceValidated";

/**
 * Displays the Decision field for an Observation.
 * @param {object} item - The Observation to display the Decision field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {string} fieldContext - The context of the field, used to determine if a tooltip should be displayed
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const Decision = ({ item, updateItemById, fieldContext, ...props }) => {
  const module = OBSERVATION.module;
  const resource = OBSERVATION.resource;
  const field = OBSERVATION.fields.decision;

  const options = OBSERVATION?.fieldConfig?.decision?.valueOptions?.map((option) => {
    return {
      text: convertCamelCaseToSentence(option),
      value: option,
    };
  });

  return (
    <span style={{ width: "100%" }}>
      <GenericFieldDropdown
        item={item}
        module={module}
        resource={resource}
        field={field}
        mutation={generateGraphql(OBSERVATION.typename, [field]).updateMutation}
        options={options}
        format="sentence"
        updateItemById={updateItemById}
        tooltip={fieldContext !== fieldContexts.GRID ? OBSERVATION?.fieldConfig?.decision?.description : undefined}
        {...props}
      />
      {item?.decision === "transfer" && (
        <div>
          <hr />
          <i title={OBSERVATION?.fieldConfig?.insurancePolicyValidated?.description}>Insurance Policy Validated: </i>
          {"  "}
          <InsuranceValidated item={item} {...props} />
        </div>
      )}
    </span>
  );
};

export default Decision;
