"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetMetricTypes = void 0;
/**
 * Retrieves all metric types for a single widget
 * @param {object} config - widget settings (contains id references to metricTypes)
 * @return {object[]} - all metric types for the widget
 */
const getWidgetMetricTypes = ({ config } = {}) => {
    //Check arguments
    const series = config?.series;
    if (!Array.isArray(series)) {
        return [];
    }
    //Get all metric types
    const metricTypeIds = [];
    for (const seriesItem of series) {
        const metricTypeId = seriesItem?.metricTypeId;
        if (metricTypeId) {
            metricTypeIds.push(metricTypeId);
        }
    }
    return metricTypeIds;
};
exports.getWidgetMetricTypes = getWidgetMetricTypes;
