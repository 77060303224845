import { checkArguments } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

export const onChangeDefaultRoute = ({ value, preferences, setDefaultRoute, addToast }) => {
  checkArguments({ value, preferences, addToast });

  try {
    preferences.setPreference("interfaceOptions", { defaultRoute: value });
    setDefaultRoute?.(value);
    addToast({
      header: "Default route was changed successfully",
      icon: "success",
    });
  } catch (e) {
    ErrorLogger(e);
    addToast({
      header: `Default route could not be changed`,
      icon: "danger",
    });
  }
};
