"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLogic = void 0;
/**
 * Creates JSON logic object
 *
 * @param operator
 * @param var1
 * @param var2
 */
const createLogic = (operator, var1, var2) => {
    return {
        [operator]: [var1, var2],
    };
};
exports.createLogic = createLogic;
