import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useExerciseDataGrid } from "../../../../views/Response/Exercises/hooks/useExerciseDataGrid";

/**
 * @description Wrapper component for useExerciseDataGrid hook
 * @param {object} props
 * @returns {JSX.Element}
 */
const ExerciseGrid = (props) => {
  return useExerciseDataGrid(props).dashboardDisplay;
};

export default withOrganizationCheck(ExerciseGrid);
