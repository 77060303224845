import React from "react";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { mergeAdditionalFields } from "../../../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { useGridCard } from "../../../../../../../hooks/views/useGridCard";
import SubControlNotes from "../../../../../Systems/components/SystemAccordion/ControlCategories/components/Controls/components/SubControlNotes";
import RiskControlDetails from "../components/RiskControlDetails";
import ImplementationRating from "../customFields/ImplementationRating";
import KeyControl from "../customFields/KeyControl";
import { deleteRiskControl } from "../functions/deleteRiskControl";

/**
 * Displays enterprise risk controls for the org
 * @param {string} controlCategoryId
 * @param {string} organizationID - ownerGroup ID of the Organization, Template, or OperationTeam
 * @param {object} queryConfig - configuration overrides for the grid query
 * @param {object} gridConfig - configuration overrides for the grid UI
 * @param {object} cardConfig - configuration overrides for the card UI
 * @param {string} [controlCategoryID] - optionally filter retrieved fields by the control category
 * @param additionalFields
 */
export const useRiskControlGrid = ({
  organizationID,
  additionalFields = [],
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  controlCategoryID,
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const typename = "RiskControl";
  const route = "#/risk/risk_config/risk_controls/";

  const fields = [
    {
      name: "statementNumber",
      width: 100,
    },
    {
      name: "name",
      width: 200,
    },
    {
      name: "controlCategory",
      visible: false,
    },
    {
      name: "implementationRating",
      component: <ImplementationRating disableTooltip={true} />,
      width: 100,
    },
    {
      name: "keyControl",
      component: <KeyControl />,
      width: 100,
      disablePropagation: true,
    },
    {
      name: "notes",
      component: <SubControlNotes disableTitle={true} />,
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  const sortSettings = {
    columns: [{ field: "statementNumber", direction: "Ascending" }],
  };

  cardConfig = {
    title: "Risk Controls",
    ...cardConfig,
  };

  const normalizeDataExternal = queryConfig?.normalizeData;
  delete queryConfig?.normalizeData;
  queryConfig = {
    query: realTimeRiskGQL.listRiskControls_riskControlList,
    organizationID: organizationID,
    normalizeData: (items) => {
      let newItems = items;
      if (newItems && normalizeDataExternal) {
        newItems = normalizeDataExternal(newItems);
      }

      if (controlCategoryID && Array.isArray(newItems)) {
        return newItems.filter((item) => item?.controlCategory?.id === controlCategoryID);
      } else {
        return newItems;
      }
    },
    ...queryConfig,
  };

  gridConfig = {
    route,
    typename,
    module,
    resource,
    fields,
    enablePersistence: false, // persistence is disabled
    sortSettings,
    detailsComponent: <RiskControlDetails />,
    enableSearch: true,
    enableContextMenu: true,
    deleteFunction: deleteRiskControl,
    ...gridConfig,
  };

  queryConfig = {
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const queryCard = useGridCard({
    queryConfig,
    gridConfig,
    roleConfig,
    cardConfig,
  });

  return { ...queryCard };
};
