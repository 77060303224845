/**
 * Utility function to check if a toast needs to be dismissed automatically based on its info (no user interaction)
 * @param {object} item - the toast information object
 * @return {boolean} TRUE if need to auto dismiss the toast and FALSE if not
 */
export const needToAutoDismissToast = (item) => {
  //No need to auto dismiss if already dismissed
  if (!item.isOnScreen) {
    return false;
  }

  //If toast is a loader keep it on screen
  if (item.icon === "spinner") {
    return false;
  }

  //Check if toast is permanent
  let noTimeout = false;
  if (typeof item.isPermanent === "boolean") {
    noTimeout = item.isPermanent;
  }
  if (noTimeout) {
    return false;
  }

  //Check if toast reached auto dismiss point
  if (item.endTime && item.endTime - Date.now() <= 0) {
    return true;
  } else {
    return false;
  }
};
