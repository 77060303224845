import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { isInSelectTemplatesGrid } from "../../../../utils/Functions/Views/grid/isInSelectTemplatesGrid";
import GenericEditField from "../../../../utils/GenericComponents/GenericEditFieldV2";

/**
 * Custom field for Meeting Time
 *
 * @param {object} item - the meeting object with data
 * @param {function} updateItemById - callback to update the data locally in the grid after a successful mutation
 * @param {function} resetFunction - callback to re-fetch data after an edit has been made
 * @param {string} timeFieldName - the field name for the meeting time to show
 * @param {string} module - app module for front end role checking
 * @param {string} resource - app resource for front end role checking
 * @param {boolean} disableRoleChecking - if TRUE will not do front end role checking
 * @param {string} gridPersistenceUUID - present if the field is in a grid
 * @param {object} props - other props to pass through to GenericEditField
 * @returns {JSX.Element}
 */
export const MeetingTime = ({
  item,
  updateItemById,
  resetFunction,
  timeFieldName,
  module = modules.GOVERNANCE,
  resource = resources.MEETING,
  disableRoleChecking = false,
  gridPersistenceUUID,
  ...props
}) => {
  //Needed so that the grid doesn't override the datepicker value upon submit in the grid
  const getMinimalItem = (item) => {
    const currentItem = { id: item?.id };
    currentItem[timeFieldName] = item.hasOwnProperty(timeFieldName) && item[timeFieldName];
    return currentItem;
  };

  const [minimalItem, setMinimalItem] = useState(getMinimalItem(item));

  useEffect(() => {
    const currentItem = getMinimalItem(item);
    if (!isEqual(currentItem, minimalItem)) {
      setMinimalItem(currentItem);
    }
  }, [item]);

  return (
    <GenericEditField
      inputType={"date"}
      item={minimalItem}
      field={timeFieldName}
      mutation={
        !isInSelectTemplatesGrid({ gridPersistenceUUID }) && generateGraphql("Meeting", [timeFieldName]).updateMutation
      }
      resource={resource}
      module={module}
      updateItemById={updateItemById}
      resetFunction={resetFunction}
      disableRoleChecking={disableRoleChecking}
      {...props}
    />
  );
};
