import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { NavLink } from "reactstrap";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import { DisabledWrapper } from "../../../../utils/GenericComponents/DisabledWrapper";

import { DataGridNextAndPreviousButtons } from "./DataGridNextAndPreviousButtons";

/**
 * @description Header for the Details Modal
 * @param {object} detailsItem - item for details component
 * @param {function} setDetailsItem
 * @param {string|function} route - route of the item type
 * @param {function} [getRouteDisabled] - determines if a route nav link should be disabled, returns a string to display a tooltip explanation and null if route is enabled
 * @param {object} config - configuration for grid details
 * @param {string} typename - the name of an item type
 * @param {string} [detailsTitle] - optional, overrides the details modal title
 * @param {object[]} data - the raw data from the grid, used to determine when to update the arrow buttons
 * @param {function} getVisibleRows - gets the grid data in visible sorted order for the arrow buttons
 * @param {object} apiRef - grid API ref, for the arrow buttons
 * @param {object} item - the currently selected row item
 * @return {JSX.Element}
 * @constructor
 */
export const DataGridDetailsModalHeader = ({
  detailsItem,
  setDetailsItem,
  route,
  getRouteDisabled,
  config = {},
  typename,
  detailsTitle,
  data,
  getVisibleRows,
  apiRef,
  item,
}) => {
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const [previousItem, setPreviousItem] = useState(null);
  const [nextItem, setNextItem] = useState(null);

  useEffect(() => {
    if (!isNullOrUndefined(item)) {
      setDetailsItem(item);
    }
  }, [item]);

  // Sets the Previous and Next buttons for the details modal when the selected item changes
  useEffect(() => {
    if (!isNullOrUndefined(detailsItem)) {
      const visibleRows = getVisibleRows();

      if (visibleRows.length === 0) {
        return;
      }

      const foundIndex = visibleRows.findIndex((row) => row.id === detailsItem?.id);

      if (!isNullOrUndefined(visibleRows[foundIndex - 1])) {
        setPreviousItem(visibleRows[foundIndex - 1]);
        setHasPrevious(true);
      } else {
        setPreviousItem(null);
        setHasPrevious(false);
      }

      if (!isNullOrUndefined(visibleRows[foundIndex + 1])) {
        setNextItem(visibleRows[foundIndex + 1]);
        setHasNext(true);
      } else {
        setHasNext(false);
      }
    }
  }, [data, detailsItem]);

  /**
   * Adjusts the selected item index by a certain amount,
   * and sets the currently saved 'detailsItem' state for the details Modal functionality
   * @param diff
   */
  const adjustSelectedItemIndex = (diff = 1) => {
    const visibleRows = getVisibleRows();

    if (visibleRows.length === 0) {
      return;
    }

    const foundIndex = visibleRows.findIndex((row) => row.id === detailsItem?.id);

    if (!isNullOrUndefined(visibleRows[foundIndex + diff])) {
      apiRef.current.selectRow(visibleRows[foundIndex + diff].id, true);
      apiRef.current.selectRow(visibleRows[foundIndex].id, false);
      setDetailsItem(visibleRows[foundIndex + diff]?.model);
    }
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <DataGridNextAndPreviousButtons
            previousItem={previousItem}
            nextItem={nextItem}
            hasPrevious={hasPrevious}
            hasNext={hasNext}
            adjustSelectedItemIndex={adjustSelectedItemIndex}
          />
        </Grid>
        <Grid item>
          <span>
            {detailsTitle || (typename ? `${convertCamelCaseToSentence(typename)} ` : "")}
            {!detailsTitle && "Details"}
          </span>
        </Grid>
        <Grid item>
          {detailsItem && typeof route === "string" && !config.disableDetails && (
            <DisabledWrapper
              isDisabled={typeof getRouteDisabled === "function" && getRouteDisabled?.(detailsItem)}
              message={(typeof getRouteDisabled === "function" && getRouteDisabled(detailsItem)) || "Details Disabled"}
            >
              <NavLink style={{ display: "inline-block" }} href={route} className="icon-share-alt" title={route} />
            </DisabledWrapper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
