import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../../../hooks/views/useForm/useForm";

/**
 * Custom hook for creating a program element (Mainly used through the component Instance CreateProgramElement.js)
 * @param {string} organizationID
 * @param {function} toggleModal
 * @param {function} resetFunction
 * @param {object} maturityMatrix
 * @param {object} props - all other props passed to useFormhook
 * @returns {{input: {}, display: *}}
 */
const useCreateProgramElement = ({ organizationID, toggleModal, resetFunction, maturityMatrix, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.PROGRAM_ELEMENT;

  const { createMutation } = generateGraphql("ProgramElement", ["name", "description", "maturityMatrixID"]);

  const formHook = useForm({
    mutation: createMutation,
    organizationID,
    toggleModal,
    module,
    resource,
    resetFunction,
    fieldConfig: {
      name: {
        label: "Name",
        inputType: "text",
        required: true,
      },
      description: {
        label: "Description",
        inputType: "text",
      },
      maturityMatrixID: {
        defaultValue: maturityMatrix?.id,
        isHidden: true,
        inputType: "text",
      },
    },
    ...props,
  });

  return { ...formHook };
};

export default useCreateProgramElement;
