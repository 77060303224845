import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useMutation } from "../../hooks/graphql/useMutation/useMutation";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { WarningLogger } from "../EventLogger";
import { emptyPlaceholder, invalidPlaceholder } from "../GenericComponents/Placeholders";

/**
 * @description component that renders buttons as disabled/enabled
 * @param {object} config - the config object for the component
 * @param {object} item - the item to render the buttons for
 * @returns {JSX.Element} - JSX element
 * @constructor
 */
const EnabledDisabledButtonGroupField = ({ config = {}, item, ...props }) => {
  /// [GENERAL SETUP]
  // - Props
  const {
    module,
    resource,
    typename = "",
    field,
    updateItemById = props?.updateItemById,
    updateInputFunction,
    disableRoleChecking = false,
    disabled = false,
    defaultValue = true,
    showOnlyButtons = false, // if true will show the button group even if they are disabled
    rightOffText = "Disabled",
    leftOnText = "Enabled",
    colorScheme = "success", // can do success (green/red) or primary (only blue)
  } = config;

  // - State
  const [isTrueSelected, setIsTrueSelected] = useState(defaultValue);

  useEffect(() => {
    if (item && item.hasOwnProperty(field) && item[field] !== null) {
      //undefined value results in false value
      setIsTrueSelected(item[field] === undefined ? false : item[field]);
    } else {
      //if no valid item object is provided the default value is used
      setIsTrueSelected(defaultValue);
    }
  }, [item]);

  // - Queries
  const { updateMutation: updateControl } = generateGraphql(typename, [field]);
  const updateControlMutationHook = useMutation({
    mutation: updateControl,
    module,
    resource,
    field,
    disableRoleChecking,
  });

  // - Permissions
  const controlPermissions = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const isUpdateEnabled = !disabled && controlPermissions.field.update;
  const isViewEnabled = !disabled && controlPermissions.field.read;

  /// [UTILITY FUNCTIONS]
  // Returns TRUE if buttons can be displayed, FALSE if invalid props
  const checkRoleConfig = () => {
    if (disableRoleChecking == false && (!module || !resource)) {
      return false; // no way to retrieve permissions
    }
    return true;
  };

  // Uses query from setup to update values when changed
  const onChange = async (newValue) => {
    // check that the value has changed
    if (isTrueSelected === newValue) return;
    setIsTrueSelected(newValue);

    // if a submitFunction is passed, bypass
    if (typeof props.submitFunction === "function") {
      await props.submitFunction({ value: newValue, field });
      return;
    }

    if (updateControl && field && item?.id && isUpdateEnabled && updateControlMutationHook) {
      const input = {
        id: item.id,
      };

      input[field] = newValue;
      if (updateInputFunction && typeof updateInputFunction === "function") {
        input[field] = await updateInputFunction(newValue);
      }

      const finalValue = await updateControlMutationHook.editItem(input);
      updateItemById?.(finalValue);
    } else {
      WarningLogger("Failed to update control when button group value has changed!");
    }
  };

  /// [COMPONENT RENDER]
  const getButtonGroup = () => {
    if (!checkRoleConfig()) {
      //Cannot show due to invalid permission setup
      return invalidPlaceholder;
    } else if (isUpdateEnabled || showOnlyButtons) {
      //Can show and update values or force displaying buttons
      return (
        <ButtonGroup size="sm">
          <Button
            className={colorScheme === "success" ? "btn-ghost-success" : "btn-ghost-primary"}
            onClick={() => {
              onChange(true);
            }}
            active={isTrueSelected === "true" || isTrueSelected === true}
            size="sm"
            disabled={!isUpdateEnabled}
            title={
              !isUpdateEnabled
                ? `Cannot edit resource field. Check permissions under ${module} -> ${resource} -> ${field}`
                : `Change field '${field}' to  '${leftOnText}'`
            }
          >
            {leftOnText}
          </Button>
          <Button
            className={colorScheme === "success" ? "btn-ghost-danger" : "btn-ghost-primary"}
            onClick={() => {
              onChange(false);
            }}
            active={isTrueSelected === "false" || isTrueSelected === false}
            size="sm"
            disabled={!isUpdateEnabled}
            title={
              !isUpdateEnabled
                ? `Cannot edit resource field. Check permissions under ${module} -> ${resource} -> ${field}`
                : `Change field '${field}' to  '${rightOffText}'`
            }
          >
            {rightOffText}
          </Button>
        </ButtonGroup>
      );
    } else if (isViewEnabled) {
      //Cannot update but can show the values
      return isTrueSelected === "true" || isTrueSelected === true ? (
        <span
          data-testid={"read_only_enabled_state_boolean_field"}
          style={{
            color: colorScheme === "success" ? "green" : "dodgerBlue",
            margin: "0 auto",
          }}
          title={`The field '${field}' has value of '${leftOnText}'`}
        >
          <i className="icon-check" style={{ marginRight: "0.3em" }} />
          {leftOnText}
        </span>
      ) : (
        <span
          style={{
            color: colorScheme === "success" ? "red" : "dodgerBlue",
            margin: "0 auto",
          }}
          title={`The field '${field}' has value of '${rightOffText}'`}
        >
          <i className="icon-close" style={{ marginRight: "0.3em" }} />
          {rightOffText}
        </span>
      );
    } else {
      return emptyPlaceholder;
    }
  };

  return <>{getButtonGroup()}</>;
};

export default EnabledDisabledButtonGroupField;
