import React from "react";
import { withRouter } from "react-router-dom";

import { modules, resources } from "@rivial-security/role-utils";

import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useAssetSizeChangeDetails } from "../hooks/useAssetSizeChangeDetails";

const AssetSizeChangeDetailsBody = ({ itemId, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const assetSizeChangeDetails = useAssetSizeChangeDetails({
    itemId,
    module,
    resource,
    tableDisplay,
  });

  return <>{assetSizeChangeDetails.display}</>;
};

const AssetSizeChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  return (
    <OrganizationCheck {...props}>
      <AssetSizeChangeDetailsBody itemId={itemId} />
    </OrganizationCheck>
  );
};

export default withRouter(AssetSizeChangeDetails);
