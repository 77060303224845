import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useJobDetails } from "../hooks/useJobDetails";

/**
 * Displays a details page for a particular Job
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const JobDetails = (props) => useJobDetails(props).display;

export default withDetailsPage(JobDetails, { title: "Job Details" });
