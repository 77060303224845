import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label } from "reactstrap";

import { passwordLength } from "../../../env-config";

/**
 * @description Allow user to enter a new password
 * @param {boolean} enableShowPassword - if true, will show the password in plain text
 * @param {boolean} enableResetButton - if true, will show a reset button
 * @returns {object} {{setReEnterPassword: (value: (((prevState: string) => string) | string)) => void, reEnterPassword: string, isValidPassword: boolean, setNewPassword: (value: (((prevState: string) => string) | string)) => void, InitialState: InitialState, display: JSX.Element, setShowPassword: (value: (((prevState: boolean) => boolean) | boolean)) => void, newPassword: string, showPassword: boolean, isPasswordMatched: boolean}}
 */
export const useNewPassword = ({ enableShowPassword = true, enableResetButton = true } = {}) => {
  const [newPassword, setNewPassword] = useState("");
  const [isUppercaseLetter, setIsUppercaseLetter] = useState(false);
  const [isLowercaseLetter, setIsLowercaseLetter] = useState(false);
  const [isOneNumber, setIsOneNumber] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isMinimumLength, setIsMinimumLength] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const isValidPassword =
    isUppercaseLetter && isLowercaseLetter && isOneNumber && isSpecialCharacter && isMinimumLength && isPasswordMatched;

  /**
   * Check if the password is valid
   */
  useEffect(() => {
    const isUppercaseLetterReg = new RegExp("(.*[A-Z].*)");
    const isUppercaseLetterResults = isUppercaseLetterReg.test(newPassword);
    setIsUppercaseLetter(isUppercaseLetterResults);

    const isLowercaseLettersReg = new RegExp("(.*[a-z].*)");
    const isLowercaseLettersResults = isLowercaseLettersReg.test(newPassword);
    setIsLowercaseLetter(isLowercaseLettersResults);

    const isOneNumberReg = new RegExp("(.*\\d.*)");
    const isOneNumberResults = isOneNumberReg.test(newPassword);
    setIsOneNumber(isOneNumberResults);

    const acceptableSpecialCharacters = [
      `^`,
      `$`,
      `*`,
      `.`,
      `[`,
      `]`,
      `{`,
      `}`,
      `(`,
      `)`,
      `?`,
      `-`,
      `!`,
      `@`,
      `#`,
      `%`,
      `&`,
      `>`,
      `<`,
      `'`,
      `:`,
      `;`,
      `|`,
      `_`,
      `~`,
      `+`,
      `=`,
    ];
    let isSpecialCharacter = false;
    for (const char of acceptableSpecialCharacters) {
      if (typeof newPassword === "string" && newPassword.includes(char)) {
        isSpecialCharacter = true;
        break;
      }
    }
    setIsSpecialCharacter(isSpecialCharacter);

    const isMinimumLengthReg = newPassword?.length >= passwordLength;
    setIsMinimumLength(isMinimumLengthReg);
  }, [newPassword]);

  useEffect(() => {
    if (newPassword && reEnterPassword && newPassword === reEnterPassword) {
      setIsPasswordMatched(true);
    } else {
      setIsPasswordMatched(false);
    }
  }, [newPassword, reEnterPassword]);

  const InitialState = () => {
    setNewPassword("");
    setIsUppercaseLetter(false);
    setIsLowercaseLetter(false);
    setIsOneNumber(false);
    setIsSpecialCharacter(false);
    setIsMinimumLength(false);
    setIsPasswordMatched(false);
    setReEnterPassword("");
  };

  const display = (
    <>
      <Col md={12}>
        <Label htmlFor="password">New Password</Label>
        <span> </span>
        <Input
          className="appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="newPassword"
          key="newPassword"
          name="newPassword"
          type={showPassword === true ? "text" : "password"}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
      </Col>
      <Col sm={12}>
        {newPassword ? (
          <>
            {isUppercaseLetter ? (
              <div style={{ color: "green" }}>&#10004; At least one capital letter</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; At least one capital letter</div>
            )}
            {isLowercaseLetter ? (
              <div style={{ color: "green" }}>&#10004; At least one letter</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; At least one letter</div>
            )}
            {isOneNumber ? (
              <div style={{ color: "green" }}>&#10004; At least one number</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; At least one number</div>
            )}
            {isSpecialCharacter ? (
              <div style={{ color: "green" }}>&#10004; At least one special character</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; At least one special character</div>
            )}
            {isMinimumLength ? (
              <div style={{ color: "green" }}>&#10004; Be at least {passwordLength} characters</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; Be at least {passwordLength} characters</div>
            )}
            <br />
          </>
        ) : null}
      </Col>
      <Col md={12}>
        <Label htmlFor="reEnterPassword">Re-Enter Password</Label>
        <span> </span>
        <Input
          className="appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="reEnterPassword"
          key="reEnterPassword"
          name="reEnterPassword"
          type={showPassword === true ? "text" : "password"}
          value={reEnterPassword}
          onChange={(event) => setReEnterPassword(event.target.value)}
        />
      </Col>
      <Col sm={12}>
        {reEnterPassword ? (
          <>
            {isPasswordMatched ? (
              <div style={{ color: "green" }}>&#10004; Passwords do match</div>
            ) : (
              <div style={{ color: "red" }}>&#10005; Passwords do not match</div>
            )}
            <br />
          </>
        ) : null}
        {enableShowPassword && (
          <FormControlLabel
            control={<Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} />}
            label="Show Password"
          />
        )}
        {enableResetButton && (
          <Button style={{ height: "2em" }} size={"sm"} onClick={() => InitialState()}>
            Reset
          </Button>
        )}
      </Col>
    </>
  );

  return {
    display,
    newPassword,
    setNewPassword,
    reEnterPassword,
    showPassword,
    setShowPassword,
    setReEnterPassword,
    isPasswordMatched,
    isValidPassword,
    InitialState,
  };
};
