import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useNestedDataCard } from "../../../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import StyledWrapper from "../../../../../../../utils/GenericComponents/StyledWrapper";
import CreateChange from "../../../../../RiskChanges/components/CreateChange";
import GroupedRiskChangeDataGrid from "../../../../../RiskChanges/components/GroupedRiskChangeDataGrid";
import CreateRiskControlChange from "../../../../../RiskChanges/components/RiskControlChange/components/CreateRiskControlChange";
import { useGroupedRiskChangeDataGrid } from "../../../../../RiskChanges/hooks/useGroupedRiskChangeDataGrid/useGroupedRiskChangeDataGrid";
import { createRiskControlChangeLink } from "../functions/createRiskControlChangeLink";
import { deleteRiskControlChangeLink } from "../functions/deleteRiskControlChangeLink";

/**
 * Displays all RiskChanges linked to a RiskControl
 * @param {object} item - the risk control to show changes for
 * @param {string} organizationID - current organization ID
 * @returns {*}
 * @constructor
 */
const RiskControlChanges = ({ item, organizationID }) => {
  const { getQuery } = generateGraphql("RiskControl", ["riskChangeLinks"], {
    riskChangeLinks: `(limit: 1000) {
      items {
        id
        costChange
        implementationChange
        riskChange {
          id
          name
          description
          reason
          changeOwner
          type
          date
          change
          groupID
          systemLinks (limit: 1000) {
            items {
              id
              ownerGroup
              system {
                id
                name
                ownerGroup
              }
            }
          }
        }
        riskControl {
          id
          name
          statementNumber
          costOfControl
          strengthRating
          implementationRating
        }
        riskControlID
        riskChangeID
      }
    }`,
  });

  const dataCard = useNestedDataCard({
    item,
    organizationID,
    query: getQuery,
    field: "riskChangeLinks",
    connectionField: "riskChange",
    typename: "RiskChange",
    parentTypename: "RiskControl",
    grid: (
      <StyledWrapper wrapperStyle={{ height: "80vh" }}>
        <GroupedRiskChangeDataGrid dashboardDisplay={false} />
      </StyledWrapper>
    ),
    gridHook: useGroupedRiskChangeDataGrid,
    form: <CreateRiskControlChange riskControl={item} />,
    createResourceComponent: <CreateChange riskControl={item} />,
    createFunction: createRiskControlChangeLink,
    deleteFunction: deleteRiskControlChangeLink,
    flipCreateParams: true,
  });

  return <div style={{ height: "60vh" }}>{dataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(RiskControlChanges);
