"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRiskControlNonStandard = void 0;
const findIndexOfRiskControlOverride_1 = require("./findIndexOfRiskControlOverride");
const isRiskControlOverrideFieldEnabled_1 = require("./isRiskControlOverrideFieldEnabled");
const isRiskControlNonStandard = (riskControl, system) => {
    const riskControlOverrides = system?.riskControlOverrides;
    const indexOfRiskControlOverride = (0, findIndexOfRiskControlOverride_1.findIndexOfRiskControlOverride)(riskControlOverrides, riskControl);
    const isImplementationOverrideEnabled = indexOfRiskControlOverride !== -1 &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled)(system.riskControlOverrides[indexOfRiskControlOverride], "implementationRating");
    const getControlImplementationRating = () => {
        if (isImplementationOverrideEnabled === false) {
            return false;
        }
        else {
            return true;
        }
    };
    return getControlImplementationRating();
};
exports.isRiskControlNonStandard = isRiskControlNonStandard;
