/**
 * Checks if two lists of objects are similar based on the values of one field
 * Can use this when there are circular references and JSON.stringify doesn't work
 * @param {string} fieldName - by default uses the 'name' property, by
 * @param {object[]} listOne - the first list
 * @param {object[]} listTwo - the second list
 * @param {object[]} disregardList - any item in this list will not be used for finding similarity (thrown out)
 */
export const objectListSimilarByField = ({ listOne = [], listTwo = [], disregardList = [], fieldName = "name" }) => {
  const originalList = getListFromObjectProperties({
    list: listOne,
    disregardList,
    fieldName,
  });
  const newList = getListFromObjectProperties({
    list: listTwo,
    disregardList,
    fieldName,
  });

  return JSON.stringify(originalList) === JSON.stringify(newList);
};

const getListFromObjectProperties = ({ list, disregardList, fieldName }) => {
  const processedList = [];
  if (list && Array.isArray(list)) {
    for (const field of list) {
      if (
        field &&
        field.hasOwnProperty(fieldName) &&
        field[fieldName] &&
        disregardList.findIndex((value) => value === field[fieldName]) === -1
      ) {
        processedList.push(field[fieldName]);
      }
    }
  }

  return processedList;
};
