import Grid from "@mui/material/Grid";
import React from "react";

import SelectAutomationTypeButton from "./SelectAutomationTypeButton";

/**
 * Displays a grid of Buttons for selecting an Automation Type
 * @param {AutomationType[]} automationTypes - the types of automations to display
 * @param {function} onSelect - function to call when an automation type is selected
 * @param {function} toggleModal - function to call to close the modal
 * @param {string} organizationID - the organization ID
 * @param {object[]} automations - the current automation steps
 * @returns {JSX.Element}
 * @constructor
 */
const SelectAutomationTypeButtons = ({ automationTypes, onSelect, toggleModal, organizationID, automations }) => {
  return (
    <Grid
      container
      spacing={3}
      alignItems={"center"}
      justifyContent="center"
      style={{
        width: "30vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {automationTypes.map((type, index) => (
        <Grid item key={`type${index}`}>
          <SelectAutomationTypeButton
            type={type}
            onSelect={onSelect}
            toggleModal={toggleModal}
            organizationID={organizationID}
            automations={automations}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SelectAutomationTypeButtons;
