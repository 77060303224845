import { checkArguments } from "@rivial-security/func-utils";

import { createLink } from "./createLink";

/**
 * Performs the operation for linking items to another item
 *
 * @param {object} item - the item that is having resources linked to it
 * @param {object[]} items - the resources to link to the parent item
 * @param {string} organizationID - organization for the mutation
 * @param {string} field - the item field that holds the connection data
 * @param {string} nestedField - the item field on the many-to-many connection object that holds the child connection object
 * @param {string} typename - the typename of the many-to-many connection model
 * @param {string} parentConnectionIDField - the ID corresponding to the field representing the parent connection item
 * @param {string} childConnectionIDField - the ID corresponding to the field representing the child connection item
 * @param {function} [updateLinkFunction] - a function that can be called right before the mutation, takes the mutation input and returns the mutation input with updates
 * @param {function} [normalizeLinkedItems] - a function to edit the currently linked items before they are displayed
 */
export const handleLinking = async (
  item,
  items,
  organizationID,
  field,
  nestedField,
  typename,
  parentConnectionIDField,
  childConnectionIDField,
  updateLinkFunction,
  normalizeLinkedItems,
  onLinkCallback,
) => {
  checkArguments({
    item,
    items,
    organizationID,
  });

  let existingLinkedResources = item[field].items.map((link) => {
    return { ...link[nestedField], link };
  });
  if (typeof normalizeLinkedItems === "function") {
    existingLinkedResources = normalizeLinkedItems(existingLinkedResources);
  }

  for (const linkItem of items) {
    let alreadyLinked = false;

    if (existingLinkedResources) {
      // loop through parent items current resource links
      for (const resource of existingLinkedResources) {
        // if resource is not already linked
        if (resource?.id === linkItem?.id) {
          alreadyLinked = true;
        }
      }
    }

    // if resource is not already linked then create the link resource
    if (!alreadyLinked) {
      const newLink = await createLink(
        item.id,
        linkItem.id,
        organizationID,
        typename,
        parentConnectionIDField,
        childConnectionIDField,
        updateLinkFunction,
      );

      if (typeof onLinkCallback === "function") {
        onLinkCallback({
          id: newLink.id,
          [nestedField]: linkItem,
          [field]: item,
        });
      }
    }
  }
};
