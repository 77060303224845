import React from "react";

import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";

/**
 * Displays the 'riskRating' field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.riskRating
 * @returns {JSX.Element}
 * @constructor
 */
const RiskRating = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={"The Risk Rating is calculated based on where the Residual Risk falls on the Risk Tolerance Curve"}
    >
      <span>{!isNullOrUndefined(item?.riskRating) ? convertCamelCaseToSentence(item.riskRating) : "N/A"}</span>
    </UnderlinedTooltip>
  );
};

export default RiskRating;
