import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCustomResourceEntryForm } from "../hooks/useCustomResourceEntryForm";

/**
 * Component for creating a Custom Resource Entry
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CustomResourceEntryForm = (props) => useCustomResourceEntryForm(props).display;

export default withOrganizationCheck(CustomResourceEntryForm);
