import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

/**
 * Checkbox button used to display or hide the 'Overrides' input form for a field
 * @param {boolean} showOverrideInput - whether or not to show the overrides input
 * @param {function} setShowOverrideInput - function to set the showOverrideInput state
 * @param {object} input - the form input object
 * @param {string} fieldName - name of the current form field
 * @param supportedOverrideTypes
 * @param {string}  inputType - type of the current form field
 * @param {boolean} required - whether or not the current form field is required
 * @param {function} validationFunction - function to validate the current form field
 * @returns {JSX.Element}
 * @constructor
 */
const OverridesButton = ({
  showOverrideInput,
  setShowOverrideInput,
  input,
  fieldName,
  supportedOverrideTypes,
  inputType,
  required = false,
  validationFunction,
}) => {
  //TODO: find a better way to check which fields have been overwritten
  const isOverrideEnabledInitially = false;
  useEffect(() => {
    if (input?.[fieldName]) {
      setShowOverrideInput(isOverrideEnabledInitially);
    }
  }, []);

  // Always show override for required fields
  // Always show override for validated fields
  // Else only show override for field types that support overrides
  const isOverrideButtonEnabled = validationFunction || required || supportedOverrideTypes?.includes(inputType);

  return (
    <FormControlLabel
      style={{
        display: isOverrideButtonEnabled ? undefined : "none",
      }}
      label="Override"
      disableTypography
      className={"float-right"}
      control={
        <Checkbox
          size={"small"}
          checked={showOverrideInput}
          onChange={(e) => setShowOverrideInput(e?.target?.checked)}
        />
      }
    />
  );
};

export default OverridesButton;
