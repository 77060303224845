import { Icon } from "@iconify/react";
import Check from "@mui/icons-material/Check";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover } from "@mui/material";
import { useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import ViewControlButton from "../ViewControlButton";
import "./css/ViewListMenu.css";

/**
 * Component which displays a button that opens a list of views for a given data grid, including the default option
 * @param {string} selectedView - the currently selected view object
 * @param {object[]} views - list of views to display in the list (should have a `name` property)
 * @param {function} onSelectedView - callback when user selects a view from the grid (arguments: {view})
 * @param {function} onDeleteView - callback when user deletes a view from the grid (arguments: {view})
 * @return {JSX.Element}
 */
const ViewListMenu = ({ selectedView, views, onSelectedView, onDeleteView }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectedView = ({ view }) => {
    handleClose();
    onSelectedView({ view });
  };

  const handleDeleteView = ({ e, view }) => {
    e.stopPropagation();
    onDeleteView({ view });
  };

  const open = Boolean(anchorEl);
  const iconWidth = "3em";

  return (
    <div>
      <ViewControlButton
        title={"Select which view to display for this grid"}
        onClick={handleOpen}
        color={"primary"}
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          maxWidth: "20em",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon icon={"pajamas:preferences"} style={{ marginRight: ".2em" }} />
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {!isNullOrUndefined(selectedView) ? selectedView?.name || "Unnamed" : "Default"}
        </div>
      </ViewControlButton>
      <Popover anchorEl={anchorEl} onClose={handleClose} open={open}>
        <Paper>
          <MenuList dense>
            <MenuItem
              onClick={() => handleSelectedView({ view: null })}
              title={"A generic grid view to use as a starting point."}
            >
              {!selectedView && (
                <ListItemIcon style={{ width: iconWidth }}>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText style={{ paddingLeft: !selectedView ? 0 : iconWidth }} inset>
                Default
              </ListItemText>
            </MenuItem>
            {Array.isArray(views) &&
              views.map((view) => {
                const isSelected = selectedView?.id === view.id;
                return (
                  <MenuItem onClick={() => handleSelectedView({ view })} title={view?.description || "No description"}>
                    {isSelected ? (
                      <ListItemIcon
                        class={"selected-data-grid-view-icon"}
                        style={{ width: iconWidth, color: "green", opacity: 1 }}
                      >
                        <Check />
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon
                        class={"delete-data-grid-view-icon"}
                        style={{ width: iconWidth, color: "red" }}
                        onClick={(e) => handleDeleteView({ e, view })}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </ListItemIcon>
                    )}
                    <ListItemText style={{ paddingLeft: 0 }} inset>
                      {view.name}
                    </ListItemText>
                  </MenuItem>
                );
              })}
          </MenuList>
        </Paper>
      </Popover>
    </div>
  );
};

export default ViewListMenu;
