import React, { useContext } from "react";
import { ListGroupItem } from "reactstrap";

import { useCard } from "../../../../../hooks/views/useCard";
import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Interface Auto-Hide Edit Buttons user preference switch
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceAutoHideEditButtons = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      autoHideEditButton: {
        tooltip:
          "When enabled, you must move your mouse over a field to display the Edit button. Note: some Edit Buttons are not affected by this",
        inputType: "switch",
        label: "Auto-Hide Edit Buttons",
        defaultValue: preferences?.getPreference("editButton", "autoHide") || false,
        onChangeFunction: ({ autoHideEditButton }) => {
          preferences?.setPreference("editButton", {
            autoHide: autoHideEditButton,
          });
          addToast({
            header: `Auto-Hide Edit Buttons was changed to ${autoHideEditButton ? "enabled" : "disabled"}`,
            icon: "success",
          });
        },
      },
    },
  });

  const card = useCard({
    id: "auto-hide-edit-buttons",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Auto-Hide Edit Buttons</h4>
      </>
    ),
    body: (
      <>
        {form.display}
        <br />
        <h5>Example</h5>
        <ListGroupItem>
          <GenericEditFieldV3
            item={{
              Example:
                form?.input?.autoHideEditButton === true
                  ? "Hover over me to see the Edit Button!"
                  : "The Edit Button is always visible",
            }}
            field={"Example"}
            disableRoleChecking={true}
            mutation={1}
          />
        </ListGroupItem>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceAutoHideEditButtons;
