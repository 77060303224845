import { Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { DontHavePermission } from "../../../../utils/AccessControl/components/DontHavePermission";
import { GridContext } from "../../../../utils/Context/GridContext";
import { useStateSync } from "../../../functional/useStateSync";
import { useDeleteMutation } from "../../../graphql/useDeleteMutation";
import { useCheckPermissions } from "../../../permissions/useCheckPermissions/useCheckPermissions";
import { useDuplicateItems } from "../../useDuplicateItems";
import { usePreferences } from "../../usePreferences/usePreferences";
import GridDetailsContextLink from "../components/GridDetailsContextLink";
import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";
import { gridEnableEditing } from "../functions/gridEnableEditing";
import { gridGetProperties } from "../functions/gridGetProperties";
import { gridSavePersistData } from "../functions/gridSavePersistData";
import { gridUpdateItemById } from "../functions/gridUpdateItemById";
import { useGridAccessControl } from "../hooks/useGridAccessControl";
import { useGridCheckFieldsRole } from "../hooks/useGridCheckFieldsRole";
import { useGridConfDetailsBar } from "../hooks/useGridConfDetailsBar";
import { useGridCreateItem } from "../hooks/useGridCreateItem";
import { useGridCustomColumns } from "../hooks/useGridCustomColumns";
import { useGridDeleteConfirm } from "../hooks/useGridDeleteConfirm";
import { useGridDetailsPanelSize } from "../hooks/useGridDetailsPanelSize";
import { useGridEditFields } from "../hooks/useGridEditFields";
import { useGridEnableEdit } from "../hooks/useGridEnableEdit";
import { useGridGetPreference } from "../hooks/useGridGetPreference";
import { useGridItemDetails } from "../hooks/useGridItemDetails";
import { useGridSetDetailsRoute } from "../hooks/useGridSetDetailsRoute";

import GridHeaderButtons from "./GridHeaderButtons";
import GridSyncfusion from "./GridSyncfusion";

/**
 * @description Grid component used to display table with a tool bar.
 * @param {boolean} allowFiltering - if allowFiltering set to true the filter bar will be displayed. If set to false
 * the filter bar will not be displayed. Filter bar allows the user to filter grid records with required criteria.
 * @param {boolean} allowGrouping - if allowGrouping set to true, then it will allow the user to dynamically group or
 * ungroup columns. Grouping can be done by drag and drop columns from column header to group drop area.
 * @param {boolean} allowPaging - If allowPaging is set to true, the pager renders at the footer of the Grid. It is used to handle page navigation in the Grid.
 * @param {boolean} allowTextWrap - if allowTextWrap set to true, then text content will wrap to the next line when
 * its text content exceeds the width of the Column Cells.
 * @param {number} columnSize - details panel size
 * @param {object} config - grid configuration object
 * @param {object} contextMenuConfig - configurations for the context menu
 * @param {object[]} contextMenuConfig.items - extra items to add to the context menu
 * @param {string} contextMenuConfig.items[].text - text for context menu button
 * @param {string} contextMenuConfig.items[].iconCss - icon css for context menu button
 * @param {function} contextMenuConfig.items[].onClick - onClick handler for context menu button
 * @param {string} createItemModalHeader - create modal header text
 * @param {JSX} createResourceComponent - create item component
 * @param {string} createResourceComponentWidth - width of the create modal
 * @param {object[]} data - array of objects, data for the grid
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteGqlQuery - graphql query for deleting an item. NOTE: should contain "__typename" field
 * @param {string} deleteMessage - message to display when deleting an item
 * @param {string} deleteMutation - graphql mutation for deleting an item.
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {string} detailsTitle - title of the details component
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {boolean} disableRoleChecking - disable role checking for the grid
 * @param duplicationSettings
 * @param {object} editSettings - configures the edit settings
 * @param {boolean} enableCheckBoxes - display checkboxes for each row in the grid
 * @param {boolean} enableColumnChooser - display column chooser for the grid
 * @param {boolean} enableContextMenu - enable context menu for the grid
 * @param {boolean} enableEdit - enable edit functionality for the grid
 * @param {boolean} enableMenu - display menu in the grid toolbar
 * @param {boolean} enableNoteIcon - enables note icon in the grid
 * @param {boolean} enableOptions - enables a column with dropdown with options
 * @param {boolean} enablePersistence - enable persistence functionality for the grid
 * @param {boolean} enablePrint - enable print functionality for the grid
 * @param {boolean} enableQuickDetails - display "info" icon amenuItemss a first row of the grid, on click opens a modal with item details
 * @param {boolean} disableQuickDetails - if TRUE will disable the info icon from the grid
 * @param {boolean} enableSearch - display search bar for the grid
 * @param {boolean} enableSelectButtons - if TRUE will display a column with select buttons by each row
 * @param {function} selectButtonCallback - called if any button shown with `enableSelectButtons` is true (passes selected item)
 * @param {function} actionComplete - called on every grid action can be used to modify the look of filter menus
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {object} filterSettings - configures the filtering behavior of the Grid.
 * @param {string} gridHeight - change grid height
 * @param {object} groupSettingsInit - initial object which configures the group behavior of the grid
 * @param {string} helpCenterUrl - for navigating to a related page in the Help Center
 * @param {JSX[]} headerButtons - array of JSX buttons for displaying on top of the grid
 * @param {boolean} isLoading - indicator for loading data from database
 * @param {object} lastSelectedItem - last selected item from the grid
 * @param {object[]} propsMenuItems - array of object for the menu of the grid
 * @param {string} module - module name of the grid data type
 * @param {string} noteIconColumnName - specify the noteIconColumnName for the grid
 * @param {JSX} noteIconComponent - JSX component for notes
 * @param {function} onToolbarClick - function triggers on toolbar click
 * @param {string} organizationID - selected organization id
 * @param {object} pageSettings - configures the paging behavior of the grid.
 * @param {string} persistenceUUID - unique grid identifier in UUID format
 * @param {object} customPersistenceData - custom data to be passed to the persistence settings that are saved
 * @param {function} resetFunction - re-fetch data from the database
 * @param {string} resource - resource name of the grid data type
 * @param {string} route - route for the grid data type
 * @param {object[]} selectedItems - array of selected items in the grid
 * @param {object} selectionSettings - configures the selection behavior of the grid
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {function} setExternalRef - sets the external reference of the grid
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {object} sortSettings - object with settings for sorting grid data
 * @param {object} textWrapSettings - configures the text wrap settings of the grid
 * @param {string} title - specify title for the grid
 * @param {object[]} toolbarOptions - array of objects for the grid toolbar
 * @param {string} typename - specify the type name of the grid data
 * @param {string} deleteButtonText - alternate text to display instead of 'Delete'
 * @param {string} deleteButtonIcon - alternate delete icon
 * @param {string} accessControlFieldName - field name to display for password protected items
 * @param setItemsToCheck
 * @param {function} getNewItem - get created item
 * @param {object[]} itemsToCheck - array of item to mark checkboxes in the grid
 * @param {function} updateItemById - an external updateItemById function that allows to parent components to hook into
 * the grid's updateItemById function
 * @param {string} updateMutation - graphql mutation
 * @param {boolean} allowKeyboard - enables or disables the key board interaction of Grid
 * @param error
 * @param setError
 * @return {JSX.Element}
 * @constructor
 */
export const Grid = ({
  typename = "Item", // putting typename at the top so that other default params may use it
  allowFiltering = false,
  allowGrouping = false,
  allowPaging = true,
  allowTextWrap = true,
  accessControlFieldName = "name",
  columnSize = 6,
  config,
  contextMenuConfig,
  createItemModalHeader = `Create a new ${convertCamelCaseToSentence(typename)}`,
  createResourceComponent,
  createResourceComponentWidth,
  data = [],
  deleteButtonText,
  deleteButtonIcon,
  deleteFunction,
  primaryField = "name",
  deleteGqlQuery,
  deleteMessage,
  deleteMutation,
  detailsComponent,
  detailsTitle,
  detailsType = DETAILS_TYPES.NONE,
  disableRoleChecking,
  duplicationSettings,
  editSettings = {
    allowEditing: false,
    allowAdding: true,
    allowDeleting: true,
    mode: "Batch",
  },
  enableCheckBoxes = false,
  itemsToCheck,
  setItemsToCheck,
  enableColumnChooser = true,
  enableContextMenu = false,
  enableEdit = false,
  enableMenu = false,
  enableNoteIcon = false,
  enableOptions = false,
  enablePersistence = true,
  enablePrint = false,
  enableQuickDetails = false,
  disableQuickDetails = false,
  enableSearch = false,
  enableSelectButtons = false,
  selectButtonCallback,
  actionComplete,
  fields,
  filterSettings = { type: "Menu" },
  gridHeight = window.innerHeight * 0.7,
  groupSettings: groupSettingsInit = {
    showToggleButton: true,
    showDropArea: false,
    disablePageWiseAggregates: true,
  },
  headerButtons = [],
  helpCenterUrl,
  isLoading,
  lastSelectedItem,
  menuItems: propsMenuItems = [],
  module,
  noteIconColumnName = "",
  noteIconComponent = null,
  onToolbarClick,
  organizationID,
  pageSettings = {
    pageSize: 20,
    pageSizes: ["5", "10", "20", "50", "100", "All"],
  },
  persistenceUUID = null, // Required for persistence to work. Must be unique across all components of the app
  customPersistenceData,
  resetFunction,
  getNewItem,
  resource,
  route = "/",
  selectedItems,
  selectionSettings = { checkboxMode: "ResetOnRowClick", type: "Multiple" },
  setLastSelectedItem,
  setRef: setExternalRef,
  setSelectedItems,
  sortSettings,
  textWrapSettings = { wrapMode: "Content" },
  title = "Items",
  toolbarOptions = [],
  updateItemById,
  updateMutation,
  allowKeyboard = false,
  error,
  setError,
}) => {
  // Forcing these settings
  if (!enableSelectButtons && !enableCheckBoxes) {
    enableQuickDetails = true;
  }
  // Done this way for backwards compatibility with the forced setting above
  if (disableQuickDetails === true) {
    enableQuickDetails = false;
  }
  detailsType = DETAILS_TYPES.NONE;

  // [PERMISSION HOOKS]
  // Checks permissions for the grid component
  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  // Get Help Center Permissions
  const checkPermissionsHelpCenter = useCheckPermissions({
    module: modules.HELP_CENTER,
    resource: resources.HELP_ARTICLE,
  });

  // [CONTEXT]
  // Get details bar for UI context
  const { isMobile, detailsBar } = useUIContext();

  // [STATE]
  // Reference to the Grid component
  const [ref, setRef] = useState("");

  // Get random uuid
  const [uuidGrid] = useState(uuid());

  // Used to reset the grid to initial state
  const [key, setKey] = useState(0);

  // Needed to temporarily disable persistence to reset the grid
  const [enablePers, setEnablePers] = useStateSync(enablePersistence);

  // Group Settings state
  const [groupSettings, setGroupSettings] = useState(groupSettingsInit);

  // Booleans indicating if the list has a next item
  const [hasNext, setHasNext] = useState(false);

  // Booleans indicating if the list has a previous item
  const [hasPrevious, setHasPrevious] = useState(false);

  // The item to show in the details modal.
  // This is held in state so that the user can easily switch between the details items of the list.
  const [detailsItem, setDetailsItem] = useState(lastSelectedItem);

  // Converted to a state to optionally show native context menu with control right click
  const [enableContextMenuState, setEnableContextMenu] = useState(enableContextMenu);

  // The last saved grid scroll position (for keeping the same scroll position when updating grid items)
  const [gridScrollPosition, setGridScrollPosition] = useState(0);

  //Number of times the databound event has been fired
  const [dataBoundCount, setDataBoundCount] = useState(0);
  const [isCreated, setIsCreated] = useState(false);

  //Destroy operations have been started
  const [destroyStarted, setDestroyStarted] = useState(false);

  // Used to prevent the details pane from updating when it doesn't need to.
  const disableUpdate = useRef(false);

  // If right clicking on a row, allows selection but disables opening the details panel
  const rightClick = useRef(false);

  // The unique ID for this grid component
  const getGridID = () => {
    return `grid${title}${persistenceUUID || uuidGrid}`;
  };

  // [USE-EFFECT]
  // Update parent hook ref state
  useEffect(() => {
    if (ref) {
      setExternalRef && setExternalRef(ref);
    }
  }, [ref]);

  // Tracking whether control key is down to disable syncfusion context menu in special cases (like for spell checking)
  const enableContextMenuFunc = (e, force = false) => {
    if ((e.keyCode === 17 && e.repeat !== true) || force === true) {
      setEnableContextMenu(enableContextMenu);
    }
  };
  const disableContextMenuFunc = (e) => {
    if (e.keyCode === 17 && e.repeat !== true) {
      setEnableContextMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", disableContextMenuFunc, false);
    document.addEventListener("keyup", enableContextMenuFunc, false);
    return () => {
      document.removeEventListener("keydown", disableContextMenuFunc, false);
      document.removeEventListener("keyup", enableContextMenuFunc, false);
    };
  }, []);

  // [HOOKS]
  // Get user preference for the grid persistence
  const { gridPreferencesRestored } = useGridGetPreference({
    setEnablePers,
    ref,
    persistenceUUID,
    enablePersistence,
    fields,
  });

  /**
   * User Preferences hook
   */
  const { setPreference, getPreference } = usePreferences();

  // Change the panel size
  // When this component unmounts, closes the details panel unless it's pinned.
  useGridDetailsPanelSize({ fields, detailsBar, detailsType, columnSize });

  // Configure the details bar
  useGridConfDetailsBar({
    detailsType,
    detailsBar,
    detailsTitle,
    resetFunction,
  });

  // Set details component route
  useGridSetDetailsRoute({ detailsBar, detailsItem, route });

  // Access control. Check fields against the user role
  useGridCheckFieldsRole({ ref, fields, checkPermissionsHook });

  // Update the column headers to display fieldNames better
  const { mappedFields } = useGridEditFields({ ref, fields, rightClick });

  // If enableEdit is true, enable editing
  useGridEnableEdit({
    ref,
    enableEdit,
    enableEditing: () => gridEnableEditing({ ref }),
  });

  // Check data for Access Control
  useGridAccessControl({ data });

  // Create item modal
  const { createItemModal } = useGridCreateItem({
    createResourceComponent,
    createItemModalHeader,
    createResourceComponentWidth,
    getNewItem,
    resetFunction,
    ref,
    typename,
  });

  // Delete item hook
  // Note: not used right now
  const deleteItemModal = useDeleteMutation({
    item: lastSelectedItem,
    module,
    resource,
    mutation: deleteMutation,
    resetFunction,
    deleteFunction: deleteFunction,
    primaryField,
    deleteGqlQuery,
    disableRoleChecking,
    typename,
    deleteButtonText,
  });

  /**
   * Delete modal with confirmation
   */
  const deleteConfirmModal = useGridDeleteConfirm({
    ref,
    selectedItems,
    setSelectedItems,
    deleteFunction,
    primaryField,
    resetFunction,
    deleteButtonText,
  });

  // Item Details modal
  const { detailsModal } = useGridItemDetails({
    ref,
    lastSelectedItem,
    module,
    resource,
    disableRoleChecking,
    typename,
    hasNext,
    hasPrevious,
    route,
    detailsItem,
    detailsComponent,
    setDetailsItem,
    detailsBar,
    detailsType,
    config,
    isLoading,
    setSelectedItems,
    setLastSelectedItem,
    disableUpdate,
    setHasPrevious,
    setHasNext,
    updateItemById: (item, disableRefresh) =>
      gridUpdateItemById({
        item,
        ref,
        disableUpdate,
        disableRefresh,
        updateItemById,
      }),
    rightClick,
    detailsTitle,
  });

  // Add custom columns for the grid
  useGridCustomColumns({
    ref,
    fields,
    detailsModal,
    setSelectedItems,
    selectedItems,
    noteIconColumnName,
    noteIconComponent,
    deleteMessage,
    deleteFunction,
    checkPermissionsHook,
    enableQuickDetails,
    enableOptions,
    enableNoteIcon,
    enableSelectButtons,
    selectButtonCallback,
    deleteConfirmModal,
  });

  // Handle selected item duplication if enabled
  const duplicateItemsHook = useDuplicateItems({
    items: selectedItems,
    typename,
    duplicationSettings,
    organizationID,
    resetFunction,
  });

  // Get grid properties
  const gridProperties = gridGetProperties({
    allowFiltering,
    allowGrouping,
    allowPaging,
    allowTextWrap,
    allowKeyboard,
    checkPermissionsHook,
    contextMenuConfig,
    createItemModal,
    createResourceComponent,
    data,
    deleteButtonText,
    deleteButtonIcon,
    deleteFunction,
    deleteGqlQuery,
    deleteMessage,
    deleteMutation,
    deleteConfirmModal,
    detailsBar,
    detailsComponent,
    detailsModal,
    detailsType,
    disableUpdate,
    duplicateItemsHook,
    duplicationSettings,
    editSettings,
    enableColumnChooser,
    enableContextMenu: enableContextMenuState,
    enableCheckBoxes,
    actionComplete,
    isCreated,
    setIsCreated,
    isLoading,
    itemsToCheck,
    setItemsToCheck,
    enableEdit,
    enablePers,
    enableSearch,
    fields,
    filterSettings,
    getGridID,
    gridHeight,
    groupSettings,
    lastSelectedItem,
    mappedFields,
    onToolbarClick,
    pageSettings,
    persistenceUUID,
    ref,
    rightClick,
    route,
    selectedItems,
    selectionSettings,
    setEnablePers,
    setGroupSettings,
    setLastSelectedItem,
    setRef,
    setSelectedItems,
    gridScrollPosition,
    dataBoundCount,
    setDataBoundCount,
    sortSettings,
    textWrapSettings,
    toolbarOptions,
    title,
    isMobile,
    destroyed: () => {
      //Only perform this function once
      if (!destroyStarted) {
        setDestroyStarted(true);

        //Save grid persist data only if the settings finished restoring
        if (gridPreferencesRestored) {
          gridSavePersistData({
            ref,
            getGridID,
            getPreference,
            setPreference,
            persistenceUUID,
            enablePersistence,
            customPersistenceData,
          });
        }
      }
    },
  });

  // Include Grid data or functions to be passed to Custom Field components
  const gridContext = {
    updateItemById: (item, saveScrollPosition, disableRefresh) =>
      gridUpdateItemById({
        item,
        ref,
        disableUpdate,
        setGridScrollPosition,
        saveScrollPosition,
        disableRefresh,
        updateItemById,
      }),
    resetFunction,
    fields,
    accessControlFieldName,
    createItemModal,
    enablePrint,
    checkPermissionsHook,
    checkPermissionsHelpCenter,
    persistenceUUID,
    propsMenuItems,
    enablePersistence,
    ref,
    deleteFunction,
    deleteButtonText,
    deleteConfirmModal,
    selectedItems,
    deleteMessage,
    setSelectedItems,
    getGridID,
    setEnablePers,
    setKey,
    enableMenu,
    updateMutation,
    resource,
    module,
    disableRoleChecking,
    setPreference,
    getPreference,
  };

  /**
   * The onContextMenu event enables sync fusion context menu upon opening the native context browser context menu.
   * This approach is used because there is no way to make sure the keyup event fires if ctrl key is lifted when
   * the context menu is opened.
   */
  return (
    <div onContextMenu={(e) => enableContextMenuFunc(e, true)} style={{ height: "100%" }}>
      {checkPermissionsHook?.resource?.read ? (
        <div id={getGridID() + uuid()} style={{ height: "100%" }}>
          <GridContext.Provider value={gridContext}>
            {deleteFunction || deleteMutation || deleteGqlQuery ? deleteItemModal.modal : ""}
            {detailsModal?.modal}
            {deleteConfirmModal?.modal}
            {duplicateItemsHook?.modal}
            <GridDetailsContextLink route={route} lastSelectedItem={lastSelectedItem} />
            <GridHeaderButtons headerButtons={headerButtons} />
            {error && <Alert severity="error">{error}</Alert>}
            <GridSyncfusion
              fields={fields}
              enableCheckBoxes={enableCheckBoxes}
              key={key}
              getGridID={getGridID}
              gridProperties={gridProperties}
            />
          </GridContext.Provider>
        </div>
      ) : (
        <DontHavePermission resource={resource} module={module} />
      )}
    </div>
  );
};
