import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { extractVendorReviewRiskControls } from "../../../functions/extractVendorReviewRiskControls";

/**
 * Retrieves the most recent finalized vendor review for a given vendor solution
 * @param {string} vendorSolutionID - the vendor solution for which to get the most recent vendor review
 * @return {Promise<{completionDate}|null>}
 */
export const getMostRecentVendorReviewForVendorSolution = async ({ vendorSolutionID }) => {
  const vendorReviews = await ListQueryBy({
    query: vendorReviewsByVendorSolution,
    variables: {
      vendorSolutionID,
      sortDirection: "DESC",
    },
    limit: 1,
  });

  let newVendorReview = null;
  if (Array.isArray(vendorReviews) && vendorReviews.length > 0) {
    newVendorReview = vendorReviews[0];
  }
  if (newVendorReview?.completionDate) {
    // for convenience extract the risk control information for each vendor control
    newVendorReview.riskControls = extractVendorReviewRiskControls({
      vendorReview: newVendorReview,
    });

    return newVendorReview;
  }

  return null;
};

const vendorReviewsByVendorSolution = /* GraphQL */ `
  query VendorReviewsByVendorSolution(
    $vendorSolutionID: ID
    $completionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVendorReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorReviewsByVendorSolution(
      vendorSolutionID: $vendorSolutionID
      completionDate: $completionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        completionDate
        rating
        ownerGroup
        controlCategories(limit: 100) {
          items {
            id
            ownerGroup
            name
            subControls(limit: 100) {
              items {
                id
                name
                statementNumber
                ownerGroup
                inPlace
                audited
                vendorControlTemplate {
                  id
                  riskControl {
                    id
                    name
                    statementNumber
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
