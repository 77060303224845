import React from "react";

import CostChange from "../../../../utils/GenericComponents/Numeric/CostChange";

const ChangeInCost = ({ item, newCost, oldCost }) => {
  const newPerc = item ? item.costChange : newCost;

  const riskControl = item && item.riskControl;

  const riskControlCost = riskControl && riskControl.costOfControl;

  const newCostOfControl = parseFloat(riskControlCost) + parseFloat(newPerc);

  const newCostDollars = newCostOfControl !== null && !isNaN(newCostOfControl) ? newCostOfControl : newCost;

  const oldCostDollars = riskControlCost !== null && !isNaN(riskControlCost) ? riskControlCost : oldCost;

  return <CostChange oldValue={oldCostDollars} newValue={newCostDollars} />;
};

export default ChangeInCost;
