import { checkArguments } from "@rivial-security/func-utils";

import { deleteControlEvidenceLink } from "./deleteControlEvidenceLink";

/**
 * Performs the operation for unlinking Evidence items from a control
 *
 * @param {Evidence[]} evidences - the evidences to unlink from the control
 * @param {Control} control - the control to unlink evidences from
 */
export const handleUnlinkingEvidences = async (evidences, control) => {
  checkArguments({
    evidences,
    control,
  });

  for (const unlinkItem of evidences) {
    if (control?.evidences?.items) {
      // loop through control's current evidence links
      for (const evidenceLink of control.evidences.items) {
        // if evidence is already linked to this control, unlink
        if (evidenceLink.evidence.id === unlinkItem.id) {
          await deleteControlEvidenceLink(evidenceLink.id);
        }
      }
    }
  }
};
