import { createInlineText, createText } from "@rivial-security/report-utils";

export const generateGeneralVendorReviewInformation = (vendorReview) => {
  const vendorName =
    (vendorReview &&
      vendorReview.vendorSolution &&
      vendorReview.vendorSolution.vendor &&
      vendorReview.vendorSolution.vendor.name) ||
    "";

  const vendorSolutionName = (vendorReview && vendorReview.vendorSolution && vendorReview.vendorSolution.name) || "";

  const vendorReviewCreationDate = new Date(vendorReview && vendorReview.createdAt);

  return [
    createText([createInlineText("Vendor: ", "Normal", { bold: true }), createInlineText(vendorName)]),
    createText([
      createInlineText("Vendor Product/Service: ", "Normal", { bold: true }),
      createInlineText(vendorSolutionName),
    ]),
    createText([
      createInlineText("Product/Service Common Name: ", "Normal", {
        bold: true,
      }),
      createInlineText("NA"),
    ]),
    createText([
      createInlineText("Date of Testing Range: ", "Normal", { bold: true }),
      createInlineText(vendorReviewCreationDate.toLocaleDateString()),
    ]),
  ];
};
