"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleGetQuery = void 0;
/**
 * Handles the algorithm for retrieving the full list of appsync data.
 * Utilizes the queryFunction parameter to perform a series of nextToken queries.
 * @param queryFunction - the Appsync "graphql" function. Either from API.graphql, or appsyncClient.graphql
 * @param query - The raw graphql query string (this function has graphql-tag built in)
 * @param variables - the variables graphql input
 */
const handleGetQuery = async ({ queryFunction, query, variables }) => {
    try {
        console.log("Running GetQuery: ", { query, variables });
        const data = await queryFunction(query, { ...variables });
        if (Array.isArray(data?.errors) && data.errors.length > 0) {
            console.error("[handleGetQuery] Errors: ", JSON.stringify(data.errors, null, 2));
        }
        const queryResultKey = Object.keys(data?.data)[0];
        if (queryResultKey !== undefined) {
            const item = data?.data?.[queryResultKey];
            if (item) {
                return item;
            }
            else {
                console.log("No matching data structure found.");
                return {};
            }
        }
        else {
            console.log("Data structure does not have the expected format.");
            return {};
        }
    }
    catch (err) {
        console.error("Error in GetQuery: ", JSON.stringify(err));
    }
    return {};
};
exports.handleGetQuery = handleGetQuery;
