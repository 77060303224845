/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *
 * Description: Allows User to create a new Maturity Indicator to be associated with a particular Program Blueprint Level
 */

import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose } from "react-apollo";
import { Button, Card, CardBody, CardFooter, Input, InputGroup, InputGroupAddon } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

const { listQuery: listMaturityMatrixs } = generateGraphql("MaturityMatrix");

const { createMutation: createMaturityIndicator } = generateGraphql("MaturityIndicator", [
  "name",
  "maturityIndicatorMaturityMatrixLevelId",
]);

const CreateMaturityIndicator = (props) => {
  const [name, setName] = useState("");

  const submit = () => {
    props.createMaturityIndicator({
      input: {
        name: name,
        ownerGroup: props.maturityMatrixLevel && props.maturityMatrixLevel.ownerGroup,
        maturityIndicatorMaturityMatrixLevelId: props.maturityMatrixLevel && props.maturityMatrixLevel.id,
      },
    });

    props.toggleModal && props.toggleModal();
  };

  return (
    <Card>
      <CardBody>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Maturity Indicator Name:</InputGroupAddon>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </InputGroup>
      </CardBody>
      <CardFooter>
        <Button color="success" onClick={() => submit()}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

CreateMaturityIndicator.propTypes = {
  maturityMatrixLevel: PropTypes.object.isRequired,
  toggleModal: PropTypes.func,
};

export default compose(graphqlMutation(gql(createMaturityIndicator), gql(listMaturityMatrixs), "components"))(
  CreateMaturityIndicator,
);
