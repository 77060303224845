import { ReactElement } from "react";

import { ItemMutation } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { Questionnaire, QuestionnaireStatus, QuestionnaireSystemLink, System } from "@rivial-security/schema-types";

import SelectNewItemsDataGrid from "@utils/Connections/components/SelectNewItemsDataGrid";
import { useUIContext } from "@utils/Context/UIContext";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { ErrorLogger } from "@utils/EventLogger";
import { performToastOperation } from "@utils/Toasts/functions/toastOperation";
import QuestionnaireDataGrid from "@views/OrganizationManager/Questionnaires/components/QuestionnaireDataGrid";
import { autoAssignQuestionnaireToSystemContacts } from "@views/OrganizationManager/Questionnaires/functions/autoAssignQuestionnaireToSystemContacts";
import { getQuestionnaireAssignedPointsOfContact } from "@views/OrganizationManager/Questionnaires/functions/getQuestionnaireAssignedPointsOfContact";
import { useQuestionnaireDataGrid } from "@views/OrganizationManager/Questionnaires/hooks/useQuestionnaireDataGrid";

export interface SystemQuestionnaireGridProps {
  organizationID: string;
  system: System;
  systemQuestionnaires: QuestionnaireSystemLink[];
}

const SystemQuestionnaireGrid = ({
  organizationID,
  system,
  systemQuestionnaires,
}: SystemQuestionnaireGridProps): ReactElement => {
  const { addToast, updateToast } = useUIContext();
  const { deleteMutation } = generateGraphql("QuestionnaireSystemLink", ["questionnaireID"]);
  const { deleteMutation: deleteQuestionnaireAssignee } = generateGraphql("QuestionnaireAssignee");

  const unlinkQuestionnaireFromSystem = async (): Promise<void> => {
    for (const questionnaire of systemQuestionnaires) {
      if (questionnaire?.id) {
        try {
          const data = await ItemMutation<Partial<QuestionnaireSystemLink>>({
            mutation: deleteMutation,
            input: {
              id: questionnaire.id,
            },
          });

          const systemPointOfContactID = system.pointOfContact?.id;
          const systemAdminPointOfContactID = system.adminPointOfContact?.id;

          const assignees = await getQuestionnaireAssignedPointsOfContact({ questionnaireID: data.questionnaireID });
          for (const assignee of assignees) {
            const isSystemPointOfContact = assignee.pointOfContactID === systemPointOfContactID;
            const isSystemAdminPointOfContact = assignee.pointOfContactID === systemAdminPointOfContactID;
            if (assignee.id && (isSystemPointOfContact || isSystemAdminPointOfContact)) {
              await ItemMutation<Partial<QuestionnaireSystemLink>>({
                mutation: deleteQuestionnaireAssignee,
                input: {
                  id: assignee.id,
                },
              });
            }
          }
        } catch (error) {
          ErrorLogger("Error unlinking questionnaire:", error);
        }
      }
    }
  };

  const onQuestionnaireAssignmentSubmit = async ({
    selectedItems,
  }: {
    selectedItems: Questionnaire[];
  }): Promise<void> => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        for (const questionnaire of selectedItems) {
          await ItemMutation<Partial<QuestionnaireSystemLink>>({
            mutation: generateGraphql("QuestionnaireSystemLink").createMutation,
            input: {
              ownerGroup: organizationID,
              systemID: system.id,
              questionnaireID: questionnaire.id,
            },
          }).then(async () => {
            await autoAssignQuestionnaireToSystemContacts({
              questionnaireID: questionnaire.id,
              systemAdmin: system.adminPointOfContact,
              systemOwner: system.pointOfContact,
              organizationID,
            });
          });
        }
      },
      inProgressText: "Assigning Questionnaires to System",
      failedText: "Failed to assign Questionnaires to System",
      successText: "Successfully assigned Questionnaires to System",
      iconColor: "success",
    });
  };
  const normalizeQuestionnaireGrid = (data: Questionnaire[]): Questionnaire[] => {
    if (!isNonEmptyArray(data)) {
      return [];
    }
    return data.filter((questionnaire) => {
      const systems = questionnaire?.systems?.items;
      return isNonEmptyArray(systems) && systems?.some((item) => item?.systemID === system.id);
    });
  };

  const filterQuestionnairesForLinkSelection = ({ allItems }: { allItems: Questionnaire[] }): Questionnaire[] => {
    return allItems.filter((item) => {
      const isQuestionnaireNotLinked = !systemQuestionnaires.some((sq) => sq.questionnaireID === item.id);
      const isQuestionnaireNotComplete = item.status !== QuestionnaireStatus.DONE;
      return isQuestionnaireNotLinked && isQuestionnaireNotComplete;
    });
  };

  const questionnaireDataGrid = useQuestionnaireDataGrid({
    organizationID,
    moduleInit: modules.RISK,
    resourceInit: resources.INFORMATION_SYSTEM,
    createResourceButtonText: "Link",
    createItemModalHeader: "Link Questionnaires to this System",
    createResourceComponent: (
      <SelectNewItemsDataGrid
        GridComponent={QuestionnaireDataGrid}
        submitFunction={onQuestionnaireAssignmentSubmit}
        filterItems={filterQuestionnairesForLinkSelection}
      />
    ),
    normalizeData: normalizeQuestionnaireGrid,
    deleteFunction: unlinkQuestionnaireFromSystem,
    deleteButtonText: "Unlink",
    deleteFunctionNote:
      "Clicking yes will remove this Questionnaire from the System. This will not delete the Questionnaire itself.",
    persistenceUUID: "jut142cd-90dc-4264-84e7-b8659dad6fee",
  });
  return <div style={{ height: "60vh" }}>{questionnaireDataGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(SystemQuestionnaireGrid);
