import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Card, CardContent } from "@mui/material";
import React, { useContext, useState } from "react";

import { GetQuery } from "@rivial-security/appsync-utils";
import { tryParse } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { genericResources } from "../../../../../../../../../../../definitions/constants/genericResources";
import { useSetAsyncData } from "../../../../../../../../../../../hooks/functional/useSetAsyncData";
import Loader from "../../../../../../../../../../../utils/LoadingComponents/Loader";
import ResultFieldViewer from "../../../../../../../../../../CustomQueries/components/ResultFieldViewer";
import { createWidgetActions } from "../../../../../../reducers/createWidgetActions";

import "./SourceNode.css";
import { CreateWidgetContext } from "../../../../../../useCreateWidget";
import SelectableTreeItem from "../SelectableTreeItem";

/**
 * A component representing raw resource from which to get data for a widget
 * @param {object} source - source state containing custom query id
 * @returns {JSX.Element}
 */
const SourceNode = ({ source }) => {
  const { dispatch } = useContext(CreateWidgetContext);

  const [sourceData, setSourceData] = useState(null);

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      const { getQuery } = generateGraphql("CustomQuery", ["name", "description", "queryConfig"]);

      const customQueryData = await GetQuery({
        query: getQuery,
        variables: { id: source.id },
      });

      customQueryData.queryConfig = tryParse(customQueryData.queryConfig);

      return customQueryData;
    },
    setData: (newSourceData) => {
      setSourceData(newSourceData);
    },
    dependencies: [source?.id],
  });

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Loader text={"Loading Source"} />
        ) : (
          <div className={"widget-source-node"}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p>{sourceData?.name || "New Source"}</p>
              <div style={{ flex: 1 }} />
              <div
                className={"delete-widget-source-icon"}
                onClick={() => {
                  dispatch({
                    type: createWidgetActions.DELETE_WIDGET_SOURCE,
                    deleteSourceInput: {
                      source,
                    },
                  });
                }}
                title={"Delete this Source"}
              >
                <DeleteOutlineOutlinedIcon />
              </div>
            </div>
            <ResultFieldViewer
              customQueryId={sourceData?.id}
              resultFields={sourceData?.queryConfig?.fields}
              resultFunctions={sourceData?.queryConfig?.functions}
              includedTypes={[genericResources.STRING, genericResources.INT, genericResources.FLOAT]}
              defaultAllExpanded={true}
              preventCollapse={true}
              CustomTreeItemComponent={SelectableTreeItem}
              sx={{
                treeView: {
                  width: "100%",
                },
              }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default SourceNode;
