import { useCsvImporter } from "../../../../hooks/views/useCsvImporter/hooks/useCsvImporter";
import { useObservationDataGrid } from "../../Observations/hooks/useObservationDataGrid";
import fields from "../constants/FINDINGS_IMPORTER_FIELDS";
import { processFindingsRow } from "../functions/processFindingsRow";

/**
 * A CSV importer used specifically for importing Observations, Recommendations, and Action Items
 *
 * A single Row may consist of fields for Observations, Recommendations, and Action Items.
 *
 * If an Observation Name is not included, won't create an Observation.
 * If a Recommendation Name is not included, won't create a Recommendation.
 * If an Action Item name is not included, won't create an Action Item
 *
 * If a source?.id param is passed in, will use it to connect Observations to the Source
 *
 * @param organizationID
 * @param source
 * @returns {{display: JSX.Element}}
 */
export const useFindingsImporter = ({ organizationID, source }) => {
  const csvImporter = useCsvImporter({
    organizationID,
    fields,
    processRow: processFindingsRow,
    isAsync: false,
    resultGrid: useObservationDataGrid,
    connectionIDField: "externalSourceID",
    connectionID: source?.id,
    typename: "Observation",
  });

  return {
    ...csvImporter,
  };
};
