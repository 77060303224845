import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import React from "react";
import { Button } from "reactstrap";

import { useModal } from "../../../../../../hooks/views/useModal";
import PolicyDetails from "../../../../../Program/Policies/components/PolicyDetails";
import { getFullName } from "../functions/getFullName";

/**
 * @description Displays timeline information about a point of contact uploading a Policy for an Evidence
 * @param {PointOfContact} pointOfContact - point of contact object
 * @param {string} pointOfContact.firstName - point of contact first name
 * @param {string} pointOfContact.lastName - point of contact last name
 * @param {object} policy - policy object
 * @returns {JSX.Element}
 * @constructor
 */
const PolicyActivity = ({ pointOfContact, policy }) => {
  const detailsModal = useModal(
    "Policy Details",
    <PolicyDetails item={policy} />,
    <Button color={"success"}>
      <i className={"icon-eye"} style={{ marginRight: "1em" }} />
      View Policy
    </Button>,
    {
      width: "80vw",
    },
  );

  const fullName = getFullName({ pointOfContact });

  return (
    <span>
      {detailsModal.modal}
      {fullName || "Someone"} referenced a{" "}
      <LightTooltip arrow interactive title={<div>{detailsModal.modalButton}</div>}>
        <span
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none;",
            cursor: "pointer",
          }}
        >
          Policy
        </span>
      </LightTooltip>
    </span>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default PolicyActivity;
