import React from "react";

/**
 * @description This component displays the role of a user.
 * @param {object} item - The user object
 * @returns {JSX.Element}
 * @constructor
 */
const UserRoleField = ({ item }) => {
  return <div>{item?.roleLinks?.items?.[0]?.role?.name ? item.roleLinks.items[0].role.name : null}</div>;
};

export default UserRoleField;
