/**
 * Converts a list of tags in a db item to a string list of the names of tags
 * @param {object} item - db item with a tags field
 * @return {string} concatenated string of tag names
 */
export const convertTags = (item) => {
  if (item?.tags?.items) {
    const tagNames = item.tags.items.map((tagLink) => tagLink?.tag?.name).filter(Boolean);
    item.tags = tagNames.join(", ");
  }

  return item.tags ?? "";
};
