"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Retrieves the resources module from the roleConfig object
 * @param {string} resource - the model typename present in role config
 * @param {object} roleConfig - object that defines the clients permissions
 */
const getResourceModule = ({ resource, roleConfig }) => {
    //Check arguments
    if (!roleConfig || !roleConfig.resources || !resource) {
        console.error("Invalid arguments in getResourceModule - ", { resource }, { roleConfig });
        return null;
    }
    //Check if resource is present in the roleConfig
    if (!roleConfig.resources.hasOwnProperty(resource)) {
        console.error("Could not find module in getResourceModule for the following arguments - ", { resource }, { roleConfig });
        return null;
    }
    //Check if resource object contains a module field
    if (!roleConfig.resources[resource].module) {
        console.error("No module found on resource in role config during getResourceModule - ", { resource }, { roleConfig });
        return null;
    }
    //Return the module associated with the resource
    return roleConfig.resources[resource].module;
};
exports.default = getResourceModule;
