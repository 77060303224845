import { useEffect } from "react";

/**
 * A state updater method for one-many transfer list hook, populates the side with items that are already linked to the item
 * NOTE: Currently not used for much but may be used in the future for special formatting.
 * @param {object[]} items - all items linked to the one item
 * @param {function} setLinkedItems - function to update the state of the transfer list linked items
 */
export const usePopulateLinkedItems = ({ items, setLinkedItems } = {}) => {
  useEffect(() => {
    const listItems = [];

    if (Array.isArray(items)) {
      for (const item of items) {
        listItems.push(item);
      }
    }

    if (typeof setLinkedItems === "function") setLinkedItems(listItems);
  }, [items]);
};
