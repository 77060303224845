import { ErrorLogger } from "@utils/EventLogger";

import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * @description: Deletes an Assessment from the database. Also deletes all associated AssessmentVulnerabilityLinks and AssessmentTargetLinks
 *
 * Deep Delete Options:
 *
 * - Delete Vulnerabilities: Delete all vulnerabilities associated with the assessment. If the Vulnerability is associated with another assessment, it will not be deleted.
 *
 * @param {object} item - Assessment to delete
 * @param {boolean} deleteVulnerabilities - if true, will delete all Vulnerabilities associated with this Assessment
 * @param {boolean} deleteTargets - if true, will delete all Targets associated with this Assessment
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<void>}
 * @constructor
 */
export const deleteAssessment = async (
  item,
  { deleteVulnerabilities = false, deleteTargets = false },
  onJobStarted,
) => {
  if (!item?.id) {
    ErrorLogger("[deleteAssessment.js] Missing Assessment ID");
    return;
  }

  /**
   * Delete Assessment in Fargate
   */
  return await deepDelete({
    typename: "Assessment",
    item,
    config: {
      deleteVulnerabilities,
      deleteTargets,
    },
    onJobStarted,
  });
};
