"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTableOfContents = void 0;
const generateTableOfContents = () => {
    return [
        {
            paragraphFormat: {
                styleName: "Normal",
                listFormat: {},
            },
            characterFormat: {},
            inlines: [],
        },
        {
            paragraphFormat: {
                styleName: "Normal",
                listFormat: {},
            },
            characterFormat: {},
            inlines: [
                {
                    characterFormat: {},
                    text: "TABLE_OF_CONTENTS_PLACEHOLDER",
                },
            ],
        },
        {
            paragraphFormat: {
                listFormat: {},
            },
            characterFormat: {},
            inlines: [
                {
                    characterFormat: {},
                    fieldType: 1,
                },
            ],
        },
    ];
};
exports.generateTableOfContents = generateTableOfContents;
