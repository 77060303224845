import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * @description Display an audit date
 * @param {object} item - compliance audit
 * @param {string} module
 * @param resource
 * @param field
 * @returns {JSX.Element}
 * @constructor
 */
const AuditDate = ({ item, module = modules.COMPLIANCE, resource = resources.AUDIT, field = "auditDate" }) => {
  const { updateMutation } = generateGraphql("Audit", ["auditDate"]);

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field={field}
      inputType={GENERIC_FIELD_TYPES.DATE}
      mutation={updateMutation}
    />
  );
};

export default AuditDate;
