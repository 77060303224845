import { removeDuplicateObjects } from "@rivial-security/func-utils";

import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

//TODO RISC-3233: move any uses of this function to an automated dynamo db trigger lambda

/**
 * Frontend gateway to submit automatic risk changes based on evidence changes/deletions
 * NOTE: if any compliance/risk control overrides are provided
 * @param {string} evidenceID - the evidence that triggered the controls check
 * @param {string} organizationID - the organization that the evidence/controls belong to (need this for correct order of operations)
 * @param {object[]} [controlOverrides] - all of the compliance controls to check (object with id property required)
 * @param {object[]} [riskControlOverrides] - all of the risk controls to check (object with id property required)
 * @param {string} [controlOverrideId] - single compliance control to check (id by itself)
 * @param {string} [riskControlOverrideId] - single risk control to check (id by itself)
 * @param {string} overrideType - the type of override (what happened to evidence)
 * @param {string} changeOwner - information on the person responsible for the control check
 * @return {Promise<object>} - the onEvidenceControl lambda response
 */
export const submitRiskComplianceSyncJob = async ({
  evidenceID,
  organizationID,
  controlOverrides,
  riskControlOverrides,
  controlOverrideID,
  riskControlOverrideID,
  overrideType,
  changeOwner,
}) => {
  //Create lambda input
  const input = {
    messageGroupID: organizationID,
    message: {
      type: "riskComplianceSync",
      input: {
        evidenceID,
        organizationID,
        controlOverrideID,
        controlOverrides: removeDuplicateObjects(controlOverrides),
        riskControlOverrideID,
        riskControlOverrides: removeDuplicateObjects(riskControlOverrides),
        overrideType,
        changeOwner, // person who triggered the
      },
    },
  };

  //Create query string
  const handleJobQueueLambda = `
    query handleJobQueue($input: AWSJSON) {
      handleJobQueue(input: $input)
    }
  `;

  try {
    return await GetQuery({
      query: handleJobQueueLambda,
      variables: {
        input: JSON.stringify(input),
      },
    });
  } catch (e) {
    throw new Error(`Error calling handleJobQueue lambda: ${e.message}`);
  }
};
