"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getARO = void 0;
/**
 * Gets the Annual Rate of Occurrence value from a Risk.
 * This does not factor in the Risk Modifier.
 * Used to get a number between an ARO range, or just the ARO itself
 *
 * @function
 * @author Jacob Blazina
 * @param {object} risk
 * @param {annualRateOfOccurrence} risk.annualRateOfOccurrence
 * @param {number} [risk.annualRateOfOccurrenceMax=null] - Optional. Is passed in this function generates a number from a range
 * @returns {number}
 */
const getRandomAROInRange_1 = require("./getRandomAROInRange");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getARO = (risk) => {
    const ARO = risk.annualRateOfOccurrence;
    const AROMax = risk.annualRateOfOccurrenceMax;
    if (AROMax && AROMax > 0)
        return (0, getRandomAROInRange_1.getRandomAROInRange)(ARO, AROMax);
    else
        return ARO;
};
exports.getARO = getARO;
