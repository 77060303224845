import React, { useEffect } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useModal } from "../../../../hooks/views/useModal";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import CreateButton from "../../../../utils/GenericComponents/CreateButton";

import { useKeyPerformanceIndicator } from "./useKeyPerformanceIndicator";

export const useSelectKeyPerformanceIndicator = ({
  organizationID,
  riskControl,
  control,
  evidence,
  resetFunction,
  onSubmitCallback,
}) => {
  const buttonCallback = async (data) => {
    onSubmitCallback && onSubmitCallback(data);

    if (riskControl && riskControl.id) {
      const { createMutation } = generateGraphql("KPIRiskControlLink");
      await ItemMutation(createMutation, {
        keyPerformanceIndicatorID: data.id,
        riskControlID: riskControl.id,
        ownerGroup: organizationID,
      }).then(() => tryFunction(resetFunction, selectKeyPerformanceIndicator.toggleModal));
    } else if (control && control.id) {
      const { createMutation } = generateGraphql("KPIComplianceControlLink");
      await ItemMutation(createMutation, {
        keyPerformanceIndicatorID: data.id,
        controlID: control.id,
        ownerGroup: organizationID,
      }).then(() => tryFunction(resetFunction, selectKeyPerformanceIndicator.toggleModal));
    } else if (evidence && evidence.id) {
      const { createMutation } = generateGraphql("KPIEvidenceLink");
      await ItemMutation(createMutation, {
        keyPerformanceIndicatorID: data.id,
        evidenceID: evidence.id,
        ownerGroup: organizationID,
      }).then(() => tryFunction(resetFunction, selectKeyPerformanceIndicator.toggleModal));
    }
  };

  const keyPerformanceIndicatorHook = useKeyPerformanceIndicator({
    organizationID,
    dataCardConfig: {
      config: {
        showSelectBoxes: true,
        selectionType: "button",
        buttonCallback,
        selectButtonTableHeader: "Select a KPI",
      },
    },
    toggleModal: () => selectKeyPerformanceIndicator?.toggleModal(),
  });

  useEffect(() => {
    keyPerformanceIndicatorHook.setShowSelectBoxes(true);
  }, []);

  const selectKeyPerformanceIndicator = useModal(
    "Select a Key Performance Indicator",
    keyPerformanceIndicatorHook.display,
    <CreateButton title="Attach a Key Performance Indicator to this resource" />,
    { width: "60vw" },
  );

  return {
    ...keyPerformanceIndicatorHook,
    ...selectKeyPerformanceIndicator,
  };
};
