import React from "react";

/**
 * Custom field for showing widget type
 * @param {string} type - the type of widget
 * @returns {JSX.Element}
 */
const CustomWidgetTypeField = ({ type }) => {
  return <div>{type}</div>;
};

export default CustomWidgetTypeField;
