import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Handle grouping workflow and other grid action callbacks
 * @param {object} ref - reference of the grid component
 * @param {object} args - argument object of the grouping workflow
 * @param {function} setGroupSettings - state management function for the grouping of fields
 */
export const gridActionHandler = ({ ref, args, setGroupSettings, actionComplete }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    ref,
    args,
    setGroupSettings,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  /**
   * Call external actionComplete function
   */
  actionComplete && actionComplete(args);

  if (args?.requestType === "grouping") {
    setGroupSettings &&
      setGroupSettings((groupSettings) => {
        return {
          ...groupSettings,
          showDropArea: true,
        };
      });
  }

  if (args?.requestType === "ungrouping") {
    if (ref?.groupSettings?.columns?.length <= 0) {
      setGroupSettings &&
        setGroupSettings((groupSettings) => {
          return {
            ...groupSettings,
            showDropArea: false,
          };
        });
    }
  }

  /**
   * Upon choosing a different set of fields in the column chooser, the search column is reset by refreshing the grid
   * NOTE: if the refresh is done too fast the visible columns are not updated yet leading to the search column not
   * getting the updated data
   * REFERENCE: https://www.syncfusion.com/forums/139591/event-when-user-choose-a-column
   */
  if (args?.requestType === "columnstate") {
    if (ref?.refresh) {
      setTimeout(() => {
        ref.refresh();
      }, 1000);
    }
  }
};
