import {
  checkSystemForRiskControlOverride,
  findIndexOfRiskControlOverride,
  isRiskControlOverrideFieldEnabled,
} from "@rivial-security/risk-calc-utils";

export const getTotalCostOfControls = (controlCategory, system) => {
  return getCostOfControlsForCategory(controlCategory, system);
};

const getCostOfControlsForCategory = (controlCategory, system) => {
  let cost = 0;

  if (controlCategory) {
    const controls =
      controlCategory.subControls && controlCategory.subControls.items ? controlCategory.subControls.items : [];

    for (const control of controls) {
      const value = getCostOfControlForSubControl(control, system);

      cost += value;
    }
  }

  return cost;
};

const getCostOfControlForSubControl = (subControl, system) => {
  const riskControlOverrides = system && system.riskControlOverrides;

  const indexOfRiskControlOverride = findIndexOfRiskControlOverride(riskControlOverrides, subControl);

  const doesOverrideExist = checkSystemForRiskControlOverride(riskControlOverrides, "costOfControl") !== false;

  const isOverrideEnabled =
    indexOfRiskControlOverride !== -1 &&
    isRiskControlOverrideFieldEnabled(riskControlOverrides[indexOfRiskControlOverride], "costOfControl");

  const overrideValue =
    doesOverrideExist &&
    riskControlOverrides[indexOfRiskControlOverride] &&
    riskControlOverrides[indexOfRiskControlOverride]["costOfControl"];

  const value = isOverrideEnabled ? overrideValue : subControl["costOfControl"];

  return value;
};
