import React from "react";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import FindingsCard from "../../../../Program/Findings/components/FindingsCard/FindingsCard";

/**
 * Displays Observations, Recommendations, and Action Items for a Control
 * @param {object} item - control object
 * @param {string} module - module to filter by
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ControlFindingsCard = ({ item, module, ...props }) => {
  return <FindingsCard control={item} module={module} />;
};

export default withOrganizationCheck(ControlFindingsCard);
