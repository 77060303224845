export const deleteSystemRiskThreatLink_minimal = /* GraphQL */ `
  mutation DeleteSystemRiskThreatLink($input: DeleteSystemRiskThreatLinkInput!) {
    deleteSystemRiskThreatLink(input: $input) {
      id
      systemRiskLink {
        id
      }
    }
  }
`;

export const deleteSystemControlCategoryLink_minimal = /* GraphQL */ `
  mutation DeleteSystemControlCategoryLink($input: DeleteSystemControlCategoryLinkInput!) {
    deleteSystemControlCategoryLink(input: $input) {
      id
    }
  }
`;
