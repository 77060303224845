import { isNullOrUndefined } from "@rivial-security/func-utils";

import {
  QUERY_RUN_TYPE,
  useCustomQueryRunnerExecutionWorkflow,
} from "./hooks/useCustomQueryRunnerExecutionLogic/useCustomQueryRunnerExecutionLogic";
import { useCustomQueryRunnerResult } from "./hooks/useCustomQueryRunnerResult/useCustomQueryRunnerResult";

/**
 * Allows running queries that were created with the query builder UI
 * @param {object} query - the query configuration object created by the user with query builder
 * @param {string} organizationID - the organization for which to run the selected query
 * @param {string} customQueryID - the id of the custom query to run
 * @param {string} runType - the type of way to perform the query (LAMBDA or LOCAL)
 * @param {string} title - optional title to display above the results grid
 * @param {string} persistenceUUID - optional persistence UUID to use when saving the query results
 * @param {boolean} disableTopLevelFunctions - if true will disable the top level functions in the query result view
 * @param {function} onColumnHeaderClick - the function to call when a column header is clicked
 * @param {function} normalizeData - the function to call to normalize the data before setting it to the grid
 * @param {object[]} gridFieldsToMerge - the fields to merge into the grid field config
 * @param {boolean} enableQuickDetails - if true will enable the quick details option in the grid
 * @param {string} detailsLink - the link to use for the details page button
 * @return {{display: JSX.Element}}
 */
export const useCustomQueryRunner = ({
  query,
  organizationID,
  customQueryID,
  runType = QUERY_RUN_TYPE.LAMBDA,
  title,
  persistenceUUID,
  disableTopLevelFunctions = false,
  onColumnHeaderClick,
  gridFieldsToMerge,
  normalizeData,
  enableQuickDetails,
  detailsLink,
  autoRender,
}) => {
  const workflow = useCustomQueryRunnerExecutionWorkflow({
    query,
    organizationID,
    customQueryID,
    runType,
    autoRender,
  });

  const grid = useCustomQueryRunnerResult({
    query,
    workflow,
    title,
    persistenceUUID,
    disableViews: isNullOrUndefined(persistenceUUID),
    organizationID,
    disableTopLevelFunctions,
    onColumnHeaderClick,
    normalizeData,
    gridFieldsToMerge,
    enableQuickDetails,
    detailsLink,
  });

  return {
    display: grid.display,
    gridDisplay: grid.gridDisplay,
    workflow,
    grid,
  };
};
