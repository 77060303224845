import { Dispatch, ReactElement, SetStateAction, useEffect } from "react";

import { useVulnerabilitiesImporter } from "../../../Vulnerabilities/hooks/useVulnerabilitiesImporter";

export interface AssessmentInput {
  files: File[];
  csvScannerName: string;
  reportConfig: object;
  columnMap: Record<string, string>;
}

export interface AddAssessmentFiles {
  input: AssessmentInput;
  setInput: Dispatch<SetStateAction<AssessmentInput>>;
  organizationID: string;
}

const AddAssessmentFiles = ({ input, setInput, organizationID }: AddAssessmentFiles): ReactElement => {
  const importer = useVulnerabilitiesImporter({
    organizationID,
    files: input?.files,
    scanner: input?.csvScannerName,
  });

  useEffect(() => {
    setInput((data) =>
      Object.assign(data, {
        csvScannerName: importer?.scanner,
        files: importer?.files,
        columnMap: importer?.columnMap,
      }),
    );
  }, [importer?.scanner, importer?.files, importer?.columnMap]);

  return <div>{importer.display}</div>;
};

export default AddAssessmentFiles;
