import cx from "classnames";
import { ReactElement } from "react";

import { formattedName } from "@rivial-security/func-utils";

import StylelessButton from "@components/Atoms/StylelessButton/StylelessButton";
import Text, { TextVariant } from "@components/Atoms/Text/Text";

import styles from "./PointOfContactName.module.scss";

interface PointOfContactNameProps {
  pointOfContact?: Record<string, unknown>;
  isDisabled?: boolean;
  onClick?: () => void;
}

const PointOfContactName = ({ pointOfContact, isDisabled, onClick }: PointOfContactNameProps): ReactElement => {
  const classNames = cx({
    [styles["name-text"]!]: true,
    [styles["name-text--assigned"]!]: pointOfContact,
    [styles["name-text--unassigned"]!]: !pointOfContact,
    [styles["name-text--disabled"]!]: isDisabled,
  });

  return (
    <StylelessButton onClick={onClick}>
      {pointOfContact ? (
        <Text htmlElement={"span"} variant={TextVariant.BODY3} className={classNames}>
          {formattedName({ pointOfContact })}
        </Text>
      ) : (
        <Text htmlElement={"span"} variant={TextVariant.BODY3} className={classNames}>
          <i className="icon-user-unfollow" /> Not Assigned
        </Text>
      )}
    </StylelessButton>
  );
};

export default PointOfContactName;
