import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";

/**
 * Displays Observations, Recommendations, and Action Items related to a Department
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentFindingsCard = ({ itemId, organizationID }) => {
  return (
    <FindingsCard
      organizationID={organizationID}
      department={{
        id: itemId,
      }}
      sx={{ height: "75vh" }}
    />
  );
};

export default withOrganizationCheck(DepartmentFindingsCard);
