import React, { useEffect } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CancelOrSubmitRow from "../../../../utils/GenericComponents/CancelOrSubmitRow";
import { useNonStandardControlsGrid } from "../../../Risk/RiskControls/hooks/useNonStandardControlsGrid";
import { listOrganizationNonStandardRiskControlEvidenceLinks } from "../functions/listOrganizationNonStandardRiskControlEvidenceLinks";

/**
 * Allows selecting multiple non-standard controls
 * @param {object} linkedItems - items to filter out, (filtered out by id field)
 * @param {function} [onSubmitCallback] - callback upon pressing submit (includes selected items {selectedItems: [...]})
 * @param {function} [toggleModal] - call back to close modal (if one exists)
 * @param {string} organizationID - the organization for the non-standard controls
 * @return {JSX.Element}
 */
const SelectNonStandardControls = ({ linkedItems = null, onSubmitCallback, toggleModal, organizationID }) => {
  const gridConfig = {
    enableSelection: true,
  };

  const fieldOverrides = [
    {
      name: "implementationDetails",
      component: null,
    },
    {
      name: "costOfControl",
      component: null,
    },
    {
      name: "implementationRating",
      component: null,
    },
  ];

  const queryConfig = {
    query: null,
  };

  const grid = useNonStandardControlsGrid({
    organizationID,
    gridConfig,
    fieldOverrides,
    queryConfig,
  });

  useEffect(() => {
    const getData = async () => {
      //Get all risk control overrides
      const data = await listOrganizationNonStandardRiskControlEvidenceLinks({
        organizationID,
      });

      //Filter out any non-standard controls that are already linked
      const filteredData = data.filter((item) => {
        return linkedItems.find((linkedItem) => linkedItem.id === item.id) === undefined;
      });

      grid?.setData(filteredData);
    };

    //Only set data when linked items are defined
    if (Array.isArray(linkedItems)) {
      getData();
    }
  }, [linkedItems]);

  return (
    <div>
      <div style={{ height: "65vh" }}>{grid.gridDisplay}</div>
      <CancelOrSubmitRow
        onBothCallback={toggleModal}
        onSubmitCallback={() => {
          onSubmitCallback && onSubmitCallback({ selectedItems: grid.selectedData });
        }}
      />
    </div>
  );
};

export default withOrganizationCheck(SelectNonStandardControls);
