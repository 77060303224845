import { resources } from "@rivial-security/role-utils";

import { enumDefinition } from "./generic/enum";
import { objectDefinition } from "./generic/object/object";
import { resourceDefinition } from "./generic/resource";
import { resourcePathDefinition } from "./generic/resourcePath";
import { stringDefinition } from "./generic/string";
import { genericResources } from "./genericResources";
import { operationTeamDefinition } from "./platform/admin/operationTeam";
import { noteDefinition } from "./platform/common/note";
import { auditDefinition } from "./platform/compliance/audit";
import { controlDefinition } from "./platform/compliance/control";
import { controlFrameworkDefinition } from "./platform/compliance/controlFramework";
import { documentDefinition } from "./platform/compliance/document";
import { evidenceDefinition } from "./platform/compliance/evidence";
import { keyPerformanceIndicatorDefinition } from "./platform/metrics/keyPerformanceIndicator";
import { automationDefinition } from "./platform/organization/automation";
import { customResourceTypeDefinition } from "./platform/organization/customResourceType";
import { departmentDefinition } from "./platform/organization/department";
import { emailDefinition } from "./platform/organization/email";
import { integrationDefinition } from "./platform/organization/integration";
import { pointOfContactDefinition } from "./platform/organization/pointOfContact";
import { queryDefinition } from "./platform/organization/query";
import { questionnaireDefinition } from "./platform/organization/questionnaire";
import { roleDefinition } from "./platform/organization/role";
import { ssoProviderDefinition } from "./platform/organization/ssoProvider";
import { userDefinition } from "./platform/organization/user";
import { widgetDefinition } from "./platform/organization/widget";
import { actionItemDefinition } from "./platform/program/actionItem";
import { blueprintDefinition } from "./platform/program/blueprint";
import { meetingDefinition } from "./platform/program/meeting";
import { observationDefinition } from "./platform/program/observation";
import { policyDefinition } from "./platform/program/policy";
import { recommendationDefinition } from "./platform/program/recommendation";
import { responsibilityDefinition } from "./platform/program/responsibility";
import { sourceDefinition } from "./platform/program/source";
import { taskDefinition } from "./platform/program/task";
import { classificationTierDefinition } from "./platform/response/classificationTier";
import { detectionDefinition } from "./platform/response/detection";
import { exerciseDefinition } from "./platform/response/exercise";
import { incidentDefinition } from "./platform/response/incident";
import { playbookDefinition } from "./platform/response/playbook";
import { responseTeamDefinition } from "./platform/response/responseTeam";
import { informationAssetDefinition } from "./platform/risk/informationAsset";
import { keyRiskIndicatorDefinition } from "./platform/risk/keyRiskIndicator";
import { riskChangeDefinition } from "./platform/risk/riskChange";
import { riskControlDefinition } from "./platform/risk/riskControl";
import { riskControlCategoryDefinition } from "./platform/risk/riskControlCategory";
import { systemDefinition } from "./platform/risk/system";
import { threatDefinition } from "./platform/risk/threat";
import { assessmentDefinition } from "./platform/testing/assessment";
import { targetDefinition } from "./platform/testing/target";
import { vulnerabilityDefinition } from "./platform/testing/vulnerability";
import { vendorDefinition } from "./platform/vendor/vendor";
import { vendorCertificationDefinition } from "./platform/vendor/vendorCertification";
import { vendorControlDefinition } from "./platform/vendor/vendorControl";
import { vendorControlCategoryDefinition } from "./platform/vendor/vendorControlCategories";
import { vendorReviewDefinition } from "./platform/vendor/vendorReview";
import { vendorSolutionDefinition } from "./platform/vendor/vendorSolution";

export const resourceDefinitions = {
  // [COMPLIANCE]
  [resources.AUDIT]: auditDefinition,
  [resources.CONTROL]: controlDefinition,
  [resources.CONTROL_FRAMEWORK]: controlFrameworkDefinition,
  [resources.EVIDENCE]: evidenceDefinition,
  [resources.DOCUMENT]: documentDefinition,

  // [ORGANIZATION]
  [resources.AUTOMATION]: automationDefinition,
  [resources.POINT_OF_CONTACT]: pointOfContactDefinition,
  [resources.CUSTOM_QUERY]: queryDefinition,
  [resources.EMAIL_TEMPLATE]: emailDefinition,
  [resources.ROLE]: roleDefinition,
  [resources.CUSTOM_WIDGET]: widgetDefinition,
  [resources.USER]: userDefinition,
  [resources.DEPARTMENT]: departmentDefinition,
  [resources.QUESTIONNAIRE]: questionnaireDefinition,
  [resources.CUSTOM_RESOURCE_TYPE]: customResourceTypeDefinition,
  [resources.TOOL]: integrationDefinition,

  // [PROGRAM]
  [resources.ACTION_ITEM]: actionItemDefinition,
  [resources.BLUEPRINT_TEMPLATE]: blueprintDefinition,
  [resources.MEETING]: meetingDefinition,
  [resources.OBSERVATION]: observationDefinition,
  [resources.POLICY]: policyDefinition,
  [resources.RECOMMENDATION]: recommendationDefinition,
  [resources.SOURCE]: sourceDefinition,
  [resources.TASK]: taskDefinition,
  [resources.IMPLEMENTATION_LEVEL]: responsibilityDefinition,

  // [RESPONSE]
  [resources.CLASSIFICATION_TIER]: classificationTierDefinition,
  [resources.DETECTION]: detectionDefinition,
  [resources.EXERCISE]: exerciseDefinition,
  [resources.INCIDENT]: incidentDefinition,
  [resources.PLAYBOOK]: playbookDefinition,
  [resources.RESPONSE_TEAM]: responseTeamDefinition,

  // [RISK]
  [resources.INFORMATION_ASSET]: informationAssetDefinition,
  [resources.INFORMATION_SYSTEM]: systemDefinition,
  [resources.KEY_RISK_INDICATOR]: keyRiskIndicatorDefinition,
  [resources.RISK_CHANGE]: riskChangeDefinition,
  [resources.RISK_CONTROL]: riskControlDefinition,
  [resources.RISK_CONTROL_CATEGORY]: riskControlCategoryDefinition,
  [resources.THREAT]: threatDefinition,

  // [TESTING]
  [resources.ASSESSMENT]: assessmentDefinition,
  [resources.TARGET]: targetDefinition,
  [resources.VULNERABILITY]: vulnerabilityDefinition,

  // [VENDOR]
  [resources.VENDOR]: vendorDefinition,
  [resources.VENDOR_SOLUTION]: vendorSolutionDefinition,
  [resources.VENDOR_REVIEW]: vendorReviewDefinition,
  [resources.VENDOR_CONTROL]: vendorControlDefinition,
  [resources.VENDOR_CERTIFICATION]: vendorCertificationDefinition,
  [resources.VENDOR_CONTROL_CATEGORY]: vendorControlCategoryDefinition,

  // [ADMIN]
  [resources.OPERATION_TEAM]: operationTeamDefinition,

  // [METRICS]
  [resources.KEY_PERFORMANCE_INDICATOR]: keyPerformanceIndicatorDefinition,

  // [GENERIC]
  [genericResources.RESOURCE_PATH]: resourcePathDefinition,
  [genericResources.RESOURCE]: resourceDefinition,
  [genericResources.OBJECT]: objectDefinition,
  [genericResources.STRING]: stringDefinition,
  [genericResources.ENUM]: enumDefinition,

  // [TYPES - No Model]
  Note: noteDefinition,
  SSOProvider: ssoProviderDefinition,
};
