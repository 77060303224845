import React, { useContext, useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useEvidenceDataGrid } from "../../../../../Compliance/Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";
import { getPointOfContact_evidenceList } from "../../../graphql/__pointOfContactGQL";

/**
 * Displays Evidences that are assigned to a Point of Contact
 * @param itemId
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactEvidence = ({ itemId, organizationID }) => {
  const context = useContext(OrganizationContext);
  const module = modules.COMPLIANCE;
  const resource = resources.EVIDENCE;
  const originOrganizationID = context?.role?.ownerGroup;

  const queryHook = useQueryGetItem({
    query: getPointOfContact_evidenceList,
    itemId,
    module,
    resource,
  });

  const queryConfig = {
    query: null,
    organizationID,
  };

  const hiddenFields = ["pointOfContacts"];

  const gridConfig = {
    allowPaging: false,
    gridHeight: "100%",
  };

  const evidenceGrid = useEvidenceDataGrid({
    organizationID: originOrganizationID,
    ...queryConfig,
    ...gridConfig,
    hiddenFields,
  });

  useEffect(() => {
    const evidences = queryHook?.item?.evidences?.items;

    if (Array.isArray(evidences)) {
      const evidences = [];

      for (const evidenceLink of evidences) {
        if (evidenceLink.evidence) {
          evidences.push(evidenceLink.evidence);
        }
      }

      evidenceGrid.setData(evidences);
    }
  }, [queryHook.item]);

  return (
    <DashboardCard title={"Evidence"} isLoading={queryHook.isLoading} icon={"icon-badge"} style={{ height: "100%" }}>
      <div style={{ height: "30em" }}>{evidenceGrid.gridDisplay}</div>
    </DashboardCard>
  );
};

export default withDetailsPage(PointOfContactEvidence);
