import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm";
import VendorImporterModalButton from "../components/VendorImporterModalButton";

/**
 * Hook for creating a single Vendor through the GUI
 *
 * @param {string} organizationID - selected organization
 * @param {function} toggleModal - callback to toggle the modal
 * @param {function} getNewItem - callback for the data after creation
 * @param {string} module - module used for role checking
 * @param {string} resource - resource used for role checking
 * @param {boolean} disableRoleChecking - disable role checking
 * @param {object} ...props - Additional props to pass to the useForm hook
 */
export const useCreateVendor = ({
  organizationID,
  toggleModal,
  getNewItem,
  module = modules.VENDORS,
  resource = resources.VENDOR,
  disableRoleChecking,
  ...props
}) => {
  const typename = "Vendor";

  const { createMutation } = generateGraphql(typename, ["name", "blackKiteCompanyID"]);

  const formHook = useForm({
    mutation: createMutation,
    toggleModal: toggleModal,
    organizationID,
    disableRoleChecking,
    getNewItem,
    module,
    resource,
    typename,
    fieldConfig: {
      name: {
        label: "Vendor Name",
        required: true,
        tooltip: "The name of the company that provides one or more Solutions",
      },
      blackKiteCompanyID: {
        label: "BlackKite Company ID",
        tooltip: "If using a Black Kite integration, this is the ID of the company in Black Kite",
      },
    },
    header: <span>Create a Vendor</span>,
    headerButtons: [<VendorImporterModalButton organizationID={organizationID} />],
    ...props,
  });

  return { ...formHook };
};
