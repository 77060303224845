/**
 * Returns the 'functions' property of a query tree at a given depth in the path
 * @param {object[]} path - path the nested functions
 * @param {object} resultFields - the fields tree which contains the functions to get
 * @param {object} resultFunctions - the functions that are available at current level of nesting
 * @param {number} depth - the depth at which to get the functions following the path
 * @return {unknown[]|*[]|[]|*}
 */
export const getFunctionsAtDepth = ({ path, resultFields, resultFunctions, depth }) => {
  if (typeof resultFields !== "object") {
    return [];
  }

  if (depth === 0) {
    return typeof resultFunctions === "object"
      ? Object.values(resultFunctions).map((func) => {
          return {
            ...func,
            isFunction: true,
          };
        })
      : [];
  }
  if (Array.isArray(path) && path.length > 0) {
    const currentFieldName = path[0].name;
    if (resultFields.hasOwnProperty(currentFieldName)) {
      const currentField = resultFields[currentFieldName];
      return getFunctionsAtDepth({
        path: path.slice(1),
        resultFields: currentField?.fields,
        resultFunctions: currentField?.functions,
        depth: depth - 1,
      });
    } else {
      return [];
    }
  }
  return [];
};
