import DataGridTagFilterMenu from "../components/DataGridTagFilterMenu";

export const tagOperators = [
  {
    label: "All/And",
    value: "and",
    getApplyFilterFn: (filterItem) => {
      return (value) =>
        tagFilterFunctionWrapper({
          value,
          filterItem,
          filterFunction: ({ filterItem, allItemTagIds }) => {
            //Check if any tags selected tags are not present in item
            for (const selectedTag of filterItem.tags) {
              if (!allItemTagIds.hasOwnProperty(selectedTag.id)) {
                return false;
              }
            }
            return true;
          },
        });
    },
    InputComponent: DataGridTagFilterMenu,
  },
  {
    label: "Any/Or",
    value: "or",
    getApplyFilterFn: (filterItem) => {
      return (value) =>
        tagFilterFunctionWrapper({
          value,
          filterItem,
          filterFunction: ({ filterItem, allItemTagIds }) => {
            //Check if any tags selected tags are present in item
            for (const selectedTag of filterItem.tags) {
              if (allItemTagIds.hasOwnProperty(selectedTag.id)) {
                return true;
              }
            }

            return false;
          },
        });
    },
    InputComponent: DataGridTagFilterMenu,
  },
  {
    label: "None/Not",
    value: "not",
    getApplyFilterFn: (filterItem) => {
      return (value) =>
        tagFilterFunctionWrapper({
          value,
          filterItem,
          filterFunction: ({ filterItem, allItemTagIds }) => {
            //Check if all selected tags are not present in the item
            for (const selectedTag of filterItem.tags) {
              if (allItemTagIds.hasOwnProperty(selectedTag.id)) {
                return false;
              }
            }

            return true;
          },
        });
    },
    InputComponent: DataGridTagFilterMenu,
  },
  {
    label: "Only",
    value: "only",
    getApplyFilterFn: (filterItem) => {
      return (value) =>
        tagFilterFunctionWrapper({
          value,
          filterItem,
          filterFunction: ({ filterItem, allItemTagIds }) => {
            //Check if all selected tags are present in the item
            for (const selectedTag of filterItem.tags) {
              if (!allItemTagIds.hasOwnProperty(selectedTag.id)) {
                return false;
              }
            }

            //Check that there are no other tags on the item by comparing tag array lengths
            if (filterItem.tags.length !== Object.keys(allItemTagIds).length) {
              return false;
            }

            return true;
          },
        });
    },
    InputComponent: DataGridTagFilterMenu,
  },
];

/**
 * Reduces amount of duplicated code by consolidating repetitive tag filter login and tag id query
 * @param {object} value - the data of the item being filtered in or out
 * @param {object} filterItem - filter input data
 * @param {function} filterFunction - the function holding logic for the filtering operator
 */
const tagFilterFunctionWrapper = ({ value, filterItem, filterFunction }) => {
  if (Array.isArray(filterItem?.tags) && filterItem.tags.length > 0) {
    const allItemTagIds = {};
    if (Array.isArray(value?.items) && value.items.length > 0) {
      value.items.forEach((itemTagLink) => {
        if (itemTagLink?.tag?.id) {
          allItemTagIds[itemTagLink.tag.id] = true;
        }
      });
    }

    return filterFunction({ filterItem, allItemTagIds });
  } else {
    //if no filter tags then do not filter anything out
    return true;
  }
};
