import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/21/19 JB: Added header signature
 *  - 8/7/19 JB: Added custom graphql
 *
 * Description: An Async Function.
 *              Deletes an exiting ControlEvidenceLink item in the Database.
 *
 */
export const deleteLink = async (itemID, typename) => {
  const { deleteMutation } = generateGraphql(typename);
  return ItemMutation(deleteMutation, {
    id: itemID,
  });
};
