import _ from "lodash";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { roleConfigDefault, updateResourcePermissions } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

/**
 * Updates the {create: bool, read: bool, update: bool, delete: bool} operations on a Resource
 * @param {string} resourceName - the enum string for the resource to update
 * @param {{create: boolean, update: boolean, delete: boolean, read: boolean}} operations - the CRUD operations for the resource
 * @param {function} setRoleConfig - state handler for the updated roleConfig object
 * @param {function} setIsChanged - state handler for setting the isChanged boolean
 */
export const handleResourceChange = (resourceName, operations, setRoleConfig, setIsChanged) => {
  setRoleConfig((roleConfig) => {
    const currentRoleConfig = _.cloneDeep(roleConfig);

    // if the current role config object doesn't have this resource as a key, add it so that the state update can proceed
    if (
      isNullOrUndefined(currentRoleConfig?.resources?.[resourceName]) &&
      !isNullOrUndefined(roleConfigDefault?.resources?.[resourceName])
    ) {
      ErrorLogger(
        `This resource (${resourceName}) is not included as a key in the current roleConfig object. Adding the key to prevent an update error`,
      );
      currentRoleConfig.resources[resourceName] = roleConfigDefault.resources[resourceName];
    }

    const updatedRoleConfig = updateResourcePermissions({
      roleConfig: currentRoleConfig,
      resourceName,
      operations,
    });

    return { ...updatedRoleConfig };
  });
  setIsChanged(true);
};
