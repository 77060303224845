import React from "react";

import { getFunctionFieldName } from "@rivial-security/widget-utils";

import DynamicForm from "../../../../../definitions/components/views/DynamicForm/DynamicForm";

/**
 * A dynamic form for specifying the arguments of a post query data transformation function
 * @param {object[]} path - list of schema resource object that represent the nesting a user took to start creating a function
 * @param {object} functionBeingEdited - the individual function definition object that is being configured
 * @param {object} resultFields - the result fields that are available to the function
 * @param {object} resultFunctions - the result functions that are available to the function
 * @param {function} onFinishEdit - callback on pressing the finish/update button
 * @param {boolean} isNested - TRUE if the UI needs to be visually indented
 * @return {JSX.Element}
 */
const FinalizeFunctionForm = ({
  path,
  functionBeingEdited,
  resultFields,
  resultFunctions,
  onFinishEdit,
  isNested = false,
}) => {
  const editedFunctionParams = functionBeingEdited?.parameters;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {isNested && (
        <div style={{ width: "2em", display: "flex", flexDirection: "row" }}>
          <div style={{ width: "calc(1em - 2px)" }} />
          <div
            style={{
              height: ".7em",
              flex: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "1px",
                height: ".7em",
                backgroundColor: "#BDBDBD",
              }}
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1 }} />
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#BDBDBD",
                }}
              />
            </div>
          </div>
          <div style={{ width: ".5em" }} />
        </div>
      )}
      <div style={{ flex: 1 }}>
        <DynamicForm
          formID={`${getFunctionFieldName({
            pathElement: functionBeingEdited,
          })}_${path.length}`}
          formConfig={{
            disableResetButton: true,
            disableRoleChecking: true,
            item: {
              name: functionBeingEdited?.name,
              ...(typeof functionBeingEdited?.initializeParameters === "function"
                ? {
                    ...functionBeingEdited?.initializeParameters({
                      path,
                      config: functionBeingEdited?.config,
                    }),
                  }
                : { ...functionBeingEdited?.config }),
              query: {
                fields: resultFields,
                functions: resultFunctions,
              },
              queryPath: path,
            },
            submitFunction: (input) => {
              //Remove extra fields (like resultFields and resultFunctions)
              const { query, queryPath, ...functionConfig } = input;
              if (typeof onFinishEdit === "function") {
                onFinishEdit(functionConfig);
              }
            },
          }}
          fields={[
            {
              name: "name",
              friendlyName: "Function Result Name",
              description: "The name that the function result object is going to have in the result query.",
            },
            {
              name: "query",
              isHidden: true,
            },
            {
              name: "queryPath",
              isHidden: true,
            },
            ...(Array.isArray(editedFunctionParams) ? editedFunctionParams : []),
          ]}
        />
      </div>
    </div>
  );
};

export default FinalizeFunctionForm;
