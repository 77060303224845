import { AppAsideToggler } from "@coreui/react";
import Button from "@mui/material/Button";
import React, { useContext } from "react";

import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";

import MeetingPanel from "./MeetingPanelV2";

/**
 * @description Display meeting sidebar component
 * @param {object} item - meeting
 * @param {function} resetFunction - reset state function
 * @param {function} toggleModal - toggle modal function
 * @returns {JSX.Element}
 * @constructor
 */
const MeetingSideBar = ({ item, resetFunction, toggleModal }) => {
  const context = useContext(OrganizationContext);

  return (
    <AppAsideToggler>
      <Button
        className="float-right"
        color="info"
        title={context.sideBar ? "Close Meeting Side Panel" : "Open Meeting Side Panel"}
        onClick={(e) => {
          if (context) {
            if (context.sideBar) {
              context.setSideBar(null);
              e.stopPropagation();
            } else {
              context.setSideBar(<MeetingPanel meeting={item} resetFunction={resetFunction} />);
              toggleModal && toggleModal();
              e.stopPropagation();
            }
          }
        }}
      >
        <i className="icon-login" style={{ marginRight: "0.5em" }} />
        {context.sideBar ? "Close " : "Open "} Panel
      </Button>
    </AppAsideToggler>
  );
};

export default MeetingSideBar;
