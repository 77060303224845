"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutomationSmartValues = void 0;
const getAutomationIdentifier_1 = require("../utils/getAutomationIdentifier");
/**
 * Finds any smart values mentioned in an automation data, returns map of smart values to automation step name/id to its smart values
 * @param {object} automation - all data available for the entire automation
 */
const getAutomationSmartValues = ({ automation }) => {
    // Check if the automation data contains any steps (when all the smart are used)
    const automationSteps = automation?.automations;
    if (!Array.isArray(automationSteps)) {
        return {};
    }
    const automationSmartValues = {};
    for (const automationStep of automationSteps) {
        // Check if automation step has a name
        const automationStepName = (0, getAutomationIdentifier_1.getAutomationStepIdentifier)({ automationStep });
        if (!automationStepName) {
            continue;
        }
        // Convert the entire automation step to a string
        const automationStepString = JSON.stringify(automationStep);
        // Find all the smart values in the automation step by finding all values in between double curly braces
        const smartValues = automationStepString.match(/{{(.*?)}}/g);
        // Check if any smart values were found
        if (!Array.isArray(smartValues) || smartValues.length === 0) {
            automationSmartValues[automationStepName] = [];
            continue;
        }
        // Remove the double curly braces from the smart values
        const smartValuesWithoutBraces = smartValues.map((smartValue) => smartValue.replace(/{{|}}/g, ""));
        // Add the smart values to the map
        automationSmartValues[automationStepName] = smartValuesWithoutBraces;
    }
    return automationSmartValues;
};
exports.getAutomationSmartValues = getAutomationSmartValues;
