import React, { useEffect, useState } from "react";
import { FormFeedback, Input } from "reactstrap";

/**
 * Allow the user to input an email
 * @param {function} onChange - set the value of the input
 * @param {string} value - the value of the input
 * @param {string} fieldName - the name of the input
 * @param {boolean} required - whether the input is required
 * @returns {JSX.Element}
 */
const CustomEmailInput = ({ onChange, value = "", fieldName, required }) => {
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    const emailRegex = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    );
    const results = emailRegex.test(value);
    setValidEmail(results);
  }, [value]);

  return (
    <div data-testid={`custom-email-input-${fieldName}`}>
      <Input
        type="email"
        id="email"
        value={value}
        valid={validEmail === true}
        invalid={validEmail === false}
        onChange={(data) => onChange(data && data.target && data.target.value)}
        required={required}
      />
      <FormFeedback>
        Looks like there is an issue with your email. Please input a correct email. Example: support@rivialsecurity.com
      </FormFeedback>
    </div>
  );
};

export default CustomEmailInput;
