import { isNullOrUndefined } from "@rivial-security/func-utils";

import UpdateSecret from "./UpdateSecret";

/**
 * Displays the 'Secret' field for Tools.
 *
 * If no secret is set, displays a UI to set one.
 * If a secret is set, displays a UI to update it.
 *
 * @param item
 * @param props
 * @constructor
 */
const ToolSecret = ({ item, ...props }) => {
  // check if no secret is set
  if (isNullOrUndefined(item?.secret?.secretId)) {
    return (
      <span title="No Secret is currently set for this Integration.">
        <UpdateSecret text="Add Secret" item={item} {...props} />
      </span>
    );
  } else {
    return (
      <span title="A Secret is currently set for this Integration.">
        <UpdateSecret text="Update Secret" item={item} {...props} />
      </span>
    );
  }
};

export default ToolSecret;
