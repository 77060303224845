import React from "react";
import { Button } from "reactstrap";

/**
 * @typedef EditButtonType
 * @property {function} [onClick] - callback function to be called when button is clicked
 * @property {object} [style] - style object to be applied to the button
 * @property {string} [className] - class name to be applied to the button
 * @property {string} [color] - color of the button
 * @property {string} [size] - size of the button
 * @property {string} [iconClassName] - class name of the icon
 * @property {boolean} [showIcon] - show icon or not
 * @property {string} [typename] - typename of the button
 * @property {string} [buttonText] - text to be displayed on the button
 * @property {string} [title] - title of the button
 * @property {boolean} [stopPropagation] - stop propagation of the event
 * @property {boolean} [disabled] - disable the button
 */

/**
 * @description simple button editor
 * @param {object} EditButtonType
 * @returns {JSX.Element}
 */
const EditButton = ({
  onClick = () => {},
  style = {},
  className = "btn-pill",
  color = "warning",
  size = "sm",
  iconClassName = "icon-pencil",
  showIcon = true,
  typename = "item",
  buttonText,
  title = `Edit${typename ? ` ${typename}` : ""}`,
  stopPropagation = false,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        onClick && onClick();
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
      style={{ ...style }}
      className={className}
      color={color}
      size={size}
      title={disabled ? "Edits are disabled for this resource" : title}
    >
      {showIcon && <i className={iconClassName} style={buttonText ? { marginRight: "1em" } : undefined} />}
      {buttonText}
    </Button>
  );
};

export default EditButton;
