"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleKeyRiskIndicatorChange = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
const handleChange_1 = require("../handleChange");
const getAllSystemsFromKeyRiskIndicator_1 = require("./getAllSystemsFromKeyRiskIndicator");
/**
 * Handles all the logic for calculating a KRI Change
 * @param change
 * @param organizationID
 * @param queryConfig
 */
const handleKeyRiskIndicatorChange = async (change, organizationID, queryConfig) => {
    const { keyRiskIndicator, annualRateOfOccurrenceChange } = change;
    /**
     * Handle KRI Change
     */
    if (keyRiskIndicator?.id) {
        const changeItem = {
            keyRiskIndicator,
            annualRateOfOccurrenceChange,
        };
        /**
         * Query full KRI object with associated systems
         */
        const { getQuery } = (0, generategraphql_1.generateGraphql)("Risk", ["name", "systems"], {
            systems: `
        (limit: 250) {
          items {
            id
            system {
              id
              name
            }
          }
        }
      `,
        });
        const fullKeyRiskIndicator = await (0, appsync_utils_1.GetQuery)({
            query: getQuery,
            variables: { id: keyRiskIndicator.id },
            appsyncEndpoint: process.env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
        });
        const associatedSystems = (0, getAllSystemsFromKeyRiskIndicator_1.getAllSystemsFromKeyRiskIndicator)(fullKeyRiskIndicator);
        return await (0, handleChange_1.handleChange)(associatedSystems, changeItem, organizationID, queryConfig);
    }
    throw new Error("keyRiskIndicator missing ID");
};
exports.handleKeyRiskIndicatorChange = handleKeyRiskIndicatorChange;
