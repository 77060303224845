/**
 * Returns whether or not an item has all of the selected tags
 * @param {object} item - database object (can be a row in a grid) with a tags link field
 * @param {object[]} selectedTags - an array of tag objects that are currently selected (such as in the filter menu)
 * @return {boolean} TRUE if all selected tags are part of the item, FALSE if not
 */
export const itemContainsAllTags = (item, selectedTags) => {
  if (
    item?.tags?.items &&
    Array.isArray(item?.tags?.items) &&
    item?.tags?.items.length > 0 &&
    selectedTags &&
    Array.isArray(selectedTags) &&
    selectedTags.length > 0
  ) {
    for (const tag of selectedTags) {
      const foundMatch = item?.tags?.items.findIndex((item) => {
        if (item?.tag?.id && tag?.id) {
          return tag.id === item.tag.id;
        } else {
          return false;
        }
      });

      //If at least one tag is not found in the item the AND condition is not satisfied
      if (foundMatch === -1) {
        return false;
      }
    }

    //Returns true when all tags were found in the item
    return true;
  } else {
    return false;
  }
};
