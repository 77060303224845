import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useKeyRiskIndicatorsGrid } from "../hooks/useKeyRiskIndicatorsGrid";

/**
 * Component instance of the useKeyRiskIndicatorsGrid
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const KeyRiskIndicatorsGrid = (props) => useKeyRiskIndicatorsGrid(props).display;

export default withOrganizationCheck(KeyRiskIndicatorsGrid);
