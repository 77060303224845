/**
 * Runs the optional callback functions
 *
 * @param {function} [updateItemById] - function that updates the item in the grid
 * @param {function} [resetFunction] - function to reset the parent state
 * @param {object} updatedItem - the updated item
 * @param {function} callback - the callback function
 */
export const handleCallbacks = ({ updateItemById, resetFunction, updatedItem, callback }) => {
  callback && callback(updatedItem);
  if (typeof updateItemById === "function") {
    updateItemById(updatedItem);
  } else if (typeof resetFunction === "function") {
    resetFunction();
  }
};
