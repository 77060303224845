/**
 * Constructs the initial mutation input for the DB operation
 *
 * @param {object} item - the item that is being mutated
 * @param {string} item.id - the ID of the item that is being mutated
 * @param {string} field - the name of the field that is being mutated
 * @param {string | number | boolean}finalValue
 * @param {string} [fieldOverride] - optional, used to override the field name
 */
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const handleMutationInput = ({ item, fieldOverride, field, finalValue }) => {
  if (isNullOrUndefined(item?.id)) {
    throw Error("item.id cannot be null or undefined");
  }

  if (isNullOrUndefined(field)) {
    throw Error("field param cannot be null or undefined");
  }

  if (typeof field !== "string") {
    throw Error("field param must be a string");
  }

  if (!isNullOrUndefined(fieldOverride) && typeof fieldOverride !== "string") {
    throw Error("if using fieldOverride, it must be a string");
  }

  const fieldInput = !isNullOrUndefined(finalValue) ? finalValue : null;

  return {
    id: item.id,
    [fieldOverride || field]: fieldInput,
  };
};
