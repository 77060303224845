import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Updates the link between an Information system and a vendor solution (also makes all non-standard controls non-outsourced)
 * @param {object} system - the system to link a vendor solution to (needs id field)
 * @param {object} vendorSolution - the vendor solution to link to the system, if null will unlink any connection
 * @param {function} [toggleModal] - called to close containing modal if any once update begins
 * @param {function} [resetFunction] - called to reset data after update
 * @return {Promise<void>}
 */
export const updateSystemVendorSolution = async ({ system, vendorSolution, toggleModal, resetFunction }) => {
  const { updateMutation } = generateGraphql("System");

  if (isNullOrUndefined(vendorSolution)) {
    //Iterate over all system risk control overrides and mark all controls as not outsourced
    const riskControlOverrides = system?.riskControlOverrides;
    if (Array.isArray(riskControlOverrides)) {
      const newRiskControlOverrides = [];
      for (const riskControlOverride of riskControlOverrides) {
        newRiskControlOverrides.push({
          ...riskControlOverride,
          outsourced: false,
        });
      }
      const { updateMutation } = generateGraphql("System");
      await ItemMutation(updateMutation, {
        id: system?.id,
        riskControlOverrides: newRiskControlOverrides,
      });
    }
  }

  toggleModal && toggleModal();

  await ItemMutation(updateMutation, {
    id: system?.id,
    vendorSolutionID: vendorSolution?.id ? vendorSolution.id : null,
  });

  resetFunction && resetFunction();
};
