import React from "react";

/**
 * @description Display date when a document was uploaded
 * @param {object} item - document object
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentUploadDate = ({ item }) => {
  const formattedDate = new Date(item?.createdAt).toLocaleString();
  return <div>{formattedDate}</div>;
};

export default DocumentUploadDate;
