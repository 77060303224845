import React from "react";

import { modules, resources } from "@rivial-security/role-utils";
import { ActionItemStatus } from "@rivial-security/schema-types";

import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import PriorityFilterMenu from "../../../../utils/FilterMenus/PriorityFilterMenu";
import { pointOfContactName } from "../../../../utils/Functions/pointOfContactName";
import ActionItemDetails from "../components/ActionItemDetails";
import CreateAction from "../components/CreateAction";
import ActionPointOfContact from "../customFields/ActionPointOfContact";
import DueDate from "../customFields/DueDate";
import Priority from "../customFields/Priority";
import Status from "../customFields/Status";
import { createActionItemTagLink } from "../functions/createActionItemTagLink";
import { deleteActionItem } from "../functions/deleteActionItem";

/**
 * A list of ActionItems for an Organisation
 * @param {string} organizationID - the ID of the Organisation to display ActionItems for
 * @param {object} gridConfig - grid settings to override defaults
 * @param {object} cardConfig - grid wrapper settings to override defaults
 * @param {object} initQueryConfig - grid query settings to override defaults
 * @param {string[]} hiddenFields - the fields to hide from the grid even if they are shown by default
 * @param {string} module - the platform module to use for frontend role checks
 * @param {object} props - additional props to pass down to useDataGrid
 * @param {boolean} hideCompleted - whether to hide completed ActionItems by default
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object, setData: (value: (((prevState: Object) => Object) | Object)) => void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, selectedIDs: [], setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useActionDataGrid = ({
  organizationID,
  gridConfig = {},
  cardConfig = {},
  queryConfig: initQueryConfig = {},
  hiddenFields = [],
  module = modules.GOVERNANCE,
  hideCompleted = false,
  ...props
}) => {
  const typename = resources.ACTION_ITEM;
  const resource = resources.ACTION_ITEM;

  initQueryConfig = {
    indexName: "actionsByOwnerGroup",
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    ...initQueryConfig,
  };

  const { queryConfig, updateMutation } = getResourceQueryData({
    fieldContext: fieldContexts.GRID,
    overrideQueryConfig: initQueryConfig,
    typename,
  });

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 250,
      bulkEdit: true,
    },
    {
      name: "pointOfContact",
      component: <ActionPointOfContact />,
      editType: "DropDownList",
      disablePropagation: true,
      visible: !hiddenFields?.includes("pointOfContact"),
      friendlyName: "Contact",
      width: 150,
      valueGetter: (value) => pointOfContactName(value),
      searchKeys: ["pointOfContact.firstName", "pointOfContact.lastName"],
    },
    {
      name: "status",
      component: <Status />,
      disablePropagation: true,
      bulkEdit: true,
      width: 150,
      sort: {
        direction: "asc",
        priority: 2,
      },
      type: "singleSelect",
      valueOptions: [
        {
          value: "pastDue",
          label: "Past Due",
        },
        {
          value: "inProgress",
          label: "In Progress",
        },
        {
          value: "onHold",
          label: "On Hold",
        },
        {
          value: "proposed",
          label: "Proposed",
        },
        {
          value: "complete",
          label: "Complete",
        },
      ],
      sortComparer: (a, b) => {
        const status = {
          pastDue: 1,
          inProgress: 2,
          onHold: 3,
          proposed: 4,
          complete: 5,
        };
        return status[a] - status[b];
      },
      filters: hideCompleted ? [{ operator: "not", value: ActionItemStatus.COMPLETE }] : undefined,
    },
    {
      name: "dueDate",
      component: <DueDate />,
      disablePropagation: true,
      width: 150,
      type: "date",
    },
    {
      name: "priority",
      type: "number",
      width: 150,
      filterTemplate: (props) => <PriorityFilterMenu props={props} />,
      component: <Priority />,
      sort: {
        direction: "desc",
        priority: 1,
      },
      valueFormatter: (value) => {
        return value || "None";
      },
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createActionItemTagLink,
      width: 200,
      bulkEdit: true,
    },
    {
      field: "enableNotifications",
      bulkEdit: true,
      hidden: true,
      minWidth: 100,
      inputType: "switch",
    },
  ];

  cardConfig = {
    title: cardConfig?.title || "Action Items",
    headerIcon: cardConfig?.headerIcon || "icon-rocket",
    ...cardConfig,
  };

  gridConfig = {
    fields,
    createResourceComponent: <CreateAction module={props?.module} />,
    module,
    resource,
    options: ["details", "delete", "edit"],
    deleteFunction: deleteActionItem,
    deleteOptions: {
      deleteRecommendations: {
        label: "Delete Recommendations",
        tooltip:
          "This will delete all recommendations associated with this action item. If the recommendation is associated with other action items, it will not be deleted.",
        defaultValue: false,
        inputType: "switch",
      },
      deleteObservations: {
        label: "Delete Observations",
        tooltip:
          "When deleting Recommendations associated with this Action Item, delete Observations associated with those Recommendations. If the Observation is associated with other Recommendations, it will not be deleted.",
        defaultValue: false,
        inputType: "switch",

        isHidden: ({ input }) => !input?.deleteRecommendations,
      },
    },
    detailsComponent: <ActionItemDetails tableDisplay={true} />,
    updateMutation,
    detailsTitle: "Action Item Details",
    persistenceUUID: "eefb30c5-d07a-47a8-ab68-fc633591a2f0",
    enablePersistence: true,
    organizationID,
    typename,
    ...gridConfig,
    ...props,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
  });

  return {
    ...gridCard,
  };
};
