import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * The 'enabled' field for Automations cooldowns
 * Must be true for automations to trigger
 * @param {object} item - the automation item
 * @param {string} organizationID - the organization ID
 * @param {object} ...props - all other props passed to generic edit field
 * @returns {JSX.Element}
 */
const AutomationCooldownEnabled = ({ item, organizationID, ...props }) => {
  const { updateMutation } = generateGraphql("Automation", ["enableCooldown"]);

  return (
    <GenericEditFieldV3
      organizationID={organizationID}
      resource={resources.AUTOMATION}
      module={modules.ORGANIZATION_MANAGER}
      typename={"Automation"}
      item={item}
      field="enableCooldown"
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      mutation={updateMutation}
      {...props}
      resetFunction={null}
      updateItemById={null}
    />
  );
};

export default withOrganizationCheck(AutomationCooldownEnabled);
