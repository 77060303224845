import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { InvalidParamError } from "../../../../analytics/CustomError";
import { duplicateItem } from "../../../../utils/Functions/Graphql/duplicateItem";

import { getControlCategoryTemplates } from "./getControlCategoryTemplates";

/**
 * Creates and attaches Control Categories and SubControls to a Vendor Review.
 * First queries control category templates then duplicates them to the Vendor Review
 * @param {string} organizationID - the organizationID to create these control categories for
 * @param {object} vendorReview - the vendorReview to attach categories to
 * @param {string} vendorReview.id - the vendorReview ID to attach categories to
 */
export const setupControlCategories = async (organizationID, vendorReview) => {
  if (isNullOrUndefined(organizationID)) {
    throw new InvalidParamError("organizationID", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorReview)) {
    throw new InvalidParamError("vendorReview", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorReview.id)) {
    throw new InvalidParamError("vendorReview.id", "cannot be null or undefined");
  }

  // get control category templates to duplicate into this review
  const controlCategories = await getControlCategoryTemplates(organizationID);

  const promises = [];

  // update vendor review with control categories
  for (const controlCategoryTemplate of controlCategories) {
    const createControlCategoryPromise = duplicateItem({
      item: controlCategoryTemplate,
      organizationID,
      input: {
        vendorReviewID: vendorReview.id,
      },
      typename: "VendorControlCategory",
    }).then(async (newControlCategory) => {
      // create all subControls and attach them to this control category
      if (!isNullOrUndefined(controlCategoryTemplate?.subControls?.items)) {
        for (const subControlTemplate of controlCategoryTemplate.subControls.items) {
          try {
            await duplicateItem({
              item: subControlTemplate,
              organizationID,
              input: {
                vendorControlCategoryID: newControlCategory.id,
                inPlace: false,
                audited: false,
                vendorControlTemplateID: subControlTemplate?.id,
              },
              typename: "VendorSubControl",
            });
          } catch (e) {
            // mutation error, going to just keep going
            ErrorLogger("Could not create Control Category for Vendor Review", e);
          }
        }
      }
    });

    promises.push(createControlCategoryPromise);
  }

  return Promise.allSettled(promises);
};
