import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates the text string for the Phishing Test notes for a single recipient
 * @param recipient
 * @param recipient.opened_at
 * @param recipient.clicked_at
 * @param recipient.reported_at
 * @returns {string}
 */
export const formatPhishingNotes = (recipient) => {
  const isOpened = !isNullOrUndefined(recipient?.opened_at);
  const isClicked = !isNullOrUndefined(recipient?.clicked_at);
  const isReported = !isNullOrUndefined(recipient?.reported_at);

  let res = ``;

  if (isOpened && isClicked && isReported) {
    res = "Opened/Clicked/Reported";
  } else if (isOpened && isClicked && !isReported) {
    res = "Opened/Clicked";
  } else if (isOpened && !isClicked && !isReported) {
    res = "Opened";
  } else if (isOpened && !isClicked && isReported) {
    res = "Opened/Reported";
  } else if (!isOpened && !isClicked && isReported) {
    res = "Reported";
  } else {
    res = "No Action";
  }

  return res;
};
