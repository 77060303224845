/**
 * Returns the 'fields' property of a query tree at a given depth in the path
 * @param {object[]} path - the path to travel in the tree, represented as object of schema resource objects
 * @param {object} resultFields - the fields available at current level of nesting in the query stored as field name to object map
 * @param {number} depth - the depth in the path for which to get fields, if 0 that is the base condition, get the fields in resultFields
 * @return {object[]}
 */
export const getFieldsAtDepth = ({ path, resultFields, depth }) => {
  if (typeof resultFields !== "object") {
    return [];
  }
  if (depth === 0) {
    return typeof resultFields === "object" ? Object.values(resultFields) : [];
  }
  if (Array.isArray(path) && path.length > 0) {
    const currentFieldName = path[0].name;
    if (resultFields.hasOwnProperty(currentFieldName)) {
      const currentField = resultFields[currentFieldName];
      return getFieldsAtDepth({
        path: path.slice(1),
        resultFields: currentField?.fields,
        depth: depth - 1,
      });
    } else {
      return [];
    }
  }
  return [];
};
