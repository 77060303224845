export const listKnowBe4PhishingTestResults = /* GraphQL */ `
  query ListKnowBe4PhishingTestResults($query: QueryListKnowBe4PhishingTestResultsQueryInput!) {
    listKnowBe4PhishingTestResults(query: $query) {
      recipient_id
      pst_id
      scheduled_at
      delivered_at
      opened_at
      clicked_at
      replied_at
      attachment_opened_at
      macro_enabled_at
      data_entered_at
      vulnerable_plugins_at
      exploited_at
      reported_at
      bounced_at
      ip
      ip_location
      browser
      browser_version
      os
      user {
        first_name
        last_name
        email
      }
    }
  }
`;
