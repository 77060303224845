import React from "react";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useLowerLossAmount } from "../functions/useLowerLossAmount";

/**
 * Displays the lower confidence interval for a KRI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LowerLossAmount = ({ system, systemRiskLink, organizationID }) => {
  const lowerLossAmountHook = useLowerLossAmount(system, organizationID, systemRiskLink);

  return <span>{lowerLossAmountHook.display}</span>;
};

export default withOrganizationCheck(LowerLossAmount);
