/**
 * Determines if a grid is of the select templates type based on its id
 * @param {string} gridPersistenceUUID - the grid id
 * @returns {boolean} - will return true if the grid is of the select templates type
 */
export const isInSelectTemplatesGrid = ({ gridPersistenceUUID }) => {
  let isInSelectTemplatesGrid;
  try {
    isInSelectTemplatesGrid =
      gridPersistenceUUID.includes("select-") && gridPersistenceUUID.includes("-templates-grid");
  } catch (e) {
    isInSelectTemplatesGrid = false;
  }

  return isInSelectTemplatesGrid === true;
};
