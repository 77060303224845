import { getResourceAttribute } from "../getResourceAttribute";

/**
 * Determines a platform route for a details page of the resource being queried by the custom query
 * @param {object} query - the custom query data
 * @param {object} [queryConfig] - optional query config to use if full query object is not available
 * @returns {string} - the route to the details page not including id
 */
export const getQueryDetailsRoute = ({ query, queryConfig }) => {
  //Find the selected query typename
  const rootFields = query?.queryConfig?.fields || queryConfig?.fields;
  const recordedTypename = query?.queryConfig?.typename || queryConfig?.typename;
  let rootTypename;
  if (typeof rootFields === "object") {
    rootTypename =
      Array.isArray(Object.keys(rootFields)) && Object.keys(rootFields).length > 0
        ? Object.keys(rootFields)[0]
        : recordedTypename;
  }
  //Check if the found typename has a details route
  let newQueryRoute;
  if (rootTypename) {
    newQueryRoute = getResourceAttribute({
      typename: rootTypename,
      attribute: "routes.details",
    });
  }

  //Retrieve the current url prefix before the # and merge found route
  const currentUrlPrefix = window.location.href.split("/#/")[0];
  let finalRoute;
  if (currentUrlPrefix && newQueryRoute) {
    finalRoute = `${currentUrlPrefix}/#/${newQueryRoute}`;
  } else {
    finalRoute = null;
  }

  return finalRoute;
};
