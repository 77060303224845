import React from "react";

/**
 * @description JSON file
 * @param {object} data - JSON data
 * @param {object} children - Children
 * @returns {JSX.Element}
 * @constructor
 */
const Json = ({ data, children }) => {
  return <pre>{data ? JSON.stringify(data, null, 2) : JSON.stringify(children, null, 2)}</pre>;
};

export default Json;
