import { isNonEmptyArray } from "@rivial-security/func-utils";
import { PointOfContact, QuestionnaireStatus, QuestionnaireSystemLink, System } from "@rivial-security/schema-types";

import { autoAssignQuestionnaireToSystemContacts } from "@views/OrganizationManager/Questionnaires/functions/autoAssignQuestionnaireToSystemContacts";
import { unassignQuestionnaireAssignee } from "@views/OrganizationManager/Questionnaires/functions/unassignQuestionnaireAssignee";

export enum SystemContactRole {
  Admin = "Admin",
  Owner = "Owner",
}
export interface HandleSystemContactAssignmentParams {
  system: System;
  role: SystemContactRole;
  pointOfContact: PointOfContact;
  isUnassign?: boolean;
}

export const handleSystemContactAssignment = async ({
  system,
  role,
  pointOfContact,
  isUnassign = false,
}: HandleSystemContactAssignmentParams): Promise<void> => {
  const systemQuestionnaires =
    system?.questionnaires?.items.filter((link): link is QuestionnaireSystemLink => link != null) ?? [];
  const organizationID = system?.ownerGroup ?? "";

  if (!isNonEmptyArray(systemQuestionnaires)) {
    return;
  }

  if (isUnassign) {
    await handleUnassignment({ systemQuestionnaires, pointOfContact, role, system });
  } else {
    await handleAssignment({ systemQuestionnaires, organizationID, role, pointOfContact, system });
  }
};

interface HandleUnassignmentParams {
  systemQuestionnaires: QuestionnaireSystemLink[];
  pointOfContact: PointOfContact;
  role: SystemContactRole;
  system: System;
}

const handleUnassignment = async ({
  system,
  systemQuestionnaires,
  pointOfContact,
  role,
}: HandleUnassignmentParams): Promise<void> => {
  await unassignQuestionnaireAssignee({
    systemQuestionnaires,
    pointOfContact,
    role,
    system,
  });
};

interface UnassignPreviousContactParams {
  system: System;
  systemQuestionnaires: QuestionnaireSystemLink[];
  role: SystemContactRole;
}

const unassignPreviousContact = async ({
  system,
  role,
  systemQuestionnaires,
}: UnassignPreviousContactParams): Promise<void> => {
  const previousContact = role === SystemContactRole.Admin ? system.adminPointOfContact : system.pointOfContact;

  if (previousContact) {
    await unassignQuestionnaireAssignee({
      systemQuestionnaires,
      pointOfContact: previousContact,
      role,
      system,
    });
  }
};

interface HandleAssignmentParams extends HandleUnassignmentParams {
  organizationID: string;
}

const handleAssignment = async ({
  system,
  systemQuestionnaires,
  organizationID,
  role,
  pointOfContact,
}: HandleAssignmentParams): Promise<void> => {
  await unassignPreviousContact({ system, role, systemQuestionnaires });
  for (const link of systemQuestionnaires) {
    const shouldAutoAssignContactToQuestionnaire = link?.questionnaire?.status !== QuestionnaireStatus.DONE;

    if (shouldAutoAssignContactToQuestionnaire) {
      await autoAssignQuestionnaireToSystemContacts({
        organizationID,
        questionnaireID: link?.questionnaire?.id ?? "",
        systemAdmin: role === SystemContactRole.Admin ? pointOfContact : null,
        systemOwner: role === SystemContactRole.Owner ? pointOfContact : null,
      });
    }
  }
};
