import { usePolicyDataGrid } from "../../../../views/Program/Policies/hooks/usePolicyDataGrid";

export const policyDefinition = {
  routes: {
    grid: "governance/policies",
    details: "governance/review_policy",
    helpCenter: "policies",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:docs",

  //[COMPONENTS]
  gridHook: usePolicyDataGrid,
};
