import React from "react";

import { useEventListener } from "../../hooks/functional/useEventListener";

/**
 * @description A generic component that can be used to create an uncontrolled accordion icon.
 * @param {function} toggler - The function that will be called when the icon is clicked.
 * @returns {JSX.Element}
 * @constructor
 */
const UncontrolledAccordionIcon = ({ toggler }) => {
  const addEvent = () => {
    if (document.getElementById(`${toggler}Arrow`).classList.contains("icon-arrow-left")) {
      document.getElementById(`${toggler}Arrow`).classList.remove("icon-arrow-left");
      document.getElementById(`${toggler}Arrow`).classList.add("icon-arrow-down");
    } else if (document.getElementById(`${toggler}Arrow`).classList.contains("icon-arrow-down")) {
      document.getElementById(`${toggler}Arrow`).classList.remove("icon-arrow-down");
      document.getElementById(`${toggler}Arrow`).classList.add("icon-arrow-left");
    }
  };

  useEventListener("click", addEvent, document.getElementById(toggler));

  return <i style={{ color: "lightGrey" }} id={`${toggler}Arrow`} className={"icon-arrow-left" + " float-sm-right"} />;
};

export default UncontrolledAccordionIcon;
