import { intersection } from "@rivial-security/func-utils";
/**
 *
 * Returns the current number of checked items.
 * This is the intersection of the 'all items' array and the 'checked items' array
 *
 * @param {object[]} items - all of the items
 * @param {object[]} checked - the currently selected items
 * @returns {*}
 */
export const numberOfChecked = (items, checked) => intersection(checked, items).length;
