import React from "react";
import { Badge } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useEditIncidentClassification } from "../hooks/useEditIncidentClassification";

/**
 * Custom Field component for Incident.classificationTier
 * @param {object} item - incident data that uses the clarificationTier
 * @param {object } classificationTier - classification tier data to show, needs at least the 'name' property
 * @param {string} organizationID - currently selected organization identifier
 * @param {function} resetFunction - callback to re-query 'item' and 'classificationTier'
 * @returns {JSX.Element}
 */
const ClassificationTier = ({ item, classificationTier, organizationID, resetFunction }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;
  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const editClassification = useEditIncidentClassification({
    organizationID,
    item,
    resetFunction,
  });

  return (
    <span>
      {!isNullOrUndefined(classificationTier?.name) ? (
        <span>
          <span style={{ fontWeight: "bold" }}>{classificationTier?.name}</span>
          {classificationTier?.description && ` - ${classificationTier?.description}`}
          {classificationTier?.level && (
            <Badge color={"primary"} style={{ margin: "0em 1em" }}>
              Level {classificationTier?.level}
            </Badge>
          )}
        </span>
      ) : (
        <span style={{ marginRight: "1em" }}>No classification tier selected</span>
      )}
      {checkPermissionsHook.resource.update && editClassification.modalButton}
    </span>
  );
};

export default ClassificationTier;
