import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useIncidentDataGrid } from "../hooks/useIncidentDataGrid";

/**
 * Component allowing to choose a single incident
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectIncident = ({ organizationID, selectButtonCallback } = {}) => {
  const grid = useIncidentDataGrid({
    organizationID,
    gridConfig: {
      createItemComponent: null,
      enableSelectButton: true,
      onSelectCallback: selectButtonCallback,
    },
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(SelectIncident);
