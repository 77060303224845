"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateROI = void 0;
const calculateNetProfit_1 = require("./calculateNetProfit");
/**
 * Calculates the Return on Investment. Returns a decimal representing a percentage
 *
 * @function
 * @param {number} inherentRisk - dollar amount representing the loss before controls
 * @param {number} residualRisk - dollar amount representing the loss after controls
 * @param {number} costOfControls - dollar amount representing total cost of controls
 * @returns {number} - decimal representing a percent Return on Investment
 */
const calculateROI = (inherentRisk, residualRisk, costOfControls) => {
    if (!costOfControls || costOfControls === 0)
        return 0;
    const netProfit = (0, calculateNetProfit_1.calculateNetProfit)(inherentRisk, residualRisk, costOfControls);
    return netProfit / costOfControls; // Multiplied by 1.0 to ensure that integer division cannot occur.
};
exports.calculateROI = calculateROI;
