import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
// import {GetQuery} from "@rivial-security/appsync-utils";

/**
 * TODO: convert to use 'byOwnerGroup' query
 * Fetches a list of Automations for an Org
 * @param {string} organizationID - the ownerGroup to fetch all Automations for
 */
export const getAutomation = async ({ id }) => {
  try {
    const fields = [
      "name",
      "triggers",
      "conditions",
      "automations",
      "itemId",
      "enabled",
      "enableCooldown",
      "cooldownPeriod",
      "lastRunDate",
    ];
    const nestedFields = {
      triggers: `{
        type
      }`,
      conditions: `{
        graphql
        filters
        typename
        fields
        rule
      }`,
      automations: `{
        id
        name
        type
        config
      }`,
    };

    const { getQuery } = generateGraphql("Automation", fields, nestedFields);

    const automation = await GetQuery({
      query: getQuery,
      variables: {
        id,
      },
    });

    return automation;
  } catch (e) {
    ErrorLogger("Could not get Automation for OrganizationID!", e);
  }
};
