"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNullOrUndefined = void 0;
/**
 * Returns true if input is null or undefined, otherwise false
 */
const isNullOrUndefined = (arg) => {
    return arg === null || arg === undefined;
};
exports.isNullOrUndefined = isNullOrUndefined;
