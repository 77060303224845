import React from "react";
import { Alert } from "reactstrap";

import { LoadingSpinner } from "../../../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * Displays an alert that system monte carlo calculations are currently running
 * @param {boolean} isOutdated - calculations are outdated compared to inputs, need to refresh calculation
 * @param {boolean} isLoading - calculations are currently running
 * @param {function} resetFunction - resets system details
 * @returns {JSX.Element}
 * @constructor
 */
const SystemLoadingAlert = ({ isOutdated, isLoading, resetFunction }) => {
  /**
   * Calls the resetFunction only if it's not currently loading
   */
  const onClick = () => {
    if (!isLoading && resetFunction && typeof resetFunction === "function") {
      resetFunction();
    }
  };

  return (
    <span>
      {(isOutdated || isLoading) && (
        <Alert
          color="primary"
          style={{
            cursor: !isLoading ? "pointer" : undefined,
          }}
          onClick={onClick}
        >
          {isLoading ? (
            <>
              Running Real-Time Risk Calculations.. <LoadingSpinner />
            </>
          ) : (
            <>An Input has changed, click here to re-run Real-Time Risk calculations.</>
          )}
        </Alert>
      )}
    </span>
  );
};

export default SystemLoadingAlert;
