import { isNullOrUndefined } from "@syncfusion/ej2-base";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useNestedDataCard } from "../../../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import CreateEvidence from "../../../../../../Compliance/Evidence/components/CreateEvidence";
import EvidenceDataGrid from "../../../../../../Compliance/Evidence/components/EvidenceDataGrid";
import { useEvidenceDataGrid } from "../../../../../../Compliance/Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";
import { createRiskControlEvidenceLink } from "../functions/createRiskControlEvidenceLink";
import { deleteRiskControlEvidenceLink } from "../functions/deleteRiskControlEvidenceLink";

/**
 * Displays a grid of Evidence attached to a Risk Control in a m-m connection
 * @param {object} item - the Risk Control to show Evidence for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const RiskControlEvidence = ({ item, organizationID }) => {
  const { getQuery } = generateGraphql("RiskControl", ["evidences"], {
    evidences: `(limit: 1000) {
      items {
        id
        system {
          id
          name
        }
        evidence {
          id
          enabled
          ownerGroup
          name
          status
          type
          frequency
          task
          allowUnauthenticatedSubmissions
          pointOfContacts (limit: 100) { items { id pointOfContact { id firstName lastName email title user { id } actions(limit: 100) { items { id name status createdAt data } } } } }
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        }
      }
    }`,
  });

  const dataCard = useNestedDataCard({
    item,
    query: getQuery,
    field: "evidences",
    connectionField: "evidence",
    typename: "Evidence",
    parentTypename: "RiskControl",
    grid: (
      <EvidenceDataGrid
        sx={{
          dashboard: {
            container: {
              height: "60vh",
            },
          },
        }}
      />
    ),
    gridHook: useEvidenceDataGrid,
    form: <CreateEvidence noHeader={true} />,
    createFunction: createRiskControlEvidenceLink,
    deleteFunction: async (link) => {
      await deleteRiskControlEvidenceLink(link, null, organizationID);
    },
    flipCreateParams: true,
    normalizeData: (items) => {
      //remove any evidence link that has a system defined (since it's a non-standard control linking)
      return items?.filter((item) => {
        if (item?.link && !item?.link?.system?.id) {
          if (!isNullOrUndefined(item?.link?.evidence)) {
            return true;
          }
        }
      });
    },
    organizationID,
  });

  return <div style={{ height: "30em" }}>{dataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(RiskControlEvidence);
