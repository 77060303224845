import { getObjectPropertyArray } from "./getObjectPropertyArray";

/**
 * @typedef {object} GetObjectPropertyStringInput
 * @property {object[]} array - the array of objects to reduce
 * @property {string[]} [propertyName=["name", "field"]] - the name of the property to return, defaults to "name" or "field"
 * @property {boolean} [ignoreNull=false] - if true, will not include null values in the returned string
 * @property {string[][]} [propertyNames] - if provided and propertyName is not, multiple properties from each object will be used to create a single key for the row
 */

/**
 * Reformats a complex object into a comma delimited string by taking specific properties from each object in the array
 * - useful for dependency arrays with complex objects
 * @param {GetObjectPropertyStringInput} input - input object
 * @return {string} - accumulation of a single property from each item in an array of objects, formatted as a comma delimited string
 */
export const getObjectPropertyString = ({
  array,
  propertyName = ["name", "field"],
  propertyNames,
  ignoreNull = false,
}) => {
  if (!Array.isArray(array)) {
    return "";
  }

  return getObjectPropertyArray({
    array,
    propertyName,
    propertyNames,
    ignoreNull,
  }).join(",");
};
