/**
 * Created from this guide: https://javascript.info/custom-errors
 */

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * A Custom Error type, used as a base for more granular errors
 */
class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

/**
 * Error for when a required function parameter is invalid.
 *
 * @example
 * const parseString = (str) => {
 *   if (string === null || string === undefined || typeof(str) !== 'string) {
 *     throw InvalidParamError("str", "str param can't be null")
 *   }
 * }
 */
export class InvalidParamError extends CustomError {
  constructor(param, message = "", filename) {
    let msg = "";

    if (!isNullOrUndefined(filename)) {
      msg += `[${filename}] `;
    }

    msg += `Invalid Parameter: ${param}. ${message}`;

    super(msg);
    this.errorMessage = msg;
  }
}

/**
 * Error for when a general Graphql issue happens.
 * Try and use MutationError or QueryError if possible
 */
class GraphqlError extends CustomError {
  constructor(errorMessage) {
    super(`GraphQL Error: The GraphQL operation failed. ${errorMessage}`);
    this.errorMessage = errorMessage;
  }
}

/**
 * Error for when a Graphql mutation error happens.
 *
 * @example
 * const createItem = () => {
 *   try {
 *     createFoo("bar")
 *   }
 *   catch (e) {
 *     throw MutationError("Could not create a Foo", e)
 *   }
 * }
 */
export class MutationError extends GraphqlError {
  constructor(errorMessage, error) {
    super(`Mutation Error: The Mutation operation failed. ${errorMessage}`, error);
    this.errorMessage = errorMessage;
    this.error = error;
  }
}
