import { RISK_CHANGE_TYPE } from "../../../../constants/riskChangeType";

import { submitControlRiskChange } from "./submitControlRiskChange";
import { submitManualRiskChange } from "./submitManualRiskChange";

/**
 * Submits a risk change to the backend
 * @param {object} input - risk change form input
 * @param {string} organizationID - the currently selected organization
 * @param {object} system - the system for which the change is being made (if any, only manual changes)
 * @param {object} riskRecommendationData - useRiskRecommendationData hook instance
 * @returns {Promise<void>}
 */
export const submitRiskChange = async ({ input, organizationID, system, riskRecommendationData }) => {
  if (input?.type === RISK_CHANGE_TYPE.MANUAL_ENTRY) {
    // Handle manual entry without going through lambda
    await submitManualRiskChange({ input, organizationID, system });
  } else {
    // Handle risk control change through a graphql lambda call
    await submitControlRiskChange({
      input,
      organizationID,
      riskRecommendationData,
    });
  }
};
