import React from "react";

import { tryParse } from "@rivial-security/func-utils";

import { DetailsTable } from "../../../../hooks/views/useDetails/hooks/useDetails";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NumberChange from "../../../../utils/CustomFields/NumberChange";
import EditButton from "../../../../utils/GenericComponents/buttons/EditButton";
import EditRiskRecommendation from "../../../Risk/Recommendations/components/EditRiskRecommendation";
import RiskChangeTable from "../../../Risk/Recommendations/components/RiskChangeTable/RiskChangeTable";
import AssociatedControl from "../../../Risk/Recommendations/customFields/AssociatedControl";
import ChangeInCost from "../../../Risk/Recommendations/customFields/ChangeInCost";
import ChangeInImplementation from "../../../Risk/Recommendations/customFields/ChangeInImplementation";
import { RiskRecommendationResolved } from "../../../Risk/Recommendations/customFields/RiskRecommendationResolved";

/**
 * Component for displaying the details of a single Risk Recommendation
 * @param data
 * @param item
 * @param organizationID
 * @param toggleModal
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const RiskRecommendationDetails = ({ data, item, organizationID, toggleModal, resetFunction }) => {
  const fields = [
    "riskControl",
    "name",
    "costChange",
    "implementationChange",
    "residualChange",
    "returnOnInvestment",
    "resolved",
  ];

  const customFields = [
    {
      field: "riskControl",
      component: <AssociatedControl />,
    },
    {
      field: "costChange",
      component: <ChangeInCost />,
    },
    {
      field: "returnOnInvestment",
      component: <NumberChange format="percent" />,
      propName: "value",
    },
    {
      field: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "implementationChange",
      component: <ChangeInImplementation />,
    },
    {
      field: "associatedControl",
      propName: "riskControl",
      component: <AssociatedControl />,
    },
    {
      field: "resolved",
      component: <RiskRecommendationResolved />,
    },
  ];

  const fieldNameDictionary = {
    name: "Recommendation Name",
    costChange: "Change in Cost",
    implementationChange: "Change in Implementation Rating",
    associatedControl: "Associated Risk Control",
    resolved: "Status",
    returnOnInvestment: "Return on Investment",
    residualChange: "Risk Mitigated",
  };

  const detailsConfig = {
    fields,
    customFields,
    fieldNameDictionary,
    config: {
      header: "Risk Recommendation Details",
    },
  };

  const parsedData = tryParse(data);
  const detailsProps = {
    fields,
    customFields,
    fieldNameDictionary,
    detailsConfig,

    //System and KRI changes appended at the end of the details table
    suffixComponent: <RiskChangeTable key={JSON.stringify(data)} item={parsedData} />,
  };

  const editRecommendationModal = useModal(
    "Edit Risk Recommendation",
    <EditRiskRecommendation
      item={item}
      organizationID={organizationID}
      disableObservation={true}
      toggleModal={toggleModal}
      resetFunction={resetFunction}
    />,
    <EditButton className={"float-right"} style={{ marginBottom: "1em", marginTop: "-2em" }} />,
    {
      width: "75vw",
    },
  );

  return (
    <span>
      {editRecommendationModal.modal}
      {editRecommendationModal.modalButton}
      <DetailsTable item={parsedData} {...detailsProps} disableRoleChecking={true} />
    </span>
  );
};

export default withOrganizationCheck(RiskRecommendationDetails);
