import { CSSProperties } from "react";

import { handleLoader } from "../Functions/Views/handleLoader";
import NotEnoughData, { CallToAction } from "../GenericComponents/NotEnoughData";

export interface DataLoaderParams {
  isLoading: boolean;
  isEnoughData: boolean;
  dataMessage?: string;
  callToAction?: CallToAction;
  children: JSX.Element;
  notEnoughDataChildren?: JSX.Element;
  dashedOverlayProps?: Record<string, unknown>;
  style?: CSSProperties;
}

/**
 * Utility component for handling isLoading and NotEnoughData states
 */
const DataLoader = ({
  isLoading = false,
  isEnoughData = false,
  dataMessage,
  callToAction,
  children,
  notEnoughDataChildren,
  dashedOverlayProps,
  style = {},
}: DataLoaderParams): JSX.Element => {
  const foo = (
    <div style={style}>
      {notEnoughDataChildren ?? (
        <NotEnoughData message={dataMessage} callToAction={callToAction} dashedOverlayProps={dashedOverlayProps} />
      )}
    </div>
  );

  return handleLoader({
    component: isEnoughData ? children : foo,
    isLoading,
  });
};

export default DataLoader;
