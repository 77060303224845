import { API, graphqlOperation } from "@aws-amplify/api";

import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

const { createMutation: createReportDocument } = generateGraphql("ReportDocument", ["name"]);

export const CreateReportDocument = (input) => {
  let reportDocument;
  API.graphql(graphqlOperation(createReportDocument, { ...input })).then(({ data }) => {
    InfoLogger(`Report Document ${data.createReportDocument.name} was Successfully Created`);
    reportDocument = data.createReportDocument;
  });

  return reportDocument;
};
