import React from "react";

import { useStack } from "../DataTableOptions/DataTableOptions";
import InterfaceWelcomeTourReset from "../InterfaceOptions/components/InterfaceWelcomeTourReset";

const WelcomeTourOptions = (props) => {
  return useStack({ items: [<InterfaceWelcomeTourReset />] });
};

export default WelcomeTourOptions;
