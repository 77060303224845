import React from "react";
import { Badge } from "reactstrap";

/**
 * Displays the current status of a KPI as a badge
 * @param item
 * @param status
 * @param style
 * @param simple
 * @returns {JSX.Element}
 * @constructor
 */
const KPIStatus = ({ item, status, style, simple = false }) => {
  const getStatus = () => {
    switch (status) {
      case "success":
        return (
          <Badge style={{ ...style }} title="Success" color="success">
            {simple ? "Success" : <i className="icon-check" />}
          </Badge>
        );
      case "failed":
        return (
          <Badge style={{ ...style }} title="Failed" color="danger">
            {simple ? "Failed" : <i className="icon-close" />}
          </Badge>
        );
      case "warning":
        return (
          <Badge style={{ ...style }} title="Warning" color="warning">
            {simple ? "Warning" : <i className="icon-question" />}
          </Badge>
        );
      case "n/a":
        return (
          <Badge style={{ ...style }} title="N/A" color="secondary">
            N/A
          </Badge>
        );
      case "N/A":
        return (
          <Badge style={{ ...style }} title="N/A" color="secondary">
            N/A
          </Badge>
        );
      default:
        return (
          <Badge style={{ ...style }} title="N/A" color="secondary">
            N/A
          </Badge>
        );
    }
  };

  return <span style={{ fontSize: "1.4em" }}>{getStatus()}</span>;
};

export default KPIStatus;
