/**
 * Determines the dot color for the evidence activity timeline
 * @param {EvidenceActivityType} type
 * @returns {string}
 */
export const getDotColor = (type) => {
  /**
   * Temporarily returning 'grey' until we settle on a color scheme
   */
  return "grey"; // temp

  /**
   * Leaving this here for the future color implementation
   */
  // switch (type) {
  //   case 'report':
  //     return 'grey';
  //   case 'document':
  //     return 'grey';
  //   case 'expiringSoon':
  //     return 'secondary'
  //   case 'validated':
  //     return 'primary';
  //   default:
  //     return 'grey'
  // }
};
