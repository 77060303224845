"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLossToleranceCurveXValues = void 0;
/**
 * Converts an array of objects of form {x: 123, y: 456} into a flat array using the x value
 * @param {object[]} lossToleranceCurve
 * @param {number} lossToleranceCurve[].x
 * @returns {number[]}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLossToleranceCurveXValues = (lossToleranceCurve) => {
    return lossToleranceCurve.map((item) => item.x);
};
exports.getLossToleranceCurveXValues = getLossToleranceCurveXValues;
