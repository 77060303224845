import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import KeyPerformanceIndicatorDetails from "../components/KeyPerformanceIndicatorDetails";
import KPIStatus from "../customFields/KPIStatus";
import MetricTypes from "../customFields/MetricTypes";
import { deleteKeyPerformanceIndicator } from "../functions/deleteKeyPerformanceIndicator";

import { useCreateKeyPerformanceIndicator } from "./useCreateKeyPerformanceIndicator";

export const useKeyPerformanceIndicator = ({
  organizationID,
  queryConfig,
  dataCardConfig,
  config,
  riskControl,
  resetFunction,
  toggleModal,
}) => {
  const module = modules.METRICS;
  const resource = resources.KEY_PERFORMANCE_INDICATOR;
  const typename = "KeyPerformanceIndicator";

  const fields = ["name", "status", "metricTypes"];

  const nestedFields = {
    metricTypes: `
    {
      items
        {
          id
          logic {
            operator
            data {
              value
              format
            }
          }
          metricType {
            id
            name
            description
            format
            metrics {
              items {
              id
              name
              date
              data {
                value
                format
              }
            }
          }
        }
      }
    }`,
  };

  const customFields = [
    {
      field: "status",
      component: <KPIStatus />,
    },
    {
      field: "metricTypes",
      component: <MetricTypes />,
    },
  ];

  const fieldNameDictionary = {
    metricTypes: "Metrics",
  };

  const createKPIForm = useCreateKeyPerformanceIndicator({
    organizationID,
    toggleModal,
  });

  queryConfig = {
    organizationID,
    ...queryConfig,
  };

  const { updateMutation } = generateGraphql(typename, fields, nestedFields);

  dataCardConfig = {
    options: ["delete"],
    customFields,
    module,
    resource,
    deleteFunction: deleteKeyPerformanceIndicator,
    updateMutation,
    createResourceComponent: createKPIForm.display,
    detailsComponent: (
      <KeyPerformanceIndicatorDetails
        fields={fields}
        customFields={customFields}
        nestedFields={nestedFields}
        fieldNameDictionary={fieldNameDictionary}
      />
    ),
    header: "Key Performance indicators",
    fieldNameDictionary,
    ...dataCardConfig,
  };

  const queryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    config,
    module,
    resource,
    fields,
    typename,
    nestedFields,
  });

  return {
    ...queryCard,
  };
};
