import { Alert } from "@mui/material";

/**
 * Displays a single user attestation result
 * @param {boolean} attestation - if TRUE the user has attested that the evidence is in place
 * @returns {JSX.Element}
 */
const EvidenceAttestationViewer = ({ attestation }) => {
  return (
    <Alert severity={attestation ? "success" : "error"}>
      {`The user has attested that this evidence is `}
      <span style={{ fontWeight: "bold" }}>{attestation ? "in place" : "not in place"}</span>
      {`.`}
    </Alert>
  );
};

export default EvidenceAttestationViewer;
