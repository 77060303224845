import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCustomResourceTypeForm } from "../hooks/useCustomResourceTypeForm";

/**
 * A component instance of the useCustomResourceTypeForm.js hook
 * @param {object} props
 * @returns {*}
 */
const CustomResourceTypeForm = (props) => useCustomResourceTypeForm(props).display;

export default withOrganizationCheck(CustomResourceTypeForm);
