/**
 * Function that checks if the query config has active filters at the current recursion level
 * @param {object} query - the recursive query config object build with useCustomQueryBuilder
 * @returns {boolean} - TRUE if there are rules defined for the current level filter, FALSE otherwise
 */
export const doesHaveActiveFilter = ({ query }) => {
  const { filters } = query || {};

  if (!filters) {
    return false;
  }

  let hasRules = false;
  if (typeof filters === "object" && Object.keys(filters).length > 0) {
    for (const combinatorKey in filters) {
      const combinatorRules = filters[combinatorKey];
      if (Array.isArray(combinatorRules) && combinatorRules.length > 0) {
        for (const rule of combinatorRules) {
          if (typeof rule === "object" && rule?.operator && rule?.field) {
            hasRules = true;
            break;
          }
        }
      }
    }
  }

  return hasRules;
};
