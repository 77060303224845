import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { SystemRecommendationForm } from "../hooks/useSystemRecommendationForm";

import MonteCarloChanges from "./MonteCarloChanges";

/**
 * Performs a Monte Carlo analysis for a Risk Recommendation
 * @param {boolean} isNonStandardOnly - TRUE if you need to hide the system input because it is pre-selected
 * @param {string} selectedSystemID - preselected system ID for the risk change (non-standard controls from system context)
 * @param {object} riskRecommendationData - contains data  from useRiskRecommendationData
 * @param {object} item - the existing Risk Recommendation, if using this as an edit form
 * @param {boolean} [disabledResults=false] - TRUE if you need not show the results UI and perform calculations for user to see
 * @param {function} [onInvalidInputChange] - callback for valid/invalid state of the recommendation form
 * @param {boolean} [showOverridesButton] - TRUE if you want to show the overrides buttons for the fields
 * @param {boolean} [disableSimulationMessage] - if present will remove the simulate option and replace with a message to the user.
 * @returns {JSX.Element}
 */
const RecommendationMonteCarlo = ({
  isNonStandardOnly,
  selectedSystemID,
  riskRecommendationData,
  item,
  disableResults = false,
  onInvalidInputChange,
  showOverridesButton,
  disableSimulationMessage,
}) => {
  const { selectedRiskControlID, riskControls, systems } = riskRecommendationData;

  return (
    <div>
      <SystemRecommendationForm
        isNonStandardOnly={isNonStandardOnly}
        selectedSystemID={selectedSystemID}
        {...riskRecommendationData}
        riskControls={riskControls}
        systems={systems}
        onInvalidInputChange={onInvalidInputChange}
        showOverridesButton={showOverridesButton}
      />
      {!disableResults && !isNullOrUndefined(selectedRiskControlID) && (
        <MonteCarloChanges
          item={item}
          riskRecommendationData={riskRecommendationData}
          disableSimulationMessage={disableSimulationMessage}
        />
      )}
    </div>
  );
};

export default RecommendationMonteCarlo;
