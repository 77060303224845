/**
 * Converts an array of objects to an array of a single property from each object, helpful for useEffect dependencies
 * @param {object[]} array - array of objects to map to an array of a specific property values
 * @param {string[]} propertyName - the name of the property to place in the result array (array of options, or operation between properties provided)
 * @param {string[][]} [propertyNames] - if provided and propertyName is not, multiple properties from each object will be used to create a single key for the row
 * @param {boolean} ignoreNull - if TRUE will not add null values to result array
 * @returns {*[]} - array of property values
 */
export const getObjectPropertyArray = ({
  array,
  propertyName = ["name", "field"],
  propertyNames,
  ignoreNull = false,
}) => {
  //Check arguments
  let propertySets = [propertyName];
  if (propertyNames) {
    propertySets = propertyNames;
  }
  if (!Array.isArray(propertySets)) {
    return [];
  }

  //Convert arrays
  if (Array.isArray(array)) {
    const newArray = [];
    for (const item of array) {
      let value = "";
      for (const propertySet of propertySets) {
        let subValue = null;
        for (const name of propertySet) {
          subValue = item[name];
          if (subValue) {
            break;
          }
        }
        if (subValue) {
          value += subValue;
        }
      }
      if (value || !ignoreNull) {
        newArray.push(value);
      }
    }
    return newArray;
  } else {
    return [];
  }
};
