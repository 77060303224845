import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import useListQuery from "../../../../../../../hooks/graphql/useListQuery";
import { IMPORTER_FIELD_FORMATS } from "../../../../../../../hooks/views/useCsvImporter/enums/IMPORTER_FIELD_FORMATS";
import { useCsvImporter } from "../../../../../../../hooks/views/useCsvImporter/hooks/useCsvImporter";
import { useModal } from "../../../../../../../hooks/views/useModal";
import ImportButton from "../../../../../../../utils/GenericComponents/ImportButton";

import { useRiskControlDataGrid } from "./useRiskControlDataGrid";

/**
 * CSV importer for Key Risk Indicators
 *
 * @param {string} organizationID - the ID of the organization to import into
 * @param {string} controlCategoryID - the control category ID to import into
 * @returns {{display: JSX.Element}}
 */
export const useRiskControlImporter = ({ organizationID, controlCategoryID }) => {
  // Get existing Control Categories to perform a name lookup
  const controlCategories = useListQuery({
    query: generateGraphql("RiskControlCategory", ["name"]).listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const getFields = () => {
    const fields = [
      {
        field: "statementNumber",
        name: "Statement Number",
        keywords: ["id", "number"],
        required: true,
      },
      {
        field: "name",
        name: "Name",
        required: true,
        keywords: ["declarative statement", "statement", "control", "risk control", "sub control"],
      },
      {
        field: "costOfControl",
        name: "Cost of Control",
        format: IMPORTER_FIELD_FORMATS.DOLLAR,
        keywords: ["cost"],
        defaultValue: 0.0,
      },
      {
        field: "strengthRating",
        name: "Strength Rating",
        format: IMPORTER_FIELD_FORMATS.PERCENT,
        keywords: ["strength", "risk reduction", "reduction"],
        defaultValue: 0.0,
      },
      {
        field: "implementationRating",
        name: "Implementation Rating",
        format: IMPORTER_FIELD_FORMATS.PERCENT,
        keywords: ["implementation", "rating"],
        defaultValue: 0.0,
      },
      {
        field: "keyControl",
        name: "Key Control",
        format: IMPORTER_FIELD_FORMATS.BOOLEAN,
        keywords: ["key"],
        defaultValue: false,
      },
      {
        field: "notes",
        name: "Notes",
        format: IMPORTER_FIELD_FORMATS.NOTES,
        keywords: ["note", "comment", "comments"],
      },
      {
        field: "controlCategoryID",
        name: "Control Category Name",
        format: IMPORTER_FIELD_FORMATS.SELECT,
        keywords: ["category", "control category"],
        connectionItemField: "controlCategoryID",
        connectionItemList: controlCategories.list,
        connectionItemID: controlCategoryID,
      },
    ];

    return fields;
  };

  const csvImporter = useCsvImporter({
    organizationID,
    typename: "RiskControl",
    fields: getFields(),
    isAsync: false,
    resultGrid: useRiskControlDataGrid,
  });

  const modal = useModal("Import Risk Controls", csvImporter.display, <ImportButton />, { width: "90vw" });

  return {
    ...csvImporter,
    ...modal,
  };
};
