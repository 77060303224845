import React, { useState } from "react";
import Lightbox from "react-awesome-lightbox";
import "./ImageViewerStyle.css";
import { Alert } from "reactstrap";
/**
 * @description Display a preview of an image
 * @param {string} initUrl - The url of the image to display
 * @param {string} initTitle - The title of the image
 * @returns {{display: JSX.Element}}
 */
export const useImageViewer = (initUrl, initTitle = "Image Preview") => {
  const [url, setURL] = useState(initUrl || "");
  const [title, setTitle] = useState(initTitle || "");

  const display = (
    <div style={{ height: "70vh" }}>
      {url !== "" ? (
        <Lightbox image={url} title={title} />
      ) : (
        <Alert color={"danger"}>No image URL to load, please try reopening the window.</Alert>
      )}
    </div>
  );

  return {
    display,
  };
};
