import { Input } from "reactstrap";

import { processTextPastedIntoInput } from "../../../Functions/String/processTextPastedIntoInput";

/**
 * A simple TextArea input field
 *
 * @param {string} field - the field that is being updated
 * @param {string} value - the current value of this field
 * @param {function} setValue - sets the current value of this field
 * @param {function} toggle - toggles the editing state of the field
 * @returns {{display: JSX.Element}}
 */
export const useTextAreaField = ({ field, value, setValue, toggle }) => {
  const display = (
    <Input
      data-testid={`textarea-input-field-${field}`}
      autoFocus={true}
      type="textarea"
      value={value}
      rows="4"
      cols="25"
      onChange={(e) => setValue(e.target.value)}
      onPaste={(e) => {
        const resultText = processTextPastedIntoInput({ e, value });
        setValue(resultText);
      }}
    />
  );

  return {
    display,
  };
};
