import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useControlDetailsAccordion } from "../hooks/useControlDetails/useControlDetailsAccordion";

/**
 * A component instance of the useControlDetailsAccordion.js hook
 * @param {object} props
 */
const ControlDetails = (props) => {
  // useControlDetailsTour();
  return useControlDetailsAccordion(props).display;
};

export default withDetailsPage(ControlDetails, { title: "Control Details" });
