import { isNonEmptyArray, isObject } from "@rivial-security/func-utils";

/**
 * Puts field configs from all form steps (if multiple) into a single field config
 * @param {object} fieldConfig - field config that is passed into the form, either already combined or seperated into separate steps
 * @returns {{}|*} - a single field config object containing settings for all fields
 */
export const combineFieldConfig = ({ fieldConfig }) => {
  //falsy field configs are invalid: return empty
  if (!fieldConfig) {
    return {};
  }

  //If fieldConfig is an array the form is split into multiple steps
  if (isNonEmptyArray(fieldConfig)) {
    const combinedFieldConfig = {};

    for (const fieldConfigStep of fieldConfig) {
      const nestedFieldConfig = fieldConfigStep?.fields;
      if (isObject(nestedFieldConfig)) {
        for (const fieldConfigFieldName in nestedFieldConfig) {
          const fieldConfigStepField = nestedFieldConfig[fieldConfigFieldName];
          if (isObject(fieldConfigStepField)) {
            combinedFieldConfig[fieldConfigFieldName] = fieldConfigStepField;
          }
        }
      }
    }

    return combinedFieldConfig;
  }

  //If fieldConfig is an object the form is a single step (original behavior)
  if (isObject(fieldConfig)) {
    return fieldConfig;
  }

  //If field config is not a non-empty array or an object, it is invalid: return empty
  return {};
};
