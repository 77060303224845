"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemTag = void 0;
/**
 * System tags for the platform (applied automatically upon imports or other user actions)
 * @type {{AUTO_FIXED: string, REPEAT_VULNERABILITY: string, MANUAL_FIX: string, NEW_VULNERABILITY: string, WAS_AN_EXCEPTION: string}}
 */
exports.SystemTag = {
    NEW_VULNERABILITY: "newVulnerability",
    REPEAT_VULNERABILITY: "repeatVulnerability",
    MANUAL_FIX: "manualFix",
    AUTO_FIXED: "autoFixed",
    WAS_AN_EXCEPTION: "wasAnException",
};
