import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useDocumentEditor } from "../hooks/useDocumentEditor";

/**
 * Component instance of the useDocumentEditor hook
 * @param {string} organizationID
 * @param {object} props
 * @returns {JSX.Element}
 */
const DocumentEditor = ({ organizationID, ...props }) => {
  const documentEditor = useDocumentEditor({ organizationID, ...props });

  return <>{documentEditor.display}</>;
};

export default withOrganizationCheck(DocumentEditor);
