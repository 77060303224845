import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Based on the attached questionnaire assignee, returns the most recent submission recorded for that assignee, if exists
 * @param {object} task - the task for which to find a questionnaire submission
 * @returns {*|null}
 */
export const getMostRecentQuestionnaireSubmission = ({ task }) => {
  const customResourceEntries = task?.questionnaireAssignee?.submissions?.items || [];

  if (!isNonEmptyArray(customResourceEntries)) {
    return null;
  }
  customResourceEntries.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return customResourceEntries[0];
};
