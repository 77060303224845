/**
 * Updates the 'value' and 'originalValue' states after the update goes through
 *
 * @param {function} setOriginalValue - sets the originalValue state
 * @param {function} setValue - sets the value state
 * @param {*} result - the result value to set the state to
 * @param {function} [customFormat] - an optional function for reformatting the result before setting the state
 */
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const handleValueUpdates = ({ customFormat, setOriginalValue, setValue, result }) => {
  if (isNullOrUndefined(setValue)) {
    throw Error("setValue function param cannot be null or undefined");
  }

  if (typeof setValue !== "function") {
    throw Error("setValue param must be a function");
  }

  if (isNullOrUndefined(setOriginalValue)) {
    throw Error("setValue function param cannot be null or undefined");
  }

  if (typeof setOriginalValue !== "function") {
    throw Error("setOriginalValue param must be a function");
  }

  // TODO: Double check the reasoning behind this customFormat is already applied to result in saveChange.js
  // /**
  //  * If a customFormat function is passed in, set the originalValue state using it
  //  */
  // if (customFormat && typeof customFormat === 'function') {
  //   setOriginalValue(customFormat(result))
  // }
  // /**
  //  * Else set the originalValue state to be the current value,
  //  * and set the current value to be the result value from the mutation??
  //  */
  // else {
  //   setOriginalValue(result);
  //   setValue(result)
  // }

  setOriginalValue(result);
  setValue(result);
};
