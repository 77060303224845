import { displayEvidenceResponseForm } from "../functions/displayEvidenceResponseForm";

/**
 * Displays the relevant Evidence Response form for the landing page
 * @param props
 * @returns {*}
 * @constructor
 */
const EvidenceResponseForm = (props) => {
  return displayEvidenceResponseForm(props);
};

export default EvidenceResponseForm;
