/**
 * Parses data grid field configuration to get the sort model to use for the data grid
 * @param {object[]} fields - the fields that will be shown in the data grid
 * @returns {object[]} - the sort model to use for the data grid
 */
export const getDefaultDataGridSortModel = ({ fields } = {}) => {
  //If no fields there is nothing to sort
  if (!Array.isArray(fields)) {
    return [];
  }

  //Convert field configuration to sort model object that the data grid accepts
  const sortModel = [];
  for (const field of fields) {
    // Field Name has two options:
    const fieldName = field?.field || field?.name;

    // Add sort related fields to the sort model
    if (fieldName && field?.sort) {
      sortModel.push({
        field: fieldName,
        sort: field.sort?.direction?.toLowerCase() || null,
        priority: field.sort?.priority || null,
      });
    }
  }

  //Sort the sort model objects by priority in ascending order
  sortModel.sort((aData, bData) => {
    const a = aData?.priority;
    const b = bData?.priority;

    if (a && b) {
      return a - b;
    } else if (a && !b) {
      return -1;
    } else if (!a && b) {
      return 1;
    }
    return 0;
  });

  return sortModel;
};
