import React, { useEffect } from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { MITRE_ATTACK_RELATIONSHIP_TYPES } from "../../../Tools/MitreAttack/enums/mitreAttackRelationshipTypes";
import { listMitreAttackRelationships } from "../../../Tools/MitreAttack/functions/listMitreAttackRelationships";
import { useMitreAttackMitigationsDataGrid } from "../../../Tools/MitreAttack/Mitigations/hooks/useMitreAttackMitigationsDataGrid";

/**
 * @description Display list of Mitre Attack mitigations for a technique component
 * @param {object} item - Mitre Attack Threat item
 * @param {string} organizationID - selected organization ID
 * @constructor
 */
const ThreatTechniqueMitigations = ({ technique, organizationID }) => {
  const fields = [
    {
      name: "id",
      width: "100",
      valueGetter: (_value, row) => row?.source_object?.workspace?.attack_id,
      searchKeys: ["source_object?.workspace?.attack_id"],
    },
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row?.source_object?.stix?.name,
      searchKeys: ["source_object?.stix?.name"],
    },
  ];

  const grid = useMitreAttackMitigationsDataGrid({
    organizationID,
    fields,
    query: null,
  });

  useEffect(() => {
    if (technique) {
      grid?.setIsLoading(true);
      listMitreAttackRelationships({
        relationshipType: MITRE_ATTACK_RELATIONSHIP_TYPES.MITIGATES,
        targetRef: technique?.parent?.stix?.id || technique?.stix?.id,
      })
        .then((data) => {
          grid?.setData(data);
        })
        .finally(() => grid?.setIsLoading(false));
    }
  }, [technique]);

  return <div style={{ height: "30em" }}>{grid?.display}</div>;
};

export default withOrganizationCheck(ThreatTechniqueMitigations);
