import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules } from "@rivial-security/role-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { locationType } from "../../../../../analytics/constants/locationType";
import { operationType } from "../../../../../analytics/constants/operationType";
import { reasonType } from "../../../../../analytics/constants/reasonType";
import { useBoolean } from "../../../../../hooks/functional/useBoolean";
import useDidMountEffect from "../../../../../hooks/functional/useDidMountEffect";
import { useQueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { useAccordionChecklist } from "../../../../../hooks/views/useChecklist/useAccordionChecklist";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import Loader from "../../../../../utils/LoadingComponents/Loader";
import { getAllProcedureGroupResponseSteps } from "../../../PlaybookBuilder/functions/getAllProcedureGroupResponseSteps";
import { getProceduresNestedFields } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";
import { handleUpstreamProcedureChanges } from "../functions/handleUpstreamProcedureChanges";
import { useStepState } from "../hooks/useStepState";

import UpstreamProcedureChanges from "./UpstreamProcedureChanges";

/**
 * Displays Incident Response Procedure information and allows the user to edit response steps
 * @param {object} item - the database item for which to show the procedure
 * @param {string} typename - the resource that holds the procedure (ie. IncidentResponse, Playbook, SystemResponseLink)
 * @param {string} procedureName - the procedure in the procedure group (item) to use detect, analyze, contain, eradicate, recover, postIncident
 * @param {boolean} enableCheckbox - if true will show checkboxes next to each step that control its completion
 * @param {boolean} [disableEdits=false] - if TRUE will prevent user from making any content changes to the procedures
 * @param {boolean} [manageEditsSwitch = false] - true if this procedure should show and manage its own edits switch (used in response plan)
 * @param {boolean} [enableStepNotes=false] - if true will allow to create and read notes for each step within a procedure
 * @param {boolean} [enableProcedureNotes=false] - if true will show notes for each procedure group inside its accordion
 * @param {object} [containerStyle] - if provided will be added to the default accordion content of individual procedure
 * @param {string} organizationID - selected organization id
 * @param {function} [setCompletion] - re-runs the total procedure completion function using the queried item,
 * useful for parent UI updates
 * @param {boolean} [disableCheckboxEdits=false] - if TRUE will prevent from making changes to completion state of steps
 * @param {boolean} [showUpstreamUpdateSwitch=false] - shows a UI option to copy changes in the checklist upstream to a parent item
 * @param {object} notesProps - props to pass to the notes components
 * @returns {JSX.Element}
 */
const Procedure = ({
  item,
  typename,
  procedureName,
  enableCheckbox = false,
  disableEdits = false,
  manageEditsSwitch = false,
  enableStepNotes = false,
  enableProcedureNotes = false,
  containerStyle = {},
  organizationID,
  setCompletion,
  disableCheckboxEdits = false,
  showUpstreamUpdateSwitch = false,
  notesProps,
}) => {
  //Set default styling
  containerStyle = {
    width: "100%",
    overflowY: "auto",
    height: "30em",
    ...containerStyle,
  };

  const { getQuery } = generateGraphql(typename, ["procedures"], getProceduresNestedFields({ includeNotes: true }));

  const itemQuery = useQueryGetItem({
    query: getQuery,
    itemId: item?.id,
    disableRoleChecking: true,
  });

  /**
   * When the Procedure item changes, update the completion state for the tabs.
   * Also manage whether edits switch is turned on based on the number of items available (if none, edits are enabled)
   */
  const [curItem, setCurItem] = useState(null);
  useDidMountEffect(() => {
    let curItem = null;
    if (itemQuery.item) {
      curItem = itemQuery.item;
    } else if (item) {
      curItem = item;
    }
    setCurItem(curItem);

    if (curItem) {
      setCompletion?.(curItem);
    }

    //Find whether need to have edits turned on at start (if no checklist items are available)
    const steps = getAllProcedureGroupResponseSteps({
      item: curItem,
      procedureName,
    });
    if (!initializedEditsSwitch && Array.isArray(steps)) {
      setInitializedEditsSwitch(true);
      if (steps.length === 0) {
        setEnableEdits(true);
      }
    }
  }, [itemQuery.item, item]);

  //Updates the queried item if the reset index changes on the details page and gets passed here with the item
  useEffect(() => {
    itemQuery.reset();
  }, [item?.resetIndex]);

  /**
   * State holding step information
   */
  const [groups] = useStepState(itemQuery.item, typename, procedureName);

  const [initializedEditsSwitch, setInitializedEditsSwitch] = useBoolean(false);
  const [enableEdits, setEnableEdits] = useBoolean(!disableEdits);
  const [enableUpstreamUpdates, setEnableUpstreamUpdates] = useState({});

  /**
   * Returns true when edits are disabled (takes into account whether this component manages this state)
   */
  const areEditsDisabled = () => {
    if (manageEditsSwitch) {
      return !enableEdits;
    }

    return disableEdits;
  };

  /**
   * Performs the mutation for an updated ResponseSteps array
   */
  const handleProcedureUpdate = async ({
    curItem,
    typename,
    procedureName,
    newGroup,
    updatedGroupID,
    updatedStepID,
    updateOperation,
  }) => {
    if (!curItem?.id) {
      throw Error("Cannot update item without an id!");
    }

    /**
     * If the `procedures` property was retrieved but is null (not undefined) then it is safe to create a
     * new `procedures` property as it wont override any existing steps. Null check for curItem is up above.
     */
    const curItemCopy = cloneDeep(curItem);
    if (curItemCopy.procedures === null) {
      curItemCopy.procedures = [];
    }

    //Find the group index within the item's 'procedures' property (create one if none exist)
    let groupIndex = -1;
    if (Array.isArray(curItemCopy.procedures)) {
      if (curItemCopy.procedures.length === 0 && newGroup?.parentID) {
        //if no procedure group present create a new one (without procedures yet)
        curItemCopy.procedures = [
          {
            parentID: newGroup.parentID,
            name: newGroup?.name,
            parentTypename: newGroup?.parentTypename,
          },
        ];
        groupIndex = 0;
      } else {
        groupIndex = curItemCopy.procedures.findIndex((item) => {
          return item?.parentID === updatedGroupID;
        });
      }
    }

    //Quit if couldn't find the needed procedure group
    if (isNullOrUndefined(groupIndex) || groupIndex === -1) {
      throw Error("Unable to change procedure steps because cannot find the procedure group to update!");
    }

    //Update the procedure within a procedure group if provided with a name
    if (procedureName) {
      const procedureGroup = curItemCopy.procedures[groupIndex];
      if (procedureGroup.hasOwnProperty(procedureName) && procedureGroup[procedureName]) {
        procedureGroup[procedureName].responseSteps = newGroup?.responseSteps;
      } else {
        procedureGroup[procedureName] = {
          name: procedureName,
          description: procedureName,
          responseSteps: newGroup?.responseSteps,
        };
      }

      //If up stream changes are enabled perform the parent updates
      if (
        updatedStepID &&
        updateOperation &&
        enableUpstreamUpdates.hasOwnProperty(updatedGroupID) &&
        enableUpstreamUpdates[updatedGroupID]
      ) {
        //Find the updated step
        const updatedStep = newGroup?.responseSteps.find((step) => step?.id == updatedStepID);

        /**
         * Perform the upstream changes
         * NOTE: if present linkID takes precedence because that is the item that holds the procedure data
         */
        await handleUpstreamProcedureChanges({
          parentID: newGroup?.linkID || newGroup?.parentID,
          parentTypename: newGroup?.parentTypename,
          procedureName,
          updatedStep,
          updatedStepID,
          updateOperation,
        });
      }
    }
    setCurItem(curItemCopy);

    const { updateMutation } = generateGraphql(typename, ["procedures"], getProceduresNestedFields());

    return await ItemMutation(updateMutation, curItemCopy);
  };

  /**
   * Determines if the updatedSteps are different from the current steps
   * @param currentSteps
   * @param updatedSteps
   */
  const isChanged = (currentSteps, updatedSteps) => {
    return JSON.stringify(currentSteps) !== JSON.stringify(updatedSteps);
  };

  /**
   * Determines if the ResponseSteps list in the database needs to be updated, and performs that update
   * @param updatedSteps
   */
  const handleResponseStepsUpdate = async ({ updatedGroup, updatedGroupID, updatedStepID, updateOperation }) => {
    //Create a group if none exist yet or clone an existing group
    let newGroup;
    const foundGroup = groups?.find((item) => item?.parentID === updatedGroupID);

    if (!foundGroup) {
      newGroup = {
        parentID: item?.id,
        parentTypename: typename,
        responseSteps: updatedGroup?.responseSteps,
      };
    } else {
      newGroup = cloneDeep(foundGroup);
      newGroup.responseSteps = updatedGroup.responseSteps;
    }

    //Only check for changes when its not a new group
    if (!foundGroup || (foundGroup && isChanged(foundGroup, newGroup))) {
      try {
        await handleProcedureUpdate({
          curItem,
          typename,
          procedureName,
          newGroup,
          updatedGroupID,
          updatedStepID,
          updateOperation,
        });
        itemQuery.reset();
      } catch (e) {
        ErrorLogger(e.message, {
          location: locationType.FUNCTION,
          operation: operationType.UPDATE,
          reason: reasonType.INVALID_PARAM,
        });
      }
    } else {
      InfoLogger("The Response Steps array hasn't changed. Skipping update..");
    }
  };

  /**
   * Determines which icon to show for an accordion item
   * @param {object} data - the accordion item data (procedure)
   * @return {string} - the icon name to show for the accordion item
   */
  const getPlaybookAccordionIcon = (data) => {
    const typename = data?.parentTypename;
    switch (typename) {
      case "Playbook":
        return "icon-book-open";
      case "ResponseTeam":
        return "icon-people";
      case "ResponseSystemLink":
        return "icon-screen-desktop";
      case "IncidentResponse":
        return "icon-map";
      default:
        return "icon-list";
    }
  };

  /**
   * Determines whether to grey out accordion item
   * @param {object} data - the accordion item data (procedure)
   * @return {boolean} - TRUE if accordion section should be disabled, FALSE if not
   */
  const getPlaybookAccordionDisabledState = (data) => {
    return !(Array.isArray(data?.responseSteps) && data.responseSteps.length > 0);
  };

  const onUpdateNotes = async ({ updatedGroupID, notes = [] }) => {
    //Do not update if the procedures cannot be found
    if (!Array.isArray(curItem?.procedures)) {
      return;
    }

    const foundGroupIndex = curItem?.procedures?.findIndex((item) => item?.parentID === updatedGroupID);

    //Do not continue if the procedure id cannot be found in the list
    if (foundGroupIndex === -1) {
      return;
    }

    const newProcedures = cloneDeep(curItem?.procedures);

    if (
      !newProcedures[foundGroupIndex].hasOwnProperty(procedureName) ||
      isNullOrUndefined(newProcedures[foundGroupIndex][procedureName])
    ) {
      newProcedures[foundGroupIndex][procedureName] = {
        name: procedureName,
        description: procedureName,
        responseSteps: [],
        notes,
      };
    } else {
      newProcedures[foundGroupIndex][procedureName].notes = notes;
    }

    await ItemMutation(generateGraphql(typename).updateMutation, {
      id: curItem.id,
      procedures: newProcedures,
    });

    itemQuery.reset();
  };

  const checklist = useAccordionChecklist({
    data: groups,
    field: "responseSteps",
    enableCheckbox,
    enableStepNotes,
    enableGroupNotes: enableProcedureNotes,
    module: modules.INCIDENT_RESPONSE,
    typename,
    organizationID,
    notesProps,
    resetFunction: () => itemQuery.reset(),
    onUpdateNotes,
    disableEdits: areEditsDisabled(),
    disableCheckboxEdits,
    textFieldName: "description",
    checkboxFieldName: "completed",
    forceAccordion: typename === "Incident" || typename === "Exercise",
    updateFunction: handleResponseStepsUpdate,
    getAccordionIcon: getPlaybookAccordionIcon,
    getAccordionDisabledState: getPlaybookAccordionDisabledState,
    groupAddonComponents:
      showUpstreamUpdateSwitch === true
        ? [
            <UpstreamProcedureChanges
              callback={({ enabled, parentID }) => {
                if (parentID) {
                  const newEnableUpstreamUpdates = cloneDeep(enableUpstreamUpdates);
                  if (enabled) {
                    newEnableUpstreamUpdates[parentID] = true;
                  } else {
                    delete newEnableUpstreamUpdates[parentID];
                  }
                  setEnableUpstreamUpdates(newEnableUpstreamUpdates);
                }
              }}
            />,
          ]
        : [],
    calculateCompletion: (procedureGroup) => {
      const steps = procedureGroup?.responseSteps;
      if (Array.isArray(steps) && steps.length > 0) {
        let completed = 0;
        for (const step of steps) {
          if (step?.completed === true) {
            completed++;
          }
        }
        return completed / steps.length;
      } else {
        return 0;
      }
    },
  });

  return (
    <div>
      <div style={{ ...containerStyle }}>
        <span style={{ marginBottom: "1em" }}>
          {itemQuery?.isLoading && itemQuery?.item === null ? (
            <div>
              {" "}
              Loading Steps... <Loader />
            </div>
          ) : (
            checklist?.display
          )}
        </span>
      </div>
      <hr />
      {manageEditsSwitch && (
        <FormControlLabel
          style={{ position: "block", bottom: 0, right: 0 }}
          className={"float-right"}
          control={
            <Switch
              size="small"
              onClick={() => setEnableEdits(!enableEdits)}
              value={enableEdits}
              checked={enableEdits}
              className={"float-right"}
            />
          }
          label="Edits"
        />
      )}
    </div>
  );
};

export default withOrganizationCheck(Procedure);
