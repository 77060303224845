"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganization = void 0;
exports.getOrganization = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    shortName
    orgType
    s3BucketName
    numberOfCustomers
    numberOfEmployees
  }
}`;
