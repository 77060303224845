import { generateReportSection } from "@rivial-security/report-utils";

/**
 * Pastes in an SFDT report section to an existing documentEditor ref.
 * This will insert the SFDT at the cursor location
 * @param ref - the document editor ref;
 * @param blocks - an array of SFDT blocks to paste in
 */
export const pasteReportSection = async (ref, blocks = []) => {
  const doc = await generateReportSection(blocks);
  ref.documentEditor.editor.paste(
    JSON.stringify({
      ...doc,
    }),
  );
};
