import React, { useEffect, useState } from "react";

import { numberWithCommas } from "@rivial-security/func-utils";
import { getTotalNumberOfRecords } from "@rivial-security/risk-calc-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the highest number of records across information assets attached to a System
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TotalNumberOfRecords = (props) => {
  const { system } = props;

  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  useEffect(() => {
    const totalNumberOfRecords = getTotalNumberOfRecords(system);
    setTotalNumberOfRecords(totalNumberOfRecords);
  }, [system]);

  return (
    <>
      <h7>Number of Records:</h7> <strong>{numberWithCommas(totalNumberOfRecords)}</strong>{" "}
      <i className="icon-question" title={`Volume = High water mark of number of records per information asset`} />
    </>
  );
};

export default withOrganizationCheck(TotalNumberOfRecords);
