import { Alert } from "@mui/material";

import { AV_STATUS } from "../../../../enums/AV_STATUS";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import AVStatus from "../../../../utils/CustomFields/AVStatus";
import MuiItemDownloadButton from "../../../../utils/GenericComponents/buttons/MuiItemDownloadButton";
import MuiItemPreviewButton from "../../../../utils/GenericComponents/buttons/MuiItemPreviewButton";

/**
 * Displays the file Download and preview buttons for a document
 * @param {string} organizationID - id of the organization the document is a part of
 * @param {object} item - the item representing a document entry in the database
 * @returns {JSX.Element}
 */
const DocumentFile = ({ organizationID, item }) => {
  const isInfected = item?.avStatus === AV_STATUS.INFECTED;

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: ".5em",
          }}
        >
          <MuiItemPreviewButton
            item={item}
            organizationID={organizationID}
            itemDisplayName={"Document"}
            buttonProps={{
              size: "medium",
            }}
          />
          <MuiItemDownloadButton
            item={item}
            organizationID={organizationID}
            itemDisplayName={"Document"}
            buttonProps={{
              size: "medium",
            }}
          />
        </div>
        <Alert severity={item?.avStatus === AV_STATUS.CLEAN ? "success" : isInfected ? "error" : "warning"}>
          Anti-Virus Status: <AVStatus item={item} /> <br />
          {item?.lastAVCheck && <span>Last Scan: {new Date(item?.lastAVCheck).toLocaleDateString()}</span>}
        </Alert>
      </div>
    </div>
  );
};

export default withOrganizationCheck(DocumentFile);
