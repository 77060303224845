import React from "react";

/**
 * A custom component that shows the color value provided and its hex representation
 * @param {string} value - hex value of the color for the field
 * @returns {JSX.Element}
 */
const CustomColorDisplay = ({ value }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "middle",
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: "20px",
          height: "20px",
          backgroundColor: value,
          borderRadius: "5px",
          marginRight: "5px",
          border: "1px solid #000000",
          borderColor: "grey",
        }}
      />
      <span style={{ margin: 0 }}>{value}</span>
    </div>
  );
};

export default CustomColorDisplay;
