/**
 * Takes in available toast information and assigns the time at which it needs to be dismissed to the same object
 * @param {object} item - the toast information object
 * @param {number} autoDismissDuration - the user proffered duration for toasts to remain on screen
 */
import { eventType } from "../../../views/Notifications/enums/eventType";

export const assignToastEndTime = (item, autoDismissDuration) => {
  //Check if toast is permanent
  let noTimeout = false;
  if (typeof item.isPermanent === "boolean") {
    noTimeout = item.isPermanent;
  }

  //For now do not show notification event types on screen,
  //NOTE: isNew property is handled at toast creation for notifications
  if (item?.type === eventType.NOTIFICATION) {
    item.endTime = 0;
    item.isOnScreen = false;
    return;
  }

  //All toasts when arrive will be shown outside of the notification tray (for at least some time)
  item.isOnScreen = true;
  item.isNew = true;

  if (noTimeout) {
    item.endTime = -1;
  } else if (item.timeoutDuration && typeof item.timeoutDuration === "number") {
    item.endTime = Date.now() + item.timeoutDuration * 1000;
  } else {
    item.endTime = Date.now() + (autoDismissDuration || 1) * 1000; //default timeout
  }
};
