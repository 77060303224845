export const fullObservationsByOwnerGroupByModule = /* GraphQL */ `
  query ObservationsByOwnerGroupByModule(
    $ownerGroup: String
    $module: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    observationsByOwnerGroupByModule(
      ownerGroup: $ownerGroup
      module: $module
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        module
        isFinding
        ownerGroup
        risk
        decision
        needsDecision
        status
        insurancePolicyValidated
        formalResponse
        evidenceID
        evidence {
          id
        }
        recommendations(limit: 500) {
          items {
            id
            recommendation {
              id
              name
              module
              rating
              status
              actionItems(limit: 500) {
                items {
                  id
                  action {
                    id
                    name
                    description
                    dueDate
                    status
                    priority
                    pointOfContact {
                      id
                      firstName
                      lastName
                    }
                    tags(limit: 100) {
                      items {
                        __typename
                        id
                        tag {
                          id
                          name
                          description
                          fontColor
                          backgroundColor
                        }
                      }
                    }
                  }
                }
                nextToken
              }
              tags(limit: 100) {
                items {
                  __typename
                  id
                  tag {
                    id
                    name
                    description
                    fontColor
                    backgroundColor
                  }
                }
              }
            }
          }
          nextToken
        }
        tags(limit: 100) {
          items {
            __typename
            id
            tag {
              id
              name
              description
              fontColor
              backgroundColor
            }
          }
        }
      }
      nextToken
    }
  }
`;
