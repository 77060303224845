import { Button, Input } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Custom input form to configure a special 'override' value for a form field
 * @param {object} input - The form input object
 * @param {function} onChange - The onChange function to update the form input object
 * @param {string} fieldName - The name of the field to override
 * @returns {JSX.Element}
 * @constructor
 */
const OverridesInput = ({ input, onChange, fieldName }) => {
  // runs input through a validation function before setting state
  const handleOverrideInput = (input) => {
    // do we allow null?
    // do other input checks here
    if (!isNullOrUndefined(input)) {
      onChange(input);
    }
  };

  return (
    <span style={{ width: "100%" }}>
      <Input value={input?.[fieldName]} onChange={(e) => handleOverrideInput(e.target.value)} type={"text"} />
      <i>
        Overrides should be used with caution. See
        <Button color={"link"}>Override Documentation</Button>
      </i>
    </span>
  );
};

export default OverridesInput;
