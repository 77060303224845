"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.listSystemDetails = exports.getSystemDetails = void 0;
const generategraphql_1 = require("@rivial-security/generategraphql");
_a = (0, generategraphql_1.generateGraphql)("System", [
    "name",
    "description",
    "hosting",
    "availability",
    "availabilityUpper",
    "availabilityLower",
    "monteCarloInputS3ObjectKey",
    "informationAssets",
    "risks",
    "riskOverrides",
    "riskControlOverrides",
], {
    informationAssets: `(limit: 250) {
      items {
        id
        numberOfRecords
        confidentialityPerRecord
        integrityPerRecord
        informationAsset {
          id
          name
          ownerGroup
          confidentiality
          integrity
          confidentialityPerRecord
          integrityPerRecord
        }
      }
    }`,
    risks: `(limit: 250) {
      items {
        id
        ownerGroup
        probabilityModifier
        cia { confidentiality integrity availability }
        riskMapping {
          dataBreach
          systemDisruption
          facilityDisruption
          fraud
          malware
          vendor
          compliance
        }
        risk {
          id
          name
          ownerGroup
          annualRateOfOccurrence
          controlCategoryID
          controlCategory {
            id
            name
            ownerGroup
            description
            subControls (limit: 500) {
              items {
                id
                name
                statementNumber
                costOfControl
                strengthRating
                implementationRating
                outsourced
                ownerGroup
                controlCategoryID
              }
            }
          }
        }
      }
    }`,
    riskOverrides: `{
      enabledFields
      riskId
      annualRateOfOccurrence
      annualRateOfOccurrenceMax
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
    }`,
    riskControlOverrides: `{
      enabledFields
      riskControlId
      costOfControl
      implementationDetails
      strengthRating
      implementationRating
      outsourced
    }`,
}), exports.getSystemDetails = _a.getQuery, exports.listSystemDetails = _a.listQuery;
