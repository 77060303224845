import { MouseEvent, PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { cssTransition, toast, ToastContainer, TypeOptions } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { IconName } from "@components/Ions/icons/components/Icons";

import IconButton from "../IconButton/IconButton";

import "./Toast.module.scss";
import "@components/Ions/styles/animations.css";
import { ToastIcon } from "./private/ToastIcon";

const ToastCloseButton = ({ closeToast }: { closeToast: (event: MouseEvent<HTMLElement>) => void }): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={"toast-close"}>
      <IconButton
        aria-label={t("toast.close button")}
        onClick={closeToast}
        iconName={IconName.CLOSE}
        className="toast-close__button"
      />
    </div>
  );
};

export type ToastProviderProps = PropsWithChildren;

/**
 * `ToastProvider` is a wrapper component that provides a toast notification system that can be used throughout the application.
 */
const ToastProvider = ({ children }: ToastProviderProps): ReactElement => {
  const bounceTransition = cssTransition({
    enter: "bounce-in-from-top",
    exit: "bounce-out-to-top",
    collapse: false,
  });

  return (
    <>
      {children}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        transition={bounceTransition}
        icon={ToastIcon}
        closeButton={ToastCloseButton}
      />
    </>
  );
};

export { ToastProvider, toast };
export type { TypeOptions };
