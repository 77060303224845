import { Button } from "@mui/material";
import React from "react";

/**
 * Button that runs a monte carlo calculation for a Risk Recommendation
 * @param {boolean} isOutdated - if the recommendation is outdated
 * @param {boolean} isDisabled - if the button should be disabled
 * @param {function} onClick - the function to run when the button is clicked
 * @param {boolean} isLoading - if the button is loading
 * @returns {JSX.Element}
 * @constructor
 */
const RunCalculationButton = ({ isOutdated, isDisabled, onClick, isLoading }) => {
  return (
    <>
      <Button
        style={{ marginTop: "1em" }}
        loading={isLoading}
        loadingIndicator="Calculating Risk..."
        variant="outlined"
        disabled={isDisabled}
        onClick={onClick}
      >
        Simulate
      </Button>
    </>
  );
};

export default RunCalculationButton;
