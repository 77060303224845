import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateRecommendation } from "../hooks/useCreateRecommendation";

/**
 * Component for creating a new Recommendation
 * @param organizationID
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateRecommendation = ({ organizationID, ...props }) => {
  return useCreateRecommendation({ organizationID, ...props }).display;
};

export default withOrganizationCheck(CreateRecommendation);
