import { concatNotes } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { getTextSymbol } from "../hooks/useVendorCompletionBadges";

import { getCategoryCompletion } from "./getCategoryCompletion";

/**
 * Creates a vendor review report config out of the vendor review id
 * @param {string} itemId - the vendor review id
 * @return {Promise<{summary, controlCategories: *[], vendorReview: (void|{object}), rating: *}|{}>}
 */
export const createVendorReviewReportConfig = async ({ itemId }) => {
  if (!itemId) {
    return {};
  }

  const nestedFields = {
    vendorSolution: `
      {
        name
        vendor {
          name
        }
      }
    `,
    controlCategories: `(limit: 100)
      {
        items {
          name
          subControls (limit: 100) {
            items {
              inPlace
              audited
            }
          }
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
        }
      }
    `,
  };

  const { getQuery } = generateGraphql(
    "VendorReview",
    ["name", "rating", "createdAt", "vendorSolution", "controlCategories", "summary"],
    nestedFields,
  );

  let vendorReview;
  if (itemId) {
    vendorReview = await ItemQuery(getQuery, itemId);
  }

  //Generate formatted control category items
  const formattedControlCategoryItems = [];
  const controlCategoryItems = vendorReview?.controlCategories?.items;
  if (Array.isArray(controlCategoryItems)) {
    for (const category of controlCategoryItems) {
      const inPlace = getCategoryCompletion("inPlace", category);
      const audited = getCategoryCompletion("audited", category);

      formattedControlCategoryItems.push({
        name: category?.name || "Control Category",
        inPlace: getTextSymbol(inPlace),
        audited: getTextSymbol(audited),
        notes: concatNotes(category?.notes || []),
        details: "",
      });
    }
  }

  //Set up the report config
  const config = {
    vendorReview: vendorReview,
    controlCategories: formattedControlCategoryItems,
    rating: vendorReview?.rating,
    summary: vendorReview?.summary,
  };

  return config;
};
