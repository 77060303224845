export const getBlackKiteCompany = /* GraphQL */ `
  query GetBlackKiteCompany($query: QueryGetBlackKiteCompanyQueryInput!) {
    getBlackKiteCompany(query: $query) {
      CompanyId
      CompanyName
      DomainName
      BreachIndex
      RansomwareIndex
      Result
      GradeLetter
      CyberRating
      ComplianceRating
      FinancialImpactRating
      DomainNames
      Ecosystems {
        EcosystemId
        EcosystemName
      }
      Tags {
        TagId
        TagName
      }
    }
  }
`;
