import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useActionDataGrid } from "../hooks/useActionDataGrid";

/**
 * @description Component used in the Findings Dashboard to display Action Items
 * @param {object} props - The props for the component
 * @returns {JSX.Element}
 */
const ActionDataGrid = (props) => {
  return useActionDataGrid(props).display;
};

export default withOrganizationCheck(ActionDataGrid);
