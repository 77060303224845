import React from "react";
import { Button } from "reactstrap";

/**
 * A simple button to be used to be used for Open functionality
 *
 * @param {string} [typename] - used so the title can have more descriptive text
 * @param {string} [icon] - css class to be used for the icon. has a default value
 * @returns {JSX.Element}
 * @constructor
 */
const OpenButton = ({ typename = "Resource", ...props }) => {
  return (
    <Button size="sm" color="ghost-primary" style={{ boxShadow: "none" }} title={`Open a ${typename}`} {...props}>
      <i className={props.icon || "icon-check"} style={{ marginRight: props.text ? "1em" : undefined }} />
      {props.text || props.children}
    </Button>
  );
};

export default OpenButton;
