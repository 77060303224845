import { useDetails } from "../../../../../hooks/views/useDetails/hooks/useDetails";

/**
 * Simple details hook for a KnowBe4 Testing Recipient
 * @param item
 * @returns {{display: JSX.Element}}
 */
export const useKnowBe4TestRecipientDetails = ({ item }) => {
  return useDetails({ item, disableRoleChecking: true });
};
