import { useVendorReviewDataGrid } from "../../../../views/Vendor/VendorSolutionReviews/hooks/useVendorReviewDataGrid";

export const vendorReviewDefinition = {
  routes: {
    grid: "vendors/reviews",
    details: "vendors/reviews",
    helpCenter: "perform-a-vendor-review",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:book-open",

  //[COMPONENTS]
  gridHook: useVendorReviewDataGrid,
};
