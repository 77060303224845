import { generateGraphqlFields } from "@rivial-security/generategraphql";

const listControlsByControlSet_fields = [
  "statementNumber",
  "name",
  "inPlace",
  "isDisabled",
  "changes",
  "tags",
  "notes",
  "controlControlSetId",
  "controlSet",
  "customFieldData",
  "createdAt",
];
const listControlsByControlSet_nestedFields = {
  tags: `(limit: 100) { items { id tag { id name description fontColor backgroundColor } } }`,
  notes: `{ content observationID }`,
  controlSet: `{id name}`,
  changes: `(limit: 500) {
    items {
      date
      field
      change
    }
  }`,
};
export const listControlsByControlSet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
         ${generateGraphqlFields(listControlsByControlSet_fields, listControlsByControlSet_nestedFields)}
      }
      nextToken
    }
  }
`;
