import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function toggleSafeDelete for Interface Safe Delete Component
 * @param {boolean} safeDelete - value for enabling or disabling safeDelete toggle
 * @param {function} setSafeDelete - function for setting the selected user value
 * @param {object} preferences - a hook for getting and setting user preferences
 * @param {function} addToast - function for displaying a toast for the user
 */
export const toggleSafeDelete = ({ safeDelete, preferences, setSafeDelete, addToast }) => {
  if (isNullOrUndefined(safeDelete)) {
    safeDelete = false;
  }

  if (isNullOrUndefined(preferences)) {
    throw new Error(`[toggleSafeDelete.js] Param: "preferences" is null`);
  }

  if (isNullOrUndefined(setSafeDelete)) {
    throw new Error(`[toggleSafeDelete.js] Param: "setSafeDelete" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[toggleSafeDelete.js] Param: "addToast" is null`);
  }

  if (safeDelete) {
    if (window.confirm("Are you sure you want to disable Safe Delete?")) {
      try {
        preferences.setPreference("interfaceOptions", { safeDelete: false });
      } catch (e) {
        addToast({
          header: `Error! Safe Delete was NOT changed`,
          icon: "danger",
        });

        throw new Error("[toggleSafeDelete.js] Can not set Preferences");
      }

      setSafeDelete(false);

      addToast({
        header: "Safe Delete was disabled",
        icon: "success",
      });
    }
  } else {
    try {
      preferences.setPreference("interfaceOptions", { safeDelete: true });
    } catch (e) {
      addToast({
        header: `Error! Safe Delete was NOT changed`,
        icon: "danger",
      });

      throw new Error("[toggleSafeDelete.js] Can not set Preferences");
    }

    setSafeDelete(true);
    addToast({
      header: "Safe Delete was enabled",
      icon: "success",
    });
  }
};
