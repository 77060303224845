"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForLoopInAdjacencyList = void 0;
/**
 * Uses one of the existing algorithms to see if the directed graph has any loops, returns the first found loop as an array of nodes
 * @param {object} adjacencyList - an object containing all nodes (automation steps) and their outgoing and incoming edges
 * see getSmartValueAdjacencyList for the format of the adjacency list
 * @returns {{message: string, status: string, loopNodes: *[]}|{message: string, status: string}}
 */
const checkForLoopInAdjacencyList = ({ adjacencyList }) => {
    // Check arguments
    if (!adjacencyList) {
        return {
            status: "warning",
            message: "Could not check for loops in automation steps!",
        };
    }
    // Do a depth first search to find any loops that may exist within the automation step nodes
    const visitedNodesGlobal = {};
    // Perform loop search until all disconnected graphs have been visited
    while (Object.keys(visitedNodesGlobal).length < Object.keys(adjacencyList).length) {
        // Find a root node to start the search from
        let rootNode = null;
        for (const nodeName in adjacencyList) {
            const node = adjacencyList[nodeName];
            if (Array.isArray(node?.incoming) && node.incoming.length === 0 && !visitedNodesGlobal.hasOwnProperty(nodeName)) {
                rootNode = node;
                break;
            }
        }
        // If there is no root, there is upper level dependency loop pick any unvisited node to find the steps associated with the loop
        if (!rootNode) {
            for (const nodeName in adjacencyList) {
                if (!visitedNodesGlobal.hasOwnProperty(nodeName)) {
                    rootNode = adjacencyList[nodeName];
                    break;
                }
            }
        }
        const stack = [];
        const visitedNodes = {};
        const dfs = (currentNode) => {
            // Add the current node to the stack
            stack.push(currentNode);
            // check to see if the current node has been visited before (if so, there is a loop)
            if (visitedNodes.hasOwnProperty(currentNode.name)) {
                return true;
            }
            visitedNodes[currentNode.name] = true;
            visitedNodesGlobal[currentNode.name] = true;
            // loop through current node's outgoing nodes in search of a loop
            if (Array.isArray(currentNode?.outgoing) && currentNode.outgoing.length > 0) {
                for (const outgoingNodeName of currentNode.outgoing) {
                    const outgoingNode = adjacencyList[outgoingNodeName];
                    const foundLoop = dfs(outgoingNode);
                    // if a loop is found, return true to preserve the stack
                    if (foundLoop) {
                        return true;
                    }
                }
            }
            // Return false if code haven't found a loop at this point
            return false;
        };
        // Perform the depth first traversal of the graph to find a loop
        const foundLoop = dfs(rootNode);
        if (foundLoop) {
            //Isolate the looped nodes only
            const loopNodes = [];
            const startNodeName = stack[stack.length - 1].name;
            for (let i = stack.length - 1; i >= 0; i--) {
                const isFirstIteration = i === stack.length - 1;
                const node = stack[i];
                loopNodes.push(node.name);
                if (node.name === startNodeName && !isFirstIteration) {
                    break;
                }
            }
            // Loops must contain at least 2 entries (even if the loop is a single node)
            if (loopNodes.length < 2) {
                return {
                    status: "error",
                    message: "The automation steps contain a loop that cannot be displayed!",
                    loopNodes,
                };
            }
            // Return the error response
            return {
                status: "error",
                message: `The automation steps contain a dependency loop! (${loopNodes.join(" -> ")})`,
                loopNodes,
            };
        }
    }
    return {
        status: "success",
        message: "There are no automation steps that create a dependency loop!",
    };
};
exports.checkForLoopInAdjacencyList = checkForLoopInAdjacencyList;
