"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleListQueryBy = void 0;
/**
 * Retrieves data from the database with the given parameters (can optionally pre-process the data)
 * @param queryFunction - function to make an API call
 * @param query - graphql query
 * @param variables - variables for running a query
 * @param filter - filter for the query
 * @param limit - max number of items per request
 * @param nextToken - pagination string
 * @param setNextToken - set manually pagination string
 * @param getDataByNextToken - get data chunks by a next token
 * @param normalizeData - pre-process data before return
 * @param normalizeRowData - pre-process data by a row before return
 * @param setIsLoading - function for setting the loading state
 */
const handleListQueryBy = async ({ queryFunction, query, variables = {}, filter, limit, nextToken, setNextToken, getDataByNextToken, normalizeData, normalizeRowData, setIsLoading, }) => {
    console.log(`Starting ListQueryBy function. Time: ${new Date().toISOString()}`);
    if (setIsLoading) {
        setIsLoading(true);
    }
    const list = [];
    try {
        const getData = async () => {
            if (setNextToken && nextToken) {
                setNextToken(nextToken);
            }
            const data = await queryFunction(query, {
                ...variables,
                filter,
                nextToken: nextToken || undefined,
                limit: limit ?? 1000,
            });
            if (Array.isArray(data?.errors) && data.errors.length > 0) {
                console.error("[handleListQueryBy] Errors: ", JSON.stringify(data.errors, null, 2));
            }
            const typeName = Object.keys(data?.data)[0];
            if (!typeName) {
                console.error("[handleListQueryBy] Cannot get data");
                return;
            }
            const listArray = data?.data?.[typeName]?.items;
            if (listArray?.length) {
                let processedData = listArray;
                if (normalizeData) {
                    processedData = normalizeData(listArray);
                }
                else if (normalizeRowData) {
                    processedData = listArray.map(normalizeRowData);
                }
                getDataByNextToken?.(processedData);
                list.push(...processedData);
            }
            nextToken = data?.data[typeName]?.nextToken;
            if (nextToken) {
                await getData();
            }
        };
        await getData();
    }
    catch (err) {
        setIsLoading?.(false);
        console.error("Error in handleListQueryBy: ", err);
    }
    finally {
        if (setIsLoading) {
            setIsLoading(false);
        }
    }
    console.log(`Finished ListQueryBy function. Time: ${new Date().toISOString()}`);
    return normalizeData ? normalizeData(list) : list;
};
exports.handleListQueryBy = handleListQueryBy;
