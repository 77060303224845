import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import AccordionCard from "../../../utils/GenericComponents/AccordionCard/components/AccordionCard";
import { useGUID } from "../../functional/useGUID";

/**
 * A utility hook for displaying an Accordion
 * @param items - accordion data (see below for details)
 * @param {string} [titleFlexBasis=50%] - determines the flex size of the title in an accordion,
 * can set to a lower number if all titles are small so that the subtitles and header buttons get more space
 * @param {object[]} items[] - an accordion item
 * @param {string | JSX.Element} items[].icon - an accordion item icon, can be a component or className string
 * @param {string} items[].title - the main text for the accordion
 * @param {string} items[].subTitle - secondary text
 * @param {boolean} items[].defaultExpanded - if true, the accordion item defaults to an open state
 * @param {JSX.Element} items[].component - component rendered in the accordion body
 * @param {string} items[].dataTestId - data-testid for the accordion item
 *
 * @param {boolean} [unmountOnExit=true] - passed down to AccordionCard,
 * NOTE: this option needs to be set to FALSE for when using grids with preselected items
 *
 * @param {object} details - instance of the useDetails hook. Used to pass references for data, callback functions, etc
 * @param {boolean} [initialMount = true] - if true, allows the component to be mounted before opened (unmountOnExit has priority)
 * @returns {{display: JSX.Element}}
 */
export const useAccordion = ({
  items = [],
  titleFlexBasis = "50%",
  unmountOnExit = true,
  details = {},
  initialMount = true,
}) => {
  /**
   * Handles the icon, if the param is a string treats is like a className
   * @param item
   * @returns {JSX.Element|*}
   */
  const handleIcon = (item) => {
    if (!isNullOrUndefined(item?.icon)) {
      if (typeof item.icon === "string") {
        return <i className={item.icon} />;
      } else return item.icon;
    }
  };

  const [guid] = useGUID();

  const display = (
    <div style={{ width: "100%" }}>
      {items &&
        items?.map(
          (item, index) =>
            !item?.hidden && (
              <AccordionCard
                key={guid + index}
                id={item.id || `accordion-card-${index}`}
                icon={handleIcon(item)}
                badgeContent={item?.badgeContent}
                title={item?.title ?? `Group #${index}`}
                accordionTitle={item?.title ?? `Group #${index}`}
                titleFlexBasis={titleFlexBasis}
                subTitle={item?.subTitle}
                defaultExpanded={item?.defaultExpanded}
                completion={item?.completion && item.completion()}
                headerButtons={item?.headerButtons ?? []}
                disabled={item?.disabled ?? false}
                initialMount={initialMount}
                unmountOnExit={isNullOrUndefined(item?.unmountOnExit) ? unmountOnExit : item.unmountOnExit}
              >
                {item.component &&
                  React.cloneElement(item.component, {
                    ...item,
                    ...details,
                    title: undefined,
                    ...item?.forcedComponentProps,
                  })}
              </AccordionCard>
            ),
        )}
    </div>
  );

  return {
    display,
  };
};
