import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../hooks/views/useForm/useForm";

/**
 * Form for saving a new Custom Query object to the database
 * @param {string} organizationID - the id of the currently selected organization
 * @param {boolean} isGlobalQuery - if TRUE, query is saved as a template
 * @param {object} queryConfig - the initial query config value to use for saving data
 * @param {object} props - other props to pass through to useForm hook
 * @returns {{input: {}, display: *}}
 */
export const useSaveCustomQuery = ({
  organizationID: initOrganizationID,
  isGlobalQuery = false,
  queryConfig,
  callback,
  ...props
}) => {
  const { createMutation } = generateGraphql("CustomQuery", [
    "name",
    "description",
    "graphql",
    "filters",
    "typename",
    "fields",
    "queryConfig",
  ]);
  const module = isGlobalQuery ? modules.ADMINISTRATOR : modules.ORGANIZATION_MANAGER;
  const resource = resources.CUSTOM_QUERY;
  const organizationID = isGlobalQuery ? "TEMPLATE" : initOrganizationID;

  const formHook = useForm({
    organizationID,
    module,
    resource,
    typename: "CustomQuery",
    updateInputFunction: (input) => {
      const query = input?.queryConfig;
      const newQueryConfig = JSON.stringify(query || {});
      return {
        ...input,
        queryConfig: newQueryConfig,
      };
    },
    mutation: createMutation,
    fieldConfig: {
      name: {
        label: "Name",
        tooltip: "Give this Custom Query a simple name.",
        required: true,
      },
      description: {
        label: "Description",
        tooltip: "Optional: Gives this Custom Query a more detailed description",
      },
      typename: {
        label: "Typename",
        tooltip: "The root resource that is being queried",
        disabled: true,
      },
      queryConfig: {
        label: "Query Config",
        defaultValue: queryConfig,
        inputType: "custom",
        customConfig: {
          component: <div>{JSON.stringify(queryConfig, null, 2)}</div>,
        },
        isHidden: true,
      },
    },
    callback,
    ...props,
  });

  return {
    ...formHook,
  };
};
