"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.omitDeep = void 0;
const lodash_1 = require("lodash");
/**
 * Will remove all occurrences of a field from an object, and its children objects.
 * NOTE: make sure to copy the object in case the field to delete needs to be used somewhere else
 * REFERENCE: https://stackoverflow.com/questions/39085399/lodash-remove-items-recursively
 * @param {object} obj - deep nested object with fields to remove
 * @param {string} field - the field to remove from all nested objects (and top level as well)
 */
const omitDeep = ({ obj, field }) => {
    // Check arguments
    if (!obj || !(0, lodash_1.isObject)(obj))
        return obj;
    if (!field || typeof field !== "string")
        return obj;
    // Perform deletions
    (0, lodash_1.forIn)(obj, (value, key) => {
        if ((0, lodash_1.isObject)(value)) {
            (0, exports.omitDeep)({ obj: value, field });
        }
        else if (key === field) {
            delete obj[key];
        }
    });
    return obj;
};
exports.omitDeep = omitDeep;
