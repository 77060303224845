/**
 * Checks if the input is valid.
 *  - If required, an input cannot be null or an empty string
 *  - If not required, an input can be null or an empty string
 * @param {boolean} [required = false] - if this is required or not
 * @param {*} value - the value to check for null or empty
 * @returns {boolean}
 */
export const isValidInput = ({ required = false, value }) => {
  return !(required && (value === null || value === ""));
};
