/**
 * Updates the raw input before it's passed to the rest of the function
 *
 * @param {string|number|boolean} value
 * @param {boolean} [forceLowercase]
 * @param {function} [updateInputFunction]
 * @param {function} [customFormat]
 * @param {function} [setValue]
 * @returns {string}
 */
export const handleUpdateInput = async ({ value, forceLowercase, updateInputFunction, customFormat, setValue }) => {
  /**
   * Mutate the value based on configurations
   */
  let finalValue = value;

  /**
   * Forces the value to be lower case
   */
  if (forceLowercase) {
    finalValue = value.toLowerCase();
  }

  /**
   * Pipes the finalValue through the optional updateInputFunction
   */
  if (updateInputFunction && typeof updateInputFunction === "function") {
    finalValue = await updateInputFunction(finalValue);
  }

  /**
   * If a customFormat function is passed in, update the current value with it
   */
  if (finalValue && customFormat && typeof customFormat === "function") {
    setValue(customFormat(finalValue));
  } else if (finalValue) {
    /**
     * If no customFormat function is passed in, set the current value to the final value
     */
    setValue(finalValue);
  }

  return finalValue;
};
