import React, { useContext, useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useTable } from "../../../../../../../hooks/views/useTable";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import ResetButton from "../../../../../../../utils/GenericComponents/buttons/ResetButton";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";

import ErmRiskItem from "./ErmRiskItem";

/**
 * Displays the Enterprise Risk results for a System.
 * @param system
 * @returns {JSX.Element}
 * @constructor
 */
const ErmRisksWithAssociatedBusinessRisks = ({ system }) => {
  const { riskScore, monteCarloResults, isLoadingMonteCarlo } = useContext(MonteCarloContext);

  const [riskScores, setRiskScores] = useState({});

  useEffect(() => {
    if (system && monteCarloResults) {
      setScores();
    }
  }, [system, monteCarloResults]);

  const setScores = async () => {
    if (riskScore) {
      setRiskScores({ ...riskScore });
    }
  };

  const tableHook = useTable({
    data: riskScores.enterpriseRisks,
    fields: ["name", "businessRisk", "residualRisk", "riskRating"],
    fieldNameDictionary: {
      businessRisk: "Greatest Business Risk",
      residualRisk: "Residual Risk",
      riskRating: "Risk Rating",
    },
    rowComponent: <ErmRiskItem greatestEnterpriseRisk={riskScores && riskScores.greatestEnterpriseRisk} />,
    config: { borderless: true },
    disableRoleChecking: true,
  });

  useEffect(() => {
    if (riskScores && riskScores.enterpriseRisks) {
      tableHook.setData(
        [...riskScores.enterpriseRisks].sort((a, b) => {
          return b.residualRisk - a.residualRisk;
        }),
      );
    }
  }, [riskScores, riskScores.enterpriseRisks]);

  return (
    <div>
      <ResetButton resetFunction={setScores} />
      <DataLoader
        isEnoughData={!isNullOrUndefined(riskScores?.enterpriseRisks)}
        isLoading={isLoadingMonteCarlo}
        dataMessage="There is not enough information to calculate Risk Scores"
      >
        <div>{tableHook.display}</div>
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(ErmRisksWithAssociatedBusinessRisks);
