import React from "react";

import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { useTransferList } from "../../../../hooks/views/useTransferList/useTransferList";
import { updateControlOverrideType } from "../../Evidence/enums/updateControlOverrideType";
import { submitRiskComplianceSyncJob } from "../../Evidence/functions/submitRiskComplianceSyncJob";
import { useCreateEvidence } from "../../Evidence/hooks/useCreateEvidence";
import { handleLinkingEvidences } from "../functions/handleLinkingEvidences";
import { handleUnlinkingEvidences } from "../functions/handleUnlinkingEvidences";

import { usePopulateControlEvidences } from "./usePopulateControlEvidences";
import { usePopulateExistingEvidences } from "./usePopulateExistingEvidences";

/**
 * Allows the user to link Evidences to a Control
 *
 * @param {string} organizationID - Organization ID of the current organization
 * @param {Control} control - the control that is being adjusted
 * @param {function} [resetFunction] - handler for refreshing the item after the adjustment
 * @param {function} [toggleModal] - handler for closing a modal after the adjustment
 * @param {boolean} [isTemplate=false] - if true then will
 * @returns {{display: JSX.Element}}
 */
export const useControlEvidenceLinking = ({
  organizationID,
  control,
  resetFunction,
  toggleModal,
  isTemplate = false,
}) => {
  const createEvidenceCallback = (item) => {
    transferList.setRightItems((notLinked) => [...notLinked, item]);
    transferList.createResourceModal.setModalIsOpen(false);
  };

  const createEvidenceHook = useCreateEvidence({
    organizationID,
    getNewItem: createEvidenceCallback,
    isTemplate,
  });

  const pleaseWait = usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 1,
    confirmationText: "Congratulations, you've successfully Linked and Unlinked Evidence!",
    steps: [
      {
        id: "unlink",
        text: "Unlinking Evidence",
      },
      {
        id: "link",
        text: "Link Evidence",
      },
    ],
  });

  /**
   * Performs the operation that links and unlinks items
   */
  const handleSubmit = async () => {
    pleaseWait.setModalIsOpen(true);
    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    await handleUnlinkingEvidences(transferList.rightItems, control);

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    await handleLinkingEvidences(transferList.leftItems, control, organizationID);

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setFinished(true);
    resetFunction && resetFunction();
    toggleModal && toggleModal();

    await submitRiskComplianceSyncJob({
      evidenceID: null,
      controlOverrideID: control?.id,
      organizationID,
      overrideType: updateControlOverrideType.GENERAL_LINKS_UPDATED,
    });
  };

  const transferList = useTransferList({
    handleSubmit,
    leftTitle: "Linked Evidence",
    rightTitle: "Available Evidence",
    createResourceComponent: createEvidenceHook.display,
    typename: "Evidence",
  });

  usePopulateExistingEvidences(control, transferList.setRightItems, organizationID);

  usePopulateControlEvidences(control, transferList.setLeftItems);

  const display = (
    <span>
      {pleaseWait.modal}
      {transferList.display}
    </span>
  );

  return {
    display,
  };
};
