export interface ModalBodyWrapperType {
  children: string | JSX.Element;
}
/**
 * A wrapper for standard modal where the body is just centered text
 */
const ModalBodyWrapper = ({ children }: ModalBodyWrapperType): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        padding: "5em",
        textAlign: "center",
      }}
    >
      {children}
    </div>
  );
};

export default ModalBodyWrapper;
