"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validateRoleConfig_1 = require("../utils/validateRoleConfig");
/**
 * Updates the specified function enabled state in the role config object
 * @param {object} roleConfig - general role object containing user permission settings
 * @param {string} functionName - the function name to edit in the role config object (will do nothing if function doesnt exists)
 * @param {string} functionModule - optional module to use in-case the function doesn't exist in role config yet
 * @param {boolean} [enabled=true] - TRUE if need to enable the function permission and FALSE if need to disable access
 * @return {object} - modified roleConfig object with the requested permissions
 */
const updateFunctionPermissions = ({ roleConfig = {}, functionName, functionModule, enabled = true }) => {
    //If role config is originally invalid return the invalid value
    if (roleConfig === null || roleConfig === undefined) {
        return roleConfig;
    }
    // Doing this so that we don't mutate the passed in object
    const tempConfig = { ...roleConfig };
    //Check arguments
    if (!(0, validateRoleConfig_1.validateRoleConfig)(tempConfig) ||
        functionName === undefined ||
        functionName === null ||
        typeof functionName !== "string" ||
        enabled === undefined ||
        enabled === null ||
        typeof enabled !== "boolean") {
        console.log("Unable to update function permissions due to invalid arguments");
        return tempConfig;
    }
    //Modify the role config object to have requested permissions
    if (tempConfig.functions.hasOwnProperty(functionName)) {
        tempConfig.functions[functionName].enabled = enabled;
    }
    else if (functionModule) {
        //Only allow adding non-existent function permissions if its module is passed in
        tempConfig.functions[functionName] = {
            enabled,
            module: functionModule,
        };
    }
    else {
        console.log("Unable to update function permissions because it does not exist in the role object");
    }
    return tempConfig;
};
exports.default = updateFunctionPermissions;
