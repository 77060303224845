import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Submits a manual risk change to the backend
 * @param {object} input - the risk change form input
 * @param {string} organizationID - currently selected organization
 * @param {object} system - system for which the change is being made (if any)
 * @returns {Promise<void>}
 */
export const submitManualRiskChange = async ({ input, organizationID, system }) => {
  const { createMutation } = generateGraphql("RiskChange", ["name", "description", "date", "type"]);

  const newRiskChange = await ItemMutation({
    mutation: createMutation,
    input: {
      ...input,
      ownerGroup: organizationID,
    },
  });

  //link system and the new risk change if created from system context
  if (newRiskChange?.id && system?.id) {
    const { createMutation: createSystemChangeLink } = generateGraphql("SystemChangeLink");
    await ItemMutation(createSystemChangeLink, {
      systemID: system?.id,
      riskChangeID: newRiskChange?.id,
    });
  }
};
