import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useTestingFalsePositivesGrid } from "../../FalsePositives/hooks/useTestingFalsePositivesGrid";

const { listByQuery: listTargetFindingLinksByTargetID } = generateGraphql(
  "TargetFindingLink",
  ["id", "targetID", "falsePositive", "vulnerability", "target"],
  {
    vulnerability: `{
      id
      name
    }`,
    target: `{
      id
      ip
      hostName
      macAddress
    }`,
  },
  {
    indexName: "listTargetFindingLinksByTargetID",
    partitionKey: "targetID",
    partitionKeyType: "ID",
  },
);

/**
 * Get False Positives for a target
 * @param {object} item - target
 * @param {string} organizationID - organization ID
 * @returns {{gridDisplay: *, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, createResourceComponent: JSX, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, setItemsToCheck: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, selectedItems: [] | undefined}}
 */
export const useTargetFalsePositives = ({ item, organizationID }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.TARGET;

  const grid = useTestingFalsePositivesGrid({
    organizationID,
    viewingFrom: resources.TARGET,
    query: listTargetFindingLinksByTargetID,
    variables: {
      targetID: item?.id,
    },
    module,
    resource,
    primaryField: "vulnerabilityName",
  });

  return {
    ...grid,
  };
};
