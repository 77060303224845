import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVulnerabilityFalsePositives } from "../hooks/useVulnerabilityFalsePositives";

/**
 * @description Display FalsePositive Targets list for Finding component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const VulnerabilityFalsePositives = (props) => {
  const display = useVulnerabilityFalsePositives(props).gridDisplay;
  return <div style={{ height: "30em" }}>{display}</div>;
};

export default withOrganizationCheck(VulnerabilityFalsePositives);
