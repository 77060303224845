import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useMutation } from "../../../../../../../hooks/graphql/useMutation/useMutation";
import { usePleaseWaitModal } from "../../../../../../../hooks/views/usePleaseWaitModal";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { useListKeyRiskIndicators } from "../hooks/useListKeyRiskIndicators";

/**
 * Allows user to select a list of KRIs to have attached to a System
 * @param module {string} - Is the string of the module you want to access
 * @param resource {string} - Is the string of the resource you want to access
 * @param disableRoleChecking {boolean} - disables role checking
 * @param organizationID {string} - organization ID of the current user
 * @param props {object} - props passed from parent
 * @returns {JSX.Element}
 * @constructor
 */
const AttachKeyRiskIndicatorToSystem = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  organizationID,
  ...props
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const usePleaseWaitModalHook = usePleaseWaitModal();

  const field = "keyRiskIndicators";

  const createSystemRiskLinkMutation = useMutation({
    mutation: realTimeRiskGQL.createSystemRiskLink_attachKRIsToSystem,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const createSystemRiskThreatLinkMutation = useMutation({
    mutation: realTimeRiskGQL.createSystemRiskThreatLink_attachKRIsToSystem,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const createSystemControlCategory = useMutation({
    mutation: realTimeRiskGQL.createSystemControlCategoryLink_attachControlsToSystem,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const removeSystemControlCategory = useMutation({
    mutation: realTimeRiskGQL.deleteSystemControlCategoryLink_removeControlsFromSystem,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const removeSystemRiskLink = useMutation({
    mutation: realTimeRiskGQL.deleteSystemRiskLink_removeKRIsFromSystem,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const updateSystemRiskLinkDefaultRiskMapping = useMutation({
    mutation: realTimeRiskGQL.updateSystemRiskLink_defaultMapping,
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const CreateSystemRiskLink = async (system, risk, ownerGroup) => {
    const input = {
      systemRiskLinkSystemId: system.id,
      systemRiskLinkRiskId: risk.id,
      ownerGroup: ownerGroup,
    };

    return await createSystemRiskLinkMutation.editItem(input);
  };

  const CreateSystemControlCategoryLink = async (system, controlCategoryID, ownerGroup) => {
    const input = {
      ownerGroup: ownerGroup,
      riskControlCategoryID: controlCategoryID,
      systemID: system.id,
    };

    return await createSystemControlCategory.editItem(input);
  };

  const [currentKRIs, setCurrentKRIs] = useState([]);

  const system = props.system;

  const listKRIsHook = useListKeyRiskIndicators({
    organizationID,
    system,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    const tempArray = props.currentKeyRiskIndicators || [];
    const items = [];
    for (const item of tempArray) {
      items.push({ ...item.risk, linkId: item.id });
    }

    setCurrentKRIs([...items]);
    listKRIsHook.setSelectedItems([...items]);
    listKRIsHook.setShowSelectBoxes(true);
  }, [props.currentKeyRiskIndicators]);

  const handleSubmit = async () => {
    usePleaseWaitModalHook.setModalIsOpen(true);

    setDisableButton(true);

    // Add KRIs to System
    for (const keyRiskIndicator of listKRIsHook.selectedItems) {
      const index = currentKRIs.findIndex((item) => item.id === keyRiskIndicator.id);
      if (index === -1) {
        async function createSystemRiskLinkAndUpdateMapping(system, keyRiskIndicator, organizationID) {
          const data = await CreateSystemRiskLink(system, keyRiskIndicator, organizationID);
          const input = {
            id: data?.id,
            riskMapping: { ...keyRiskIndicator.defaultRiskMapping },
            cia: {
              confidentiality: true,
              integrity: true,
              availability: true,
            },
            probabilityModifier: 0.0,
          };
          await updateSystemRiskLinkDefaultRiskMapping.editItem(input);
          return data;
        }

        const systemRiskLink = await createSystemRiskLinkAndUpdateMapping(system, keyRiskIndicator, organizationID);

        await CreateSystemControlCategoryLink(system, keyRiskIndicator.controlCategoryID, organizationID);

        try {
          // Get all Threats linked to Risks at the enterprise level
          const threatLinks = keyRiskIndicator?.threatLinks?.items || [];

          // Copy these links to the SystemRiskLinks
          for (const threatLink of threatLinks) {
            const threat = threatLink.threat;
            if (!isNullOrUndefined(threat?.id)) {
              // Create the System Risk Threat Link
              await createSystemRiskThreatLinkMutation.editItem({
                systemRiskThreatLinkSystemRiskLinkId: systemRiskLink.id,
                systemRiskThreatLinkThreatId: threat.id,
                ownerGroup: organizationID,
              });
            }
          }
        } catch (e) {
          ErrorLogger("Couldn't attach Threats to System Risk Links! ", e);
        }
      }
    }

    // Remove assets from system
    for (const currentKRI of currentKRIs) {
      const index = listKRIsHook.selectedItems.findIndex((item) => item.id === currentKRI.id);
      if (index === -1) {
        const input = {
          id: currentKRI.linkId,
        };
        await removeSystemRiskLink.editItem(input);

        const controlCategoryLinks =
          system && system.controlCategories && system.controlCategories.items ? system.controlCategories.items : [];
        const controlCategoryLink = controlCategoryLinks.find(
          (x) => x.riskControlCategoryID === currentKRI.controlCategoryID,
        );

        if (controlCategoryLink) {
          await removeSystemControlCategory.editItem({
            id: controlCategoryLink.id,
          });
        }
      }
    }

    usePleaseWaitModalHook.setModalIsOpen(false);

    props.resetFunction?.(true);
    props.toggleModal?.();
  };

  return (
    <div>
      <Button
        color="success"
        disabled={isEqual(currentKRIs, listKRIsHook.selectedItems) || disableButton}
        onClick={() => handleSubmit()}
        style={{ marginBottom: "1em" }}
      >
        Submit
      </Button>
      {listKRIsHook.display}
      {usePleaseWaitModalHook.modal}
    </div>
  );
};

export default withOrganizationCheck(AttachKeyRiskIndicatorToSystem);
