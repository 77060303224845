import React from "react";

import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

const VCISODashboard = React.lazy(() => import("@views/ActionCenter/ActionCenter"));

export const actionCenterNavigation: NavigationModule = {
  moduleName: modules.ACTION_CENTER,
  sectionName: NavigationSectionName.TOOLS,
  name: "Action Center",
  path: "/action_center",
  deprecatedPaths: ["/metrics/metrics"],
  defaultRoutePath: "",
  icon: IconName.ACTION,
  routes: [
    {
      path: "",
      name: "Action Center",
      component: VCISODashboard,
    },
  ],
};
