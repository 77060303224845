import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { DeleteS3Object } from "../../../../utils/Functions/S3Storage/DeleteS3Object";

/**
 * @description Delete a document from database and S3 bucket
 * @param {object} item - document
 * @returns {Promise<null>}
 */
export const deleteDocument = async (item) => {
  if (!item || !item.id) {
    return null;
  }

  const { getQuery, deleteMutation } = generateGraphql("Document", ["file"], {
    file: `{ bucket region key }`,
  });

  const document = await ItemQuery(getQuery, item.id);

  await ItemMutation(deleteMutation, { id: item.id });

  const file = document?.file;
  if (file && file.bucket && file.key)
    await DeleteS3Object({
      bucketName: file.bucket,
      objectKey: file.key,
      organizationID: document?.ownerGroup,
    });
};
