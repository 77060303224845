import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import MitreAttackSubTechniqueDetails from "../components/MitreAttackSubTechniqueDetails";

/**
 * @description Display list of Mitre Attack Sub Techniques hook
 * @param {string} organizationID - selected organization ID
 * @param {object[]} fields - fields to display
 * @param {object} props - other props for the hook
 */
export const useMitreAttackSubTechniquesDataGrid = ({ organizationID, fields = [], ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.MITRE_ATTACK_TECHNIQUE;

  return useDataGrid({
    module,
    resource,
    detailsTitle: "Mitigation Details",
    detailsComponent: <MitreAttackSubTechniqueDetails />,
    title: "Mitre Att&ck Sub Techniques",
    headerIcon: "icon-layers",
    persistenceUUID: "cef4b959-234a-42f1-9e19-04f66714f2df",
    fields,
    organizationID,
    ...props,
  });
};
