/**
 * Generates a persistence id for the main display grid of useNestedDataCar
 * NOTE: required for the delete function of the create grid to not replace the delete function of the main grid
 * @param {string} typename - the resource name of the
 * @param {string} parentTypename - the
 * @param field
 * @return {string}
 */
export const getConnectionsGridPersistenceID = ({ typename, parentTypename, field }) => {
  const typenamePart = `${typename ?? ""}To${parentTypename ?? ""}`;
  const fieldPart = `On${field ?? ""}`;
  return `${typenamePart + fieldPart}ConnectionsGrid`;
};
