import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useAutomationsGrid } from "../hooks/useAutomationsGrid";

/**
 * Component instance of the Automations Grid
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AutomationsGrid = (props) => useAutomationsGrid(props).display;

export default withOrganizationCheck(AutomationsGrid);
