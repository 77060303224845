import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext } from "react";
import { v4 as uuid } from "uuid";

import { SERIES_TYPE } from "@rivial-security/widget-utils";

import { genericResources } from "../../../../../../../../../../../definitions/constants/genericResources";
import CreateButton from "../../../../../../../../../../../utils/GenericComponents/CreateButton";
import { createWidgetActions } from "../../../../../../reducers/createWidgetActions";
import { CreateWidgetContext } from "../../../../../../useCreateWidget";
import { CategoryNameField } from "../CategoryNameField";
import SeriesField from "../SeriesField/SeriesField";

import "./SeriesNode.css";

/**
 * Component representing a single series going into pie or bar chart widget
 * @param {object} seriesItem - the series state from widget config
 * @param {number} index - the series position in array of all series
 * @returns {JSX.Element}
 */
const SeriesNode = ({ seriesItem, index }) => {
  const { dispatch } = useContext(CreateWidgetContext);

  return (
    <Card>
      <CardContent>
        <div className={"widget-series-content"} style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
          {/*Series Name Field*/}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p>{`Series #${index + 1}`}</p>
            <div style={{ flex: 1 }} />
            <div
              className={"delete-widget-series-icon"}
              onClick={() => {
                dispatch({
                  type: createWidgetActions.DELETE_WIDGET_SERIES,
                  deleteSeriesInput: {
                    seriesItem,
                  },
                });
              }}
              title={"Delete this Series Item"}
            >
              <DeleteOutlineOutlinedIcon />
            </div>
          </div>
          {/*Series Type Drop Down*/}
          <FormControl fullWidth>
            <InputLabel id="series-type-select">Series Type</InputLabel>
            <Select
              labelId="series-type-select"
              id="series-type-select"
              value={seriesItem?.type || SERIES_TYPE.AUTO}
              label="Series Type"
              onChange={(e) => {
                dispatch({
                  type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
                  seriesInput: {
                    ...seriesItem,
                    type: e.target.value,
                  },
                });
              }}
            >
              <MenuItem value={SERIES_TYPE.AUTO}>Automatic</MenuItem>
              <MenuItem value={SERIES_TYPE.MANUAL}>Manual</MenuItem>
            </Select>
          </FormControl>
          {/*Field Selection - Auto*/}
          {seriesItem?.type === SERIES_TYPE.AUTO && (
            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
              <SeriesField
                label={"Categories Field"}
                seriesItem={seriesItem}
                path={["categoriesField"]}
                fieldRequirements={{
                  includeTypes: [genericResources.STRING],
                  allowMany: true,
                  allowOne: false,
                  requiredSibling: ["valuesField"],
                }}
              />
              <SeriesField
                label={"Values Field"}
                seriesItem={seriesItem}
                path={["valuesField"]}
                fieldRequirements={{
                  includeTypes: [genericResources.INT, genericResources.FLOAT],
                  allowMany: true,
                  allowOne: false,
                  requiredSibling: ["categoriesField"],
                }}
              />
            </div>
          )}

          {/*Field Selection - Manual*/}
          {seriesItem?.type === SERIES_TYPE.MANUAL && (
            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
              {Array.isArray(seriesItem?.categories) &&
                seriesItem.categories.map((category, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5em",
                        alignItems: "center",
                      }}
                    >
                      <CategoryNameField
                        label={"Category"}
                        seriesItem={seriesItem}
                        path={["categories", index, "name"]}
                      />
                      <SeriesField
                        label={"Value Field"}
                        seriesItem={seriesItem}
                        path={["categories", index, "valueField"]}
                        sx={{ container: { flex: 1 } }}
                        fieldRequirements={{
                          includeTypes: [genericResources.INT, genericResources.FLOAT],
                          allowMany: false,
                          allowOne: true,
                        }}
                      />
                    </div>
                  );
                })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CreateButton
                  typename={"Category"}
                  text={"Add a Category"}
                  onClick={() => {
                    dispatch({
                      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
                      seriesInput: {
                        ...seriesItem,
                        categories: [
                          ...(seriesItem?.categories || []),
                          {
                            id: uuid(),
                          },
                        ],
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default SeriesNode;
