import { Auth } from "@aws-amplify/auth";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { setPreferredMFA } from "../../../../views/Settings/SettingPages/LoginOptions/functions/setPreferredMFA";
import { UIContext } from "../../../Context/UIContext";
import { ErrorLogger } from "../../../EventLogger";
import { updateUserPreferredMFA } from "../../../Functions/MFA/updateUserPreferredMFA";

/**
 * @description Setup SMS MFA for a user
 * @param {object} user - The user to setup SMS MFA for.
 * @param {function} changeState - The function to change the sign in state.
 * @returns {JSX.Element}
 * @constructor
 */
const MFASetupSMS = ({ user, changeState }) => {
  const { addToast } = useContext(UIContext);
  const history = useHistory();

  return (
    <div>
      <Alert color={"warning"} icon={false}>
        Please Note: after setting up Text Message MFA, <br />
        you will be logged out from all Platform sessions <br />
        including this current one and will need to log <br />
        back in using an MFA code.
      </Alert>
      <br />
      <Button
        variant="contained"
        onClick={async (e) => {
          e.preventDefault();
          try {
            setPreferredMFA({
              authType: "SMS",
              user,
              challengeAnswer: "",
              addToast,
            });

            await updateUserPreferredMFA({ preferredMFA: "SMS", user });
            /**
             * Logout user after setting up SMS MFA
             */
            await Auth.signOut({ global: true })
              .then(() => {
                changeState("signedOut", null);
                history.push("/");
                window.location.reload();
              })
              .catch((err) => {
                ErrorLogger("Failed to signOut: ", err);
              });
          } catch (e) {
            ErrorLogger(`Failed to setup SMS MFA: ${e}`);
          }
        }}
        title="This action will setup your preferred MFA to SMS"
      >
        Use SMS MFA
      </Button>
    </div>
  );
};

export default MFASetupSMS;
