import { useEffect } from "react";

/**
 * Populates the list of associated Controls for an Evidence item
 *
 * @param {Evidence} evidence - the evidence item
 * @param {function} setItems - handler for setting the list of associated controls
 * @param {string} controlSetID - only shows controls from a single control set at a time
 */
export const usePopulateEvidenceControls = (evidence, setItems, controlSetID) => {
  return useEffect(() => {
    if (evidence?.controls?.items) {
      const d = [];

      for (const controlLink of evidence.controls.items) {
        if (controlLink?.control?.controlControlSetId === controlSetID) {
          d.push(controlLink.control);
        }
      }

      setItems(d);
    }
  }, [evidence, controlSetID]);
};
