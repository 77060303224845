import i18next from "i18next";
import { initReactI18next, Trans } from "react-i18next";

import resources, { Languages } from "./resources";
import { getVariationOfAorAn } from "./utils/getVariationOfAorAn";

i18next
  .use(initReactI18next)
  .init({
    lng: Languages.EN, // if you're using a language detector, do not define the lng option
    resources,
    defaultNS: "common",
    supportedLngs: Object.values(Languages),
    fallbackLng: Languages.EN,
    interpolation: {
      // handle the case of using "a" or "an" in the translation only for English
      // https://stackoverflow.com/questions/43288898/when-using-i18next-switch-between-a-and-an-depending-on-the-first-letter-in
      format: (value, format, lng) => getVariationOfAorAn({ value, format, lng }),
      skipOnVariables: false,
      escapeValue: false,
    },
  })
  .then(() => void 0)
  .catch((error) => {
    throw error;
  });

export { Trans };
export default i18next;
