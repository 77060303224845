import React from "react";

import { enumToDropdownData, isNonEmptyArray } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import SelectNewItemsDataGrid from "../../../../utils/Connections/components/SelectNewItemsDataGrid";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import PointOfContactDataGrid from "../../PointOfContacts/components/PointOfContactDataGrid";
import PointOfContactDetails from "../../PointOfContacts/components/PointOfContactDetails";
import { CustomPointOfContactEmailField } from "../../PointOfContacts/customFields/CustomPointOfContactEmailField";
import QuestionnaireAssigneeResponseStatusField from "../components/CustomFields/QuestionnaireAssigneeResponseStatusField/QuestionnaireAssigneeResponseStatusField";
import { questionnaireAssigneeStatus } from "../constants/questionnaireAssigneeStatus";
import { assignPointsOfContactToQuestionnaire } from "../functions/assignPointsOfContantToQuestionnaire";
import { deleteQuestionnaireAssignee } from "../functions/deleteQuestionnaireAssignee";
import { getQuestionnaireAssignedPointsOfContact } from "../functions/getQuestionnaireAssignedPointsOfContact";

/**
 * Grid for displaying and manage a single questionnaires assignees
 * @param {string} organizationID - the organization that the questionnaire is a part of
 * @param {string} questionnaireID - the questionnaire for which to display assignees
 * @param {function} resetFunctionExternal - function to refresh any parent component data on change of assignees
 * @param {object} props - any additional props to pass into
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object[]|Object, setData: function(*): void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useQuestionnaireAssignees = ({ organizationID, questionnaireID, resetFunctionExternal, ...props }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.QUESTIONNAIRE;
  const roleConfig = {
    module,
    resource,
  };

  const { addToast, updateToast } = useUIContext();

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      return await getQuestionnaireAssignedPointsOfContact({ questionnaireID });
    },
    setData: (newAssignees) => {
      grid?.setData(newAssignees);
    },
    dependencies: [questionnaireID],
  });

  const fields = [
    {
      name: "firstName",
      flex: 1,
      minWidth: 100,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "lastName",
      flex: 1,
      minWidth: 100,
    },
    {
      name: "email",
      component: <CustomPointOfContactEmailField module={module} resource={resource} disableEdits={true} />,
      disablePropagation: true,
      width: 300,
      valueFormatter: (value) => {
        return value || "No Email Provided";
      },
    },
    {
      name: "questionnaireAssigneeStatus",
      friendlyName: "Status",
      isComputed: true,
      component: <QuestionnaireAssigneeResponseStatusField />,
      type: "singleSelect",
      valueOptions: enumToDropdownData({
        ENUM: questionnaireAssigneeStatus,
        overrides: {},
        textFieldName: "label",
        valueFieldName: "value",
      }),
    },
  ];

  const gridConfig = {
    fields,
    options: ["details", "delete"],
    typename: resources.POINT_OF_CONTACT,
    createResourceComponent: (
      <SelectNewItemsDataGrid
        GridComponent={PointOfContactDataGrid}
        submitButtonProps={{
          title: "Assign points of contacts to questionnaire",
          text: "Assign",
          dataTestId: "assign-points-of-contact-to-questionnaire-submit-button",
        }}
        getFilteredItems={() =>
          getQuestionnaireAssignedPointsOfContact({
            questionnaireID,
          })
        }
        filterItems={({ filteredItems, allItems }) => {
          const newItems = [];
          if (!isNonEmptyArray(allItems)) {
            return [];
          }

          if (!isNonEmptyArray(filteredItems)) {
            return allItems;
          }

          for (const item of allItems) {
            const isFound = filteredItems.find((filteredItem) => {
              return filteredItem?.pointOfContactID === item?.id;
            });

            if (isFound) {
              continue;
            }

            newItems.push(item);
          }

          return newItems;
        }}
        submitFunction={async ({ selectedItems }) => {
          await performToastOperation({
            addToast,
            updateToast,
            operation: async () => {
              await assignPointsOfContactToQuestionnaire({
                organizationID,
                questionnaireID,
                pointsOfContact: selectedItems,
              });
            },
            inProgressText: "Assigning Points of Contacts to Questionnaire",
            failedText: "Failed to assign Points of Contacts to Questionnaire",
            successText: "Successfully assigned Points of Contacts to Questionnaire",
            iconColor: "success",
          });
          resetFunctionExternal?.();
        }}
      />
    ),
    detailsComponent: (
      <StyledWrapper
        normalizeItem={({ item }) => {
          const newItem = { ...item };
          newItem.id = item.pointOfContactID;
          return newItem;
        }}
      >
        <PointOfContactDetails tableDisplay={true} />
      </StyledWrapper>
    ),
    route: "#/organization_manager/contacts/",
    deleteFunction: deleteQuestionnaireAssignee,
    persistenceUUID: "e5d6726a-4200-4ac8-906f-c99db1f2bba1",
    createResourceButtonText: "Assign",
    createResourceButtonTitle: "Add points of contacts that need to answer this Questionnaire",
    createItemModalHeader: "Assign Points of Contacts to Questionnaire",
    createResourceComponentWidth: "80vw",
    deleteButtonText: "Withdraw",
    deleteButtonTitle: "Remove points of contacts from answering this Questionnaire",
  };

  const cardConfig = {
    title: "Assignees",
    icon: "icon-people",
  };

  const grid = useDataGrid({
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
    resetFunction: () => {
      resetFunction?.();
      resetFunctionExternal?.();
    },
    isLoading,
  });

  return grid;
};
