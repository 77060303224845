import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useIncidentDataGrid } from "../../Incidents/hooks/useIncidentDataGrid";

/**
 * Displays a list of incidents for an org
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IncidentGrid = (props) => {
  return useIncidentDataGrid(props).display;
};

export default withOrganizationCheck(IncidentGrid);
