"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVAILABILITY_MAPPING = void 0;
const RATING_SCALE_1 = require("./RATING_SCALE");
/**
 * The mapping between rating scale and a percentage.
 * This is used to determine the availability rating as a percentage of Confidentiality or Integrity (whichever is greater)
 * @type {{string: number}}
 */
exports.AVAILABILITY_MAPPING = {
    [RATING_SCALE_1.RATING_SCALE.HIGH]: 1.0,
    [RATING_SCALE_1.RATING_SCALE.MEDIUM_HIGH]: 0.8,
    [RATING_SCALE_1.RATING_SCALE.MEDIUM]: 0.6,
    [RATING_SCALE_1.RATING_SCALE.LOW_MEDIUM]: 0.4,
    [RATING_SCALE_1.RATING_SCALE.LOW]: 0.2,
};
