import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Converts a phone number inputted by a user into a valid cognito phone number
 * @param {string} phoneNumber - the phone input string provided by the user
 */
export const getCognitoPhoneNumber = (phoneNumber) => {
  //Return empty phone number when the input is empty
  if (isNullOrUndefined(phoneNumber) || phoneNumber === "" || typeof phoneNumber !== "string") {
    return "";
  }

  //Strip any special and alphabetical characters (except for the + at the start)
  const hasPlusPrefix = phoneNumber.startsWith("+");
  const phoneNumberDigits = phoneNumber.replace(/\D/g, "");

  //Check number of digits to be between 10 and 15
  if (phoneNumberDigits.length < 10 || phoneNumberDigits.length > 15) {
    return "";
  }

  let resultPhoneNumber;

  //If input doesn't start with a + assuming the US country code (+1)
  if (!hasPlusPrefix) {
    if (phoneNumberDigits.length === 10) {
      resultPhoneNumber = `+1${phoneNumberDigits}`;
    } else if (phoneNumberDigits.length === 11 && phoneNumberDigits.startsWith("1")) {
      resultPhoneNumber = `+${phoneNumberDigits}`;
    } else {
      return ""; // not a valid american number
    }
  } else {
    resultPhoneNumber = `+${phoneNumberDigits}`;
  }

  return resultPhoneNumber;
};
