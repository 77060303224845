"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskConfig = void 0;
exports.getRiskConfig = `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      assetSize
      numberOfCustomers
      numberOfEmployees
      s3BucketName
      riskSetup {
        high
        mediumHigh
        medium
        lowMedium
        low
      }
      lossToleranceCurve {
        amount
        probability
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      config
    }
  }
`;
