import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorDataGrid } from "../hooks/useVendorDataGrid";

/**
 * Component instance that displays all Vendors for a specific organization
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VendorDataGrid = (props) => {
  return useVendorDataGrid({ dashboardDisplay: true, ...props }).display;
};

export default withOrganizationCheck(VendorDataGrid);
