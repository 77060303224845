import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { deleteSystemRiskLink_custom } from "../../../graphql/__risksGQL";

import { DeleteSystemRiskThreatLink } from "./DeleteSystemRiskThreatLink";

export const DeleteSystemRiskLink = async (systemRiskLink) => {
  if (!systemRiskLink) {
    ErrorLogger("[DeleteSystemRiskLink.js] Error: No SystemRiskLink was passed in");
    return null;
  }
  // Delete SystemRiskThreatLinks
  const systemRiskThreatLinks =
    systemRiskLink.threats && systemRiskLink.threats.items ? systemRiskLink.threats.items : [];

  for (const systemRiskThreatLink of systemRiskThreatLinks) {
    await DeleteSystemRiskThreatLink(systemRiskThreatLink);
  }

  // Delete the SystemRiskLink
  return await ItemMutation(deleteSystemRiskLink_custom, {
    id: systemRiskLink.id,
  });
};
