"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganizationS3BucketName = exports.putS3Object = void 0;
const client_s3_1 = require("@aws-sdk/client-s3");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Puts an object in an S3 bucket
 * @param bucketName - name of the S3 bucket
 * @param objectKey - key of the object in the S3 bucket
 * @param file - file to upload
 * @param organizationID - ID of the organization
 * @param appsyncEndpoint - GQL endpoint for appsync
 */
const putS3Object = async ({ bucketName, objectKey, file, organizationID, appsyncEndpoint, }) => {
    if (!bucketName && !organizationID) {
        throw new Error("[getSignedObjectUrl] Bucket name or organization ID is required");
    }
    if (!bucketName && organizationID) {
        const org = await (0, appsync_utils_1.GetQuery)({
            query: exports.getOrganizationS3BucketName,
            variables: { id: organizationID },
            appsyncEndpoint,
        });
        bucketName = org.s3BucketName ?? "";
    }
    const client = new client_s3_1.S3Client({
        region: process.env.AWS_REGION,
    });
    const command = new client_s3_1.PutObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
        Body: file,
    });
    try {
        return await client.send(command);
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
exports.putS3Object = putS3Object;
exports.getOrganizationS3BucketName = `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      s3BucketName
    }
  }
`;
