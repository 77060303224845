import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useObservationsGrid } from "../hooks/useObservationsGrid";

/**
 * Component used in the Findings Dashboard to display Observations
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ObservationsGrid = (props) => {
  return useObservationsGrid(props).display;
};

export default withOrganizationCheck(ObservationsGrid);
