/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const duplicateComplianceControls = /* GraphQL */ `
  query DuplicateComplianceControls($controls: [AWSJSON], $organizationID: ID) {
    duplicateComplianceControls(
      controls: $controls
      organizationID: $organizationID
    )
  }
`;
export const getControlSet = /* GraphQL */ `
  query GetControlSet($id: ID!) {
    getControlSet(id: $id) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      controls {
        nextToken
      }
    }
  }
`;
export const listControlSets = /* GraphQL */ `
  query ListControlSets(
    $filter: ModelControlSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControlRecommendation = /* GraphQL */ `
  query GetControlRecommendation($id: ID!) {
    getControlRecommendation(id: $id) {
      id
      observation
      recommendation
      rating
      controlID
      resolved
      dateResolved
      ownerGroup
      createdAt
      updatedAt
      control {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
    }
  }
`;
export const listControlRecommendations = /* GraphQL */ `
  query ListControlRecommendations(
    $filter: ModelControlRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlRecommendations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        observation
        recommendation
        rating
        controlID
        resolved
        dateResolved
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      subject
      body
      ownerGroup
      type
      date
      reporter
      status
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        body
        ownerGroup
        type
        date
        reporter
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelpArticle = /* GraphQL */ `
  query GetHelpArticle($id: ID!) {
    getHelpArticle(id: $id) {
      id
      title
      content
      route
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listHelpArticles = /* GraphQL */ `
  query ListHelpArticles(
    $filter: ModelHelpArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        route
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      name
      status
      description
      type
      ownerGroup
      module
      documents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        description
        type
        ownerGroup
        module
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportDocument = /* GraphQL */ `
  query GetReportDocument($id: ID!) {
    getReportDocument(id: $id) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      report {
        id
        name
        status
        description
        type
        ownerGroup
        module
        createdAt
        updatedAt
      }
      reportDocumentReportId
      updatedAt
    }
  }
`;
export const listReportDocuments = /* GraphQL */ `
  query ListReportDocuments(
    $filter: ModelReportDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        visibility
        createdAt
        ownerGroup
        reportDocumentReportId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportTemplate = /* GraphQL */ `
  query GetReportTemplate($id: ID!) {
    getReportTemplate(id: $id) {
      id
      name
      description
      type
      ownerGroup
      module
      creator
      key
      createdAt
      updatedAt
    }
  }
`;
export const listReportTemplates = /* GraphQL */ `
  query ListReportTemplates(
    $filter: ModelReportTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        ownerGroup
        module
        creator
        key
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSnapshot = /* GraphQL */ `
  query GetSnapshot($id: ID!) {
    getSnapshot(id: $id) {
      id
      ownerGroup
      name
      date
      systemStats {
        id
        ownerGroup
        name
        inherentLoss
        residualLoss
        whatIfLoss
        currentCostOfControls
        whatIfCostOfControls
        currentROI
        whatIfOverallROI
        whatIfAdditionalROI
        createdAt
        updatedAt
      }
      risksStats {
        nextToken
      }
      snapshotSystemId
      createdAt
      updatedAt
    }
  }
`;
export const listSnapshots = /* GraphQL */ `
  query ListSnapshots(
    $filter: ModelSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSnapshots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerGroup
        name
        date
        snapshotSystemId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      owner
      timeStamp
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        timeStamp
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($id: ID!) {
    getNotes(id: $id) {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      itemId
      controlNotesId
      riskControlNotesId
      assessmentNotesId
      createdAt
      updatedAt
    }
  }
`;
export const listNotess = /* GraphQL */ `
  query ListNotess(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        itemId
        controlNotesId
        riskControlNotesId
        assessmentNotesId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      name
      owner
      encrypted
      encryptionKey
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      action {
        id
        name
        dateCreated
        dateCompleted
        description
        status
        pastDueAt
        ownerGroup
        executionARN
        type
        priority
        module
        createdAt
        updatedAt
      }
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        encrypted
        encryptionKey
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecurityAppliance = /* GraphQL */ `
  query GetSecurityAppliance($id: ID!) {
    getSecurityAppliance(id: $id) {
      id
      applianceID
      ip
      port
      openVasUsername
      openVasPassword
      status
      destination
      trackingNumber
      checkOutDate
      checkInDate
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listSecurityAppliances = /* GraphQL */ `
  query ListSecurityAppliances(
    $filter: ModelSecurityApplianceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecurityAppliances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        status
        destination
        trackingNumber
        checkOutDate
        checkInDate
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      username
      email
      phoneNumber
      ownerGroup
      config
      accountType
      roleLinks {
        nextToken
      }
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      userLinks {
        nextToken
      }
      roleConfig
      precedence
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        roleConfig
        precedence
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVcisoAction = /* GraphQL */ `
  query GetVcisoAction($id: ID!) {
    getVCISOAction(id: $id) {
      id
      name
      dateCreated
      dueDate
      globalStatus
      status
      description
      type
      ownerGroup
      priority
      maturityIndicator {
        id
        name
        isTrue
        ownerGroup
        createdAt
        updatedAt
      }
      module
      createdAt
      updatedAt
    }
  }
`;
export const listVcisoActions = /* GraphQL */ `
  query ListVcisoActions(
    $filter: ModelVCISOActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVCISOActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        dueDate
        globalStatus
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaturityColumn = /* GraphQL */ `
  query GetMaturityColumn($id: ID!) {
    getMaturityColumn(id: $id) {
      id
      name
      ownerGroup
      notes {
        nextToken
        observationID
      }
      maturityMatrixLevels {
        nextToken
      }
      maturityGoal
      createdAt
      updatedAt
    }
  }
`;
export const listMaturityColumns = /* GraphQL */ `
  query ListMaturityColumns(
    $filter: ModelMaturityColumnFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaturityColumns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        maturityGoal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaturityMatrixLevel = /* GraphQL */ `
  query GetMaturityMatrixLevel($id: ID!) {
    getMaturityMatrixLevel(id: $id) {
      id
      name
      ownerGroup
      maturityColumn {
        id
        name
        ownerGroup
        maturityGoal
        createdAt
        updatedAt
      }
      maturityIndicators {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMaturityMatrixLevels = /* GraphQL */ `
  query ListMaturityMatrixLevels(
    $filter: ModelMaturityMatrixLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaturityMatrixLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaturityIndicator = /* GraphQL */ `
  query GetMaturityIndicator($id: ID!) {
    getMaturityIndicator(id: $id) {
      id
      name
      isTrue
      ownerGroup
      maturityMatrixLevel {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      actions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMaturityIndicators = /* GraphQL */ `
  query ListMaturityIndicators(
    $filter: ModelMaturityIndicatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaturityIndicators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        isTrue
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      name
      description
      status
      meetingDate
      meetingTime
      pointOfContacts {
        nextToken
      }
      organizer
      location
      duration
      isAllDay
      startTime
      endTime
      agendaItems {
        nextToken
      }
      documents {
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgendaItem = /* GraphQL */ `
  query GetAgendaItem($id: ID!) {
    getAgendaItem(id: $id) {
      id
      content
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAgendaItems = /* GraphQL */ `
  query ListAgendaItems(
    $filter: ModelAgendaItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgendaItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPointOfContactMeetingLink = /* GraphQL */ `
  query GetPointOfContactMeetingLink($id: ID!) {
    getPointOfContactMeetingLink(id: $id) {
      id
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPointOfContactMeetingLinks = /* GraphQL */ `
  query ListPointOfContactMeetingLinks(
    $filter: ModelPointOfContactMeetingLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointOfContactMeetingLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingDocument = /* GraphQL */ `
  query GetMeetingDocument($id: ID!) {
    getMeetingDocument(id: $id) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      meeting {
        id
        name
        description
        status
        meetingDate
        meetingTime
        organizer
        location
        duration
        isAllDay
        startTime
        endTime
        linkedResources
        ownerGroup
        createdAt
        updatedAt
      }
      meetingDocumentMeetingId
      updatedAt
    }
  }
`;
export const listMeetingDocuments = /* GraphQL */ `
  query ListMeetingDocuments(
    $filter: ModelMeetingDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        visibility
        createdAt
        ownerGroup
        meetingDocumentMeetingId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      name
      policyVersions {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listPolicys = /* GraphQL */ `
  query ListPolicys(
    $filter: ModelPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolicyVersion = /* GraphQL */ `
  query GetPolicyVersion($id: ID!) {
    getPolicyVersion(id: $id) {
      id
      description
      version
      status
      approvedBy
      approvalDate
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      reviewers {
        nextToken
      }
      policy {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listPolicyVersions = /* GraphQL */ `
  query ListPolicyVersions(
    $filter: ModelPolicyVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicyVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReviewer = /* GraphQL */ `
  query GetReviewer($id: ID!) {
    getReviewer(id: $id) {
      id
      reviewerPointOfContactId
      status
      reviewedDate
      ownerGroup
      policyVersionID
      policyVersion {
        id
        description
        version
        status
        approvedBy
        approvalDate
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listReviewers = /* GraphQL */ `
  query ListReviewers(
    $filter: ModelReviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reviewerPointOfContactId
        status
        reviewedDate
        ownerGroup
        policyVersionID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaturityMatrix = /* GraphQL */ `
  query GetMaturityMatrix($id: ID!) {
    getMaturityMatrix(id: $id) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMaturityMatrixs = /* GraphQL */ `
  query ListMaturityMatrixs(
    $filter: ModelMaturityMatrixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaturityMatrixs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ownerGroup
        defaultMatrix
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgramElement = /* GraphQL */ `
  query GetProgramElement($id: ID!) {
    getProgramElement(id: $id) {
      id
      name
      description
      programSections {
        nextToken
      }
      maturityMatrixID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listProgramElements = /* GraphQL */ `
  query ListProgramElements(
    $filter: ModelProgramElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        maturityMatrixID
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgramSection = /* GraphQL */ `
  query GetProgramSection($id: ID!) {
    getProgramSection(id: $id) {
      id
      name
      description
      implementationLevels {
        nextToken
      }
      currentLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      goalLevel {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      programElement {
        id
        name
        description
        maturityMatrixID
        ownerGroup
        createdAt
        updatedAt
      }
      programElementID
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listProgramSections = /* GraphQL */ `
  query ListProgramSections(
    $filter: ModelProgramSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        programElementID
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImplementationLevel = /* GraphQL */ `
  query GetImplementationLevel($id: ID!) {
    getImplementationLevel(id: $id) {
      id
      description
      date
      level
      indicator
      frequency
      accountable
      consulted
      informed
      programSection {
        id
        name
        description
        programElementID
        ownerGroup
        createdAt
        updatedAt
      }
      programSectionID
      ownerGroup
      createdAt
      updatedAt
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listImplementationLevels = /* GraphQL */ `
  query ListImplementationLevels(
    $filter: ModelImplementationLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImplementationLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        date
        level
        indicator
        frequency
        accountable
        consulted
        informed
        programSectionID
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorSolution = /* GraphQL */ `
  query GetVendorSolution($id: ID!) {
    getVendorSolution(id: $id) {
      id
      name
      description
      riskRating
      documents {
        nextToken
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listVendorSolutions = /* GraphQL */ `
  query ListVendorSolutions(
    $filter: ModelVendorSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        riskRating
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorDocument = /* GraphQL */ `
  query GetVendorDocument($id: ID!) {
    getVendorDocument(id: $id) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
      vendorSolution {
        id
        name
        description
        riskRating
        ownerGroup
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listVendorDocuments = /* GraphQL */ `
  query ListVendorDocuments(
    $filter: ModelVendorDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        visibility
        createdAt
        ownerGroup
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvidence = /* GraphQL */ `
  query GetEvidence($id: ID!) {
    getEvidence(id: $id) {
      id
      name
      ownerGroup
      frequency
      reminder
      enableAutoReminders
      nextDate
      recentUploadDate
      documents {
        nextToken
      }
      controlLinks {
        nextToken
      }
      evidencePointOfContactId
      task
      createdAt
      updatedAt
      actions {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEvidences = /* GraphQL */ `
  query ListEvidences(
    $filter: ModelEvidenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvidences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listControls = /* GraphQL */ `
  query ListControls(
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        statementNumber
        assessmentFactor
        component
        maturityLevel
        validation
        controlControlSetId
        subControls
        rivialControl
        basePriorityPoints
        isCompliant
        ownerGroup
        domain
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControl = /* GraphQL */ `
  query GetControl($id: ID!) {
    getControl(id: $id) {
      id
      name
      statementNumber
      assessmentFactor
      component
      maturityLevel
      validation
      controlSet {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      controlControlSetId
      subControls
      rivialControl
      basePriorityPoints
      isCompliant
      ownerGroup
      domain
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      evidenceLinks {
        nextToken
      }
      recommendations {
        nextToken
      }
      changes {
        nextToken
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
    }
  }
`;
export const listKeyPerformanceIndicators = /* GraphQL */ `
  query ListKeyPerformanceIndicators(
    $filter: ModelKeyPerformanceIndicatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyPerformanceIndicators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerGroup
        description
        jsonLogic
        status
        getStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKeyPerformanceIndicator = /* GraphQL */ `
  query GetKeyPerformanceIndicator($id: ID!) {
    getKeyPerformanceIndicator(id: $id) {
      id
      name
      ownerGroup
      description
      jsonLogic
      status
      getStatus
      history {
        date
        status
      }
      createdAt
      updatedAt
      metricTypes {
        nextToken
      }
      riskControls {
        nextToken
      }
    }
  }
`;
export const listMetricTypes = /* GraphQL */ `
  query ListMetricTypes(
    $filter: ModelMetricTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetricTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ownerGroup
        enabled
        frequency
        nextDate
        format
        calculationType
        maxAge
        createSecurityMetric
        getSecurityMetric
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMetricType = /* GraphQL */ `
  query GetMetricType($id: ID!) {
    getMetricType(id: $id) {
      id
      name
      description
      ownerGroup
      enabled
      frequency
      nextDate
      format
      fieldConfig {
        fieldName
        tooltip
        label
        placeHolder
        defaultValue
      }
      calculationType
      maxAge
      automation {
        name
        description
        jsonPath
        config
      }
      createSecurityMetric
      getSecurityMetric
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      metrics {
        nextToken
      }
    }
  }
`;
export const getMetric = /* GraphQL */ `
  query GetMetric($id: ID!) {
    getMetric(id: $id) {
      id
      name
      ownerGroup
      description
      status
      date
      level
      data {
        value
        format
      }
      metricTypeID
      createdAt
      updatedAt
    }
  }
`;
export const listMetrics = /* GraphQL */ `
  query ListMetrics(
    $filter: ModelMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        description
        status
        date
        level
        metricTypeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRiskAction = /* GraphQL */ `
  query GetRiskAction($id: ID!) {
    getRiskAction(id: $id) {
      id
      name
      description
      dateCreated
      dueDate
      status
      globalStatus
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
    }
  }
`;
export const listRiskActions = /* GraphQL */ `
  query ListRiskActions(
    $filter: ModelRiskActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        dateCreated
        dueDate
        status
        globalStatus
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      description
      ownerGroup
      calculations {
        date
        expectedInherentLoss
        expectedResidualLoss
      }
      riskSnapshots {
        nextToken
      }
      hosting
      accessType
      confidentiality
      integrity
      availability
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        isCompliant
        costOfControl
        controlEffectiveness
        annualRateReduction
        strengthRating
        implementationRating
        outsourced
        implementationDetails
      }
      monteCarloInputS3ObjectKey
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
      informationAssets {
        nextToken
      }
      components {
        nextToken
      }
      risks {
        nextToken
      }
      controlCategories {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInformationAssets = /* GraphQL */ `
  query ListInformationAssets(
    $filter: ModelInformationAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInformationAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        confidentiality
        integrity
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInformationAsset = /* GraphQL */ `
  query GetInformationAsset($id: ID!) {
    getInformationAsset(id: $id) {
      id
      name
      confidentiality
      integrity
      ownerGroup
      createdAt
      updatedAt
      systems {
        nextToken
      }
    }
  }
`;
export const getComponent = /* GraphQL */ `
  query GetComponent($id: ID!) {
    getComponent(id: $id) {
      id
      name
      description
      ownerGroup
      notes {
        nextToken
        observationID
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSystemRiskLinks = /* GraphQL */ `
  query ListSystemRiskLinks(
    $filter: ModelSystemRiskLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemRiskLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerGroup
        probabilityModifier
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemRiskLink = /* GraphQL */ `
  query GetSystemRiskLink($id: ID!) {
    getSystemRiskLink(id: $id) {
      id
      ownerGroup
      cia {
        confidentiality
        integrity
        availability
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      probabilityModifier
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      risk {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      threats {
        nextToken
      }
    }
  }
`;
export const listRisks = /* GraphQL */ `
  query ListRisks(
    $filter: ModelRiskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRisk = /* GraphQL */ `
  query GetRisk($id: ID!) {
    getRisk(id: $id) {
      id
      name
      classification
      description
      annualRateOfOccurrence
      annualRateOfOccurrenceMax
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      riskSystemId
      ownerGroup
      controlCategoryID
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      createdAt
      updatedAt
      systems {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const listThreats = /* GraphQL */ `
  query ListThreats(
    $filter: ModelThreatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThreat = /* GraphQL */ `
  query GetThreat($id: ID!) {
    getThreat(id: $id) {
      id
      name
      ownerGroup
      createdAt
      updatedAt
      systemRiskLinks {
        nextToken
      }
    }
  }
`;
export const listRiskControlCategorys = /* GraphQL */ `
  query ListRiskControlCategorys(
    $filter: ModelRiskControlCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskControlCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRiskControlCategory = /* GraphQL */ `
  query GetRiskControlCategory($id: ID!) {
    getRiskControlCategory(id: $id) {
      id
      name
      ownerGroup
      description
      createdAt
      updatedAt
      systems {
        nextToken
      }
      subControls {
        nextToken
      }
    }
  }
`;
export const getSystemControlCategoryLink = /* GraphQL */ `
  query GetSystemControlCategoryLink($id: ID!) {
    getSystemControlCategoryLink(id: $id) {
      id
      ownerGroup
      riskControlCategoryID
      systemID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSystemControlCategoryLinks = /* GraphQL */ `
  query ListSystemControlCategoryLinks(
    $filter: ModelSystemControlCategoryLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemControlCategoryLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        riskControlCategoryID
        systemID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRiskControls = /* GraphQL */ `
  query ListRiskControls(
    $filter: ModelRiskControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRiskControl = /* GraphQL */ `
  query GetRiskControl($id: ID!) {
    getRiskControl(id: $id) {
      id
      name
      statementNumber
      costOfControl
      strengthRating
      implementationRating
      outsourced
      ownerGroup
      controlCategoryID
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      createdAt
      updatedAt
      keyPerformanceIndicators {
        nextToken
      }
      controlCategory {
        id
        name
        ownerGroup
        description
        createdAt
        updatedAt
      }
      recommendations {
        nextToken
      }
      riskChangeLinks {
        nextToken
      }
    }
  }
`;
export const getStatistic = /* GraphQL */ `
  query GetStatistic($id: ID!) {
    getStatistic(id: $id) {
      id
      name
      ownerGroup
      date
      data
      type
      createdAt
      updatedAt
    }
  }
`;
export const listStatistics = /* GraphQL */ `
  query ListStatistics(
    $filter: ModelStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerGroup
        date
        data
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistoricalPicture = /* GraphQL */ `
  query GetHistoricalPicture($id: ID!) {
    getHistoricalPicture(id: $id) {
      id
      dateTime
      statistics
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listHistoricalPictures = /* GraphQL */ `
  query ListHistoricalPictures(
    $filter: ModelHistoricalPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoricalPictures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateTime
        statistics
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRiskRecommendation = /* GraphQL */ `
  query GetRiskRecommendation($id: ID!) {
    getRiskRecommendation(id: $id) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      createdAt
      updatedAt
      system {
        id
        name
        description
        ownerGroup
        hosting
        accessType
        confidentiality
        integrity
        availability
        monteCarloInputS3ObjectKey
        createdAt
        updatedAt
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
        ownerGroup
        controlCategoryID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRiskRecommendations = /* GraphQL */ `
  query ListRiskRecommendations(
    $filter: ModelRiskRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskRecommendations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        resolved
        riskControlID
        implementationChange
        costChange
        residualChange
        roiChange
        returnOnInvestment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRiskChanges = /* GraphQL */ `
  query ListRiskChanges(
    $filter: ModelRiskChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRiskChange = /* GraphQL */ `
  query GetRiskChange($id: ID!) {
    getRiskChange(id: $id) {
      id
      ownerGroup
      name
      type
      description
      date
      userEmail
      changeOwner
      reason
      change
      createdAt
      updatedAt
      riskControlLinks {
        nextToken
      }
      systemLinks {
        nextToken
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      shortName
      numSimulations
      orgType
      riskRating
      lossToleranceCurve {
        amount
        probability
      }
      complianceRating
      createdAt
      documents {
        nextToken
      }
      enabledStatistics
      groups
      assetSize
      numberOfEmployees
      numberOfCustomers
      riskSetup {
        high
        mediumHigh
        medium
        lowMedium
        low
      }
      riskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
      s3BucketName
      publicRSAKey
      config
      updatedAt
      actions {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        dateCompleted
        description
        status
        pastDueAt
        ownerGroup
        executionARN
        type
        priority
        module
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      pastDueAt
      ownerGroup
      executionARN
      type
      documents {
        nextToken
      }
      priority
      module
      createdAt
      updatedAt
      evidence {
        id
        name
        ownerGroup
        frequency
        reminder
        enableAutoReminders
        nextDate
        recentUploadDate
        evidencePointOfContactId
        task
        createdAt
        updatedAt
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
    }
  }
`;
export const listActionItems = /* GraphQL */ `
  query ListActionItems(
    $filter: ModelActionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        dueDate
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActionItem = /* GraphQL */ `
  query GetActionItem($id: ID!) {
    getActionItem(id: $id) {
      id
      name
      dateCreated
      dueDate
      status
      description
      type
      ownerGroup
      priority
      module
      createdAt
      updatedAt
      emails {
        nextToken
      }
    }
  }
`;
export const getEmail = /* GraphQL */ `
  query GetEmail($id: ID!) {
    getEmail(id: $id) {
      id
      subject
      recipients
      body
      status
      frequency
      dueDate
      executionARN
      type
      module
      ownerGroup
      createdAt
      updatedAt
      actionItem {
        id
        name
        dateCreated
        dueDate
        status
        description
        type
        ownerGroup
        priority
        module
        createdAt
        updatedAt
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEmails = /* GraphQL */ `
  query ListEmails(
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        recipients
        body
        status
        frequency
        dueDate
        executionARN
        type
        module
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      name
      createdAt
      scheduledDate
      includedListTargets
      excludedListTargets
      securityAppliance {
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        status
        destination
        trackingNumber
        checkOutDate
        checkInDate
        ownerGroup
        createdAt
        updatedAt
      }
      assessmentPointOfContactId
      penTester
      penTesterEmail
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      documents {
        nextToken
      }
      type
      status
      scanStatus
      taskId
      scoreCVSS
      ownerGroup
      updatedAt
      findingLinks {
        nextToken
      }
      targets {
        nextToken
      }
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTargets = /* GraphQL */ `
  query ListTargets(
    $filter: ModelTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostName
        ip
        macAddress
        createdAt
        lastTestedDate
        targetAssessmentId
        ownerGroup
        disabled
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTarget = /* GraphQL */ `
  query GetTarget($id: ID!) {
    getTarget(id: $id) {
      id
      hostName
      ip
      macAddress
      createdAt
      lastTestedDate
      targetAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      disabled
      updatedAt
      assessment {
        id
        name
        createdAt
        scheduledDate
        includedListTargets
        excludedListTargets
        assessmentPointOfContactId
        penTester
        penTesterEmail
        type
        status
        scanStatus
        taskId
        scoreCVSS
        ownerGroup
        updatedAt
      }
      findings {
        nextToken
      }
    }
  }
`;
export const listFindings = /* GraphQL */ `
  query ListFindings(
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFindings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFinding = /* GraphQL */ `
  query GetFinding($id: ID!) {
    getFinding(id: $id) {
      id
      name
      type
      severityLevel
      port
      protocol
      cvss
      cve
      nvtOID
      impact
      solutionType
      summary
      status
      findingTargetId
      findingAssessmentId
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
      }
      ownerGroup
      createdAt
      updatedAt
      assessmentLinks {
        nextToken
      }
      target {
        id
        hostName
        ip
        macAddress
        createdAt
        lastTestedDate
        targetAssessmentId
        ownerGroup
        disabled
        updatedAt
      }
    }
  }
`;
export const getTestingAction = /* GraphQL */ `
  query GetTestingAction($id: ID!) {
    getTestingAction(id: $id) {
      id
      name
      description
      dueDate
      priority
      type
      status
      globalStatus
      module
      ownerGroup
      createdAt
      updatedAt
      finding {
        id
        name
        type
        severityLevel
        port
        protocol
        cvss
        cve
        nvtOID
        impact
        solutionType
        summary
        status
        findingTargetId
        findingAssessmentId
        ownerGroup
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTestingActions = /* GraphQL */ `
  query ListTestingActions(
    $filter: ModelTestingActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestingActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        dueDate
        priority
        type
        status
        globalStatus
        module
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPointOfContacts = /* GraphQL */ `
  query ListPointOfContacts(
    $filter: ModelPointOfContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointOfContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPointOfContact = /* GraphQL */ `
  query GetPointOfContact($id: ID!) {
    getPointOfContact(id: $id) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
      meetings {
        nextToken
      }
      user {
        id
        name
        username
        email
        phoneNumber
        ownerGroup
        config
        accountType
        preferences
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      evidences {
        nextToken
      }
      systems {
        nextToken
      }
      organization {
        id
        name
        shortName
        numSimulations
        orgType
        riskRating
        complianceRating
        createdAt
        enabledStatistics
        groups
        assetSize
        numberOfEmployees
        numberOfCustomers
        s3BucketName
        publicRSAKey
        config
        updatedAt
      }
      emails {
        nextToken
      }
      assessments {
        nextToken
      }
    }
  }
`;
