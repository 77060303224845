import React from "react";
import { Button, Col, FormGroup, Label, ListGroup, ListGroupItem } from "reactstrap";

import { useModal } from "../../../../hooks/views/useModal";
import MeetingSelectPointOfContact from "../../../Program/Meetings/customFields/MeetingSelectPointOfContact";

/**
 *
 * @param organizationID {string}
 * @param setContacts {function}
 * @param toggleModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

const SelectPointOfContacts = ({ organizationID, setContacts, contacts = [], toggleModal }) => {
  const pointOfContactModal = useModal(
    "Add Contacts to this Response Team",
    <MeetingSelectPointOfContact
      organizationID={organizationID}
      setContacts={setContacts}
      contacts={contacts}
      toggleModal={toggleModal}
    />,
    <Button className="btn-pill" size="sm" color="ghost-success" title="Select contact(s)">
      {"  "}
      <i className="icon-plus" />
    </Button>,
    {
      width: "70vw",
    },
  );

  return (
    <>
      <FormGroup row>
        <Col xs="12">
          <Label for="input-meeting-contacts">
            Points of Contact
            {pointOfContactModal.modalButton}
          </Label>
          <ListGroup>
            {contacts?.length < 1 ? (
              <ListGroupItem color="danger">No Points Of Contact Selected</ListGroupItem>
            ) : (
              contacts.map((contact, index) => (
                <ListGroupItem id={contact.id + contact.firstName} key={contact.id + contact.lastName}>
                  {`${contact.firstName} ${contact.lastName}`} | {"  "}
                  <strong>Title:</strong> {contact.title} | {"  "}
                  <strong>Email:</strong> {contact.email}
                  <Button
                    className="float-right"
                    close
                    onClick={() => {
                      if (
                        window.confirm(`Are you sure you want to delete
                            ${
                              contact && contact.firstName && contact.lastName
                                ? `${contact.firstName} ${contact.lastName}`
                                : "this point of contact"
                            }?`)
                      ) {
                        const index = contacts.indexOf(contact);
                        if (index !== -1) contacts.splice(index, 1);
                        // forceUpdate();
                      }
                    }}
                  />
                </ListGroupItem>
              ))
            )}
          </ListGroup>
        </Col>
      </FormGroup>
    </>
  );
};

export default SelectPointOfContacts;
