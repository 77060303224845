import { useEffect } from "react";

export const useUpdateSystemsTable = (systemsTable, overallChanges) => {
  /**
   * Update the SystemsTable when the calculations get updated
   */
  useEffect(() => {
    systemsTable.setData([...overallChanges.systemCalcs]);
  }, [overallChanges.systemCalcs]);
};
