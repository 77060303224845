/**
 * @function
 * @description Round a number
 * @param value
 * @param precision
 */
export const round = (value: number, precision: number): number => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
