import _ from "lodash";
import * as React from "react";
import { useEffect, useRef } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useBoolean } from "../../../../../hooks/functional/useBoolean";
import { useElementSize } from "../../../../../hooks/functional/useElementSize";
import TabCard from "../../../../../utils/GenericComponents/TabCard";
import FindingsReportButton from "../../../../Reports/FindingsReport/components/FindingsReportButton";
import { deleteActionItem } from "../../../Actions/functions/deleteActionItem";
import { useActionDataGrid } from "../../../Actions/hooks/useActionDataGrid";
import { useObservationDataGrid } from "../../../Observations/hooks/useObservationDataGrid";
import { deleteRecommendation } from "../../../Recommendations/functions/deleteRecommendation";
import { useRecommendationsDataGrid } from "../../../Recommendations/hooks/useRecommendationsDataGrid";

import { getFullFindings } from "./functions/getFullFindings";
import { handleObservationsData } from "./functions/handleFullFindingsData";

/**
 * Displays Observations, Recommendations, and Action Items in a TabCard format
 * @param {string} organizationID - the org to query for
 * @param {string} module - if module passed in, automatically filters query
 * @param {object} [vulnerability] - vulnerability object with id if need to filter by vulnerability id
 * @param {object} [incident] - incident object with id if need to filter by incident id
 * @param {object} [exercise] - exercise object with id if need to filter by exercise id
 * @param {object} [evidence] - evidence object with id if need to filter by evidence id
 * @param {object} [control] - control object with id if need to filter by control id
 * @param {object} [audit] - audit object with id if need to filter by control id
 * @param {object} [source] - source object with id if need to filter by source id
 * @param {object} [meeting] - meeting object with id if need to filter by meeting id
 * @param {object} [riskControl] - riskControl object with id if need to filter by riskControl id
 * @param {object} [department] - department object with id if need to filter by department id
 * @param {object} [assessment] - assessment object with id if need to filter by assessment id
 * @param {boolean} readOnly - if true, disables all modifications
 * @param {function} dataCallback - function to call when data is updated
 * @param {string[]} observationHiddenFields - fields to hidde in the observation grid
 * @return {{resetFunction, display: JSX.Element}}
 */
export const useFindingsCard = ({
  organizationID,
  module,
  vulnerability,
  incident,
  exercise,
  evidence,
  control,
  audit,
  source,
  meeting,
  riskControl,
  department,
  assessment,
  readOnly = false,
  dataCallback,
  observationHiddenFields = [],
}) => {
  // State
  const [isLoading, setIsLoading] = useBoolean();

  // Card size for layout flags
  const ref = useRef(null);
  const { width } = useElementSize({ ref });
  const useAcronyms = width < 600;
  const hideReportText = width < 800;

  const handleData = async () => {
    const grids = [observationsGrid, recommendationGrid, actionItemsGrid];
    grids.forEach((grid) => grid.setIsLoading(true));
    setIsLoading(true);
    try {
      const fullFindings = await getFullFindings({
        organizationID,
        variables: {
          ownerGroup: organizationID,
          module: { eq: module },
        },
        filters: getFilters(),
      });

      const { observations, recommendations, actionItems } = handleObservationsData(fullFindings);
      observationsGrid.setData(observations);
      recommendationGrid.setData(recommendations);
      actionItemsGrid.setData(actionItems);

      if (typeof dataCallback === "function") {
        dataCallback({
          observations,
          recommendations,
          actionItems,
        });
      }
    } catch (e) {
      ErrorLogger("Could not retrieve observations, recommendations, action items. ", e);
    }
    grids.forEach((grid) => grid.setIsLoading(false));
    setIsLoading(false);
  };

  useEffect(() => {
    handleData();
  }, [vulnerability]);

  const gridConfig = {
    gridHeight: "100%",
    resetFunction: () => handleData(),
    getNewItem: (item) => {
      if (observationsGrid?.ref?.refresh) {
        observationsGrid?.ref?.refresh();
      }
    },
  };

  const getFilters = () => {
    const filters = {};

    if (!isNullOrUndefined(vulnerability?.id)) {
      filters.vulnerabilityID = { eq: vulnerability?.id };
    }

    if (!isNullOrUndefined(incident?.id)) {
      filters.incidentID = { eq: incident?.id };
    }

    if (!isNullOrUndefined(exercise?.id)) {
      filters.exerciseID = { eq: exercise?.id };
    }

    if (!isNullOrUndefined(evidence?.id)) {
      filters.evidenceID = { eq: evidence?.id };
    }

    if (!isNullOrUndefined(control?.id)) {
      filters.complianceControlID = { eq: control?.id };
    }

    if (!isNullOrUndefined(riskControl?.id)) {
      filters.riskControlID = { eq: riskControl?.id };
    }

    if (!isNullOrUndefined(audit?.id)) {
      filters.auditID = { eq: audit?.id };
    }

    if (!isNullOrUndefined(source?.id)) {
      filters.externalSourceID = { eq: source?.id };
    }

    if (!isNullOrUndefined(department?.id)) {
      filters.departmentID = { eq: department?.id };
    }

    if (!isNullOrUndefined(assessment?.id)) {
      filters.assessmentID = { eq: assessment?.id };
    }

    if (!isNullOrUndefined(meeting?.id)) {
      filters.meetingID = { eq: meeting?.id };
    }

    if (!_.isEmpty(filters)) {
      return filters;
    }
  };

  const queryConfig = {
    query: null,
  };

  // When an observation is created, refresh the data to make sure Recommendations and Action Items also get populated
  const onCreateObservation = () => {
    handleData();
  };

  const observationsGrid = useObservationDataGrid({
    resetFunction: () => handleData(),
    vulnerability,
    incident,
    exercise,
    evidence,
    control,
    audit,
    meeting,
    department,
    assessment,
    module,
    organizationID,
    queryConfig,
    hiddenFields: ["module", ...observationHiddenFields],
    readOnly,
    onCreate: onCreateObservation,
  });

  const recommendationGrid = useRecommendationsDataGrid({
    resetFunction: () => handleData(),
    module,
    organizationID,
    queryConfig,
    gridConfig: {
      ...gridConfig,
      createResourceComponent: null,
      deleteFunction: deleteRecommendation,
    },
  });

  const actionItemsGrid = useActionDataGrid({
    module,
    organizationID,
    queryConfig,
    gridConfig: {
      ...gridConfig,
      createResourceComponent: null,
      deleteFunction: deleteActionItem,
    },
  });

  const display = (
    <div ref={ref} style={{ flex: 1 }}>
      <TabCard
        sx={{ tabPanels: { height: "35em" } }}
        resetFunction={() => handleData()}
        isLoading={isLoading}
        headerButtons={[
          <FindingsReportButton
            auditID={audit?.id}
            sourceID={source?.id}
            complianceControlID={control?.id}
            riskControlID={riskControl?.id}
            evidenceID={evidence?.id}
            vulnerabilityID={vulnerability?.id}
            exerciseID={exercise?.id}
            incidentID={incident?.id}
            department={department?.id}
            assessmentID={assessment?.id}
            isCompact={hideReportText}
          />,
        ]}
        items={[
          {
            title: useAcronyms ? "Obs." : "Observations",
            total: observationsGrid?.data?.length,
            component: observationsGrid?.gridDisplay,
            icon: <i className={"icon-eye"} />,
          },
          {
            title: useAcronyms ? "Rec." : "Recommendations",
            total: recommendationGrid?.data?.length,
            component: recommendationGrid?.gridDisplay,
            icon: <i className={"icon-check"} />,
          },
          {
            title: useAcronyms ? "Act." : "Action Items",
            total: actionItemsGrid?.data?.length,
            component: actionItemsGrid?.gridDisplay,
            icon: <i className={"icon-rocket"} />,
          },
        ]}
      />
    </div>
  );

  return {
    display,
    resetFunction: () => handleData(),
    observationsGrid,
    recommendationGrid,
    actionItemsGrid,
  };
};
