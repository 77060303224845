import { useContext } from "react";

import { checkPermissions as checkPermissionsFunc } from "@rivial-security/role-utils";

import { OrganizationContext } from "@utils/Context/OrganizationContext";

/**
 * @typedef {Object} UseCheckPermissionsResult
 * @property {object} module
 * @property {boolean} module.isEnabled
 *
 * @property {object} resource
 * @property {boolean} resource.read
 * @property {boolean} resource.create
 * @property {boolean} resource.update
 * @property {boolean} resource.delete
 *
 * @property {object} field
 * @property {boolean} field.read
 * @property {boolean} field.update
 *
 * @property {object} function
 * @property {boolean} function.isEnabled
 *
 * @property {object} roleConfig
 * @property {function} CheckFieldPermissionsRead
 * @property {function} CheckFieldPermissionsUpdate
 * @property {function} CheckFuncPermissions
 */

/**
 * @description Checks RolePermissions for the current logged-in user.
 *
 * Documentation: https://rivialsecurity.atlassian.net/l/c/u9jc5jQt
 *
 * @param {object} checkPermissions
 * @param {string} [checkPermissions.module] - The module to check permissions for.
 * @param {string} [checkPermissions.resource] - The resource to check permissions for.
 * @param {string} [checkPermissions.field] - The field to check permissions for.
 * @param {string} [checkPermissions.func] - The function to check permissions for.
 * @param {boolean} [checkPermissions.disableRoleChecking] - Whether to disable role checking. If true, will not check for role permissions.
 * @returns {UseCheckPermissionsResult}
 */
export const useCheckPermissions = ({ module, resource, field, func, disableRoleChecking }) => {
  const { roleConfig } = useContext(OrganizationContext);

  const res = checkPermissions({
    module,
    resource,
    field,
    func,
    roleConfig,
    disableRoleChecking,
  });

  const CheckFieldPermissionsRead = (fieldName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      field: fieldName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).field.read;
  };

  const CheckFieldPermissionsUpdate = (fieldName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      field: fieldName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).field.update;
  };

  const CheckFuncPermissions = (funcName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      func: funcName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).function.isEnabled;
  };

  return {
    ...res,
    CheckFieldPermissionsRead,
    CheckFieldPermissionsUpdate,
    CheckFuncPermissions,
    roleConfig,
  };
};

/**
 * @param {object} [input]
 * @param {string} [input.module]
 * @param {string} [input.resource]
 * @param {string} [input.field]
 * @param {string} [input.func]
 * @param {object} [input.roleConfig]
 * @param {boolean} [input.disableRoleChecking]
 * @returns {{resourceAlone: {read: boolean, create: boolean, update: boolean, delete: boolean}, field: {read: boolean, update: boolean}, resource: {read: boolean, create: boolean, update: boolean, delete: boolean}, fieldAlone: {read: boolean, update: boolean}, module: {isEnabled: boolean}, function: {isEnabled: boolean}, functionAlone: {isEnabled: boolean}}|{noErrors: string[], warnings: string[], noWarnings: string[], errors: string[]}|{field: {read: boolean, update: boolean}, resource: {read: boolean, create: boolean, update: boolean, delete: boolean}, module: {isEnabled: boolean}, function: {isEnabled: boolean}}}
 */
export const checkPermissions = ({ module, resource, field, func, roleConfig, disableRoleChecking = false }) => {
  if (disableRoleChecking === true) {
    return {
      module: {
        isEnabled: true,
      },
      resource: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      field: {
        read: true,
        update: true,
      },
      function: {
        isEnabled: true,
      },
    };
  } else return checkPermissionsFunc({ module, resource, field, func, roleConfig });
};
