import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Converts an importer field value into a decimal number for the database.
 *
 * handles the following formats:
 * - $75
 * - 75
 * - $75,500
 * @param {string|number} value
 * @returns {string|number|*}
 */
export const handleDollarFormat = ({ value }) => {
  if (!isNullOrUndefined(value)) {
    if (typeof value === "string") {
      const removedDollarSymbol = value.replaceAll("$", "");
      const removedCommas = removedDollarSymbol.replaceAll("$", "");

      const convertedToFloat = parseFloat(removedCommas);

      // Check if proper number
      if (!isNaN(convertedToFloat)) {
        return convertedToFloat;
      }
      // If improper number, just returns a blank string. The other part of the function will pick up on required fields
      else {
        return "";
      }
    }

    // handle direct decimal input
    // Check if proper number
    if (!isNaN(value)) {
      return value;
    }
    // If improper number, just returns a blank string. The other part of the function will pick up on required fields
    else {
      return "";
    }
  }
};
