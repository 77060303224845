import { useEffect, useState } from "react";

import { getDefaultDataGridFilterModel } from "./functions/getDefaultDataGridFilterModel";

/**
 * Holds the filter info in state for 'controlled' filtering, prioritizes grid view info as the source of truth
 * @param {object[]} fields - fields prop passed through from useDataGrid
 * @param {object} targetView - user's custom settings for the grid column width, order, etc.
 * @return {{filterModel: object}}
 */
export const useDataGridFiltering = ({ fields, targetView }) => {
  const [filterModel, setFilterModel] = useState(getDefaultDataGridFilterModel({ fields }));

  useEffect(() => {
    let newFilterModel = targetView?.filterModel;
    if (!newFilterModel) {
      newFilterModel = getDefaultDataGridFilterModel({ fields });
    }

    if (JSON.stringify(filterModel) === JSON.stringify(newFilterModel)) {
      return;
    }

    setFilterModel(newFilterModel);
  }, [JSON.stringify(targetView?.filterModel)]);

  return {
    filterModel,
  };
};
