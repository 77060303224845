import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import { useWorkflow } from "@hooks/views/useWorkflow";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import AddAssessmentFiles from "../customFields/AddAssessment/AddAssessmentFiles";
import AddAssessmentGeneralInfo from "../customFields/AddAssessment/AddAssessmentGeneralInfo";
import AddAssessmentPenetrationTesterInfo from "../customFields/AddAssessment/AddAssessmentPenetrationTesterInfo";
import AddAssessmentPointOfContact from "../customFields/AddAssessment/AddAssessmentPointOfContact";
import AddAssessmentSubmit from "../customFields/AddAssessment/AddAssessmentSubmit";
import AddAssessmentTargets from "../customFields/AddAssessment/AddAssessmentTargets";

/**
 * Add an Assessment UI from the database.
 * @param {object} props - the props object
 * @param {string} props.organizationID - the organization ID
 * @param {function} props.getNewItem - the function to get the new item
 * @param {function} props.toggleModal - the function to toggle the modal
 * @returns {JSX.Element}
 */
const AddSingleAssessment = ({ organizationID, getNewItem, toggleModal }) => {
  // State for a new Assessment
  const [input, setInput] = useState({});

  const workflow = useWorkflow({
    initialStepIndex: 0,
    steps: [
      {
        id: "general-information",
        text: "General Information",
        display: <AddAssessmentGeneralInfo input={input} setInput={setInput} organizationID={organizationID} />,
      },
      {
        id: "penetration-tester-info",
        text: "Penetration Tester Info",
        display: <AddAssessmentPenetrationTesterInfo input={input} setInput={setInput} />,
      },
      {
        id: "point-of-contact",
        text: "Point of Contact",
        display: (
          <StyledWrapper
            wrapperStyle={{
              height: "70vh",
            }}
          >
            <AddAssessmentPointOfContact input={input} setInput={setInput} organizationID={organizationID} />
          </StyledWrapper>
        ),
      },
      {
        id: "targets",
        text: "Targets",
        display: <AddAssessmentTargets input={input} setInput={setInput} organizationID={organizationID} />,
      },
      {
        id: "select-csv",
        text: "Select CSV",
        display: <AddAssessmentFiles organizationID={organizationID} input={input} setInput={setInput} />,
      },
      {
        id: "submit",
        text: "Submit",
        display: (
          <AddAssessmentSubmit
            input={input}
            setInput={setInput}
            organizationID={organizationID}
            getNewItem={getNewItem}
            toggleModal={toggleModal}
          />
        ),
      },
    ],
  });

  return (
    <Row>
      <Col sm="4">{workflow.progressTracker.display}</Col>
      <Col sm="8">{workflow.display}</Col>
    </Row>
  );
};

export default withOrganizationCheck(AddSingleAssessment);
