import { isNullOrUndefined } from "@rivial-security/func-utils";

import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

import { prepareDataForExport } from "./prepareDataForExport";

/**
 * @description Handle logic for on menu element click
 * @param {object} item - contains clicked menu item
 * @param {object} ref - reference of the the grid component
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {object[]} selectedItems - array of selected items in the grid
 * @param {function} resetFunction - re-fetch data from the database
 * @param {function} deleteGridItems - deletes items from the grid
 * @param {function} resetLocalStorage - resets this Grid in local storage if being persisted
 * @param helpCenter - helpCenter hook reference
 * @param {string} helpCenterUrl - used to navigate to a specific page in the help center
 */
export const gridOnMenuClick = ({
  item,
  ref,
  fields,
  selectedItems,
  resetFunction,
  deleteGridItems,
  resetLocalStorage,
  helpCenter,
  helpCenterUrl,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    item,
    ref,
    fields,
    selectedItems,
    resetFunction,
    deleteGridItems,
    resetLocalStorage,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  if (ref) {
    const exportProperties = prepareDataForExport({
      ref,
      selectedItems,
      fields,
    });

    const checkInputForExport = (data) => {
      if (data?.dataSource && Array.isArray(data?.dataSource) && data?.dataSource.length > 0) {
        return true;
      } else {
        alert("Please select at least one item from the table. Use SHIFT + Click for multiple.");
        return false;
      }
    };

    switch (item.id) {
      case "refresh_data":
        resetFunction && resetFunction();
        break;
      case "print":
        ref.print && ref.print();
        break;
      case "delete":
        deleteGridItems();
        break;
      case "csv_export":
        if (checkInputForExport(exportProperties)) ref.csvExport && ref.csvExport(exportProperties);
        break;
      case "pdf_export":
        if (checkInputForExport(exportProperties)) ref.pdfExport && ref.pdfExport(exportProperties);
        break;
      case "help_center":
        if (!isNullOrUndefined(helpCenterUrl)) {
          helpCenter.setHelpCenterLink(helpCenterUrl);
        }

        helpCenter.setIsOpen(true);

        // // Full screen mode
        // const helpCenterButton = document.getElementById('app-aside-toggler-help-center');
        // // Mobile screen mode
        // const helpCenterButtonMobile = document.getElementsByClassName('d-lg-none navbar-toggler');
        //
        // if (helpCenterButton && helpCenterButton.click) {
        //   helpCenterButton.click();
        // } else if (helpCenterButtonMobile && helpCenterButtonMobile[1]) {
        //   helpCenterButtonMobile[1].click()
        // }
        break;
      case "reset_local_storage":
        resetLocalStorage();
        break;
      default:
        return null;
    }
  }
};
