import CalculateIcon from "@mui/icons-material/CalculateOutlined";
import { Button } from "@mui/material";
import { useContext, useMemo, useState } from "react";

import { formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { CALCULATION_CONTEXT } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "@hooks/views/useGrid/functions/mergeAdditionalFields";
import { OrganizationContext } from "@utils/Context/OrganizationContext";
import { useUIContext } from "@utils/Context/UIContext";
import { createSystemTagLink } from "@utils/Tags/functions/createTagLinks/createSystemTagLink";
import { performToastOperation } from "@utils/Toasts/functions/toastOperation";
import { systemsByOwnerGroupTest } from "@views/Risk/graphql/systemsByOwnerGroup";
import SystemAdmin from "@views/Risk/Systems/customFields/SystemAdmin";
import SystemOwner from "@views/Risk/Systems/customFields/SystemOwner";

import CreateSystem from "../components/CreateSystem";
import SystemHosting from "../components/SystemAccordion/SystemOverview/customFields/SystemHosting";
import SystemDetails from "../components/SystemDetails";
import GreatestBusinessRisk from "../customFields/GreatestBusinessRisk";
import GreatestEnterpriseRisk from "../customFields/GreatestEnterpriseRisk";
import GreatestKeyRiskIndicator from "../customFields/GreatestKeyRiskIndicator";
import InherentRisk from "../customFields/InherentRisk";
import ResidualRisk from "../customFields/ResidualRisk";
import RiskRating from "../customFields/RiskRating";
import { deleteSystem } from "../functions/deleteSystem";
import { MonteCarloCalculationWithQueryLambda } from "../functions/MonteCarloCalculationWithQueryLambda";

const { updateMutation: updateSystem } = generateGraphql(
  "System",
  [
    "name",
    "description",
    "pointOfContact",
    "hosting",
    "adminPointOfContact",
    "tags",
    "inherentRisk",
    "residualRisk",
    "riskRating",
    "greatestEnterpriseRisk",
    "greatestBusinessRisk",
    "greatestKeyRiskIndicator",
    "questionnaires",
  ],
  {
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
    pointOfContact: `{ id ownerGroup firstName lastName title email }`,
    adminPointOfContact: `{ id ownerGroup firstName lastName title email }`,
    questionnaires: `(limit: 100) { items { id questionnaire { name id status assignees { items { id questionnaireID pointOfContactID } } } } }`,
  },
);

/**
 * Displays a list of Information Systems
 *
 * @typedef {object} DataGridProps
 * @property {string} organizationID - the database identifier of the currently selected organization
 * @property {object} queryConfig - passed down to the data grid overriding default values
 * @property {object} cardConfig - passed down to the data grid overriding default values
 * @property {object} gridConfig - passed down to the data grid overriding default values
 * @property {boolean} enableSticky
 * @property {string} module - the module to check permissions for
 * @property {string} resource - the resource typename to use for role checking
 * @property {boolean} [disableRoleChecking=false] - if TRUE user role permissions won't be checked on the front end
 * @property {object[]} additionalFields - custom field configurations to merge with the default ones
 * @property {object} props - props to pass to the DataGrid component
 *
 * @param {DataGridProps} props - the props for the useSystemDataGrid hook
 * @return {{selectedData: [], isLoading: *, gridDisplay: *, setIsLoading: (value: (((prevState: *) => *) | *)) => void, data: *, setData: (value: (((prevState: *) => *) | *)) => void, resetFunction: function(): void, display: *, selectedIDs: []}}
 */
export const useSystemDataGrid = ({
  organizationID,
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
  enableSticky,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  additionalFields,
  ...props
}) => {
  const [systemGridRef, setSystemGridRef] = useState(null);
  const context = useContext(OrganizationContext);
  const roleConfig = {
    module,
    resource,
  };
  const { addToast, updateToast } = useUIContext();

  queryConfig = {
    query: systemsByOwnerGroupTest,
    variables: {
      ownerGroup: organizationID,
    },
    organizationID,
    ...queryConfig,
  };

  const fields = [
    {
      name: "name",
      minWidth: 200,
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      width: 300,
      bulkEdit: true,
    },
    {
      name: "pointOfContact",
      friendlyName: "System Owner",
      component: <SystemOwner module={module} resource={resource} />,
      disablePropagation: true,
      width: 150,
      valueGetter: (_value, row) => {
        return formattedName({
          pointOfContact: row?.pointOfContact,
        });
      },
      searchKeys: ["pointOfContact.firstName", "pointOfContact.lastName"],
    },
    {
      name: "adminPointOfContact",
      friendlyName: "System Admin",
      component: <SystemAdmin module={module} resource={resource} />,
      disablePropagation: true,
      width: 150,
      valueGetter: (_value, row) => {
        return formattedName({
          pointOfContact: row?.adminPointOfContact,
        });
      },
      searchKeys: ["adminPointOfContact.firstName", "adminPointOfContact.lastName"],
    },
    {
      name: "hosting",
      component: <SystemHosting {...{ module, resource, disableRoleChecking }} />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 150,
      type: "singleSelect",
      valueOptions: [
        {
          value: "outsourced",
          label: "Outsourced",
        },
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "hybrid",
          label: "Hybrid",
        },
      ],
      bulkEdit: true,
    },
    {
      name: "inherentRisk",
      visible: false,
      component: <InherentRisk />,
      width: 100,
      type: "number",
    },
    {
      name: "residualRisk",
      component: <ResidualRisk />,
      width: 100,
      type: "number",
    },
    {
      name: "riskRating",
      visible: true,
      component: <RiskRating />,
      width: 100,
      type: "singleSelect",
      valueOptions: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "lowMedium",
          label: "Low-Medium",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "mediumHigh",
          label: "Medium-High",
        },
        {
          value: "high",
          label: "High",
        },
        {
          value: "veryHigh",
          label: "Very High",
        },
        {
          value: null,
          label: "N/A",
        },
      ],
    },
    {
      name: "greatestEnterpriseRisk",
      visible: false,
      component: <GreatestEnterpriseRisk />,
      width: 100,
    },
    {
      name: "greatestBusinessRisk",
      visible: false,
      component: <GreatestBusinessRisk />,
      width: 100,
    },
    {
      name: "greatestKeyRiskIndicator",
      visible: false,
      component: <GreatestKeyRiskIndicator />,
      width: 100,
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createSystemTagLink,
      width: 200,
      bulkEdit: true,
    },
  ];
  mergeAdditionalFields({ additionalFields, fields });

  gridConfig = {
    fields,
    detailsComponent: <SystemDetails organizationID={organizationID} />,
    route: "#/risk/systems/",
    header: "Information Systems",
    createResourceComponent: <CreateSystem organizationID={organizationID} />,
    options: ["details", "delete", "duplicate", "edit"],
    updateMutation: updateSystem,
    deleteFunction: deleteSystem,
    detailsTitle: "Information System Details",

    persistenceUUID: "e68f39ac-137f-4ca7-bbac-a4e028ab163a",
    typename: "System",
    duplicationSettings,

    module,
    resource,
    disableRoleChecking,

    ...gridConfig,
    ...props,
  };

  cardConfig = {
    title: "Information Systems",
    headerIcon: "icon-screen-desktop",
    enableSticky: true,
    ...cardConfig,
  };
  const customOptions = context?.isAdmin
    ? [
        {
          component: (
            <Button
              disabled={systemGridRef?.data?.length === 0}
              startIcon={<CalculateIcon />}
              onClick={async () => {
                await performToastOperation({
                  addToast,
                  updateToast,
                  inProgressText: "Recalculating Information Systems",
                  successText: "Successfully recalculated Information Systems",
                  failedText: "Failed to recalculate Information Systems",
                  onSuccess: () => systemGridRef?.resetFunction(),
                  operation: async () =>
                    await MonteCarloCalculationWithQueryLambda({
                      systems: systemGridRef.data,
                      organizationID,
                      calculationContext: CALCULATION_CONTEXT.RISK_CHANGE,
                    }),
                });
              }}
            >
              Recalculate
            </Button>
          ),
        },
      ]
    : [];

  const systemGrid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
    customOptions,
  });

  useMemo(() => {
    setSystemGridRef(systemGrid);
  }, [systemGrid.data]);

  return {
    ...systemGrid,
  };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates a System. Preserves connections to Risks (copies Probability Modifier, Notes, CIA, and Enterprise Risk Mapping), Control Categories (copies Notes), and Information Assets (copies Number of Records). Does not copy Risk/Threat links, Risk Changes, Recommendations, or KPIs",
  fields: [
    "name",
    "description",
    "hosting",
    "pointOfContact",
    "adminPointOfContact",
    "confidentiality",
    "integrity",
    "availability",
    "risks",
    "riskOverrides",
    "riskControlOverrides",
    "informationAssets",
    "controlCategories",
    "notes",
    "tags",
  ],
  nestedFields: {
    pointOfContact: `{id}`,
    adminPointOfContact: `{id}`,
    risks: `(limit: 1000) { items { id ownerGroup system { id } risk { id } cia { confidentiality integrity availability } riskMapping { dataBreach systemDisruption facilityDisruption fraud malware vendor compliance } probabilityModifier notes { author content timeStamp ownerGroup observationID } } }`,
    informationAssets: `(limit: 1000) { items { id systemID informationAssetID system { id } informationAsset { id } numberOfRecords ownerGroup } }`,
    controlCategories: `(limit: 1000) { items { id ownerGroup riskControlCategoryID systemID riskControlCategory { id } system { id } notes { author content timeStamp ownerGroup observationID } } }`,
    notes: `{author content timeStamp ownerGroup observationID }`,
    riskOverrides: `{enabledFields riskId annualRateOfOccurrence annualRateOfOccurrenceMax confidenceIntervalLower confidenceIntervalUpper costOfControls controlEffectiveness}`,
    riskControlOverrides: `{enabledFields riskControlId isCompliant costOfControl controlEffectiveness annualRateReduction strengthRating implementationRating outsourced implementationDetails}`,
    tags: `(limit: 1000) { items { id ownerGroup systemID tagID system { id } tag { id } } }`,
  },
  primaryField: "name",
  inputAdaptor: (input, item) => {
    return {
      ...input,
      // have to do this because 'systemPointOfContactId' is hidden and can't be queried directly
      systemPointOfContactId: item?.pointOfContact?.id || undefined,
      // have to do this because 'systemAdminPointOfContactId' is hidden and can't be queried directly
      systemAdminPointOfContactId: item?.adminPointOfContact?.id || undefined,
    };
  },
  connectionAdaptor: {
    risks: {
      connectionTypename: "SystemRiskLink",
      itemConnectionIDField: "systemRiskLinkSystemId",
      childConnectionIDField: "systemRiskLinkRiskId",
      childConnectionField: "risk",
      fields: ["cia", "riskMapping", "probabilityModifier", "notes"],
    },
    controlCategories: {
      connectionTypename: "SystemControlCategoryLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "riskControlCategoryID",
      childConnectionField: "riskControlCategory",
      fields: ["notes"],
    },
    informationAssets: {
      connectionTypename: "SystemInformationAssetLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "informationAssetID",
      childConnectionField: "informationAsset",
      fields: ["numberOfRecords"],
    },
    tags: {
      connectionTypename: "SystemTagLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "tagID",
      childConnectionField: "tag",
    },
  },
};
