import React from "react";

import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { getResourceQueryData } from "@definitions/functions/getResourceQueryData";
import { fieldContexts } from "@enums/fieldContexts";
import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";
import StringListField from "@utils/CustomFields/StringListField";

import ControlSetDetails from "../components/ControlSetDetails";
import CreateControlSet from "../components/CreateControlSet";
import FrameworkComplianceField from "../components/FrameworkComplianceField";
import DeleteControlSet from "../functions/deleteControlSet";

/**
 * Displays Control Frameworks for an Organization
 * @param {string} organizationID - the organization/operation team for which to show Control Frameworks
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {string} gridCardTitle - the title to use for the top of the grid
 * @param {object} queryConfig - the configuration for the query
 * @param {string[]} queryFields - the fields to query for the grid
 * @param {object} nestedFields - the nested field information to query
 * @param {object} gridConfig - grid configuration
 * @param {object[]} fields - the fields to display in the grid
 * @param {object} props - additional props to pass to the grid
 * @return {{selectedData: [], isLoading: *, setIsLoading: (value: (((prevState: *) => *) | *)) => void, data: *, setData: (value: (((prevState: *) => *) | *)) => void, resetFunction: function(): void, display: *, selectedIDs: []}}
 */
export const useControlSetDataGrid = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL_FRAMEWORK,
  disableRoleChecking = false,
  isTemplate = false,
  gridCardTitle,
  queryConfig: initQueryConfig = {},
  gridConfig,
  fields = [],
  ...props
}) => {
  const typename = "ControlSet";
  const route = "#/continuous_compliance/control_frameworks/";

  initQueryConfig = {
    indexName: "controlSetsByOwnerGroup",
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    ...initQueryConfig,
  };

  const { queryConfig, updateMutation } =
    getResourceQueryData({
      fieldContext: fieldContexts.GRID,
      overrideQueryConfig: initQueryConfig,
      typename,
    }) || {};

  const cardConfig = {
    title: gridCardTitle ? gridCardTitle : "Control Frameworks",
    headerIcon: "icon-check",
  };

  fields = [
    {
      name: "name",
      minWidth: 200,
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "customFields",
      friendlyName: "Custom Control Fields",
      component: <StringListField fieldName={"customFields"} deepProperty={"name"} />,
      minWidth: 200,
      flex: 1,
      valueGetter: (value) => Array.isArray(value) && value?.map((field) => field?.name)?.join(", "),
      searchKeys: ["customFields.*.name"],
    },
    ...fields,
  ];

  //Only show metrics field for non template control frameworks
  if (!isTemplate) {
    fields.push({
      name: "totalComplianceMetric",
      width: 250,
      friendlyName: "Compliance Status",
      component: <FrameworkComplianceField />,
      filterable: false,
    });
  }

  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  gridConfig = {
    fields,
    typename,
    route,
    createItemModalHeader: "Create a new Control Framework",
    createResourceComponent: (
      <CreateControlSet
        organizationID={organizationID}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        isTemplate={isTemplate}
      />
    ),
    options: ["details", "duplicate", "delete"],
    deleteFunction: DeleteControlSet,
    updateMutation,

    // details component setup
    detailsTitle: "Control Framework Details",
    detailsComponent: <ControlSetDetails organizationID={organizationID} tableDisplay={true} isTemplate={isTemplate} />,
    persistenceUUID: "control_set_grid",
    duplicationSettings,
    organizationID,
    ...gridConfig,
    ...props,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
    ...cardConfig,
  });

  return { ...gridCard };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates Control Frameworks. Preserves Custom Fields configuration. Duplicates all Controls associated with the Framework, and attempts to preserve Evidence linking.",
  primaryField: "name",
  fields: ["name", "customFields", "controls"],
  nestedFields: {
    customFields: `{ name type options { label value } multipleSelect { label value } numberSettings { min max step format }}`,
    controls: `(limit: 1000) { items { name statementNumber isDisabled customFieldData evidences { items { evidenceID } } } }`,
  },
  connectionAdaptor: {
    controls: {
      // connectedItem is a control, and item is the controlFramework
      // duplicate the control, as well as evidenceLinks
      mutationFunction: async ({ item, connectedItem, organizationID }) => {
        // graphql mutation for creating new Control
        const { createMutation: createControlMutation } = generateGraphql(
          "Control",
          ["statementNumber", "name", "inPlace", "isDisabled", "evidences", "customFieldData"],
          {
            evidences: `(limit: 500) {
              items {
                evidenceID
              }
            }`,
          },
        );

        // graphql mutation for creating new Control Evidence Link
        const { createMutation: createEvidenceLinkMutation } = generateGraphql("ControlEvidenceLink", [
          "controlID",
          "evidenceID",
        ]);

        // create the new control
        const control = await ItemMutation({
          mutation: createControlMutation,
          input: {
            name: connectedItem.name,
            statementNumber: connectedItem.statementNumber,
            inPlace: connectedItem.inPlace,
            isDisabled: connectedItem.isDisabled,
            customFieldData: connectedItem.customFieldData,
            ownerGroup: organizationID,
            controlControlSetId: item.id,
          },
        });

        // allow promises to run in parallel
        const evidenceLinkPromises = [];

        const evidenceLinks = connectedItem?.evidences?.items || [];

        // now create evidence links to the new control
        for (const evidenceLink of evidenceLinks) {
          if (evidenceLink?.evidenceID && control?.id) {
            // create the new evidence link
            const evidenceLinkPromise = ItemMutation({
              mutation: createEvidenceLinkMutation,
              input: {
                controlID: control.id, // this is the ID of the newly created control
                evidenceID: evidenceLink.evidenceID, // this is the ID of the existing evidence
                ownerGroup: organizationID,
              },
            });

            evidenceLinkPromises.push(evidenceLinkPromise);
          }
        }

        // await all the evidence link promises so they don't get skipped
        await Promise.allSettled(evidenceLinkPromises);
      },
    },
  },
};
