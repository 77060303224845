"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertOperator = void 0;
/**
 * Converts sync fusion operators into jsonLogic operators
 *
 * @param syncFusionOperator
 */
const convertOperator = (syncFusionOperator) => {
    switch (syncFusionOperator) {
        case "lessthan":
            return "<";
        case "greaterthan":
            return ">";
        case "greaterthanorequal":
            return ">=";
        case "lessthanorequal":
            return "<=";
        case "notequal":
            return "!==";
        case "equal":
            return "===";
        case "contains":
            return "in";
        case "notcontains":
            return "!==";
        case "startswith":
            return "in";
        case "endswith":
            return "in";
        case "notin":
            return "!==";
        case "isempty":
            return "!";
        case "isnotempty":
            return "!!";
        case "isnull":
            return "!";
        case "isnotnull":
            return "!!";
        default:
            return syncFusionOperator;
    }
};
exports.convertOperator = convertOperator;
