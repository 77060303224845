import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Add Event Listener for the grid row mouse click
 * @param {object} ref - reference of the the grid component
 * @return {object} {function(): void}
 */
export const gridEnableEditing = ({ ref }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    ref,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  ref.element?.addEventListener("mousedown", (e) => {
    if (e.target.classList.contains("e-rowcell")) {
      const index = parseInt(e.target.parentElement.getAttribute("aria-rowindex"));
      const colindex = parseInt(e.target.getAttribute("aria-colindex"));
      const field = ref.getColumns()[colindex].field;
      ref.editModule?.editCell(index, field);
    }
  });

  return () => {
    ref.element?.removeEventListener("mousedown", (e) => {
      if (e.target.classList.contains("e-rowcell")) {
        const index = parseInt(e.target.parentElement.getAttribute("aria-rowindex"));
        const colindex = parseInt(e.target.getAttribute("aria-colindex"));
        const field = ref.getColumns()[colindex].field;
        ref.editModule?.editCell(index, field);
      }
    });
  };
};
