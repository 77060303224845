import PhoneNumber from "awesome-phonenumber";
import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { getCognitoPhoneNumber } from "../../../../utils/Functions/Number/getCognitoPhoneNumber";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { CheckPhoneNumberRegex } from "../../../../utils/Regex/Regex";

/**
 * Component to Display and Edit Point of Contact phone field
 * @param {string} item - the data for object containing the phone number
 * @param {string} field - name of the field inside item representing phone number
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {function} updateItemById - callback for when data changes
 * @returns {JSX.Element}
 */
export const CustomPointOfContactPhoneField = ({ item, module, resource, updateItemById }) => {
  const { updateMutation } = generateGraphql("PointOfContact", ["phone"]);

  const disableEdits = item && item.user;

  return (
    <GenericEditFieldV3
      item={item}
      mutation={updateMutation}
      updateInputFunction={getCognitoPhoneNumber}
      displayFormat={(phoneNumber) => {
        if (isNullOrUndefined(phoneNumber)) {
          return "";
        }
        const pn = new PhoneNumber(phoneNumber);
        return pn?.getNumber("international") ?? "";
      }}
      module={module}
      resource={resource}
      field="phone"
      disableEdits={disableEdits}
      updateItemById={updateItemById}
      inputConfig={{
        isValidInput: (input) => {
          return CheckPhoneNumberRegex(getCognitoPhoneNumber(input));
        },
        validInputMessage: "Phone number is valid",
        invalidInputMessage:
          "Invalid format, must contain 10 - 15 digits. International numbers must start with a plus sign and contain country code.",
      }}
    />
  );
};
