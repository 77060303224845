export const listEvidenceByOwnerGroupForGrid = /* GraphQL */ `
  query EvidencesByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    evidencesByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemNumber
        name
        status
        task
        type
        frequency
        enabled
        allowUnauthenticatedSubmissions
        pointOfContacts(limit: 100) {
          items {
            id
            pointOfContact {
              id
              firstName
              lastName
              email
              title
              user {
                id
              }
              actions(limit: 100) {
                items {
                  id
                  name
                  status
                  createdAt
                  data
                }
              }
            }
          }
        }
        tags(limit: 100) {
          items {
            __typename
            id
            tag {
              id
              name
              description
              fontColor
              backgroundColor
            }
          }
        }
        ownerGroup
      }
      nextToken
    }
  }
`;

export const listEvidenceTemplatesByOwnerGroupForGrid = /* GraphQL */ `
  query EvidencesByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    evidencesByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemNumber
        name
        status
        task
        frequency
        allowUnauthenticatedSubmissions
        ownerGroup
        type
      }
      nextToken
    }
  }
`;
