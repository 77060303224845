"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDatabaseWidget = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
/**
 * Gets all widget database data by its id
 * @param {string} widgetId - widget database identifier
 * @return {Promise<object>} - the widget database data
 */
const getDatabaseWidget = async ({ widgetId }) => {
    // Check if widget id was provided
    if (!widgetId) {
        return null;
    }
    try {
        // Create the database query
        const fields = ["name", "description", "type", "config", "customQuery"];
        const nestedFields = {
            customQuery: `{ id name description queryConfig typename ownerGroup}`,
        };
        const { getQuery } = (0, generategraphql_1.generateGraphql)("CustomWidget", fields, nestedFields);
        // Get widget data from database
        const widget = await (0, appsync_utils_1.GetQuery)({
            query: getQuery,
            variables: { id: widgetId },
        });
        return widget;
    }
    catch (e) {
        console.log(e);
    }
    return null;
};
exports.getDatabaseWidget = getDatabaseWidget;
