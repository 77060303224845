import Add from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useModal } from "../../../../hooks/views/useModal";
import CreateWidget from "../../WidgetBuilder/components/CreateWidget";
import BarChartWidgetTabCard from "../components/BarChartWidgetTabCard";
import CardWidgetTabCard from "../components/CardWidgetTabCard";
import GaugeWidgetTabCard from "../components/GaugeWidgetTabCard";
import HistoryChartWidgetTabCard from "../components/HistoryChartWidgetTabCard";
import PieChartWidgetTabCard from "../components/PieChartWidgetTabCard";
import TableWidgetTabCard from "../components/TableWidgetTabCard";

/**
 * Displays groups of Selection Tiles to allow the user to select a widget to add to a dashboard
 *
 * Groups are based on the widget type, and display Built In vs Custom widgets
 *
 * @param {string} organizationID - The ID of the organization
 * @param {function} onSelect - callback to be called when an item is selected
 * @param {boolean} isTemplate - whether the dashboard is a template
 */
export const useWidgetSelector = ({ organizationID, onSelect, isTemplate }) => {
  const handleSelection = async (item) => {
    await onSelect(item);
  };

  const createWidgetModal = useModal(
    "Create Widget",
    <CreateWidget organizationID={organizationID} callback={handleSelection} />,
    <Button startIcon={<Add />} className={"float-right"}>
      Create Widget
    </Button>,
    {
      width: "80vw",
    },
  );

  const accordion = useAccordion({
    items: [
      {
        title: "Tables",
        subtitle:
          "Tables contain tabular data and can be filtered, exported, and configured to display data in a variety of ways.",
        icon: "icon-list",
        defaultExpanded: true,
        component: (
          <TableWidgetTabCard organizationID={organizationID} onSelect={handleSelection} isTemplate={isTemplate} />
        ),
      },
      {
        title: "Cards",
        icon: "icon-layers",
        component: <CardWidgetTabCard organizationID={organizationID} onSelect={handleSelection} />,
      },
      {
        title: "Gauges",
        icon: "icon-speedometer",
        component: <GaugeWidgetTabCard organizationID={organizationID} onSelect={handleSelection} />,
      },
      {
        title: "History Charts",
        icon: "icon-graph",
        component: (
          <HistoryChartWidgetTabCard
            organizationID={organizationID}
            onSelect={handleSelection}
            isTemplate={isTemplate}
          />
        ),
      },
      {
        title: "Pie Charts",
        icon: "icon-pie-chart",
        component: (
          <PieChartWidgetTabCard organizationID={organizationID} onSelect={handleSelection} isTemplate={isTemplate} />
        ),
      },
      {
        title: "Bar Charts",
        icon: "icon-chart",
        component: (
          <BarChartWidgetTabCard organizationID={organizationID} onSelect={handleSelection} isTemplate={isTemplate} />
        ),
      },
    ],
  });

  const display = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createWidgetModal.modalButton}
      </Grid>
      <Grid item xs={12}>
        {accordion.display}
      </Grid>
    </Grid>
  );

  return {
    accordion,
    display,
  };
};
