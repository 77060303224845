"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepMergeTwoObjects = void 0;
/**
 *
 * @param target
 * @param source
 * @return {boolean|*}
 */
const deepMergeTwoObjects = (target = {}, source = {}) => {
    if (typeof target !== "object" || typeof source !== "object")
        return false;
    for (const prop in source) {
        if (!source.hasOwnProperty(prop))
            continue;
        if (prop in target) {
            if (typeof target[prop] !== "object") {
                target[prop] = source[prop];
            }
            else {
                if (typeof source[prop] !== "object") {
                    target[prop] = source[prop];
                }
                else {
                    if (target[prop].concat && source[prop].concat) {
                        target[prop] = target[prop].concat(source[prop]);
                    }
                    else {
                        target[prop] = (0, exports.deepMergeTwoObjects)(target[prop], source[prop]);
                    }
                }
            }
        }
        else {
            target[prop] = source[prop];
        }
    }
    return target;
};
exports.deepMergeTwoObjects = deepMergeTwoObjects;
