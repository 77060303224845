import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useVulnerabilityDetails } from "../hooks/useVulnerabilityDetails";

/**
 * @description Wrapper component for useVulnerabilityDetails hook
 * @param {object} props
 * @returns {*|JSX.Element}
 * @constructor
 */
const VulnerabilityDetails = (props) => {
  return useVulnerabilityDetails(props).display;
};

export default withDetailsPage(VulnerabilityDetails, {
  title: "Vulnerability Details",
});
