import React from "react";

/**
 * @description Display details for an operation team
 * @param {object} item - operation team
 * @returns {JSX.Element}
 * @constructor
 */
const OperationTeamsListItemDetails = ({ item }) => {
  return (
    <>
      {item && (
        <div>
          {item?.name && <div>Name: {item?.name}</div>}
          {item?.description && <div>Description: {item?.description}</div>}
          {item?.address && <div>Address: {item?.address}</div>}
          {item?.phoneNumber && <div>Phone Number: {item?.phoneNumber}</div>}
          {item?.primaryContact && <div>Primary Contact: {item?.primaryContact}</div>}
          {item?.primaryEmail && <div>Primary Email: {item?.primaryEmail}</div>}
        </div>
      )}
    </>
  );
};

export default OperationTeamsListItemDetails;
