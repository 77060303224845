/**
 * Determines all the different types that a different type (resource) can be represented as
 * - for instance all schema types can be represented as an OBJECT
 * @param {string} resource - the typename for which to get other representative types (useful for sharing query functions across resources)
 * @return {string[]} - the list of types that the resource can be represented as
 */
export const getResourcePrimitiveTypes = ({ resource }) => {
  const primitiveTypes = [];
  if (resource?.isNested) {
    primitiveTypes.push("Object");
  }

  return primitiveTypes;
};
