import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import { useKnowBe4TrainingDetails } from "../hooks/useKnowBe4TrainingDetails";

/**
 * @description Display KnowBe4 Training Campaign details component
 * @param {object} props
 * @constructor
 */
const KnowBe4TrainingDetails = (props) => useKnowBe4TrainingDetails(props).display;

export default withDetailsPage(KnowBe4TrainingDetails, {
  title: "KnowBe4 Training Details",
});
