import { Button } from "@mui/material";
import React from "react";

/**
 * A Reusable component for the filter menus
 * @param {function} onClickClear - callback for pressing the "Clear" button
 * @param {function} [onClickFilter] - if provided will show a "Filter" button to the left of the "Clear" button
 * @param {boolean} [clearDisabled] - if TRUE will show the clear button but it will not be intractable (disabled)
 * @constructor
 */
const FilterAndClearButtonGroup = ({ onClickClear, onClickFilter, clearDisabled }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {/*Filter button*/}
      {onClickFilter && <Button onClick={onClickFilter}>Filter</Button>}

      {/*Spacer between buttons*/}
      {onClickFilter && " "}

      {/*Clear button*/}
      <Button disabled={clearDisabled} onClick={onClickClear}>
        Clear
      </Button>
    </div>
  );
};

export default FilterAndClearButtonGroup;
