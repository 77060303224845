"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatWidgetData = void 0;
const widgetType_1 = require("../../constants/widgetType");
const formatBarChartWidgetData_1 = require("./formatBarChartWidgetData");
const formatHistoryChartWidgetData_1 = require("./formatHistoryChartWidgetData");
const formatPieChartWidgetData_1 = require("./formatPieChartWidgetData");
/**
 * Based on the widget type call the correct data formatter to get final datasource for the widget
 * @param {string} type - widget type
 * @param {object} config - widget settings object
 * @param {object} data - map of arrays with metric data linked to metricType id
 * @return {object} - final dataSource property of the chart config
 */
const formatWidgetData = ({ type, config, data } = {}) => {
    const { metricTypes = {}, customQueries = {} } = data || {};
    switch (type) {
        case widgetType_1.WIDGET_TYPE.HISTORY_CHART:
            return (0, formatHistoryChartWidgetData_1.formatHistoryChartWidgetData)({ config, metricTypes });
        case widgetType_1.WIDGET_TYPE.PIE_CHART:
            return (0, formatPieChartWidgetData_1.formatPieChartWidgetData)({ config, customQueries });
        case widgetType_1.WIDGET_TYPE.BAR_CHART:
            return (0, formatBarChartWidgetData_1.formatBarChartWidgetData)({ config, customQueries });
        default:
            return config?.dataSource;
    }
};
exports.formatWidgetData = formatWidgetData;
