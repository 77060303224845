import React from "react";

import { convertCamelCaseToSentence, enumToDropdownData, formattedName } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import TaskPointOfContact from "@views/Program/Tasks/components/TaskPointOfContact";

import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import CreateTask from "../components/CreateTask";
import { TaskDepartmentCustomField } from "../components/TaskDepartmentCustomField";
import TaskDetails from "../components/TaskDetails";
import { TaskStatus } from "../constants/taskStatus";
import { taskType } from "../constants/taskType";
import { createTaskTagLink } from "../functions/createTaskTagLink";
import { deleteTask } from "../functions/deleteTask";

/**
 * A view representing a list of tasks
 * @param {string} organizationID - the id of the organization for which the task is being created
 * @param {string} moduleInit - the module to use for role checking
 * @param {string} resourceInit - the resource to use for role checking
 * @param {object} initQueryConfig - the query config to use for the data grid
 * @param {object} initGridConfig - the grid config to use for the data grid
 * @param {object} createFormProps - the props to pass down to the create form
 * @param {object} props - any other props to pass down to the useDataGrid hook
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object, setData: (value: (((prevState: Object) => Object) | Object)) => void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useTaskDataGrid = ({
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  queryConfig: initQueryConfig,
  gridConfig: initGridConfig,
  createFormProps,
  ...props
}) => {
  const typename = resources.TASK;
  const module = moduleInit || modules.GOVERNANCE;
  const resource = resourceInit || resources.TASK;

  initQueryConfig = {
    indexName: "tasksByOwnerGroup",
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    ...initQueryConfig,
  };

  const { queryConfig, updateMutation } =
    getResourceQueryData({
      fieldContext: fieldContexts.GRID,
      overrideQueryConfig: initQueryConfig,
      typename,
    }) || {};

  const roleConfig = {
    module,
    resource,
    disableRoleChecking: true,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 100,
      bulkEdit: true,
    },
    {
      name: "description",
      flex: 1,
      minWidth: 100,
      bulkEdit: true,
    },
    {
      name: "module",
      width: 150,
      type: GENERIC_FIELD_TYPES.MODULE,
      disablePropagation: true,
      defaultValue: module || modules.GOVERNANCE,
      filter: {
        type: "CheckBox",
      },
      isHidden: true,
    },
    {
      name: "status",
      width: 150,
      type: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      valueOptions: [
        {
          label: "To Do",
          value: TaskStatus.TODO,
        },
        {
          label: "In Progress",
          value: TaskStatus.IN_PROGRESS,
        },
        {
          label: "Past Due",
          value: TaskStatus.PAST_DUE,
        },
        {
          label: "Done",
          value: TaskStatus.DONE,
        },
      ],
      inputConfig: {
        data: enumToDropdownData({
          ENUM: TaskStatus,
          overrides: {
            [TaskStatus.TODO]: "To Do",
          },
        }),
      },
      displayConfig: {
        customFormat: (value) => {
          if (value === "Todo") {
            return "To Do";
          }
          return value;
        },
        forceSentenceCase: true,
      },
      valueFormatter: (value) => {
        if (value === TaskStatus.TODO) {
          return "To Do";
        }
        return convertCamelCaseToSentence(value);
      },
    },
    {
      name: "type",
      width: 150,
      type: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      inputConfig: {
        data: enumToDropdownData({
          ENUM: {
            CHECKLIST: taskType.CHECKLIST,
            CUSTOM: taskType.CUSTOM,
          },
        }),
      },
      disableEdits: ({ item }) => item?.type !== taskType.CUSTOM && item?.type !== taskType.CHECKLIST,
    },
    {
      name: "dueDate",
      disablePropagation: true,
      width: 150,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
    },
    {
      name: "pointOfContact",
      friendlyName: "Contact",
      component: <TaskPointOfContact />,
      disablePropagation: true,
      width: 150,
      valueGetter: (_value, row) => {
        return formattedName({
          pointOfContact: row?.pointOfContact,
        });
      },
      searchKeys: ["pointOfContact.firstName", "pointOfContact.lastName"],
      isHidden: true,
    },
    {
      name: "department",
      component: <TaskDepartmentCustomField />,
      disablePropagation: true,
      width: 200,
      valueGetter: (value) => value?.name,
      searchKeys: ["department.name"],
      isHidden: true,
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createTaskTagLink,
      width: 200,
      bulkEdit: true,
    },
  ];

  const gridConfig = {
    fields,
    module,
    resource,
    typename,
    organizationID,
    createResourceComponent: <CreateTask {...createFormProps} />,

    options: ["details", "delete", "edit"],
    deleteFunction: deleteTask,
    detailsComponent: <TaskDetails organizationID={organizationID} />,
    updateMutation,
    detailsTitle: "Task Details",
    persistenceUUID: "c949de38-60f7-40d7-9c96-020bb74f9de3",
    enablePersistence: true,
    ...initGridConfig,
  };

  const cardConfig = {
    title: "Tasks",
    headerIcon: "icon-check",
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
    ...props,
  });

  return {
    ...gridCard,
  };
};
