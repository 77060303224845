"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateReportSection = void 0;
const generateReport_1 = require("./generateReport");
const generateSection_1 = require("./generateSection");
/**
 * Generates a full SFDT document object with a single section made up of the input blocks.
 * This is required for 'pasting' SFDT directly into the editor,
 * since the SFDT parser needs section and styling properties
 * @param blocks
 */
const generateReportSection = async (blocks = []) => {
    console.log("[generateReportSection] - ", { blocks });
    return await (0, generateReport_1.generateReport)([(0, generateSection_1.generateSection)({ blocks: [...blocks] })]);
};
exports.generateReportSection = generateReportSection;
