import { useStateEffect } from "./useStateEffect";

/**
 * @description Combines the useState and useEffect hook.
 * @param {*} variable - the variable to initialize the state with.
 * @returns {[unknown,(value: unknown) => void]} - the state and setState function.
 */
export const useStateSync = (variable) => {
  return useStateEffect(variable, [variable], () => {
    return variable;
  });
};
