import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateVendorSubControl } from "../hooks/useCreateVendorSubControl";

/**
 * Component instance of the useCreateVendorSubControl hook
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateVendorSubControl = (props) => {
  return useCreateVendorSubControl(props).display;
};

export default withOrganizationCheck(CreateVendorSubControl);
