/**
 * Helper function for converting legacy module names to new module names on the frontend.
 * Returns the same string if no match is found.
 *
 * @param {string} text - the module name to convert
 * @returns {*|string}
 */
export const moduleDictionary = (text) => {
  switch (text?.toLowerCase()) {
    case "testing":
      return "vulnerabilities";
    case "program":
      return "governance";
    default:
      return text;
  }
};
