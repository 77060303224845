"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleItemMutation = void 0;
/**
 * Performs a mutation on an item.
 * @param mutationFunction - the mutation function
 * @param mutation - the mutation string
 * @param input - the input object
 */
const handleItemMutation = async ({ mutationFunction, mutation, input, }) => {
    console.log("Performing Mutation..");
    console.log({ mutation, input });
    try {
        const response = await mutationFunction(mutation, { input });
        if (response.data === null || response.data === undefined) {
            console.error(`Error: Mutation ${JSON.stringify(response.errors)}`);
            return {};
        }
        const mutationResultKey = Object.keys(response?.data)[0];
        if (mutationResultKey !== undefined && response?.data?.[mutationResultKey]) {
            const item = response?.data?.[mutationResultKey];
            console.log(`Mutation: Item: ${item?.id}`);
            return item;
        }
        return {};
    }
    catch (err) {
        console.error(`Error: Mutation ${JSON.stringify(err)}`);
        return {};
    }
};
exports.handleItemMutation = handleItemMutation;
