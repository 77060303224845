import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { commonProcedures } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";

/**
 * Takes in an item with a "procedures: [ProcedureGroup]" field and determines total completion percentage of all steps
 * @param {object} item - the resource object that holds the procedures data
 * @return {{setTotalCompletion: *, completedPercent: *}}
 */
export const usePlaybookTotalCompletionTracker = ({ item }) => {
  const [completedDecimal, setCompletedDecimal] = useState(0);
  const setTotalCompletion = (playbook) => {
    if (!playbook) {
      setCompletedDecimal(0);
      return;
    }

    //Iterates over each procedure in each procedure group and appends all completion amounts to an array
    const playbookCompletionAmounts = [];
    for (const procedureName of commonProcedures) {
      //Iterate over all procedure groups
      if (Array.isArray(playbook?.procedures)) {
        for (const group of playbook.procedures) {
          //When a group has the named procedure and response steps its completion % is appended
          if (group.hasOwnProperty(procedureName) && !isNullOrUndefined(group[procedureName])) {
            const procedure = group[procedureName];
            if (procedure && Array.isArray(procedure?.responseSteps)) {
              const steps = procedure.responseSteps;
              let countCompleted = 0;
              for (const step of steps) {
                if (step?.completed === true) {
                  countCompleted++;
                }
              }

              if (steps.length > 0) {
                playbookCompletionAmounts.push(countCompleted / steps.length);
              } else {
                playbookCompletionAmounts.push(0);
              }
            }
          }
        }
      }
    }

    const sum = playbookCompletionAmounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let finalPercentage = 0;
    if (playbookCompletionAmounts.length > 0) {
      finalPercentage = sum / playbookCompletionAmounts.length;
    }

    setCompletedDecimal(finalPercentage);
  };
  useEffect(() => {
    setTotalCompletion(item);
  }, [item]);

  return {
    setTotalCompletion,
    completedDecimal,
  };
};
