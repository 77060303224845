import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useQuestionnaireAssignees } from "../hooks/useQuestionnaireAssignees";

/**
 * A component instance of the useQuestionnaireAssignees.js hook
 * @param {object} props
 */
const QuestionnaireAssignees = (props) => {
  return useQuestionnaireAssignees(props).display;
};

export default withOrganizationCheck(QuestionnaireAssignees);
