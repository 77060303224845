"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertQueryStringPathToObjectPath = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Traversed the query config to recreate the string path as an array of objects with more details
 * @param {object} config - the parsed queryConfig field from a Custom Query
 * @param {string[]} stringPath - a path to a query result field
 * @returns {*[]|null} - null if string path could not be traversed in config,
 * otherwise an array of objects representing the path to the result field
 */
const convertQueryStringPathToObjectPath = ({ config, stringPath }) => {
    const parsedConfig = (0, func_utils_1.tryParse)(config);
    if (!(0, func_utils_1.isObject)(parsedConfig)) {
        return null;
    }
    if (!(0, func_utils_1.isNonEmptyArray)(stringPath)) {
        return null;
    }
    const objectPath = [];
    let currentConfig = parsedConfig;
    for (const pathItem of stringPath) {
        const { functions, fields } = currentConfig || {};
        let foundItem = null;
        if ((0, func_utils_1.isObject)(functions) && functions.hasOwnProperty(pathItem)) {
            foundItem = functions[pathItem];
        }
        if (!foundItem && (0, func_utils_1.isObject)(fields) && fields.hasOwnProperty(pathItem)) {
            foundItem = fields[pathItem];
        }
        if (!foundItem) {
            return null;
        }
        objectPath.push(foundItem);
        currentConfig = foundItem;
    }
    return objectPath;
};
exports.convertQueryStringPathToObjectPath = convertQueryStringPathToObjectPath;
