"use strict";
/**
 * @function
 * @description flatten Array Of Objects
 * @param array {Array}
 * @param propertyName {string}
 * @returns {object[]|*}
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenArrayOfObjects = void 0;
const flattenArrayOfObjects = (array, propertyName) => {
    if (array && propertyName) {
        const temp = [];
        array.forEach((item) => {
            temp.push(item[propertyName]);
        });
        return temp;
    }
    else {
        return array;
    }
};
exports.flattenArrayOfObjects = flattenArrayOfObjects;
