import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useRoleTemplateDetails } from "../hooks/useRoleTemplateDetails";

/**
 * Component wrapper for role templates details page hook
 * @param {object} props - the props passed down from parent component and HOC withDetailsPage
 * @returns {JSX.Element}
 */
const RoleTemplateDetails = (props) => {
  return useRoleTemplateDetails(props).display;
};

export default withDetailsPage(RoleTemplateDetails);
