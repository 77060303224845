import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

import { useEvidencePointOfContacts } from "./hooks/useEvidencePointOfContacts";

/**
 * Displays the currently assigned points of contact for an Evidence
 * @param {object} props - the props for the component
 * @returns {*}
 * @constructor
 */
const EvidencePointOfContacts = (props) => {
  return useEvidencePointOfContacts(props).display;
};

export default withOrganizationCheck(EvidencePointOfContacts);
