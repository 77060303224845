export const getControlSet = /* GraphQL */ `
  query GetControlSet($id: ID!) {
    getControlSet(id: $id) {
      id
      name
      controls(limit: 1000) {
        items {
          evidences(limit: 1000) {
            items {
              evidence {
                id
                ownerGroup
                itemNumber
                name
                status
                pointOfContacts(limit: 100) {
                  items {
                    id
                    pointOfContact {
                      id
                      firstName
                      lastName
                      email
                      title
                      user {
                        id
                      }
                      actions(limit: 100) {
                        items {
                          id
                          name
                          status
                          createdAt
                          data
                        }
                      }
                    }
                  }
                }
                frequency
                type
                enabled
                allowUnauthenticatedSubmissions
                tags(limit: 100) {
                  items {
                    __typename
                    id
                    tag {
                      id
                      name
                      description
                      fontColor
                      backgroundColor
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
