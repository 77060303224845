import { schemaEntities } from "../../schemaEntities";

export const noteDefinition = {
  schemaEntity: schemaEntities.TYPE,

  fields: {
    type: {},
    author: {},
    timeStamp: {},
    content: {},
    tag: {},
    observationID: {},
  },
};
