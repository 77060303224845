import React from "react";

import { useModal } from "../../../../hooks/views/useModal";

import MultipleArtifactValidation from "./MultipleArtifactValidation";

/**
 * Hook for displaying the modal for validating multiple artifacts
 * @param {object} artifacts - the artifacts in need of validation
 * @param {function} resetFunction - function the refreshes the artifacts that need validation
 * @param {boolean} isLoading - if TRUE the artifacts to validate are still loading
 * @param {string} module - the module this artifact validation is taking place for role checking
 * @param {string} resource - the resource this artifact validation is taking place, for role checking
 * @returns {{setModalIsOpen: *, modalButton: *, modalIsOpen: *, modal: *, toggleModal: function(): void}}
 */
export const useMultipleArtifactValidationModal = ({ artifacts, resetFunction, isLoading, module, resource }) => {
  const isOne = Array.isArray(artifacts) && artifacts.length === 1;

  const modal = useModal(
    isOne ? "Artifact Validation" : "Select an Artifact to Validate",
    <MultipleArtifactValidation
      artifacts={artifacts}
      resetFunction={resetFunction}
      isLoading={isLoading}
      module={module}
      resource={resource}
    />,
    null,
    {
      width: "90vw",
    },
  );

  return {
    ...modal,
  };
};
