import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { useGUID } from "../../../functional/useGUID";
import { gridMenuItems } from "../functions/gridMenuItems";
import { gridOnMenuClick } from "../functions/gridOnMenuClick";
import { gridResetPersistence } from "../functions/gridResetPersistence";
import { useGridContext } from "../hooks/useGridContext";

/**
 * @description Display menu bar for the grid
 * @return {JSX.Element}
 * @constructor
 */
const GridToolbarMenu = () => {
  const {
    enablePrint,
    checkPermissionsHook,
    enablePersistence,
    checkPermissionsHelpCenter,
    persistenceUUID,
    propsMenuItems,
    ref,
    deleteFunction,
    deleteConfirmModal,
    selectedItems,
    fields,
    deleteMessage,
    resetFunction,
    setSelectedItems,
    getGridID,
    setEnablePers,
    setKey,
    enableMenu,
    setPreference,
    getPreference,
    helpCenterUrl,
    deleteButtonText,
  } = useGridContext();

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const initMenuItems = gridMenuItems({
      enablePrint,
      checkPermissionsHook,
      enablePersistence,
      checkPermissionsHelpCenter,
      persistenceUUID,
      propsMenuItems,
      deleteButtonText,
    });

    setMenuItems(initMenuItems);

    /**
     * Get all nodes from the left side of the toolbar, return if the element is not yet available
     */
    let toolbarLeftNodes;
    if (ref?.element) {
      const toolbarLeft = ref.element.getElementsByClassName("e-toolbar-left");
      if (toolbarLeft.length !== 0 && toolbarLeft[0].childNodes) {
        toolbarLeftNodes = toolbarLeft[0].childNodes;
      }
    }
    if (!toolbarLeftNodes) return;

    /**
     * Filter nodes that you need to move to the right side of the toolbar of the grid
     */
    const items = [].slice.call(toolbarLeftNodes).filter((item) => {
      return item?.firstChild?.firstChild?.getAttribute("id") === "button-create-item-grid";
    });

    /**
     * Remove nodes that need to stay on the left side of the toolbar
     */
    items.forEach((element) => {
      element.remove();
    });

    /**
     * Add nodes to the right side of the grid toolbar
     */
    for (const item of items) {
      ref?.element?.getElementsByClassName("e-toolbar-right")[0].appendChild(item);
    }
  }, [ref]);

  const { helpCenter } = useUIContext();

  /**
   * On select dropdown item
   */
  const onSelect = (args) => {
    gridOnMenuClick({
      item: args?.item,
      ref,
      fields,
      selectedItems,
      resetFunction,
      deleteGridItems: () => deleteConfirmModal?.setModalIsOpen(true),
      resetLocalStorage: () =>
        gridResetPersistence({
          ref,
          getGridID,
          setEnablePers,
          setKey,
          persistenceUUID,
          setPreference,
          getPreference,
        }),
      helpCenter,
      helpCenterUrl,
    });
  };

  const [id] = useGUID();

  return (
    <>
      {enableMenu &&
        menuItems &&
        Array.isArray(menuItems) &&
        menuItems.length > 0 &&
        menuItems.map((item) => (
          <DropDownButtonComponent
            // makes multiple grids in the same modal not cause memory leak
            id={id + (!isNullOrUndefined(item?.id) ? item?.id : "")}
            key={item?.id}
            items={item?.items}
            select={onSelect}
            cssClass="e-caret-hide"
          >
            {item?.text}
          </DropDownButtonComponent>
        ))}
    </>
  );
};

export default GridToolbarMenu;
