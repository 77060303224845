/**
 * Finds first and last names in an items point of contact field and returns the full name
 * @param {object} item from the database with a point of contact field
 * @returns {string} full name of the point of contact if it is present
 */
export const extractEvidencePointOfContact = (item) => {
  let fullName = "";

  if (item?.pointOfContact) {
    if (item.pointOfContact.firstName) {
      fullName = item.pointOfContact.firstName;
    }
    if (item.pointOfContact.lastName) {
      if (fullName === "") {
        fullName = item.pointOfContact.lastName;
      } else {
        fullName += ` ${item.pointOfContact.lastName}`;
      }
    }
  }
  return fullName;
};
