"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMode = void 0;
const convertAndFilterOnlyNumericItems_1 = require("./convertAndFilterOnlyNumericItems");
/**
 * Returns the mode of the array. The number which occurs the most amount of times.
 * @param {*[]} items - array of items that can be converted to numeric values or are already numbers
 * @returns {number|null} - the mode or null if no valid items were passed
 */
const getMode = ({ items }) => {
    const numericItems = (0, convertAndFilterOnlyNumericItems_1.convertAndFilterOnlyNumericItems)({ items });
    if (!Array.isArray(numericItems) || numericItems.length === 0) {
        return null;
    }
    else {
        const counts = {};
        numericItems.forEach((item) => {
            counts[item] = (counts[item] || 0) + 1;
        });
        const maxCount = Math.max(...Object.values(counts));
        const modes = Object.keys(counts).filter((key) => counts[key] === maxCount);
        //only return the lowest mode
        if (Array.isArray(modes) && modes.length > 1) {
            return modes[0];
        }
        else {
            return null;
        }
    }
};
exports.getMode = getMode;
