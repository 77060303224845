import Switch from "@mui/material/Switch";
import React, { useState } from "react";

/**
 * Controls weather to group vulnerability timeline items by assessment or not
 * @return {{display: JSX.Element, isGrouped: (*|boolean)}}
 */
export const useGroupHistoryByAssessment = () => {
  const [isGrouped, setIsGrouped] = useState(true);

  const display = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <Switch
        size="small"
        onClick={() => setIsGrouped(!isGrouped)}
        value={isGrouped}
        checked={isGrouped}
        label="Group by Assessment"
      />
      <span style={{ marginLeft: ".5em", marginRight: ".5em" }}>Group by Assessment</span>
    </div>
  );

  return {
    isGrouped,
    display,
  };
};
