import { useContext } from "react";

import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import SelectionTiles from "../../../../../../utils/GenericComponents/SelectionTiles/components/SelectionTiles";
import { createWidgetActions } from "../reducers/createWidgetActions";
import { CreateWidgetContext } from "../useCreateWidget";

/**
 * Step allowing users to choose the type of custom query to create
 * @returns {JSX.Element}
 */
const SelectTypeStep = () => {
  const { widget, dispatch } = useContext(CreateWidgetContext);

  return (
    <SelectionTiles
      items={[
        {
          text: "Table",
          type: WIDGET_TYPE.TABLE,
          icon: "material-symbols:format-list-bulleted-add",
        },
        {
          text: "History Chart",
          type: WIDGET_TYPE.HISTORY_CHART,
          icon: "ph:chart-line-bold",
        },
        {
          text: "Pie Chart",
          type: WIDGET_TYPE.PIE_CHART,
          icon: "simple-line-icons:pie-chart",
        },
        {
          text: "Bar Chart",
          type: WIDGET_TYPE.BAR_CHART,
          icon: "ri:bar-chart-box-line",
        },
      ]}
      checkIsSelected={(item) => {
        return item?.type === widget?.type;
      }}
      onSelect={(item) => {
        dispatch({
          type: createWidgetActions.UPDATE_WIDGET_INPUT,
          newWidget: {
            type: item.type,
          },
        });
      }}
    />
  );
};

export default SelectTypeStep;
