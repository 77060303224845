import React from "react";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import RiskRecommendationSettings from "../../../Risk/RiskConfig/MonteCarloSettings/components/RiskRecommendationSettings";
import { RecommendationStatus } from "../enums/RecommendationStatus";

/**
 * Displays the Status field for a RecommendationItem.
 * Allows the user to set the status to 'resolved' or 'unresolved'
 * @param {object} item - recommendation
 * @param {function} updateItemById - function to update item by id
 * @param {function} resetFunction - function to reset the parent state
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 */
const Resolved = ({ item, updateItemById, resetFunction, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.RECOMMENDATION;
  const field = "status";

  /**
   * Sets the resolved date if this recommendation is marked as `resolved`
   * @param {object} input
   * @returns {*}
   */
  const updateMutationFunction = (input) => {
    if (input.status === "resolved") {
      input.resolvedAt = new Date();
    }

    return input;
  };

  return (
    <GenericEditFieldV3
      item={item}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: enumToDropdownData({ ENUM: RecommendationStatus }),
      }}
      field={field}
      mutation={generateGraphql("RecommendationItem", [field]).updateMutation}
      displayConfig={{
        forceSentenceCase: true,
      }}
      updateItemById={updateItemById}
      module={module}
      resource={resource}
      updateMutationFunction={updateMutationFunction}
      editSettingsComponent={item?.module === modules.RISK && <RiskRecommendationSettings />}
      {...props}
    />
  );
};

export default Resolved;
