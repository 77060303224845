import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateRiskChange } from "../hooks/useCreateRiskChange/useCreateRiskChange";

/**
 * Component used for creating risk changes
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateChange = (props) => useCreateRiskChange(props).display;

export default withOrganizationCheck(CreateChange);
