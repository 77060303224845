import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import { useDepartmentDetails } from "../../OrganizationManager/Departments/hooks/useDepartmentDetails";

/**
 * @description Component to display details for a single Department Template
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const DepartmentTemplateDetails = (props) => useDepartmentDetails(props).display;

export default withDetailsPage(DepartmentTemplateDetails);
