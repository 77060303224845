/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  *  - 10/18/19 JB: Added better styling
 *     - 10/18/19 JB: Added Context Menu and Print options (WIP)
 *
 * Description: Displays a Program Blueprint.
 *              Edits are turned off by default, but may be switched on via the `enableEdits` prop.
 *              This component displays a group of Maturity Columns as its children.
 *              This component is UI only (no query), it MUST receive a "maturityMatrix" object as a prop to function.
 */

import { PDFExport } from "@progress/kendo-react-pdf";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditField";
import { GenericModalWithButton } from "../../../../../../utils/GenericComponents/GenericModalWithButton";
import MaturityLevelRadarChart from "../../../../../Charts/VISCO/MaturityLevelRadarChart";
import CreateMaturityColumn from "../../deprecated/MaturityColumn/CreateMaturityColumn";
import MaturityColumnVertical from "../../deprecated/MaturityColumn/MaturityColumnVertical";

const MaturityMatrixVertical = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [filename, setFilename] = useState("untitled_matrix");
  const [pdf, setPdf] = useState("");

  const setModal = (body) => {
    body && setModalIsOpen(!modalIsOpen);
    setModalBody(body);
  };

  return (
    <div>
      {props.maturityMatrix ? (
        <>
          <Row>
            <h4 style={{ marginBot: "20px", textAlign: "center" }}>
              <GenericEditField
                item={props.maturityMatrix}
                field="name"
                mutation="updateMaturityMatrix"
                disableEdits={!props.enableEdits}
              />
            </h4>
            {props.enableEdits ? (
              <>
                <GenericModalWithButton
                  title={`Create Maturity Column for: ${props.maturityMatrix.name}`}
                  body={<CreateMaturityColumn maturityMatrix={props.maturityMatrix} />}
                  modalButton={
                    <Button style={{ marginBottom: "15px" }} color="success">
                      Create New Maturity Column
                    </Button>
                  }
                />
              </>
            ) : null}
          </Row>
          <PDFExport
            scale={0.6}
            margin="1in"
            landscape
            paperSize="Letter"
            fileName={`${filename}.pdf`}
            title=""
            subject=""
            keywords=""
            ref={(r) => setPdf(r)}
          >
            <Row>
              {props.settings.showRadarChart && (
                <Col lg={4}>
                  <div style={{ height: "500px", background: "white" }}>
                    <MaturityLevelRadarChart maturityMatrix={props.maturityMatrix} />
                  </div>
                </Col>
              )}
              {props.maturityMatrix &&
              props.maturityMatrix.maturityColumns &&
              props.maturityMatrix.maturityColumns.items &&
              props.maturityMatrix.maturityColumns.items.length > 0
                ? props.maturityMatrix.maturityColumns.items.map((column, index) => (
                    <MaturityColumnVertical
                      settings={props.settings}
                      enableEdits={props.enableEdits}
                      key={`maturityColumn${column.id}${index}`}
                      maturityColumn={column}
                      setModalBody={setModal}
                    />
                  ))
                : "No Maturity Columns"}
            </Row>
          </PDFExport>
        </>
      ) : null}
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>Maturity Indicator Details</ModalHeader>
        <ModalBody>{modalBody && React.cloneElement(modalBody)}</ModalBody>
      </Modal>
    </div>
  );
};

MaturityMatrixVertical.propTypes = {
  maturityMatrix: PropTypes.object.isRequired,
  enableEdits: PropTypes.bool,
  settings: PropTypes.object,
};

MaturityMatrixVertical.defaultProps = {
  enableEdits: false,
  settings: {},
};

export default MaturityMatrixVertical;
