import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col } from "reactstrap";

import DashboardTile from "../../utils/GenericComponents/DashboardTile";
import Loader from "../../utils/LoadingComponents/Loader";

/**
 * A dashboard with Tiles across the top that allows the user to switch between pages.
 * @param {string} id - css prefix to use for the tile container id property
 * @param {boolean} isLoading - if TRUE will display a loader for totals
 * @param {object[]} tiles - array of tile settings for the dashboard, defines names and order
 * @param {JSX.Element[]} headerButtons - button to display next to dashboard title
 * @param {object} tileConfig - the size of tiles at different screen sizes
 * @param {boolean} [expandTiles=false] - if TRUE will try to make the tiles fill the full width of the screen
 * @param {number} [initial = 0] - the index of the tile to start on
 * @param {string} tourId - the tour id for the whole dashboard
 * @param {string} headerTourId - tourId for the header buttons
 * @param {string} contentTourId - tourID for the body of the dashboard
 * @param {object} childrenProps - props to pass to the 'component' React element of each tile
 * @param {function} onTileChange - callback for when the user changes the selected tile
 * @param {JSX.Element} tileComponent - the component to use for each tile
 * @param {JSX.Element} contentComponent - the component to use for the content of the dashboard
 *
 * tile = {
 *   id,    // required, used for selected tile key
 *   name,
 *   title,
 *   icon,
 *   text,
 *   content,
 *   component,
 *   gradient1,
 *   gradient2,
 *   badgeColor,
 *   disabled,
 *   disableTotals,
 *   tourId,
 *   total,
 *   rightBottomComponent,
 *   rightTopComponent,
 *   footerComponent,
 * }
 */
export const useTileDashboard = ({
  id,
  isLoading = false,
  tiles,
  expandTiles = false,
  headerButtons,
  tileConfig = {},
  initial = 0,
  tourId,
  headerTourId,
  contentTourId,
  onTileChange,
  tileComponent,
  contentComponent,
  childrenProps = {},
}) => {
  const [selectedTile, setSelectedTile] = useState(tiles[initial] || "");

  /**
   * Refreshes the selectedTile state when the tiles array changes
   */
  useEffect(() => {
    if (tiles && Array.isArray(tiles) && tiles.length > 0 && !isNaN(initial)) {
      setSelectedTile(tiles[initial]);
    }
  }, [tiles?.length, initial]);

  const expandTileWidth = tiles.length === 1 ? 12 : tiles.length === 2 ? 6 : tiles.length === 3 ? 4 : 3;

  const expandTileConfig = {
    lg: expandTileWidth,
    md: expandTileWidth,
    sm: expandTileWidth,
    xl: expandTileWidth,
    xxl: expandTileWidth,
  };

  tileConfig = {
    ...(expandTiles && { ...expandTileConfig }),
    ...tileConfig,
  };

  useEffect(() => {
    if (tiles && Array.isArray(tiles) && tiles.length > 0) setSelectedTile(tiles[initial]);
  }, [tiles.length]);

  const handleSelect = (selectedTile) => {
    const selectedIndex = tiles.findIndex((tile) => tile.id === selectedTile.id);
    if (typeof onTileChange === "function") {
      onTileChange({ tile: selectedTile, index: selectedIndex });
    }
    setSelectedTile(selectedTile);
  };

  const checkSelected = (name) => {
    return selectedTile.name === name;
  };

  const display = (
    <div
      id={"main_card"}
      data-tourid={tourId}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {headerButtons && (
        <>
          <div style={{ position: "static" }}>{headerButtons && React.cloneElement(headerButtons)}</div>
          <br />
        </>
      )}
      <div className="e-card-header" data-tourid={headerTourId}>
        <DashboardTiles
          id={`${id}tiles`}
          tiles={tiles}
          handleSelect={handleSelect}
          checkSelected={checkSelected}
          selectedTile={selectedTile}
          tileComponent={tileComponent}
          {...tileConfig}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
        className="e-card-content"
        data-tourid={contentTourId}
      >
        {!isLoading &&
          (selectedTile?.component || contentComponent) &&
          React.cloneElement(selectedTile.component || contentComponent, {
            tile: selectedTile,
            ...childrenProps,
          })}
        {isLoading && (
          <div>
            <Loader /> Loading...
          </div>
        )}
      </div>
    </div>
  );

  return {
    display,
    selectedTile,
    setSelectedTile,
  };
};

const DashboardTiles = ({ tiles, tileComponent, handleSelect, selectedTile, xs, sm, md, lg, xl, xxl }) => {
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const [ref, setRef] = useState("");

  const scrollSpeed = 2;

  const scrollRight = () => {
    if (ref) {
      ref.scrollLeft = ref.scrollLeft + scrollSpeed;
    }
  };

  const scrollLeft = () => {
    if (ref) {
      ref.scrollLeft = ref.scrollLeft - scrollSpeed;
    }
  };

  const [scrollingLeft, setScrollingLeft] = useState(false);
  const [scrollingRight, setScrollingRight] = useState(false);

  const [scrollCount, setScrollCount] = useState(0);

  /**
   * Handles continuous scrolling
   */
  useEffect(() => {
    if (scrollingRight && showRightArrow) {
      scrollRight();
      setScrollCount((scrollCount) => scrollCount + 1);
    }

    if (scrollingLeft && showLeftArrow) {
      scrollLeft();
      setScrollCount((scrollCount) => scrollCount + 1);
    }
  }, [scrollCount, scrollingRight, scrollingLeft]);

  /**
   * Disable scrolling when it reaches the start
   */
  useEffect(() => {
    if (!showLeftArrow) {
      setScrollingLeft(false);
    }
  }, [showLeftArrow]);

  /**
   * Disable scrolling when it reaches the end
   */
  useEffect(() => {
    if (!showRightArrow) {
      setScrollingRight(false);
    }
  }, [showRightArrow]);

  const arrowStyle = {
    height: "7em",
    width: "2.5em",
    marginTop: "1em",
    color: "grey",
    cursor: "pointer",
    marginLeft: "0.1em",
    marginRight: "0.1em",
    textAlign: "center",
  };

  const getStyle = () => {
    return { display: "flex", flexDirection: "row", flex: 1 };
  };

  return (
    <div style={getStyle()}>
      {showLeftArrow && (
        <div
          onMouseOver={() => setScrollingLeft(true)}
          onMouseLeave={() => setScrollingLeft(false)}
          className="e-card"
          style={arrowStyle}
        >
          <i className="icon-arrow-left" />
        </div>
      )}
      <PerfectScrollbar
        style={{ flex: 1 }}
        onXReachStart={() => setShowLeftArrow(false)}
        onXReachEnd={() => setShowRightArrow(false)}
        onScrollRight={() => setShowLeftArrow(true)}
        onScrollLeft={() => setShowRightArrow(true)}
        containerRef={(ref) => setRef(ref)}
      >
        <div
          style={{
            marginBottom: "1em",
            marginTop: "1em",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {tiles.map((tile, index) => (
            <Col
              key={tile.name + index}
              widths={["xs", "sm", "md", "lg", "xl", "xxl"]}
              xs={xs || 12}
              sm={sm || 6}
              md={md || 4}
              lg={lg || 4}
              xl={xl || 3}
              xxl={xxl || 2}
              style={{
                display: "inline-block",
                marginBottom: "1em",
                paddingLeft: index === 0 ? "1px" : undefined,
                paddingRight: index === tiles.length - 1 ? "1px" : undefined,
              }}
            >
              {React.cloneElement(tileComponent ?? <DashboardTile />, {
                title: tile.title,
                icon: tile.icon,
                itemTotal: tile.total,
                isSelected: selectedTile.id === tile.id,
                setIsSelected: () => handleSelect(tile),
                disabled: false,
                text: tile.text,
                content: tile.content,
                rightBottomComponent: tile.rightBottomComponent,
                rightTopComponent: tile.rightTopComponent,
                footerComponent: tile.footerComponent,
                disableTotals: tile.disableTotals,
                badgeColor: tile.badgeColor,
                gradient1: tile.gradient1,
                gradient2: tile.gradient2,
                tile,
              })}
            </Col>
          ))}
        </div>
      </PerfectScrollbar>
      {showRightArrow && (
        <div
          onMouseOver={() => setScrollingRight(true)}
          onMouseLeave={() => setScrollingRight(false)}
          className="e-card"
          style={arrowStyle}
        >
          <i className="icon-arrow-right" />
        </div>
      )}
    </div>
  );
};
