import { ROOT_URL } from "../../../../env-config";
import { RESOURCE_TYPE_CONFIG } from "../../../../utils/ResourceTypes/RESOURCE_TYPE_CONFIG";

/**
 * Configures the 'mergeTags' object to use with the emailEditor
 * @returns {{resources: {name: string, mergeTags: {}}, widgets: {name: string, mergeTags: {charts: {name: string, mergeTags: {compliancePieChart: {name: string}}}, tables: {name: string, mergeTags: {evidenceTable: {name: string, value: string}}}}}}}
 */
export const getMergeTags = ({ widgets = [] }) => {
  const res = {};

  const tableWidgets = {};
  const chartWidgets = {};

  if (Array.isArray(widgets) && widgets.length > 0) {
    for (const widget of widgets) {
      if (widget.type === "table") {
        tableWidgets[widget.name] = {
          name: widget.name,
          value: `{{${widget?.name}::${widget?.id}}}`,
        };
      } else if (widget.type === "chart") {
        chartWidgets[widget.name] = {
          name: widget.name,
          value: `{{${widget?.name}::${widget?.id}}}`,
        };
      }
    }
  }

  for (const [resource, resourceConfig] of Object.entries(RESOURCE_TYPE_CONFIG)) {
    const resourceMergeTag = {
      name: resourceConfig.name,
      mergeTags: {},
    };

    for (const [field, fieldConfig] of Object.entries(resourceConfig.fields)) {
      resourceMergeTag.mergeTags[field] = {
        name: field,
        value: `{{${resource}.${field}}}`,
      };
    }

    res[resource] = resourceMergeTag;
  }

  return {
    widgets: {
      name: "Widgets",
      mergeTags: {
        charts: {
          name: "Charts",
          mergeTags: {
            ...chartWidgets,
          },
        },
        tables: {
          name: "Tables",
          mergeTags: {
            ...tableWidgets,
          },
        },
      },
    },
    resources: {
      name: "Resources",
      mergeTags: res,
    },
    routes: {
      name: "URL Routes",
      mergeTags: {
        ...ROUTES,
      },
    },
  };
};

const ROUTES = {
  observationDetails: {
    name: "Observation Details",
    value: `${ROOT_URL}/governance/observations/{{item.id}}`,
  },
};
