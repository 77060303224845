"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnnualRateOfOccurrence = void 0;
const checkSystemForRiskOverride_1 = require("./checkSystemForRiskOverride");
const findIndexOfRiskOverride_1 = require("./findIndexOfRiskOverride");
const isRiskOverrideFieldEnabled_1 = require("./isRiskOverrideFieldEnabled");
/**
 * Gets an annualRateOfOccurrence value for a risk on a particular system
 * @param risk - the risk to get the ARO for
 * @param system - the system that we are running calculations on
 */
const getAnnualRateOfOccurrence = (risk, system) => {
    const indexOfRiskOverride = system.riskOverrides ? (0, findIndexOfRiskOverride_1.findIndexOfRiskOverride)(system.riskOverrides, risk) : -1;
    const doesOverrideExist = (0, checkSystemForRiskOverride_1.checkSystemForRiskOverride)(system.riskOverrides, risk) !== false;
    const isOverrideEnabled = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskOverrideFieldEnabled_1.isRiskOverrideFieldEnabled)(system.riskOverrides[indexOfRiskOverride], "annualRateOfOccurrence");
    const overrideValue = doesOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        system.riskOverrides[indexOfRiskOverride]?.annualRateOfOccurrence;
    let value = 0;
    if (isOverrideEnabled) {
        if (overrideValue && !isNaN(overrideValue)) {
            value = overrideValue;
        }
        else {
            value = 0.0;
        }
    }
    else if (risk.annualRateOfOccurrence !== undefined) {
        value = risk.annualRateOfOccurrence;
    }
    return value;
};
exports.getAnnualRateOfOccurrence = getAnnualRateOfOccurrence;
