"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRenderedWidget = void 0;
const report_utils_1 = require("@rivial-security/report-utils");
/**
 * Takes in final formatted widget config and
 * @param {object} config - formatted widget config with data and custom options all set
 * @param {function} renderWidget - function to render the widget (convert from formatted config to base64 image string)
 */
const createRenderedWidget = async ({ config, renderWidget }) => {
    let imgString;
    let width;
    let height;
    if (renderWidget) {
        const view = await renderWidget({ config });
        imgString = view?.imgString;
        width = view?.clip?.width;
        height = view?.clip?.height;
    }
    else {
        return (0, report_utils_1.createText)(`[ERROR: Could not render widget]`);
    }
    if (!imgString) {
        return (0, report_utils_1.createText)(`[ERROR: Could not finish rendering widget]`);
    }
    //Check if width and height are given in pixels
    if (!height || !width) {
        return (0, report_utils_1.createText)("[ERROR: Could not find widget width or height]");
    }
    //convert the widget image to match report width
    const targetImageWidth = 450;
    const conversionFactor = targetImageWidth / width;
    const newHeight = height * conversionFactor;
    return {
        paragraphFormat: {
            styleName: "Normal",
            listFormat: {},
        },
        characterFormat: {},
        inlines: [
            {
                characterFormat: {},
                imageString: imgString,
                isMetaFile: false,
                width: targetImageWidth,
                height: newHeight,
                name: "1",
                visible: true,
                verticalPosition: 0,
                verticalOrigin: "Margin",
                verticalAlignment: "None",
                horizontalPosition: 0,
                horizontalOrigin: "Margin",
                horizontalAlignment: "None",
                allowOverlap: true,
                textWrappingStyle: "Inline",
                textWrappingType: "Both",
                distanceBottom: 0,
                distanceLeft: 9,
                distanceRight: 9,
                distanceTop: 0,
                layoutInCell: true,
                zOrderPosition: 2147483647,
            },
        ],
    };
};
exports.createRenderedWidget = createRenderedWidget;
