import React from "react";

import { useModal } from "../../../hooks/views/useModal";
import CreateButton from "../../GenericComponents/CreateButton";
import CreateLabel from "../components/CreateLabel";

/**
 * A customized modal for creating a single label
 * @param {object} props - all props to pass down to the create label form
 * @returns {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useCreateLabelModal = (props) => {
  const modalHook = useModal("Create a new Label", <CreateLabel {...props} />, <CreateButton typename={"Label"} />);

  return {
    ...modalHook,
  };
};
