import React from "react";

import { useForm } from "../../../../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import NestedField from "../../../../../../../utils/GenericComponents/NestedField/NestedField";
import { SelectItemGrid } from "../../../../../../../utils/GenericComponents/SelectItemGrid";
import { useVendorControlCategoryDataGrid } from "../../../../../../Vendor/VendorControlCategories/hooks/useVendorControlCategoryDataGrid";
import CreateVendorSubControl from "../../../../../../Vendor/VendorSubControls/components/CreateVendorSubControl";
import VendorSubControlDetails from "../../../../../../Vendor/VendorSubControls/components/VendorSubControlDetails";
import VendorSubControlGrid from "../../../../../../Vendor/VendorSubControls/components/VendorSubControlGrid";

/**
 * Custom field displaying the vendor sub control linked to a risk control item
 * @param {object} item - the risk control data with vendor field populated
 * @param {string} organizationID - the selected organization
 * @param {function} resetFunction - function to call to re-query the item data
 * @return {JSX.Element}
 */
const RiskControlVendorSubControl = ({ item, organizationID, resetFunction } = {}) => {
  const prepareSelectionForm = useForm({
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      vendorControlCategory: {
        inputType: "item-select",
        label: "Vendor Control Category",
        tooltip: "Select which vendor control category contains the vendor control you would like to link.",
        required: true,
        itemSelectConfig: {
          grid: <SelectItemGrid organizationID={organizationID} gridHook={useVendorControlCategoryDataGrid} />,
          outputAsObject: true,
          hideSelectButton: true,
        },
      },
    },
  });

  return (
    <NestedField
      typename={"VendorSubControl"}
      parentTypename={"RiskControl"}
      item={item}
      field={"vendorSubControl"}
      idField={"vendorSubControlID"}
      parentIdField={"riskControlID"}
      displayField={"name"}
      prepareSelectionForm={prepareSelectionForm}
      detailsComponent={<VendorSubControlDetails />}
      form={<CreateVendorSubControl />}
      grid={<VendorSubControlGrid />}
      biDirectional={true}
      resetFunction={resetFunction}
    />
  );
};

export default withOrganizationCheck(RiskControlVendorSubControl);
