import { useRoleTemplateGrid } from "../hooks/useRoleTemplateGrid";

/**
 * Component instance of the useRoleTemplateGrid hook
 * @param {object} props - props to pass down from parent to create role template hook and the HOC component
 * @returns {JSX.Element}
 */
const RoleTemplateGrid = (props) => {
  return useRoleTemplateGrid(props).display;
};

export default RoleTemplateGrid;
