import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";

import { useLabelBookmarks } from "../hooks/useLabelBookmarks";

/**
 * Displays a single label, optionally with information on nested resources
 * @param {object} label - label information from the database, see Label model in schema
 * @param {function} onClick - callback function to handle click on the label tag
 * @param {function} onUpdatedLabelLink - callback when the label ui modifies the label link
 * @param {function} onRemoved - callback when label is removed
 * @param {function} onSelect - callback when label is selected (visible)
 * @param {function} onDeselect - callback when label is deselected (disabled)
 * @param {function} showBookmarks - if TRUE will show the bookmark count and allow to see all bookmarks in a modal
 * @param {boolean} isDisabled - if true the label will not be selectable/deletable
 * @returns {JSX.Element}
 */
const Label = ({
  label,
  onClick,
  onUpdatedLabelLink,
  onRemoved,
  onSelect,
  onDeselect,
  showBookmarks = false,
  isDisabled = false,
}) => {
  const {
    id,
    name = "Error Initializing Label",
    description = "Error Initializing Label",
    fontColor = "#070000",
    backgroundColor = "#CBD3F9",
  } = label || {};

  const [isSelected, setIsSelected] = useState(true);
  const labelBookmarks = useLabelBookmarks({
    label,
    onBookmarksUpdated: ({ newBookmarks }) => {
      if (typeof onUpdatedLabelLink === "function") {
        onUpdatedLabelLink({ id: label?.labelLinkID, bookmarks: newBookmarks });
      }
    },
  });

  /**
   * Handles select/deselect behavior if the select and deselect functions are available
   */
  const handleClick = () => {
    if (!isDisabled) {
      if (typeof onClick === "function") {
        onClick({ label });
      }
      if (typeof onDeselect === "function" && isSelected) {
        onDeselect({ label });
        setIsSelected(false);
      } else if (typeof onSelect === "function" && !isSelected) {
        onSelect({ label });
        setIsSelected(true);
      }
    }
  };

  /**
   * Handles delete behavior if deletion is enabled
   */
  const handleDelete = async (e) => {
    if (!isDisabled && typeof onRemoved === "function") {
      e.stopPropagation();
      onRemoved({ label });
    }
  };

  const linkRef = useRef(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, [linkRef.current]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Badge
        id={`label${id}`}
        onClick={handleClick}
        style={{
          backgroundColor,
          color: fontColor,
          marginLeft: "1px",
          marginRight: "1px",
          marginBottom: "3px",
          opacity: isSelected ? 1.0 : 0.4,
          cursor: !isDisabled && "pointer",
          padding: "0em",
        }}
        ref={linkRef}
        title={description}
      >
        <ButtonGroup size="sm">
          <Button
            style={{
              backgroundColor: "transparent",
              color: fontColor,
              border: "none",
              opacity: 1.0,
            }}
            disabled
          >
            {name}
          </Button>
          {!isDisabled && typeof onRemoved === "function" && (
            <Button
              size="sm"
              style={{ display: "inline-block", marginRight: "0.2em" }}
              close
              title={"Delete this label"}
              onClick={async (e) => await handleDelete(e)}
            />
          )}
        </ButtonGroup>
      </Badge>
      {showBookmarks && <div style={{ marginLeft: ".5em" }}>{labelBookmarks.modalButton}</div>}
      {ready && id && (
        <UncontrolledTooltip placement="auto" target={linkRef.current}>
          {description}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

export default Label;
