import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import styles from "../css/PDFControls.module.css";

/**
 * Small overlay displaying the current page and zoom level of the PDF viewer
 * @param {object} settings - current viewer display parameters
 * @param {object} document - PDF.js opened document object
 * @returns {JSX.Element}
 */
const PDFControls = ({ settings, document }) => {
  const getZoomLevel = () => {
    let selectedScale = 1;
    if (!isNullOrUndefined(settings?.scale)) {
      selectedScale = settings?.scale;
    }

    return (selectedScale * 100).toFixed(0);
  };

  return (
    <div className={styles["overlay-controls"]}>{`Page ${settings?.curPage} / ${
      document?.numPages || 0
    } | Zoom ${getZoomLevel()}%`}</div>
  );
};

export default PDFControls;
