"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineNotes = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Post query function for custom queries which allows to use concat notes on the resource's notes if any exist
 * @param item - data for the resource containing the notes
 * @param _params - any parameters the user entered with the function name
 * @returns {string} - combined notes as a string
 */
const combineNotes = ({ element: item, _params }) => {
    const notes = item?.notes;
    if (!Array.isArray(notes))
        return "";
    return (0, func_utils_1.concatNotes)(notes);
};
exports.combineNotes = combineNotes;
