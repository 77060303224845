import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { getCurrentDate } from "@rivial-security/func-utils";
import { createMonteCarloSystemInput, monteCarloCalculation, realTimeRiskGQL } from "@rivial-security/risk-calc-utils";

import { createRiskChange, updateRiskChange } from "../../../../../../graphql/mutations";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";
import { useTable } from "../../../../../../hooks/views/useTable";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import NumberChange from "../../../../../../utils/CustomFields/NumberChange";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import EditButton from "../../../../../../utils/GenericComponents/buttons/EditButton";
import ResetButton from "../../../../../../utils/GenericComponents/buttons/ResetButton";
import CustomTooltip from "../../../../../../utils/GenericComponents/CustomTooltip";
import { LoadingSpinner } from "../../../../../../utils/LoadingComponents/LoadingSpinner";
import SystemDetails from "../../../../Systems/components/SystemDetails";
import { useSystemList } from "../../../../Systems/hooks/useSystemList";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";
import { useAdjustInformationAssets } from "../hooks/useAdjustInformationAssets";

const CreateSystemAssetsChangeBody = ({ item, organizationID, resetFunction, toggleModal, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOutdated, setIsOutdated] = useState(false);

  const context = useContext(OrganizationContext);

  const [selectedSystem, setSelectedSystem] = useState(null);

  const [changeItem, setChangeItem] = useState({
    informationAssetsChange: null,
    system: {},
  });

  const [oldInformationAssets, setOldInformationAssets] = useState(null);
  const [newInformationAssets, setNewInformationAssets] = useState(null);

  const [systemCalcs, setSystemCalcs] = useState([]);

  const [overallInherentChange, setOverallInherentChange] = useState(0);
  const [overallResidualChange, setOverallResidualChange] = useState(0);
  const [overallROIChange, setOverallROIChange] = useState(0);

  const calculateMonteCarloForSystem = async (system) => {
    const sys = await ItemQuery(realTimeRiskGQL.getSystem_systemDetails, system.id);

    if (sys) {
      const oldMonteCarloInput = await createMonteCarloSystemInput(sys, organizationID);
      const oldMonteCarloResults = monteCarloCalculation({
        system: oldMonteCarloInput,
      });

      if (!oldInformationAssets && sys.informationAssets && sys.informationAssets.items) {
        setOldInformationAssets([...sys.informationAssets.items]);
      }

      const reusableRandoms = oldMonteCarloResults.reusableRandoms;

      const newMonteCarloInput = await createMonteCarloSystemInput(sys, organizationID, changeItem);
      const newMonteCarloResults = monteCarloCalculation({
        system: newMonteCarloInput,
        reusableRandoms,
      });

      // setNewAvailability(newMonteCarloInput.availability);

      const oldInherentLoss = oldMonteCarloResults.systemStats.inherentRisk;
      const newInherentLoss = newMonteCarloResults.systemStats.inherentRisk;

      const oldResidualLoss = oldMonteCarloResults.systemStats.residualRisk;
      const newResidualLoss = newMonteCarloResults.systemStats.residualRisk;

      const systemCalc = {
        id: system.id,
        name: system.name,
        oldMonteCarloResults,
        newMonteCarloResults,
        changeInInherentRisk: newInherentLoss - oldInherentLoss,
        changeInResidualRisk: newResidualLoss - oldResidualLoss,
        changeInROI: newMonteCarloResults.systemStats.currentROI - oldMonteCarloResults.systemStats.currentROI,
      };

      return systemCalc;
    }
  };

  const calculateMonteCarlo = async () => {
    setIsLoading(true);
    const calcs = [];
    let overallInherent = 0;
    let overallResidual = 0;
    let overallROI = 0;

    const calc = await calculateMonteCarloForSystem(selectedSystem);

    if (calc) {
      overallInherent += calc.changeInInherentRisk;
      overallResidual += calc.changeInResidualRisk;
      overallROI += calc.changeInROI;
      calcs.push(calc);
    }

    setIsOutdated(false);
    setOverallInherentChange(overallInherent);
    setOverallResidualChange(overallResidual);
    setOverallROIChange(overallROI);
    setSystemCalcs([...calcs]);
    setIsLoading(false);
  };

  const customFields = [
    {
      field: "changeInInherentRisk",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "changeInResidualRisk",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "changeInROI",
      component: <NumberChange format="percent" />,
      propName: "value",
    },
  ];

  const systemsTable = useTable({
    data: [],
    fields: ["name", "changeInInherentRisk", "changeInResidualRisk", "changeInROI"],
    customFields,
    disableRoleChecking: true,
    fieldNameDictionary: {
      changeInInherentRisk: "Change in Inherent Risk",
      changeInResidualRisk: "Change in Residual Risk",
      changeInROI: "Return on Investment",
    },
    detailsComponent: <SystemDetails />,
  });

  useEffect(() => {
    systemsTable.setData([...systemCalcs]);
  }, [systemCalcs]);

  useEffect(() => {
    setIsOutdated(true);
  }, [changeItem]);

  useEffect(() => {
    if (selectedSystem) {
      calculateMonteCarlo();
    }
  }, [selectedSystem]);

  const updateInputFunction = (input) => {
    return {
      ...input,
      type: "system_asset_change",
      change: JSON.stringify({
        system: selectedSystem,
        systemCalcs,
        overallROIChange,
        overallResidualChange,
        overallInherentChange,
        informationAssetsChange: changeItem.informationAssetsChange,
        oldInformationAssets: oldInformationAssets,
        newInformationAssets: newInformationAssets,
      }),
    };
  };

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });
  const updateSystemInformationAssetLink = useMutation({
    mutation: realTimeRiskGQL.updateSystemInformationAssetLink_riskChange,
    disableRoleChecking: true,
    disableToast: true,
  });

  const callback = async (riskChange) => {
    if (riskChange) {
      const change = JSON.parse(riskChange.change);

      for (const informationAssetLink of change.oldInformationAssets) {
        updateSystemInformationAssetLink.editItem({
          id: informationAssetLink.id,
          numberOfRecords: informationAssetLink.numberOfRecords,
        });
      }

      if (selectedSystem && selectedSystem.id) {
        createSystemChangeLink.createItem({
          ownerGroup: organizationID,
          riskChangeID: riskChange.id,
          systemID: selectedSystem.id,
        });
      }
    }
  };

  const updateRiskChangeHook = useMutation({
    mutation: updateRiskChange,
    disableRoleChecking: true,
  });

  const submitFunction = async (input) => {
    delete input["riskControlLinks"];
    delete input["systemLinks"];

    return await updateRiskChangeHook.editItem({
      id: item && item.id,
      ...input,
    });
  };

  const formHook = useForm({
    typename: "System Information Asset Change",
    organizationID,
    getNewItem: props.getNewItem,
    mutation: createRiskChange,
    disableRoleChecking: true,
    updateInputFunction,
    callback,
    submitFunction,
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name of Change",
        required: true,
        defaultValue: "",
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) {
            return false;
          }
          if (input) {
            return input.length > 5;
          } else {
            return false;
          }
        },
        validationText: "Must be more than 5 characters",
      },
      description: {
        inputType: "textarea",
        label: "Description of Change",
        required: true,
      },
      date: {
        inputType: "date",
        label: "Date of Change",
        defaultValue: getCurrentDate(),
        tooltip: "When did this Change happen?",
      },
      userEmail: {
        inputType: "text",
        label: "Person Entering Change",
        defaultValue: context.userEmail,
        disabled: true,
      },
      changeOwner: {
        inputType: "text",
        label: "Change Owner",
        tooltip: "Who in the Organization is responsible for this Change?",
        required: true,
      },
      reason: {
        inputType: "textarea",
        label: "Reason for Change",
        tooltip: "Why did this Change occur?",
        required: true,
      },
    },
    resetFunction,
    toggleModal,
  });

  useEffect(() => {
    if (item) {
      formHook.setInput(item);
    }
  }, [item]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <div style={{ textAlign: "center", marginBottom: "1em" }}>
            <div style={{ display: "inline-block" }}>
              {isLoading ? <LoadingSpinner loadingMessage="Running Real-Time Risk Calculations.. " /> : null}
              {!isLoading && isOutdated ? "Input has changed, please refresh calculation" : undefined}
            </div>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  Total Change in Inherent Risk:{" "}
                  <NumberChange
                    value={overallInherentChange}
                    format="dollar"
                    positiveNumber="red"
                    negativeNumber="green"
                  />
                </Col>
                <Col lg={4}>
                  Total Change in Residual Risk:{" "}
                  <NumberChange
                    value={overallResidualChange}
                    format="dollar"
                    positiveNumber="red"
                    negativeNumber="green"
                  />
                </Col>
                <Col lg={4}>
                  Total Return on Investment: <NumberChange value={overallROIChange} format="percent" />
                  <ResetButton
                    highlightStyle={{ border: "2px solid gold" }}
                    isHighlighted={!isLoading && isOutdated}
                    resetFunction={() => calculateMonteCarlo()}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <AdjustInformationAssets
            organizationID={organizationID}
            oldInformationAssets={oldInformationAssets}
            newInformationAssets={newInformationAssets}
            setNewInformationAssets={setNewInformationAssets}
            changeItem={changeItem}
            setChangeItem={setChangeItem}
            selectedSystem={selectedSystem}
            setSelectedSystem={setSelectedSystem}
          />
          {formHook.display}
        </Col>
        <Col lg={6}>
          <h5>
            Associated System
            <CustomTooltip tooltip="The Selected System being Changed" />
          </h5>
          {systemsTable.display}
        </Col>
      </Row>
    </>
  );
};

const AdjustInformationAssets = ({
  organizationID,
  selectedSystem,
  setSelectedSystem,
  changeItem,
  setChangeItem,
  oldInformationAssets,
}) => {
  const adjustInformationAssetsFieldHook = useAdjustInformationAssets({
    informationAssets: oldInformationAssets,
  });

  // Create the temp item for monte carlo
  useEffect(() => {
    if (selectedSystem && selectedSystem.id) {
      setChangeItem({
        informationAssetsChange: adjustInformationAssetsFieldHook.newInformationAssets,
        system: selectedSystem,
      });
    }
  }, [adjustInformationAssetsFieldHook.newInformationAssets, selectedSystem]);

  const systemList = useSystemList({
    organizationID,
    dataCardConfig: {
      config: {
        showSelectBoxes: true,
        selectionType: "button",
        buttonCallback: (system) => setSelectedSystem(system),
      },
    },
  });

  useEffect(() => {
    systemList.setSortField("name", "asc");
  }, []);

  return (
    <div>
      {selectedSystem ? (
        <>
          <h5>
            Selected System
            <EditButton
              color="ghost-warning"
              style={{ marginLeft: "1em" }}
              onClick={() => setSelectedSystem(null)}
              title="Select a different System"
            />
          </h5>
          {selectedSystem.name}
        </>
      ) : (
        systemList.display
      )}
      <hr />
      {selectedSystem && adjustInformationAssetsFieldHook.display}
      <hr />
    </div>
  );
};

const CreateSystemAssetsChange = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateSystemAssetsChangeBody />
    </OrganizationCheck>
  );
};

export default CreateSystemAssetsChange;
