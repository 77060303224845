import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";
import { LoadingButton } from "@mui/lab";
import { Alert, Button } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { GetQuery, ItemMutation } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";

import { tryFunction } from "../../../../../utils/Functions/tryFunction";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { performToastOperation } from "../../../../../utils/Toasts/functions/toastOperation";
import { deleteAgendaItem } from "../../functions/deleteAgendaItem";

import MeetingAgenda from "./MeetingAgenda";
import MeetingAgendaV2 from "./MeetingAgendaV2";

/**
 * Shows the old and new meeting agenda views (allows users to migrate from old to new views with a click)
 * @param {string} organizationID - currently selected organization id
 * @param {object} detailsHook - meeting details hook instance
 * @param {object} meeting - the meeting data from the database
 * @param {function} resetFunction - function to re query 'meeting" data
 * @param {object} sx - inline style properties
 * @returns {JSX.Element}
 */
const MeetingAgendaWrapper = ({ organizationID, detailsHook, meeting, resetFunction, sx }) => {
  const { addToast, updateToast } = useUIContext();

  const hasOldData = isNonEmptyArray(meeting?.agendaItems?.items);
  const [showOldData, setShowOldData] = useState(false);
  const [transferInProgress, setTransferInProgress] = useState(false);

  //Determine to show old or new view
  let bodyWidget = (
    <MeetingAgendaV2
      organizationID={organizationID}
      meeting={meeting}
      onChangeCallback={(newItems) => {
        detailsHook?.setItem({
          ...meeting,
          agenda: newItems,
        });
      }}
    />
  );
  if (showOldData === true) {
    bodyWidget = <MeetingAgenda item={meeting} resetFunction={resetFunction} />;
  }

  //Helper functions
  const toggleView = () => {
    setShowOldData((showOldData) => !showOldData);
  };

  const transferOldAgendaItems = async () => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        const { getQuery, updateMutation } = generateGraphql(resources.MEETING, ["agendaItems", "agenda"], {
          agendaItems: `(limit: 500) {
              items {
                id
                content
                notes { id type ownerGroup author timeStamp content tag }
                __typename
              }
            }`,
          agenda: `{
              id
              content
              completed
              notes {
                id
                type
                ownerGroup
                author
                timeStamp
                content
                tag
              }
            }`,
        });
        const currentMeeting = await GetQuery({
          query: getQuery,
          variables: {
            id: meeting?.id,
          },
        });

        setTransferInProgress(true);
        const oldAgendaItems = currentMeeting?.agendaItems?.items;
        const newAgendaItems = cloneDeep(isNonEmptyArray(currentMeeting?.agenda) ? currentMeeting.agenda : []);
        //Recreate old agenda items with notes into new agenda items
        if (isNonEmptyArray(oldAgendaItems)) {
          for (const oldAgendaItem of oldAgendaItems) {
            const { content, notes } = oldAgendaItem || {};
            const newAgendaItem = {
              id: uuid(),
              content,
              notes,
              completed: false,
            };
            newAgendaItems.push(newAgendaItem);
          }
        }

        //Update meeting with new agenda items
        await ItemMutation({
          mutation: updateMutation,
          input: {
            id: currentMeeting?.id,
            agenda: newAgendaItems,
          },
        });

        //Delete old agenda items
        const agendaItemDeleteRequests = [];
        for (const oldAgendaItem of oldAgendaItems) {
          agendaItemDeleteRequests.push(deleteAgendaItem(oldAgendaItem));
        }
        await Promise.allSettled(agendaItemDeleteRequests);
      },
      inProgressText: "Transferring Agenda Items...",
      iconColor: "success",
      failedText: "Failed to transfer agenda items",
      successText: "Successfully transferred agenda items",
    });

    tryFunction(resetFunction);
    setShowOldData(false);
    setTransferInProgress(false);
  };

  const transferButton = (
    <LoadingButton
      title={"This action will convert all available agenda items from the old view into the the new view."}
      disabled={hasOldData !== true}
      loading={transferInProgress}
      loadingPosition={"start"}
      variant={"text"}
      startIcon={<SwitchAccessShortcutIcon />}
      onClick={transferOldAgendaItems}
    >
      {transferInProgress ? "Processing..." : "Transfer Items"}
    </LoadingButton>
  );

  return (
    <DashboardCard style={{ overflowY: "auto", ...(sx?.dashboardCard || {}) }} title="Agenda" icon={"icon-calendar"}>
      {/*New view alert on existing old data*/}
      {showOldData !== true && hasOldData === true && (
        <Alert severity="warning">
          <p>You are viewing the new meeting agenda view, however this meeting also has old agenda items saved.</p>
          <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
            <Button startIcon={<AutorenewIcon />} color="error" variant="text" onClick={toggleView}>
              Go to Old View
            </Button>
            {transferButton}
          </div>
        </Alert>
      )}
      {/*Old view alert on transferring items to new view*/}
      {showOldData === true && (
        <Alert severity="warning">
          <p>You are viewing the old meeting agenda view, an updated agenda view is available.</p>
          <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
            <Button startIcon={<AutorenewIcon />} color="success" variant="text" onClick={toggleView}>
              Go to New View
            </Button>
            {transferButton}
          </div>
        </Alert>
      )}
      {bodyWidget}
    </DashboardCard>
  );
};

export default MeetingAgendaWrapper;
