"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getControlImplementationRating = void 0;
const checkSystemForRiskControlOverride_1 = require("./checkSystemForRiskControlOverride");
const findIndexOfRiskControlOverride_1 = require("./findIndexOfRiskControlOverride");
const isRiskControlOverrideFieldEnabled_1 = require("./isRiskControlOverrideFieldEnabled");
/**
 * Gets the implementationRating of a particular control for a system.
 * Checks for overrides and returns the original value or the overridden value accordingly
 * @param {subControl} riskControl
 * @param {system} system
 */
const getControlImplementationRating = (riskControl, system) => {
    const riskControlOverrides = system?.riskControlOverrides;
    const indexOfRiskControlOverride = (0, findIndexOfRiskControlOverride_1.findIndexOfRiskControlOverride)(riskControlOverrides, riskControl);
    const doesImplementationOverrideExist = (0, checkSystemForRiskControlOverride_1.checkSystemForRiskControlOverride)(system?.riskControlOverrides, riskControl) !== false;
    const isImplementationOverrideEnabled = indexOfRiskControlOverride !== -1 &&
        // @ts-expect-error Fix me, needs better typing
        (0, isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled)(system.riskControlOverrides[indexOfRiskControlOverride], "implementationRating");
    const implementationOverrideValue = doesImplementationOverrideExist &&
        // @ts-expect-error Fix me, needs better typing
        system.riskControlOverrides[indexOfRiskControlOverride]?.implementationRating;
    const handleFunction = () => {
        if (isImplementationOverrideEnabled !== null &&
            isImplementationOverrideEnabled !== undefined &&
            isImplementationOverrideEnabled) {
            if (implementationOverrideValue !== null &&
                implementationOverrideValue !== undefined &&
                implementationOverrideValue !== false &&
                !isNaN(implementationOverrideValue)) {
                return implementationOverrideValue;
            }
            else {
                return 0.0;
            }
        }
        else {
            return riskControl?.implementationRating;
        }
    };
    return handleFunction();
};
exports.getControlImplementationRating = getControlImplementationRating;
