import { Alert } from "@mui/material";
import React, { useContext } from "react";

import { WIDGET_TYPE } from "@rivial-security/widget-utils";

import { CreateWidgetContext } from "../../useCreateWidget";

import ChartWidgetDataGraphBuilder from "./components/ChartWidgetDataGraphBuilder/ChartWidgetDataGraphBuilder";
import HistoryChartDataSelection from "./components/HistoryChartDataSelection";
import TableWidgetDataSelection from "./components/TableWidgetDataSelection";

/**
 * UI for configuring which data will be used to populate the widget
 *
 * @param {string} organizationID - the currently selected organization ID
 * @returns {JSX.Element}
 */
const ProvideDataStep = ({ organizationID }) => {
  const { widget } = useContext(CreateWidgetContext);
  switch (widget?.type) {
    case WIDGET_TYPE.TABLE:
      return <TableWidgetDataSelection organizationID={organizationID} />;
    case WIDGET_TYPE.PIE_CHART:
    case WIDGET_TYPE.BAR_CHART:
      return <ChartWidgetDataGraphBuilder organizationID={organizationID} />;
    case WIDGET_TYPE.HISTORY_CHART:
      return <HistoryChartDataSelection organizationID={organizationID} />;
    default:
      return <Alert>This widget is not supported yet</Alert>;
  }
};

export default ProvideDataStep;
