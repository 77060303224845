import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useCreateRiskChange } from "../hooks/useCreateRiskChange/useCreateRiskChange";

/**
 * A component used as a landing page to input a Manual Risk Change entry.
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateManualChangeLandingPage = (props) => useCreateRiskChange(props).display;

export default withDetailsPage(CreateManualChangeLandingPage);
