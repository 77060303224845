import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateVulnerability } from "../hooks/useCreateVulnerability";

/**
 * Add single Vulnerability component
 * @param props
 * @returns {JSX.Element}
 */
const CreateVulnerability = (props) => useCreateVulnerability(props).display;

export default withOrganizationCheck(CreateVulnerability);
