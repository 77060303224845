import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * A function that converts a camelCase rating string to a friendly format.
 * If a rating param is not in the dictionary, this function just returns that param unabridged
 * @param {string} rating - the rating to be converted
 * @returns {string}
 */
export const ratingDictionary = (rating) => {
  const dictionary = {
    low: "Low",
    lowMedium: "Low-Medium",
    medium: "Medium",
    mediumHigh: "Medium-High",
    high: "High",
  };

  if (!isNullOrUndefined(dictionary[rating])) {
    return dictionary[rating];
  } else {
    return rating;
  }
};
