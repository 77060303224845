import React, { useContext } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import TestingVulnerabilitiesBySeverityLevel from "../../Dashboard/components/TestingVulnerabilitiesBySeverityLevel";

/**
 * Display bar chart of Findings by the severity level
 * @param {object} item - assessment
 * @returns {JSX.Element}
 */
const AssessmentVulnerabilitiesBySeverityLevel = ({ item }) => {
  const context = useContext(OrganizationContext);

  const queryConfig = {
    query: listAssessmentVulnerabilityLinksByAssessmentID,
    limit: 1000,
    variables: {
      assessmentID: item?.id,
    },
    normalizeData: (links) => {
      const normalizedArray = [];

      for (const link of links) {
        const vulnerability = link?.vulnerability;
        if (!vulnerability) {
          continue;
        }

        const found = normalizedArray.find((x) => x.id === vulnerability?.id);
        if (found !== undefined) {
          continue;
        }

        if (context?.preferences?.testing?.showInfoVulnerabilities) {
          normalizedArray.push(vulnerability);
        } else if (vulnerability?.severityLevel !== VULNERABILITY_SEVERITY_LEVEL.INFO) {
          normalizedArray.push(vulnerability);
        }
      }

      return normalizedArray;
    },
  };

  return <TestingVulnerabilitiesBySeverityLevel queryConfig={queryConfig} />;
};

export default AssessmentVulnerabilitiesBySeverityLevel;

const { listByQuery: listAssessmentVulnerabilityLinksByAssessmentID } = generateGraphql(
  "AssessmentVulnerabilityLink",
  ["assessmentID", "vulnerability"],
  {
    vulnerability: `{
      id
      name
      severityLevel
    }`,
  },
  {
    indexName: "listAssessmentVulnerabilityLinksByAssessmentID",
    partitionKey: "assessmentID",
    partitionKeyType: "ID",
  },
);
