import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorSolutionDataGrid } from "../hooks/useVendorSolutionDataGrid";

/**
 * Allows to display a list of vendor solutions for an organization
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VendorSolutionsDataGrid = (props) => {
  return useVendorSolutionDataGrid({ dashboardDisplay: true, ...props }).display;
};

export default withOrganizationCheck(VendorSolutionsDataGrid);
