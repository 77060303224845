import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import { useCustomQueryDetails } from "../../CustomQueries/hooks/useCustomQueryDetails";

/**
 * @description Component to display details for a single CustomQuery Template
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const CustomQueryTemplateDetails = (props) => useCustomQueryDetails(props).display;

export default withDetailsPage(CustomQueryTemplateDetails);
