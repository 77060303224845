import { GetQuery } from "@rivial-security/appsync-utils";
import { tryParse } from "@rivial-security/func-utils";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import { sendRequestToLambdaPromise } from "@utils/Functions/Lambda/sendRequestToLambdaPromise";

/**
 * Submits user inputs for a questionnaire to be saved as a custom resource entry
 * @param {string} taskID - the id of the task which to complete
 * @param {string} questionnaireID - the id of the questionnaire for which to save a response
 * @param {string} questionnaireAssigneeID - the id of questionnaire assignee for which to save a response
 * @param {object} dataForm - the custom field information to save as a response
 * @param {boolean} [isGuest] - whether the user is a guest or not
 * @returns {Promise<{task: *}|{task: null, error}|null>}
 */
export const submitQuestionnaireLambda = async ({
  taskID,
  questionnaireID,
  questionnaireAssigneeID,
  dataForm,
  isGuest,
}) => {
  if (!taskID || !questionnaireID || !questionnaireAssigneeID) {
    ErrorLogger("[submitQuestionnaireLambda]", "Cannot continue missing required input.");
    return null;
  }

  try {
    const processQuestionnaire = /* GraphQL */ `
      query ProcessQuestionnaire($input: AWSJSON) {
        processQuestionnaire(input: $input)
      }
    `;

    const input = {
      operation: "submitQuestionnaire",
      taskID,
      questionnaireID,
      questionnaireAssigneeID,
      dataForm,
    };

    InfoLogger(`Sending request to processQuestionnaire lambda. Input: ${JSON.stringify(input)}`);

    let lambdaResult;
    if (isGuest) {
      lambdaResult = await sendRequestToLambdaPromise({
        name: "processQuestionnaire",
        input,
      });
    } else {
      lambdaResult = await GetQuery({
        query: processQuestionnaire,
        variables: {
          input: JSON.stringify(input),
        },
      });
    }

    return tryParse(lambdaResult);
  } catch (e) {
    ErrorLogger("[submitQuestionnaireLambda] error submitting task data", e);
    return {
      task: null,
      error: e,
    };
  }
};
