"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLossCurveRating = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const compareRiskAmount_1 = require("./compareRiskAmount");
const getLossCurveValue_1 = require("./getLossCurveValue");
/**
 * Gets the High/Med/Low rating of a Risk $ based on x axis value of the Loss Tolerance Curve and Rating Scale overlay
 * @param {RatingScale} ratingScale
 * @param {number} ratingScale.high
 * @param {number} ratingScale.mediumHigh
 * @param {number} ratingScale.medium
 * @param {number} ratingScale.lowMedium
 * @param {number} ratingScale.low
 * @param {object[]} lossToleranceCurve
 * @param {number} lossToleranceCurve[].x
 * @param {number} lossToleranceCurve[].y
 * @param {number} riskAmount
 * @returns {string}
 */
const getLossCurveRating = ({ ratingScale, lossToleranceCurve, riskAmount }) => {
    /**
     * Helper function to get the value threshold of a specific rating on the rating scale
     * @param rating
     * @returns {number}
     */
    const getRatingValue = (rating) => (0, getLossCurveValue_1.getLossCurveValue)({ ratingScale, lossToleranceCurve, rating });
    const lowRange = [0, getRatingValue("low")];
    const lowMediumRange = [getRatingValue("low"), getRatingValue("lowMedium")];
    const mediumRange = [getRatingValue("lowMedium"), getRatingValue("medium")];
    const mediumHighRange = [getRatingValue("medium"), getRatingValue("mediumHigh")];
    const highRange = [getRatingValue("mediumHigh"), getRatingValue("high")];
    if ((0, compareRiskAmount_1.compareRiskAmount)(riskAmount, lowRange))
        return "low";
    else if ((0, compareRiskAmount_1.compareRiskAmount)(riskAmount, lowMediumRange))
        return "lowMedium";
    else if ((0, compareRiskAmount_1.compareRiskAmount)(riskAmount, mediumRange))
        return "medium";
    else if ((0, compareRiskAmount_1.compareRiskAmount)(riskAmount, mediumHighRange))
        return "mediumHigh";
    else if ((0, compareRiskAmount_1.compareRiskAmount)(riskAmount, highRange))
        return "high";
    else if (!(0, func_utils_1.isNullOrUndefined)(riskAmount))
        return "veryHigh";
    else
        return "No Risk";
};
exports.getLossCurveRating = getLossCurveRating;
