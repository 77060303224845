import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";
import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { createQuestionnaireTagLink } from "@utils/Tags/functions/createTagLinks/createQuestionnaireTagLink";
import QuestionnaireStatus from "@views/OrganizationManager/Questionnaires/components/CustomFields/QuestionnaireStatus/QuestionnaireStatus";

import { getResourceIcon } from "../../../../definitions/functions/getResourceIcon";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import CreateQuestionnaire from "../components/CreateQuestionnaire";
import QuestionnaireDetails from "../components/QuestionnaireDetails";
import { deleteQuestionnaire } from "../functions/deleteQuestionnaire";

/**
 * Hook displaying a list of all questionnaires in a grid
 * @param {object} props - any additional props to pass to the useDataGrid hook
 * @param {string} organizationID - the currently selected organization
 * @param {string} moduleInit - platform module to use for role checking
 * @param {string} resourceInit - platform resource to use for role checking
 * @param {object} initQueryConfig - query config overrides
 * @param {object} initGridConfig -  grid config overrides
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object[]|Object, setData: function(*): void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}, setIsLoading: *, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useQuestionnaireDataGrid = ({
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  queryConfig: initQueryConfig,
  gridConfig: initGridConfig,
  ...props
}) => {
  const typename = resources.QUESTIONNAIRE;
  const module = moduleInit || modules.ORGANIZATION_MANAGER;
  const resource = resourceInit || resources.QUESTIONNAIRE;
  const icon = getResourceIcon({ typename });

  initQueryConfig = {
    indexName: "questionnairesByOwnerGroup",
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    ...initQueryConfig,
  };

  const { queryConfig, updateMutation } =
    getResourceQueryData({
      fieldContext: fieldContexts.GRID,
      overrideQueryConfig: initQueryConfig,
      typename,
    }) || {};

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 100,
      bulkEdit: true,
    },
    {
      name: "description",
      flex: 1,
      minWidth: 100,
      bulkEdit: true,
    },
    {
      name: "status",
      width: 150,
      component: <QuestionnaireStatus moduleInit={module} resourceInit={resource} />,
      bulkEdit: true,
    },
    {
      name: "startDate",
      disablePropagation: true,
      width: 150,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: { dateOnly: true },
      displayConfig: { dateOnly: true },
      bulkEdit: true,
    },
    {
      name: "endDate",
      disablePropagation: true,
      width: 150,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: { dateOnly: true },
      displayConfig: { dateOnly: true },
      bulkEdit: true,
    },
    {
      name: "allowUnauthenticatedSubmissions",
      friendlyName: "Guest Submissions",
      visible: false,
      width: 100,
      bulkEdit: true,
      type: GENERIC_FIELD_TYPES.SWITCH,
    },
    {
      field: "tags",
      name: "tags",
      description: "Tags that correspond to this questionnaire across the entire Platform",
      createLinkFunction: createQuestionnaireTagLink,
      width: 200,
      bulkEdit: true,
      //NOTE: some properties provided by default in handleDataGridColumns.js
    },
  ];

  const gridConfig = {
    fields,
    module,
    resource,
    typename,
    organizationID,
    options: ["details", "delete", "edit"],
    createResourceComponent: <CreateQuestionnaire />,
    deleteFunction: deleteQuestionnaire,
    detailsComponent: <QuestionnaireDetails />,
    updateMutation,
    detailsTitle: "Questionnaire Details",
    persistenceUUID: "f8079c0f-34d9-413e-8531-19cf1857a0c2",
    enablePersistence: true,
    connectionAdaptor: {
      tags: {
        connectionTypename: "QuestionnaireTagLink",
        itemConnectionIDField: "questionnaireID",
        childConnectionIDField: "tagID",
        childConnectionField: "tag",
      },
    },
    ...initGridConfig,
  };

  const cardConfig = {
    title: "Questionnaires",
    headerIcon: icon,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
    ...props,
  });

  return { ...gridCard };
};
