import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useCreateControlSet } from "../hooks/useCreateControlSet";

/**
 * A component instance of the useCreateControlSet.js hook
 * @param props
 */
const CreateControlSet = (props) => {
  return useCreateControlSet(props).display;
};

export default withOrganizationCheck(CreateControlSet);
