/**
 * Author: Anatoli Railean
 * Created At: 11/17/19
 * Description: Deletes a Policy resource in the DB.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";

import { generateGraphql } from "@rivial-security/generategraphql";

import { InfoLogger } from "@utils/EventLogger";

import { DeletePolicyVersion } from "./DeletePolicyVersion";

const { deleteMutation: deletePolicy } = generateGraphql("Policy");

export const DeletePolicy = async (policy) => {
  if (policy?.policyVersions?.items?.length > 0) {
    policy.policyVersions.items.forEach(DeletePolicyVersion);
  }

  API.graphql(
    graphqlOperation(deletePolicy, {
      input: {
        id: policy.id,
      },
    }),
  ).then(({ data }) => {
    if (data && data.deletePolicy) {
      InfoLogger(`Policy ${data.deletePolicy.id} was Successfully Deleted`);
    }
  });
};
