import React from "react";
import { Col, Row } from "reactstrap";

import { DETAILS_TYPES } from "../../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import { useMeetingsDataGrid } from "../../../../../Program/Meetings/hooks/useMeetingsDataGrid";

/**
 * Allows the user to select a report to upload for Evidence
 * @param organizationID
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
const MeetingEvidenceForm = ({ organizationID, onSubmit }) => {
  const gridConfig = {
    gridHeight: "60vh",
    allowPaging: false,
    createResourceComponentWidth: "80vw",
    detailsType: DETAILS_TYPES.MODAL,
    enableQuickDetails: true,
    selectionSettings: {
      type: "Single",
    },
    enableSelectButton: true,
    onSelectCallback: (value) => {
      const activityInput = {
        type: EVIDENCE_ACTIVITY_TYPES.MEETING,
        meeting: {
          id: value?.id,
          name: value?.name,
        },
      };
      onSubmit && onSubmit(activityInput);
    },
  };

  const cardConfig = {
    title: "Select a Meeting",
    enableSticky: false,
  };

  const meetingGrid = useMeetingsDataGrid({
    organizationID,
    gridConfig,
    cardConfig,
  });

  return (
    <div>
      <Row>
        <Col>
          <div style={{ marginBottom: "1em", height: "30em" }}>{meetingGrid.display}</div>
        </Col>
      </Row>
    </div>
  );
};

export default MeetingEvidenceForm;
