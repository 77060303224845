"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runSimulationsForRisk = void 0;
const generateReusableRandoms_1 = require("../../createMonteCarloSystemInput/functions/generateReusableRandoms");
const createLossCurve_1 = require("./createLossCurve");
const getARO_1 = require("./getARO");
/**
 * Runs Monte Carlo simulations for a single risk.
 *
 * @function
 * @author Jacob Blazina
 * @param {object} risk - the KRI to run simulations on.
 * @param {number} numberOfSimulations - how many simulations to run for each KRI. Between 1000 - 10000
 * @param {object} controlStats - the control statistics for the corresponding control category
 * @param {number} controlStats.cost - the cost of the controls in the control category
 * @param {number} controlStats.effectiveness - the total control effectiveness in the control category
 * @param {number} [controlStats.recommendationEffectiveness=null] - optional. used to calculate potential effects of a recommendation
 * @returns {{riskName: *, averageLoss: number, recommendationAverageLoss: number, costOfControls: * | number, controlEffectiveness: (*|number), recommendationControlEffectiveness: (*|number), lossProbabilityCurve: Array, simulatedLosses: Array, annualRateOfOccurrence: *}}
 */
const runSimulationsForRisk = (risk, numberOfSimulations, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
controlStats, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
reusableRandoms, lossCurveXPoints) => {
    const cost = controlStats.cost;
    let effectiveness = controlStats.effectiveness;
    let recommendationEffectiveness = controlStats.recommendationEffectiveness;
    if (effectiveness > 1)
        effectiveness = 1;
    if (recommendationEffectiveness > 1)
        recommendationEffectiveness = 1;
    let simulatedLosses = [];
    let simulatedLossTotal = 0;
    let recommendationSimulatedLossTotal = 0;
    // Retry up to 5 times to get a total loss amount
    for (let retry = 1; retry <= 5; retry++) {
        simulatedLosses = [];
        simulatedLossTotal = 0;
        recommendationSimulatedLossTotal = 0;
        for (let i = 0; i < numberOfSimulations; i++) {
            let probabilityRand;
            let impactRand;
            const ARO = (0, getARO_1.getARO)(risk);
            const existingRandoms = reusableRandoms?.get(`${risk.id}_${i}`);
            if (existingRandoms !== undefined && existingRandoms !== null) {
                let resetRandom = false;
                // If this is a retry, refresh the randoms.
                if (retry !== 1) {
                    // Clear the ARO random so that it regenerates below
                    existingRandoms.p = null;
                    // Clear the impact random so that it regenerates below
                    // NOTE: for the 'retry' functionality we may want to gradually reduce the 'impact random' for each retry,
                    // this would increase the probability of a 'hit' for a simulation. (if the impact random is ~0 there will be a gauranteed 'hit')
                    existingRandoms.i = null;
                }
                if (existingRandoms.p !== null && existingRandoms.p !== undefined && typeof existingRandoms.p === "number") {
                    probabilityRand = existingRandoms.p;
                }
                else {
                    probabilityRand = (0, generateReusableRandoms_1.getRandomOccurrenceNum)(undefined, undefined);
                    resetRandom = true;
                }
                if (existingRandoms.i !== null && existingRandoms.i !== undefined && typeof existingRandoms.i === "number") {
                    impactRand = existingRandoms.i;
                }
                else {
                    impactRand = (0, generateReusableRandoms_1.getRandomLossAmount)(risk);
                    resetRandom = true;
                }
                if (resetRandom) {
                    reusableRandoms.set(`${risk.id}_${i}`, { p: probabilityRand, i: impactRand });
                }
            }
            else {
                probabilityRand = (0, generateReusableRandoms_1.getRandomOccurrenceNum)(undefined, undefined);
                impactRand = (0, generateReusableRandoms_1.getRandomLossAmount)(risk);
                reusableRandoms.set(`${risk.id}_${i}`, { p: probabilityRand, i: impactRand });
            }
            // Calculate if the event has occurred
            const riskProportionUnaccountedByControls = 1.0 - effectiveness;
            const AROAdjustedForControls = ARO * riskProportionUnaccountedByControls;
            const eventHasOccurred = probabilityRand < AROAdjustedForControls;
            // Add would-be loss to total loss if the event did occur
            let simulatedLoss = 0;
            if (eventHasOccurred) {
                simulatedLoss = impactRand;
            }
            // Add any incurred loss to the total
            if (simulatedLoss && !isNaN(simulatedLoss)) {
                simulatedLossTotal += simulatedLoss;
            }
            if (recommendationEffectiveness !== undefined && recommendationEffectiveness !== null) {
                const recommendationSimulatedLoss = probabilityRand < (1 - recommendationEffectiveness) * ARO ? impactRand : 0;
                if (recommendationSimulatedLoss !== null && !isNaN(recommendationSimulatedLoss)) {
                    recommendationSimulatedLossTotal += recommendationSimulatedLoss;
                }
            }
            else if (simulatedLoss && !isNaN(simulatedLoss)) {
                recommendationSimulatedLossTotal += simulatedLoss;
            }
            simulatedLosses.push(simulatedLoss);
        } // End 'for' loop.
        // Check if simulated loss is 0. If so retry
        if (simulatedLossTotal === 0) {
            // retry for loop
            console.log(`[monteCarloCalculation] Got a $0 simulated loss for Risk: ${risk.name}. (Retry ${retry}/5)..`);
        }
        else {
            // got a valid value, break out of the loop
            break;
        }
    }
    return {
        riskName: risk.name,
        averageLoss: simulatedLossTotal / numberOfSimulations,
        recommendationAverageLoss: recommendationSimulatedLossTotal / numberOfSimulations,
        costOfControls: cost,
        controlEffectiveness: effectiveness,
        recommendationControlEffectiveness: recommendationEffectiveness,
        // @ts-expect-error Fix me, needs better typing
        lossProbabilityCurve: (0, createLossCurve_1.createLossCurve)(simulatedLosses, lossCurveXPoints),
        simulatedLosses,
        annualRateOfOccurrence: risk.annualRateOfOccurrence,
    };
};
exports.runSimulationsForRisk = runSimulationsForRisk;
