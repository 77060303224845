import React, { useEffect, useRef } from "react";

import TabCard from "../../../../../../../utils/GenericComponents/TabCard";

import SystemAssessments from "./SystemAssessments";
import SystemTargets from "./SystemTargets";
import SystemVulnerabilities from "./SystemVulnerabilities";

/**
 * System Accordion list item that displays the integrations with the testing module targets
 * @param {object} system - a risk module system object
 * @param {function} resetFunction - function to re-fetch data from database
 */
const SystemSecurityTesting = ({ system: item, resetFunction }) => {
  //Reference needed to properly pass updated system to the tab card components
  const systemRef = useRef(item);
  useEffect(() => {
    systemRef.current = item;
  }, [item]);

  return (
    <TabCard
      items={[
        {
          title: "Targets",
          component: <SystemTargets itemRef={systemRef} />,
          icon: <i className={"icon-target"} />,
        },
        {
          title: "Vulnerabilities",
          component: <SystemVulnerabilities itemRef={systemRef} />,
          icon: <i className={"icon-fire"} />,
        },
        {
          title: "Assessments",
          component: <SystemAssessments itemRef={systemRef} />,
          icon: <i className={"icon-badge"} />,
        },
      ]}
    />
  );
};

export default SystemSecurityTesting;
