import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactElement } from "react";

import { useAntivirusGate } from "../../../hooks/functional/useAntivirusGate";
import { S3FileType } from "../../Functions/S3Storage/types/S3FileType";
import { openInNewTab } from "../../Functions/Views/openInNewTab";
import { DisabledWrapper } from "../DisabledWrapper";

export interface MuiDownloadButtonType {
  id?: string;
  downloadFunction?: ({ bucket, key }: { bucket?: string; key?: string }) => Promise<string>;
  s3File?: S3FileType;
  avStatus: string;
  itemDisplayName?: string;
  showDisplayNameInButtonText?: boolean;
  size: "small" | "medium" | "large";
}

interface OnUrlReceived {
  url: string;
}

/**
 * Download button for s3 files
 */
const MuiDownloadButton = ({
  id = "download-button",
  downloadFunction,
  s3File,
  avStatus,
  itemDisplayName = "Document",
  showDisplayNameInButtonText = false,
  size = "small",
}: MuiDownloadButtonType): ReactElement => {
  const onUrlReceived = ({ url }: OnUrlReceived): void => {
    if (url && typeof url === "string") {
      openInNewTab({ url });
    }
  };

  const { isGettingDownloadLink, isDownloadable, cannotDownloadReason, confirmUnsafeDownload, beginDownload } =
    useAntivirusGate({
      downloadFunction,
      s3File,
      avStatus,
      itemDisplayName,
      onUrlReceived,
      operationName: "download",
    });

  const getButtonText = (): string => {
    const itemSuffix = showDisplayNameInButtonText ? ` ${itemDisplayName}` : "";
    if (isGettingDownloadLink) {
      return `Downloading${itemSuffix}...`;
    } else {
      return `Download${itemSuffix}`;
    }
  };

  return (
    <>
      {confirmUnsafeDownload.modal}
      <DisabledWrapper greyscalePercentage={0} isDisabled={!isDownloadable} message={cannotDownloadReason()}>
        <LoadingButton
          id={id}
          data-testid={id}
          loading={isGettingDownloadLink}
          loadingPosition={"start"}
          color={"primary"}
          size={size}
          variant={"text"}
          title={`Begin downloading this ${itemDisplayName}`}
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            beginDownload({
              userWantsToDownloadUnsafeFile: false,
            })
          }
          disabled={!isDownloadable}
        >
          {getButtonText()}
        </LoadingButton>
      </DisabledWrapper>
    </>
  );
};

export default MuiDownloadButton;
