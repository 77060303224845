import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import AddOrRemoveIcon from "../../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import ResetButton from "../../../../../../../utils/GenericComponents/buttons/ResetButton";
import { LoadingSpinner } from "../../../../../../../utils/LoadingComponents/LoadingSpinner";

import AttachKeyRiskIndicatorToSystem from "./AttachKeyRiskIndicatorToSystem";
import KeyRiskIndicatorItem from "./KeyRiskIndicatorItem";

/**
 * Displays Key Risk Indicators that are currently attached to a System
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const KeyRiskIndicators = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const field = "keyRiskIndicators";

  const systemID = props.system && props.system.id;

  const keyRiskIndicatorsQuery = useQueryGetItem({
    itemId: systemID,
    query: realTimeRiskGQL.keyRiskIndicatorsGQL,
    module,
    resource,
    disableRoleChecking,
  });

  const [keyRiskIndicators, setKeyRiskIndicators] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const keyRiskIndicators = keyRiskIndicatorsQuery?.item?.risks?.items || [];

    setKeyRiskIndicators([...keyRiskIndicators]);
    setInitialLoad(false);
  }, [keyRiskIndicatorsQuery.item]);

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const attachKRIModal = useModal(
    "Add/Remove Key Risk Indicators on this Risk/System",
    <AttachKeyRiskIndicatorToSystem
      module={module}
      resource={resource}
      organizationID={props.organizationID}
      currentKeyRiskIndicators={keyRiskIndicators}
      system={keyRiskIndicatorsQuery.item}
      disableRoleChecking={disableRoleChecking}
      resetFunction={() => {
        keyRiskIndicatorsQuery.reset();
        props.resetFunction && props.resetFunction(true);
      }}
    />,
    <Button
      size="sm"
      color="ghost-secondary"
      className="btn-pill"
      disabled={!checkPermissionsHook.resource.update}
      title={
        checkPermissionsHook.resource.update
          ? "Add/Remove Key Risk Indicators"
          : "You don't have Update Permissions on this Resource"
      }
    >
      <AddOrRemoveIcon />
    </Button>,
  );

  const reset = (resetKey = true) => {
    resetKey && keyRiskIndicatorsQuery.reset && keyRiskIndicatorsQuery.reset();
  };

  return (
    <>
      <strong>
        Key Risk Indicators
        {attachKRIModal.modalButton}
        <ResetButton resetFunction={keyRiskIndicatorsQuery.reset} />
      </strong>
      {initialLoad && keyRiskIndicatorsQuery.isLoading ? (
        <div>
          <LoadingSpinner loadingMessage="Loading Key Risk Indicators.. " />
        </div>
      ) : (
        <Table responsive hover borderless>
          <thead>
            <tr>
              {props.fields &&
                props.fields.map((field, index) => (
                  <th key={index} scope="col">
                    {field in props.fieldNameDictionary ? props.fieldNameDictionary[field] : field}
                  </th>
                ))}
              {props.isAdmin && props.options && props.options.length > 0 ? <th scope="col">Options</th> : null}
            </tr>
          </thead>
          <tbody>
            {keyRiskIndicators
              .sort((a, b) => a.risk.name.split(" ")[0] - b.risk.name.split(" ")[0])
              .map((systemRiskLink, index) => (
                <KeyRiskIndicatorItem
                  viewType={props.viewType}
                  key={systemRiskLink.id + index}
                  organizationID={props.organizationID}
                  isAdmin={props.isAdmin}
                  item={systemRiskLink.risk}
                  systemRiskLink={systemRiskLink}
                  fields={props.fields}
                  options={props.options}
                  system={props.system}
                  enableOverrides
                  resetFunction={reset}
                  disableEdits={true}
                  Context={props.Context}
                  module={module}
                  resource={resource}
                  disableRoleChecking={disableRoleChecking}
                />
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default withOrganizationCheck(KeyRiskIndicators);
