import React from "react";
import { Input, Spinner } from "reactstrap";

const SelectRating = (props) => {
  const { ratingScale, rating, setRating, disabled } = props;

  const ratings = ratingScale && [
    {
      name: "High",
      enum: "high",
      assetSizePercentage: ratingScale.high,
    },
    {
      name: "Medium-High",
      enum: "mediumHigh",
      assetSizePercentage: ratingScale.mediumHigh,
    },
    {
      name: "Medium",
      enum: "medium",
      assetSizePercentage: ratingScale.medium,
    },
    {
      name: "Low-Medium",
      enum: "lowMedium",
      assetSizePercentage: ratingScale.lowMedium,
    },
    {
      name: "Low",
      enum: "low",
      assetSizePercentage: ratingScale.low,
    },
  ];

  return (
    <>
      {ratingScale ? (
        <Input
          disabled={disabled || props.disableEdit}
          type="select"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
        >
          {ratings.map((item, index) => (
            <option key={JSON.stringify(item) + index} value={item.enum}>
              {`${item.name} - ${item.assetSizePercentage * 100}%`}
            </option>
          ))}
        </Input>
      ) : (
        <div>
          Loading Scale.. <Spinner size="sm" color="primary" />
        </div>
      )}
    </>
  );
};

export default SelectRating;
