"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formattedName = void 0;
/**
 * Get a Point of Contact full name
 * @param {object} pointOfContact - the point of contact data to use
 * @param {boolean} includeEmail - flag to include the point of contact's email if its available
 * @param {boolean} includeTitle - flag to include the point of contact's title if its available
 * @returns {string} - the full point of contact info formatted as "FirstName LastName | Title | Email" (suffixes as requested)
 */
const formattedName = ({ pointOfContact, includeEmail = false, includeTitle = false }) => {
    if (!pointOfContact) {
        return "";
    }
    if (typeof pointOfContact !== "object") {
        return "";
    }
    let fullName = ``;
    const firstName = pointOfContact.firstName || "";
    const lastName = pointOfContact.lastName || "";
    if (firstName && lastName) {
        fullName = `${firstName} ${lastName}`;
    }
    else if (firstName) {
        fullName = firstName;
    }
    else if (lastName) {
        fullName = lastName;
    }
    else {
        return "";
    }
    const title = pointOfContact.title || "";
    if (includeTitle && title && typeof title === "string") {
        fullName += " | ";
        fullName += pointOfContact.title;
    }
    const email = pointOfContact.email || "";
    if (includeEmail && email && typeof email === "string") {
        fullName += " | ";
        fullName += pointOfContact.email;
    }
    return fullName;
};
exports.formattedName = formattedName;
