import React, { useEffect } from "react";

import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import AssetSizeChangeDetails from "../components/AssetSizeChange/components/AssetSizeChangeDetails";
import KeyRiskIndicatorChangeDetails from "../components/KeyRiskIndicatorChange/components/KeyRiskIndicatorChangeDetails";
import ManualChangeDetails from "../components/ManualChange/components/ManualChangeDetails";
import NumberOfCustomersChangeDetails from "../components/NumberOfCustomersChange/components/NumberOfCustomersChangeDetails";
import RiskControlChangeDetails from "../components/RiskControlChange/components/RiskControlChangeDetails";
import SystemAssetChangeDetails from "../components/SystemAssetsChange/components/SystemAssetChangeDetails";
import SystemAvailabilityChangeDetails from "../components/SystemAvailabilityChange/components/SystemAvailabilityChangeDetails";
import { getRiskChange_riskChangeDetails } from "../graphql/getRiskChange_riskChangeDetails";

/**
 * A custom hook.
 * Displays the Details of a particular Risk Change based on type.
 * Different types of change have unique details components, this hook acts like a switch.
 *
 * @author Jacob Blazina
 * @param {string} itemId - the ID of the RiskChange object
 * @param {object} [queryConfig] - optional configs passed to the useQueryGetItem hook
 * @returns {{display: *}}
 */
export const useRiskChangeDetails = ({ itemId, queryConfig = {}, tableDisplay, ...props }) => {
  const query = useQueryGetItem({
    itemId,
    query: getRiskChange_riskChangeDetails,
    disableRoleChecking: true,
    ...queryConfig,
  });

  useEffect(() => {
    props.setQueryRef && props.setQueryRef(query);
  }, []);

  useEffect(() => {
    props.setIsLoading && props.setIsLoading(query.isLoading);
  }, [query.isLoading]);

  const display = (
    <>
      {query &&
        query.item &&
        (() => {
          switch (query.item.type) {
            case "enterpriseControl":
              return <RiskControlChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "nonStandardControl":
              return <RiskControlChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "risk_control_change":
              return <RiskControlChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "key_risk_indicator_change":
              return <KeyRiskIndicatorChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "asset_size_change":
              return <AssetSizeChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "number_of_customers_change":
              return <NumberOfCustomersChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "system_availability_change":
              return <SystemAvailabilityChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            case "system_asset_change":
              return <SystemAssetChangeDetails item={query.item} tableDisplay={tableDisplay} />;
            default:
              return <ManualChangeDetails item={query.item} resetFunction={query.reset} tableDisplay={tableDisplay} />;
          }
        })()}
    </>
  );

  return {
    display,
  };
};
