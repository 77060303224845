import React, { useEffect, useState } from "react";

import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays documents for an exercise
 * @param item
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ExerciseDocuments = ({ item, organizationID }) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setDocuments(item?.documents?.items);
  }, [item?.documents]);

  const gridConfig = {
    gridHeight: "100%",
    allowPaging: false,
  };

  const queryConfig = {
    query: null,
  };

  useEffect(() => {
    grid?.setData(documents);
  }, [documents]);

  const grid = useDocumentDataGrid({
    organizationID,
    ...queryConfig,
    ...gridConfig,
    connectionInput: { exerciseID: item?.id },
    disableRoleChecking: true,
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(ExerciseDocuments);
