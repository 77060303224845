import { ButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { Button } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import useDidMountEffect from "@hooks/functional/useDidMountEffect";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import TabbedEvidenceCard from "../../Evidence/components/TabbedEvidenceCard";

import AuditControlEvidenceLinking from "./AuditControlEvidenceLinking";
import ReviewedCheckbox from "./ReviewedCheckbox";

/**
 * Displays a list of Evidences with a 'Reviewed' Checkbox, option to add Notes, and button for quickly viewing all Artifacts
 * Corresponds to AuditControlLinks and AuditControlEvidenceLinks
 * @param evidenceLinks
 * @param item - the AuditControlLink object
 * @param item.auditControlLink - the AuditControlLink object
 * @returns {JSX.Element}
 *
 * @example
 *   const auditControlLink = {
    evidenceLinks: {
      items: [
        {
          id: "123abc",
          evidence: {
            id: "ev1",
            name: "Information Security Policy"
          },
          reviewed: true
        },
        {
          id: "123cde",
          evidence: {
            id: "ev2",
            name: "Security Training Records"
          },
          reviewed: false
        }
      ]
    }
  }

 * @constructor
 */
const EvidenceField = ({ item, organizationID, resetFunction, updateItemById, optimisticUpdate, audit }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;

  const checkPermissions = useCheckPermissions({ module, resource });

  const readOnly = !checkPermissions.resource.update;

  const getEvidenceLinks = () => {
    const evidenceLinks = item?.auditControlLink?.evidenceLinks?.items ?? [];
    if (!Array.isArray(evidenceLinks)) {
      return;
    }

    const newEvidenceLinks = [];
    for (const evidenceLink of evidenceLinks) {
      newEvidenceLinks.push({
        // Link attributes
        linkId: evidenceLink.id,
        reviewed: evidenceLink.reviewed,
        notes: evidenceLink.notes,

        // Evidence attributes
        id: evidenceLink.evidence.id,
        name: evidenceLink.evidence.name,
      });
    }
    return newEvidenceLinks;
  };

  const [evidences, setEvidences] = useState(getEvidenceLinks());
  useDidMountEffect(() => {
    setEvidences(getEvidenceLinks());
  }, [JSON.stringify(item?.auditControlLink?.evidenceLinks?.items)]);

  return (
    <Grid id={"evidence-reviewed-field"} container justifyContent="center" alignItems="center">
      <Grid item lg={11} xs={11}>
        <div>
          {Array.isArray(evidences) && evidences.length > 0 ? (
            evidences?.map((evidenceItem) => (
              <ReviewedCheckbox
                key={evidenceItem.id}
                resetFunction={resetFunction}
                updateItemById={updateItemById}
                optimisticUpdate={optimisticUpdate}
                item={evidenceItem}
                auditControlItem={item}
                audit={audit}
                readOnly={readOnly}
              />
            ))
          ) : (
            <span style={{ textAlign: "center" }}>
              <i>No linked Evidence</i>
            </span>
          )}
        </div>
      </Grid>
      <Grid item lg={1} xs={1}>
        <OptionButtons
          item={item}
          evidences={evidences}
          organizationID={organizationID}
          optimisticUpdate={optimisticUpdate}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
};

/**
 * Displays Linking, Preview, and Download buttons for a group of evidence
 * @constructor
 */
const OptionButtons = ({ item, organizationID, optimisticUpdate, evidences, readOnly }) => {
  return (
    <ButtonGroup size="small" style={{ marginTop: "-1em", marginLeft: "-2em" }} orientation="vertical">
      {!readOnly && (
        <LinkingModalButton item={item} organizationID={organizationID} optimisticUpdate={optimisticUpdate} />
      )}
      {evidences?.length > 0 && <PreviewButton organizationID={organizationID} items={evidences} />}
    </ButtonGroup>
  );
};

/**
 * Linking modal for manually adding/removing AuditControlEvidenceLinks for this Audit
 * @param item
 * @param item.auditControlLink
 * @param organizationID
 * @param optimisticUpdate
 * @returns {JSX.Element}
 * @constructor
 */
const LinkingModalButton = ({ item, organizationID, optimisticUpdate }) => {
  const linkingModal = useModal(
    "Link Evidence to this Audit/Control Combination",
    <AuditControlEvidenceLinking item={item} organizationID={organizationID} optimisticUpdate={optimisticUpdate} />,
    <Button
      id={"evidence-reviewed-linking-button"}
      color="grey"
      aria-label="upload picture"
      component="span"
      title={"Add or Remove Evidences linked to this Audit/Control Combination"}
      size="large"
    >
      {getIcon("gg:arrows-exchange")}
    </Button>,
    {
      width: "80vw",
    },
  );

  return linkingModal.modalButton;
};

/**
 * Button that opens a modal to quickly preview all of the artifacts for one or more evidences at once
 * @param items
 * @param organizationID
 * @constructor
 */
const PreviewButton = ({ items, organizationID }) => {
  const modal = useModal(
    "Preview Evidence",
    <div>
      <MultipleEvidenceTabCards items={items} organizationID={organizationID} />
    </div>,
    <Button id={"evidence-reviewed-preview-button"} color="grey" component="span" size="large">
      {getIcon("carbon:document-view")}
    </Button>,
    {
      width: "75vw",
    },
  );

  return modal.modalButton;
};

const MultipleEvidenceTabCards = ({ items, organizationID }) => {
  const accordion = useAccordion({
    items: Array.isArray(items)
      ? items.map((item, index) => {
          return {
            title: item.name,
            component: <EvidenceCard item={item} />,
            defaultExpanded: true,
          };
        })
      : [],
  });

  return accordion.display;
};

const EvidenceCard = ({ item }) => {
  return (
    <span>
      <TabbedEvidenceCard item={item} />
    </span>
  );
};

export default withOrganizationCheck(EvidenceField);
