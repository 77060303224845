/**
 * An actionComplete function to be used with sync fusion's grid to hide unneeded filter UI
 * @param {object} args - action complete arguments
 * @param {string[]} [hideAllFields] - list of column/field names for which to hide all of the UI (eg. tags)
 * @param {string[]} [hideOperatorFields] - list of column/field names for which to only hide the top of the filter
 * menu but keep the buttons "Filter" and "Clear"
 * REFERENCE: https://www.syncfusion.com/forums/162656/hide-the-filter-operator-dropdown-for-a-specific-column
 */
export const hideFilterMenuUI = ({ args, hideAllFields = [], hideOperatorFields = [] }) => {
  if (args?.requestType === "filterafteropen") {
    const foundInAll = hideAllFields.findIndex((field) => args?.columnName === field) !== -1;
    const foundInOperator = hideOperatorFields.findIndex((field) => args?.columnName === field) !== -1;

    if (foundInOperator || foundInAll) {
      args.filterModel.dlgObj.element.children[0].firstElementChild.children[0].style.display = "none";
      args.filterModel.dlgObj.element.children[0].firstElementChild.children[1].style.padding = 0;
    }

    if (foundInAll) {
      args.filterModel.dlgObj.element.children[1].style.display = "none";
    }
  }
};
