"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGreatestRiskCurves = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Looks through monte carlo results and returns the risk curve with the highest risk
 *
 * @param riskScore
 * @param {MonteCarloCalculationResult} monteCarloResults
 * @returns {{}}
 */
const getGreatestRiskCurves = ({ riskScore, monteCarloResults }) => {
    let curves = {};
    //Check arguments
    if (!riskScore || !monteCarloResults) {
        return curves;
    }
    //Find the greatest cost KRI
    const greatestEnterpriseRisk = riskScore?.greatestEnterpriseRisk;
    const greatestBusinessRisk = greatestEnterpriseRisk?.greatestBusinessRisk;
    const greatestKeyRiskIndicator = greatestBusinessRisk?.greatestKeyRiskIndicator;
    curves.greatestKeyRiskIndicator = greatestKeyRiskIndicator;
    const riskName = greatestKeyRiskIndicator?.name;
    //Find the greatest cost KRI curves
    const foundRiskResult = monteCarloResults?.riskStats?.find((risk) => risk.name === riskName);
    if (!(0, func_utils_1.isNullOrUndefined)(foundRiskResult?.lossExceedanceCurve)) {
        curves = { ...curves, ...foundRiskResult?.lossExceedanceCurve };
    }
    return curves;
};
exports.getGreatestRiskCurves = getGreatestRiskCurves;
