import React from "react";
import { Progress } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { VulnerabilityStatus } from "../constants/VulnerabilityStatus";
import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "../functions/convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";

/**
 * Display Vulnerability status component
 * @param {object} item - vulnerability
 * @param {object} props - all other props passed to generic edit field
 * @returns {JSX.Element}
 */
export const VulnerabilityStatusCustomField = ({ item, ...props }) => {
  const statusCounts = item?.statusCounts || props?.statusCounts;
  const { vulnerabilityStatus, notFixedPercent, exceptionPercent, fixedPercent } =
    convertTargetVulnerabilityStatusCountsToVulnerabilityStatus({
      statusCounts,
    });

  if (vulnerabilityStatus === VulnerabilityStatus.UNKNOWN) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p style={{ margin: 0, textAlign: "center" }}>Unknown</p>
        <Progress color={"secondary"} style={{ width: "100%" }} />
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <p style={{ margin: 0, textAlign: "center" }}>{convertCamelCaseToSentence(vulnerabilityStatus)}</p>
      <Progress multi style={{ width: "100%" }}>
        {fixedPercent ? (
          <Progress bar color="success" value={fixedPercent}>
            {fixedPercent}%
          </Progress>
        ) : undefined}
        {exceptionPercent ? (
          <Progress bar color="warning" value={exceptionPercent}>
            {exceptionPercent}%
          </Progress>
        ) : undefined}
        {notFixedPercent ? (
          <Progress bar color="danger" value={notFixedPercent}>
            {notFixedPercent}%
          </Progress>
        ) : undefined}
      </Progress>
    </div>
  );
};
