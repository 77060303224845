/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *
 * Description: A ControlEvidenceLink wrapper for the GenericCSVImporter component.
 */

import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Spinner } from "reactstrap";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ProgressText } from "../../../../utils/GenericComponents/ProgressModal/components/ProgressText";
import { createControlEvidenceLink } from "../../../Compliance/ControlEvidenceLinks/functions/createControlEvidenceLink";
import GenericCsvImporter from "../../GenericImporter/components/GenericCsvImporter";
import { MapControlIDsToEvidenceIDs } from "../functions/MapControlIDsToEvidenceIDs";
import { MapControlStatementNumbersToIds } from "../functions/MapControlStatementNumbersToIds";
import { MapEvidenceNamesToIds } from "../functions/MapEvidenceNamesToIds";

class ControlEvidenceLinkImporter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.getInitialState = this.getInitialState.bind(this);
    this.createControlEvidenceLinkFromRow = this.createControlEvidenceLinkFromRow.bind(this);
    this.setProgressText = this.setProgressText.bind(this);
    this.setProgressBar = this.setProgressBar.bind(this);
    this.setProgressTotal = this.setProgressTotal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.alertResults = this.alertResults.bind(this);
  }

  getInitialState = () => ({
    evidenceDictionary: null,
    controlDictionary: null,
    controlEvidenceLinkDictionary: null,
    modal: false,
    inProgress: false,
    progressText: [],
    progressValue: 0,
    progressTotal: 0,
  });

  setProgressText = (text) => {
    const currentText = this.state.progressText;
    currentText.push(text);
    this.setState({
      progressText: currentText,
    });
  };

  setProgressBar = () => {
    const currentProgress = this.state.progressValue;
    this.setState({
      progressValue: currentProgress + 1,
    });
  };

  setProgressTotal = (total) => {
    this.setState({
      progressTotal: total,
    });
  };

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      inProgress: !prevState.inProgress,
      progressText: [],
    }));
  }

  static isControlEvidenceLinkHeader(headerRow) {
    const validRow = ["Statement Number", "Evidence"];

    if (!Array.isArray(headerRow) || headerRow.length !== validRow.length) return false;

    for (let i = 0; i < validRow.length; i++) {
      if (headerRow[i].trim() !== validRow[i]) return false;
    }

    return true;
  }

  static isValidControlEvidenceLink(row) {
    // Return false if the row is empty, else return true.
    return row && row[0] && row[1] && row[0].length > 0;
  }

  alertResults(successfulRows, failedRows) {
    const successMessage = `Successfully created ${successfulRows.length} Control Evidence Links!`;
    const failedMessage = `Could not create Control Evidence Link from ${failedRows.length} rows.`;
    let message = `Uploads Finished!\n${successMessage}`;

    if (failedRows.length > 0) message += `\n${failedMessage}`;

    this.setState({
      inProgress: false,
    });
    this.setProgressText(message);
  }

  async createControlEvidenceLinkFromRow(row) {
    if (this.state.inProgress === false) {
      this.toggleModal();
      this.setProgressText("Starting Import..");
    }

    if (this.state.evidenceDictionary == null) {
      this.setProgressText("Fetching Evidence..");
      await this.setState({
        evidenceDictionary: await MapEvidenceNamesToIds(this.props.evidenceOrganizationID || this.props.organizationID),
      });
      this.setProgressText(`Found ${this.state.evidenceDictionary.size} Evidences`);
    }

    if (this.state.controlDictionary == null) {
      this.setProgressText("Fetching Controls..");
      await this.setState({
        controlDictionary: await MapControlStatementNumbersToIds(
          this.props.controlOrganizationID || this.props.organizationID,
        ),
      });
      this.setProgressText(`Found ${this.state.controlDictionary.size} Controls`);
    }

    if (this.state.controlEvidenceLinkDictionary == null) {
      this.setProgressText("Fetching Current Control/Evidence Links..");
      await this.setState({
        controlEvidenceLinkDictionary: await MapControlIDsToEvidenceIDs(
          this.props.controlOrganizationID || this.props.organizationID,
        ),
      });
      this.setProgressText(`Found ${this.state.controlEvidenceLinkDictionary.size} Control/Evidence Links`);
    }

    this.setProgressText(`Looking up Evidence: ${row[1]}`);
    const evidenceID = this.state.evidenceDictionary.get(row[1]);
    this.setProgressText(`Evidence: ${row[1]} has ID: ${evidenceID}`);

    this.setProgressText(`Looking up Control: ${row[0]}`);
    const controlID = this.state.controlDictionary.get(row[0]);
    this.setProgressText(`Control: ${row[0]} has ID: ${controlID}`);

    this.setProgressText(`Creating link between Control: ${controlID} and Evidence: ${evidenceID}...`);
    await this.createControlEvidenceLinkFromRowHelper(controlID, evidenceID, this.state.controlEvidenceLinkDictionary);
  }

  async createControlEvidenceLinkFromRowHelper(controlID, evidenceID, controlEvidenceLinkDictionary) {
    if (controlID === undefined || evidenceID === undefined) {
      this.setProgressText("ERROR, SOMETHING IS UNDEFINED");
    }

    // first check if this control/evidence link already exists. if it does, skip it.

    if (controlEvidenceLinkDictionary.has(controlID)) {
      if (controlEvidenceLinkDictionary.get(controlID) === evidenceID) {
        this.setProgressText(`Control: ${controlID} is already linked to Evidence: ${evidenceID}, skipping.`);
        return;
      }
    }

    await createControlEvidenceLink(
      controlID, // id from statementNumber
      evidenceID, // id from Evidence Name
      this.props.organizationID, // ownerGroup
    )
      .then(() => {
        this.setProgressText(`Success: Link Created with ControlID: ${controlID}, EvidenceID: ${evidenceID}`);
        this.setProgressBar();
        this.setState({
          progressText: [],
        });
        InfoLogger(`Control Evidence Link created from CSV, with ControlID: ${controlID}, EvidenceID: ${evidenceID}`);
      })
      .catch((err) => {
        this.setProgressText("FAILED TO CREATE CONTROL EVIDENCE LINK FROM ROW:");
        ErrorLogger("FAILED TO CREATE CONTROL EVIDENCE LINK FROM ROW:");
        this.setProgressText("FAILED ROW ERROR:");
        ErrorLogger("FAILED ROW ERROR:");
        this.setProgressText(err);
        ErrorLogger(err);
      });
  }

  render() {
    return (
      <div>
        <GenericCsvImporter
          checkHeader={ControlEvidenceLinkImporter.isControlEvidenceLinkHeader}
          checkRow={ControlEvidenceLinkImporter.isValidControlEvidenceLink}
          alertResults={this.alertResults}
          processRow={this.createControlEvidenceLinkFromRow}
          cardTitle={<>Create Control Evidence links From .CSV File</>}
          setProgressText={this.setProgressText}
          setProgressTotal={this.setProgressTotal}
        />
        {/*The Progress Modal*/}
        <Modal key={"controlEvidenceLinkModal"} isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader>
            Associating Control with Evidence{" "}
            {this.state.inProgress ? <Spinner size="sm" color="primary" /> : <i className="icon-like" />}
          </ModalHeader>
          <ModalBody>
            <Progress animated={this.state.inProgress} value={this.state.progressValue} max={this.state.progressTotal}>
              {((this.state.progressValue / this.state.progressTotal) * 100).toFixed(0)}%
            </Progress>
            <ProgressText progressText={this.state.progressText} />
          </ModalBody>
          <ModalFooter>
            <Button close onClick={this.toggleModal} />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ControlEvidenceLinkImporter;
