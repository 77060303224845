import { usePolicyForm } from "../hooks/usePolicyForm";

/**
 * Component version of the policy creation hook
 * @param {object} props - all props to pass down to the hook
 * @return {JSX.Element}
 */
const PolicyForm = (props) => {
  return usePolicyForm(props).display;
};

export default PolicyForm;
