/**
 * Returns a list of all 3 way links for a given evidence between risk control and system, representing a non standard control
 * @param {object} evidence - the evidence for which to get the links
 * @return {*[]} - see title
 */
export const listEvidenceNonStandardRiskControlLinks = ({ evidence } = {}) => {
  //Check arguments
  if (!evidence) {
    return [];
  }

  // Parse the evidence item for any attached non-standard risk controls
  const data = [];
  const riskControlLinks = evidence?.riskControls?.items ?? [];
  if (Array.isArray(riskControlLinks)) {
    for (const riskControlLink of riskControlLinks) {
      const nonStandardRiskControl = riskControlLink?.system;
      if (nonStandardRiskControl) {
        data.push(riskControlLink);
      }
    }
  }

  return data;
};
