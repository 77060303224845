/**
 * Resets the value to match the 'item' object
 *
 * @param {function} setValue - sets the value state
 * @param {object} item - the item being updated
 * @param {string} field - the field being updated
 */
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const revertToOriginalValue = ({ setValue, item, field }) => {
  if (isNullOrUndefined(item)) {
    throw Error("item param cannot be null or undefined");
  }

  if (Array.isArray(item) || typeof item !== "object") {
    throw Error("item param must be an object");
  }

  if (isNullOrUndefined(field)) {
    throw Error("field param cannot be null or undefined");
  }

  if (typeof field !== "string") {
    throw Error("field param must be a string");
  }

  if (isNullOrUndefined(setValue)) {
    throw Error("setValue function param cannot be null or undefined");
  }

  if (typeof setValue !== "function") {
    throw Error("setValue param must be a function");
  }

  setValue(item[field]);
};
