import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { useTileDashboard } from "../../../hooks/views/useTileDashboard";
import GenericEditFieldV3 from "../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import DataLoader from "../../../utils/LoadingComponents/DataLoader";

import CustomDashboardLayoutDisplay from "./CustomDashboardLayoutDisplay";

/**
 * Displays multiple layouts of widgets as a tile dashboard. Each tile dashboard is displays a 'layout' which then has 'layoutElements'
 * @param {object[]} layouts - the layouts to display. Source of truth for the UI and state
 * @param {string} layouts[].id
 * @param {string} layouts[].name
 * @param {object[]} layouts[].elements
 * @param {boolean} isEditMode - whether or not the user is in edit mode. Displays different UI depending on this value
 * @param {function} onLayoutChange - callback to be called when a layout is changed
 * @param {string} organizationID - the ID of the organization
 * @param {object} addWidgetModal - reference to useModal hook for adding a widget
 * @param {function} save - function to be called whenever the layout changes to auto-save in the database
 * @param {function} removePage - function to be called when a page is deleted from the dashboard
 * @param {function} removeWidget - function to be called when a widget is deleted from a page
 * @param {function} editPageDetails - function to be called when a page is renamed
 * @param {boolean} isTemplate - whether or not the dashboard is a template
 * @returns {JSX.Element}
 * @constructor
 */
const MultipleLayoutDisplay = ({
  layouts,
  isEditMode,
  onLayoutChange,
  organizationID,
  addWidgetModal,
  save,
  removePage,
  removeWidget,
  editPageDetails,
  isTemplate,
}) => {
  const tileDashboard = useTileDashboard({
    id: `tile-dashboard-edits${isEditMode}`,
    expandTiles: true,
    onTileChange: onLayoutChange,

    // these props get passed to the CustomDashboardLayoutDisplay components
    childrenProps: {
      isEditMode,
      addWidgetModal,
      save,
      removeWidget,
      organizationID,
      layouts,
    },
    tiles:
      layouts?.map((layout) => ({
        id: layout.id,
        text: layout.name,
        name: layout.name,
        title: isEditMode ? (
          <span>
            <GenericEditFieldV3
              item={layout}
              field={"name"}
              mutationFunction={async (input) => await editPageDetails({ name: input.name, pageID: layout.id })}
              disableRoleChecking={true}
              typename="CustomDashboard"
            />
          </span>
        ) : (
          layout.name
        ),
        disableTotals: true,
        rightTopComponent: (
          <LayoutDeleteButton layout={layout} removePage={removePage} isEditMode={isEditMode} layouts={layouts} />
        ),
        icon: "icon-list",
        component: (
          <CustomDashboardLayoutDisplay
            // only used to pass the layout.id prop
            // actual real-time layout updates are handled by the 'childrenProps' prop
            layout={layout}
            isTemplate={isTemplate}
          />
        ),
      })) || [],
  });

  return (
    <DataLoader isLoading={!Array.isArray(layouts) || layouts?.length < 1} isEnoughData={true}>
      <span
        style={{
          width: "100%",
        }}
      >
        {tileDashboard.display}
      </span>
    </DataLoader>
  );
};

const LayoutDeleteButton = ({ layout, removePage, isEditMode, layouts }) => {
  return isEditMode && layouts?.length > 1 ? (
    <span>
      <IconButton
        variant={"contained"}
        color={"error"}
        onClick={async () => await removePage({ pageID: layout.id })}
        title={"Delete this Page from the Dashboard"}
      >
        <Delete />
      </IconButton>
    </span>
  ) : (
    <span />
  );
};

export default MultipleLayoutDisplay;
