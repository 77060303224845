import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { TreeItem, treeItemClasses, TreeView } from "@mui/x-tree-view";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import Alert, { AlertVariant } from "@components/Atoms/Alert/Alert";

import { useFeatureFlagsContext } from "../../../../featureFlags/FeatureFlagsProvider";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";

import styles from "./useComplianceArtifacts.module.scss";
import { useComplianceDocumentPreview } from "./useComplianceDocumentPreview";
import { useExportZip } from "./useExportZip";
import { useFetchDocumentsBy } from "./useFetchDocumentsBy";

/**
 * Compliance Documents Hook
 * @param {string} organizationID - selected organization id
 * @param {string} groupBy - group documents by
 * @param {string} controlFrameworkID - selected control framework id
 * @returns {{display: JSX.Element}}
 */
export const useComplianceArtifacts = ({
  organizationID,
  groupBy: groupByInit,
  controlFrameworkID,
  module = modules.COMPLIANCE,
  resource = resources.ARTIFACT,
}) => {
  const { flags } = useFeatureFlagsContext();

  const artifactDownloadMaxIntervalInDays = flags?.artifactDownloadMaxIntervalInDays ?? 395;
  const { form, items, groupBy, isLoading, defaultRootName } = useFetchDocumentsBy({
    organizationID,
    groupByInit,
    controlFrameworkID,
    maxIntervalInDays: artifactDownloadMaxIntervalInDays,
  });

  const [hasValidRange, setHasValidRange] = useState(false);
  useEffect(() => {
    const newDateRange = form?.input?.dateRange;
    if (!isNonEmptyArray(newDateRange) || newDateRange.length !== 2) {
      setHasValidRange(false);
      return;
    }

    const daysInRange = moment(newDateRange[1]).diff(moment(newDateRange[0]), "days");
    if (!daysInRange || isNaN(daysInRange) || daysInRange > artifactDownloadMaxIntervalInDays) {
      setHasValidRange(false);
      return;
    }

    setHasValidRange(true);
  }, [form?.input?.dateRange, flags?.artifactDownloadMaxIntervalInDays]);

  const exportZip = useExportZip({
    items,
    groupBy,
    organizationID,
    hasValidRange,
    disableDownloadButton: isLoading || !hasValidRange,
    defaultArchiveName: defaultRootName,
  });

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: theme.spacing(2),
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

  const StyledTreeItem = (props) => {
    const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other } = props;

    return (
      <StyledTreeItemRoot
        label={
          <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
            <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
            <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        {...other}
      />
    );
  };

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  const ICON_MAP = {
    pdf: PictureAsPdfIcon,
    file: FileCopyIcon,
    folder: FolderIcon,
  };

  /**
   * Document Preview
   */
  const filePreview = useComplianceDocumentPreview({ organizationID });

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes?.nodeId}
      nodeId={nodes?.nodeId}
      labelText={<div onClick={() => filePreview?.onFileClick(nodes)}>{nodes?.name}</div>}
      labelIcon={ICON_MAP[nodes?.type]}
      labelInfo={nodes?.children?.length}
    >
      {Array.isArray(nodes.children) ? nodes?.children?.map((node) => renderTree(node)) : null}
    </StyledTreeItem>
  );

  const display = (
    <PermissionsOverlay module={module} resource={resource}>
      <div>
        <div className={styles["artifact-download-container"]}>
          <Alert
            variant={AlertVariant.INFO}
            content={`
            For optimal download speeds and reliability, you can select data for a maximum
            of ${artifactDownloadMaxIntervalInDays} days. If you need data spanning more than
            ${artifactDownloadMaxIntervalInDays} days, simply choose different date ranges and download
            the data in multiple batches.
          `}
            className={styles["artifact-download-interval-alert"]}
          />
          {exportZip.modalButton}
        </div>

        {form.display}
        {filePreview.modal}
        <div>
          <DashboardCard isLoading={isLoading} style={{ height: "60vh" }}>
            <TreeView
              aria-label="root"
              defaultExpanded={["root"]}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              sx={{
                height: "100%",
                flexGrow: 1,
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              {renderTree(items)}
            </TreeView>
          </DashboardCard>
        </div>
      </div>
    </PermissionsOverlay>
  );

  return {
    display,
  };
};
