import { useControlSetDataGrid } from "../../../../views/Compliance/Controls/ControlSets/hooks/useControlSetDataGrid";

export const controlFrameworkDefinition = {
  routes: {
    grid: "continuous_compliance/control_frameworks",
    details: "continuous_compliance/control_frameworks",
    helpCenter: "control-frameworks",
  },

  //[APPEARANCE]
  icon: "fluent:text-bullet-list-square-shield-20-regular",
  gridHook: useControlSetDataGrid,

  fields: {
    name: {},
    customFields: {
      default: {
        description: "A list of additional fields unique to this Control Framework.",
        nestedFields:
          "{name type options { label value } multipleSelect { label value } numberSettings { min max step format }}",
      },
    },
    totalComplianceMetric: {
      default: {
        description:
          "The value of a Key Performance Indicator (KPI) that tracks the overall compliance of this Control Framework, updated daily.",
        nestedFields: "{id name}",
      },
    },
    controls: {
      default: {
        description: "A list of Controls that are part of this Control Framework",
        nestedFields: "{items {id}}",
      },
    },
  },

  views: {
    grid: {
      queryFields: ["name", "customFields", "totalComplianceMetric"],
    },
    details: {
      queryFields: ["name", "customFields", "totalComplianceMetric", "controls"],
      displayFields: ["name", "customFields"],
    },
  },
};
