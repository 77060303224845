/**
 * Converts automation step data displayed in the UI to the format required by the mutation to save data to the database
 * @param {object} step - the automation step data to format
 * @returns {object} - the formatted step
 */
export const formatAutomationStepForMutation = ({ step }) => {
  const newStep = { ...(typeof step === "object" ? step : {}) };
  if (typeof newStep.config !== "string") {
    newStep.config = JSON.stringify(newStep.config);
  }
  const allowedFields = ["id", "type", "config", "name", "description"];
  for (const key in newStep) {
    if (!allowedFields.includes(key)) {
      delete newStep[key];
    }
  }
  return newStep;
};
