import { checkArguments } from "@rivial-security/func-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { checkPermissions } from "../../../permissions/useCheckPermissions/useCheckPermissions";

/**
 * Takes in initial props to and returns an array of fields to display in use details.
 * The returned fields are filtered by permissions and user selected fields (in the fields prop)
 * @param {object} item - data for all of the item's fields as key value pairs
 * @param {string[]} fields - list fields to display (field names)
 * @param {object[]} customFields - additional properties impacting the visibility of the fields
 * @param {string} module - module to which the displayed information pertains to
 * @param {string} resource - the resource inside the provided module that this information pertains to
 * @param {boolean} disableRoleChecking - TRUE if no need to check for permissions to access content
 * @param {function} roleConfig - roleConfig received from a check permissions hook
 * @returns {string[]} all field names that are verified to exist and have user has permission to see
 */
export const getShownFields = ({
  item,
  fields = null,
  customFields = null,
  module,
  resource,
  disableRoleChecking = false,
  roleConfig,
}) => {
  //Check arguments to be of correct type and not be null
  try {
    checkArguments(
      {
        item,
        fields,
        customFields,
        module,
        resource,
        disableRoleChecking,
        roleConfig,
      },
      {
        item: { type: "object" },
        fields: {
          type: "string",
          validAsNull: true,
          isArray: true,
          deepTypeCheck: true,
        },
        customFields: {
          type: "object",
          validAsNull: true,
          isArray: true,
          deepTypeCheck: true,
        },
        module: { type: "string", validAsNull: true },
        resource: { type: "string", validAsNull: true },
        disableRoleChecking: { type: "boolean" },
        roleConfig: { type: "object", validAsNull: true },
      },
    );
  } catch (e) {
    ErrorLogger(e);
    return [];
  }

  //Include custom fields marked as computed and normal query fields in the available list
  const availableFields = Object.keys(item);
  if (Array.isArray(customFields)) {
    for (const customField of customFields) {
      if (customField?.isComputed === true) {
        availableFields.push(customField?.field);
      }
    }
  }

  //Sort the available fields in the order they appear in the fields array
  if (Array.isArray(fields)) {
    availableFields.sort((a, b) => {
      const aIndex = fields.indexOf(a);
      const bIndex = fields.indexOf(b);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });
  }

  let selectedFields = [];
  const shownFields = [];

  //Filter by selected fields
  if (fields) {
    selectedFields = availableFields.filter((itemField) => fields.includes(itemField));
  } else {
    //Include all fields otherwise
    selectedFields = [...availableFields];
  }

  const dynamicCustomFields = [];
  if (Array.isArray(customFields)) {
    //Include any dynamic custom field (for example control framework fields in controls)
    for (const field of customFields) {
      if (field?.field && field?.isDynamicCustomField) {
        dynamicCustomFields.push(field.field);
      }
    }
    selectedFields = selectedFields.concat(dynamicCustomFields);
  }

  //Filter out hidden custom fields
  if (Array.isArray(selectedFields) && Array.isArray(customFields)) {
    selectedFields = selectedFields.filter((fieldName) => {
      const customField = customFields.find((field) => (field?.name || field?.field) === fieldName);

      const isHidden = customField?.isHidden;
      if (typeof isHidden === "function") {
        return !isHidden({ item });
      } else {
        return !isHidden;
      }
    });
  }

  //Filter by permissions
  if (!disableRoleChecking && module && resource) {
    for (const itemField of selectedFields) {
      const noRolePermissionsField =
        customFields && customFields.find((item) => item?.field === itemField && item.isDynamicCustomField);

      const grantedPermissions = checkPermissions({
        module,
        resource,
        field: itemField,
        roleConfig,
        disableRoleChecking: disableRoleChecking || noRolePermissionsField != null,
      });

      if (grantedPermissions.field.read) {
        shownFields.push(itemField);
      }
    }
  } else {
    return selectedFields;
  }

  return shownFields;
};
