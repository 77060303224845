import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

import { deleteSystemRiskThreatLink_minimal } from "./deleteFunctionGQL";

export const DeleteSystemRiskThreatLink = async (systemRiskThreatLink) => {
  if (!systemRiskThreatLink) {
    ErrorLogger("[DeleteSystemRiskThreatLink.js] Error: No SystemRiskThreatLink was passed in");
    return null;
  }

  // Delete the SystemRiskThreatLink
  return await ItemMutation(deleteSystemRiskThreatLink_minimal, {
    id: systemRiskThreatLink.id,
  });
};
