"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumToDropdownData = void 0;
const convertCamelCaseToSentence_1 = require("../String/convertCamelCaseToSentence");
/**
 * IMPORTANT: update package to 5.23.1 to use this function
 * Creates a readable version of the enums for sync fusion dropdowns
 * @param {object} ENUM - any enum type object with no nesting (eg. EVIDENCE_STATUS)
 * @param {object} [overrides] - optional overrides for the enum values (to have a custom name)
 * @param {string} [valueFieldName] - optional name of the value field
 * @param {string} [textFieldName] - optional name of the text field
 * @param {boolean} [doConvertCamelCaseToSentence=true] - optional flag to convert the text to camel case
 * @return {object[]} - the dropdown data (array of objects, with keys as one property and values as another)
 * //NOTE: convert to camel case doesn't impact override values
 */
const enumToDropdownData = ({ ENUM, overrides = {}, valueFieldName = "value", textFieldName = "text", doConvertCamelCaseToSentence = true, }) => {
    const data = [];
    // Check the enum to not be null
    if (ENUM && typeof ENUM === "object") {
        // Check enum options to be non-null strings
        for (const option in ENUM) {
            if (!ENUM[option] && typeof ENUM[option] === "string") {
                return data;
            }
        }
    }
    else {
        return data;
    }
    // Generate the dropdown structure
    for (const option in ENUM) {
        const originalValue = ENUM[option];
        if (overrides &&
            overrides.hasOwnProperty(originalValue) &&
            overrides[originalValue] &&
            typeof overrides[originalValue] === "string") {
            data.push({
                [textFieldName]: overrides[originalValue],
                [valueFieldName]: originalValue,
            });
        }
        else {
            data.push({
                [textFieldName]: doConvertCamelCaseToSentence === true ? (0, convertCamelCaseToSentence_1.convertCamelCaseToSentence)(ENUM[option]) : ENUM[option],
                [valueFieldName]: originalValue,
            });
        }
    }
    return data;
};
exports.enumToDropdownData = enumToDropdownData;
