export const vendorCertificationDefinition = {
  routes: {
    grid: "vendors/certification",
    details: "vendors/certification",
    helpCenter: "pre-qualified-certifications",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:badge",
};
