import EnumFormField from "../../components/generic/enum/EnumFormField";
import { genericResources } from "../genericResources";

export const enumDefinition = {
  //[TYPE]
  isPrimitive: true,
  underlyingType: genericResources.STRING,

  //[APPEARANCE]
  InputComponent: EnumFormField,
};
