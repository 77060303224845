/**
 * WIP - not yet implemented
 * See: RISC-3028
 *
 * A list of default smart values that are built into the system.
 *
 * Default smart values consist of static values such as organization.name, user.name, etc.
 *
 * @type {string[]}
 */
export const DEFAULT_SMART_VALUES = [
  // "organization.name",
  // "organization.shortName",
  // "organization.type",
  // "date.today",
];
