"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intersection = void 0;
/**
 * Returns an array of values that includes only matching values from both arrays
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {Array}
 */
const intersection = (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }
    else {
        return [];
    }
};
exports.intersection = intersection;
