"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSecret = void 0;
const client_secrets_manager_1 = require("@aws-sdk/client-secrets-manager");
/**
 * @description Creates a new version with a new encrypted secret value and attaches it to the secret. The version can contain a new SecretString value or a new SecretBinary value.
 * @param {string} secretId - the name of the secret to add a new version to.
 * @param {string} arn - the ARN of the secret to add a new version to.
 * @param {string} secretString - the text to encrypt and store in the new version of the secret.
 * @param {string} clientRequestToken - a unique identifier for the new version of the secret.
 * @returns {Promise<SecretsManager.PutSecretValueResponse & {$response: Response<SecretsManager.PutSecretValueResponse, Error & {code: string, message: string, retryable?: boolean, statusCode?: number, time: Date, hostname?: string, region?: string, retryDelay?: number, requestId?: string, extendedRequestId?: string, cfId?: string, originalError?: Error}>}>}
 */
const updateSecret = async ({ secretId, arn, secretString, clientRequestToken }) => {
    if (!secretId && !arn)
        throw Error("Missing secretId and arn");
    const secretsManager = new client_secrets_manager_1.SecretsManagerClient({ apiVersion: "2017-10-17" });
    const params = {
        ClientRequestToken: clientRequestToken,
        SecretId: secretId || arn,
        SecretString: secretString,
    };
    const command = new client_secrets_manager_1.PutSecretValueCommand(params);
    return await secretsManager.send(command).catch((err) => {
        throw Error(`Could not update secret: ${err}`);
    });
};
exports.updateSecret = updateSecret;
