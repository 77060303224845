"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImpactFromAssetLink = void 0;
/**
 * Gets the C or I value from an asset link by multiplying numberOfRecords with costPerRecord
 *
 * @param {SystemInformationAssetLink} assetLink - the SystemInformationAssetLink item
 * @param {number} assetLink.confidentialityPerRecord
 * @param {number} assetLink.integrityPerRecord
 * @param {InformationAsset} assetLink.informationAsset
 * @param {number} assetLink.informationAsset.confidentialityPerRecord
 * @param {number} assetLink.informationAsset.integrityPerRecord
 * @param {string} field - either 'confidentialityPerRecord' or 'integrityPerRecord'
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getImpactFromAssetLink = (assetLink, field) => {
    const fieldValue = assetLink?.[field] || assetLink?.informationAsset?.[field]; // TODO refactor this for a null check on the link field, so that a 0 value will still override properly
    const numberOfRecords = assetLink?.numberOfRecords;
    return fieldValue * numberOfRecords;
};
exports.getImpactFromAssetLink = getImpactFromAssetLink;
