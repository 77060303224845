import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const defaultTheme = createTheme();

/**
 * Used for all data grid element styling
 * @type {(props?: any) => ClassNameMap<"root"|"textField">}
 */
export const useDataGridStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    toolbar: {
      fontSize: "0.8125rem",
      "& MuiButtonBase-root-MuiButton-root": {
        fontSize: "0.8125rem",
      },
    },
  }),
  { defaultTheme },
);
