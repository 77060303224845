import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";

interface UpdateRiskRecommendationInput {
  item: {
    id: string;
  };
  input: {
    recommendation: string;
    rating: string;
  };
  formattedRecommendationData: string;
  formattedRiskRecommendationData: {
    selectedRiskControl: {
      id: string;
    };
    changeInImplementation: number;
    changeInCost: number;
    selectedSystem: {
      id: string;
      name: string;
    };
    overallResidualChange: number;
    overallROIChange: number;
    systemChanges: {
      id: string;
      name: string;
      residualChange: number;
      returnOnInvestment: number;
    }[];
  };
}

/**
 * Updates an existing Risk Recommendation
 */
export const updateRiskRecommendation = async ({
  item,
  input,
  formattedRecommendationData,
  formattedRiskRecommendationData,
}: UpdateRiskRecommendationInput) => {
  const { updateMutation } = generateGraphql("RecommendationItem", ["name", "rating", "data"]);
  const mutationInput = {
    id: item.id,
    name: input.recommendation,
    rating: input.rating,

    // structured version of the data
    riskRecommendationData: formattedRiskRecommendationData,

    // legacy: unstructured version of the data
    data: formattedRecommendationData, // Monte Carlo Data for recommendation goes here
  };

  return await ItemMutation(updateMutation, mutationInput);
};
