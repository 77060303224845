import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useModal } from "../../../../hooks/views/useModal";
import { UIContext } from "../../../../utils/Context/UIContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { usePointOfContactDataGrid } from "../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";

/**
 * @description AddMeetingParticipants
 * @param {meeting} item - meeting
 * @param {string} organizationID - selected organization
 * @param {function} resetFunction - reset state function
 * @returns {JSX.Element}
 * @constructor
 */
const AddMeetingParticipants = ({ item, organizationID, resetFunction }) => {
  const { addToast, updateToast } = useContext(UIContext);

  const [participants, setParticipants] = useState([]);
  const itemPointOfContactsArray = item?.pointOfContacts?.items || [];

  useEffect(() => {
    const meetingParticipants = [];
    if (itemPointOfContactsArray) {
      for (const link of itemPointOfContactsArray) {
        const pointOfContact = link?.pointOfContact;
        if (pointOfContact) {
          const found = meetingParticipants.find((x) => x.id === pointOfContact.id);
          !found && meetingParticipants.push(pointOfContact);
        }
      }
      setParticipants(meetingParticipants);
      grid.setSelectedData(meetingParticipants);
    }
  }, [item]);
  const grid = usePointOfContactDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
  });

  const assignParticipants = async () => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        if (itemPointOfContactsArray) {
          const selectedData = grid?.selectedData;

          if (selectedData?.length > 0) {
            const { createMutation } = generateGraphql("PointOfContactMeetingLink");

            for (const contact of selectedData) {
              const alreadyIn = participants.find((x) => x.id === contact.id);

              if (!alreadyIn) {
                await ItemMutation(createMutation, {
                  pointOfContactMeetingLinkMeetingId: item?.id,
                  pointOfContactMeetingLinkPointOfContactId: contact.id,
                  ownerGroup: organizationID,
                });
              }
            }
          }

          resetFunction && resetFunction();
          pointOfContactModal?.setModalIsOpen(false);
        }
      },
      inProgressText: "Adding participants to the meeting...",
      failedText: "Failed to add participants to the meeting.",
      successText: "Successfully added participants to the meeting.",
      iconColor: "success",
    });
  };

  const pointOfContactModal = useModal(
    "Select Participants",
    <div style={{ height: "100vh" }}>
      {grid.gridDisplay}
      <Button className={"float-right"} onClick={() => assignParticipants()} style={{ marginTop: "1em" }}>
        Add Participants
      </Button>
    </div>,
    <Button className="btn-pill float-right" size="sm" title="Select contact for meeting">
      {"  "}
      <i className="icon-plus" />
    </Button>,
    {
      width: "60vw",
    },
  );

  return pointOfContactModal.modalButton;
};

export default AddMeetingParticipants;
