/**
 * This enum contains information on why the error has occurred
 */
export const reasonType = {
  GENERIC: 1, // if no error type is provided this one will be used
  INVALID_PARAM: 2, // a function or code block didn't get the correct arguments passed in (null or bad type)
  PERMISSION_BLOCK: 3, // operation cannot be completed due to the user not having enough permissions
  GRAPHQL_FAIL: 4, // when a mutation or query cannot complete due to a schema inconsistency (wrong fields used or not enough info)
  LAMBDA_FAIL: 5, // generic lambda error if the lambda doesn't log its own errors or doesn't pass back an error reason
  USER_CANCEL: 6, // happens if user explicitly (stop button) or inexplicitly (refresh page) stops an operation from completing
  NO_CONNECTION: 7, // error occurred because user is not logged in or does not have an active internet connection
};
