import { getCustomQueryFunction } from "@rivial-security/widget-utils";

/**
 * Convenience method to check if the last item in the query builder path state represents a post query function
 * @param {object[]} path - the current path that the user is building in the query builder
 * @return {boolean} - TRUE if the last item in the path is a function, FALSE otherwise
 */
export const lastPathElementIsAFunction = ({ path }) => {
  if (!Array.isArray(path)) {
    return false;
  }

  if (path.length === 0) {
    return false;
  }

  const lastPathElement = path[path.length - 1];
  const customQueryFunction = getCustomQueryFunction({
    functionData: lastPathElement,
  });
  return typeof customQueryFunction === "function";
};
