import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphqlFields } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { useGridCard } from "../../../../hooks/views/useGridCard";
import NumberChange from "../../../../utils/CustomFields/NumberChange";
import RecommendationDetails from "../../../Program/Recommendations/components/RecommendationDetails";
import Resolved from "../../../Program/Recommendations/customFields/Resolved";
import { deleteRecommendation } from "../../../Program/Recommendations/functions/deleteRecommendation";
import CreateRecommendation from "../components/CreateRecommendation";
import AssociatedControl from "../customFields/AssociatedControl";
import { updateRiskRecommendation_recommendationList } from "../graphql/__riskRecommendationsGQL";

/**
 * @deprecated - use useRecommendationsDataGrid instead
 * @description Displays Risk Recommendations for an Organization
 * @param {string} organizationID - The organization ID
 * @param {object} cardConfig - The card configuration
 * @param {object} gridConfig - The grid configuration
 * @param {string} systemID - The system ID
 * @param {object} props - The props
 */
export const useRecommendationsGrid = ({ organizationID, cardConfig = {}, gridConfig = {}, systemID, ...props }) => {
  const module = modules.RISK;
  const resource = resources.RECOMMENDATION;

  const queryFields = ["name", "status", "data", "observations", "module"];
  const nestedFields = {
    observations: `(limit: 1000) { items { observation { module } } }`,
  };

  /**
   * @description Converts the RecommendationItem query data to fit this table
   * @param {object} data -  The data to convert
   */
  const normalizeData = (data) => {
    const res = [];

    for (const item of data) {
      const dataField = item.data ? JSON.parse(item.data) : {};

      /**
       * If a SystemID is passed in, only show for that system
       */
      if (!isNullOrUndefined(systemID)) {
        if (systemID === dataField?.riskRecommendationSystemId) {
          res.push({
            id: item.id,
            name: item.name,
            status: item.status,
            riskControl: dataField?.riskControl,
            residualChange: dataField?.residualChange,
            returnOnInvestment: dataField?.returnOnInvestment,
          });
        }
      } else {
        res.push({
          id: item.id,
          name: item.name,
          status: item.status,
          riskControl: dataField?.riskControl,
          residualChange: dataField?.residualChange,
          returnOnInvestment: dataField?.returnOnInvestment,
        });
      }
    }

    return res;
  };

  const recommendationsByOwnerGroupByModule = /* GraphQL */ `
  query RecommendationsByOwnerGroupByModule(
    $ownerGroup: String
    $module: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByOwnerGroupByModule(
      ownerGroup: $ownerGroup
      module: $module
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${generateGraphqlFields(queryFields, nestedFields)}
      }
      nextToken
    }
  }
`;

  const queryConfig = {
    query: recommendationsByOwnerGroupByModule,
    variables: {
      ownerGroup: organizationID,
      module: { eq: module },
    },
    normalizeData,
    limit: 1000,
  };

  const fields = [
    {
      name: "name",
      width: 200,
    },
    {
      name: "riskControl",
      width: "200",
      component: <AssociatedControl />,
    },
    {
      name: "resolved",
      component: <Resolved />,
      disablePropagation: true,
      width: 90,
    },
    {
      name: "returnOnInvestment",
      component: <NumberChange format="percent" field="returnOnInvestment" />,
      width: 90,
    },
    {
      name: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" field="residualChange" />,
      width: 90,
    },
  ];

  gridConfig = {
    module,
    resource,
    detailsTitle: "Risk Recommendation Details",
    typename: "Risk Recommendation",
    header: "Risk Recommendations",
    fields: fields,
    options: ["details", "delete"],
    detailsComponent: <RecommendationDetails tableDisplay={true} />,
    route: "#/governance/recommendations/",
    createResourceComponent: <CreateRecommendation />,
    updateMutation: updateRiskRecommendation_recommendationList,
    deleteFunction: deleteRecommendation,
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    detailsType: DETAILS_TYPES.PANEL,
    enablePrint: true,
    enableMenu: true,
    createResourceComponentWidth: "80vw",
    persistenceUUID: "7aa8ef39-a37a-459c-98d3-7dd08cb4f417",
    genericEditFieldConfig: {
      name: {
        inputType: "textarea",
      },
    },
    ...gridConfig,
    ...props,
  };

  cardConfig = {
    title: "Risk Recommendations",
    headerIcon: "icon-tag",
    enableSticky: true,
    ...cardConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const gridCard = useGridCard({
    queryConfig,
    cardConfig,
    gridConfig,
    roleConfig,
  });

  return {
    ...gridCard,
  };
};
