"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultGaugeConfig = void 0;
const contextType_1 = require("../contextType");
/**
 * Preset settings to use when rendering or displaying a gauge widget
 * in different contexts (when there is no other config provided)
 */
exports.defaultGaugeConfig = {
    [contextType_1.CONTEXT_TYPE.ALL]: {
        custom: {
            labelStyle: {
                format: "${value}",
            },
            bottomTitle: {
                layout: {
                    height: null,
                },
                dataSource: {
                    text: null,
                },
                custom: {
                    containerStyle: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    style: {
                        alignText: "center",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        margin: 0,
                    },
                },
            },
            bottomSubtitle: {
                layout: {
                    height: null,
                },
                dataSource: {
                    text: null,
                },
                custom: {
                    containerStyle: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    style: {
                        alignText: "center",
                        fontSize: "1.3em",
                        fontWeight: "500",
                        margin: 0,
                    },
                },
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.REPORT]: {
        layout: {
            width: "700px",
            height: "400px",
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
    [contextType_1.CONTEXT_TYPE.WEB]: {
        layout: {
            width: "100%",
            height: "100%",
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    },
};
