import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { useBarChart } from "../../../../../hooks/charts/useBarChart";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a breakout of Phishing test results in a bar chart
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const KnowBe4PhishingBarChart = ({ item }) => {
  const [data, setData] = useState([]);

  const chart = useBarChart(data, ["count"], "type", {
    isCustomColors: true,
  });

  useEffect(() => {
    if (!isNullOrUndefined(item)) {
      const { delivered_count, opened_count, clicked_count, data_entered_count, reported_count } = item;

      const tempChartData = [];

      tempChartData.push({
        type: "Clicked",
        count: clicked_count,
        color: "hsl(0,100%,60%)",
      });

      tempChartData.push({
        type: "Opened",
        count: opened_count,
        color: "hsl(24,97%,57%)",
      });

      tempChartData.push({
        type: "Reported",
        count: reported_count,
        color: "hsl(139,100%,36%)",
      });

      tempChartData.push({
        type: "Delivered",
        count: delivered_count,
        color: "hsl(217,100%,61%)",
      });

      setData(tempChartData);
    }
  }, [item]);

  return chart.display;
};

export default withOrganizationCheck(KnowBe4PhishingBarChart);
