/**
 * Based on query settings for a series in a widget table grid creates a object of field settings to merge when
 * rendering inside useDataGrid, intended for configuring settings that are not part of data grid views, such
 * as header names
 * @param {object} editedSeries - the current series being edited
 * @return {object} - object of field settings to merge into the data grid
 */
export const getGridFieldsToMerge = ({ editedSeries }) => {
  const fieldSettings = editedSeries?.fieldSettings;
  if (typeof fieldSettings !== "object") {
    return null;
  }

  const gridFieldsToMerge = {};
  for (const fieldName in fieldSettings) {
    const field = fieldSettings[fieldName];
    const fieldToMerge = {};
    if (fieldName) {
      const { headerName } = field;
      if (headerName) {
        fieldToMerge.friendlyName = headerName;
      }

      gridFieldsToMerge[fieldName] = fieldToMerge;
    }
  }
  return gridFieldsToMerge;
};
