import { deleteRiskChange } from "./deleteRiskChange";

/**
 * Deletes a group of Risk Changes along with Risk Control and System links
 * @param {object[]} riskChanges - an array of Risk Changes to delete
 * @returns {Promise<null|*>} - returns when all Risk Changes have been deleted
 */
export const deleteGroupedRiskChanges = async (groupedRiskChanges) => {
  const riskChanges = groupedRiskChanges?.riskChanges;

  const riskChangesToDelete = [];
  if (Array.isArray(riskChanges)) {
    for (const riskChange of riskChanges) {
      if (riskChange?.id) {
        riskChangesToDelete.push(riskChange);
      }
    }
  }

  const deleteRequests = [];
  for (const riskChange of riskChangesToDelete) {
    deleteRequests.push(deleteRiskChange(riskChange));
  }

  await Promise.allSettled(deleteRequests);
};
