import { Icon } from "@iconify/react";
import { Link, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";

import { getFunctionFieldName } from "@rivial-security/widget-utils";

import { getFieldIcon } from "../../../functions/getFieldIcon";
import { getFieldsAtDepth } from "../../../functions/getFieldsAtDepth";
import { getFunctionsAtDepth } from "../../../functions/getFunctionsAtDepth";
import { lastPathElementIsAFunction } from "../../../functions/lastPathElementIsAFunction";

/**
 * Component which displays the current chain of elements that the user is configuring in a custom query
 * @param {object} path - the current chain of elements that the user is configuring in a custom query
 * @param {function} setPath - function for setting the path if the user wants to change it through this UI
 * @param {object} resultFields - fields selected at the root level of the query
 * @param {object} resultFunctions - functions selected at the root level of the query
 * @return {JSX.Element}
 */
const ResultFieldPath = ({ path, setPath, resultFields, resultFunctions }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const newSteps = [];
    if (Array.isArray(path)) {
      for (const segment of path) {
        let finalSegmentName = segment?.name;
        if (segment?.isFunction === true) {
          finalSegmentName = getFunctionFieldName({ pathElement: segment });
        } else if (finalSegmentName && segment?.isRoot === true) {
          finalSegmentName += " (Root)";
        }

        if (finalSegmentName) {
          newSteps.push({
            label: finalSegmentName,
          });
        }
      }
    }
    setSteps(newSteps);
  }, [path]);

  return (
    <Stepper activeStep={steps.length - 1} orientation="vertical">
      {steps.map((step, index) => {
        let otherFieldCount = null;
        if (resultFields) {
          const fieldsAtDepth = getFieldsAtDepth({
            path,
            resultFields,
            depth: index,
          });
          otherFieldCount = fieldsAtDepth.length;
        }

        let otherFunctionCount = null;
        if (resultFunctions) {
          const functionsAtDepth = getFunctionsAtDepth({
            path,
            resultFields,
            resultFunctions,
            depth: index,
          });
          otherFunctionCount = functionsAtDepth.length;
        }

        // Subtract the currently viewed field from other field count
        if (path.length > 1) {
          if (lastPathElementIsAFunction({ path })) {
            if (otherFunctionCount !== null) {
              otherFunctionCount -= 1;
            }
          } else {
            if (otherFieldCount !== null) {
              otherFieldCount -= 1;
            }
          }
        }

        return (
          <Step key={`${step?.label}_${index}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <StepLabel StepIconComponent={(props) => CustomStepIcon({ path, ...props })}>{step.label}</StepLabel>
              <Link
                component="button"
                variant="body2"
                underline="always"
                onClick={() => {
                  setPath(path.slice(0, index));
                }}
              >
                {otherFieldCount !== null && (
                  <span>{` + ${otherFieldCount} ${otherFieldCount === 1 ? "field" : "fields"}`}</span>
                )}
                {otherFunctionCount !== null && otherFunctionCount > 0 && (
                  <span>
                    {`${otherFieldCount !== null ? ", " : ""}${otherFunctionCount} ${
                      otherFunctionCount === 1 ? "function" : "functions"
                    }`}
                  </span>
                )}
                {otherFieldCount === null && otherFunctionCount === null && <span>back to here</span>}
              </Link>
            </div>
          </Step>
        );
      })}
      {path.length > 0 && <Step />}
    </Stepper>
  );
};

/**
 * The icon displayed inside each step of the path stepper UI
 * @param {object} path - current path being edited
 * @param {number} icon - the depth of the step
 * @param {boolean} active - if step is currently selected (controls color)
 * @param {boolean} error - if step failed (controls color)
 * @param {boolean} completed - if step is done (controls color)
 * @return {JSX.Element}
 */
const CustomStepIcon = ({ path, icon, active, error, completed }) => {
  const field = path[icon - 1];

  const getIconBackgroundColor = () => {
    if (active) {
      return "#2196F3";
    } else if (completed) {
      return "#2196F3";
    } else if (error) {
      return "#f44336";
    } else {
      return "#bdbdbd";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "2em",
        height: "2em",
        borderRadius: "50%",
        backgroundColor: getIconBackgroundColor(),
      }}
    >
      <Icon icon={getFieldIcon({ field })} style={{ width: "1em", height: "1em", color: "white" }} />
    </div>
  );
};

export default ResultFieldPath;
