"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateStyles = void 0;
const generateStyles = () => {
    return [
        {
            name: "Normal",
            type: "Paragraph",
            paragraphFormat: {
                listFormat: {},
            },
            characterFormat: {},
            next: "Normal",
        },
        {
            name: "Heading 1",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 12,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level1",
                listFormat: {},
            },
            characterFormat: {
                fontSize: 16,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Normal",
            link: "Heading 1 Char",
            next: "Normal",
        },
        {
            name: "Heading 1 Char",
            type: "Character",
            characterFormat: {
                fontSize: 16,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Default Paragraph Font",
            type: "Character",
            characterFormat: {},
        },
        {
            name: "Hyperlink",
            type: "Character",
            characterFormat: {
                underline: "Single",
                fontColor: "#0563C1FF",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Heading 2",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 2,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level2",
                listFormat: {},
            },
            characterFormat: {
                fontSize: 13,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Normal",
            link: "Heading 2 Char",
            next: "Normal",
        },
        {
            name: "Heading 2 Char",
            type: "Character",
            characterFormat: {
                fontSize: 13,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Heading 3",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 2,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level3",
                listFormat: {},
            },
            characterFormat: {
                fontSize: 12,
                fontFamily: "Calibri Light",
                fontColor: "#1F3763",
            },
            basedOn: "Normal",
            link: "Heading 3 Char",
            next: "Normal",
        },
        {
            name: "Heading 3 Char",
            type: "Character",
            characterFormat: {
                fontSize: 12,
                fontFamily: "Calibri Light",
                fontColor: "#1F3763",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Heading 4",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 2,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level4",
                listFormat: {},
            },
            characterFormat: {
                italic: true,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Normal",
            link: "Heading 4 Char",
            next: "Normal",
        },
        {
            name: "Heading 4 Char",
            type: "Character",
            characterFormat: {
                italic: true,
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Heading 5",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 2,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level5",
                listFormat: {},
            },
            characterFormat: {
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Normal",
            link: "Heading 5 Char",
            next: "Normal",
        },
        {
            name: "Heading 5 Char",
            type: "Character",
            characterFormat: {
                fontFamily: "Calibri Light",
                fontColor: "#2F5496",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Heading 6",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                firstLineIndent: 0,
                textAlignment: "Left",
                beforeSpacing: 2,
                afterSpacing: 0,
                lineSpacing: 1.0791666507720947,
                lineSpacingType: "Multiple",
                outlineLevel: "Level6",
                listFormat: {},
            },
            characterFormat: {
                fontFamily: "Calibri Light",
                fontColor: "#1F3763",
            },
            basedOn: "Normal",
            link: "Heading 6 Char",
            next: "Normal",
        },
        {
            name: "Heading 6 Char",
            type: "Character",
            characterFormat: {
                fontFamily: "Calibri Light",
                fontColor: "#1F3763",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Header",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 0,
                rightIndent: 0,
                textAlignment: "Left",
                listFormat: {},
                tabs: [
                    {
                        position: 216,
                        deletePosition: 0,
                        tabJustification: "Center",
                        tabLeader: "None",
                    },
                    {
                        position: 432,
                        deletePosition: 0,
                        tabJustification: "Right",
                        tabLeader: "None",
                    },
                ],
            },
            characterFormat: {
                bold: false,
                italic: false,
                fontSize: 11,
                fontFamily: "Calibri",
                strikethrough: "None",
                fontColor: "#00000000",
                bidi: false,
                fontSizeBidi: 11,
                fontFamilyBidi: "Calibri",
            },
            basedOn: "Normal",
            next: "Header",
        },
        {
            name: "Header Char",
            type: "Character",
            characterFormat: {
                bold: false,
                italic: false,
                fontSize: 12,
                fontFamily: "Calibri",
                strikethrough: "None",
                fontColor: "#00000000",
                bidi: false,
                fontSizeBidi: 12,
                fontFamilyBidi: "Calibri",
            },
            basedOn: "Default Paragraph Font",
        },
        {
            name: "Toc1",
            type: "Paragraph",
            paragraphFormat: {
                afterSpacing: 5,
                listFormat: {},
            },
            characterFormat: {},
            basedOn: "Normal",
            next: "Normal",
        },
        {
            name: "Toc2",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 11,
                afterSpacing: 5,
                listFormat: {},
            },
            characterFormat: {},
            basedOn: "Normal",
            next: "Normal",
        },
        {
            name: "Toc3",
            type: "Paragraph",
            paragraphFormat: {
                leftIndent: 22,
                afterSpacing: 5,
                listFormat: {},
            },
            characterFormat: {},
            basedOn: "Normal",
            next: "Normal",
        },
        {
            name: "Subtitle",
            type: "Paragraph",
            paragraphFormat: {
                afterSpacing: 8,
                listFormat: {
                    listLevelNumber: 1,
                },
            },
            characterFormat: {
                fontFamily: "Calibri",
                fontColor: "#5A5A5AFF",
                fontFamilyBidi: "Calibri",
            },
            basedOn: "Normal",
            link: "Subtitle Char",
            next: "Normal",
        },
        {
            name: "Subtitle Char",
            type: "Character",
            characterFormat: {
                fontFamily: "Calibri",
                fontColor: "#5A5A5AFF",
                fontFamilyBidi: "Calibri",
            },
            basedOn: "Default Paragraph Font",
        },
    ];
};
exports.generateStyles = generateStyles;
