"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Currently defined Precedence (Role Types)
 * A lower number means higher precedence (more access)
 * @type {{DEVELOPER: number, SUPERVISOR: number, MANAGER: number, ADMIN: number, USER: number}}
 */
const PRECEDENCE_TYPES = {
    DEVELOPER: 0,
    ADMIN: 1,
    MANAGER: 2,
    SUPERVISOR: 3,
    USER: 4,
};
exports.default = PRECEDENCE_TYPES;
