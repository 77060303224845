import React from "react";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { STEP_STATUS, usePleaseWaitModal } from "../usePleaseWaitModal";
import { useTransferList } from "../useTransferList/useTransferList";

import { usePopulateLinkedItems } from "./hooks/usePopulateLinkedItems";
import { usePopulateUnlinkedItems } from "./hooks/usePopulateUnlinkedItems";

/**
 * Hook providing UI to link many resources to a single resource in a many-one relationship
 * @param {string} organizationID - currently selected organization identifier
 * @param {object} manyItem - the item that holds many items (to which to connect other items)
 * @param {object[]} existingItems - the resources that are already attached
 * @param {object} createHook - create hook for adding a resource on the fly
 * @param {string} oneTypename - the schema type name of the item
 * @param {string} oneField - the field name of the `manyItem` on the linked items
 * @param {string} oneIdField - the field name of the id of the `manyItem` on the linked items
 * @param {string} manyTypename - the schema type name of the linked items
 * @param {string} manyField - the field name of the linked items on the `manyItem` resource
 * @param {function} [resetFunction] - the function to call to re-query manyItem after changes are made
 * @param {function} [toggleModal] - callback for closing the containing modal when finished updating links
 * @param {function} [listTitleAdapter] - function to format how items are displayed in the transfer list UI
 * @return {{display: JSX.Element}}
 */
export const useManyToOneLinking = ({
  organizationID,
  manyItem,
  existingItems,
  createHook,

  oneTypename,
  oneField,
  oneIdField,
  manyTypename,
  manyField,

  resetFunction,
  toggleModal,
  listTitleAdapter = (item) => {
    return item?.name ?? item?.title ?? item?.id ?? "Item";
  },
}) => {
  const friendlyTypename = convertCamelCaseToSentence(oneTypename);

  const pleaseWait = usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 1,
    confirmationText: `Congratulations, you've successfully Linked and Unlinked ${friendlyTypename}!`,
    steps: [
      {
        id: "unlink",
        text: `Unlinking ${friendlyTypename} Items`,
      },
      {
        id: "link",
        text: `Linking ${friendlyTypename} Items`,
      },
    ],
  });

  const updateOneLink = async ({ oneItem, linkValue } = {}) => {
    if (!oneItem?.id) {
      return;
    }

    const { updateMutation } = generateGraphql(oneTypename, [oneIdField]);

    await ItemMutation(updateMutation, {
      id: oneItem?.id,
      [oneIdField]: linkValue,
    });
  };

  const unlinkResources = async ({ itemsToUnlink } = {}) => {
    //Make a list of items that need to be unlinked
    const notUnlinkedYet = [];
    const manyItems = manyItem?.[manyField]?.items ?? [];

    if (Array.isArray(manyItems)) {
      for (const oneItemToLink of itemsToUnlink) {
        const foundLinkedItem = manyItems.find((oneItem) => oneItem?.id === oneItemToLink?.id);

        if (foundLinkedItem) {
          notUnlinkedYet.push(oneItemToLink);
        }
      }
    }

    //Remove all links that need deleting
    const mutationRequests = [];
    for (const oneItem of notUnlinkedYet) {
      mutationRequests.push(updateOneLink({ oneItem, linkValue: null }));
    }
    await Promise.allSettled(mutationRequests);
  };

  const linkResources = async ({ itemsToLink } = {}) => {
    //Based on the current item populate the items that are already linked
    const alreadyLinked = {};
    const manyItems = manyItem?.[manyField]?.items ?? [];
    for (const oneItem of manyItems) {
      const oneItemId = oneItem?.id;
      if (oneItemId) {
        alreadyLinked[oneItemId] = true;
      }
    }

    //Make a list of items that need to be linked
    const willBeLinked = {};
    const notLinkedYet = [];

    if (Array.isArray(itemsToLink)) {
      for (const oneItem of itemsToLink) {
        const oneItemId = oneItem?.id;
        if (oneItemId) {
          if (alreadyLinked.hasOwnProperty(oneItemId)) {
            alreadyLinked[oneItemId] = true;
          } else if (!willBeLinked.hasOwnProperty(oneItemId)) {
            willBeLinked[oneItemId] = true;
            notLinkedYet.push(oneItem);
          }
        }
      }
    }

    //Create all the links that are missing
    const mutationRequests = [];
    for (const oneItem of notLinkedYet) {
      mutationRequests.push(updateOneLink({ oneItem, linkValue: manyItem?.id }));
    }
    await Promise.allSettled(mutationRequests);
  };

  const handleSubmit = async () => {
    pleaseWait.setModalIsOpen(true);
    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    await unlinkResources({ itemsToUnlink: transferList.rightItems });

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    await linkResources({ itemsToLink: transferList.leftItems });

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setFinished(true);

    resetFunction && resetFunction();
    toggleModal && toggleModal();
  };

  const createCallback = (item) => {
    transferList.setRightItems((notLinked) => [...notLinked, item]);
    transferList.createResourceModal.setModalIsOpen(false);
  };

  const createResourceHook = createHook({
    organizationID,
    getNewItem: createCallback,
  });

  const transferList = useTransferList({
    handleSubmit,
    leftTitle: `Linked ${friendlyTypename} Items`,
    rightTitle: `Available ${friendlyTypename} Items`,
    createResourceComponent: createResourceHook.display,
    listTitleAdapter,
    typename: oneTypename,
  });

  //State updaters for the transfer list
  usePopulateLinkedItems({
    items: manyItem?.[manyField]?.items,
    setLinkedItems: transferList.setLeftItems,
  });

  usePopulateUnlinkedItems({
    manyItems: manyItem?.[manyField]?.items,
    setUnlinkedItems: transferList.setRightItems,
    existingItems,
  });

  const display = (
    <span>
      {pleaseWait.modal}
      {transferList.display}
    </span>
  );

  return {
    display,
  };
};
