import { formattedPercent } from "@rivial-security/func-utils";

/**
 * Displays a percentage change between two percent values
 * @param {number} newValue - the new value of the percentage field
 * @param {number} oldValue - the old value of the percentage field
 * @param {boolean} isInDecimalForm - if true, the new and old values are in decimal form (0.5 = 50%)
 * @returns {JSX.Element}
 */
const PercentageChange = ({ oldValue, newValue, isInDecimalForm = true }) => {
  //Get the old decimal value
  let oldPercent = null;
  if (Number.isFinite(oldValue)) {
    oldPercent = isInDecimalForm ? oldValue : oldValue / 100;
  }

  //Get the new decimal value
  let newPercent = null;
  if (Number.isFinite(newValue)) {
    newPercent = isInDecimalForm ? newValue : newValue / 100;
  }

  //Instance Variables
  const noValueChange = newPercent === oldPercent || newPercent === null;
  let percentChange = null;
  let signChange = "";
  if (noValueChange === false && oldPercent !== null) {
    if (oldPercent === 0) {
      percentChange = null;
    } else {
      percentChange = (newPercent - oldPercent) / oldPercent;
      signChange = percentChange > 0 ? "+" : "-";
    }
  }

  return (
    <div>
      {/* old and new values side by side */}
      <div>
        {/* old percentage */}
        {oldPercent !== null && (
          <p
            style={{
              textDecoration: noValueChange ? "none" : "line-through",
              display: "inline",
            }}
          >
            {formattedPercent(oldPercent)}
          </p>
        )}{" "}
        {/* new percentage*/}
        {noValueChange ? (
          <p style={{ display: "inline" }}>(No Change)</p>
        ) : (
          <p
            style={{
              display: "inline",
              color: newPercent > oldPercent || !oldPercent ? "green" : "red",
            }}
          >
            {formattedPercent(newPercent)}
          </p>
        )}
      </div>

      {/* show percent change between old and new values below, unless there's no change */}
      {noValueChange === false && percentChange !== null && (
        <div>
          <p
            style={{
              color: newPercent > oldPercent ? "green" : "red",
            }}
          >
            {`[ ${signChange}${Math.abs(percentChange * 100).toFixed(2)}% ]`}
          </p>
        </div>
      )}
    </div>
  );
};

export default PercentageChange;
