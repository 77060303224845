import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import { Inject } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { LoadingSpinner } from "../../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * Quickly displays Training information as a pie chart
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const KnowBe4TrainingPieChart = ({ item }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!isNullOrUndefined(item)) {
      const res = [];

      const { completion_percentage } = item;

      const series1 = {
        id: "percent_complete",
        x: `Completed`,
        y: completion_percentage,
        text: `${completion_percentage}%`,
        fill: "green",
      };

      const series2 = {
        id: "percent_not_complete",
        x: `Not Completed`,
        y: 100 - completion_percentage,
        text: `${100 - completion_percentage}%`,
        fill: "red",
      };

      res.push(series1);
      res.push(series2);

      setData(res);
    }
  }, [item]);

  return (
    <>
      {data ? (
        <AccumulationChartComponent
          id={`testing-pie-chart-${uuid()}`}
          legendSettings={{
            visible: true,
            position: "Top",
            enabled: false,
            toggleVisibility: false,
          }}
          enableSmartLabels={true}
          tooltip={{ enable: true }}
          enableAnimation={true}
          enableBorderOnMouseMove={true}
          highlightPattern="pacman"
        >
          <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              name="Project"
              dataSource={data}
              xName="x"
              yName="y"
              innerRadius="40%"
              startAngle={0}
              endAngle={360}
              radius="70%"
              explode={true}
              explodeOffset="10%"
              explodeIndex={3}
              pointColorMapping="fill"
              dataLabel={{
                visible: true,
                name: "text",
                position: "Inside",
                font: {
                  fontWeight: "600",
                  color: "#ffffff",
                },
              }}
              animation={{ enable: false }}
            ></AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      ) : (
        <LoadingSpinner loadingMessage="Loading Data.. " />
      )}
    </>
  );
};

export default withOrganizationCheck(KnowBe4TrainingPieChart);
