import { generateGraphql } from "@rivial-security/generategraphql";

import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

const { deleteMutation: deleteRiskChangeGQL } = generateGraphql("RiskChange");

/**
 * Deletes a Risk Change along with Risk Control and System links
 * @param riskChange
 * @returns {Promise<null|*>}
 */
export const deleteRiskChange = async (riskChange) => {
  const { getQuery } = generateGraphql("RiskChange", ["riskControlLinks", "systemLinks", "__typename"], {
    riskControlLinks: `(limit: 500) { items { id __typename } }`,
    systemLinks: `(limit: 500) { items { id __typename } }`,
  });

  return deleteItem(getQuery, riskChange);
};
