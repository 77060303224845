import GuestAuthentication from "./GuestAuthentication";

/**
 * A higher order component that wraps all UI under the guest route, checks if user is a robot prior to anonymous authentication
 * @param {JSX.Element} - the component to wrap
 * @return {JSX.Element|*} - the wrapped UI
 */
// eslint-disable-next-line react/display-name
export const withGuestAuthentication = (Component) => (props) => {
  return (
    <GuestAuthentication {...props}>
      <Component />
    </GuestAuthentication>
  );
};
