import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";

/**
 * Displays Observations, Recommendations, and Action Items for an Assessment in the Testing module
 * @param {object} item - the Assessment
 * @param {object} ...props - all props passed to the FindingsCard
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentFindingsCard = ({ item, ...props }) => {
  return <FindingsCard assessment={item} {...props} />;
};

export default withOrganizationCheck(AssessmentFindingsCard);
