import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import CreateVendorReview from "../components/CreateVendorReview";
import VendorReviewStatus from "../components/VendorReviewStatus";
import VendorSolutionReview from "../components/VendorSolutionReview";
import { listVendorReviewsByOwnerGroup } from "../constants/listVendorReviewsByOwnerGroup";
import RatingDropdown from "../customFields/RatingDropdown";
import { deleteVendorSolutionReview } from "../functions/deleteVendorSolutionReview";

/**
 * Display a list of Vendor Reviews for an organization
 *
 * @param {string} organizationID       - ownerGroup filter for the Vendor Reviews
 * @param {object} gridConfig           - @deprecated overrides to pass as useDataGrid props
 * @param {object} cardConfig           - @deprecated overrides to pass as useDataGrid props
 * @param {object} queryConfig          - @deprecated overrides to pass as useDataGrid props
 * @param {object[]} additionalFields   - additional fields to display in the grid or override the default fields
 * @param {string} vendorSolutionID     - ID of the Vendor Solution for which to display the Vendor Reviews
 * @param {string} route                - overrides the default route for the Vendor Review details
 * @param {string} module               - overrides the default module for the Vendor Review details
 * @param {string} resource             - overrides the default resource for the Vendor Review details
 * @param {boolean} disableRoleChecking - disables the role checking for the Vendor Review details
 * @param {object} ...props             - all other props passed to useDataGrid
 */
export const useVendorReviewDataGrid = ({
  organizationID,
  gridConfig = {},
  cardConfig = {},
  queryConfig = {},
  additionalFields = [],
  vendorSolutionID,
  route = "#/vendors/reviews/",
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  ...props
}) => {
  const typename = "VendorReview";
  const queryFields = ["name", "rating", "completedBy", "status"];

  const { updateMutation } = generateGraphql(typename, queryFields);

  const roleConfig = {
    module,
    resource,
  };

  cardConfig = {
    title: "Vendor Solution Reviews",
    headerIcon: "icon-book-open",
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "completedBy",
      flex: 0.5,
      bulkEdit: true,
    },
    {
      name: "rating",
      component: <RatingDropdown />,
      disablePropagation: true,
      flex: 0.5,
      bulkEdit: true,
    },
    {
      name: "status",
      component: <VendorReviewStatus />,
      flex: 0.5,
    },
  ];
  mergeAdditionalFields({ additionalFields, fields });

  gridConfig = {
    organizationID,
    fields,
    detailsComponent: (
      <VendorSolutionReview
        tableDisplay={true}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    route,
    options: ["details", "delete", "open", "edit"],
    deleteFunction: deleteVendorSolutionReview,
    updateMutation,
    detailsTitle: "Vendor Review Details",
    typename,
    createResourceComponent: (
      <CreateVendorReview
        vendorSolutionID={vendorSolutionID}
        organizationID={organizationID}
        route={route}
        module={module}
        resource={resource}
      />
    ),
    persistenceUUID: "1c9778a4-d1e1-4f7a-96d9-3ea2e75f46902",
    ...gridConfig,
    ...props,
  };

  queryConfig = {
    query: listVendorReviewsByOwnerGroup,
    limit: 1000,
    variables: { ownerGroup: organizationID },
    ...queryConfig,
  };

  const queryCardHook = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
  });

  return { ...queryCardHook };
};
