import { ListViewComponent } from "@syncfusion/ej2-react-lists";

import { useUUID } from "../../../hooks/functional/useUUID";

export interface ListViewProps {
  // eslint-disable-next-line
  list: { [key: string]: Object }[] | string[] | number[] | undefined;
}

/**
 * @description List View component
 * List param structure:
 *  [
 *    { text: 'Artwork', id: '01' },
 *    { text: 'Abstract', id: '02' },
 *    { text: 'Modern Painting', id: '03' },
 *    { text: 'Ceramics', id: '04' },
 *    { text: 'Animation Art', id: '05' },
 *    { text: 'Oil Painting', id: '06' }
 *   ]
 */
const ListView = ({ list }: ListViewProps): JSX.Element => {
  const [uuid] = useUUID();

  return <ListViewComponent id={`list-view-${uuid}`} dataSource={list} />;
};

export default ListView;
