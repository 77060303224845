import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";
import { GetQuery } from "../../../../../../utils/Functions/Graphql/GetQuery";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useActionDataGrid } from "../../../../../Program/Actions/hooks/useActionDataGrid";

/**
 * A DashboardCard component for displaying a Point of Contact's Action Items
 * @param {object} item - The Point of Contact to display
 * @param {string} item.id - The ID of the Point of Contact
 * @param {JSX.Element[]} headerButtons - The buttons to display in the header
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactActions = ({ item, headerButtons }) => {
  const typename = "PointOfContact";
  const fields = ["actions"];
  const nestedFields = {
    actions: `(limit: 1000) {
      items {
        id
        name
        description
        status
        priority
        dueDate
        completionDate
        startDate
        module
        createdAt
        data
        pointOfContact { id firstName lastName email }
      }
    }`,
  };

  const { getQuery } = generateGraphql(typename, fields, nestedFields);

  const [isLoading, setIsLoading] = useBoolean(true);

  const actionsDataGrid = useActionDataGrid({
    hiddenFields: ["pointOfContact"],
    queryConfig: {
      query: null,
    },
    gridConfig: {
      sortSettings: {
        columns: [{ field: "priority", direction: "Descending" }],
      },
    },
  });

  useEffect(() => {
    if (!isNullOrUndefined(item?.id)) {
      setIsLoading(true);
      GetQuery({
        query: getQuery,
        limit: 1000,
        variables: {
          id: item.id,
        },
      }).then((pointOfContactWithActions) => {
        if (!isNullOrUndefined(pointOfContactWithActions?.actions?.items)) {
          actionsDataGrid.setData(pointOfContactWithActions?.actions?.items);
        }

        setIsLoading(false);
      });
    }
  }, [item?.id]);

  return (
    <DashboardCard
      title={"Action Items"}
      icon={"icon-rocket"}
      isLoading={isLoading}
      headerButtons={headerButtons}
      style={{ height: "70vh" }}
    >
      {actionsDataGrid.gridDisplay}
    </DashboardCard>
  );
};

PointOfContactActions.propTypes = {
  pointOfContact: PropTypes.object, //deprecated. use props.item instead
  item: PropTypes.object,
};

PointOfContactActions.defaultProps = {
  pointOfContact: {},
};

export default withDetailsPage(PointOfContactActions);
