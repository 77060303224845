import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Creates a ColumnData array to be used with the Query Builder component from schema.json
 * @param selectedType
 * @param types
 * @returns {object[]}
 */
export const getColumnDataFromTypes = (selectedType, types) => {
  const typeObject = types.find((item) => item.typename === selectedType);
  const columnData = [];
  const fields = typeObject?.fields;
  if (typeof fields === "object") {
    for (const fieldName in fields) {
      if (fields.hasOwnProperty(fieldName)) {
        const { type, typename, enumValues, options, isEnum, label } = fields[fieldName] || {};

        const enumOptions = [];
        if (isEnum && Array.isArray(enumValues)) {
          //custom query builder types
          for (const enumValue of enumValues) {
            enumOptions.push({
              label: convertCamelCaseToSentence(enumValue),
              value: enumValue,
            });
          }
        } else if (options) {
          // automation trigger types
          for (const option in options) {
            const optionLabel = options[option];
            enumOptions.push({
              label: optionLabel,
              value: option,
            });
          }
        }

        columnData.push({
          field: fieldName,
          label: label || convertCamelCaseToSentence(fieldName),
          type: convertType({ isEnum, type, typename }),
          operators: getOperatorsForType({ type }),
          options: enumOptions,
        });
      }
    }
  }

  return columnData;
};

/**
 * @deprecated - logic builder determines operators automatically
 * Gets the valid operation types for Mui QueryBuilder
 * @param {string} type
 */
const getOperatorsForType = ({ type }) => {
  if (type === "enum") {
    return [
      {
        key: "Is",
        value: "equal",
      },
      {
        key: "Is Not",
        value: "notequal",
      },
    ];
  }
  if (type === "string") {
    return [
      {
        key: "Equals",
        value: "equal",
      },
      {
        key: "Does Not Equal",
        value: "notequal",
      },
      {
        key: "Contains",
        value: "contains",
      },
      {
        key: "Does Not Contain",
        value: "notcontains",
      },
      {
        key: "Starts With",
        value: "startswith",
      },
    ];
  }

  if (type === "number") {
    return [
      {
        key: "Equals",
        value: "equal",
      },
      {
        key: "Does Not Equal",
        value: "notequal",
      },
      {
        key: "Less Than",
        value: "lessthan",
      },
      {
        key: "Less Than or Equal To",
        value: "lessthanorequal",
      },
      {
        key: "Greater Than",
        value: "greaterthan",
      },
      {
        key: "Greater Than or Equal To",
        value: "greaterthanorequal",
      },
      {
        key: "Between",
        value: "between",
      },
    ];
  }
};

/**
 * Converts the schema field type to the Mui QueryBuilder equivalent. Converts Enum types to String
 */
const convertType = ({ isEnum, type, typename }) => {
  if (type === "enum" || isEnum) {
    return "enum";
  } else if (type === "boolean" || typename === "Boolean") {
    return "boolean";
  } else if (type === "date" || typename === "AWSDateTime") {
    return "date";
  } else if (type === "number") {
    return "number";
  } else if (type !== "string" || type !== "number") {
    return "string";
  } else {
    return type;
  }
};
