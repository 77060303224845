import React, { useEffect } from "react";

import { removeDuplicateObjects } from "@rivial-security/func-utils";

import DocumentDataGrid from "../../../../hooks/views/useDocumentGrid/components/DocumentDataGrid";
import { deleteDocument } from "../../../../hooks/views/useDocumentGrid/functions/deleteDocument";
import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Documents associated with an Evidence through Activities
 * @param {object} item - evidence
 * @param {object} recordEvidence - record evidence hook instance
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
export const useEvidenceDocuments = ({ item, recordEvidence, organizationID }) => {
  useEffect(() => {
    if (recordEvidence?.key !== 0) nestedDataCard.resetFunction();
  }, [recordEvidence?.key]);

  const gridConfig = {
    createResourceComponent: null,
    deleteFunction: deleteDocument,
    options: ["details", "delete"],
  };

  const gridProps = {
    fields: [
      {
        name: "comment",
        width: 200,
      },
    ],
  };

  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "activity",
    typename: "Document",
    parentTypename: "Evidence",
    grid: <DocumentDataGrid />,
    gridHook: useDocumentDataGrid,
    fields: ["activity"],
    gridProps,
    gridHookGridConfig: gridConfig,
    nestedFields: {
      activity: `(limit: 1000) {
        items {
          id
          comment
          archived
          documents (limit: 1000) {
            items {
              id
              name
              ownerGroup
              visibility
              avStatus
              lastAVCheck
              createdAt
              file {
                bucket
                region
                key
              }
              accessControl {
                password
                passwordOwnerEmail
                pointOfContacts
                roles
              }
            }
          nextToken
          }
        }
        nextToken
      }`,
    },
    createResourceComponent: null,
    normalizeData: (items) => {
      let res = [];
      items?.forEach((item) => {
        const documents = item?.documents?.items;

        if (Array.isArray(documents) && !item?.archived) {
          const tempArray = [];
          /**
           * Assign a comment to a document from the parent evidence activity
           */
          for (const document of documents) {
            tempArray.push({ ...document, comment: item?.comment });
          }
          res = res.concat(tempArray);
        }
      });
      return removeDuplicateObjects(res);
    },
  });

  const display = <div style={{ height: "100%" }}>{nestedDataCard.gridDisplay}</div>;

  return {
    ...nestedDataCard,
    display,
  };
};

const EvidenceDocuments = (props) => useEvidenceDocuments(props).display;

export default withOrganizationCheck(EvidenceDocuments);
