export const listKnowBe4PhishingTests = `
      query ListKnowBe4PhishingTests($query: QueryListKnowBe4PhishingTestsQueryInput!) {
        listKnowBe4PhishingTests(query: $query) {
          pst_id
          campaign_id
          name
          started_at
          opened_count
          clicked_count
          delivered_count
          status
          phish_prone_percentage
        }
      }
    `;
