import { makeStyles } from "@mui/styles";

/**
 * Custom styles for the SelectionTiles components
 */
export const useSelectionTileStyles = makeStyles((theme) => ({
  button: {
    height: "10em",
    width: "10em",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
  },
  selectedButton: {
    background: `linear-gradient(44deg, rgb(5,138,239) 0%, rgb(31,72,181) 50%)`,
  },
}));
