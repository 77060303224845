"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLossCurveXPoints = void 0;
/**
 * Used for generating X/Y values of the loss tolerance curve.
 * Returns an array of X values to be used as the X-Axis of the loss exceedance curve
 * @author Jacob Blazina
 * @function
 * @param {number} [lossCurveMaxX=1000000] - The maximum X-value (in $) to be used. Defaults to $1,000,000
 * @param {number} [lossCurvePointCount = 100] - How many points of the X axis to plot. Defaults to 100
 * @returns {number[]} - An array of numbers corresponding to dollar amounts on the x axis
 */
const getLossCurveXPoints = (lossCurveMaxX = 1000000, lossCurvePointCount = 100) => {
    const xValues = [];
    const intervalSize = lossCurveMaxX / (1.0 * lossCurvePointCount); // Multiply by 1.0 to ensure integer division doesn't occur.
    for (let intervalNum = 0; intervalNum < lossCurvePointCount; intervalNum++) {
        if (intervalNum === 0) {
            // Min x-axis value.
            xValues[intervalNum] = 0.01;
        }
        else if (intervalNum === lossCurvePointCount - 1) {
            // Max x-axis value.
            xValues[intervalNum] = lossCurveMaxX;
        }
        else {
            xValues[intervalNum] = intervalNum * intervalSize;
        }
    }
    return xValues;
};
exports.getLossCurveXPoints = getLossCurveXPoints;
