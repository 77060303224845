import { getDefaultDataGridViewsData } from "./getDefaultDataGridViewsData";

/**
 * Saves the passed in viewID as the users preferred setting for the particular gridID
 * @param {string} gridID - the ID of the grid to save the view for
 * @param {string} viewID - the ID of the view to save as the users preferred view
 * @param {object} preferencesHook - instance of usePreferences hook
 * @return {Promise<void>}
 */
export const saveUserSelectedView = async ({ gridID, viewID, preferencesHook }) => {
  //Check arguments
  const { setPreference, getNetworkPreference } = preferencesHook || {};
  if (!gridID || typeof setPreference !== "function" || typeof getNetworkPreference !== "function") {
    throw new Error("saveDataGridView: invalid arguments", {
      gridID,
      viewID,
      setPreference,
      getNetworkPreference,
    });
  }

  //Create the data grid views data object
  let dataGridViewsData = await getNetworkPreference("dataGridViews", gridID);

  if (!dataGridViewsData) {
    dataGridViewsData = getDefaultDataGridViewsData({});
  } else {
    dataGridViewsData.selectedViewID = viewID;
  }

  //Save the view object to preferences
  await setPreference("dataGridViews", { [gridID]: dataGridViewsData });
};
