import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useUIContext } from "@utils/Context/UIContext";
import { ErrorLogger } from "@utils/EventLogger";

import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { STEP_STATUS, usePleaseWaitModal } from "../../../../../hooks/views/usePleaseWaitModal";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { tryFunction } from "../../../../../utils/Functions/tryFunction";
import RecommendationMonteCarlo from "../../../Recommendations/hooks/useCreateRiskRecommendation/components/RecommendationMonteCarlo";
import { useRiskRecommendationData } from "../../../Recommendations/hooks/useCreateRiskRecommendation/hooks/useRiskRecommendationData";
import { RISK_CHANGE_TYPE } from "../../constants/riskChangeType";

import { submitRiskChange } from "./functions/submitRiskChange/submitRiskChange";

/**
 * Form hook for creating a new risk change from the frontend
 * @param {string} organizationID - the currently selected organization identifier
 * @param {function} [resetFunction] - if present can be used to re-query passed in data
 * @param {function} [toggleModal] - if present can be used to close the containing modal
 * @param {object} [system] - optional, if passed in, automatically selects the appropriate system
 * @param {object} [riskControl] - optional, if passed in will lock the selection to specific risk control (both non-standard and enterprise)
 * @param {function} [submitFunctionExternal] - optional, if passed in will be called instead of the default submit function
 * @param {boolean} [showOverridesButton] - TRUE if you want to show the overrides buttons for the fields
 * @param {boolean} [disableSimulationMessage] - if present will remove the simulate option and replace with a message to the user.
 * @param {object} ...props - all other props passed to useForm hook
 */
export const useCreateRiskChange = ({
  organizationID,
  resetFunction,
  toggleModal,
  system,
  riskControl,
  submitFunction: submitFunctionExternal,
  showOverridesButton,
  disableSimulationMessage,
  ...props
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;
  const context = useContext(OrganizationContext);

  const riskRecommendationData = useRiskRecommendationData({
    organizationID,
    system,
    filterRiskControls: (availableRiskControls) => {
      if (riskControl?.id && Array.isArray(availableRiskControls)) {
        return availableRiskControls.filter(
          (availableRiskControl) => availableRiskControl && riskControl && availableRiskControl.id === riskControl.id,
        );
      }
      return availableRiskControls;
    },
  });

  const uiContext = useUIContext();

  const defaultFields = ["name", "description", "date", "userEmail", "changeOwner", "reason", "type"];

  const updateInputFunction = (input) => {
    if (input?.type !== RISK_CHANGE_TYPE.MANUAL_ENTRY && !isNullOrUndefined(system)) {
      return {
        ...input,
        type: RISK_CHANGE_TYPE.NON_STANDARD_CONTROL,
      };
    } else {
      return input;
    }
  };

  const [riskControlInputInvalid, setRiskControlInputInvalid] = useState(false);

  const onInvalidInputChange = ({ isInvalid, type }) => {
    if (type !== RISK_CHANGE_TYPE.MANUAL_ENTRY) {
      if (!isNullOrUndefined(isInvalid)) {
        setRiskControlInputInvalid(isInvalid);
      }
    } else {
      setRiskControlInputInvalid(false);
    }
  };

  const SubmitStep = {
    EXECUTE_SUBMIT_FUNCTION: "executeSubmitFunction",
  };
  const pleaseWaitModal = usePleaseWaitModal({
    steps: [
      {
        id: SubmitStep.EXECUTE_SUBMIT_FUNCTION,
        text: "Submitting a Risk Change",
      },
    ],
    confirmationText: "",
    autoClose: true,
    toggleModal,
    resetFunction: () => {
      uiContext.addToast({
        header: `Your risk change has been successfully submitted! Depending on the type of change it may take up to 3 minutes to finish processing.`,
        icon: "success",
        timeoutDuration: 10, //10 seconds of guaranteed on-screen time,
      });
      tryFunction(resetFunction);
    },
    onClosed: () => {
      tryFunction(toggleModal);
    },
    disableCloseModal: true,
  });

  const submitFunction = async (input) => {
    pleaseWaitModal.setModalIsOpen(true);
    pleaseWaitModal.setStepsStatusById({
      ids: [SubmitStep.EXECUTE_SUBMIT_FUNCTION],
      status: STEP_STATUS.IN_PROGRESS,
    });

    //Determine change type
    let changeType = RISK_CHANGE_TYPE.MANUAL_ENTRY;
    const isNotManualEntry = input?.type !== RISK_CHANGE_TYPE.MANUAL_ENTRY;
    const didSelectSystem = !isNullOrUndefined(riskRecommendationData.selectedSystemID);
    if (isNotManualEntry && didSelectSystem) {
      changeType = RISK_CHANGE_TYPE.NON_STANDARD_CONTROL;
    } else if (isNotManualEntry && !didSelectSystem) {
      changeType = RISK_CHANGE_TYPE.ENTERPRISE_CONTROL;
    }

    //Submit the risk change
    try {
      if (typeof submitFunctionExternal === "function") {
        const {
          calculationResults,
          selectedSystemID,
          selectedRiskControlID,
          selectedRiskControl,
          changeInCost,
          changeInImplementation,
        } = riskRecommendationData || {};

        await submitFunctionExternal({
          riskRecommendationData: {
            calculationResults,
            selectedSystemID,
            selectedRiskControlID,
            selectedRiskControl,
            changeInCost,
            changeInImplementation,
          },
          riskChangeFormInput: input,
          changeType,
          organizationID,
        });
      } else {
        await submitRiskChange({
          input,
          organizationID,
          system,
          riskRecommendationData,
        });
      }

      pleaseWaitModal.setStepsStatusById({
        ids: [SubmitStep.EXECUTE_SUBMIT_FUNCTION],
        status: STEP_STATUS.COMPLETE,
      });
      pleaseWaitModal.setFinished(true);
    } catch (e) {
      //Report error to the local and remote console
      let errorMessage = "Unable to create a risk change or timed out!";
      ErrorLogger(errorMessage, e.message);
      Sentry.captureException(e);

      //Check if simulations were attached to the request
      const didSimulateResults =
        !isNullOrUndefined(riskRecommendationData?.calculationResults) && changeType !== RISK_CHANGE_TYPE.MANUAL_ENTRY;
      if (didSimulateResults) {
        errorMessage += ` Try to submit another change without simulating the results.`;
      }
      pleaseWaitModal.finishWithError({
        errorMessage,
      });
    }
  };

  const formHook = useForm({
    fields: defaultFields,
    typename: "Risk Change",
    organizationID,
    module,
    resource,
    submitFunction,
    disableRoleChecking: true,
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name of Change",
        required: true,
        defaultValue: "",
      },
      description: {
        inputType: "textarea",
        label: "Description of Change",
      },
      date: {
        inputType: "date",
        label: "Date of Change",
        defaultValue: new Date(),
        tooltip: "When did this Change happen?",
      },
      userEmail: {
        inputType: "text",
        label: "Person Entering Change",
        defaultValue: context.userEmail,
      },
      changeOwner: {
        inputType: "text",
        label: "Change Owner",
        tooltip: "Who in the Organization is responsible for this Change?",
      },
      reason: {
        inputType: "textarea",
        label: "Reason for Change",
        tooltip: "Why did this Change occur?",
      },
      type: {
        inputType: "dropdown",
        label: "Change Type",
        defaultValue: RISK_CHANGE_TYPE.MANUAL_ENTRY,
        dropdownConfig: {
          data: [
            {
              text: "Manual Entry",
              value: RISK_CHANGE_TYPE.MANUAL_ENTRY,
            },
            {
              text: "Risk Control",
              value: RISK_CHANGE_TYPE.ENTERPRISE_CONTROL,
            },
          ],
        },
      },
      riskControl: {
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: (
            <RecommendationMonteCarlo
              isNonStandardOnly={!isNullOrUndefined(system)}
              selectedSystemID={system?.id}
              organizationID={organizationID}
              riskRecommendationData={riskRecommendationData}
              onInvalidInputChange={(isInvalid) => onInvalidInputChange({ isInvalid, type: formHook?.input?.type })}
              showOverridesButton={showOverridesButton}
              disableSimulationMessage={disableSimulationMessage}
            />
          ),
        },
      },
    },
    externalFormInvalid: riskControlInputInvalid,
    updateInputFunction,
    ...props,
    showOverridesButton: false, // forcing overrides off with this form. need to add override to date field by itself
  });

  // Handles the custom data for risk recommendations
  useEffect(() => {
    onInvalidInputChange({ type: formHook?.input?.type });
    if (formHook?.input?.type === RISK_CHANGE_TYPE.ENTERPRISE_CONTROL) {
      formHook.setFields((fields) => {
        return [...fields, "riskControl"];
      });
    } else if (formHook?.input?.type === RISK_CHANGE_TYPE.NON_STANDARD_CONTROL) {
      formHook.setFields((fields) => {
        return [...fields, "riskControl"];
      });
    } else if (formHook?.input?.type === RISK_CHANGE_TYPE.MANUAL_ENTRY) {
      formHook.setFields((fields) => {
        //filter out risk control field
        return fields.filter((field) => field !== "riskControl");
      });
    }
  }, [formHook?.input?.type]);

  useEffect(() => {
    if (!isNullOrUndefined(system)) {
      formHook.setInput((input) => {
        return {
          ...input,
          type: RISK_CHANGE_TYPE.ENTERPRISE_CONTROL,
        };
      });
    }
  }, [system]);

  const display = (
    <>
      {pleaseWaitModal.modal}
      {formHook.display}
    </>
  );

  return { ...formHook, ...riskRecommendationData, display };
};
