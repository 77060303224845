import React from "react";

import { resources } from "@rivial-security/role-utils";

import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import ActionDataGrid from "../../Actions/components/ActionDataGrid";
import CreateAction from "../../Actions/components/CreateAction";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import { useActionDataGrid } from "../../Actions/hooks/useActionDataGrid";
import { deleteRecommendationActionLink } from "../functions/deleteRecommendationActionLink";

/**
 * Displays Action items for a recommendation
 * @param {object} item - the recommendation item to pull action items for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const RecommendationActionItems = ({ item, organizationID }) => {
  const nestedDataCard = useNestedDataCard({
    item,
    field: "actionItems",
    connectionField: "action",
    typename: resources.ACTION_ITEM,
    parentTypename: resources.RECOMMENDATION,
    grid: <ActionDataGrid />,
    gridHook: useActionDataGrid,
    form: <CreateAction />,
    createFunction: createRecommendationActionItemLink,
    deleteFunction: deleteRecommendationActionLink,
    flipCreateParams: true,
    organizationID,
  });

  return nestedDataCard.gridDisplay;
};

export default withOrganizationCheck(RecommendationActionItems);
