import {
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useMenu } from "../../../hooks/views/useMenu";
import OrganizationCheck from "../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { ListQuery } from "../../../utils/Functions/Graphql/ListQuery";

import { convertMetricData } from "./functions/convertMetricData";

/**
 * @author Jacob Blazina
 * @description A SyncFusion Line Chart to display historical numerical data.
 * @param {object} metricType - The MetricType object to display historical data for
 * @param organizationID
 * @returns {*}
 * @constructor
 */
const MetricLineChartBody = ({ metricType, organizationID, disableMenu }) => {
  const [data, setData] = useState([]);

  const [selectedMetricTypes, setSelectedMetricTypes] = useState([]);

  const [metricTypes, setMetricTypes] = useState([]);

  useEffect(() => {
    const { listQuery } = generateGraphql("MetricType", ["name"]);
    ListQuery({ organizationID, query: listQuery }).then((data) => {
      setMetricTypes([...data]);
    });
  }, []);

  useEffect(() => {
    if (metricType) {
      setSelectedMetricTypes([metricType]);
    }
  }, [metricType]);

  useEffect(() => {
    if (selectedMetricTypes && Array.isArray(selectedMetricTypes)) {
      handleData(selectedMetricTypes);
    }
  }, [selectedMetricTypes]);

  const handleData = async (selectedMetricTypes) => {
    const arr = [];
    for (const selectedMetricType of selectedMetricTypes) {
      const res = await convertMetricData(selectedMetricType);
      arr.push({
        data: res,
        name: selectedMetricType.name,
        id: selectedMetricType.id,
      });
    }
    setData([...arr]);
  };

  useEffect(() => {
    if (metricTypes) {
      menuHook.setMenuItems([
        {
          items: [
            ...metricTypes.map((item) => {
              return {
                id: item.id,
                text: item.name,
                iconCss: selectedMetricTypes.find((i) => i.id === item.id) ? "icon-check text-success" : undefined,
              };
            }),
          ],
          iconCss: "icon-list",
        },
      ]);
    }
  }, [metricTypes, selectedMetricTypes]);

  const primaryXAxis = { valueType: "Category" };

  const menuHook = useMenu({
    onClick: (item) => {
      setSelectedMetricTypes((selectedMetricTypes) => [...selectedMetricTypes, item]);
    },
  });

  const [uuid] = useState(uuidv4());

  return (
    <div key={`chartContainer${uuid}`}>
      {!disableMenu && (
        <span id={`span${uuid}`} key={`span${uuid}`}>
          {menuHook.display}
        </span>
      )}
      {metricType ? (
        <ChartComponent
          id={`chart${metricType.id}${uuid}`}
          key={`chart${metricType.id}${uuid}`}
          primaryXAxis={primaryXAxis}
          title={metricType.name}
          width="100%"
          height="95%"
        >
          <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective>
            {data.map((item) => (
              <SeriesDirective
                id={item.id}
                key={`series${item.id}`}
                dataSource={item.data}
                xName="date"
                yName="value"
                name={item.name}
              />
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      ) : (
        "There is not enough data to generate this Chart"
      )}
    </div>
  );
};

const MetricLineChart = (props) => {
  return (
    <OrganizationCheck {...props}>
      <MetricLineChartBody />
    </OrganizationCheck>
  );
};

export default MetricLineChart;
