import { API, graphqlOperation } from "@aws-amplify/api";

import { ErrorLogger } from "../../EventLogger";

/**
 * @function
 * @description Get item query function
 * @param query {string}
 * @param id {string}
 * @returns {Promise<any>}
 */

export const ItemQuery = async (query, id) => {
  return await API.graphql(graphqlOperation(query, { id }))
    .then(({ data }) => {
      return data && data[Object.keys(data)[0]];
    })
    .catch((err) => ErrorLogger(`Error: Query ${JSON.stringify(err)}`));
};

/**
 * @function
 * @description Get list of items
 * @param query {string}
 * @param organizationID {string}
 * @param limit {number}
 * @returns {Promise<*|void>}
 * @constructor
 */

export const ListQuery = async (query, organizationID, limit = 100) => {
  return await API.graphql(
    graphqlOperation(query, {
      limit,
      filter: { ownerGroup: { eq: organizationID } },
    }),
  )
    .then(({ data }) => {
      return data;
    })
    .catch((err) => ErrorLogger(`Error: Query ${JSON.stringify(err)}`));
};
