"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractHTMLContent = void 0;
/**
 * @description Get text from an HTML tag
 * @param s
 * @returns {string}
 */
const extractHTMLContent = (s) => {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
};
exports.extractHTMLContent = extractHTMLContent;
