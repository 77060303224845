"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNestedField = void 0;
const aggregationFunctions_1 = require("./aggregationFunctions");
const getNestedItems_1 = require("./getNestedItems");
/**
 * Extracts deeply nested info from an object or array using the resource path defined by the user
 * @param {object} element - the element to transform
 * @param {object} params - the parameters for the function, see ResourcePath definition for path property details
 * @return {*} - array OR single item depending on whether the path contains a hasMany field and whether an aggregation function is selected
 */
const getNestedField = ({ element, params }) => {
    //Check arguments
    const { path, aggregationFunction: aggregationFunctionName } = params || {};
    if (!Array.isArray(path)) {
        return null;
    }
    //Evaluate nested field
    const hasManyPresent = path?.some((pathElement) => pathElement?.hasMany);
    const items = (0, getNestedItems_1.getNestedItems)({ element, params: { ...params, path } });
    //Determine the dynamic return value
    let result = null;
    if (Array.isArray(items)) {
        //When no has many is present but multiple items are returned the evaluation is wrong
        if (!hasManyPresent && items.length > 1) {
            result = null;
        }
        //When has many is present and only one item is returned the evaluation is correct, grab the first item
        else if (!hasManyPresent && items.length === 0) {
            result = items[0];
        }
        //Otherwise return the array of items
        else {
            result = items;
        }
    }
    //When has many is present in the path check if an aggregation function is selected
    if (typeof aggregationFunctionName === "string") {
        const aggregationFunction = aggregationFunctions_1.aggregationFunctions[aggregationFunctionName];
        if (typeof aggregationFunction === "function") {
            result = aggregationFunction({ items: result });
        }
    }
    return result;
};
exports.getNestedField = getNestedField;
