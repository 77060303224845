import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useUpstreamProcedureChanges } from "../hooks/useUpstreamProcedureChanges";

/**
 * A component instance of the useUpstreamProcedureChanges.js hook
 * @param props
 */
const UpstreamProcedureChanges = (props) => {
  return useUpstreamProcedureChanges(props).display;
};

export default withOrganizationCheck(UpstreamProcedureChanges);
