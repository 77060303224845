"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskScores_businessRisk = void 0;
const getRating_KRI_1 = require("./getRating_KRI");
const getRiskAmount_KRI_1 = require("./getRiskAmount_KRI");
/**
 * Gets the Risk Score for a particular Business risk
 *
 * @param {Object} businessRisk - Required
 * @param {Object} businessRisk.name
 * @param {Object} system - Required
 * @param {Object} system.risks
 * @param {Object} system.risks.items
 * @param {Object} system.risks.items[].riskMapping
 * @param {Object} system.risks.items[].risk
 * @param {Object} system.risks.items[].risk.name
 * @param {Object} enterpriseRisk - Required
 * @param {Object} monteCarloResults - Required
 * @param {Object} [ratingScale] - Optional
 */
const getRiskScores_businessRisk = async ({ businessRisk, system, monteCarloResults, ratingScale }) => {
    const name = businessRisk.name;
    let greatestKeyRiskIndicator = {};
    const keyRiskIndicators = [];
    let residualRisk = 0;
    let inherentRisk = 0;
    let greatestInherentKeyRiskIndicator = null;
    let riskRating = "No Risk";
    const id = JSON.stringify(businessRisk);
    const systemRisks = [];
    const systemRiskLinks = system?.risks?.items;
    // Check if there are risk links, can't find rating without risks
    if (!Array.isArray(systemRiskLinks)) {
        throw Error("Error [getRiskScores_businessRisk.js]: Could not calculate Risk Ratings because System doesn't have Risks linked!");
    }
    // Gets Risks from this System (only if they are mapped to this businessRisk)
    for (const systemRiskLink of systemRiskLinks) {
        const riskMapping = systemRiskLink.riskMapping;
        if (riskMapping && riskMapping[businessRisk.name]) {
            systemRisks.push(systemRiskLink.risk);
        }
    }
    // For each System Risk, format object with name, riskRating, inherentRisk, and residualRisk
    for (const keyRiskIndicator of systemRisks) {
        keyRiskIndicators.push({
            name: keyRiskIndicator.name,
            riskRating: (0, getRating_KRI_1.getRating_KRI)({
                risk: keyRiskIndicator,
                monteCarloResults,
                ratingScale,
            }),
            residualRisk: (0, getRiskAmount_KRI_1.getRiskAmount_KRI)({ risk: keyRiskIndicator, monteCarloResults, field: "residualRisk" }),
            inherentRisk: (0, getRiskAmount_KRI_1.getRiskAmount_KRI)({ risk: keyRiskIndicator, monteCarloResults, field: "inherentRisk" }),
        });
    }
    // Get highest residualRisk value
    const highestResidualRisk = Math.max.apply(Math, keyRiskIndicators.map((o) => {
        return o.residualRisk;
    }));
    const highestInherentRisk = Math.max.apply(Math, keyRiskIndicators.map((o) => {
        return o.inherentRisk;
    }));
    // Get the Key Risk Indicator with the highest residualRisk value
    const keyRiskIndicatorWithGreatestRisk = keyRiskIndicators.find((o) => {
        return o.residualRisk === highestResidualRisk;
    });
    // Get the Key Risk Indicator with the highest inherentRisk value
    const keyRiskIndicatorWithGreatestInherentRisk = keyRiskIndicators.find((o) => {
        return o.inherentRisk === highestInherentRisk;
    });
    // Set the businessRisk property values
    if (keyRiskIndicatorWithGreatestRisk) {
        greatestKeyRiskIndicator = keyRiskIndicatorWithGreatestRisk;
        residualRisk = keyRiskIndicatorWithGreatestRisk.residualRisk;
        inherentRisk = keyRiskIndicatorWithGreatestRisk.inherentRisk;
        riskRating = keyRiskIndicatorWithGreatestRisk.riskRating;
    }
    if (keyRiskIndicatorWithGreatestInherentRisk) {
        greatestInherentKeyRiskIndicator = keyRiskIndicatorWithGreatestInherentRisk;
    }
    return {
        id,
        name,
        greatestKeyRiskIndicator,
        keyRiskIndicators,
        residualRisk,
        inherentRisk,
        riskRating,
        greatestInherentKeyRiskIndicator,
    };
};
exports.getRiskScores_businessRisk = getRiskScores_businessRisk;
