import { checkModulePermissions } from "@application/Layout/hooks/useNavigationLinks/functions/checkModulePermissions";
import { NavigationRoute } from "@application/Navigation/types";

export interface CheckRouteRequiredModulesEnabledParams {
  route: NavigationRoute;
  roleConfig: Record<string, unknown>;
}

export const checkRouteRequiredModulesEnabled = ({
  route,
  roleConfig,
}: CheckRouteRequiredModulesEnabledParams): boolean => {
  return (
    route?.requiredModules?.every((moduleName) =>
      checkModulePermissions({ navigationModule: { moduleName }, roleConfig }),
    ) ?? true
  );
};
