export const playbookDefinition = {
  routes: {
    grid: "response/plan",
    details: "response/playbooks",
  },

  schema: {
    indexNames: {
      byOwnerGroup: "playbooksByOwnerGroup",
    },
  },

  fields: {
    name: {},
    description: {},
  },

  views: {
    grid: {
      queryFields: ["name", "description"],
    },
  },
};
