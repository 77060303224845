import { makeStyles } from "@mui/styles";
import React, { useMemo, useRef } from "react";

import { useElementSize } from "../../../hooks/functional/useElementSize";

import OverflowMenu from "./OverflowMenu";

/**
 * Displays a Toolbar with menu buttons that go into an overflow menu as the toolbar shrinks
 *
 * Note: Assumes all buttons are around 100px in width
 *
 * @example
 * <OverflowToolbar
 *  items={[
 *    <Button1/>,
 *    <Button2/>,
 *    <Button3/>
 *  ]}
 * />
 *
 * @param {ReactNode} item - The items to be displayed in the toolbar
 * @param {number} buttonSize - the estimated size of each button. Larger buttons
 * @param {number} overlapSize - the threshold at which a button gets put into the overflow menu. Bigger numbers mean the button will be in the overflow menu sooner
 * @returns {JSX.Element} - A toolbar with an overflow menu
 */
const OverflowToolbar = ({ items = [], buttonSize = 120, overlapSize = 60 }) => {
  const classes = useStyles();

  const ref = useRef(null);

  const { width } = useElementSize({ ref });

  // Holds the array of buttons for each side of the toolbar
  const [toolbarItems, overflowItems] = useMemo(() => {
    // These buttons go on the toolbar
    const toolbar = [];

    // These buttons go in the overflow menu
    const overflow = [];

    let spaceLeft = width;

    // Note: we are assuming each button is around 100px
    for (const item of items) {
      // Push into the toolbar menu
      // Allowing a bit of overlap before the button goes into the overflow menu
      if (spaceLeft >= overlapSize) {
        toolbar.push(item);

        // Subtracting 100px for each button
        spaceLeft = spaceLeft - buttonSize;
      }
      // Push the rest into the overflow menu
      else {
        overflow.push(item);
      }
    }

    return [toolbar, overflow];
  }, [items, width]);

  return (
    <div className={classes.toolbarWrapper} ref={ref}>
      <span
        style={{
          width: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "flex",
          gap: "10px",
        }}
      >
        {toolbarItems &&
          toolbarItems.map(
            (item, index) =>
              React.isValidElement(item) &&
              React.cloneElement(item, {
                key: `toolbar-item${index}`,
              }),
          )}
      </span>
      {Array.isArray(overflowItems) && overflowItems.length > 0 && (
        <OverflowMenu className={classes.overflowStyle}>{overflowItems}</OverflowMenu>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 0,
    display: "flex",
  },
  toolbarWrapper: {
    display: "flex",
    overflow: "hidden",
    flex: "0 0 auto",
  },
  overflowStyle: {
    order: 99,
    position: "sticky",
    right: "0",

    // trying to do a linear opacity gradient from left to right, doesn't seem to be working though.
    background: "linear-gradient(0.25turn, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1.0));",
  },
}));

export default OverflowToolbar;
