import { precedenceTypes as PRECEDENCE_TYPES } from "@rivial-security/role-utils";

/**
 * Returns a string explaining the use case behind a certain Role Type (precedence)
 * @param precedence
 * @returns {string}
 */
export const getPrecedenceDescription = (precedence) => {
  switch (precedence) {
    case PRECEDENCE_TYPES.USER:
      return "A basic User role. Can not have permissions related to Organization Management such as Points of Contact and Roles.";
    case PRECEDENCE_TYPES.SUPERVISOR:
      return "Users that are allowed to have permissions related to Point of Contact / User management.";
    case PRECEDENCE_TYPES.MANAGER:
      return "Super Users that have full access to Organization resources including Role and User management.";
    case PRECEDENCE_TYPES.ADMIN:
      return "Rivial Admins that have access to all Resources in multiple Organizations";
    case PRECEDENCE_TYPES.DEVELOPER:
      return "Rivial Developers that have the same access as Admins, but with some extra developer-only powers";
    default:
      return precedence;
  }
};
