import { useEffect, useState } from "react";

//REFERENCE: partially adapted from https://usehooks-typescript.com/react-hook/use-element-size

/**
 * @description This hook allows you to get the dimensions of a component after it has been rendered
 * @param {object} ref - react reference from useRef to the UI component
 * @return {object} - object with width and height properties
 * @example const [width, height] = useElementSize({ref}); // width = 100 and height = 100
 */
export const useElementSize = ({ ref }) => {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const resizeObserver = new ResizeObserver((entries) => updateSize());

  const updateSize = () => {
    if (ref?.current) {
      setWidth(ref.current?.offsetWidth);
      setHeight(ref.current?.offsetHeight);
    } else {
      setWidth(null);
      setHeight(null);
    }
  };

  useEffect(() => {
    if (ref?.current) {
      resizeObserver.observe(ref?.current);
    }
    return () => {
      if (ref?.current) {
        resizeObserver.unobserve(ref?.current);
      }
    };
  }, [ref]);

  return {
    width,
    height,
  };
};
