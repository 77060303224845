import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useObservationDataGrid } from "../../Observations/hooks/useObservationDataGrid";
import { linkSourceToObservations } from "../functions/linkSourceToObservations";

/**
 * Hook for creating a new Source object in the database
 * @param organizationID
 * @param getNewItem
 * @returns {{display: *, input: {}}}
 */
export const useCreateSource = ({ organizationID, getNewItem, ...config }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.SOURCE;
  const typename = resources.SOURCE;
  const fields = ["name", "description", "date", "organization", "framework"];

  const observationGrid = useObservationDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
  });
  const selectedObservations = observationGrid?.selectedData;

  const { createMutation } = generateGraphql(typename, fields);

  /**
   * Callback function from the form hook to attach source to selected observations
   * @param {object} sourceItem - the source item that was created
   */
  const callback = (sourceItem) => {
    linkSourceToObservations({
      source: sourceItem,
      selectedObservations,
      organizationID,
      typename,
    });
  };

  const form = useForm({
    typename,
    header: "Add an External Source",
    organizationID,
    getNewItem,
    fieldConfig: {
      name: {
        label: "Name",
        tooltip: "A descriptive name for this Source. E.g. '2020 Self-Assessment'",
        required: true,
      },
      description: {
        label: "Description",
        tooltip: "Optional",
        inputType: "textarea",
      },
      organization: {
        label: "Organization",
        tooltip: "Is this Source related to a particular institution like the NCUA?",
      },
      framework: {
        label: "Framework",
        tooltip: "Is this Source related to a particular control framework like ACET?",
      },
      date: {
        label: "Date",
        tooltip: "Is there a relevant date to go along with this Source?",
        inputType: "date",
        defaultValue: new Date(),
      },
      observations: {
        label: "Link Observations",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: (
            <div
              style={{
                height: "30em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {observationGrid?.display}
            </div>
          ),
        },
        tooltip: "Select one or multiple Observations to link this Source to.",
      },
    },
    callback,
    module,
    resource,
    mutation: createMutation,
    ...config,
  });

  return form;
};
