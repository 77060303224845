import { Alert } from "@mui/material";
import React from "react";

/**
 * Alert for when a template query is being used
 * @returns {JSX.Element}
 * @constructor
 */
export const TemplateQueryAlert = () => {
  return (
    <Alert severity={"info"} style={{ marginBottom: "1em" }}>
      You are currently viewing a Template. Query data is being fetched from other 'Template' resources.
    </Alert>
  );
};
