import React from "react";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

import ToolDashboard from "./ToolDashboard";

/**
 * Displays CustomDashboards that are configured for a Tool
 * @param {object} item - the Tool to display Custom Dashboards for
 * @param {string} organizationID - the organization ID to filter the CustomDashboards by
 * @param {boolean} isTemplate - whether the tool is a template
 * @param toolID
 * @returns {JSX.Element}
 * @constructor
 */
const ToolCustomDashboards = ({ item, organizationID, isTemplate, toolID }) => {
  return (
    <div>
      <ToolDashboard
        item={item}
        itemId={toolID || item?.id}
        organizationID={organizationID}
        isTemplate={isTemplate}
        // this will ensure that the UI will not display the default dashboard since this is a nested component
        disableDefaultDashboard={true}
      />
    </div>
  );
};

export default withOrganizationCheck(ToolCustomDashboards);
