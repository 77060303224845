import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

/**
 * A form input allowing user to enter multiple comma seperated strings
 * @param {string[]} value - the state value of the input, should change input if changed from above
 * @param {function} onChange - callback when data changes in the input
 * @param {string} fieldName - the field name where the input is going to be stored
 * @param {string} placeholder - input hint text for new values
 * @return {JSX.Element}
 */
const CustomStringArrayInput = ({ value, onChange, fieldName, placeholder }) => {
  const [baseValue, setBaseValue] = useState(value || []);
  const [inputValue, setInputValue] = useState(value ? value.join(",") : "");

  useEffect(() => {
    if (JSON.stringify(baseValue) !== JSON.stringify(value)) {
      setBaseValue(value || []);
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      freeSolo
      id={`${fieldName}-string-array-input`}
      options={[]}
      value={baseValue}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        setBaseValue(newValue);
        if (typeof onChange === "function") {
          onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        const options = newInputValue.split(",");

        if (options.length > 1) {
          const newValue = baseValue
            .concat(options)
            .map((x) => x.trim())
            .filter((x) => x);
          setBaseValue(newValue);
          setInputValue("");
          if (typeof onChange === "function") {
            onChange(newValue);
          }
        } else {
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  );
};

export default CustomStringArrayInput;
