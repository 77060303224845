import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import { Inject } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";

import { formattedPercent, isNullOrUndefined } from "@rivial-security/func-utils";

import { useUUID } from "../../../../hooks/functional/useUUID";
import { useAutoUpdateForm } from "../../../../hooks/views/useAutoUpdateForm";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * Displays the Status of Controls in an Audit as a Pie Chart
 * @param {object} item - the Audit item from the useAuditDetails hook
 * @param {object} item.controls - the Audit item 'controls' field (m-m nested field)
 * @param {object[]} item.controls.items - the AuditControlLinks array
 * @param {object} item.controls.items[].control - the associated Control for an AuditControlLink
 * @param {boolean} item.controls.items[].isCompliant - isCompliant field for an AuditControlLink
 * @returns {{fetchData: fetchData, data: null, setData: React.Dispatch<React.SetStateAction<null>>, display: JSX.Element}}
 */
export const useAuditControlStatusChart = ({ item }) => {
  const [data, setData] = useState(null);

  /**
   * Form used to auto-update the chart data
   */
  const autoUpdateForm = useAutoUpdateForm({
    label: "Auto-Update",
    timeout: 10000,
  });

  /**
   * Fetches the data for the pie chart
   */
  const fetchData = () => {
    if (!isNullOrUndefined(item) && Array.isArray(item?.controls?.items)) {
      const res = [];

      const total = item.controls.items.length;

      let numCompliant = 0;
      let numNotCompliant = 0;
      let numNA = 0;

      for (const control of item.controls.items) {
        if (control.isCompliant === true) numCompliant++;
        else if (control.isCompliant === false) numNotCompliant++;
        else numNA++;
      }

      const series1 = {
        id: "number_compliant",
        x: `Compliant`,
        y: numCompliant,
        text: `${formattedPercent(numCompliant / total)}`,
        fill: "green",
      };

      const series2 = {
        id: "number_not_compliant",
        x: `Not Compliant`,
        y: numNotCompliant,
        text: `${formattedPercent(numNotCompliant / total)}`,
        fill: "red",
      };

      const series3 = {
        id: "number_na",
        x: `N/A`,
        y: numNA,
        text: `${formattedPercent(numNA / total)}`,
        fill: "grey",
      };

      numCompliant > 0 && res.push(series1);
      numNotCompliant > 0 && res.push(series2);
      numNA > 0 && res.push(series3);

      setData(res);
    }
  };

  /**
   * Fetches data on first render, and then every time the refreshToken changes
   */
  useEffect(() => {
    fetchData();
  }, [autoUpdateForm.refreshToken]);

  const [uuid] = useUUID();

  const display = (
    <span style={{ height: "100%" }}>
      {data ? (
        <AccumulationChartComponent
          id={`testing-pie-chart-${uuid}`}
          legendSettings={{
            visible: true,
            position: "Top",
            enabled: false,
            toggleVisibility: false,
          }}
          margin={{ top: 0, bottom: 0 }}
          enableSmartLabels={true}
          tooltip={{ enable: true }}
          enableAnimation={true}
          enableBorderOnMouseMove={true}
          highlightPattern="pacman"
        >
          <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              name="Project"
              dataSource={data}
              xName="x"
              yName="y"
              innerRadius="40%"
              startAngle={0}
              endAngle={360}
              radius="70%"
              explode={true}
              explodeOffset="10%"
              explodeIndex={3}
              pointColorMapping="fill"
              dataLabel={{
                visible: true,
                name: "text",
                position: "Inside",
                font: {
                  fontWeight: "600",
                  color: "#ffffff",
                },
              }}
              animation={{ enable: false }}
            ></AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      ) : (
        <LoadingSpinner loadingMessage="Loading Data.. " />
      )}
      {data && (
        <div
          className={"float-left"}
          style={{ marginTop: "-2em" }}
          title={
            "Automatically refreshes the chart data every 10 seconds. Disable this if you are experiencing performance issues."
          }
        >
          {autoUpdateForm.display}
        </div>
      )}
    </span>
  );

  return {
    display,
    data,
    setData,
    fetchData,
  };
};
