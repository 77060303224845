"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRivialColors = void 0;
const shuffleArray_1 = require("../Array/shuffleArray");
/**
 * This function returns an array of colors that are used in the rivial-information-security-center.
 * @param {boolean} shuffle - A boolean that determines if the colors are shuffled or not.
 * @returns {string[]|*} - An array of colors that are used in the rivial-information-security-center.
 */
const getRivialColors = (shuffle = false) => {
    const colors = [
        "#50a9cc", // primary
        "#2c4c93", // primary
        "#4a9977", // secondary
        "#50576b", // primary
        "#37758f", // secondary
        "#25556a", // secondary
        "#0f2634", // secondary
    ];
    if (shuffle) {
        return (0, shuffleArray_1.shuffleArray)(colors);
    }
    else {
        return colors;
    }
};
exports.getRivialColors = getRivialColors;
