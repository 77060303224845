export const listVendorReviewsByOwnerGroup = /* GraphQL */ `
  query VendorReviewsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorReviewsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        rating
        completedBy
        status
      }
      nextToken
    }
  }
`;
