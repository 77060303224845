import React, { useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import useDidMountEffect from "@hooks/functional/useDidMountEffect";
import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { emptyPlaceholder } from "@utils/GenericComponents/Placeholders";
import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";
import { numberFormatTypes } from "@views/CustomResources/CustomResourceFields/constants/numberFormatTypes";

import EnabledDisabledButtonGroupField from "../../../../../../utils/CustomFields/EnabledDisabledButtonGroupField";
import GenericEditFieldV3 from "../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

import { updateCustomFieldInput } from "./updateCustomFieldInput";

/**
 * Shows a single custom field that is listed for a Custom Resource Entry
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} config - object with at least two properties name and type to show the custom field
 * @param {string|boolean|number} data - serves as the initial value for the custom field
 * @param {object} item - the full control information
 * @param {function} updateItemById - gets called when a custom field has been updated
 * @param {boolean} inGrid - TRUE if inside a grid
 * @param {boolean} readOnly - if TRUE will disable edits
 * @param {function} submitFunction - passed to generic edit field
 * @param {boolean} defaultOpen - if TRUE will default to open
 * @param {string} typename - the typename of the item being edited, could be Control or CustomResourceEntry
 * @return {JSX.Element}
 */
const CustomFieldEditor = ({
  module,
  resource,
  disableRoleChecking = false,
  config,
  data,
  item,
  updateItemById,
  inGrid,
  readOnly,
  submitFunction,
  defaultOpen,
  typename = "Control",
}) => {
  // [QUERY SETUP]
  const { updateMutation } = generateGraphql(typename, ["customFieldData"]);

  // Manage the custom field value state
  const getCustomFieldData = () => {
    if (!item?.id) {
      return undefined;
    }

    //Determine custom field value depending on provided info
    let customFieldData;
    if (!isNullOrUndefined(data)) {
      // - data param takes priority
      customFieldData = data;
    } else if (config?.name && item && item.hasOwnProperty(config.name)) {
      // - fallback info is within item
      customFieldData = item[config.name];
    }

    return { id: item.id, customFieldData };
  };
  const [customFieldItem, setCustomFieldItem] = useState(getCustomFieldData());
  useDidMountEffect(() => {
    setCustomFieldItem(getCustomFieldData());
  }, [data, item]);

  // get custom dropdown options to pass to GenericEditFieldV3 inputConfig
  const customDropdownOptions = config?.options?.map((option) => {
    return { value: option?.value, text: option?.label };
  });

  const customMultipleSelectOptions = config?.multipleSelect?.map((option) => {
    return { key: option?.value, label: option?.label };
  });

  /**
   * Because the way custom dynamic fields are displayed in details and grid component differs
   * the grid update item function need to change
   * @param {function} updatedItem
   */
  const updateItemByIdGrid = (updatedItem) => {
    if (updatedItem?.customFieldData) {
      const newData = JSON.parse(updatedItem?.customFieldData);
      if (newData && config?.name && newData.hasOwnProperty(config?.name)) {
        updatedItem[config.name] = newData[config.name];
        updateItemById?.(updatedItem);
      }
    }
  };

  if (config?.type === CustomFieldType.BOOLEAN) {
    return (
      <EnabledDisabledButtonGroupField
        config={{
          typename,
          module,
          resource,
          disableRoleChecking,
          field: "customFieldData",
          updateInputFunction: async (value) => {
            return await updateCustomFieldInput({
              value,
              itemId: item?.id,
              fieldName: config?.name,
              typename,
            });
          },
          updateItemById: inGrid ? updateItemByIdGrid : updateItemById,
          rightOffText: "False",
          leftOnText: "True",
        }}
        item={customFieldItem}
        submitFunction={submitFunction}
        defaultOpen={defaultOpen}
      />
    );
  } else if (config?.type === CustomFieldType.ENUM) {
    return (
      <GenericEditFieldV3
        inputType={GENERIC_FIELD_TYPES.DROPDOWN}
        item={customFieldItem}
        inputConfig={{
          data: customDropdownOptions,
        }}
        displayConfig={{
          customFormat: (value) => {
            const foundOption = customDropdownOptions?.find((option) => {
              return option?.value === value;
            });
            return foundOption?.text || emptyPlaceholder;
          },
          forceSentenceCase: false,
        }}
        field={"customFieldData"}
        mutation={updateMutation}
        updateItemById={updateItemById}
        resource={resource}
        module={module}
        disableRoleChecking={disableRoleChecking}
        updateInputOnlyForMutation={true}
        updateInputFunction={async (value) => {
          return await updateCustomFieldInput({
            value,
            itemId: item?.id,
            fieldName: config?.name,
            typename,
          });
        }}
      />
    );
  } else if (config?.type === CustomFieldType.MULTIPLE_SELECT) {
    return (
      <GenericEditFieldV3
        inputType={GENERIC_FIELD_TYPES.CHECKBOXES}
        item={customFieldItem}
        inputConfig={{
          data: customMultipleSelectOptions,
        }}
        displayConfig={{
          customFormat: (value) => {
            return customMultipleSelectOptions
              .filter((option) => value.includes(option.key))
              .map((option) => option.label)
              .join(", ");
          },
          forceSentenceCase: false,
        }}
        field={"customFieldData"}
        mutation={updateMutation}
        updateItemById={updateItemById}
        resource={resource}
        module={module}
        disableRoleChecking={disableRoleChecking}
        updateInputOnlyForMutation={true}
        updateInputFunction={async (value) => {
          return await updateCustomFieldInput({
            value,
            itemId: item?.id,
            fieldName: config?.name,
            typename,
          });
        }}
      />
    );
  } else if (config?.type === CustomFieldType.DATE) {
    return (
      <GenericEditFieldV3
        inputType={GENERIC_FIELD_TYPES.DATE}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        field={"customFieldData"}
        item={customFieldItem}
        mutation={updateMutation}
        updateInputOnlyForMutation={true}
        updateInputFunction={async (value) => {
          return await updateCustomFieldInput({
            value,
            itemId: item?.id,
            fieldName: config?.name,
            typename,
          });
        }}
        updateItemById={inGrid ? updateItemByIdGrid : updateItemById}
        disableEdits={readOnly}
        submitFunction={submitFunction}
        defaultOpen={defaultOpen}
      />
    );
  } else if (config?.type === CustomFieldType.NUMBER) {
    const { format } = config?.numberSettings || {};
    let inputType = GENERIC_FIELD_TYPES.NUMERIC;
    if (format === numberFormatTypes.CURRENCY) {
      inputType = GENERIC_FIELD_TYPES.DOLLAR;
    } else if (format === numberFormatTypes.PERCENT) {
      inputType = GENERIC_FIELD_TYPES.PERCENT;
    }

    return (
      <GenericEditFieldV3
        inputType={inputType}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        field={"customFieldData"}
        item={customFieldItem}
        mutation={updateMutation}
        updateInputOnlyForMutation={true}
        updateInputFunction={async (value) => {
          return await updateCustomFieldInput({
            value,
            itemId: item?.id,
            fieldName: config?.name,
            typename,
          });
        }}
        inputConfig={{
          ...(config?.type === CustomFieldType.NUMBER ? config?.numberSettings || {} : {}),
        }}
        updateItemById={inGrid ? updateItemByIdGrid : updateItemById}
        disableEdits={readOnly}
        submitFunction={submitFunction}
        defaultOpen={defaultOpen}
      />
    );
  } else {
    return (
      <GenericEditFieldV3
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        field={"customFieldData"}
        item={customFieldItem}
        mutation={updateMutation}
        updateInputOnlyForMutation={true}
        updateInputFunction={async (value) => {
          return await updateCustomFieldInput({
            value,
            itemId: item?.id,
            fieldName: config?.name,
            typename,
          });
        }}
        updateItemById={inGrid ? updateItemByIdGrid : updateItemById}
        disableEdits={readOnly}
        submitFunction={submitFunction}
        defaultOpen={defaultOpen}
      />
    );
  }
};

export default CustomFieldEditor;
