import { Icon } from "@iconify/react";
import Assessment from "@mui/icons-material/Assessment";
import React from "react";

import { vulnerabilityDefinition } from "../../../../definitions/constants/platform/testing/vulnerability";
import { TargetVulnerabilityHistoryType } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";

/**
 * Renders the timeline dot based on the TargetVulnerabilityHistoryType
 * @param {string} type - the TargetVulnerabilityHistory type
 */
export const getTimelineDotIcon = ({ type }) => {
  switch (type) {
    case TargetVulnerabilityHistoryType.INITIAL:
      return getIcon(vulnerabilityDefinition.icon);
    case TargetVulnerabilityHistoryType.STATUS:
      return <Icon icon="fluent:status-16-filled" width="22" height="22" />;
    case TargetVulnerabilityHistoryType.FALSE_POSITIVE:
      return <Assessment />;
    case TargetVulnerabilityHistoryType.PRIORITY:
      return <Icon icon="ant-design:calendar-filled" width="22" height="22" />;
    case TargetVulnerabilityHistoryType.TAG:
      return <Icon icon="ic:baseline-change-circle" width="22" height="22" />;
    case TargetVulnerabilityHistoryType.ASSESSMENT:
      return <Assessment />;
  }
};
