"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSecret = void 0;
const client_secrets_manager_1 = require("@aws-sdk/client-secrets-manager");
/**
 * @description Creates a new secret. A secret is a set of credentials, such as a user name and password, that you store in an encrypted form in Secrets Manager.
 * @param {string} name - the name of the new secret
 * @param {string} secretString - the text data to encrypt and store in this new version of the secret. We recommend you use a JSON structure of key/value pairs for your secret value.
 * @param {string} description - the description of the secret.
 * @param {string} clientRequestToken - Secrets Manager creates an initial version for the secret, and this parameter specifies the unique identifier for the new version.
 * @returns {Promise<SecretsManager.CreateSecretResponse & {$response: Response<SecretsManager.CreateSecretResponse, Error & {code: string, message: string, retryable?: boolean, statusCode?: number, time: Date, hostname?: string, region?: string, retryDelay?: number, requestId?: string, extendedRequestId?: string, cfId?: string, originalError?: Error}>}>}
 */
const createSecret = async ({ name, secretString, description, clientRequestToken }) => {
    const secretsManager = new client_secrets_manager_1.SecretsManagerClient({ apiVersion: "2017-10-17" });
    const params = {
        ClientRequestToken: clientRequestToken,
        Description: description,
        Name: name,
        SecretString: secretString,
    };
    const command = new client_secrets_manager_1.CreateSecretCommand(params);
    return await secretsManager.send(command).catch((err) => {
        throw Error(`Could not create secret: ${err}`);
    });
};
exports.createSecret = createSecret;
