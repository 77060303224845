"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openvasScannerFields = void 0;
exports.openvasScannerFields = [
    {
        displayName: "Name",
        resourceFieldName: "name",
        defaultCSVFieldNames: ["NVT Name"],
    },
    {
        displayName: "Type",
        resourceFieldName: "type",
        interpreterDescription: "This field should represent the severity level, if the field value is 'Log' the platform type will be marked as informational.",
    },
    {
        displayName: "Severity Level",
        resourceFieldName: "severityLevel",
        interpreterDescription: "This field should represent the severity level, standard scanner values will be mapped to platform severity levels.",
    },
    {
        displayName: "Port",
        resourceFieldName: "port",
        defaultCSVFieldNames: ["Port"],
    },
    {
        displayName: "Protocol",
        resourceFieldName: "protocol",
        defaultCSVFieldNames: ["Port Protocol"],
    },
    {
        displayName: "CVSS",
        resourceFieldName: "cvss",
        defaultCSVFieldNames: ["CVSS"],
    },
    {
        displayName: "CVE",
        resourceFieldName: "cve",
        defaultCSVFieldNames: ["CVEs"],
    },
    {
        displayName: "NVT OID",
        resourceFieldName: "nvtOID",
        defaultCSVFieldNames: ["NVT OID"],
    },
    {
        displayName: "Impact",
        resourceFieldName: "impact",
        defaultCSVFieldNames: ["Impact"],
    },
    {
        displayName: "Solution",
        resourceFieldName: "solution",
        defaultCSVFieldNames: ["Solution"],
    },
    {
        displayName: "Solution Type",
        resourceFieldName: "solutionType",
        defaultCSVFieldNames: ["Solution Type"],
    },
    {
        displayName: "Summary",
        resourceFieldName: "summary",
        defaultCSVFieldNames: ["Summary"],
    },
    {
        displayName: "IP",
        resourceFieldName: "ip",
        defaultCSVFieldNames: ["IP"],
    },
    {
        displayName: "Host Name",
        resourceFieldName: "hostName",
        defaultCSVFieldNames: ["Hostname"],
    },
];
