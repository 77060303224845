import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../../hooks/views/useForm/useForm";

/**
 * Creates (or Edit) a Response Playbook
 * @param {string} organizationID - this playbook's organizations
 * @param {function} toggleModal - called on pressing submit to close the modal (passed in by useModal)
 * @param {function} resetFunction - function to call to update UI after create or edit operation took place
 * @param {object} [item] - if passed in becomes a edit Response playbook form
 * @returns {{display: *, input: {}}}
 */
export const useCreatePlaybook = ({ organizationID, toggleModal, resetFunction, item }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.PLAYBOOK;
  const typename = "Playbook";
  const fields = ["name"];

  const { createMutation, updateMutation } = generateGraphql(typename, fields);

  const fieldConfig = {
    name: {
      label: "Playbook Name",
      tooltip: "The name of this Response Playbook. Ex: 'Ransomware Attack' ",
      required: true,
    },
    description: {
      label: "Scenario Description",
      inputType: "textarea",
      tooltip:
        "A description of scenario that this playbook will be used for. Ex: 'A hardware level attack on information systems'",
    },
  };

  return useForm({
    item,
    mutation: item ? updateMutation : createMutation,
    organizationID,
    toggleModal,
    resetFunction,

    fieldConfig,

    module,
    resource,
  });
};
