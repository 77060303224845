"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatMetricData = void 0;
const schema_types_1 = require("@rivial-security/schema-types");
/**
 * Formats metric data according to the "format" field
 * @example
 * enum MetricDataFormat {
 *     decimal # ex. "0.1234".
 *     string  # for non-numerical data
 *     dollar  # USD form (with or without the commas and/or dollar sign). ex. "$123.50", "$1,234.00", "12356.50"
 *     percent # if input is already in percentage form (with or without the percent sign). ex. "75%", "36.0"
 *     json    # JSON.stringified, will be JSON.parsed
 * }
 * @param data
 */
const formatMetricData = (data) => {
    const { value, format } = data;
    switch (format) {
        case schema_types_1.MetricDataFormat.DECIMAL:
            return value && parseFloat(value);
        case schema_types_1.MetricDataFormat.STRING:
            return value;
        case schema_types_1.MetricDataFormat.JSON:
            return value && JSON.parse(value);
        default:
            return value;
    }
};
exports.formatMetricData = formatMetricData;
