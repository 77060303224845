import { Icon } from "@iconify/react";
import { Alert } from "@mui/material";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import TabCard from "../../../../../utils/GenericComponents/TabCard";
import MeetingDetails from "../../../../Program/Meetings/components/MeetingDetails";
import PolicyDetails from "../../../../Program/Policies/components/PolicyDetails";
import ReportDetails from "../../../../Reports/ReportDetails";
import KnowBe4PhishingDetails from "../../../../Tools/KnowBe4/Phishing/components/KnowBe4PhishingDetails";
import KnowBe4TrainingDetails from "../../../../Tools/KnowBe4/Training/components/KnowBe4TrainingDetails";

import EvidenceAttestationViewer from "./components/EvidenceAttestationViewer";
import EvidenceExternalURLViewer from "./components/EvidenceExternalURLViewer";
import EvidenceDocumentViewer from "./EvidenceDocumentViewer";

/**
 * Displays all evidence documents under an evidence activity, including other connected resources
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifact - the artifact containing the evidence activity for which to display documents
 * @returns {JSX.Element}
 */
const EvidenceMultiDocumentViewer = ({ organizationID, artifact }) => {
  const documents = artifact?.evidenceActivity?.documents?.items;
  const evidenceActivity = artifact?.evidenceActivity;

  const items = [];

  //Check if evidence activity references any other user interaction or resource
  if (!isNullOrUndefined(evidenceActivity?.attested)) {
    items.push({
      title: "Attestation",
      component: <EvidenceAttestationViewer attestation={evidenceActivity?.attested} />,
      icon: <Icon icon={"akar-icons:person-check"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.externalUrl)) {
    items.push({
      title: "External URL",
      component: <EvidenceExternalURLViewer url={evidenceActivity?.externalUrl} />,
      icon: <Icon icon={"akar-icons:link-chain"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.reportID)) {
    items.push({
      title: "Report",
      component: <ReportDetails />,
      forcedComponentProps: {
        item: { id: evidenceActivity?.reportID },
      },
      icon: <Icon icon={"bxs:report"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.policyID)) {
    items.push({
      title: "Policy",
      component: <PolicyDetails />,
      forcedComponentProps: {
        item: { id: evidenceActivity?.policyID },
      },
      icon: <Icon icon={"carbon:policy"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.meetingID)) {
    items.push({
      title: "Meeting",
      component: <MeetingDetails />,
      forcedComponentProps: {
        item: { id: evidenceActivity?.meetingID },
      },
      icon: <Icon icon={"akar-icons:calendar"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.trainingID)) {
    items.push({
      title: "Training",
      component: <KnowBe4TrainingDetails />,
      forcedComponentProps: {
        item: { id: evidenceActivity?.trainingID },
      },
      icon: <Icon icon={"healthicons:i-training-class"} />,
    });
  }

  if (!isNullOrUndefined(evidenceActivity?.phishingID)) {
    items.push({
      title: "Phishing",
      component: <KnowBe4PhishingDetails item={{ id: evidenceActivity?.phishingID }} />,
      forcedComponentProps: {
        item: { id: evidenceActivity?.phishingID },
      },
      icon: <Icon icon={"ic:baseline-phishing"} />,
    });
  }

  //Add any attached documents as separate tabs
  if (Array.isArray(documents) && documents.length > 0) {
    for (const document of documents) {
      items.push({
        title: document?.name || "Unnamed Document",
        component: <EvidenceDocumentViewer organizationID={organizationID} document={document} />,
        icon: <Icon icon={"material-symbols:file-copy-outline-rounded"} />,
      });
    }
  }
  //Add all document entries and other evidence activity info into a tab card
  if (Array.isArray(items) && items.length > 0) {
    return <TabCard tabDisplay={true} items={items} />;
  } else {
    return <Alert severity="warning">No supporting user inputs found</Alert>;
  }
};

export default EvidenceMultiDocumentViewer;
