"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskScores_businessRisks = void 0;
const getRiskScores_businessRisk_1 = require("./getRiskScores_businessRisk");
/**
 * Gets all of the Business Risk Scores for a particular Enterprise risk
 *
 * @param {Object} system - Required
 * @param {Object} enterpriseRisk - Required
 * @param {Object} riskMapping - Required
 * @param {Object} monteCarloResults - Required
 * @param {Object} [ratingScale] - Optional
 * @returns {{greatestBusinessRisk, businessRisks: Array, residualRisk: number, riskRating: string}}
 */
const getRiskScores_businessRisks = async ({ system, enterpriseRisk, riskMapping, monteCarloResults, ratingScale, }) => {
    let greatestBusinessRisk = {};
    let businessRiskItems = [];
    const businessRisks = [];
    let residualRisk = 0;
    let inherentRisk = 0;
    let riskRating = "No Risk";
    const ermRiskItems = [
        {
            name: "compliance",
            businessRisks: [],
        },
        {
            name: "reputation",
            businessRisks: [],
        },
        {
            name: "financial",
            businessRisks: [],
        },
        {
            name: "operational",
            businessRisks: [],
        },
        {
            name: "consumerData",
            businessRisks: [],
        },
        {
            name: "strategic",
            businessRisks: [],
        },
        {
            name: "transaction",
            businessRisks: [],
        },
    ];
    // Loop through riskMapping object from database
    for (const businessRisk in riskMapping) {
        // Get ErmRisk array e.g. dataBreach: ["compliance", "reputation", "financial", "consumerData"]
        const ermRiskArray = riskMapping[businessRisk];
        // Loop through ErmRisk array. If the
        for (const ermRiskItem of ermRiskArray) {
            const found = ermRiskItems.find((x) => x.name === ermRiskItem);
            if (found) {
                found.businessRisks.push({ name: businessRisk });
            }
        }
    }
    const temp = ermRiskItems.find((a) => a.name === enterpriseRisk.name);
    businessRiskItems = [...temp.businessRisks];
    for (const businessRiskItem of businessRiskItems) {
        const businessRisk = await (0, getRiskScores_businessRisk_1.getRiskScores_businessRisk)({
            businessRisk: businessRiskItem,
            system,
            monteCarloResults,
            ratingScale,
        });
        businessRisks.push(businessRisk);
    }
    const res = Math.max.apply(Math, businessRisks.map((o) => {
        return o.residualRisk;
    }));
    const obj = businessRisks.find((o) => {
        return o.residualRisk === res;
    });
    if (obj) {
        greatestBusinessRisk = obj;
        residualRisk = obj.residualRisk;
        inherentRisk = obj.inherentRisk;
        riskRating = obj.riskRating;
    }
    return {
        greatestBusinessRisk,
        businessRisks,
        residualRisk,
        inherentRisk,
        riskRating,
    };
};
exports.getRiskScores_businessRisks = getRiskScores_businessRisks;
