import React from "react";

import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { handleLoader } from "../../../../utils/Functions/Views/handleLoader";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import { useCustomDashboardSelector } from "../../../CustomDashboards/hooks/useCustomDashboardSelector";
import { useToolDetails } from "../hooks/useToolDetails";

import ToolDefaultDashboard from "./ToolDefaultDashboard";

/**
 * Displays the Dashboard page for a specific tool. This is different from the configuration page with useToolDetails
 *
 * Based on the tool configuration, has a default dashboard with the following cards:
 *
 * 1. Tool Name, Description, Logo
 * 2. Tile Dashboard with tiles for each Custom Resource Type
 *
 * @param {string} itemId - ID from the tool, gathered from the route
 * @param {string} organizationID - ID from the organization, gathered from the route
 * @param {boolean} disableDefaultDashboard - whether to disable the default dashboard
 * @param {object} ...props - any other props passed to useToolDetails hook
 * @constructor
 */
const ToolDashboard = ({ itemId, organizationID, disableDefaultDashboard = false, ...props }) => {
  const toolDetails = useToolDetails({ itemId, organizationID, ...props });

  const selectDashboard = useCustomDashboardSelector({
    defaultDashboard: (
      <ToolDefaultDashboard toolDetails={toolDetails} organizationID={organizationID} isTemplate={props.isTemplate} />
    ),
    toolID: itemId,
    organizationID,
    isTemplate: props.isTemplate,
    disableDefaultDashboard,
  });

  return (
    <Dashboard
      title={toolDetails?.item?.name}
      subTitle="Custom Integration"
      headerButtons={[<span style={{ width: "20em" }}>{selectDashboard.form.display}</span>]}
      key={`tool-dashboard-${toolDetails?.item?.id}`}
    >
      {handleLoader({
        isLoading: toolDetails.isLoading,
        loaderMessage: "Loading Integration Details",
        component: selectDashboard.display,
      })}
    </Dashboard>
  );
};

export default withDetailsPage(ToolDashboard);
