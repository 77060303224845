import React, { useEffect, useRef, useState } from "react";

import { updateItemById } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { useGridCard } from "../../../../hooks/views/useGridCard";
import TagFilterMenu from "../../../../utils/FilterMenus/TagFilterMenu";
import { pointOfContactName } from "../../../../utils/Functions/pointOfContactName";
import { hideFilterMenuUI } from "../../../../utils/Functions/Views/grid/hideFilterMenuUI";
import { tagOperators } from "../../../../utils/Tags/components/TagFilterOperatorButtonGroup";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import { createSystemTagLink } from "../../../../utils/Tags/functions/createTagLinks/createSystemTagLink";
import { filterItemsBasedOnTagOperator } from "../../../../utils/Tags/functions/filterItemsBasedOnTagOperator";
import { convertTags } from "../../../Compliance/Controls/Controls/hooks/useControlGrid/functions/convertTags";
import CreateSystem from "../components/CreateSystem";
import SystemHosting from "../components/SystemAccordion/SystemOverview/customFields/SystemHosting";
import SystemDetails from "../components/SystemDetails";
import GreatestBusinessRisk from "../customFields/GreatestBusinessRisk";
import GreatestEnterpriseRisk from "../customFields/GreatestEnterpriseRisk";
import GreatestKeyRiskIndicator from "../customFields/GreatestKeyRiskIndicator";
import InherentRisk from "../customFields/InherentRisk";
import ResidualRisk from "../customFields/ResidualRisk";
import RiskRating from "../customFields/RiskRating";
import SystemAdmin from "../customFields/SystemAdmin";
import SystemOwner from "../customFields/SystemOwner";
import { deleteSystem } from "../functions/deleteSystem";

const {
  listQuery,
  updateMutation: updateSystem,
  onCreate,
  onUpdate,
  onDelete,
} = generateGraphql(
  "System",
  [
    "name",
    "description",
    "pointOfContact",
    "hosting",
    "adminPointOfContact",
    "tags",
    "inherentRisk",
    "residualRisk",
    "riskRating",
    "greatestEnterpriseRisk",
    "greatestBusinessRisk",
    "greatestKeyRiskIndicator",
  ],
  {
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
    pointOfContact: `{ id ownerGroup firstName lastName title email }`,
    adminPointOfContact: `{ id ownerGroup firstName lastName title email }`,
  },
);

/**
 * @deprecated - use useSystemDataGrid instead
 * @param organizationID
 * @param queryConfig
 * @param cardConfig
 * @param gridConfig
 * @param additionalFields
 * @param enableSticky
 * @param props
 * @returns {{gridDisplay: *, data: *, lastSelectedItem: string, setData: function(*): void, display: *|JSX.Element, error: unknown, unfilteredData: *[], isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, setError: (value: unknown) => void, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: [], persistenceData: Object, setUnfilteredData: (value: (((prevState: *[]) => *[]) | *[])) => void}}
 */
export const useSystemGrid = ({
  organizationID,
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
  additionalFields = [],
  enableSticky,
  ...props
}) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  //- tag filter menu values
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsOperator, setTagsOperator] = useState(tagOperators.OR);
  const selectedTagsRef = useRef([]);
  const tagsOperatorRef = useRef(tagsOperator);
  useEffect(() => {
    selectedTagsRef.current = selectedTags;
    tagsOperatorRef.current = tagsOperator;
  }, [selectedTags, tagsOperator]);

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: listQuery,
    organizationID,
    queryCallback: (data) => {
      setUnfilteredData(data);
    },
    ...queryConfig,
  };

  const fields = [
    {
      name: "name",
    },
    {
      name: "description",
    },
    {
      name: "pointOfContact",
      friendlyName: "System Owner",
      component: <SystemOwner />,
      exportConvert: (item) => pointOfContactName(item.pointOfContact),
      disablePropagation: true,
      allowFiltering: false,
    },
    {
      name: "adminPointOfContact",
      friendlyName: "System Admin",
      component: <SystemAdmin />,
      exportConvert: (item) => pointOfContactName(item.adminPointOfContact),
      disablePropagation: true,
      allowFiltering: false,
    },
    {
      name: "hosting",
      component: <SystemHosting />,
      visible: false,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
    },
    {
      name: "inherentRisk",
      visible: false,
      component: <InherentRisk />,
    },
    {
      name: "residualRisk",
      component: <ResidualRisk />,
    },
    {
      name: "riskRating",
      visible: true,
      component: <RiskRating />,
    },
    {
      name: "greatestEnterpriseRisk",
      visible: false,
      component: <GreatestEnterpriseRisk />,
    },
    {
      name: "greatestBusinessRisk",
      visible: false,
      component: <GreatestBusinessRisk />,
    },
    {
      name: "greatestKeyRiskIndicator",
      visible: false,
      component: <GreatestKeyRiskIndicator />,
    },
    {
      name: "tags",
      type: "string",
      allowSorting: false,
      component: <TagsField module={module} resource={resource} createLinkFunction={createSystemTagLink} />,
      exportConvert: convertTags,
      filterTemplate: (props) => {
        return (
          <TagFilterMenu
            tagsOperatorRef={tagsOperatorRef}
            selectedTagsRef={selectedTagsRef}
            setTagsOperator={setTagsOperator}
            setSelectedTags={setSelectedTags}
            organizationID={organizationID}
          />
        );
      },
      disablePropagation: true,
    },
  ];

  //Merge any additional field by name
  mergeAdditionalFields({ additionalFields, fields });

  gridConfig = {
    fields,
    detailsComponent: <SystemDetails organizationID={organizationID} />,
    route: "#/risk/systems/",
    header: "Information Systems",
    createResourceComponent: <CreateSystem organizationID={organizationID} />,
    options: ["details", "delete"],
    updateMutation: updateSystem,
    deleteFunction: deleteSystem,
    detailsTitle: "Information System Details",
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    detailsType: DETAILS_TYPES.PANEL,
    enablePrint: true,
    enableMenu: true,
    columnSize: 8,

    //On unfiltered item change, update the data (allows for proper filtering of tags without grid refresh)
    updateItemById: (item) => {
      updateItemById(setUnfilteredData, item);
    },
    //Hide default tags column filter menu elements
    actionComplete: (args) => hideFilterMenuUI({ args, hideAllFields: ["tags"] }),

    persistenceUUID: "e68f39ac-137f-4ca7-bbac-a4e028ab163a",
    enableSticky,
    typename: "System",
    duplicationSettings,
    ...gridConfig,
    ...props,
  };

  cardConfig = {
    title: "Information Systems",
    headerIcon: "icon-screen-desktop",
    enableSticky: true,
    ...cardConfig,
  };

  const systemGrid = useGridCard({
    queryConfig,
    cardConfig,
    gridConfig,
    roleConfig,
  });

  //handles active filtering by tags
  useEffect(() => {
    filterItemsBasedOnTagOperator({
      grid: systemGrid,
      unfilteredData,
      selectedTags,
      tagsOperator,
    });
  }, [selectedTags, tagsOperator, JSON.stringify(unfilteredData)]);

  return {
    ...systemGrid,
    unfilteredData,
    setUnfilteredData,
  };
};

const duplicationSettings = {
  enabled: true,
  description:
    "Duplicates a System. Preserves connections to Risks (copies Probability Modifier, Notes, CIA, and Enterprise Risk Mapping), Control Categories (copies Notes), and Information Assets (copies Number of Records). Does not copy Risk/Threat links, Risk Changes, Recommendations, or KPIs",
  fields: [
    "name",
    "description",
    "hosting",
    "pointOfContact",
    "adminPointOfContact",
    "confidentiality",
    "integrity",
    "availability",
    "risks",
    "riskOverrides",
    "riskControlOverrides",
    "informationAssets",
    "controlCategories",
    "notes",
    "tags",
  ],
  nestedFields: {
    pointOfContact: `{id}`,
    adminPointOfContact: `{id}`,
    risks: `(limit: 1000) { items { id ownerGroup system { id } risk { id } cia { confidentiality integrity availability } riskMapping { dataBreach systemDisruption facilityDisruption fraud malware vendor compliance } probabilityModifier notes { author content timeStamp ownerGroup observationID } } }`,
    informationAssets: `(limit: 1000) { items { id systemID informationAssetID system { id } informationAsset { id } numberOfRecords ownerGroup } }`,
    controlCategories: `(limit: 1000) { items { id ownerGroup riskControlCategoryID systemID riskControlCategory { id } system { id } notes { author content timeStamp ownerGroup observationID } } }`,
    notes: `{author content timeStamp ownerGroup observationID}`,
    riskOverrides: `{enabledFields riskId annualRateOfOccurrence annualRateOfOccurrenceMax confidenceIntervalLower confidenceIntervalUpper costOfControls controlEffectiveness}`,
    riskControlOverrides: `{enabledFields riskControlId isCompliant costOfControl controlEffectiveness annualRateReduction strengthRating implementationRating outsourced implementationDetails}`,
    tags: `(limit: 1000) { items { id ownerGroup systemID tagID system { id } tag { id } } }`,
  },
  primaryField: "name",
  inputAdaptor: (input, item) => {
    return {
      ...input,
      // have to do this because 'systemPointOfContactId' is hidden and can't be queried directly
      systemPointOfContactId: item?.pointOfContact?.id || undefined,
      // have to do this because 'systemAdminPointOfContactId' is hidden and can't be queried directly
      systemAdminPointOfContactId: item?.adminPointOfContact?.id || undefined,
    };
  },
  connectionAdaptor: {
    risks: {
      connectionTypename: "SystemRiskLink",
      itemConnectionIDField: "systemRiskLinkSystemId",
      childConnectionIDField: "systemRiskLinkRiskId",
      childConnectionField: "risk",
      fields: ["cia", "riskMapping", "probabilityModifier", "notes"],
    },
    controlCategories: {
      connectionTypename: "SystemControlCategoryLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "riskControlCategoryID",
      childConnectionField: "riskControlCategory",
      fields: ["notes"],
    },
    informationAssets: {
      connectionTypename: "SystemInformationAssetLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "informationAssetID",
      childConnectionField: "informationAsset",
      fields: ["numberOfRecords"],
    },
    tags: {
      connectionTypename: "SystemTagLink",
      itemConnectionIDField: "systemID",
      childConnectionIDField: "tagID",
      childConnectionField: "tag",
    },
  },
};
