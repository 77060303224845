import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import MetricLineChart from "../../../Charts/Metrics/MetricLineChart";
import Enabled from "../customFields/Enabled";
import Frequency from "../customFields/Frequency";
import MetricAutomation from "../customFields/MetricAutomation";
import Metrics from "../customFields/Metrics";

/**
 * Displays the details of a single MetricType
 *
 * - Displays the name, description, enabled, frequency, calculationType, automation, metrics, and toolID
 *
 * @param {string} organizationID
 * @param {object} queryConfig
 * @param {object} detailsConfig
 * @param {string} itemId
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: *, setIsLoading: (function(((function(boolean): boolean)|boolean)): void), tableDisplay: (React.DetailedReactHTMLElement<{item: *, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetFunction: ((function(): void)|*), display: JSX.Element, resetIndex: number, reset: (function(): void), setItem: (function(*): void)}}
 */
export const useMetricTypeDetails = ({ organizationID, queryConfig, detailsConfig, itemId }) => {
  const module = modules.METRICS;
  const resource = resources.METRIC_TYPE;
  const typename = "MetricType";

  const queryFields = [
    "name",
    "description",
    "enabled",
    "frequency",
    "calculationType",
    "automation",
    "metrics",
    "toolID",
  ];

  const fields = ["name", "description", "enabled", "frequency", "calculationType", "automation"];

  const nestedFields = {
    metrics: "(limit: 200) {items {id name description date data { value format }}}",
    automation: `{ name description jsonPath config fetchConfig { url apiKey } }`,
  };

  const fieldNameDictionary = {
    enabled: "Automated Metric Gathering",
  };

  const customFields = [
    {
      field: "metrics",
      component: <Metrics view="full" />,
    },
    {
      field: "frequency",
      component: <Frequency />,
    },
    {
      field: "enabled",
      component: <Enabled />,
    },
    {
      field: "automation",
      component: <MetricAutomation />,
    },
  ];

  const { getQuery, updateMutation } = generateGraphql(typename, [...queryFields], nestedFields);

  queryConfig = {
    organizationID,
    itemId,
    query: getQuery,
    ...queryConfig,
  };

  detailsConfig = {
    customFields,
    updateMutation,
    fieldNameDictionary,
    ...detailsConfig,
  };

  const metricDetailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    fields,
    typename,
    nestedFields,
  });

  const display = (
    <Grid container spacing={2}>
      <Grid lg={12} xs={12}>
        {metricDetailsHook.item && <MetricLineChart metricType={metricDetailsHook.item} />}
      </Grid>
      <Grid item lg={6} xs={6}>
        <DashboardCard title={"General Information"} icon={"icon-list"} style={{ height: "100%" }}>
          {metricDetailsHook.tableDisplay}
        </DashboardCard>
      </Grid>

      <Grid item lg={6} xs={6}>
        <DashboardCard title={"Metrics"} icon={"icon-list"} style={{ height: "100%" }}>
          <Metrics view="full" item={metricDetailsHook?.item} />
        </DashboardCard>
      </Grid>
    </Grid>
  );

  return {
    ...metricDetailsHook,
    display,
  };
};
