/* Author: Cole Rowell
 * Created At: 3/27/20
 * Edits:
 *  - 3/30/20 CR: Added the Timeout option
 *  - 3/31/20 CR: Improved the Layout of each option
 *  - 6/22/20 CR: Removed unused import to remediate console warning
 * Description: All login options within the settings.
 */

import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

import LoginInactivityLogout from "./LoginInactivityLogout";
import LoginRememberMyDevice from "./LoginRememberMyDevice";
import LoginSetupMfa from "./LoginSetupMfa";

/**
 * @description User Login Options
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const LoginOptions = (props) => {
  return (
    <div className="animated fadeIn">
      <LoginSetupMfa />
      <br />
      <LoginRememberMyDevice />
      <br />
      <LoginInactivityLogout />
    </div>
  );
};

export default withOrganizationCheck(LoginOptions);
