import { interpreter } from "../../Actions/functions/interpretActions";

/**
 * Determines if a value for isFinding is true or false in the importer context
 * @param {*} value - a single value to interpret from the importer
 * @returns {*|boolean}
 */
export const interpretIsFinding = (value) => {
  if (typeof value === "boolean") {
    return value;
  }

  const keywords = {
    true: ["yes", "true", "finding", "1"],
    false: ["no", "false", "not a finding", "not finding", "observation", "0"],
  };

  return interpreter(value, keywords);
};
