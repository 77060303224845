import React from "react";

import { convertCamelCaseToSentence, enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import VendorSolutionDetailsAccordion from "../components/VendorSolutionDetailsAccordion";
import VendorSolutionForm from "../components/VendorSolutionForm";
import { contractStatus } from "../constants/contractStatus";
import { listVendorSolutionsByOwnerGroup } from "../constants/listVendorSolutionsByOwnerGroup";
import { deleteVendorSolution } from "../functions/deleteVendorSolution";

/**
 * Allows to display a list of vendor solutions
 * @param {string} organizationID - usually provided by the component wrapper of this hook
 * @param {string} vendorID       - if provided the list will only include solutions from this vendor
 * @param {object} cardConfig     - @deprecated optional overrides to pass to useDataGrid
 * @param {string} module         - overrides the module used for role checking
 * @param {string} resource       - overrides the resource used for role checking
 * @param {object} ...props       - all other props passed to useDataGrid hook
 */
export const useVendorSolutionDataGrid = ({
  organizationID,
  vendorID,
  cardConfig = {},
  module = modules.VENDORS,
  resource = resources.VENDOR_SOLUTION,
  ...props
}) => {
  const typename = "VendorSolution";
  const queryFields = [
    "name",
    "description",
    "vendor",
    "vendorID",
    "contractDetails",
    "contractStatus",
    "contractExpirationDate",
    "contractExpiringSoonOffset",
  ];
  const nestedFields = {
    vendor: `{id name}`,
  };

  const { updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  const roleConfig = {
    module,
    resource,
  };

  cardConfig = {
    title: "Vendor Solutions",
    headerIcon: "icon-basket-loaded",
    enableSticky: true,
    ...cardConfig,
  };

  const queryConfig = {
    query: listVendorSolutionsByOwnerGroup,
    limit: 1000,
    variables: { ownerGroup: organizationID },
    filters: vendorID ? { vendorID: { eq: vendorID } } : undefined,
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
      width: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "vendor",
      searchKeys: ["vendor.name"],
      visible: !vendorID,
      valueGetter: (_value, row) => {
        return row?.vendor?.name || "No Vendor";
      },
      plainText: true,
      width: 200,
    },
    {
      name: "contractStatus",
      width: 150,
      type: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      valueOptions: Object.values(contractStatus).map((status) => ({
        label: convertCamelCaseToSentence(status),
        value: status,
      })),
      inputConfig: {
        data: enumToDropdownData({ ENUM: contractStatus }),
      },
      bulkEdit: true,
    },
    {
      name: "contractExpirationDate",
      disablePropagation: true,
      width: 150,
      type: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
      bulkEdit: true,
    },
    {
      name: "contractDetails",
      width: 200,
      type: GENERIC_FIELD_TYPES.TEXT_AREA,
      bulkEdit: true,
    },
    {
      name: "contractExpiringSoonOffset",
      width: 100,
      type: GENERIC_FIELD_TYPES.NUMERIC,
      bulkEdit: true,
      inputConfig: {
        min: 0,
        max: 365,
      },
      hidden: true,
    },
  ];

  const gridConfig = {
    organizationID,
    fields, //show vendor name only when outside vendor context
    options: ["open", "edit", "details", "duplicate", "delete"],
    createResourceComponent: (
      <VendorSolutionForm vendorID={vendorID} organizationID={organizationID} module={module} resource={resource} />
    ),
    deleteFunction: deleteVendorSolution,
    updateMutation,
    route: "#/vendors/solutions/",
    detailsComponent: (
      <VendorSolutionDetailsAccordion
        tableDisplay={true}
        organizationID={organizationID}
        module={module}
        resource={resource}
      />
    ),
    detailsTitle: "Vendor Solution Details",
    typename,
    duplicationSettings: {
      description:
        "Duplicates Vendor Solutions. Duplicated Vendor Solutions remain attached to the same Vendor. Does not duplicate Vendor Reviews",
      enabled: true,
      primaryField: "name",
      fields: ["name", "description", "vendorID", "contractStatus", "contractExpirationDate"],
    },
    persistenceUUID: "e9e1c7d7-9698-435b-bd76-395edbe1230e2",
    ...props,
  };

  const vendorSolutionsQueryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...vendorSolutionsQueryCard };
};
