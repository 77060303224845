import { ResponsiveRadar } from "@nivo/radar";
/**
 * @description UseRadarChart hook, displays a radar chart
 * @param {object[]} data - The data to display in the chart
 * @param {keys[]} keys - The keys to display in the chart
 * @param {string} indexBy - The key to index the data by
 * @returns {{display: JSX.Element}} - returns a display component
 * @example useRadarChart({ data, keys, indexBy }) => { display } - returns a display component
 */
export const useRadarChart = (data, keys, indexBy) => {
  const display = (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy={indexBy}
      maxValue="5"
      margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
      enableDots={true}
      dotSize={10}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      dotBorderColor={{ from: "color" }}
      enableDotLabel={true}
      dotLabel="value"
      dotLabelYOffset={-12}
      fillOpacity={0.5}
      borderColor={{ from: "color" }}
      colors={{ scheme: "set1" }}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateX: -50,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );

  return {
    display,
  };
};
