"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironment = void 0;
/**
 * Extracts and prioritizes the necessary environment variables for backend configuration.
 * If all required variables are present, it returns them; otherwise, it signifies a non-configurable state by returning null.
 * @returns Either an object containing essential backend configuration or null if required variables are missing
 */
const getEnvironment = ({ appsyncEndpoint } = {}) => {
    const env = process?.env; // Direct access to process.env with optional chaining for safety
    const effectiveVars = {
        API_RISC_GRAPHQLAPIENDPOINTOUTPUT: appsyncEndpoint ?? env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
        AWS_ACCESS_KEY_ID: env.AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: env.AWS_SECRET_ACCESS_KEY,
        AWS_REGION: env.AWS_REGION,
        AWS_SESSION_TOKEN: env.AWS_SESSION_TOKEN,
    };
    // Ensure all required environment variables are present
    if (effectiveVars.API_RISC_GRAPHQLAPIENDPOINTOUTPUT &&
        effectiveVars.AWS_ACCESS_KEY_ID &&
        effectiveVars.AWS_SECRET_ACCESS_KEY &&
        effectiveVars.AWS_REGION &&
        effectiveVars.AWS_SESSION_TOKEN) {
        return effectiveVars;
    }
    return null;
};
exports.getEnvironment = getEnvironment;
