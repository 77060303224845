import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorReviewDataGrid } from "../hooks/useVendorReviewDataGrid";

/**
 * Component allowing to choose a single vendor review from a list of meetings that's visible to the user
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectVendorReview = ({ organizationID, selectButtonCallback } = {}) => {
  const grid = useVendorReviewDataGrid({
    organizationID,
    gridConfig: {
      createResourceComponent: null,
      enableSelectButton: true,
      onSelectCallback: selectButtonCallback,
    },
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(SelectVendorReview);
