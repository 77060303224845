import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useUserDetails } from "../hooks/useUserDetails";

/**
 * A details card component for displaying the details of a particular User
 * @param props
 * @returns {*}
 * @constructor
 */
const UserDetails = (props) => {
  return useUserDetails(props).display;
};

export default withDetailsPage(UserDetails);
