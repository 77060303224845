import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Determines if a Target element is a child of a column that has a custom field
 * @param {object} target - element is a child of a column that has a custom field
 * @param {function} checkAriaLabel - checks if an element has area label
 * @returns {boolean}
 */
export const gridCustomFieldColumn = ({ target, checkAriaLabel }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = { checkAriaLabel };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  /**
   * If the target has a parent element, recursively check
   */
  if (checkAriaLabel(target)) {
    return true;
  } else if (target?.parentElement) {
    if (checkAriaLabel(target.parentElement)) {
      return true;
    } else {
      return gridCustomFieldColumn({
        target: target.parentElement,
        checkAriaLabel,
      });
    }
  } else {
    return false;
  }
};
