import { ErrorLogger } from "@utils/EventLogger";

/**
 * Lists all available data grid views for a particular grid id,
 * up to date views are retrieved from the database
 * @param {string} gridID - the grid identifier, currently provided with a persistenceUUID prop
 * @param {object} preferencesHook - instance of usePreferences hook
 * @return {object[]} - list of all available data grid views
 */
export const listDataGridViews = async ({ gridID, preferencesHook }) => {
  //Check arguments
  const getNetworkPreference = preferencesHook?.getNetworkPreference;
  if (!gridID || typeof getNetworkPreference !== "function") return [];

  //Get the list of views from preferences
  let views = [];
  try {
    const dataGridData = await getNetworkPreference("dataGridViews", gridID);
    views = dataGridData?.views;
    if (!Array.isArray(views)) {
      views = [];
    }
  } catch (e) {
    ErrorLogger("Error getting data grid views", { gridID, e });
  }
  return views;
};
