import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Displays the InformationAsset.confidentialityPerRecord field
 * @param item
 * @param organizationID
 * @param props
 * @constructor
 */
const ConfidentialityPerRecord = ({ item, organizationID, resetFunction, ...props }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_ASSET;
  const field = "confidentialityPerRecord";
  const inputType = GENERIC_FIELD_TYPES.DOLLAR;
  const typename = resources.INFORMATION_ASSET;

  const { updateMutation } = generateGraphql(typename, [field]);

  const passProps = {
    module,
    resource,
    field,
    item,
    organizationID,
    inputType,
    typename,
    mutation: updateMutation,
    resetFunction,
  };

  return <GenericEditFieldV3 {...passProps} {...props} />;
};

export default withOrganizationCheck(ConfidentialityPerRecord);
