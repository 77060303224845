import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import React, { useState } from "react";

import { AllowedFileUploadTypes } from "../../../../definitions/constants/allowedFileUploadTypes";
import DashboardCardSection from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCardSection";
import { handleFileUploads } from "../../../../utils/GenericComponents/Validation/FileValidation/FileUploadValidation/functions/handleFileUploads";

/**
 * Component allowing to select documents from a user's computer without uploading them
 * @param {function} onSubmit - function accepting file objects that were selected
 * @param {object} buttons - custom text to display for the buttons shown in the sync fusion component, passed through
 * @param {function} onSelected - function accepting file objects that were selected
 * @param {number} maxFileSize - maximum file size in bytes
 * Param Docs: https://ej2.syncfusion.com/react/documentation/api/uploader/#buttons
 * @param {boolean} displayIsEncrypted - if true, the user can select whether to encrypt the files or not
 */
const SelectDocuments = ({ onSubmit, buttons, onSelected, maxFileSize }) => {
  const allowedExtensions = AllowedFileUploadTypes.join(",");
  const [uploaderRef, setUploaderRef] = useState("");

  return (
    <div title="Upload Documents">
      <DashboardCardSection
        title={"Select Files"}
        content={
          <div>
            <UploaderComponent
              autoUpload={false}
              buttons={
                buttons || {
                  browse: "Choose Files",
                  clear: "Clear All",
                  upload: "Continue",
                }
              }
              selected={(event) => {
                if (typeof onSelected === "function") onSelected(event);
              }}
              allowedExtensions={allowedExtensions}
              beforeUpload={() => {
                const files = uploaderRef?.getFilesData();
                const validFiles = handleFileUploads(files, AllowedFileUploadTypes);
                if (typeof onSubmit === "function") onSubmit({ files: validFiles });
              }}
              ref={(r) => setUploaderRef(r)}
              maxFileSize={maxFileSize || 524288000} // 500 MB
            />
          </div>
        }
      />
    </div>
  );
};

export default SelectDocuments;
