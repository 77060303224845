import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCustomResourceTypeDataGrid } from "../hooks/useCustomResourceTypeDataGrid";

/**
 * A component instance of the useCustomResourceTypeDataGrid.js hook
 * @param {object} props
 */
const CustomResourceTypeDataGrid = (props) => useCustomResourceTypeDataGrid(props).display;

export default withOrganizationCheck(CustomResourceTypeDataGrid);
