import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useMeetingDetails } from "../../../../views/Program/Meetings/hooks/useMeetingDetails";
import { useMeetingForm } from "../../../../views/Program/Meetings/hooks/useMeetingForm";
import { useMeetingsDataGrid } from "../../../../views/Program/Meetings/hooks/useMeetingsDataGrid";

// lazy load components
const MeetingDetails = React.lazy(() => import("../../../../views/Program/Meetings/components/MeetingDetails"));
const MeetingsDataGrid = React.lazy(() => import("../../../../views/Program/Meetings/components/MeetingsDataGrid"));
const CreateMeeting = React.lazy(() => import("../../../../views/Program/Meetings/components/MeetingForm"));

export const meetingDefinition = {
  typename: "Meeting",
  module: modules.GOVERNANCE,
  resource: resources.MEETING,
  routes: {
    grid: "governance/meetings",
    details: "governance/meetings",
    helpCenter: "meetings",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:people",

  //[COMPONENTS]
  gridComponent: <MeetingsDataGrid />,
  formComponent: <CreateMeeting />,
  detailsComponent: <MeetingDetails tableDisplay={true} />,

  //[HOOKS]
  gridHook: useMeetingsDataGrid,
  formHook: useMeetingForm,
  detailsHook: useMeetingDetails,

  //[FIELDS]
  fields: {
    name: {},
    description: {},
    status: {},
    startTime: {},
    endTime: {},
    isAllDay: {},
    organizer: {},
    location: {},
    duration: {},
    module: {},
    linkedResources: {},
    pointOfContacts: {
      default: {
        nestedFields: `(limit: 100) { items {  id pointOfContact { id firstName lastName title email ownerGroup  }} }`,
      },
    },
    accessControl: {
      default: {
        nestedFields: `{ password passwordOwnerEmail roles pointOfContacts }`,
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    observations: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id observation { id name description } } }`,
      },
    },
  },

  views: {
    grid: {
      queryFields: [
        "name",
        "description",
        "startTime",
        "endTime",
        "status",
        "isAllDay",
        "pointOfContacts",
        "organizer",
        "location",
        "duration",
        "linkedResources",
        "accessControl",
        "tags",
        "module",
      ],
    },
  },
};
