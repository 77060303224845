import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { usePointOfContactDataGrid } from "../hooks/usePointOfContactDataGrid";

/**
 * Displays a list of points of contact
 * @param props
 * @returns {JSX.Element}
 */
const PointOfContactDataGrid = (props) => {
  return usePointOfContactDataGrid(props).display;
};

export default withOrganizationCheck(PointOfContactDataGrid);
