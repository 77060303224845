import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Custom field for the System Hosting
 *
 * @param {object} item - the system object
 * @param {function} updateItemById - function to update an external grid after the mutation
 * @param {boolean} disableRoleChecking - disable the role checking
 * @returns {JSX.Element}
 * @constructor
 */
const SystemHosting = ({ item, updateItemById, disableRoleChecking = false, ...props }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const { updateMutation } = generateGraphql("System", ["hosting"]);

  return (
    <GenericEditFieldV3
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      item={item}
      inputConfig={{
        data: [
          {
            value: "",
            text: "None",
          },
          {
            value: "outsourced",
            text: "Outsourced",
          },
          {
            value: "hybrid",
            text: "Hybrid",
          },
          {
            value: "internal",
            text: "Internal",
          },
        ],
      }}
      field={"hosting"}
      mutation={updateMutation}
      updateItemById={updateItemById}
      resource={resource}
      module={module}
      disableRoleChecking={disableRoleChecking}
      format={"sentence"}
      {...props}
    />
  );
};

export default SystemHosting;
