import { useState } from "react";

/**
 * Handles the state and logic for mapping CSV headers to available fields
 * @returns {{columnMap: {}, availableFields: *, setAvailableFields: function, setColumnMapping: function}}
 */
export const useColumnMapping = () => {
  // Current column mapping between csv headers and available fields
  const [columnMap, setColumnMap] = useState({});

  // Function that sets the column mapping, and removes already mapped columns from Available columns
  const setColumnMapping = (columnName, header, previousColumnName) => {
    setColumnMap((columnMap) => {
      return {
        ...columnMap,
        [header]: columnName,
      };
    });
  };

  const resetColumnMapping = () => {
    setColumnMap({});
  };

  return {
    resetColumnMapping,
    setColumnMapping,
    columnMap,
  };
};
