import React, { useEffect, useState } from "react";
import { Collapse, ListGroup } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { GetQuery } from "../../../../../../../utils/Functions/Graphql/GetQuery";
import CustomTooltip from "../../../../../../../utils/GenericComponents/CustomTooltip";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";

import ControlCategoryThreat from "./ControlCategoryThreat";

/**
 * Displays Threats associated with a SystemRiskLink
 * @param {string} organizationID - ID of the Organization
 * @param {object} controlCategory - ControlCategory object
 * @param {object} system - System object
 * @param {boolean} disableRoleChecking - Disable role checking
 * @param {string} viewType - View type
 * @returns {JSX.Element}
 * @constructor
 */
const ControlCategoryThreats = ({ organizationID, controlCategory, system, disableRoleChecking, viewType }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "threats";

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const [threats, setThreats] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (isOpen && system?.id && controlCategory?.id) {
      setIsLoading(true);

      const { getQuery } = generateGraphql("System", ["risks"], {
        risks: `(limit: 1000) {
          items {
            id
            risk {
              id
              controlCategoryID
              threatLinks (limit: 1000) {
                items {
                  id
                  threat {
                    id
                    name
                    techniqueID
                    ownerGroup
                  }
                }
              }
            }
            ownerGroup
          }
          nextToken
        }
      `,
      });

      GetQuery({
        query: getQuery,
        variables: { id: system?.id },
      }).then((data) => {
        try {
          const items = [];
          const riskLinks = data?.risks?.items;

          for (const riskLink of riskLinks) {
            if (riskLink.risk.controlCategoryID !== controlCategory?.id) continue;

            const threatLinks = riskLink.risk.threatLinks;

            if (threatLinks) {
              for (const threatLink of threatLinks) {
                if (threatLink?.threat) {
                  items.push(threatLink?.threat);
                }
              }
            }
          }
          setThreats([...items]);
        } catch (e) {
          ErrorLogger("Cannot get threats", e);
        } finally {
          setIsLoading(false);
        }
      });
    }
  }, [system, controlCategory, isOpen, resetKey]);

  return (
    <>
      {checkPermissionsHook?.field?.read && (
        <div style={{ marginTop: "1em" }}>
          <strong>
            <span style={{ marginRight: "1em" }}>Threats</span>
            <i
              className={!isOpen ? "fa fa-eye-slash" : "fa fa-eye"}
              title={`${isOpen ? "Hide" : "Show"} Threats`}
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpen(!isOpen)}
            />
          </strong>
          <CustomTooltip tooltip={"Threat library with corresponding mitigations"} />
          <Collapse isOpen={isOpen}>
            <div style={{ height: "15em", overflow: "auto" }}>
              <ListGroup style={{ marginTop: "0.5em" }}>
                {Array.isArray(threats) && threats?.length > 0 ? (
                  threats?.map((threat) => <ControlCategoryThreat item={threat} organizationID={organizationID} />)
                ) : isLoading ? (
                  <DataLoader isLoading={isLoading} />
                ) : (
                  <div style={{ fontStyle: "italic" }}>No Threats</div>
                )}
              </ListGroup>
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default withOrganizationCheck(ControlCategoryThreats);
