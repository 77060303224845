import React from "react";
import { LazyLog } from "react-lazylog";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { FlipCard } from "../../../../utils/GenericComponents/FlipCard/components/FlipCard";
import Json from "../../../../utils/GenericComponents/Json";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";

/**
 * Displays the Logs for a particular job in JSON format
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const JobLogs = ({ item }) => {
  const getLogText = (logs) => {
    let res = "";
    if (typeof logs === "string") {
      const parsed = JSON.parse(logs);

      parsed.forEach((log) => {
        res = res.concat(`[${log.type} - ${log.timestamp}] ${log.title}\n`);
      });
    }

    return res;
  };

  return (
    <span>
      <DataLoader isEnoughData={!isNullOrUndefined(item?.logs)} isLoading={false}>
        <FlipCard
          title={"Logs"}
          flipTitle={"Raw Logs"}
          icon={getIcon("radix-icons:activity-log")}
          content={
            <div style={{ height: "60vh", width: "100%" }}>
              <LazyLog enableSearch={true} extraLines={0} text={getLogText(item?.logs)} caseInsensitive />
            </div>
          }
          flipContent={
            <div>
              <Json data={!isNullOrUndefined(item?.logs) ? JSON.parse(item?.logs) : {}} />
            </div>
          }
        />
      </DataLoader>
    </span>
  );
};

export default withOrganizationCheck(JobLogs);
