"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createList = void 0;
const createList = (items, listId) => {
    const itemDocumentInfo = [];
    for (const item of items) {
        itemDocumentInfo.push({
            paragraphFormat: {
                leftIndent: 36,
                firstLineIndent: -18,
                styleName: "Normal",
                listFormat: {
                    listId: listId,
                    listLevelNumber: 0,
                },
            },
            characterFormat: {},
            inlines: [
                {
                    characterFormat: {
                        bidi: false,
                    },
                    text: item || "Item",
                },
            ],
        });
    }
    return itemDocumentInfo;
};
exports.createList = createList;
