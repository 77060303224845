import { useState } from "react";
import { Button, ListGroup } from "reactstrap";

import { ListQueryBy } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "@hooks/views/useModal";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

import AddOrRemoveIcon from "../../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";

import AddInformationAssetToSystem from "./AddInformationAssetToSystem";
import InformationAssetItem from "./InformationAssetItem";

/**
 * Displays Information Assets for a System
 * @param module - the module being used
 * @param resource - the resource being used
 * @param disableRoleChecking - whether to disable role checking
 * @param resetSystem - function to reset the system state
 * @param system - the system to display information assets for
 * @param isOpen - whether the information asset accordion is open
 * @param props - the other props passed to the component
 * @returns {JSX.Element}
 */
const InformationAssets = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  resetSystem,
  system,
  isOpen,
  ...props
}) => {
  const [informationAssets, setInformationAssets] = useState([]);
  const { listByQuery } = generateGraphql(
    "SystemInformationAssetLink",
    ["systemID", "informationAsset", "numberOfRecords"],
    {
      informationAsset: `{ confidentialityPerRecord integrityPerRecord name id }`,
    },
    {
      indexName: "systemInformationAssetLinksBySystemID",
      partitionKey: "systemID",
      partitionKeyType: "ID",
    },
  );
  const field = "informationAssets";

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      const informationAssetLinks = await ListQueryBy({
        query: listByQuery,
        limit: 1000,
        variables: { systemID: system?.id },
      });

      return informationAssetLinks.map((item) =>
        item?.informationAsset
          ? {
              ...item.informationAsset,
              informationAssetLink: item,
            }
          : null,
      );
    },
    defaultValue: [],
    setData: setInformationAssets,
    dependencies: [system],
  });

  const reset = () => {
    resetFunction?.();
    resetSystem?.();
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const addInformationAssetModal = useModal(
    "Add or Remove Information Asset",
    <AddInformationAssetToSystem
      disableRoleChecking={disableRoleChecking}
      organizationID={props.organizationID}
      system={system}
      currentAssets={informationAssets.map((asset) => asset.informationAssetLink)}
      resetFunction={reset}
    />,
    <Button
      size="sm"
      color="ghost-secondary"
      className="btn-pill"
      title={
        checkPermissionsHook.resource.update
          ? "Add/Remove Information Assets for this System"
          : "You don't have Update permission for this Resource"
      }
      disabled={!checkPermissionsHook.resource.update}
    >
      <AddOrRemoveIcon />
    </Button>,
  );

  return (
    <>
      <h7>
        Information Assets
        {addInformationAssetModal.modalButton}
      </h7>
      <DataLoader
        isLoading={isLoading}
        isEnoughData={isNonEmptyArray(informationAssets)}
        dataMessage={"There are no Information Assets linked to this System."}
        callToAction={
          checkPermissionsHook.resource.update && {
            message: " to attach an Information Asset",
            function: () => addInformationAssetModal.setModalIsOpen(true),
          }
        }
        style={{ fontStyle: "italic" }}
      >
        <ListGroup className="shadow">
          {informationAssets.map((item) => (
            <InformationAssetItem
              key={item.id}
              item={item}
              resetFunction={props.resetFunction}
              isOpen={!!isOpen}
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
            />
          ))}
        </ListGroup>
      </DataLoader>
    </>
  );
};

export default withOrganizationCheck(InformationAssets);
