import { Auth } from "@aws-amplify/auth";
import React, { useContext, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Progress,
  Row,
} from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useUIContext } from "@utils/Context/UIContext";
import { InfoLogger } from "@utils/EventLogger";

import { S3_BUCKET_REGION } from "../../../../env-config";
import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { PutS3Object } from "../../../../utils/Functions/S3Storage/PutS3Object";

const { createMutation: createPolicyVersion } = generateGraphql(
  "PolicyVersion",
  ["version", "status", "description", "approvedBy", "approvalDate", "reviewers", "file"],
  {
    reviewers: `(limit: 1000) { items { id status pointOfContact { id firstName lastName }  } }`,
    file: `{ key bucket region }`,
  },
);

export const useAddPolicyVersion = (props) => {
  const context = useContext(OrganizationContext);

  const [description, setDescription] = useState("");
  const [progressBar, setProgressBar] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [files, setFiles] = useState([]);

  const isSubmitEnabled = description && files.length > 0;

  const handleFileChange = () => {
    setFiles(Array.from(document.getElementById("selectFiles").files));
  };

  const { addToast, updateToast } = useUIContext();

  const pleaseWait = usePleaseWaitModal({
    steps: [
      {
        text: "Fetching S3 Bucket",
      },
      {
        text: "Getting Current User Info",
      },
      {
        text: "Parsing File",
      },
      {
        text: "Uploading File to S3 Bucket",
      },
      {
        text: "Creating new Policy Version",
      },
    ],
  });

  const CreateNewPolicyVersion = async (saveAsWordBlob, policyID) => {
    pleaseWait.setModalIsOpen(true);

    const toastId = addToast({
      header: "Uploading new Policy Version..",
      icon: "spinner",
    });

    const region = S3_BUCKET_REGION;
    const visibility = "private";

    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    const bucketName =
      context && context.selectedOrganizationObjectMinimal && context.selectedOrganizationObjectMinimal.s3BucketName;

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    const { username: owner } = await Auth.currentUserInfo();

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(2, STEP_STATUS.IN_PROGRESS);

    const fileInput = document.getElementById("selectFiles");

    let res;
    let files;

    if (props.saveAsWordBlob) {
      InfoLogger("Saving Report as a Word Doc Blob");
      const name = "temp";
      files = [new File([await props.saveAsWordBlob()], `${name}.docx`)];
    } else {
      files = fileInput?.files || [];
    }

    pleaseWait.setStepStatus(2, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(3, STEP_STATUS.IN_PROGRESS);

    for (const file of files) {
      const fileName = file.name;
      const key = generateS3ObjectKey(`policies/${fileName}`);

      await PutS3Object({
        file: file,
        bucketName,
        objectKey: key,
        organizationID: props.organizationID,
        onprogress: (progress) => setProgressBar(progress),
      }).then(async () => {
        pleaseWait.setStepStatus(3, STEP_STATUS.COMPLETE);
        pleaseWait.setStepStatus(4, STEP_STATUS.IN_PROGRESS);

        const fileInfo = {
          bucket: bucketName,
          region: region,
          key: key,
        };

        props.setHighestVersionNumber((highestVersionNumber) => {
          ItemMutation(createPolicyVersion, {
            description: description,
            version: highestVersionNumber ? highestVersionNumber : 1,
            status: "draft",
            owner: owner,
            visibility: visibility,
            createdAt: new Date(),
            file: fileInfo,
            ownerGroup: props.organizationID,
            policyVersionPolicyId: props.policyId ? props.policyId : policyID || undefined,
          })
            .then((newPolicyVersion) => {
              pleaseWait.setStepStatus(4, STEP_STATUS.COMPLETE);
              InfoLogger(`New Policy Version ${newPolicyVersion.id} was Successfully Created`);
              props.setHighestVersionNumber((highestVersionNumber) => highestVersionNumber + 1);
              props.setVersion(newPolicyVersion);
              res = newPolicyVersion;
            })
            .catch((err) => {
              setUploadMessage(`${file.name}  failed to upload. ${JSON.stringify(err)}`);
              updateToast({
                id: toastId,
                header: "Error! Could not upload new Policy Version",
                body: JSON.stringify(err),
              });
            }); // If the upload fails.
        });
      });
    }

    pleaseWait.setModalIsOpen(false);
    updateToast({
      id: toastId,
      header: "Successfully Uploaded new Policy Version!",
      icon: "success",
    });
    props.resetFunction?.();
    props.toggleModal?.();

    return res;
  };

  const display = (
    <Card>
      {pleaseWait.modal}
      <CardHeader>Add New Policy Version</CardHeader>
      <CardBody>
        <Container>
          <Row>
            <Col sm={4}>
              <Label for="description">
                Version Description <span style={{ color: "red" }}>*</span>
              </Label>
            </Col>
            <Col sm={8}>
              <Input
                value={description}
                id="description"
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  setDescription(e.target.value);
                }}
                required
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={4}>
              <Label for="selectFiles">
                Select New Policy Version <span style={{ color: "red" }}>*</span>
              </Label>
            </Col>
            <Col sm={8}>
              <Input
                id="selectFiles"
                type="file"
                multiple
                onChange={() => handleFileChange()}
                accept={".docx, .doc, .pdf, .txt"}
                required
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12}>
              <Progress animated={progressBar !== 100} value={progressBar}>
                {parseInt(progressBar)}%
              </Progress>
              {uploadMessage && <Alert color="primary">{uploadMessage}</Alert>}
            </Col>
          </Row>
        </Container>
      </CardBody>
      <CardFooter>
        <Button
          disabled={!isSubmitEnabled}
          onClick={async () => await CreateNewPolicyVersion()}
          size="sm"
          color="primary"
        >
          <i className="fa fa-dot-circle-o" /> Add New Version
        </Button>
      </CardFooter>
    </Card>
  );

  return {
    display,
    CreateNewPolicyVersion,
    pleaseWait,
  };
};
