import "./styles/App.scss";

import { createTheme } from "@mui/material";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@mui/styles";
import { LicenseInfo } from "@mui/x-license";
import { ConfirmSignUp, ForgotPassword, SignIn, withAuthenticator } from "aws-amplify-react";
import { ReactElement } from "react";
import { ApolloProvider } from "react-apollo";
import { HotkeysProvider } from "react-hotkeys-hook";
import { HashRouter, Route, Switch } from "react-router-dom";

import { ToastProvider } from "@components/Atoms/Toast/Toast";
import { CustomSignIn } from "@utils/SignIn/components/CustomSignIn";

import { MUI_LICENSE_KEY } from "../../env-config";
import { FeatureFlagsProvider } from "../../featureFlags/FeatureFlagsProvider";

import AuthenticatedApp from "./AuthenticatedApp";
import { initializeAppsyncClient } from "./functions/initializeAppsyncClient";
import GuestApp from "./GuestApp";
import { withGuestAuthentication } from "./hoc/withGuestAuthentication/withGuestAuthentication";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});

const client = initializeAppsyncClient();

const WrappedGuestApp = withGuestAuthentication(GuestApp);

const AuthenticatedAppWithApolloClient = ({ onStateChange }): ReactElement => (
  <AuthenticatedApp client={client} onStateChange={onStateChange} />
);

const WrappedAuthenticatedApp = withAuthenticator(AuthenticatedAppWithApolloClient, false, [
  // @ts-expect-error Thinks it's not a valid component
  <CustomSignIn override={SignIn} key={0} />,
  // @ts-expect-error Thinks it's not a valid component
  <ForgotPassword key={1} />,
  // @ts-expect-error Thinks it's not a valid component
  <ConfirmSignUp key={2} />,
]);

const App = (): ReactElement => (
  <FeatureFlagsProvider>
    <StylesProvider generateClassName={generateClassName}>
      {/* @ts-expect-error Thinks it's not a valid component */}
      <ApolloProvider client={client}>
        <ThemeProvider theme={createTheme()}>
          <ToastProvider>
            <HotkeysProvider initiallyActiveScopes={["modal"]}>
              <HashRouter>
                <Switch>
                  <Route path={"/guest/"} component={WrappedGuestApp} />
                  <Route path={"/"} component={WrappedAuthenticatedApp} />
                </Switch>
              </HashRouter>
            </HotkeysProvider>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
    </StylesProvider>
  </FeatureFlagsProvider>
);

export default App;
