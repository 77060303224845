import { useAccessControl } from "../hooks/useAccessControl";

/**
 * @description Wrapper component for the useAccessControl hook
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const AccessControl = (props) => useAccessControl(props).display;

export default AccessControl;
