import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { updateMutation } from "../graphql/customDashboardGraphql";

import { getCurrentDashboard } from "./getCurrentDashboard";

/**
 * Removes a page from a dashboard based on dashboardID and pageID.
 *
 * Does a fresh fetch of the dashboard, then performs a mutation to update the dashboard
 *
 * @param {string} dashboardID - the dashboardID of the dashboard to remove the page from
 * @param {string} pageID - the pageID of the page to remove
 * @param {function} setItemQuery - the setItem function from the useQueryGetItem hook
 * @returns {Promise<void>}
 */
export const removePageFromDashboard = async ({ dashboardID, pageID, setItemQuery }) => {
  if (!window.confirm("Are you sure you want to remove this Page?")) {
    return;
  }

  // first, perform a clean fetch of the CustomDashboardLayouts
  const customDashboardQuery = await getCurrentDashboard({
    itemId: dashboardID,
  });

  // get layouts from CustomDashboard query
  const layouts = customDashboardQuery?.layouts || [];

  // Update layouts array by filtering out this layout based on pageID
  const updatedLayouts = layouts.filter((layout) => layout.id !== pageID);

  // perform a mutation to update the CustomDashboard object in the db with the new layouts
  const updatedItem = await ItemMutation(updateMutation, {
    id: dashboardID,
    layouts: updatedLayouts,
  });

  setItemQuery(updatedItem);
};
