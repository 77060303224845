/**
 * Determines whether a custom field configuration defines that the field should be hidden by default
 * @param {object} field - the field configuration object with visibility settings
 * @param {boolean} [defaultValue=true] - default value to use in case the field object doesn't have any visibility values
 * @return {boolean} - will TRUE if the field needs to be hidden based on its configuration
 */
export const fieldIsHidden = ({ field, defaultValue = false }) => {
  return (
    field.hide === true ||
    field.hidden === true ||
    field.visible === false ||
    field.isHidden === true ||
    field.visibility === false ||
    defaultValue
  );
};
