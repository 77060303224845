import { Button as MuiButton } from "@mui/material";
import React from "react";

import { useModal } from "../../../../hooks/views/useModal";
import AddOrRemoveIcon from "../../../../utils/GenericComponents/AddOrRemoveIcon";
import ControlEvidenceLinkImporterButton from "../../ControlEvidenceLinks/components/ControlEvidenceLinkImporterButton";
import EvidenceControlLinking from "../components/EvidenceControlLinking";

/**
 * Modal wrapper around useEvidenceControlLinking
 * @param {string} organizationID - the organization/operation team for which to show linking UI
 * @param {object} item - the evidence to which to link controls
 * @param {function} resetFunction - function to re-query item
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @return {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useEvidenceControlLinkingModal = ({ organizationID, item, resetFunction, isTemplate = false }) => {
  const headerText = isTemplate
    ? "Link / Unlink Control Templates from this Evidence Template"
    : "Link / Unlink Controls from this Evidence";

  const linkingModal = useModal(
    <span>
      {headerText}
      <ControlEvidenceLinkImporterButton
        organizationID={organizationID}
        evidenceOrganizationID={organizationID}
        controlOrganizationID={organizationID}
      />
    </span>,
    <EvidenceControlLinking organizationID={organizationID} evidence={item} resetFunction={resetFunction} />,
    <MuiButton className="float-right" title={headerText}>
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "80vw",
    },
  );

  return {
    ...linkingModal,
  };
};
