/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  - 10/18/19 JB: Added better styling
 *  - 3/30/20 JB: Added permissions checking
 *
 * Description: Displays a Program Blueprint Column component.
 *              Maturity Columns may correspond to a module in the app / a service provided by Rivial
 *              Edits are turned off by default, but may be switched on via the `enableEdits` prop.
 *              This component displays a list of Maturity Levels as it's children.
 */

import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose } from "react-apollo";
import { Button, ButtonGroup, Col, Row } from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";

import { GenericDeleteButton } from "../../../../../../utils/GenericComponents/GenericDeleteButton";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditField";
import { GenericModalWithButton } from "../../../../../../utils/GenericComponents/GenericModalWithButton";
import DeleteMaturityColumn from "../../../functions/DeleteMaturityColumn";
import CreateMaturityLevel from "../MaturityMatrixLevel/CreateMaturityLevel";
import MaturityMatrixLevelHorizontal from "../MaturityMatrixLevel/MaturityMatrixLevelHorizontal";

const { listQuery: listMaturityMatrixs } = generateGraphql("MaturityMatrix");
const { updateMutation: updateMaturityColumn } = generateGraphql("MaturityColumn");

const MaturityColumnHorizontal = (props) => {
  const getColumnGoal = (column) => {
    let goalID = null;

    if (column.maturityGoal) {
      goalID = column.maturityGoal;
    }

    return goalID;
  };

  const [levelGoal, setLevelGoal] = useState(getColumnGoal(props.maturityColumn));

  const setGoal = (maturityMatrixLevel) => {
    const goalID = getColumnGoal(props.maturityColumn);

    if (goalID !== maturityMatrixLevel.id) {
      if (
        window.confirm(
          `Are you sure you want to set the ${props.maturityColumn.name} goal to: ${maturityMatrixLevel.name}?`,
        )
      ) {
        setLevelGoal(maturityMatrixLevel.id);
        props.updateMaturityColumn({
          input: {
            id: props.maturityColumn.id,
            maturityGoal: maturityMatrixLevel.id,
          },
        });
      }
    }
  };

  return (
    <Row
      style={{
        minHeight: "200px",
      }}
    >
      <div
        className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
        style={{
          position: "sticky",
          left: 0,
          zIndex: 100,
          background: "white",
          width: "20mm",
        }}
      >
        <div
          style={{
            /* Abs positioning makes it not take up vert space */
            position: "absolute",
            bottom: 0,
            marginLeft: "10%",
            color: "black",
            transform: "rotate(270deg)",
            transformOrigin: "0 0",
            whiteSpace: "normal",
          }}
        >
          <h4>
            <GenericEditField
              item={props.maturityColumn}
              field="name"
              mutation="updateMaturityColumn"
              disableEdits={!props.enableEdits}
            />
          </h4>
          {props.enableEdits ? (
            <ButtonGroup size="sm" className="float-right">
              <GenericModalWithButton
                title={`Add Maturity level to: ${props.maturityColumn.name}`}
                body={<CreateMaturityLevel maturityColumn={props.maturityColumn} />}
                modalButton={
                  <Button size="sm" title="Create Maturity Level" className="float-right" color="ghost-success">
                    <i className="icon-plus" />
                  </Button>
                }
              />
              <GenericDeleteButton
                className="float-right"
                deleteFunction={DeleteMaturityColumn}
                item={props.maturityColumn}
              />
            </ButtonGroup>
          ) : null}
        </div>
      </div>
      <Col lg={11}>
        {props.maturityColumn &&
        props.maturityColumn.maturityMatrixLevels &&
        props.maturityColumn.maturityMatrixLevels.items &&
        props.maturityColumn.maturityMatrixLevels.items.length > 0
          ? props.maturityColumn.maturityMatrixLevels.items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((maturityMatrixLevel, index) => (
                <MaturityMatrixLevelHorizontal
                  goal={levelGoal}
                  setGoal={setGoal}
                  enableEdits={props.enableEdits}
                  key={`maturityMatrixLevels${maturityMatrixLevel.id}${index}`}
                  maturityMatrixLevel={maturityMatrixLevel}
                  setModalBody={props.setModalBody}
                />
              ))
          : "No Maturity Levels for this Column"}
      </Col>
    </Row>
  );
};

MaturityColumnHorizontal.propTypes = {
  maturityColumn: PropTypes.object.isRequired,
  enableEdits: PropTypes.bool,
  setModalBody: PropTypes.func,
  matrixSettings: PropTypes.object,
};

MaturityColumnHorizontal.defaultProps = {
  enableEdits: false,
  matrixSettings: {},
};

export default compose(graphqlMutation(gql(updateMaturityColumn), gql(listMaturityMatrixs), "MaturityColumn"))(
  MaturityColumnHorizontal,
);
