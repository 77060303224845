import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Handles toolbar interactions
 * @param {object} args - syncfusion argument when clicking a toolbar item
 * @param {function} onToolbarClick - function triggers on tool bar click
 */
export const gridHandleToolbarClick = ({ args, onToolbarClick }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    args,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  onToolbarClick && onToolbarClick(args?.item?.id);
};
