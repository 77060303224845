import React from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import AutomationDetails from "../components/AutomationDetails";
import AutomationEnabled from "../components/AutomationEnabled";
import CreateAutomation from "../components/CreateAutomation";
import { deleteAutomation } from "../functions/deleteAutomation";

/**
 * Displays a list of Automations for an org
 * @param {string} organizationID - ownerGroup filtering field
 * @param {string} itemId - pre-filters grid to only show automations using the 'automationsByItemId' custom query
 * @param {string} typename - typename to use in display components
 * @param {object} filter - additional filter to add to the grid
 * @param {object[]} additionalFields - additional fields to add to the grid
 * @param {object} gridConfig - additional config to pass to useDataGrid. overrides props and default configs
 * @param {object} ...props - other props to pass to useDataGrid
 * @returns {{selectedData: *, isLoading: *, setIsLoading: *, data: unknown, setData: React.Dispatch<React.SetStateAction<unknown>>, resetFunction: *, display: JSX.Element, selectedIDs: *[]}}
 */
export const useAutomationsGrid = ({
  organizationID,
  itemId,
  typename,
  filter,
  additionalFields = [],
  gridConfig = {},
  ...props
}) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.AUTOMATION;
  const route = "#/organization_manager/automations/";

  const { listQuery, updateMutation } = generateGraphql("Automation", ["name", "description", "enabled"]);

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "description",
      flex: 1,
      bulkEdit: true,
    },
    {
      name: "enabled",
      width: 200,
      component: <AutomationEnabled />,
      bulkEdit: true,
      type: "boolean",
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  return useDataGrid({
    module,
    resource,
    fields,
    route,
    detailsComponent: <AutomationDetails />,
    createResourceComponent: <CreateAutomation itemId={itemId} typename={typename} />,
    createResourceComponentWidth: "80vw",
    query: listQuery,
    filters: !isNullOrUndefined(filter) ? filter : undefined,
    typename: "Automation",
    persistenceUUID: "data-grid-automations",
    deleteFunction: deleteAutomation,
    updateMutation,
    organizationID,
    options: ["delete", "duplicate", "details", "edit"],
    duplicationSettings: {
      description: "Duplicates an Automation. Copies over Triggers, Conditions, and Automations",
      enabled: true,
      fields: ["name", "description", "triggers", "conditions", "automations"],
      nestedFields: {
        triggers: `{
          type
          webhookUrl
          schedule
        }`,
        conditions: `{
          typename
          rule
        }`,
        automations: `{
          id
          name
          type
          config
          description
        }`,
      },
      primaryField: "name",
      fieldAdaptor: {
        name: (item) => `${item.name}-duplicated`,
      },
    },
    ...props,
    ...gridConfig,
  });
};
