import React, { useEffect } from "react";
import { SketchPicker } from "react-color";
import { v4 as uuid } from "uuid";

/**
 * Configurable color picker component (used in Generic Edit Field)
 * @param {string} field - the name of the field being displayed (should be present as a property in item)
 * @param {string} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed (pass in the current state of the input)
 * @param {object} [inputConfig={}] - holds settings for the date component
 */
export const useCustomColor = ({ field, value, setValue, inputConfig = {} }) => {
  const id = uuid();

  // If a null value is provided, set it to white
  useEffect(() => {
    if (!value) {
      setValue("#ffffff");
    }
  }, []);

  const display = <SketchPicker color={value} onChangeComplete={(e) => setValue(e.hex)} />;

  return {
    display,
  };
};
