import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Items to display on mouse right click
 * @param {boolean} enableContextMenu - enable context menu for the grid
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteGqlQuery - graphql query for deleting an item. NOTE: should contain "__typename" field
 * @param {string} deleteMutation - graphql mutation for deleting an item.
 * @param {object} checkPermissionsHook - checks permissions for the grid component
 * @param {object} contextMenuConfig - configurations for the context menu
 * @param {object} duplicationSettings - duplication settings for the grid. see hooks/views/useDuplicateItems.js
 * @param {object[]} contextMenuConfig.items - extra items to add to the context menu
 * @param {string} contextMenuConfig.items[].text - text for context menu button
 * @param {string} contextMenuConfig.items[].iconCss - icon css for context menu button
 * @param {function} contextMenuConfig.items[].onClick - onClick handler for context menu button
 * @param {boolean} isMobile - passed down from UI context to change settings based on mobile view
 * @param {string} deleteButtonText - alternate text to display instead of 'Delete'
 * @param {string} deleteButtonIcon - alternate delete icon
 * @return {object} {[]}
 */
export const gridContextMenuItems = ({
  enableContextMenu,
  deleteFunction,
  deleteGqlQuery,
  deleteMutation,
  checkPermissionsHook,
  contextMenuConfig = {},
  duplicationSettings = {},
  isMobile,
  deleteButtonText = "Delete",
  deleteButtonIcon,
}) => {
  if (enableContextMenu) {
    const array = [];

    !contextMenuConfig?.disableDetails &&
      array.push({
        id: "details",
        text: "Details",
        iconCss: "icon-share-alt",
      });

    (!isNullOrUndefined(deleteFunction) || !isNullOrUndefined(deleteGqlQuery) || !isNullOrUndefined(deleteMutation)) &&
      !contextMenuConfig?.disableDelete &&
      checkPermissionsHook.resource.delete &&
      array.push({
        id: "delete",
        text: deleteButtonText || "Delete",
        iconCss: deleteButtonIcon || "icon-trash",
      });

    array.push(
      {
        id: "csv_export",
        text: "CSV Export",
        iconCss: "icon-calculator",
      },
      {
        id: "pdf_export",
        text: "PDF Export",
        iconCss: "icon-doc",
      },
    );

    if (duplicationSettings?.enabled === true && checkPermissionsHook.resource.create) {
      array.push({
        id: "duplicate_items",
        text: "Duplicate",
        iconCss: "icon-docs",
      });
    }

    if (!isNullOrUndefined(contextMenuConfig?.items) && Array.isArray(contextMenuConfig?.items)) {
      array.push(...contextMenuConfig.items);
    }

    if (!isMobile) {
      //Doesn't serve any purpose but to let the user know they can ctrl + right click to access the native context menu
      array.push({
        id: "more_options",
        text: "Ctrl + Right Click for More Options",
        iconCss: "icon-mouse",
      });
    }

    return array;
  }
};
