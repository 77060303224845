import React from "react";

import { useNestedDataCard } from "../../../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import CreateChange from "../../../../../RiskChanges/components/CreateChange";
import GroupedRiskChangeDataGrid from "../../../../../RiskChanges/components/GroupedRiskChangeDataGrid";
import { useGroupedRiskChangeDataGrid } from "../../../../../RiskChanges/hooks/useGroupedRiskChangeDataGrid/useGroupedRiskChangeDataGrid";
import { getSystem_riskChanges } from "../graphql/gql_getSystem_riskChanges";

/**
 * Displays Risk Changes for a particular system
 * @param organizationID
 * @param system
 * @param tableConfig
 */
export const useSystemRiskChangesGrid = ({ organizationID, system }) => {
  const nestedDataGrid = useNestedDataCard({
    item: system,
    query: getSystem_riskChanges,
    field: "riskChangeLinks",
    connectionField: "riskChange",
    parentTypename: "System",
    typename: "RiskChange",
    gridHook: useGroupedRiskChangeDataGrid,
    grid: <GroupedRiskChangeDataGrid />,
    createItemModalHeader: "Create a new Risk Change for this System",
    createResourceComponent: <CreateChange system={system} />,
    organizationID,
  });

  const display = <div style={{ height: "60vh" }}>{nestedDataGrid.gridDisplay}</div>;

  return {
    ...nestedDataGrid,
    display,
  };
};
