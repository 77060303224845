import Grid from "@mui/material/Grid";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import JobLogs from "../components/JobLogs";

export const useJobDetails = ({ itemId, organizationID, enableSubscription = true, module, resource, ...props }) => {
  const typename = "Job";

  const { getQuery, updateMutation, onUpdate } = generateGraphql("Job", [
    "status",
    "name",
    "createdAt",
    "completedAt",
    "source",
    "source",
    "sourceID",
    "logs",
  ]);

  const queryConfig = {
    query: getQuery,
    itemId,
    onUpdateItemSubscription: onUpdate,
  };

  const detailsConfig = {
    fields: ["status", "name", "createdAt", "completedAt", "source", "sourceID"],
  };

  const customFields = [
    {
      field: "createdAt",
      inputType: "date",
    },
    {
      field: "completedAt",
      inputType: "date",
    },
  ];

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    organizationID,
    module,
    resource,
    typename,
    updateMutation,
    customFields,
    ...props,
  });

  const display = (
    <Dashboard resetFunction={details.resetFunction}>
      <Grid container spacing={2}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <DashboardCard icon={"icon-list"} title="General Information" style={{ height: "100%" }}>
            {details.tableDisplay}
          </DashboardCard>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <JobLogs item={details.item} />
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
