import React from "react";

import VendorReviewReport from "../../../Reports/VendorReviewReport/components/VendorReviewReport";

/**
 * Generates a Report for this Vendor Review
 */
export const useGenerateReport = () => {
  const display = (
    <div className="e-card">
      <div className="e-card-content">
        <div className="dashboard-title e-card-title">Generate Report</div>
        <hr />
        <VendorReviewReport />
      </div>
    </div>
  );

  return {
    display,
  };
};
