import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useControlEvidenceLinking } from "../hooks/useControlEvidenceLinking";

/**
 * Component for showing Control Evidence Linking UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ControlEvidenceLinking = (props) => {
  return useControlEvidenceLinking(props).display;
};

export default withOrganizationCheck(ControlEvidenceLinking);
