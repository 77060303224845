import React from "react";

/**
 * A Component wrapper that allows to pass through props but also apply a container style
 * NOTE: was designed for a single child component
 * @param {object} wrapperStyle - css inline style to apply to the div wrapper
 * @param {JSX.Element[]} children - children to render inside the wrapper
 * @param {object} renameProps - an object of key value pairs to rename props that are being passed through
 * @param {function} [normalizeItem] - a function to remap the item being passed through
 * @param {object} props - any remaining props to pass through to the children
 * @return {JSX.Element}
 */
const StyledWrapper = ({ wrapperStyle = {}, children, renameProps, normalizeItem, ...props }) => {
  /**
   * Performs renaming of props if the option is passed in
   * @param {object} props - the props to possibly rename
   * @returns {{}|*}
   */
  const getProps = (props) => {
    let currentProps = props || {};
    if (typeof renameProps === "object") {
      // add the renamed props even if they are null
      const renamedProps = {};
      Object.keys(renameProps).forEach((key) => {
        renamedProps[renameProps[key]] = props?.[key];
      });
      // add all the non renamed props
      Object.keys(props).forEach((key) => {
        if (!renamedProps[key]) {
          renamedProps[key] = props[key];
        }
      });
      currentProps = renamedProps;
    }

    const currentItem = currentProps?.item;
    if (typeof normalizeItem === "function" && currentItem) {
      currentProps.item = normalizeItem({ item: currentItem });
    }

    return currentProps;
  };

  return <div style={wrapperStyle}>{React.cloneElement(children, getProps(props))}</div>;
};

export default StyledWrapper;
