import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useCustomWidgetDetails } from "../hooks/useCustomWidgetDetails";

/**
 * A component instance of the useCustomWidgetDetails.js hook
 * @param props
 */
const CustomWidgetDetails = (props) => {
  return useCustomWidgetDetails(props).display;
};

export default withDetailsPage(CustomWidgetDetails);
