import { useFrequencyBuilder } from "../../../hooks/views/useFrequencyBuilder/useFrequencyBuilder";

/**
 * WIP - work in progress, not yet implemented
 * Generic component for setting CRON expressions
 * @param {object} item - object with other info pertaining to this evidence
 * @param {string} frequency - current evidence frequency
 * @param {function} updateItemById - update evidence by id
 */
const FrequencyScheduler = (props) => useFrequencyBuilder(props).display;

export default FrequencyScheduler;
