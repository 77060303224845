// utility that uses the sortComparator function and takes in statusOrder as a parameter
export const enumSortComparator = (statusOrder) => {
  return (a, b) => {
    const aNum = statusOrder.indexOf(a);
    const bNum = statusOrder.indexOf(b);
    if (aNum > bNum) {
      return 1;
    } else if (aNum < bNum) {
      return -1;
    } else {
      return 0;
    }
  };
};
