import { isNullOrUndefined, tryParse } from "@rivial-security/func-utils";

import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { stripRiskRecommendationCalculationDataForSaving } from "../../Recommendations/functions/stripRiskRecommendationCalculationDataForSaving";
import { RISK_CHANGE_TYPE } from "../constants/riskChangeType";

/**
 * Calls the createRiskChange lambda function through fargate by taking information from the useCreateRiskChangeWorkflow hook
 * 1. goes to fargateAPI to queue the request
 * 2. fargate queue gets retrieved by a fargate instance
 * 3. createRiskChange lambda is called to perform final calculations and save the risk change
 * @param {object} riskChangeFormInput - all top level risk change values, name is required
 * @param {object} riskRecommendationData - useRiskRecommendationData hook instance
 * @param {string} changeType - an enum value from RISK_CHANGE_TYPE, determines the type of change
 * @param {string} organizationID - the organization for which to perform the change
 * @param {string} sentryTrace - sentry trace for tracing calls from frontend to backend
 * @returns {object} - lambda call result with status code and message (see README for the lambda)
 */
export const callCreateRiskChangeLambda = async ({
  riskChangeFormInput,
  riskRecommendationData,
  changeType,
  organizationID,
}) => {
  //Check top level arguments
  if (!riskChangeFormInput || !riskRecommendationData || !changeType) {
    throw new Error("Invalid input was provided into createRiskChange!");
  }

  //Clone calculations data and delete any extraneous data that would create a larger request than necessary
  // - if calculationResults are passed in, we send these directly to the lambda and skip a second calculation
  const calculations = stripRiskRecommendationCalculationDataForSaving({
    calculations: riskRecommendationData?.calculationResults,
  });

  //Determine if the change type is of non-standard control type instead of enterprise control
  if (riskRecommendationData?.selectedSystemID && changeType === RISK_CHANGE_TYPE.ENTERPRISE_CONTROL) {
    changeType = RISK_CHANGE_TYPE.NON_STANDARD_CONTROL;
  }

  //Create the lambda input
  const input = {
    change: {
      ...riskChangeFormInput,
      type: changeType, // type of risk change (standard, enterprise or kri)
      organizationID,
      control: {
        ...(changeType === RISK_CHANGE_TYPE.NON_STANDARD_CONTROL && {
          system: { id: riskRecommendationData?.selectedSystemID },
        }),
        costChange: parseFloat(riskRecommendationData?.changeInCost),
        implementationChange: parseFloat(riskRecommendationData?.changeInImplementation) / 100,
        riskControl: riskRecommendationData?.selectedRiskControl,
      },
    },
    calculations, // This is okay to be null, will force the lambda to run it's own calculations
  };

  //Create query
  const createRiskChange = `
    query createRiskChange($input: AWSJSON) {
      createRiskChange(input: $input)
    }
  `;

  //Make the lambda request
  const result = await GetQuery({
    query: createRiskChange,
    variables: {
      input: JSON.stringify(input),
    },
  });

  const parsedResults = tryParse(result);
  const { status, body } = parsedResults || {};
  const parsedBody = tryParse(body);
  const firstError = parsedBody?.errors?.[0];

  const hasRiskChangeID = !isNullOrUndefined(parsedBody?.id);

  const completedCreatingRiskChange = status === 200 && hasRiskChangeID;
  const completedSubmittingRiskChange = firstError?.errorType === "Lambda:ExecutionTimeoutException";

  if (completedCreatingRiskChange || completedSubmittingRiskChange) {
    return parsedResults;
  } else {
    throw new Error(`Could not validate a successful response from the createRiskChange lambda!`);
  }
};
