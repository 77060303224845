"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAssetSizeChange = void 0;
const getAllSystems_1 = require("../getAllSystems");
const handleChange_1 = require("../handleChange");
/**
 * Gets all Systems for the Organization and calculates the total effect from a change in Asset Size
 * @param change
 * @param organizationID
 * @param queryConfig
 */
const handleAssetSizeChange = async (change, organizationID, queryConfig) => {
    if (change?.assetSizeChange) {
        const associatedSystems = await (0, getAllSystems_1.getAllSystems)(organizationID);
        const changeItem = {
            assetSizeChange: change.assetSizeChange,
        };
        return await (0, handleChange_1.handleChange)(associatedSystems, changeItem, organizationID, queryConfig);
    }
    throw new Error("handleAssetSizeChange is missing assetSizeChange");
};
exports.handleAssetSizeChange = handleAssetSizeChange;
