import React, { useEffect } from "react";
import { Input } from "reactstrap";

import { useNewPassword } from "../../../../utils/SignIn/hooks/useNewPassword";
import { useBoolean } from "../../../functional/useBoolean";

/**
 * @description Allow the user to input a password
 * @param {function} onChange - set the value of the input
 * @param {string} value - the value of the input
 * @param {string} fieldName - the name of the input
 * @param {boolean} required - whether the input is required
 * @param {string} defaultValue - the default value of the input
 * @param {object} passwordConfig - the config for the input
 * @param {boolean} [passwordConfig.confirmPassword = false] - display a confirmation UI to enter a password
 * @returns {JSX.Element}
 * @constructor
 */
const CustomPasswordInput = ({
  onChange,
  value = "",
  fieldName,
  defaultValue,
  passwordConfig = {
    confirmPassword: false,
  },
}) => {
  const passwordHook = useNewPassword();

  const [isFirstRender, setIsFirstRender] = useBoolean(true);

  /**
   * Set the password default value on the first render if it exists
   */
  useEffect(() => {
    if (defaultValue && isFirstRender) {
      setIsFirstRender(false);
      passwordHook.setNewPassword(defaultValue);
      passwordHook.setReEnterPassword(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    /**
     * Check if re-entered password matches the new password
     * Also check if the new password is valid
     */
    if (passwordHook?.isPasswordMatched && passwordHook?.isValidPassword) {
      onChange(passwordHook?.reEnterPassword);
    } else {
      /**
       * If the password is not valid, clear the input
       */
      onChange("");
    }
  }, [passwordHook?.reEnterPassword, passwordHook?.isPasswordMatched, passwordHook?.isValidPassword]);

  return (
    <div data-testid={`custom_input_password_${fieldName}`}>
      {passwordConfig?.confirmPassword ? (
        passwordHook.display
      ) : (
        <Input
          id={`custom_input_password_field_${fieldName}`}
          type="password"
          value={value}
          onChange={(event) => onChange(event?.target?.value)}
        />
      )}
    </div>
  );
};

export default CustomPasswordInput;
