import { Button as MuiButton } from "@mui/material";
import React, { useState } from "react";

import { convertCamelCaseToSentence, isNullOrUndefined, removeDuplicateObjects } from "@rivial-security/func-utils";

import { getMany } from "../../../../utils/Functions/getMany";
import AddOrRemoveIcon from "../../../../utils/GenericComponents/AddOrRemoveIcon";
import { useModal } from "../../useModal";
import { STEP_STATUS, usePleaseWaitModal } from "../../usePleaseWaitModal";
import { useTransferList } from "../../useTransferList/useTransferList";
import { handleLinking } from "../functions/handleLinking";
import { handleUnlinking } from "../functions/handleUnlinking";

import { usePopulateExistingResources } from "./usePopulateExistingResources";
import { usePopulateItemLinkedResources } from "./usePopulateItemLinkedResources";

/**
 * Allows the user to link/unlink Multiple Resources to a Single Resource via many-to-many connections
 *
 * @param {string} organizationID - Organization ID of the current organization
 * @param {Control} item - the item that is being adjusted
 * @param {function} [resetFunction] - handler for refreshing the item after the adjustment
 * @param {function} [toggleModal] - handler for closing a modal after the adjustment
 * @param {string} field - the item field that holds the connection data
 * @param {string} nestedField - the item field on the many-to-many connection object that holds the child connection object
 * @param {object[]} existingItems - the database objects to use as candidates for linking to the item
 * @param {string} typename - the typename of the many-to-many connection model
 * @param {string} parentConnectionIDField - the first connection ID field in the many-to-many object
 * @param {string} childConnectionIDField - the second connection ID field in the many-to-many object
 * @param {JSX.Element} [createResourceComponent] - an optional form component for creating new resources to link
 * @param {function} [normalizeLinkedItems] - a function to edit the currently linked items before they are displayed
 * @param {string} [childTypename] - the typename of the resource to be linked, used for titles
 * @param {string} [parentTypename] - the typename of the resource being linked to, used for titles
 * @param {function} [updateLinkFunction] - a function that can be called right before the mutation, takes the mutation input and returns the mutation input with updates
 * @param {function} [handleLinkingFunction] - a custom function for handling the linking process
 * @param {function} [handleUnlinkingFunction] - a custom function for handling the unlinking process
 * @param {function} [handleChangedItemsFunction] - a custom function for handling the changed items after mutation is complete
 * @param {function} listTitleAdapter - updates the list items in each side of the transfer list
 * @param {function} onLinkCallback - gets called for every new item being linked
 * @param {function} onUnlinkCallback - gets called for every item being unlinked
 * @returns {{display: JSX.Element}}
 */
export const useManyLinking = ({
  organizationID,
  item,
  resetFunction,
  toggleModal,
  field,
  nestedField,
  existingItems,
  typename,
  parentConnectionIDField,
  childConnectionIDField,
  createResourceComponent,
  childTypename = "Resource",
  parentTypename = "Resource",
  updateLinkFunction,
  normalizeLinkedItems,
  handleLinking: handleLinkingFunction,
  handleUnlinking: handleUnlinkingFunction,
  handleChangedItems: handleChangedItemsFunction,
  listTitleAdapter,
  onLinkCallback,
  onUnlinkCallback,
}) => {
  const [key, setKey] = useState(0);

  /**
   * Adds new items to the transfer list if a createResourceComponent is being used
   * @param item
   */
  const createResourceCallback = (item) => {
    transferList.setRightItems((notLinked) => [...notLinked, item]);
    transferList.createResourceModal.setModalIsOpen(false);
  };

  // const createResourceHook = useCreateEvidence({organizationID, getNewItem: createEvidenceCallback});
  const pleaseWait = usePleaseWaitModal({
    autoClose: true,
    autoCloseInterval: 1,
    confirmationText: "Congratulations, you've successfully Linked and Unlinked Resources!",
    steps: [
      {
        id: "unlink",
        text: "Unlinking Resources",
      },
      {
        id: "link",
        text: "Link Resources",
      },
    ],
  });

  /**
   * Performs the operation that links and unlinks items
   */
  const handleSubmit = async () => {
    pleaseWait.setModalIsOpen(true);
    pleaseWait.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

    /**
     * if a 'handleUnlinking' function param is passed in, use this instead of the default function
     */
    if (!isNullOrUndefined(handleUnlinkingFunction)) {
      await handleUnlinkingFunction(item, transferList.rightItems, field, nestedField, typename);
    } else {
      await handleUnlinking(item, transferList.rightItems, field, nestedField, typename, onUnlinkCallback);
    }

    pleaseWait.setStepStatus(0, STEP_STATUS.COMPLETE);
    pleaseWait.setStepStatus(1, STEP_STATUS.IN_PROGRESS);

    /**
     * if a 'handleLinking' function param is passed in, use this instead of the default function
     */
    if (!isNullOrUndefined(handleLinkingFunction)) {
      await handleLinkingFunction(
        item,
        transferList.leftItems,
        organizationID,
        field,
        nestedField,
        typename,
        parentConnectionIDField,
        childConnectionIDField,
        updateLinkFunction,
      );
    } else {
      await handleLinking(
        item,
        transferList.leftItems,
        organizationID,
        field,
        nestedField,
        typename,
        parentConnectionIDField,
        childConnectionIDField,
        updateLinkFunction,
        normalizeLinkedItems,
        onLinkCallback,
      );
    }

    if (handleChangedItemsFunction) {
      const newItems = transferList.leftItems;
      let changedItems = transferList.leftItems;
      const previousItems = getMany(item, field, nestedField);
      changedItems = changedItems.concat(previousItems);
      changedItems = removeDuplicateObjects(changedItems);
      handleChangedItemsFunction(changedItems, newItems);
    }

    pleaseWait.setStepStatus(1, STEP_STATUS.COMPLETE);
    pleaseWait.setFinished(true);
    pleaseWait.setModalIsOpen(false);
    resetFunction && resetFunction();
    toggleModal && toggleModal();
    linkingModal?.setModalIsOpen(false);
    setKey((key) => key + 1);
  };

  const transferList = useTransferList({
    listTitleAdapter,
    typename: childTypename || "Resource",
    handleSubmit,
    leftTitle: `Linked ${convertCamelCaseToSentence(childTypename)}s`,
    rightTitle: `Available ${convertCamelCaseToSentence(childTypename)}s`,
    createResourceComponent:
      !isNullOrUndefined(createResourceComponent) &&
      React.cloneElement(createResourceComponent, {
        getNewItem: createResourceCallback,
      }),
  });

  /**
   * Populates the right side of the transfer list with available resources for linking
   */
  usePopulateExistingResources(
    item,
    transferList.setRightItems,
    existingItems,
    field,
    nestedField,
    normalizeLinkedItems,
  );

  /**
   * Populates the left side of the transfer list for resources that are already linked to the item
   */
  usePopulateItemLinkedResources(item, transferList.setLeftItems, field, nestedField, normalizeLinkedItems);

  const linkingText = `Link / Unlink ${convertCamelCaseToSentence(
    childTypename,
  )}s for this ${convertCamelCaseToSentence(parentTypename)}`;

  const linkingModal = useModal(
    <span>{linkingText}</span>,
    <span>
      {transferList.display}
      {pleaseWait.modal}
    </span>,
    <MuiButton className="float-right" title={linkingText}>
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "80vw",
    },
  );

  const display = (
    <span>
      {pleaseWait.modal}
      {transferList.display}
    </span>
  );

  return {
    display,
    createResourceCallback,
    ...linkingModal,
    key,
    setKey,
  };
};
