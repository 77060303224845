export const getLossTolerance = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    lossToleranceCurve {
      amount
      probability
    }
  }
}`;
