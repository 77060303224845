import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";

/**
 * A generic button to use throughout all login screen pages
 * @param {string} startIcon - iconify string to place at the beginning of the button
 * @param {string} text - the words to display in the middle of the button
 * @param {string} textColor - custom button text color (hex)
 * @param {string} buttonColor - custom button background color (hex)
 * @param {string} [buttonBorder] - css style for the button border
 * @param {object} props - additional props to pass down to the mui button
 * @return {JSX.Element}
 */
const LoginButton = ({ startIcon, text, textColor, buttonColor, buttonBorder, ...props }) => {
  // Create grey button theme
  const theme = createTheme({
    palette: {
      primary: {
        main: buttonColor,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        startIcon={startIcon}
        variant="contained"
        fullWidth={true}
        disableElevation={true}
        style={{
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: "1em",
          color: textColor,
          ...(buttonBorder && { border: buttonBorder }),
        }}
        {...props}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {text || "Login"}
        </div>
      </Button>
    </ThemeProvider>
  );
};

export default LoginButton;
